import { AxiosResponse } from 'axios';
import { useState } from 'react';

import { api } from 'fr-shared/api';
import { ManufacturabilityChecks } from 'fr-shared/lib/manufacturability_checks';
import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';
import { getMarketoCookies } from 'fr-shared/lib/marketo';

import { CNC_DEFAULT_VALUES } from 'portal/pages/part_config/util';

import { AutoquoteBulkConfigRequest, AutoquoteErrors, AutoquoteRequest } from './autoquote';

export interface Cart {
  id: number;
  line_items: Array<CartLineItem>;
  is_manual: boolean;
  submitted_at: string;
}

interface InspectionType {
  id: number;
  name: string;
}

interface ManufacturingProcess {
  id: number;
  name: string;
}

/**
 * Contains that numbers of error and warning manufacturability checks that
 * failed for a specific manufacturing process ID.
 */
export interface FailedManufacturabilityCheckCounts {
  manufacturing_process_id: number;
  failed_error_checks_count: number;
  failed_warning_checks_count: number;
}

export interface CartLineItem {
  autoquotable_errors?: string[];
  color_id?: number;
  color_name?: string;
  comments?: string;
  /** the standard or custom finish for customer portal note */
  customer_portal_finish?: string;
  description?: string;
  finish_name?: string;
  finish_note?: string;
  /** the finish that belongs to a material */
  finish_id?: number;
  id?: number | string;
  infill?: string;
  inspection_type_id?: number;
  inspection_type_name?: string;
  inspection_type?: InspectionType;
  is_complete?: boolean;
  is_manual?: boolean;
  is_prototype: boolean;
  layer_thickness_id?: number;
  lead_time?: { max_days: number; min_days: number };
  location_id?: number;
  failed_manufacturability_check_counts?: FailedManufacturabilityCheckCounts;
  manufacturing_process_id?: number;
  manufacturing_process?: ManufacturingProcess;
  material_id?: number;
  material_name?: string;
  notes?: string;
  part_id?: number;
  part?: Part;
  process_name?: string;
  promised_ship_date?: string;
  quantity?: number;
  cnc_surface_roughness?: SurfaceRoughness;
  cnc_surface_roughness_id?: number;
  cnc_gdt?: boolean;
  cnc_masked_surfaces?: boolean;
  cnc_thread_count?: number;
  cnc_thread_variety?: number;
  cnc_advanced_features?: boolean;
  cnc_certifications?: boolean;
  cnc_tolerance_count_0005?: number;
  cnc_tolerance_count_001?: number;
  cnc_tolerance_count_002?: number;
  cnc_tolerance_count_003?: number;
  part_usage?: string;
  part_designation?: string;
  tolerance?: Partial<Tolerance>;
  tolerance_id?: number;
  read_only?: boolean;
  unit_price?: Money;
  total_price?: Money;
}

interface FRDocument {
  id: number;
  file_name?: string;
  file_type?: string;
  s3_path?: string;
  created_by_id?: number;
  url?: string;
}

export interface Part {
  id: number;
  name?: string;
  documents?: Array<FRDocument>;
  current_revision?: PartFileRevision;
  revisions?: Array<PartFileRevision>;
  organization_id?: string;
  source?: string;
  units?: string;
  has_timed_out?: boolean;
}

export interface PartFileRevision {
  id: number;
  name?: string;
  s3_path?: string;
  inserted_at?: string;
  did_manufacturability_checks_run?: boolean;
  // TODO: Remove manufacturability_checks after the old part config page is
  // removed.
  manufacturability_checks?: ManufacturabilityChecks;
  manufacturability_checks_v2?: ManufacturabilityCheck[];
  manufacturability_check_files: ManufacturabilityCheckFile[];
  failed_manufacturability_check_counts_by_process?: FailedManufacturabilityCheckCounts[];
  stl_url?: string;
  screenshot?: string;
  part_id: number;
  part_name?: string;
  max_x_length?: number;
  max_y_length?: number;
  max_z_length?: number;
  volume?: number;
  part_units?: string;
  is_analyzed?: boolean;
}

export const convertLineItemToCartLineItem = (lineItem: CartLineItem) => {
  const lineItemParams = {
    quantity: lineItem.quantity,
    finish_note: lineItem.finish_name,
    notes: lineItem.notes,
    part_id: lineItem.part.id,
    manufacturing_process_id: lineItem.manufacturing_process_id,
    color_id: lineItem.color_id,
    material_id: lineItem.material_id,
    inspection_type_id: lineItem.inspection_type_id,
    customer_portal_finish: lineItem.customer_portal_finish || 'Standard',
  };

  return lineItemParams;
};

export interface CreateCartParams {
  part_id: number | string;
  color_id?: number | string;
  manufacturing_process_id: number | string;
  material_id?: number | string;
  layer_thickness_id?: number | string;
}

type CreateCartAsync = (
  params: CreateCartParams,
  processName: string
) => Promise<AxiosResponse<any>>;

export const useCreateCart = (): { isSubmitting: boolean; createCart: CreateCartAsync } => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createCart = (params: CreateCartParams, processName: string) => {
    setIsSubmitting(true);

    let lineItemParams = {
      part_id: params.part_id,
      color_id: params.color_id,
      manufacturing_process_id: params.manufacturing_process_id,
      material_id: params.material_id,
      layer_thickness_id: params.layer_thickness_id,
    };

    // If we're in CNC, we need to add default values when creating the CLI in
    // order for it to be autoquotable
    if (processName === MANUFACTURING_PROCESSES.CNC) {
      lineItemParams = { ...lineItemParams, ...CNC_DEFAULT_VALUES };
    }

    return api
      .post('/customer_portal/cart_line_items', {
        line_item: lineItemParams,
        marketo_attrs: getMarketoCookies(),
      })
      .catch(error => {
        throw error;
      })
      .finally(() => setIsSubmitting(false));
  };

  return { isSubmitting, createCart };
};

export const addLineItemsToCart = (lineItems: Array<CartLineItem>) => {
  const cliParams = lineItems.map(convertLineItemToCartLineItem);

  return api.post('/customer_portal/cart_line_items/bulk', {
    line_items: cliParams,
    marketo_attrs: getMarketoCookies(),
  });
};

const transformPartToRequest = (part: Part) => ({
  part: part,
  part_id: part.id,
  units: part.units,
  quantity: 1,
});

export const addPartsToCart = (parts: Array<Part>) => {
  const cliParams = parts.map(transformPartToRequest);

  return api.post('/customer_portal/cart_line_items/bulk', {
    line_items: cliParams,
    marketo_attrs: getMarketoCookies(),
  });
};

export const addPartToCart = (part: Part) => {
  return api.post('/customer_portal/cart_line_items', {
    lineItem: transformPartToRequest(part),
    market_attrs: getMarketoCookies(),
  });
};

export const convertLineItemToAutoquoteRequest = (lineItem: CartLineItem): AutoquoteRequest => {
  return convertLineItemAndPartToAutoquoteRequest(lineItem, lineItem.part, false);
};

export const convertLineItemAndPartToAutoquoteRequest = (
  lineItem: CartLineItem,
  part: Part,
  useDefaults: boolean
): AutoquoteRequest => {
  return {
    cnc_advanced_features: lineItem.cnc_advanced_features,
    cnc_certifications: lineItem.cnc_certifications,
    cnc_gdt: lineItem.cnc_gdt,
    cnc_surface_roughness_id: lineItem.cnc_surface_roughness?.id,
    cnc_masked_surfaces: lineItem.cnc_masked_surfaces,
    cnc_thread_count: lineItem.cnc_thread_count,
    cnc_thread_variety: lineItem.cnc_thread_variety,
    cnc_tolerance_count_0005: lineItem.cnc_tolerance_count_0005,
    cnc_tolerance_count_001: lineItem.cnc_tolerance_count_001,
    cnc_tolerance_count_002: lineItem.cnc_tolerance_count_002,
    cnc_tolerance_count_003: lineItem.cnc_tolerance_count_003,
    color_id: lineItem.color_id,
    customer_portal_finish: lineItem.customer_portal_finish,
    finish_id: lineItem.finish_id,
    finish_note: lineItem.finish_note,
    infill: lineItem.infill,
    inspection_type_id: lineItem.inspection_type_id,
    layer_thickness_id: lineItem.layer_thickness_id,
    manufacturing_process_id: lineItem.manufacturing_process_id,
    material_id: lineItem.material_id,
    part_file_revision_id: part?.current_revision?.id,
    part_id: part.id,
    quantity: lineItem.quantity,
    tolerance_id: lineItem.tolerance?.id,
    use_defaults: useDefaults,
  };
};

export const convertLineItemAndPartToBulkConfigAutoquoteRequest = (
  lineItem: CartLineItem,
  parts: Array<Part & { quantity: number }>,
  useDefaults: boolean
): AutoquoteBulkConfigRequest => {
  return {
    cnc_advanced_features: lineItem.cnc_advanced_features,
    cnc_certifications: lineItem.cnc_certifications,
    cnc_gdt: lineItem.cnc_gdt,
    cnc_surface_roughness_id: lineItem.cnc_surface_roughness?.id,
    cnc_masked_surfaces: lineItem.cnc_masked_surfaces,
    cnc_thread_count: lineItem.cnc_thread_count,
    cnc_thread_variety: lineItem.cnc_thread_variety,
    cnc_tolerance_count_0005: lineItem.cnc_tolerance_count_0005,
    cnc_tolerance_count_001: lineItem.cnc_tolerance_count_001,
    cnc_tolerance_count_002: lineItem.cnc_tolerance_count_002,
    cnc_tolerance_count_003: lineItem.cnc_tolerance_count_003,
    color_id: lineItem.color_id,
    customer_portal_finish: lineItem.customer_portal_finish,
    finish_id: lineItem.finish_id,
    finish_note: lineItem.finish_note,
    infill: lineItem.infill,
    inspection_type_id: lineItem.inspection_type_id,
    layer_thickness_id: lineItem.layer_thickness_id,
    manufacturing_process_id: lineItem.manufacturing_process_id,
    material_id: lineItem.material_id,
    part_data: Object.keys(parts).map((key: string) => {
      return {
        part_file_revision_id: parts[parseInt(key)]?.current_revision?.id,
        quantity: parts[parseInt(key)].quantity,
      };
    }),
    tolerance_id: lineItem.tolerance?.id,
    use_defaults: useDefaults,
  };
};

export const CART_MANUAL_REASONS = {
  UnableToCalculateWeight: 'unable_to_calculate_weight',
  MultipleSupplierRegions: 'multiple_supplier_regions',
  ExceedsCartWeight: 'exceeds_cart_weight_limit',
  CartExceedsMaxPrice: 'cart_exceeds_max_price_amount_allowed',
};

const MISSING_INFO_REASONS: string[] = [
  AutoquoteErrors.MissingCNCAdvancedFeatures,
  AutoquoteErrors.MissingCNCCertification,
  AutoquoteErrors.MissingCncGdt,
  AutoquoteErrors.MissingTolerance,
  AutoquoteErrors.MissingSupportingDocument,
  AutoquoteErrors.Incomplete,
];

export interface CartLineItemWithChecks {
  failed_manufacturability_check_counts?: FailedManufacturabilityCheckCounts;
  part?: {
    id: number;
    current_revision?: {
      did_manufacturability_checks_run?: boolean;
    };
  };
  process_name?: string;
}

/**
 * Returns true only if the given {@link cartLineItem} manufacturability checks are incomplete.
 */
export function manufacturabilityChecksIncomplete(cartLineItem: CartLineItemWithChecks): boolean {
  return cartLineItem.part?.current_revision?.did_manufacturability_checks_run === false;
}

/**
 * Returns whether the given {@link cartLineItem} has non-critical issues with the DFM checks.
 */
export function hasNonCriticalIssues(cartLineItem: CartLineItemWithChecks): boolean {
  return (
    !manufacturabilityChecksIncomplete(cartLineItem) &&
    cartLineItem.failed_manufacturability_check_counts?.failed_warning_checks_count > 0
  );
}

/**
 * Returns whether the given {@link cartLineItem} has critical issues with the DFM checks.
 */
export function hasCriticalIssues(cartLineItem: CartLineItemWithChecks): boolean {
  return (
    !manufacturabilityChecksIncomplete(cartLineItem) &&
    cartLineItem.failed_manufacturability_check_counts?.failed_error_checks_count > 0
  );
}

/**
 * Returns whether the given {@link autoquotableErrors} indicates missing info
 * based on a number of different reasons in {@link MISSING_INFO_REASONS}.
 */
export function hasMissingInfo(autoquotableErrors: string[]): boolean {
  return (autoquotableErrors || []).some(err => MISSING_INFO_REASONS.includes(err));
}

/**
 * Returns whether the given {@link autoquotableErrors} indicates an invalid file type.
 */
export function hasInvalidFileType(autoquotableErrors: string[]): boolean {
  return (autoquotableErrors || []).some(err => err === AutoquoteErrors.InvalidFileType);
}
