import React from 'react';

import { IconFont, classNames } from 'fr-shared/components';

import { PartImage } from 'portal/components';
import { PartFileRevision } from 'portal/lib/cart';
import { COST_DRIVER_COLORS, CostDriver } from 'portal/lib/cost_drivers';
import { filterChecksByCostDriver } from 'portal/lib/manufacturability_check';

import ColorSwatchSVG from './ColorSwatchSVG';
import styles from './CostCurves.module.css';
import { NEW_COST_DRIVER_COPY } from './CostDriversCopy';
import CostDriversDonutChart from './CostDriversDonutChart';
import carouselStyles from './CostingRecommendationsCarousel/CostingRecommendationsCarousel.module.css';
import MachiningInfoSection from './MachiningInfoSection';

interface Props {
  costDrivers: Array<CostDriver>;
  failedChecks: ManufacturabilityCheck[];
  handleCostDriverCopy: (driver: CostDriver) => void;
  machineType: string;
  machineTime: number;
  manufacturingProcess: any;
  onOpenPartViewerModal: (index: number) => void;
  partFileRevision: PartFileRevision;
  setCostDriver: (driver: CostDriver) => void;
}

const ExpandedCostDrivers = ({
  costDrivers,
  failedChecks,
  handleCostDriverCopy,
  machineTime,
  machineType,
  manufacturingProcess,
  onOpenPartViewerModal,
  partFileRevision,
  setCostDriver,
}: Props) => {
  const handlePartViewerOpen = (driver: CostDriver, index: number) => {
    setCostDriver(driver);
    onOpenPartViewerModal(index);
  };

  return (
    <div data-testid="costing-expanded-cost-drivers" className="flex-column w-100">
      <div className="flex-column w-100">
        <div className="mb-2" style={{ alignItems: 'center' }}>
          <IconFont name="icon-quote" className="icon-quote mr-1" style={{ fontSize: '20px' }} />
          <span className="text-base text-coolGray-100">Cost drivers</span>
        </div>
        <span className={classNames([styles.HorizontalDivider])} />
      </div>
      {costDrivers
        .slice()
        .sort((a, b) => {
          return b.percentage - a.percentage;
        })
        .map((costDriver, index) => {
          const name = costDriver.cost_driver_name;
          const color = COST_DRIVER_COLORS.find(color => name === color.cost_driver_name)?.color;
          const failedCheckRecommendations =
            costDriver !== null
              ? filterChecksByCostDriver(failedChecks, costDriver).slice(0, 5)
              : [];
          return (
            <div key={`cost-driver${index}`}>
              <span
                className="text-base text-coolGray-100"
                style={{ textTransform: 'capitalize' }}
              >
                <span style={{ marginRight: '16px' }}>
                  <ColorSwatchSVG color={color} />
                </span>
                {name}
              </span>
              <div
                className={classNames([
                  'flex flex-row mt-2',
                  index !== costDrivers.length - 1 && 'mb-6',
                  index === costDrivers.length - 1 && 'mb-2',
                ])}
                key={`cost-driver-${index}`}
              >
                <section className="border-right">
                  <CostDriversDonutChart
                    onDriverUpdate={handleCostDriverCopy}
                    costDrivers={costDrivers}
                    renderLegend={false}
                    highlightSector
                    selectedDriver={costDriver}
                  />
                </section>
                <div className="flex flex-1 flex-column">
                  <div
                    className={classNames([
                      'flex flex-row',
                      failedCheckRecommendations.length > 0 && 'border-bottom',
                    ])}
                  >
                    <section
                      className={classNames([
                        'flex-1 w-1/2',
                        name === 'equipment' && (machineTime || machineType) && 'border-right',
                      ])}
                    >
                      <div className="mx-4 mb-4">
                        <>
                          <div>
                            {
                              NEW_COST_DRIVER_COPY[manufacturingProcess?.name][
                                costDriver?.cost_driver_name
                              ]
                            }
                          </div>
                        </>
                      </div>
                    </section>
                    <section className="flex-1 w-1/2">
                      <div className="mx-4 mb-4">
                        {name === 'equipment' && (
                          <MachiningInfoSection
                            machineTime={machineTime}
                            machineType={machineType}
                          />
                        )}
                      </div>
                    </section>
                  </div>

                  {name !== null && (
                    <>
                      {failedCheckRecommendations.length > 0 && (
                        <section className="flex-1">
                          <div>
                            <div className="flex flex-row flex-wrap w-100">
                              {failedCheckRecommendations.map((recommendation, index) => {
                                return (
                                  <div
                                    className={classNames([
                                      'w-1/2 border-bottom',
                                      styles.Recommendation,
                                      failedCheckRecommendations.length !== 1 && 'border-right',
                                    ])}
                                    key={`recommendation-${index}`}
                                  >
                                    <div className={classNames(['mx-4 my-4'])}>
                                      {index === 0 && (
                                        <div
                                          className="mb-2 text-coolGray-300"
                                          data-testid="costing-recommendations-section"
                                        >
                                          Recommendation
                                        </div>
                                      )}
                                      <div className="text-coolGray-100 text-base mb-1">
                                        {recommendation.label}
                                      </div>
                                      <div className="text-coolGray-100 mb-4">
                                        {recommendation.description}
                                      </div>
                                      <div
                                        className="position-relative"
                                        style={{ margin: '0 25%' }}
                                      >
                                        <PartImage
                                          size="md"
                                          backdrop={false}
                                          onClick={() => handlePartViewerOpen(costDriver, index)}
                                          src={partFileRevision.screenshot}
                                          className="cursor-pointer"
                                        />
                                        <button
                                          onClick={() => handlePartViewerOpen(costDriver, index)}
                                          className={classNames([
                                            carouselStyles.ExpandIconBackground,
                                            'position-absolute -right-6 bottom-0 border-none',
                                          ])}
                                        >
                                          <IconFont
                                            name="expand-out"
                                            className={carouselStyles.ExpandIcon}
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </section>
                      )}
                    </>
                  )}
                </div>
              </div>
              {index !== costDrivers.length - 1 && (
                <span className={classNames([styles.HorizontalDivider])} />
              )}
            </div>
          );
        })}
    </div>
  );
};

export default ExpandedCostDrivers;
