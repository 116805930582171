import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { api } from 'fr-shared/api';
import { Button } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';

const Webhook = ({ webhook, onDelete }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useContext(UserContext);
  const handleDelete = () => {
    setIsLoading(true);
    api
      .delete(`/smartsheet_webhooks/${webhook.id}`)
      .then(() => onDelete(webhook))
      .finally(() => setIsLoading(false));
  };
  return (
    <div className="p-2 flex flex-row">
      <div className="flex-1">
        <h3>
          Name: <strong>{webhook.name}</strong>
        </h3>
        <div>
          ID: <strong>{webhook.id}</strong>{' '}
        </div>
        <div>
          Created At: <strong>{webhook.created_at}</strong>
        </div>
        <div>
          Status: <strong>{webhook.status}</strong>
        </div>
      </div>
      <div className="ml-a flex flex-column justify-content-around">
        {user.canDeleteWebhooks && (
          <Button outline={true} color="danger" onClick={handleDelete} loading={isLoading}>
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

Webhook.propTypes = {
  onDelete: PropTypes.func,
  webhook: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    created_at: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default Webhook;
