import * as Sentry from '@sentry/react';
import { useTreatments } from '@splitsoftware/splitio-react';
import { isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { api } from 'fr-shared/api';
import { Breadcrumb, GlobalErrorBoundary, Helmet, Modal, NotFound } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { CR_BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import {
  COSTING_REQUEST_LI_STATES,
  COSTING_REQUEST_STATES,
  isSubmitted,
} from 'fr-shared/lib/costing_requests';

import CostingForm from './components/CostingForm';
import DuplicateLineItemsForm from './components/DuplicateLineItemsForm';
import { formatErrorMessages, formatFormikErrors } from './utils/formatErrorMessages';
import {
  transformCostingRequestFromLoad,
  transformCostingRequestLineItem,
  transformNewRequest,
} from './utils/transforms';

const handleError = (err, values, formik) => {
  if (err.response?.data) {
    const { errors, messages } = err.response.data;
    formik.setErrors({
      server: formatErrorMessages(errors, messages, values.line_items),
      ...formatFormikErrors(errors),
      'customer_contact.id': errors && errors.customer_contact_id,
      'customer.id': errors && errors.customer_id,
    });
  } else {
    formik.setErrors({
      server: ['An unexpected error occurred. Refresh and try again.'],
    });
  }
};

const CostingEdit = ({ match }) => {
  const { [CR_BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';
  const [initialValues, setInitialValues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [initialDuplicateLineItems, setInitialDuplicateLineItems] = useState([]);
  const [submittingDuplicateLineItems, setSubmittingDuplicateLineItems] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const history = useHistory();
  const costingRequestId = match.params.id;

  const getCostingRequest = useCallback(() => {
    setInitialValues(null);
    setIsLoading(true);
    setHasError(false);
    api
      .get(`/costing_requests/${costingRequestId}`)
      .then(res => {
        setInitialValues(transformCostingRequestFromLoad(res.data));
      })
      .catch(error => {
        if (error.response?.status !== 404) {
          setHasError(true);
          Sentry.captureMessage('Costing Request GET Failed');
          Sentry.setExtra('costing_request_id', costingRequestId);
          Sentry.setExtra('error', error);
        }
      })
      .finally(() => setIsLoading(false));
  }, [costingRequestId]);

  useEffect(() => {
    getCostingRequest();
  }, [getCostingRequest]);

  const handleSubmitTeamContacts = (values, _formik) => {
    const costingRequest = transformNewRequest(values);
    api
      .put(`/costing_requests/${values.id}/contact`, { costing_request: costingRequest })
      .then(_res => {
        setAlert({
          message: 'Updated Team',
          color: 'success',
        });
      });
  };

  const handleArchive = (values, formik) => {
    const costingRequest = transformNewRequest(values);
    api
      .put(`/costing_requests/${values.id}`, { costing_request: costingRequest })
      .then(res => {
        formik.setErrors({});
        const isSuccess = res.data.state === COSTING_REQUEST_STATES.Archived;
        const reason =
          costingRequest.line_items.length === 0
            ? ` You cannot archive a Costing Request with no ${lineItemName}s.`
            : '';
        const message = isSuccess
          ? `Costing Request CR#${res.data.id} has been archived.`
          : `Failed to archive CR#${res.data.id}.${reason}`;
        const color = isSuccess ? 'success' : 'danger';
        setAlert({ message, color });
        if (isSuccess) {
          history.push('/admin/costing_requests');
        }
      })
      .catch(err => handleError(err, values, formik))
      .finally(() => formik.setSubmitting(false));
  };
  const handleSubmit = (values, formik) => {
    const costingRequest = transformNewRequest(values);

    api
      .put(`/costing_requests/${values.id}`, { costing_request: costingRequest })
      .then(res => {
        formik.setErrors({});
        setInitialValues(transformCostingRequestFromLoad(res.data));
        setAlert({
          message: isSubmitted(res.data)
            ? `Submitted CR #${res.data.id}`
            : `Successfully Saved CR #${res.data.id} as a draft`,
          color: 'success',
        });
      })
      .catch(err => handleError(err, values, formik))
      .finally(() => formik.setSubmitting(false));
  };

  const handleDuplicateSubmit = (values, formik) => {
    setSubmittingDuplicateLineItems(true);
    const lineItems = map(values.line_items, li => {
      return transformCostingRequestLineItem(li, {
        state: COSTING_REQUEST_LI_STATES.Requested,
        rejected_codes: [],
      });
    });

    api
      .post(`/costing_requests/${costingRequestId}/duplicate_costing_request_line_items`, {
        line_items: lineItems,
      })
      .then(() => {
        handleCloseDuplicateLineItemsForm();
        setAlert({
          message: `Successfully requested new ${lineItemName}s for CR #${costingRequestId}`,
        });
        getCostingRequest();
      })
      .catch(err => handleError(err, values, formik))
      .finally(() => setSubmittingDuplicateLineItems(false));
  };

  const handleCloseDuplicateLineItemsForm = () => {
    setInitialDuplicateLineItems([]);
  };

  if (isLoading) return null;
  if (hasError) return <GlobalErrorBoundary />;
  if (!initialValues) return <NotFound />;

  const dealId = initialValues.deal
    ? initialValues.deal.hashid
    : initialValues.public_id ?? costingRequestId;

  const costingFormTitle = initialValues.customer_portal
    ? `Portal Request ${dealId}`
    : `Costing Request #CR-${costingRequestId}`;

  return (
    <>
      <Helmet title="Edit Costing Request" />
      <Breadcrumb to="/admin/costing_requests">Costing</Breadcrumb>
      <Breadcrumb to={`/admin/costing_requests/${costingRequestId}/edit`}>
        Edit #{dealId}
      </Breadcrumb>
      <Modal
        fullscreen={true}
        toggle={handleCloseDuplicateLineItemsForm}
        isOpen={!isEmpty(initialDuplicateLineItems)}
      >
        <DuplicateLineItemsForm
          loading={submittingDuplicateLineItems}
          onCloseForm={handleCloseDuplicateLineItemsForm}
          onSubmit={handleDuplicateSubmit}
          initialLineItems={initialDuplicateLineItems}
          costingRequest={{ ...initialValues, state: 'Draft' }}
        />
      </Modal>
      <CostingForm
        onDuplicateLineItems={setInitialDuplicateLineItems}
        costingRequest={initialValues}
        onSubmit={handleSubmit}
        onArchive={handleArchive}
        onSubmitTeam={handleSubmitTeamContacts}
        title={costingFormTitle}
      />
    </>
  );
};

CostingEdit.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export default CostingEdit;
