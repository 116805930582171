import React, {
  Component,
  PropsWithChildren,
  createRef,
  useEffect,
  useRef,
  useState,
} from 'react';

// #region [Common globals & types]

// the tooltip has a padding of 5px to make room for the pointer...this represents that size for position calcs
// keep this value in sync with the padding in .react_hint in _tooltip.css
const PADDING_OFFSET = 5;

type TooltipEvents = boolean | { click: boolean; hover: boolean; focus: boolean };
type TooltipPosition = 'top' | 'left' | 'right' | 'bottom';
type TooltipAlignment = 'near' | 'center' | 'far';

// #endregion

// #region [Tooltip component]

interface TooltipProps {
  /** Allows setting a custom tooltip attribute instead of the default one. Default 'data-rh'. */
  attribute: string;

  /** Autopositions tooltips based on closeness to window borders. Default false. */
  autoPosition: boolean;

  /** You can override the tooltip style by passing the className property. Default 'react-hint'. */
  className: string;

  /** The default delay (in milliseconds) before showing/hiding the tooltip. Default 0. */
  delay: number | { hide: number; show: number };

  /** Enables/disables all events or a subset of events. Default false. */
  events: TooltipEvents;

  /** Hide the tooltip only on outside click, hover, etc. Default false. */
  persist: boolean;

  /** Allows setting the default tooltip placement. Default 'top'. */
  position: TooltipPosition;

  /** Allows rendering of custom HTML content (with attached event handlers). Pass a function which returns a react node. */
  onRenderContent: (target: HTMLElement, content: string) => JSX.Element;
}

interface TooltipState {
  /** The hint positioning used for render */
  at?: TooltipPosition;

  /** The hint relative "nearness" used for render, near being towards the top-left corner */
  atAlign?: TooltipAlignment;
  content?: string;
  hidden: boolean;
}

/**
 * react-hint tooltip library - https://github.com/saleae/react-hint/blob/fix-infinite-loop/src/index.js
 * vendored because the project is dead and newer versions of React caused
 * an infinite setState bug which needs this patch
 *
 * Users can override the {@link position} by setting the target element's attribute `{attribute}-at`, e.g `data-rh-at="top"`.
 *
 * Users can set the relative alignment of the tooltip by setting the target element's attribute `{attribute}-atAlign`, e.g `data-rh-atAlign="near"`.
 * Accepted values are `near`, `center`, and `far`. Nearness is defined relative to the top-left corner. For example, position `left` and alignment `far` means the tooltip
 * is positioned to the left and aligned to the bottom.
 */
class Tooltip extends Component<TooltipProps, TooltipState> {
  static defaultProps: TooltipProps = {
    attribute: 'data-rh',
    autoPosition: false,
    className: 'react-hint',
    delay: 0,
    events: false,
    onRenderContent: null,
    persist: false,
    position: 'top',
  };

  _hintEl: HTMLElement = null;
  _container = createRef<HTMLDivElement>();

  state: TooltipState = { hidden: true };
  target: HTMLElement = null;
  _containerStyle: React.CSSProperties = { position: 'relative' };
  _timeout: ReturnType<typeof setTimeout> = null;

  constructor(props: TooltipProps) {
    super(props);
  }

  componentDidMount() {
    this.toggleEvents(this.props, true);
  }

  componentWillUnmount() {
    this.toggleEvents(this.props, false);
    clearTimeout(this._timeout);
  }

  toggleEvents = ({ events }: { events: boolean | TooltipEvents }, flag: boolean) => {
    const action = flag ? 'addEventListener' : 'removeEventListener';
    const hasEvents = events === true;
    let click, hover, focus;
    if (typeof events !== 'boolean') {
      ({ click, hover, focus } = events);
    }

    (click || hasEvents) && document[action]('click', this.toggleHint);
    (focus || hasEvents) && document[action]('focusin', this.toggleHint);
    (hover || hasEvents) && document[action]('mouseover', this.toggleHint);
    (click || hover || hasEvents) && document[action]('touchend', this.toggleHint);
  };

  toggleHint = ({ target = null } = {}) => {
    target = this.getHint(target);
    clearTimeout(this._timeout);
    this._timeout = setTimeout(
      () => {
        this.target = target;
        this.getHintData();
      },
      target === null
        ? typeof this.props.delay === 'number'
          ? this.props.delay
          : this.props.delay.hide
        : typeof this.props.delay === 'number'
        ? this.props.delay
        : this.props.delay.show
    );
  };

  getHint = (el: Node) => {
    const { attribute, persist } = this.props;
    const target = this.target;
    let hintEl: HTMLElement = null;

    while (el && !hintEl) {
      if (el === document) break;
      if (persist && el === this._hintEl) hintEl = target;
      if (el instanceof HTMLElement && el.hasAttribute(attribute)) hintEl = el;
      el = el.parentNode;
    }
    return hintEl;
  };

  shallowEqual = (a: any, b: any) => {
    const keys = Object.keys(a);
    return (
      keys.length === Object.keys(b).length &&
      keys.reduce(
        (result, key) =>
          result &&
          ((typeof a[key] === 'function' && typeof b[key] === 'function') || a[key] === b[key]),
        true
      )
    );
  };

  componentDidUpdate() {
    if (this.target && !this.state.hidden) {
      this.getHintData();
    }
  }

  shouldComponentUpdate(props: TooltipProps, state: TooltipState) {
    return !this.shallowEqual(state, this.state) || !this.shallowEqual(props, this.props);
  }

  getHintData = () => {
    if (!this.target) {
      this.setState({ hidden: true });
      return;
    }
    const { attribute, position } = this.props;
    const content = this.target.getAttribute(attribute) || '';
    const atRaw = this.target.getAttribute(`${attribute}-at`) || position;
    const at: TooltipPosition = parseTooltipPosition(atRaw);
    const atAlignRaw = this.target.getAttribute(`${attribute}-atAlign`);
    const atAlign = parseTooltipAlignment(atAlignRaw) || 'center';

    const newState = {
      content,
      at,
      atAlign,
      hidden: false,
    };
    this.setState(newState);
  };

  render() {
    const { className, onRenderContent } = this.props;
    const { content, at, atAlign } = this.state;

    return (
      <TooltipControlled
        className={`${className} print:hidden`}
        at={at}
        atAlign={atAlign}
        target={this.target}
        onTooltipReady={el => (this._hintEl = el)}
      >
        {onRenderContent ? (
          onRenderContent(this.target, content)
        ) : (
          <TooltipContent className={className}>{content}</TooltipContent>
        )}
      </TooltipControlled>
    );
  }
}

export default Tooltip;

// #endregion

// #region [TooltipContent component]

/**
 * Convenience component to wrap tooltip content with the default tooltip styling.
 *
 * Example usage:
 * ```
 * <TooltipControlled target={myTarget}>
 *  <TooltipContent><b>custom</b> tooltip</TooltipContent>
 * </TooltipControlled>
 * ```
 * */
export const TooltipContent = ({
  className = 'react-hint print:hidden',
  children,
}: PropsWithChildren<{ className?: string }>) => (
  <div className={`${className}__content`}>{children}</div>
);

// #endregion

// #region [TooltipControlled component]

type TooltipControlledProps = {
  /** Whether to change the {@link TooltipPosition} of the tooltip to better fit the screen. Defaults to false. */
  autoPosition?: boolean;

  /** Defaults to "react-hint". */
  className?: string;

  /** Default position of the tooltip relative to the target. Defaults to "top". */
  at?: TooltipPosition;

  /** Nearness of the tooltip relativd to the target, with near being towards the top-left corner. */
  atAlign?: TooltipAlignment;

  /** Target element to place the tooltip next to. Tooltip is hidden when target is `null`. */
  target?: HTMLElement;

  /** Callback triggered when the tooltip element is loaded in the DOM. */
  onTooltipReady?: (el: HTMLElement) => void;
};

/**
 * Places a tooltip around the given {@link target} with the given {@link TooltipControlledProps} configuration.
 */
export const TooltipControlled = ({
  autoPosition = false,
  className = 'react-hint print:hidden',
  children,
  at = 'top',
  atAlign = 'center',
  target,
  onTooltipReady,
}: PropsWithChildren<TooltipControlledProps>) => {
  const containerRef = useRef<HTMLDivElement>();
  const hintRef = useRef<HTMLDivElement>();

  const [placement, setPlacement] = useState<TooltipPosition>(null);
  const [positionValues, setPositionValues] = useState<{ top: number; left: number }>(null);

  // anytime our target changes, reset the placement
  useEffect(() => {
    if (target) {
      setPlacement(null);
    }
  }, [target, setPlacement]);

  // when the hint ref / DOM element is rendered, let subscribers know
  useEffect(() => {
    if (hintRef.current) {
      onTooltipReady && onTooltipReady(hintRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hintRef.current]);

  const { top: containerTop, left: containerLeft } =
    containerRef.current?.getBoundingClientRect() || { top: 0, left: 0 };
  const { width: hintWidth, height: hintHeight } = hintRef.current?.getBoundingClientRect() || {
    width: 0,
    height: 0,
  };
  const {
    top: targetTop,
    left: targetLeft,
    width: targetWidth,
    height: targetHeight,
  } = target?.getBoundingClientRect() || { top: 0, left: 0 };

  // when the hint dimensions change because of the content, re-position the placement of our hint component
  useEffect(() => {
    if (target) {
      // use the placement if it's been set already (prevent constantly rotating the tooltip until the placement is cleared)
      let atCalculated = placement || at;

      // autoposition the tooltip to determine final placement if unset
      if (autoPosition && !placement) {
        const isHoriz = ['left', 'right'].includes(atCalculated);

        const { clientHeight, clientWidth } = document.documentElement;

        const directions: Record<string, boolean> = {
          left:
            (isHoriz ? targetLeft - hintWidth : targetLeft + ((targetWidth - hintWidth) >> 1)) >
            0,
          right:
            (isHoriz
              ? targetLeft + targetWidth + hintWidth
              : targetLeft + ((targetWidth + hintWidth) >> 1)) < clientWidth,
          bottom:
            (isHoriz
              ? targetTop + ((targetHeight + hintHeight) >> 1)
              : targetTop + targetHeight + hintHeight) < clientHeight,
          top: (isHoriz ? targetTop - (hintHeight >> 1) : targetTop - hintHeight) > 0,
        };
        if (!atCalculated || !directions[atCalculated]) {
          switch (atCalculated) {
            case 'left':
              if (!directions.left) atCalculated = 'right';
              if (!directions.top) atCalculated = 'bottom';
              if (!directions.bottom) atCalculated = 'top';
              break;

            case 'right':
              if (!directions.right) atCalculated = 'left';
              if (!directions.top) atCalculated = 'bottom';
              if (!directions.bottom) atCalculated = 'top';
              break;

            case 'bottom':
              if (!directions.bottom) atCalculated = 'top';
              if (!directions.left) atCalculated = 'right';
              if (!directions.right) atCalculated = 'left';
              break;

            case 'top':
            default:
              if (!directions.top) atCalculated = 'bottom';
              if (!directions.left) atCalculated = 'right';
              if (!directions.right) atCalculated = 'left';
              break;
          }
        }
      }

      // determine the position of the tooltip based on the placement
      let topOffset, leftOffset;
      switch (atCalculated) {
        case 'left':
          topOffset = calcDistanceOffset(targetHeight - hintHeight, atAlign);
          leftOffset = -hintWidth;
          break;

        case 'right':
          topOffset = calcDistanceOffset(targetHeight - hintHeight, atAlign);
          leftOffset = targetWidth;
          break;

        case 'bottom':
          topOffset = targetHeight;
          leftOffset = calcDistanceOffset(targetWidth - hintWidth, atAlign);
          break;

        case 'top':
        default:
          topOffset = -hintHeight;
          leftOffset = calcDistanceOffset(targetWidth - hintWidth, atAlign);
      }

      // if the hint has content rendered (non-zero dimensions), save the placement
      if (hintHeight > 0 || hintWidth > 0) {
        setPlacement(atCalculated);
      }

      setPositionValues({
        top: (topOffset + targetTop - containerTop) | 0,
        left: (leftOffset + targetLeft - containerLeft) | 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hintWidth,
    hintHeight,
    targetTop,
    targetLeft,
    targetWidth,
    targetHeight,
    containerTop,
    containerLeft,
  ]);

  const top = positionValues ? positionValues.top : targetTop;
  const left = positionValues ? positionValues.left : targetLeft;
  const currentlyAt = placement || at;

  return (
    <div ref={containerRef} className="relative">
      <div
        className={`${className} ${className}--${currentlyAt} ${className}--${atAlign}`}
        ref={hintRef}
        role="tooltip"
        style={{ top, left, display: target ? undefined : 'none' }}
      >
        {target && children}
      </div>
    </div>
  );
};

// #endregion

// #region [Helper functions]

/** returns null if not parsed */
function parseTooltipPosition(str: string): TooltipPosition {
  switch (str) {
    case 'top':
    case 'left':
    case 'bottom':
    case 'right':
      return str;
    default:
      return null;
  }
}

/** returns null if not parsed */
function parseTooltipAlignment(str: string): TooltipAlignment {
  switch (str) {
    case 'near':
    case 'center':
    case 'far':
      return str;
    default:
      return null;
  }
}

/** offsets the distance based on the tooltip alignment */
function calcDistanceOffset(dist: number, atAlign: TooltipAlignment) {
  let offsetResult: number;
  switch (atAlign) {
    case 'near':
      offsetResult = -1 * PADDING_OFFSET;
      break;

    case 'far':
      offsetResult = dist + PADDING_OFFSET;
      break;

    case 'center':
    default:
      offsetResult = dist >> 1;
      break;
  }
  return offsetResult;
}

// #endregion
