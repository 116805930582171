import { omit, pick, pickBy } from 'lodash';
import React, { useContext } from 'react';

import { api } from 'fr-shared/api';
import {
  Breadcrumb,
  Button,
  Helmet,
  Icon,
  Sidebar,
  SidebarPage,
  SidebarPageContainer,
} from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { useTableFilters } from 'fr-shared/hooks';
import { keyboardDown } from 'fr-shared/utils';

import QuotesSidebar from './components/QuotesSidebar';
import QuotesTable from './components/QuotesTable';

const INITIAL_FILTERS = { page: 1, sort_key: 'inserted_at', sort_desc: true };
const FILTER_NAME = 'quotes';

const getQuoteRequests = filters => {
  return api.get('/quotes', { params: pickBy(filters) });
};

const Quotes = () => {
  const { user } = useContext(UserContext);
  const {
    data: quoteRequests,
    filters,
    handleSearch,
    onFetchData,
    pages,
    resetFilters,
    setFilters,
    loading,
  } = useTableFilters(FILTER_NAME, INITIAL_FILTERS, getQuoteRequests);

  const sanitizedFilters = pick(filters, [
    'sales_contact_id',
    'manufacturing_process_id',
    'state',
  ]);

  const filterLabels = {
    sales_contact_id: 'Sales Contact ID',
    manufacturing_process_id: 'MFG Process ID',
    state: 'State',
  };

  const visibleFilters = pickBy(sanitizedFilters);

  const handleRemoveFilter = filter => {
    setFilters(omit(filters, filter));
  };

  return (
    <>
      <Helmet title="Quotes" />
      <Breadcrumb to="/admin/quotes">Quotes</Breadcrumb>

      <SidebarPageContainer>
        <SidebarPage>
          <div className="page-header mb-0 px-3">
            <div className="flex">
              <h2>List of Quotes</h2>
              <div className="page-actions mb-0">
                <div className="flex justify-content-end align-items-center">
                  <div className="relative mr-2">
                    <input
                      type="text"
                      name="opportunity_id"
                      className="form-control"
                      placeholder="Search by Opportunity ID"
                      value={filters.opportunity_id || ''}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="relative mr-2">
                    <input
                      type="text"
                      name="public_id"
                      className="form-control"
                      placeholder="Search by Public ID"
                      value={filters.public_id || ''}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="relative mr-2">
                    <input
                      type="text"
                      name="customer_name"
                      className="form-control"
                      placeholder="Search by Customer"
                      value={filters.customer_name || ''}
                      onChange={handleSearch}
                    />
                  </div>
                  {user.canCreateQuotes && (
                    <Button to="/admin/quotes/new" color="primary">
                      New Quote
                    </Button>
                  )}
                </div>
                {Object.keys(visibleFilters).length > 0 && (
                  <div className="flex justify-content-end">
                    <h5>
                      <Icon name="filter" className="text-primary" right />
                      Applied Filters
                    </h5>
                  </div>
                )}
                <div className="flex flex-wrap justify-content-end">
                  {Object.keys(visibleFilters).map((filter, index) => (
                    <div
                      key={index}
                      className="filter-label delete ml-2"
                      onClick={() => handleRemoveFilter(filter)}
                      onKeyDown={evt =>
                        keyboardDown(evt, 'Enter', () => handleRemoveFilter(filter))
                      }
                      role="button"
                      tabIndex={0}
                    >
                      <div>
                        <strong>{filterLabels[filter]}</strong>: {filters[filter]}
                      </div>
                      <i />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="p-3">
            <QuotesTable
              data={quoteRequests || []}
              pages={pages}
              onFetchData={onFetchData}
              loading={loading}
            />
          </div>
        </SidebarPage>

        <Sidebar>
          <QuotesSidebar filters={filters} setFilters={setFilters} resetFilters={resetFilters} />
        </Sidebar>
      </SidebarPageContainer>
    </>
  );
};

export default Quotes;
