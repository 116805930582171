/**
 * Massage braintree validation errors to formik format
 * @param {array} braintreeErrors - Array of braintree hosted fields that have errors
 */
export const transformBraintreeErrorsToFormikErrors = braintreeErrors => {
  return braintreeErrors.reduce((errorMap, currentError) => {
    errorMap[currentError] = 'This field is invalid';
    return errorMap;
  }, {});
};

/**
 * Get and format the expiration month and year from the braintree details object
 * @param {object} details - Object returned back from braintree when a card is tokenized
 */
export const getExpirationDateFromDetails = details => {
  if (!details) return;
  const { expirationMonth, expirationYear } = details;

  return `${expirationMonth}/${expirationYear.substring(
    expirationYear.length - 2,
    expirationYear.length
  )}`;
};

/**
 * Get and format the last four digits of a card from the braintree details object
 * @param {object} details - Object returned back from braintree when a card is tokenized
 */
export const getFormattedLastFourFromDetails = details =>
  details && `************${details.lastFour}`;
