import { useTreatments } from '@splitsoftware/splitio-react';

import featureFlagAttributes, { Attributes } from 'fr-shared/featureFlagAttributes';

type UseFeatureFlagsInput = { [key: string]: string };
type FeatureFlags = { [key: string]: boolean };

export default function useFeatureFlags(
  flags: UseFeatureFlagsInput,
  attributes: Attributes = {}
): FeatureFlags {
  const treatments = useTreatments(Object.keys(flags), featureFlagAttributes(attributes));
  return Object.entries(treatments).reduce(
    (acc, [name, { treatment }]) => ({ ...acc, [name]: treatment === flags[name] }),
    {}
  );
}
