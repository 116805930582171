import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { pluralize } from 'fr-shared/utils';

import { Link, PartImage } from 'portal/components';
import { date } from 'portal/lib/date';

import styles from '../../../customer_portal/pages/part_upload/components/RecentUploads.module.css';

const RecentUploads = ({ partIds = [], organizationId }) => {
  const [parts, setParts] = useState([]);
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    api
      .get(`/organizations/${organizationId}/parts`, {
        params: { page_size: 5, sort: 'Upload Time (Latest to Earliest)' },
      })
      .then(res => {
        setParts(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partIds]);

  if (parts.length === 0 || loading) return null;

  return (
    <div className="my-5">
      <div className="flex align-items-center pt-2 pb-5">
        <div className="flex align-items-center">
          <h3>Recent parts</h3>
          {parts.length > 0 && (
            <Link className="ml-2 underline" to={`admin/customers/${organizationId}/parts`}>
              View all
            </Link>
          )}
        </div>
        <div className="ml-auto text-lightgrey font-size-md">
          {parts.length} {pluralize('part', parts.length !== 1)}
        </div>
      </div>
      {parts.map(part => (
        <div className="flex align-items-center py-2 pl-3 pr-2 mb-1" key={part.id}>
          <div>
            <PartImage
              src={part?.current_revision?.screenshot}
              size="xs"
              backdrop={false}
              className="mr-3"
            />
          </div>
          <div className={styles.PartDetails}>
            <div className={`${styles.PartName} font-size-lg text-dark`}>{part.name}</div>
            <div className="font-size-md text-gray">{date(part.inserted_at)}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

RecentUploads.propTypes = {
  partIds: PropTypes.arrayOf(PropTypes.number),
  organizationId: PropTypes.string,
};

export default RecentUploads;
