import { isNil } from 'lodash';
import React from 'react';

interface Props {
  label?: React.ReactNode;
  value: React.ReactNode;
  valueClassName?: string;
}

const OrderFormDisplayField = ({ label, value, valueClassName = '' }: Props) => (
  <div className="mr-4 mb-2">
    {label && (
      <>
        <span className="text-muted mr-1">{label}</span>{' '}
      </>
    )}
    <strong className={valueClassName}>{isNil(value) ? '----' : value}</strong>
  </div>
);

export default OrderFormDisplayField;
