import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Icon } from 'fr-shared/components';
import { QUOTE_STATES } from 'fr-shared/lib/quotes';

import GenerateQuotePdfButton from './GenerateQuotePdfButton';
import PreviewCustomerQuoteModal from './PreviewCustomerQuoteModal/PreviewCustomerQuoteModal';

const QuoteFormButtons = ({
  isJustifyBottom,
  isLoading,
  onConvertToOrderClicked,
  onSyncButtonClicked,
  onSaveDraft,
}) => {
  const formik = useFormikContext();
  const history = useHistory();
  const isEditing = (formik.values.id ?? null) !== null;
  const hasLineItems = !!formik.values.line_items.length;
  const isDraft = formik.values.state === QUOTE_STATES.Draft;
  const hasVersionConvertedToOrder = formik.values.has_completed_version;

  const SUBMIT_AND_SYNC_WITH_SALESFORCE =
    'Submit this quote to sync it with Salesforce and the customer portal. Then you can email it to your customer.';
  const DISABLED_CONVERT_TO_ORDER_TEXT =
    "A different quote associated with the same opportunity has been converted to an order. An opportunity can only be associated with one order, so you'll need to create a new costing request.";
  return (
    <div className="justify-content-end">
      <div className="flex justify-content-end text-right my-2">
        {!isJustifyBottom && (
          <Button color="secondary" className="ml-2" onClick={e => history.goBack(e)}>
            Back
          </Button>
        )}
        {formik.values.state === QUOTE_STATES.Submitted &&
          isEditing &&
          !hasVersionConvertedToOrder && (
            <Button
              loading={isLoading}
              className="ml-2"
              color="primary"
              to={`/admin/quotes/duplicate/${formik.values.id}`}
            >
              Duplicate and Edit
            </Button>
          )}
        {formik.values.state === QUOTE_STATES.Submitted && !hasVersionConvertedToOrder && (
          <Button
            loading={isLoading}
            className="ml-2"
            color="success"
            onClick={onConvertToOrderClicked}
          >
            Convert to Order
          </Button>
        )}
        {formik.values.state === QUOTE_STATES.Submitted && hasVersionConvertedToOrder && (
          <Button
            loading={isLoading}
            className="ml-2"
            color="success"
            tooltip={DISABLED_CONVERT_TO_ORDER_TEXT}
            disabled
          >
            Convert to Order
          </Button>
        )}
        {(!isEditing || isDraft) && (
          <>
            <Button
              loading={isLoading}
              type="submit"
              color="primary"
              className="ml-2"
              onClick={onSaveDraft}
              disabled={!hasLineItems}
              showLoadingIcon={true}
              dataTestId="quote-save-as-draft--button"
            >
              {isEditing ? 'Save Draft' : 'Save as Draft'}
            </Button>
            <div className="flex flex-row align-items-center">
              <Button
                loading={isLoading}
                color="success"
                className="min-w-[100px] ml-2"
                onClick={onSyncButtonClicked}
                disabled={!hasLineItems}
                showLoadingIcon={true}
              >
                Submit
              </Button>
              <Icon
                className="ml-1"
                name="info-circle"
                type="far"
                tooltip={SUBMIT_AND_SYNC_WITH_SALESFORCE}
              />
            </div>
          </>
        )}
      </div>
      {!isJustifyBottom && isEditing && (
        <div className="text-right my-2">
          <PreviewCustomerQuoteModal quoteId={formik.values.id} />
          <GenerateQuotePdfButton preview />
          <GenerateQuotePdfButton />
        </div>
      )}
    </div>
  );
};

QuoteFormButtons.propTypes = {
  isLoading: PropTypes.bool,
  isJustifyBottom: PropTypes.bool,
  onConvertToOrderClicked: PropTypes.func,
  onSyncButtonClicked: PropTypes.func,
  onRequestCreditCardClicked: PropTypes.func,
  onSaveDraft: PropTypes.func,
};

export default QuoteFormButtons;
