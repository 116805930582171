import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { IconFont } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { pluralize } from 'fr-shared/utils';

import { Button, Link, PartImage } from 'portal/components';
import { date } from 'portal/lib/date';

import styles from './RecentUploads.module.css';

const RecentUploads = ({ onPartCreated }) => {
  const { user } = useContext(UserContext);
  const [parts, setParts] = useState([]);
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    api
      .get('/customer_portal/parts', {
        params: { page_size: 5, source: 'Customer Portal Quote', filter_user_id: user.id },
      })
      .then(res => {
        setParts(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (parts.length === 0 || loading) return null;

  return (
    <div data-testid="recent-uploads--container" className="my-5">
      <div className="flex align-items-center pt-2 pb-5">
        <div className="flex align-items-center">
          <h3>Recent parts</h3>
          {parts.length > 0 && (
            <Link className="ml-2 underline" to="/parts">
              View all
            </Link>
          )}
        </div>
        <div
          data-testid="recent-uploads--parts-amount"
          className="ml-auto text-lightgrey font-size-md"
        >
          {parts.length} {pluralize('part', parts.length !== 1)}
        </div>
      </div>
      {parts.map(part => (
        <div
          className="flex align-items-center bg-gray-600 py-2 pl-3 pr-2 mb-1 rounded-md"
          key={part.id}
        >
          <div>
            <PartImage
              src={part?.current_revision?.screenshot}
              size="xs"
              backdrop={false}
              className="mr-3"
            />
          </div>
          <div className={styles.PartDetails}>
            <div
              data-testid="recent-uploads--part-name"
              className={`${styles.PartName} font-size-lg text-light`}
            >
              {part.name}
            </div>
            <div className="font-size-md text-gray">{date(part.inserted_at)}</div>
          </div>
          <div className="ml-auto">
            <Button
              dataTestId="recent-uploads--add-to-quote-button"
              outline
              size="sm"
              onClick={() => onPartCreated(part)}
            >
              <IconFont name="plus" />
              Add to quote
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

RecentUploads.propTypes = {
  onPartCreated: PropTypes.func,
};

export default RecentUploads;
