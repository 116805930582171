export function getQuoteSource(isCustomerPortal: boolean): string {
  return isCustomerPortal ? 'Customer' : 'Internal';
}

export interface Quote {
  closed_at: string;
  completed_at: string;
  customer: Customer;
  customer_portal: boolean;
  date_submitted: string;
  id: number;
  is_cart: boolean;
  is_costing_request: boolean;
  costing_request_id?: number;
  is_manual?: boolean;
  line_items?: Array<QuoteLineItem>;
  public_id: string;
  state: string;
  submitted_at?: string;
  total_price: Money;
  user_id?: number;
}
export interface QuoteLineItem {
  id: number;
}
interface Customer {
  name: string;
}

export const QUOTE_STATES = {
  Draft: 'Draft',
  Requested: 'Requested',
  Submitted: 'Submitted',
  Closed: 'Closed',
  Converted: 'Converted to Order',
};

export const TAB_STATES = {
  Carts: 'Portal quotes',
  Quotes: 'FROS quotes',
};

export const SOURCE_FILTER_OPTIONS = {
  Customer: 'Customer',
  All: 'All',
};

export const OWNER_FILTER_OPTIONS = {
  You: 'You',
  Anyone: 'Anyone',
};

export const SORT_OPTIONS = [
  {
    value: 'newest_to_oldest',
    label: 'Newest — oldest',
  },
  {
    value: 'oldest_to_newest',
    label: 'Oldest — newest',
  },
  {
    value: 'last_updated',
    label: 'Last Updated',
  },
];

export const DEFAULT_SORT = SORT_OPTIONS.find(
  sortOption => sortOption.value === 'newest_to_oldest'
);
