import React from 'react';

import { IconFont } from 'fr-shared/components';

export const LinksAndSummary = () => {
  return (
    <>
      <p className="text-coolGray-300 text-xs">
        International Traffic in Arms Regulations (ITAR) and Export Administration Regulations
        (EAR) are US export control laws. They apply to a restricted list of parts, products and
        technology related to military use. Find more info here:
      </p>

      <div>
        <a
          className="text-coolGray-100 text-xs no-underline"
          href="https://www.pmddtc.state.gov/ddtc_public?id=ddtc_public_portal_itar_landing"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="underline">ITAR regulations</span>
          <IconFont name="external-link" className="ml-1 text-sm" />
        </a>

        <a
          className="text-coolGray-100 text-xs no-underline ml-3"
          href="https://www.bis.doc.gov/index.php/regulations/export-administration-regulations-ear"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="underline">EAR regulations</span>
          <IconFont name="external-link" className="ml-1 text-sm" />
        </a>
      </div>
    </>
  );
};

export default LinksAndSummary;
