import { IconFont } from '@fast-radius/shared-ui';
import React, { SyntheticEvent } from 'react';

import { Button, classNames } from 'portal/components';
import {
  filterChecksByProcess,
  filterFailedErrorChecks,
  filterFailedWarningChecks,
  filterPassedChecks,
  prioritizeChecksBySeverity,
} from 'portal/lib/manufacturability_check';

import DfmCheck from '../../../dfm/DfmCheck/DfmCheck';

type Props = {
  colId: string;
  dfmChecks: ManufacturabilityCheck[];
  disabled: boolean;
  processId: number;
  processName: string;
  selectedDfmCheck: { colId?: string; checkId?: number };
  handleSelected: (dfmCheck: ManufacturabilityCheck) => void;
  pushToEvaluate: () => void;
};

const DfmChecks = ({
  colId,
  dfmChecks,
  processId,
  processName,
  selectedDfmCheck,
  handleSelected,
  pushToEvaluate,
}: Props) => {
  const checksForProcess = filterChecksByProcess(dfmChecks, processId);
  const prioritizedChecks = prioritizeChecksBySeverity(checksForProcess);
  const failedErrorChecks = filterFailedErrorChecks(prioritizedChecks);
  const failedWarningChecks = filterFailedWarningChecks(prioritizedChecks);
  const passedChecks = filterPassedChecks(prioritizedChecks);

  return (
    <div>
      {checksForProcess?.length > 0 ? (
        <>
          <div className="compare-component-header dfm-header print:mb-1">
            <h1 className="hidden print:block print:text-xl print:pb-1 print:leading-5 text-black">
              DFM checks
            </h1>
          </div>
          <div className="pb-2 mb-2 border-0 border-b border-coolGray-600 border-solid print:border-none print:mr-6 print:flex print:flex-row print:flex-wrap print:min-h-[90px] print:pb-0 print:mb-0 print:content-start">
            {failedErrorChecks.map((errorCheck: ManufacturabilityCheck) => {
              const selected =
                selectedDfmCheck.colId === colId && selectedDfmCheck.checkId === errorCheck.id;
              return (
                <DfmCheck
                  key={errorCheck.id}
                  className={classNames([
                    'mb-1 hover:bg-coolGray-600 print:w-1/2 print:mb-0',
                    !selected && 'bg-coolGray-800',
                  ])}
                  disableEventsWhenSelected={false}
                  dfmCheck={errorCheck}
                  open
                  selected={selected}
                  onSelectDfmCheck={(event: SyntheticEvent<HTMLElement>) => {
                    event.stopPropagation();
                    handleSelected(errorCheck);
                  }}
                />
              );
            })}
            {failedWarningChecks.map((warningCheck: ManufacturabilityCheck) => {
              const selected =
                selectedDfmCheck.colId === colId && selectedDfmCheck.checkId === warningCheck.id;
              return (
                <DfmCheck
                  key={warningCheck.id}
                  className={classNames([
                    'mb-1 hover:bg-coolGray-600 print:w-1/2',
                    !selected && 'bg-coolGray-800',
                  ])}
                  disableEventsWhenSelected={false}
                  dfmCheck={warningCheck}
                  open
                  selected={selected}
                  onSelectDfmCheck={(event: SyntheticEvent<HTMLElement>) => {
                    event.stopPropagation();
                    handleSelected(warningCheck);
                  }}
                />
              );
            })}
            {passedChecks.length > 0 && (
              <Button
                className="hover:bg-coolGray-600 no-underline text-sm bg-coolGray-800 w-full p-2 rounded-[8px] flex justify-between print:p-0 print:bg-transparent print:hover:bg-transparent print:w-1/2"
                color="none"
                onClick={pushToEvaluate}
              >
                <div className="flex items-center leading-none">
                  <IconFont name="circle" className="text-success-400 text-xl mr-1" />
                  <div className="text-coolGray-100 print:text-coolGray-500">
                    <span className="print:hidden">See all</span>
                    <span className="hidden print:inline">{passedChecks.length}</span> passed
                    checks
                  </div>
                </div>
                <IconFont
                  name="arrow-right"
                  className="text-coolGray-100 leading-none text-xl mr-0 print:hidden"
                />
              </Button>
            )}
          </div>
          <Button
            className="no-underline p-0 rounded-[8px] flex items-center text-coolGray-100 print:hidden"
            color="none"
            onClick={pushToEvaluate}
          >
            <IconFont name="lightbulb" className="text-xl" />
            <div className="text-sm ">See full breakdown</div>
          </Button>
        </>
      ) : (
        <div className="text-coolGray-500 text-2xl font-medium">
          DFM checks unavailable for {processName}
        </div>
      )}
    </div>
  );
};

export default DfmChecks;
