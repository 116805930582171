import { useState } from 'react';

import { api } from 'fr-shared/api';

const getPackingSlip = ({ ship_to, line_items }) => {
  const lineItems = line_items.map(li => ({ id: li.order_line_item_id, quantity: li.quantity }));

  return api.post('/packing_slip', {
    packing_slip: { ship_to, line_items: lineItems },
  });
};

const usePackingSlip = shipmentValues => {
  const [loadingPackingSlip, setLoadingPackingSlip] = useState(false);
  const [packingSlip, setPackingSlip] = useState(null);

  const handleGetPackingSlip = () => {
    setLoadingPackingSlip(true);
    return getPackingSlip(shipmentValues)
      .then(res => {
        setPackingSlip(res.data);
        window.scrollTo(0, 0);
      })
      .finally(() => {
        setLoadingPackingSlip(false);
      });
  };

  return { handleGetPackingSlip, packingSlip, loadingPackingSlip };
};

export default usePackingSlip;
