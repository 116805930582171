import React from 'react';

import { Page, PageHeader } from 'portal/components';
import { PartFileRevision } from 'portal/lib/cart';
import { CostData } from 'portal/lib/cost_drivers';
import CostCurves from 'portal/pages/insights/components/CostCurves';

interface Props {
  costData: CostData | null;
  onCreateFeasibilityReport: () => void;
  partFileRevision: PartFileRevision | null;
}

const Costs = ({ costData, onCreateFeasibilityReport, partFileRevision }: Props) => {
  return (
    <Page className="overflow-y-auto lg:h-screen">
      <PageHeader
        title="Cost curve"
        subtitle="Find the most cost-effective way to make your parts, based on number of units."
      />
      {costData && (
        <CostCurves
          className="pb-3 pt-6"
          insight={costData}
          showHeader={false}
          onCreateFeasibilityReport={onCreateFeasibilityReport}
          partFileRevision={partFileRevision}
        />
      )}
    </Page>
  );
};

export default Costs;
