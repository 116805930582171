import { unionWith } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';

import { Loading, classNames } from 'fr-shared/components';
import { usePrevious } from 'fr-shared/hooks';

import TablePagination from './TablePagination';

const LoadingComponent = ({ className, loading }) => {
  return (
    <div className={classNames(['-loading', { '-active': loading }, className])}>
      <div className="-loading-inner">
        {loading && <span className="sr-only">Loading new table data.</span>}
        <Loading />
      </div>
    </div>
  );
};

LoadingComponent.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
};

export const Table = ({ data, columns, ...props }) => {
  const [expandedColumns, setExpandedColumns] = useState({});
  const previousData = usePrevious(data);
  useEffect(() => {
    if (unionWith(data, previousData, (d1, d2) => d1.id === d2.id).length !== data.length) {
      setExpandedColumns({});
    }
  }, [data, previousData]);

  const onExpandedChange = newExpandedColumns => {
    setExpandedColumns(newExpandedColumns);
  };

  return (
    <ReactTable
      defaultPageSize={5}
      LoadingComponent={LoadingComponent}
      PaginationComponent={TablePagination}
      expanded={expandedColumns}
      onExpandedChange={onExpandedChange}
      data={data}
      columns={columns}
      {...props}
    />
  );
};

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  expanderCellIndex: PropTypes.number,
};

export default Table;
