import { Formik } from 'formik';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { IconFont } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { keyboardDown } from 'fr-shared/utils';

import { Page, PageHeader } from 'portal/components';
import { requestCallSchema } from 'portal/lib/schema';

import RequestCallForm from './components/RequestCallForm/RequestCallForm';

const formatValues = (
  {
    what_do_you_want_to_build,
    which_manufacturing_process_did_you_have_in_mind,
    do_your_parts_have_any_special_requirements,
    any_other_notes_for_us,
    supportingDocuments,
  },
  isFrom2DFilePath
) => {
  const uploaded_file_s3_filenames = supportingDocuments.map(sd => sd.data?.filename);
  const uploaded_file_s3_paths = supportingDocuments.map(sd => sd.data?.path);

  return {
    what_do_you_want_to_build,
    which_manufacturing_process_did_you_have_in_mind,
    do_your_parts_have_any_special_requirements,
    any_other_notes_for_us,
    quote_request_type: isFrom2DFilePath ? '2D' : 'Call',
    uploaded_file_s3_filenames,
    uploaded_file_s3_paths,
  };
};

const RequestCall = () => {
  const history = useHistory();
  const handleClick = history.goBack;
  const { setAlert } = useContext(AlertContext);

  const handleSubmit = values => {
    const params = formatValues(values, '2d-files');

    return api
      .post('/customer_portal/request_call', params)
      .then(() => {
        history.push('/create-quote/2d-files/confirmation');
      })
      .catch(() => {
        // NOTE: We are using a warning color because the red is so intense and 99%
        // of the time this catch happens is because details are missing in SF due
        // to Marketo issues. This is not the user's fault and gets corrected once
        // one of our SF admins sees an associated Sentry error. That can take a few
        // hours.
        setAlert({
          message:
            "Oh no! We weren't able to submit your call request. There are lot of moving parts here at Fast Radius and sometimes this happens. We're so sorry! Please try again in a few hours and we'll get back to you as soon as we can.",
          color: 'warning',
        });
      });
  };

  return (
    <Page>
      <div
        className="cursor-pointer font-size-sm mb-3 text-gray text-decoration-none"
        onClick={handleClick}
        onKeyDown={evt => keyboardDown(evt, 'Enter', handleClick)}
        role="button"
        tabIndex={0}
      >
        <IconFont name="chevron-left" />
        {'  '}Back
      </div>

      <PageHeader
        subtitle="Just give us a few details about your part or project and we’ll give you a call within 1 business day."
        title="Let’s set up your call"
      />

      <Formik
        initialValues={{
          what_do_you_want_to_build: '',
          supportingDocuments: [],
        }}
        onSubmit={handleSubmit}
        validationSchema={requestCallSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <RequestCallForm />
      </Formik>
    </Page>
  );
};

export default RequestCall;
