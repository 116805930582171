import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { IconFont, LineItemProcess } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';

import { Badge } from 'portal/components';
import {
  filterChecksByProcess,
  filterFailedErrorChecks,
  filterFailedWarningChecks,
  filterPassedChecks,
  prioritizeChecksBySeverity,
} from 'portal/lib/manufacturability_check';
import Panel from 'portal/pages/evaluate/components/Panel/Panel';
import DfmCheckSection from 'portal/pages/evaluate/dfm/DfmCheckSection';
import DfmViewer from 'portal/pages/evaluate/dfm/DfmViewer/DfmViewer';
import DfmStyles from 'portal/pages/evaluate/dfm/DfmViewer/DfmViewer.module.css';

const Viewer = () => {
  const { order_line_item_id: oli_id } =
    useParams<{ order_line_item_id: string; check_id: string }>();

  const { setAlert } = useContext(AlertContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Store result from API call to server
  const [oli, setOli] = useState(null);

  // State of DFM sidebar, can use `?dfm=false` to disable
  const [dfmSidebar, setDfmSidebar] = useState(searchParams.get('dfm') !== 'false');

  // Store the selected DFM check data to pass to viewer
  const [selectedDfmCheck, setSelectedDfmCheck] = useState<ManufacturabilityCheck | null>();

  // Get the initial data for viewer
  useEffect(() => {
    api
      .get(`/order_line_items/${oli_id}`)
      .then(res => {
        if (!res.data.part) {
          setAlert({
            message: `Order line item ID ${oli_id} does not have a Part.`,
            color: 'warning',
            autoClose: false,
          });
        }
        setOli(res.data);
      })
      .catch(error => {
        setAlert({
          message:
            error.response.status === 404
              ? `Could not find an order line item with ID: ${oli_id}`
              : `Could not load the part for order line item ${oli_id}`,
          color: 'danger',
          autoClose: false,
        });
      });
  }, [setAlert, oli_id]);

  if (!oli || !oli.part) return null;

  // This is a hack to get around the server needing a couple extra preloads
  // for part_file_revision.part
  const partFileRevision = {
    ...oli.part?.current_revision,
    part_name: oli.part.name,
    part_units: oli.part.units,
  };

  // Construct data for DFM Checks sidebar
  const dfmChecks = partFileRevision.manufacturability_checks_v2;
  const selectedManufacturingProcess = oli.manufacturing_process;

  if (!dfmChecks) return null;

  const checksForProcess = filterChecksByProcess(dfmChecks, selectedManufacturingProcess?.id);
  const prioritizedChecks = prioritizeChecksBySeverity(checksForProcess);
  const failedErrorChecks = filterFailedErrorChecks(prioritizedChecks);
  const failedWarningChecks = filterFailedWarningChecks(prioritizedChecks);
  const passedChecks = filterPassedChecks(prioritizedChecks);

  const dfmCheckSections = [
    {
      name: 'Critical',
      description:
        "We've found some critical manufacturability issues. View these in more detail to see our suggested fixes. Or contact us for help.",
      color: 'error',
      checks: failedErrorChecks,
    },
    {
      name: 'Non-critical',
      description:
        "We've found non-critical issues you need to be aware of. These could cause problems for your part. View in more detail to see our suggested fixes.",
      color: 'warning',
      checks: failedWarningChecks,
    },
    {
      name: 'Passed',
      color: 'success',
      checks: passedChecks,
    },
  ];

  const handleSelectDfmCheck = (dfmCheck: ManufacturabilityCheck) => {
    if (dfmCheck?.id === selectedDfmCheck?.id) {
      setSelectedDfmCheck(null);
    } else {
      setSelectedDfmCheck(dfmCheck);
    }
  };

  return (
    <>
      <Helmet title={`${oli?.part.name} (#${oli?.part?.id}) OLI: ${oli?.id} Viewer`}>
        <body className="theme-dark overflow-hidden" />
      </Helmet>

      <Panel.Wrapper>
        <div className="order-1 col-lg p-0">
          <DfmViewer
            customControls={<ToggleSidebar onClick={setDfmSidebar} />}
            partFileRevision={partFileRevision}
            selectedDfmCheck={selectedDfmCheck}
            showControls={true}
            showDetails={false}
          />
        </div>

        {dfmSidebar && (
          <Panel>
            <div className="flex flex-column flex-fill pt-3 px-3">
              <div>
                <h5 className="mb-2 font-bold">{oli.part.name}</h5>
                {oli.manufacturing_process && (
                  <div className="mb-1">
                    <span>Manufacturing Details: </span>{' '}
                    <span className="text-white">
                      <LineItemProcess data={oli} separator=", " />
                    </span>
                  </div>
                )}
                {oli.finish && (
                  <div className="mb-1">
                    <span>Finish: </span> <span className="text-white">{oli.finish.name}</span>
                  </div>
                )}
                {oli.finish_note && (
                  <div className="mb-1">
                    <span>Finish Note: </span>{' '}
                    <span className="text-white">{oli.finish_note}</span>
                  </div>
                )}
                {partFileRevision.max_x_length && (
                  <div className="mb-1">
                    <span>Dimensions: </span>{' '}
                    <span className="text-white">
                      {partFileRevision.max_x_length} x {partFileRevision.max_y_length} x{' '}
                      {partFileRevision.max_z_length} {partFileRevision.part_units}
                    </span>
                  </div>
                )}
                {partFileRevision.volume && (
                  <div className="mb-1">
                    <span>Part Volume: </span>{' '}
                    <span className="text-white">
                      {partFileRevision.volume} {partFileRevision.part_units}³
                    </span>
                  </div>
                )}
                {partFileRevision.surface_area && (
                  <div className="mb-1">
                    <span>Surface Area: </span>{' '}
                    <span className="text-white">
                      {partFileRevision.surface_area} {partFileRevision.part_units}²
                    </span>
                  </div>
                )}
                {oli.notes && (
                  <div className="mb-1">
                    <span>Notes: </span> <span className="text-white">{oli.notes}</span>
                  </div>
                )}
              </div>
              <div className="mt-3">
                <h5 className="flex align-items-center mb-3">
                  Automated checks
                  <div className="ml-auto">
                    <Badge pill color="error">
                      {failedErrorChecks.length}
                    </Badge>
                    <Badge pill className="ml-1" color="warning">
                      {failedWarningChecks.length}
                    </Badge>
                    <Badge pill className="ml-1" color="success">
                      {passedChecks.length}
                    </Badge>
                  </div>
                </h5>

                {dfmCheckSections.map(section => (
                  <DfmCheckSection
                    color={section.color}
                    description={section.description}
                    dfmChecks={section.checks}
                    key={section.name}
                    name={section.name}
                    onSelectDfmCheck={handleSelectDfmCheck}
                    selectedDfmCheck={selectedDfmCheck}
                  />
                ))}
              </div>
            </div>
          </Panel>
        )}
      </Panel.Wrapper>
    </>
  );
};

const ToggleSidebar = ({ onClick }: any) => {
  return (
    <>
      <button
        className={DfmStyles.DfmViewerButton}
        onClick={() => {
          onClick((dfmSidebar: boolean) => !dfmSidebar);
        }}
      >
        <IconFont name="list-view" className="text-lg" />
      </button>
    </>
  );
};

export default Viewer;
