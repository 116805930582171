import { debounce, get, isEmpty, keys } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, Helmet, Modal } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { WORK_ORDER_STATE_STATIONS } from 'fr-shared/lib/work_orders';

import WorkOrderStateUpdateForm from './components/WorkOrderStateUpdateForm';

const StateUpdater = ({ match, history }) => {
  const { setAlert } = useContext(AlertContext);
  const state = get(match, ['params', 'state']);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    setModalIsOpen(false);
  }, [state]);

  useEffect(() => {
    resetDebounced();
  }, [resetDebounced]);

  const resetDebounced = debounce(() => {
    location.reload();
  }, 120000);

  const selectState = e => {
    history.push(`/admin/work_orders/state_updater/${e.target.value}`);
  };

  const handleSubmit = async (values, formik) => {
    setModalIsOpen(true);
    setModalText(`Updating Work Order #${values.id}`);

    resetDebounced();

    const res = await api.get(`/work_orders/${values.id}`);
    formik.setValues(res.data);

    api
      .put(`/work_orders/${values.id}`, {
        work_order: values,
      })
      .then(() => {
        setModalText(`${values.state} state assigned to Work Order #${values.id}`);
        setTimeout(() => {
          location.reload();
        }, 2000);
      })
      .catch(err => {
        setModalIsOpen(false);
        if (err.response?.data) {
          const { errors } = err.response.data;
          formik.setErrors({
            ...errors,
          });
        } else {
          setAlert({
            color: 'danger',
            message: 'An unknown error occurred. Please refresh the page and try again',
          });
        }
      });
  };

  return (
    <>
      <Helmet title={`State Updater`} />
      <Breadcrumb to="/admin/work_orders">Work Orders</Breadcrumb>
      <Breadcrumb to="/admin/work_orders/state_updater">State Updater</Breadcrumb>
      {state && (
        <Breadcrumb to={`/admin/work_orders/state_updater/${state}`}>
          {WORK_ORDER_STATE_STATIONS[state]}
        </Breadcrumb>
      )}
      <Modal isOpen={modalIsOpen}>
        {({ toggle }) => (
          <>
            <Modal.Header title="State Update" onClose={toggle} />
            <div className="modal-body">
              <h2>{modalText}</h2>
            </div>
          </>
        )}
      </Modal>
      <div className="page-header mb-2">
        <div className="container">
          <div className="stack">
            <h2>{state ? `${WORK_ORDER_STATE_STATIONS[state]} Scanner` : 'State Scanner'} </h2>
          </div>
        </div>
      </div>
      <div className="container flex flex-row">
        <h3> Select a state to {state ? 'reassign' : 'assign'} it to this scanner </h3>
        <select className="custom-select ml-4 flex-1" value={state} onChange={selectState}>
          <option value="" />
          {keys(WORK_ORDER_STATE_STATIONS).map((stateKey, index) => {
            return (
              <option key={index} value={stateKey}>
                {WORK_ORDER_STATE_STATIONS[stateKey]}
              </option>
            );
          })}
        </select>
      </div>
      <div className={isEmpty(state) ? 'd-none' : ''}>
        <WorkOrderStateUpdateForm
          canScan={!modalIsOpen}
          onSubmit={handleSubmit}
          state={WORK_ORDER_STATE_STATIONS[state]}
        />
      </div>
    </>
  );
};

StateUpdater.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      state: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.object,
};

export default StateUpdater;
