import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Creates an Error Boundary component that catches any uncaught errors thrown
 * during renders of child components, and renders a fallback component
 *
 * React Docs: https://reactjs.org/docs/error-boundaries.html
 * Usage:
 *
 *   const MyErrBoundary = withErrorBoundaryFallback(MyFallbackComponent);
 *
 *   <MyErrBoundary>
 *     <ComponentThatThrows />
 *   </MyErrBoundary>
 */
const withErrorBoundaryFallback = (Fallback, boundaryId = 'Generic') => {
  return class ErrorBoundary extends React.Component {
    static propTypes = {
      children: PropTypes.node.isRequired,
    };

    state = {
      caughtError: null,
    };

    static getDerivedStateFromError(caughtError) {
      return { caughtError };
    }

    componentDidCatch(error, info) {
      if (Sentry) {
        Sentry.setExtra('error', error);
        Sentry.setExtra('componentStack', info.componentStack);
        Sentry.captureMessage(
          `${boundaryId} Error Boundary caught error and displayed fallback UI`
        );
      }
    }

    render() {
      if (this.state.caughtError) {
        return React.createElement(Fallback, { ...this.state });
      }
      return this.props.children;
    }
  };
};

export default withErrorBoundaryFallback;
