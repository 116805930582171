import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

import { ScrollToTop } from 'fr-shared/components';

/* PrivateRoute supports two methods for rendering children because we use different methods within the app.
The component prop + render function is an older method from when we used an earlier version of React Router.
Passing children between <PrivateRoute></PrivateRoute> is the more updated, preferred usage.
This allows us to use hooks in the child component to access route information (eg history) as needed.
See PrivateRoute.test.js for usage examples.
*/

const PrivateRoute = ({ component: Component, scrollToTop, children, ...rest }) => {
  if (Component) {
    return (
      <Route
        render={props => (
          <>
            {scrollToTop && <ScrollToTop />}
            <Component {...props} />
          </>
        )}
        {...rest}
      />
    );
  } else {
    return (
      <Route {...rest}>
        <>
          {scrollToTop && <ScrollToTop />}
          {children}
        </>
      </Route>
    );
  }
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
  scrollToTop: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  scrollToTop: false,
};

export default PrivateRoute;
