import React from 'react';

import { Button, IconFont } from 'fr-shared/components';

const FileRedirectLink = () => {
  return (
    <div className="flex justify-between">
      <div className="ml-auto">
        <span className="mr-2">Don&apos;t have a CAD file?</span>
        <Button outline size="sm" to="/create-quote/2d-files">
          <IconFont name="plus" />
          Add part drawing
        </Button>
      </div>
    </div>
  );
};

export default FileRedirectLink;
