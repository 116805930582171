/**
 * Runs function if event keybtn matches
 * @param  {Object} evt key down event object
 * @param  {String} keyBtn keyboard button name that is pressed
 * @param  {Function} callback function that should fire when button targeted is pressed
 */
export const keyboardDown = (evt, keyBtn, fx) => {
  if (evt.key === keyBtn) {
    if (fx) fx(evt);
  }
};
