import {
  HemisphericLight as BabylonHemisphericLight,
  PointLight as BabylonPointLight,
  SpotLight as BabylonSpotLight,
  Color3,
  Scene,
  Vector3,
} from 'babylonjs';

export type Light = PointLight | SpotLight | HemisphericLight;

/**
 * Emits light in every direction from a point.
 */
export type PointLight = {
  kind: 'PointLight';
  id: string;
  position: Vector3;
  intensity: number;
  specular: Color3;
};

/**
 * Returns a point light.
 */
export const newPointLight = (
  id: string,
  position: Vector3,
  intensity: number,
  specular: Color3
): PointLight => {
  return {
    kind: 'PointLight',
    id: id,
    position: position,
    intensity: intensity,
    specular: specular,
  };
};

/**
 * Asserts that the light is of type `PointLight`.
 */
export const isPointLight = (light: Light): light is PointLight => {
  return light.kind === 'PointLight';
};

/**
 * Emits light in a cone from a point.
 */
export type SpotLight = {
  kind: 'SpotLight';
  id: string;
  position: Vector3;
  direction: Vector3;
  angle: number;
  exponent: number;
};

/**
 * Returns a spot light.
 */
export const newSpotLight = (
  id: string,
  position: Vector3,
  direction: Vector3,
  angle: number,
  exponent: number
): SpotLight => {
  return {
    kind: 'SpotLight',
    id: id,
    position: position,
    direction: direction,
    angle: angle,
    exponent: exponent,
  };
};

/**
 * Asserts that the light is of type `SpotLight`.
 */
export const isSpotLight = (light: Light): light is SpotLight => {
  return light.kind === 'SpotLight';
};

/**
 * Simulates ambient environment light.
 */
export type HemisphericLight = {
  kind: 'HemisphericLight';
  id: string;
  intensity: number;
  reflectionDirection: Vector3;
};

/**
 * Returns a point light.
 */
export const newHemisphericLight = (
  id: string,
  intensity: number,
  reflectionDirection: Vector3
): HemisphericLight => {
  return {
    kind: 'HemisphericLight',
    id: id,
    intensity: intensity,
    reflectionDirection: reflectionDirection,
  };
};

/**
 * Asserts that the light is of type `HemisphericLight`.
 */
export const isHemisphericLight = (light: Light): light is HemisphericLight => {
  return light.kind === 'HemisphericLight';
};

/**
 * Adds the light to the Babylon scene.
 */
export const addToScene = (light: Light, scene: Scene) => {
  if (isPointLight(light)) {
    let babylonLight = new BabylonPointLight(light.id, light.position, scene);
    babylonLight.intensity = light.intensity;
    babylonLight.specular = light.specular;
  } else if (isSpotLight(light)) {
    new BabylonSpotLight(
      light.id,
      light.position,
      light.direction,
      light.angle,
      light.exponent,
      scene
    );
  } else if (isHemisphericLight(light)) {
    let babylonLight = new BabylonHemisphericLight(light.id, light.reflectionDirection, scene);
    babylonLight.intensity = light.intensity;
  } else {
    throw new Error('light type not supported');
  }
};
