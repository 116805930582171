import PropTypes from 'prop-types';
import React, { memo, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { AlertContext } from 'fr-shared/context';
import { ORDER_STATES } from 'fr-shared/lib/orders';

import { Button, Link, Money, StateBadge, TableBodyCell, TableBodyRow } from 'portal/components';
import { addLineItemsToCart } from 'portal/lib/cart';
import { date } from 'portal/lib/date';

const ListViewRow = ({ order }) => {
  const history = useHistory();
  const { setAlert } = useContext(AlertContext);
  const canReorder = order.state === ORDER_STATES.Shipped;

  const handleReorder = () => {
    addLineItemsToCart(order.line_items)
      .then(() => {
        history.push('/quotes/draft');
      })
      .catch(() => {
        setAlert({
          color: 'danger',
          message: 'We were unable to reorder. Please refresh and try again',
        });
      });
  };

  return (
    <TableBodyRow>
      <TableBodyCell>
        <Link to={`/orders/${order.id}`}>{order.public_id}</Link>
      </TableBodyCell>
      <TableBodyCell>
        <StateBadge state={order.state}>{order.state}</StateBadge>
      </TableBodyCell>
      <TableBodyCell value={order.customer_po} />
      <TableBodyCell value={date(order.inserted_at)} />
      <TableBodyCell value={date(order.actual_ship_date)} />
      <TableBodyCell value={order.line_items.length} width="1%" />
      <TableBodyCell align="right">
        <Money amount={order?.total_price?.amount} />
      </TableBodyCell>
      <TableBodyCell width="10%">
        {canReorder && (
          <Button outline size="xs" className="ml-2" onClick={handleReorder}>
            Reorder
          </Button>
        )}
      </TableBodyCell>
    </TableBodyRow>
  );
};

ListViewRow.propTypes = {
  order: PropTypes.object,
};

export default memo(ListViewRow);
