import { find, get, groupBy } from 'lodash';
import React from 'react';

import { FormFieldBase, Select } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { CHECKABLE_MFG_PROCESSES } from 'fr-shared/lib/manufacturability_checks';
import {
  AUTOCOSTABLE_LAYER_THICKNESSES,
  MANUFACTURING_PROCESSES,
  PROCESS_NAMES,
  getManufacturingProcessImageUrl,
} from 'fr-shared/lib/manufacturing_process';

import { CardWrapper } from 'portal/components';
import { getColorImageUrl, getMaterialImageUrl } from 'portal/lib/material';

import { MATERIAL_DESCRIPTIONS, PROCESS_DESCRIPTIONS } from '../../copy';
import FullScreenOptionCard, {
  FullScreenOption,
} from '../FullScreenOptionCard/FullScreenOptionCard';
import FullScreenSelector, {
  SelectedOptionStateContext,
} from '../FullScreenSelector/FullScreenSelector';

export type ManufacturingProcessFields = {
  manufacturing_process_id?: number;
  material_id?: number;
  color_id?: number;
  layer_thickness_id?: number;
};

type FormCollapsibleMfgProcessProps = {
  activeGlow?: boolean;
  analyticsTag?: string;
  manufacturingProcesses: ManufacturingProcess[];
  onFieldChanges?: (changes: Partial<ManufacturingProcessFields>) => void;
  partConfig?: ManufacturingProcessFields;
  partId?: number;
  showColor?: boolean;
  showLayerThickness?: boolean;
  showMaterial?: boolean;
  error?: string;
  readonly?: boolean;
};

const getAutocostableLayerThicknesses = (layerThicknesses: LayerThickness[]) =>
  layerThicknesses.filter(thickness => AUTOCOSTABLE_LAYER_THICKNESSES.includes(thickness.value));

const convertThicknessToOption = (thickness: LayerThickness) => ({
  id: thickness.id,
  name: `${thickness.value} ${thickness.unit}`,
});

const FormCollapsibleMfgProcess = ({
  activeGlow,
  analyticsTag,
  manufacturingProcesses,
  onFieldChanges,
  partConfig,
  showColor,
  showLayerThickness,
  error = null,
  readonly = false,
}: FormCollapsibleMfgProcessProps) => {
  const userAnalytics = useUserAnalyticsContext();
  const analyticsPrefix = `${analyticsTag || 'Unknown'} - `;

  // Pull the process
  const currentProcess = find(manufacturingProcesses, {
    id: +partConfig.manufacturing_process_id,
  });
  const groupedProcesses = groupBy(manufacturingProcesses, (mp: ManufacturingProcess) =>
    mp.additive ? 'additive' : 'traditional'
  );

  // Pull the materials from the process
  const relevantMaterials = currentProcess?.materials || null;
  const getMaterialById = (materialId: string | number) =>
    materialId && find(relevantMaterials, { id: +materialId });
  const currentMaterial = getMaterialById(partConfig.material_id);

  const showMaterialSelector =
    relevantMaterials &&
    relevantMaterials.length > 0 &&
    [
      MANUFACTURING_PROCESSES.DLS,
      MANUFACTURING_PROCESSES.FDM,
      MANUFACTURING_PROCESSES.MJF,
      MANUFACTURING_PROCESSES.SLA,
      MANUFACTURING_PROCESSES.CNC,
      MANUFACTURING_PROCESSES.InjectionMolding,
      MANUFACTURING_PROCESSES.CastUrethane,
    ].includes(get(currentProcess, 'name'));

  const materialColors = currentMaterial?.colors;
  const currentMaterialColor = materialColors?.find(c => c.id === partConfig.color_id);

  // Layer Thicknesses
  const getDefaultedLayerThickness = (material: Material, fallback: LayerThickness) => {
    if (!material?.layer_thicknesses?.length) {
      return null;
    }
    return (
      material.layer_thicknesses?.find(
        layerThickness => layerThickness.id === partConfig?.layer_thickness_id
      ) || fallback
    );
  };

  const layerThicknesses = currentMaterial?.layer_thicknesses;
  const currentLayerThickness = getDefaultedLayerThickness(currentMaterial, null);

  const autocostableLayerThicknesses = getAutocostableLayerThicknesses(layerThicknesses || []);

  const layerThicknessOptions = layerThicknesses &&
    layerThicknesses.length > 0 && [
      { id: -1, name: 'Instant Quote Available', disabled: true },
      ...autocostableLayerThicknesses.map(convertThicknessToOption),
    ];

  /** Contextual helpers */
  const logFieldChange = (type: string, inputValue: string) =>
    userAnalytics.track(`${analyticsPrefix}${type}`, { inputValue });

  const manufacturingProcessOnChange = (option: FullScreenOption) => {
    const manufacturingProcessOption: Partial<ManufacturingProcessFields> = {
      manufacturing_process_id: option.id,
      material_id: null,
      color_id: null,
      layer_thickness_id: null,
    };
    onFieldChanges(manufacturingProcessOption);
  };

  const materialOnChange = (option: Material) => {
    const autocostableThicknesses = getAutocostableLayerThicknesses(
      option?.layer_thicknesses || []
    );

    const defaultAutocostableThickness = autocostableThicknesses.find(lt => lt.value === '0.01');

    const defaultFdmLayerThickness = defaultAutocostableThickness
      ? defaultAutocostableThickness
      : autocostableThicknesses[0];

    // Set material id and default the layer thickness
    let fieldChanges: Partial<ManufacturingProcessFields> = {
      material_id: option.id,
      layer_thickness_id: getDefaultedLayerThickness(option, defaultFdmLayerThickness)?.id,
    };

    // Default the color if we are using the color input
    if (showColor) {
      const colors = option?.colors;
      fieldChanges = {
        ...fieldChanges,
        color_id: colors?.length === 1 ? colors[0].id : null,
      };
    }
    onFieldChanges(fieldChanges);
    logFieldChange('Material', option.name);
  };

  /** Render */
  return (
    <>
      <FullScreenSelector
        activeGlow={activeGlow && !currentProcess}
        disabled={readonly}
        error={error}
        name="manufacturing_process_id"
        labelText="Manufacturing process"
        modalHeader="Manufacturing process"
        modalSubHeader="Select a manufacturing process for your part."
        currentOption={currentProcess}
        onChange={(option: FullScreenOption) => {
          manufacturingProcessOnChange(option);
          logFieldChange('Process', option.name);
        }}
        placeholder="Please select"
      >
        {({ onSubmit, selectedOption }: SelectedOptionStateContext) => (
          <div className="flex flex-1 flex-column">
            {Object.keys(groupedProcesses)
              .reverse()
              .map((group: string) => {
                // render each group of Manufacturing Processes
                const processes: ManufacturingProcess[] = groupedProcesses[group];
                return (
                  <div className="mb-4" key={group}>
                    <h5 className="mb-4">
                      {group === 'additive' ? '3D Printing' : 'Traditional Manufacturing'}
                    </h5>
                    <CardWrapper>
                      {processes.map((option: ManufacturingProcess) => (
                        <FullScreenOptionCard
                          key={`${option.name}${option.id}`}
                          onSubmit={onSubmit}
                          name={PROCESS_NAMES[option.name]}
                          description={PROCESS_DESCRIPTIONS[option.name]}
                          imageUrl={getManufacturingProcessImageUrl(option.name)}
                          option={option}
                          selectedOption={selectedOption}
                        >
                          {CHECKABLE_MFG_PROCESSES.includes(option.name) && (
                            <div className="border-top mt-auto pt-2">
                              <span className="font-size-sm text-tertiary-500">
                                Automated DFM checks available
                              </span>
                            </div>
                          )}
                        </FullScreenOptionCard>
                      ))}
                    </CardWrapper>
                  </div>
                );
              })}
          </div>
        )}
      </FullScreenSelector>

      {showMaterialSelector && (
        <FullScreenSelector
          activeGlow={activeGlow && currentProcess && !currentMaterial}
          disabled={readonly}
          name="material_id"
          labelText="Material"
          modalHeader="Material"
          modalSubHeader="Select a material for your part."
          currentOption={currentMaterial}
          onChange={materialOnChange}
          placeholder="Please select"
        >
          {({ onSubmit, selectedOption }: SelectedOptionStateContext) => (
            <CardWrapper>
              {relevantMaterials?.map((option: FullScreenOption) => {
                return (
                  <FullScreenOptionCard
                    key={`${option.name}${option.id}`}
                    description={MATERIAL_DESCRIPTIONS[option.name]}
                    onSubmit={onSubmit}
                    imageUrl={getMaterialImageUrl(currentProcess.name, option.name)}
                    option={option}
                    selectedOption={selectedOption}
                  />
                );
              })}
            </CardWrapper>
          )}
        </FullScreenSelector>
      )}

      {showColor && materialColors?.length > 0 && (
        <FullScreenSelector
          activeGlow={activeGlow && currentProcess && currentMaterial && !currentMaterialColor}
          disabled={readonly || (materialColors?.length === 1 && !!currentMaterialColor)}
          name="color"
          labelText="Color"
          modalHeader="Color"
          modalSubHeader="Select a color for your part."
          currentOption={currentMaterialColor}
          onChange={(option: FullScreenOption) => {
            onFieldChanges({ color_id: option.id });
            logFieldChange('Color', option.name);
          }}
          placeholder="Please select"
        >
          {({ onSubmit, selectedOption }: SelectedOptionStateContext) => (
            <CardWrapper>
              {materialColors?.map((option: FullScreenOption) => {
                return (
                  <FullScreenOptionCard
                    key={`${option.name}${option.id}`}
                    option={option}
                    onSubmit={onSubmit}
                    imageUrl={
                      materialColors.length > 1
                        ? getColorImageUrl(currentProcess.name, currentMaterial.name, option.name)
                        : getMaterialImageUrl(currentProcess.name, currentMaterial.name)
                    }
                    selectedOption={selectedOption}
                  />
                );
              })}
            </CardWrapper>
          )}
        </FullScreenSelector>
      )}

      {showLayerThickness && layerThicknessOptions && (
        <FormFieldBase name="layer_thickness_id" label="Layer Thickness" isControl={true}>
          <Select
            name="layer_thickness_id"
            className="form-group is-floating"
            selectClassName="form-control"
            sorted={false}
            hasBlankOption={false}
            optionList={layerThicknessOptions}
            value={currentLayerThickness?.id?.toString()}
            onChange={e => onFieldChanges({ [e.target.name]: +e.target.value })}
          />
        </FormFieldBase>
      )}
    </>
  );
};

export default FormCollapsibleMfgProcess;
