import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, Button, GlobalErrorBoundary, Helmet, NotFound } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';

import GenerateSupplierPurchaseOrderButton from './components/GenerateSupplierPurchaseOrderButton';
import OrderForm from './components/OrderForm';
import OrderWorkOrderButton from './components/OrderWorkOrderButton';

export default class OrderShow extends Component {
  static contextType = UserContext;

  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
  };

  state = {
    error: null,
    loading: false,
    order: null,
    braintree: {
      token: null,
      error: null,
    },
  };

  componentDidMount() {
    const { match } = this.props;
    const id = match.params.id;

    this.getOrders(id);
  }

  getOrders = id => {
    this.setState({ loading: true });
    api
      .get(`/orders/${id}`)
      .then(res => {
        this.setState({ order: res.data });
      })
      .catch(error => {
        if (error.response?.status !== 404) {
          Sentry.captureMessage('Order GET Failed');
          Sentry.setExtra('order_id', id);
          Sentry.setExtra('error', error);
          this.setState({ error: error });
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { error, loading, order } = this.state;
    const { history, match } = this.props;
    const { user } = this.context;

    if (loading) return null;
    if (error) return <GlobalErrorBoundary />;
    if (!order) return <NotFound />;

    const id = match.params.id;
    const publicId = order.public_id || id;

    return (
      <>
        <Helmet title={`Order ${publicId}`} />
        <Breadcrumb to="/admin/orders">Orders</Breadcrumb>
        <Breadcrumb to={`/admin/orders/${id}`}>Order {publicId}</Breadcrumb>

        {order && (
          <OrderForm
            title={`Order ${publicId}`}
            match={match}
            history={history}
            initialValues={order}
            readonly={true}
            isEditing={false}
          >
            <div className="row">
              <div className="col-md-12 flex justify-content-end flex-wrap">
                {user.canCreateSupplierPurchaseOrders && (
                  <GenerateSupplierPurchaseOrderButton
                    order={order}
                    getOrders={() => this.getOrders(id)}
                  />
                )}

                {order.state !== 'Draft' && (
                  <OrderWorkOrderButton orderId={order.id} orderLineItems={order.line_items} />
                )}

                {order.public_id && (
                  <Button
                    color="primary"
                    size="sm"
                    className="ml-2 mb-2"
                    to={{
                      pathname: `/admin/shipments/new`,
                      state: { initialValues: order },
                    }}
                  >
                    New Shipment
                  </Button>
                )}
                <Button
                  className="ml-2 mb-2"
                  color="primary"
                  size="sm"
                  to={`/admin/packing_slips/${id}`}
                >
                  Generate Packing Slip
                </Button>
              </div>
            </div>
          </OrderForm>
        )}
      </>
    );
  }
}
