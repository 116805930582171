import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import {
  IconFont,
  ManufacturabilityChecks,
  ManufacturabilityDefinitionsModal,
} from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { useToggle } from 'fr-shared/hooks';
import { CHECKABLE_MFG_PROCESSES, checkPassFail } from 'fr-shared/lib/manufacturability_checks';
import { keyboardDown } from 'fr-shared/utils';

import { Loading, classNames } from 'portal/components';

const AutomatedChecks = ({
  className,
  headerClassName,
  hideToggleDetails = false,
  manufacturingProcess,
  onToggleMesh,
  onViewDfmDefinitions,
  part,
  visibleMeshes,
}) => {
  const userAnalytics = useUserAnalyticsContext();
  const [hideManufacturingCheckDetails, toggleHideManufacturingCheckDetails] = useToggle(false);
  const currentRevision = part?.current_revision;
  const manufacturabilityChecks = currentRevision?.manufacturability_checks;
  const { passedCheckNames, errorCheckNames, warningCheckNames, totalChecks } = checkPassFail(
    manufacturabilityChecks,
    manufacturingProcess
  );
  const hasTimedOut = part?.has_timed_out === true;
  const hasFailed = manufacturabilityChecks === false;
  const loadingManufacturabilityChecks = isEmpty(manufacturabilityChecks) && !hasTimedOut;
  const errorCheckAmt = errorCheckNames.length;
  const warningCheckAmt = warningCheckNames.length;
  const successCheckAmt = passedCheckNames.length;
  const hasCheckErrors = errorCheckAmt > 0;
  const hasCheckWarnings = warningCheckAmt > 0;
  const hasChecksPassed = successCheckAmt > 0;
  /*
   * `(loadingManufacturabilityChecks || totalChecks > 0)` handles two possibilities:
   * 1. A timeout occurred and we don't have any data.
   * 2. `manufacturabilityChecks` has been populated but there's no checks for the selected mfg process.
   *
   * The second case shouldn't occur in practice but it's technically possibiltiy given the
   * allowed data types/shapes.
   */
  const showManufacturingChecks =
    CHECKABLE_MFG_PROCESSES.includes(manufacturingProcess?.name) &&
    (loadingManufacturabilityChecks || totalChecks > 0) &&
    !hasFailed;
  const logChecksAmt = (type, checkQty) =>
    userAnalytics.track(`DFM Insights - ${type}`, { checkQty });
  const logDFMInsights = () => {
    if (hasCheckErrors) logChecksAmt('Errors', errorCheckAmt);
    if (hasCheckWarnings) logChecksAmt('Warnings', warningCheckAmt);
    if (hasChecksPassed) logChecksAmt('Passed Checks', successCheckAmt);
  };

  useEffect(() => {
    if (!loadingManufacturabilityChecks) logDFMInsights();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedCheckNames, errorCheckNames, warningCheckNames, totalChecks]);

  if (!showManufacturingChecks) return null;

  return (
    <div className={className}>
      <div
        className={classNames([
          'flex justify-content-between align-items-center mb-0 py-2',
          { 'border-bottom': !hideManufacturingCheckDetails },
          headerClassName,
        ])}
      >
        <h3 className="pb-0 mb-0">Automated Checks</h3>
        <div className="flex align-items-center">
          {!loadingManufacturabilityChecks && (
            <>
              {hasCheckErrors && (
                <div className="badge badge-danger rounded-circle ml-2">
                  {errorCheckNames.length}
                </div>
              )}
              {hasCheckWarnings && (
                <div className="badge badge-warning rounded-circle text-white ml-2">
                  {warningCheckNames.length}
                </div>
              )}
              {hasChecksPassed && (
                <div className="badge badge-success text-white rounded-circle ml-2">
                  {passedCheckNames.length}
                </div>
              )}
            </>
          )}

          {!hideToggleDetails && (
            <div
              className="d-inline-block cursor-pointer ml-2"
              onClick={toggleHideManufacturingCheckDetails}
              onKeyDown={evt => keyboardDown(evt, 'Enter', toggleHideManufacturingCheckDetails)}
              role="button"
              tabIndex={0}
            >
              <IconFont name={hideManufacturingCheckDetails ? 'chevron-down' : 'chevron-up'} />
            </div>
          )}
        </div>
      </div>

      {!hideManufacturingCheckDetails && (
        <div className={classNames(['py-2', headerClassName])}>
          {loadingManufacturabilityChecks ? (
            <div className="flex justify-content-center">
              <Loading />
            </div>
          ) : (
            <>
              <ManufacturabilityChecks
                manufacturingProcess={manufacturingProcess}
                onToggleMesh={onToggleMesh}
                revision={currentRevision}
                showHelpMessage
                visibleMeshes={visibleMeshes}
              />
              <div className="mt-2 mb-1">
                <ManufacturabilityDefinitionsModal
                  manufacturingProcess={manufacturingProcess}
                  revision={currentRevision}
                  onViewDfmDefinitions={onViewDfmDefinitions}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

AutomatedChecks.propTypes = {
  className: PropTypes.string,
  hasTimedOut: PropTypes.bool,
  headerClassName: PropTypes.string,
  hideToggleDetails: PropTypes.bool,
  manufacturingProcess: PropTypes.object,
  onToggleMesh: PropTypes.func,
  onViewDfmDefinitions: PropTypes.func,
  part: PropTypes.object,
  visibleMeshes: PropTypes.object,
};

export default AutomatedChecks;
