import React, { useContext } from 'react';

const withContext =
  <ContextType, Props extends ContextType>(Context: React.Context<ContextType>) =>
  (Component: React.ComponentType<Props>) =>
  (props: Props) => {
    const context = useContext(Context);

    return <Component {...props} {...context} />;
  };

export default withContext;
