import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { logout } from 'fr-shared/auth';
import { getUserShortcuts } from 'fr-shared/lib/user_roles';

import { mixPanelUserAnalytics } from './UserAnalytics/MixPanelUserAnalytics';

const UserContext = React.createContext({ user: { id: undefined } });

const UserProvider = ({ children }) => {
  const [userStore, setUserStore] = useState({
    user: null,
    isLoading: true,
  });

  const { user, isLoading } = userStore;

  useEffect(() => {
    // heartbeat to refresh JWT session cookie expiration every 2 hours
    const interval = setInterval(() => {
      api.post('/keep-alive').catch(() => {});
    }, 7200000);
    return () => clearInterval(interval);
  }, []);

  // The following route is excluded in LOGOUT_ROUTES_EXCLUDED in api.js from triggering a logout on 401
  const refreshUser = useCallback(() => {
    return api
      .get('/users/me')
      .then(res => {
        const user = res.data;
        if (user) {
          const userWithShortcuts = getUserShortcuts(user);

          setUserStore(userStore => {
            return {
              ...userStore,
              user: userWithShortcuts,
              isLoading: false,
            };
          });

          mixPanelUserAnalytics.identify(user.id);

          if (process.env.MIX_ENV !== 'dev') {
            Sentry.setUser(user);
          }
        } else {
          setUserStore(userStore => ({ ...userStore, user: null, isLoading: false }));
        }
      })
      .catch(err => {
        // clear the user so that we trigger the UnauthenticatedRoutes flow in router.js
        logout(true).finally(() =>
          setUserStore(userStore => ({ ...userStore, user: null, isLoading: false }))
        );
        if (process.env.MIX_ENV === 'dev') return;
        if (process.env.MIX_ENV === 'prod') {
          Sentry.setExtra('error', err);
          Sentry.captureMessage('users:me request failed');
        }
      });
  }, []);

  useEffect(() => {
    refreshUser();
  }, [refreshUser]);

  if (isLoading) return null;

  return (
    <UserContext.Provider
      value={{
        user,
        refreshUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

const UserConsumer = UserContext.Consumer;

export { UserProvider, UserConsumer, UserContext };
