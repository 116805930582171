import PropTypes from 'prop-types';
import React from 'react';

import { AddressTypes } from 'fr-shared/lib/address';
import { keyboardDown } from 'fr-shared/utils';

import { Address, Button, Modal } from 'portal/components';

import AddressModal from '../../account/account_details/components/AddressModal';

const AddressSelector = ({
  addresses,
  onAddressAdded,
  onAddressIndexSelected,
  selectedAddressIndex,
  type,
}) => {
  return (
    <>
      <div>
        {addresses.map((address, index) => {
          return (
            <div
              key={`AddressSelector-option-${index}`}
              className="bg-gray-600 rounded-md p-3 mb-2 hover:cursor-pointer"
              onClick={() => onAddressIndexSelected(index)}
              onKeyDown={evt => keyboardDown(evt, 'Enter', () => onAddressIndexSelected(index))}
              role="button"
              tabIndex={0}
            >
              <div className="custom-control custom-radio mb-0 flex flex-row">
                <input
                  className="custom-control-input"
                  type="radio"
                  id={`address-${index}`}
                  value={index}
                  checked={selectedAddressIndex === index}
                  onChange={() => onAddressIndexSelected(index)}
                />
                <label
                  htmlFor={`address-${index}`}
                  className="custom-control-label pl-2 mb-0 hover:cursor-pointer"
                >
                  <Address address={address} flatten />
                </label>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        action={
          <Button outline size="sm">
            Add new {type} address
          </Button>
        }
      >
        {({ toggle }) => (
          <AddressModal
            type={type}
            toggle={toggle}
            isEdit={false}
            onSave={onAddressAdded}
            title={`Add New ${type} address`}
          />
        )}
      </Modal>
    </>
  );
};

export default AddressSelector;

AddressSelector.propTypes = {
  addresses: PropTypes.array,
  onAddressAdded: PropTypes.func,
  onAddressIndexSelected: PropTypes.func,
  selectedAddressIndex: PropTypes.number,
  type: PropTypes.oneOf(AddressTypes),
};
