import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  NavLink as RouterNavLink,
} from 'react-router-dom';

import { Loading, classNames } from 'fr-shared/components';

interface LinkProps extends RouterLinkProps {
  dataTestId?: string;
  loading?: boolean;
  openNewWindow?: boolean;
  openNewWindowIcon?: boolean;
}

export const Link = ({
  children,
  dataTestId,
  loading,
  openNewWindow,
  openNewWindowIcon = false,
  className,
  ...props
}: LinkProps) => {
  const target = openNewWindow ? '_blank' : null;
  const rel = openNewWindow ? 'noopener noreferrer' : null;

  const classes = classNames([
    className,
    { 'external-link': openNewWindow && openNewWindowIcon },
  ]);

  return (
    <RouterLink data-testid={dataTestId} target={target} rel={rel} className={classes} {...props}>
      {loading ? <Loading /> : children}
    </RouterLink>
  );
};

export const NavLink = RouterNavLink;

export default Link;
