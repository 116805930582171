import React from 'react';

import { Button, IconFont, Modal } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';

import { ContactUs, ContactUsType } from 'portal/components';

import styles from './HelpModal.module.css';

interface HelpModalProps {
  action: JSX.Element;
  onActionHandler?: () => void;
  userAnalyticsPrefix?: string;
}

export const HelpModal = ({
  action,
  onActionHandler,
  userAnalyticsPrefix = 'General',
}: HelpModalProps) => {
  const userAnalytics = useUserAnalyticsContext();

  return (
    <Modal action={action} onActionHandler={onActionHandler} className="modal-md">
      {({ toggle }: { toggle: any }) => {
        const onClickContact = () => {
          userAnalytics.track(`${userAnalyticsPrefix} - Get Help Email`);
          toggle();
        };

        const onOpenDrift = () => {
          userAnalytics.track(`${userAnalyticsPrefix} - Get Help Chat`);
          window?.drift?.api?.openChat();
          toggle();
        };

        return (
          <>
            <Modal.Header title="How can we help?" textCentered={true} onClose={toggle} />
            <Modal.Body>
              <p className="text-center text-muted font-size-base">
                Talk to one of our manufacturing experts.
                <br />
                We’d love to dig into the details with you.
              </p>
            </Modal.Body>
            <Modal.Footer className={styles.FooterContainer}>
              <ContactUs
                linkType={ContactUsType['button']}
                color="secondary"
                outline={true}
                className="font-size-base"
                onClick={onClickContact}
              >
                <IconFont name="email" className={styles.ButtonIcon} />
                Email
              </ContactUs>
              <Button
                color="secondary"
                outline={true}
                className="font-size-base"
                onClick={onOpenDrift}
                onKeyDown={onOpenDrift}
              >
                <IconFont name="comments" className={styles.ButtonIcon} />
                Chat
              </Button>
            </Modal.Footer>
          </>
        );
      }}
    </Modal>
  );
};

export default HelpModal;
