import { differenceBy, take } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { api } from 'fr-shared/api';
import { Button, Checkbox, Icon, Loading, withPagination } from 'fr-shared/components';
import { SidebarContext } from 'fr-shared/context';

import WorkOrderRow from './WorkOrderRow';

const WorkOrdersTable = ({
  creators,
  filterState,
  locations,
  manufacturingProcess,
  materials,
  onNextState,
  onMassSelectWorkOrder,
  onSelectWorkOrder,
  pagination,
  printers,
  selectedWorkOrders,
}) => {
  const { open } = useContext(SidebarContext);
  const [loadingTravelers, setLoadingTravelers] = useState(false);

  const handleDownloadTravelers = useMemo(() => {
    return () => {
      const workOrders = selectedWorkOrders?.length > 0 ? selectedWorkOrders : pagination.data;
      const workOrderIDs = workOrders.map(work_order => work_order.id);

      setLoadingTravelers(true);
      api
        .get('/bulk_traveler', { params: { work_order_ids: workOrderIDs } })
        .then(res => {
          window.open(res.data.path);
        })
        .finally(() => {
          setLoadingTravelers(false);
        });
    };
  }, [pagination, selectedWorkOrders, setLoadingTravelers]);

  /*
   * Any time a filterable prop is set to a new meaningful value, (re)apply that
   * criterion via the `Paginated` HOC, which forwards filters as additional
   * query params on the underlying request.
   * @param {array} creators - integer IDs of one or more users to filter work orders by
   */
  useEffect(() => {
    pagination.filterBy({
      target: { name: 'creators', value: creators },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creators]);

  /*
   * Any time a filterable prop is set to a new meaningful value, (re)apply that
   * criterion via the `Paginated` HOC, which forwards filters as additional
   * query params on the underlying request.
   * @param {array} locations - integer IDs of one or more locations to filter work orders by
   */
  useEffect(() => {
    pagination.filterBy({
      target: { name: 'locations', value: locations },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  /*
   * Any time a filterable prop is set to a new meaningful value, (re)apply that
   * criterion via the `Paginated` HOC, which forwards filters as additional
   * query params on the underlying request.
   * @param {array} manufacturingProcess - name of a manufacturing process to filter work orders by
   */
  useEffect(() => {
    pagination.filterBy({
      target: { name: 'process', value: manufacturingProcess },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturingProcess]);

  /*
   * Any time a filterable prop is set to a new meaningful value, (re)apply that
   * criterion via the `Paginated` HOC, which forwards filters as additional
   * query params on the underlying request.
   * @param {array} materials - names of one or more manufacturing materials to filter work orders by
   */
  useEffect(() => {
    pagination.filterBy({
      target: { name: 'materials', value: materials },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materials]);

  /*
   * Any time a filterable prop is set to a new meaningful value, (re)apply that
   * criterion via the `Paginated` HOC, which forwards filters as additional
   * query params on the underlying request.
   * @param {string} printer - name of one or more printers to filter work orders by
   */
  useEffect(() => {
    pagination.filterBy({
      target: { name: 'printers', value: printers },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printers]);

  const { data, total, renderPagination, renderPageSize } = pagination;

  const selectNextUnchecked = count => {
    if (onMassSelectWorkOrder) {
      let unchecked = take(differenceBy(data, selectedWorkOrders, 'id'), count);
      onMassSelectWorkOrder(unchecked);
    }
  };

  return (
    <div className="mb-3" data-testid={`work-orders-${manufacturingProcess}`}>
      <div className="work-order-header">
        <h4 className="mb-0 align-items-center flex flex-grow-1">
          {filterState}
          <span className="badge badge-primary badge-indicator ml-2">{total}</span>
        </h4>
        <div className="flex" data-testid="work-orders-select-next-buttons">
          {open && manufacturingProcess && (
            <div className="ml-auto mr-2 flex align-items-center justify-content-end">
              <span className="mb-0 mr-1 whitespace-nowrap" style={{ fontSize: '0.875em' }}>
                Select Next
              </span>
              <div className="btn-group">
                {[10, 20, 50, 75, 100].map(n => {
                  if (n <= 10 || (n <= pagination.pageSize && n <= total))
                    return (
                      <Button
                        active={manufacturingProcess !== null && manufacturingProcess !== ''}
                        key={`select_next_button_${n}`}
                        size="sm"
                        tooltip={`Check next ${n} unchecked Work Orders`}
                        onClick={() => {
                          selectNextUnchecked(n);
                        }}
                      >
                        {n >= pagination.pageSize || n >= total ? 'All' : n}
                      </Button>
                    );
                })}
              </div>
            </div>
          )}
          <div
            className="ml-auto flex align-items-center justify-content-end"
            data-testid="work-orders-pagination"
          >
            {renderPageSize()}

            <Button
              className="ml-1"
              size="sm"
              tooltip="Download all Travelers"
              loading={loadingTravelers}
              onClick={handleDownloadTravelers}
            >
              <Icon name="file-download" />
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-column justify-content-between">
        {data ? (
          data.map(workOrder => (
            <div key={workOrder.id} className="flex align-items-center mb-2">
              {open && (
                <Checkbox
                  data-testid={`select-work-order-${workOrder.id}`}
                  name={`work_order.${workOrder.id}.selected`}
                  className="d-inline-block mb-0"
                  onChange={() => onSelectWorkOrder && onSelectWorkOrder(workOrder)}
                  value={
                    selectedWorkOrders && !!selectedWorkOrders.find(wo => wo.id === workOrder.id)
                  }
                />
              )}
              <WorkOrderRow
                className={
                  open &&
                  selectedWorkOrders &&
                  selectedWorkOrders.find(wo => wo.id === workOrder.id)
                    ? 'selected'
                    : 'not-selected'
                }
                data-test-id={`work-order-${workOrder.id}`}
                onClick={() => open && onSelectWorkOrder && onSelectWorkOrder(workOrder)}
                onNextState={onNextState}
                workOrder={workOrder}
              />
            </div>
          ))
        ) : (
          <Loading />
        )}
      </div>
      {renderPagination()}
    </div>
  );
};

WorkOrdersTable.propTypes = {
  creators: PropTypes.arrayOf(PropTypes.number),
  filterState: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.number),
  manufacturingProcess: PropTypes.string,
  materials: PropTypes.arrayOf(PropTypes.string),
  onNextState: PropTypes.func,
  onMassSelectWorkOrder: PropTypes.func,
  onSelectWorkOrder: PropTypes.func,
  pagination: PropTypes.object,
  printers: PropTypes.arrayOf(PropTypes.string),
  selectedWorkOrders: PropTypes.array,
  sortDesc: PropTypes.oneOf(['true', 'false']),
  sortKey: PropTypes.string,
};

export default withPagination({
  url: '/work_orders',
  channelName: 'refresh:all',
  channelEvent: 'refresh',
  updateAllData: true,
  scrollToTop: false,
  pageSize: props => props.pageSize,
  initialFilters: props => ({
    state: props.filterState,
    creators: props.creators,
    locations: props.locations,
    printers: props.printers,
    process: props.manufacturingProcess,
    sort_desc: props.sortDesc,
    sort_key: props.sortKey || 'earliest_ship_date',
  }),
})(WorkOrdersTable);
