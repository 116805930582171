/* eslint-disable react/display-name,react/prop-types */
import { sanitizeUrl } from '@braintree/sanitize-url';
import { countBy, kebabCase } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, FirstOrderBadge, Link, Table } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { quantityShipped } from 'fr-shared/lib/shipping';
import { userCanEditOrder } from 'fr-shared/lib/user_roles';

import { formatNullableDate } from './planned_shipment_line_items/PSLITableEdit';

const OrdersTable = ({ data, pages, loading, sort, onFetchData, ...props }) => {
  const { user } = React.useContext(UserContext);

  return (
    <Table
      manual
      data={data}
      pages={pages}
      pageSize={data.length || 20}
      showPagination={true}
      loading={loading}
      className="bg-white -left -striped mb-5"
      onFetchData={onFetchData}
      noDataText="No orders found"
      defaultSorted={sort}
      columns={[
        {
          id: 'expander',
          sortable: false,
          width: 45,
          expander: true,
          Expander: ({ isExpanded, ...rest }) => (
            <div className="flex justify-content-between align-items-center">
              {rest.original.line_items && rest.original.line_items.length > 0 && (
                <Button size="sm" color="secondary">
                  <i className={`fas fa-chevron-${isExpanded ? 'up' : 'down'}`} />
                </Button>
              )}
            </div>
          ),
        },
        {
          Header: 'Order #',
          id: 'public_id',
          width: 120,
          Cell: ({ original }) => (
            <Link to={`/admin/orders/${original.id}`}>
              {original.is_first_order && (
                <FirstOrderBadge className="mr-1" fontSize="xs" showText={false} />
              )}
              {original.public_id}
            </Link>
          ),
        },
        {
          Header: 'Customer',
          id: 'customer_id',
          accessor: d =>
            d.customer && <Link to={`/admin/customers/${d.customer.id}`}>{d.customer.name}</Link>,
        },
        { Header: 'State', accessor: 'state' },
        {
          Header: 'Public ID',
          id: 'public_id',
          Cell: ({ original }) => <>{original.public_id}</>,
        },
        {
          Header: 'Sales Contact',
          id: 'sales_contact_id',
          accessor: d => d.sales_contact && d.sales_contact.name,
        },
        {
          Header: 'Created',
          id: 'inserted_at',
          width: 100,
          accessor: d => moment(d.inserted_at).format('MM/DD/YYYY'),
        },
        {
          Header: 'Produced By',
          id: 'location_id',
          accessor: d => d.line_items?.[0]?.location?.name,
        },
        {
          Header: 'MFG Process',
          id: 'manufacturing_process_id',
          accessor: d => {
            const count = countBy(d.line_items, 'manufacturing_process.name');
            const countString = Object.keys(count).map(k => ` ${k}: ${count[k]}`);
            return <span data-rh={countString}>{Object.keys(count).join(', ')}</span>;
          },
        },
        {
          Header: 'Items',
          id: 'line_items',
          sortable: false,
          width: 85,
          accessor: d => d.line_items?.length,
        },
        {
          Header: 'Total',
          id: 'total_price',
          accessor: data => data?.total_price?.formatted,
          sortable: false,
        },
        {
          Header: 'Promised Ship Date',
          id: 'promised_ship_date',
          accessor: d => {
            return formatNullableDate(d.effective_promised_ship_date);
          },
        },
        {
          id: 'box_edit_view',
          sortable: false,
          width: 140,
          Cell: row => (
            <div className="flex justify-content-between align-items-center">
              {row.original.box_file_location && (
                <Link
                  className="btn btn-link btn-lg font-size-md letter-spacing-xs px-1"
                  to={{ pathname: sanitizeUrl(row.original.box_file_location) }}
                  openNewWindow
                >
                  box
                </Link>
              )}
              {userCanEditOrder(user, row.original) && (
                <Link
                  className="btn btn-link btn-lg font-size-md px-1"
                  to={`/admin/orders/${row.original.id}/edit`}
                >
                  Edit
                </Link>
              )}
              <Link
                className="btn btn-link btn-lg font-size-md px-1 pr-2"
                to={`/admin/orders/${row.original.id}`}
              >
                View
              </Link>
            </div>
          ),
          style: {
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: 'pointer',
            fontSize: 18,
            padding: '0',
            textAlign: 'center',
            userSelect: 'none',
          },
        },
      ]}
      SubComponent={row => {
        return (
          <Table
            data={row.original.line_items}
            defaultPageSize={row.original.line_items.length}
            showPagination={false}
            className="-no-shadow"
            columns={[
              { Header: 'ID', accessor: 'id' },
              { Header: 'File Name', accessor: 'description' },
              {
                Header: 'MFG Process',
                accessor: 'manufacturing_process.name',
              },
              { Header: 'State', accessor: 'state' },
              { Header: 'Produced By', accessor: 'location.name' },
              { Header: 'Material', accessor: 'material.name' },
              {
                Header: 'Color',
                id: 'color',
                accessor: d => (
                  <span className={kebabCase(d.color && d.color.name)}>
                    {d.color && d.color.name}
                  </span>
                ),
              },
              {
                Header: 'Layer Height',
                id: 'layer height',
                accessor: d =>
                  d.layer_thickness === null ? (
                    <span className="text-muted">N/A</span>
                  ) : (
                    `${d.layer_thickness.value}  ${d.layer_thickness.unit}`
                  ),
              },
              { Header: 'Quantity', accessor: 'quantity' },
              {
                Header: 'Promised Ship Date',
                id: 'promised_ship_date',
                accessor: d =>
                  d.promised_ship_date && moment(d.promised_ship_date).format('MM/DD/YYYY'),
              },
              {
                Header: 'Amount Shipped',
                id: 'amount_shipped',
                accessor: d => (
                  <span>
                    {quantityShipped(d)} / {d.quantity}
                  </span>
                ),
              },
              {
                Header: 'Actual Ship Date',
                id: 'actual_ship_date',
                sortable: false,
                accessor: d =>
                  d.actual_ship_date && moment(d.actual_ship_date).format('MM/DD/YYYY'),
              },
              {
                Header: 'Inspection Type',
                id: 'inspection_type',
                sortable: false,
                accessor: d => d?.inspection_type?.name,
              },
              {
                Header: 'Total',
                id: 'total_price',
                accessor: data => data?.total_price?.formatted,
                sortable: false,
              },
            ]}
          />
        );
      }}
      {...props}
    />
  );
};

OrdersTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      public_id: PropTypes.string.isRequired,
      customer: PropTypes.shape({
        name: PropTypes.string,
      }),
      sales_contact: PropTypes.shape({
        name: PropTypes.string,
      }),
      inserted_at: PropTypes.string,
      box_file_location: PropTypes.string,
      line_items: PropTypes.arrayOf(
        PropTypes.shape({
          amount_shipped: PropTypes.number,
          quantity: PropTypes.number,
          promised_ship_date: PropTypes.string,
          actual_ship_date: PropTypes.string,
          id: PropTypes.number.isRequired,
          description: PropTypes.string,
          state: PropTypes.string,
          material: PropTypes.shape({ name: PropTypes.string }),
          layer_thickness: PropTypes.shape({ value: PropTypes.string }),
          color: PropTypes.shape({ name: PropTypes.string }),
          location: PropTypes.shape({ name: PropTypes.string }),
          manufacturing_process: PropTypes.shape({ name: PropTypes.string }),
          part: PropTypes.shape({
            name: PropTypes.string,
          }),
        })
      ),
      total_price: PropTypes.shape({
        formatted: PropTypes.string,
      }),
    })
  ),
  pages: PropTypes.number,
  loading: PropTypes.bool,
  sort: PropTypes.array,
  onFetchData: PropTypes.func,
};

export default OrdersTable;
