import { PropTypes } from 'prop-types';
import React from 'react';

import { classNames } from 'fr-shared/components';

import styles from './Card.module.css';

export const CardWrapper = ({ children, className }) => {
  return <div className={classNames([styles.CardWrapper, className])}>{children}</div>;
};

CardWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
