import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';

import { FormField } from 'fr-shared/components';
import { SHIPPING_PAYMENT_TYPES, SHIPPING_PAYMENT_TYPE_OPTIONS } from 'fr-shared/lib/shipping';

import { QuoteFormContext } from './QuoteFormContext';

const QuoteFormShippingPaymentType = ({ readonly = false }) => {
  const formik = useFormikContext();
  const { setValuesAndPushToChannel } = useContext(QuoteFormContext);

  const handleChange = useCallback(
    e => {
      if (e.target.value === SHIPPING_PAYMENT_TYPES.CustomerAccount) {
        setValuesAndPushToChannel({
          ...formik.values,
          shipping_payment_type: e.target.value,
          selected_shipping_estimate_id: null,
          shipping_method: null,
          shipping_price: null,
        });
      } else {
        setValuesAndPushToChannel({
          ...formik.values,
          shipping_payment_type: e.target.value,
        });
      }
    },
    [formik, setValuesAndPushToChannel]
  );

  return (
    <FormField
      component="select"
      label="Shipping Payment Type"
      name="shipping_payment_type"
      onChange={handleChange}
      readonly={readonly}
      required
    >
      <option value=""></option>
      {SHIPPING_PAYMENT_TYPE_OPTIONS.map(paymentType => (
        <option key={paymentType} value={paymentType}>
          {paymentType}
        </option>
      ))}
    </FormField>
  );
};

QuoteFormShippingPaymentType.propTypes = {
  readonly: PropTypes.bool,
};

export default QuoteFormShippingPaymentType;
