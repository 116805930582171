import PropTypes from 'prop-types';
import React from 'react';

import { Icon, classNames } from 'fr-shared/components';

import styles from './TableHeadCell.module.css';

export const TableHeadCell = ({
  align,
  children,
  className = '',
  onClick,
  scope = 'col',
  sorted = false,
  isSortDesc = true,
  value,
  width,
}) => {
  const handleCellClick = onClick ? () => onClick(value) : () => {};

  return (
    <th
      onClick={handleCellClick}
      className={classNames([onClick && 'cursor-pointer', `${styles.Root} ${className}`])}
      style={{ textAlign: align, width }}
      scope={scope}
    >
      {children ? children : value}
      {sorted && (
        <Icon
          className="float-right pr-1 pt-0.5"
          name={isSortDesc ? 'chevron-up' : 'chevron-down'}
        />
      )}
    </th>
  );
};

TableHeadCell.propTypes = {
  align: PropTypes.string,
  children: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  scope: PropTypes.string,
  sorted: PropTypes.bool,
  isSortDesc: PropTypes.bool,
  value: PropTypes.string,
  width: PropTypes.string,
};

export default TableHeadCell;
