// lib/fast_radius/deals/deal.ex
export const DEAL_STATUSES = {
  Created: 'Created',
  Deleted: 'Deleted',
  ReleasedToFloor: 'Released to Floor',
  Printing: 'Printing',
  ThermalProcessing: 'Thermal Processing',
  PostProcessing: 'Post-Processing',
  QualityControl: 'Quality Control',
  Shipped: 'Shipped',
  Rejected: 'Rejected',
  Canceled: 'Canceled',
};

export const TRACKER_STATUSES = [
  DEAL_STATUSES.Created,
  DEAL_STATUSES.ReleasedToFloor,
  DEAL_STATUSES.Printing,
  DEAL_STATUSES.ThermalProcessing,
  DEAL_STATUSES.PostProcessing,
  DEAL_STATUSES.QualityControl,
  DEAL_STATUSES.Shipped,
];

export const DEAL_LABELS = {
  [DEAL_STATUSES.Created]: 'Submitted',
  [DEAL_STATUSES.PostProcessing]: 'Post Production',
  [DEAL_STATUSES.Deleted]: 'Canceled',
  [DEAL_STATUSES.Rejected]: 'Canceled',
};

export const getDealStatusLabel = status => {
  return DEAL_LABELS[status] ? DEAL_LABELS[status] : status;
};
