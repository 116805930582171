import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { FormField } from 'fr-shared/components';
import { MATERIAL_CONVERSIONS, getMaterialName } from 'fr-shared/lib/work_orders';

const MaterialAmountFormField = ({ showGrams, ...props }) => {
  const formik = useFormikContext();
  const materialName = getMaterialName(formik.values);
  const materialAmountInGrams =
    formik.values.material_amount * (MATERIAL_CONVERSIONS[materialName] || 1);

  return (
    <div>
      <FormField
        className="d-md-flex"
        name="material_amount"
        label="Material Amount (ml)"
        append="ml"
        {...props}
      />
      {showGrams && (
        <div className="row form-group d-md-flex my-2">
          <div className="col-md-4">
            <label htmlFor="material_amount">Material Amount (g)</label>
          </div>
          <div className="col-md">
            <div className="input-group">
              <div>{materialAmountInGrams}</div>
              <div className="input-group-append">
                <span className="input-group-text">grams ({materialName})</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

MaterialAmountFormField.propTypes = {
  showGrams: PropTypes.bool,
};

export default MaterialAmountFormField;
