import PropTypes from 'prop-types';
import React, { memo, useContext } from 'react';

import { TableBody, TableCustom, TableHead, TableHeadCell } from 'portal/components';
import { OrdersContext } from 'portal/contexts/OrdersContext';

import ListViewRow from './ListViewRow';

const ListView = ({ orders, onSort }) => {
  const { currentSortParam, isSortDesc } = useContext(OrdersContext);

  return (
    <TableCustom>
      <TableHead>
        <TableHeadCell
          onClick={onSort}
          isSortDesc={isSortDesc}
          sorted={currentSortParam === 'public_id'}
          value="Order #"
        />
        <TableHeadCell
          onClick={onSort}
          isSortDesc={isSortDesc}
          sorted={currentSortParam === 'state'}
          value="Status"
        />
        <TableHeadCell
          onClick={onSort}
          isSortDesc={isSortDesc}
          sorted={currentSortParam === 'customer_po'}
          value="PO #"
        />
        <TableHeadCell
          onClick={onSort}
          isSortDesc={isSortDesc}
          sorted={currentSortParam === 'inserted_at'}
          value="Date ordered"
        />
        <TableHeadCell value="Ship date" />
        <TableHeadCell value="Items" />
        <TableHeadCell value="Total cost" align="right" />
        <TableHeadCell value="" />
      </TableHead>
      <TableBody>
        {orders.map(order => (
          <ListViewRow key={order.id} order={order} />
        ))}
      </TableBody>
    </TableCustom>
  );
};

ListView.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSort: PropTypes.func,
};

export default memo(ListView);
