import { FastField, Field } from 'formik';
import { get, isString } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

import { Button, Icon, classNames } from 'fr-shared/components';
import { renderWithLineBreaks } from 'fr-shared/utils';

import FormLink from './FormLink';

const FormField = ({
  advice,
  append,
  autoComplete,
  children,
  className,
  component: Component,
  dataTestId,
  deleteTestId,
  disabled,
  fastField,
  inputClassName,
  inputGroupClassName,
  inputProps,
  inputStyle,
  label,
  labelClassName,
  labelWrap = false,
  floatingLabel,
  helpText,
  mask,
  md,
  name,
  onBlur,
  onChange,
  onDelete,
  placeholder,
  prepend,
  readonly,
  required,
  showLabel,
  showError,
  showErrorMsg = true,
  onlyShowErrorIfTouched,
  stacked,
  style,
  type,
}) => {
  const FieldComponent = fastField ? FastField : Field;

  return (
    <FieldComponent name={name}>
      {({ field, form, meta }) => {
        const isTouched = get(form.touched, name);
        const isInvalid = showError && get(form.errors, name);

        const hasError = onlyShowErrorIfTouched ? isTouched && isInvalid : isInvalid;
        const errorText = get(form.errors, name);

        const wrapperClasses = classNames([
          'row',
          'form-group',
          hasError && 'is-invalid',
          floatingLabel && 'is-floating',
          readonly && 'is-readonly',
          Component === 'select' && 'is-select',
          className,
        ]);

        const inputClasses = classNames([
          'form-control',
          hasError && 'is-invalid',
          !showErrorMsg && 'is-invalid-no-msg',
          inputClassName,
        ]);

        const value = field.value === undefined || field.value === null ? '' : field.value;
        const values = form.values;

        const handleBlur = e => {
          if (value.trim) {
            form.setFieldValue(name, value.trim());
          }
          field.onBlur(e);
          onBlur && onBlur(e, form, meta);
        };

        if (mask) Component = MaskedInput;

        let inputComponent = (
          <Component
            {...field}
            {...inputProps}
            id={name}
            autoComplete={autoComplete}
            className={inputClasses}
            data-testid={dataTestId || name}
            disabled={disabled}
            mask={mask}
            onBlur={handleBlur}
            onChange={onChange || field.onChange}
            placeholder={placeholder}
            required={floatingLabel}
            style={inputStyle}
            type={type}
            value={value}
          >
            {children}
          </Component>
        );

        const textValue = get(values, readonly) || get(values, name);
        if (values && readonly) {
          if (isString(textValue) && textValue.includes('http')) {
            inputComponent = (
              <strong>
                <FormLink textValue={textValue} />
              </strong>
            );
          } else {
            inputComponent = (
              <>
                <div
                  aria-readonly="true"
                  className="text-break"
                  data-testid={dataTestId || name}
                  id={name}
                >
                  <strong>{renderWithLineBreaks(textValue)}</strong>
                </div>
                {type === 'hidden' ? <input type={type} name={name} value={value} /> : null}
              </>
            );
          }
        }

        const labelComponent = showLabel && (
          <label
            htmlFor={name}
            className={classNames([
              labelWrap ? '' : 'whitespace-nowrap',
              stacked ? 'mb-1' : '',
              labelClassName,
            ])}
          >
            {label}{' '}
            {required && !readonly && !floatingLabel && <span className="text-error">*</span>}
          </label>
        );

        const decoratedClassNames = { readonly: readonly, hidden: !value && readonly };

        const decoratedComponent = (
          <div className={classNames(['input-group', inputGroupClassName])}>
            {prepend && (
              <div className={classNames(['input-group-prepend', decoratedClassNames])}>
                <span className="input-group-text">{prepend}</span>
              </div>
            )}
            {inputComponent}
            {append && (
              <div className={classNames(['input-group-append', decoratedClassNames])}>
                {typeof append === 'function' ? (
                  <span className="input-group-text">{append(textValue)}</span>
                ) : (
                  <span className="input-group-text">{append}</span>
                )}
              </div>
            )}
            {onDelete && !readonly && (
              <Button
                dataTestId={deleteTestId}
                className="ml-2"
                onClick={onDelete}
                color="danger"
                outline={true}
              >
                <Icon name="times" />
              </Button>
            )}
          </div>
        );

        const component = prepend || append || onDelete ? decoratedComponent : inputComponent;

        return (
          <div className={wrapperClasses} style={style}>
            {floatingLabel ? (
              <div className="col-md">
                {readonly || Component === 'select' ? (
                  <>
                    {labelComponent}
                    {component}
                  </>
                ) : (
                  <>
                    {component}
                    {labelComponent}
                  </>
                )}
                {hasError && (
                  <div className="form-control-error" data-testid={`errors-${name}`}>
                    {errorText}
                  </div>
                )}
                {helpText && !readonly && (
                  <div className="font-italic text-gray pt-1 font-size-sm">{helpText}</div>
                )}
                {advice}
              </div>
            ) : stacked ? (
              <div className="col-md">
                {labelComponent}
                {component}
                {hasError && (
                  <div className="form-control-error" data-testid={`errors-${name}`}>
                    {errorText}
                  </div>
                )}
                {helpText && !readonly && (
                  <div className="font-italic text-gray pt-1 font-size-sm">{helpText}</div>
                )}
                {advice}
              </div>
            ) : (
              <>
                {showLabel && label && (
                  <div className={`col-md-${md ? md : 4}`}>{labelComponent}</div>
                )}
                <div className="col-md">
                  {component}
                  {hasError && showErrorMsg && (
                    <div className="form-control-error" data-testid={`errors-${name}`}>
                      {errorText}
                    </div>
                  )}
                  {helpText && !readonly && (
                    <div className="font-italic text-gray pt-1 font-size-sm">{helpText}</div>
                  )}
                  {advice}
                </div>
              </>
            )}
          </div>
        );
      }}
    </FieldComponent>
  );
};

FormField.defaultProps = {
  component: 'input',
  showLabel: true,
  showError: true,
  onlyShowErrorIfTouched: false,
};

FormField.propTypes = {
  advice: PropTypes.node,
  autoComplete: PropTypes.string,
  append: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.func,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.string,
  dataTestId: PropTypes.string,
  deleteTestId: PropTypes.string,
  disabled: PropTypes.bool,
  fastField: PropTypes.bool,
  floatingLabel: PropTypes.bool,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  inputClassName: PropTypes.string,
  inputGroupClassName: PropTypes.string,
  inputProps: PropTypes.object,
  inputStyle: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
  ]),
  labelClassName: PropTypes.string,
  labelWrap: PropTypes.bool,
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  mask: PropTypes.oneOfType([PropTypes.bool, PropTypes.array, PropTypes.func]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  placeholder: PropTypes.string,
  prepend: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  readonly: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  showError: PropTypes.bool,
  showErrorMsg: PropTypes.bool,
  onlyShowErrorIfTouched: PropTypes.bool,
  stacked: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.string,
};

export default FormField;
