import { Form, Formik } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Breadcrumb, Button, FormField, FormNumber, Helmet } from 'fr-shared/components';
import { AlertContext, UserContext } from 'fr-shared/context';

const MarginControlEdit = ({ history, location, match }) => {
  const { user } = useContext(UserContext);
  const { setAlert } = useContext(AlertContext);

  const [costMargin, setCostMargin] = useState(null);

  useEffect(() => {
    api.get(`/cost_margins/${match?.params.id}`).then(res => {
      setCostMargin(res.data);
    });
  }, [match]);

  const handleSubmit = (values, actions) => {
    api
      .put(`/cost_margins/${costMargin?.manufacturing_process?.id}`, { cost_margin: values })
      .then(res => {
        setAlert({
          message:
            'Successfully set the margin percentage for ' + res.data.manufacturing_process.name,
          color: 'success',
        });

        history.push('/admin/margin_control');
      })
      .catch(err => {
        if (err.response?.data) {
          const errors = err.response.data.errors;
          const messages = err.response.data.messages;
          actions.setErrors({
            server: messages,
            ...errors,
          });
        }

        setAlert({
          message: 'Sorry, we were unable to update this margin percentage.',
          color: 'danger',
        });
      });
  };

  if (!user.canEditMargins) {
    setAlert({
      message: 'Sorry, you do not have permission to change margins.',
      color: 'danger',
    });
    return <Redirect to="/admin/margin_control" />;
  }

  const initialValues = get(location, 'state', {
    manufacturing_process: costMargin?.manufacturing_process,
    margin_percentage: costMargin?.margin_percentage,
  });

  return (
    <>
      <Helmet title="Margin Control" />
      <Breadcrumb to="/admin/margin_control">Margin Control</Breadcrumb>
      <Breadcrumb to="/admin/margin_control/edit">Edit Margin Control</Breadcrumb>
      <div className="container">
        <div className="flex align-items-center py-3">
          <h2 className="mb-0">Edit Margin Control</h2>
          <div className="ml-auto">
            <Button color="secondary" onClick={e => history.goBack(e)}>
              Back
            </Button>
          </div>
        </div>
        <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleSubmit}>
          <Form>
            <div className="row">
              <div className="col">
                <FormField
                  label="Manufacturing Process"
                  name="manufacturing_process.name"
                  readonly
                />
              </div>
              <div className="col">
                <FormNumber step={0.0001} label="Margin Percentage" name="margin_percentage" />
              </div>
            </div>
            <div>
              <div className="mt-4">
                <Button type="submit" color="success">
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

MarginControlEdit.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export default MarginControlEdit;
