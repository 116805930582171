import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { ModalStickyHeader } from 'fr-shared/components';

import { Button, Modal, Page } from 'portal/components';
import QuoteDetailsBody from 'portal/pages/quotes/components/QuoteDetailsBody';
import QuoteDetailsHeader from 'portal/pages/quotes/components/QuoteDetailsHeader';
import QuoteShippingAndBilling from 'portal/pages/quotes/components/QuoteShippingAndBilling';

import styles from './PreviewCustomerQuoteModal.module.css';

interface PreviewCustomerQuoteModalProps {
  quoteId: number;
  showPreviewButton: boolean;
  onClose: () => void;
}

export default function PreviewCustomerQuoteModal({
  quoteId,
  showPreviewButton = true,
  onClose = () => {},
}: PreviewCustomerQuoteModalProps): JSX.Element {
  const [portalQuote, setPortalQuote] = useState(null);
  const [isOpen, setIsOpen] = useState(!showPreviewButton);
  const history = useHistory();

  const toggle = () => {
    if (isOpen) onClose();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      api.get(`/quotes/${quoteId}/customer_portal_quote`).then(res => {
        setPortalQuote(res.data?.quote_details?.data);
      });
    }
  }, [isOpen, quoteId]);

  return (
    <>
      {showPreviewButton && (
        <Button
          className="ml-2"
          color="primary"
          size="sm"
          dataTestId="quote-portal-preview-button"
          onClick={toggle}
        >
          Preview Portal Quote
        </Button>
      )}
      {isOpen && (
        <Modal fullscreen={true} isOpen={isOpen} toggle={toggle}>
          <ModalStickyHeader
            header={'Quote - Portal view'}
            handleClose={() => {
              toggle();
              setIsOpen(false);
            }}
            onClickPrimaryCta={() => {
              let regex = /\/admin\/quotes\/[0-9]+/i;
              if (history.location.pathname.match(regex)) {
                toggle();
                setIsOpen(false);
              } else {
                history.push(`/admin/quotes/${portalQuote.id}`);
              }
            }}
            onClickSecondaryCta={() => {
              toggle();
              setIsOpen(false);
            }}
            primaryCtaText={'View FROS Quote'}
            secondaryCtaText="Cancel"
          />
          <div className="theme-dark">
            <div className={styles.Root}>
              <Page>
                {portalQuote && (
                  <div className="container py-3">
                    <QuoteDetailsHeader
                      quote={portalQuote}
                      readonly={true}
                      onReorderClicked={() => {}}
                    />
                    <QuoteDetailsBody quote={portalQuote} readonly={true} />
                    {portalQuote.state === 'Ordered' && (
                      <QuoteShippingAndBilling quote={portalQuote} />
                    )}
                  </div>
                )}
              </Page>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
