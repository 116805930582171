import { COSTING_REQUEST_LI_STATES } from 'fr-shared/lib/costing_requests';

export const convertLineItemsFromFormik = (formik: any, defaultState: string) => {
  return formik.values.line_items.map((li: any) => {
    const completed = COSTING_REQUEST_LI_STATES.Completed;

    const state = li.state === completed ? completed : defaultState;

    return { ...li, state };
  });
};
