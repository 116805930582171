import axios from 'axios';
import React from 'react';

import { api } from 'fr-shared/api';
import { getExtension, isAllowed, sanitizeFilename } from 'fr-shared/utils/files';

const s3Reducer = (state, action) => {
  switch (action.type) {
    case 'LOADING': {
      return { ...state, loading: true, data: null, error: null };
    }
    case 'LOADED': {
      return { ...state, loading: false, data: action.data, error: null };
    }
    case 'ERROR': {
      return { ...state, loading: false, data: null, error: action.error };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const useS3Upload = () => {
  const [file, dispatch] = React.useReducer(s3Reducer, {
    data: null,
    loading: false,
    error: null,
    file: null,
  });

  const uploadToS3 = (endpoint, file) => {
    dispatch({ type: 'LOADING', file: file });

    if (!isAllowed(file.name)) {
      dispatch({ type: 'ERROR', file: file, error: 'blacklisted file type for upload' });
    } else {
      const ext = getExtension(file.name);

      api
        .post(endpoint, { filename: sanitizeFilename(file.name) })
        .then(res => {
          const { presigned_url, path } = res.data;
          return axios
            .put(presigned_url, file, {
              headers: { 'content-type': file.type },
            })
            .then(() => {
              dispatch({ type: 'LOADED', data: { s3_path: path, ext } });
            });
        })
        .catch(error => {
          dispatch({ type: 'ERROR', error });
        });
    }
  };

  return [file, uploadToS3];
};

export default useS3Upload;
