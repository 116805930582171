import { Form, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  OWNER_FILTER_OPTIONS,
  SORT_OPTIONS,
  SOURCE_FILTER_OPTIONS,
} from 'src/views/sales_portal/lib/quotes';

import {
  Button,
  IconFont,
  Modal,
  ProgressCircle,
  ToggleSwitch,
  classNames,
} from 'fr-shared/components';

import FilterCheckBoxes from '../FilterCheckBoxes';
import styles from './FilterModal.module.css';

interface FilterModalContainerProps {
  isOpen: boolean;
  loading: boolean;
  toggle: () => void;
  resetFilters: () => void;
  setFilters: (filters: any) => void;
  filters: any;
  salesContactId: number;
  filterCount: number;
}

const ownerFromBool = new Map([
  [true, OWNER_FILTER_OPTIONS.You],
  [false, OWNER_FILTER_OPTIONS.Anyone],
]);

const sourceFromBool = new Map([
  [true, SOURCE_FILTER_OPTIONS.All],
  [false, SOURCE_FILTER_OPTIONS.Customer],
]);

const getOwnerFromFilters = (filters: any) => {
  return filters.sales_contact_id !== null
    ? OWNER_FILTER_OPTIONS.You
    : OWNER_FILTER_OPTIONS.Anyone;
};

const getSourceFromFilters = (filters: any) => {
  return filters.source !== null ? SOURCE_FILTER_OPTIONS.Customer : SOURCE_FILTER_OPTIONS.All;
};

const FilterModalContainer = ({
  isOpen,
  loading,
  toggle,
  resetFilters,
  setFilters,
  filters,
  salesContactId,
  filterCount,
}: FilterModalContainerProps): any => {
  const [owner, setOwner] = useState(getOwnerFromFilters(filters));
  const [source, setSource] = useState(getSourceFromFilters(filters));
  const [showSort, setShowSort] = useState(false);
  const [sortValue, setSortValue] = useState(filters.sort_key);

  useEffect(() => {
    setOwner(getOwnerFromFilters(filters));
    setSource(getSourceFromFilters(filters));
    setSortValue(filters.sort_key);
  }, [filters]);

  const closeModal = () => {
    setShowSort(false);
    toggle();
  };

  const handleOwnerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOwner = ownerFromBool.get(e.currentTarget.checked);
    setOwner(newOwner);
    setFilters({
      ...filters,
      page: 1,
      sales_contact_id: newOwner === OWNER_FILTER_OPTIONS.You ? salesContactId : null,
    });
  };

  const handleSourceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSource = sourceFromBool.get(e.currentTarget.checked);
    setSource(newSource);
    setFilters({
      ...filters,
      page: 1,
      source: newSource === SOURCE_FILTER_OPTIONS.All ? null : 'customer',
    });
  };

  const handleSortChange = (selectedSortOption: string) => {
    setSortValue(selectedSortOption);
    setFilters({
      ...filters,
      page: 1,
      sort_key: selectedSortOption,
    });
  };

  const findSortLabel = (sortKey: string) => {
    return SORT_OPTIONS.find(sortOption => sortOption.value === sortKey).label;
  };

  return (
    <div>
      <FilterModal
        closeModal={closeModal}
        handleOwnerChange={handleOwnerChange}
        handleSourceChange={handleSourceChange}
        isOpen={isOpen}
        filters={filters}
        filterCount={filterCount}
        findSortLabel={findSortLabel}
        loading={loading}
        owner={owner}
        setFilters={setFilters}
        setShowSort={setShowSort}
        resetFilters={resetFilters}
        source={source}
        toggle={toggle}
      />
      <SortModal
        closeModal={closeModal}
        filterCount={filterCount}
        handleSortChange={handleSortChange}
        loading={loading}
        resetFilters={resetFilters}
        setShowSort={setShowSort}
        showSort={showSort}
        sortValue={sortValue}
        toggle={toggle}
      />
    </div>
  );
};

const defaultValues = {
  filter_all: true,
  filter_draft: true,
  filter_submitted: true,
  filter_closed: true,
  filter_converted: true,
};

const getInitialValues = (props: any) => ({
  ...defaultValues,
  ...props.initialValues,
});

export default withFormik({
  mapPropsToValues: getInitialValues,
  enableReinitialize: true,
  handleSubmit: (values, actions) => {
    actions.props.onSubmit && actions.props.onSubmit(values, actions);
  },
  validateOnBlur: false,
  validateOnChange: false,
})(FilterModalContainer);

interface FilterModalProps {
  closeModal: () => void;
  filters: any;
  filterCount: number;
  findSortLabel: (sortKey: string) => string;
  handleOwnerChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSourceChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  isOpen: boolean;
  owner: string;
  resetFilters: () => void;
  setShowSort: (state: boolean) => void;
  setFilters: (filters: any) => void;
  source: string;
  toggle: () => void;
}

const FilterModal = ({
  closeModal,
  filters,
  filterCount,
  findSortLabel,
  handleOwnerChange,
  handleSourceChange,
  isOpen,
  loading,
  owner,
  resetFilters,
  setFilters,
  setShowSort,
  source,
  toggle,
}: FilterModalProps) => {
  return (
    <Modal
      centered={false}
      isOpen={isOpen}
      isRounded={false}
      onCloseHandler={closeModal}
      rightSidebar
      scrollable
      toggle={toggle}
      closeTimeoutMS={500}
      className={isOpen ? styles.Sidebar : styles.HiddenSidebar}
    >
      <Modal.Header
        className={classNames(['mx-4 mt-4 pt-0 pb-0 px-0', styles.Divider])}
        onClose={closeModal}
        textUppercase={false}
      >
        <div className={classNames(['flex mr-2 pb-1.5', styles.Divider])}>
          <h3>Filter & sort</h3>
        </div>
      </Modal.Header>
      <Modal.Body className={classNames(['mx-4 px-0', styles.Divider])}>
        <Form>
          <div className={classNames(['pt-2 pb-2', styles.Divider])}>
            <div className="flex align-items-center justify-between">
              <div className="text-coolGray-100">Sort</div>
              <Button
                className="flex align-items-center flex-row text-decoration-none"
                color="link"
                onClick={() => setShowSort(true)}
              >
                <div className="text-coolGray-300">{findSortLabel(filters.sort_key)}&nbsp;</div>
                <IconFont className="font-size-xl ml-2 text-coolGray-100" name="chevron-right" />
              </Button>
            </div>
          </div>
          <div className={classNames(['py-3', styles.Divider])}>
            <div className="flex flex-column mb-2">
              <div className="mb-1 text-coolGray-300">Owner</div>
              <ToggleSwitch
                className={styles.ToggleSwitch}
                id="quote-owner"
                checked={owner === ownerFromBool.get(true)}
                onCheck={handleOwnerChange}
                defaultTrueLabel={OWNER_FILTER_OPTIONS.You}
                defaultFalseLabel={OWNER_FILTER_OPTIONS.Anyone}
              />
            </div>
            <div className="flex flex-column">
              <div className="mb-1 text-coolGray-300">Source</div>
              <ToggleSwitch
                className={styles.ToggleSwitch}
                id="quote-source"
                checked={source === sourceFromBool.get(true)}
                onCheck={handleSourceChange}
                defaultTrueLabel={SOURCE_FILTER_OPTIONS.All}
                defaultFalseLabel={SOURCE_FILTER_OPTIONS.Customer}
              />
            </div>
          </div>
          <div className="my-3">
            <div className="mb-2 text-coolGray-300">Quote status</div>
            <FilterCheckBoxes setFilters={setFilters} filters={filters} />
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="h-[95px] pt-0">
        <div>
          {loading && (
            <ProgressCircle
              className={styles.ProgressCircle}
              percentage={75}
              spinner={true}
              backgroundColor="#191e25"
              fadesIntoBackground
            />
          )}
        </div>
        <div className="flex justify-end align-items-center w-100">
          <Button
            onClick={resetFilters}
            color="link"
            className={
              filterCount === 0
                ? 'text-decoration-none text-coolGray-300 outline-none ml-auto'
                : 'text-decoration-none text-white outline-none ml-auto'
            }
          >
            <IconFont
              className={
                filterCount === 0
                  ? 'font-size-xl mr-0 text-coolGray-300'
                  : 'font-size-xl mr-0 text-white'
              }
              name="reset"
            />
            &nbsp;&nbsp;Reset
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

interface SortModalProps {
  closeModal: () => void;
  filterCount: number;
  handleSortChange: (selectedSortOption: string) => void;
  loading: boolean;
  resetFilters: () => void;
  setShowSort: (state: boolean) => void;
  showSort: boolean;
  sortValue: any;
  toggle: () => void;
}

const SortModal = ({
  closeModal,
  filterCount,
  handleSortChange,
  loading,
  resetFilters,
  setShowSort,
  showSort,
  sortValue,
  toggle,
}: SortModalProps) => {
  return (
    <Modal
      centered={false}
      isOpen={showSort}
      isRounded={false}
      onCloseHandler={closeModal}
      rightSidebar
      scrollable
      toggle={toggle}
      closeTimeoutMS={500}
      className={showSort ? styles.Sidebar : styles.HiddenSidebar}
    >
      <Modal.Header
        className={classNames(['mx-4 mt-4 pt-0 pb-0 px-0', styles.Divider])}
        onClose={closeModal}
        textUppercase={false}
      >
        <div className={classNames(['flex mr-2 pb-1.5', styles.Divider])}>
          <Button
            onClick={() => {
              setShowSort(false);
            }}
            color="link"
            className="text-decoration-none text-coolGray-300 outline-none"
          >
            <IconFont className="font-size-xl mr-1 text-coolGray-100" name="chevron-left" />
          </Button>

          <h3>Sort</h3>
        </div>
      </Modal.Header>
      <Modal.Body className={classNames(['mx-4 px-0', styles.Divider])}>
        <div className="flex flex-column">
          {SORT_OPTIONS.map(sortOption => {
            return (
              <div
                className={classNames([
                  'custom-control custom-radio py-2 mr-2 flex',
                  styles.Divider,
                ])}
                key={sortOption.value}
              >
                <input
                  id={sortOption.value}
                  type="radio"
                  className="custom-control-input"
                  checked={sortValue === sortOption.value}
                  onChange={() => handleSortChange(sortOption.value)}
                ></input>
                <label
                  htmlFor={sortOption.value}
                  className="flex custom-control-label font-weight-normal ml-1"
                >
                  <div className="align-self-center ml-1 text-coolGray-100">
                    {sortOption.label}
                  </div>
                </label>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className="h-[95px]">
        <div>
          {loading && (
            <ProgressCircle
              className={styles.ProgressCircle}
              percentage={75}
              spinner={true}
              backgroundColor="#191e25"
              fadesIntoBackground
            />
          )}
        </div>
        <div className="flex justify-end align-items-center w-100">
          <Button
            onClick={resetFilters}
            color="link"
            className={
              filterCount === 0
                ? 'text-decoration-none text-coolGray-300 outline-none ml-auto'
                : 'text-decoration-none text-white outline-none ml-auto'
            }
          >
            <IconFont
              className={
                filterCount === 0
                  ? 'font-size-xl mr-0 text-coolGray-300'
                  : 'font-size-xl mr-0 text-white'
              }
              name="reset"
            />
            &nbsp;&nbsp;Reset
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
