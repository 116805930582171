import { useFormikContext } from 'formik';
import { get, set } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'fr-shared/components';
import { COUNTRIES } from 'fr-shared/lib/countries';
import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';
import { SHIPPING_PAYMENT_TYPES } from 'fr-shared/lib/shipping';
import { randomNumber, randomString } from 'fr-shared/utils';

export const formDataFor = formType => {
  switch (formType) {
    case 'COSTING_REQUEST':
      return {
        box_file_location: `https://fastradius.box.com/s/g0wkh1jiqcbbjxm4zycbsk1hypyp${randomNumber()}`,
        customer: { id: '1', name: 'Fast Radius' },
        customer_contact_id: 1,
        customer_id: 1,
        name: 'test-user-0',
        opportunity_id: `00000001212${randomNumber()}`,
        public_id: `${randomNumber()}`,
      };
    case 'COSTING_REQUEST_LINE_ITEM':
      return {
        build_package: {
          processes: [
            {
              inspection_type_id: '15',
              inspection_type: { id: '15', name: 'Visual Check' },
              manufacturing_process: { id: '4', name: MANUFACTURING_PROCESSES.DLS },
              manufacturing_process_id: '4',
              material: { id: '41', name: 'EPU 40' },
              material_id: '41',
              type: MANUFACTURING_PROCESSES.DLS,
            },
          ],
        },
        inspection_type_id: '15',
        inspection_type: { id: '15', name: 'Visual Check' },
        manufacturing_process: { id: '4', name: MANUFACTURING_PROCESSES.DLS },
        manufacturing_process_id: '4',
        material: { id: '41', name: 'EPU 40' },
        material_id: '41',
        supplier_costs: [
          { part_quantities: [{ quantity: 2, per_unit_cost: { amount: '100.00' } }] },
        ],
      };
    case 'ORDER':
      return {
        billing_address: {
          name_line_1: 'Lou Rassey',
          street_line_1: '113 May St',
          street_line_2: 'Suite 1',
          city: 'Chicago',
          state: 'IL',
          country: COUNTRIES.UNITED_STATES,
          postal_code: '60607',
          email: 'test@example.com',
        },
        box_file_location: 'https://fastradius.com/box.com/s/1234',
        certificate_of_conformance: true,
        comments: 'Order comments',
        customer: { name: 'Fast Radius', id: 1 },
        customer_contact_id: '1',
        customer_id: 1,
        customer_po: `PO 8OOGL`,
        finishing_certificaton_required: true,
        first_article_inspection: true,
        isOpen: true,
        line_items: [
          {
            box_file_url: 'https://fastradius.com/box.com/s/1234',
            build_quantity_optimal: 5,
            comments: 'Testing comments',
            description: `Part XY${randomString()}`,
            documents: [],
            finishing: 'Standard',
            inspection_type_id: '12',
            isOpen: true,
            units: 'mm',
            location_id: 1,
            manufacturing_process_id: '4',
            material_id: '41',
            planned_shipment_line_items: [
              {
                quantity: 5,
                remaining_to_fulfill: 5,
                already_fulfilled: 0,
                commit_date: moment().add(5, 'day').toISOString(),
                promised_ship_date: moment().add(6, 'day').toISOString(),
                supplier_promised_ship_date: moment().add(7, 'day').toISOString(),
                valid: true,
              },
            ],
            promised_ship_date: moment().add(5, 'day').toISOString(),
            quantity: 5,
            sales_price: { amount: '12.99' },
            shipped_to_customer_from: 'FR Chicago',
            shipping_charge: { amount: '3.50' },
            shipping_tracking_number: '1ZF89A520108547424',
            unit_cost: { amount: '8.50' },
          },
        ],
        manufacturing_process_id: '4',
        material_certification_required: false,
        material_id: '41',
        payment_terms: 'Net 30',
        payment_type: 'Customer PO',
        public_id: `${randomNumber()}`,
        shipping_address: {
          name_line_1: 'Lou Rassey',
          street_line_1: '113 May St',
          street_line_2: 'Suite 1',
          city: 'Chicago',
          state: 'IL',
          country: COUNTRIES.UNITED_STATES,
          postal_code: '60607',
          phone_number: '555-555-5555',
        },
        shipping_payment_type: SHIPPING_PAYMENT_TYPES.InvoiceToCustomer,
        supplier_po: `SUPP-PO FOB8A`,
        tax_rate: 1.5,
        type: 'Personal',
      };
    case 'QUOTE_TO_ORDER':
      return {
        billing_address: {
          name_line_1: 'Lou Rassey',
          street_line_1: '113 May St',
          street_line_2: 'Suite 1',
          city: 'Chicago',
          state: 'IL',
          country: COUNTRIES.UNITED_STATES,
          postal_code: '60607',
          email: 'test@example.com',
        },
        comments: 'Order comments',
        customer_po: `PO 8OOGL`,
        payment_terms: 'Net 30',
        payment_type: 'Customer PO',
        shipping_address: {
          name_line_1: 'Lou Rassey',
          street_line_1: '113 May St',
          street_line_2: 'Suite 1',
          city: 'Chicago',
          state: 'IL',
          country: COUNTRIES.UNITED_STATES,
          postal_code: '60607',
          phone_number: '555-555-5555',
        },
        shipping_method: 'DHL',
        shipping_payment_type: SHIPPING_PAYMENT_TYPES.InvoiceToCustomer,
        type: 'Sample',
      };
  }
};

const FormFillDevButton = ({ form, pathPrefix }) => {
  const { values, setValues } = useFormikContext();

  const handleFillForm = () => {
    const generatedData = formDataFor(form);
    const newValues = pathPrefix
      ? set(values, pathPrefix, { ...get(values, pathPrefix), ...generatedData })
      : { ...values, ...generatedData };

    setValues(newValues);
  };

  if (process.env.MIX_ENV === 'prod') return null;

  return (
    <Button
      color="danger"
      size="sm"
      onClick={handleFillForm}
      id="test-fill-form"
      dataTestId="dev-fill-form-button"
    >
      <small>DEV</small> Fill Form
    </Button>
  );
};

FormFillDevButton.propTypes = {
  form: PropTypes.string,
  pathPrefix: PropTypes.string,
};

export default FormFillDevButton;
