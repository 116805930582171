import React from 'react';

import { PartFileRevision } from 'portal/lib/cart';

const DfmViewerDetails = ({ partFileRevision }: { partFileRevision: PartFileRevision }) => {
  return (
    <div className="absolute text-light mx-3 my-3 flex flex-row">
      <div className="flex flex-column align-items-start pr-3 mr-5">
        <span className="font-size-sm text-coolGray-300 mr-2">File name</span>
        <p data-testid="part-form-viewer-file-name" className="font-size-md text-light">
          {partFileRevision.part_name}
        </p>
      </div>

      {partFileRevision.max_x_length && (
        <div className="flex flex-column align-items-start pr-3 mr-5">
          <span className="font-size-sm text-coolGray-300 mr-2">Dimensions</span>
          <p className="font-size-md text-light">
            {partFileRevision.max_x_length} x {partFileRevision.max_y_length} x{' '}
            {partFileRevision.max_z_length} {partFileRevision.part_units}
          </p>
        </div>
      )}

      {partFileRevision.volume && (
        <div className="flex flex-column align-items-start pr-3 mr-5">
          <span className="font-size-sm text-coolGray-300 mr-2">Volume</span>
          <p className="font-size-md text-light">
            {partFileRevision.volume} {partFileRevision.part_units}³
          </p>
        </div>
      )}

      <div className="flex flex-column align-items-start">
        <span className="font-size-sm text-coolGray-300 mr-2">File units</span>
        <p className="font-size-md text-light">{partFileRevision.part_units}</p>
      </div>
    </div>
  );
};

export default DfmViewerDetails;
