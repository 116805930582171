import { useFormikContext } from 'formik';
import { get, isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { Button, Modal } from 'fr-shared/components';
import { AlertContext, UserContext } from 'fr-shared/context';
import { ORDER_LINE_ITEM_STATES } from 'fr-shared/lib/orders';

const hasAnyQuantityChanged = (values, initialValues) => {
  return values.line_items.some(sli => {
    const initial_sli = initialValues.line_items.find(
      initial_sli => initial_sli.order_line_item_id === sli.order_line_item_id
    );

    return initial_sli ? initial_sli.quantity !== sli.quantity : true;
  });
};

const hasSelectionChanged = (values, initialValues) => {
  const initial = initialValues.line_items.map(sli => sli?.order_line_item_id);
  const current = values.line_items.map(sli => sli?.order_line_item_id);
  return !isEqual(initial.sort(), current.sort());
};

const SubmitShipmentButton = ({ onUpdate, isEditing, isLoading }) => {
  const formik = useFormikContext();
  const { user } = useContext(UserContext);
  const { setAlert } = useContext(AlertContext);
  const handleSubmit = toggle => {
    validateAndUpdate();
    toggle();
  };

  const showSaveAndAlertCustomer = user.canSendOrderAlerts;

  const handleSendOrderShipped = () => {
    formik.setFieldValue('send_order_shipped', true);
  };

  const [totalPSLIFCount, setTotalPSLIFCount] = useState(0);

  const isDirty =
    hasAnyQuantityChanged(formik.values, formik.initialValues) ||
    hasSelectionChanged(formik.values, formik.initialValues);

  const validateAndUpdate = () => {
    const lineItemsErrors = formik.errors?.line_items || [];
    const hasAnyPSLIFError = lineItemsErrors
      .filter(lineItemError => !isEmpty(lineItemError))
      .reduce((prev, lineItemError) => {
        if (prev) {
          // if a previous line item had errors, ignore processing each subsequent
          // line item for errors.
          return prev;
        }

        // sometimes formik doesn't clear out the key in the errors store, mainly
        // when validation was triggered and then handled by user input and so the
        // values should be checked for non-null to submit or not.
        if (Object.keys(lineItemError).includes('planned_shipment_line_item_fulfillments')) {
          const pslifErrors = lineItemError['planned_shipment_line_item_fulfillments'];

          return !isEmpty(
            pslifErrors.flatMap(pslifError => Object.values(pslifError).filter(val => !!val))
          );
        }

        return false;
      }, false);

    const hasInvalidPSLIF = formik.values.line_items
      .filter(
        li =>
          li.state !== ORDER_LINE_ITEM_STATES.Shipped &&
          li.state !== ORDER_LINE_ITEM_STATES.Canceled
      )
      .reduce((previousIsInvalid, lineItem) => {
        if (previousIsInvalid) {
          return previousIsInvalid;
        }

        if (!Object.keys(lineItem).includes('planned_shipment_line_item_fulfillments')) {
          // if the included line item for some reason don't have a fulfillments key to filter later it's likely invalid.
          return true;
        }

        if (
          lineItem.planned_shipment_line_item_fulfillments.filter(pslif => pslif.selected)
            .length === 0
        ) {
          // if a line item has been added without PSLIs selected to fulfill
          return true;
        }

        if (
          lineItem.planned_shipment_line_item_fulfillments.filter(
            pslif => pslif.selected && pslif.quantity === ''
          ).length > 0
        ) {
          // if a line item was added and the checkbox to fulfill a PSLI and the quantity is empty
          return true;
        }

        return false;
      }, false);

    if (hasAnyPSLIFError || hasInvalidPSLIF) {
      let alertMessage;
      if (hasAnyPSLIFError) {
        alertMessage =
          'There was an error submitting the shipment due to invalid Planned Shipment Line Item Fulfillment quantity.';
      } else {
        alertMessage =
          'There was an error submitting the shipment due to invalid Planned Shipment Line Item Fulfillments. Please ensure each shipment line item has at least one Planned Shipment Line Item selected with quantity filled';
      }
      setAlert({
        color: 'danger',
        message: alertMessage,
      });
    } else {
      const line_items = get(formik.values, 'line_items', []);
      const filteredPSLIFLineItems = line_items.map(li => {
        return {
          ...li,
          planned_shipment_line_item_fulfillments:
            li.planned_shipment_line_item_fulfillments.filter(pslif => pslif.selected),
        };
      });

      formik.setFieldValue('line_items', filteredPSLIFLineItems);
      onUpdate();
    }
  };

  useEffect(() => {
    const lineItems = get(formik.values, 'line_items', []);
    setTotalPSLIFCount(
      lineItems
        .filter(
          li =>
            li.state !== ORDER_LINE_ITEM_STATES.Canceled &&
            li.state !== ORDER_LINE_ITEM_STATES.Shipped
        )
        .reduce((prev, current) => {
          return (
            prev +
            (current.planned_shipment_line_item_fulfillments?.filter(pslif => pslif.selected)
              .length || 0)
          );
        }, 0)
    );
  }, [formik.values]);

  return (
    <>
      {isEditing && isDirty ? (
        <Modal
          action={
            <Button
              dataTestId="shipment-submit-button-modal"
              loading={isLoading}
              color="success"
              disabled={totalPSLIFCount === 0}
            >
              Save Shipment
            </Button>
          }
        >
          {({ toggle }) => (
            <>
              <Modal.Header title="Edit Shipment Quantity" onClose={toggle} />
              <div className="modal-body">
                <p>
                  This action will change the quantity in a shipped line item. This could impact a
                  customer&apos;s order status and the customer&apos;s billing cycle. Please
                  notify <strong>finance@fastradius.com</strong> of the shipment you&apos;re
                  changing and what the change was.
                </p>
                <p>Are you sure you want to continue?</p>
              </div>
              <div className="modal-footer">
                <Button color="link" onClick={toggle}>
                  No, Cancel
                </Button>
                <Button
                  onClick={() => handleSubmit(toggle)}
                  color="primary"
                  className="ml-2"
                  type="submit"
                >
                  Yes, Edit Quantity
                </Button>
              </div>
            </>
          )}
        </Modal>
      ) : (
        <Button
          onClick={validateAndUpdate}
          loading={isLoading}
          color="success"
          dataTestId="shipment-submit-button"
          disabled={totalPSLIFCount === 0}
        >
          Save Shipment
        </Button>
      )}
      {showSaveAndAlertCustomer && (
        <>
          {isEditing && isDirty ? (
            <Modal
              action={
                <Button
                  className="ml-2"
                  dataTestId="shipment-submit-and-alert-button-modal"
                  loading={isLoading}
                  color="success"
                  disabled={totalPSLIFCount === 0}
                >
                  Save Shipment and Alert Customer
                </Button>
              }
            >
              {({ toggle }) => (
                <>
                  <Modal.Header title="Edit Shipment Quantity" onClose={toggle} />
                  <div className="modal-body">
                    <p>
                      This action will change the quantity in a shipped line item. This could
                      impact a customer&apos;s order status and the customer&apos;s billing cycle.
                      Please notify <strong>finance@fastradius.com</strong> of the shipment
                      you&apos;re changing and what the change was.
                    </p>
                    <p>Are you sure you want to continue?</p>
                  </div>
                  <div className="modal-footer">
                    <Button color="link" onClick={toggle}>
                      No, Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        handleSendOrderShipped();
                        handleSubmit(toggle);
                      }}
                      color="primary"
                      className="ml-2"
                      type="submit"
                    >
                      Yes, Edit Quantity
                    </Button>
                  </div>
                </>
              )}
            </Modal>
          ) : (
            <Button
              className="ml-2"
              onClick={() => {
                handleSendOrderShipped();
                validateAndUpdate();
              }}
              loading={isLoading}
              color="success"
              dataTestId="shipment-submit-and-alert-button"
              disabled={totalPSLIFCount === 0}
            >
              Save Shipment and Alert Customer
            </Button>
          )}
        </>
      )}
    </>
  );
};

SubmitShipmentButton.propTypes = {
  onUpdate: PropTypes.func,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default SubmitShipmentButton;
