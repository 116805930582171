import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button, Link, Modal } from 'fr-shared/components';

import { convertDispositionToExplanation } from './utils/reviewTabs';

const PartFailureLines = ({ partFailures }) => {
  return (
    <strong>
      <div className="flex flex-row">
        <div className="flex-column">
          {partFailures.map((partFailure, index) => (
            <div key={index} className="mr-3 mb-2">
              {index + 1}.
            </div>
          ))}
        </div>
        <div className="flex-column mr-auto">
          {partFailures.map((partFailure, index) => (
            <div className="mb-2" key={index}>
              {partFailure.work_order_line_item.order_line_item.description}
            </div>
          ))}
        </div>
        <div className="flex-column">
          {partFailures.map((partFailure, index) => (
            <div key={index} className="mr-3 mb-2">
              {`${partFailure.quantity}/${partFailure.work_order_line_item.quantity}`}
            </div>
          ))}
        </div>
        <div className="flex-column">
          {partFailures.map((partFailure, index) => (
            <div key={index} className="mr-3 mb-2">
              <Link
                to={`/admin/orders/${partFailure.work_order_line_item.order_line_item.order.id}`}
                openNewWindow
                openNewWindowIcon
              >
                {partFailure.work_order_line_item.order_line_item.order.public_id}
              </Link>
            </div>
          ))}
        </div>
        <div className="flex-column ">
          {partFailures.map((partFailure, index) => (
            <div key={index} className="mr-3 mb-2">
              W.O.{' '}
              <Link
                to={`/admin/work_orders/${partFailure.work_order_line_item.work_order_id}`}
                openNewWindow
                openNewWindowIcon
              >
                #{partFailure.work_order_line_item.work_order_id}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </strong>
  );
};

PartFailureLines.propTypes = {
  partFailures: PropTypes.object,
};

const DispositionAssignModal = ({ partFailures, toggle, onSubmit, disposition }) => {
  const [note, setNote] = useState('');

  const handleSubmit = () => {
    toggle();
    onSubmit(note);
  };

  return (
    <>
      <Modal.Header title={`Mark as "${disposition}"`} onClose={toggle}>
        {convertDispositionToExplanation(disposition)}
      </Modal.Header>
      <div className="modal-body">
        <PartFailureLines partFailures={partFailures} />
        <div className="flex flex-row form-group align-items-center">
          <label htmlFor="disposition_notes">Notes </label>
          <input
            id="disposition_notes"
            name="disposition_notes"
            className="form-control ml-2"
            onChange={e => setNote(e.target.value)}
            placeholder="Add Disposition Notes"
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="page-actions">
          <Button color="secondary mr-2" onClick={toggle}>
            Cancel
          </Button>
          <Button color="success" onClick={handleSubmit}>
            Mark as {disposition}
          </Button>
        </div>
      </div>
    </>
  );
};

DispositionAssignModal.propTypes = {
  partFailures: PropTypes.array,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  disposition: PropTypes.string,
};

export default DispositionAssignModal;
