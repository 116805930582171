import { debounce } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';

import { api } from 'fr-shared/api';
import { Breadcrumb, Button, Helmet } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';

import RolesEditForm from './RolesEditForm';

const RolesEdit = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const searchInputRef = useRef();
  const [selectedUser, setSelectedUser] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    Promise.all([api.get('/roles'), api.get('/permissions')]).then(([roles, permissions]) => {
      setRoles(roles.data);
      setPermissions(permissions.data);
    });

    searchInputRef.current?.focus();
  }, []);

  const fetchSelectedUser = () => {
    api.get(`/users/${selectedUser.id}`).then(res => {
      setSelectedUser(res.data);
    });
  };

  const handleUserSearch = debounce((inputValue, callback) => {
    api.get('/users', { params: { email: inputValue } }).then(res => {
      callback(res.data.map(user => ({ label: user.email, value: user })));
    });
  }, 500);

  if (!user.isAdmin) return <Redirect to="/admin" />;

  return (
    <>
      <Helmet title="Role Management" />
      <Breadcrumb to="/admin/roles">Roles</Breadcrumb>
      <div className="page-header">
        <div className="container">
          <div>
            <h2>Role Management</h2>
          </div>
          <div className="page-actions flex align-items-center">
            <Button color="secondary" onClick={e => history.goBack(e)}>
              Back
            </Button>
          </div>
        </div>
        <div className="container mt-3">
          <div className="w-100">
            <AsyncSelect
              loadOptions={handleUserSearch}
              defaultOptions={false}
              cacheOptions={false}
              ref={searchInputRef}
              classNamePrefix="react-select"
              name="email"
              noOptionsMessage={option => (option.inputValue === '' ? null : 'No users found')}
              onChange={result => setSelectedUser(result.value)}
              placeholder="Enter the email of the user"
            />
          </div>
        </div>
      </div>

      {selectedUser && (
        <RolesEditForm
          user={selectedUser}
          roles={roles}
          permissions={permissions}
          onClearUser={() => setSelectedUser(null)}
          onUpdateUser={fetchSelectedUser}
        />
      )}
    </>
  );
};

export default RolesEdit;
