import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'fr-shared/components';

const Loading = ({
  width = 24,
  height = 30,
  duration = '1s',
  color = '#06a7e2',
  type = 'loader',
  className,
  style,
}) => {
  if (type === 'circle') {
    return (
      <Icon
        data-testid="loading"
        name="spinner"
        type="fas"
        className={`${className} fa-pulse`}
        style={{ ...style, fontSize: width, color }}
      />
    );
  }
  return (
    <svg
      data-testid="loading"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${30}`}
      className={className}
      style={style}
      xmlSpace="preserve"
    >
      <rect x="0" y="5.26997" width="4" height="19.4601" opacity="0.2" fill={color}>
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="0.2; 1; .2"
          begin="0s"
          dur={duration}
          repeatCount="indefinite"
        />
        <animate
          attributeName="height"
          attributeType="XML"
          values="10; 20; 10"
          begin="0s"
          dur={duration}
          repeatCount="indefinite"
        />
        <animate
          attributeName="y"
          attributeType="XML"
          values="10; 5; 10"
          begin="0s"
          dur={duration}
          repeatCount="indefinite"
        />
      </rect>
      <rect x="8" y="7.23003" width="4" height="15.5399" opacity="0.2" fill={color}>
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="0.2; 1; .2"
          begin="0.15s"
          dur={duration}
          repeatCount="indefinite"
        />
        <animate
          attributeName="height"
          attributeType="XML"
          values="10; 20; 10"
          begin="0.15s"
          dur={duration}
          repeatCount="indefinite"
        />
        <animate
          attributeName="y"
          attributeType="XML"
          values="10; 5; 10"
          begin="0.15s"
          dur={duration}
          repeatCount="indefinite"
        />
      </rect>
      <rect x="16" y="9.73003" width="4" height="10.5399" opacity="0.2" fill={color}>
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="0.2; 1; .2"
          begin="0.3s"
          dur={duration}
          repeatCount="indefinite"
        />
        <animate
          attributeName="height"
          attributeType="XML"
          values="10; 20; 10"
          begin="0.3s"
          dur={duration}
          repeatCount="indefinite"
        />
        <animate
          attributeName="y"
          attributeType="XML"
          values="10; 5; 10"
          begin="0.3s"
          dur={duration}
          repeatCount="indefinite"
        />
      </rect>
    </svg>
  );
};

Loading.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  duration: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.oneOf(['circle', 'loader']),
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Loading;
