import { Logo } from '@fast-radius/shared-ui';
import React from 'react';

import {
  Card,
  FormCustomerSelect,
  FormField,
  FormFieldWithLinkPreview,
  FormFillDevButton,
  Icon,
} from 'fr-shared/components';

const CostingFormDetails = () => {
  return (
    <Card border className="bg-light my-3">
      <Card.Header>
        <Logo fill="#777" width={22} showText={false} className="mr-2" />
        <span className="font-weight-light font-size-lg">Opportunity Information</span>
        <div className="ml-auto">
          <FormFillDevButton form="COSTING_REQUEST" />
        </div>
      </Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-md">
            <FormField
              label="Opportunity ID"
              name="opportunity_id"
              required={true}
              dataTestId="opportunity_id"
            />
            <FormField
              label={
                <>
                  Public ID&nbsp;
                  <Icon
                    name="info-circle"
                    tooltip="Enter the Quote Number issued by your Salesforce Opportunity"
                  />
                </>
              }
              name="public_id"
              required={true}
            />
          </div>
          <div className="col-md">
            <FormCustomerSelect />
            <FormFieldWithLinkPreview
              label="Box Link"
              name="box_file_location"
              required={true}
              showLink={false}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

CostingFormDetails.propTypes = {};

export default CostingFormDetails;
