import { withFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { AlertContext, withAlert } from 'fr-shared/context';
import { billingSchema } from 'fr-shared/lib/address';

import { AlertBanner } from 'portal/components';

import CreditCardInformationForm from './CreditCardInformationForm';
import CreditCardInformationSuccess from './CreditCardInformationSuccess';

const CreditCardContainer = () => {
  const [showFormSuccess, setShowFormSuccess] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const authToken = searchParams.get('auth');
  const quoteId = searchParams.get('quote_id');
  const requesterId = searchParams.get('requester_id');

  const { setAlert } = useContext(AlertContext);

  if (!authToken) return <Redirect to="/" />;

  const handleSubmit = values => {
    api
      .post(`/payment_methods`, {
        token: authToken,
        form: values,
        quote_id: quoteId,
        requester_id: requesterId,
      })
      .then(() => {
        setAlert({ message: 'Successfully added credit card information' });
        setShowFormSuccess(true);
      })
      .catch(err => {
        if (err?.response?.data?.errors?.billing_address) {
          setAlert({
            message: 'A card and billing address has already been added to this account.',
            color: 'danger',
          });
        } else {
          setAlert({
            message:
              'Something went wrong and we failed to add your card. Please refresh and try again.',
            color: 'danger',
          });
        }
      });
  };

  return (
    <div className="container py-5">
      <AlertBanner />
      <FormikContainer
        authToken={authToken}
        onSubmit={handleSubmit}
        showFormSuccess={showFormSuccess}
      />
    </div>
  );
};

const FormikContainer = withFormik({
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: billingSchema,
  handleSubmit: (values, formik) => {
    // call the parent onSubmit prop
    formik.props.onSubmit && formik.props.onSubmit(values, formik);
  },
})(({ authToken, showFormSuccess, handleSubmit }) => {
  return showFormSuccess ? (
    <CreditCardInformationSuccess />
  ) : (
    <CreditCardInformationForm authToken={authToken} onSubmit={handleSubmit} />
  );
});

export default withAlert(CreditCardContainer);
