import { get, isFunction } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { Button, Icon } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { useChannel } from 'fr-shared/hooks';
import { organizationChannelForUser } from 'fr-shared/lib/channels';

// transform search filter params for server side searching
const transformFilters = (page, pageSize, filters, initialFilters) => {
  // initial state of the pagination can be passed in from HOC
  let params = { page: page, page_size: pageSize, ...initialFilters, ...filters };

  params = Object.keys(params).reduce((r, e) => {
    if (params[e]) r[e] = params[e];
    return r;
  }, {});

  return params;
};

export default options => {
  return WrappedComponent => {
    const Paginated = props => {
      const [data, setData] = useState(null);
      const [page, setPage] = useState(1);
      const [pages, setPages] = useState(1);
      const [pageSize, setPageSize] = useState(
        props.pageSize && isFunction(props.pageSize) ? props.pageSize(props) : props.pageSize
      );
      const [total, setTotal] = useState(null);
      const [loading, setLoading] = useState(false);
      const [filters, setFilters] = useState({});
      const { user } = useContext(UserContext);
      const [channel] = useChannel(props.channelName || organizationChannelForUser(user));

      const [initialFilters] = useState(props.initialFilters && props.initialFilters(props));
      const url = isFunction(props.url) ? props.url(props) : props.url;

      const getPaginatedData = useCallback(
        (page = 1) => {
          setLoading(true);

          // take the filters state and convert it to params for API call
          const params = transformFilters(page, pageSize, filters, initialFilters);

          // get the new paginated data from server
          api.get(url, { params: params }).then(res => {
            if (res.status !== 200) return;

            const totalPages = get(res, 'headers.total-pages', '0');
            const total = get(res, 'headers.total', '0');

            setData(res.data);
            setPage(page);
            setPages(parseInt(totalPages));
            setTotal(parseInt(total));
            setLoading(false);

            props.scrollToTop !== false && window.scrollTo(0, 0);
          });
        },
        [props.scrollToTop, url, pageSize, filters, initialFilters]
      );

      const updateData = useCallback(
        newItem => {
          if (data) {
            // TODO: this id lookup to update will work for 90% of use cases
            const newData = data.map(d => (d.id === newItem.id ? newItem : d));
            setData(newData);
          }
        },
        [data]
      );

      useEffect(() => {
        getPaginatedData(page);
      }, [getPaginatedData, page]);

      useEffect(() => {
        if (channel && props.channelEvent) {
          const channelRef = channel.on(props.channelEvent, res => {
            // if we have updateAllData set, we refresh the entire data object,
            // else we just update the single object from the response
            props.updateAllData ? getPaginatedData(page) : updateData(res.data);
          });

          return () => channel.off(props.channelEvent, channelRef);
        }
      }, [channel, props.channelEvent, props.updateAllData, getPaginatedData, page, updateData]);

      const setNewData = newData => {
        setData(newData);
      };

      const handlePrevPage = () => {
        if (page <= 0) return;
        setPage(page - 1);
      };

      const handleNextPage = () => {
        if (page >= pages) return;
        setPage(page + 1);
      };

      const handlePageSizeChange = e => {
        const { value } = e.target;
        setPageSize(value);
      };

      const filterBy = e => {
        // TODO: rename handleFilterChange something
        // this is used by dropdown filters as a change event
        const { name, value } = e.target;
        const newFilters = { ...filters, [name]: value ? value : false };
        setFilters(newFilters);
      };

      const renderPageSize = () => (
        <div className="form-group mb-0">
          <div className="flex align-items-center">
            <div>
              <label htmlFor="page_size" className="whitespace-nowrap mr-2">
                Page Size
              </label>
            </div>
            <select
              name="page_size"
              id="page_size"
              className="custom-select"
              onChange={handlePageSizeChange}
              defaultValue={pageSize}
              style={{ width: 80 }}
            >
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="100000">All</option>
            </select>
          </div>
        </div>
      );

      const renderPagination = () => {
        if (!data || pages <= 1) return null;
        return (
          <div className="pagination flex justify-content-end align-items-center">
            <Button disabled={page <= 1} onClick={handlePrevPage} color="secondary" outline>
              <Icon name="chevron-left" sm right /> Previous
            </Button>
            <div className="px-3">
              {page} of {pages}
            </div>
            <Button disabled={page >= pages} onClick={handleNextPage} color="primary" outline>
              Next <Icon name="chevron-right" sm left />
            </Button>
          </div>
        );
      };

      const pagination = {
        data,
        page,
        pages,
        pageSize,
        total,
        filters,
        loading,
        filterBy,
        getPaginatedData,
        handleNextPage,
        handlePrevPage,
        renderPagination,
        renderPageSize,
        updateData,
        setNewData,
      };

      return <WrappedComponent {...props} pagination={pagination} />;
    };

    Paginated.propTypes = {
      url: PropTypes.string,
      updateAllData: PropTypes.bool,
      pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.func]),
      channelEvent: PropTypes.string,
      channelName: PropTypes.string,
      scrollToTop: PropTypes.bool,
      initialFilters: PropTypes.func,
    };

    Paginated.defaultProps = {
      url: options.url || null,
      pageSize: options.pageSize || 20,
      initialFilters: options.initialFilters || null,
      channelEvent: options.channelEvent || null,
      channelName: options.channelName || null,
      updateAllData: options.updateAllData || false,
      scrollToTop: options.scrollToTop,
    };

    return Paginated;
  };
};
