import axios, { AxiosError, AxiosResponse } from 'axios';
import { uniqueId } from 'lodash';
import React from 'react';

import { api } from 'fr-shared/api';
import { isAllowed, sanitizeFilename } from 'fr-shared/utils/files';

interface UploadFile {
  file: File;
}

export interface AwsS3File {
  presigned_url: string;
  filename: string;
  original_filename?: string;
  path: string;
}

type DataResponse<T> = Pick<AxiosResponse<T>, 'data'>;

/**
 * useS3FilesUpload is our hook for signing and uploading files to S3.
 *
 * The hook returns two values:
 *  - resultingFiles: The list of files after they have been uploaded. This list gets updated as the files upload status changes
 *  - handleUpload: A function that is called with a list of files which will kick off the upload.
 *      This function returns the same list of files with uuids marked on each ones
 *
 * Basic Usage:
 * const [resultingFiles, handleUpload] = useS3FilesUpload("/s3/sign/part_file")
 *
 * useEffect(() => {
 *  if (resultFiles.length === 0) return 0
 *  // 'Files that have completed upload', resultingFiles
 * }, [resultingFiles])
 *
 * const uploadFiles = () => {
 *   const loadingFiles = startUpload([{ file: new File() }, { file: new File() }])
 *   // 'Files that have started uploading with uuids', loadingFiles
 * }
 *
 * Type {@link T} represents the result type of handling the file upload
 *
 * @param {The endpoint to hit for the s3 sign} endpoint
 * @param {A promise that that will run on complete. Use this to run any after effect of a file being uploaded} onUploadComplete
 */
const useS3FilesUpload = <T, O = {}>(
  endpoint: string,
  onFileUploadComplete: (
    res: AxiosResponse<AwsS3File>,
    file: File,
    options: Partial<O>
  ) => Promise<DataResponse<AwsS3File | T>> = res => Promise.resolve(res),
  onFileSuccess: (res: DataResponse<AwsS3File | T>) => any = () => {},
  onFileFail: (err: AxiosError<any>) => any = () => {}
) => {
  type UploadResult = { file: File; data: AwsS3File | T };

  const [resultingFiles, dispatch] = React.useReducer(buildFilesReducer<T>(), []);

  const handleUpload = (
    files: UploadFile[],
    options: Partial<O> = {},
    onUploadSuccess: (files: UploadResult[]) => void = () => {},
    onUploadFail: (err: any) => void = () => {}
  ) => {
    if (!Array.isArray(files)) {
      throw new Error(`useS3FilesUpload.ts: handleUpload() must receive an array`);
    }

    const batchId = uniqueId('batch_id_');

    const filesWithIds = files.map(file => ({
      ...file,
      batchId,
      uuid: uniqueId('upload_id_'),
      upload_status: 'loading',
    }));
    const fileCompletePromises: Promise<UploadResult>[] = [];
    filesWithIds.forEach(f => {
      const file = f.file;

      fileCompletePromises.push(
        new Promise((resolve, reject) => {
          if (!isAllowed(file.name)) {
            const error = 'file type not allowed for upload';
            dispatch({
              file,
              uuid: f.uuid,
              batchId: f.batchId,
              type: 'ERROR',
              error: { raw: error, type: 'unsupported', statusCode: 400 },
            });
            reject({ file, error: error });
          } else {
            api
              .post(endpoint, {
                filename: sanitizeFilename(file.name),
              })
              .then((res: AxiosResponse<AwsS3File>) => {
                const { presigned_url, path } = res.data;
                return axios.put(presigned_url, file).then(() =>
                  onFileUploadComplete(res, file, options).then(res => {
                    onFileSuccess(res);
                    dispatch({
                      file,
                      type: 'SUCCESS',
                      uuid: f.uuid,
                      batchId: f.batchId,
                      error: null,
                      data: { path, ...res.data },
                    });
                    resolve({ file, data: { ...res.data } });
                  })
                );
              })
              .catch(error => {
                const parsedError = error.response ? error.response.data.messages : error;
                const statusCode = error.response?.status || -1;
                const type: UploadErrorType = statusCode === 415 ? 'unsupported' : 'unknown';
                onFileFail(parsedError);
                dispatch({
                  file,
                  uuid: f.uuid,
                  batchId: f.batchId,
                  type: 'ERROR',
                  error: { raw: parsedError, type, statusCode },
                });
                reject({ file, error: parsedError });
              });
          }
        })
      );
    });

    Promise.all(fileCompletePromises)
      .then((files: UploadResult[]) => onUploadSuccess(files))
      .catch(errors => onUploadFail(errors));

    return filesWithIds;
  };

  return [resultingFiles, handleUpload] as const;
};

function buildFilesReducer<T>() {
  const filesReducer = (
    files: FileState<AwsS3File | T>[],
    action: FileAction<AwsS3File | T>
  ): FileState<AwsS3File | T>[] => {
    switch (action.type) {
      case 'SUCCESS': {
        return [
          ...files.filter(f => f.uuid !== action.uuid),
          {
            file: action.file,
            batchId: action.batchId,
            uuid: action.uuid,
            upload_status: 'success',
            data: action.data,
            error: null,
          },
        ];
      }
      case 'ERROR': {
        return [
          ...files.filter(f => f.uuid !== action.uuid),
          {
            file: action.file,
            batchId: action.batchId,
            uuid: action.uuid,
            upload_status: 'error',
            data: null,
            error: action.error,
          },
        ];
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  };
  return filesReducer;
}

type UploadErrorType = 'unsupported' | 'unknown';

export interface UploadError {
  raw: any;
  type: UploadErrorType;
  statusCode: number;
}

export interface FileState<T> {
  file: File;
  upload_status: 'success' | 'error';
  uuid: string;
  batchId: string;
  data: T;
  error: UploadError;
}

interface FileAction<T> {
  type: 'SUCCESS' | 'ERROR';
  file: File;
  uuid: string;
  batchId: string;
  data?: T;
  error: UploadError;
}

export default useS3FilesUpload;
