import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Alert, Button, Icon, Modal } from 'fr-shared/components';

const FormFieldAdvice = ({ isVisible, adviceText, adviceColor, modalTitle, modalContent }) => {
  const [displayModal, setDisplayModal] = useState(false);

  if (!isVisible) return null;

  const closeModal = () => setDisplayModal(false);
  const alertContent = (
    <div>
      {adviceText}
      &nbsp;
      <Icon name="question-circle" />
    </div>
  );
  const alertColor = adviceColor || 'warning';

  return (
    <Alert color={alertColor} className="mt-2 cursor-pointer">
      <Modal
        action={alertContent}
        isOpen={displayModal}
        toggle={isVisible => setDisplayModal(isVisible)}
      >
        <Modal.Header title={modalTitle} onClose={closeModal} />

        <Modal.Body>{modalContent}</Modal.Body>

        <Modal.Footer>
          <Button color="primary" onClick={closeModal}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </Alert>
  );
};

FormFieldAdvice.propTypes = {
  isVisible: PropTypes.bool,
  adviceText: PropTypes.string,
  adviceColor: PropTypes.string,
  modalTitle: PropTypes.string,
  modalContent: PropTypes.object,
};

export default FormFieldAdvice;
