import { connect } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { classNames } from 'fr-shared/components';
import { TRACKER_WORK_ORDER_STATES } from 'fr-shared/lib/work_orders';

const formatStateEvent = (workOrderState, format) => {
  return workOrderState ? moment(workOrderState.inserted_at).format(format) : null;
};

const WorkOrderTracker = ({ formik }) => {
  const {
    values: { state, state_events },
  } = formik;

  const states = [...TRACKER_WORK_ORDER_STATES, ...(state === 'Failed' ? ['Failed'] : [])];
  const activeIndex = states.findIndex(e => e === state);

  return (
    <div className="py-2 px-3 mb-2 overflow-hidden d-none d-md-block">
      <div className="text-center">
        <div className="multi-steps">
          {states.map((woState, index) => {
            const woStateEvent =
              state_events && state_events.find(event => woState === event.state);
            return (
              <div
                key={woState}
                data-rh={formatStateEvent(woStateEvent, 'dddd, MMMM Do YYYY, h:mm:ss a')}
                className={classNames(['multi-step', { 'is-active': index === activeIndex }])}
              >
                {woState}
                <div className="text-muted">
                  {formatStateEvent(woStateEvent, 'MMMM DD, YYYY')}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

WorkOrderTracker.propTypes = {
  formik: PropTypes.object,
};

export default connect(WorkOrderTracker);
