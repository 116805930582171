import PropTypes from 'prop-types';
import React from 'react';

import { Button, IconFont, classNames } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';

import { Card } from 'portal/components';

import CreateAccountForm from './CreateAccountForm';
import styles from './SignupView.module.css';

const SignupView = ({ handleSubmit, setDisplayModal, submitLoading }) => {
  const userAnalytics = useUserAnalyticsContext();

  const handleDisplayMedia = () => {
    userAnalytics.track('Sign Up - Clicked Video Link');
    setDisplayModal();
  };

  return (
    <div className="lg:flex">
      <Card size="lg" dataTestId="create-account-form-fields">
        <h1 className="h6 text-light mb-4">Let&apos;s create your account</h1>
        <p className="mb-4">
          To start your quote, please create an account with us. It’s simple and fast.
        </p>
        <CreateAccountForm onSubmit={handleSubmit} submitLoading={submitLoading} />
      </Card>
      <aside className={classNames([styles['video-demo']])}>
        <div className={classNames([styles['os-screenshots'], 'mb-4'])}>
          <div>
            <img
              src="https://os.fastradius.com/images/fros-screenshot.png"
              alt="Fast Radius OS Screenshot"
            />
          </div>
        </div>
        <h2>Make new things possible</h2>
        <p className="mb-4">
          Design, make, fulfill: all in one place. Bring your ideas to life with our Cloud
          Manufacturing Platform™.
        </p>
        <Button outline={true} onClick={handleDisplayMedia}>
          <div className="flex align-items-center">
            <IconFont name="icon-media-play-alt" className="icon-media-play-alt" />
            Watch our demo
          </div>
        </Button>
      </aside>
    </div>
  );
};

SignupView.propTypes = {
  handleSubmit: PropTypes.func,
  setDisplayModal: PropTypes.func,
  submitLoading: PropTypes.bool,
};

export default SignupView;
