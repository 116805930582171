import { Form, Formik } from 'formik';
import React from 'react';

import { COUNTRIES } from 'fr-shared/lib/countries';

import { quoteSchema } from 'portal/lib/schema';
import { CP_SHIPPING_METHODS } from 'portal/lib/shipping';

import PartUpload from './components/PartUpload';

const PortalQuoteContainer = () => {
  return (
    <Formik
      initialValues={{
        line_items: [],
        quote_units: 'mm',
        billing_address: {},
        shipping_address: { country: COUNTRIES.UNITED_STATES },
        shipping_price: {},
        shipping_method: CP_SHIPPING_METHODS.Ground,
      }}
      validationSchema={quoteSchema}
    >
      <Form className="flex flex-fill flex-column">
        <PartUpload />
      </Form>
    </Formik>
  );
};

export default PortalQuoteContainer;
