import React, { useCallback, useState } from 'react';
import { Cell, Pie, PieChart, Sector } from 'recharts';

import { useUserAnalyticsContext } from 'fr-shared/context';
import { usePortalSubscription } from 'fr-shared/hooks';

import { COST_DRIVER_COLORS, CostDriver } from 'portal/lib/cost_drivers';

import CostDriversDonutChartLegend from './CostDriversDonutChartLegend';

interface Props {
  onDriverUpdate: (driver: CostDriver) => void;
  costDrivers: Array<CostDriver>;
  renderLegend: boolean;
  selectedDriver?: CostDriver;
  highlightSector?: boolean;
}
const CostDriversDonutChart = ({
  onDriverUpdate,
  costDrivers = [],
  renderLegend,
  selectedDriver,
  highlightSector,
}: Props) => {
  const selectedDriverIndex = selectedDriver ? costDrivers.indexOf(selectedDriver) : null;
  const [selectedSectorIndex, setSelectedSectorIndex] = useState(selectedDriverIndex);
  const userAnalytics = useUserAnalyticsContext();

  const isPortalSubscribed = usePortalSubscription();

  const handleSectorClick = useCallback(
    (_, index) => {
      if (costDrivers.length <= 1) index = 0;
      userAnalytics.track('Cost Insights - Clicked Cost Drivers', {
        cost_driver_name: costDrivers[index].cost_driver_name,
      });
      if (highlightSector) {
        setSelectedSectorIndex(selectedDriverIndex);
      } else if (index === selectedSectorIndex) {
        setSelectedSectorIndex(null);
        onDriverUpdate(null);
      } else {
        setSelectedSectorIndex(index);
        onDriverUpdate(costDrivers[index]);
      }
    },

    [
      setSelectedSectorIndex,
      onDriverUpdate,
      costDrivers,
      userAnalytics,
      highlightSector,
      selectedDriverIndex,
      selectedSectorIndex,
    ]
  );

  const renderActiveShape = (props: any) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;

    return (
      <>
        <g>
          <text x={cx} y={cy} dy={-10} textAnchor="middle">
            <tspan stroke={'#FAFCFF'} fill={'#FAFCFF'} fontSize="16px">
              {Math.round(payload.percentage * 100)}
            </tspan>
            <tspan fill={'#A4ADB9'} fontSize={'12px'}>
              %
            </tspan>
          </text>
          <line x1="35%" y1={cy} x2="65%" y2={cy} stroke="#3C4551" />
          <text x={cx} y={cy} dy={20} textAnchor="middle">
            <textPath href="myPath" />
            <tspan fill={'#A4ADB9'} fontSize={'12px'}>
              $
            </tspan>
            <tspan stroke={'#FAFCFF'} fill={'#FAFCFF'} fontSize="16px">
              {parseFloat(payload.price).toFixed(2)}
            </tspan>
          </text>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={75}
            outerRadius={84}
            fill={fill}
            cornerRadius={2}
          />
        </g>
      </>
    );
  };

  const legendCallback = (legendIndex: number) => {
    if (legendIndex === selectedSectorIndex) {
      setSelectedSectorIndex(null);
      onDriverUpdate(null);
    } else {
      setSelectedSectorIndex(legendIndex);
      onDriverUpdate(costDrivers[legendIndex]);
    }
  };

  let standardUserCostDrivers: Array<object> = [];
  costDrivers.map((data: any) => {
    const calculateHiddenPercentage = (1 - data.percentage) / 4;
    const calculateHiddenPrice = data.price / 4;
    standardUserCostDrivers.push(
      { percentage: data.percentage, price: data.price },
      { percentage: calculateHiddenPercentage, price: calculateHiddenPrice },
      { percentage: calculateHiddenPercentage, price: calculateHiddenPrice },
      { percentage: calculateHiddenPercentage, price: calculateHiddenPrice },
      { percentage: calculateHiddenPercentage, price: calculateHiddenPrice }
    );
  });

  return (
    <>
      <article>
        <div className="flex flex-row font-size-md">
          <section>
            <PieChart width={220} height={220}>
              <Pie
                activeIndex={selectedSectorIndex}
                activeShape={renderActiveShape}
                data={isPortalSubscribed ? costDrivers : standardUserCostDrivers}
                dataKey="percentage"
                nameKey="cost_driver_name"
                cx="50%"
                cy="50%"
                innerRadius={59}
                outerRadius={79}
                fill="#252C35"
                paddingAngle={3}
                stroke={'0'}
                cornerRadius={2}
                onClick={handleSectorClick}
              >
                {!highlightSector &&
                  costDrivers.map((entry: CostDriver) => {
                    const sectorColor = COST_DRIVER_COLORS.find(
                      data => data.cost_driver_name === entry.cost_driver_name
                    );
                    return (
                      <Cell key={`cell-${entry.cost_driver_name}`} fill={sectorColor.color} />
                    );
                  })}
                {highlightSector &&
                  costDrivers.map((entry: CostDriver) => {
                    const GREY = '#252C35';
                    let sectorColor = GREY;
                    if (selectedDriver.cost_driver_name === entry.cost_driver_name) {
                      sectorColor = COST_DRIVER_COLORS.find(
                        data => data.cost_driver_name === entry.cost_driver_name
                      ).color;
                    }
                    return <Cell key={`cell-${entry.cost_driver_name}`} fill={sectorColor} />;
                  })}
              </Pie>
            </PieChart>
          </section>
        </div>
      </article>
      {renderLegend && (
        <CostDriversDonutChartLegend
          selectedSector={selectedSectorIndex}
          legendCallback={legendCallback}
          costDrivers={costDrivers}
        />
      )}
    </>
  );
};

export default CostDriversDonutChart;
