import React from 'react';

import { AddressTypeEnum } from 'fr-shared/lib/address';

import NewAccountAddresses from '../account_details/components/NewAccountAddresses';
import AccountPaymentMethod from './components/AccountPaymentMethod';

const PaymentDetailsContainer = () => {
  return (
    <>
      <AccountPaymentMethod />
      <div className="col-md mt-5">
        <NewAccountAddresses type={AddressTypeEnum.Shipping} />
      </div>

      <div className="col-md mt-8">
        <NewAccountAddresses type={AddressTypeEnum.Billing} />
      </div>
    </>
  );
};

export default PaymentDetailsContainer;
