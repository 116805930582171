export const SHIPPING_PAYMENT_TYPES = {
  CustomerAccount: 'Customer Account',
  FreeShipping: 'Free Shipping',
  InvoiceToCustomer: 'Invoice to Customer',
};

export const SHIPPING_PAYMENT_TYPE_OPTIONS = [
  SHIPPING_PAYMENT_TYPES.InvoiceToCustomer,
  SHIPPING_PAYMENT_TYPES.CustomerAccount,
  SHIPPING_PAYMENT_TYPES.FreeShipping,
];

export enum DomesticShippingMethod {
  Ground = 'ground',
  NextDayAir = 'next_day_air',
  NextDayEarlyAM = 'next_day_early_am',
  NextDaySaver = 'next_day_saver',
  SecondDayAir = 'second_day_air',
  SecondDayAirAM = 'ups_second_day_air',
  ThreeDaySelect = 'three_day_select',
}

export enum InternationalShippingMethod {
  Standard = 'standard',
  WorldwideExpedited = 'worldwide_expedited',
  WorldwideExpress = 'worldwide_express',
  WorldwideExpressPlus = 'worldwide_express_plus',
  WorldwideSaver = 'worldwide_saver',
}

const DOMESTIC_SHIPPING_METHODS = Object.values<string>(DomesticShippingMethod);
const INTERNATIONAL_SHIPPING_METHODS = Object.values<string>(InternationalShippingMethod);

export function isShippingMethod(shippingMethod: string): shippingMethod is ShippingMethod {
  return (
    DOMESTIC_SHIPPING_METHODS.includes(shippingMethod) ||
    INTERNATIONAL_SHIPPING_METHODS.includes(shippingMethod)
  );
}

export const shippingMethodToDisplayName = (shippingMethod: ShippingMethod) => {
  switch (shippingMethod) {
    case DomesticShippingMethod.Ground:
      return SHIPPING_METHODS.UpsGround.name;

    case DomesticShippingMethod.NextDayAir:
      return SHIPPING_METHODS.UpsNextDayAir.name;

    case DomesticShippingMethod.NextDayEarlyAM:
      return SHIPPING_METHODS.UpsNextDayAirEarly.name;

    case DomesticShippingMethod.NextDaySaver:
      return SHIPPING_METHODS.UpsNextDaySaver.name;

    case DomesticShippingMethod.SecondDayAir:
      return SHIPPING_METHODS.Ups2DayAir.name;

    case DomesticShippingMethod.SecondDayAirAM:
      return SHIPPING_METHODS.Ups2DayAirAM.name;

    case DomesticShippingMethod.ThreeDaySelect:
      return SHIPPING_METHODS.Ups3DaySelect.name;

    case InternationalShippingMethod.Standard:
      return SHIPPING_METHODS.UpsStandard.name;

    case InternationalShippingMethod.WorldwideExpedited:
      return SHIPPING_METHODS.UpsWorldwideExpedited.name;

    case InternationalShippingMethod.WorldwideExpress:
      return SHIPPING_METHODS.UpsWorldwideExpress.name;

    case InternationalShippingMethod.WorldwideExpressPlus:
      return SHIPPING_METHODS.UpsWorldwideExpressPlus.name;

    case InternationalShippingMethod.WorldwideSaver:
      return SHIPPING_METHODS.UpsWorldwideSaver.name;

    default:
      return shippingMethod;
  }
};

export const shippingMethodToDescription = (shippingMethod: ShippingMethod) => {
  switch (shippingMethod) {
    case DomesticShippingMethod.Ground:
      return 'Arrives 2-5 business days after ship date.';

    case DomesticShippingMethod.NextDayAir:
      return 'Arrives 1 business day after ship date.';

    case DomesticShippingMethod.NextDayEarlyAM:
      return 'Arrives at 8AM 1 business day after ship date to all major cities in the Continental 48 states.';

    case DomesticShippingMethod.NextDaySaver:
      return 'Arrives 1 business day after ship date between 3:00 p.m. and 4:30 p.m. to commercial addresses and an undetermined time of day to residential addresses.';

    case DomesticShippingMethod.SecondDayAir:
      return 'Arrives 2nd business day after ship date, by 10:30 a.m. or 12:00 noon to most commercial destinations.';

    case DomesticShippingMethod.SecondDayAirAM:
      return 'Arrives 2nd business day after ship date, by 10:30 a.m. or 12:00 noon to most commercial destinations.';

    case DomesticShippingMethod.ThreeDaySelect:
      return 'Arrives within 3 business days after the shipping date.';

    case InternationalShippingMethod.Standard:
      return 'Arrives 1-3 business days after ship date. Best, non-urgent option for shipping between US, Canada, and Mexico.';

    case InternationalShippingMethod.WorldwideExpedited:
      return 'Arrives 2-5 business days after ship date. Speedy, guaranteed option for non-urgent international shipments.';

    case InternationalShippingMethod.WorldwideExpress:
      return 'Arrives in 1-3 business days by 10:30 a.m., noon, or 2 p.m., depending on the destination.';

    case InternationalShippingMethod.WorldwideExpressPlus:
      return 'Arrives in 1-2 business days by 8:00 a.m., 8:30 a.m., or 9:00 a.m., depending on the destination.';

    case InternationalShippingMethod.WorldwideSaver:
      return 'Arrives in 2-3 business days by the end of the day to most countries in the world.';
  }
};

// values here must match the backend at ~/lib/fast_radius/orders/field_values.ex available_shipping_methods/0
export const SHIPPING_METHODS = {
  Dhl: { name: 'DHL', isActive: true },
  Ups2DayAir: { name: 'UPS 2nd Day Air', isActive: true },
  Ups2DayAirAM: { name: 'UPS 2nd Day Air A.M.', isActive: true },
  Ups3DaySelect: { name: 'UPS 3 Day Select', isActive: true },
  UpsAirFreightConsolidated: { name: 'UPS Air Freight Consolidated', isActive: true },
  UpsAirFreightDirect: { name: 'UPS Air Freight Direct', isActive: true },
  UpsFreight: { name: 'UPS Freight', isActive: false },
  UpsGround: { name: 'UPS Ground', isActive: true },
  UpsNextDayAir: { name: 'UPS Next Day Air', isActive: true },
  UpsNextDayAirEarly: { name: 'UPS Next Day Air Early', isActive: true },
  UpsNextDaySaver: { name: 'UPS Next Day Air Saver', isActive: true },
  UpsOceanFreight: { name: 'UPS Ocean Freight', isActive: true },
  UpsStandard: { name: 'UPS Standard', isActive: true },
  UpsWorldwideExpedited: { name: 'UPS Worldwide Expedited', isActive: true },
  UpsWorldwideExpress: { name: 'UPS Worldwide Express', isActive: true },
  UpsWorldwideExpressFreight: { name: 'UPS Worldwide Express Freight', isActive: true },
  UpsWorldwideExpressPlus: { name: 'UPS Worldwide Express Plus', isActive: true },
  UpsWorldwideSaver: { name: 'UPS Worldwide Saver', isActive: true },
  FedEx: { name: 'FedEx', isActive: false },
  FedEx2DayAir: { name: 'FedEx 2nd Day Air', isActive: true },
  FedExFreight: { name: 'FedEx Freight', isActive: true },
  FedExGround: { name: 'FedEx Ground', isActive: true },
  FedExInternational: { name: 'FedEx International', isActive: false },
  FedExInternationalEconomy: { name: 'FedEx International Economy', isActive: true },
  FedExInternationalPriority: { name: 'FedEx International Priority', isActive: true },
  FedExOvernightPriorityAir: { name: 'FedEx Overnight Priority Air', isActive: true },
  FedExOvernightStandardAir: { name: 'FedEx Overnight Standard Air', isActive: true },
  ChrFreight: { name: 'CHR Freight', isActive: false },
  ChrAir: { name: 'CH Robinson Air', isActive: true },
  ChrOcean: { name: 'CH Robinson Ocean', isActive: true },
  Other: { name: 'Other - See notes', isActive: true },
};

export const SHIPPING_METHODS_LIST = Object.values(SHIPPING_METHODS).map(method => method.name);

export const ACTIVE_SHIPPING_METHODS_LIST = Object.values(SHIPPING_METHODS)
  .filter(obj => obj['isActive'])
  .map(method => method.name);

// Until we have a more robust solution in place, we will offer free shipping if
// there is an error fetching/calculating.
export const getFallbackShippingCost = () => ({ formatted: 'FREE', amount: null } as Money);

export const quantityShipped = (orderLineItem: any, shipTo = 'Customer') => {
  if (!orderLineItem?.shipment_line_items) return 0;

  const slisToCustomer = orderLineItem.shipment_line_items.filter((sli: any) =>
    isAFulfillingShipment(sli, shipTo)
  );

  return slisToCustomer.reduce((acc: number, sli: any) => acc + sli.quantity, 0);
};

export const isAFulfillingShipment = (sli: any, shipTo = 'Customer') => {
  return sli ? sli.shipment?.ship_to === shipTo : false;
};

export default { ACTIVE_SHIPPING_METHODS_LIST, SHIPPING_METHODS_LIST };
