import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { api } from 'fr-shared/api';
import { FormContactPicker } from 'fr-shared/components';
import { QUOTE_STATES } from 'fr-shared/lib/quotes';

const CustomerContactPicker = () => {
  const isEditableState = (quote_state: keyof typeof QUOTE_STATES) => {
    return [QUOTE_STATES.Draft, QUOTE_STATES.Submitted].includes(quote_state);
  };

  const useOrgUsers = (customerId: number) => {
    return useQuery(
      ['organizations', customerId],
      () => api.get(`/organizations/${customerId}`).then(res => res.data),
      {
        refetchOnWindowFocus: false,
        staleTime: 10000,
      }
    );
  };

  const [isUpdating, setIsUpdating] = useState(false);

  const formik: any = useFormikContext();
  const quoteId: number = formik.values.id;
  const customerId: number = formik.values.customer_id;
  const customerContact: User = formik.values.customer_contact;
  const state: keyof typeof QUOTE_STATES = formik.values.state;

  const isThisQuoteEditable = isEditableState(state);

  const { data, isLoading } = useOrgUsers(customerId);

  const orgUsers = !isLoading && data.users ? data.users : [];

  const handleChange = (selectedUser: User) => {
    if (selectedUser.id === customerContact.id) {
      return;
    }

    setIsUpdating(true);

    api
      .put(`/quotes/${quoteId}`, { quote: { customer_contact_id: selectedUser.id } })
      .then(res => {
        formik.setFieldValue('customer_contact_id', res.data.customer_contact_id);
        formik.setFieldValue('customer_contact', res.data.customer_contact);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return (
    <FormContactPicker
      name="quote-customer-contact"
      label="Customer Contact"
      unassignedButtonText="Select Customer Contact"
      options={orgUsers}
      value={customerContact}
      isDisabled={!isThisQuoteEditable || isLoading || isUpdating}
      onChange={handleChange}
      showAvatar={true}
      includeUser={false}
      includeUnassignButton={false}
      isStacked={false}
    />
  );
};

export default CustomerContactPicker;
