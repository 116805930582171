import { useTreatments } from '@splitsoftware/splitio-react';
import { get } from 'lodash';
import React from 'react';

import { Button, Icon, LineItemProcess, classNames } from 'fr-shared/components';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';

import styles from './OrderLineItem.module.css';

interface OrderLineItemsProps {
  lineItem: OrderLineItem;
  onSelect?: (id: number[]) => void;
  selected?: boolean;
  readonly?: boolean;
}

const OrderLineItem = (props: OrderLineItemsProps) => {
  const { lineItem, onSelect, selected, readonly } = props;
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';

  const screenshot = get(lineItem, 'part.current_revision.screenshot', null);
  const productionLocation = get(lineItem, 'location.name', '- -');
  const partName = get(lineItem, 'description', '- -');
  const shippedToCustomerFrom = get(lineItem, 'shipped_to_customer_from', '- -');
  const quantity = get(lineItem, 'quantity', 0);
  const remainingToFulfill = get(lineItem, 'remaining_to_fulfill', 0);

  const fulfilledQty = quantity - remainingToFulfill;
  return (
    <div
      data-testid="new-order-line-item"
      key={lineItem.id}
      className={classNames(['card-body', { 'pb-0': selected }])}
    >
      <div
        className={classNames(['flex flex-row align-items-md-center px-3', { 'pb-0': selected }])}
      >
        {!readonly && (
          <div className="col-1 flex-1">
            <Button
              aria-label={`${selected ? 'Remove' : 'Select'} ${lineItemName} ${lineItem.id}`}
              onClick={() => onSelect([lineItem.id])}
              className={classNames([
                'rounded-circle',
                selected ? styles.OliSelectedButton : styles.OliNotSelectedButton,
              ])}
              dataTestId="new_order_line_item_select_button"
              outline
            >
              <Icon name={selected ? 'minus' : 'plus'} />
            </Button>
          </div>
        )}
        <div className="col border bg-light rounded">
          <div className={'row pt-2 pb-2'}>
            <div className="pl-2 pr-2">
              <img
                src={screenshot}
                width={72}
                height={72}
                className="rounded border"
                alt={`screenshot of ${partName}`}
              />
            </div>
            <div className="pl-2">
              <div className="row ml-0 mr-0">
                <strong>
                  <div>Part Name:</div>
                </strong>
                <div className="pl-1">{partName}</div>
              </div>
              <div className="row ml-0 mr-0">
                <strong>
                  <div>OLI ID:</div>
                </strong>
                <div className="pl-1">{lineItem.id}</div>
              </div>
              <div className="row ml-0 mr-0">
                <strong>
                  <div>Shipped to Customer From:</div>
                </strong>
                <div className="pl-1">
                  {shippedToCustomerFrom ? shippedToCustomerFrom : '- -'}
                </div>
              </div>
            </div>
            <div className="pl-2">
              <div className="row ml-0 mr-0">
                <strong>
                  <div>Production Location:</div>
                </strong>
                <div className="pl-1">{productionLocation}</div>
              </div>
              <div className="row ml-0 mr-0">
                <strong>
                  <div>Production Details:</div>
                </strong>
                <div className="pl-1">
                  <LineItemProcess data={lineItem} />
                </div>
              </div>
              <div className="row ml-0 mr-0">
                <strong>
                  <div>Quantity Shipped:</div>
                </strong>
                <div className="pl-1">{`${fulfilledQty}/${quantity}`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderLineItem;
