import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import { IconFont } from 'fr-shared/components';

import { Button } from 'portal/components';
import useCart from 'portal/hooks/useCart';

export const QuoteButton = ({ dataTestId, size, onClick = null, ...props }) => {
  const location = useLocation();
  const { data: cart, refetch: getCart, isFetched } = useCart();
  const handleClick = type => {
    if (onClick) onClick(type);
  };

  useEffect(() => {
    getCart();
  }, [location, getCart]);

  if (!isFetched) return null;

  return cart ? (
    <Button
      dataTestId={dataTestId}
      size={size}
      onClick={() => handleClick('edit')}
      to="/quotes/draft"
      {...props}
    >
      <IconFont name="pencil" />
      Edit quote
    </Button>
  ) : (
    <Button
      dataTestId={dataTestId}
      size={size}
      onClick={() => handleClick('create')}
      to="/add-part"
      {...props}
    >
      <IconFont name="plus" />
      Create quote
    </Button>
  );
};

QuoteButton.propTypes = {
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};
export default QuoteButton;
