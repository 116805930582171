import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import { DropdownIndicator } from 'fr-shared/components';

const QuoteFormSearch = ({ onSearchOptions, onChange, value }) => {
  return (
    <div className="pb-3 px-3">
      <AsyncSelect
        cacheOptions={true}
        classNamePrefix="react-select"
        components={{
          DropdownIndicator,
          IndicatorSeparator: null,
          Option: Option,
        }}
        loadOptions={onSearchOptions}
        name="opportunity_id"
        noOptionsMessage={option =>
          option.inputValue === '' ? null : 'No completed costing requests found'
        }
        onChange={onChange}
        placeholder="Enter the Opportunity ID from the Salesforce URL"
        value={value}
      />
    </div>
  );
};

QuoteFormSearch.propTypes = {
  onSearchOptions: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

const Option = ({ data, ...props }) => {
  return (
    <components.Option {...props}>
      <div className="flex">
        <div className="mr-4">
          <div>
            <small className="text-muted">Opportunity</small>
          </div>
          <div>
            <strong>{data.opportunity_id}</strong>
          </div>
        </div>
        <div className="mr-4">
          <div>
            <small className="text-muted">Customer</small>
          </div>
          <div>
            <strong>{data.customer.name}</strong>
          </div>
        </div>
        {data.customer_contact && (
          <>
            <div className="mr-4">
              <div>
                <small className="text-muted">Contact</small>
              </div>
              <div>
                <strong>{data.customer_contact.name}</strong>
              </div>
            </div>
            <div className="mr-4">
              <div>
                <small className="text-muted">Email</small>
              </div>
              <div>
                <strong>{data.customer_contact.email}</strong>
              </div>
            </div>
          </>
        )}
      </div>
    </components.Option>
  );
};

Option.propTypes = {
  data: PropTypes.object,
};

export default QuoteFormSearch;
