import { SAAS_BILLING } from 'fr-shared/feature_flags';
import { useFeatureFlags } from 'fr-shared/hooks';

const useSaasBillingFeatureFlag = () => {
  const { [SAAS_BILLING]: isSaasBillingEnabled } = useFeatureFlags({
    [SAAS_BILLING]: 'on',
  });
  return isSaasBillingEnabled;
};

export default useSaasBillingFeatureFlag;
