import React, { useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { Avatar, Button, DropdownItem, Navbar } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { COSTING_REQUEST_STATES } from 'fr-shared/lib/costing_requests';

import { ENV_COLOR_CLASSNAME } from '../../shared/components/common/EnvironmentIndicator';

const NavbarAdmin = () => {
  const { user } = useContext(UserContext);

  const showCostingRequestQueues = Boolean(user.isOps || user.isSales);

  const [counts, setCounts] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hasApiError, setHasApiError] = useState(false);

  useEffect(() => {
    if (!user.isSales) return;

    api
      .get('/costing_request_queue_counts', {
        params: {
          states: [
            COSTING_REQUEST_STATES.Draft,
            COSTING_REQUEST_STATES.Completed,
            COSTING_REQUEST_STATES.Requested,
            COSTING_REQUEST_STATES.InProgress,
          ],
          created_by_id: user.id,
        },
      })
      .then(res => setCounts(res.data))
      .catch(() => setHasApiError(true))
      .finally(() => setIsLoading(false));
  }, [setIsLoading, user.id, user.isSales]);

  const showQueueCountBadge = costingRequestState => {
    return user.isSales && !isLoading && !hasApiError && counts[costingRequestState] > 0;
  };

  const queueCountBadge = costingRequestState => {
    if (showQueueCountBadge(costingRequestState)) {
      return (
        <div className="badge badge-pill badge-danger ml-auto">{counts[costingRequestState]}</div>
      );
    }
  };

  return (
    <Navbar logoUrl="/admin" className={ENV_COLOR_CLASSNAME} color="dark" container="fluid">
      {user.canCreateCostingRequests && (
        <Button
          to="/admin/costing_requests/new"
          dataTestId="create-new-costing-request--button"
          color="primary"
          className="order-0 order-xl-9 nav-item my-2 my-xl-0"
        >
          Start a Costing Request
        </Button>
      )}
      <Navbar.Dropdown
        title="Costing Requests"
        className="order-1"
        isVisible={showCostingRequestQueues}
      >
        <DropdownItem header className="gray-200 px-2 py-0">
          Queues
        </DropdownItem>
        <Navbar.DropdownLink to="/admin/costing_requests/all_queues">
          View All
        </Navbar.DropdownLink>
        <Navbar.DropdownLink
          to="/admin/costing_requests/draft_queue"
          isVisible={user.isSales}
          className="flex align-items-center"
        >
          Drafts
          {queueCountBadge(COSTING_REQUEST_STATES.Draft)}
        </Navbar.DropdownLink>
        <Navbar.DropdownLink
          to="/admin/costing_requests/requested_queue"
          className="flex align-items-center"
        >
          Requested
          {queueCountBadge(COSTING_REQUEST_STATES.Requested)}
        </Navbar.DropdownLink>
        <Navbar.DropdownLink
          to="/admin/costing_requests/in_progress_queue"
          className="flex align-items-center"
        >
          In Progress
          {queueCountBadge(COSTING_REQUEST_STATES.InProgress)}
        </Navbar.DropdownLink>
        <Navbar.DropdownLink
          to="/admin/costing_requests/completed_queue"
          className="flex align-items-center"
        >
          Completed
          {queueCountBadge(COSTING_REQUEST_STATES.Completed)}
        </Navbar.DropdownLink>
        <Navbar.DropdownLink to="/admin/costing_requests/closed_queue" isVisible={user.isSales}>
          Closed
        </Navbar.DropdownLink>
        <DropdownItem divider />
        <Navbar.DropdownLink to="/admin/costing_requests">View Index</Navbar.DropdownLink>
        <Navbar.DropdownLink to="/admin/costing_csv_imports" isVisible={user.canImportCosts}>
          Imports
        </Navbar.DropdownLink>
      </Navbar.Dropdown>
      <Navbar.Link
        to="/admin/costing_requests"
        className="order-2"
        isVisible={!showCostingRequestQueues}
      >
        Costing Requests
      </Navbar.Link>
      <Navbar.Link to="/admin/quotes" className="order-3">
        Quotes
      </Navbar.Link>
      <Navbar.Link to="/admin/orders" className="order-4">
        Orders
      </Navbar.Link>
      <Navbar.Dropdown title="Customers" className="order-5">
        <Navbar.DropdownLink to="/admin/customers">Customers</Navbar.DropdownLink>
        <Navbar.DropdownLink to="/admin/customers/subscribed">Pro Customers</Navbar.DropdownLink>
      </Navbar.Dropdown>
      <Navbar.Dropdown title="Factory" className="order-7">
        <Navbar.DropdownLink to="/admin/work_orders">Work Orders</Navbar.DropdownLink>
        <Navbar.DropdownLink to="/admin/shipments">Shipments</Navbar.DropdownLink>
        <Navbar.DropdownLink to="/admin/packing_slips">Packing Slips</Navbar.DropdownLink>
        <Navbar.DropdownLink to="/admin/suppliers">Suppliers</Navbar.DropdownLink>
        <Navbar.DropdownLink to="/admin/work_orders/state_updater">
          State Updater
        </Navbar.DropdownLink>
        <Navbar.DropdownLink to="/admin/lead_times">Lead Times</Navbar.DropdownLink>
        <Navbar.DropdownLink to="/admin/rejection_reasons">Rejection Reasons</Navbar.DropdownLink>
        <Navbar.DropdownLink to="/admin/printers">Printers</Navbar.DropdownLink>
        <Navbar.DropdownLink to="/admin/qc/review" className="order-6" isVisible={user.canViewQC}>
          QC
        </Navbar.DropdownLink>
        <Navbar.DropdownLink to="/admin/margin_control">Margin Control</Navbar.DropdownLink>
      </Navbar.Dropdown>
      <Navbar.Link to="/admin/work_orders/qr_reader" className="order-8 d-lg-none">
        QR Reader
      </Navbar.Link>
      <Navbar.Dropdown
        title={<Avatar size="sm" subject={user} imageLeft={false} />}
        className="order-10"
        dropdownToggleClassName="flex align-items-center justify-content-end"
      >
        <Navbar.DropdownLink to="/admin/roles" isVisible={user.isAdmin}>
          Role Management
        </Navbar.DropdownLink>
        <Navbar.DropdownLink to="/admin/users/new" isVisible={user.isAdmin}>
          Add Fast Radius User
        </Navbar.DropdownLink>
        {user.canViewWebhooks && (
          <Navbar.DropdownLink to="/admin/smartsheets/webhooks" isVisible={user.ViewWebhooks}>
            Smartsheet Webhooks
          </Navbar.DropdownLink>
        )}
        <Navbar.DropdownLink to="/admin/inventory/material/new" isVisible={user.isAdmin}>
          Add Material
        </Navbar.DropdownLink>
        <Navbar.DropdownLink to="/logout">Logout</Navbar.DropdownLink>
        {process.env.VERSION && (
          <div className="text-right text-gray font-size-xs border-top px-2 mt-1 pt-1">
            <span>v{process.env.VERSION}</span>
          </div>
        )}
      </Navbar.Dropdown>
    </Navbar>
  );
};

export default NavbarAdmin;
