import { Logo } from '@fast-radius/shared-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  EnvironmentIndicator,
  Link,
  NavLink,
  UncontrolledDropdown,
  classNames,
} from 'fr-shared/components';

const Navbar = ({
  children,
  className,
  container,
  color = 'light',
  isResponsive = true,
  logoOpenNewWindow,
  logoUrl,
  showBeta,
  showLogoExtra,
  showEnvironment = true,
}) => {
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(true);

  /**
   * When we navigate pages, we want to collapse the navbar if its expanded on mobile
   * @param {object} location - url location object
   */
  useEffect(() => {
    setIsCollapsed(true);
  }, [location]);

  return (
    <>
      {showEnvironment && <EnvironmentIndicator />}
      <nav
        className={classNames([
          'navbar',
          color && `navbar-${color}`,
          { 'navbar-expand-xl': isResponsive },
          className,
        ])}
      >
        <div className={container ? `container-${container}` : 'container'}>
          <div className="navbar-brand flex align-items-center">
            <Link to={logoUrl} openNewWindow={logoOpenNewWindow}>
              <Logo
                className="flex"
                showText={true}
                textColor={color !== 'light' ? 'white' : '#1c355e'}
                fill={color === 'dark' ? 'white' : undefined}
              />
            </Link>

            {showLogoExtra}

            {showBeta && <div className="beta beta-light mr-4">beta</div>}
          </div>

          {isResponsive && (
            <button className="navbar-toggler" onClick={() => setIsCollapsed(!isCollapsed)}>
              <span className="navbar-toggler-icon" />
            </button>
          )}

          <div className={classNames(['collapse navbar-collapse', !isCollapsed && 'show'])}>
            <div className="navbar-nav">{children}</div>
          </div>
        </div>
      </nav>
    </>
  );
};

Navbar.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['light', 'dark']),
  container: PropTypes.string,
  className: PropTypes.string,
  logoUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  logoOpenNewWindow: PropTypes.bool,
  isResponsive: PropTypes.bool,
  showBeta: PropTypes.bool,
  showEnvironment: PropTypes.bool,
  showLogoExtra: PropTypes.object,
};

export const NavbarLink = ({ children, className, exact, isVisible = true, to }) =>
  isVisible && (
    <div className={classNames(['nav-item', className])}>
      <NavLink exact={exact} className="nav-link" to={to}>
        {children}
      </NavLink>
    </div>
  );

NavbarLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  exact: PropTypes.bool,
  isVisible: PropTypes.bool,
  to: PropTypes.string,
};

export const NavbarDropdown = ({
  children,
  className,
  dropdownToggleClassName,
  isVisible = true,
  title,
}) =>
  isVisible && (
    <UncontrolledDropdown nav inNavbar tag="div" className={className}>
      <DropdownToggle className={dropdownToggleClassName} nav caret>
        {title}
      </DropdownToggle>
      <DropdownMenu right>{children}</DropdownMenu>
    </UncontrolledDropdown>
  );

NavbarDropdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dropdownToggleClassName: PropTypes.string,
  isVisible: PropTypes.bool,
  title: PropTypes.node,
};

export const NavbarDropdownLink = ({ children, className, isVisible = true, onClick, to }) =>
  isVisible && (
    <DropdownItem
      className={classNames([className, onClick && 'cursor-pointer'])}
      tag={to ? Link : 'div'}
      onClick={onClick}
      to={to}
    >
      {children}
    </DropdownItem>
  );

NavbarDropdownLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isVisible: PropTypes.bool,
  to: PropTypes.string,
};

Navbar.Dropdown = NavbarDropdown;
Navbar.DropdownLink = NavbarDropdownLink;
Navbar.Link = NavbarLink;

export default Navbar;
