import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  EnvironmentIndicator,
  GlobalErrorBoundary,
  Helmet,
  PrivateRoute,
} from 'fr-shared/components';
import { PhoenixSocketProvider, UserContext, withAlert } from 'fr-shared/context';
import {
  useComparisonToolV2FeatureFlag,
  useMaintenanceModeFeatureFlag,
  usePortalBulkUploadFeatureFlag,
} from 'fr-shared/hooks';
import usePortalBulkConfigFeatureFlag from 'fr-shared/hooks/__mocks__/usePortalBulkConfigFeatureFlag';

import { AlertBanner, CookieBanner, Footer, ScrollToTop, Sidebar } from 'portal/components';
import useUnverifiedAlert from 'portal/hooks/useUnverifiedAlert';
import styles from 'portal/index.module.css';

import SalesQuotesContainer from '../sales_portal/pages/quotes';
import AccountHomeContainer from './pages/account';
import ForgotPasswordContainer from './pages/account/forgot_password';
import EditCart from './pages/cart/edit';
import CheckoutCartContainer from './pages/checkout/cart';
import ConfirmationContainer from './pages/checkout/confirmation';
import ManualQuoteConfirmationContainer from './pages/checkout/manual_quote_confirmation';
import CheckoutQuotesContainer from './pages/checkout/quotes';
import SubscriptionCheckoutContainer from './pages/checkout/subscription';
import SubscriptionConfirmation from './pages/checkout/subscription/components/SubscriptionConfirmation';
import ContactUsContainer from './pages/contact_us';
import RequestCallContainer from './pages/create_quote/request_call';
import RequestCallConfirmationContainer from './pages/create_quote/request_call_confirmation';
import DashboardContainer from './pages/dashboard';
import EvaluateContainer from './pages/evaluate';
import EvaluateComparePage from './pages/evaluate/compare';
import CompareContainer from './pages/evaluate/compare/CompareContainer';
import EvaluateComparePageV2 from './pages/evaluate/compare_v2';
import EvaluatePartContainer from './pages/evaluate/show';
import OrdersContainer from './pages/orders';
import OrderContainer from './pages/orders/show';
import PartConfigContainer from './pages/part_config';
import PartUploadContainer from './pages/part_upload';
import UploadFailContainer from './pages/part_upload_fail';
import PortalQuoteV2Container from './pages/part_upload_v2';
import PartsContainer from './pages/parts';
import QuotesContainer from './pages/quotes';
import ManualQuoteContainer from './pages/quotes/manual_show';
import QuoteContainer from './pages/quotes/show';

const CustomerPortal = () => {
  const { user } = useContext(UserContext);
  const maintenanceModeFeatureFlagEnabled = useMaintenanceModeFeatureFlag();

  // Check if a user hasn't verified yet, and display a banner
  useUnverifiedAlert();

  const evaluateComparePage = useComparisonToolV2FeatureFlag()
    ? EvaluateComparePageV2
    : EvaluateComparePage;

  const evaluateQuotePage = usePortalBulkUploadFeatureFlag()
    ? PortalQuoteV2Container
    : PartUploadContainer;

  const evaluatePartsPage = usePortalBulkConfigFeatureFlag() ? evaluateQuotePage : PartsContainer;

  return (
    <div>
      <Helmet>
        <body className="theme-dark" />
      </Helmet>
      <EnvironmentIndicator className="pl-10" />
      <div className={styles.Wrapper}>
        <ScrollToTop />
        <CookieBanner />
        <PhoenixSocketProvider>
          <Switch>
            <Route exact={true} path="/insights/:hashid" render={() => null} />
            <Route path="/checkout" render={() => null} />
            <PrivateRoute path="/" component={Sidebar} />
          </Switch>
          <main className={styles.Content}>
            <GlobalErrorBoundary>
              <div className={styles.PageWrapper}>
                <AlertBanner container="sm" />
                <Switch>
                  <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
                  <Redirect exact={true} from="/" to="/dashboard" />
                  <Redirect exact={true} from="/checkout" to="/checkout/shipping" />
                  <Redirect
                    exact={true}
                    from="/checkout/:id([0-9]+)"
                    to="/checkout/:id([0-9]+)/shipping"
                  />
                  <Redirect exact={true} from="/orders" to="/orders/active" />
                  <PrivateRoute
                    exact={true}
                    scrollToTop={true}
                    path="/checkout/confirmation/:hashid"
                    component={ConfirmationContainer}
                  />
                  <PrivateRoute
                    exact={true}
                    scrollToTop={true}
                    path="/checkout/confirmation/order/:id"
                    component={ManualQuoteConfirmationContainer}
                  />
                  <PrivateRoute
                    exact={true}
                    path="/subscription/confirmation/:id"
                    component={SubscriptionConfirmation}
                  />
                  <PrivateRoute
                    exact={true}
                    scrollToTop={true}
                    path="/checkout/:id([0-9]+)/shipping"
                    component={CheckoutQuotesContainer}
                  />
                  <PrivateRoute
                    exact={true}
                    scrollToTop={true}
                    path="/checkout/:id([0-9]+)/billing"
                    component={CheckoutQuotesContainer}
                  />
                  <PrivateRoute
                    exact={true}
                    scrollToTop={true}
                    path="/checkout/:id([0-9]+)/review"
                    component={CheckoutQuotesContainer}
                  />
                  <PrivateRoute
                    scrollToTop={true}
                    path="/checkout"
                    component={CheckoutCartContainer}
                  />
                  <PrivateRoute
                    scrollToTop={true}
                    path="/contact-us"
                    component={ContactUsContainer}
                  />
                  <PrivateRoute
                    exact={true}
                    scrollToTop={true}
                    path="/orders/:type(active|completed)"
                    component={OrdersContainer}
                  />
                  <PrivateRoute
                    exact
                    scrollToTop={true}
                    path={['/parts', '/parts/:id']}
                    component={evaluatePartsPage}
                  />
                  <PrivateRoute
                    exact={true}
                    scrollToTop={true}
                    path="/orders/:id"
                    component={OrderContainer}
                  />
                  <PrivateRoute
                    exact={true}
                    scrollToTop={true}
                    path="/quotes"
                    component={user.canViewSalesPortal ? SalesQuotesContainer : QuotesContainer}
                  />
                  <PrivateRoute
                    exact={true}
                    scrollToTop={true}
                    path="/quotes/draft"
                    component={EditCart}
                  />
                  <PrivateRoute
                    exact={true}
                    scrollToTop={true}
                    path="/quotes/:id"
                    component={QuoteContainer}
                  />
                  <PrivateRoute
                    exact={true}
                    scrollToTop={true}
                    path="/quotes/requested/:id"
                    component={ManualQuoteContainer}
                  />
                  <PrivateRoute
                    scrollToTop={true}
                    path="/part-config/:id"
                    component={PartConfigContainer}
                  />
                  <PrivateRoute
                    exact={true}
                    scrollToTop={true}
                    path="/add-part"
                    component={evaluateQuotePage}
                  />
                  <PrivateRoute
                    exact={true}
                    scrollToTop={true}
                    path="/edit-parts"
                    component={evaluateQuotePage}
                  />
                  <Redirect exact={true} scrollToTop={true} from="/create-quote" to="/add-part" />
                  <Redirect
                    exact={true}
                    scrollToTop={true}
                    from="/create-quote/3d-files"
                    to="/add-part"
                  />
                  <Redirect exact={true} scrollToTop={true} from="/upload-part" to="/add-part" />
                  <Redirect
                    exact={true}
                    scrollToTop={true}
                    from="/create-quote/call-us"
                    to="/contact-us"
                  />
                  <PrivateRoute
                    exact={true}
                    scrollToTop={true}
                    path="/create-quote/2d-files"
                    component={RequestCallContainer}
                  />
                  <PrivateRoute
                    exact={true}
                    scrollToTop={true}
                    path="/create-quote/2d-files/confirmation"
                    component={RequestCallConfirmationContainer}
                  />
                  <PrivateRoute
                    exact={true}
                    path={[
                      '/account',
                      '/account/details',
                      '/account/subscription/details',
                      '/account/payment-info',
                    ]}
                    component={AccountHomeContainer}
                  />
                  <PrivateRoute
                    exact={true}
                    path={[
                      '/subscription/frequency',
                      '/subscription/billing',
                      '/subscription/review',
                    ]}
                    component={SubscriptionCheckoutContainer}
                  />
                  <PrivateRoute exact={true} path="/forgot" component={ForgotPasswordContainer} />
                  <Redirect exact={true} from="/studio" to="/studio/evaluate" />
                  <Redirect
                    exact={true}
                    from="/studio/evaluate/:part_id"
                    to="/studio/evaluate/:part_id/dfm"
                  />
                  <PrivateRoute
                    scrollToTop={true}
                    path="/studio/evaluate/:part_id/dfm/:check_id?"
                    component={EvaluatePartContainer}
                  />
                  <PrivateRoute
                    scrollToTop={true}
                    path="/studio/evaluate/:part_id/materials/:material_id"
                    component={EvaluatePartContainer}
                  />
                  <PrivateRoute
                    scrollToTop={true}
                    path="/studio/evaluate/:part_id/costs"
                    component={EvaluatePartContainer}
                  />
                  <PrivateRoute
                    scrollToTop={true}
                    path="/studio/evaluate/:part_id/materials"
                    component={EvaluatePartContainer}
                  />
                  <PrivateRoute
                    scrollToTop={true}
                    path="/studio/evaluate/:part_id/compare"
                    component={evaluateComparePage}
                  />
                  <PrivateRoute
                    scrollToTop={true}
                    path="/studio/evaluate"
                    component={EvaluateContainer}
                  />
                  <PrivateRoute
                    scrollToTop={true}
                    path="/studio/compare"
                    component={CompareContainer}
                  />
                  <PrivateRoute
                    exact={true}
                    scrollToTop={true}
                    path="/studio/upload-fail"
                    component={UploadFailContainer}
                  />
                  <PrivateRoute
                    scrollToTop={true}
                    path="/dashboard"
                    component={DashboardContainer}
                  />
                  <Redirect to="/dashboard" />
                </Switch>
              </div>
            </GlobalErrorBoundary>
            <Switch>
              {maintenanceModeFeatureFlagEnabled && (
                <Redirect
                  from={[
                    '/dashboard',
                    '/checkout',
                    '/checkout/*',
                    '/orders',
                    '/orders/*',
                    '/subscription',
                    '/subscription/*',
                    '/contact-us',
                    '/parts',
                    '/parts/*',
                    '/quotes',
                    '/quotes/*',
                    '/part-config/*',
                    '/add-part',
                    '/create-quote',
                    '/create-quote/*',
                    '/upload-part',
                    '/account',
                    '/account/*',
                    '/forgot',
                    '/studio',
                    '/studio/*',
                  ]}
                  to="/maintenance"
                />
              )}
              <Route path="/part-config/:id" exact render={() => null} />
              <Route path="/studio/evaluate/:id" render={() => null} />
              <Route path="/edit-parts" render={() => null} />
              <PrivateRoute path="/" component={Footer} />
            </Switch>
          </main>
        </PhoenixSocketProvider>
      </div>
    </div>
  );
};

export default withAlert(CustomerPortal);
