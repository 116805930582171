import { IconFont } from '@fast-radius/shared-ui';
import React, { useState } from 'react';

import { Button, Modal, classNames } from 'portal/components';
import { PartFileRevision } from 'portal/lib/cart';
import DfmViewer from 'portal/pages/evaluate/dfm/DfmViewer/DfmViewer';

import CostingRecommendationsCarousel from '../CostingRecommendationsCarousel/CostingRecommendationsCarousel';
import styles from './CostingRecommendationsPartViewer.module.css';

interface Props {
  partFileRevision: PartFileRevision;
  initialDfmCheckIndex: number;
  allChecks: Array<ManufacturabilityCheck>;
  onClose: () => void;
  isOpen: boolean;
}

const CostingRecommendationsPartViewer = ({
  partFileRevision,
  initialDfmCheckIndex,
  allChecks,
  onClose,
  isOpen,
}: Props) => {
  const [activeDfmCheckIndex, setActiveDfmCheckIndex] = useState(initialDfmCheckIndex);

  const onChangeDfmCheck = (index: number) => {
    setActiveDfmCheckIndex(index);
  };

  return (
    <Modal
      contentClassName="bg-dark border-none"
      fullscreen={true}
      isOpen={isOpen}
      toggle={onClose}
    >
      {() => (
        <>
          <div className="absolute z-20 w-screen p-4">
            <div className="flex flex-row justify-between">
              <div className="flex flex-col">
                <span>File name</span>
                <h3>{partFileRevision?.part_name}</h3>
              </div>
              <Button
                onClick={onClose}
                size="md"
                className={'modal-close font-size-xl h-11 w-11'}
              >
                <IconFont name="close" />
              </Button>
            </div>
          </div>
          <div
            className={classNames([
              styles.RecommendationsPanel,
              'absolute z-10 bg-transparent flex flex-column overflow-y-auto overflow-x-hidden justify-center',
            ])}
          >
            <div className="flex flex-row">
              <span className={styles.Divider}></span>
              <div className="flex flex-col mr-4">
                <CostingRecommendationsCarousel
                  recommendations={allChecks}
                  displayScreenshot={false}
                  displaySlideCount={false}
                  activeSlide={activeDfmCheckIndex + 1}
                  onUpdateDfmCheckIndex={onChangeDfmCheck}
                />
              </div>
            </div>
          </div>
          <DfmViewer
            partFileRevision={partFileRevision}
            selectedDfmCheck={allChecks[activeDfmCheckIndex]}
            showDetails={false}
            showControls={true}
          />
        </>
      )}
    </Modal>
  );
};

export default CostingRecommendationsPartViewer;
