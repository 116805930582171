import { connect } from 'formik';
import { some } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { FormField, FormLocations } from 'fr-shared/components';
import {
  isExternalProcess,
  isInternalProcess,
  mfgProcessesWithEditableSupplierOnAutoquote,
} from 'fr-shared/lib/manufacturing_process';
import { isFormikAtPrefixTheSame } from 'fr-shared/utils';

import AdditionalCostingInputs from './AdditionalCostingInputs';
import CostingDocuments from './CostingDocuments';
import SupplierCostPartQuantity from './SupplierCostPartQuantity';

const SupplierCosts = ({
  fieldPrefix,
  canSubmitCosts,
  lineItem,
  supplierCost,
  supplierCostIndex,
  process,
}) => {
  const isExternal = isExternalProcess(process.manufacturing_process.name);

  const canAddAdditionalCostingInputs =
    canSubmitCosts && isInternalProcess(process.manufacturing_process.name);

  const isLocationReadonly = (supplierCost, supplierCostIndex) =>
    !canSubmitCosts ||
    (supplierCostIndex === 0 &&
      some(supplierCost.part_quantities, 'generated_per_unit_cost.formatted') &&
      !mfgProcessesWithEditableSupplierOnAutoquote.includes(
        process?.manufacturing_process?.name
      ));

  return (
    <>
      {isExternal && (
        <div className="row">
          <div className="col-md-6">
            <FormLocations
              name={`${fieldPrefix}.supplier_costs.${supplierCostIndex}.location_id`}
              required={true}
              readonly={
                isLocationReadonly(supplierCost, supplierCostIndex) &&
                `${fieldPrefix}.supplier_costs.${supplierCostIndex}.location.name`
              }
            />
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-md">
          {supplierCost.part_quantities?.map((partQuantity, poIndex) => (
            <SupplierCostPartQuantity
              fieldPrefix={fieldPrefix}
              canSubmitCosts={canSubmitCosts}
              key={`partQuantity-${poIndex}`}
              lineItemState={lineItem.state}
              name={`${fieldPrefix}.supplier_costs.${supplierCostIndex}.part_quantities.${poIndex}`}
              partQuantity={partQuantity}
              supplierCostIndex={supplierCostIndex}
            />
          ))}
        </div>

        {canAddAdditionalCostingInputs && (
          <AdditionalCostingInputs
            manufacturingProcess={process.manufacturing_process}
            fieldPrefix={fieldPrefix}
            readonly={!canSubmitCosts}
          />
        )}
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="mt-3">
            <FormField
              label="Costing Notes"
              md={4}
              name={`${fieldPrefix}.supplier_costs.${supplierCostIndex}.notes`}
              inputClassName="font-size-md"
              component="textarea"
              readonly={!canSubmitCosts}
            />
          </div>

          <CostingDocuments
            fieldPrefix={`${fieldPrefix}.supplier_costs.${supplierCostIndex}`}
            readonly={!canSubmitCosts}
            withLabel={true}
          />
        </div>
        <div className="col-md-6">
          <div className="mt-3">
            <FormField
              label="Supplier Notes"
              md={4}
              name={`${fieldPrefix}.supplier_costs.${supplierCostIndex}.supplier_notes`}
              inputClassName="font-size-md"
              component="textarea"
              readonly={!canSubmitCosts}
            />
          </div>
        </div>
      </div>
    </>
  );
};

SupplierCosts.propTypes = {
  canSubmitCosts: PropTypes.bool,
  fieldPrefix: PropTypes.string,
  formik: PropTypes.object,
  lineItem: PropTypes.object.isRequired,
  supplierCost: PropTypes.object.isRequired,
  supplierCostIndex: PropTypes.number.isRequired,
  process: PropTypes.object,
};

export default connect(React.memo(SupplierCosts, isFormikAtPrefixTheSame));
