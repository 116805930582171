import { useTreatments } from '@splitsoftware/splitio-react';
import React, { useContext, useEffect, useState } from 'react';

import { Alert, Button, Icon } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { USE_STALE_CLIENT_BANNER } from 'fr-shared/feature_flags';
import { useAssetsChecksum } from 'fr-shared/hooks';

const reloadWithoutCache = () => {
  // firefox still supports the deprecated forceGet param:
  // https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
  window.location.reload(true);
};

const VersionBanner = () => {
  const [visible, setVisible] = useState(false);
  const { isOpen } = useContext(AlertContext);
  const assetsChecksum = useAssetsChecksum();

  const {
    [USE_STALE_CLIENT_BANNER]: { treatment: treatment },
  } = useTreatments([USE_STALE_CLIENT_BANNER]);

  useEffect(() => {
    setVisible(assetsChecksum !== (process.env.ASSETS_CHECKSUM ?? null));
  }, [assetsChecksum]);

  if (visible && treatment === 'on') {
    return (
      <Alert color="primary" className={`${isOpen ? 'mb-1' : 'mb-0'} rounded-0`}>
        <div className="container">
          <div className="flex align-items-center">
            <Icon name="bell-exclamation" type="far" className="text-primary mr-2" />
            <strong className="flex-1">
              {"Good news! There's a new version of FROS. Reload the page to update."}
            </strong>
            <Button type="button" color="primary" size="sm" onClick={reloadWithoutCache}>
              <Icon name="redo" className="mr-1" />
              Reload
            </Button>
          </div>
        </div>
      </Alert>
    );
  }

  return null;
};

export default VersionBanner;
