import { Form, withFormik } from 'formik';
import React, { useContext } from 'react';

import { api } from 'fr-shared/api';
import { IconFont } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';

import { Alert, Button, Card, FormField, Link } from 'portal/components';

const ForgotPasswordForm = formik => {
  const { email } = formik.values;

  const { user } = useContext(UserContext);
  const isUserLoggedOut = !user;

  const handleResend = () =>
    api
      .post('/forgot', { email })
      .then(() => {
        formik.setStatus({ resend: true });
      })
      .catch(err => {
        formik.setErrors({ server: err.response?.data?.server });
      });

  return (
    <Card className={!isUserLoggedOut && 'self-center bg-[#111115] border-none mt-32'} size="lg">
      {formik.status?.success ? (
        <>
          <h1 className="h6 text-light mb-4">Please check your inbox</h1>
          <div className="font-size-base">
            <p className="text-lightgray">
              If the email you entered matches your account with us, you’ll get a link to quickly
              reset your password.
            </p>
            <p className="text-lightgray mb-4">
              Didn’t get the email? Please check your spam folder or{' '}
              <Link onClick={handleResend}>resend the email</Link>.
            </p>

            <Link to="/login">Go back to log in</Link>
          </div>
        </>
      ) : (
        <>
          {formik.errors?.server && (
            <Alert data-testid="error-alert" className="mb-5" color="danger">
              <IconFont name="error-triangle" className="mr-2" right />
              {formik.errors.server}
            </Alert>
          )}
          {formik.status?.resend && (
            <Alert className="mb-3" color="success">
              <IconFont name="check-circle" className="mr-2" right />
              We&apos;ve sent you a new email with a new link
            </Alert>
          )}
          <h1 className="h6 text-light mb-4">Forgotten your password?</h1>
          <p className="mb-3 font-size-md">
            No problem. Enter the email you created your account with. We’ll send you a link to
            securely reset your password.
          </p>
          <Form noValidate className="flex flex-column mb-5">
            <FormField label="Your business email" name="email" floatingLabel={true} required />
            <Button className="mt-2" type="submit">
              Send my reset link
            </Button>
          </Form>
          {isUserLoggedOut && (
            <div className="text-center">
              <div className="mb-2">
                <Link to="/login">Go back to log in</Link>
              </div>
              <div>
                <span>No account yet? &nbsp;</span>
                <Link to="/create-account/">Create account</Link>
              </div>
            </div>
          )}
        </>
      )}
    </Card>
  );
};

export default withFormik({
  // default values of form
  mapPropsToValues: () => ({
    email: '',
  }),
  validate: values => {
    const errors = {};
    if (!values.email) errors.email = 'Email Required';
    if (!values.email.includes('@')) errors.email = 'Email Required';
    return errors;
  },
  validateOnBlur: true,
  validateOnChange: false,
  handleSubmit: ({ email }, formik) => {
    api
      .post('/forgot', { email })
      .then(() => {
        formik.setStatus({ success: true });
      })
      .catch(err => {
        formik.setErrors({ server: err.response.data?.server });
      });
  },
})(ForgotPasswordForm);
