import { useTreatments } from '@splitsoftware/splitio-react';
import { FieldArray, useFormikContext } from 'formik';
import { defaultTo, find, isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { api } from 'fr-shared/api';
import {
  Alert,
  Button,
  Checkbox,
  FormDocuments,
  FormFastField,
  FormField,
  FormFieldBase,
  FormNumber,
  FormSelect,
  Icon,
  Modal,
} from 'fr-shared/components';
import { DocumentsContext, UserContext } from 'fr-shared/context';
import { CR_BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { useBoolTimer, useDuplicateLineItem, useForm, useInspectionTypes } from 'fr-shared/hooks';
import {
  COSTING_REQUEST_LI_STATES,
  COSTING_REQUEST_STATES,
  isDraft,
  stateCanDuplicateAndEditLineItem,
} from 'fr-shared/lib/costing_requests';
import { userIsCostingTeam } from 'fr-shared/lib/user_roles';

import {
  anyItemsSelected,
  countItemsSelected,
  everyItemSelected,
  findSelectedItems,
  hasPartialSelection,
} from '../utils/selected';
import { bulkEditDocuments, setInitialBulkSupportingDocuments } from '../utils/transforms';
import RequestedLineItems from './RequestedLineItems';
import { CostingFormContext } from './context/CostingFormContext';

const requestedLineItemsCount = items => items.filter(li => li.state === 'Requested').length;

const ALERT_SHOW_MS = 15000; // 15 seconds

const CostingLineItemBulkEditor = ({
  onDuplicateLineItems,
  onSubmitCosts,
  onConvertToQuote,
  onBulkUpdateState,
  isSubmitted,
  onDownloadDocuments,
}) => {
  const { [CR_BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';
  const [isBulkSelected, setIsBulkSelected] = useState(false);
  const [showDuplicateAlert, toggleDuplicateAlert] = useBoolTimer();
  const [showEditedAlert, toggleEditedAlert] = useBoolTimer();
  const formik = useFormikContext();
  const { lineItemFilters, lineItems } = useContext(CostingFormContext);
  const crIsDraft = isDraft(formik.values);

  const bulkSetItemSelection = (val, { updateChildren = false } = {}) => {
    setIsBulkSelected(val);
    let newLineItems = [];
    if (lineItemFilters.state === 'Completed' && updateChildren) {
      // sets all the build packages(or line items), supplier costs, and part quantities to selected

      newLineItems = formik.values.line_items.map(li =>
        li.state === lineItemFilters.state
          ? {
              ...li,
              build_package: {
                ...li.build_package,
                processes: [
                  {
                    ...li.build_package.processes[0],
                  },
                ],
              },
              selected: val,
              supplier_costs: li.supplier_costs.map(sc => ({
                ...sc,
                selected: val,
                part_quantities: sc.part_quantities.map(pq => ({ ...pq, selected: val })),
              })),
            }
          : li
      );
    } else if (crIsDraft) {
      newLineItems = formik.values.line_items.map(li => ({ ...li, selected: val }));
    } else {
      newLineItems = formik.values.line_items.map(li =>
        li.state === lineItemFilters.state ? { ...li, selected: val } : li
      );
    }

    formik.setFieldValue('line_items', newLineItems);
  };

  const handleDownloadDocuments = () => {
    onDownloadDocuments(findSelectedItems(lineItems));
  };

  const handleSubmitCosts = () => {
    onSubmitCosts(findSelectedItems(lineItems));
  };

  const handleBulkUpdateState = state => {
    onBulkUpdateState(findSelectedItems(lineItems), state);
  };

  const handleDuplicateLineItems = () => {
    onDuplicateLineItems(findSelectedItems(lineItems));
  };

  React.useEffect(() => {
    if (lineItems.length && !isBulkSelected && everyItemSelected(lineItems)) {
      bulkSetItemSelection(true);
    } else if (lineItems.length && isBulkSelected && !anyItemsSelected(lineItems)) {
      bulkSetItemSelection(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBulkSelected, lineItems]);

  return (
    <>
      <div
        className="mb-0 mr-auto flex align-items-center"
        data-testid="costing-request-page--bulk-editor-container"
      >
        <div>
          <Checkbox
            dataTestId="bulk-actions-checkbox"
            name="bulk_actions"
            label="Bulk Items Selection"
            className="d-inline"
            srOnlyLabel={true}
            indeterminate={hasPartialSelection(lineItems)}
            value={isBulkSelected}
            onChange={e => {
              bulkSetItemSelection(e.target.checked, { updateChildren: true });
            }}
          />
        </div>

        {anyItemsSelected(lineItems) &&
          (isSubmitted ? (
            <BulkActionButtons
              state={lineItems[0]?.state}
              onDuplicateLineItems={handleDuplicateLineItems}
              onSubmitCosts={handleSubmitCosts}
              onConvertToQuote={onConvertToQuote}
              onBulkUpdateState={handleBulkUpdateState}
              onDownloadDocuments={handleDownloadDocuments}
            />
          ) : (
            <DuplicateAndEdit
              onDuplicate={() => toggleDuplicateAlert(ALERT_SHOW_MS)}
              onEditsApplied={() => toggleEditedAlert(ALERT_SHOW_MS)}
            />
          ))}
        <span className="h4 d-inline-block text-muted">
          {anyItemsSelected(lineItems)
            ? `${countItemsSelected(lineItems)} ${lineItemName}s selected`
            : `Select ${lineItemName}s to reveal bulk actions`}
        </span>
      </div>
      {showEditedAlert && (
        <Alert className="border border-success mb-0 mr-2 px-2 py-1 rounded" color="success">
          <Icon name="check-circle" /> Edits applied
        </Alert>
      )}
      {showDuplicateAlert && (
        <Alert className="border border-success mb-0 mr-2 px-2 py-1 rounded" color="success">
          <Icon name="check-circle" /> Duplicated
        </Alert>
      )}
      {isSubmitted && (
        <RequestedLineItems requestedLineItemsCount={requestedLineItemsCount(lineItems)} />
      )}
    </>
  );
};

CostingLineItemBulkEditor.propTypes = {
  isSubmitted: PropTypes.bool,
  onDuplicateLineItems: PropTypes.func,
  onBulkUpdateState: PropTypes.func,
  onConvertToQuote: PropTypes.func,
  onSubmitCosts: PropTypes.func,
  onDownloadDocuments: PropTypes.func,
};

export default CostingLineItemBulkEditor;

const BulkActionButtons = ({
  onDuplicateLineItems,
  onConvertToQuote,
  onSubmitCosts,
  onBulkUpdateState,
  onDownloadDocuments,
  state,
}) => {
  const { user } = useContext(UserContext);

  const renderCostingTeamButtons = () => {
    if (!userIsCostingTeam(user)) return null;

    switch (state) {
      case COSTING_REQUEST_LI_STATES.InProgress:
        return (
          <>
            <Button
              className="mr-2"
              color="primary"
              onClick={() => onBulkUpdateState(COSTING_REQUEST_LI_STATES.Requested)}
            >
              Revert to Requested
            </Button>
            <Button className="mr-2" color="success" onClick={onSubmitCosts}>
              Submit Costs
            </Button>
          </>
        );
      case COSTING_REQUEST_LI_STATES.Requested:
        return (
          <Button
            className="mr-2"
            color="success"
            onClick={() => onBulkUpdateState(COSTING_REQUEST_LI_STATES.InProgress)}
          >
            Move to In Progress
          </Button>
        );
      case COSTING_REQUEST_LI_STATES.Completed:
        return (
          <Button
            className="mr-2"
            color="primary"
            onClick={() => onBulkUpdateState(COSTING_REQUEST_LI_STATES.InProgress)}
          >
            Edit Costs
          </Button>
        );
    }
  };

  return (
    <>
      {renderCostingTeamButtons()}
      {user.canDuplicateAndAddLineItemsToCostingRequests &&
        stateCanDuplicateAndEditLineItem(state) && (
          <Button className="mr-2" color="primary" onClick={onDuplicateLineItems}>
            Duplicate & Edit
          </Button>
        )}
      {user.canConvertCostingRequestsToQuotes && state === COSTING_REQUEST_STATES.Completed && (
        <Button className="mr-2" color="primary" onClick={onConvertToQuote}>
          Convert to Quote
        </Button>
      )}
      {
        <Button className="mr-2" color="primary" onClick={onDownloadDocuments}>
          Download
        </Button>
      }
    </>
  );
};

BulkActionButtons.propTypes = {
  onDuplicateLineItems: PropTypes.func,
  onBulkUpdateState: PropTypes.func,
  onConvertToQuote: PropTypes.func,
  onSubmitCosts: PropTypes.func,
  onDownloadDocuments: PropTypes.func,
  state: PropTypes.string,
};

const duplicateLineItemOverrides = lineItem => ({
  duplicated: true,
  id: null,
  selected: false,
  supplier_costs: lineItem.supplier_costs?.map(({ part_quantities }) => ({
    part_quantities: part_quantities?.map(({ per_unit_cost, quantity }) => ({
      per_unit_cost,
      quantity,
    })),
  })),
});

const DuplicateAndEdit = ({ onDuplicate = () => {}, onEditsApplied = () => {} }) => {
  const { [CR_BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';
  const [modalOpen, setModalOpen] = React.useState(false);
  const { lineItems } = useContext(CostingFormContext);
  const formik = useFormikContext();
  const { bulkDuplicateLineItems } = useDuplicateLineItem(
    'line_items',
    duplicateLineItemOverrides
  );

  const handleDuplicate = () => {
    bulkDuplicateLineItems(findSelectedItems(formik.values.line_items));
    onDuplicate();
  };

  return (
    <>
      <Button color="primary" className="mr-2" onClick={handleDuplicate}>
        Duplicate
      </Button>
      <Button
        color="primary"
        className="mr-2"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <Icon name="edit" right />
        Bulk Edit
      </Button>
      {modalOpen && (
        <Modal isOpen={true} scrollable={true} toggle={() => setModalOpen(false)}>
          <Modal.Header
            title={
              <span>
                Bulk Edit{' '}
                <small className="text-lowercase ml-2 font-weight-light">
                  {countItemsSelected(lineItems)} {lineItemName}s selected
                </small>
              </span>
            }
            onClose={() => setModalOpen(false)}
          />
          <div className="modal-body p-3">
            <BulkEditForm
              lineItems={findSelectedItems(lineItems)}
              onCancel={() => setModalOpen(false)}
              onApply={newItems => {
                const updatedItemsRepo = new Map(newItems.map(item => [item._id, item]));
                formik.setFieldValue(
                  'line_items',
                  formik.values.line_items.map(ogItem => {
                    const updatedItem = updatedItemsRepo.get(ogItem._id);
                    return updatedItem ? updatedItem : ogItem;
                  })
                );
                setModalOpen(false);
                onEditsApplied();
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

DuplicateAndEdit.propTypes = {
  onDuplicate: PropTypes.func,
  onEditsApplied: PropTypes.func,
};

const MIXED_LABEL = 'Mixed Selection...';
const MIXED_OPT = { id: 'MIXED', name: MIXED_LABEL, value: MIXED_LABEL, unit: '' };

const BulkEditForm = ({ lineItems, onCancel, onApply }) => {
  const { [CR_BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';
  const shouldSetupForm = React.useRef(true);
  const [availableProcesses, setAvailableProcesses] = React.useState([]);
  const s3Path = '/s3/sign/part_file';

  React.useEffect(() => {
    if (shouldSetupForm.current) {
      const initialValues = initialValuesFromLineItems(lineItems);
      setFieldValue('bulk', initialValues);
      shouldSetupForm.current = false;
    }
  });

  React.useEffect(() => {
    api.get('/manufacturing_process').then(res => setAvailableProcesses(res.data));
  }, []);

  const {
    setFieldValue,
    values: { bulk },
  } = useForm();

  const process = find(availableProcesses, { id: parseInt(bulk.processId, 10) });
  const availableMaterials = process?.materials || [];
  const material = find(availableMaterials, { id: parseInt(bulk.materialId, 10) });
  const availableColors = material?.colors || [];
  const color = find(availableColors, { id: parseInt(bulk.colorId) });
  const availableFinishes = material?.finishes || [];
  const finishes = find(availableFinishes, { id: parseInt(bulk.finishId) });
  const availableLayers = material?.layer_thicknesses || [];
  const layer = find(availableLayers, { id: parseInt(bulk.layerId) });
  const { data: availableInspectionTypes } = useInspectionTypes();
  const inspectionType = find(availableInspectionTypes, { id: bulk.inspectionTypeId });
  const quantities = bulk.quantities;

  const getOptions = (id, opts) => (id === MIXED_OPT.id ? [MIXED_OPT].concat(opts) : opts);

  const processOptions = getOptions(bulk.processId, availableProcesses);
  const materialOptions = getOptions(bulk.materialId, availableMaterials);
  const layerOptions = getOptions(bulk.layerId, availableLayers);
  const inspectionOptions = getOptions(bulk.inspectionTypeId, availableInspectionTypes);
  const finishOptions = getOptions(bulk.finishId, availableFinishes);

  const colorOptions =
    material?.name === 'UMA 90' ? [] : getOptions(bulk.colorId, availableColors);

  const hexColorPlaceholder = () => {
    if (bulk.hexColorMixed) {
      return MIXED_LABEL;
    } else if (!bulk.hexColor) {
      return 'FFFFFF';
    }
  };

  const allLineItemsHaveParts = () => {
    return lineItems.every(item => item.part?.current_revision);
  };
  return (
    <>
      <FormSelect label="MFG Process" name="bulk.processId" optionList={processOptions} />
      {!isEmpty(materialOptions) && (
        <FormSelect label="Material" name="bulk.materialId" optionList={materialOptions} />
      )}
      {!isEmpty(colorOptions) && (
        <FormSelect label="Color" name="bulk.colorId" optionList={colorOptions} />
      )}
      {material?.name === 'UMA 90' && (
        <FormField
          label="Hex Color"
          placeholder={hexColorPlaceholder()}
          name="bulk.hexColor"
          className="hex-color-input"
          append={
            <div
              style={{
                width: 40,
                height: 36,
                background: `#${bulk.hexColor}`,
              }}
            />
          }
        />
      )}
      {!isEmpty(layerOptions) && (
        <FormSelect
          label="Layer Thickness"
          name="bulk.layerId"
          nameAccessor={lt => `${lt.value} ${lt.unit}`}
          optionList={layerOptions}
        />
      )}
      <FormSelect
        label="Inspection Type"
        name="bulk.inspectionTypeId"
        optionList={inspectionOptions}
      />
      {!isEmpty(finishOptions) && bulk.materialId === MIXED_OPT.id ? (
        <FormSelect
          label="Finish"
          name="bulk.finishId"
          optionList={finishOptions}
          disabled="true"
        />
      ) : (
        <FormSelect label="Finish" name="bulk.finishId" optionList={finishOptions} />
      )}
      {bulk.finishId === MIXED_OPT.id ? (
        <FormFastField
          label="Finish Notes"
          name="bulk.finish"
          disabled="true"
          placeholder={bulk.finishMixed ? MIXED_LABEL : undefined}
        />
      ) : (
        <FormFastField
          label="Finish Notes"
          name="bulk.finish"
          onChange={e => {
            setFieldValue('bulk.finishDirty', true);
            setFieldValue('bulk.finish', e.target.value);
          }}
          placeholder={bulk.finishMixed ? MIXED_LABEL : undefined}
        />
      )}
      <FormFastField
        label="Notes"
        name="bulk.notes"
        onChange={e => {
          setFieldValue('bulk.notesDirty', true);
          setFieldValue('bulk.notes', e.target.value);
        }}
        placeholder={bulk.notesMixed ? MIXED_LABEL : undefined}
      />
      <FormFastField
        label="Internal Notes"
        name="bulk.internalNotes"
        onChange={e => {
          setFieldValue('bulk.internalNotesDirty', true);
          setFieldValue('bulk.internalNotes', e.target.value);
        }}
        placeholder={bulk.internalNotesMixed ? MIXED_LABEL : undefined}
      />
      {allLineItemsHaveParts() ? (
        <FormDocuments
          s3Endpoint={s3Path}
          fieldName="documents"
          fieldPrefix="bulk"
          label="Supporting Documents"
          readonly={false}
          showAlerts
        >
          <DocumentsContext.Consumer>
            {({ isUploading }) => (
              <div className="">
                <Button color="secondary" loading={isUploading}>
                  Upload Documents
                </Button>
              </div>
            )}
          </DocumentsContext.Consumer>
        </FormDocuments>
      ) : (
        <FormFieldBase label="Supporting Documents">
          <Alert color="danger">
            {`All selected ${lineItemName}s must have a 3D part file in order to bulk edit`}
          </Alert>
        </FormFieldBase>
      )}
      <FieldArray name={`bulk.quantities`}>
        {({ push, remove }) => (
          <div className="my-3">
            {quantities?.map((pq, pqIndex) => {
              return (
                <FormNumber
                  name={`bulk.quantities.${pqIndex}`}
                  placeholder={quantities[pqIndex] ? undefined : MIXED_LABEL}
                  type="number"
                  key={`partQuantity-quantity-${pqIndex}`}
                  min={1}
                  label={pqIndex === 0 ? 'Quantity' : ' '}
                  className="append-button"
                  onDelete={() => remove(pqIndex)}
                />
              );
            })}

            <div className="row form-group mt-2">
              <div className="col-md-4" />
              <div className="col-md">
                <Button
                  color="secondary"
                  onClick={() => push({ per_unit_cost: '', quantity: '' })}
                >
                  Add additional quantity
                </Button>
              </div>
            </div>
          </div>
        )}
      </FieldArray>
      <div className="flex justify-content-end border-top pt-3 mt-3">
        <Button
          color="secondary"
          outline={true}
          className="uppercase px-4"
          onClick={() => {
            setFieldValue('bulk', {});
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          className="ml-3 px-4 uppercase"
          onClick={() => {
            onApply(
              bulkTransformLineItems(lineItems, {
                ...bulk,
                process,
                material,
                color,
                finishes,
                layer,
                inspectionType,
                quantities,
              })
            );
          }}
        >
          Apply
        </Button>
      </div>
    </>
  );
};

BulkEditForm.propTypes = {
  onApply: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      manufacturing_process_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      material_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      color_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      finish_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      finish_note: PropTypes.string,
      notes: PropTypes.string,
    })
  ).isRequired,
};

const bulkTransformLineItems = (lineItems, bulkEdits) => {
  return lineItems.map(item => {
    const buildPackage = item.build_package;
    const process = item.build_package.processes[0];
    const processWithEdits = { ...process, bulk_edit_applied: true };
    const buildPackageWithEdits = { ...buildPackage, bulk_edit_applied: true };

    const lineItemWithEdits = { ...item, bulk_edit_applied: true };

    if (bulkEdits.process) {
      processWithEdits.manufacturing_process = bulkEdits.process;
      processWithEdits.manufacturing_process_id = bulkEdits.process.id;
      processWithEdits.type = bulkEdits.process.name;
    } else if (!bulkEdits.processId) {
      processWithEdits.manufacturing_process = null;
      processWithEdits.manufacturing_process_id = null;
      processWithEdits.type = null;
    }

    if (bulkEdits.material) {
      processWithEdits.material = bulkEdits.material;
      processWithEdits.material_id = bulkEdits.material.id;
    } else if (!bulkEdits.materialId) {
      processWithEdits.material = null;
      processWithEdits.material_id = null;
    }

    if (bulkEdits.color) {
      processWithEdits.color = bulkEdits.color;
      processWithEdits.color_id = bulkEdits.color.id;
    } else if (!bulkEdits.colorId) {
      processWithEdits.color = null;
      processWithEdits.color_id = null;
    }

    if (bulkEdits.hexColor) {
      processWithEdits.hex_color = bulkEdits.hexColor;
    }
    if (bulkEdits.finishes) {
      processWithEdits.finishes = bulkEdits.finishes;
      processWithEdits.finish_id = bulkEdits.finishId;
    } else if (!bulkEdits.finishId) {
      processWithEdits.finishes = null;
      processWithEdits.finish_id = null;
    }

    if (bulkEdits.layer) {
      processWithEdits.layer_thickness = bulkEdits.layer;
      processWithEdits.layer_thickness_id = bulkEdits.layer.id;
    } else if (!bulkEdits.layerId) {
      processWithEdits.layer_thickness = null;
      processWithEdits.layer_thickness_id = null;
    }

    if (bulkEdits.inspectionTypeId) {
      processWithEdits.inspection_type_id = bulkEdits.inspectionTypeId;
    } else if (!bulkEdits.inspectionTypeId) {
      processWithEdits.inspection_type_id = null;
    }

    if (bulkEdits.finishDirty) {
      processWithEdits.finish_note = bulkEdits.finish;
    }
    if (bulkEdits.notesDirty) {
      lineItemWithEdits.notes = bulkEdits.notes;
    }

    if (bulkEdits.internalNotesDirty) {
      lineItemWithEdits.internal_notes = bulkEdits.internalNotes;
    }

    if (bulkEdits.quantities) {
      const bulkEditQuantities = bulkEdits.quantities.filter(q => q != null);
      if (bulkEditQuantities.length > 0) {
        const partQuantities = bulkEditQuantities.map(quantity => {
          return { quantity };
        });
        lineItemWithEdits.supplier_costs[0].part_quantities = partQuantities;
      }
    }

    if (bulkEdits.documents && lineItemWithEdits.part) {
      lineItemWithEdits.part.documents = bulkEditDocuments(
        lineItemWithEdits.part.documents,
        bulkEdits.documents
      );
    }

    return {
      ...lineItemWithEdits,
      build_package: {
        ...buildPackageWithEdits,
        processes: [
          {
            ...processWithEdits,
          },
        ],
      },
    };
  });
};

const attrGetter = attr => obj => obj[attr];
const quantitiesGetter = item => {
  return item.supplier_costs[0].part_quantities
    .map(pq => parseInt(pq.quantity))
    .sort((a, b) => a - b);
};

const initialValuesFromLineItems = lineItems => {
  const getProcessId = attrGetter('manufacturing_process_id');
  const getMatId = attrGetter('material_id');
  const getColorId = attrGetter('color_id');
  const getFinishId = attrGetter('finish_id');
  const getLayerId = attrGetter('layer_thickness_id');
  const getInspType = attrGetter('inspection_type_id');
  const getHexColor = attrGetter('hex_color');

  const [firstItem, ...restItems] = lineItems;
  const initialValues = {
    processId: MIXED_OPT.id,
    materialId: MIXED_OPT.id,
    colorId: MIXED_OPT.id,
    hexColor: null,
    hexColorMixed: false,
    layerId: MIXED_OPT.id,
    inspectionTypeId: MIXED_OPT.id,
    finish: '',
    finishId: MIXED_OPT.id,
    finishMixed: true,
    notes: '',
    notesMixed: true,
    internalNotesMixed: true,
    quantities: [null],
    documents: [],
  };
  if (
    restItems.every(
      item =>
        getProcessId(item.build_package.processes[0]) ===
        getProcessId(firstItem.build_package.processes[0])
    )
  ) {
    initialValues.processId = getProcessId(firstItem.build_package.processes[0]);
  }

  if (
    restItems.every(
      item =>
        getMatId(item.build_package.processes[0]) ===
        getMatId(firstItem.build_package.processes[0])
    )
  ) {
    initialValues.materialId = getMatId(firstItem.build_package.processes[0]);
  }

  if (
    restItems.every(
      item =>
        getColorId(item.build_package.processes[0]) ===
        getColorId(firstItem.build_package.processes[0])
    )
  ) {
    initialValues.colorId = getColorId(firstItem.build_package.processes[0]);
  }
  if (
    restItems.every(
      item =>
        getFinishId(item.build_package.processes[0]) ===
        getFinishId(firstItem.build_package.processes[0])
    )
  ) {
    initialValues.finishId = getFinishId(firstItem.build_package.processes[0]);
  }

  if (
    restItems.every(
      item =>
        getHexColor(item.build_package.processes[0]) ===
        getHexColor(firstItem.build_package.processes[0])
    )
  ) {
    initialValues.hexColor = getHexColor(firstItem.build_package.processes[0]);
  } else {
    initialValues.hexColorMixed = true;
  }

  if (
    restItems.every(
      item =>
        getLayerId(item.build_package.processes[0]) ===
        getLayerId(firstItem.build_package.processes[0])
    )
  ) {
    initialValues.layerId = getLayerId(firstItem.build_package.processes[0]);
  }

  if (
    restItems.every(
      item =>
        getInspType(item.build_package.processes[0]) ===
        getInspType(firstItem.build_package.processes[0])
    )
  ) {
    initialValues.inspectionTypeId = getInspType(firstItem.build_package.processes[0]);
  }

  const firstItemNotes = defaultTo(firstItem.notes, '');
  if (restItems.every(item => defaultTo(item.notes, '') === firstItemNotes)) {
    initialValues.notes = firstItem.notes;
    initialValues.notesMixed = false;
  }

  const firstItemInternalNotes = defaultTo(firstItem.internal_notes, '');
  if (restItems.every(item => defaultTo(item.internal_notes, '') === firstItemInternalNotes)) {
    initialValues.internalNotes = firstItem.internal_notes;
    initialValues.internalNotesMixed = false;
  }

  const firstItemFinishNote = defaultTo(firstItem.build_package.processes[0].finish_note, '');
  if (
    restItems.every(
      item => defaultTo(item.build_package.processes[0].finish_note, '') === firstItemFinishNote
    )
  ) {
    initialValues.finish = firstItem.build_package.processes[0].finish_note;
    initialValues.finishMixed = false;
  }

  if (restItems.every(item => isEqual(quantitiesGetter(item), quantitiesGetter(firstItem)))) {
    initialValues.quantities = quantitiesGetter(firstItem);
  }

  initialValues.documents = setInitialBulkSupportingDocuments(lineItems);

  return initialValues;
};
