import { isEmpty, pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import {
  Button,
  Card,
  FormCheckbox,
  FormCustomerSelect,
  FormDocuments,
  FormField,
  FormFillDevButton,
  FormIncoterms,
  FormSelect,
  ServerError,
} from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { useForm } from 'fr-shared/hooks';
import { ORDER_PAYMENT_TERMS, ORDER_TYPES } from 'fr-shared/lib/orders';
import {
  ACTIVE_SHIPPING_METHODS_LIST,
  SHIPPING_PAYMENT_TYPES,
  SHIPPING_PAYMENT_TYPE_OPTIONS,
  shippingMethodToDisplayName,
} from 'fr-shared/lib/shipping';
import {
  PURCHASE_ORDER_FILE_EXTENSIONS,
  PURCHASE_ORDER_MAX_FILE_SIZE_MB,
} from 'fr-shared/utils/files';

import BraintreeDropIn from '../../orders/components/BraintreeDropIn';
import OrderBillingFields from '../../orders/components/OrderBillingFields';
import OrderShippingFields from '../../orders/components/OrderShippingFields';
import PaymentTypeFormField from '../../orders/components/PaymentTypeFormField';

const QuoteToOrder = ({ onSubmit, selectedShippingEstimate, toggle, isLoading }) => {
  const { values, errors, setFieldValue } = useForm();
  const [shippingAccounts, setShippingAccounts] = useState([]);
  const customerContactId = values.customer_contact?.id;
  const paymentMethod = values.payment_method;
  const selectedShippingAddress =
    selectedShippingEstimate && selectedShippingEstimate.shipping_address;
  useEffect(() => {
    api.get('/shipping_accounts').then(res => setShippingAccounts(res.data));
  }, []);

  useEffect(() => {
    if (!selectedShippingEstimate) return;

    const name = shippingMethodToDisplayName(selectedShippingEstimate.service);
    setFieldValue('order.shipping_method', name);
  }, [selectedShippingAddress, selectedShippingEstimate, setFieldValue]);

  useEffect(() => {
    if (paymentMethod) {
      setFieldValue('order.payment_type', 'Customer Credit Card');
      setFieldValue('order.payment_terms', 'Credit Card');
    }
  }, [paymentMethod, setFieldValue]);

  const closeModal = () => {
    toggle();
  };

  const handleUseBillingAddressAsShippingAddress = () => {
    const billingAddress = pick(values.order.billing_address, [
      'name_line_1',
      'country',
      'street_line_1',
      'street_line_2',
      'city',
      'state',
      'postal_code',
    ]);

    setFieldValue('order.shipping_address', {
      ...values.order.shipping_address,
      ...billingAddress,
    });
  };

  const showBraintreeDropIn =
    values.order.payment_type === 'Customer Credit Card' ||
    values.order.payment_terms === 'Credit Card';

  const shippingDisabledTooltipText = descriptor => {
    return selectedShippingEstimate
      ? `To update shipping ${descriptor}, return to the quote. Duplicate the quote to edit.`
      : null;
  };

  const { user } = useContext(UserContext);

  return (
    <div className="bg-light">
      <div className="bg-white border-bottom">
        <div className="container py-3 flex justify-content-between align-items-center">
          <h3 className="mb-0">Convert Quote #{values.id} to Order</h3>
          <div>
            <Button onClick={closeModal} color="light" className="border">
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              color="success"
              className="min-w-[110px] ml-2"
              loading={isLoading}
              showLoadingIcon={true}
            >
              Create Order
            </Button>
          </div>
        </div>
      </div>
      <div className="container py-3">
        <ServerError errors={errors.server} />
        <div className="row">
          <div className="col">
            <Card>
              <Card.Header>Customer Information</Card.Header>
              <Card.Body>
                <FormCustomerSelect
                  isCustomerNameEditable={false}
                  onCustomerContactChange={customerContact => {
                    setFieldValue('payment_method_nonce', null);
                    setFieldValue('order.customer_contact', customerContact);
                    setFieldValue('order.customer_contact_id', customerContact.id);

                    // prefill the contact name
                    setFieldValue('billing_address.name_line_1', customerContact?.name);
                    setFieldValue('shipping_address.name_line_1', customerContact?.name);
                  }}
                />
              </Card.Body>
            </Card>
          </div>
          <div className="col">
            <Card>
              <Card.Header>
                FR Information
                {
                  <div className="absolute mb-2" style={{ top: 0, right: 0, zIndex: 1000 }}>
                    <FormFillDevButton form="QUOTE_TO_ORDER" pathPrefix="order" />
                  </div>
                }
              </Card.Header>
              <Card.Body>
                <FormField
                  label="Order Type"
                  name="order.type"
                  component="select"
                  required={true}
                >
                  <option value="" />
                  {ORDER_TYPES.map(orderType => (
                    <option key={orderType} value={orderType}>
                      {orderType}
                    </option>
                  ))}
                </FormField>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>Additional Information</Card.Header>
              <Card.Body>
                <FormField label="Order Comments" name="order.comments" component="textarea" />
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Card>
              <Card.Header> Payment Information </Card.Header>
              <Card.Body>
                <PaymentTypeFormField fieldPrefix="order" />
                <FormField
                  label="Payment Terms"
                  name="order.payment_terms"
                  component="select"
                  required={true}
                >
                  <option value="" />
                  {ORDER_PAYMENT_TERMS.map(paymentTerm => (
                    <option key={paymentTerm} value={paymentTerm}>
                      {paymentTerm}
                    </option>
                  ))}
                </FormField>
                <FormField label="Customer PO" name="order.customer_po" />
                <FormDocuments
                  acceptedFileTypes={PURCHASE_ORDER_FILE_EXTENSIONS}
                  fieldName="po_documents"
                  fieldPrefix="order"
                  isRequired={values?.order?.type === 'Production'}
                  label="PO Documents"
                  maxSizeInBytes={PURCHASE_ORDER_MAX_FILE_SIZE_MB * 1000000}
                  s3Endpoint="/s3/sign/purchase_order_file"
                  showDate
                  showUploadContainer
                />
                {customerContactId && (
                  <div className={showBraintreeDropIn ? '' : 'hidden'}>
                    <div className="add-payment-disabled">
                      <BraintreeDropIn customerContactId={customerContactId} readonly={true} />
                      <p>
                        If there are no credit cards associated with this contact, send them the
                        quote to accept via the Customer Portal.
                      </p>

                      <p>
                        You can also send an email requesting credit card information via the
                        customer contact page. We no longer support manual credit card entry so we
                        can comply with Payment Card Industry Data Security Standards.
                      </p>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          </div>
          <div className="col">
            <Card>
              <Card.Header> Shipping Information </Card.Header>
              <Card.Body>
                <div className="mb-3">
                  <FormCheckbox label="Blind Ship" name="order.blind_ship" />
                </div>
                <FormField
                  component="select"
                  disabled={!isEmpty(values.shipping_payment_type)}
                  label="Shipping Payment Type"
                  name="order.shipping_payment_type"
                  required
                >
                  {SHIPPING_PAYMENT_TYPE_OPTIONS.map(paymentType => (
                    <option key={paymentType} value={paymentType}>
                      {paymentType}
                    </option>
                  ))}
                </FormField>
                {values.order.shipping_payment_type ===
                  SHIPPING_PAYMENT_TYPES.InvoiceToCustomer && (
                  <FormSelect
                    label="Shipping Account"
                    name="order.shipping_account_id"
                    optionList={shippingAccounts}
                    nameAccessor={shippingAccount =>
                      `${shippingAccount.label}: ${shippingAccount.account_number}`
                    }
                  />
                )}
                {values.order.shipping_payment_type ===
                  SHIPPING_PAYMENT_TYPES.CustomerAccount && (
                  <FormField
                    label="Shipping Account"
                    name="order.shipping_account_number"
                    required={true}
                  />
                )}
                <div data-rh={selectedShippingEstimate && shippingDisabledTooltipText('method')}>
                  <FormField
                    disabled={!!selectedShippingEstimate}
                    label="Shipping Method"
                    name="order.shipping_method"
                    component="select"
                    required={true}
                  >
                    <option value="" />
                    {ACTIVE_SHIPPING_METHODS_LIST.map(shippingMethod => (
                      <option key={shippingMethod} value={shippingMethod}>
                        {shippingMethod}
                      </option>
                    ))}
                  </FormField>
                </div>
                <FormField
                  label=" Supplier Shipping Method"
                  name="order.supplier_shipping_method"
                  component="select"
                >
                  <option value="" />
                  {ACTIVE_SHIPPING_METHODS_LIST.map(shippingMethod => (
                    <option key={shippingMethod} value={shippingMethod}>
                      {shippingMethod}
                    </option>
                  ))}
                </FormField>
                <FormIncoterms disabled={!user.canEditIncoterm} />
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Card>
              <Card.Header>Billing Address</Card.Header>
              <Card.Body>
                <OrderBillingFields fieldPrefix="order" customerContactId={customerContactId} />
              </Card.Body>
            </Card>
          </div>
          <div className="col">
            <Card>
              <Card.Header>
                Shipping Address
                <div className="flex ml-auto">
                  <Button
                    onClick={handleUseBillingAddressAsShippingAddress}
                    size="sm"
                    color="primary"
                  >
                    Use Billing Address
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>
                <div data-rh={selectedShippingAddress && shippingDisabledTooltipText('address')}>
                  <OrderShippingFields
                    disabled={!!selectedShippingAddress}
                    fieldPrefix="order"
                    initialAddress={selectedShippingAddress}
                    shippingAccounts={shippingAccounts}
                    customerContactId={customerContactId}
                  />
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

QuoteToOrder.propTypes = {
  onSubmit: PropTypes.func,
  selectedShippingEstimate: PropTypes.object,
  toggle: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default QuoteToOrder;
