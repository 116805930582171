import { useFormikContext } from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react';

import { IconFont, Link } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { pluralize } from 'fr-shared/utils';

import { ContactUsInfoBox, Money, PartImage, ShippingPriceLabel } from 'portal/components';
import { CartLineItem } from 'portal/lib/cart';
import { BILLING_CYCLE, SUBSCRIPTION } from 'portal/lib/plans';

import CheckoutNotification from './CheckoutNotification';

function renderShipDate(promisedShipDate: string) {
  if (!promisedShipDate) {
    return '-';
  }
  // Expect the ship date in the YYYY-MM-DD format coming from the backend
  return moment(promisedShipDate, 'YYYY-MM-DD').format('MMM D, YYYY');
}

interface CheckoutSummaryProps {
  analyticsContext?: string;
  canEdit?: boolean;
  discount?: string;
  isSubscriptionCheckout?: boolean;
  lineItems?: any[];
  partCount?: number;
  shippingPrice?: string;
  subtotal?: string;
  totalPrice: string;
  showNotifications?: boolean;
}

const CheckoutSummary = ({
  analyticsContext = 'Checkout',
  canEdit = true,
  discount,
  isSubscriptionCheckout = false,
  lineItems,
  partCount,
  shippingPrice,
  subtotal,
  totalPrice,
  showNotifications = false,
}: CheckoutSummaryProps) => {
  const userAnalytics = useUserAnalyticsContext();
  const formik = useFormikContext();
  const selectedBillingCycle = get(formik.values, 'subscription.billing_cycle');
  const planType = get(formik.values, 'subscription.subscription_type');

  const handleEditSideEffect = () => {
    userAnalytics.track(`${analyticsContext} - Edit Order Summary`);
  };

  const pushToStudio = (lineItem: CartLineItem) => {
    const searchParams = new URLSearchParams({
      cart_line_item_id: lineItem?.id?.toString(),
      manufacturing_process_id: lineItem?.manufacturing_process_id?.toString(),
      material_id: lineItem?.material_id?.toString(),
      color_id: lineItem?.color_id?.toString(),
      quantity: lineItem?.quantity?.toString(),
    });
    const studioUrl = encodeURI(
      `/studio/evaluate/${lineItem?.part?.id}/dfm?${searchParams.toString()}`
    );
    window.open(studioUrl, '_blank');
  };

  return (
    <>
      <div data-testid="checkout-summary" className="mb-3">
        <div className="flex mb-3 pb-1 border-bottom items-start">
          <div className="text-muted font-size-md">
            <h5 className="mb-0 font-weight-500">Order Summary</h5>
            <span className="text-gray-light">
              {!isSubscriptionCheckout && `${partCount} ${pluralize('part', partCount !== 1)}`}
            </span>
          </div>
          {canEdit && (
            <div className="ml-auto">
              <Link
                className="no-underline text-coolGray-300"
                to="/quotes/draft"
                onClick={handleEditSideEffect}
              >
                <IconFont name="pencil" className="text-2xl leading-none" />
              </Link>
            </div>
          )}
        </div>

        <>
          {lineItems?.map((lineItem: CartLineItem, index: number) => (
            <div key={index} className="bg-coolGray-800 my-2 rounded-md px-3 py-2">
              <div className="flex align-items-start">
                <PartImage
                  src={lineItem.part.current_revision?.screenshot}
                  backdrop={false}
                  size="sm"
                  className="mt-1"
                />
                <div className="ml-3 flex-1 font-size-md">
                  <div className="mb-1">
                    <strong className="break-all white-space-pre-wrap">
                      {lineItem.part.name}
                    </strong>
                  </div>
                  <div className="flex flex-1 align-items-start text-coolGray-100">
                    <div className="mr-2">
                      <div className="text-coolGray-300 font-size-sm">Est. ship date</div>
                      {renderShipDate(lineItem.promised_ship_date)}

                      <div className="whitespace-nowrap text-coolGray-300 font-size-sm mt-2">
                        Unit price
                      </div>
                      <Money amount={lineItem?.unit_price?.amount} />
                    </div>
                    <div className="ml-auto mr-3">
                      <div className="whitespace-nowrap text-coolGray-300 font-size-sm">
                        Quantity
                      </div>
                      {lineItem.quantity}

                      <div className="whitespace-nowrap text-coolGray-300 font-size-sm mt-2">
                        Item total
                      </div>
                      <Money className="font-bold" amount={lineItem?.total_price?.amount} />
                    </div>
                  </div>
                </div>
              </div>
              {showNotifications && (
                <CheckoutNotification
                  checkCounts={lineItem?.failed_manufacturability_check_counts}
                  className="mt-1"
                  onClick={() => pushToStudio(lineItem)}
                />
              )}
            </div>
          ))}
        </>

        {isSubscriptionCheckout && planType !== SUBSCRIPTION.BASIC && (
          <div>
            {selectedBillingCycle === BILLING_CYCLE.MONTHLY ? selectedBillingCycle : 'Annual'}{' '}
            subscription to <span>Fast Radius Pro</span>
          </div>
        )}

        {!isSubscriptionCheckout && (
          <div className="flex align-items-center mt-3 mb-1">
            <span className="font-size-sm text-muted">Items subtotal</span>
            <div className="ml-auto">
              <Money amount={subtotal} dataTestId="checkout-summary-subtotal" />
            </div>
          </div>
        )}

        {!isSubscriptionCheckout && (
          <div className="flex align-items-center mb-1">
            <span className="font-size-sm text-muted">
              <ShippingPriceLabel />
            </span>
            <div className="ml-auto">
              <Money amount={shippingPrice} dataTestId="checkout-summary-shipping" />
            </div>
          </div>
        )}

        {+discount > 0 && (
          <div className="flex align-items-center mb-1">
            <span className="font-size-sm text-muted">Discount</span>
            <div className="ml-auto">
              <Money
                amount={discount}
                dataTestId="checkout-summary-discount"
                className="text-success"
                isNegative={true}
                unstyledSymbol={true}
              />
            </div>
          </div>
        )}

        <div className="flex align-items-center">
          <span className="font-size-lg">Total</span>
          <div className="ml-auto">
            <div className="font-size-lg font-weight-bold">
              <Money amount={totalPrice} dataTestId="checkout-summary-total" />
            </div>
          </div>
        </div>
      </div>

      <ContactUsInfoBox className="bg-transparent hidden lg:block -mx-3" />
    </>
  );
};

export default CheckoutSummary;
