import PropTypes from 'prop-types';
import React from 'react';

import { IconFont, PartImage } from 'fr-shared/components';
import { keyboardDown } from 'fr-shared/utils';

import styles from './Part.module.css';

const Part = ({ onRemovePart, screenshotSrc }) => {
  return (
    <div className="relative mb-2 mr-2">
      <PartImage size="sm" src={screenshotSrc} />

      <div
        className={styles.RemovePart}
        onClick={onRemovePart}
        onKeyDown={evt => keyboardDown(evt, 'Enter', onRemovePart)}
        role="button"
        tabIndex={0}
      >
        <IconFont name="minus" />
      </div>
    </div>
  );
};

Part.propTypes = {
  onRemovePart: PropTypes.func,
  screenshotSrc: PropTypes.string,
};

export default Part;
