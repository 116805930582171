import { get, last } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { AlertContext } from 'fr-shared/context';

import Confirmation from './components/Confirmation';

const ConfirmationContainer = ({ history, match }) => {
  const [deal, setDeal] = useState(null);
  const { setAlert } = useContext(AlertContext);

  const hashid = get(match, 'params.hashid');

  useEffect(() => {
    api
      .get(`/customer_portal/deals/${hashid}`)
      .then(res => setDeal(res.data))
      .catch(error => {
        setAlert({
          color: 'danger',
          message:
            error.response?.status === 404
              ? `We couldn't find the order you're looking for. Please try again`
              : 'Something unexpected happened. Please try again.',
          autoClose: true,
        });
        history.push('/orders');
      });
  }, [history, hashid, setAlert]);

  if (!deal) return null;

  return (
    <Confirmation
      quote={deal.order || last(deal.quotes)}
      quoteNumber={deal.hashid}
      status={deal.status}
      paymentTerms={deal?.payment_terms}
      poNumber={deal?.customer_po}
    />
  );
};

ConfirmationContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      hashid: PropTypes.string.isRequired,
    }),
  }),
};

export default ConfirmationContainer;
