/* eslint-disable react/display-name, react/prop-types */
import React, { useState } from 'react';

import { api } from 'fr-shared/api';
import { Alert, Breadcrumb, Button, Helmet, Icon, Link, Table } from 'fr-shared/components';

const CustomersSubscribed = () => {
  const [isError, setIsError] = useState(false);
  const [subscribedCustomers, setSubscribedCustomers] = useState([]);
  const [totalSubscribed, setTotalSubscribed] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const handleFetchData = (tableState: any) => {
    setIsError(false);
    const page = tableState ? tableState.page + 1 : 1;

    api
      .get('/organizations/subscriptions', { params: { page: page } })
      .then(res => {
        setSubscribedCustomers(res.data);
        setTotalPages(parseInt(res.headers['total-pages']));
        setTotalSubscribed(parseInt(res.headers['total']));
      })
      .catch(() => setIsError(true));
  };

  return (
    <>
      {isError && (
        <Alert color="danger" toggle={true}>
          There is an error loading subscribed users. Please refresh and try again.
        </Alert>
      )}
      <Helmet title="Pro Customers" />
      <Breadcrumb to="/admin/customers/subscribed">Pro Customers</Breadcrumb>
      <div className="page-header">
        <div className="container">
          <h2>Pro Customers</h2>
        </div>
      </div>
      <div className="container">
        <div className="p-1">Total: {totalSubscribed}</div>
        <Table
          manual
          data={subscribedCustomers}
          pages={totalPages}
          sortable={false}
          className="bg-white -striped -left mb-5"
          noDataText="No pro customers found"
          onFetchData={handleFetchData}
          columns={[
            {
              Header: '',
              width: 45,
              filterable: false,
              sortable: false,
              expander: true,
              Expander: (row: any) => {
                return (
                  <div className="flex justify-content-between align-items-center">
                    {row?.original.users.length > 0 && (
                      <Button size="sm" color="secondary">
                        <i className={`fas fa-chevron-${row?.isExpanded ? 'up' : 'down'}`} />
                      </Button>
                    )}
                  </div>
                );
              },
            },
            {
              Header: 'Customer Name',
              accessor: 'name',
              Cell: (row: any) => {
                return (
                  <div className="text-left">
                    <Link to={`/admin/customers/${row.original.id}`}>{row.original.name}</Link>
                  </div>
                );
              },
            },
            {
              Header: 'Contacts',
              maxWidth: 100,
              id: 'contacts',
              accessor: (d: any) => (
                <div>
                  <Icon name="user" className="mr-1" />
                  {d.users.length}
                </div>
              ),
            },
            {
              Header: '',
              width: 70,
              sortable: false,
              Cell: (row: any) => (
                <div className="text-center">
                  <Link to={`/admin/customers/${row.original.id}`}>View</Link>
                </div>
              ),
            },
          ]}
          SubComponent={(row: any) => {
            const orgId = row.original.id;
            return (
              <Table
                data={row.original.users}
                defaultPageSize={row.original.users.length}
                className="-highlight -left"
                sortable={false}
                showPagination={false}
                columns={[
                  {
                    Header: 'Contact Name',
                    accessor: 'name',
                    Cell: (row: any) => {
                      return (
                        <div className="text-left">
                          <Link to={`/admin/customers/${orgId}/contact/${row.original.id}`}>
                            {row.original.name}
                          </Link>
                        </div>
                      );
                    },
                  },
                  { Header: 'Contact Email', accessor: 'email' },
                  { Header: 'Phone Number', accessor: 'phone_number' },
                  { Header: 'Braintree ID', accessor: 'braintree_id' },
                  {
                    Header: '',
                    filterable: false,
                    Cell: (row: any) => (
                      <div className="text-right">
                        <Link to={`/admin/customers/${orgId}/contact/${row.original.id}`}>
                          View
                        </Link>
                      </div>
                    ),
                  },
                ]}
              />
            );
          }}
        />
      </div>
    </>
  );
};

export default CustomersSubscribed;
