import { IconFont } from '@fast-radius/shared-ui';
import React from 'react';

import { Button, Modal } from 'portal/components';
import { PartFileRevision } from 'portal/lib/cart';
import DfmViewer from 'portal/pages/evaluate/dfm/DfmViewer/DfmViewer';

interface Props {
  partFileRevision: PartFileRevision;
  onClose: () => void;
  isOpen: boolean;
}

const PartFileViewer = ({ partFileRevision, onClose, isOpen }: Props) => {
  return (
    <Modal
      contentClassName="bg-dark border-none"
      fullscreen={true}
      isOpen={isOpen}
      toggle={onClose}
    >
      {() => (
        <>
          <div className="absolute z-20 w-screen p-4">
            <div className="flex flex-row justify-between">
              <div className="flex flex-col">
                <span>File name</span>
                <h3>{partFileRevision?.part_name}</h3>
              </div>
              <Button
                onClick={onClose}
                size="md"
                className={'modal-close font-size-xl h-11 w-11'}
              >
                <IconFont name="close" />
              </Button>
            </div>
          </div>
          <DfmViewer
            partFileRevision={partFileRevision}
            showDetails={false}
            showControls={true}
          />
        </>
      )}
    </Modal>
  );
};

export default PartFileViewer;
