import React from 'react';

import { Modal } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';

import InspectionTypeColumn from './InspectionTypeColumn';

interface Props {
  advancedInspectionPrice: null | Money;
  inspectionTypes: InspectionTypeForDefinition[];
  daysAmtFunc: (name: string) => string;
  isCncSelected: boolean;
}

interface InspectionTypeForDefinition {
  id: number;
  name: string;
  public_description: string;
  public_usecase_description: string;
  customer_portal_autoquoteable: boolean;
  price: Money;
}

const InspectionTypeDefinitionsModal = ({
  advancedInspectionPrice,
  inspectionTypes,
  daysAmtFunc,
}: Props) => {
  const userAnalytics = useUserAnalyticsContext();

  const trackOpen = () => userAnalytics.track('Part Config - Inspection Definitions');

  return (
    <Modal
      action={
        <div className="text-white font-size-sm mt-2 text-right cursor-pointer">
          <span className="underline">Definitions</span>
        </div>
      }
      onActionHandler={trackOpen}
      centered={false}
      className="modal-wide-xl"
    >
      {({ toggle }: { toggle: any }) => (
        <>
          <Modal.Header
            className="pt-5"
            title="Inspection types"
            onClose={toggle}
            textUppercase={false}
          >
            <div className="mt-1">
              See what each inspection includes, what it’s best for and how long it takes.
            </div>
            <div className="mt-3" style={{ borderBottom: '1px solid #3c4551' }}></div>
          </Modal.Header>
          <div className="modal-body">
            <div className="row">
              {inspectionTypes.map(inspectionType => (
                <InspectionTypeColumn
                  advancedInspectionPrice={advancedInspectionPrice}
                  daysAmtFunc={daysAmtFunc}
                  inspectionType={inspectionType}
                  key={inspectionType.id}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export { InspectionTypeDefinitionsModal, InspectionTypeForDefinition };

export default InspectionTypeDefinitionsModal;
