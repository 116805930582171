import DropIn from 'braintree-web-drop-in-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { api } from 'fr-shared/api';
import { Alert, Button, Loading } from 'fr-shared/components';

const BraintreeDropIn = React.forwardRef(
  ({ customerContactId, onInstance, onSaveCard, readonly }, braintreeRef) => {
    const localRef = useRef(null);
    const braintreeInstance = braintreeRef || localRef;

    const [braintreeToken, setBraintreeToken] = useState(null);
    const [braintreeError, setBraintreeError] = useState(false);

    useEffect(() => {
      setBraintreeToken(null);
      api
        .get(`/braintree_token?user_id=${customerContactId}`)
        .then(res => setBraintreeToken(res.data))
        .catch(() => setBraintreeError(true));
    }, [customerContactId]);

    const handleSaveCard = () => {
      // set the payment_method_nonce field value in the form
      if (braintreeInstance.current) {
        braintreeInstance.current.requestPaymentMethod().then(onSaveCard);
      }
    };

    const handleBraintreeInstance = instance => {
      braintreeInstance.current = instance;
      if (onInstance) onInstance(instance);
    };

    return (
      <>
        {braintreeToken ? (
          <div className={`braintree-dropin mt-4 ${readonly ? 'show' : ''}`}>
            <DropIn
              options={{
                authorization: braintreeToken,
                translations: {
                  chooseAnotherWayToPay: 'Add another payment method',
                  chooseAWayToPay: 'Add a payment method',
                  payingWith: '',
                },
                defaultFirst: true,
                card: {
                  cardholderName: { required: true },
                },
                options: {
                  defaultFirst: true,
                  cvv: true,
                  overrides: { fields: { cvv: true } },
                },
              }}
              preselectVaultedPaymentMethod={true}
              onInstance={handleBraintreeInstance}
            />
          </div>
        ) : (
          <>{!braintreeError && <Loading />}</>
        )}
        <div className="py-2">
          {braintreeError && (
            <Alert color="danger">
              <p>The customer could not be found in Braintree</p>
            </Alert>
          )}
          {!readonly && (
            <Button className="btn-block" color="primary" onClick={handleSaveCard}>
              Save Card
            </Button>
          )}
        </div>
      </>
    );
  }
);

BraintreeDropIn.displayName = 'BraintreeDropIn';

BraintreeDropIn.propTypes = {
  customerContactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onInstance: PropTypes.func,
  onSaveCard: PropTypes.func,
  readonly: PropTypes.bool,
};

export default BraintreeDropIn;
