import React from 'react';

import { COST_DRIVER_COLORS, CostDriver } from 'portal/lib/cost_drivers';

import ColorSwatchSVG from './ColorSwatchSVG';
import styles from './CostDriversDonutChartLegend.module.css';

interface Props {
  selectedSector: number;
  legendCallback: (index: number) => void;
  costDrivers: Array<CostDriver>;
}

const CostDriversDonutChartLegend = ({ selectedSector, legendCallback, costDrivers }: Props) => {
  return (
    <div className={styles.Legend}>
      <ul className={styles.LegendColumn}>
        {costDrivers.map((data: any, index: number) => {
          const findColor = COST_DRIVER_COLORS.find(
            color => data.cost_driver_name === color.cost_driver_name
          );

          const labelColor = findColor?.color;

          return (
            <button
              key={index}
              data-testid={`donut-chart-legend-${data.cost_driver_name}`}
              className={styles.ListItem}
              onClick={() => legendCallback(index)}
            >
              <li className={styles.LegendList}>
                {selectedSector === index ? (
                  <span style={{ marginRight: '12px' }}>
                    <ColorSwatchSVG active color={labelColor} />
                  </span>
                ) : (
                  <span style={{ marginRight: '16px' }}>
                    <ColorSwatchSVG color={labelColor} />
                  </span>
                )}
                {data.cost_driver_name}
              </li>
            </button>
          );
        })}
      </ul>
    </div>
  );
};
export default CostDriversDonutChartLegend;
