import moment from 'moment';
import React, { memo } from 'react';

import { Money } from 'fr-shared/components';

import {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableCustom,
  TableHead,
  TableHeadCell,
} from 'portal/components';

const SubscriptionPaymentHistory = ({
  transactions,
}: {
  transactions: SubscriptionTransaction[];
}) => {
  return (
    <TableCustom>
      <TableHead>
        <TableHeadCell value="Payment date" />
        <TableHeadCell value="Payment method" />
        <TableHeadCell value="Amount" />
        <TableHeadCell value="Plan name" />
        <TableHeadCell value="Transaction ID" />
        <TableHeadCell value="Status" />
      </TableHead>

      <TableBody>
        {transactions.map((transaction: SubscriptionTransaction) => (
          <TableBodyRow key={transaction.invoice_id}>
            <TableBodyCell>{moment(transaction.payment_date).format('MM/DD/YY')}</TableBodyCell>
            <TableBodyCell>Card ending in {transaction.last_4}</TableBodyCell>
            <TableBodyCell>
              <Money amount={transaction.amount.amount} />
            </TableBodyCell>
            <TableBodyCell>{transaction.plan_name}</TableBodyCell>
            <TableBodyCell>{transaction.invoice_id}</TableBodyCell>
            <TableBodyCell
              className={transaction.successful ? 'text-success-400' : 'text-error-400'}
            >
              {transaction.successful ? 'Complete' : 'Unsuccessful'}
            </TableBodyCell>
          </TableBodyRow>
        ))}
      </TableBody>
    </TableCustom>
  );
};

export default memo(SubscriptionPaymentHistory);
