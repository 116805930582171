import { get } from 'lodash';

export const lineItemDescriptionPrefix = workOrderLineItem => {
  switch (get(workOrderLineItem, 'spawned_from_part_failure.disposition')) {
    case 'Remake':
      return 'R - ';
    case 'Rework':
      return 'RW - ';
    default:
      return '';
  }
};

export const transformPartFailureToLineItem = partFailure => ({
  ...get(partFailure, 'work_order_line_item'),
  spawned_from_part_failure_id: partFailure.id,
  spawned_from_part_failure: partFailure,
  quantity: get(partFailure, 'quantity'),
  order_line_item_id: get(partFailure, 'work_order_line_item.order_line_item_id'),
  customer: {
    name: get(partFailure, 'work_order_line_item.order_line_item.order.customer.name'),
  },
});
