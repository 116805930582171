import PropTypes from 'prop-types';
import React from 'react';

import { Button, Modal } from 'fr-shared/components';

const ConfirmImportModal = ({ actionButton, onConfirm }) => (
  <Modal action={actionButton}>
    {({ toggle }) => (
      <>
        <Modal.Header title="Confirm Import" onClose={toggle} />
        <Modal.Body>
          <p>Are you sure you want to import costs from the CSV file?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button color="link" onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="success"
            className="ml-2"
            onClick={() => {
              onConfirm && onConfirm();
              toggle();
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </>
    )}
  </Modal>
);

ConfirmImportModal.propTypes = {
  actionButton: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmImportModal;
