import { useTreatments } from '@splitsoftware/splitio-react';
import { Form, useFormikContext, withFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { Button, FormTeamPicker, Prompt, ServerError } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { CR_BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { usePartUpdate, useUsersByRole } from 'fr-shared/hooks';
import useSalesforceEnabledFeatureFlag from 'fr-shared/hooks/useEnableSalesforceFeatureFlag';
import {
  COSTING_REQUEST_LI_STATES,
  LINE_ITEMS_ACCESSOR,
  isSubmitted,
} from 'fr-shared/lib/costing_requests';
import { ROLES } from 'fr-shared/lib/roles';

import { convertLineItemsFromFormik } from '../utils/convertLineItemsFromFormik';
import { transformCostingRequestForForm } from '../utils/transforms';
import BuildPackChooser from './BuildPackChooser/';
import CostingFormDetails from './CostingFormDetails';
import CostingFormHeader from './CostingFormHeader';
import CostingFormLineItems from './CostingFormLineItems';
import CostingFormOpportunityInformation from './CostingFormOpportunityInformation';
import CostingFormPartUpload from './CostingFormPartUpload';
import { CostingFormProvider } from './context/CostingFormContext';

const CostingForm = ({
  costingRequest,
  title,
  onSubmit,
  onArchive,
  onDuplicateLineItems,
  onSubmitTeam = () => {},
}) => {
  const formik = useFormikContext();
  const { user } = useContext(UserContext);
  usePartUpdate(LINE_ITEMS_ACCESSOR);

  const salesforceCallsEnabled = useSalesforceEnabledFeatureFlag();

  const isEditing = !!formik.values.id;
  const isCRSubmitted = isSubmitted(formik.values);
  const isCustomerSelected = formik.values.customer && formik.values.customer.id;
  const { [CR_BUILD_PACK_UI_SUPPORT]: crBuildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );

  // We have to use onSubmit here because we actually don't want to update the states on the form
  // until after the the api request resolves successfully.
  //
  // This prevents against a bug where we update a costing request line items state before submission
  // and the api request fails, but the line items are still in the updated state
  const handleSaveDraft = () => {
    const values = {
      sales_contact_id: user.id,
      ...formik.values,
      requested_at: null,
      line_items: convertLineItemsFromFormik(formik, COSTING_REQUEST_LI_STATES.Draft),
    };
    onSubmit(values, formik);
  };

  const { data: salesUsers } = useUsersByRole(ROLES.Sales);
  const { data: engineeringUsers } = useUsersByRole(ROLES.ApplicationEngineering);
  const { data: supportUsers } = useUsersByRole(ROLES.OperationsProjectManagement);

  const handleChange = entityType => contact => {
    const idField = `${entityType}_id`;
    let values = {};
    values[entityType] = contact;
    values[idField] = contact.id;
    formik.setValues({ ...formik.values, ...values });

    if (formik.values.id) {
      let requestParams = {
        id: formik.values.id,
        line_items: [],
        requested_at: moment(),
      };
      requestParams[idField] = contact.id;

      onSubmitTeam(requestParams, formik);
    }
  };

  const handleArchive = () => {
    const values = {
      id: formik.values.id,
      line_items: formik.values.line_items.map(li => ({
        ...li,
        state: COSTING_REQUEST_LI_STATES.Archived,
      })),
    };

    onArchive(values, formik);
  };

  const handleSubmit = () => {
    const values = {
      ...formik.values,
      line_items: convertLineItemsFromFormik(formik, COSTING_REQUEST_LI_STATES.Requested),
      requested_at: moment(),
    };
    onSubmit(values, formik);
  };

  const [useManualForm, setUseManualForm] = useState(false);
  return (
    <CostingFormProvider initialValues={costingRequest}>
      <Form>
        <Prompt
          message={
            'If you continue without saving, your updates to the costing request will be lost. Are you sure you want to continue without saving?'
          }
          when={!isEditing && formik.dirty && !formik.isSubmitting}
        />
        <div className="bg-white text-dark">
          <CostingFormHeader
            onSaveDraft={handleSaveDraft}
            onSubmit={handleSubmit}
            onArchive={handleArchive}
            title={title}
            setContact={handleChange}
            salesUsers={salesUsers ?? []}
            supportUsers={supportUsers ?? []}
            engineeringUsers={engineeringUsers ?? []}
          />
          <div className="container">
            <ServerError className="mt-3" errors={formik.errors?.server} />
            {!isCRSubmitted && (
              <div className="row my-3">
                <div className="col-9">
                  <>
                    {useManualForm || !salesforceCallsEnabled ? (
                      <CostingFormDetails />
                    ) : (
                      <CostingFormOpportunityInformation
                        useManualForm={() => setUseManualForm(true)}
                        formik={formik}
                      />
                    )}
                    {isCustomerSelected ? (
                      <>
                        {crBuildPackUiSupportFlag.treatment === 'on' ? (
                          <BuildPackChooser />
                        ) : (
                          <CostingFormPartUpload />
                        )}
                      </>
                    ) : (
                      <div className="card p-4 flex align-items-center justify-content-center bg-light">
                        <h4>Select a customer to get started</h4>
                      </div>
                    )}
                  </>
                </div>
                <div className="col-3">
                  <FormTeamPicker
                    entity="costing-request"
                    user={user}
                    salesUsers={salesUsers ?? []}
                    salesContact={formik.values.sales_contact || user}
                    engineeringUsers={engineeringUsers ?? []}
                    engineeringContact={formik.values.engineering_contact}
                    supportUsers={supportUsers ?? []}
                    supportContact={formik.values.support_contact}
                    handleChange={handleChange}
                    toggleable={false}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="container pb-5">
          <CostingFormLineItems onDuplicateLineItems={onDuplicateLineItems} />

          {!isCRSubmitted && !!formik.values?.line_items?.length && (
            <div className="flex justify-content-end">
              <Button
                onClick={handleSaveDraft}
                color="primary"
                className="mr-3 min-w-[94px]"
                loading={formik.isSubmitting}
                showLoadingIcon={true}
              >
                {isEditing ? 'Update ' : 'Save '}Draft
              </Button>
              <Button
                onClick={handleSubmit}
                color="success"
                loading={formik.isSubmitting}
                showLoadingIcon={true}
                className="min-w-[125px]"
              >
                Submit Request
              </Button>
            </div>
          )}
        </div>
      </Form>
    </CostingFormProvider>
  );
};

CostingForm.propTypes = {
  costingRequest: PropTypes.object,
  onDuplicateLineItems: PropTypes.func,
  onSubmit: PropTypes.func,
  onArchive: PropTypes.func,
  onSubmitTeam: PropTypes.func,
  title: PropTypes.string,
};

export default withFormik({
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: ({ costingRequest }) => {
    return transformCostingRequestForForm(costingRequest);
  },
  mapPropsToErrors: ({ costingRequest }) => {
    return costingRequest?.line_items
      ? {
          [LINE_ITEMS_ACCESSOR]: costingRequest.line_items.map(lineItemToErrors),
        }
      : {};
  },
})(CostingForm);

const lineItemToErrors = lineItem => {
  let partError = lineItem.part?.dimensional_check;
  return partError ? { id: partError } : {};
};
