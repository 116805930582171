import React, { useEffect, useState } from 'react';

import { IconFont, classNames } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { usePortalSubscription } from 'fr-shared/hooks';

import { Button, SubscriptionUpgradeCard } from 'portal/components';
import { PartFileRevision } from 'portal/lib/cart';
import { CostCurvePoint, CostDriver } from 'portal/lib/cost_drivers';
import {
  filterChecksByCostDriver,
  filterChecksByProcess,
  filterFailedErrorChecks,
  filterFailedWarningChecks,
  prioritizeChecksBySeverity,
} from 'portal/lib/manufacturability_check';

import styles from './CostCurves.module.css';
import { MAX_COST_DRIVER_COPY, NEW_COST_DRIVER_COPY } from './CostDriversCopy';
import CostDriversDonutChart from './CostDriversDonutChart';
import CostingRecommendationsCarousel from './CostingRecommendationsCarousel/CostingRecommendationsCarousel';
import CostingRecommendationsPartViewer from './CostingRecommendationsPartViewer/CostingRecommendationsPartViewer';
import ExpandedCostDrivers from './ExpandedCostDrivers';
import MachiningInfoSection from './MachiningInfoSection';

interface Props {
  manufacturingProcess: any;
  costCurveDriver: string;
  costDrivers: Array<CostDriver>;
  quantity: number;
  costCurvePoints: Array<CostCurvePoint>;
  machineType: string;
  machineTime: number;
  onCreateFeasibilityReport: () => void;
  partFileRevision: PartFileRevision;
  isFeasibilityReport: boolean;
}

const CostDrivers = ({
  manufacturingProcess,
  costCurveDriver = 'labor',
  costDrivers,
  quantity,
  costCurvePoints,
  machineTime,
  machineType,
  onCreateFeasibilityReport,
  partFileRevision,
  isFeasibilityReport = false,
}: Props) => {
  const isPortalSubscribed = usePortalSubscription();
  const [costDriver, setCostDriver] = useState(null);
  const [openPartViewerModal, setOpenPartViewerModal] = useState(false);
  const [activeCheckIndex, setActiveCheckIndex] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const userAnalytics = useUserAnalyticsContext();

  useEffect(() => {
    setIsExpanded(false);
  }, [manufacturingProcess]);

  const checksForMfgProcess = filterChecksByProcess(
    partFileRevision?.manufacturability_checks_v2 ?? [],
    manufacturingProcess?.id
  );
  const prioritizedChecks = prioritizeChecksBySeverity(checksForMfgProcess);
  const failedChecks = filterFailedErrorChecks(prioritizedChecks).concat(
    filterFailedWarningChecks(prioritizedChecks)
  );

  const failedCheckRecommendations =
    costDriver !== null ? filterChecksByCostDriver(failedChecks, costDriver).slice(0, 5) : [];

  const handleCostDriverCopy = (driver: CostDriver) => {
    setCostDriver(driver);
  };

  const handleExpandClick = () => {
    userAnalytics.track('Cost Insights - Expand Cost Drivers');
    setIsExpanded(true);
  };

  const onOpenPartViewerModal = (checkIndex: number) => {
    if (!isFeasibilityReport) {
      setOpenPartViewerModal(true);
      setActiveCheckIndex(checkIndex);
    }
  };

  const onClosePartViewerModal = () => {
    setOpenPartViewerModal(false);
    setActiveCheckIndex(null);
  };

  const perUnitCost: any = costCurvePoints?.find(
    (pt: any) => pt.quantity === quantity
  ).predicted_cost;

  const findMaxCostDriver = costDrivers.find(
    (driver: any) =>
      driver.percentage === Math.max(...costDrivers.map(highest => highest.percentage))
  );
  const maxCostDriverName = findMaxCostDriver?.cost_driver_name;

  if (!costCurveDriver) return null;

  return (
    <>
      {openPartViewerModal && (
        <CostingRecommendationsPartViewer
          partFileRevision={partFileRevision}
          initialDfmCheckIndex={activeCheckIndex}
          allChecks={failedCheckRecommendations}
          onClose={onClosePartViewerModal}
          isOpen={openPartViewerModal}
        />
      )}
      <article className="p-4">
        <>
          <div className="flex flex-row justify-content-between">
            <h3 className="border-0 mb-3">
              ${perUnitCost.toFixed(2)} per unit{' '}
              {quantity ? (quantity === 1 ? `@ ${quantity} unit` : `@ ${quantity} units`) : null}
            </h3>
            <div className="flex flex-row">
              {isExpanded ? (
                <Button
                  color="link"
                  disabled={!isPortalSubscribed}
                  onClick={() => setIsExpanded(false)}
                  className="text-decoration-none text-white outline-none mb-3 border-right rounded-none"
                >
                  <span className="font-size-md">Collapse</span>
                  <IconFont name="collapse-row" className="ml-1 mr-3" />
                </Button>
              ) : (
                <Button
                  color="link"
                  dataTestId="costing-expand-button"
                  disabled={!isPortalSubscribed}
                  onClick={() => handleExpandClick()}
                  className="text-decoration-none text-white outline-none mb-3 border-right rounded-none"
                >
                  <span className="font-size-md">Expand</span>
                  <IconFont name="expand-row" className="ml-1 mr-3" />
                </Button>
              )}
              {onCreateFeasibilityReport && (
                <Button
                  color="link"
                  disabled={!isPortalSubscribed}
                  onClick={onCreateFeasibilityReport}
                  className="text-decoration-none text-white outline-none mb-3 ml-3"
                >
                  <span className="font-size-md">Create report</span>
                  <IconFont name="external-link" className={styles.CreateReportButton} />
                </Button>
              )}
            </div>
          </div>
          <span className={styles.HorizontalDivider} />
        </>{' '}
        {!isExpanded ? (
          <>
            <div>
              <div style={{ alignItems: 'center' }}>
                <IconFont
                  name="icon-quote"
                  className="icon-quote mr-1"
                  style={{ fontSize: '20px' }}
                />
                <span style={{ fontSize: '16px' }}>Cost drivers</span>
              </div>
            </div>
            <div className="flex flex-row justify-content-center font-size-md">
              <section
                className={classNames([styles.CostDriverSection, 'justify-content-center'])}
              >
                <CostDriversDonutChart
                  onDriverUpdate={handleCostDriverCopy}
                  costDrivers={costDrivers}
                  selectedDriver={costDriver}
                  renderLegend
                />
              </section>
              <span className={classNames([styles.NewDivider, 'mx-4', 'mt-2'])} />

              <section className={styles.CostDriverSection}>
                <div className="mt-2">
                  {costDriver !== null ? (
                    <>
                      {
                        NEW_COST_DRIVER_COPY[manufacturingProcess?.name][
                          costDriver?.cost_driver_name
                        ]
                      }
                      {costDriver.cost_driver_name === 'equipment' && (
                        <div className="mt-4">
                          <MachiningInfoSection
                            machineTime={machineTime}
                            machineType={machineType}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="mb-2 text-coolGray-300">Highest Cost Driver</div>
                      <div>
                        {
                          MAX_COST_DRIVER_COPY[manufacturingProcess?.name][maxCostDriverName][
                            costDriver
                          ]
                        }
                      </div>
                    </>
                  )}
                </div>
              </section>
              <span className={classNames([styles.NewDivider, 'mx-4', 'mt-2'])} />
              {isPortalSubscribed ? (
                <>
                  {costDriver !== null ? (
                    <>
                      {failedCheckRecommendations.length > 0 ? (
                        <>
                          <section className={styles.CostDriverSection}>
                            <div className="mt-2" data-testid="costing-recommendations-section">
                              <CostingRecommendationsCarousel
                                recommendations={failedCheckRecommendations}
                                partScreenshotSrc={partFileRevision.screenshot}
                                displayScreenshot={true}
                                displaySlideCount={true}
                                onOpenPartViewerModal={onOpenPartViewerModal}
                              />
                            </div>
                          </section>
                        </>
                      ) : (
                        <>
                          <section style={{ minWidth: '220px' }}></section>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <section className={styles.CostDriverSection}>
                        <div className="mt-2">
                          <div
                            className="mb-2 text-coolGray-300"
                            data-testid="costing-recommendations-section"
                          >
                            Recommendations
                          </div>
                          <div data-testid="no-cost-driver-selected">
                            <NoCostDriversSelectedSection />
                          </div>
                        </div>
                      </section>
                    </>
                  )}
                </>
              ) : (
                <>
                  <section className={styles.CostDriverSection}>
                    <div className="mt-2" data-testid="cost-driver-upgrade-subscription">
                      <div className="mb-2 text-coolGray-300">Recommendations</div>
                      <SubscriptionUpgradeCard analyticsContext="Cost Insights - Clicked Upgrade" />
                    </div>
                  </section>
                </>
              )}
            </div>
          </>
        ) : (
          <ExpandedCostDrivers
            costDrivers={costDrivers}
            failedChecks={failedChecks}
            handleCostDriverCopy={handleCostDriverCopy}
            machineTime={machineTime}
            machineType={machineType}
            manufacturingProcess={manufacturingProcess}
            onOpenPartViewerModal={onOpenPartViewerModal}
            partFileRevision={partFileRevision}
            setCostDriver={setCostDriver}
          />
        )}
      </article>
    </>
  );
};

const NoCostDriversSelectedSection = () => {
  return (
    <div className="list-none bg-coolGray-700 p-2 rounded-[8px] flex flex-row mb-[4px]">
      <IconFont name="evaluate-donut" className={classNames(['text-2xl mr-2 text-white'])} />
      <div>
        <p className="text-white">Choose a cost driver to view recommendations</p>
      </div>
    </div>
  );
};

export default CostDrivers;
