import { intersection, map, startCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { api } from 'fr-shared/api';
import { Button, Checkbox, Icon } from 'fr-shared/components';
import { hasPermission, hasPermissionDirectly, hasRole } from 'fr-shared/lib/user_roles';

const RolesEditForm = ({ permissions, roles, user, onClearUser, onUpdateUser }) => {
  const handleRoleChange = role => {
    const userHasRole = hasRole(user, role.name);

    const apiCall = userHasRole
      ? api.delete('/user_roles', { params: { user_id: user.id, role_id: role.id } })
      : api.post('/user_roles', { user_role: { user_id: user.id, role_id: role.id } });

    apiCall.then(() => {
      onUpdateUser();
    });
  };

  const handlePermissionChange = permission => {
    const userHasPermission = hasPermissionDirectly(user, permission.name);

    const apiCall = userHasPermission
      ? api.delete('/user_permissions', {
          params: { user_id: user.id, permission_id: permission.id },
        })
      : api.post('/user_permissions', {
          user_permission: { user_id: user.id, permission_id: permission.id },
        });

    apiCall.then(() => {
      onUpdateUser();
    });
  };

  // returns the roles, if any, through which the user has the given permission
  const userRolesForPermission = permission => {
    return intersection(map(permission.roles, 'name'), map(user.roles, 'name'));
  };

  const hasPermissionThroughRole = permission => {
    return userRolesForPermission(permission).length > 0;
  };

  return (
    <div className="container">
      <div className="card">
        <div className="card-header">
          <h3 className="flex-1 flex align-items-center">
            <Icon name="user" right />
            {user.email}
            <div className="ml-auto">
              <Button onClick={onClearUser} size="sm">
                Clear
              </Button>
            </div>
          </h3>
        </div>
        <div className="flex">
          <div className="flex-1">
            <div className="card-body">
              <h3>Roles</h3>
              {roles.map(role => (
                <div key={role.id}>
                  <Checkbox
                    name={role.name}
                    label={
                      <div>
                        {role.name}
                        <div className="py-1">
                          {role.permissions.map(permission => (
                            <div key={permission.id} className="font-size-xs text-muted mr-2">
                              {startCase(permission.name)}
                            </div>
                          ))}
                        </div>
                      </div>
                    }
                    key={`user_role_${role.id}`}
                    className="flex-1"
                    onChange={() => handleRoleChange(role)}
                    value={hasRole(user, role.name)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex-1">
            <div className="card-body">
              <h3>Permissions</h3>
              {permissions.map(permission => (
                <div key={`user_permission_${permission.id}`}>
                  <Checkbox
                    name={permission.name}
                    label={
                      <div>
                        {startCase(permission.name)}
                        <div>
                          {userRolesForPermission(permission).map(roleName => (
                            <span key={roleName} className="font-size-xs text-muted mr-2">
                              {roleName}
                            </span>
                          ))}
                        </div>
                      </div>
                    }
                    readonly={true}
                    onChange={() => handlePermissionChange(permission)}
                    disabled={hasPermissionThroughRole(permission)}
                    value={hasPermission(user, permission.name)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RolesEditForm.propTypes = {
  onClearUser: PropTypes.func,
  permissions: PropTypes.array,
  roles: PropTypes.array,
  user: PropTypes.object,
  onUpdateUser: PropTypes.func,
};

export default RolesEditForm;
