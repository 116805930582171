import DropIn from 'braintree-web-drop-in-react';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { api } from 'fr-shared/api';
import { UserContext, useUserAnalyticsContext } from 'fr-shared/context';

import { Alert, Button, Loading, classNames } from 'portal/components';

import styles from './AccountPaymentMethod.module.css';

const AccountPaymentMethod = ({ onlyShowPaymentInfo = false, onInstance, onSaveCard }) => {
  const braintreeRef = useRef(null);

  const { user } = useContext(UserContext);
  const userAnalytics = useUserAnalyticsContext();
  const braintreeInstance = braintreeRef;

  const [braintreeToken, setBraintreeToken] = useState(null);
  const [braintreeError, setBraintreeError] = useState(false);

  useEffect(() => {
    setBraintreeToken(null);
    api
      .get(`/braintree_token?user_id=${user.id}`)
      .then(res => setBraintreeToken(res.data))
      .catch(() => setBraintreeError(true));
  }, [user]);

  const handleSaveCard = () => {
    userAnalytics.track('AS - Add Credit Card');
    if (braintreeInstance.current) {
      braintreeInstance.current.requestPaymentMethod().then(onSaveCard);
    }
  };

  const handleBraintreeInstance = instance => {
    braintreeInstance.current = instance;
    if (onInstance) onInstance(instance);
  };

  return (
    <div className="mt-3">
      {!onlyShowPaymentInfo && (
        <div className="border-bottom mb-4 py-2">
          <h5>Saved Payment Information</h5>
        </div>
      )}
      {braintreeToken ? (
        <div
          className={classNames([
            onlyShowPaymentInfo && styles.HideElement,
            styles.OverrideStyles,
            'account-dropin braintree-dropin',
          ])}
        >
          <DropIn
            options={{
              authorization: braintreeToken,
              translations: {
                chooseAnotherWayToPay: 'Add another payment method',
                chooseAWayToPay: 'Add a payment method',
                payingWith: '',
              },
              defaultFirst: true,
              card: {
                cardholderName: { required: true },
                overrides: {
                  styles: {
                    input: {
                      color: '#f5f5f5',
                    },
                    ':focus': {
                      color: '#f5f5f5',
                    },
                  },
                },
              },
              options: {
                defaultFirst: true,
                cvv: true,
                overrides: { fields: { cvv: true } },
              },
            }}
            preselectVaultedPaymentMethod={true}
            onInstance={handleBraintreeInstance}
          />
        </div>
      ) : (
        !braintreeError && <Loading />
      )}
      <div className="py-2 mr-[15px]">
        {braintreeError && (
          <Alert color="danger">
            <p>An unexpected error occured with our payment information provider.</p>
          </Alert>
        )}
        {braintreeToken && braintreeInstance && !onlyShowPaymentInfo && (
          <Button
            outline
            className="px-5 py-0 float-right h-4 hover:font-bold"
            onClick={handleSaveCard}
          >
            Save Card
          </Button>
        )}
      </div>
    </div>
  );
};

AccountPaymentMethod.displayName = 'AccountPaymentMethod';

AccountPaymentMethod.propTypes = {
  onlyShowPaymentInfo: PropTypes.bool,
  onInstance: PropTypes.func,
  onSaveCard: PropTypes.func,
};

export default AccountPaymentMethod;
