import React from 'react';

import { IconFont } from 'fr-shared/components';

export const ShippingPriceLabel = () => {
  return (
    <>
      Shipping &amp; taxes
      <IconFont
        tooltip="Your total shipping cost includes all relevant taxes. If your order is arriving from outside your country, these taxes may include international duties or tariffs."
        name="info-circle"
        className="ml-1 text-white"
      />
    </>
  );
};

export default ShippingPriceLabel;
