import { find } from 'lodash';
import React, { PropsWithChildren, SyntheticEvent, useEffect, useState } from 'react';

import { TooltipContent, TooltipControlled } from 'fr-shared/components/core/Tooltip/Tooltip';
import { usePortalSubscription } from 'fr-shared/hooks';
import { keyboardDown } from 'fr-shared/utils';

import {
  FormFieldBase,
  NumberInputField,
  SubscriptionUpgradeCard,
  ToggleSwitch,
  classNames,
} from 'portal/components';
import { isAutoquoteLoading, isAutoquoteTierList } from 'portal/lib/autoquote';

import { ColumnState, LeadTimeFields } from '.';
import { PartConfig } from '../viewer';
import Accordion from './Accordion';
import CompareActions from './CompareActions';
import styles from './CompareContainer.module.css';
import ManufacturingSpecificationFields, {
  getMaterialInfo,
} from './ManufacturingSpecificationFields';
import CostCurvesCard, { DriverStats } from './components/CostCurvesCard';
import TierPriceCard from './components/TierPriceCard';
import DfmChecks from './components/dfmChecks/DfmChecks';
import Material from './components/material';

// NOTE: Keep this in sync with the gap inbetween columns in CompareContainer.module.css for scrolling calculations
const COL_GAP = 48;

// NOTE: Keep this in sync with the column width in CompareContainer.module.css
const COL_WIDTH = 330;

// NOTE: Keep this in sync with the transition duration in CompareContainer.module.css for column transition
const UNDO_DELAY = 500;

type ColumnHandler = (columnId: string) => void;

export interface BaseCompareContainerProps {
  colFieldData: ColumnState[];
  closeDfmPanel: () => void;
  dfmChecks: ManufacturabilityCheck[] | null;
  dfmCheckOnClick: (
    colId: string,
    dfmCheck: ManufacturabilityCheck,
    pushToEvaluateParams: PartConfig
  ) => void;
  manufacturingProcesses: ManufacturingProcess[];
  handleBack: () => void;
  handleFieldChanges: (changes: Partial<ColumnState>, index: number) => void;
  onChangeQuantity: (quantity: number) => void;
  onCheckStandard: (isStandard: boolean) => void;
  pushToEvaluate: (params: PartConfig) => void;
  selectedDfmCheck: { colId?: string; checkId?: number };
  selectionText?: string;
  leadTimeFields: LeadTimeFields;
}

interface ShiftHandlerProps {
  onDelete: ColumnHandler;
  onShiftLeft: ColumnHandler;
  onShiftRight: ColumnHandler;
  onShiftCancel: () => void;
  onShiftDone: () => void;
  onSeeMore?: ColumnHandler;
  onSelectConfig?: ColumnHandler;
}

type CompareContainerProps = BaseCompareContainerProps & ShiftHandlerProps;

export const scrollColumnIntoView = (containerEl: HTMLDivElement, target: HTMLElement) => {
  // bring the target just into view, with the left-most column align snapped to the left edge
  const xGap = COL_GAP;

  // get the position and width in the view port
  const { x: tx, width: targetWidth } = target.getBoundingClientRect();
  const { x: cx, width: containerWidth } = containerEl.getBoundingClientRect();
  // determine how many columns actually fit in the container (minimum 0 aligns the target to the left edge)
  const numColumnsInView = Math.max(Math.floor(containerWidth / (targetWidth + xGap)) - 1, 0);

  const isOverflowRight = tx + targetWidth > cx + containerWidth;
  const isOverflowLeft = tx < cx;

  if (isOverflowRight) {
    // start from the left and then work backwards by the num of columns we want in view
    containerEl.scrollTo({
      left: target.offsetLeft - numColumnsInView * (targetWidth + xGap),
      top: containerEl.scrollTop,
      behavior: 'smooth',
    });
  } else if (isOverflowLeft) {
    // just align the left edge
    containerEl.scrollTo({
      left: target.offsetLeft,
      top: containerEl.scrollTop,
      behavior: 'smooth',
    });
  }
};

interface panelScrollColumnIntoViewProps {
  containerEl: HTMLDivElement;
  childEl: HTMLDivElement;
}

export const panelScrollColumnIntoView = ({
  containerEl,
  childEl,
}: panelScrollColumnIntoViewProps) => {
  // Keep this width in sync with the width of the DFM panel
  const PANEL_WIDTH = 420;
  const { x: tx, width: targetWidth } = childEl.getBoundingClientRect();

  const { x: cx, width: containerWidth } = containerEl.getBoundingClientRect();

  if (tx + targetWidth > cx + containerWidth - PANEL_WIDTH) {
    containerEl.scrollTo({
      left: childEl.offsetLeft - (containerWidth - PANEL_WIDTH - (targetWidth + COL_GAP)),
      top: containerEl.scrollTop,
      behavior: 'smooth',
    });
  }
};

const CompareContainer = ({
  children,
  colFieldData,
  closeDfmPanel,
  dfmChecks,
  dfmCheckOnClick,
  handleBack,
  handleFieldChanges,
  leadTimeFields,
  manufacturingProcesses,
  onChangeQuantity,
  onCheckStandard,
  onDelete,
  onShiftLeft,
  onShiftRight,
  onShiftCancel,
  onShiftDone,
  onSeeMore,
  onSelectConfig,
  pushToEvaluate,
  selectedDfmCheck,
  selectionText,
}: PropsWithChildren<CompareContainerProps>) => {
  const [driverData, setDriverData] = useState<{ target: HTMLElement; stats: DriverStats }>({
    target: null,
    stats: null,
  });
  const isPortalSubscribed = usePortalSubscription();
  const [activeShiftingIndex, setActiveShiftingIndex] = useState(null);
  const isShifting = activeShiftingIndex != null;

  // dynamically apply to all columns as a classname
  const animClassname = isShifting ? styles['column--shifting'] : '';

  const containerRef = React.createRef<HTMLDivElement>();

  const isLoadingTiers = colFieldData.some(
    c => c.autoquoteTiersState && isAutoquoteLoading(c.autoquoteTiersState)
  );
  const hasMultipleTiers = colFieldData.some(
    c =>
      c.autoquoteTiersState &&
      isAutoquoteTierList(c.autoquoteTiersState) &&
      c.autoquoteTiersState.length > 1
  );
  const toggleDisabled = isLoadingTiers || !hasMultipleTiers;

  // Switch the toggle back to standard if after loading all the tiers, none have multiple tiers
  useEffect(() => {
    if (!isLoadingTiers && !hasMultipleTiers) {
      onCheckStandard(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingTiers, hasMultipleTiers]);

  /**
   * Utilizes position: sticky heavily to make the header, footer, and accordion titles stick.
   * Sticky relies on dimensions of respective width or height to be set in order to calculate how and when to stick.
   * For example, the header and the footer stick because the container has it's height fixed (in this case calc(100vh - 150px)).
   * Likewise the Quantity / Lead Time Option and the Accordion titles stick because width is defined with "width: fit-content" (w-fit).
   *
   * Since all the content is within the container, both the header and footer scroll horizontally with the non-sticky content.
   */
  return (
    <section>
      <div className={styles['container']} ref={containerRef}>
        {children}
        <div className="w-fit">
          <header className={styles['process-header']}>
            <div className={styles['row']}>
              {colFieldData.map((fieldData, index) => {
                const isLimited = !isPortalSubscribed && index > 0;
                return (
                  <Column
                    className={classNames(['pt-5 pb-6', animClassname])}
                    key={fieldData.columnId}
                    offset={fieldData.colOffset}
                    onFocus={(event: SyntheticEvent<HTMLElement>) =>
                      scrollColumnIntoView(containerRef.current, event.currentTarget)
                    }
                  >
                    <ManufacturingSpecificationFields
                      disabled={isShifting || isLimited}
                      index={index}
                      fieldData={fieldData}
                      manufacturingProcesses={manufacturingProcesses}
                      handleBack={handleBack}
                      handleFieldChanges={handleFieldChanges}
                    />
                  </Column>
                );
              })}
              {!isPortalSubscribed && (
                <SubscriptionUpgradeCard
                  analyticsContext="Compare Page"
                  className="absolute left-[756px] top-8 max-w-[330px]"
                />
              )}
            </div>
          </header>

          <Accordion title="Costs & lead times">
            <div
              className={classNames([
                'sticky left-0 flex border-solid border-0 border-coolGray-700 border-b items-end pb-1',
                styles['container-width'],
              ])}
            >
              <div className={styles['column']}>
                <FormFieldBase name="quantity" label="Quantity" isControl={true}>
                  <NumberInputField
                    value={leadTimeFields.quantity}
                    onChange={val => onChangeQuantity(Math.floor(val))}
                    min={1}
                    data-testid="part-config-quantity"
                  />
                </FormFieldBase>
              </div>
              <div className={classNames(['mb-3 ml-6', styles['column']])}>
                <label htmlFor="lead-time-opts">Lead time:</label>
                <ToggleSwitch
                  className="inline-block ml-3 align-bottom"
                  id="lead-time-opts"
                  disabled={toggleDisabled}
                  checked={leadTimeFields.isStandard}
                  onCheck={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    onCheckStandard(evt.currentTarget.checked);
                  }}
                  defaultTrueLabel="Standard"
                  defaultFalseLabel="Fast"
                />
              </div>
            </div>

            <div className={styles['row']}>
              {colFieldData.map(fieldData => {
                const currentProcess = find(manufacturingProcesses, {
                  id: +fieldData.manufacturing_process_id,
                });

                return (
                  <Column
                    className={classNames(['pt-5 pb-6', animClassname])}
                    key={fieldData.columnId}
                    offset={fieldData.colOffset}
                    onFocus={(event: SyntheticEvent<HTMLElement>) =>
                      scrollColumnIntoView(containerRef.current, event.currentTarget)
                    }
                  >
                    {currentProcess ? (
                      <TierPriceCard
                        state={fieldData.autoquoteTiersState}
                        isStandard={leadTimeFields.isStandard}
                      />
                    ) : null}
                  </Column>
                );
              })}
            </div>
          </Accordion>

          <Accordion title="Costs curves">
            <TooltipControlled target={driverData.target}>
              <TooltipContent className="react-hint">
                <span className="text-coolGray-300">
                  <b className="text-base text-white">
                    {(driverData.stats?.percentage * 100)?.toFixed(1)}
                  </b>
                  % | <sup>$</sup>
                  <b className="text-base text-white">{driverData.stats?.price?.toFixed(2)}</b>
                  &nbsp;of the&nbsp;
                  <span className="text-white">${driverData.stats?.totalPrice?.toFixed(2)}</span>
                  &nbsp;total
                </span>
              </TooltipContent>
            </TooltipControlled>
            <div className={styles['row']}>
              {colFieldData.map(fieldData => {
                const process = manufacturingProcesses?.find(
                  mp => mp.id === fieldData.manufacturing_process_id
                );
                return (
                  <Column
                    className={animClassname}
                    key={fieldData.columnId}
                    offset={fieldData.colOffset}
                    onFocus={(event: SyntheticEvent<HTMLElement>) =>
                      scrollColumnIntoView(containerRef.current, event.currentTarget)
                    }
                  >
                    {fieldData.valid ? (
                      <CostCurvesCard
                        costData={fieldData.costData}
                        currentProcessName={process?.name}
                        onDriverOpen={(target, stats) => setDriverData({ target, stats })}
                        onDriverClose={() => setDriverData({ target: null, stats: null })}
                        onSeeMore={() => onSeeMore(fieldData.columnId)}
                      />
                    ) : null}
                  </Column>
                );
              })}
            </div>
          </Accordion>

          <Accordion title="Material">
            <div className={styles['row']}>
              {colFieldData.map(fieldData => {
                const currentProcess = find(manufacturingProcesses, {
                  id: +fieldData.manufacturing_process_id,
                });

                const { currentMaterial } = getMaterialInfo(currentProcess, fieldData);

                return (
                  <Column
                    className={animClassname}
                    key={fieldData.columnId}
                    offset={fieldData.colOffset}
                    onFocus={(event: SyntheticEvent<HTMLElement>) =>
                      scrollColumnIntoView(containerRef.current, event.currentTarget)
                    }
                  >
                    {currentProcess && (
                      <Material
                        selectedManufacturingProcess={currentProcess}
                        material={currentMaterial}
                      />
                    )}
                  </Column>
                );
              })}
            </div>
          </Accordion>

          {dfmChecks && dfmChecks.length > 0 && (
            <Accordion title="DFM checks" disableAnimateCollapse={true}>
              <div className={styles['row']}>
                {colFieldData.map(fieldData => {
                  if (!fieldData.manufacturing_process_id) return null;
                  const childRef = React.createRef<HTMLDivElement>();
                  const pushToEvaluateParams = {
                    manufacturing_process_id: fieldData.manufacturing_process_id,
                    material_id: fieldData.material_id,
                    color_id: fieldData.color_id,
                    layer_thickness_id: fieldData.layer_thickness_id,
                  };
                  const { name: processName } = find(manufacturingProcesses, {
                    id: +fieldData.manufacturing_process_id,
                  });
                  return (
                    <div key={fieldData.columnId} ref={childRef}>
                      <Column
                        className={classNames(['pb-6', animClassname])}
                        offset={fieldData.colOffset}
                        onFocus={(event: SyntheticEvent<HTMLElement>) =>
                          scrollColumnIntoView(containerRef.current, event.currentTarget)
                        }
                      >
                        <DfmChecks
                          colId={fieldData.columnId}
                          dfmChecks={dfmChecks}
                          disabled={isShifting}
                          processId={fieldData.manufacturing_process_id}
                          processName={processName}
                          selectedDfmCheck={selectedDfmCheck}
                          handleSelected={(dfmCheck: ManufacturabilityCheck) => {
                            dfmCheckOnClick(fieldData.columnId, dfmCheck, pushToEvaluateParams);
                            panelScrollColumnIntoView({
                              containerEl: containerRef.current,
                              childEl: childRef.current,
                            });
                          }}
                          pushToEvaluate={() => pushToEvaluate(pushToEvaluateParams)}
                        />
                      </Column>
                    </div>
                  );
                })}
              </div>
            </Accordion>
          )}
        </div>

        <footer className="bg-coolGray-800 sticky bottom-0 w-fit z-10">
          {/* This is the footer background */}
          <div className={styles['footer-background']}></div>
          <div className={styles['row']}>
            {/* introduce an overlay to prevent interaction with the page until done shifting */}
            {(isShifting || selectedDfmCheck.colId) && (
              <div
                onClick={closeDfmPanel}
                role="button"
                onKeyDown={evt => keyboardDown(evt, 'Enter', closeDfmPanel)}
                className="absolute top-[-100vh] left-0 bg-[rgb(17,17,17,0.5)] w-full h-screen"
                tabIndex={0}
              ></div>
            )}
            {/* render the controls */}
            {colFieldData.map((fieldData, index) => {
              // allow the user to only interact with the controls if not shifting or if it is the column that initiated shifting
              const isActive =
                (!isShifting || activeShiftingIndex === index) && !selectedDfmCheck.colId;
              const shiftedIndex = index + fieldData.colOffset;

              // collect complex conditions here for readability
              const canDelete = isActive && colFieldData.length > 2;
              const canShiftLeft = isActive && shiftedIndex > 0;
              const nextColumn = colFieldData[shiftedIndex + 1];
              const canShiftRight =
                isActive && shiftedIndex < colFieldData.length - 1 && nextColumn?.valid;
              const canSelect = isActive && fieldData.valid && onSelectConfig;

              // capture the call to null the active shifting index with a closure since it will be used inside a setTimeout with delay
              const undoHandler = () => setActiveShiftingIndex(null);

              return (
                <Column
                  className={animClassname}
                  key={fieldData.columnId}
                  offset={fieldData.colOffset}
                  onFocus={(event: SyntheticEvent<HTMLElement>) =>
                    !isShifting && scrollColumnIntoView(containerRef.current, event.currentTarget)
                  }
                >
                  {fieldData.valid && (
                    <CompareActions
                      onDelete={
                        (canDelete &&
                          (() => {
                            setActiveShiftingIndex(null);
                            onDelete(fieldData.columnId);
                          })) ||
                        undefined
                      }
                      onHighlight={
                        (isActive && (() => setActiveShiftingIndex(index))) || undefined
                      }
                      onShiftLeft={
                        (canShiftLeft && (() => onShiftLeft(fieldData.columnId))) || undefined
                      }
                      onShiftRight={
                        (canShiftRight && (() => onShiftRight(fieldData.columnId))) || undefined
                      }
                      onCancelHighlight={(action: 'DELETE' | 'SHIFT') => {
                        if (action === 'SHIFT' && fieldData.colOffset !== 0) {
                          setTimeout(undoHandler, UNDO_DELAY);
                          onShiftCancel();
                        } else {
                          undoHandler();
                        }
                      }}
                      onShiftDone={() => {
                        setActiveShiftingIndex(null);
                        onShiftDone();
                      }}
                      onSelect={
                        (canSelect && (() => onSelectConfig(fieldData.columnId))) || undefined
                      }
                      selectionText={selectionText}
                    />
                  )}
                </Column>
              );
            })}
          </div>
        </footer>
      </div>
    </section>
  );
};

export const Column = ({
  className = '',
  children,
  onFocus = null,
  offset = 0,
}: ColumnProps &
  PropsWithChildren<{
    className?: string;
    offset: number;
    onFocus: (event: SyntheticEvent<HTMLElement>) => void;
  }>) => {
  const newStyle = { transform: `translateX(${(COL_WIDTH + COL_GAP) * offset}px)` };
  return (
    <div
      style={newStyle}
      className={classNames([styles['column'], className])}
      onFocus={onFocus}
      onClick={onFocus}
      onKeyDown={evt => keyboardDown(evt, 'Enter', onFocus)}
      tabIndex={0}
      role="button"
    >
      {children}
    </div>
  );
};

export interface ColumnProps {
  className?: string;
  verbText?: string;
}

export default CompareContainer;
