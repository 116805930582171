/* eslint-disable react/display-name,react/prop-types */
import { sanitizeUrl } from '@braintree/sanitize-url';
import { countBy, get, kebabCase } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Link, Table } from 'fr-shared/components';

import { quoteToDisplayID } from '../utils/transforms';

const QuotesTable = ({ data, pages, onFetchData, sort, loading, ...props }) => (
  <Table
    manual={true}
    data={data}
    pages={pages}
    pageSize={data.length || 20}
    showPagination={true}
    onFetchData={onFetchData}
    loading={loading}
    className="bg-white -left -striped mb-5"
    noDataText="No quote requests found"
    defaultSorted={sort}
    columns={[
      {
        Header: '',
        expander: true,
        sortable: false,
        width: 45,
        Expander: ({ isExpanded }) => (
          <div className="flex justify-content-between align-items-center">
            <Button size="sm" color="secondary">
              <i className={`fas fa-chevron-${isExpanded ? 'up' : 'down'}`} />
            </Button>
          </div>
        ),
      },
      {
        Header: 'Quote #',
        id: 'id',
        accessor: data => <Link to={`/admin/quotes/${data.id}`}>{quoteToDisplayID(data)}</Link>,
        width: 100,
      },
      {
        Header: 'Customer',
        id: 'customer_id',
        accessor: data => (
          <Link to={`/admin/customers/${get(data, 'customer.id')}`}>
            {get(data, 'customer.name')}
          </Link>
        ),
      },
      {
        Header: 'Contact',
        id: 'customer_contact_name',
        accessor: 'customer_contact.name',
        sortable: false,
      },
      {
        Header: 'Public ID',
        id: 'public_id',
        accessor: 'public_id',
      },
      {
        Header: 'State',
        accessor: 'state',
        sortable: false,
      },
      {
        Header: 'MFG Process',
        id: 'manufacturing_process_id',
        sortable: false,
        accessor: data => {
          const count = countBy(data.line_items, 'manufacturing_process.name');
          const countString = Object.keys(count).map(k => ` ${k}: ${count[k]}`);
          return <span data-rh={countString}>{Object.keys(count).join(', ')}</span>;
        },
      },
      {
        Header: 'Submitted',
        id: 'completed_at',
        sortable: false,
        accessor: data => data.completed_at && moment(data.completed_at).format('MM/DD/YYYY'),
      },
      {
        Header: 'Items',
        id: 'items',
        sortable: false,
        accessor: data => data.line_items && data.line_items.length,
      },
      {
        Header: 'Total',
        id: 'total',
        sortable: false,
        accessor: data => data.total_price.formatted,
      },
      {
        Header: '',
        sortable: false,
        width: 120,
        Cell: row => (
          <div className="flex justify-content-between align-items-center">
            {row.original.box_file_location && (
              <Link
                className="btn btn-link btn-lg font-size-md letter-spacing-xs px-1"
                to={{ pathname: sanitizeUrl(row.original.box_file_location) }}
                openNewWindow
              >
                box
              </Link>
            )}
            <Link
              className="btn btn-link btn-lg font-size-md px-1 pr-2"
              to={`/admin/quotes/${row.original.id}`}
            >
              View
            </Link>
          </div>
        ),
        style: {
          display: 'flex',
          justifyContent: 'flex-end',
          cursor: 'pointer',
          fontSize: 18,
          padding: '0',
          textAlign: 'center',
          userSelect: 'none',
        },
      },
    ]}
    SubComponent={row => {
      return (
        <Table
          data={row.original.line_items}
          defaultPageSize={row.original.line_items.length}
          showPagination={false}
          className="-no-shadow"
          columns={[
            {
              Header: 'Part Name',
              accessor: 'part_name',
            },
            {
              Header: 'MFG Process',
              accessor: 'manufacturing_process.name',
            },
            {
              Header: 'Material',
              accessor: 'material.name',
            },
            {
              Header: 'Color',
              id: 'color',
              accessor: data => (
                <span className={kebabCase(get(data, 'color.name'))}>
                  {get(data, 'color.name')}
                </span>
              ),
            },
            {
              Header: 'Finish',
              accessor: 'finish',
            },
            {
              Header: 'Inspection',
              accessor: 'inspection_type.name',
            },
            {
              Header: 'Qty',
              accessor: 'quantity',
            },
            {
              Header: 'Price',
              id: 'price',
              accessor: data => data.unit_price.formatted,
            },
            {
              Header: 'Total',
              id: 'total_price',
              accessor: data => data.total_price.formatted,
            },
          ]}
        />
      );
    }}
    {...props}
  />
);

QuotesTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      line_items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
        })
      ).isRequired,
      unit_price: PropTypes.shape({
        formatted: PropTypes.string,
      }),
      total_price: PropTypes.shape({
        formatted: PropTypes.string,
      }).isRequired,
      color: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  pages: PropTypes.number,
  sort: PropTypes.array,
  onFetchData: PropTypes.func,
  isExpanded: PropTypes.bool,
  loading: PropTypes.bool,
};

export default QuotesTable;
