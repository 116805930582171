import PropTypes from 'prop-types';
import React from 'react';

import { Button, Checkbox, SidebarContent } from 'fr-shared/components';

const SupplierFilters = ({ clearFilters, setFilters, filters }) => {
  return (
    <SidebarContent name="Filters" icon="filter">
      <div className="form-group">
        <Checkbox
          label="Show Inactive Suppliers"
          name="show_inactive"
          value={filters.show_inactive || false}
          onChange={e => setFilters({ ...filters, show_inactive: e.target.checked })}
        />
      </div>

      <div className="form-group text-right mt-3">
        <Button size="sm" onClick={clearFilters}>
          Clear filters
        </Button>
      </div>
    </SidebarContent>
  );
};

SupplierFilters.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    show_inactive: PropTypes.bool,
  }),
};

export default SupplierFilters;
