import PropTypes from 'prop-types';
import React from 'react';

import { Card, FormCheckbox } from 'fr-shared/components';

const QuoteFormCertificates = ({ readonly }) => {
  return (
    <Card>
      <Card.Header>Certificates</Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-md">
            <FormCheckbox
              label="First Article Inspection"
              name="first_article_inspection"
              readonly={readonly}
            />
            <FormCheckbox
              label="Certificate of Conformance"
              name="certificate_of_conformance"
              readonly={readonly}
            />
            <FormCheckbox
              label="CoC Signature Required"
              name="coc_signature_required"
              readonly={readonly}
            />
            <FormCheckbox
              label="Material Certification Required"
              name="material_certification_required"
              readonly={readonly}
            />
            <FormCheckbox
              label="Finishing Certification Required"
              name="finishing_certificaton_required"
              readonly={readonly}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

QuoteFormCertificates.propTypes = {
  readonly: PropTypes.bool,
};

export default QuoteFormCertificates;
