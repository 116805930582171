import { get, isEqual } from 'lodash';

/**
 * This function is designed to be used as a React.memo compare function with a formik connected component
 * that has a fieldPrefix prop.
 *
 * Using it should look something like this:
 *
 * export default connect(React.memo(component, isFormikAtPrefixTheSame));
 *
 * @param {object} prevProps - Props from the previous render cycle
 * @param {object} nextProps - Props for the render cycle to come
 */
export const isFormikAtPrefixTheSame = (prevProps, nextProps) => {
  return (
    isEqual(
      get(prevProps.formik, `values.${prevProps.fieldPrefix}`),
      get(nextProps.formik, `values.${nextProps.fieldPrefix}`)
    ) &&
    isEqual(
      get(prevProps.formik, `errors.${prevProps.fieldPrefix}`),
      get(nextProps.formik, `errors.${nextProps.fieldPrefix}`)
    )
  );
};
