import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Icon, Link } from 'fr-shared/components';

const DocumentItem = ({ document, onDelete = () => {}, readonly, showDate }) => {
  // If `inserted_at` is undefined, moment() will just return the date/time
  // that it is right now. This will happen if a document has just been
  // uploaded the S3 but has not been saved to the database.
  const dateUploaded = showDate && moment(document.inserted_at).format('MM/DD/YY');

  return (
    <div className="flex flex-row align-items-center">
      <div className="flex-grow-1">
        {document.url ? (
          <Link data-testid="doc-link" color="link" to={{ pathname: document.url }} openNewWindow>
            {readonly && (
              <Icon data-testid="download-doc" name="arrow-circle-down" className="mr-1" />
            )}
            {document.file_name}
          </Link>
        ) : (
          <div>{document.file_name}</div>
        )}
      </div>

      {dateUploaded && <div className="mx-1 my-0 text-secondary">{dateUploaded}</div>}

      {!readonly && (
        <Button
          color="danger"
          outline={true}
          size="sm"
          onClick={onDelete}
          dataTestId={`delete-doc-${document.url}`}
        >
          <Icon name="times" />
        </Button>
      )}
    </div>
  );
};

DocumentItem.propTypes = {
  dateUploaded: PropTypes.string,
  document: PropTypes.shape({
    deleted_at: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    file_name: PropTypes.string,
    inserted_at: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func,
  readonly: PropTypes.bool,
  showDate: PropTypes.bool,
};

export default DocumentItem;
