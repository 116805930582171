import PropTypes from 'prop-types';
import React, { memo, useCallback, useContext } from 'react';

import { useUserAnalyticsContext } from 'fr-shared/context';

import { PartCard, PartPreviewModal } from 'portal/components';
import { PartsContext } from 'portal/contexts/PartsContext';

const GridViewPart = ({ part, pageName }) => {
  const { handleSelectPart, selectedParts } = useContext(PartsContext);
  const userAnalytics = useUserAnalyticsContext();
  const isPartSelected = !!selectedParts[part.id];
  const logOnSelection = () =>
    userAnalytics.track(`Select Part - ${pageName}`, { valid: !isPartSelected });
  const logOnClickPart = () =>
    userAnalytics.track(`${pageName} - View Part`, { valid: !isPartSelected });
  const handleSelectClick = useCallback(() => {
    handleSelectPart(isPartSelected, part);
  }, [handleSelectPart, isPartSelected, part]);

  return (
    <PartPreviewModal
      part={part}
      pageName={pageName}
      action={
        <PartCard
          key={part.id}
          selected={isPartSelected}
          onSelection={handleSelectClick}
          part={part}
          logOnSelection={logOnSelection}
          logOnClickPart={logOnClickPart}
        />
      }
    />
  );
};

GridViewPart.propTypes = {
  pageName: PropTypes.string,
  part: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default memo(GridViewPart);
