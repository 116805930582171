import PropTypes from 'prop-types';
import React from 'react';

import ContactsTable from './ContactsTable';

export const CustomerContacts = ({ organization }) => {
  if (!organization) return null;

  const contacts = organization.users || [];

  return (
    <div>
      <h3 className="mb-3">Contacts</h3>
      <ContactsTable
        data={contacts}
        defaultPageSize={contacts.length}
        defaultSorted={[{ id: 'created', desc: true }]}
        showPagination={false}
        organizationId={organization.id}
      />
    </div>
  );
};

CustomerContacts.propTypes = {
  organization: PropTypes.object,
  customerId: PropTypes.string,
};
