/* eslint-disable react/display-name */
import { useFormikContext } from 'formik';
import { get, has, isEmpty } from 'lodash';
import moment from 'moment';
import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';

import { Checkbox, FormField, Icon, Table, classNames } from 'fr-shared/components';
import { ORDER_LINE_ITEM_STATES } from 'fr-shared/lib/orders';

import { formatNullableDate } from '../../orders/components/planned_shipment_line_items/PSLITableEdit';
import OrderLineItem from './OrderLineItem';
import styles from './SelectedOrderLineItem.module.css';

interface SelectedOrderLineItemProps {
  isEditing: boolean;
  line_item: any;
  onSelect: (ids: number[]) => void;
}

interface ShipmentLineItem {
  order_line_item_id: number;
  planned_shipment_line_item_fulfillments: PlannedShipmentLineItemFulfillment[];
  quantity: number;
}

interface PlannedShipmentLineItemTableRow {
  index: number;
  original: PlannedShipmentLineItem;
}

const SelectedOrderLineItem = (props: SelectedOrderLineItemProps) => {
  const { isEditing, line_item } = props;
  const { values, setFieldValue, setFieldError } = useFormikContext();

  const oliIndex = get(values, props.isEditing ? 'order.line_items' : 'line_items', []).findIndex(
    (formikLineItem: any) => formikLineItem.id === line_item.id
  );

  const sliIndex = get(values, 'line_items', [])
    .filter(
      (li: any) =>
        li.state !== ORDER_LINE_ITEM_STATES.Shipped &&
        li.state !== ORDER_LINE_ITEM_STATES.Canceled
    )
    .findIndex((formikLineItem: any) => formikLineItem.order_line_item_id === line_item.id);
  const psliFormikPrefix = `${
    props.isEditing ? 'order.' : ''
  }line_items[${oliIndex}].planned_shipment_line_items`; // order line items that will be turned into shipping line items in API
  const psliFulfillmentFormikPrefix = `line_items[${sliIndex}].planned_shipment_line_item_fulfillments`;
  const [plannedShipmentLineItems, setPlannedShipmentLineItems] = useState<
    Array<PlannedShipmentLineItem>
  >([]);
  const [psliFulfillments, setPSLIFulfillments] = useState<
    Array<PlannedShipmentLineItemFulfillment>
  >([]);
  const [selectedRowIds, setSelectedRowIds] = useState<Array<number>>([]);
  const [invalidRowIds, setInvalidRowIds] = useState<Array<number>>([]);

  const updateSelectedRowIds = (fulfillments: PlannedShipmentLineItemFulfillment[]) => {
    setSelectedRowIds(
      fulfillments
        .filter((pslif: PlannedShipmentLineItemFulfillment) => pslif.selected)
        .map((pslif: PlannedShipmentLineItemFulfillment) => pslif.planned_shipment_line_item_id)
    );
  };

  const setupDefaultsForNewSLI = () => {
    setFieldValue(`line_items[${sliIndex}].quantity`, 0);

    // get and set PSLIs
    const pslis = get(values, psliFormikPrefix, [])
      .filter((psli: PlannedShipmentLineItem) => psli.remaining_to_fulfill !== 0)
      .sort((a: PlannedShipmentLineItem, b: PlannedShipmentLineItem) => {
        return (
          new Date(a.promised_ship_date).valueOf() - new Date(b.promised_ship_date).valueOf()
        );
      });
    setPlannedShipmentLineItems(pslis);
    setFieldValue(psliFormikPrefix, pslis);

    // set all fulfillments as unselected, unselected fulfillments will be dropped prior to server submit
    const defaultPSLIFulfillments = pslis.map((psli: PlannedShipmentLineItem) => {
      return {
        planned_shipment_line_item_id: psli.id,
        quantity: '',
        selected: false,
      };
    });
    setPSLIFulfillments(defaultPSLIFulfillments);
    setFieldValue(psliFulfillmentFormikPrefix, defaultPSLIFulfillments);

    updateSelectedRowIds(defaultPSLIFulfillments);
  };

  const handleEdits = () => {
    const shipmentLineItems = get(values, 'line_items', []);
    const shipmentLineItemForOLI = shipmentLineItems.find(
      (shipmentLineItem: ShipmentLineItem) => shipmentLineItem.order_line_item_id === line_item.id
    );

    const originalShipmentLineItems = get(values, `original_shipment_line_items`, []);

    if (!has(shipmentLineItemForOLI, 'planned_shipment_line_item_fulfillments')) {
      // new sli on existing shipment
      setupDefaultsForNewSLI();
    } else {
      // existing SLI served from successful saving of new shipment.

      setFieldValue(`line_items[${sliIndex}].quantity`, shipmentLineItemForOLI.quantity);

      const sliFulfillments = shipmentLineItemForOLI.planned_shipment_line_item_fulfillments.map(
        (pslif: PlannedShipmentLineItemFulfillment) => {
          // need this incase editing shipment to uncheck pslif, remove sli and re-add sli
          const pslifQuanity =
            isEmpty(pslif.quantity) && has(pslif, 'original_quantity')
              ? pslif.original_quantity
              : pslif.quantity;

          return {
            ...pslif,
            original_quantity: pslifQuanity,
            selected: has(pslif, 'selected') ? pslif.selected : true,
            quantity: pslifQuanity,
          };
        }
      );

      // need to save off SLIs in the case of removing and re-adding this SLI that had associated PSLIFs to this shipment
      if (isEmpty(originalShipmentLineItems)) {
        setFieldValue(`original_shipment_line_items`, shipmentLineItems);
      }

      const sliFulfillmentPSLIids = sliFulfillments.map(
        (pslif: PlannedShipmentLineItemFulfillment) => pslif.planned_shipment_line_item_id
      );

      const pslis = get(values, psliFormikPrefix, [])
        .filter(
          (psli: PlannedShipmentLineItem) =>
            psli.remaining_to_fulfill !== 0 ||
            sliFulfillments
              .map(
                (sliFulfillment: PlannedShipmentLineItemFulfillment) =>
                  sliFulfillment.planned_shipment_line_item_id
              )
              .includes(psli.id)
        )
        .sort((a: PlannedShipmentLineItem, b: PlannedShipmentLineItem) => {
          return (
            new Date(a.promised_ship_date).valueOf() - new Date(b.promised_ship_date).valueOf()
          );
        });
      setPlannedShipmentLineItems(pslis);
      setFieldValue(psliFormikPrefix, pslis);

      const defaultPSLIFulfillments = pslis.map((psli: PlannedShipmentLineItem) => {
        return {
          planned_shipment_line_item_id: psli.id,
          quantity: '',
          selected: false,
        };
      });

      // need to merge all default pslifs with data returned from sli pslifs to be able to act on them after loaded.
      const mergedPSLIFs = defaultPSLIFulfillments.map(
        (defaultPSLIF: PlannedShipmentLineItemFulfillment) => {
          if (sliFulfillmentPSLIids.includes(defaultPSLIF.planned_shipment_line_item_id)) {
            const sliFulfillment = sliFulfillments.find(
              (sliPSLIF: PlannedShipmentLineItemFulfillment) =>
                sliPSLIF.planned_shipment_line_item_id ===
                defaultPSLIF.planned_shipment_line_item_id
            );
            // use existing sli pslif data
            return sliFulfillment;
          }

          return defaultPSLIF;
        }
      );

      setPSLIFulfillments(mergedPSLIFs);
      setFieldValue(psliFulfillmentFormikPrefix, mergedPSLIFs);

      updateSelectedRowIds(mergedPSLIFs);
    }
  };

  useEffect(() => {
    if (isEditing) {
      handleEdits();
    } else {
      // new shipment, have to set up defaults.
      setupDefaultsForNewSLI();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [line_item.id]);

  const handleChange = (e: any, row: PlannedShipmentLineItemTableRow) => {
    if (!!e.target.value && row.original.quantity) {
      const psli = row.original;
      const newVal = parseInt(e.target.value);
      const pslifs = get(values, psliFulfillmentFormikPrefix, []);

      const pslifIndex = pslifs.findIndex(
        (pslif: PlannedShipmentLineItemFulfillment) =>
          pslif.planned_shipment_line_item_id === psli.id
      );
      if (pslifIndex !== -1) {
        // row should be selected first before being able to edit value
        const originalPSLIFQuantity = pslifs[pslifIndex].original_quantity;
        const maxAllowedPSLIQuantity = isEditing
          ? originalPSLIFQuantity + psli.remaining_to_fulfill
          : psli.remaining_to_fulfill;

        if (newVal > maxAllowedPSLIQuantity || newVal <= 0) {
          const workingInvalidRows = [...invalidRowIds];
          workingInvalidRows.push(row.index);
          setInvalidRowIds(workingInvalidRows);

          setFieldError(
            `${psliFulfillmentFormikPrefix}[${row.index}].quantity`,
            `invalid quantity ${psli.remaining_to_fulfill}`
          );
          return;
        }

        setFieldValue(`${psliFulfillmentFormikPrefix}[${pslifIndex}]`, {
          ...pslifs[pslifIndex],
          quantity: newVal,
        });

        const sliQuantity = pslifs.reduce(
          (prev: number, current: PlannedShipmentLineItemFulfillment) => {
            if (current.planned_shipment_line_item_id === psli.id) {
              // use updated value form text input
              return newVal + prev;
            }
            // use value stored in formik state
            // if NaN quantity is currently an empty string
            const parsedQuantity = parseInt(current.quantity.toString());
            return (isNaN(parsedQuantity) ? 0 : parsedQuantity) + prev;
          },
          0
        );

        setFieldValue(`line_items[${sliIndex}].quantity`, sliQuantity);

        //reset field error becuase entry was successful above
        setFieldError(`${psliFulfillmentFormikPrefix}[${row.index}].quantity`, null);
        setInvalidRowIds(invalidRowIds.filter(invalidIds => invalidIds !== row.index));
      }
    } else {
      // likely an empty value in quantity field
      const workingInvalidRows = [...invalidRowIds];
      workingInvalidRows.push(row.index);
      setInvalidRowIds(workingInvalidRows);

      setFieldError(`${psliFulfillmentFormikPrefix}[${row.index}].quantity`, 'required quantity');
    }
  };

  const handleSelectToggle = (e: any, row: PlannedShipmentLineItemTableRow) => {
    const { id, remaining_to_fulfill } = row.original;
    const workingPSLIFulfillments = Array.from(psliFulfillments);
    const selectedPSLIFulfillmentIndex = workingPSLIFulfillments.findIndex(
      (pslif: PlannedShipmentLineItemFulfillment) => pslif.planned_shipment_line_item_id === id
    );
    const selectedPSLIFulfillment = Object.assign(
      {},
      workingPSLIFulfillments[selectedPSLIFulfillmentIndex]
    );

    selectedPSLIFulfillment.selected = e.target.checked;

    selectedPSLIFulfillment.quantity = e.target.checked
      ? has(selectedPSLIFulfillment, 'original_quantity')
        ? selectedPSLIFulfillment.original_quantity + remaining_to_fulfill
        : remaining_to_fulfill
      : '';

    workingPSLIFulfillments[selectedPSLIFulfillmentIndex] = selectedPSLIFulfillment;

    setPSLIFulfillments(workingPSLIFulfillments);
    setFieldValue(psliFulfillmentFormikPrefix, workingPSLIFulfillments);

    updateSelectedRowIds(workingPSLIFulfillments);

    const sliQuantity = workingPSLIFulfillments
      .filter((pslif: PlannedShipmentLineItemFulfillment) => pslif.selected)
      .reduce((prev: number, current: PlannedShipmentLineItemFulfillment) => {
        // if NaN quantity is currently an empty string
        const parsedQuantity = parseInt(current.quantity.toString());
        return (isNaN(parsedQuantity) ? 0 : parsedQuantity) + prev;
      }, 0);
    setFieldValue(`line_items[${sliIndex}].quantity`, sliQuantity);
  };

  const columns = useMemo(
    () => [
      {
        width: 100,
        accessor: 'selected',
        Cell: (row: PlannedShipmentLineItemTableRow) => {
          return (
            <Checkbox
              className="ml-2 mb-0"
              dataTestId={`${psliFulfillmentFormikPrefix}[${row.index}].selected`}
              name={`${psliFulfillmentFormikPrefix}[${row.index}].selected`}
              onChange={(e: SyntheticEvent) => handleSelectToggle(e, row)}
              value={selectedRowIds.includes(row.original.id)}
            />
          );
        },
      },
      {
        Header: 'Planned Shipment Line Item ID',
        id: 'psli-table-row-id',
        Cell: (row: PlannedShipmentLineItemTableRow) => (
          <div style={{ textAlign: 'center', width: '100%' }}>{row.original.id}</div>
        ),
      },
      {
        Header: 'Quantity to Ship',
        Cell: (row: PlannedShipmentLineItemTableRow) => {
          const { remaining_to_fulfill } = row.original;
          const originalPSLIFQuantity = get(
            values,
            `${psliFulfillmentFormikPrefix}[${row.index}].original_quantity`,
            0
          );
          const maxAllowedPSLIQuantity = isEditing
            ? originalPSLIFQuantity + remaining_to_fulfill
            : remaining_to_fulfill;

          return (
            <div
              className="flex align-items-center justify-content-center"
              style={{ width: '100%' }}
            >
              {invalidRowIds.includes(row.index) && (
                <Icon name="exclamation-circle" className="text-danger mr-2" />
              )}
              <FormField
                type="number"
                className={styles.QuantityField}
                disabled={!selectedRowIds.includes(row.original.id)}
                name={`${psliFulfillmentFormikPrefix}[${row.index}].quantity`}
                onBlur={(e: SyntheticEvent) => handleChange(e, row)}
                showErrorMsg={false}
              />
              <div
                data-testid={`${psliFulfillmentFormikPrefix}[${row.index}].max_allowed_quantity`}
                className="ml-1"
              >
                /{maxAllowedPSLIQuantity}
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Total PSLI Units',
        accessor: 'quantity',
        Cell: (row: PlannedShipmentLineItemTableRow) => (
          <div style={{ textAlign: 'center', width: '100%' }}>{row.original.quantity}</div>
        ),
      },
      {
        Header: 'Commit Date',
        id: 'psli-table-commit-date',
        Cell: (row: PlannedShipmentLineItemTableRow) => (
          <div style={{ textAlign: 'center', width: '100%' }}>
            {moment(row.original.commit_date).format('MM/DD/YYYY')}
          </div>
        ),
      },
      {
        Header: 'Customer Promised Date',
        id: 'psli-table-customer-promised-date',
        Cell: (row: PlannedShipmentLineItemTableRow) => (
          <div
            data-testid={`${psliFulfillmentFormikPrefix}[${row.index}].promised_ship_date`}
            style={{ textAlign: 'center', width: '100%' }}
          >
            {moment(row.original.promised_ship_date).format('MM/DD/YYYY')}
          </div>
        ),
      },
      {
        Header: 'Supplier Promised Ship Date',
        id: 'psli-table-supplier-promised-ship-date',
        Cell: (row: PlannedShipmentLineItemTableRow) => (
          <div style={{ textAlign: 'center', width: '100%' }}>
            {formatNullableDate(row.original.supplier_promised_ship_date)}
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [psliFulfillmentFormikPrefix, selectedRowIds, invalidRowIds]
  );

  return (
    <div key={line_item.id}>
      <OrderLineItem lineItem={line_item} onSelect={props.onSelect} selected={true} />
      <Table
        className={classNames(['bg-white -striped -flex mb-3', styles.SelectedOliPsliTable])}
        columns={columns}
        data={plannedShipmentLineItems}
        manual={true}
        style={{ borderRadius: 0 }}
        pages={1}
        pageSize={1}
        showPagination={false}
        sortable={false}
        noDataText={`No Planned Shipments available with available quantity to be fulfilled. Please edit Order to adjust or add quantities for this line item.`}
      />
    </div>
  );
};

export default SelectedOrderLineItem;
