import { Logo } from '@fast-radius/shared-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { AlertProvider } from 'fr-shared/context';

import { AlertBanner, Card, ContactUs, VerifyEmailButton } from 'portal/components';
import styles from 'portal/index.module.css';

const missingTokenError = 'missing';
const expiredTokenError = 'expired';
const unexpectedError = 'unexpected';

const VerifyEmail = ({ history }) => {
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const token = searchParams.get('token');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!token) {
      setError(missingTokenError);
      return;
    }

    api
      .get(`/verify?token=${token}`)
      .then(() => {
        // Hard redirect so we get the session cookie from backend.
        window.location = '/';
      })
      .catch(err => {
        let parsedError = null;
        try {
          parsedError = err.response?.data?.errors[0];
        } catch {
          // Leave parsedError as null.
        }

        if (parsedError === 'missing token') {
          setError(missingTokenError);
        } else if (parsedError === 'expired token') {
          setError(expiredTokenError);
        } else {
          setError(unexpectedError);
        }
      });
  }, [email, token, history]);

  if (!error) return null;

  return (
    <div className="theme-dark">
      <AlertProvider>
        <div className={styles.Unauthenticated}>
          <div className={styles.Centered}>
            <div>
              <AlertBanner />
              <Logo className="mt-2 mb-4" fill="white" />
              <Card size="lg">
                {error === expiredTokenError && <ExpiredTokenError email={email} />}
                {error === missingTokenError && <MissingTokenError email={email} />}
                {error === unexpectedError && <UnexpectedError email={email} />}
              </Card>
            </div>
          </div>
        </div>
      </AlertProvider>
    </div>
  );
};

VerifyEmail.propTypes = {
  history: PropTypes.object,
};

export default VerifyEmail;

const ExpiredTokenError = ({ email }) => {
  return (
    <div>
      <p>We could not confirm your account because the link in your email has expired.</p>
      {!!email && (
        <div>
          <VerifyEmailButton email={email}>Click here</VerifyEmailButton> to send a new
          confirmation email.
        </div>
      )}
    </div>
  );
};

ExpiredTokenError.propTypes = {
  email: PropTypes.string.isRequired,
};

const MissingTokenError = ({ email }) => {
  return (
    <div>
      <p>Something unexpected went wrong while confirming your account.</p>
      <p>Try pasting the link from the confirmation email into your browser.</p>
      {!!email && (
        <div>
          You can also <VerifyEmailButton email={email}>click here</VerifyEmailButton> to send a
          new confirmation email.
        </div>
      )}
    </div>
  );
};

MissingTokenError.propTypes = {
  email: PropTypes.string.isRequired,
};

const UnexpectedError = ({ email }) => {
  return (
    <div>
      <p>
        Something unexpected happened while confirming your account.{' '}
        <ContactUs>Contact us</ContactUs> for assistance.
      </p>
      {!!email && (
        <div>
          You can also <VerifyEmailButton email={email}>click here</VerifyEmailButton> to send a
          new confirmation email.
        </div>
      )}
    </div>
  );
};

UnexpectedError.propTypes = {
  email: PropTypes.string.isRequired,
};
