import { useQuery } from 'react-query';

import { api } from 'fr-shared/api';

const fetchResubmissionReasons = () => api.get('/resubmission_reasons').then(res => res.data);

const useResubmissionReasons = () => {
  return useQuery('resubmission_reasons', fetchResubmissionReasons, {
    refetchOnWindowFocus: false,
  });
};

export default useResubmissionReasons;
