import { IconFont } from '@fast-radius/shared-ui';
import React, { useState } from 'react';

import { Button, classNames } from 'portal/components';

import styles from './CompareActions.module.css';

type CompareAction = 'DELETE' | 'SHIFT';

interface CompareActionsProps {
  onCancelHighlight: (action: CompareAction) => void;
  onDelete: () => void;
  onHighlight: (action: CompareAction) => void;
  onShiftLeft: () => void;
  onShiftRight: () => void;
  onShiftDone: () => void;
  onSelect: () => void;
  selectionText?: string;
}

const CompareActions = ({
  onDelete,
  onCancelHighlight,
  onHighlight,
  onShiftLeft,
  onShiftRight,
  onShiftDone,
  onSelect,
  selectionText = 'Use selection',
}: CompareActionsProps) => {
  const [isShifting, setIsShifting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  let innerContent: React.ReactNode = null;
  if (isShifting) {
    innerContent = (
      <>
        <ActionButtons
          actionText="Done"
          actionColor="primary"
          onAction={() => {
            onShiftDone();
            setIsShifting(false);
          }}
          onCancel={() => {
            onCancelHighlight('SHIFT');
            setIsShifting(false);
          }}
        />
        {/* Floating chevrons to change the column order */}
        <div className="flex justify-between absolute rounded-full bg-coolGray-700 top-[-72px] z-[10] p-1 px-2 gap-6 left-[50%] translate-x-[-50%]">
          <Button
            className="no-underline text-2xl text-white w-[24px] align-text-bottom"
            color="link"
            onClick={onShiftLeft}
            disabled={!onShiftLeft}
            aria-label="Move column left"
          >
            <IconFont className="m-0" name="chevron-left"></IconFont>
          </Button>
          <Button
            className="no-underline text-2xl text-white w-[24px] align-text-bottom"
            color="link"
            onClick={onShiftRight}
            disabled={!onShiftRight}
            aria-label="Move column right"
          >
            <IconFont className="m-0" name="chevron-right"></IconFont>
          </Button>
        </div>
      </>
    );
  } else if (isDeleting) {
    innerContent = (
      <ActionButtons
        actionText="Delete column"
        actionColor="danger"
        onAction={() => {
          onDelete();
          setIsDeleting(false);
        }}
        onCancel={() => {
          onCancelHighlight('DELETE');
          setIsDeleting(false);
        }}
      />
    );
  } else {
    innerContent = (
      <>
        {/* Regular flow buttons */}
        <Button
          className="no-underline text-3xl text-white w-[48px]"
          color="link"
          onClick={() => {
            onHighlight('DELETE');
            setIsDeleting(true);
          }}
          disabled={!onDelete}
          aria-label="Delete column"
        >
          <IconFont className="m-0 align-text-bottom" name="trash"></IconFont>
        </Button>
        <Button
          className="no-underline text-xl text-white w-[48px] align-text-bottom"
          color="link"
          onClick={() => {
            onHighlight('SHIFT');
            setIsShifting(true);
          }}
          disabled={!onShiftLeft && !onShiftRight}
          aria-label={'Move column'}
        >
          <IconFont className="m-0" name="chevron-left"></IconFont>|
          <IconFont className="m-0" name="chevron-right"></IconFont>
        </Button>
        <Button className="text-lg" onClick={onSelect} disabled={!onSelect}>
          {selectionText}
        </Button>
      </>
    );
  }

  return (
    <div className="flex gap-3 py-3 align-center h-[96px] justify-end relative">
      {innerContent}
    </div>
  );
};

interface ActionButtonProps {
  actionText: string;
  actionColor: 'primary' | 'danger';
  onAction: () => void;
  onCancel: () => void;
}

const ActionButtons = ({ actionText, actionColor, onAction, onCancel }: ActionButtonProps) => {
  return (
    <>
      <Button className="m-0 text-lg px-4 flex-1" outline={true} onClick={onCancel}>
        Cancel
      </Button>
      <Button
        className={classNames([
          'm-0 text-lg px-4 flex-1',
          actionColor === 'danger' ? styles['DeleteButton'] : '',
        ])}
        outline={true}
        color={actionColor}
        onClick={onAction}
      >
        {actionText}
      </Button>
    </>
  );
};

export default CompareActions;
