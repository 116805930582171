import { useQuery } from 'react-query';

import { api } from 'fr-shared/api';
import { AuditLineItem, AuditLogQueryObject } from 'fr-shared/lib/audit_log';

const fetchAuditLogs = async (logObject: AuditLogQueryObject) => {
  const { orgId, subject, subjectId, topic, topicId } = getQueryParams(logObject);

  let query = '';
  if (!topic || !topicId) {
    query = `/organization/${orgId}/${subject}/${subjectId}/audits`;
  } else {
    query = `/organization/${orgId}/${subject}/${subjectId}/${topic}/${topicId}/audits`;
  }

  try {
    const res = await api.get(query);
    return JSON.parse(res.data);
  } catch (error) {
    throw new Error(error.message);
  }
};

const getQueryParams = (logObject: AuditLogQueryObject) => {
  return {
    orgId: logObject.orgId,
    subject: logObject.subject,
    subjectId: logObject.subjectId,
    topic: logObject.topic ?? '',
    topicId: logObject.topicId ?? 0,
  };
};

const useAuditLogs = (logObject: AuditLogQueryObject) => {
  const cacheKey = `audit_logs-${logObject.orgId}-${logObject.subject}-${logObject.subjectId}`;
  return useQuery<AuditLineItem[], Error>(cacheKey, () => fetchAuditLogs(logObject), {
    refetchOnWindowFocus: true,
    retry: false,
    refetchInterval: 30000,
    staleTime: 10000,
  });
};

export default useAuditLogs;
