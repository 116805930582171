import { Avatar } from '@fast-radius/shared-ui';
import React, { useContext } from 'react';

import { UserContext } from 'fr-shared/context';

const EditableAvatar = () => {
  const { user } = useContext(UserContext) as { user: User };
  return (
    <div className="w-1/2 flex flex-col items-center">
      <Avatar
        className="rounded-full h-40 w-40"
        dataTestId="editable-avatar"
        subject={user}
        size="xl"
        showName={false}
      />
    </div>
  );
};

export default EditableAvatar;
