import { useQuery } from 'react-query';

import { api } from 'fr-shared/api';

interface LocationParams {
  active?: boolean;
  manufacturingProcess?: string;
}

interface ControllerParams {
  show_inactive?: boolean;
  manufacturing_process?: string;
}

const fetchLocations = ({
  active = true,
  manufacturingProcess = null,
}: LocationParams): Promise<Location[]> => {
  let params: ControllerParams = {
    show_inactive: !active,
  };
  if (manufacturingProcess) params.manufacturing_process = manufacturingProcess;
  return api.get('/locations', { params }).then(res => res.data);
};

const useLocations = ({ active = true, manufacturingProcess }: LocationParams = {}) => {
  return useQuery<Location[], Error>(
    ['locations', active, manufacturingProcess],
    () => fetchLocations({ active, manufacturingProcess }),
    {
      refetchOnWindowFocus: false,
      staleTime: 2000,
    }
  );
};

export default useLocations;
