import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Button, Icon, classNames } from 'fr-shared/components';
import { SidebarContext, SidebarProvider } from 'fr-shared/context';
import { keyboardDown } from 'fr-shared/utils';

export const Sidebar = ({ children }) => {
  const { open, toggle } = useContext(SidebarContext);

  const Collapse = ({ className, dataTestId }) => (
    <div
      className={`sidebar-collapse ${className}`}
      onClick={toggle}
      onKeyDown={evt => keyboardDown(evt, 'Enter', toggle)}
      role="button"
      tabIndex={0}
    >
      <Button color="link" dataTestId={dataTestId}>
        <Icon lg name={`angle-${open ? 'right' : 'left'}`} />
        {open && <span className="ml-2">Collapse</span>}
      </Button>
    </div>
  );

  return (
    <div className={classNames(['sidebar', { open: open }])}>
      <Collapse dataTestId="sidebar-button-sidebar-collapse" />
      <div className="sidebar-children">{children}</div>
      <Collapse className="hidden md:block" />
    </div>
  );
};

Sidebar.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

Sidebar.LOCAL_STORAGE_KEYS = {
  costing: 'showFilters.costing',
  orders: 'showFilters.orders',
  review: 'showFilters.review',
  workOrders: 'showFilters.workOrders',
};

export const SidebarContent = ({ icon, name, children }) => {
  const { open, toggle } = useContext(SidebarContext);
  return (
    <>
      {!open ? (
        <Button color="link" tooltip={name} className="border-bottom px-4 py-3" onClick={toggle}>
          <Icon lg name={icon} />
        </Button>
      ) : (
        <div className="p-3 border-bottom">
          <div className="flex align-items-center mb-3">
            <Icon lg name={icon} className="text-primary mr-3" />
            <h4>{name}</h4>
          </div>
          <div>{children}</div>
        </div>
      )}
    </>
  );
};

SidebarContent.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node,
};

export const SidebarPageContainer = ({ className, children, localStorageKey, defaultOpen }) => (
  <SidebarProvider localStorageKey={localStorageKey} defaultOpen={defaultOpen}>
    <div className={classNames(['sidebar-page-container', className])}>{children}</div>
  </SidebarProvider>
);

SidebarPageContainer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  localStorageKey: PropTypes.string,
  defaultOpen: PropTypes.bool,
};

export const SidebarPage = ({ children, className }) => (
  <div className={classNames(['sidebar-page', className])}>{children}</div>
);

SidebarPage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};
