import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Quote, getQuoteSource } from 'src/views/sales_portal/lib/quotes';

import { Link, Money, StateBadge, TableBodyCell, TableBodyRow } from 'fr-shared/components';

import { date } from 'portal/lib/date';

import CustomerNameCell from './CustomerNameCell/CustomerNameCell';

interface ListViewRowProps {
  owner?: boolean;
  quote: Quote;
}

const ListViewRow = ({ owner = false, quote }: ListViewRowProps) => {
  const navigateToString = () => {
    return `/admin/quotes/${quote.id}`;
  };

  const images = get(quote, 'line_items', [])
    .map((lineItem: any) => get(lineItem, 'screenshot', null))
    .filter((screenshot: any) => screenshot)
    .toString();

  return (
    <TableBodyRow isDashed={quote.state === 'Draft' && !owner} isBlueBorder={owner}>
      <TableBodyCell value={quote.customer.name}>
        <CustomerNameCell id={quote.id} name={quote.customer.name} screenshotSrc={images} />
      </TableBodyCell>
      <TableBodyCell value={quote.public_id}>
        <Link to={navigateToString()}>{quote.public_id}</Link>
      </TableBodyCell>
      <TableBodyCell value={getQuoteSource(quote.customer_portal)} />
      <TableBodyCell>
        <Money amount={quote?.total_price?.amount} />
      </TableBodyCell>
      <TableBodyCell value={date(quote.date_submitted)} />
      <TableBodyCell align="left">
        <StateBadge state={quote.state}>{owner ? 'My Draft' : quote.state}</StateBadge>
      </TableBodyCell>
    </TableBodyRow>
  );
};

ListViewRow.propTypes = {
  owner: PropTypes.bool,
  quote: PropTypes.object,
};

export default memo(ListViewRow);
