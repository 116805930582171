import * as Sentry from '@sentry/react';
import { connect } from 'formik';
import { isEmpty } from 'lodash';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { AlertContext, UserContext, useUserAnalyticsContext } from 'fr-shared/context';
import { AddressTypeEnum, LABELS_BY_COUNTRY } from 'fr-shared/lib/address';
import { COUNTRIES } from 'fr-shared/lib/countries';
import { CUSTOMER_PORTAL_PO_ORDER_ITEMS_SUBTOTAL_MINIMUM as PO_ORDER_ITEMS_SUBTOTAL_MINIMUM } from 'fr-shared/lib/orders';

import {
  Button,
  Checkbox,
  FormCheckbox,
  FormCountries,
  FormField,
  FormStates,
} from 'portal/components';
import { subscriptionSchema } from 'portal/lib/schema';

import AddressSelector from './AddressSelector';
import CardCheckout from './CardCheckout';
import PaymentContainer from './Payment/PaymentContainer';

interface CheckoutBillingProps {
  analyticsContext: string;
  formik: any;
  isActive: boolean;
  isSubscriptionCheckout?: boolean;
  itemsSubtotal?: number;
  onRequestManualQuote?: () => void;
  quoteId?: number;
  shippingIsValid?: boolean;
}

const CheckoutBilling = ({
  analyticsContext = 'Checkout',
  formik,
  isActive,
  isSubscriptionCheckout = false,
  itemsSubtotal,
  onRequestManualQuote,
  quoteId = null,
  shippingIsValid,
}: CheckoutBillingProps) => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const { setAlert } = useContext(AlertContext);
  const userAnalytics = useUserAnalyticsContext();
  const braintreeInstance = useRef(null);
  const [token, setToken] = useState(null);
  const [dropInLoaded, setDropInLoaded] = useState(false);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const [addresses, setAddresses] = useState(null);
  const planType = new URLSearchParams(location.search).get('plan');
  const isSubscriptionFormValid = subscriptionSchema.isValidSync(formik.values);
  const labelsByCountry = LABELS_BY_COUNTRY[formik.values?.billing_address?.country];

  const prefixAnalyticsName = (name: string) => `${analyticsContext} - ${name}`;

  const showUnexpectedError = () =>
    setAlert({
      color: 'danger',
      autoClose: false,
      message: (
        <span className="font-size-md flex align-items-center">
          Something unexpected happened. Please try again{' '}
          {onRequestManualQuote && (
            <>
              or continue with a{' '}
              <Button color="link" className="ml-1" onClick={onRequestManualQuote}>
                manual quote
              </Button>
            </>
          )}
        </span>
      ),
    });

  const refreshAddresses = useCallback(() => {
    api
      .get(`/customer_portal/account/${user.id}/billing_addresses`, {
        params: { sort_key: 'inserted_at', sort_desc: true },
      })
      .then(res => {
        setSelectedAddressIndex(0);
        setAddresses(res.data);
      });
  }, [user.id]);

  useEffect(() => {
    // get the braintree token to authenticate the drop-in
    api
      .get(`/braintree_token?user_id=${user.id}`)
      .then(res => setToken(res.data))
      .catch(error => {
        Sentry.setExtra('error', error);
        Sentry.setExtra('user', user);
        Sentry.captureMessage('Portal Braintree Token Error', 'error' as any);
        showUnexpectedError();
      });

    refreshAddresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAddresses, user.id]);

  // Whenever an address is selected, set formik state
  useEffect(() => {
    if (!addresses) return;

    const selectedAddress = addresses[selectedAddressIndex];
    if (selectedAddress) {
      formik.setFieldValue('billing_address', selectedAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddressIndex, addresses]);

  const handleInstance = (instance: any) => {
    setDropInLoaded(true);
    braintreeInstance.current = instance;
  };

  const handleBillingSubmit = (quoteId: number) => {
    const redirectTo = isSubscriptionCheckout
      ? `/subscription/review?plan=${planType}`
      : quoteId
      ? `/checkout/${quoteId}/review`
      : '/checkout/review';
    const logEvt = (valid: boolean) =>
      userAnalytics.track(prefixAnalyticsName('Completed Billing'), { valid });
    if (formik?.values?.credit_card_checkout) {
      if (braintreeInstance.current) {
        braintreeInstance.current.requestPaymentMethod().then((res: any) => {
          formik.setFieldValue('payment_method_nonce', res.nonce);
          formik.setFieldValue('payment_details', res.details);
          formik.setFieldValue('po_documents', undefined);
          formik.setFieldValue('customer_po', undefined);
          formik.setFieldValue('billing_address.email', undefined);
          logEvt(true);
          history.push(redirectTo);
        });
      } else {
        Sentry.setExtra('braintreeInstance ref', braintreeInstance);
        Sentry.setExtra('braintreeInstance current', braintreeInstance.current);
        Sentry.captureMessage('Portal Billing Submit Error', 'error' as any);
        logEvt(false);
        showUnexpectedError();
      }
    } else {
      formik.setFieldValue('payment_method_nonce', undefined);
      history.push(redirectTo);
    }
  };

  const handleUseShippingAddress = (e: React.ChangeEvent<any>) => {
    const isChecked = !!e.target.checked;
    const newBillingAddress = isChecked ? formik.values.shipping_address : {};
    userAnalytics.track(prefixAnalyticsName('Same as shipping address'), { valid: isChecked });
    formik.setFieldValue('billing_address', newBillingAddress);
  };

  const handleStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    if (!formik.values.billing_address.country)
      formik.setFieldValue('billing_address.country', COUNTRIES.UNITED_STATES);
  };

  const editUrl = isSubscriptionCheckout
    ? `/subscription/billing?plan=${planType}`
    : quoteId
    ? `/checkout/${quoteId}/billing`
    : '/checkout/billing';

  const isEditable = isSubscriptionCheckout || (shippingIsValid && !formik.isSubmitting);

  const selectedCountry = formik.values.billing_address?.country;

  return (
    <CardCheckout
      name="Billing"
      editUrl={editUrl}
      isActive={isActive}
      isEditable={isEditable}
      footer={
        <div className="py-4">
          <Button
            dataTestId="checkout-to-review"
            disabled={
              !formik.isValid ||
              (formik.values.credit_card_checkout && !dropInLoaded) ||
              (isSubscriptionCheckout && !isSubscriptionFormValid)
            }
            onClick={() => {
              handleBillingSubmit(quoteId);
            }}
          >
            Continue to review
          </Button>
        </div>
      }
    >
      <div>
        <h5 className="mb-3">Address</h5>
        {isEmpty(addresses) ? (
          <div className="flex flex-column justify-content-between">
            {!isSubscriptionCheckout && (
              <Checkbox
                name="use_shipping"
                label="Same as shipping address"
                className="mb-3"
                onChange={handleUseShippingAddress}
              />
            )}
            <div className="row">
              <div className="col-md">
                <FormField
                  name="billing_address.name_line_1"
                  label="Full Name"
                  floatingLabel={true}
                  onlyShowErrorIfTouched={true}
                  required={true}
                />
              </div>
              <div className="col-md">
                <FormField
                  name="billing_address.name_line_2"
                  label="Company (optional)"
                  floatingLabel={true}
                  onlyShowErrorIfTouched={true}
                  onBlur={e => {
                    if (e.target.value) {
                      userAnalytics.track(prefixAnalyticsName('Company field billing'), {
                        inputValue: e.target.value,
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <FormField
                  name="billing_address.street_line_1"
                  label="Address line 1"
                  stacked={true}
                  floatingLabel={true}
                  onlyShowErrorIfTouched={true}
                  required={true}
                />
              </div>
              <div className="col-md">
                <FormField
                  name="billing_address.street_line_2"
                  label="Address line 2 (optional)"
                  floatingLabel={true}
                  onlyShowErrorIfTouched={true}
                  stacked={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <FormCountries
                  name="billing_address.country"
                  label={<span>Country</span>}
                  required={true}
                  floatingLabel={true}
                  onlyShowErrorIfTouched={true}
                  placeholder="Country"
                />
              </div>
              <div className="col-md">
                <FormStates
                  country={selectedCountry}
                  label={labelsByCountry ? labelsByCountry.state : 'State'}
                  name="billing_address.state"
                  required={true}
                  floatingLabel={true}
                  onlyShowErrorIfTouched={true}
                  onChange={handleStateChange}
                  placeholder={labelsByCountry ? labelsByCountry.state : 'State'}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <FormField
                  name="billing_address.city"
                  label="City"
                  stacked={true}
                  floatingLabel={true}
                  onlyShowErrorIfTouched={true}
                  required={true}
                />
              </div>
              <div className="col-md">
                <FormField
                  name="billing_address.postal_code"
                  label={labelsByCountry ? labelsByCountry.zip : 'ZIP Code'}
                  floatingLabel={true}
                  onlyShowErrorIfTouched={true}
                  required={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md"></div>
              <div className="col-md" />
            </div>
            {!isSubscriptionCheckout && (
              <FormCheckbox
                name="billing_address.associate_user"
                label="Save this billing address to my account"
                className="mt-2"
                onBlur={e => {
                  userAnalytics.track(prefixAnalyticsName('Save billing address'), {
                    valid: !!e.target.checked,
                  });
                }}
              />
            )}
          </div>
        ) : (
          <AddressSelector
            addresses={addresses}
            onAddressAdded={refreshAddresses}
            onAddressIndexSelected={setSelectedAddressIndex}
            selectedAddressIndex={selectedAddressIndex}
            type={AddressTypeEnum.Billing}
          />
        )}
        <PaymentContainer
          formik={formik}
          showPoOrderOption={
            !isSubscriptionCheckout && itemsSubtotal >= PO_ORDER_ITEMS_SUBTOTAL_MINIMUM
          }
          brainTreeToken={token}
          handleBrainTreeInstance={handleInstance}
        />
      </div>
    </CardCheckout>
  );
};

export default connect(CheckoutBilling);
