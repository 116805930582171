import { Form, Formik } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import {
  Breadcrumb,
  Button,
  FormLocations,
  FormNumber,
  FormSelect,
  Helmet,
} from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';

const LeadTimeNew = ({ location, history }) => {
  const [manufacturingProcesses, setManufacturingProcesses] = useState();
  const { setAlert } = useContext(AlertContext);
  useEffect(() => {
    api.get('/manufacturing_process').then(res => setManufacturingProcesses(res.data));
  }, []);

  const handleSubmit = (values, actions) => {
    api
      .post('/lead_times', { lead_time: values })
      .then(res => {
        const mfgProcess = get(
          manufacturingProcesses.find(mfgp => mfgp.id === res.data.manufacturing_process_id),
          'name'
        );
        setAlert({
          message: 'Successfully set the lead times for ' + mfgProcess,
          color: 'success',
        });
        history.goBack();
      })
      .catch(err => {
        if (err.response?.data) {
          const errors = err.response.data.errors;
          const messages = err.response.data.messages;
          actions.setErrors({
            server: messages,
            ...errors,
          });
        }

        setAlert({
          message: 'Sorry, we were unable to update this lead time.',
          color: 'danger',
        });
      });
  };

  const initialValues = get(location, 'state', {
    manufacturing_process_id: null,
    min_days: null,
    max_days: null,
  });

  if (!manufacturingProcesses) return null;
  return (
    <>
      <Helmet title="Lead Times" />
      <Breadcrumb to="/admin/lead_times">Lead Times</Breadcrumb>
      <Breadcrumb to="/admin/lead_times/new">Add Lead Time</Breadcrumb>
      <div className="container">
        <div className="flex align-items-center py-3">
          <h2 className="mb-0">Add a Lead Time</h2>
          <div className="ml-auto">
            <Button color="secondary" onClick={e => history.goBack(e)}>
              Back
            </Button>
          </div>
        </div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form>
            <div className="row">
              <div className="col">
                <FormSelect
                  label="Manufacturing Process"
                  name="manufacturing_process_id"
                  optionList={manufacturingProcesses}
                />
                <FormLocations name="location_id" />
              </div>
              <div className="col">
                <FormNumber name="min_days" label="Minimum Lead Time(days)" />
                <FormNumber name="max_days" label="Maximum Lead Time(days)" />
              </div>
            </div>
            <div>
              <div className="mt-4">
                <Button type="submit" color="success">
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

LeadTimeNew.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default LeadTimeNew;
