import { useQuery } from 'react-query';

import { api } from 'fr-shared/api';

const fetchCart = () => api.get('/customer_portal/has_cart').then(res => res.data);

const useCart = () => {
  return useQuery('has_cart', fetchCart, { staleTime: 2000 });
};

export default useCart;
