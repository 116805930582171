import { sanitizeUrl } from '@braintree/sanitize-url';
import { useFormikContext } from 'formik';
import moment from 'moment';
import PropTypes, { number, shape, string } from 'prop-types';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Button,
  CostingRequestStateBadge,
  FormTeamPicker,
  Link,
  Modal,
  Tabs,
} from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { filterByCostingState, isDraft, isSubmitted } from 'fr-shared/lib/costing_requests';
import { getOpportunityUrl } from 'fr-shared/lib/salesforce';

import SendCostingEmailModal from './SendCostingEmailModal';
import { CostingFormContext } from './context/CostingFormContext';

const CostingFormHeader = ({
  onSaveDraft,
  onSubmit,
  onArchive,
  title,
  setContact,
  salesUsers,
  supportUsers,
  engineeringUsers,
}) => {
  const formik = useFormikContext();
  const { lineItemFilters, setLineItemFilter } = useContext(CostingFormContext);
  const { user } = useContext(UserContext);
  const history = useHistory();
  const isCRSubmitted = isSubmitted(formik.values);
  const isCRDraft = isDraft(formik.values);

  const getCount = state => {
    const lineItems = filterByCostingState(formik.values.line_items, state);
    return lineItems.length || 0;
  };

  const costingTabs = ['Requested', 'In Progress', 'Completed', 'Rejected', 'Closed'].map(
    tabName => ({
      active: lineItemFilters.state === tabName,
      count: getCount(tabName),
      dataTestId: `costing-request-page--costing-tab-${tabName}`,
      disabled: getCount(tabName) === 0,
      name: tabName,
      onClick: () => setLineItemFilter('state', tabName),
    })
  );

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const handleArchive = () => {
    setShowArchiveModal(false);
    onArchive();
  };

  return (
    <div className="page-header page-mobile mb-0">
      <div className="container align-items-start">
        <Modal isOpen={showArchiveModal}>
          <>
            <Modal.Header
              textUppercase={false}
              title={`Are you sure you want to archive CR #${formik.values.id}?`}
              onClose={() => setShowArchiveModal(false)}
            ></Modal.Header>
            <Modal.Body>
              <p>
                This action will remove all access to this costing request. It will no longer
                appear in any queues. This action cannot be undone.
              </p>
              <p>Do you wish to archive this costing request?</p>
            </Modal.Body>
            <Modal.Footer>
              <div className="flex flex-row justify-content-end">
                <Button
                  className="mr-2"
                  outline
                  color="primary"
                  onClick={() => setShowArchiveModal(false)}
                >
                  Cancel
                </Button>
                <Button color="primary" onClick={handleArchive}>
                  Archive
                </Button>
              </div>
            </Modal.Footer>
          </>
        </Modal>
        <div className="w-100">
          <div className="flex flex-row w-100">
            <h2 className="mr-2" data-testid="costing-request-page--header">
              {isCRDraft ? `DRAFT: ${title}` : title}
            </h2>
            {isCRSubmitted && <CostingRequestStateBadge state={formik.values.state} />}
            <div className="flex ml-auto ">
              <Button color="light" onClick={e => history.goBack(e)} className="mr-2">
                Back
              </Button>
              {user.canSendCostingEmail && !user.isCRDraft && !isCRDraft && (
                <SendCostingEmailModal />
              )}
              {!isCRSubmitted && (
                <>
                  {user.canArchiveCostingRequests && formik.values.id && (
                    <Button
                      onClick={() => setShowArchiveModal(!showArchiveModal)}
                      color="danger"
                      outline
                      className="mr-2"
                    >
                      Archive
                    </Button>
                  )}
                  <Button
                    onClick={onSaveDraft}
                    color="primary"
                    className="mr-2 min-w-[94px]"
                    loading={formik.isSubmitting}
                    showLoadingIcon={true}
                  >
                    {formik.values.id ? 'Update' : 'Save'} Draft
                  </Button>
                  <Button
                    onClick={onSubmit}
                    color="success"
                    loading={formik.isSubmitting}
                    showLoadingIcon={true}
                    className="min-w-[125px]"
                  >
                    Submit Request
                  </Button>
                </>
              )}
            </div>
          </div>

          {isCRSubmitted && (
            <div className="d-flex flex-row w-100 mt-3">
              <div className="mr-5">
                {formik.values.public_id && (
                  <div>
                    Public ID: <strong>{formik.values.public_id}</strong>
                  </div>
                )}
                {formik.values.customer && (
                  <div>
                    Customer:{' '}
                    <Link to={`/admin/customers/${formik.values.customer.id}`} openNewWindow>
                      <strong>{formik.values.customer.name}</strong>
                    </Link>
                  </div>
                )}
                <div>
                  Request Date:{' '}
                  <strong>{moment(formik.values.inserted_at).format('MM/DD/YY, h:mm A')}</strong>
                </div>
              </div>
              <div className="mr-5">
                <div>
                  Opportunity:{' '}
                  <Link
                    to={{ pathname: getOpportunityUrl(formik.values.opportunity_id) }}
                    openNewWindow
                  >
                    <strong>{formik.values.opportunity_id}</strong>
                  </Link>
                </div>
                {formik.values.customer && formik.values.customer_contact && (
                  <div>
                    Contact:{' '}
                    <Link
                      to={`/admin/customers/${formik.values.customer.id}/contact/${formik.values.customer_contact.id}`}
                      openNewWindow
                    >
                      <strong>{formik.values.customer_contact.name}</strong>
                    </Link>
                  </div>
                )}
                <div>
                  Complete Date:{' '}
                  <strong>
                    {formik.values.completed_at ? (
                      <>{moment(formik.values.completed_at).format('MM/DD/YY, h:mm A')}</>
                    ) : (
                      <>&mdash;/&mdash;/&mdash;</>
                    )}
                  </strong>
                </div>
              </div>
              <div className="mr-5">
                {formik.values.deal && (
                  <div>
                    Deal #: <strong>{formik.values.deal.hashid}</strong>
                  </div>
                )}
                {formik.values.created_by && (
                  <div>
                    Requested By: <strong>{formik.values.created_by.name}</strong>
                  </div>
                )}
                {formik.values.sales_contact && (
                  <div>
                    Sales Contact: <strong>{formik.values.sales_contact.name}</strong>
                  </div>
                )}
                <div>
                  <Link
                    to={{ pathname: sanitizeUrl(formik.values.box_file_location) }}
                    openNewWindow
                  >
                    <strong>Link to Box</strong>
                  </Link>
                </div>
              </div>
              <div className="ml-auto w-25">
                <FormTeamPicker
                  entity="costing-request"
                  user={user}
                  salesUsers={salesUsers}
                  salesContact={formik.values.sales_contact}
                  engineeringUsers={engineeringUsers}
                  engineeringContact={formik.values.engineering_contact}
                  supportUsers={supportUsers}
                  supportContact={formik.values.support_contact}
                  canCreateCostingRequests={user.canCreateCostingRequests}
                  handleChange={setContact}
                  showAvatar={true}
                  open={false}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {isCRSubmitted && (
        <div className="container mb-0">
          <Tabs className="mt-4" tabs={costingTabs} />
        </div>
      )}
    </div>
  );
};

const User = shape({
  id: number,
  name: string,
});

CostingFormHeader.propTypes = {
  onSaveDraft: PropTypes.func,
  onSubmit: PropTypes.func,
  onArchive: PropTypes.func,
  title: PropTypes.string,
  setContact: PropTypes.func,
  salesUsers: PropTypes.arrayOf(User),
  supportUsers: PropTypes.arrayOf(User),
  engineeringUsers: PropTypes.arrayOf(User),
};

export default CostingFormHeader;
