import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Card, FormDocuments, FormField, Icon } from 'fr-shared/components';
import { ORDER_PAYMENT_TERMS } from 'fr-shared/lib/orders';
import {
  PURCHASE_ORDER_FILE_EXTENSIONS,
  PURCHASE_ORDER_MAX_FILE_SIZE_MB,
} from 'fr-shared/utils/files';

import BraintreeDropIn from './BraintreeDropIn';
import PaymentTypeFormField from './PaymentTypeFormField';
import SupplierPOList from './SupplierPOList';

const OrderFormPaymentInformation = React.forwardRef(
  (
    {
      customerContactId,
      paymentTerms,
      paymentType,
      readonly,
      supplierPO,
      isExistingOrder,
      orderType,
    },
    _ref
  ) => {
    const formik = useFormikContext();

    const showBraintreeDropIn =
      paymentType === 'Customer Credit Card' || paymentTerms === 'Credit Card';

    return (
      <Card border={true}>
        <Card.Header>
          <Icon name="money-bill-alt" className="mr-2" />
          Payment Information
        </Card.Header>

        <Card.Body>
          <FormField
            label="Tax Rate"
            name="tax_rate"
            type="number"
            append="%"
            readonly={readonly}
          />
          <PaymentTypeFormField readonly={readonly} />
          <FormField label="Customer PO" name="customer_po" readonly={readonly} />

          <FormDocuments
            acceptedFileTypes={PURCHASE_ORDER_FILE_EXTENSIONS}
            fieldName="po_documents"
            isRequired={orderType === 'Production'}
            label="PO Documents"
            maxSizeInBytes={PURCHASE_ORDER_MAX_FILE_SIZE_MB * 1000000}
            readonly={readonly}
            s3Endpoint="/s3/sign/purchase_order_file"
            showDate
            showUploadContainer
          />

          {!isEmpty(formik.values.supplier_po) && (
            <FormField label="Legacy Supplier PO" name="supplier_po" readonly={true} />
          )}

          {isExistingOrder && <SupplierPOList supplierPO={supplierPO} />}

          <FormField
            label="Payment Terms"
            name="payment_terms"
            component="select"
            readonly={readonly}
          >
            <option value="" />
            {ORDER_PAYMENT_TERMS.map(paymentTerm => (
              <option key={paymentTerm} value={paymentTerm}>
                {paymentTerm}
              </option>
            ))}
          </FormField>
          {customerContactId && (
            <div className={showBraintreeDropIn ? '' : 'hidden'}>
              <div className="add-payment-disabled">
                <BraintreeDropIn customerContactId={customerContactId} readonly={true} />
                <p>
                  If there are no credit cards associated with this contact, send them the quote
                  to accept via the Customer Portal.
                </p>

                <p>
                  You can also send an email requesting credit card information via the customer
                  contact page. We no longer support manual credit card entry so we can comply
                  with Payment Card Industry Data Security Standards.
                </p>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    );
  }
);

OrderFormPaymentInformation.displayName = 'OrderFormPaymentInformation';

OrderFormPaymentInformation.propTypes = {
  customerContactId: PropTypes.number,
  paymentTerms: PropTypes.string,
  paymentType: PropTypes.string,
  readonly: PropTypes.bool,
  supplierPO: PropTypes.array,
  isExistingOrder: PropTypes.bool,
  orderType: PropTypes.string,
};

export default OrderFormPaymentInformation;
