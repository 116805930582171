import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { Button, Modal } from 'fr-shared/components';

const TransitionToApproved = ({
  isModalOpen,
  onAccept = () => {},
  onReject = () => {},
  orderId,
}) => {
  const handleClickApproveNoEmailButton = useCallback(() => {
    onAccept(orderId, { state: 'Customer Order Placed' });
  }, [onAccept, orderId]);

  return (
    <Modal isOpen={isModalOpen} toggle={onReject} className="modal-wide-lg">
      <Modal.Header
        onClose={onReject}
        textUppercase={false}
        title={<h3>Update state to Customer Order Placed</h3>}
      />
      <Modal.Body>
        <p>
          You are updating the state to <strong>Customer Order Placed</strong>.
        </p>
        <p>
          <strong>Are you sure you want to approve the order?</strong>
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-normal bg-white">
        <div className="flex-1 flex justify-content-between">
          <div>
            <Button color="link" className="px-0" onClick={onReject}>
              Cancel
            </Button>
          </div>
          <div>
            <Button onClick={handleClickApproveNoEmailButton} color="primary">
              Approve
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

TransitionToApproved.propTypes = {
  isModalOpen: PropTypes.bool,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  orderId: PropTypes.number,
};

export default TransitionToApproved;
