import { sortBy, uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { FormField, classNames } from 'fr-shared/components';

const sortByName = list => {
  return sortBy(list, item => item.name);
};

const FormSectionedSelect = ({
  inputClassName,
  placeholder,
  nameAccessor,
  sectionedOptions = [],
  sorted,
  ...props
}) => {
  return (
    <FormField
      {...props}
      inputClassName={classNames(['custom-select', inputClassName])}
      component="select"
    >
      {placeholder && (
        <option value="" disabled={true} hidden={true}>
          {placeholder}
        </option>
      )}
      {sectionedOptions &&
        sectionedOptions.map(section => (
          <React.Fragment key={`section-title-${uniqueId()}`}>
            <option value="" disabled={true}>
              {section.title}
            </option>
            {section.options &&
              (sorted ? sortByName(section.options) : section.options).map(option => (
                <option key={`${nameAccessor(option)}-${uniqueId}`} value={option.id}>
                  {nameAccessor(option)}
                </option>
              ))}
          </React.Fragment>
        ))}
    </FormField>
  );
};

FormSectionedSelect.propTypes = {
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  nameAccessor: PropTypes.func,
  sorted: PropTypes.bool,
  sectionedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          name: PropTypes.string,
        })
      ),
    })
  ),
};

FormSectionedSelect.defaultProps = {
  nameAccessor: option => option.name,
};
export default FormSectionedSelect;
