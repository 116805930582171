import * as Sentry from '@sentry/browser';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useFormikRef } from 'fr-shared/hooks';

import { LINE_ITEM_SORT_OPTIONS, sortLineItemsByOption } from '../utils/sort';

const QuoteFormContext = React.createContext({ setValuesAndPushToChannel: () => {} });

const mergeChannelValues = (channelValues, formikValues) => {
  const channelLineItems = channelValues.line_items;
  const newLineItems = formikValues.line_items.map(formikLineItem => {
    const lineItemUpdates = channelLineItems.find(
      channelLineItem =>
        channelLineItem.supplier_cost_part_quantity_id ===
        formikLineItem.supplier_cost_part_quantity_id
    );
    return { ...formikLineItem, ...lineItemUpdates };
  });

  return { ...formikValues, ...channelValues, line_items: newLineItems };
};

const QuoteFormProvider = ({ channel, children }) => {
  const [opportunityIdLineItemsSortKey, setOpportunityIdLineItemsSortKey] = useState(
    LINE_ITEM_SORT_OPTIONS.COMPLETED_AT_DESC
  );
  const [opportunityIdLineItems, setOpportunityIdLineItems] = useState([]);
  const formik = useFormikContext();
  const formikRef = useFormikRef();

  /**
   * This sets up event handling for the quote channel, setting the values
   * of the form to the response of a successful `refresh_quote` message
   * @params {object} channel - the phoenix channel to bind events to
   */
  useEffect(() => {
    if (!channel) return;

    const subscriptionId = channel.on('refresh_quote', res => {
      if (res.data?.errors) {
        const { errors: channelErrors, messages } = res.data;
        formikRef.current.setErrors({
          ...formikRef.current.errors,
          server: messages,
          ...channelErrors,
        });
      } else {
        const newValues = mergeChannelValues(res.data, formikRef.current.values);
        formikRef.current.setValues(newValues);
      }
    });

    return () => {
      channel.off('refresh_quote', subscriptionId);
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [channel]);

  const setValuesAndPushToChannel = values => {
    formik.setValues(values);
    channel && channel.push('refresh_quote', values);
  };

  const estimateShipping = payload =>
    new Promise((resolve, reject) => {
      if (channel) {
        // The data service's integral solver is set to time out at 45 seconds,
        // plus however long it takes UPS to respond, equals about a minute in my book.
        channel
          .push('estimate_shipping', payload, 60000)
          .receive('ok', message => resolve(message))
          .receive('error', reason => reject(reason))
          .receive('timeout', () => {
            Sentry.captureMessage('Timeout attempting to estimate shipping costs', {
              extra: { channelPushPayload: payload },
            });
            reject('timeout');
          });
      } else {
        reject();
      }
    });

  const sortedOpportunityIdLineItems = sortLineItemsByOption(
    opportunityIdLineItemsSortKey,
    opportunityIdLineItems
  );

  return (
    <QuoteFormContext.Provider
      value={{
        estimateShipping,
        opportunityIdLineItems,
        opportunityIdLineItemsSortKey,
        setOpportunityIdLineItems,
        setOpportunityIdLineItemsSortKey,
        setValuesAndPushToChannel,
        sortedOpportunityIdLineItems,
      }}
    >
      {children}
    </QuoteFormContext.Provider>
  );
};

QuoteFormProvider.propTypes = {
  channel: PropTypes.object,
  children: PropTypes.node,
};

export { QuoteFormProvider, QuoteFormContext };
