import React, { SyntheticEvent, useEffect } from 'react';

import { FormFieldBase, Select } from 'fr-shared/components';
import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';

import { trackInputFunctionType } from 'portal/hooks/useScopedAnalytics';
import { mapById } from 'portal/lib/util';

/** Public API */
export interface Props {
  className?: string;
  material: Material;
  formState: FinishAndColorState;
  onChange: (change: FinishAndColorChange) => void;
  trackInput: trackInputFunctionType;
  process: ManufacturingProcess;
}

export type FinishAndColorState = {
  finish_id?: number;
  color_id?: number;
};

interface FinishAndColorChange {
  finish: Finish;
  color: Color;
}

/** Internal Helpers */
const FIELD_NAMES = {
  MaterialFinish: 'finish_id',
  Color: 'color_id',
} as const;

const getColors = (finish: Finish, material: Material) => {
  if (finish?.colors?.length > 0) {
    return finish.colors;
  }
  // fallback to material colors like in FROS
  return material?.colors;
};

const DEFAULT_COLOR = { id: '', name: 'No color' };

/** Component */
export const FinishAndColorField = ({
  className,
  material,
  formState,
  onChange,
  trackInput,
  process,
}: Props) => {
  const finishMap = mapById(material?.finishes);
  const finishOptions = material?.finishes;
  const currentFinish = finishMap[formState?.finish_id];
  const hasOptions = !!finishOptions?.length;

  const relevantColors = getColors(currentFinish, material) || [];
  const colorMap = mapById(relevantColors);
  const currentColor = colorMap[formState?.color_id];
  const colorOptions = [
    process.name === MANUFACTURING_PROCESSES.CNC && DEFAULT_COLOR,
    ...relevantColors.sort((a, b) => a.name.localeCompare(b.name)),
  ];

  // Make sure the form state's finish id is consistent with the available options
  useEffect(() => {
    if (!currentFinish) {
      if (hasOptions) {
        let defaultOption;
        if (process.name === MANUFACTURING_PROCESSES.CNC) {
          defaultOption = finishOptions.find(finish => {
            return finish.name === 'As-Machined (Broken Edges)';
          });
        } else if (process.name === MANUFACTURING_PROCESSES.InjectionMolding) {
          defaultOption = finishOptions.find(finish => {
            return finish.name === 'SPI C-3';
          });
        }
        if (defaultOption === undefined) {
          // If 'As-Machined (Broken Edges)' doesn't exist, default to the first available finish
          const finishOptionsSorted = [...finishOptions].sort((a, b) =>
            a.name.localeCompare(b.name)
          );

          defaultOption = finishOptionsSorted[0];
        }
        onChange({ finish: defaultOption, color: currentColor || null });
      } else {
        // Clear out if no available options
        onChange({ finish: null, color: currentColor || null });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFinish, finishOptions, hasOptions]);

  /** Render */
  if (!hasOptions) return null;

  return (
    <div className={className}>
      <FormFieldBase name={FIELD_NAMES.MaterialFinish} label="Finish" isControl={true}>
        <Select
          name={FIELD_NAMES.MaterialFinish}
          className="form-group is-floating"
          selectClassName="form-control"
          sorted={true}
          hasBlankOption={false}
          value={currentFinish?.id?.toString()}
          optionList={finishOptions}
          onBlur={(event: SyntheticEvent<HTMLSelectElement>) =>
            trackInput('Material Finish', finishMap[+event.currentTarget.value]?.name)
          }
          onChange={(event: SyntheticEvent<HTMLSelectElement>) => {
            const finish_id = parseInt(event.currentTarget.value);
            onChange({ finish: finishMap[finish_id], color: null });
          }}
        />
      </FormFieldBase>

      <FormFieldBase name={FIELD_NAMES.Color} label="Color" isControl={true}>
        <Select
          name={FIELD_NAMES.Color}
          className="form-group is-floating"
          selectClassName="form-control"
          sorted={false}
          hasBlankOption={false}
          optionList={colorOptions}
          value={currentColor?.id?.toString() || DEFAULT_COLOR.id} // use empty string to match blank option as fallback
          onBlur={(event: SyntheticEvent<HTMLSelectElement>) => {
            const selectedColor = colorMap[+event.currentTarget.value]?.name || 'No color';
            trackInput('Finish Color', selectedColor);
          }}
          onChange={e => {
            const color_id = parseInt(e.currentTarget.value);
            onChange({ finish: currentFinish, color: colorMap[color_id] });
          }}
        />
      </FormFieldBase>
    </div>
  );
};

export default FinishAndColorField;
