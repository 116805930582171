import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { Button, FormContactPicker, FormTextArea, Icon, Modal } from 'fr-shared/components';

const TransitionToRejected = ({
  failedToSelectSalesContact,
  failedToSelectSupportContact,
  isModalOpen,
  onAccept = () => {},
  onReject = () => {},
  orderId,
  salesContact,
  salesUsers,
  setSalesContact,
  setSupportContact,
  supportContact,
  supportUsers,
}) => {
  const handleClickUpdateOrderStateButton = useCallback(() => {
    onAccept(orderId, {
      state: 'Action Required',
    });
  }, [onAccept, orderId]);

  return (
    <Modal isOpen={isModalOpen} toggle={onReject} className="modal-wide-lg">
      <Modal.Header
        onClose={onReject}
        textUppercase={false}
        title={<h3>Required. Add Contract Notes</h3>}
      />
      <Modal.Body>
        <p>
          You are updating the order state to <strong>Action Required</strong>.
        </p>
        <p>
          <strong>Provide additonal context on the steps to resolve any found issues.</strong>
        </p>

        <div>
          A notification will be sent to Sales Contact,{' '}
          <FormContactPicker
            unassignedButtonText="Assign Sales Contact"
            onChange={setSalesContact}
            showLabel={false}
            color={failedToSelectSalesContact ? 'danger' : 'primary'}
            options={salesUsers ?? []}
            style={{ display: 'inline-block' }}
            value={salesContact}
          />{' '}
          , and to the Support Contact{' '}
          <FormContactPicker
            unassignedButtonText="Assign Support Contact"
            onChange={setSupportContact}
            showLabel={false}
            color={failedToSelectSupportContact ? 'danger' : 'primary'}
            options={supportUsers ?? []}
            style={{ display: 'inline-block' }}
            value={supportContact}
          />{' '}
        </div>
        <div className="mt-4">
          <FormTextArea
            label="Comment"
            labelClassName="text-muted"
            stacked={true}
            required={true}
            name="order_state_event_comment"
            className="w-100 resize"
          />
          <p className="text-muted">
            <Icon name="eye-slash" /> Only visible to the internal FR team
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-normal bg-white">
        {(failedToSelectSalesContact || failedToSelectSupportContact) && (
          <p className="alert alert-danger text-center" outline="true">
            Select a Sales Contact and a Support Contact to continue
          </p>
        )}
        <div className="flex-1 flex justify-content-between">
          <div>
            <Button color="link" className="px-0" onClick={onReject}>
              Cancel
            </Button>
          </div>
          <div>
            <Button color="primary" onClick={handleClickUpdateOrderStateButton}>
              Update Order State
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

TransitionToRejected.propTypes = {
  failedToSelectSalesContact: PropTypes.bool,
  failedToSelectSupportContact: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  orderId: PropTypes.number,
  salesContact: PropTypes.shape({ name: PropTypes.string }),
  salesUsers: PropTypes.arrayOf(PropTypes.object),
  setSalesContact: PropTypes.func,
  setSupportContact: PropTypes.func,
  supportContact: PropTypes.shape({ name: PropTypes.string }),
  supportUsers: PropTypes.arrayOf(PropTypes.object),
};

export default TransitionToRejected;
