import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';

import { api } from 'fr-shared/api';

import { CostData, CostDriverData } from './cost_drivers';

export type AutoquoteTypeState = Autoquote | AutoquoteTierList;

export type AutoquoteResponseState = AutoquoteLoading | Autoquote | AutoquoteError;

export type AutoquoteTiersResponseState = AutoquoteLoading | AutoquoteTierList | AutoquoteError;

export type BulkConfigAutoquoteTiersResponseState =
  | AutoquoteLoading
  | BulkConfigAutoquoteTierList
  | AutoquoteError;

export type AutoquoteLoading = {
  loading: string;
};

export type AutoquoteTierList = AutoquoteTier[];

export type BulkConfigAutoquoteTierList = BulkConfigAutoquoteTier[];

export interface AutoquoteRequest {
  part_id?: number;
  part_file_revision_id?: number;
  quantity?: number;
  manufacturing_process_id?: number;
  material_id?: number;
  color_id?: number;
  layer_thickness_id?: number;
  inspection_type_id?: number;
  infill?: string;
  finish_note?: string;
  customer_portal_finish?: string;
  finish_id?: number;
  use_defaults?: boolean;
  tolerance_id?: number;

  cnc_advanced_features?: boolean;
  cnc_certifications?: boolean;
  cnc_gdt?: boolean;
  cnc_masked_surfaces?: boolean;
  cnc_surface_roughness_id?: number;
  cnc_thread_count?: number;
  cnc_thread_variety?: number;

  cnc_tolerance_count_003?: number;
  cnc_tolerance_count_002?: number;
  cnc_tolerance_count_001?: number;
  cnc_tolerance_count_0005?: number;
}

export interface AutoquoteBulkConfigRequest {
  part_data: { part_file_revision_id: number; quantity: number }[];
  manufacturing_process_id?: number;
  material_id?: number;
  color_id?: number;
  layer_thickness_id?: number;
  inspection_type_id?: number;
  infill?: string;
  finish_note?: string;
  customer_portal_finish?: string;
  finish_id?: number;
  use_defaults?: boolean;
  tolerance_id?: number;

  cnc_advanced_features?: boolean;
  cnc_certifications?: boolean;
  cnc_gdt?: boolean;
  cnc_masked_surfaces?: boolean;
  cnc_surface_roughness_id?: number;
  cnc_thread_count?: number;
  cnc_thread_variety?: number;

  cnc_tolerance_count_003?: number;
  cnc_tolerance_count_002?: number;
  cnc_tolerance_count_001?: number;
  cnc_tolerance_count_0005?: number;
}

export interface Autoquote {
  id: number;
  label?: string;
  total_price: Money;
  total_price_range_lower?: Money;
  total_price_range_upper?: Money;
  unit_price: Money;
  unit_price_range_lower?: Money;
  unit_price_range_upper?: Money;
  lead_time?: number;
  location_id?: number;
  production_region?: string;
}

export interface AutoquoteTier {
  id: number;
  advanced_inspection_price: Money;
  label: string;
  production_region: 'INTERNATIONAL' | 'USA' | null;
  location_id: number | null;
  total_price: Money;
  unit_price: Money;
  lead_time: number;
}

export interface BulkConfigAutoquoteTier {
  label: string;
  production_region: 'INTERNATIONAL' | 'USA' | null;
  location_id: number | null;
  unit_price?: Money;
  total_price: Money;
  lead_time?: number;
  parts: BulkConfigAutoquotePart[];
}

export interface BulkConfigAutoquotePart {
  id: number;
  advanced_inspection_price: Money;
  lead_time: number;
  part_file_revision: number;
  part_file_revision_id: number;
  total_price: Money;
  unit_price: Money;
}

export type AutoquoteErrorValues = string[] | Record<string, string[]>;

export interface AutoquoteError {
  code: string;
  errors: AutoquoteErrorValues;
}

export const AutoquoteErrors = {
  InvalidFileType: 'InvalidFileType',
  BusinessReasons: 'BusinessReasons',
  MissingManufacturingProcess: 'MissingManufacturingProcess',
  MissingSupportingDocument: 'MissingSupportingDocument',
  CNCRequirementsNotMet: 'CNCRequirementsNotMet',
  CNCMaterialNotAutoquotable: 'CNCMaterialNotAutoquotable',
  CNCMissingCNCAutoquoteAttribute: 'CNCMissingCNCAutoquoteAttribute',
  CNCSurfaceRoughnessNotAutocostable: 'CNCSurfaceRoughnessNotAutocostable',
  CNCToleranceCountsNotAutocostable: 'CNCToleranceCountsNotAutocostable',
  CustomFinish: 'CustomFinish',
  Incomplete: 'Incomplete',
  InspectionType: 'InspectionType',
  ManualQuoteMfgProcess: 'ManualQuoteMfgProcess',
  ManufacturabilityErrors: 'ManufacturabilityErrors',
  ManufacturabilityChecksDidNotRun: 'ManufacturabilityChecksDidNotRun',
  PartTooBig: 'PartTooBig',
  UnsupportedMaterial: 'UnsupportedMaterial',
  CNCAdvancedFeatureSelectedYes: 'CNCAdvancedFeatureSelectedYes',
  CNCCertificationsSelectedYes: 'CNCCertificationsSelectedYes',
  MissingCNCAdvancedFeatures: 'MissingCNCAdvancedFeatures',
  MissingCNCCertification: 'MissingCNCCertification',
  MissingCncGdt: 'MissingCncGdt',
  MissingTolerance: 'MissingTolerance',
  ToleranceIsTooLow: 'ToleranceIsTooLow',
  CustomColorSelected: 'CustomColorSelected',
} as const;

export const AutoquoteErrorCodes = {
  RevisionNotFound: 'revision_not_found',
  RevisionNotAnalyzed: 'revision_not_analyzed',
  NotAutoquotable: 'not_autoquotable',
  ModelError: 'model_error',
  // catch-all for an unknown error code or missing error code
  Unknown: 'unknown',
} as const;

export const isAutoquoteLoading = (
  state:
    | AutoquoteResponseState
    | AutoquoteTiersResponseState
    | BulkConfigAutoquoteTiersResponseState
): state is AutoquoteLoading => {
  return 'loading' in state;
};

export const isAutoquote = (
  state: AutoquoteResponseState | AutoquoteTiersResponseState
): state is Autoquote => {
  return 'unit_price' in state;
};

export const isAutoquoteTierList = (
  state: AutoquoteTiersResponseState
): state is AutoquoteTierList => {
  return Array.isArray(state) && (state.length === 0 || 'location_id' in state[0]);
};

export const isAutoquoteError = (
  state:
    | AutoquoteResponseState
    | AutoquoteTiersResponseState
    | BulkConfigAutoquoteTiersResponseState
): state is AutoquoteError => {
  return 'errors' in state;
};

export const shouldShowManual = (
  state: AutoquoteResponseState | AutoquoteTiersResponseState
): boolean => {
  if (isAutoquoteLoading(state)) {
    return false;
  } else if (isAutoquoteError(state)) {
    return (
      Array.isArray(state.errors) &&
      state.errors.length > 1 &&
      state.errors.every((e: string) => e !== AutoquoteErrors.Incomplete)
    );
  } else {
    return false;
  }
};

export const newAutoquoteLoading = (): AutoquoteLoading => {
  return { loading: '' };
};

interface InsightsRequest {
  autoquote_id: string | number;
  part_file_revision_id: string | number;
  manufacturing_process_id: string | number;
  material_id: string | number;
  color_id: string | number;
}

export const generateInsightsLink = (params: InsightsRequest) => {
  api
    .post(`/customer_portal/insights`, params)
    .then(res => {
      window.open(`/insights/${res.data.uuid}`);
    })
    .catch(error => {
      Sentry.setExtra('error', error);
      Sentry.captureMessage('Insights link generation error');
    });
};

/**
 * Makes an api call to fetch {@link AutoquoteTierList} for the given {@link request}.
 * @param request
 * @throws an {@link AutoquoteError} on request failure
 */
export const fetchAutoquoteTiers = (request: AutoquoteRequest): Promise<AutoquoteTierList> => {
  return api
    .post<AutoquoteTierList>('/customer_portal/autoquote_tiers', request)
    .then(res => {
      return res.data;
    })
    .catch((error: AxiosError<AutoquoteError>) => {
      throw error.response.data;
    });
};

/**
 * Makes an api call to fetch {@link BulkConfigAutoquoteTierList} for the given {@link request}.
 * @param request
 * @throws an {@link AutoquoteError} on request failure
 */
export const fetchAutoquoteTiersForBulkConfig = (
  request: AutoquoteBulkConfigRequest
): Promise<BulkConfigAutoquoteTierList> => {
  return api
    .post<BulkConfigAutoquoteTierList>('/customer_portal/bulk_config_autoquote_tiers', request)
    .then(res => {
      return res.data;
    })
    .catch((error: AxiosError<AutoquoteError>) => {
      throw error.response.data;
    });
};

interface CostCurvePoint {
  predicted_cost: number;
  quantity: number;
}

export interface CostDriver {
  cost_driver_name: string;
  percentage: number;
  price: number;
}

/**
 * Makes an api call to fetch {@link CostData} for the given {@link autoquoteId}
 * @param autoquoteId
 * @throws the response error's payload if it is an {@link AxiosError}, otherwise throws the caught error
 */
export const fetchCostData = (autoquoteId: string | number): Promise<CostData> => {
  return api
    .get<CostData>(`/customer_portal/autoquotes/${autoquoteId}/insights/cost_data`)
    .then(res => res.data)
    .catch(err => {
      if (err?.isAxiosError) {
        throw err.response.data;
      }
      throw err;
    });
};

/**
 * Makes an api call to fetch {@link CostDriverData} for the given {@link autoquoteId} and {@link quantity}
 * @param autoquoteId
 * @param quantity
 * @throws the response error's payload if it is an {@link AxiosError}, otherwise throws the caught error
 */
export const fetchCostDriverData = (
  autoquoteId: string | number,
  quantity: number
): Promise<CostDriverData> => {
  return api
    .get<CostDriverData>(`/customer_portal/autoquotes/${autoquoteId}/insights/cost_drivers`, {
      params: { quantity: quantity },
    })
    .then(res => res.data)
    .catch(err => {
      if (err?.isAxiosError) {
        throw err.response.data;
      }
      throw err;
    });
};
