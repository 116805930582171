import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { FormField, Link } from 'fr-shared/components';

const PrintIdFormField = ({ showPrintIdLink, ...props }) => {
  const formik = useFormikContext();
  return (
    <>
      <FormField name="print_id" label="Print ID" {...props} />
      {showPrintIdLink && (
        <div className="form-group text-right">
          <Link to={{ pathname: formik.values.print_id }} openNewWindow>
            {formik.values.print_id}
          </Link>
        </div>
      )}
    </>
  );
};

PrintIdFormField.propTypes = {
  printId: PropTypes.string,
  showPrintIdLink: PropTypes.bool,
};

export default PrintIdFormField;
