import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { FormField, FormNumber } from 'fr-shared/components';
import {
  convertCeramicMaterialToRods,
  convertMetalMaterialToRods,
} from 'fr-shared/lib/work_orders';

import { BuildTimeFormField, PrinterFormField } from './fields';

const WorkOrderDetailsBMD = ({ isEditing, readonly }) => {
  const formik = useFormikContext();
  const { total_metal_material, total_ceramic_material } = formik.values;

  React.useEffect(() => {
    formik.setFieldValue('min_metal_rods', convertMetalMaterialToRods(total_metal_material));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total_metal_material]);

  React.useEffect(() => {
    formik.setFieldValue(
      'min_ceramic_rods',
      convertCeramicMaterialToRods(total_ceramic_material)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total_ceramic_material]);

  return (
    <div className="card-body">
      <div className="row">
        <div className="col-lg">
          {isEditing && <PrinterFormField readonly={readonly} />}
          <div className="d-none d-md-flex">
            <BuildTimeFormField />
          </div>

          <div className="row form-group d-none d-md-flex">
            <fieldset>
              <div className="col-md-4">
                <legend>Debind Time</legend>
              </div>
              <div className="col-md flex justify-content-around">
                <FormField
                  name="debind_time_hours"
                  append="hours"
                  type="number"
                  className="mr-3"
                />
                <FormField name="debind_time_minutes" append="minutes" type="number" />
              </div>
            </fieldset>
          </div>
          <FormField name="total_metal_material" label="Total Metal Material" append="g" />
          <FormField name="total_ceramic_material" label="Total Ceramic Material" append="g" />
          <FormNumber name="min_metal_rods" label="Minimum # of Metal Rods" disabled={true} />
          <FormNumber name="min_ceramic_rods" label="Minimum # of Ceramic Rods" disabled={true} />
        </div>

        <div className="col-lg">
          <FormField name="expected_green_mass" label="Expected Green Part Mass" append="g" />
          <FormField name="expected_brown_mass" label="Expected Brown Part Mass" append="g" />
          <FormField name="expected_total_mass" label="Expected Final Part Mass" append="g" />
          <FormField name="actual_green_mass" label="Actual Green Part Mass" append="g" />
          <FormField name="actual_brown_mass" label="Actual Brown Part Mass" append="g" />
          <FormField name="actual_total_mass" label="Actual Final Part Mass" append="g" />
          <FormNumber
            name="sinter_time"
            label="Sinter Time"
            placeholder="48"
            append="hr"
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
};

WorkOrderDetailsBMD.propTypes = {
  isEditing: PropTypes.bool,
  readonly: PropTypes.bool,
};

export default WorkOrderDetailsBMD;
