import React, { SyntheticEvent } from 'react';

import { FormField, IconFont, Link, classNames } from 'fr-shared/components';
import { MARKETING_URLS } from 'fr-shared/lib/marketing_urls';

import useScopedAnalytics from 'portal/hooks/useScopedAnalytics';

import styles from '../Payment.module.css';
import PoDocumentsList from './PoDocumentsList';
import PoDocumentsModal from './PoDocumentsModal';

type Props = { formik: any };

function PoCheckout({ formik }: Props) {
  const handleAnalytics = useScopedAnalytics('Checkout');
  const handleDeleteDocument = (index: number) => {
    const newDocuments = [...formik.values.po_documents];
    newDocuments.splice(index, 1);
    formik.setFieldValue('po_documents', newDocuments);
  };
  return (
    <div>
      <div className="row">
        <div className="col-md">
          <FormField
            name="customer_po"
            label="PO #"
            floatingLabel={true}
            onlyShowErrorIfTouched={true}
            required={true}
            dataTestId="po-checkout-customer-po"
            onBlur={(event: SyntheticEvent<HTMLSelectElement>) =>
              handleAnalytics('Entered PO #', event.currentTarget.value)
            }
          />
        </div>
        <div className="col-md align-center">
          <IconFont
            className={classNames([
              'font-size-xl relative right-[1.3rem] top-[.7rem]',
              styles.ToolTip,
            ])}
            name="info-circle"
            tooltip={'Add the number or reference you want to use for this purchase order (PO).'}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md">
          <FormField
            name="billing_address.email"
            label="Email invoice to:"
            floatingLabel={true}
            onlyShowErrorIfTouched={true}
            required={true}
            dataTestId="po-checkout-email"
            onBlur={(event: SyntheticEvent<HTMLSelectElement>) =>
              handleAnalytics('Entered billing email address', event.currentTarget.value)
            }
          />
        </div>
        <div className="col-md"></div>
      </div>
      {formik?.values?.po_documents?.length > 0 && (
        <div className="row mb-2">
          <div className="col-md">
            <div className="text-coolGray-300 text-xs mb-1">PO documents</div>
            <PoDocumentsList
              documents={formik.values.po_documents}
              handleDeleteDocument={handleDeleteDocument}
            />
          </div>
          <div className="col-md"></div>
        </div>
      )}
      <div className="row mb-3">
        <div className="col-md">
          <PoDocumentsModal />
        </div>
        <div className="col-md"></div>
      </div>
      <div className="flex">
        <IconFont className="mr-1 font-size-xl flex" name="info-circle" />
        <div>
          <div className="text-xs text-coolGray-100">
            Payment is due when we confirm your order.
          </div>
          <div className="text-xs text-coolGray-300">
            Your order isn&apos;t confirmed until we review it and send you an email confirmation.
            Our review takes up to 1 business day and we might include a credit check or request a
            deposit. Our{' '}
            <Link to={{ pathname: MARKETING_URLS.TermsAndConditions }} openNewWindow>
              Terms and Conditions
            </Link>{' '}
            govern the PO and your order. Notwithstanding any acceptance of your order and the
            commercial terms of the PO (e.g. pricing and delivery), we expressly reject any terms
            and conditions included or referenced in your PO.
          </div>
        </div>
      </div>
    </div>
  );
}

export default PoCheckout;
