import { MAINTENANCE_MODE } from 'fr-shared/feature_flags';
import { useFeatureFlags } from 'fr-shared/hooks';

const useMaintenanceModeFeatureFlag = () => {
  const { [MAINTENANCE_MODE]: isMaintenanceModeOn } = useFeatureFlags({
    [MAINTENANCE_MODE]: 'on',
  });

  return isMaintenanceModeOn;
};

export default useMaintenanceModeFeatureFlag;
