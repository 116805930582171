import { useTreatments } from '@splitsoftware/splitio-react';
import { FieldArray, Form, useFormikContext, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Icon, ServerError } from 'fr-shared/components';
import { CR_BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { useDuplicateLineItem, usePartUpdate } from 'fr-shared/hooks';
import { LINE_ITEMS_ACCESSOR } from 'fr-shared/lib/costing_requests';

import { transformCostingRequestForForm, transformDuplicateLineItems } from '../utils/transforms';
import CollapseOrExpandAll from './CollapseOrExpandAll';
import CostingFormLineItem from './CostingFormLineItem';
import DuplicateLineItemActions from './DuplicateLineItemActions';

const DuplicateLineItemsForm = props => {
  const { [CR_BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );
  const capLineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'Build Pack' : 'Line Item';

  usePartUpdate(LINE_ITEMS_ACCESSOR);
  const formik = useFormikContext();
  const { duplicateLineItem } = useDuplicateLineItem(LINE_ITEMS_ACCESSOR);
  const lineItems = formik.values[LINE_ITEMS_ACCESSOR];

  return (
    <Form onSubmit={props.handleSubmit}>
      <div className="sticky-top bg-white border-bottom">
        <div className="container">
          <ServerError className="mt-3" errors={formik.errors?.server} />
          <div className="flex justify-content-between py-3">
            <h2 className="mb-0">{`Add ${capLineItemName}s to #CR-${formik.values.id}`}</h2>
            <div>
              <Button loading={props.loading} outline onClick={props.onCloseForm}>
                Cancel
              </Button>
              <Button
                loading={props.loading}
                className="ml-2"
                color="success"
                onClick={props.handleSubmit}
              >
                <Icon name="check-circle" className="mr-1" />
                Submit for Costing
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-5">
        <FieldArray name={LINE_ITEMS_ACCESSOR}>
          {arrayHelpers => {
            return (
              <>
                <div className="flex flex-row flex-end py-2">
                  <CollapseOrExpandAll />
                </div>

                {lineItems.map((lineItem, lineItemIndex) => {
                  const key = `line-item-${lineItemIndex}`;
                  return (
                    <div key={key}>
                      <CostingFormLineItem
                        arrayHelpers={arrayHelpers}
                        customBorderColor="primary"
                        fieldPrefix={`${LINE_ITEMS_ACCESSOR}.${lineItemIndex}`}
                        isCustomerPortalAutoquote={false}
                        isCRSubmitted={false}
                        isDuplicating={true}
                        key={key}
                        lineItem={lineItem}
                        lineItemIndex={lineItemIndex}
                      >
                        <DuplicateLineItemActions
                          loading={props.loading}
                          onDuplicateLineItem={() => duplicateLineItem(lineItemIndex)}
                          onRemoveLineItem={() => arrayHelpers.remove(lineItemIndex)}
                        />
                      </CostingFormLineItem>
                    </div>
                  );
                })}
              </>
            );
          }}
        </FieldArray>
      </div>
    </Form>
  );
};

DuplicateLineItemsForm.propTypes = {
  loading: PropTypes.bool,
  onCloseForm: PropTypes.func,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialLineItems: PropTypes.array,
  costingRequest: PropTypes.object,
  onDuplicateLineItem: PropTypes.func,
};
export default withFormik({
  mapPropsToValues: ({ costingRequest, initialLineItems }) => {
    return transformCostingRequestForForm(costingRequest, {
      [LINE_ITEMS_ACCESSOR]: transformDuplicateLineItems(initialLineItems),
    });
  },
  handleSubmit: (values, formik) => {
    formik.props.onSubmit(values, formik);
  },
})(DuplicateLineItemsForm);
