import { COMPARISON_TOOL_V2 } from 'fr-shared/feature_flags';
import { useFeatureFlags } from 'fr-shared/hooks';

const useComparisonToolV2FeatureFlag = () => {
  const { [COMPARISON_TOOL_V2]: isCompararisonToolV2On } = useFeatureFlags({
    [COMPARISON_TOOL_V2]: 'on',
  });

  return isCompararisonToolV2On;
};

export default useComparisonToolV2FeatureFlag;
