/* eslint-disable react/display-name, react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Link, Table } from 'fr-shared/components';
import useSalesforceSybridgeInstanceFeatureFlag from 'fr-shared/hooks/useSalesforceSybridgeInstanceFeatureFlag';

import { getSalesforceLink } from '../show';

const ContactsTable = ({ data, pages, organizationId, onFetchData, ...props }) => {
  const sybridgeSalesforceInstanceEnabled = useSalesforceSybridgeInstanceFeatureFlag();
  return (
    <Table
      manual
      data={data}
      pages={pages}
      sortable={false}
      className="bg-white -striped -left mb-5"
      noDataText="No contacts found"
      onFetchData={onFetchData}
      {...props}
      columns={[
        {
          Header: '',
          width: 30,
          Cell: () => (
            <div>
              <Icon name="user" />
            </div>
          ),
        },
        {
          Header: 'Contact Name',
          accessor: 'name',
          Cell: row => {
            return (
              <div className="text-left">
                <Link to={`/admin/customers/${organizationId}/contact/${row.original.id}`}>
                  {row.original.name}
                </Link>
              </div>
            );
          },
        },
        { Header: 'Contact Email', accessor: 'email', width: 250 },
        {
          Header: 'Phone Number',
          accessor: 'phone_number',
        },
        {
          Header: 'Strategy',
          accessor: 'strategy',
        },
        {
          Header: 'Salesforce ID',
          id: 'salesforce_id',
          accessor: data => {
            return (
              data.salesforce_id && (
                <Link
                  to={{
                    pathname: getSalesforceLink(
                      data.salesforce_id,
                      sybridgeSalesforceInstanceEnabled
                    ),
                  }}
                  openNewWindow
                >
                  {data.salesforce_id}
                </Link>
              )
            );
          },
        },
        {
          Header: 'Braintree ID',
          accessor: 'braintree_id',
        },
      ]}
    />
  );
};

ContactsTable.propTypes = {
  data: PropTypes.array,
  pages: PropTypes.number,
  onFetchData: PropTypes.func,
};

export default ContactsTable;
