import { useTreatments } from '@splitsoftware/splitio-react';
import { connect } from 'formik';
import { get, isNil } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import {
  Alert,
  BulletPointAdviceModal,
  Button,
  DocumentsAlert,
  DocumentsList,
  DocumentsUpload,
  FormAddNotesMenu,
  FormField,
  FormFieldBase,
  FormFillDevButton,
  FormInspectionTypes,
  FormManufacturingProcess,
  FormSelect,
  Icon,
  LineItemDutiesTaxesTariffs,
  classNames,
} from 'fr-shared/components';
import { DocumentsContext, DocumentsProvider, UserContext } from 'fr-shared/context';
import { CR_BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { useFormikRef } from 'fr-shared/hooks';
import { COSTING_REQUEST_LI_STATES, isSubmitted } from 'fr-shared/lib/costing_requests';
import { unitsChangeHandler } from 'fr-shared/lib/line_items';
import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';
import { UNITS_OF_MEASUREMENT } from 'fr-shared/lib/parts';
import { isFormikAtPrefixTheSame } from 'fr-shared/utils';

import CostingLineItemPart from './CostingLineItemPart';
import CostingLineItemResubmissionReasons from './CostingLineItemResubmissionReasons';
import { EditPartInfoButtons } from './EditButtons';
import InspectionTypeModal from './InspectionTypeModal';
import SupplierCostPartQuantityFields from './SupplierCostPartQuantityFields';

const CostingLineItemFields = ({
  className,
  fieldPrefix,
  formik,
  isCustomerPortalAutoquote,
  isDuplicating,
  lineItem,
  onSaveLineItem,
}) => {
  const { user } = useContext(UserContext);
  // assuming that one CRLI has one build pack process
  const buildPackFieldPrefix = `${fieldPrefix}.build_package`;
  const processFieldPrefix = `${buildPackFieldPrefix}.processes.0`;
  const process = get(formik.values, processFieldPrefix);

  const { [CR_BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'Build Pack' : 'Line Item';
  const readonlyBuildPack =
    lineItem.build_package.working_status === 'committed' &&
    buildPackUiSupportFlag.treatment === 'on';

  const isCRSubmitted = isSubmitted(formik.values);

  const isEditingPartInfo = get(
    formik.values,
    `${fieldPrefix}.is_editing_part_information`,
    false
  );

  const canAddPartQuantities =
    user.canUpdateDraftOrRequestedCostingRequestLineItems && !isCRSubmitted;

  const isDraftEditable =
    lineItem.state === 'Draft' && user.canUpdateDraftOrRequestedCostingRequestLineItems;

  const isCrliCompleted = lineItem.state === COSTING_REQUEST_LI_STATES.Completed;

  const isSubmittedEditable =
    (lineItem.state === COSTING_REQUEST_LI_STATES.Requested ||
      lineItem.state === COSTING_REQUEST_LI_STATES.InProgress) &&
    isEditingPartInfo;

  const showToleranceAndRoughness =
    process?.manufacturing_process?.name === MANUFACTURING_PROCESSES.CNC;

  const arePartInfoFieldsEditable =
    !isCustomerPortalAutoquote && (isDraftEditable || isSubmittedEditable);

  const committedBuildPackInDraftCR = readonlyBuildPack && isDraftEditable;

  const hasInternalNotes = !isNil(lineItem?.internal_notes);
  const hasFinishNote = !isNil(process?.finish_note);
  const hasNotes = !isNil(lineItem?.notes);
  const hasDocuments =
    get(lineItem, 'part.documents', []).filter(doc => !doc.deleted_at).length > 0;

  const error = get(formik.errors, `${fieldPrefix}.id`);

  const formikRef = useFormikRef();
  const handleUnitsChange = unitsChangeHandler(lineItem, formikRef);

  const handleAddFinishNote = () => formik.setFieldValue(`${processFieldPrefix}.finish_note`, '');
  const handleRemoveFinishNote = () =>
    formik.setFieldValue(`${processFieldPrefix}.finish_note`, null);

  const handleAddInternalNotes = () => formik.setFieldValue(`${fieldPrefix}.internal_notes`, '');
  const handleRemoveInternalNotes = () =>
    formik.setFieldValue(`${fieldPrefix}.internal_notes`, null);

  const handleAddNotes = () => formik.setFieldValue(`${fieldPrefix}.notes`, '');
  const handleRemoveNotes = () => formik.setFieldValue(`${fieldPrefix}.notes`, null);
  const partInfoFields = (
    <>
      <div className="col-md-6">
        <FormField
          dataTestId="costing-line-item-field--part-name"
          label="Part Name"
          name={`${fieldPrefix}.description`}
          required={true}
          readonly={!arePartInfoFieldsEditable || readonlyBuildPack}
        />
        <FormSelect
          label="File Units"
          name={`${fieldPrefix}.units`}
          readonly={!arePartInfoFieldsEditable || readonlyBuildPack}
          hasBlankOption={false}
          onChange={handleUnitsChange}
          optionList={UNITS_OF_MEASUREMENT}
          valueAccessor={option => option}
          nameAccessor={option => option}
        />
        <FormManufacturingProcess
          readonly={!arePartInfoFieldsEditable || readonlyBuildPack}
          fieldPrefix={fieldPrefix}
          showBuildTime={false}
          showFinish={true}
          buildPackUIEnabled={true}
        />
        <FormInspectionTypes
          name={`${processFieldPrefix}.inspection_type_id`}
          label="Inspection Type"
          readonly={
            (!arePartInfoFieldsEditable &&
              `${processFieldPrefix}.inspection_type.name_with_price`) ||
            readonlyBuildPack
          }
          required={true}
          append={textValue => (
            <InspectionTypeModal
              inspectionTypeName={textValue}
              canEditFields={arePartInfoFieldsEditable || readonlyBuildPack}
            />
          )}
        />
        {canAddPartQuantities && (
          <div className="my-1">
            <SupplierCostPartQuantityFields
              fieldPrefix={fieldPrefix}
              supplierCostIndex={0}
              readonly={!arePartInfoFieldsEditable || readonlyBuildPack || isCrliCompleted}
            />
          </div>
        )}
      </div>
    </>
  );
  return (
    <>
      <div className={classNames(['card-body border-top', className])}>
        <div className="flex flex-row mb-3 align-items-start">
          <h4 className="col-md-auto px-0 mb-0 mr-auto">{lineItemName} Details</h4>
          <>
            {error && (
              <Alert className="ml-2" color="danger">
                {error}
              </Alert>
            )}
            <div className="ml-2 hide-empty">
              <EditPartInfoButtons
                fieldPrefix={fieldPrefix}
                isCustomerPortalAutoquote={isCustomerPortalAutoquote}
                isEditing={isEditingPartInfo}
                onSave={onSaveLineItem}
              />
            </div>
            {(arePartInfoFieldsEditable || committedBuildPackInDraftCR) && (
              <div className="ml-2">
                <FormFillDevButton form="COSTING_REQUEST_LINE_ITEM" pathPrefix={fieldPrefix} />
              </div>
            )}
          </>
        </div>
        <div className="row">
          {(!arePartInfoFieldsEditable && buildPackUiSupportFlag.treatment === 'on') ||
          readonlyBuildPack ? (
            <div className="col-md-6">
              {formik.values.created_by?.name && (
                <div className="line-item-static-data">
                  <div>Created by</div>
                  <span>{formik.values.created_by.name}</span>
                </div>
              )}
              <div className="line-item-static-data">
                <div>Created on</div>
                <span>{moment(formik.values.inserted_at).format('DD MMM YYYY')}</span>
              </div>
              {lineItem.part?.source && (
                <div className="line-item-static-data">
                  <div>Source</div>
                  <span>{lineItem.part.source}</span>
                </div>
              )}
              {process.material?.name && (
                <div className="line-item-static-data">
                  <div>Material</div>
                  <span>{process.material.name}</span>
                </div>
              )}
              {process.finish?.name && (
                <div className="line-item-static-data">
                  <div>Finish</div>
                  <span>{process.finish.name}</span>
                </div>
              )}
              {process.color?.name && (
                <div className="line-item-static-data">
                  <div>Color</div>
                  <span>{process.color.name}</span>
                </div>
              )}
              {process.inspection_type?.name && (
                <div className="line-item-static-data">
                  <div>Inspection type</div>
                  <span>{process.inspection_type.name}</span>
                </div>
              )}
              {canAddPartQuantities && (
                <div className="committed-line-item-quantity">
                  <SupplierCostPartQuantityFields
                    fieldPrefix={fieldPrefix}
                    supplierCostIndex={0}
                    readonly={isCrliCompleted}
                  />
                </div>
              )}
            </div>
          ) : (
            partInfoFields
          )}
          {!readonlyBuildPack ? (
            <DocumentsProvider
              fieldName="documents"
              fieldPrefix={`${fieldPrefix}.part`}
              readonly={!arePartInfoFieldsEditable}
              s3Endpoint={'/s3/sign/part_file'}
            >
              <div className="col-md-6">
                <CostingLineItemPart
                  fieldPrefix={fieldPrefix}
                  readonly={isCRSubmitted || isCrliCompleted}
                  showManufacturabilityChecks={user.canViewManufacturabilityChecks}
                />
                <CostingLineItemResubmissionReasons
                  fieldPrefix={fieldPrefix}
                  lineItem={lineItem}
                  readonly={!arePartInfoFieldsEditable}
                  isDuplicating={isDuplicating}
                />
                {arePartInfoFieldsEditable && (
                  <div className="flex flex-xl-row flex-lg-row flex-column justify-content-end mb-1">
                    <Button
                      className="ml-2 mb-1"
                      outline
                      color="secondary"
                      size="md"
                      onClick={handleAddFinishNote}
                    >
                      <Icon name="plus" /> Add Finish Notes
                    </Button>
                    <FormAddNotesMenu
                      dataTestIdSuffix={fieldPrefix}
                      onAddInternalNotes={handleAddInternalNotes}
                      onAddNotes={handleAddNotes}
                      showAddInternalNotes={!hasInternalNotes}
                      showAddNotes={!hasNotes}
                    />
                    <div className="ml-2 mb-1">
                      <DocumentsUpload>
                        <DocumentsContext.Consumer>
                          {({ isUploading }) => (
                            <Button
                              className="w-100"
                              color="secondary"
                              loading={isUploading}
                              tooltip={
                                isUploading
                                  ? 'Uploading document. This should only take a few seconds.'
                                  : null
                              }
                              outline
                              size="md"
                            >
                              <Icon name="plus" /> Add Supporting Docs
                            </Button>
                          )}
                        </DocumentsContext.Consumer>
                      </DocumentsUpload>
                    </div>
                  </div>
                )}
                <DocumentsAlert />
                {hasDocuments && (
                  <FormFieldBase
                    label="Supporting Documents"
                    readonly={!arePartInfoFieldsEditable}
                  >
                    <DocumentsList />
                  </FormFieldBase>
                )}
                {showToleranceAndRoughness && (
                  // For now we just hardcode these values until we have more information on what our offerings can be
                  <>
                    <FormFieldBase readonly={true} label="Tolerance">
                      Default Tolerance is +/- 0.005&quot; for metals and +/- 0.008&quot; for
                      plastics. Upload a drawing to the Supporting Docs to indicate a different
                      tolerance.
                    </FormFieldBase>
                    <FormFieldBase readonly={true} label="Roughness">
                      Default roughness is 125ui Ra.
                    </FormFieldBase>
                  </>
                )}
                {hasFinishNote && (
                  <FormField
                    label="Finish Notes"
                    name={`${processFieldPrefix}.finish_note`}
                    readonly={!arePartInfoFieldsEditable}
                    deleteTestId={`remove-finish-${processFieldPrefix}`}
                    onDelete={handleRemoveFinishNote}
                  />
                )}
                {hasNotes && (
                  <FormField
                    bulletPointHotkey
                    component="textarea"
                    inputStyle={{ height: 65, minHeight: 65 }}
                    label="Notes"
                    name={`${fieldPrefix}.notes`}
                    readonly={!arePartInfoFieldsEditable}
                    advice={
                      <div className="align-items-center flex flex-wrap font-size-sm justify-content-between">
                        <div className="my-1">
                          <Icon type="far" name="eye" />
                          {' Visible to everybody'}
                        </div>
                        {arePartInfoFieldsEditable && <BulletPointAdviceModal />}
                      </div>
                    }
                    deleteTestId={`remove-notes-${fieldPrefix}`}
                    onDelete={handleRemoveNotes}
                  />
                )}
                {hasInternalNotes && (
                  <FormField
                    component="textarea"
                    inputStyle={{ height: 65, minHeight: 65 }}
                    label="Internal Notes"
                    name={`${fieldPrefix}.internal_notes`}
                    readonly={!arePartInfoFieldsEditable}
                    advice={
                      <div className="font-size-sm my-1">
                        <Icon type="far" name="eye-slash" /> Only visible to FR team
                      </div>
                    }
                    deleteTestId={`remove-internal-notes-${fieldPrefix}`}
                    onDelete={handleRemoveInternalNotes}
                  />
                )}
              </div>
            </DocumentsProvider>
          ) : (
            <DocumentsProvider
              fieldName="documents"
              fieldPrefix={`${fieldPrefix}.part`}
              readonly={true}
              s3Endpoint={'/s3/sign/part_file'}
            >
              <div className="col-md-6">
                <CostingLineItemPart
                  fieldPrefix={fieldPrefix}
                  readonly={isCRSubmitted || isCrliCompleted}
                  showManufacturabilityChecks={user.canViewManufacturabilityChecks}
                />
                <CostingLineItemResubmissionReasons
                  fieldPrefix={fieldPrefix}
                  lineItem={lineItem}
                  readonly={!arePartInfoFieldsEditable}
                  isDuplicating={isDuplicating}
                />
                <DocumentsAlert />
                {hasDocuments && (
                  <FormFieldBase
                    label="Supporting Documents"
                    readonly={!arePartInfoFieldsEditable}
                  >
                    <DocumentsList />
                  </FormFieldBase>
                )}
                {showToleranceAndRoughness && (
                  // For now we just hardcode these values until we have more information on what our offerings can be
                  <>
                    <FormFieldBase readonly={true} label="Tolerance">
                      Default Tolerance is +/- 0.005&quot; for metals and +/- 0.008&quot; for
                      plastics. Upload a drawing to the Supporting Docs to indicate a different
                      tolerance.
                    </FormFieldBase>
                    <FormFieldBase readonly={true} label="Roughness">
                      Default roughness is 125ui Ra.
                    </FormFieldBase>
                  </>
                )}
                {hasFinishNote && (
                  <FormField
                    label="Finish Notes"
                    name={`${processFieldPrefix}.finish_note`}
                    readonly={true}
                    deleteTestId={`remove-finish-${processFieldPrefix}`}
                    onDelete={handleRemoveFinishNote}
                  />
                )}
                {hasNotes && (
                  <FormField
                    bulletPointHotkey
                    component="textarea"
                    inputStyle={{ height: 65, minHeight: 65 }}
                    label="Notes"
                    name={`${fieldPrefix}.notes`}
                    readonly={true}
                    advice={
                      <div className="align-items-center flex flex-wrap font-size-sm justify-content-between">
                        <div className="my-1">
                          <Icon type="far" name="eye" />
                          {' Visible to everybody'}
                        </div>
                      </div>
                    }
                    deleteTestId={`remove-notes-${fieldPrefix}`}
                    onDelete={handleRemoveNotes}
                  />
                )}
                {hasInternalNotes && (
                  <FormField
                    component="textarea"
                    inputStyle={{ height: 65, minHeight: 65 }}
                    label="Internal Notes"
                    name={`${fieldPrefix}.internal_notes`}
                    readonly={true}
                    advice={
                      <div className="font-size-sm my-1">
                        <Icon type="far" name="eye-slash" /> Only visible to FR team
                      </div>
                    }
                    deleteTestId={`remove-internal-notes-${fieldPrefix}`}
                    onDelete={handleRemoveInternalNotes}
                  />
                )}
              </div>
            </DocumentsProvider>
          )}
        </div>
      </div>

      <div className="card-body border-top">
        <h4 className="mb-3 col-md-auto px-0 mb-0 mr-auto">Duties, Taxes, and Tariffs</h4>
        <div className="row">
          <LineItemDutiesTaxesTariffs
            fieldPrefix={fieldPrefix}
            readonly={!arePartInfoFieldsEditable || readonlyBuildPack}
          />
        </div>
      </div>
    </>
  );
};

CostingLineItemFields.propTypes = {
  className: PropTypes.string,
  fieldPrefix: PropTypes.string,
  formik: PropTypes.object,
  isCustomerPortalAutoquote: PropTypes.bool,
  isDuplicating: PropTypes.bool,
  lineItem: PropTypes.object,
  onSaveLineItem: PropTypes.func,
};

const memoizedCostingLineItemFields = connect(
  React.memo(CostingLineItemFields, isFormikAtPrefixTheSame)
);

export default memoizedCostingLineItemFields;
