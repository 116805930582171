import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { IconFont } from 'fr-shared/components';
import { keyboardDown } from 'fr-shared/utils';

import { Button, classNames } from 'portal/components';

const InfoBox = ({ children, className, isDismissable, icon, link, title }) => {
  const [hidden, setHidden] = useState(false);
  const handleLinkClick = () => link.onClick();
  const handleClose = () => setHidden(true);

  if (hidden) return null;

  return (
    <div
      data-testid="info-box"
      className={classNames([
        'bg-gray-transparent rounded-md font-size-md flex flex-column',
        className,
      ])}
    >
      <div className="flex flex-row align-items-center px-3 pt-3">
        {icon}
        {title}

        {isDismissable && (
          <div className="ml-auto">
            <Button className="modal-close" onClick={handleClose}>
              <IconFont className="mr-0" name="close" />
            </Button>
          </div>
        )}
      </div>

      <div className="p-3">{children}</div>

      {link && (
        <div
          data-testid="info-box-link"
          className="cursor-pointer mt-auto px-3 pb-3"
          onClick={handleLinkClick}
          onKeyDown={evt => keyboardDown(evt, 'Enter', handleLinkClick)}
          role="button"
          tabIndex={0}
        >
          <span className="mr-1">{link.text}</span>
          <IconFont name="arrow-right" />
        </div>
      )}
    </div>
  );
};

InfoBox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.node,
  isDismissable: PropTypes.bool,
  color: PropTypes.oneOf(['light']),
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
  }),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default InfoBox;
