import { connect } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { api } from 'fr-shared/api';
import { Modal } from 'fr-shared/components';

import YieldReportForm from './YieldReportForm';

const WorkOrderYieldReportModal = ({ workOrder, isOpen }) => {
  const id = workOrder.id;

  const handleSubmit = (values, bag, toggle) => {
    const { setErrors } = bag;

    api
      .put('/part_failures/bulk_assign', {
        ...values,
      })
      .then(() => {
        toggle();
      })
      .catch(error => {
        if (error.response.data) {
          const { errors, messages } = error.response.data;
          setErrors({ server: messages, ...errors });
        }
      });
  };
  return (
    <Modal
      className="modal-wide-xl"
      backdrop="static"
      isOpen={isOpen}
      dataTestId="yield-report-modal"
    >
      {({ toggle }) => (
        <YieldReportForm
          id={id}
          workOrder={workOrder}
          handleSubmit={(values, bag) => handleSubmit(values, bag, toggle)}
        />
      )}
    </Modal>
  );
};

WorkOrderYieldReportModal.propTypes = {
  workOrder: PropTypes.object,
  isOpen: PropTypes.bool,
};

export default connect(WorkOrderYieldReportModal);
