import { map, uniq } from 'lodash';
import React, { useContext } from 'react';

import { Icon, Select } from 'fr-shared/components';

import { LINE_ITEM_SORT_TEXT } from '../utils/sort';
import { CostingFormContext } from './context/CostingFormContext';

const CostingFormFilterAndSort = () => {
  const {
    lineItems,
    lineItemFilters,
    setLineItemFilter,
    lineItemSortOption,
    setLineItemSortOption,
  } = useContext(CostingFormContext);

  const processes = lineItems
    .map(lineItem => lineItem?.build_package?.processes[0])
    .filter(process => process != null);

  const filteredManufacturingProcesses = uniq(map(processes, 'manufacturing_process.name'));

  return (
    <>
      <div className="form-group mb-0 ml-2 flex align-items-center">
        <label htmlFor="manufacturing_process" className="whitespace-nowrap mr-2">
          <Icon name="filter" /> Process:
        </label>
        <Select
          dataTestId="manufacturing_process_filter_select"
          name="manufacturing_process"
          value={lineItemFilters.manufacturing_process}
          onChange={e => setLineItemFilter('manufacturing_process', e.target.value)}
          optionList={['All', ...filteredManufacturingProcesses]}
          valueAccessor={option => option}
          nameAccessor={option => option}
        />
      </div>
      <div className="form-group mb-0 ml-2 flex align-items-center">
        <label htmlFor="line_item_sort" className="whitespace-nowrap mr-2">
          <Icon name="sort" /> Sort by:
        </label>
        <Select
          dataTestId="sort_select"
          name="line_item_sort"
          value={lineItemSortOption}
          onChange={e => setLineItemSortOption(e.target.value)}
          optionList={Object.keys(LINE_ITEM_SORT_TEXT)}
          nameAccessor={option => LINE_ITEM_SORT_TEXT[option]}
          valueAccessor={option => option}
        />
      </div>
    </>
  );
};

export default CostingFormFilterAndSort;
