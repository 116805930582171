import { SplitSdk } from '@splitsoftware/splitio-react';
import { isEmpty } from 'lodash';

export const BUILD_PACK_UI_SUPPORT = 'build-pack-ui-support';
export const COMPARISON_TOOL_V2 = 'comparison-tool-v2';
export const CR_BUILD_PACK_UI_SUPPORT = 'cr-build-pack-ui-support';
export const DISABLE_AUTO_SYNC_OLI_DATE_FIELDS_WITH_PSLI =
  'disable-auto-sync-oli-date-fields-with-psli';
export const USE_STALE_CLIENT_BANNER = 'use-stale-client-banner';
export const SAAS_BILLING = 'saas-billing';
export const USE_SUPPLIER_PORTAL_CONFIRMATION_BADGE = 'use-supplier-portal-confirmation-badge';
export const PORTAL_BULK_UPLOAD = 'portal-bulk-upload';
export const PORTAL_BULK_CONFIG = 'portal-bulk-config';
export const MAINTENANCE_MODE = 'maintenance-mode';
export const SALESFORCE_CALLS_ENABLED = 'salesforce-calls-enabled';
export const SALESFORCE_SYBRIDGE_INSTANCE = 'salesforce-sybridge-instance';

class FeatureFlagClient {
  constructor(userId) {
    if (!isEmpty(FeatureFlagClient.instance)) {
      return FeatureFlagClient.instance;
    }

    FeatureFlagClient.instance = this;

    const splitOptions = {
      core: {
        authorizationKey: process.env.SPLIT_CLIENT_KEY,
        key: userId,
      },
      startup: {
        readyTimeout: 1.5,
      },
    };

    if (process.env.MIX_ENV === 'dev') {
      splitOptions.core.authorizationKey = 'localhost';
      // configure local flags in `./local_feature_flags.json`
      // or reference how to use in ~/README.md
      splitOptions.features = process.env.LOCAL_FEATURE_FLAGS;
    }

    this.factory = SplitSdk(splitOptions);

    this.client = this.factory.client();

    this.isReady = false;
    this.client.once(this.client.Event.SDK_READY, () => {
      this.isReady = true;
    });

    return this;
  }
}

export default FeatureFlagClient;
