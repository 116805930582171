import { Badge, BadgeColor, IconFont } from '@fast-radius/shared-ui';
import { get, lowerCase } from 'lodash';
import moment from 'moment';
import React from 'react';

import { Link, Loading, classNames } from 'portal/components';
import { SUBSCRIPTION, getFormattedPricingBasedOffSelectedPlan } from 'portal/lib/plans';

import styles from './SubscriptionCards.module.css';

interface SubscriptionCardsProps {
  currentSubscription: string;
  isLoading: boolean;
  plans: Plan[];
  subscriptionData: Subscription;
}

const SubscriptionCards = ({
  currentSubscription,
  isLoading,
  plans,
  subscriptionData,
}: SubscriptionCardsProps) => {
  const nextBillingDate = moment(get(subscriptionData, 'next_billing_date')).format('ll');
  const billingCycle = get(subscriptionData, 'plan_version.billing_cycle');

  return (
    <div className="flex flex-col pt-8 sm:flex-row flex-wrap">
      {plans.map((plan: Plan) => {
        const { annualPrice, monthlyPrice, totalAnnualPrice } =
          getFormattedPricingBasedOffSelectedPlan(plan.name, plans);
        const isBasic = plan.name === SUBSCRIPTION.BASIC;
        const isSelected = plan.name === currentSubscription;

        const toUrl = `/subscription/${
          plan.name === SUBSCRIPTION.BASIC ? 'review' : 'frequency'
        }?plan=${plan.name}`;

        return (
          <div
            data-testid="subscription-plan-card"
            key={plan.id}
            className={classNames([
              isSelected && 'bg-coolGray-900 border border-coolGray-700',
              'text-coolGray-100 p-3 h-[557px] mr-4 rounded-3xl flex flex-col flex-1 min-w-[320px] max-w-xs mb-3 bg-coolGray-800',
            ])}
          >
            {isLoading ? (
              <div className="text-center mt-40">
                <Loading type="circle" />
              </div>
            ) : (
              <>
                <div className="text-2xl border border-b-coolGray-600 border-t-0 border-x-0 pb-0.5">
                  {!isBasic && <IconFont name="pro" className="text-tertiary-400 text-3xl" />}
                  {plan.name}
                  {isSelected && (
                    <Badge color={BadgeColor.TERTIARY} className="float-right p-0.5">
                      Current plan
                    </Badge>
                  )}
                </div>
                <div className="py-4 text-lg">
                  {/* Most plans will have both an annual & monthly pricing but this ternary operator is for displaying styling if both billing frequencies exist, then either monthly or annual */}
                  {monthlyPrice && annualPrice ? (
                    <>
                      {isBasic ? '$0' : annualPrice}{' '}
                      <span className="text-coolGray-300">
                        {isBasic ? 'forever' : 'per month'}
                      </span>
                      {!isBasic && (
                        <div className="text-sm text-coolGray-300">
                          Billed annually or {monthlyPrice} if month-to-month
                        </div>
                      )}
                    </>
                  ) : monthlyPrice ? (
                    <span>
                      {monthlyPrice}{' '}
                      <span className="text-sm text-coolGray-300">billed monthly</span>
                    </span>
                  ) : totalAnnualPrice ? (
                    <span>
                      {totalAnnualPrice}{' '}
                      <span className="text-sm text-coolGray-300"> billed annually</span>
                    </span>
                  ) : null}
                </div>
                <Feature>Unlimited cloud storage</Feature>
                <Feature>Basic DFM checks</Feature>
                <Feature>Basic comparison tool access</Feature>
                <div
                  className={classNames([
                    currentSubscription !== SUBSCRIPTION.BASIC &&
                      'border-t-coolGray-600 border border-b-0 border-x-0',
                    'mt-auto',
                  ])}
                >
                  {!isSelected ? (
                    <Link
                      to={toUrl}
                      className={classNames([
                        isBasic ? styles.BasicButton : styles.OtherButton,
                        'text-base w-fit mt-3 px-3 rounded-lg border justify-center no-underline h-6 text-center flex flex-col leading-3',
                      ])}
                    >
                      <div>
                        {!isBasic && <IconFont name="pro" className="text-2xl h-[24px]" />}
                        Switch to {plan.name}
                      </div>
                    </Link>
                  ) : !isBasic && nextBillingDate && billingCycle ? (
                    <div className="h-6 mt-3">
                      <div className="flex">Billed {lowerCase(billingCycle)}</div>
                      <div className="flex justify-between pb-3">
                        <span className="text-coolGray-300">Next payment:</span>
                        <span>{nextBillingDate}</span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

const Feature = ({ children, className }: { children: string; className?: string }) => (
  <div className={classNames([className, 'inline-flex'])}>
    <IconFont name="check" className="mr-1 text-tertiary-300" />
    <span className="inline-block text-coolGray-100">{children}</span>
  </div>
);

export default SubscriptionCards;
