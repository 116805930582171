import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';

import { Button, FormDate, FormField, Modal } from 'fr-shared/components';

interface ModalFormProps {
  oliIndex: number;
  psliIndex: number;
  quantity?: string;
}

interface ModalProps {
  action: ReactElement;
  onActionHandler?: () => void;
  submitButtonText?: string;
  title?: string;
  toggle?: () => void;
}
export type PSLIModalFormProps = ModalFormProps & ModalProps;

const PSLIModalForm: React.FC<PSLIModalFormProps> = props => {
  const {
    action,
    oliIndex,
    onActionHandler,
    psliIndex,
    quantity: oliQuantity,
    submitButtonText,
    title,
  } = props;
  const formik = useFormikContext();
  const formikPrefix = `line_items[${oliIndex}].planned_shipment_line_items[${psliIndex}]`;

  const [initialPSLIValues, setInitialPSLIValues] = useState({
    id: null,
    valid: false,
    commit_date: '',
    promised_ship_date: '',
    quantity: 0,
    supplier_promised_ship_date: '',
  });

  useEffect(() => {
    setInitialPSLIValues(
      get(formik.values, formikPrefix, {
        commit_date: '',
        promised_ship_date: '',
        quantity: '',
        supplier_promised_ship_date: '',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [psliIndex]);

  const isFormValid = () => {
    const { values, setFieldError, setErrors, errors }: any = formik;
    const {
      commit_date,
      promised_ship_date,
      already_fulfilled,
      quantity: psliQuantity,
    } = get(values, formikPrefix, {
      commit_date: '',
      promised_ship_date: '',
      quantity: 0,
    });
    let isValid = true;
    const currentOLIErrors = Array.isArray(errors?.line_items)
      ? errors?.line_items[oliIndex]
      : {};
    if (Array.isArray(currentOLIErrors?.planned_shipment_line_items)) {
      // reset psli errors on subsequent submit attempts
      currentOLIErrors.planned_shipment_line_items.splice(psliIndex, 1, {});
      errors.line_items.splice(oliIndex, 1, currentOLIErrors);
      setErrors({
        ...errors,
      });
    }

    if (!psliQuantity || psliQuantity <= 0) {
      isValid = false;
      setFieldError(`${formikPrefix}.quantity`, 'required - must be greater than 0');
    } else if (parseInt(oliQuantity) < 0) {
      isValid = false;
      setFieldError(`${formikPrefix}.quantity`, `can't be greater than total order quantity`);
    } else if (psliQuantity < already_fulfilled) {
      isValid = false;
      setFieldError(
        `${formikPrefix}.quantity`,
        `quantity cannot be less than already fulfilled quantity(${already_fulfilled})`
      );
    }

    if (isEmpty(commit_date)) {
      isValid = false;
      setFieldError(`${formikPrefix}.commit_date`, "can't be blank");
    }
    if (isEmpty(promised_ship_date)) {
      isValid = false;
      setFieldError(`${formikPrefix}.promised_ship_date`, "can't be blank");
    }
    return isValid;
  };

  const onCancelHandler = () => {
    const { setFieldValue }: any = formik;
    if (!initialPSLIValues.valid && !initialPSLIValues.id) {
      setFieldValue(formikPrefix, undefined);
    } else {
      setFieldValue(`${formikPrefix}`, initialPSLIValues);
    }
  };

  return (
    <Modal
      dataTestId="psli-modal-form"
      action={action}
      onActionHandler={onActionHandler}
      onCloseHandler={onCancelHandler}
    >
      {({ toggle }: ModalProps) => (
        <>
          <Modal.Header
            title={title}
            onClose={() => {
              onCancelHandler();
              toggle();
            }}
            textUppercase={false}
          />
          <Modal.Body className="px-3">
            <div className="form-group mb-3">
              <FormField
                {...props}
                label={`Quantity (${oliQuantity} Left)`}
                name={`${formikPrefix}.quantity`}
                type="number"
                required
                placeholder="Enter Quantity"
              />
              <FormDate
                {...props}
                label="Commit Date"
                name={`${formikPrefix}.commit_date`}
                isRequired
              />
              <FormDate
                {...props}
                label="Customer Promise Date"
                name={`${formikPrefix}.promised_ship_date`}
                isRequired
              />
              <FormDate
                {...props}
                label="Supplier Promise Date"
                name={`${formikPrefix}.supplier_promised_ship_date`}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-space-between">
            <>
              <Button
                dataTestId="psli-modal-form-cancel-button"
                color="light"
                className="border uppercase px-3"
                onClick={() => {
                  onCancelHandler();
                  toggle();
                }}
              >
                Cancel
              </Button>
              <Button
                dataTestId="psli-modal-form-submit-button"
                color="primary"
                className="ml-2 uppercase px-3"
                onClick={() => {
                  if (isFormValid()) {
                    // ok this is kinda gross, but as we are using formik as the data store,
                    // we need to add a value that we can key off of on the PSLITableEdit to show
                    // the new PSLI.
                    const { setFieldValue, values }: any = formik;

                    // set initial values to updated form state in case PSLI is
                    // edited successfully, then the modal is open and cancel is clicked
                    setInitialPSLIValues(get(values, formikPrefix));

                    setFieldValue(`${formikPrefix}.valid`, true);
                    setFieldValue(`${formikPrefix}.already_fulfilled`, 0);
                    setFieldValue(`${formikPrefix}.fulfillments`, []);
                    toggle();
                  }
                }}
              >
                {submitButtonText}
              </Button>
            </>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default PSLIModalForm;
