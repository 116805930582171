import React from 'react';

import { Modal, ProgressCircle, classNames } from 'fr-shared/components';

import Carousel from './Carousel/Carousel';
import styles from './PartUploadModal.module.css';
import Slider from './Slider/Slider';

interface PartUploadModalProps {
  onClose?: () => void;
  state: string;
}

export const PartUploadModal = ({ onClose, state }: PartUploadModalProps) => {
  return (
    <Modal backdrop="static" dataTestId="part-upload-modal" isOpen onCloseHandler={onClose}>
      {({ toggle }: { toggle: any }) => (
        <>
          <Modal.Header
            dataTestId="itar-ear-confirmation-modal--header-title"
            onClose={() => {
              if (onClose) onClose();
              toggle();
            }}
          >
            <div className="mt-5 flex">
              <div>
                <ProgressCircle
                  percentage={75}
                  spinner={true}
                  backgroundColor="#191e25"
                  fadesIntoBackground
                />
              </div>

              <div className={classNames([styles.ModalHeaderTextContainer, 'ml-4'])}>
                <Slider state={state} />
              </div>
            </div>
          </Modal.Header>

          <div className="modal-body pb-4">
            <Carousel />
          </div>
        </>
      )}
    </Modal>
  );
};

export default PartUploadModal;
