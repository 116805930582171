import React, { useContext } from 'react';

import {
  Breadcrumb,
  Helmet,
  Sidebar,
  SidebarPage,
  SidebarPageContainer,
} from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { useTableFilters } from 'fr-shared/hooks';

import CostingSearch from './components/CostingSearch';
import CostingSidebar from './components/CostingSidebar';
import CostingTable from './components/CostingTable';
import {
  COMPLETED_COLUMNS,
  FILTER_FIELDS,
  INITIAL_COMPLETED_FILTERS,
  INITIAL_COMPLETED_SORT,
  OPS_COMPLETED_STATES,
  SALES_COMPLETED_STATES,
  getCompletedCostingRequestsForOps,
  getCompletedCostingRequestsForSales,
} from './utils/queues';

const FILTER_NAME = 'costing_completed';

const FIELDS = [
  FILTER_FIELDS.State,
  FILTER_FIELDS.SalesContactId,
  FILTER_FIELDS.ManufacturingProcessId,
  FILTER_FIELDS.EngineeringContactId,
  FILTER_FIELDS.SupportContactId,
];

const CostingCompletedQueue = () => {
  const { user } = useContext(UserContext);

  const FILTER_STATES = user.isSales ? SALES_COMPLETED_STATES : OPS_COMPLETED_STATES;

  const {
    data: costingRequests,
    filters,
    loading,
    handleSearch: handleTextSearch,
    onFetchData: handleTableFetchData,
    pages,
    resetFilters,
    setFilters,
  } = useTableFilters(
    FILTER_NAME,
    INITIAL_COMPLETED_FILTERS,
    user.isSales ? getCompletedCostingRequestsForSales : getCompletedCostingRequestsForOps
  );

  return (
    <>
      <Helmet title="Completed Queue" />
      <Breadcrumb to="/admin/costing_requests">Costing Requests</Breadcrumb>
      <Breadcrumb to="/admin/costing_requests/completed_queue">Completed Queue</Breadcrumb>

      <SidebarPageContainer localStorageKey={Sidebar.LOCAL_STORAGE_KEYS.costing_completed}>
        <SidebarPage>
          <div className="page-header flex mb-0 px-3">
            <h2>Completed Queue</h2>
            <CostingSearch
              filters={filters}
              setFilters={setFilters}
              onTextSearch={handleTextSearch}
            />
          </div>

          <CostingTable
            columns={COMPLETED_COLUMNS}
            data={costingRequests}
            emptyText="No Requests are currently completed"
            loading={loading}
            onFetchData={handleTableFetchData}
            pages={pages}
            sort={INITIAL_COMPLETED_SORT}
          />
        </SidebarPage>

        <Sidebar>
          <CostingSidebar
            fields={FIELDS}
            filters={filters}
            resetFilters={resetFilters}
            setFilters={setFilters}
            states={FILTER_STATES}
          />
        </Sidebar>
      </SidebarPageContainer>
    </>
  );
};

export default CostingCompletedQueue;
