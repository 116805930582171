import { Logo } from '@fast-radius/shared-ui';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import {
  Button,
  Card,
  FormCustomerSelect,
  FormField,
  FormFieldWithLinkPreview,
  Icon,
} from 'fr-shared/components';

import OpportunityTable from './OpportunityTable';

const CostingFormOpportunityInformation = ({ formik, useManualForm }) => {
  const [opportunities, setOpportunities] = useState([]);
  const [opportunitiesLoaded, setOpportunitiesLoaded] = useState(false);
  const [activeOpportunityId, setActiveOpportunityId] = useState(formik.values.opportunity_id);
  const [isOpportunitiesLoading, setOpportunitiesLoading] = useState(false);
  const [showBoxLink, setShowBoxLink] = useState(false);
  const customerId = formik.values.customer_id;
  const contactId = formik.values.customer_contact_id;
  const getAndSetOpportunities = async ({ customerId, contactId }) => {
    setOpportunitiesLoading(true);
    setOpportunitiesLoaded(false);
    api
      .get('/salesforce_opportunities', {
        params: { customer_id: customerId, customer_contact_id: contactId },
      })
      .then(res => {
        setOpportunities(res.data);
        setOpportunitiesLoading(false);
        setOpportunitiesLoaded(true);
      });
  };

  const setOpportunity = useCallback(
    data => {
      if (data) {
        formik.setFieldValue('public_id', data.public_id);
        formik.setFieldValue('opportunity_id', data.id);
        formik.setFieldValue('opportunity_name', data.name);
        setActiveOpportunityId(data.id);
      } else {
        formik.setFieldValue('public_id', null);
        formik.setFieldValue('opportunity_id', null);
        formik.setFieldValue('opportunity_name', null);
        setActiveOpportunityId(null);
      }
    },
    [formik, setActiveOpportunityId]
  );

  useEffect(() => {
    if (!formik.values.opportunity_name && typeof activeOpportunityId === 'string') {
      api.get(`/salesforce_opportunities/${activeOpportunityId}`).then(res => {
        setOpportunity(res.data);
      });
    }
  }, [activeOpportunityId, setOpportunity, formik.values.opportunity_name]);

  const salesForceLink =
    process.env.MIX_ENV === 'prod'
      ? 'https://fastradius.lightning.force.com/'
      : 'https://cs59.lightning.force.com/';
  const NoOpportunites = () => {
    return (
      <Card className="mt-3">
        <div className="my-4">
          <h2 className="text-center text-muted">0 matching results</h2>
          <p className="mt-2 text-center">
            There are no open opportunities for this Customer Contact available.
          </p>
          <p className="mt-n2 text-center">
            Please create an opportunity in <a href={salesForceLink}>Salesforce</a>. Then search
            again.
          </p>
        </div>
      </Card>
    );
  };

  const Opportunities = ({ data }) => {
    if (!activeOpportunityId) {
      return (
        <div>
          <p>All Open Opportunities in Salesforce</p>
          <OpportunityTable
            data={data}
            emptyText={''}
            loading={isOpportunitiesLoading}
            showPagination={false}
            setOpportunity={setOpportunity}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  Opportunities.propTypes = {
    data: PropTypes.array,
  };

  const resetOpportunities = () => {
    setOpportunities([]);
    setOpportunity(null);
    setOpportunitiesLoaded(false);
    setOpportunitiesLoading(false);
  };

  return (
    <Card border className="bg-light">
      <Card.Header>
        <Logo fill="#777" width={22} showText={false} className="mr-2" />
        <span
          className="font-weight-light font-size-lg"
          data-testid="cr-opportunity-information--heading"
        >
          Opportunity Information
        </span>
      </Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-md">
            <FormCustomerSelect onCustomerChange={resetOpportunities} />
          </div>
          <div className="col-md">
            <Button
              dataTestId="cr-search-opportunities--button"
              disabled={!customerId || isOpportunitiesLoading}
              color="primary"
              onClick={() => getAndSetOpportunities({ customerId, contactId })}
            >
              Search Opportunities
            </Button>
          </div>
        </div>
        {opportunitiesLoaded && opportunities.length === 0 ? (
          <NoOpportunites />
        ) : isOpportunitiesLoading || (opportunitiesLoaded && opportunities.length > 0) ? (
          <>
            <br /> <Opportunities data={opportunities} />
          </>
        ) : (
          ''
        )}
        {activeOpportunityId ? (
          <>
            <div className="row">
              <div className="col-md">
                <FormField
                  disabled={true}
                  stacked={true}
                  label="Opportunity Name"
                  name="opportunity_name"
                  required={true}
                />
              </div>
              <div className="col-md">
                <FormField
                  disabled={true}
                  stacked={true}
                  label={
                    <>
                      Public ID{' '}
                      <Icon
                        className="text-primary"
                        name="info-circle"
                        tooltip="Public ID is the same as the quote or sync number in Salesforce."
                      />
                    </>
                  }
                  name="public_id"
                  required={true}
                />
              </div>
              <div className="col-md">
                <FormFieldWithLinkPreview
                  stacked={true}
                  label="Box Link"
                  name="box_file_location"
                  showLink={false}
                  required={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <Button onClick={() => setOpportunity(null)} color="link">
                  <Icon name="arrow-left" sm={true}></Icon> Back to opportunity list
                </Button>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
        <div className="row">
          <div className="col-md">
            {!activeOpportunityId && !showBoxLink ? (
              <Button color="link" className="mx-0 px-0" onClick={() => setShowBoxLink(true)}>
                <Icon name="plus" sm /> Add link to box
              </Button>
            ) : !activeOpportunityId ? (
              <FormFieldWithLinkPreview
                stacked={true}
                label="Box Link"
                name="box_file_location"
                showLink={false}
                required={true}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="flex align-items-center justify-content-center">
          <Button color="link" onClick={useManualForm}>
            Use Manual Form
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

CostingFormOpportunityInformation.propTypes = {
  formik: PropTypes.object,
  useManualForm: PropTypes.func,
};

export default CostingFormOpportunityInformation;
