import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Pagination, ProgressCircle } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { useTableFilters } from 'fr-shared/hooks';
import { ORDER_SORT_VALUES } from 'fr-shared/lib/orders';

import {
  EmptyOrdersCard,
  NavLink,
  Page,
  PageHeader,
  PageResults,
  PageSearch,
  PageToolbar,
  PageViewToggle,
} from 'portal/components';
import { OrdersProvider } from 'portal/contexts/OrdersContext';

import GridView from './components/GridView';
import ListView from './components/ListView';

const initialFiltersForType = type => {
  switch (type) {
    case 'active':
      return {
        search: '',
        is_active: true,
        page: 1,
        page_size: 20,
        sort_desc: true,
        sort_key: 'inserted_at',
      };
    case 'completed':
      return {
        search: '',
        is_complete: true,
        page: 1,
        page_size: 20,
        sort_desc: true,
        sort_key: 'inserted_at',
      };
  }
};

const fetchData = filters => {
  return api.get('/customer_portal/orders', { params: filters });
};

const Orders = () => {
  const { type } = useParams();
  const [isGridView, setIsGridView] = useState(true);
  const userAnalytics = useUserAnalyticsContext();
  const {
    data,
    loading,
    handleSearch: handleTableSearch,
    pages,
    totalItems,
    filters,
    setFilters,
  } = useTableFilters(null, initialFiltersForType(type), fetchData);

  const { search: searchParam, sort_desc: isSortDesc, sort_key: sortBy } = filters;

  const orders = data ?? [];
  const totalOrders = searchParam ? orders.length : totalItems;

  const logCreateQuote = () => userAnalytics.track('Quote CTA - Orders Page', { valid: true });

  useEffect(() => {
    setFilters(initialFiltersForType(type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const handleSetPage = page => {
    setFilters({ ...filters, page });
  };
  const handleSearch = value => {
    // TODO: useTableFilters expects a synthetic event, but we're controlling <Input />
    handleTableSearch({ target: { name: 'search', value } });
  };

  const handleSort = sortValue => {
    setFilters({
      ...filters,
      sort_key: ORDER_SORT_VALUES[sortValue],
      sort_desc: !filters.sort_desc,
    });
  };

  const handleGridToggle = () => {
    setIsGridView(!isGridView);
    userAnalytics.track(`${isGridView ? 'List' : 'Card'} View - Orders`, { valid: true });
  };

  const renderBody = () => {
    if (loading)
      return (
        <div className="flex justify-center mt-32">
          <ProgressCircle
            percentage={75}
            spinner={true}
            backgroundColor="#111115"
            fadesIntoBackground
          />
        </div>
      );
    else if (isEmpty(orders)) return <EmptyOrdersCard onActionClick={logCreateQuote} />;
    else return <DisplayComponent orders={orders} onSort={handleSort} />;
  };

  const DisplayComponent = isGridView ? GridView : ListView;

  return (
    <OrdersProvider
      currentSearchParam={searchParam}
      currentSortParam={sortBy}
      isSortDesc={isSortDesc}
    >
      <Page>
        <PageHeader
          border={false}
          title="Orders"
          subtitle="View any order and track its progress."
        >
          <div className="pt-4">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <NavLink to={`/orders/active`} exact={true} className="nav-link">
                  Active orders
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/orders/completed'} exact={true} className="nav-link">
                  Completed orders
                </NavLink>
              </li>
            </ul>
          </div>
        </PageHeader>

        <PageToolbar>
          <PageSearch
            defaultValue={filters.search}
            onSearch={handleSearch}
            placeholder="Search by order number, PO, or part name"
            onBlur={e => {
              userAnalytics.trackFieldEvents({
                e,
                logType: 'string',
                trackName: 'Orders - Search',
                customValidation: eVal => Boolean(eVal.trim()),
              });
            }}
          />
          <PageResults value={totalOrders} context="order" />
          <PageViewToggle toggled={isGridView} onToggle={handleGridToggle} />
        </PageToolbar>

        <div className="mt-5">{renderBody()}</div>

        {pages > 1 && !loading && (
          <div className="flex-1 mt-3 flex align-items-end">
            <Pagination page={filters.page} setPage={handleSetPage} totalPages={pages} />
          </div>
        )}
      </Page>
    </OrdersProvider>
  );
};

export default Orders;
