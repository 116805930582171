import { intersection, isEmpty, isString, pick, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, DropdownCard, Icon, classNames } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { checkPassFail, getFailedCheckToDisplay } from 'fr-shared/lib/manufacturability_checks';
import { keyboardDown } from 'fr-shared/utils';

const ManufacturabilityChecks = ({
  manufacturingProcess,
  onToggleMesh,
  revision,
  showHelpMessage = false,
  visibleMeshes,
}) => {
  const { manufacturabilityChecks, passedCheckNames, failedCheckNames, totalChecks } =
    checkPassFail(revision?.manufacturability_checks, manufacturingProcess);
  const failedCheckGroups = pick(manufacturabilityChecks, failedCheckNames);

  const handleToggleMesh = checkName => {
    if (!visibleMeshes) return null;

    const checkGroup = manufacturabilityChecks[checkName];

    // we want to turn on every mesh for each group,
    // but only if they have failed (passed: false)
    const newMeshes = checkGroup.reduce((meshes, check) => {
      const { name, passed } = check;

      if (!passed) {
        meshes[name] = !visibleMeshes[name];
      }

      return meshes;
    }, {});

    onToggleMesh(newMeshes);
  };

  const isVisible = checkGroup => {
    const visibleChecks = Object.keys(pickBy(visibleMeshes, mesh => mesh));
    return !isEmpty(
      intersection(
        visibleChecks,
        checkGroup.map(check => check.name)
      )
    );
  };
  if (!manufacturabilityChecks) return null;

  return (
    <div className="manufacturability-checks">
      {Object.keys(failedCheckGroups).map(checkGroupName => {
        const group = failedCheckGroups[checkGroupName];
        const check = getFailedCheckToDisplay(group);
        const isMeshVisible = isVisible(group);

        return (
          <FailedCheck
            check={check}
            checkGroupName={checkGroupName}
            isSelected={isMeshVisible}
            key={checkGroupName}
            onClick={() => handleToggleMesh(checkGroupName)}
            showViewButton={!!visibleMeshes}
            showHelpMessage={showHelpMessage}
          />
        );
      })}

      {!isEmpty(passedCheckNames) && (
        <DropdownCard
          expanded={false}
          renderHeader={() => (
            <div className="flex align-items-center font-size-md">
              <Icon name="check-circle" className="text-success mr-1" />
              All passed checks
              <div className="text-muted font-size-sm ml-auto">
                {passedCheckNames.length} of {totalChecks}
              </div>
            </div>
          )}
          renderBody={() => (
            <div className="p-2">
              {passedCheckNames.map(name => (
                <div key={name} className="font-size-md pb-1">
                  {name}
                </div>
              ))}
            </div>
          )}
        />
      )}
    </div>
  );
};

ManufacturabilityChecks.propTypes = {
  manufacturingProcess: PropTypes.object,
  onToggleMesh: PropTypes.func,
  revision: PropTypes.object,
  showHelpMessage: PropTypes.bool,
  visibleMeshes: PropTypes.object,
};

const FailedCheck = ({
  check,
  checkGroupName,
  isSelected,
  onClick,
  showViewButton,
  showHelpMessage,
}) => {
  const userAnalytics = useUserAnalyticsContext();
  const handleViewDetails = () =>
    userAnalytics.track('DFM Insights - View Details', { type: checkGroupName || 'N/A' });

  if (!check) return null;

  const isError = check.type === 'error';

  const mainColor = isError ? 'error' : 'warning';

  return (
    <div className="failed-check">
      <div
        className={classNames([
          'header cursor-pointer p-1 rounded font-size-md flex align-items-center mb-1',
          {
            'mesh-visible': isSelected,
            'text-white': isSelected,
            [`bg-${mainColor}`]: isSelected,
          },
        ])}
        onClick={onClick}
        onKeyDown={evt => keyboardDown(evt, 'Enter', onClick)}
        role="button"
        tabIndex={0}
      >
        <span>
          <Icon
            data-testid="check-icon"
            name={isError ? 'times-circle' : 'exclamation-circle'}
            className={classNames(['mr-1', isSelected ? 'text-white' : `text-${mainColor}`])}
          />
        </span>
        <span className="line-height-md">{checkGroupName}</span>
        <div className="ml-auto">
          {!isSelected && showViewButton && (
            <Button outline size="xs" onClick={handleViewDetails}>
              View
            </Button>
          )}
        </div>
      </div>
      {isSelected && (
        <div className="content font-size-md">
          <div className="mb-1 p-2">{isString(check.description) && check.description}</div>
          <div className="suggestion font-weight-bold rounded mb-2 p-2">{check.suggestion}</div>
          {showHelpMessage && (
            <div className="mb-1 p-2">
              <strong>Need some help?</strong>{' '}
              {`Use the notes field at the bottom of this page to ask any questions you have. Once submitted, we'll reach out to you.`}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

FailedCheck.propTypes = {
  check: PropTypes.shape({
    description: PropTypes.string.isRequired,
    suggestion: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  checkGroupName: PropTypes.string,
  isSelected: PropTypes.bool,
  manufacturingProcess: PropTypes.object,
  onClick: PropTypes.func,
  showHelpMessage: PropTypes.bool,
  showViewButton: PropTypes.bool,
};

export default ManufacturabilityChecks;
