import { PORTAL_BULK_UPLOAD } from 'fr-shared/feature_flags';
import { useFeatureFlags } from 'fr-shared/hooks';

const usePortalBulkUploadFeatureFlag = () => {
  const { [PORTAL_BULK_UPLOAD]: isPortalBulkUploadOn } = useFeatureFlags({
    [PORTAL_BULK_UPLOAD]: 'on',
  });

  return isPortalBulkUploadOn;
};

export default usePortalBulkUploadFeatureFlag;
