// keep in sync with lib/fast_radius/types/incoterm.ex

export const INCOTERMS = [
  {
    name: 'Ex Works',
    id: 'exw',
    description:
      'The seller is expected to have the goods ready for collection at the agreed place of delivery \
      (commonly the seller’s factory or warehouse). The buyer is accountable for all subsequent costs and \
      risk, including all export procedures, starting with loading the goods onto a transport vehicle at the seller’s premises.',
  },
  {
    name: 'Carriage Paid To',
    id: 'cpt',
    description:
      'Carriage Paid To (CPT) rules require the seller to clear the goods and arrange carriage (by one or more transport modes) \
      to the named place of destination. The seller does not need to obtain or pay for insurance. Buyer bears risk during main and on carriage',
  },
  {
    name: 'Delivered At Place',
    id: 'dap',
    description:
      'The seller is responsible for delivery of the goods, ready for unloading, at the named place of destination, not offloaded.',
  },
  {
    name: 'Delivered At Place Unloaded - (Seller responsible for unloading)',
    id: 'dpu',
    description:
      'Requires the seller to deliver the goods at the disposal of the buyer after they’ve been unloaded from the arriving means of transport.',
  },
  {
    name: 'Delivered Duty Paid',
    id: 'ddp',
    description:
      'The seller assumes all responsibilities and costs for delivering the goods to the named place of destination. The seller must pay both \
      export and import formalities, fees, duties and taxes, not offloaded.',
  },
  {
    name: 'Free Carrier',
    id: 'fca',
    description:
      'The seller is responsible for export clearance and delivery of goods to the carrier at the named place of delivery. Buyer bears all costs \
      and risk from time goods are handed off to its designated carrier/agent through to destination.',
  },
  {
    name: 'Free on Board (Ocean only)',
    id: 'fob',
    description:
      'The seller clears the goods for export and ensures they are delivered to and loaded onto the vessel for transport at the named port of departure.',
  },
];
