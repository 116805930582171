import {
  AbstractMesh,
  ActionManager,
  ArcRotateCamera,
  Axis,
  Color3,
  DirectionalLight,
  Engine,
  ExecuteCodeAction,
  HemisphericLight,
  Scene,
  StandardMaterial,
  StopAnimationAction,
  Vector3,
  VertexBuffer,
} from 'babylonjs';

export const MAIN_STL_MESH = 'stlmesh';
export const BUILD_PLATE_MESH = 'buildplatemesh';
export const CARBON_BUILD_PLATE_URL =
  'https://fast-radius-part-files.s3-us-gov-west-1.amazonaws.com/carbon_build_tray.obj';

/**
 * Calculates the camera zoom (radius) so the imported model fits in the canvas viewport
 */
export const calculateCamRadiusForModel = (model: AbstractMesh, scene: Scene, engine: Engine) => {
  const camera = scene.activeCamera;
  const radius = model.getBoundingInfo().boundingSphere.radiusWorld;
  const aspectRatio = engine.getAspectRatio(camera);
  let halfMinFov = camera.fov / 2;
  if (aspectRatio < 1) {
    halfMinFov = Math.atan(aspectRatio * Math.tan(camera.fov / 2));
  }
  return Math.abs(radius / Math.sin(halfMinFov));
};

/**
 * Defines the attributes of the material we use on the STL
 */
export const solidMaterial = (scene: Scene) => {
  const mat = new StandardMaterial('solidMaterial', scene);
  mat.diffuseColor = new Color3(0.7, 0.7, 0.7);
  mat.specularColor = new Color3(0.1, 0.1, 0.1);
  return mat;
};

/**
 * Clicking on the STL model will center the camera on that point via the moveTo func
 */
export const setMeshActions = (scene: Scene, mesh: AbstractMesh) => {
  const camera = scene.activeCamera as ArcRotateCamera;
  mesh.actionManager = new ActionManager(scene);
  mesh.actionManager.registerAction(
    new ExecuteCodeAction({ trigger: ActionManager.OnPickTrigger }, () => {
      const pick = scene.pick(scene.pointerX, scene.pointerY);
      if (pick.hit) {
        camera.lockedTarget.moveTo(pick.pickedPoint, 90);
      }
    })
  );
  mesh.actionManager.registerAction(
    new StopAnimationAction({ trigger: ActionManager.OnDoublePickTrigger }, camera.lockedTarget)
  );
};

/**
 * Setup lighting for the camera and scene
 */
export const initLights = (scene: Scene) => {
  const dirLight1 = new DirectionalLight('dirLight1', new Vector3(-0.612, -0.707, 0.354), scene);
  dirLight1.intensity = 2.5 / 4;
  dirLight1.diffuse = new Color3(1.0, 1.0, 1.0);

  const dirLight2 = new DirectionalLight('dirLight2', new Vector3(-0.557, -0.766, 0.321), scene);
  dirLight2.intensity = 1.0 / 4;
  dirLight2.diffuse = new Color3(1.0, 1.0, 1.0);

  const dirLight3 = new DirectionalLight('dirLight3', new Vector3(0.557, 0.766, -0.321), scene);
  dirLight3.intensity = 1.0 / 4;
  dirLight3.diffuse = new Color3(1.0, 1.0, 1.0);

  const hemi = new HemisphericLight('hemiLight', new Vector3(-1, -2, -2), scene);
  hemi.diffuse = new Color3(0.8, 0.8, 0.8);
  hemi.specular = new Color3(0.8, 0.8, 0.8);
  hemi.groundColor = new Color3(0.8, 0.8, 0.8);
  hemi.intensity = 0.25;
};

export const alignBuildPlateAndPart = (buildPlateMesh: AbstractMesh, partMesh: AbstractMesh) => {
  const partBoundingBox = partMesh.getBoundingInfo().boundingBox;
  const partCenter = partBoundingBox.centerWorld;
  const partMinimum = worldMinPoint(partMesh);

  const buildPlateBoundingBox = buildPlateMesh.getBoundingInfo().boundingBox;
  // the amount to move the build plate vertically
  const heightMoveAmount = buildPlateBoundingBox.maximumWorld.y - partMinimum.y;

  // the amounts to move the built plate so that the part STL is centered on the build plate
  const xMoveAmount = buildPlateBoundingBox.centerWorld.x - partCenter.x;
  const zMoveAmount = buildPlateBoundingBox.centerWorld.z - partCenter.z;

  buildPlateMesh.id = BUILD_PLATE_MESH;
  buildPlateMesh.name = 'build-plate-mesh';
  buildPlateMesh.isVisible = false;

  buildPlateMesh.position.x -= xMoveAmount;
  buildPlateMesh.position.y -= heightMoveAmount;
  buildPlateMesh.position.z -= zMoveAmount;
};

/**
 * Rotate part in-place to a given build orientation
 */
export const rotatePartToBuildOrientation = (mesh: AbstractMesh, buildOrientation: Vector3) => {
  let other;

  if (Math.abs(buildOrientation.z) > 0.5) {
    other = Axis.Y;
  } else {
    other = Axis.Z;
  }
  let normal = Vector3.Cross(other, buildOrientation);

  var orientation = Vector3.RotationFromAxis(
    normal,
    Vector3.Cross(buildOrientation, normal),
    buildOrientation
  );

  if (mesh) {
    mesh.rotation = orientation;
  }
};

const worldMinPoint = (mesh: AbstractMesh) => {
  let vertices = mesh.getVerticesData(VertexBuffer.PositionKind);
  let min = new Vector3(1e10, 1e10, 1e10);
  let m = mesh.getWorldMatrix();

  let v = new Vector3();
  for (let i = 0; i < vertices.length / 3; ++i) {
    v.copyFromFloats(vertices[i * 3 + 0], vertices[i * 3 + 1], vertices[i * 3 + 2]);
    Vector3.TransformCoordinatesToRef(v, m, v);
    min.minimizeInPlace(v);
  }

  return min;
};

/**
 * Set camera target and angle
 */
export const resetCamera = (scene: Scene, engine: Engine) => {
  const mesh = scene.getMeshByID(MAIN_STL_MESH);
  const camera = scene.activeCamera as ArcRotateCamera;
  camera.radius = calculateCamRadiusForModel(mesh, scene, engine);
  camera.lockedTarget.moveTo(mesh.getBoundingInfo().boundingBox.centerWorld);
  camera.alpha = (-120.0 * Math.PI) / 180.0; // -120 degrees
  camera.beta = Math.PI / 4; // 45 degrees
};
