import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { IconFont } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { DEAL_STATUSES } from 'fr-shared/lib/deals';
import { MARKETING_URLS } from 'fr-shared/lib/marketing_urls';

import { ContactUs, Link, Money, ShippingPriceLabel } from 'portal/components';

import PortalLineItem from './PortalLineItem';

const ConfirmationSummary = ({ quote, showDisclaimer, showPrices }) => {
  const shippingPrice =
    get(quote, 'shipping_price.amount') || get(quote, 'shipping_charge.amount');
  const subtotalPrice = get(quote, 'sub_total_price.amount');
  const discount = get(quote, 'discount.amount');
  const totalPrice = get(quote, 'total_price.amount');
  const userAnalytics = useUserAnalyticsContext();
  const showAllPrices = quote.status
    ? quote.status !== DEAL_STATUSES.Rejected || quote.status !== DEAL_STATUSES.Canceled
    : showPrices;

  const totalQty = quote.line_items.reduce((preVal, currVal) => {
    return preVal + currVal.quantity;
  }, 0);

  useEffect(() => {
    userAnalytics.track('Checkout - Part LIs in Order', { qty: quote.line_items.length });
    userAnalytics.track('Checkout - Quantity in Order', { qty: totalQty });
    userAnalytics.track('Checkout - Amount of Order', { total: totalPrice });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="flex border-bottom align-items-center py-3">
        <h3 className="mb-0">Items</h3>
      </div>

      {quote.line_items.map((lineItem, lineItemIndex) => (
        <div className="flex flex-column py-3" key={lineItemIndex}>
          <PortalLineItem lineItem={lineItem} showPrices={showAllPrices} />
        </div>
      ))}

      <div className="py-2">
        <div className="mb-3">
          <div className="flex py-2 flex-column">
            <div className="flex mb-2">
              Items subtotal
              <div className="ml-auto">
                <div data-testid="subtotal">
                  <Money amount={subtotalPrice} />
                </div>
              </div>
            </div>
            {shippingPrice && (
              <div className="flex align-items-center mb-2">
                <ShippingPriceLabel />
                <div className="ml-auto">
                  <div data-testid="shipping-price">
                    <Money amount={shippingPrice} />
                  </div>
                </div>
              </div>
            )}
            {+discount > 0 && (
              <div className="flex align-items-center mb-2">
                Discount
                <div className="ml-auto">
                  <div data-testid="shipping-price">
                    <Money
                      amount={discount}
                      className="text-success"
                      isNegative={true}
                      unstyledSymbol={true}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="flex align-items-center">
              <span className="font-size-base">Total</span>
              <div className="ml-auto">
                <div data-testid="order-total" className="font-weight-bold font-size-lg">
                  <Money amount={totalPrice} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showDisclaimer && (
          <div className="font-size-sm py-2">
            <p>
              To expedite delivery, sometimes we send items in{' '}
              <strong>multiple shipments.</strong>
              If you have missing items, they may still be in transit.
            </p>
            <p>
              Prices are subject to change. Lead times represent date of expected shipment and are
              subject to change due to capacity.
            </p>
            <p>
              The Fast Radius Terms and Conditions apply to every quote and contract. Learn more
              at{' '}
              <Link to={{ pathname: MARKETING_URLS.TermsAndConditions }} openNewWindow>
                {MARKETING_URLS.TermsAndConditions}
              </Link>
            </p>
          </div>
        )}
      </div>

      <p className="font-size-md">
        <IconFont name="info-circle" opacity className="text-primary mr-1" />
        If your order summary doesn&apos;t look right, please <ContactUs>contact us</ContactUs>.
      </p>

      <div className="py-3 font-size-sm">
        <p className="mb-3">
          Fast Radius is the leading provider of additive and traditional manufacturing solutions
          - including application discovery, product design and testing, production-grade
          manufacturing, and global fulfillment.
        </p>
        <p>
          <strong>Shipping terms: </strong> Default shipping method is UPS Overnight Air (North
          America) and UPS Worldwide Express (International), and will be invoiced according to
          the rates charged to Fast Radius. Please notify your Account Executive if you desire
          alternative shipping arrangements.
        </p>
      </div>
    </div>
  );
};

ConfirmationSummary.propTypes = {
  quote: PropTypes.object,
  showDisclaimer: PropTypes.bool,
  showPrices: PropTypes.bool,
  showProductionData: PropTypes.bool,
};

export default ConfirmationSummary;
