import React, { SyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, IconClickable, IconFont, classNames } from 'fr-shared/components';
import SupportingDocuments, {
  DocWithStatus,
} from 'fr-shared/components/form/SupportingDocuments';
import { numberInputMinValueFilter } from 'fr-shared/utils/input';

import { Badge, Card, FormFieldBase, Input, Modal, Money, PartImage } from 'portal/components';
import { BulkConfigAutoquotePart } from 'portal/lib/autoquote';
import { InitialPartConfig, PartConfig } from 'portal/pages/evaluate/viewer';
import { PartFormState } from 'portal/pages/part_config/components/PartFormFields';

import styles from './PartConfigurationCard.module.css';

interface Props {
  handleOpenPartViewer: (part: Part) => void;
  handleFieldChanges: (documents: DocWithStatus[]) => void;
  onRemovePart: (part: Part) => void;
  part: Part & { quantity: number; current_docs: DocWithStatus[] };
  action?: any;
  when?: boolean;
  cancelText?: string;
  confirmText?: string;
  onActionHandler?: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
  setQuantity: (quantity: number) => void;
  autoquoteData: BulkConfigAutoquotePart | null;
  manufacturingProcessId: number;
  bulkPartConfig: PartFormState;
  needsSupportingDocs: boolean;
}

export const PartConfigurationCard = ({
  handleOpenPartViewer,
  handleFieldChanges,
  onRemovePart,
  part,
  cancelText = 'Cancel',
  confirmText = 'Remove',
  onActionHandler,
  onCancel,
  onConfirm,
  setQuantity,
  autoquoteData,
  manufacturingProcessId,
  bulkPartConfig,
  needsSupportingDocs,
}: Props) => {
  const history = useHistory<InitialPartConfig>();
  const handleCancel = (toggle: any) => {
    onCancel && onCancel();
    toggle();
  };
  const handleConfirm = (toggle: any) => {
    onConfirm && onConfirm();
    onRemovePart(part);
    toggle();
  };
  const unitPrice = autoquoteData?.unit_price?.amount;
  const totalItemPrice = autoquoteData?.total_price?.amount;

  const handleQuantitiesUpdate = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = numberInputMinValueFilter(+e.currentTarget.valueAsNumber, 1);
    setQuantity(value);
  };
  const dfmFailures = part.current_revision?.failed_manufacturability_check_counts_by_process;
  const failedCheckCounts: DfmCheckFailedCounts = dfmFailures?.find(
    (mp: DfmCheckFailedCounts) => mp.manufacturing_process_id === manufacturingProcessId
  );
  const errorCount = failedCheckCounts?.failed_error_checks_count;
  const warningCount = failedCheckCounts?.failed_warning_checks_count;
  const hasFailure = errorCount > 0 || warningCount > 0;
  const textColor = errorCount ? 'text-error-300' : 'text-warning-300';

  const viewDfmDetails = (params: PartConfig) => {
    let basePath = `/studio/evaluate/${part.id}/dfm`;
    history.push(basePath, params);
  };

  const dfmParams = {
    manufacturing_process_id: bulkPartConfig?.manufacturing_process_id,
    material_id: bulkPartConfig?.material_id,
    color_id: bulkPartConfig?.color_id,
    layer_thickness: bulkPartConfig?.layer_thickness_id,
    from_edit_parts: history.location?.pathname === '/edit-parts',
  };

  return (
    <>
      {part && (
        <Card className="w-full rounded-[8px] mb-0 mt-1 mx-0 p-2 pt-1">
          <div className="flex flex-row justify-between">
            <div className="flex items-center flex-row">
              <div className="flex mr-2">
                <PartImage
                  backdrop={false}
                  className="cursor-pointer"
                  onClick={() => handleOpenPartViewer(part)}
                  size="sm"
                  src={part?.current_revision?.screenshot}
                />
              </div>
              <div>
                <div className="text-sm text-coolGray-100 truncate">{part.name}</div>
                {failedCheckCounts && (
                  <div className="flex items-center leading-none h-2 mt-1 text-xs">
                    {errorCount > 0 && errorCount && (
                      <Badge
                        className="mr-[4px] w-[16px] h-[16px] text-[10px]"
                        pill
                        outline
                        color="error"
                      >
                        {errorCount}
                      </Badge>
                    )}
                    {warningCount > 0 && warningCount && (
                      <Badge
                        className="mr-[4px] w-[16px] h-[16px] text-[10px]"
                        pill
                        outline
                        color="warning"
                      >
                        {warningCount}
                      </Badge>
                    )}
                    {hasFailure ? (
                      <Button
                        className="p-0 m-0"
                        color="none"
                        onClick={() => viewDfmDetails(dfmParams)}
                      >
                        <span
                          className={classNames([textColor, 'ml-[4px] text-[12px] underline'])}
                        >
                          See issues
                        </span>
                      </Button>
                    ) : (
                      <Button
                        className="p-0 m-0"
                        color="none"
                        onClick={() => viewDfmDetails(dfmParams)}
                      >
                        <IconFont className="text-success-300 text-sm mr-1" name="check" />
                        <span className="text-success-300 text-[12px] mt-0 underline">
                          DFM checks passed
                        </span>
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row">
              <div className="min-w-[40px] m-0 mr-4">
                <FormFieldBase name="quantity" isControl={true}>
                  <Input
                    className="mb-0 w-[40px]"
                    inputClassname="pb-0"
                    name="quantity"
                    value={part.quantity}
                    type="number"
                    size="md"
                    onChange={handleQuantitiesUpdate}
                    onBlur={(e: SyntheticEvent<HTMLInputElement>) => {
                      if (isNaN(e.currentTarget.valueAsNumber)) setQuantity(1);
                    }}
                    min={1}
                  />
                </FormFieldBase>
              </div>
              <div className="min-w-[100px] flex items-center">
                <Money amount={unitPrice} className="text-coolGray-100" />
              </div>
              <div className="min-w-[100px] flex items-center">
                <Money amount={totalItemPrice} className="text-coolGray-100" />
              </div>
              <div className="flex items-center leading-none">
                <div className="flex items-center h-[32px] border-solid border-0 border-l border-coolGray-600">
                  <IconClickable
                    iconClassname="text-2xl text-coolGray-300 ml-[18px]"
                    iconName="3d-box"
                    onClick={() => handleOpenPartViewer(part)}
                  />
                  {onRemovePart && (
                    <Modal
                      className={styles.Prompt}
                      dataTestId="prompt-modal"
                      action={
                        <IconFont
                          className="text-2xl text-coolGray-300 ml-[23px] cursor-pointer"
                          name="trash"
                        />
                      }
                      onActionHandler={onActionHandler}
                    >
                      {({ toggle }: { toggle: any }) => (
                        <>
                          <Modal.Body className="pt-16">
                            <div className={styles.Title}>
                              <h5 className="mb-2">Remove part from list?</h5>
                            </div>
                            <div className={styles.Message}>
                              <div className="font-size-sm text-gray mt-auto mb-auto ml-2">
                                <IconFont
                                  className="font-size-xl text-error-300 mr-[18px]"
                                  name="error-triangle"
                                />
                                Are you sure you want to remove &apos;
                                <span className="text-error-300">{part.name}</span>&apos;?
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <div className="w-[436px] flex gap-3">
                              <Button
                                className="w-full"
                                outline
                                onClick={() => handleCancel(toggle)}
                              >
                                {cancelText}
                              </Button>
                              <Button
                                className="w-full"
                                outline
                                color="danger"
                                onClick={() => handleConfirm(toggle)}
                              >
                                {confirmText}
                              </Button>
                            </div>
                          </Modal.Footer>
                        </>
                      )}
                    </Modal>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="my-2 border-0 border-t border-solid border-coolGray-600" />
            <SupportingDocuments
              needsSupportingDocs={needsSupportingDocs && part.current_docs?.length < 1}
              readonly={false}
              documents={part.documents}
              bulkEdit
              baseDocumentURI={`/customer_portal/parts/${part.id}/documents`}
              onChange={handleFieldChanges}
            />
          </div>
        </Card>
      )}
    </>
  );
};
