import PropTypes from 'prop-types';
import React from 'react';

import { CardWrapper, OrderCard } from 'portal/components';

const OrderGrid = ({ orders, dataTestId }) => {
  return (
    <CardWrapper>
      {orders.map(order => (
        <OrderCard key={order.id} order={order} dataTestId={dataTestId} />
      ))}
    </CardWrapper>
  );
};

OrderGrid.propTypes = {
  orders: PropTypes.array,
  dataTestId: PropTypes.string,
};

export default OrderGrid;
