import PropTypes from 'prop-types';
import React from 'react';

import { IconFont } from 'fr-shared/components';

import { classNames } from 'portal/components';

import styles from './Input.module.css';

export const Input = ({
  icon = undefined,
  size,
  className = '',
  inputClassname = '',
  ...props
}) => {
  // TODO: this should use FormFieldBase
  return (
    <div
      className={classNames([
        'form-group',
        'is-floating',
        styles.FormGroup,
        size && styles[size],
        icon && styles.IconWrapper,
        className,
      ])}
    >
      {icon && <IconFont className={styles.Icon} name={icon} />}
      <input className={classNames(['form-control', styles.Input, inputClassname])} {...props} />
    </div>
  );
};

Input.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  inputClassname: PropTypes.string,
  className: PropTypes.string,
};

export default Input;
