import React from 'react';

import { Loading, Money } from 'portal/components';
import { AutoquoteResponseState, isAutoquote, isAutoquoteLoading } from 'portal/lib/autoquote';

interface PartFormTotals {
  autoquoteResponse: AutoquoteResponseState;
}

const PartFormTotals = ({ autoquoteResponse }: PartFormTotals) => {
  return (
    <div className="flex flex-column w-100">
      <div className="flex flex-row justify-content-between mb-1">
        <div className="text-muted mb-0 font-size-md">Unit price</div>
        {isAutoquoteLoading(autoquoteResponse) ? (
          <Loading />
        ) : (
          <div className="text-white font-size-md">
            <Money
              amount={
                isAutoquote(autoquoteResponse) ? autoquoteResponse.unit_price?.amount : null
              }
            />
          </div>
        )}
      </div>
      <div className="flex flex-row justify-content-between text-right mb-3">
        <div className="text-white mb-0 font-size-base text-light">Item total</div>
        {isAutoquoteLoading(autoquoteResponse) ? (
          <Loading />
        ) : (
          <div
            className="font-size-base text-white font-weight-bold"
            data-testid="part-config-item-total"
          >
            <Money
              amount={
                isAutoquote(autoquoteResponse) ? autoquoteResponse.total_price?.amount : null
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PartFormTotals;
