import { isEmpty } from 'lodash';
import React from 'react';

import { IconFont } from 'fr-shared/components';
import { CHECKABLE_MFG_PROCESSES } from 'fr-shared/lib/manufacturability_checks';
import { AUTOCOSTABLE_MFG_PROCESSES } from 'fr-shared/lib/manufacturing_process';

import { Badge, Button } from 'portal/components';
import {
  filterChecksByProcess,
  filterFailedErrorChecks,
  filterFailedWarningChecks,
  filterPassedChecks,
  prioritizeChecksBySeverity,
} from 'portal/lib/manufacturability_check';

import AlertBox from './AlertBox';
import DfmCheckSection from './DfmCheckSection';

interface DfmTab {
  dfmChecks: ManufacturabilityCheck[];
  hasMaterial: boolean;
  isSubmitting?: boolean;
  onCompare?: () => void;
  onRequestChecks?: () => void;
  onSelectDfmCheck: (dfmCheck: ManufacturabilityCheck) => void;
  selectedDfmCheck: ManufacturabilityCheck;
  selectedManufacturingProcess: ManufacturingProcess;
  isBulkEditPart: boolean;
}

const DfmTab = ({
  dfmChecks,
  hasMaterial,
  isSubmitting,
  onCompare,
  onRequestChecks,
  onSelectDfmCheck,
  selectedDfmCheck,
  selectedManufacturingProcess,
  isBulkEditPart,
}: DfmTab) => {
  const checksForProcess = filterChecksByProcess(dfmChecks, selectedManufacturingProcess?.id);
  const prioritizedChecks = prioritizeChecksBySeverity(checksForProcess);
  const failedErrorChecks = filterFailedErrorChecks(prioritizedChecks);
  const failedWarningChecks = filterFailedWarningChecks(prioritizedChecks);
  const passedChecks = filterPassedChecks(prioritizedChecks);

  const dfmCheckSections = [
    {
      name: 'Critical',
      description:
        "We've found some critical manufacturability issues. View these in more detail to see our suggested fixes. Or contact us for help.",
      color: 'error',
      checks: failedErrorChecks,
    },
    {
      name: 'Non-critical',
      description:
        "We've found non-critical issues you need to be aware of. These could cause problems for your part. View in more detail to see our suggested fixes.",
      color: 'warning',
      checks: failedWarningChecks,
    },
    {
      name: 'Passed',
      color: 'success',
      checks: passedChecks,
    },
  ];

  // If we haven't selected a manufacturing process and don't have DFM checks,
  // show this component to tell the user
  if (
    !selectedManufacturingProcess?.id ||
    (!hasMaterial &&
      !isEmpty(selectedManufacturingProcess?.materials) &&
      AUTOCOSTABLE_MFG_PROCESSES.includes(selectedManufacturingProcess?.name))
  ) {
    return (
      <AlertBox title="Automated checks" className="mt-2">
        <p className="text-muted mt-2">
          We can run Design for Manufacturability (DFM) checks automatically for any of these
          manufacturing processes:
        </p>
        <p className="text-muted my-2">
          &mdash; Digital Light Synthesis
          <br />
          &mdash; Multijet Fusion
          <br />
          &mdash; Fused Deposition Modeling
          <br />
          &mdash; Stereolithography
          <br />
          &mdash; CNC Machining
          <br />
          &mdash; Injection Molding
          <br />
        </p>
        <p className="mt-2 text-muted">
          Choose a <span className="text-white">manufacturing process</span> and a{' '}
          <span className="text-white">material</span> to get started.
        </p>
      </AlertBox>
    );
  }

  if (prioritizedChecks.length === 0) {
    return (
      <AlertBox title="Automated checks" className="mt-2">
        {CHECKABLE_MFG_PROCESSES.includes(selectedManufacturingProcess?.name) ? (
          <p className="text-muted mt-2">
            Unfortunately, we were unable to generate Automated Checks for this specific file.
          </p>
        ) : (
          <p className="text-muted mt-2">
            Automated checks aren&apos;t available for {selectedManufacturingProcess.name}, yet.
          </p>
        )}
        <p className="text-muted mt-2">
          But if you send us a request, we can run the same checks manually.
        </p>
        <Button loading={isSubmitting} block outline onClick={onRequestChecks}>
          Request manual checks
        </Button>
      </AlertBox>
    );
  }

  return (
    <div className="mt-3">
      <h5 className="flex align-items-center mb-3">
        Automated checks
        <div className="ml-auto">
          <Badge pill outline color="error">
            {failedErrorChecks.length}
          </Badge>
          <Badge pill outline className="ml-1" color="warning">
            {failedWarningChecks.length}
          </Badge>
          <Badge pill outline className="ml-1" color="success">
            {passedChecks.length}
          </Badge>
        </div>
      </h5>

      {dfmCheckSections.map(section => (
        <DfmCheckSection
          color={section.color}
          description={section.description}
          disableEventsOnSelected={true}
          dfmChecks={section.checks}
          key={section.name}
          name={section.name}
          onSelectDfmCheck={onSelectDfmCheck}
          selectedDfmCheck={selectedDfmCheck}
        />
      ))}

      {isBulkEditPart ? (
        <div className="flex justify-end my-3">
          <Button
            outline
            block
            onClick={() => onCompare()}
            className="w-[157px] h-[32px] content-center p-0"
          >
            <IconFont name="comparison" className="mr-0.5 text-sm" />
            <span className="text-xs">Compare results</span>
          </Button>
        </div>
      ) : (
        <div className="mt-4">
          <Button outline block onClick={() => onCompare()} className="font-size-lg">
            <IconFont name="comparison" className="mr-1" />
            Compare results
          </Button>
        </div>
      )}
    </div>
  );
};

export default DfmTab;
