import { get } from 'lodash';
import React from 'react';

import { classNames } from 'fr-shared/components';

import DfmCheck from './DfmCheck';
import styles from './DfmCheck.module.css';

interface DfmNonCriticalChecksProps {
  disableEventsOnSelected: boolean;
  groupedNonCriticalDfmChecks: GroupedNonCriticalManufacturabilityChecks;
  onSelectDfmCheck: (dfmCheck: ManufacturabilityCheck) => void;
  open: boolean;
  selectedDfmCheckId: number;
}

export const DfmNonCriticalChecks = ({
  disableEventsOnSelected,
  groupedNonCriticalDfmChecks,
  onSelectDfmCheck,
  open,
  selectedDfmCheckId,
}: DfmNonCriticalChecksProps) => {
  const costChecks = get(groupedNonCriticalDfmChecks, 'cost');
  const designChecks = get(groupedNonCriticalDfmChecks, 'design');

  return (
    <>
      {costChecks.length > 0 && (
        <div
          className={classNames([
            open ? styles.enterAnimate : styles.exitAnimate,
            'text-xs pb-1',
          ])}
        >
          Cost reduction
        </div>
      )}
      {costChecks.map((dfmCheck: ManufacturabilityCheck) => (
        <DfmCheck
          key={dfmCheck.id}
          dfmCheck={dfmCheck}
          disableEventsWhenSelected={disableEventsOnSelected}
          onSelectDfmCheck={() => onSelectDfmCheck(dfmCheck)}
          open={open}
          selected={selectedDfmCheckId === dfmCheck.id}
        />
      ))}
      {designChecks.length > 0 && (
        <div
          className={classNames([
            open ? styles.enterAnimate : styles.exitAnimate,
            'text-xs pb-1',
          ])}
        >
          Manufacturability
        </div>
      )}
      {designChecks.map((dfmCheck: ManufacturabilityCheck) => (
        <DfmCheck
          key={dfmCheck.id}
          dfmCheck={dfmCheck}
          disableEventsWhenSelected={disableEventsOnSelected}
          onSelectDfmCheck={() => onSelectDfmCheck(dfmCheck)}
          open={open}
          selected={selectedDfmCheckId === dfmCheck.id}
        />
      ))}
    </>
  );
};

export default DfmNonCriticalChecks;
