import { connect } from 'formik';
import { flatMap, get, isEmpty, mapValues, set } from 'lodash';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  Button,
  Card,
  DropdownCard,
  FormFieldBase,
  Icon,
  LineItemProcess,
  Link,
  ManufacturabilityChecks,
  Modal,
  Viewer,
  classNames,
} from 'fr-shared/components';
import { COSTING_REQUEST_LI_STATES } from 'fr-shared/lib/costing_requests';
import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';

import CostingDocuments from './CostingDocuments';

const CostingPartViewer = ({ isOpen, toggle, fieldPrefix, formik }) => {
  const lineItem = get(formik.values, fieldPrefix);
  const currentRevision = get(lineItem, 'part.current_revision');
  const process = get(formik.values, `${fieldPrefix}.build_package.processes.0`);

  const [initialLineItem, setInitialLineItem] = useState(lineItem);
  const [noteValue, setNoteValue] = useState('');
  const [visibleMeshes, setVisibleMeshes] = useState({});

  const docs = lineItem.part?.documents.filter(doc => !doc.deleted_at);
  const units = lineItem.part?.units;
  const quantities = flatMap(lineItem.supplier_costs ?? [], ({ part_quantities: pq = [] }) =>
    pq.map(({ quantity }) => quantity)
  );

  /**
   * We want to reset the initial line item every time the modal opens or closes.
   * This was way we can have a snapshot of the line item as it is when the user
   * starts going through the modal flow
   */
  useEffect(() => {
    setInitialLineItem(lineItem);
    setNoteValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, fieldPrefix]);

  const handleCancel = () => {
    formik.setFieldValue(fieldPrefix, initialLineItem);
    toggle();
  };

  const handleRejectPart = () => {
    const newLineItem = {
      ...lineItem,
      rejected_at: moment(),
      state: COSTING_REQUEST_LI_STATES.Rejected,
      rejected_reason: noteValue,
      openRejectionModal: true,
    };
    formik.setFieldValue(fieldPrefix, newLineItem);
    toggle();
  };

  const handleApplyNotes = () => {
    const newLineItem = { ...lineItem };
    set(newLineItem, 'supplier_costs.0.notes', noteValue);
    formik.setFieldValue(fieldPrefix, newLineItem);
    toggle();
  };

  const handleResetCamera = () => {
    setVisibleMeshes(mapValues(visibleMeshes, () => false));
  };

  const handleToggleMesh = meshesToBeShown => {
    // we want to turn off all visible meshes before applying new ones
    const disabledMeshes = mapValues(visibleMeshes, () => false);
    setVisibleMeshes({ ...disabledMeshes, ...meshesToBeShown });
  };

  return (
    <Modal fullscreen={true} isOpen={isOpen} toggle={toggle}>
      <div className="modal-header mb-3">
        <div className="container flex">
          <div>
            <h3>{lineItem.description}</h3>
            STL Viewer
          </div>
          <div className="flex ml-auto">
            <div className="flex align-items-center">
              <Button onClick={handleCancel} color="secondary" className="mr-2">
                Back
              </Button>
              <Button onClick={handleRejectPart} color="danger" outline={true} className="mr-2">
                Reject Part
              </Button>
              <Button onClick={handleApplyNotes} color="success">
                Apply Notes
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-8">
            <Viewer
              key={currentRevision.id}
              onResetCamera={handleResetCamera}
              revision={currentRevision}
              showBuildPlateToggle={
                process?.manufacturing_process?.name === MANUFACTURING_PROCESSES.DLS
              }
              visibleMeshes={visibleMeshes}
              partUnits={units}
              manufacturingProcess={process.manufacturing_process}
              theme="admin"
            />
          </div>
          <div className="col-4">
            <ViewerCard iconName="clipboard" headerText="Build Pack Details" className="mb-2">
              {process.manufacturing_process && (
                <div className="form-group mb-1">
                  <span>Manufacturing Details: </span>{' '}
                  <strong>
                    <LineItemProcess data={process} separator=", " />
                  </strong>
                </div>
              )}
              {process.finish && (
                <div className="form-group mb-1">
                  <span>Finish: </span> <strong>{process.finish.name}</strong>
                </div>
              )}
              {process.finish_note && (
                <div className="form-group mb-1">
                  <span>Finish Note: </span> <strong>{process.finish_note}</strong>
                </div>
              )}
              {currentRevision.max_x_length && (
                <div className="form-group mb-1">
                  <span>Dimensions: </span>{' '}
                  <strong>
                    {currentRevision.max_x_length} x {currentRevision.max_y_length} x{' '}
                    {currentRevision.max_z_length} {units}
                  </strong>
                </div>
              )}
              {currentRevision.volume && (
                <div className="form-group mb-1">
                  <span>Part Volume: </span>{' '}
                  <strong>
                    {currentRevision.volume} {units}³
                  </strong>
                </div>
              )}
              {currentRevision.surface_area && (
                <div className="form-group mb-1">
                  <span>Surface Area: </span>{' '}
                  <strong>
                    {currentRevision.surface_area} {units}²
                  </strong>
                </div>
              )}
              {lineItem.notes && (
                <div className="form-group mb-1">
                  <span>Notes: </span> <strong>{lineItem.notes}</strong>
                </div>
              )}
              {!isEmpty(quantities) && (
                <div className="form-group mb-1">
                  <span>Quantities: </span> <strong>{quantities.join(', ')}</strong>
                </div>
              )}
              {!isEmpty(docs) && (
                <DropdownCard
                  expanded={true}
                  renderHeader={() => (
                    <div className="flex flex-row">
                      <div className="flex-1 font-size-sm text-muted">Supporting Documents</div>
                      <div className="font-size-sm text-muted">{docs.length}</div>
                    </div>
                  )}
                  renderBody={() => {
                    return docs.map((doc, docIndex) => (
                      <div
                        className="flex flex-row align-items-center py-1"
                        key={`modal-doc${docIndex}`}
                      >
                        {doc.url ? (
                          <Link
                            className="overflow-auto"
                            data-testid="doc-link"
                            color="link"
                            onClick={() => window.open(doc.url)}
                          >
                            <Icon
                              data-testid="download-doc"
                              right={true}
                              name="external-link-square-alt"
                            />
                            {doc.file_name}
                          </Link>
                        ) : (
                          <div>{doc.file_name}</div>
                        )}
                      </div>
                    ));
                  }}
                />
              )}
            </ViewerCard>
            {currentRevision?.manufacturability_checks && (
              <ViewerCard iconName="comment" headerText="Automated Checks" className="mb-2">
                <ManufacturabilityChecks
                  manufacturingProcess={process.manufacturing_process}
                  revision={currentRevision}
                  onToggleMesh={handleToggleMesh}
                  visibleMeshes={visibleMeshes}
                />
              </ViewerCard>
            )}
            <ViewerCard iconName="comment" headerText="Costing Feedback" className="mb-2">
              <FormFieldBase label="Costing Feedback" isStacked>
                <textarea
                  className="form-control"
                  onChange={e => setNoteValue(e.target.value)}
                  value={noteValue}
                />
              </FormFieldBase>
              {/* Because costing documents are associated with supplier costs, but this UI makes it appear
               * as if they are associated with line items, we attach these documents to the first supplier cost.
               *
               * As well, bc this view is only show for internal mfg process line items, we can assume that there
               * will only be on supplier cost
               */}
              {!isEmpty(lineItem.supplier_costs) && (
                <CostingDocuments
                  isStacked={true}
                  withLabel={true}
                  fieldPrefix={`${fieldPrefix}.supplier_costs.0`}
                />
              )}
            </ViewerCard>
          </div>
        </div>
      </div>
    </Modal>
  );
};

CostingPartViewer.propTypes = {
  formik: PropTypes.object,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  fieldPrefix: PropTypes.string,
};

export const ViewerCard = ({ className, headerText, iconName, children }) => {
  return (
    <Card border={true} className={classNames([className, 'mb-3'])}>
      <Card.Header className="p-2">
        <Icon type="far" name={iconName} right={true} />
        {headerText}
      </Card.Header>
      <Card.Body className="p-2">{children}</Card.Body>
    </Card>
  );
};

ViewerCard.propTypes = {
  className: PropTypes.string,
  headerText: PropTypes.string,
  iconName: PropTypes.string,
  children: PropTypes.node,
};

export default connect(CostingPartViewer);
