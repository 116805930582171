import { Form, Formik, FormikHelpers, FormikValues, useFormikContext } from 'formik';
import React, { useContext } from 'react';

import { AlertContext, UserContext } from 'fr-shared/context';
import {
  AddressFormikValues,
  AddressType,
  AddressTypeEnum,
  CP_SHIPPING_COUNTRIES,
  LABELS_BY_COUNTRY,
  createOrUpdateAddress,
} from 'fr-shared/lib/address';
import { COUNTRIES } from 'fr-shared/lib/countries';

import {
  Button,
  FormCountries,
  FormField,
  FormPhoneNumber,
  FormStates,
  Modal,
} from 'portal/components';

const modalByType = (
  type: AddressType
): ((props: InnerAddressModalProps) => JSX.Element) | null => {
  switch (type) {
    case AddressTypeEnum.Billing:
      return BillingAddressModal;
    case AddressTypeEnum.Shipping:
      return ShippingAddressModal;
    default:
      return null;
  }
};

interface AddressModalProps {
  initialValues?: FormikValues;
  onSave: () => void;
  type: AddressType;
}

const AddressModal = ({
  initialValues,
  isEdit,
  onSave,
  title,
  toggle,
  type,
}: AddressModalProps & InnerAddressModalProps) => {
  const { user } = useContext(UserContext);
  const { setAlert } = useContext(AlertContext);

  const onSubmit = (
    values: AddressFormikValues,
    { setErrors }: FormikHelpers<AddressFormikValues>
  ) => {
    createOrUpdateAddress(values, isEdit, user.id, type)
      .then(() => {
        setAlert({
          message: `Successfully ${isEdit ? 'updated' : 'added'} address`,
          color: 'success',
        });
        onSave();
        toggle();
      })
      .catch(err => {
        if (err.response?.data) {
          const errors = err.response.data.errors;
          setErrors({ ...errors });
        }
      });
  };

  const ModalComponent = modalByType(type);

  return (
    ModalComponent && (
      <Formik
        initialValues={initialValues ? initialValues : { country: COUNTRIES.UNITED_STATES }}
        onSubmit={onSubmit}
      >
        <ModalComponent toggle={toggle} title={title} isEdit={isEdit} />
      </Formik>
    )
  );
};

interface InnerAddressModalProps {
  toggle: () => void;
  title: string;
  isEdit: boolean;
}

const BillingAddressModal = ({ toggle, title, isEdit }: InnerAddressModalProps) => {
  const formikValues: { country?: string } = useFormikContext().values;
  const labelsByCountry = LABELS_BY_COUNTRY[formikValues.country];
  return (
    <>
      <Modal.Header title={title} showCloseButton={false} />
      <Form noValidate>
        <div className="modal-body">
          <FormField label="Full Name" name="name_line_1" required={true} floatingLabel={true} />
          <FormField
            label="Address line 1"
            name="street_line_1"
            required={true}
            floatingLabel={true}
          />
          <FormField label="Address line 2" name="street_line_2" floatingLabel={true} />
          <div className="row">
            <div className="col">
              <FormCountries
                label="Country"
                name="country"
                readonly={false}
                required={true}
                placeholder="Country"
                floatingLabel={true}
              />
            </div>
            <div className="col-md">
              <FormStates
                country={formikValues.country}
                required={true}
                label={labelsByCountry ? labelsByCountry.state : 'State'}
                name="state"
                placeholder={labelsByCountry ? labelsByCountry.state : 'State'}
                floatingLabel={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FormField label="City" name="city" required={true} floatingLabel={true} />
            </div>
            <div className="col-md">
              <FormField
                label={labelsByCountry ? labelsByCountry.zip : 'ZIP Code'}
                name="postal_code"
                required={true}
                floatingLabel={true}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer flex justify-end">
          <Button type="button" outline onClick={toggle}>
            Cancel
          </Button>
          <Button type="submit">{isEdit ? 'Edit ' : 'Add '} this address</Button>
        </div>
      </Form>
    </>
  );
};

const ShippingAddressModal = ({ isEdit, title, toggle }: InnerAddressModalProps) => {
  const formikValues: { country?: string } = useFormikContext().values;
  const labelsByCountry = LABELS_BY_COUNTRY[formikValues.country];
  return (
    <>
      <Modal.Header title={title} showCloseButton={false} />
      <Form noValidate>
        <div className="modal-body">
          <FormField label="Full Name" name="name_line_1" required={true} floatingLabel={true} />
          <FormField label="Company" name="name_line_2" floatingLabel={true} />
          <FormField
            label="Address line 1"
            name="street_line_1"
            required={true}
            floatingLabel={true}
          />
          <FormField label="Address line 2" name="street_line_2" floatingLabel={true} />
          <div className="row">
            <div className="col">
              <FormCountries
                options={CP_SHIPPING_COUNTRIES}
                label="Country"
                name="country"
                placeholder="Country"
                readonly={false}
                required={true}
                floatingLabel={true}
              />
            </div>
            <div className="col-md">
              <FormStates
                country={formikValues.country}
                label={labelsByCountry ? labelsByCountry.state : 'State'}
                name="state"
                placeholder={labelsByCountry ? labelsByCountry.state : 'State'}
                required={true}
                floatingLabel={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FormField label="City" name="city" required={true} floatingLabel={true} />
            </div>
            <div className="col-md">
              <FormField
                label={labelsByCountry ? labelsByCountry.zip : 'ZIP Code'}
                name="postal_code"
                required={true}
                floatingLabel={true}
              />
            </div>
          </div>
          <FormPhoneNumber
            defaultCountry={formikValues.country}
            label="Phone number"
            name="phone_number"
            floatingLabel={true}
          />
        </div>
        <div className="modal-footer flex justify-end">
          <Button type="button" outline onClick={toggle}>
            Cancel
          </Button>
          <Button type="submit">{isEdit ? 'Edit ' : 'Add '} this address</Button>
        </div>
      </Form>
    </>
  );
};

export default AddressModal;
