import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { classNames } from 'fr-shared/components';

import DropdownContext from './DropdownContext';

const DropdownItem = props => {
  const dropdownContext = React.useContext(DropdownContext);
  const tabIndex = props.disabled || props.header || props.divider ? '-1' : '0';
  const role = tabIndex > -1 ? 'menuitem' : undefined;
  let { className, divider, tag: Tag, header, active, ...tagProps } = omit(props, ['toggle']);

  const classes = classNames([
    className,
    {
      disabled: tagProps.disabled,
      'dropdown-item': !divider && !header,
      active: active,
      'dropdown-header': header,
      'dropdown-divider': divider,
    },
  ]);

  if (Tag === 'button') {
    if (header) {
      Tag = 'h6';
    } else if (divider) {
      Tag = 'div';
    } else if (tagProps.href) {
      Tag = 'a';
    }
  }

  return (
    <Tag
      type={Tag === 'button' && (tagProps.onClick || props.toggle) ? 'button' : undefined}
      {...tagProps}
      tabIndex={tabIndex}
      role={role}
      className={classes}
      onClick={e => {
        if (props.disabled || props.header || props.divider) {
          e.preventDefault();
          return;
        }

        if (props.onClick) {
          props.onClick(e);
        }

        if (props.toggle) {
          dropdownContext.toggle(e);
        }
      }}
    />
  );
};

DropdownItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  divider: PropTypes.bool,
  tag: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
        PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
      ])
    ),
  ]),
  header: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  toggle: PropTypes.bool,
};

DropdownItem.defaultProps = {
  tag: 'button',
  toggle: true,
};

export default DropdownItem;
