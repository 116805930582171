import React from 'react';

import { FormSelect } from 'fr-shared/components';
import { HP_PRINT_MODES } from 'fr-shared/lib/work_orders';

const HPPrintModeFormField = props => {
  return (
    <FormSelect
      name="hp_print_mode"
      label="Print Mode"
      optionList={HP_PRINT_MODES}
      nameAccessor={option => option}
      valueAccessor={option => option}
      {...props}
    />
  );
};

export default HPPrintModeFormField;
