import React from 'react';

import { IconFont } from 'fr-shared/components';

interface PoDocumentsListProps {
  documents: FRDocument[];
  handleDeleteDocument: (index: number) => void;
}

function PoDocumentsList({ documents, handleDeleteDocument }: PoDocumentsListProps) {
  return (
    <>
      {documents.length > 0 && (
        <ul className="list-none m-0 p-0">
          {documents.map((document, index) => (
            <li
              key={`document-${index}`}
              className="mb-1 flex w-full p-1 bg-coolGray-700 rounded-sm"
            >
              <div className="flex flex-row w-full items-center">
                <IconFont className="text-xl text-coolGray-100 flex" name="document" />

                <div className="ml-1 text-xs text-coolGray-100 p-0">{document.file_name}</div>
              </div>
              <div>
                <button
                  onClick={() => handleDeleteDocument(index)}
                  className="bg-transparent border-none p-0 text-white text-xl mr-1"
                >
                  <IconFont name="close-circle" type="fal" />
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

export default PoDocumentsList;
