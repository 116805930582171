import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { createContext, useEffect } from 'react';

import { tabStateForLineItems } from 'fr-shared/lib/costing_requests';

import { transformCostingRequestForForm } from '../../utils/transforms';
import useLineItemFilters from './useLineItemFilters';

const CostingFormContext = createContext({ filterValues: {} });

const CostingFormProvider = ({ initialValues, children }) => {
  const formik = useFormikContext();
  const {
    setLineItemFilters,
    lineItemFilters,
    lineItems,
    lineItemSortOption,
    setLineItemSortOption,
  } = useLineItemFilters();

  const setLineItemFilter = (filterName, value) => {
    setLineItemFilters({ ...lineItemFilters, [filterName]: value });
  };

  /**
   * Select correct tab on load based on the state of the costing request
   * @param {object} initialValues
   */
  useEffect(() => {
    if (initialValues?.state && lineItemFilters.state !== initialValues.state) {
      setLineItemFilters({ ...lineItemFilters, state: initialValues.state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  /**
   * Watch the costing request for changes, and update formik
   * This is needed because enableReinitialize has to be disabled on this form
   * @param {object} initialValues - the costing request to watch for changes
   */
  useEffect(() => {
    formik.setValues(transformCostingRequestForForm(initialValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  /**
   * Automatically update tab state when the current tab is empty
   * @param {array} lineItems - the list of all of the selected line items
   */
  useEffect(() => {
    const newState = tabStateForLineItems(formik.values.line_items);
    if (isEmpty(lineItems) && newState !== lineItemFilters.state) {
      setLineItemFilter('state', newState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItems]);

  /**
   *
   * @param {*} lineItemAccessor the path to the line item in formik
   * @param {*} errorMessage a string representing the action a user is trying to take, eg "submit"
   * @param {*} callback function to be called if the user is not in the middle of other actions\
   * When a line item is In Progress, users can edit part and quantity information.
   * If they try to take another action on the line item, like reverting to requested, submitting, duplicating, etc,
   * We show an error message that alerts them they need to finish their current actions first.
   * This function sets up formik state in order to show the right error messages.
   */
  const validateAction = (lineItemAccessor, errorMessage, callback) => {
    const isEditingQuantities = get(
      formik.values,
      `${lineItemAccessor}.is_editing_quantities`,
      false
    );
    const isEditingPartInfo = get(
      formik.values,
      `${lineItemAccessor}.is_editing_part_information`,
      false
    );

    if (isEditingQuantities || isEditingPartInfo) {
      formik.setFieldValue(`${lineItemAccessor}.action_error_message`, errorMessage);
    } else {
      callback();
    }
  };

  return (
    <CostingFormContext.Provider
      value={{
        setLineItemFilter,
        lineItemFilters,
        lineItems,
        lineItemSortOption,
        setLineItemSortOption,
        validateAction,
      }}
    >
      {children}
    </CostingFormContext.Provider>
  );
};

CostingFormProvider.propTypes = {
  children: PropTypes.node,
  initialValues: PropTypes.object,
};

export { CostingFormProvider, CostingFormContext };
