import { get, isArray } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Address, PODocumentsModal } from 'portal/components';

const BillingDetails = ({ order }) => {
  const lastFour = get(order, 'payment_method.last_4_digits');

  let isPoOrder =
    !!order.customer_po && isArray(order.po_documents) && order.po_documents.length > 0;

  return (
    <div className="border-top mb-5 py-5">
      <h3>Billing details</h3>

      <div className="mt-3 row">
        {isPoOrder ? (
          <div className="col">
            <div>
              <div className="font-size-sm text-gray">PO #</div>

              <div className="text-light mt-0.5">{order.customer_po}</div>
            </div>

            <div className="mt-3">
              <div className="font-size-sm text-gray">PO document(s)</div>

              <PODocumentsModal
                action={
                  <div className="cursor-pointer mt-0.5 text-light underline">
                    {order.po_documents.length} attached
                  </div>
                }
                documents={order.po_documents}
              />
            </div>
          </div>
        ) : (
          <div className="col">
            <div className="font-size-sm text-gray">Credit Card</div>

            <div className="text-light">
              {lastFour ? `**** **** **** ${lastFour}` : 'No credit card information'}
            </div>
          </div>
        )}

        <div className="col">
          <div className="text-gray font-size-sm"> Address</div>

          <div className="text-light">
            <Address address={order.billing_address} />
          </div>
        </div>
      </div>
    </div>
  );
};

BillingDetails.propTypes = {
  order: PropTypes.shape({
    billing_address: PropTypes.object.isRequired,
    customer_po: PropTypes.string,
    payment_method: PropTypes.shape({
      last_4_digits: PropTypes.string,
    }),
    po_documents: PropTypes.arrayOf(PropTypes.object.isRequired),
  }).isRequired,
};

export default BillingDetails;
