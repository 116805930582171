import { useQuery } from 'react-query';

import { api } from 'fr-shared/api';

export interface User {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
}

export interface UserSearchArgs {
  organization_id: number;
  name?: string;
  page_size?: number;
}

const fetchUsersByOrg = ({
  name,
  organization_id,
  page_size = 1000,
}: UserSearchArgs): Promise<User[]> => {
  return api
    .get(`/organizations/${organization_id}/users`, { params: { page_size, name } })
    .then(res => res.data);
};

const useOrganizationUsers = ({ name, organization_id, page_size = 1000 }: UserSearchArgs) => {
  return useQuery<User[], Error>(
    ['usersByOrganization', organization_id, name],
    () => fetchUsersByOrg({ name, organization_id, page_size }),
    {
      refetchOnWindowFocus: false,
      staleTime: 10000,
    }
  );
};

export default useOrganizationUsers;
