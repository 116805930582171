import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Prompt as RRPrompt } from 'react-router';

const promptForExternalNavigation = e => {
  // Cancel the event
  e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
  // Chrome requires returnValue to be set
  e.returnValue = '';
};

const Prompt = props => {
  const { when } = props;

  useEffect(() => {
    if (when) {
      window.addEventListener('beforeunload', promptForExternalNavigation);

      return () => window.removeEventListener('beforeunload', promptForExternalNavigation);
    }
  }, [when]);

  return <RRPrompt {...props} />;
};

Prompt.propTypes = {
  when: PropTypes.bool,
};

export default Prompt;
