import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { CopyLink, FormCheckbox, Icon } from 'fr-shared/components';

const DisplayQuoteInPortalCheckbox = ({ onToggleOff, onToggleOn }) => {
  const formik = useFormikContext();

  const DISPLAY_IN_PORTAL_UNCHECKED = 'This will display the quote in the customer portal.';
  const DISPLAY_IN_PORTAL_CHECKED =
    'Unchecking this will remove this quote from the customer portal and disable the quote link sent to the customer.';

  const displayInPortal = formik.values.display_in_portal;

  const handleCheckboxSelection = () => {
    if (formik.values.state === 'Submitted') {
      if (displayInPortal) {
        onToggleOff();
      } else {
        onToggleOn();
      }
    }
  };
  return (
    <>
      <div className="flex justify-content-end align-items-baseline">
        {displayInPortal && formik.values.state === 'Submitted' && (
          <CopyLink
            link={`${window.location.origin}/quotes/${formik.values.id}`}
            initialText="Copy quote link"
            successText="Quote link copied!"
          />
        )}
        <FormCheckbox
          label="Display on customer portal"
          name="display_in_portal"
          className="ml-5"
          onClick={handleCheckboxSelection}
        />
        {formik.values.state !== 'Submitted' && (
          <>
            {displayInPortal ? (
              <Icon
                className="ml-1"
                name="info-circle"
                type="far"
                tooltip={DISPLAY_IN_PORTAL_CHECKED}
              />
            ) : (
              <Icon
                className="ml-1"
                name="info-circle"
                type="far"
                tooltip={DISPLAY_IN_PORTAL_UNCHECKED}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

DisplayQuoteInPortalCheckbox.propTypes = {
  onToggleOff: PropTypes.func,
  onToggleOn: PropTypes.func,
};

export default DisplayQuoteInPortalCheckbox;
