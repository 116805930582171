import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Link } from 'fr-shared/components';

const SupplierPOList = supplierPO => {
  return (
    <div className="row form-group">
      <span className="col-md-4">Supplier PO</span>
      <div className="col-md flex flex-column">
        {supplierPO.supplierPO.map((item, index) => (
          <div className="supplier-po mb-1" key={index}>
            <div className="flex flex-row align-items-center">
              <Link
                className="flex-grow-1"
                color="link"
                to={{ pathname: item.url }}
                openNewWindow
              >
                <Icon name="download" className="mr-1" />
                {item.location_name + ' - v' + item.revision + '.pdf'}
              </Link>
            </div>
            <p className="font-weight-bold">
              Generated by {item.created_by.name} on{' '}
              {moment(item.updated_at).format('MM/DD/YY, LT')}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

SupplierPOList.propTypes = {
  supplierPO: PropTypes.array,
};

export default SupplierPOList;
