import { forEach } from 'lodash';

import { CostDriver } from './cost_drivers';

/**
 * The different check types severity
 */
const MANUFACTURABILITY_CHECK_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
} as const;

/**
 * Non-critical DFM checks array of values that should be grouped together by either Cost or Design
 */
const GROUPED_COST_NON_CRITICAL_DFM_CHECKS = [
  'non_standard_holes',
  'low_material_utilization',
  'cnc_material_utilization',
  'cnc_contouring',
] as string[];

export type ManufacturabilityCheckType =
  typeof MANUFACTURABILITY_CHECK_TYPES[keyof typeof MANUFACTURABILITY_CHECK_TYPES];

/**
 * Finds the manufacturability check that has the given manufacturing process ID
 * and manufacturability check ID. Returns `null` if manufacturability check
 * cannot be found.
 */
export const findManufacturabilityCheck = (
  checks: ManufacturabilityCheck[],
  manufacturabilityCheckId: number | null
): ManufacturabilityCheck | null => {
  if (manufacturabilityCheckId === null) {
    return null;
  }
  let check = checks.find(c => c.id === manufacturabilityCheckId);
  return check ? check : null;
};

/**
 * Returns that manufacturability checks for the given manufacturing process.
 */
export const filterChecksByProcess = (
  checks: ManufacturabilityCheck[],
  manufacturingProcessId: number
): ManufacturabilityCheck[] => {
  return checks.filter(c => c.manufacturing_process_id === manufacturingProcessId);
};

/**
 * non critical dfm checks are grouped by cost & design. . Any non critical check that is in the GROUPED_COST_NON_CRITICAL_DFM_CHECKS constant is cost related
 * everything else would be a design issue (as long as it's non-critical)
 */
export const groupNonCriticalDfmChecks = (checks: ManufacturabilityCheck[]) => {
  let groupedValues = {
    cost: [],
    design: [],
  } as GroupedNonCriticalManufacturabilityChecks;
  forEach(checks, function (check) {
    if (GROUPED_COST_NON_CRITICAL_DFM_CHECKS.includes(check.name)) {
      groupedValues.cost.push(check);
    } else {
      groupedValues.design.push(check);
    }
  });
  return groupedValues;
};

/**
 * Takes the list of checks and picks the most severe if there multiple with the same label property.
 */
export const prioritizeChecksBySeverity = (checks: ManufacturabilityCheck[]) => {
  // Build up map / dictionary that maps each unique label to the most severe check for that label
  const checksByLabel = checks.reduce(
    (acc: Record<string, ManufacturabilityCheck>, curr: ManufacturabilityCheck) => {
      const existing = acc[curr.label];
      if (existing) {
        // consider it to be more severe if the old one passed or the new one has a more severe type (error)
        const isMoreSevere = existing.passed || curr.type === MANUFACTURABILITY_CHECK_TYPES.ERROR;
        if (!curr.passed && isMoreSevere) {
          acc[curr.label] = curr;
        }
      } else {
        // assign the first one since doesn't yet exist
        acc[curr.label] = curr;
      }
      return acc;
    },
    {}
  );
  // now turn the map into an array
  return Object.values(checksByLabel);
};

/**
 * Returns that manufacturability checks that passed.
 */
export const filterPassedChecks = (
  checks: ManufacturabilityCheck[]
): ManufacturabilityCheck[] => {
  return checks.filter(c => c.passed);
};

/**
 * Returns that manufacturability checks that failed and should be treated as
 * warnings.
 */
export const filterFailedWarningChecks = (
  checks: ManufacturabilityCheck[]
): ManufacturabilityCheck[] => {
  return checks.filter(c => !c.passed && c.type === MANUFACTURABILITY_CHECK_TYPES.WARNING);
};

/**
 * Returns that manufacturability checks that failed and should be treated as
 * errors.
 */
export const filterFailedErrorChecks = (
  checks: ManufacturabilityCheck[]
): ManufacturabilityCheck[] => {
  return checks.filter(c => !c.passed && c.type === MANUFACTURABILITY_CHECK_TYPES.ERROR);
};

export const filterChecksByCostDriver = (
  checks: ManufacturabilityCheck[],
  costDriver: CostDriver
): ManufacturabilityCheck[] => {
  const costDriverManufacturabilityCheckMap: any = {
    equipment: ['cnc_contouring'],
  };

  return checks.filter(c =>
    costDriverManufacturabilityCheckMap[costDriver.cost_driver_name]?.includes(c.name)
  );
};

/**
 * Indicates whether a mesh for the given manufacturability check will subsume
 * the part mesh.
 *
 * This happens, for example, when the manufacturability check mesh is the mesh
 * of a standard stock that is used to create a CNC part.
 */
export const subsumesPartMesh = (check: ManufacturabilityCheck): boolean => {
  return check.name === 'cnc_material_utilization';
};
