export const MATERIAL_MARKETING_URLS = {
  'PA 12': 'https://www.fastradius.com/resources/know-your-materials-nylon/',
  'PA12-GB': 'https://www.fastradius.com/resources/know-your-materials-nylon/',
  'Tough 1500': 'https://www.fastradius.com/resources/know-your-materials-sla-tough-resin/',
  'Rigid 4000': 'https://www.fastradius.com/resources/know-your-materials-rigid-resin/',
  'Rigid 10K': 'https://www.fastradius.com/resources/know-your-materials-rigid-resin/',
  'Flexible Gray': 'https://www.fastradius.com/resources/know-your-materials-flexible-resin/',
  'Flexible Clear': 'https://www.fastradius.com/resources/know-your-materials-flexible-resin/',
  Elastic: 'https://www.fastradius.com/resources/elastic-resin/',
  'UMA 90': 'https://www.fastradius.com/resources/urethane-methacrylate-uma/',
  'SIL 30': 'https://www.fastradius.com/resources/silicone-sil/',
  'RPU 70': 'https://www.fastradius.com/resources/rigid-polyurethane-rpu70/',
  'MPU 100': 'https://www.fastradius.com/resources/multi-purpose-polyurethane-mpu/',
  'Loctite IND 405 Clear':
    'https://www.fastradius.com/resources/3d-printing-materials-loctite-3d-ind405-clear/',
  'EPX 82': 'https://www.fastradius.com/resources/epoxy-epx-82/',
  'EPU 41': 'https://www.fastradius.com/resources/elastomeric-polyurethane-epu-40-and-41/',
  'EPU 40': 'https://www.fastradius.com/resources/elastomeric-polyurethane-epu-40-and-41/',
  'CE 221': 'https://www.fastradius.com/resources/cyanate-ester-ce-221/',
  'PC-ABS':
    'https://www.fastradius.com/resources/know-your-materials-polycarbonate-acrylonitrile-butadiene-styrene-pc-abs/',
  'Nylon 6': 'https://www.fastradius.com/resources/know-your-materials-nylon/',
  'Nylon 12CF': 'https://www.fastradius.com/resources/know-your-materials-nylon/',
  'Nylon 12': 'https://www.fastradius.com/resources/know-your-materials-nylon/',
  ASA: 'https://www.fastradius.com/resources/acrylonitrile-styrene-acrylate-asa/',
  ABS: 'https://www.fastradius.com/resources/know-your-materials-acrylonitrile-butadiene-styrene-abs/',
  'Nylon 6/6': 'https://www.fastradius.com/resources/know-your-materials-nylon/',
  'PEEK (Polyetheretherketone)': 'https://www.fastradius.com/resources/know-your-materials-peek/',
  'PVC (Polyvinyl chloride)':
    'https://www.fastradius.com/resources/know-your-materials-polyvinyl-chloride/',
  UHMW: 'https://www.fastradius.com/resources/know-your-materials-uhmw/',
};

export const MATERIAL_TYPES = {
  Plastic: 'Plastic',
  Metal: 'Metal',
};

export const materialHelpText = (material: Material): String => {
  switch (material?.material_type?.type) {
    case MATERIAL_TYPES.Metal:
      return 'Assume broken edges on all metal finishes. Color is the inherent color of the selected material.';
    case MATERIAL_TYPES.Plastic:
      return 'Assume sharp edges on all plastic finishes.';
  }
};
