import PropTypes from 'prop-types';
import React from 'react';

import { Button, Modal } from 'portal/components';

import styles from './ModalPrompt.module.css';

const ModalPrompt = ({
  action,
  title,
  message,
  when = true,
  cancelText = 'Cancel',
  confirmText = "Yes, I'm sure",
  onActionHandler,
  onCancel,
  onConfirm,
}) => {
  const handleCancel = toggle => {
    onCancel && onCancel();
    toggle();
  };

  const handleConfirm = toggle => {
    onConfirm && onConfirm();
    toggle();
  };

  if (!when) return React.cloneElement(action);

  return (
    <Modal
      className={styles.Prompt}
      dataTestId="prompt-modal"
      action={action}
      onActionHandler={onActionHandler}
    >
      {({ toggle }) => (
        <>
          <Modal.Body className="text-center pt-3">
            <h5 className="mb-1">{title}</h5>
            <div className="font-size-base text-gray">{message}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button outline onClick={() => handleCancel(toggle)}>
              {cancelText}
            </Button>
            <Button className="ml-2" onClick={() => handleConfirm(toggle)}>
              {confirmText}
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

ModalPrompt.propTypes = {
  action: PropTypes.node,
  title: PropTypes.string,
  message: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onActionHandler: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  when: PropTypes.bool,
};
export default ModalPrompt;
