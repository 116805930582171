import { IconFont } from '@fast-radius/shared-ui';
import React, { useEffect, useState } from 'react';

import { Pagination, PartImage, classNames } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';

import styles from './CostingRecommendationsCarousel.module.css';

interface CostingRecommendationsCarouselProps {
  recommendations: Array<ManufacturabilityCheck>;
  displayScreenshot: Boolean;
  displaySlideCount: Boolean;
  partScreenshotSrc?: string;
  onOpenPartViewerModal?: (index: number) => void;
  onUpdateDfmCheckIndex?: (index: number) => void;
  activeSlide?: number;
}

const CostingRecommendationsCarousel = ({
  recommendations,
  displayScreenshot,
  displaySlideCount,
  partScreenshotSrc,
  onOpenPartViewerModal,
  onUpdateDfmCheckIndex,
  activeSlide = 1,
}: CostingRecommendationsCarouselProps) => {
  const userAnalytics = useUserAnalyticsContext();
  const [pageNumber, setPageNumber] = useState(activeSlide);

  const togglePageNumber = (newPageNumber: number) => {
    userAnalytics.track('Cost Insights - Browsed Recommendations');
    setPageNumber(newPageNumber);
    if (onUpdateDfmCheckIndex) onUpdateDfmCheckIndex(newPageNumber - 1);
  };

  useEffect(() => {
    setPageNumber(activeSlide);
  }, [recommendations, activeSlide]);

  if (recommendations.length === 0) {
    return <></>;
  }

  const content = recommendations.map((rec, index) => (
    <>
      <div className="text-coolGray-100 mb-2">{rec?.cost_recommendation}</div>
      {displayScreenshot && (
        <button
          className={classNames([
            styles.Screenshot,
            'mb-2',
            'flex',
            'flex-column',
            'bg-transparent',
            'border-none',
          ])}
          onClick={() => {
            userAnalytics.track('Cost Insights - Clicked Recommendation');
            if (onOpenPartViewerModal) onOpenPartViewerModal(index);
          }}
        >
          <span className={styles.ScreenshotIcon}>
            <div className={styles.ExpandIconBackground}>
              <IconFont name="expand-out" className={styles.ExpandIcon} />
            </div>
          </span>
          <PartImage size="md" backdrop={false} src={partScreenshotSrc} />
        </button>
      )}
    </>
  ));

  return (
    <>
      <div className="flex justify-between">
        <div className="mb-2 text-coolGray-300" data-testid="costing-recommendations-section">
          Recommendations
        </div>
        {recommendations.length > 1 && displaySlideCount && (
          <span>
            <span className="text-coolGray-100">{pageNumber}</span> /{' '}
            <span className="text-coolGray-300">{content.length}</span>
          </span>
        )}
      </div>
      {recommendations.length > 1 ? (
        <div className={classNames(['h-100', 'w-100', 'flex', 'flex-column'])}>
          <div data-testid="costing-recommendations-carousel">{content[pageNumber - 1]}</div>
          <Pagination
            className={classNames(['flex', 'justify-content-center'])}
            page={pageNumber}
            totalPages={content.length}
            pageNumberStyle={'circular'}
            setPage={togglePageNumber}
            enabledChevronClassName={styles.EnabledChevron}
            activePaginationCircleClassName={styles.ActivePaginationCircle}
            inactivePaginationCircleClassName={styles.InactivePaginationCircle}
          />
        </div>
      ) : (
        <div data-testid="costing-recommendations-single-slide">{content[0]}</div>
      )}
    </>
  );

  return;
};

export default CostingRecommendationsCarousel;
