import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, Button, Helmet } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { useManufacturingProcess } from 'fr-shared/hooks';

import RejectionReasonForm from './RejectionReasonForm';

const RejectionReasonNew = ({ history }) => {
  const [mfgProcesses, setMfgProcesses] = useState([]);
  const { setAlert } = useContext(AlertContext);
  const { data: manufacturingProcessesData } = useManufacturingProcess();

  useEffect(() => {
    if (manufacturingProcessesData) {
      const allMfgProcesses = manufacturingProcessesData.map(process => {
        return {
          id: process.id,
          label: process.name,
          value: false,
        };
      });
      setMfgProcesses(allMfgProcesses);
    }
  }, [manufacturingProcessesData]);

  const handleSubmit = (values, actions) => {
    const rejectReasonParams = {
      ...values,
      manufacturing_processes: values.manufacturing_processes.filter(p => p.value),
    };
    api
      .post('/rejection_reasons/', { rejection_reason: rejectReasonParams })
      .then(() => {
        setAlert({
          message: 'Successfully created Rejection Reason',
          color: 'success',
        });
        history.push('/admin/rejection_reasons/');
      })
      .catch(err => {
        if (err.response?.data) {
          const { errors, messages } = err.response.data;
          actions.setErrors({
            server: messages,
            ...errors,
          });
        }

        setAlert({
          message: 'Sorry, we were unable to create this rejection reason.',
          color: 'danger',
        });
      });
  };

  return (
    <>
      <Helmet title="Rejection Reasons" />
      <Breadcrumb to="/admin/rejection_reasons">Rejection Reasons</Breadcrumb>
      <Breadcrumb to="/admin/rejection_reasons/new">Add Rejection Reason</Breadcrumb>
      <div className="container">
        <div className="flex align-items-center py-3">
          <h2 className="mb-0">Add a Rejection Reason</h2>
          <div className="ml-auto">
            <Button color="secondary" onClick={e => history.goBack(e)}>
              Back
            </Button>
          </div>
        </div>
        <RejectionReasonForm
          reason={{ manufacturing_processes: mfgProcesses }}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
};

RejectionReasonNew.propTypes = {
  history: PropTypes.object,
};

export default RejectionReasonNew;
