import { connect } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Button } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';

const WorkOrderButtons = ({ onDeleteClicked, onTravelerClicked, travelerIsLoading, formik }) => {
  const { state } = formik.values;
  const { user } = useContext(UserContext);
  return (
    <div className="mt-3 flex align-items-center">
      {user.canDeleteWorkOrders && (
        <Button
          outline={true}
          color="danger"
          className="hidden md:inline-block"
          onClick={onDeleteClicked}
        >
          Delete Work Order
        </Button>
      )}
      <div className="flex flex-1 justify-end ml-auto">
        {state !== 'Draft' && (
          <Button
            color="primary"
            className="mr-2 hidden md:inline-block"
            loading={travelerIsLoading}
            onClick={() => onTravelerClicked(formik.values)}
          >
            Generate Traveler
          </Button>
        )}
        <Button color="success" type="submit">
          Save
        </Button>
      </div>
    </div>
  );
};

WorkOrderButtons.propTypes = {
  onDeleteClicked: PropTypes.func,
  onTravelerClicked: PropTypes.func,
  travelerIsLoading: PropTypes.bool,
  formik: PropTypes.object,
};

export default connect(WorkOrderButtons);
