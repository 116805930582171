import { IconFont } from '@fast-radius/shared-ui';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';

import { api } from 'fr-shared/api';
import { Button, ButtonLink } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { useManufacturingProcess } from 'fr-shared/hooks';
import { AUTOCOSTABLE_MFG_PROCESSES } from 'fr-shared/lib/manufacturing_process';

import { Link, Page, PageHeader, PartImage, classNames } from 'portal/components';
import {
  AutoquoteError,
  AutoquoteRequest,
  AutoquoteTiersResponseState,
  fetchAutoquoteTiers,
  fetchCostData,
  isAutoquoteTierList,
  newAutoquoteLoading,
} from 'portal/lib/autoquote';
import { useCreateCart } from 'portal/lib/cart';
import { CostData } from 'portal/lib/cost_drivers';

import DfmPanel from '../dfm/DfmPanel/DfmPanel';
import { InitialPartConfig, PartConfig } from '../viewer';
import viewerAnimationStyles from '../viewer.module.css';
import CompareContainer from './CompareContainer';
import styles from './CompareContainer.module.css';
import { ManufacturingSpecState } from './ManufacturingSpecificationFields';
import PrintCompareContainer from './PrintCompareContainer';

export type ColumnState = ManufacturingSpecState & {
  columnId: string;
  colOffset: number;
  costData: CostData;
  autoquoteTiersState: AutoquoteTiersResponseState;
};

function genNewColumn(): ColumnState {
  return {
    autoquoteTiersState: null,
    columnId: uuidv4(),
    colOffset: 0,
    costData: null,
    valid: false,
    manufacturing_process_id: null,
    material_id: null,
    color_id: null,
    layer_thickness_id: null,
  };
}

export type LeadTimeFields = {
  quantity: number;
  isStandard: boolean;
};

const AUTOQUOTE_DEBOUNCE_MS = 400;

const ComparePage = () => {
  const { part_id: partId }: { part_id: string } = useParams();
  const { data: manufacturingProcessesData }: { data: ManufacturingProcess[] } =
    useManufacturingProcess({ publiclyAvailable: true });
  const [comparePageVersion, setComparePageVersion] = useState<string>('screen');
  const manufacturingProcesses = manufacturingProcessesData?.filter(mp =>
    AUTOCOSTABLE_MFG_PROCESSES.includes(mp.name)
  );
  const history = useHistory<InitialPartConfig>();
  const historyState = history.location.state;
  const fromPartSelection = !historyState;
  const initialColumnData = historyState
    ? { ...historyState, valid: true }
    : {
        manufacturing_process_id: null,
        material_id: null,
        color_id: null,
        layer_thickness_id: null,
        valid: false,
      };
  const [leadTimeFields, setLeadTimeFields] = useState<LeadTimeFields>({
    quantity: 1,
    isStandard: true,
  });
  const [colFieldData, setColFieldData] = useState<ColumnState[]>([
    {
      ...initialColumnData,
      autoquoteTiersState: null,
      columnId: uuidv4(),
      colOffset: 0,
      costData: null,
    },
    genNewColumn(),
  ]);
  const [part, setPart] = useState(null);
  const [dfmPanelData, setDfmPanelData] = useState<{
    colId: string;
    selectedDfmCheck: ManufacturabilityCheck;
    pushToEvaluateParams: PartConfig;
  } | null>(null);
  const [showPanel, setShowPanel] = useState<boolean>(false);
  const { isSubmitting, createCart } = useCreateCart();

  const { setAlert } = useContext(AlertContext);

  // debounce the call to update all the columns when the quantity changes
  // callback instance depends on part since it starts out null
  const updateAllColumnsRaw = (quantity: number) => {
    setColFieldData(prev => {
      return prev.map(col => {
        const { newState } = updateColumn(col, quantity);
        return newState;
      });
    });
  };
  const updateAllColumnsDebounced = useCallback(
    debounce(updateAllColumnsRaw, AUTOQUOTE_DEBOUNCE_MS),
    [part]
  );

  // If we already had a configuration from the history state, fetch the autoquote
  useEffect(() => {
    if (historyState && part) {
      handleFieldChanges({ valid: true }, 0);
    }
    // disabled since we don't want to change if only the quantity changes - this is initial state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyState, part]);

  /**
   * Load the part
   */
  const getPart = useCallback(() => {
    return api
      .get(`/customer_portal/parts/${partId}`)
      .then(res => {
        const { data: part } = res;
        setPart(part);
      })
      .catch(() => {
        setAlert({
          color: 'danger',
          message: 'An unexpected error occurred, please refresh and try again',
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Get the initial part data to start the comparison
   */
  useEffect(() => {
    getPart();
  }, [getPart]);

  const closeDfmPanel = () => {
    setShowPanel(false);
    setTimeout(() => setDfmPanelData(null), 500);
  };

  const handleBack = () => {
    const redirectPath = fromPartSelection ? '/studio/compare' : `/studio/evaluate/${partId}`;
    history.push(redirectPath);
  };

  const pushToEvaluate = (params: PartConfig, checkId?: number) => {
    let basePath = `/studio/evaluate/${partId}/dfm`;
    if (checkId) basePath += `/${checkId}`;
    history.push(basePath, params);
  };

  const getParamsForColumn = (columnId: string) => {
    const columnData = colFieldData.find(c => c.columnId === columnId);
    if (!columnData) return null;

    return {
      manufacturing_process_id: columnData.manufacturing_process_id,
      material_id: columnData.material_id,
      color_id: columnData.color_id,
      layer_thickness_id: columnData.layer_thickness_id,
    };
  };

  const handleSeeMoreCost = (columnId: string) => {
    const baseParams = getParamsForColumn(columnId);
    if (!baseParams) return;

    history.push(`/studio/evaluate/${partId}/costs`, baseParams);
  };

  const handleSelectConfig = (columnId: string) => {
    const baseParams = getParamsForColumn(columnId);
    if (!baseParams) return;

    if (fromPartSelection) {
      // GO TO QUOTE
      const process = manufacturingProcessesData.find(
        mp => mp.id === baseParams.manufacturing_process_id
      );
      createCart({ ...baseParams, part_id: partId }, process?.name)
        .then(res => {
          const { data: cli } = res;
          history.push(`/part-config/${cli.id}`);
        })
        .catch(() => {
          setAlert({
            color: 'danger',
            message: 'An unexpected error occurred, please refresh and try again',
          });
        });
    } else {
      pushToEvaluate(baseParams);
    }
  };

  const handleFieldChanges = (changes: Partial<ColumnState>, index: number) => {
    setColFieldData(prevState => {
      const newState = [...prevState];
      newState[index] = { ...prevState[index], ...changes };

      // Add a new column if we've filled out the last column
      if (newState[newState.length - 1].valid) {
        return [...newState, genNewColumn()];
      }
      return newState;
    });
    if (changes.valid) {
      const { autoquotePromise } = updateColumn(
        { ...colFieldData[index], ...changes },
        leadTimeFields.quantity
      );
      // When the autoquote is fetched, then fetch the cost data
      autoquotePromise.then(autoquoteTiersResponseState => {
        return fetchCostDataForColumn(autoquoteTiersResponseState, colFieldData[index].columnId);
      });
    }
  };

  const handleDeleteColumn = (columnId: string) => {
    setColFieldData(prevState => {
      return prevState.filter(c => c.columnId !== columnId);
    });
  };

  const handleShiftCancel = () => {
    setColFieldData(prevState => {
      return prevState.map(c => ({ ...c, colOffset: 0 }));
    });
  };

  const handleShiftLeft = (columnId: string) => {
    setColFieldData(prevState => {
      const indexColToShift = prevState.findIndex(c => c.columnId === columnId);
      const colToShift = prevState[indexColToShift];
      if (!colToShift || indexColToShift + colToShift.colOffset <= 0) {
        return prevState;
      }

      // Swap the column to shift and the one before it in the "display" order (index + colOffset)
      return prevState.map((c, index) => {
        const newC = { ...c };
        if (index === indexColToShift) {
          newC.colOffset -= 1;
        } else if (index + newC.colOffset === indexColToShift + colToShift.colOffset - 1) {
          newC.colOffset += 1;
        }
        return newC;
      });
    });
  };

  const handleShiftRight = (columnId: string) => {
    setColFieldData(prevState => {
      const indexColToShift = prevState.findIndex(c => c.columnId === columnId);
      const colToShift = prevState[indexColToShift];
      if (!colToShift || indexColToShift + colToShift.colOffset >= prevState.length - 1) {
        return prevState;
      }

      // Swap the column to shift and the one after it in the "display" order (index + colOffset)
      return prevState.map((c, index) => {
        const newC = { ...c };
        if (index === indexColToShift) {
          newC.colOffset += 1;
        } else if (index + newC.colOffset === indexColToShift + colToShift.colOffset + 1) {
          newC.colOffset -= 1;
        }
        return newC;
      });
    });
  };

  const handleShiftDone = () => {
    setColFieldData(prevState => {
      const newState = prevState.map((c, index) => ({
        ...c,
        newIndex: index + c.colOffset,
        colOffset: 0,
      }));
      newState.sort((a, b) => a.newIndex - b.newIndex);
      return newState;
    });
  };

  const handleQuantityChange = (quantity: number) => {
    // Skip if the quantity hasn't actually changed
    if (quantity === leadTimeFields.quantity) return;

    setLeadTimeFields(prevState => {
      return { ...prevState, quantity };
    });

    updateAllColumnsDebounced(quantity);
  };
  const handleCheckStandard = (isStandard: boolean) => {
    setLeadTimeFields(prevState => {
      return { ...prevState, isStandard };
    });
  };

  const updateColumn = (
    columnState: ColumnState,
    quantity: number
  ): { newState: ColumnState; autoquotePromise: Promise<AutoquoteTiersResponseState> } => {
    if (!columnState || !columnState.valid) {
      return { newState: columnState, autoquotePromise: Promise.resolve(null) };
    }
    const req: AutoquoteRequest = {
      part_id: part?.current_revision?.part_id,
      part_file_revision_id: part?.current_revision?.id,
      manufacturing_process_id: columnState.manufacturing_process_id,
      material_id: columnState.material_id,
      color_id: columnState.color_id,
      layer_thickness_id: columnState.layer_thickness_id,
      quantity: quantity,
      use_defaults: true,
    };
    const autoquotePromise = fetchAutoquoteTiersForColumn(req, columnState.columnId);
    const newState = {
      ...columnState,
      autoquoteTiersState: newAutoquoteLoading(),
    };
    return { newState, autoquotePromise };
  };

  const fetchCostDataForColumn = (
    autoquoteTiers: AutoquoteTiersResponseState,
    columnId: string
  ): Promise<CostData> => {
    if (!isAutoquoteTierList(autoquoteTiers)) {
      updateColFieldData(columnId, { costData: null });
      return Promise.resolve(null);
    }
    const tiers = autoquoteTiers;
    // pick the autoquote based on standard or not
    const autoquoteToGetCostData = leadTimeFields.isStandard ? tiers[0] : tiers[1];

    return fetchCostData(autoquoteToGetCostData.id)
      .catch(_ => null) // eat the error and null out the cost data
      .then((costData: CostData) => {
        // save the resulting cost data
        updateColFieldData(columnId, {
          costData: {
            ...costData,
          },
        });
        return costData;
      });
  };

  const updateColFieldData = (columnId: string, change: Partial<ColumnState>) => {
    setColFieldData(prev => {
      // Update the matching column with the new state
      const matchingCol = prev.findIndex(c => c.columnId === columnId);
      if (matchingCol >= 0) {
        // Copy over that specific column, but update the autoquote state
        const newState = [...prev];
        newState[matchingCol] = {
          ...newState[matchingCol],
          ...change,
        };
        return newState;
      }
      return prev;
    });
  };

  const fetchAutoquoteTiersForColumn = (
    request: AutoquoteRequest,
    columnId: string
  ): Promise<AutoquoteTiersResponseState> => {
    return fetchAutoquoteTiers(request)
      .catch((error: AutoquoteError) => error)
      .then(tiersOrError => {
        // Save the autoquote state
        updateColFieldData(columnId, { autoquoteTiersState: tiersOrError });
        return tiersOrError;
      });
  };

  const changeCompareContainerScreen = (type: string) => {
    setComparePageVersion(type);
  };

  return (
    <Page className="p-0 mx-0 max-w-full">
      {comparePageVersion === 'screen' ? (
        <CompareContainer
          colFieldData={colFieldData}
          closeDfmPanel={closeDfmPanel}
          dfmChecks={part?.current_revision?.manufacturability_checks_v2}
          dfmCheckOnClick={(
            colId: string,
            dfmCheck: ManufacturabilityCheck,
            pushToEvaluateParams: PartConfig
          ) => {
            setDfmPanelData({
              colId: colId,
              selectedDfmCheck: dfmCheck,
              pushToEvaluateParams: pushToEvaluateParams,
            });
            setShowPanel(true);
          }}
          handleBack={handleBack}
          handleFieldChanges={handleFieldChanges}
          leadTimeFields={leadTimeFields}
          manufacturingProcesses={manufacturingProcesses}
          onChangeQuantity={handleQuantityChange}
          onCheckStandard={handleCheckStandard}
          onDelete={handleDeleteColumn}
          onShiftLeft={handleShiftLeft}
          onShiftRight={handleShiftRight}
          onShiftCancel={handleShiftCancel}
          onShiftDone={handleShiftDone}
          onSeeMore={handleSeeMoreCost}
          onSelectConfig={(!isSubmitting && handleSelectConfig) || undefined}
          pushToEvaluate={pushToEvaluate}
          selectedDfmCheck={{
            colId: dfmPanelData?.colId || null,
            checkId: dfmPanelData?.selectedDfmCheck?.id || null,
          }}
          selectionText={fromPartSelection ? 'Add to quote' : 'Use selection'}
        >
          <div className={classNames(['mb-1 mt-4 sticky left-0'])}>
            <div className={styles['container-width']}>
              {!fromPartSelection && (
                <div className="mb-3">
                  <Link
                    className="text-decoration-none text-coolGray-300 font-size-sm"
                    to={`/studio/evaluate/${partId}`}
                  >
                    <IconFont name="chevron-left" right />
                    Back to Evaluate part
                  </Link>
                </div>
              )}

              <PageHeader
                border={false}
                className="flex justify-between print:mb-2"
                title="Compare specs"
                subtitle="See which process and material gives you the best cost and lead time for your part."
              >
                <div className="flex items-center">
                  <div className="mr-2">
                    <ButtonLink
                      className="print:hidden text-coolGray-300"
                      onClick={() => changeCompareContainerScreen('print')}
                    >
                      Print-friendly version
                    </ButtonLink>
                  </div>
                  <div className="flex items-center bg-coolGray-800 rounded-full py-1 px-2 max-h-[64px]">
                    <PartImage
                      className="mr-1 bg-coolGray-800"
                      src={part?.current_revision?.screenshot}
                      width={48}
                      height={48}
                    />
                    <div className="text-coolGray-100 text-sm">{part?.name}</div>
                    {fromPartSelection && (
                      <Button
                        onClick={handleBack}
                        className="modal-close text-2xl ml-3 print:hidden"
                      >
                        <IconFont name="close" />
                      </Button>
                    )}
                  </div>
                </div>
              </PageHeader>
            </div>
          </div>
        </CompareContainer>
      ) : (
        <PrintCompareContainer
          colFieldData={colFieldData}
          closeDfmPanel={closeDfmPanel}
          dfmChecks={part?.current_revision?.manufacturability_checks_v2}
          dfmCheckOnClick={(
            colId: string,
            dfmCheck: ManufacturabilityCheck,
            pushToEvaluateParams: PartConfig
          ) => {
            setDfmPanelData({
              colId: colId,
              selectedDfmCheck: dfmCheck,
              pushToEvaluateParams: pushToEvaluateParams,
            });
            setShowPanel(true);
          }}
          handleBack={handleBack}
          handleFieldChanges={handleFieldChanges}
          leadTimeFields={leadTimeFields}
          manufacturingProcesses={manufacturingProcesses}
          onChangeQuantity={handleQuantityChange}
          onCheckStandard={handleCheckStandard}
          pushToEvaluate={pushToEvaluate}
          selectedDfmCheck={{
            colId: dfmPanelData?.colId || null,
            checkId: dfmPanelData?.selectedDfmCheck?.id || null,
          }}
          selectionText={fromPartSelection ? 'Add to quote' : 'Use selection'}
        >
          <div className={classNames(['mb-1 mt-4 sticky left-0 print:mb-0 print:mt-0'])}>
            <div className={styles['container-width']}>
              {!fromPartSelection && (
                <div className="mb-3">
                  <Link
                    className="text-decoration-none text-coolGray-300 font-size-sm"
                    to={`/studio/evaluate/${partId}`}
                  >
                    <IconFont name="chevron-left" right />
                    Back to Evaluate part
                  </Link>
                </div>
              )}

              <PageHeader
                border={false}
                className="flex justify-between print:mb-0"
                title="Compare specs"
                subtitle="See which process and material gives you the best cost and lead time for your part."
              >
                <div className="flex items-center">
                  <div className="mr-2">
                    <ButtonLink
                      className="print:hidden text-coolGray-300 no-underline mr-2 inline-block"
                      onClick={() => changeCompareContainerScreen('screen')}
                    >
                      Back
                    </ButtonLink>

                    <ButtonLink
                      className="print:hidden text-coolGray-300"
                      onClick={() => window.print()}
                    >
                      Print
                    </ButtonLink>
                  </div>
                  <div className="flex items-center bg-coolGray-800 rounded-full py-1 px-2 max-h-[64px]">
                    <PartImage
                      className="mr-1 bg-coolGray-800"
                      src={part?.current_revision?.screenshot}
                      width={48}
                      height={48}
                    />
                    <div className="text-coolGray-100 text-sm">{part?.name}</div>
                    {fromPartSelection && (
                      <Button
                        onClick={handleBack}
                        className="modal-close text-2xl ml-3 print:hidden"
                      >
                        <IconFont name="close" />
                      </Button>
                    )}
                  </div>
                </div>
              </PageHeader>
            </div>
          </div>
        </PrintCompareContainer>
      )}

      <CSSTransition
        in={showPanel}
        classNames={{
          enter: viewerAnimationStyles.slideIn,
          exit: viewerAnimationStyles.slideOut,
        }}
        timeout={500}
      >
        <div className="absolute right-0 top-0 z-20">
          {dfmPanelData && (
            <DfmPanel
              className={styles.panel}
              onClose={closeDfmPanel}
              pushToEvaluate={() => {
                pushToEvaluate(
                  dfmPanelData.pushToEvaluateParams,
                  dfmPanelData.selectedDfmCheck.id
                );
              }}
              selectedDfmCheck={dfmPanelData.selectedDfmCheck}
            />
          )}
        </div>
      </CSSTransition>
    </Page>
  );
};

export default ComparePage;
