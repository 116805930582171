import { useFormikContext } from 'formik';
import { has, isEmpty, without } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { Button, Modal, classNames } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { ORDER_LINE_ITEM_STATES, ORDER_STATES } from 'fr-shared/lib/orders';

const OrderFormSubmitButtons = ({ isDraftSavable, onFormSubmit }) => {
  const formik = useFormikContext();
  const { user } = useContext(UserContext);

  const [showLineItemQuantityDeductionModal, setShowLineItemQuantityDeductionModal] =
    useState(false);
  const [touchedLineItemQuantities, setTouchedLineItemQuantities] = useState([]);

  const canSendAlert = user.canSendOrderAlerts;

  const handleSubmit = (nextOrderState, nextLineItemState) => {
    const newLineItems = formik.values.line_items.map(li => ({
      ...li,
      state: nextLineItemState,
    }));
    formik.setFieldValue('line_items', newLineItems);
    formik.setFieldValue('state', nextOrderState);
  };

  const handleSendConfirmation = () => {
    formik.setFieldValue('send_confirmation', true);
  };

  const updateStatusAndActualShipDateBasedonTouchedLineItemsQuantities = async () => {
    touchedLineItemQuantities.forEach(touchedLineItem => {
      const aggregateShipmentInfo = touchedLineItem.shipmentLineItems
        .filter(shipmentLineItem => shipmentLineItem.shipment.ship_to === 'Customer')
        .reduce(
          (acc, currentSLI) => {
            const shippedDate =
              new Date(acc.shippedDate) > new Date(currentSLI.shipment.shipped_date)
                ? acc.shippedDate
                : currentSLI.shipment.shipped_date;

            return {
              quantity: acc.quantity + currentSLI.quantity,
              shippedDate,
            };
          },
          { quantity: 0, shippedDate: null }
        );

      const { currentQuantity, sumPSLIQuantity } = touchedLineItem;

      if (
        aggregateShipmentInfo.quantity === currentQuantity &&
        currentQuantity === sumPSLIQuantity
      ) {
        const { setFieldValue } = formik;
        // https://github.com/jaredpalmer/formik/issues/529
        setFieldValue(
          `line_items[${touchedLineItem.index}].actual_ship_date`,
          aggregateShipmentInfo.shippedDate,
          false
        );
        setFieldValue(
          `line_items[${touchedLineItem.index}].state`,
          ORDER_LINE_ITEM_STATES.Shipped,
          false
        );
      }
    });
  };

  useEffect(() => {
    if (has(formik.touched, 'line_items')) {
      const shippedLineItemsWithAlteredQuantities = without(
        formik.touched.line_items.map((li, liIndex) => {
          if (
            !isEmpty(li) &&
            li.quantity &&
            !isEmpty(formik.initialValues.line_items[liIndex]) &&
            !isEmpty(formik.initialValues.line_items[liIndex].shipment_line_items)
          ) {
            const initialQuantity = formik.initialValues.line_items[liIndex]?.quantity || 0;
            const liId = formik.values.line_items[liIndex].id;
            const currentQuantity = formik.values.line_items[liIndex].quantity;
            const shipmentLineItems =
              formik.initialValues.line_items[liIndex]?.shipment_line_items || [];

            const sumPSLIQuantity = formik.values.line_items[
              liIndex
            ].planned_shipment_line_items.reduce((sum, currentPSLI) => {
              return sum + currentPSLI.quantity;
            }, 0);

            return {
              id: liId,
              index: liIndex,
              initialQuantity,
              currentQuantity,
              shipmentLineItems,
              sumPSLIQuantity,
            };
          }

          return false;
        }),
        false,
        undefined
      );

      if (!isEmpty(shippedLineItemsWithAlteredQuantities)) {
        setTouchedLineItemQuantities(shippedLineItemsWithAlteredQuantities);
        const anyLineItemQuantityDecreased = shippedLineItemsWithAlteredQuantities.reduce(
          (hasAnyLIWithDecreasedQuantity, currentLi) => {
            if (hasAnyLIWithDecreasedQuantity) {
              return hasAnyLIWithDecreasedQuantity;
            }

            return currentLi.currentQuantity < currentLi.initialQuantity;
          },
          false
        );

        setShowLineItemQuantityDeductionModal(anyLineItemQuantityDecreased);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.touched, formik.values.line_items]);

  if (isDraftSavable) {
    return (
      <>
        <Button
          className="ml-2 mb-2 min-w-[125px]"
          color="success"
          id="submit-button"
          onClick={() => handleSubmit(ORDER_STATES.Pending, ORDER_LINE_ITEM_STATES.Pending)}
          type="submit"
          showLoadingIcon={true}
          loading={formik.isSubmitting}
        >
          Submit for Approval
        </Button>
        <Button
          className="ml-2 mb-2 min-w-[110px]"
          color="secondary"
          id="submit-draft-button"
          onClick={() => handleSubmit(ORDER_STATES.Draft, ORDER_LINE_ITEM_STATES.Draft)}
          type="submit"
          showLoadingIcon={true}
          loading={formik.isSubmitting}
        >
          Save as Draft
        </Button>
      </>
    );
  } else {
    const showResubmitForApproval =
      user.canCreateOrders && formik.values.state === ORDER_STATES.ActionRequired;
    const showSaveAndAlertCustomer =
      canSendAlert &&
      ![ORDER_STATES.ActionRequired, ORDER_STATES.Pending].includes(formik.values.state);

    return (
      <>
        {showLineItemQuantityDeductionModal ? (
          <Modal
            dataTestId="oli-quantity-deduction-modal"
            action={
              <Button
                className="ml-2 mb-2"
                color="secondary"
                id="submit-button"
                dataTestId="submit-button-quantity-deduction-modal"
              >
                Save
              </Button>
            }
          >
            {({ toggle }) => (
              <>
                <Modal.Header title="Edit Order Line Item Quantity" onClose={toggle} />
                <div className="modal-body">
                  <p>
                    This action will change the quantity in a shipped line item. This could impact
                    a customer&apos;s order status and the customer&apos;s billing cycle. Please
                    notify <strong>finance@fastradius.com</strong> of the order you&apos;re
                    changing and what the change was.
                  </p>
                  <p>Are you sure you want to continue?</p>
                </div>
                <div className="modal-footer">
                  <Button color="link" onClick={toggle}>
                    No, Cancel
                  </Button>
                  <Button
                    onClick={async e => {
                      // this is async because formik setFieldValue is async but not really.
                      // see https://github.com/jaredpalmer/formik/issues/529 for more details.
                      await updateStatusAndActualShipDateBasedonTouchedLineItemsQuantities();

                      toggle();
                      onFormSubmit(e);
                    }}
                    color="primary"
                    className="ml-2"
                    type="submit"
                    dataTestId="quantity-deduction-modal-continue-button"
                  >
                    Yes, Continue
                  </Button>
                </div>
              </>
            )}
          </Modal>
        ) : (
          <Button
            className="ml-2 mb-2"
            color="secondary"
            id="submit-button"
            type="submit"
            dataTestId="submit-button"
          >
            Save
          </Button>
        )}
        {showSaveAndAlertCustomer && (
          <>
            {showLineItemQuantityDeductionModal ? (
              <Modal
                dataTestId="oli-quantity-deduction-modal"
                action={
                  <Button
                    className="ml-2 mb-2"
                    color="secondary"
                    id="submit-button-and-alert"
                    dataTestId="submit-and-alert-button-quantity-deduction-modal"
                  >
                    Save and Alert Customer
                  </Button>
                }
              >
                {({ toggle }) => (
                  <>
                    <Modal.Header title="Edit Order Line Item Quantity" onClose={toggle} />
                    <div className="modal-body">
                      <p>
                        This action will change the quantity in a shipped line item. This could
                        impact a customer&apos;s order status and the customer&apos;s billing
                        cycle. Please notify <strong>finance@fastradius.com</strong> of the order
                        you&apos;re changing and what the change was.
                      </p>
                      <p>Are you sure you want to continue?</p>
                    </div>
                    <div className="modal-footer">
                      <Button color="link" onClick={toggle}>
                        No, Cancel
                      </Button>
                      <Button
                        onClick={async e => {
                          handleSendConfirmation();

                          // this is async because formik setFieldValue is async but not really.
                          // see https://github.com/jaredpalmer/formik/issues/529 for more details.
                          await updateStatusAndActualShipDateBasedonTouchedLineItemsQuantities();

                          toggle();
                          onFormSubmit(e);
                        }}
                        color="primary"
                        className="ml-2"
                        type="submit"
                      >
                        Yes, Continue
                      </Button>
                    </div>
                  </>
                )}
              </Modal>
            ) : (
              <Button
                className="ml-2 mb-2"
                color="secondary"
                id="submit-button-and-alert"
                onClick={handleSendConfirmation}
                type="submit"
                dataTestId="submit-and-alert-button"
              >
                Save and Alert Customer
              </Button>
            )}
          </>
        )}
        {/*
         * This button is not conditionally rendered. It is conditionally hidden using CSS.
         * There's a very important reason for that. First, note the conditions around this button.
         *
         * - All the buttons in this component are submit buttons.
         * - The onSubmit function on the Formik form in the `OrderForm` component:
         *    - Stops event bubbling.
         *    - Passes up to a function in the `/admin/orders/edit.js` component that actually calls out to the server.
         *
         * This has very specific consequences.
         *
         * - Calling `formik.submitForm()` doesn't actually do anything.
         * - Passing the `OrderForm` submit function into this component and calling that after updating Formik won't work because
         *   `OrderForm` would need a chance to rerender before calling up to `/views/admin/orders/edit.js` or else the
         *   values it passes from Formik will be stale.
         * - Conditionally rendering this button will cause it to change the order state, which will change the condition on which
         *   it is shown, meaning the component will rerender and hide the button before the form submit event bubbles up to Formik,
         *   which means it won't actually submit the form. (It's unclear whether the event never occurs, or if it does reach Formik
         *   and the reference to the element is undefined, causing it to ignore the event.)
         *
         * The workaround is to keep the button on the page but hide it using CSS. If the button remains in the DOM, the submit event
         * will fire and the form will submit as expected. Ideally the entire `OrderForm` component can be reworked to be simpler, but
         * this fixes the issue until then.
         */}
        <Button
          className={classNames(['ml-2', 'mb-2', { hidden: !showResubmitForApproval }])}
          color="success"
          id="resubmit-button"
          onClick={() => handleSubmit(ORDER_STATES.Pending, ORDER_LINE_ITEM_STATES.Pending)}
          type="submit"
        >
          Resubmit for Approval
        </Button>
      </>
    );
  }
};

OrderFormSubmitButtons.propTypes = {
  isDraftSavable: PropTypes.bool,
  onFormSubmit: PropTypes.func,
};

export default OrderFormSubmitButtons;
