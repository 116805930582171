import PropTypes from 'prop-types';
import React from 'react';

import { classNames } from 'fr-shared/components';

import styles from './TableBodyRow.module.css';

export const TableBodyRow = ({
  children,
  isSelected,
  isBlueBorder = false,
  isDashed = false,
  className,
}) => {
  const rootClassNames = classNames([
    className,
    styles.Root,
    isSelected && styles.isSelected,
    isDashed && styles.isDashed,
    isBlueBorder && styles.BlueBorder,
  ]);
  return <tr className={rootClassNames}>{children}</tr>;
};

TableBodyRow.propTypes = {
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool,
  isBlueBorder: PropTypes.bool,
  isDashed: PropTypes.bool,
  className: PropTypes.string,
};

export default TableBodyRow;
