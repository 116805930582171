import { includes, some } from 'lodash';
import React from 'react';

import { IconFont } from 'fr-shared/components';
import {
  ADDITIVE_PART_FILE_EXTENSIONS_PORTAL,
  TRADITIONAL_PART_FILE_EXTENSIONS_PORTAL,
} from 'fr-shared/utils/files';

import { FormattedExtensions } from 'portal/components/SupportedFiles/SupportedFiles';
import { AutoquoteError, AutoquoteErrors } from 'portal/lib/autoquote';
import { CartLineItem } from 'portal/lib/cart';

interface QuoteInfoBoxProps {
  autoquoteError: AutoquoteError;
  lineItem: CartLineItem;
}

const AutoquoteErrorBox = ({ autoquoteError, lineItem }: QuoteInfoBoxProps) => {
  const errorCopy = getAutoquoteErrorCopy(
    autoquoteError.code,
    Array.isArray(autoquoteError.errors) ? autoquoteError.errors : [],
    lineItem
  );

  if (!errorCopy) return null;

  return (
    <div
      className="max-w-[522px] bg-coolGray-700 border border-coolGray-600 rounded-[8px] py-2 px-3 flex"
      data-testid="autoquote-error"
    >
      <div className="flex flex-column mr-2 mt-1">
        <div className="flex text-white text-lg align-items-center mb-1">{errorCopy.title}</div>
        <div className="text-coolGray-300 text-xs">{errorCopy.text}</div>
      </div>
      <IconFont
        name="error-triangle"
        className="flex flex-column justify-start text-coolGray-100 text-[40px]"
      />
    </div>
  );
};

export const getAutoquoteErrorCopy = (
  code: string,
  errors: string[] = [],
  lineItem: CartLineItem
) => {
  if (errors.includes(AutoquoteErrors.PartTooBig) && lineItem?.part?.units === 'mm') {
    return HELP_TEXTS.PartTooBig;
  } else if (errors.includes(AutoquoteErrors.PartTooBig)) {
    return HELP_TEXTS.PartTooBigRetryUnits;
  }

  if (errors.includes(AutoquoteErrors.InvalidFileType)) {
    return HELP_TEXTS.InvalidFileType;
  }

  if (errors.includes(AutoquoteErrors.Incomplete)) {
    return HELP_TEXTS.Incomplete;
  }

  if (errors.includes(AutoquoteErrors.CustomFinish)) {
    return HELP_TEXTS.CustomFinish;
  }

  if (errors.includes(AutoquoteErrors.UnsupportedMaterial)) {
    return HELP_TEXTS.UnsupportedMaterial;
  }

  if (errors.includes(AutoquoteErrors.BusinessReasons)) {
    return HELP_TEXTS.Generic;
  }

  if (
    some(
      [
        AutoquoteErrors.CNCRequirementsNotMet,
        AutoquoteErrors.CNCMaterialNotAutoquotable,
        AutoquoteErrors.CNCSurfaceRoughnessNotAutocostable,
        AutoquoteErrors.CNCToleranceCountsNotAutocostable,
        AutoquoteErrors.CNCMissingCNCAutoquoteAttribute,
        AutoquoteErrors.CNCAdvancedFeatureSelectedYes,
        AutoquoteErrors.CNCCertificationsSelectedYes,
        AutoquoteErrors.MissingCNCAdvancedFeatures,
        AutoquoteErrors.MissingCNCCertification,
        AutoquoteErrors.MissingCncGdt,
        AutoquoteErrors.MissingTolerance,
        AutoquoteErrors.ToleranceIsTooLow,
        AutoquoteErrors.CustomColorSelected,
        AutoquoteErrors.InspectionType,
      ],
      error => includes(errors, error)
    )
  ) {
    return HELP_TEXTS.Generic;
  }

  if (errors.includes(AutoquoteErrors.MissingManufacturingProcess)) {
    return HELP_TEXTS.MissingManufacturingProcess;
  }

  if (errors.includes(AutoquoteErrors.ManualQuoteMfgProcess)) {
    return HELP_TEXTS.ManualQuoteMfgProcess;
  }

  if (errors.includes(AutoquoteErrors.ManufacturabilityChecksDidNotRun)) {
    return HELP_TEXTS.Generic;
  }

  if (errors.includes(AutoquoteErrors.ManufacturabilityErrors)) {
    return HELP_TEXTS.ManufacturabilityErrors;
  }

  if (errors.includes(AutoquoteErrors.MissingSupportingDocument)) {
    return HELP_TEXTS.MissingSupportingDocument;
  }

  if (code === 'model_error') {
    return HELP_TEXTS.Generic;
  }

  if (code === 'revision_not_analyzed') {
    return HELP_TEXTS.Generic;
  }

  return null;
};

export const HELP_TEXTS = {
  CustomFinish: {
    title: 'Custom finishes require a manual quote',
    text: (
      <>
        Add any notes about the custom finish you need. We’ll review these and get back to you
        with a quote. For an instant quote, select a standard finish.
      </>
    ),
  },
  Incomplete: {
    title: 'Important info is missing',
    text: <>Check your manufacturing specs and complete all required fields.</>,
  },
  MissingSupportingDocument: {
    title: 'Supporting document is missing',
    text: (
      <>
        Your part requires a supporting document. Please upload one to continue with your quote.
      </>
    ),
  },
  UnsupportedMaterial: {
    title: 'Your material requires a manual quote',
    text: (
      <>
        We’ll need to review your specs before we give you a quote. For an instant quote, try a
        different material or manufacturing process.
      </>
    ),
  },
  MissingManufacturingProcess: {
    title: 'Manufacturing process is missing',
    text: <>Select a manufacturing process to continue with your quote.</>,
  },
  ManualQuoteMfgProcess: {
    title: 'Your process requires a manual quote',
    text: (
      <>
        We’ll need to review your specs before we give you a quote. For an instant quote, select a
        different manufacturing process.
      </>
    ),
  },
  PartTooBig: {
    title: 'Part is too big for this manufacturing process',
    text: (
      <>
        Please select a different manufacturing process or request a manual quote and our experts
        will help.
      </>
    ),
  },
  PartTooBigRetryUnits: {
    title: 'Part is too big for this manufacturing process',
    text: (
      <>
        Re-upload your part file to switch from inches to mm. You can also try a different
        manufacturing process or request a manual quote and our experts will help.
      </>
    ),
  },
  Generic: {
    title: 'Your part requires a manual quote',
    text: (
      <>
        We’ll need to review your specs before we can quote. Continue for a manual quote or upload
        a new file and adjust your specs for an instant quote.
      </>
    ),
  },
  InvalidFileType: {
    title: 'Your process requires a different file type',
    text: (
      <>
        <p>
          Please upload a different file type or request a manual quote and our experts will help.
        </p>
        <ul className="mb-0">
          <li>
            For DLS, FDM, MJF &amp; SLA we support:{' '}
            <FormattedExtensions extensions={ADDITIVE_PART_FILE_EXTENSIONS_PORTAL} />
          </li>
          <li>
            For Cast Urethane, CNC &amp; Injection Molding:{' '}
            <FormattedExtensions extensions={TRADITIONAL_PART_FILE_EXTENSIONS_PORTAL} />
          </li>
        </ul>
      </>
    ),
  },
  ManufacturabilityErrors: {
    title: 'Manual quote required due to design error(s)',
    text: (
      <>
        This part requires a traditional quote because of errors identified in automated checks.
        We will review your submission and contact you with a quote. If you want an instant quote,
        try adjusting the part file to eliminate errors.
      </>
    ),
  },
  ManufacturabilityWarnings: {
    title: 'Automated check warning(s)',
    text: <>This part has design issues that may impact quality and/or price.</>,
    warning: true,
  },
};

export default AutoquoteErrorBox;
