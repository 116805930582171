/* eslint-disable react/display-name, react/prop-types */
import { kebabCase, startCase } from 'lodash';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { Button, Icon, Link, Table } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import useSalesforceSybridgeInstanceFeatureFlag from 'fr-shared/hooks/useSalesforceSybridgeInstanceFeatureFlag';

import { DataTable } from '../components/DataTable';
import ContactConvertCartToCRModal from './ContactConvertCartToCRModal';
import PortalCartPreviewModal from './PortalCartPreviewModal';

export const getSalesforceLink = (id, sybridgeSalesforceInstanceEnabled) => {
  if (sybridgeSalesforceInstanceEnabled) {
    return process.env.MIX_ENV === 'prod'
      ? `https://sybridgetech.lightning.force.com/lightning/r/Opportunity/${id}/view`
      : `https://sybridgetech--frclone.sandbox.lightning.force.com/lightning/r/Opportunity/${id}/view`;
  } else {
    return process.env.MIX_ENV === 'prod'
      ? `https://fastradius.lightning.force.com/lightning/r/Opportunity/${id}/view`
      : `https://fastradius--frclone.lightning.force.com/lightning/r/Opportunity/${id}/view`;
  }
};

export const ContactCarts = () => {
  const [displayConvertToCRModal, setDisplayConvertToCRModal] = useState(false);
  const [cartID, setCartID] = useState(null);
  const { user_id: userId } = useParams();
  const { user } = useContext(UserContext);
  const sybridgeSalesforceInstanceEnabled = useSalesforceSybridgeInstanceFeatureFlag();

  const history = useHistory();

  const closeModal = () => {
    setCartID(null);
    setDisplayConvertToCRModal(false);
  };

  return (
    <>
      {displayConvertToCRModal && (
        <ContactConvertCartToCRModal
          isOpen={displayConvertToCRModal}
          cartID={cartID}
          toggle={closeModal}
          userID={userId}
        />
      )}
      <DataTable
        title="Portal Carts"
        description="The light blue row is the user's current unsubmitted cart"
        className="-flex"
        d
        url="/carts"
        params={{ user_id: userId }}
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              style: {
                background: rowInfo.row.submitted_at ? 'inherit' : '#def1ff',
              },
            };
          }
          return {};
        }}
        columns={[
          {
            id: 'expander',
            sortable: false,
            width: 45,
            expander: true,
            Expander: ({ isExpanded, ...rest }) => (
              <div className="flex justify-content-between align-items-center">
                {rest.original.line_items && rest.original.line_items.length > 0 && (
                  <Button size="sm" color="secondary">
                    <i className={`fas fa-chevron-${isExpanded ? 'up' : 'down'}`} />
                  </Button>
                )}
              </div>
            ),
          },
          {
            Header: 'Cart ID',
            id: 'cart_id_modal',
            accessor: d => (
              <PortalCartPreviewModal
                cart={d}
                onSubmit={() => {
                  if (d.submitted_at === null && user.canConvertCustomerCartToCostingRequest) {
                    setCartID(d.id);
                    setDisplayConvertToCRModal(true);
                  } else {
                    history.push(`/admin/costing_requests/${d.costing_request_id}`);
                  }
                }}
              />
            ),
            width: 80,
          },
          {
            Header: 'Opportunity ID',
            id: 'salesforce_opportunity_id',
            accessor: d =>
              d.salesforce_opportunity_id && (
                <Link
                  to={{
                    pathname: getSalesforceLink(
                      d.salesforce_opportunity_id,
                      sybridgeSalesforceInstanceEnabled
                    ),
                  }}
                  openNewWindow
                >
                  {d.salesforce_opportunity_id}
                </Link>
              ),
          },
          {
            Header: 'Costing Request',
            id: 'convert_to_cr',
            accessor: d =>
              d.submitted_at === null && user.canConvertCustomerCartToCostingRequest ? (
                <Button
                  onClick={() => {
                    setCartID(d.id);
                    setDisplayConvertToCRModal(true);
                  }}
                >
                  Convert to CR
                </Button>
              ) : (
                d.costing_request_id && (
                  <Link
                    dataTestId="cart-table-costing-request-id-link"
                    to={`/admin/costing_requests/${d.costing_request_id}`}
                  >
                    CR-{d.costing_request_id}
                  </Link>
                )
              ),
            width: 160,
          },
          {
            Header: '# of items',
            id: 'items',
            width: 100,
            accessor: d => d.line_items.length,
          },
          {
            Header: 'Autoquoteable',
            id: 'can_checkout',
            accessor: d =>
              !d.is_manual ? (
                <Icon name="check" className="text-success text-lg" />
              ) : (
                <Icon
                  name="times"
                  className="text-error text-lg"
                  tooltip={d.is_manual_reason ? startCase(d.is_manual_reason) : 'Manual/no price'}
                />
              ),
          },
          {
            Header: 'Total Price',
            id: 'total_price',
            accessor: d => (d.total_price ? '$' + d.total_price?.amount : null),
          },
          {
            Header: 'Created',
            id: 'inserted_at',
            accessor: d =>
              d.inserted_at ? moment(d.inserted_at).format('MM/DD/YY, h:mm A') : null,
            width: 160,
          },
          {
            Header: 'Submitted',
            id: 'submitted_at',
            accessor: d =>
              d.submitted_at ? moment(d.submitted_at).format('MM/DD/YY, h:mm A') : null,
            width: 160,
          },
        ]}
        SubComponent={row => {
          return (
            <Table
              data={row.original.line_items}
              defaultPageSize={row.original.line_items.length}
              showPagination={false}
              className="-no-shadow"
              columns={[
                {
                  Header: 'File Name',
                  id: 'name',
                  sortable: false,
                  Cell: row => {
                    return (
                      <Link
                        openNewWindow
                        to={{
                          pathname: `https://api.fastradius.com/api/v1/part_file_revisions/${row.original.part?.current_revision?.id}`,
                        }}
                      >
                        {row.original.part?.name}
                      </Link>
                    );
                  },
                },
                {
                  Header: 'MFG Process',
                  accessor: 'manufacturing_process.name',
                },
                { Header: 'Material', accessor: 'material.name' },
                {
                  Header: 'Color',
                  id: 'color',
                  accessor: d => (
                    <span className={kebabCase(d.color && d.color.name)}>
                      {d.color && d.color.name}
                    </span>
                  ),
                },
                { Header: 'Inspection Type', accessor: 'inspection_type.name' },
                {
                  Header: 'Autoquotable',
                  id: 'autoquotable',
                  accessor: d =>
                    !d.is_manual ? (
                      <Icon name="check" className="text-success" />
                    ) : (
                      <Icon
                        name="times"
                        className="text-error"
                        tooltip={d.autoquotable_errors.join(', ')}
                      />
                    ),
                },
                { Header: 'Quantity', accessor: 'quantity' },
                {
                  Header: 'Last modified',
                  id: 'updated_at',
                  accessor: d =>
                    d.updated_at ? moment(d.updated_at).format('MM/DD/YY, h:mm A') : null,
                },
                {
                  Header: 'Unit Price',
                  id: 'unit_price',
                  accessor: d => d.unit_price?.formatted,
                },
                {
                  Header: 'Total Price',
                  id: 'total_price',
                  accessor: d => d.total_price?.formatted,
                },
              ]}
            />
          );
        }}
      />
    </>
  );
};
