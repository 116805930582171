import React from 'react';

import { FormSelect } from 'fr-shared/components';
import { COOLING_OPTIONS } from 'fr-shared/lib/work_orders';

const CoolingFormField = props => {
  return (
    <FormSelect
      label="Cooling"
      name="cooling"
      optionList={COOLING_OPTIONS}
      valueAccessor={option => option}
      nameAccessor={option => option}
      {...props}
    />
  );
};
export default CoolingFormField;
