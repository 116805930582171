import PropTypes from 'prop-types';
import React from 'react';

import { IconFont } from 'fr-shared/components';

import { Alert, Link, classNames } from 'portal/components';

import styles from './DetailsHeader.module.css';

export const DetailsHeader = ({ backLink, backText, children, alert, readonly = false }) => (
  <div className={classNames([styles.Root, 'flex flex-column pb-4', readonly && 'relative'])}>
    {alert && (
      <Alert color="warning">
        <IconFont name="error-triangle" /> {alert}
      </Alert>
    )}
    <div className="mb-3">
      <Link className="font-size-sm text-decoration-none" to={readonly ? '#' : backLink}>
        <IconFont name="chevron-left" right />
        {backText}
      </Link>
    </div>
    <div className="row">{children}</div>
  </div>
);

DetailsHeader.propTypes = {
  alert: PropTypes.string,
  backLink: PropTypes.string,
  backText: PropTypes.string,
  children: PropTypes.node,
  readonly: PropTypes.bool,
};

const MainCell = ({ children }) => <div className="col-12 col-lg-8">{children}</div>;

MainCell.propTypes = {
  children: PropTypes.node,
};

const EndCell = ({ className, children }) => (
  <div className={classNames([styles.End, 'col-12 col-lg-4', className])}>{children}</div>
);

EndCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

DetailsHeader.MainCell = MainCell;
DetailsHeader.EndCell = EndCell;

export default DetailsHeader;
