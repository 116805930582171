import PropTypes from 'prop-types';
import React from 'react';

import { usePartViewer } from 'fr-shared/hooks';

import AutomatedChecks from 'portal/components/AutomatedChecks/AutomatedChecks';

import styles from './DesignInsights.module.css';

export const DesignInsights = ({ insight }) => {
  const { visibleMeshes, handleToggleMesh } = usePartViewer();

  const { part, manufacturing_process: manufacturingProcess } = insight;

  return (
    <AutomatedChecks
      className={styles.AutomatedChecks}
      headerClassName={styles.AutomatedChecksHeader}
      part={part}
      manufacturingProcess={manufacturingProcess}
      onToggleMesh={handleToggleMesh}
      hideToggleDetails={true}
      visibleMeshes={visibleMeshes}
    />
  );
};

DesignInsights.propTypes = {
  insight: PropTypes.object,
};

export default DesignInsights;
