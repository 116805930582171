import React, { useState } from 'react';

import { Badge, IconFont, classNames } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { keyboardDown } from 'fr-shared/utils';

import { groupNonCriticalDfmChecks } from 'portal/lib/manufacturability_check';

import DfmCheck from './DfmCheck/DfmCheck';
import DfmNonCriticalChecks from './DfmCheck/DfmNonCriticalChecks';
import styles from './DfmCheckSection.module.css';

interface Props {
  /** The color "type" of the checks */
  color: string;
  /** The description copy for the section */
  description?: string;
  /** The list of DFM checks to display */
  dfmChecks: ManufacturabilityCheck[];
  /** Disables events, such as clicks events and keyboard presses. on selected `DfmCheck`s. */
  disableEventsOnSelected?: boolean;
  /** The name of the section */
  name: string;
  /** Which DFM check is selected */
  selectedDfmCheck: ManufacturabilityCheck;
  /** Event handler for selecting DFM check */
  onSelectDfmCheck: (dfmCheck: ManufacturabilityCheck) => void;
}

const DfmCheckSection = ({
  color,
  description,
  dfmChecks,
  disableEventsOnSelected = false,
  name,
  selectedDfmCheck,
  onSelectDfmCheck,
}: Props) => {
  const userAnalytics = useUserAnalyticsContext();
  // We want to open the section initially if we have a selected DFM checked (user goes directly to /:check_id)
  const initialOpen =
    selectedDfmCheck && dfmChecks.some(check => check.id === selectedDfmCheck.id);
  const isNonCritical = name === 'Non-critical';
  const groupedNonCriticalDfmChecks = groupNonCriticalDfmChecks(
    dfmChecks
  ) as GroupedNonCriticalManufacturabilityChecks;

  const [open, setOpen] = useState(initialOpen);

  const toggleOpen = () => {
    setOpen(open => {
      const newOpen = !open;
      userAnalytics.track('Studio DFM Checks - Expand/Collapse', { name, open: newOpen });
      return newOpen;
    });
  };

  if (!dfmChecks) return null;

  const isCollapsible = dfmChecks.length > 0;

  return (
    <div className="dfm-check-section">
      <div
        className={classNames([styles.DfmCheckSection, isCollapsible && styles.collapsible])}
        onClick={isCollapsible ? toggleOpen : null}
        role="button"
        tabIndex={0}
        onKeyDown={evt => isCollapsible && keyboardDown(evt, 'Enter', toggleOpen)}
      >
        <div className="flex align-items-center select-none">
          <div className="flex align-items-center">
            <Badge pill outline color={color}>
              {dfmChecks.length}
            </Badge>
            <h5 className="ml-1 text-white">{name}</h5>
          </div>
          {isCollapsible && (
            <div className="ml-auto">
              <IconFont name={open ? 'minus' : 'plus'} className="font-size-xl" />
            </div>
          )}
        </div>
        {description && isCollapsible && (
          <p className="select-none text-coolGray-300 mt-3 pr-3">{description}</p>
        )}
      </div>
      {!isNonCritical &&
        dfmChecks.map(dfmCheck => (
          <DfmCheck
            key={dfmCheck.id}
            dfmCheck={dfmCheck}
            disableEventsWhenSelected={disableEventsOnSelected}
            onSelectDfmCheck={() => {
              userAnalytics.track('Studio DFM Checks - View Check Details', {
                name: dfmCheck.label,
              });
              onSelectDfmCheck(dfmCheck);
            }}
            open={open}
            selected={dfmCheck.id === selectedDfmCheck?.id}
          />
        ))}
      {/* Non critical DFM checks are grouped by cost & design. . This component is essentially two of the above component since the non critical checks are seperated into two arrays */}
      {isNonCritical && (
        <DfmNonCriticalChecks
          disableEventsOnSelected={disableEventsOnSelected}
          groupedNonCriticalDfmChecks={groupedNonCriticalDfmChecks}
          onSelectDfmCheck={(dfmCheck: ManufacturabilityCheck) => {
            userAnalytics.track('Studio DFM Checks - View Check Details', {
              name: dfmCheck.label,
            });
            onSelectDfmCheck(dfmCheck);
          }}
          open={open}
          selectedDfmCheckId={selectedDfmCheck?.id}
        />
      )}
    </div>
  );
};

export default DfmCheckSection;
