/* eslint-disable react/display-name,react/prop-types */
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NavLink, Route, Switch, useParams } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Breadcrumb, Button, Card, Helmet, Icon, Link } from 'fr-shared/components';
import useSalesforceSybridgeInstanceFeatureFlag from 'fr-shared/hooks/useSalesforceSybridgeInstanceFeatureFlag';

import { CustomerContacts } from './customer/CustomerContacts';
import { CustomerCostingRequests } from './customer/CustomerCostingRequests';
import { CustomerOrders } from './customer/CustomerOrders';
import { CustomerQuotes } from './customer/CustomerQuotes';
import styles from './show.module.css';

const date = (date, format = 'MMM DD YYYY, h:mm a') => {
  return date ? moment(date).format(format) : '-';
};

export const getSalesforceLink = (id, sybridgeSalesforceInstanceEnabled) => {
  if (sybridgeSalesforceInstanceEnabled) {
    return process.env.MIX_ENV === 'prod'
      ? `https://sybridgetech.lightning.force.com/lightning/r/Account/${id}/view`
      : `https://sybridgetech--frclone.sandbox.lightning.force.com/lightning/r/Account/${id}/view`;
  } else {
    return process.env.MIX_ENV === 'prod'
      ? `https://fastradius.lightning.force.com/lightning/r/Account/${id}/view`
      : `https://fastradius--frclone.lightning.force.com/lightning/r/Account/${id}/view`;
  }
};

const CustomerShow = ({ match }) => {
  const sybridgeSalesforceInstanceEnabled = useSalesforceSybridgeInstanceFeatureFlag();
  const [organization, setOrganization] = useState({});
  const customerId = match.params.id;
  const { id } = useParams();

  useEffect(() => {
    getOrganization(customerId);
  }, [customerId]);

  const getOrganization = id => {
    api.get(`/organizations/${id}`).then(res => {
      setOrganization(res.data);
    });
  };

  if (isEmpty(organization)) return null;

  const { name } = organization;

  return (
    <>
      <Helmet title={name} />
      <Breadcrumb to="/admin/customers">Customers</Breadcrumb>
      <Breadcrumb to={`/admin/customers/${id}`}>{name}</Breadcrumb>

      <div className="page-header">
        <div className="container">
          <div>
            <h2 className="mb-3">{name}</h2>
            <div className="font-size-md">
              {organization && organization.sales_contact && (
                <div>
                  Salesforce Owner:{' '}
                  <Link
                    to={{
                      pathname: getSalesforceLink(
                        organization.sales_contact.salesforce_id,
                        sybridgeSalesforceInstanceEnabled
                      ),
                    }}
                    openNewWindow
                  >
                    <strong>{organization.sales_contact.name}</strong>
                  </Link>
                </div>
              )}
              {organization && organization.salesforce_id && (
                <div>
                  Salesforce ID:{' '}
                  <Link
                    to={{
                      pathname: getSalesforceLink(
                        organization.salesforce_id,
                        sybridgeSalesforceInstanceEnabled
                      ),
                    }}
                    openNewWindow
                  >
                    {organization.salesforce_id}
                  </Link>
                </div>
              )}
              {organization && (
                <div>
                  Created: <strong>{date(organization.inserted_at)}</strong> &mdash; Last updated:{' '}
                  <strong>{date(organization.updated_at)}</strong>
                </div>
              )}
            </div>
          </div>

          <div className="page-actions">
            <Button to="/admin/customers" color="secondary">
              Back
            </Button>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <div className="flex gap-4 flex-col md:flex-row">
          <div className="min-w-[250px]">
            <Card className="position-sticky mb-5">
              <NavLink
                exact={true}
                activeClassName={styles.active}
                className={styles.NavLink}
                to={`/admin/customers/${id}/contacts`}
              >
                Contacts
              </NavLink>
              <NavLink
                exact={true}
                activeClassName={styles.active}
                className={styles.NavLink}
                to={`/admin/customers/${id}/costing_requests`}
              >
                Costing Requests
              </NavLink>
              <NavLink
                exact={true}
                activeClassName={styles.active}
                className={styles.NavLink}
                to={`/admin/customers/${id}/quotes`}
              >
                Quotes
              </NavLink>
              <NavLink
                exact={true}
                activeClassName={styles.active}
                className={styles.NavLink}
                to={`/admin/customers/${id}/orders`}
              >
                Orders
              </NavLink>
              <Link
                className={styles.NavLink}
                to={{
                  pathname: getSalesforceLink(
                    organization.salesforce_id,
                    sybridgeSalesforceInstanceEnabled
                  ),
                }}
                openNewWindow
              >
                Salesforce Account
                <Icon name="external-link" className="ml-1" />
              </Link>
            </Card>
          </div>

          <div className="flex-1 overflow-x-auto">
            <Switch>
              <Route
                exact
                path="/admin/customers/:id/contacts"
                render={() => <CustomerContacts organization={organization} />}
              />
              <Route
                exact
                path="/admin/customers/:id/costing_requests"
                render={() => <CustomerCostingRequests params={{ customer_id: id }} />}
              />
              <Route
                exact
                path="/admin/customers/:id/quotes"
                render={() => <CustomerQuotes params={{ customer_id: id }} />}
              />
              <Route
                exact
                path="/admin/customers/:id/orders"
                render={() => <CustomerOrders params={{ customer_id: id }} />}
              />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerShow;
