import { Form, useFormikContext, withFormik } from 'formik';
import { get, head } from 'lodash';
import React from 'react';
import * as Yup from 'yup';

import { api } from 'fr-shared/api';
import { Auth0 } from 'fr-shared/auth';
import { IconFont } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';

import { Alert, Button, Card, FormField, Link } from 'portal/components';

import GoogleIcon from '../components/GoogleIcon';
import MicrosoftIcon from '../components/MicrosoftIcon';

const auth0 = new Auth0();

const LogIn = () => {
  const formik = useFormikContext();
  const userAnalytics = useUserAnalyticsContext();

  const logLoginMethod = (type = 'Email') => userAnalytics.track('Login Method', { type });
  const loginWithGoogle = () => {
    logLoginMethod('Google');
    auth0.loginWithGoogle();
  };
  const loginWithMicrosoft = () => {
    logLoginMethod('Microsoft');
    auth0.loginWithMicrosoft();
  };
  const loginWithEmail = () => logLoginMethod();
  const handleCreateAcct = () => userAnalytics.track('Sign Up', { valid: true });

  return (
    <Card size="lg" dataTestId="login-form-fields">
      {formik.errors.server && (
        <Alert data-testid="error-alert" className="mb-5" color="danger">
          <IconFont name="error-triangle" className="mr-2" right />
          {formik.errors.server}
        </Alert>
      )}
      <Form noValidate>
        <h1 className="h6 text-light mb-4">Let&apos;s log in</h1>

        <FormField
          name="username"
          label="Your business email"
          required={true}
          floatingLabel={true}
          onlyShowErrorIfTouched={true}
          className="my-3"
        />
        <FormField
          name="password"
          type="password"
          label="Your password"
          floatingLabel={true}
          onlyShowErrorIfTouched={true}
          required={true}
          className="mb-3"
        />

        <div className="flex flex-column pt-2 mb-2">
          <Button
            type="submit"
            className="mb-3"
            size="lg"
            data-test="submit"
            loading={formik.isSubmitting}
            onClick={loginWithEmail}
          >
            Log in
          </Button>
        </div>

        <div className="text-lined">Or log in with</div>

        <div className="flex justify-content-center py-4">
          <Button className="btn-icon" onClick={loginWithGoogle} outline>
            <GoogleIcon />
          </Button>
          <Button className="btn-icon" onClick={loginWithMicrosoft} outline>
            <MicrosoftIcon />
          </Button>
        </div>

        <div className="my-1 text-center">
          <div className="mb-2">
            <span>No account yet? &nbsp;</span>
            <Link
              to="/create-account"
              onClick={handleCreateAcct}
              dataTestId="login-create-account"
            >
              Create account
            </Link>
          </div>
          <div>
            <Link to="/forgot">Forgotten your password?</Link>
          </div>
        </div>
      </Form>
    </Card>
  );
};

export const RawSchema = {
  username: Yup.string().label('Email').required(),
  password: Yup.string().label('Password').required(),
};

export const Schema = Yup.object().shape(RawSchema);

export default withFormik({
  handleSubmit: ({ username, password }, formik) => {
    api
      .post('/login', { username, password })
      .then(() => {
        // For attribution reasons, we also want to submit a hidden Marketo form on login
        const marketoForm = head(window.MktoForms2?.allForms());
        if (marketoForm) {
          marketoForm?.addHiddenFields({ Email: username });
          marketoForm?.submit();
        }

        // hard redirect so we get the session cookie from backend
        window.location = '/';
      })
      .catch(err => {
        const errors = get(
          err,
          'response.data.errors.0',
          'Something went wrong. Please refresh and try again.'
        );

        formik.setErrors({ server: errors });
        formik.setSubmitting(false);
      });
  },
  mapPropsToValues: ({ username }) => ({
    // default values of form
    username,
    password: '',
  }),
  validationSchema: Schema,
  validateOnBlur: true,
  validateOnChange: false,
})(LogIn);
