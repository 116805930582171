import { flatMap, head, isEmpty, omit, omitBy, pickBy, uniqBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { api } from 'fr-shared/api';
import { COUNTRIES } from 'fr-shared/lib/countries';
import { QUOTE_STATES } from 'fr-shared/lib/quotes';
import { SHIPPING_PAYMENT_TYPES } from 'fr-shared/lib/shipping';

export const transformLineItems = (lineItems, leadTimes, costingRequest) => {
  return flatMap(lineItems, lineItem => {
    return lineItem.supplier_costs.reduce((acc, supplierCost) => {
      supplierCost.part_quantities.forEach(pq => {
        if (pq.per_unit_cost && pq.quantity) {
          const buildPackage = lineItem.build_package;
          const process = buildPackage.processes[0];
          const offered_lead_time = getOfferedLeadTime(process, pq, supplierCost, leadTimes);

          const newLineItem = {
            ...pq,
            ...lineItem,
            adjustments: [],
            build_package_id: buildPackage.id,
            color: process.color,
            customer: costingRequest.customer,
            customer_contact: costingRequest.customer_contact,
            customer_contact_id: costingRequest.customer_contact_id,
            customer_id: costingRequest.customer_id,
            finish: process.finish,
            id: null,
            inspection_type: process.inspection_type,
            layer_thickness: process.layer_thickness,
            line_item_notes: lineItem.notes,
            manufacturing_process: process.manufacturing_process,
            margin_percentage: 50,
            material: process.material,
            notes: supplierCost.notes,
            offered_lead_time,
            supplier_cost_part_quantity_id: pq.id,
            supplier_location: supplierCost.location,
            unit_cost: pq.per_unit_cost,
            uuid: uuidv4(),
          };
          acc.push(newLineItem);
        }
      });
      return acc;
    }, []);
  });
};

const getOfferedLeadTime = (process, partQuantity, supplierCost, leadTimes) => {
  if (partQuantity.lead_time != null) return partQuantity.lead_time;

  const leadTimeWithLocation = leadTimes?.find(
    lt =>
      lt.manufacturing_process_id === process.manufacturing_process.id &&
      lt.location_id === supplierCost.location_id
  );
  if (leadTimeWithLocation) return leadTimeWithLocation?.min_days;

  return leadTimes?.find(
    lt =>
      lt.manufacturing_process_id === process.manufacturing_process.id && lt.location_id == null
  )?.min_days;
};

export const transformCostingRequestSearchResults = costingRequests => {
  return uniqBy(costingRequests, 'opportunity_id');
};

export const transformQuoteToSave = data => {
  // remove empty addresses before we send
  return omitBy(data, (value, key) => {
    return (key === 'billing_address' || key === 'shipping_address') && isEmpty(pickBy(value));
  });
};

export const transformQuoteFromLoad = data => ({
  ...data,
  /*
   * This converts this boolean field into a string representation because it is shown in the
   * UI as a pair of radio buttons.
   *
   * See assets/src/views/admin/quotes/components/QuoteFormLineCost.js
   *
   * HTML forms don't handle setting booleans well, even with Formik. As of this writing, the
   * `transformQuoteToSave` function doesn't need to change this value back from a string
   * because the backend accepts and coerces boolean-like strings appropriately.
   */
  menu_quote: typeof data.menu_quote === 'boolean' ? String(data.menu_quote) : data.menu_quote,
});

export const transformDuplicateQuote = data => ({
  ...data,
  inserted_at: null,
});

export const transformErrors = async (errors, values) => {
  if (errors?.completed_at) {
    try {
      const res = await api.get('/quotes', {
        params: { opportunity_id: values.opportunity_id, state: QUOTE_STATES.Draft },
      });
      return { completed_at_quote_id: head(res.data).id };
    } catch {
      return errors;
    }
  }

  return errors;
};

export const transformQuoteToOrder = quote => {
  const omittedFields = ['id', 'inserted_at', 'is_default'];

  const billingAddress = omit(
    quote.billing_address || { country: COUNTRIES.UNITED_STATES },
    omittedFields
  );
  const shippingAddress = omit(
    quote.shipping_address || { country: COUNTRIES.UNITED_STATES },
    omittedFields
  );

  return {
    billing_address: billingAddress,
    blind_ship: quote.blind_ship,
    comments: quote.notes,
    customer_contact: quote.customer_contact,
    customer_contact_id: quote.customer_contact_id,
    deal_id: quote.deal_id,
    payment_type: 'Customer PO',
    po_documents: [],
    shipping_address: shippingAddress,
    shipping_payment_type: isEmpty(quote.shipping_payment_type)
      ? SHIPPING_PAYMENT_TYPES.InvoiceToCustomer
      : quote.shipping_payment_type,
    shipping_method: quote.shipping_method,
    type: 'Production',
  };
};

export const quoteToDisplayID = quote => {
  return `${quote.id}_v${quote.version}`;
};
