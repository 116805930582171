import React from 'react';

export const MicrosoftIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.4057 11.4038H0V0H11.4057V11.4038Z" fill="#F1511B" />
      <path d="M23.9999 11.4038H12.5933V0H23.9989V11.4038H23.9999Z" fill="#80CC28" />
      <path d="M11.4057 24H0V12.5962H11.4057V24Z" fill="#00ADEF" />
      <path d="M23.9999 24H12.5933V12.5962H23.9989V24H23.9999Z" fill="#FBBC09" />
    </svg>
  );
};

export default MicrosoftIcon;
