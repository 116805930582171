import React from 'react';

import {
  TierPriceInfo as OriginalTierPriceInfo,
  TierPriceInfoProps,
} from 'portal/pages/part_config/components/TierPriceContainer';

import { LeadTimeFields } from '..';
import { LeadTimeFieldsEl } from './TierPriceCard';

const TierPriceInfo = ({
  autoquoteTier,
  setLabel = true,
  leadTimeFields,
}: TierPriceInfoProps & { leadTimeFields?: LeadTimeFields }) => {
  if (!autoquoteTier) {
    return null;
  }

  return (
    <>
      <div className="compare-component-header cost-lead-header print:mb-1">
        <h1 className="hidden print:block print:text-xl print:pb-1 print:leading-5 text-black">
          Cost & lead times{' '}
        </h1>
      </div>
      {leadTimeFields ? <LeadTimeFieldsEl leadTimeFields={leadTimeFields} /> : null}

      <OriginalTierPriceInfo autoquoteTier={autoquoteTier} setLabel={setLabel} />
    </>
  );
};

export default TierPriceInfo;
