import React from 'react';

import { Button, Modal, classNames } from 'fr-shared/components';

import styles from './DfmCheckModal.module.css';

interface DfmCheckModalProps {
  action: JSX.Element;
  onActionHandler: () => void;
  onContinue?: () => void;
  onSeeIssues: () => void;
  errorCheckCount: number;
  warningCheckCount: number;
}

const DFM_MODAL_COPY = {
  CRITICAL: {
    title: 'Your part has critical issues',
    footer:
      'We recommend fixing these manufacturability issues before continuing with your part.',
  },
  NONCRITICAL: {
    title: 'Your part has non-critical issues',
    footer:
      'We recommend fixing these manufacturability issues before continuing with your part.',
  },
  BOTH: {
    title: 'Your part has manufacturability issues',
    footer:
      'We’ve found critical issues we recommend you fix. And non-critical issues we recommend you review. These issues could make it difficult to adequately manufacture your part.',
  },
};

export const DfmCheckModal = ({
  onActionHandler,
  onContinue = () => {},
  onSeeIssues,
  errorCheckCount,
  warningCheckCount,
  action,
}: DfmCheckModalProps) => {
  if (errorCheckCount <= 0 && warningCheckCount <= 0) {
    return null;
  }

  let modalCopy = DFM_MODAL_COPY.BOTH;
  if (errorCheckCount === 0 && warningCheckCount) {
    modalCopy = DFM_MODAL_COPY.NONCRITICAL;
  } else if (errorCheckCount && warningCheckCount === 0) {
    modalCopy = DFM_MODAL_COPY.CRITICAL;
  }

  return (
    <Modal action={action} onActionHandler={onActionHandler} className="modal-wide">
      {({ toggle }: { toggle: any }) => (
        <>
          <Modal.Header
            title={modalCopy.title}
            textCentered={false}
            onClose={toggle}
            showCloseButton={false}
          >
            <div className="border-coolGray-700 border-b-0 border-t border-solid mt-1"></div>
          </Modal.Header>
          <Modal.Body className={classNames(['d-flex flex-row', styles.Body])}>
            {!!errorCheckCount && (
              <DfmBadge
                dataTestId="dfm-modal-error-badge"
                level="error"
                count={errorCheckCount}
              />
            )}
            {!!warningCheckCount && (
              <DfmBadge
                dataTestId="dfm-modal-warning-badge"
                level="warning"
                count={warningCheckCount}
              />
            )}
          </Modal.Body>
          <Modal.Footer className="flex-column">
            <p className="text-muted font-size-base mx-0">{modalCopy.footer}</p>
            <div className="d-flex flex-row gap-2 w-100">
              <Button outline={true} className="font-size-base flex-1" onClick={onSeeIssues}>
                See all issues
              </Button>
              <Button
                className="font-size-base border-error-300 text-error-300 flex-1"
                dataTestId="dfm-check-modal-continue-btn"
                onClick={() => {
                  onContinue();
                  toggle();
                }}
                outline={true}
              >
                Continue anyway
              </Button>
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

interface DfmBadgeProps {
  level: 'warning' | 'error';
  count: number;
  dataTestId?: string;
}

const DfmBadge = ({ level, count, dataTestId }: DfmBadgeProps) => {
  return (
    <div
      className={classNames([
        'flex-1 p-2 font-size-base d-flex flex-row align-items-center',
        styles.BadgeContainer,
      ])}
      data-testid={dataTestId}
    >
      <span className={classNames(['mr-2', styles.Badge, styles[`Badge--${level}`]])}>
        {count}
      </span>
      {level === 'error' ? 'Critical' : 'Non-critical'}
    </div>
  );
};

export default DfmCheckModal;
