import { sortBy, uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { classNames } from 'fr-shared/utils/classNames';

const Select = ({
  children,
  className,
  dataTestId,
  hasBlankOption = true,
  id,
  label,
  labelClassName,
  name,
  nameAccessor = option => option.name,
  onBlur,
  onChange,
  optionList = [],
  readonly,
  selectAttrs = {},
  selectClassName,
  sorted = true,
  value,
  valueAccessor = option => option.id,
  disabledAccessor = option => !!option.disabled,
}) => {
  const options = sorted ? sortBy(optionList, nameAccessor) : optionList;

  return (
    <span className={className}>
      {label && (
        <label className={labelClassName} htmlFor={name}>
          {label}
        </label>
      )}
      <select
        data-testid={dataTestId}
        className={classNames(['custom-select', selectClassName])}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        disabled={readonly}
        {...selectAttrs}
      >
        {hasBlankOption && <option value="" />}
        {children && children}
        {options.map(option => (
          <option
            key={`${uniqueId()}-${valueAccessor(option)}`}
            value={valueAccessor(option)}
            disabled={disabledAccessor(option)}
          >
            {nameAccessor(option)}
          </option>
        ))}
      </select>
    </span>
  );
};

Select.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  disabledAccessor: PropTypes.func,
  hasBlankOption: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.node,
  labelClassName: PropTypes.string,
  name: PropTypes.string,
  nameAccessor: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  optionList: PropTypes.array,
  readonly: PropTypes.bool,
  selectClassName: PropTypes.string,
  selectAttrs: PropTypes.object,
  sorted: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  valueAccessor: PropTypes.func,
};

export default Select;
