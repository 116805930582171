import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, FormField, Modal } from 'fr-shared/components';
import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';

import {
  BuildCartFormField,
  BuildDensityFormField,
  BuildHeightFormField,
  BuildTimeFormField,
  CarbonLinkFormField,
  CassetteFormField,
  CoolingFormField,
  DetailingAgentFormField,
  FusingAgentFormField,
  HPPrintModeFormField,
  IrradianceFormField,
  MJFLinkFormField,
  MaterialAmountFormField,
  PowderConsumptionFormField,
  PrintIdFormField,
  PrinterFormField,
} from './fields';
import { hasProcess } from './utils/hasProcess';

const WorkOrderStageGateModal = ({ isOpen, toggle, onSubmit }) => {
  const formik = useFormikContext();
  const nextState = formik?.errors?.next_state?.[0];

  const handleSubmitNextState = () => {
    formik.setFieldValue('state', nextState);
    onSubmit();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      {nextState ? (
        <NextStateModalContent
          currentState={formik.values.state}
          nextState={nextState}
          onSubmit={handleSubmitNextState}
          onCancel={toggle}
        />
      ) : (
        <MissingFieldsModal
          nextState={formik.values.state}
          onSubmit={onSubmit}
          onCancel={toggle}
          errors={formik.errors}
          lineItems={formik.values.line_items}
        />
      )}
    </Modal>
  );
};

WorkOrderStageGateModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

const NextStateModalContent = ({ currentState, nextState, onSubmit, onCancel }) => {
  return (
    <>
      <Modal.Header title={`Move to ${nextState}`} onClose={onCancel} />
      <Modal.Body>
        The work order needs to move to {nextState} before it can progress to {currentState}.
      </Modal.Body>
      <Modal.Footer>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" onClick={onSubmit} dataTestId="move-to-button">
          Move to {nextState}
        </Button>
      </Modal.Footer>
    </>
  );
};

NextStateModalContent.propTypes = {
  currentState: PropTypes.string,
  nextState: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

const MissingFieldsModal = ({ nextState, onSubmit, onCancel, errors, lineItems }) => {
  const isMJF = hasProcess(lineItems, MANUFACTURING_PROCESSES.MJF);
  return (
    <>
      <Modal.Header title={`Move to ${nextState}`} onClose={onCancel} />
      <Modal.Body>
        <div className="mb-2">
          The following fields are required in order to move to {nextState}
        </div>
        {errors.build_density && <BuildDensityFormField showError={false} required={true} />}
        {errors.irradiance && <IrradianceFormField showError={false} required={true} />}
        {errors.build_height && <BuildHeightFormField showError={false} required={true} />}
        {errors.printer_id && <PrinterFormField showError={false} required={true} />}
        {errors.cooling && <CoolingFormField showError={false} required={true} />}
        {errors.hp_print_mode && <HPPrintModeFormField showError={false} required={true} />}
        {errors.build_time_minutes && <BuildTimeFormField showError={false} required={true} />}
        {errors.material_amount && (
          <MaterialAmountFormField showGrams={true} showError={false} required={true} />
        )}
        {errors.cassette && <CassetteFormField required={true} showError={false} />}
        {errors.carbon_link && (
          <CarbonLinkFormField showCarbonLink={true} showError={false} required={true} />
        )}
        {errors.mjf_link && (
          <MJFLinkFormField showMJFLink={true} showError={false} required={true} />
        )}
        {errors.layer_height && (
          <FormField name="layer_height" label="Layer Height" showError={false} required={true} />
        )}
        {errors.material_color && (
          <FormField
            name="material_color"
            label="Material Color"
            showError={false}
            required={true}
          />
        )}
        {errors.build_cart_id && <BuildCartFormField showError={false} required={true} />}
        {errors.print_id && (
          <PrintIdFormField showPrintIdLink={true} showError={false} required={true} />
        )}
        {errors.material_lot_number_a && (
          <FormField
            label={isMJF ? 'Powder (Lot A)' : 'Lot A'}
            name="material_lot_number_a"
            showError={false}
            required={true}
          />
        )}
        {errors.material_lot_number_b && (
          <FormField
            label={isMJF ? 'Fusing Agent (Lot B)' : 'Lot B'}
            name="material_lot_number_b"
            showError={false}
            required={true}
          />
        )}
        {errors.material_lot_number_c && (
          <FormField
            label={isMJF ? 'Detailing Agent (Lot C)' : 'Lot C'}
            name="material_lot_number_c"
            showError={false}
            required={true}
          />
        )}
        {errors.material_roll_id && (
          <FormField
            name="material_roll_id"
            label="Material Roll ID"
            showError={false}
            required={true}
          />
        )}
        {errors.support_roll_id && (
          <FormField
            name="support_roll_id"
            label="Support Roll ID"
            showError={false}
            required={true}
          />
        )}
        {errors.fusing_agent_consumption && (
          <FusingAgentFormField showError={false} required={true} />
        )}
        {errors.detailing_agent_consumption && (
          <DetailingAgentFormField showError={false} required={true} />
        )}
        {errors.powder_consumption && (
          <PowderConsumptionFormField showError={false} required={true} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" onClick={onSubmit} dataTestId="move-to-button">
          Move to {nextState}
        </Button>
      </Modal.Footer>
    </>
  );
};

MissingFieldsModal.propTypes = {
  errors: PropTypes.shape({
    build_density: PropTypes.array,
    irradiance: PropTypes.array,
    build_height: PropTypes.array,
    printer_id: PropTypes.array,
    cassette: PropTypes.array,
    cooling: PropTypes.array,
    hp_print_mode: PropTypes.array,
    build_time_minutes: PropTypes.array,
    material_amount: PropTypes.array,
    carbon_link: PropTypes.array,
    mjf_link: PropTypes.array,
    layer_height: PropTypes.array,
    material_color: PropTypes.array,
    build_cart_id: PropTypes.array,
    print_id: PropTypes.array,
    material_lot_number_a: PropTypes.array,
    material_lot_number_b: PropTypes.array,
    material_lot_number_c: PropTypes.array,
    material_roll_id: PropTypes.array,
    support_roll_id: PropTypes.array,
    fusing_agent_consumption: PropTypes.array,
    detailing_agent_consumption: PropTypes.array,
    powder_consumption: PropTypes.array,
  }),
  nextState: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  lineItems: PropTypes.array,
};

export default WorkOrderStageGateModal;
