import { connect } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { FormField } from 'fr-shared/components';

const FormNumber = ({
  min,
  max,
  step,
  formik,
  mask,
  name,
  onBlur,
  onChange,
  forceZero,
  ...formProps
}) => {
  const { setFieldValue, handleBlur, handleChange, values } = formik;

  const numberMask = createNumberMask({ allowDecimal: true, prefix: '' });

  const handleConstrainedChange = e => {
    const newVal = e.target.value;
    if (newVal && newVal !== '') {
      if (max !== undefined && newVal > max) return;
      if (min !== undefined && newVal < min) return;
    }
    onChange && onChange(newVal);
    handleChange(e);
  };

  const handleConstrainedBlur = e => {
    const inv = 1.0 / step;
    let fieldValue = get(values, name);
    if ((fieldValue && fieldValue !== '') || forceZero) {
      fieldValue = Math.round(fieldValue * inv) / inv;
    }
    setFieldValue(name, fieldValue);
    onBlur && onBlur();
    handleBlur(e);
  };

  return (
    <FormField
      {...formProps}
      name={name}
      type={!mask ? 'number' : null}
      inputProps={{ step: step ? step : 1 }}
      mask={mask && numberMask}
      onChange={handleConstrainedChange}
      onBlur={handleConstrainedBlur}
    />
  );
};

FormNumber.propTypes = {
  /* name of the input field */
  name: PropTypes.string,
  /* min value of input */
  min: PropTypes.number,
  /* max value of input */
  max: PropTypes.number,
  /* controls if the input should be masked with commas */
  mask: PropTypes.bool,
  /* value of the step for incrementing/decrementing */
  step: PropTypes.number,
  /* called before formik's onBlur handler */
  onBlur: PropTypes.func,
  /* called before formik's onChange handler with the updated value */
  onChange: PropTypes.func,
  /* forces the value to 0 if left empty */
  forceZero: PropTypes.bool,

  formik: PropTypes.object,
};

FormNumber.defaultProps = {
  step: 1,
};

export default connect(FormNumber);
