import { head } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Modal } from 'fr-shared/components';
import { AlertContext, useUserAnalyticsContext } from 'fr-shared/context';
import { getMarketoCookies } from 'fr-shared/lib/marketo';

import { Card, LoadingCircle } from 'portal/components';

import styles from './CreateAccount.module.css';
import SignupView from './components/SignupView';

const CreateAccount = () => {
  const history = useHistory();
  const userAnalytics = useUserAnalyticsContext();
  const { setAlert } = useContext(AlertContext);
  const [displayModal, setDisplayModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    userAnalytics.track('Create Account Page');
  }, [userAnalytics]);

  const handleExistingAccountError = () => {
    history.push('/login');
    setAlert({
      color: 'warning',
      message: 'An account with that email already exists. Please log in.',
      autoClose: false,
    });
  };

  const handleChangesetErrors = errors => {
    let message = Object.entries(errors)
      .map(field => `${field[0]}: ${field[1].join(' ')}`)
      .join(', ');
    history.push('/create-account/');
    setAlert({
      color: 'danger',
      message: message,
      autoClose: false,
    });
  };

  const handleUnexpectedError = () => {
    history.push('/create-account/');
    setAlert({
      color: 'danger',
      message: 'An unexpected error occurred. Refresh and try again.',
      autoClose: false,
    });
  };

  const handleSubmit = (values, _formik) => {
    const {
      email,
      password,
      first_name,
      last_name,
      phone_number,
      marketing_company_name,
      marketing_role,
      marketing_role_other,
    } = values;

    setSubmitLoading(true);

    api
      .post('/customer_portal/account', {
        user: {
          email,
          password,
          first_name,
          last_name,
          phone_number,
          marketing_company_name,
          marketing_role: marketing_role === 'Other' ? marketing_role_other : marketing_role,
        },
        marketo_attrs: getMarketoCookies(),
      })
      .then(() => {
        // For attribution reasons, we also want to submit a hidden Marketo form on create-account
        const marketoForm = head(window.MktoForms2?.allForms());
        if (marketoForm) {
          marketoForm?.addHiddenFields({
            Email: email,
            FirstName: first_name,
            LastName: last_name,
          });
          marketoForm?.submit();
        }

        window.location = '/';
      })
      .catch(err => {
        setSubmitLoading(false);
        let errors = err.response?.data?.errors;
        if (!errors) {
          handleUnexpectedError();
        } else if (!Array.isArray(errors)) {
          // Assume the error is a changeset error if it's not an array.
          handleChangesetErrors(errors);
        } else if (errors.length === 0) {
          handleUnexpectedError();
        } else if (errors[0] === 'account exists') {
          handleExistingAccountError();
        } else {
          handleUnexpectedError();
        }
      });
  };

  const SubmitLoader = () => {
    return (
      <Card size="lg">
        <div className="flex flex-column align-items-center justify-content-center">
          <LoadingCircle className="mb-3" />
          <h4>Creating your account...</h4>
        </div>
      </Card>
    );
  };

  return (
    <>
      <Helmet title="Instant Quote Online">
        <meta
          name="description"
          content="Get an instant quote online for your CNC Machining, Additive Manufacturing, Injection Molding, and Urethane Casting needs today."
        />
        <body className="theme-dark bg-dark" />
      </Helmet>

      {submitLoading ? (
        <SubmitLoader />
      ) : (
        <SignupView
          setDisplayModal={() => setDisplayModal(!displayModal)}
          handleSubmit={handleSubmit}
          submitLoading={submitLoading}
        />
      )}

      <Modal
        className="modal-wide-xl"
        isOpen={displayModal}
        toggle={() => setDisplayModal(!displayModal)}
      >
        <div className={styles.VideoContainer}>
          <iframe
            className={styles.Video}
            src="https://www.youtube.com/embed/IgyTU-IbpqY?autoplay=1"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Fast Radius OS Overview"
          />
        </div>
      </Modal>
    </>
  );
};

export default CreateAccount;
