import PropTypes from 'prop-types';
import React from 'react';

import { classNames } from 'portal/components';

import styles from './Badge.module.css';

export const Badge = ({ color = 'outline', className, pill, glow, outline, children }) => {
  return (
    <div
      data-testid="badge"
      className={classNames([
        'portal-badge',
        styles.Badge,
        outline && styles.outline,
        color && styles[color],
        glow && styles.glow,
        pill && styles.pill,
        className,
      ])}
    >
      {children}
    </div>
  );
};

Badge.propTypes = {
  color: PropTypes.oneOf([
    'error',
    'success',
    'warning',
    'outline',
    'light',
    'purple',
    'blue',
    'green',
    'teal',
  ]),
  pill: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  outline: PropTypes.bool,
  glow: PropTypes.bool,
};

export default Badge;
