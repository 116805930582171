import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { useUserAnalyticsContext } from 'fr-shared/context';
import { QUOTE_STATES } from 'fr-shared/lib/quotes';

import { LineItemCard, LineItemNotificationList, Money, PartImage } from 'portal/components';

const QuoteLineItemCard = ({ lineItem, quoteState, readonly = false }) => {
  const userAnalytics = useUserAnalyticsContext();

  const renderDfmCheck = useCallback(() => {
    // Don't render DFM checks for customer acceptance quotes - they currently don't have authorization to view parts they don't own
    if (quoteState === QUOTE_STATES.Submitted) {
      return null;
    }
    return (
      <LineItemNotificationList
        lineItem={lineItem}
        onPushStudio={() =>
          userAnalytics.track('Quote - Click DFM Checks', {
            state: quoteState,
            red_checks:
              lineItem?.failed_manufacturability_check_counts?.failed_error_checks_count > 0,
            yellow_checks:
              lineItem?.failed_manufacturability_check_counts?.failed_warning_checks_count > 0,
          })
        }
        redirectState={{
          manufacturing_process_id: lineItem?.manufacturing_process_id,
          material_id: lineItem?.material_id,
          color_id: lineItem?.color_id,
          quantity: lineItem?.quantity,
        }}
        readonly={readonly}
      />
    );
  }, [userAnalytics, lineItem, quoteState, readonly]);

  return (
    <LineItemCard
      title={lineItem?.part?.name}
      footer={
        <>
          {
            // The notes we are displaying here are the notes that an AE inputed that are intended for the customer to see
            // The lineItem.notes on a quote in a requested state are the customers own inputed notes.
            lineItem.notes && quoteState !== QUOTE_STATES.Requested && (
              <div className="w-100 bg-coolGray-700 p-2 rounded-[8px] flex flex-col items-start text-xs mb-2 only:mb-0">
                <div>Note:</div>
                <div className="text-coolGray-300">{lineItem.notes}</div>
              </div>
            )
          }

          {renderDfmCheck()}
        </>
      }
    >
      <LineItemCard.StartCell>
        <PartImage size="md" src={lineItem.part?.current_revision?.screenshot} />
        {lineItem.offered_lead_time && (
          <div className="text-gray font-size-sm mt-3">
            Lead time: <strong>{lineItem.offered_lead_time} business days</strong>
          </div>
        )}
      </LineItemCard.StartCell>
      <LineItemCard.CenterCell lineItem={lineItem} />
      <LineItemCard.EndCell>
        <div className="flex font-size-sm justify-content-between">
          <p className="text-gray mb-1">Unit price</p>
          <Money amount={lineItem?.unit_price?.amount} className="text-gray font-size-md" />
        </div>
        <div className="flex font-size-base justify-content-between">
          <p className="text-light mb-1">Item total</p>
          <Money
            amount={lineItem?.total_price?.amount}
            className="text-light font-weight-bolder font-size-lg"
          />
        </div>
      </LineItemCard.EndCell>
    </LineItemCard>
  );
};

QuoteLineItemCard.propTypes = {
  lineItem: PropTypes.object.isRequired,
  quoteState: PropTypes.string,
  readonly: PropTypes.bool,
};

export default QuoteLineItemCard;
