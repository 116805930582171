import React from 'react';

import { IconFont } from 'fr-shared/components';

import { classNames } from 'portal/components';

interface Props {
  iconButtonClassname?: string;
  iconName?: string;
  iconClassname?: string;
  onClick: () => void;
}

export const IconClickable = ({
  iconButtonClassname,
  iconName,
  iconClassname,
  onClick,
}: Props) => {
  return (
    <div
      onClick={() => onClick()}
      role="button"
      tabIndex={0}
      onKeyDown={e => {
        if (e.key === 'Enter') onClick();
      }}
      className={classNames(['cursor-pointer', iconButtonClassname])}
    >
      <IconFont name={iconName} className={iconClassname} />
    </div>
  );
};

export default IconClickable;
