import { Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { api } from 'fr-shared/api';
import {
  Button,
  FormField,
  FormSelect,
  Icon,
  Link,
  Modal,
  ServerError,
} from 'fr-shared/components';
import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';
import { DLS_SMART_WASH, WORK_ORDER_STATES } from 'fr-shared/lib/work_orders';

import {
  BuildTimeFormField,
  CarbonLinkFormField,
  CassetteFormField,
  MPILinkFormField,
  MaterialAmountFormField,
} from './fields';

const WorkOrderBulkUpdate = ({
  dirty,
  errors,
  isOpen,
  selectedWorkOrders,
  setErrors,
  setStatus,
  status,
  toggle,
  values,
}) => {
  const hasProcess = process => {
    return !!selectedWorkOrders.find(
      wo => wo.manufacturing_process && wo.manufacturing_process === process
    );
  };

  const handleBulkTraveler = () => {
    setStatus({ travelerLoading: true });
    const workOrderIDs = selectedWorkOrders.map(work_order => work_order.id);

    api
      .get('/bulk_traveler', { params: { work_order_ids: workOrderIDs } })
      .then(res => {
        window.open(res.data.path);
      })
      .catch(() => {
        setErrors({
          server: ['Sorry, traveler generation failed. The dev team has been notified.'],
        });
      })
      .finally(() => {
        setStatus({ travelerLoading: false });
      });
  };

  const hasChanges = dirty;

  return (
    <Modal
      className="modal-scrollable modal-wide-xl"
      backdrop="static"
      fade={false}
      isOpen={isOpen}
      toggle={toggle}
    >
      <Form>
        <Modal.Header title="Bulk Update" onClose={toggle} />
        <div className="modal-body">
          <ServerError errors={errors?.server} />
          <p className="mb-4">
            Select updates to apply to the following Work Orders:{' '}
            {selectedWorkOrders.map((wo, index) => (
              <strong key={wo.id}>{`${index ? ', ' : ''}#${wo.id}`}</strong>
            ))}
          </p>
          <div className="row">
            <div className="col-md-7">
              <FormSelect
                label="Work Order State"
                name="state"
                optionList={WORK_ORDER_STATES}
                valueAccessor={option => option}
                nameAccessor={option => option}
              />
              <MPILinkFormField />
              {hasProcess(MANUFACTURING_PROCESSES.DLS) && (
                <>
                  <CarbonLinkFormField showCarbonLink={true} />
                  <FormField label="Lot A" name="material_lot_number_a" />
                  <FormField label="Lot B" name="material_lot_number_b" />
                  <MaterialAmountFormField />
                  <BuildTimeFormField />
                  <CassetteFormField />
                  <FormSelect
                    label="Smart Wash"
                    name="smart_wash"
                    nameAccessor={option => option}
                    optionList={DLS_SMART_WASH}
                    valueAccessor={option => option}
                  />
                  <FormField label="Post Process Wash" name="post_process_wash" />
                  <FormField component="textarea" label="Oven Notes" name="oven_notes" />
                  <FormField label="Notes" name="notes" />
                </>
              )}
            </div>
            <div className="col-md">
              <span className="text-muted">
                <Icon className="text-warning" name="exclamation-triangle" right /> Proposed
                Changes:
              </span>
              {hasChanges &&
                selectedWorkOrders.map(workOrder => {
                  if (!workOrder) return null;
                  return (
                    <div className="border p-2 mb-2" key={workOrder.id}>
                      <div className="mb-1">
                        <Link
                          to={{ pathname: `/admin/work_orders/${workOrder.id}` }}
                          openNewWindow
                        >
                          Work Order <strong>#{workOrder.id}</strong>
                        </Link>
                      </div>
                      {Object.keys(values).map(
                        name =>
                          values[name] &&
                          values[name] !== '' && (
                            <div className="flex align-items-center" key={name}>
                              <div className="font-size-sm uppercase text-muted mb-0 mr-3">
                                {name} :
                              </div>
                              <div>
                                <span className="bg-error/20 px-1">
                                  {workOrder[name] ? (
                                    <strike>{workOrder[name].toString()}</strike>
                                  ) : (
                                    <code>n/a</code>
                                  )}
                                </span>
                                <Icon name="chevron-right" className="mx-2" sm />
                                {values[name] && (
                                  <span className="bg-success/20 px-1">
                                    {values[name].toString()}
                                  </span>
                                )}
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            className="mr-2"
            loading={status && status.travelerLoading}
            onClick={handleBulkTraveler}
          >
            Bulk Generate Travelers
          </Button>
          <div className="ml-auto">
            <Button color="secondary" className="mr-2" onClick={toggle}>
              Close
            </Button>
            <Button type="submit" color="success">
              Update All
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

WorkOrderBulkUpdate.propTypes = {
  dirty: PropTypes.bool,
  handleSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  selectedWorkOrders: PropTypes.array,
  status: PropTypes.object,
  setErrors: PropTypes.func,
  setStatus: PropTypes.func,
  toggle: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
};

const defaultValues = {};

export default withFormik({
  handleSubmit: (values, bag) => {
    const { setErrors, setStatus } = bag;
    const { selectedWorkOrders, toggle } = bag.props;

    setStatus({ bulkLoading: true });
    const ids = selectedWorkOrders.map(wo => wo.id);

    return api
      .put('/work_orders/bulk_update', { ids: ids, work_order: values })
      .then(() => toggle())
      .catch(() => {
        setStatus({ bulkLoading: false });
        setErrors({
          server: [
            'Something terrible happened with the bulk update. The dev team has been notified.',
          ],
        });
      });
  },
  mapPropsToValues: () => ({ ...defaultValues }),
})(WorkOrderBulkUpdate);
