import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import { FormFieldBase, classNames } from 'fr-shared/components';

const FormMultiSelect = ({ onChange, options, placeholder, value, ...formProps }) => {
  const { error, name, readonly } = formProps;

  const wrapperClasses = classNames([
    'basic-multi-select',
    error && 'form-multi-select-is-invalid',
  ]);

  const readOnlyValue = value.map(({ label }) => label).join(', ');

  return (
    <FormFieldBase {...formProps} value={readOnlyValue}>
      {readonly ? (
        <strong>{readOnlyValue}</strong>
      ) : (
        <Select
          className={wrapperClasses}
          classNamePrefix="select"
          isMulti
          name={name}
          id={name}
          onChange={onChange}
          options={options}
          placeholder={placeholder || 'Select from list'}
          value={value}
        />
      )}
    </FormFieldBase>
  );
};

FormMultiSelect.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.array.isRequired,
};

export default React.memo(FormMultiSelect);
