import { useTreatments } from '@splitsoftware/splitio-react';
import { connect } from 'formik';
import { compact, isEmpty, isEqual, map, uniq } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  Button,
  FormDate,
  FormField,
  FormSelect,
  Icon,
  SidebarContent,
} from 'fr-shared/components';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { usePrevious } from 'fr-shared/hooks';
import { ORDER_LINE_ITEM_STATES } from 'fr-shared/lib/orders';

import { SELECTABLE_ORDER_LINE_ITEM_STATES } from '../utils/states';
import TariffSelect from './TariffSelect';

const OrderFormSidebar = ({ onBulkUpdate, formik }) => {
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';

  const { values, setFieldValue, setFieldError } = formik;
  const [filters, setFilters] = useState({ state: 'all', manufacturing_process: 'all' });
  const prevFilters = usePrevious(filters);

  const lineItems = values.line_items;
  const selectedLineItems = lineItems.filter(li => li.selected).length;
  const manufacturingProcesses = uniq(map(lineItems, 'manufacturing_process.name'));
  const lineItemStates = uniq(map(lineItems, 'state'));
  const [showActualShipDate, setShowActualShipDate] = useState(false);

  const handleFilterBy = e => {
    const { name, type, checked, value } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    const newFilters = { ...filters, [name]: newValue ? newValue : false };
    setFilters(newFilters);
  };

  useEffect(() => {
    if (isEqual(filters, prevFilters)) {
      lineItems.forEach((line, i) => {
        const hasProcess =
          filters.manufacturing_process === 'all'
            ? true
            : line.manufacturing_process.name === filters.manufacturing_process;

        const hasState = filters.state === 'all' ? true : filters.state === line.state;

        if (hasProcess && hasState) {
          setFieldValue(`line_items.${i}.hidden`, false);
        } else {
          setFieldValue(`line_items.${i}.hidden`, true);
        }
      });
    }
    setShowActualShipDate(formik.values.bulk_updates.state === ORDER_LINE_ITEM_STATES.Shipped);
  }, [filters, lineItems, prevFilters, setFieldValue, formik.values.bulk_updates]);

  const handleBulkUpdate = () => {
    const bulkUpdateParams = formik.values.bulk_updates;
    if (Object.keys(bulkUpdateParams).includes('state')) {
      if (bulkUpdateParams['state'] === ORDER_LINE_ITEM_STATES.Shipped) {
        if (isEmpty(bulkUpdateParams['actual_ship_date'])) {
          setFieldError(
            'bulk_updates.actual_ship_date',
            `required when updating state to ${ORDER_LINE_ITEM_STATES.Shipped}`
          );
          return;
        }
        if (new Date(bulkUpdateParams['actual_ship_date']) > new Date()) {
          setFieldError('bulk_updates.actual_ship_date', 'cannot be in the future');
          return;
        }
      } else {
        // reset actual ship date if state is changed to anything other than Shipped.
        bulkUpdateParams['actual_ship_date'] = '';
      }
    }

    const updatedLineItems = lineItems.map(li => {
      if (!li.selected) {
        return li;
      } else if (li.state === ORDER_LINE_ITEM_STATES.ActionRequired) {
        return { ...li, ...bulkUpdateParams, state: li.state };
      } else {
        return { ...li, ...bulkUpdateParams };
      }
    });
    setFieldValue('line_items', updatedLineItems);
    handleClearBulkSelections();
  };

  const handleClearBulkSelections = () => {
    setFieldValue('bulk_updates', {});
    setFieldError('bulk_updates.actual_ship_date', '');
  };

  const handleChangeTariff = tariff => {
    setFieldValue('bulk_updates.tariff_id', tariff?.id);
    setFieldValue('bulk_updates.tariff', tariff);
  };

  return (
    <>
      <SidebarContent name="Filters" icon="filter">
        <div className="form-group mb-3">
          <label htmlFor="state" className="mb-1">
            Filter by State
          </label>
          <select
            name="state"
            className="custom-select"
            value={filters.state}
            onChange={handleFilterBy}
          >
            <option value="all">All</option>
            {compact(lineItemStates).map(s => (
              <option key={s} value={s}>
                {s}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="manufacturing_process" className="mb-1">
            Filter by Process
          </label>
          <select
            id="manufacturing_process"
            name="manufacturing_process"
            className="custom-select"
            value={filters.manufacturing_process}
            onChange={handleFilterBy}
          >
            <option value="all">All</option>
            {compact(manufacturingProcesses).map(m => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </select>
        </div>
      </SidebarContent>
      <SidebarContent name="Bulk Updates" icon="spinner">
        <p className="font-size-md text-muted">
          Select {lineItemName}s on the left to apply your bulk updates to.
        </p>
        <div className="flex justify-content-between mb-2">
          <Button
            color="link"
            size="md"
            onClick={() => onBulkUpdate('selected', true)}
            className="px-0"
          >
            <Icon name="check-square" right />
            Select All
          </Button>
          <Button
            color="link"
            size="md"
            onClick={() => onBulkUpdate('selected', false)}
            className="px-0"
          >
            <Icon name="square" type="far" right />
            Deselect All
          </Button>
        </div>
        <FormSelect
          label="State"
          name="bulk_updates.state"
          optionList={SELECTABLE_ORDER_LINE_ITEM_STATES}
          nameAccessor={option => option}
          valueAccessor={option => option}
          stacked={true}
        />
        {showActualShipDate && (
          <FormDate
            isStacked
            label="Actual Ship Date"
            name="bulk_updates.actual_ship_date"
            disallowFutureDate
          />
        )}
        <FormField
          component="textarea"
          label="Notes"
          name={'bulk_updates.comments'}
          advice={
            <div className="font-size-sm my-1">
              <Icon type="far" name="eye-slash" /> Visible to everybody
            </div>
          }
        />
        <FormField
          component="textarea"
          label="Internal Notes"
          name={'bulk_updates.internal_notes'}
          advice={
            <div className="font-size-sm my-1">
              <Icon type="far" name="eye" /> Only visible to FR team
            </div>
          }
        />
        <TariffSelect
          isStacked
          name={`bulk_updates.tariff_id`}
          onChange={handleChangeTariff}
          value={values.bulk_updates?.tariff}
        />
        <div className="flex form-group align-items-center text-right mt-3">
          <div className="text-muted font-size-md">
            {selectedLineItems > 0 && <span>{selectedLineItems} selected</span>}
          </div>
          <div className="ml-auto">
            <Button className="mr-2" onClick={handleClearBulkSelections}>
              Clear
            </Button>
            <Button color="primary" onClick={handleBulkUpdate}>
              Apply
            </Button>
          </div>
        </div>
      </SidebarContent>
    </>
  );
};

OrderFormSidebar.propTypes = {
  onBulkUpdate: PropTypes.func,
  formik: PropTypes.object,
};

export default connect(OrderFormSidebar);
