import React, { useCallback } from 'react';

import {
  DetailOption,
  DetailOptionId,
  DetailSelectButton,
  DetailSelectOptions,
  InspectionTypePrice,
  classNames,
  useDetailSelectState,
} from 'fr-shared/components';
import { useInspectionTypes, useManufacturingProcess } from 'fr-shared/hooks';
import {
  AUTOQUOTE_EXCLUDED_INSPECTION_TYPES,
  INSPECTION_TYPE_NAMES,
  MOLDING_INSPECTION_TYPES,
  getInspectionTypeDisplayName,
} from 'fr-shared/lib/inspection_types';
import {
  getCNCManufacturingId,
  getCUManufacturingId,
  getIMManufacturingId,
} from 'fr-shared/lib/manufacturing_process';

import { InspectionTypeDefinitionsModal, InspectionTypeForDefinition } from 'portal/components';
import { getDaysAmt } from 'portal/lib/inspection_type';

interface InspectionTypesProps {
  advancedInspectionPrice: null | Money;
  className?: string;
  inspectionTypeId: number;
  onChange: (inspectionType: InspectionTypeForDefinition) => void;
  errorText?: string;
  manufacturingProcessId: number;
  readonly?: boolean;
}

export const InspectionTypes = ({
  advancedInspectionPrice,
  className = '',
  manufacturingProcessId,
  inspectionTypeId,
  onChange,
  errorText,
  readonly = false,
}: InspectionTypesProps) => {
  // State
  const { isRenderedBelow, isOpen, handleSelectClick, setIsOpen } = useDetailSelectState();

  // External data
  const { data: inspectionTypes }: { data: InspectionTypeForDefinition[] } = useInspectionTypes();

  const { data: manufacturingProcesses }: { data: ManufacturingProcess[] } =
    useManufacturingProcess({ publiclyAvailable: true });
  const cncId = manufacturingProcesses && getCNCManufacturingId(manufacturingProcesses);
  const isCncSelected = manufacturingProcessId === cncId;

  const imId = manufacturingProcesses && getIMManufacturingId(manufacturingProcesses);
  const cuId = manufacturingProcesses && getCUManufacturingId(manufacturingProcesses);
  const isMoldingProcessSelected =
    manufacturingProcessId === imId || manufacturingProcessId === cuId;

  // Computed / derived values
  const portalInspectionTypes = isMoldingProcessSelected
    ? inspectionTypes?.filter(inspectionType => {
        let moldingInspectionTypes = [...MOLDING_INSPECTION_TYPES];
        return moldingInspectionTypes.includes(inspectionType?.name);
      })
    : inspectionTypes?.filter(inspectionType => {
        let excludeInspectionTypes = [...AUTOQUOTE_EXCLUDED_INSPECTION_TYPES];
        return !excludeInspectionTypes.includes(inspectionType?.name);
      });

  // Instanced helpers
  const getInspectionTypeById = (id: number) => portalInspectionTypes?.find(x => x.id === id);
  const selectedInspectionType = getInspectionTypeById(inspectionTypeId);
  const selectedInspectionTypeValue = getInspectionTypeDisplayName(selectedInspectionType?.name);

  const renderSkeleton = () => <div className="py-2 invisible">Loading inspection types...</div>;

  const renderOption = useCallback(
    (option: DetailOption, _currentOptionId: DetailOptionId) => {
      return inspectionTypeOptionRender(
        option as InspectionTypeForDefinition,
        advancedInspectionPrice,
        getDaysAmt
      );
    },
    [advancedInspectionPrice]
  );

  return (
    <div onBlur={() => setIsOpen(false)} className={className}>
      <div className={classNames(['w-100 relative z-auto'])} tabIndex={0} role="menu">
        {!selectedInspectionType && renderSkeleton()}
        {selectedInspectionType && (
          <>
            <DetailSelectButton
              labelText={'Inspection'}
              onClick={handleSelectClick}
              readonly={readonly}
              value={selectedInspectionTypeValue}
            />
            <DetailSelectOptions
              className="z-[1001]"
              currentOptionId={selectedInspectionType?.id}
              isOpen={isOpen}
              isRenderedBelow={isRenderedBelow}
              onSelect={(id: DetailOptionId) => {
                onChange(getInspectionTypeById(+id));
                setIsOpen(false);
              }}
              options={portalInspectionTypes}
              renderOption={renderOption}
            />
          </>
        )}
      </div>
      {errorText && (
        <div className="form-control-error" data-testid="errors-inspection-type">
          {errorText}
        </div>
      )}
      {portalInspectionTypes && (
        <InspectionTypeDefinitionsModal
          advancedInspectionPrice={advancedInspectionPrice}
          inspectionTypes={portalInspectionTypes}
          daysAmtFunc={getDaysAmt}
          isCncSelected={isCncSelected}
        />
      )}
    </div>
  );
};

function inspectionTypeOptionRender(
  inspectionType: InspectionTypeForDefinition,
  advancedInspectionPrice: null | Money,
  daysAmtFunc: any
) {
  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex flex-row justify-content-between w-100">
        <div className="select-none text-base leading-5 text-coolGray-100">
          {getInspectionTypeDisplayName(inspectionType.name)}
        </div>
        <div className="select-none mr-3 text-xs leading-4 text-coolGray-100">
          <InspectionTypePrice
            advancedInspectionPrice={advancedInspectionPrice}
            inspectionType={inspectionType}
          />
        </div>
      </div>
      {!AUTOQUOTE_EXCLUDED_INSPECTION_TYPES.includes(inspectionType.name) && (
        <div className="text-xs leading-4 text-coolGray-300 mt-1">
          <span className="select-none">Time it takes:</span>{' '}
          <span className="text-coolGray-100 select-none">
            <span>{daysAmtFunc(inspectionType.name)}</span>
          </span>
        </div>
      )}
      {inspectionType.name === INSPECTION_TYPE_NAMES.AdvancedInspection && (
        <div className="text-coolGray-300 text-xs select-none">
          <sup className="static text-xs">*</sup> Varies by quantity
        </div>
      )}
    </div>
  );
}

export default InspectionTypes;
