import React from 'react';
import { useHistory } from 'react-router-dom';

import { ButtonLink, Icon, Link } from 'fr-shared/components';

const NotFound = () => {
  const history = useHistory();
  return (
    <div className="container my-5 py-5">
      <div className="alert p-4 flex justify-content-center align-items-center" role="alert">
        <div className="circle p-4 border-0">
          <Icon name="exclamation-triangle" className="mb-2 font-size-5xl" />
        </div>
        <div className="ml-5">
          <p className="font-size-xl">
            <strong>404</strong>
          </p>
          <p>Page not found</p>
          <p>
            <ButtonLink onClick={() => history.goBack()}>Go back</ButtonLink> or visit{' '}
            <Link to="/">os.fastradius.com</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
