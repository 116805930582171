import { get } from 'lodash';
import { useContext, useEffect } from 'react';

import { UserContext } from 'fr-shared/context';
import { useChannel, useFormikRef } from 'fr-shared/hooks';
import { organizationChannelForUser } from 'fr-shared/lib/channels';

const usePartUpdate = lineItemsPrefix => {
  const { user } = useContext(UserContext);
  const [channel] = useChannel(organizationChannelForUser(user));
  const formikRef = useFormikRef();

  useEffect(() => {
    if (!channel) return;

    const callbackRef = channel.on('part_update', res => {
      const updatedPart = res.data;
      const lineItems = get(formikRef.current.values, lineItemsPrefix);
      lineItems.forEach((li, index) => {
        if (li.part?.id === updatedPart.id) {
          // these are any docs that the user adds while model processing is running;
          // we don't want to overwrite them when we get a part_update
          const existingSupportingDocs = get(li, 'part.documents', []);
          formikRef.current.setFieldValue(`${lineItemsPrefix}.[${index}]`, {
            ...li,
            part: {
              ...updatedPart,
              documents: existingSupportingDocs,
              usage: li?.part?.usage,
              designation: li?.part?.designation,
            },
          });
        }
      });
    });

    return () => channel.off('part_update', callbackRef);
  }, [channel, lineItemsPrefix, formikRef]);

  useEffect(() => {
    if (!channel) return;

    const callbackRef = channel.on('part_analysis_timed_out', res => {
      const partId = res.data.id;
      const lineItems = get(formikRef.current.values, lineItemsPrefix);

      const newLineItems = lineItems.map(li => {
        const lineItemPart = li.part;
        if (lineItemPart?.id === partId) {
          return {
            ...li,
            part: {
              ...lineItemPart,
              has_timed_out: true,
              usage: li?.part?.usage,
              designation: li?.part?.designation,
            },
          };
        } else {
          return li;
        }
      });
      formikRef.current.setFieldValue(lineItemsPrefix, newLineItems);
    });

    return () => channel.off('part_analysis_timed_out', callbackRef);
  }, [channel, lineItemsPrefix, formikRef]);
};

export default usePartUpdate;
