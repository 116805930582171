/* eslint-disable react/display-name,react/prop-types */
import { get, pickBy } from 'lodash';
import moment from 'moment';
import React from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, FirstOrderBadge, Helmet, Link, Table } from 'fr-shared/components';
import { useTableFilters } from 'fr-shared/hooks';

import ShipmentSearch from './components/ShipmentSearch';

const FILTER_NAME = 'shipments';
const INITIAL_FILTERS = {
  page: 1,
  sort_key: 'id',
  sort_desc: true,
};

const getShipments = filters => api.get('/shipments', { params: pickBy(filters) });

const Shipments = () => {
  const { data, filters, handleSearch, onFetchData, setFilters, pages, loading } =
    useTableFilters(FILTER_NAME, INITIAL_FILTERS, getShipments);

  return (
    <>
      <Helmet title="Shipments" />
      <Breadcrumb to="/admin/shipments">Shipments</Breadcrumb>

      <div className="page-header flex mb-0 px-3">
        <div className="container">
          <h2>Shipments</h2>
          <ShipmentSearch filters={filters} setFilters={setFilters} onTextSearch={handleSearch} />
        </div>
      </div>

      <div className="container py-3">
        <Table
          pages={pages}
          manual={true}
          data={data || []}
          showPagination={true}
          onFetchData={onFetchData}
          loading={loading}
          className="bg-white -striped mb-5"
          noDataText="No shipments found"
          columns={[
            {
              Header: 'Shipment #',
              id: 'id',
              accessor: data => <Link to={`/admin/shipments/${data.id}`}>#{data.id}</Link>,
              width: 100,
            },
            {
              Header: 'Customer',
              id: 'customer_name',
              accessor: data => (
                <Link to={`/admin/customers/${get(data, 'order.customer.id')}`} openNewWindow>
                  {get(data, 'order.customer.name')}
                </Link>
              ),
            },
            {
              Header: 'Order #',
              id: 'public_id',
              accessor: 'order.public_id',
              Cell: ({ original, value }) => (
                <div>
                  {original.order.is_first_order && (
                    <FirstOrderBadge className="mr-1" fontSize="xs" showText={false} />
                  )}
                  {value}
                </div>
              ),
            },
            {
              Header: 'Tracking Number',
              accessor: 'tracking_number',
              sortable: false,
            },
            {
              Header: 'Shipped Date',
              id: 'shipped_date',
              accessor: data => moment(data.shipped_date).format('MM/DD/YYYY'),
            },
            {
              Header: 'Shipping Charge',
              id: 'shipping-charge',
              sortable: false,
              accessor: data => data.shipping_charge.formatted,
            },
          ]}
        />
      </div>
    </>
  );
};

export default Shipments;
