import PropTypes from 'prop-types';
import React from 'react';

type SetAlertCallback = (args: {
  message: string | null | React.ReactElement;
  color?: string;
  autoClose?: boolean;
}) => void;

const AlertContext = React.createContext<{
  autoClose: boolean;
  closeAlert: () => void;
  color: string;
  isOpen: boolean;
  message: string | null | React.ReactElement;
  setAlert: SetAlertCallback;
  timeout: number | null;
}>({
  autoClose: true,
  closeAlert: () => {},
  color: 'success',
  isOpen: false,
  message: null,
  setAlert: () => {},
  timeout: null,
});

class AlertProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  state: {
    autoClose: boolean;
    color: string;
    isOpen: boolean;
    message: string | null | React.ReactElement;
    timeout: number | null;
  } = {
    autoClose: true,
    color: 'success',
    isOpen: false,
    message: null,
    timeout: null,
  };

  clearTimeout() {
    if (this.state.timeout !== null) {
      clearTimeout(this.state.timeout);

      this.setState({ timeout: null });
    }
  }

  setAlert: SetAlertCallback = ({ message, color = 'success', autoClose = true }) => {
    this.clearTimeout();
    this.setState({ message, color, isOpen: true });

    if (autoClose) {
      const timeout = setTimeout(() => this.setState({ isOpen: false }), 10000);
      this.setState({ timeout });
    }
  };

  closeAlert = () => this.setState({ isOpen: false });

  componentWillUnmount() {
    this.clearTimeout();
  }

  render() {
    return (
      <AlertContext.Provider
        value={{
          setAlert: this.setAlert,
          closeAlert: this.closeAlert,
          ...this.state,
        }}
      >
        {this.props.children}
      </AlertContext.Provider>
    );
  }
}

const AlertConsumer = AlertContext.Consumer;

const withAlert =
  <P,>(Component: React.JSXElementConstructor<P>) =>
  (props: P) =>
    (
      <AlertProvider>
        <Component {...props} />
      </AlertProvider>
    );

export { AlertConsumer, AlertContext, AlertProvider, withAlert };
