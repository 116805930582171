import PropTypes from 'prop-types';
import React from 'react';

import { Button, IconFont, classNames } from 'fr-shared/components';
import { keyboardDown } from 'fr-shared/utils';

import styles from './TwoDFileDocItem.module.css';

const TwoDFileDocItem = ({ doc, onDelete }) => {
  const name = doc.file_name ? doc.file_name : doc.file.name;
  const failed = doc.upload_status === 'error';
  const loading = doc.upload_status === 'loading';

  return (
    <div
      className={classNames([
        'supporting-doc-item flex align-items-center my-1',
        failed && 'alert alert-danger p-0 px-1',
      ])}
    >
      {loading && <IconFont name="spinner" className="fa-pulse text-primary mr-1" />}
      {failed ? (
        <div>
          <strong>{name}</strong> ... failed to upload
        </div>
      ) : (
        <div className={styles.DocLinks}>
          <Button disabled={loading} color="link" onClick={() => window.open(doc.url)}>
            {name}
          </Button>

          <p>
            <span
              onClick={onDelete}
              onKeyDown={evt => keyboardDown(evt, 'Enter', onDelete)}
              role="button"
              tabIndex={0}
            >
              remove
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

TwoDFileDocItem.propTypes = {
  doc: PropTypes.shape({
    file: PropTypes.shape({
      name: PropTypes.string,
    }),
    file_name: PropTypes.string,
    upload_status: PropTypes.string,
    url: PropTypes.string,
  }),
  onDelete: PropTypes.func.isRequired,
};

export default TwoDFileDocItem;
