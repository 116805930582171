import PropTypes from 'prop-types';
import React from 'react';

import { Alert, Icon } from 'fr-shared/components';

const ServerError = ({ className, errors = [] }) => {
  if (errors.length === 0) return null;

  return (
    <Alert className={className} color="danger" toggle={true}>
      <Icon name="exclamation-triangle" right />
      Sorry, we&apos;re having a problem processing this form. The following areas need your
      attention:
      <ul className="my-2">
        {errors.map(e => (
          <li key={e}>{e}</li>
        ))}
      </ul>
    </Alert>
  );
};

ServerError.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
  ),
};

export default ServerError;
