import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';

import { FormCurrency, Icon, classNames } from 'fr-shared/components';
import { AlertContext, withAlert } from 'fr-shared/context';
import { useAddresses } from 'fr-shared/hooks';
import { AddressTypeEnum } from 'fr-shared/lib/address';
import {
  ACTIVE_SHIPPING_METHODS_LIST,
  SHIPPING_METHODS_LIST,
  shippingMethodToDisplayName,
} from 'fr-shared/lib/shipping';

import { QuoteFormContext } from '../QuoteFormContext';
import DestinationSelector from './DestinationSelector';
import ShippingEstimateSelector from './ShippingEstimateSelector';

const QuoteFormShippingEstimates = ({ isEnabled = false, readonly = false }) => {
  const formik = useFormikContext();
  const { closeAlert, setAlert } = useContext(AlertContext);
  const { estimateShipping, setValuesAndPushToChannel } = useContext(QuoteFormContext);

  const [calculationError, setCalculationError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addresses, refreshAddresses } = useAddresses(
    formik.values.customer_contact_id,
    AddressTypeEnum.Shipping
  );

  const shippingEstimates = formik.values?.shipping_estimates ?? [];
  const selectedShippingEstimate = formik.values?.selected_shipping_estimate;
  const shippingMethods = readonly ? SHIPPING_METHODS_LIST : ACTIVE_SHIPPING_METHODS_LIST;

  const showRequiredError = Object.prototype.hasOwnProperty.call(
    formik.errors,
    'selected_shipping_estimate_id'
  );
  const hasShippingPrice = Boolean(formik.values?.shipping_price);

  const handleClickCalculate = useCallback(
    async payload => {
      setCalculationError(null);
      setLoading(true);
      closeAlert();

      try {
        await estimateShipping(payload);

        setAlert({ message: 'Calculations Complete', color: 'success' });
      } catch (e) {
        setAlert({
          message: (
            <>
              <Icon name="exclamation" right /> Could not calculate
            </>
          ),
          color: 'warning',
        });

        setCalculationError(e);
      } finally {
        setLoading(false);
      }
    },
    [closeAlert, estimateShipping, setAlert]
  );

  const handleChangeShippingPrice = useCallback(() => {
    setValuesAndPushToChannel(formik.values);
  }, [formik.values, setValuesAndPushToChannel]);

  const handleSelectShippingEstimate = useCallback(
    (id, shippingMethod) => {
      const formattedShippingMethod = shippingMethodToDisplayName(shippingMethod);
      setValuesAndPushToChannel({
        ...formik.values,
        selected_shipping_estimate_id: id,
        shipping_method: formattedShippingMethod,
      });
    },
    [formik.values, setValuesAndPushToChannel]
  );

  return (
    <div className="container p-0">
      <div
        className={classNames([
          'row',
          'm-0',
          'p-1',
          {
            border: showRequiredError,
            'border-error': showRequiredError,
            'rounded-top': showRequiredError,
          },
        ])}
      >
        {readonly && hasShippingPrice ? (
          <div className="col">
            <FormCurrency label="Shipping Price" name="shipping_price" readonly />
            <FormCurrency label="Shipping Method" name="shipping_method" readonly />
            <FormCurrency
              label="Estimated Duties and Taxes Subtotal"
              name="estimated_duties_taxes"
              readonly
            />
          </div>
        ) : isEnabled ? (
          <>
            <div className="col-md-6 pl-0">
              <h6 className="font-size-md font-weight-bold mb-0">Shipping Address</h6>
              {!readonly && (
                <p className="mb-2">
                  Select a shipping address or enter a shipping zip code for this quote.
                </p>
              )}
              <DestinationSelector
                addresses={addresses ?? []}
                className="mx-1"
                isLoading={loading}
                readonly={readonly}
                onAddAddress={refreshAddresses}
                onSubmit={handleClickCalculate}
                quote={formik.values}
              />
            </div>
            <div className="col-md-6 pr-0">
              <h6 className="font-size-md font-weight-bold mb-0">Shipping Options</h6>
              {!readonly && (
                <p className="mb-2">{"Select the option you'd like to appear on the quote."}</p>
              )}
              <ShippingEstimateSelector
                error={calculationError}
                hasShippingPrice={hasShippingPrice}
                isLoading={loading}
                onChangeShippingPrice={handleChangeShippingPrice}
                onSelectShippingEstimate={handleSelectShippingEstimate}
                readonly={readonly}
                selectedShippingEstimate={selectedShippingEstimate}
                shippingEstimates={shippingEstimates}
                shippingMethods={shippingMethods}
              />
            </div>
          </>
        ) : (
          <p className="flex-grow-1 font-size-lg text-center text-muted">
            Save quote as a draft in order to engage with shipping calculations.
          </p>
        )}
      </div>
      {showRequiredError && (
        <div className="form-control-error">
          Shipping information is required.{' '}
          {!isEnabled && 'Save quote as draft to calculate shipping.'}
        </div>
      )}
    </div>
  );
};

QuoteFormShippingEstimates.propTypes = {
  isEnabled: PropTypes.bool,
  readonly: PropTypes.bool,
};

export default withAlert(QuoteFormShippingEstimates);
