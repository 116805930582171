import React from 'react';

import { FormSelect } from 'fr-shared/components';
import { useBuildCarts } from 'fr-shared/hooks';

const BuildCartFormField = props => {
  const { data: buildCarts } = useBuildCarts();

  if (!buildCarts) return null;

  return (
    <FormSelect
      name="build_cart_id"
      label="Build Cart"
      optionList={buildCarts}
      nameAccessor={option => option.id}
      {...props}
    />
  );
};

export default BuildCartFormField;
