import React from 'react';

export const GoogleIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.7189 12.279C23.7189 11.298 23.6391 10.5815 23.4662 9.83911H12.229V14.2676H18.8253C18.6927 15.3683 17.9739 17.0255 16.378 18.1396L16.3559 18.2881L19.9091 21.0326L20.1551 21.0575C22.416 18.9749 23.7189 15.9115 23.7189 12.279Z"
        fill="#4285F4"
      />
      <path
        d="M12.1901 24C15.4286 24 18.148 22.9304 20.1337 21.085L16.3482 18.143C15.3352 18.8521 13.9754 19.3468 12.1901 19.3468C9.01806 19.3468 6.32558 17.2473 5.3655 14.3448L5.2249 14.3564L1.52227 17.2318L1.47412 17.3671C3.44629 21.2985 7.49848 24 12.1901 24Z"
        fill="#34A853"
      />
      <path
        d="M5.33496 14.3601C5.08286 13.6123 4.93716 12.8104 4.93716 11.9825C4.93716 11.1546 5.08286 10.3527 5.32154 9.60492L5.31579 9.44668L1.58316 6.52875L1.46142 6.58761C0.651442 8.2164 0.1875 10.0469 0.1875 11.9835C0.1875 13.9201 0.651442 15.7496 1.46142 17.3793L5.33496 14.3601Z"
        fill="#FBBC05"
      />
      <path
        d="M12.1968 4.65415C14.4504 4.65415 15.9708 5.63001 16.8379 6.44644L20.2256 3.1295C18.1444 1.19035 15.437 0 12.1968 0C7.50268 0 3.44831 2.70148 1.47412 6.63292L5.35506 9.65517C6.32915 6.75369 9.02307 4.65415 12.1968 4.65415Z"
        fill="#EB4335"
      />
    </svg>
  );
};

export default GoogleIcon;
