import PropTypes from 'prop-types';
import React from 'react';

import { useUserAnalyticsContext } from 'fr-shared/context';
import { ORDER_STATES } from 'fr-shared/lib/orders';

import {
  Button,
  ContactUs,
  ContactUsType,
  DetailsHeader,
  Money,
  ShippingPriceLabel,
  StateBadge,
} from 'portal/components';
import { date } from 'portal/lib/date';

const OrderDetailsHeader = ({ order, onReorderClicked }) => {
  const userAnalytics = useUserAnalyticsContext();
  const canReorder = order.state === ORDER_STATES.Shipped;
  const handleContactClick = () => userAnalytics.track('Contact Us - Order', { valid: true });

  return (
    <DetailsHeader backLink="/orders" backText="Back to all orders">
      <DetailsHeader.MainCell>
        <div className="font-size-sm text-gray"> Order #</div>
        <div className="flex align-items-center">
          <h3 className="mb-0">{order.public_id}</h3>
          <div className="ml-3">
            <StateBadge state={order.state}>{order.state}</StateBadge>
          </div>
        </div>
        <div className="row mt-3">
          {order.state !== ORDER_STATES.Canceled && (
            <div className="col-sm mb-3">
              <div className="text-gray font-size-sm">Est. ship date</div>
              <div className="text-light">{date(order.promised_ship_date)}</div>
            </div>
          )}
          <div className="col-sm mb-3">
            <div className="text-gray font-size-sm">Ordered by</div>
            <div className="text-light">{order.customer_contact_name}</div>
          </div>
          <div className="col-sm mb-3">
            <div className="text-gray font-size-sm">Date ordered</div>
            <div className="text-light">{date(order.inserted_at)}</div>
          </div>
          {order.state === ORDER_STATES.Canceled && (
            <div className="col-sm mb-3">
              <div className="text-gray font-size-sm">Date canceled</div>
              <div className="text-light">{date(order.updated_at)}</div>
            </div>
          )}
          {order.customer_po && (
            <div className="col-sm mb-3">
              <div className="text-gray font-size-sm">PO #</div>
              <div className="text-light">{order.customer_po}</div>
            </div>
          )}
        </div>
      </DetailsHeader.MainCell>
      <DetailsHeader.EndCell className="font-size-base">
        <div className="font-size-md flex justify-content-between align-items-center">
          <p className="text-gray">Items subtotal</p>
          <Money amount={order?.sub_total_price?.amount} className="text-gray" />
        </div>
        <div className="font-size-md flex justify-content-between align-items-center">
          <p className="text-gray">
            <ShippingPriceLabel />
          </p>
          <Money amount={order?.shipping_charge?.amount} className="text-gray" />
        </div>
        {+order?.discount?.amount > 0 && (
          <div className="font-size-md flex justify-content-between align-items-center">
            <p className="text-gray">Discount</p>
            <Money
              amount={order?.discount?.amount}
              className="text-success"
              isNegative={true}
              unstyledSymbol={true}
            />
          </div>
        )}
        <div className="flex justify-content-between align-items-center">
          <p className="text-light">Total</p>
          <Money
            amount={order?.total_price?.amount}
            className="text-light font-size-lg font-weight-bolder"
          />
        </div>
        <div className="flex justify-content-between mt-2">
          <ContactUs
            outline
            className="flex-1"
            linkType={ContactUsType['button']}
            onClick={handleContactClick}
          >
            Contact us
          </ContactUs>
          {canReorder && (
            <Button className="flex-1 ml-2" onClick={onReorderClicked} dataTestId="btn-reorder">
              Reorder
            </Button>
          )}
        </div>
      </DetailsHeader.EndCell>
    </DetailsHeader>
  );
};

OrderDetailsHeader.propTypes = {
  onReorderClicked: PropTypes.func,
  order: PropTypes.shape({
    customer_contact_name: PropTypes.string,
    customer_po: PropTypes.string,
    discount: PropTypes.object,
    inserted_at: PropTypes.string,
    updated_at: PropTypes.string,
    promised_ship_date: PropTypes.string,
    public_id: PropTypes.string,
    shipping_charge: PropTypes.object,
    state: PropTypes.string,
    sub_total_price: PropTypes.object,
    total_price: PropTypes.object,
  }),
};

export default OrderDetailsHeader;
