import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Creatable from 'react-select/creatable';

import { FormField, FormFieldBase, classNames } from 'fr-shared/components';
import { useForm } from 'fr-shared/hooks';

const formatDropdownLabels = (addresses, displayFormat) => {
  if (addresses) {
    return addresses.map(address => {
      return { label: displayFormat(address), address };
    });
  } else {
    return [];
  }
};

const OrderAddressSelect = ({
  disabled,
  dropdownOptions,
  formatOptions,
  label,
  name,
  onChange,
  onCreateOption,
  readonly,
  required,
}) => {
  const formik = useForm();
  const value = get(formik.values, name);
  const error = get(formik.errors, name);

  const options = formatDropdownLabels(dropdownOptions, formatOptions);
  const DropdownIndicator = () => <i className="orders-custom-select-icon" />;

  if (disabled) return <FormField disabled={disabled} label={label} name={name} />;
  if (readonly) return <FormField label={label} name={name} readonly={readonly} />;

  return (
    <FormFieldBase name={name} label={label} error={error} isRequired={required}>
      <Creatable
        className={classNames(['react-select-wrapper', { 'is-invalid': error }])}
        classNamePrefix="react-select"
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator,
        }}
        formatCreateLabel={input => input}
        inputId={name}
        name={name}
        noOptionsMessage={() => 'No saved information'}
        onChange={address => onChange(address)}
        onCreateOption={onCreateOption}
        options={options}
        required={true}
        value={value ? { label: value, value } : null}
      />
    </FormFieldBase>
  );
};

OrderAddressSelect.propTypes = {
  disabled: PropTypes.bool,
  dropdownOptions: PropTypes.array,
  name: PropTypes.string.isRequired,
  formatOptions: PropTypes.func,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onCreateOption: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
};

export default OrderAddressSelect;
