import { useFormikContext } from 'formik';
import { get } from 'lodash';
import React from 'react';

import ShippingLineItem from './ShippingLineItem';

const ShippingDetails = () => {
  const formik = useFormikContext();

  const lineItems = get(formik.values, `line_items`, []);
  return lineItems.map((li: OrderLineItem, index: number) => (
    <ShippingLineItem key={li.id} lineItem={li} oliIndex={index} />
  ));
};

export default ShippingDetails;
