import React, { PropsWithChildren, useState } from 'react';

import { Icon, classNames } from 'fr-shared/components';

import styles from './CompareContainer.module.css';

/** Keep this in sync with the animation duration in CompareContainer.module.css */
const ACCORDION_ANIM_DURATION_MS = 500;

interface AccordionProps {
  title: string;
  disableAnimateCollapse?: boolean;
}

type AccordionState = 'expanded' | 'collapsing' | 'collapsed';

const Accordion = ({
  children,
  title,
  disableAnimateCollapse = false,
}: AccordionProps & PropsWithChildren<Record<never, never>>) => {
  const [state, setState] = useState<AccordionState>('expanded');

  const nextAccordionState = (state: AccordionState): AccordionState => {
    if (state === 'expanded') {
      if (disableAnimateCollapse) {
        return 'collapsed';
      }
      setTimeout(() => setState('collapsed'), ACCORDION_ANIM_DURATION_MS);
      return 'collapsing';
    }
    if (state === 'collapsing') {
      return 'collapsed';
    }
    return 'expanded';
  };

  return (
    <section className={classNames(['mb-8 w-full'])}>
      <button
        className={classNames([
          styles['container-width'],
          'appearance-none text-left outline-0 border-0 p-0 bg-transparent border-b border-coolGray-600 border-solid mb-6 sticky left-0',
        ])}
        onClick={() => setState(nextAccordionState)}
      >
        <h1 className="mb-1 text-2xl font-medium">{title}</h1>

        <div className="border-none font-size-lg absolute right-0 top-2">
          <Icon
            className={classNames([
              'text-coolGray-100',
              styles['accordion-chevron'],
              styles[`accordion-chevron--${state}`],
            ])}
            style={{ marginLeft: '4px' }}
            name="chevron-down"
          />
        </div>
      </button>
      <div className={classNames([styles['accordion'], styles[`accordion--${state}`]])}>
        {state !== 'collapsed' && children}
      </div>
    </section>
  );
};

export default Accordion;
