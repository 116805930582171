/*
https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state

This is a general hook for grabbing the previous state or prop of a component.
As the link says, this might end up shipping with react, so we may want to delete this in the future
*/
import { MutableRefObject, useEffect, useRef } from 'react';

export function usePrevious<T>(value: T): MutableRefObject<T | undefined>['current'] {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export default usePrevious;
