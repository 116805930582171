import * as Sentry from '@sentry/react';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Button, classNames } from 'fr-shared/components';
import { getMarketoCookies } from 'fr-shared/lib/marketo';

import { PartsContext } from 'portal/contexts/PartsContext';

import styles from './YesConfirmation.module.css';

interface Props {
  cliId: string | number;
  partId: number;
}

const YesConfirmation = ({ cliId, partId }: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const history = useHistory();
  const isBulkEdit = window.location.pathname === '/edit-parts';
  const parts = useContext(PartsContext);

  const handleDelete = () => {
    setIsDeleting(true);
    if (isBulkEdit) {
      const { selectedParts, removeBulkConfig } = parts;
      api
        .delete('/customer_portal/parts/bulk_itar_ear_parts', {
          data: { part_ids: Object.keys(selectedParts) },
        })
        .then(() => {
          removeBulkConfig();
          setIsDeleting(false);
          return history.push('/');
        });
    } else {
      return api
        .delete(`/customer_portal/cart_line_items/${cliId}`, {
          data: { marketo_attrs: getMarketoCookies() },
        })
        .then(() => api.delete(`/customer_portal/parts/${partId}/itar_ear_part`))
        .then(() => {
          setIsDeleting(false);
          return history.push('/');
        })
        .catch(error => {
          setIsDeleting(false);
          Sentry.setExtra('error', error);
          Sentry.setExtra('cliId', cliId);
          Sentry.setExtra('partId', partId);
          Sentry.captureMessage(
            'ITAR/EAR part potentially failed to be deleted via the customer portal'
          );
        });
    }
  };

  return (
    <>
      <p
        data-testid="itar-ear-confirmation-modal--yes-confirmation-title"
        className="text-error-300 text-xs mb-0"
      >
        Unfortunately we can’t continue with
        {isBulkEdit ? ' these parts' : ' this part'}
      </p>
      <p className="text-coolGray-100 text-xs">
        We’re required by law to remove
        {isBulkEdit ? ' these files ' : ' this file '}
        from our server, as we don’t have licenses to make any parts regulated by ITAR or EAR
        export laws.{' '}
      </p>
      <div className="mt-2" style={{ borderBottom: '1px solid #3c4551' }}></div>

      <Button
        active={isDeleting}
        className={classNames([
          styles.DangerButton,
          styles.DangerButtonExtraSpecificity,
          'float-right mt-4',
        ])}
        loading={isDeleting}
        loadingIconColor="#f87e88"
        onClick={handleDelete}
        outline
        showLoadingIcon={isDeleting}
      >
        Delete and go to dashboard
      </Button>
    </>
  );
};

export default YesConfirmation;
