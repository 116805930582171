import React, { useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, Helmet } from 'fr-shared/components';

import Webhook from './components/Webhook';

const WebhooksIndex = () => {
  const [webhooks, setWebhooks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api
      .get('/smartsheet_webhooks')
      .then(res => setWebhooks(res.data))
      .catch(() => setApiError(true))
      .finally(() => setIsLoading(false));
  }, [setIsLoading]);

  const handleDelete = webhook => {
    setWebhooks(webhooks.filter(hook => hook.id !== webhook.id));
  };

  return (
    <>
      <Helmet title="Smartsheet Webhooks" />
      <Breadcrumb to="/admin/smartsheets/webhooks">Smartsheet Webhooks</Breadcrumb>
      <div className="page-header flex mb-0 px-3">
        <h2>List of Webhooks</h2>
      </div>
      <div className="container">
        {apiError ? (
          <div>
            There was an error loading the webhooks. Please check your permissions and try again
          </div>
        ) : isLoading ? (
          <div> LOADING... </div>
        ) : (
          webhooks.map(webhook => {
            return (
              <div key={`id_${webhook.id}`} className="card">
                <Webhook webhook={webhook} onDelete={handleDelete} />
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default WebhooksIndex;
