import React, { useEffect, useRef, useState } from 'react';

import { classNames } from 'fr-shared/components';

import styles from './Slider.module.css';

interface SubtitleCopy {
  index: number;
}

interface GetClassName {
  index: number | (() => void);
  isLast?: boolean;
  type?: string;
}

interface SliderProps {
  state: string;
}

export const Slider = (props: SliderProps) => {
  const titles: any = {
    1: "We're uploading your file",
    2: "We're analyzing your file",
  };

  const subTitles: any = {
    1: "We'll be as quick as we can...",
    2: "We're still working on it...",
    3: 'Sit tight or grab a coffee...',
    4: "We're getting closer...",
    5: "You'll see your part soon...",
  };

  const [subTitleSlideNumber, setSubTitleSlideNumber] = useState(1);

  const subTitleInterval = useRef(null);

  const isOnMobile = window.innerWidth < 768;

  const reset = () => {
    clearInterval(subTitleInterval.current);
  };

  const runSubTitleInterval = () => {
    subTitleInterval.current = setInterval(() => {
      setSubTitleSlideNumber((currentSlideNumber: number) => {
        if (currentSlideNumber === 4) {
          clearInterval(subTitleInterval.current);
        }
        return currentSlideNumber + 1;
      });
    }, 20000);
  };

  const SubtitleCopy = (props: SubtitleCopy) => (
    <p className="text-coolGray-300 mb-1 text-sm">{subTitles[props.index]}</p>
  );

  const getClassName = (obj: GetClassName) => {
    let classStyles = [
      obj?.type === 'p' ? styles.Paragraph : styles.Subtitle,
      subTitleSlideNumber === obj?.index && styles.isShowing,
      subTitleSlideNumber > obj?.index && styles.wasShown,
    ];
    if (obj?.isLast) {
      classStyles.pop();
    }
    return classNames(classStyles);
  };

  useEffect(() => {
    // In the rare case that a user uploads a part from mobile, everything will work
    // on the part upload and the modal. They just won't see all the titles.
    if (!isOnMobile && props.state === 'analyze') {
      setSubTitleSlideNumber(2);
      runSubTitleInterval();
      return reset;
    }
  }, [isOnMobile, props.state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className={styles.MaskedTitleLayer}>
        <div
          className={classNames([
            'mb-1',
            styles.TitleContainer,
            props.state === 'analyze' && styles.isAnimating,
          ])}
        >
          <h3
            className={classNames([
              styles.Title,
              styles.FirstTitle,
              props.state === 'upload' && styles.isShowing,
            ])}
          >
            {titles[1]}
          </h3>

          <h3
            className={classNames([
              styles.Title,
              styles.SecondTitle,
              props.state === 'analyze' && styles.isShowing,
            ])}
          >
            {titles[2]}
          </h3>
        </div>
      </div>

      <div className={styles.MaskedSubTitleLayer}>
        <div className={classNames([styles.SubTitleContainer])}>
          <div>
            <div className={getClassName({ index: 1 })}>
              <SubtitleCopy index={1} />
            </div>
            <div className={getClassName({ index: 2 })}>
              <SubtitleCopy index={2} />
            </div>

            <div className={getClassName({ index: 3 })}>
              <SubtitleCopy index={3} />
            </div>
            <div className={getClassName({ index: 4 })}>
              <SubtitleCopy index={4} />
            </div>
            <div className={getClassName({ index: 5, isLast: true })}>
              <SubtitleCopy index={5} />
            </div>
          </div>

          <div className={getClassName({ index: 1, type: 'p' })}>
            <p className="text-coolGray-300 mb-0 text-xs">
              Large part files can take a while to process. Please don&apos;t close this window
              until we start analyzing your file.{' '}
            </p>
          </div>

          <div
            className={classNames([
              styles.Paragraph,
              (subTitleSlideNumber === 4 || subTitleSlideNumber === 5) && styles.isShowing,
            ])}
          >
            <p className="text-coolGray-300 mb-0 text-xs">
              Large part files can take a while to process. Feel free to leave this with us, for
              now. We&apos;ll email you as soon as your part is ready to review.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slider;
