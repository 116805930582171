import { Logo } from '@fast-radius/shared-ui';
import React from 'react';

import { MARKETING_URLS } from 'fr-shared/lib/marketing_urls';

import { Link, Page } from 'portal/components';

interface CheckoutLayoutProps {
  backLink: React.ReactNode;
  mainContent: React.ReactNode;
  isSubscriptionCheckout?: boolean;
  summary: React.ReactNode;
}

const CheckoutLayout = ({
  backLink,
  mainContent,
  isSubscriptionCheckout = false,
  summary,
}: CheckoutLayoutProps) => {
  return (
    <Page>
      <div className="mb-5">
        <div className="mb-3">{backLink}</div>
        <Logo textColor="white" />
      </div>
      <div className="row pt-3 flex-col-reverse lg:flex-row">
        <div className="col-lg mr-5">
          <h6 className="mb-3">Checkout</h6>
          <div className="card-count">{mainContent}</div>
          <div className="text-muted mt-6 mb-4">
            <p className="font-size-sm">
              Fast Radius is the leading provider of additive and traditional manufacturing
              solutions - including application discovery, product design and testing,
              production-grade manufacturing, and global fulfillment.
            </p>
            {!isSubscriptionCheckout && (
              <>
                <p className="font-size-sm">
                  Our engineers will review your order more closely. If we find any issues, the
                  prices here could change.
                </p>
                <p className="font-size-sm">
                  Lead times are estimated and represent date of expected shipment and may change
                  due to capacity.{' '}
                </p>
                <p className="font-size-sm text-white">
                  If you&apos;re paying by credit card, payment is made when we confirm your
                  order.
                </p>
              </>
            )}
            <p className="font-size-sm">
              The Fast Radius Terms and Conditions{' '}
              <Link to={{ pathname: MARKETING_URLS.TermsAndConditions }} openNewWindow>
                {`(${MARKETING_URLS.TermsAndConditions})`}
              </Link>{' '}
              and Terms of Use{' '}
              <Link to={{ pathname: MARKETING_URLS.TermsOfUse }} openNewWindow>
                {`(${MARKETING_URLS.TermsOfUse})`}
              </Link>{' '}
              apply to every quote and contract.
            </p>
          </div>
        </div>
        <div className="col-lg-4">{summary}</div>
      </div>
    </Page>
  );
};

export default CheckoutLayout;
