import {
  AbstractMesh,
  Animation,
  CubicEase,
  DefaultRenderingPipeline,
  EasingFunction,
  Mesh,
  SSAO2RenderingPipeline,
} from 'babylonjs';
import PropTypes from 'prop-types';
import React from 'react';

import { BabylonJSContext } from './Scene';
import { initLights } from './utils';

// create ArcRotateCamera and HemisphericLight with Babylon's createDefaultCameraOrLight
// https://doc.babylonjs.com/babylon101/cameras#arc-rotate-camera
export default class Camera extends React.Component {
  static contextType = BabylonJSContext;

  componentDidMount() {
    const scene = this.context.scene;
    scene.createDefaultCamera(true, true, true);
    this._camera = scene.activeCamera;
    this._camera.maxZ = 10000;
    initLights(scene);
    // lock the camera to an invisible box whose position we animate
    // via the moveTo function to simulate panning
    this._camera.lockedTarget = Mesh.CreateBox('target', 25, scene);
    this._camera.lockedTarget.visibility = 0;
    this._camera.lockedTarget.isPickable = false;

    // smaller # is slower zoom
    this._camera.wheelDeltaPercentage = 0.002;
    const ssao = new SSAO2RenderingPipeline('ssao', scene, {
      ssaoRatio: 0.5, // Ratio of the SSAO post-process, in a lower resolution
      blurRatio: 1, // Ratio of the combine post-process (combines the SSAO and the scene)
    });
    ssao.radius = 8;
    ssao.totalStrength = 0.9;
    ssao.expensiveBlur = true;
    ssao.samples = 16;
    ssao.maxZ = 100;
    ssao.base = 1.0;
    scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline('ssao', this._camera);

    const defaultpipeline = new DefaultRenderingPipeline('default', true, scene, [this._camera]);
    defaultpipeline.fxaaEnabled = true;
    defaultpipeline.imageProcessingEnabled = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.radius !== prevProps.radius) {
      this._camera.radius = this.props.radius;
    }
    if (this.props.position !== prevProps.position) {
      this._camera.lockedTarget.moveTo(this.props.position);
    }
  }

  render() {
    return null;
  }
}

Camera.propTypes = {
  radius: PropTypes.number,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    z: PropTypes.number,
  }),
};

AbstractMesh.prototype.moveTo = function (targetPos) {
  let ease = new CubicEase();
  ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
  Animation.CreateAndStartAnimation(
    'at5',
    this,
    'position',
    90,
    120,
    this.position,
    targetPos,
    0,
    ease
  );
};
