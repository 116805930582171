import { pickBy } from 'lodash';
import React, { useContext } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, Button, Helmet } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { useTableFilters } from 'fr-shared/hooks';

import CostingCSVImportsTable from './components/CostingCSVImportsTable';

const FILTER_NAME = 'costing_csv_imports';
const INITIAL_FILTERS = { page: 1, sort_key: 'id', sort_desc: true };

const CostingCSVImports = () => {
  const { user } = useContext(UserContext);

  const getCostingCSVImports = filters => {
    const params = pickBy(filters);
    return api.get('/costing_csv_imports', { params });
  };

  const {
    data: costingCSVImports,
    loading,
    onFetchData,
    pages,
  } = useTableFilters(FILTER_NAME, INITIAL_FILTERS, getCostingCSVImports);

  return (
    <>
      <Helmet title="List of Imports" />
      <Breadcrumb to="/admin/costing_csv_imports">Imports</Breadcrumb>

      <div className="page-header flex mb-0 px-3">
        <h2>List of Imports</h2>
        <div className="page-actions mb-0">
          <div className="flex justify-content-end align-items-center">
            {user.canImportCosts && (
              <Button to="/admin/costing_csv_imports/new" color="primary">
                New Import
              </Button>
            )}
          </div>
        </div>
      </div>

      <CostingCSVImportsTable
        data={costingCSVImports || []}
        loading={loading}
        pages={pages}
        onFetchData={onFetchData}
      />
    </>
  );
};

export default CostingCSVImports;
