import { Sidebar } from '@fast-radius/shared-ui';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { UserContext, useUserAnalyticsContext } from 'fr-shared/context';
import { usePortalSubscription } from 'fr-shared/hooks';
import { MARKETING_URLS } from 'fr-shared/lib/marketing_urls';

import { SubscriptionModal, classNames } from 'portal/components';

import SidebarLiveChat from './SidebarLiveChat';

export const PortalSidebar = () => {
  const { user }: UserContext = useContext(UserContext);
  const location = useLocation();

  const isLinkActive = (linkTo: string) => {
    if (location.pathname.includes(linkTo) || location.pathname === '/') return true;
  };

  const isPortalSubscribed = usePortalSubscription();
  const userAnalytics = useUserAnalyticsContext();

  const logEvt = (str: string) => userAnalytics.track(str, { valid: true });
  const handleAcctClick = () => logEvt('View Account Settings');
  const handleResourcesClick = () => logEvt('Resources');
  const handleContactUsClick = () => logEvt('Contact Us - Nav');
  const handleDashClick = () => logEvt('Go to Dash - Nav');
  const handlePartsClick = () => logEvt('Go to Parts - Nav');
  const handleOrdersClick = () => logEvt('Go to Orders - Nav');
  const handleQuotesLink = () => logEvt('Go to Quotes - Nav');
  const handleEvaluationlink = () => logEvt('Go to Evaluation - Nav');
  const handleSignoutClick = () => logEvt('Sign Out');
  const handlePartnerPortalClick = () => logEvt('Go to Partner Portal - Nav');
  const handleCustomerPortalClick = () => logEvt('Go to Customer Portal - Nav');

  return (
    <Sidebar location={location} logoUrl="/dashboard" showJnjLogo={user?.show_jnj_logo}>
      <Sidebar.Section>
        <Sidebar.Link
          component={Link}
          dataTestId="sidebar-dashboard"
          icon="dashboard"
          isActive={isLinkActive('dashboard')}
          onClick={handleDashClick}
          text="Dashboard"
          to="/dashboard"
        />
        <Sidebar.Title>STUDIO</Sidebar.Title>
        <Sidebar.Panel icon="tools" text="Tools">
          <Sidebar.Link
            component={Link}
            dataTestId="sidebar-studio"
            className="md:mt-[130px]"
            to="/studio/evaluate"
            onClick={handleEvaluationlink}
            isActive={false}
            borderBottom
          >
            <Sidebar.LinkContent
              linkTitle="Evaluate parts"
              description="Upload a part to see instant DFM checks, cost estimates and material properties."
            />
          </Sidebar.Link>
          <Sidebar.Link
            component={Link}
            dataTestId="sidebar-compare"
            to="/studio/compare"
            isActive={false}
          >
            <Sidebar.LinkContent
              linkTitle="Compare specs"
              description="Compare manufacturing processes and costs to find the best spec for your part."
            />
          </Sidebar.Link>
        </Sidebar.Panel>
        <Sidebar.Title>FACTORY</Sidebar.Title>
        <Sidebar.Link
          component={Link}
          dataTestId="sidebar-orders"
          icon="orders"
          isActive={isLinkActive('orders')}
          onClick={handleOrdersClick}
          text="Orders"
          to="/orders"
        />
        <Sidebar.Link
          component={Link}
          dataTestId="sidebar-quotes"
          icon="quote"
          onClick={handleQuotesLink}
          isActive={isLinkActive('quotes')}
          text="Quotes"
          to="/quotes"
        />
        <Sidebar.Link
          component={Link}
          dataTestId="sidebar-parts"
          icon="parts"
          onClick={handlePartsClick}
          isActive={isLinkActive('parts')}
          text="Parts"
          to="/parts"
        />
      </Sidebar.Section>
      <div className="mt-auto">
        {!isPortalSubscribed && (
          <SubscriptionModal
            action={<Sidebar.SubscribeButton className="mb-2" />}
            onActionHandler={() =>
              userAnalytics.track('Subscription - View Modal', { context: 'Sidebar' })
            }
          />
        )}

        <SidebarLiveChat />
      </div>
      <Sidebar.Section>
        <Sidebar.Panel icon="support" text="Support">
          <Sidebar.Link
            className="md:mt-auto"
            component={Link}
            dataTestId="sidebar-contact-us"
            to="/contact-us"
            onClick={handleContactUsClick}
            isActive={false}
            borderBottom
          >
            <Sidebar.LinkContent
              linkTitle="Contact us"
              description="Need any help? We’re here 8:00am - 6:00pm Central Time."
            />
          </Sidebar.Link>
          <Sidebar.Link
            className="md:before:border-none"
            to={MARKETING_URLS.Resources}
            dataTestId="sidebar-resources"
            onClick={handleResourcesClick}
            isActive={false}
            openNewWindow
            borderBottom
          >
            <Sidebar.LinkContent
              icon="external-link"
              linkTitle="Resources"
              description="See our articles, videos and guides for 3D Printing, Injection Molding, CNC and more."
            />
          </Sidebar.Link>
        </Sidebar.Panel>
      </Sidebar.Section>
      <Sidebar.UserPanel
        isUserSubscribed={isPortalSubscribed}
        onClick={handleAcctClick}
        to="/account/details"
        user={user}
        openNewWindow={false}
      >
        {user.isSupplierPortalUser && (
          <>
            <Sidebar.Title className="mt-auto mb-1">Accounts</Sidebar.Title>
            <Sidebar.Link
              className="flex items-center justify-between"
              component={Link}
              isActive={true}
              onClick={handleCustomerPortalClick}
              to="/dashboard"
            >
              Customer Portal
              <Sidebar.SelectionCircle selected />
            </Sidebar.Link>
            <Sidebar.Link
              borderBottom
              className="flex items-center justify-between"
              component={Link}
              isActive={false}
              onClick={handlePartnerPortalClick}
              openNewWindow
              to="/supplier-portal"
            >
              Partner Portal
              <Sidebar.SelectionCircle />
            </Sidebar.Link>
          </>
        )}
        <Sidebar.Link
          borderBottom
          className={classNames(['hidden md:flex', !user.isSupplierPortalUser && 'mt-auto'])}
          isActive={false}
          to="/account/details"
          onClick={handleAcctClick}
        >
          <Sidebar.LinkContent
            linkTitle="My account"
            description="Click here to view your account details."
          />
        </Sidebar.Link>
        <Sidebar.Link
          borderBottom
          dataTestId="sidebar-logout"
          isActive={false}
          onClick={handleSignoutClick}
          to="/logout"
        >
          <Sidebar.LinkContent
            linkTitle="Sign out"
            description="Click here to sign out of your account."
          />
        </Sidebar.Link>
      </Sidebar.UserPanel>
    </Sidebar>
  );
};

export default PortalSidebar;
