import { truncate } from 'lodash';
import React, { useMemo } from 'react';
import Select, { OptionsType } from 'react-select';

import { useLocations } from 'fr-shared/hooks';

interface LocationFiltersProps {
  locations?: number[]; // id
  manufacturingProcess?: string;
  onSelectLocation?: (location_ids: number[]) => void;
}

interface LocationOption {
  label: string;
  value: number;
}

const WorkOrdersLocationsFilter: React.FC<LocationFiltersProps> = ({
  manufacturingProcess,
  onSelectLocation,
  locations,
}: LocationFiltersProps) => {
  const onChange = useMemo(() => {
    return (selected: OptionsType<LocationOption>, _event_details: any) => {
      const ids = selected ? selected.map((location: LocationOption) => location.value) : [];
      onSelectLocation(ids);
    };
  }, [onSelectLocation]);

  const {
    data: availableLocations,
    isError,
    isLoading,
    error,
  } = useLocations({ manufacturingProcess });

  if (isLoading) return <div>Location list loading...</div>;
  if (isError) return <div>Error loading locations: {error.message}</div>;

  const options = availableLocations.map((location: Location): LocationOption => {
    return { label: location.name, value: location.id };
  });

  const selected =
    locations && options
      ? options.filter((el: LocationOption) => locations.includes(el.value))
      : [];

  const placeholder =
    options.length > 0
      ? `Example: ${truncate(options[0].label, { length: 20 })}`
      : 'Select a process';

  return (
    <div className="form-group">
      <label htmlFor="location-filter">Location</label>
      <Select
        className="mb-2"
        closeMenuOnSelect={true}
        delimiter={','}
        id="location-filter"
        isMulti={true}
        name="location-filter"
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        value={selected}
      />
    </div>
  );
};

export default WorkOrdersLocationsFilter;
