import { isEmpty, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { Button, Modal } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { PART_FAILURE_DISPOSITIONS, PART_FAILURE_RESOLUTIONS } from 'fr-shared/lib/part_failures';

import DispositionAssignModal from './DispositionAssignModal';
import { DISPOSITION_TABS } from './utils/reviewTabs';

const selectButtonText = dispositionTab => {
  switch (dispositionTab) {
    case DISPOSITION_TABS.REMAKE:
    case DISPOSITION_TABS.REWORK:
    case DISPOSITION_TABS.AWAITING_CUSTOMER:
    case DISPOSITION_TABS.SEARCH:
      return 'Update Disposition';
    case DISPOSITION_TABS.IN_QC_INVENTORY:
      return 'Resolve';

    case DISPOSITION_TABS.REVIEW:
      return 'Assign Disposition';
  }
};

const ReviewActionBar = ({
  selectedPartFailures,
  totalPartFailures,
  onUpdate,
  dispositionTab,
  history,
}) => {
  const [selectedDisposition, setSelectedDisposition] = useState('');
  const [selectedResolution, setSelectedResolution] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    setSelectedResolution('');
    setSelectedDisposition('');
  }, [dispositionTab]);

  const onOptionSelected = e => {
    dispositionTab === DISPOSITION_TABS.IN_QC_INVENTORY
      ? setSelectedResolution(e.target.value)
      : setSelectedDisposition(e.target.value);
  };
  const onOptionButtonClicked = () => {
    if (dispositionTab === DISPOSITION_TABS.IN_QC_INVENTORY) {
      onUpdate({
        action: selectedResolution,
        resolved_by_user_id: user.id,
        resolved_at: new Date(),
      });
    } else {
      setModalIsOpen(true);
    }
  };

  const onModalSubmit = note => {
    onUpdate({
      disposition: selectedDisposition,
      dispositioned_by_user_id: user.id,
      dispositioned_at: new Date(),
      disposition_notes: note,
    });
  };

  const onWorkOrderButtonClicked = () => {
    api.post('/work_orders', { work_order: { state: 'Draft' } }).then(res => {
      history.push({
        pathname: `/admin/work_orders/${res.data.id}`,
        state: { disposition: dispositionTab, partFailures: selectedPartFailures },
      });
    });
  };

  const showWorkOrderButton = [DISPOSITION_TABS.REMAKE, DISPOSITION_TABS.REWORK].includes(
    dispositionTab
  );
  const workOrderButtonText =
    dispositionTab === DISPOSITION_TABS.REMAKE
      ? 'Create Remake Work Order'
      : 'Create Rework Work Order';

  const selectValue =
    dispositionTab === DISPOSITION_TABS.IN_QC_INVENTORY
      ? selectedResolution
      : selectedDisposition;
  const selectData =
    dispositionTab === DISPOSITION_TABS.IN_QC_INVENTORY
      ? PART_FAILURE_RESOLUTIONS
      : values(PART_FAILURE_DISPOSITIONS);

  if (dispositionTab === DISPOSITION_TABS.RESOLVED) return null;

  return (
    <div className="flex flex-row mt-2 px-4">
      <div className="my-auto">
        <strong>Total:</strong>
        <div className="badge badge-primary py-1 px-1 ml-2">{totalPartFailures}</div>
      </div>
      <div className=" flex flex-1 justify-content-end">
        {selectedPartFailures.length > 0 && (
          <div
            data-testid="selection-indicator"
            className="text-dark flex align-items-center mr-3"
          >
            {selectedPartFailures.length} Row{selectedPartFailures.length > 1 ? 's' : ''} Selected
          </div>
        )}
        {showWorkOrderButton && (
          <Button
            data-testid="work-order-button"
            onClick={onWorkOrderButtonClicked}
            className="mr-2"
            disabled={selectedPartFailures.length === 0}
            color="primary"
            tooltip={
              selectedPartFailures.length === 0 ? 'Select parts to create a work order' : null
            }
          >
            {workOrderButtonText}
          </Button>
        )}
        <select
          data-testid="action-select"
          name="page_size"
          className="custom-select mr-2"
          onChange={onOptionSelected}
          value={selectValue}
        >
          <option value="" hidden>
            Select Action
          </option>
          <option value="" />
          {selectData.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        <Button
          disabled={isEmpty(selectValue) || selectedPartFailures.length === 0}
          color="primary"
          onClick={onOptionButtonClicked}
          tooltip={
            isEmpty(selectValue) || selectedPartFailures.length === 0
              ? 'Select parts and action to continue'
              : null
          }
        >
          {selectButtonText(dispositionTab)}
        </Button>
        <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(!modalIsOpen)}>
          {({ toggle }) => (
            <DispositionAssignModal
              disposition={selectedDisposition}
              toggle={toggle}
              partFailures={selectedPartFailures}
              onSubmit={onModalSubmit}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

ReviewActionBar.propTypes = {
  selectedPartFailures: PropTypes.array,
  totalPartFailures: PropTypes.number,
  onUpdate: PropTypes.func,
  dispositionTab: PropTypes.string,
  history: PropTypes.object,
};

export default ReviewActionBar;
