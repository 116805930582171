import React from 'react';

import { classNames } from 'portal/components';

interface Props {
  [propName: string]: unknown;
  right?: boolean;
  left?: boolean;
  className?: string;
  tooltip?: string;
  name?: string;
}

export const IconFont = ({ className, tooltip, left, right, name, ...attributes }: Props) => {
  const classes = classNames([`icon-${name}`, { 'mr-2': right }, { 'ml-2': left }, className]);
  return <i data-rh={tooltip} className={classes} {...attributes} />;
};

export default IconFont;
