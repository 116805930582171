import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, Button, GlobalErrorBoundary, Helmet, NotFound } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { useManufacturingProcess } from 'fr-shared/hooks';

import RejectionReasonForm from './RejectionReasonForm';

const RejectionReasonEdit = ({ match, history }) => {
  const [reason, setReason] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const { data: manufacturingProcessesData } = useManufacturingProcess();

  useEffect(() => {
    if (manufacturingProcessesData && manufacturingProcessesData.length) {
      setIsLoading(true);
      setHasError(false);
      setReason(null);

      api
        .get(`/rejection_reasons/${match.params.id}`)
        .then(res => {
          const { rejection_reason } = res.data;
          if (rejection_reason) {
            const reasonManufacturingProcessIds = rejection_reason.manufacturing_processes.map(
              p => p.id
            );
            const allMfgProcesses = manufacturingProcessesData.map(process => {
              return {
                id: process.id,
                label: process.name,
                value: reasonManufacturingProcessIds.includes(process.id),
              };
            });

            rejection_reason.manufacturing_processes = allMfgProcesses;

            setReason(rejection_reason);
          }
        })
        .catch(error => {
          if (error.response?.status !== 404) {
            setHasError(true);
            Sentry.captureMessage('Rejection Reason GET Failed');
            Sentry.setExtra('rejection_reason_id', match.params.id);
            Sentry.setExtra('error', error);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [match.params.id, manufacturingProcessesData]);

  const handleSubmit = (values, actions) => {
    const rejectReasonParams = {
      ...values,
      manufacturing_processes: values.manufacturing_processes.filter(p => p.value),
    };

    api
      .put(`/rejection_reasons/${match.params.id}`, { rejection_reason: rejectReasonParams })
      .then(() => {
        setAlert({
          message: 'Successfully updated Rejection Reason',
          color: 'success',
        });
        history.push('/admin/rejection_reasons/');
      })
      .catch(err => {
        if (err.response?.data) {
          const { errors, messages } = err.response.data;
          actions.setErrors({
            server: messages,
            ...errors,
          });
        }

        setAlert({
          message: 'Sorry, we were unable to update this rejection reason.',
          color: 'danger',
        });
      });
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this rejection reason?')) {
      api.delete(`/rejection_reasons/${match.params.id}`).then(() => {
        setAlert({
          message: 'Deleted Rejection Reason',
          color: 'success',
        });
        history.push('/admin/rejection_reasons/');
      });
    }
  };

  if (isLoading || !manufacturingProcessesData) return null;
  if (hasError) return <GlobalErrorBoundary />;
  if (!reason) return <NotFound />;

  return (
    <>
      <Helmet title="Rejection Reasons" />
      <Breadcrumb to="/admin/rejection_reasons">Rejection Reasons</Breadcrumb>
      <Breadcrumb to={`/admin/rejection_reasons/${match.params.id}/edit`}>
        Edit Rejection Reason
      </Breadcrumb>
      <div className="container">
        <div className="flex align-items-center py-3">
          <h2 className="mb-0">Edit Rejection Reason</h2>
          <div className="ml-auto">
            <Button color="secondary" onClick={e => history.goBack(e)}>
              Back
            </Button>
          </div>
        </div>
        <RejectionReasonForm
          reason={reason}
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
        />
      </div>
    </>
  );
};

RejectionReasonEdit.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default RejectionReasonEdit;
