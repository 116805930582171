import { Vector3 } from 'babylonjs';

/**
 * The position and orientation of the scene's arc rotate camera.
 */
export type ArcRotateCameraConfig = {
  alpha: number;
  beta: number;
  radius: number;
  target: Vector3;

  /**
   * Field of vertical view in radians.
   */
  fovY: number;

  /**
   * Update this value to reset the camera the defined orientation (without
   * needing to update any other fields). The actual value of this field doesn't
   * matter so long as it's changed.
   */
  moveCameraIndex: number;
};

export const newArcRotateCameraConfig = (
  alpha: number,
  beta: number,
  radius: number,
  target: Vector3,
  fovY: number,
  moveCameraIndex?: number
): ArcRotateCameraConfig => {
  return {
    alpha: alpha,
    beta: beta,
    radius: radius,
    target: target,
    fovY: fovY,
    // If moveCameraIndex is not provided, we'd like to default to a value that
    // will be unique on each different call. Ideally we would use a GUID, but
    // Date.now is probably good enough.
    moveCameraIndex: moveCameraIndex !== undefined ? moveCameraIndex : Date.now(),
  };
};
