import { useFormikContext } from 'formik';
import { get, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { api } from 'fr-shared/api';

import { FormFieldBase } from 'portal/components';

const FormLeadTime = ({ fieldPrefix, lineItem, onLineItemChange, ...props }) => {
  const formik = useFormikContext();

  /**
   * Gets the lead times from the server and stamps it onto the line item
   * so we know what the customer saw when quoting
   */
  useEffect(() => {
    if (!lineItem?.manufacturing_process_id) return;

    api.get('/customer_portal/lead_times').then(res => {
      const leadTimes = values(res.data);
      const leadTime = leadTimes.find(
        lt => lt.manufacturing_process_id === parseInt(lineItem?.manufacturing_process_id)
      );

      formik.setFieldValue(`${fieldPrefix}.lead_time`, leadTime || null);
      formik.setFieldValue(`${fieldPrefix}.lead_time_id`, get(leadTime, 'id', null));

      onLineItemChange({
        ...lineItem,
        lead_time: leadTime,
        lead_time_id: get(leadTime, 'id', null),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItem?.manufacturing_process_id]);

  if (!lineItem || !lineItem.lead_time) return null;

  return (
    <FormFieldBase label="Lead time" name={name} {...props}>
      <div>
        <strong>
          {get(lineItem, 'lead_time.min_days')}-{get(lineItem, 'lead_time.max_days')} business
          days
        </strong>
      </div>
    </FormFieldBase>
  );
};

FormLeadTime.propTypes = {
  fieldPrefix: PropTypes.string,
  lineItem: PropTypes.object,
  onLineItemChange: PropTypes.func,
};

export default FormLeadTime;
