import { useContext } from 'react';

import { UserContext } from 'fr-shared/context';

const usePortalSubscription = () => {
  const { user } = useContext(UserContext) as { user: User };

  return user.subscribed;
};

export default usePortalSubscription;
