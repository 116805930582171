import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Breadcrumb, GlobalErrorBoundary, Helmet, NotFound } from 'fr-shared/components';
import { UserConsumer } from 'fr-shared/context';
import { userCanEditOrder } from 'fr-shared/lib/user_roles';

import OrderForm from './components/OrderForm';
import { transformOrderFromLoad } from './utils/transforms';

export default class OrderEdit extends Component {
  static propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
  };

  state = {
    error: null,
    loading: false,
    order: null,
  };

  componentDidMount() {
    const { match } = this.props;
    const id = match.params.id;

    this.getOrders(id);
  }

  getOrders = id => {
    this.setState({ loading: true });
    api
      .get(`/orders/${id}`)
      .then(res => {
        this.setState({ order: transformOrderFromLoad(res.data) });
      })
      .catch(error => {
        if (error.response?.status !== 404) {
          Sentry.captureMessage('Order GET Failed');
          Sentry.setExtra('order_id', id);
          Sentry.setExtra('error', error);
          this.setState({ error: error });
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleSubmit = (form, instance) => {
    const { values } = form;

    const hasCC =
      values.payment_type === 'Customer Credit Card' || values.payment_terms === 'Credit Card';

    if (hasCC && instance) {
      instance
        .requestPaymentMethod()
        .then(res => {
          values.payment_method_nonce = res.nonce;
          this.updateOrder(form);
        })
        .catch(() => {
          Sentry.captureMessage('Braintree requestPaymentMethod failed');
        });
    } else {
      this.updateOrder(form);
    }
  };

  updateOrder = form => {
    const { history, location, match } = this.props;
    const { values, setSubmitting, setErrors } = form;

    const id = match.params.id;

    api
      .put(`/orders/${id}`, { order: values, send_confirmation: values.send_confirmation })
      .then(res => {
        this.setState({ order: res.data });

        if (location.search.includes('line_items=true')) {
          history.push(`/admin/orders/${res.data.id}?line_items=true`);
        } else {
          history.push(`/admin/orders/${res.data.id}`);
        }
      })
      .catch(err => {
        setSubmitting(false);
        if (err.response?.data) {
          const errors = err.response.data.errors;
          const messages = err.response.data.messages;
          setErrors({
            server: messages,
            ...errors,
            'customer_contact.id': errors && errors.customer_contact_id,
            'customer.id': errors && errors.customer_id,
          });
        } else {
          setErrors({
            server: ['An unexpected error occurred. Refresh and try again.'],
          });
        }

        window.scrollTo(0, 0);
      });
  };

  render() {
    const { error, loading, order } = this.state;
    const { history, match } = this.props;

    const id = match.params.id;

    if (loading) return null;
    if (error) return <GlobalErrorBoundary />;
    if (!order) return <NotFound />;

    const publicId = (order && order.public_id) || id;

    return (
      <>
        {order && (
          <UserConsumer>
            {({ user }) =>
              userCanEditOrder(user, order) ? (
                <>
                  <Helmet title={`Edit Order ${publicId}`} />
                  <Breadcrumb to="/admin/orders">Orders</Breadcrumb>
                  <Breadcrumb to={`/admin/orders/${id}`}>Order {publicId}</Breadcrumb>
                  <Breadcrumb to={`/admin/orders/${id}/edit`}>Edit</Breadcrumb>

                  {order && (
                    <OrderForm
                      title={`Edit Order ${publicId}`}
                      onSubmit={this.handleSubmit}
                      readonly={false}
                      initialValues={order}
                      history={history}
                      match={match}
                      isEditing={true}
                    />
                  )}
                </>
              ) : (
                <Redirect to={`/admin/orders/${id}`} />
              )
            }
          </UserConsumer>
        )}
      </>
    );
  }
}
