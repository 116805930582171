/* eslint-disable react/prop-types */
import { useTreatments } from '@splitsoftware/splitio-react';
import { Field, FieldArray } from 'formik';
import { get } from 'lodash';
import React from 'react';

import { Button, Card, Icon, LineItemProcess } from 'fr-shared/components';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';

import LineItemSelect from './LineItemSelect';

const LineItemPicker = ({
  data,
  disabled = false,
  id,
  label = true,
  name = 'line_items',
  onChange,
  onRemove,
  values,
  width = 800,
}) => {
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';
  const lineItemSelectPlaceholder = 'Search ' + { lineItemName } + 's...';
  const capLineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'Build Pack' : 'Line Item';

  const handleRemove = (index, fn, item) => {
    fn(index);
    onRemove && onRemove(item);
  };

  const getLabel = lineItem =>
    `FR${id} ${lineItem.description} ${get(lineItem.manufacturing_process, 'name')} ${get(
      lineItem.material,
      'name'
    )} ${get(lineItem.location, 'name')}`;

  return (
    <FieldArray name={name}>
      {({ remove, push }) => (
        <Card className="mb-4">
          <Card.Header>
            {capLineItemName}s
            <Card.Actions>
              <Button color="primary" size="md" onClick={() => push({})}>
                <Icon name="plus" className="mr-2" />
                Add {capLineItemName}
              </Button>
            </Card.Actions>
          </Card.Header>
          <Card.Body>
            {values[name] &&
              values[name].map((item, index) => (
                <LineItemSelect
                  md="1"
                  label={label && `Line ${index + 1}`}
                  name={`${name}.${index}.id`}
                  key={index}
                  placeholder={lineItemSelectPlaceholder}
                  onChange={onChange}
                  disabled={disabled}
                  components={{ SingleValue: LineItemSelected, Option: LineItemOption }}
                  styles={{
                    container: base => ({
                      ...base,
                      width: width,
                    }),
                  }}
                  className="flex justify-content-end"
                  options={data.map(lineItem => ({
                    id: id,
                    index: index + 1,
                    lineItem: lineItem,
                    label: getLabel(lineItem),
                    value: lineItem.id,
                  }))}
                >
                  <Field
                    type="number"
                    name={`${name}.${index}.quantity`}
                    className="form-control"
                    placeholder="Qty"
                    style={{ width: 80, height: 38 }}
                  />
                  <Button color="secondary" onClick={() => handleRemove(index, remove, item)}>
                    <Icon name="times" />
                  </Button>
                </LineItemSelect>
              ))}
          </Card.Body>
        </Card>
      )}
    </FieldArray>
  );
};

export const LineItemSelected = props => {
  const { className, data, cx, isDisabled, innerProps } = props;
  return (
    <div
      className={cx(
        {
          'single-value': true,
          'single-value--is-disabled': isDisabled,
        },
        'flex align-items-center',
        className
      )}
      {...innerProps}
    >
      <span className="font-size-sm text-muted mr-2">
        <strong>
          FR {data.id} - LINE ID: {data.lineItem.id}
        </strong>
      </span>
      <span className="mr-2">{get(data, 'lineItem.description')}</span>
      <span className="font-size-sm">{get(data, 'lineItem.comments')}</span>
    </div>
  );
};

export const LineItemOption = props => {
  const { cx, data, className, isDisabled, isFocused, isSelected, innerRef, innerProps } = props;

  return (
    <div
      {...innerProps}
      ref={innerRef}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className
      )}
    >
      <div className="flex w-100 align-items-center">
        <div className="text-muted font-size-sm mr-2">
          <strong>
            FR {data.id} - LINE ID: {data.lineItem.id}
          </strong>
        </div>
        <div className="mr-2">{data.lineItem.description}</div>
        <div className="font-size-sm">{data.lineItem.comments}</div>
        <div className="ml-auto">
          <span className="mr-2">
            <LineItemProcess data={data.lineItem} />
          </span>
          <span>
            <small className="text-muted mr-2">QTY</small>
            {data.lineItem.quantity}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LineItemPicker;
