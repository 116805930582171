// TODO: remove once we deprecate donut chart feature flag
export const COST_DRIVER_GENERIC_COPY: string =
  'There are three main cost drivers for manufactured parts: material consumption, equipment operation, and labor. Depending on the material choice, design of the part, and required secondary processes, one of these drivers will affect the price of your part more than the others.';

export const NRE_COPY_LINE_1: string =
  'Non-recurring engineering cost is proportionally high due to the low part quantity.';
export const NRE_COPY_LINE_2: string =
  "CAM programmers need to do the same amount of work whether you're ordering one or a hundred parts.";

export const CNC_ENGINEERING_MAX_COST_DRIVER: string =
  'Engineering is the highest cost driver for this part. Initial CAM programming requires significant upfront work, for any number of parts.';
export const CNC_LABOR_MAX_COST_DRIVER: string =
  'Labor is the highest cost driver for this part. Some CNC parts require multiple setups, post-processing, and machine preparation. This increases the labor needed to make them.';
export const CNC_EQUIPMENT_MAX_COST_DRIVER: string =
  'Equipment is the highest cost driver for this part. If parts need significant material removal or have complex geometries, longer machining times or more advanced machines could be required.';
export const CNC_MATERIAL_MAX_COST_DRIVER: string =
  'Material is the highest cost driver for this part. Excess material, specialized materials, and uncommon stock can increase your overall costs.';
export const ALL_YIELD_MAX_COST_DRIVER: string =
  'Low manufacturing yield is the highest cost driver for this part. Tight tolerances, complex geometries, and strict finish requirements can be difficult to manufacture and could increase your costs.';
export const MJF_DLS_FDM_SLA_ENGINEERING_MAX_COST_DRIVER: string =
  'Engineering is the highest cost driver for this part. Initial build preparation requires significant upfront work, for any number of parts.';
export const MJF_LABOR_MAX_COST_DRIVER: string =
  'Labor is the highest cost driver for this part. Some CNC parts require multiple setups, post-processing, and machine preparation. This increases the labor needed to make them.';
export const MJF_DLS_FDM_SLA_EQUIPMENT_MAX_COST_DRIVER: string =
  'Equipment is the highest cost driver for this part. Large parts take longer to print and increase your equipment costs.';
export const MJF_MATERIAL_MAX_COST_DRIVER: string =
  'Material is the highest cost driver for this part. Specialized materials can increase your overall costs.';
export const DLS_FDM_SLA_LABOR_MAX_COST_DRIVER: string =
  'Labor is the highest cost driver for this part. When parts are printed with supports, extra work is required to remove these and finish each part.';
export const DLS_FDM_SLA_MATERIAL_MAX_COST_DRIVER: string =
  'Material is the highest cost driver for this part. Specialized materials and large supported surfaces can increase your overall costs.';

export const CNC_ENGINEERING_COST_DRIVER_COPY: string =
  'Engineering cost is the time it takes to create initial CAM programming and production plans.';
export const CNC_LABOR_COST_DRIVER_COPY: string =
  'Labor cost is a combination of the machine preparation and post-processing your part will need.';
export const CNC_EQUIPMENT_COST_DRIVER_COPY: string =
  'Equipment cost is a combination of the machine type, setup time, and cutting time your part will need.';
export const CNC_MATERIAL_COST_DRIVER_COPY: string =
  'Material cost is the price of your chosen material.';
export const ALL_YIELD_COST_DRIVER_COPY: string =
  "Yield is the acceptance rate of manufactured parts. A lower yield means it's more difficult to make parts and more attempts are needed to fulfill your order to a high quality. Yield is influenced by tolerances, part complexity, and finish requirements.";
export const MJF_ENGINEERING_COST_DRIVER_COPY: string =
  'Engineering cost is the time required for initial build packing and preparation.';
export const MJF_LABOR_COST_DRIVER_COPY: string =
  'Labor cost is a combination of powder removal and other post-processing steps.';
export const MJF_EQUIPMENT_COST_DRIVER_COPY: string =
  'Equipment cost is a combination of the machine type, number of builds, and build packing efficiency for your part.';
export const MJF_MATERIAL_COST_DRIVER_COPY: string =
  'Material cost is the price of consumed material required to make your part.';
export const DLS_FDM_SLA_ENGINEERING_COST_DRIVER_COPY: string =
  'Engineering cost is the time required for initial build preparation and any printing supports.';
export const DLS_FDM_SLA_LABOR_COST_DRIVER_COPY: string =
  'Labor cost is a combination of support removal and other post-processing steps.';
export const DLS_FDM_SLA_EQUIPMENT_COST_DRIVER_COPY: string =
  'Equipment cost is a combination of the machine type and print time your part will need.';
export const DLS_FDM_SLA_MATERIAL_COST_DRIVER_COPY: string =
  'Material cost is the price of consumed material required to make your part, including any printing supports.';
