import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Tabs } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';

import { StudioRoutes } from '../../viewer';
import styles from './ViewerTabs.module.css';

const ViewerTabs = ({
  onTabChange,
  routes,
}: {
  onTabChange?: () => void;
  routes: StudioRoutes;
}) => {
  const userAnalytics = useUserAnalyticsContext();
  const location = useLocation();
  const history = useHistory();

  const handleTabClick = (route: string) => {
    onTabChange && onTabChange();
    history.push(route);
  };

  const tabs = [
    { name: 'Materials', url: routes.Materials },
    { name: 'DFM Checks', url: routes.DFMChecks },
    { name: 'Costs', url: routes.Costs },
  ];

  // We can't use `to` in the <Tabs /> component  because we
  // need `onClick`, so we have to set the active tab manually
  const VIEWER_TABS = tabs.map((tab: { name: string; url: string }) => ({
    name: tab.name,
    active: location.pathname.includes(tab.url),
    onClick: () => {
      userAnalytics.track(`Studio - ${tab.name} - in Tab`);
      handleTabClick(tab.url);
    },
  }));

  return <Tabs className={styles.ViewerTabs} tabs={VIEWER_TABS} />;
};

export default ViewerTabs;
