import { useTreatments } from '@splitsoftware/splitio-react';
import { FieldArray, useFormikContext } from 'formik';
import { isEqual, uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  Button,
  Checkbox,
  FormCurrency,
  FormFastField,
  FormFieldBase,
  FormNumber,
  Icon,
  Modal,
} from 'fr-shared/components';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';

import {
  anyItemsSelected,
  countItemsSelected,
  everyItemSelected,
  findSelectedItems,
  hasPartialSelection,
} from '../../costing_requests/utils/selected';
import { QuoteFormContext } from './QuoteFormContext';

const MIXED_LABEL = 'Mixed Selection...';

const getDiscounts = item => item.adjustments.filter(({ type }) => type === 'discount');
const getExtras = item => item.adjustments.filter(({ type }) => type === 'extra');

const initialValuesFromLineItems = lineItems => {
  const [firstItem, ...restItems] = lineItems;

  const initialValues = {
    discounts: [{ mixed: true, type: 'discount' }],
    extras: [{ mixed: true, type: 'extra' }],
    marginPercentage: '',
    marginPercentageMixed: true,
    notes: '',
    notesMixed: true,
    offeredLeadTime: '',
    offeredLeadTimeMixed: true,
  };

  const firstItemDiscounts = getDiscounts(firstItem);
  if (restItems.every(item => isEqual(getDiscounts(item), firstItemDiscounts))) {
    initialValues.discounts = firstItemDiscounts;
  }

  const firstItemExtras = getExtras(firstItem);
  if (restItems.every(item => isEqual(getExtras(item), firstItemExtras))) {
    initialValues.extras = firstItemExtras;
  }

  const firstItemMarginPercentage = firstItem.margin_percentage ?? '';
  if (restItems.every(item => (item.margin_percentage ?? '') === firstItemMarginPercentage)) {
    initialValues.marginPercentage = firstItemMarginPercentage;
    initialValues.marginPercentageMixed = false;
  }

  const firstItemNotes = firstItem.notes ?? '';
  if (restItems.every(item => (item.notes ?? '') === firstItemNotes)) {
    initialValues.notes = firstItemNotes;
    initialValues.notesMixed = false;
  }

  const firstItemOfferedLeadTime = firstItem.offered_lead_time ?? '';
  if (restItems.every(item => (item.offered_lead_time ?? '') === firstItemOfferedLeadTime)) {
    initialValues.offeredLeadTime = firstItemOfferedLeadTime;
    initialValues.offeredLeadTimeMixed = false;
  }

  return initialValues;
};

const bulkTransformLineItems = (lineItems, bulkEdits) => {
  return lineItems.map(item => {
    const lineItemWithEdits = { ...item };

    const extraMixed = bulkEdits.extras.find(extra => extra.mixed);
    if (extraMixed && !extraMixed.dirty) {
      // Append new extras
      lineItemWithEdits.adjustments.push(
        ...bulkEdits.extras
          .filter(extra => !extra.mixed)
          .map(({ id, amount, description, type }) => ({
            id: id ?? null,
            amount,
            description,
            type,
          }))
      );
    } else {
      // Replace all extras
      lineItemWithEdits.adjustments = getDiscounts(lineItemWithEdits).concat(
        bulkEdits.extras.map(({ id, amount, description, type }) => ({
          id: id ?? null,
          amount,
          description,
          type,
        }))
      );
    }

    const discountMixed = bulkEdits.discounts.find(discount => discount.mixed);
    if (discountMixed && !discountMixed.dirty) {
      // Append new discounts
      lineItemWithEdits.adjustments.push(
        ...bulkEdits.discounts
          .filter(discount => !discount.mixed)
          .map(({ id, amount, description, type }) => ({
            id: id ?? null,
            amount,
            description,
            type,
          }))
      );
    } else {
      // Replace all discounts
      lineItemWithEdits.adjustments = getExtras(lineItemWithEdits).concat(
        bulkEdits.discounts.map(({ id, amount, description, type }) => ({
          id: id ?? null,
          amount,
          description,
          type,
        }))
      );
    }

    if (bulkEdits.marginPercentageDirty) {
      lineItemWithEdits.margin_percentage = bulkEdits.marginPercentage;
    }

    if (bulkEdits.notesDirty) {
      lineItemWithEdits.notes = bulkEdits.notes;
    }

    if (bulkEdits.offeredLeadTimeDirty) {
      lineItemWithEdits.offered_lead_time = bulkEdits.offeredLeadTime;
    }

    return lineItemWithEdits;
  });
};

const BulkEditModal = ({ lineItems, onUpdate }) => {
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';
  const shouldSetupForm = useRef(true);
  const [modalOpen, setModalOpen] = useState(false);
  const formik = useFormikContext();

  const bulk = formik.values.bulk ?? {};
  const discounts = bulk.discounts ?? [];
  const extras = bulk.extras ?? [];

  /**
   * When the modal is opened, update the bulk values once with their initial values.
   * When the modal is closed, shouldSetupForm is reset to true.
   */
  useEffect(() => {
    if (modalOpen && shouldSetupForm.current) {
      const initialValues = initialValuesFromLineItems(lineItems);
      formik.setFieldValue('bulk', initialValues);
      shouldSetupForm.current = false;
    }
  });

  const closeModal = () => {
    formik.setFieldValue('bulk', {});
    setModalOpen(false);
    shouldSetupForm.current = true;
  };

  const handleBlurDiscount = index => {
    const newValue = formik.values.bulk.discounts[index].amount;

    if (newValue) {
      formik.setFieldValue(`bulk.discounts.${index}.amount`, (newValue * -1).toString());
    }
  };
  const handleChangeDiscountDescription = (index, value) => {
    formik.setFieldValue(`bulk.discounts.${index}.description`, value);
    handleDirtyDiscount(index);
  };
  const handleChangeExtraDescription = (index, value) => {
    formik.setFieldValue(`bulk.extras.${index}.description`, value);
    handleDirtyExtra(index);
  };
  const handleDirtyDiscount = index =>
    formik.setFieldValue(`bulk.discounts.${index}.dirty`, true);
  const handleDirtyExtra = index => formik.setFieldValue(`bulk.extras.${index}.dirty`, true);

  return (
    <>
      <Button color="primary" className="mr-2" onClick={() => setModalOpen(true)}>
        <Icon name="edit" right /> Bulk Edit
      </Button>

      {modalOpen && (
        <Modal size="lg" isOpen={true} scrollable={true} toggle={closeModal}>
          <Modal.Header
            title={
              <span>
                Bulk Edit{' '}
                <small className="text-lowercase ml-2 font-weight-light">
                  {countItemsSelected(lineItems)} {lineItemName}s selected
                </small>
              </span>
            }
            onClose={closeModal}
          />
          <div className="modal-body p-3">
            <FormNumber
              append="%"
              inputClassName="col-5 col-md-5 col-sm-3"
              label="Margin"
              max={99.99}
              min={0}
              name="bulk.marginPercentage"
              onChange={() => formik.setFieldValue('bulk.marginPercentageDirty', true)}
              placeholder={bulk.marginPercentageMixed ? MIXED_LABEL : ''}
              step={0.01}
            />
            <FormFieldBase label="Extra Charges">
              <FieldArray name="bulk.extras">
                {({ push, remove }) => (
                  <>
                    {extras.map((extra, index) => (
                      <div key={index} className="flex mb-1">
                        <FormCurrency
                          className="mb-0 mr-1"
                          placeholder={extra.mixed ? MIXED_LABEL : ''}
                          name={`bulk.extras.${index}.amount`}
                          onChange={() => handleDirtyExtra(index)}
                        />
                        <FormFastField
                          className="mb-0 mr-1 w-75"
                          placeholder={extra.mixed ? MIXED_LABEL : 'Notes'}
                          name={`bulk.extras.${index}.description`}
                          onChange={e => handleChangeExtraDescription(index, e.target.value)}
                        />
                        <Button
                          id={`delete-extra-${index}`}
                          color="danger"
                          outline={true}
                          onClick={() => remove(index)}
                        >
                          <Icon name="times" />
                        </Button>
                      </div>
                    ))}
                    <Button
                      color="light"
                      onClick={() =>
                        push({ amount: '', description: '', dirty: true, type: 'extra' })
                      }
                    >
                      <Icon name="plus" /> Add extra charge
                    </Button>
                  </>
                )}
              </FieldArray>
            </FormFieldBase>
            <FormFieldBase label="Unit Price Discounts">
              <FieldArray name="bulk.discounts">
                {({ push, remove }) => (
                  <>
                    {discounts.map((discount, index) => (
                      <div key={index} className="flex mb-1">
                        <FormCurrency
                          className="mb-0 mr-1"
                          placeholder={discount.mixed ? MIXED_LABEL : ''}
                          name={`bulk.discounts.${index}.amount`}
                          onBlur={() => handleBlurDiscount(index)}
                          onChange={() => handleDirtyDiscount(index)}
                        />
                        <FormFastField
                          className="mb-0 mr-1 w-75"
                          placeholder={discount.mixed ? MIXED_LABEL : 'Notes'}
                          name={`bulk.discounts.${index}.description`}
                          onChange={e => handleChangeDiscountDescription(index, e.target.value)}
                        />
                        <Button
                          id={`delete-discount-${index}`}
                          color="danger"
                          outline={true}
                          onClick={() => remove(index)}
                        >
                          <Icon name="times" />
                        </Button>
                      </div>
                    ))}
                    <Button
                      color="light"
                      onClick={() =>
                        push({ amount: '', description: '', dirty: true, type: 'discount' })
                      }
                    >
                      <Icon name="plus" /> Add unit price discount
                    </Button>
                  </>
                )}
              </FieldArray>
            </FormFieldBase>
            <FormNumber
              append="business days"
              inputClassName="col-5 col-md-5 col-sm-3"
              label="Lead Time"
              min={1}
              name="bulk.offeredLeadTime"
              onChange={() => formik.setFieldValue('bulk.offeredLeadTimeDirty', true)}
              placeholder={bulk.offeredLeadTimeMixed ? MIXED_LABEL : ''}
            />
            <FormFastField
              component="textarea"
              label="Notes"
              name="bulk.notes"
              onChange={e => {
                formik.setFieldValue('bulk.notesDirty', true);
                formik.setFieldValue('bulk.notes', e.target.value);
              }}
              placeholder={bulk.notesMixed ? MIXED_LABEL : ''}
            />
            <div className="flex justify-content-end border-top pt-3 mt-3">
              <Button color="secondary" outline className="uppercase px-4" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                color="primary"
                className="ml-3 px-4 uppercase"
                onClick={() => {
                  const transformedLineItems = bulkTransformLineItems(lineItems, bulk);
                  closeModal();
                  onUpdate(transformedLineItems);
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

BulkEditModal.propTypes = {
  lineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

const QuoteFormBulkEditor = () => {
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';

  const { setValuesAndPushToChannel } = useContext(QuoteFormContext);
  const formik = useFormikContext();
  const [bulkSelected, setBulkSelected] = useState(false);

  const lineItems = formik.values.line_items.map(li => ({
    ...li,
    _id: li._id ?? uniqueId('quote-line-item-'),
    adjustments: li.adjustments ?? [],
  }));

  const bulkSetLineItemValue = values =>
    formik.setFieldValue(
      'line_items',
      lineItems.map(li => ({ ...li, ...values }))
    );

  const bulkSetItemSelection = val => {
    setBulkSelected(val);
    bulkSetLineItemValue({ selected: val });
  };

  const bulkUpdateLineItems = newItems => {
    const updatedItemsRepo = new Map(newItems.map(item => [item._id, item]));

    setValuesAndPushToChannel({
      ...formik.values,
      line_items: lineItems.map(ogItem => {
        const updatedItem = updatedItemsRepo.get(ogItem._id);
        return updatedItem ? updatedItem : ogItem;
      }),
    });
  };

  /**
   * Watches the line items and updates the state of the bulk checkbox based
   * on the selected state of all line items
   * @param {array} lineItems - array of line items
   */
  useEffect(() => {
    if (lineItems.length && !bulkSelected && everyItemSelected(lineItems)) {
      bulkSetItemSelection(true);
    } else if (lineItems.length && bulkSelected && !anyItemsSelected(lineItems)) {
      bulkSetItemSelection(false);
    }
  });

  return (
    <div className="flex align-items-center py-2 sticky-top bg-light mb-2">
      <div className="mb-0 mr-auto flex align-items-center">
        <Checkbox
          name="bulk_actions"
          label="Bulk Items Selection"
          className="d-inline mb-0"
          srOnlyLabel={true}
          indeterminate={hasPartialSelection(lineItems)}
          value={bulkSelected}
          onChange={e => {
            bulkSetItemSelection(e.target.checked);
          }}
        />
        {anyItemsSelected(lineItems) && (
          <BulkEditModal
            lineItems={findSelectedItems(lineItems)}
            onUpdate={bulkUpdateLineItems}
          />
        )}
        <span className="h4 d-inline-block text-muted">
          {anyItemsSelected(lineItems)
            ? `${countItemsSelected(lineItems)} ${lineItemName}s selected`
            : `Select ${lineItemName}s to reveal bulk actions`}
        </span>
      </div>
    </div>
  );
};

export default QuoteFormBulkEditor;
