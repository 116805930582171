import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { api } from 'fr-shared/api';
import {
  Alert,
  Breadcrumb,
  Button,
  FormCheckbox,
  Icon,
  LineItemPicker,
  Link,
  OrderPicker,
} from 'fr-shared/components';

class PackingSlips extends Component {
  static propTypes = {
    match: PropTypes.object,
    setFieldValue: PropTypes.func,
    handleChange: PropTypes.func,
    values: PropTypes.object,
  };

  state = {
    loading: false,
    downloadUrl: null,
    cocUrl: null,
    lineItems: null,
    order: null,
    errors: null,
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    id && this.getOrder(id);
  }

  getOrder = id => {
    api.get(`/orders/${id}`).then(res => {
      const order = res.data;
      const lineItems = order.line_items;

      lineItems.forEach((value, i) => {
        this.props.setFieldValue(`line_items.${i}.id`, value.id);
        this.props.setFieldValue(`line_items.${i}.quantity`, value.quantity);
      });

      this.setState({ order, lineItems });
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ errors: false, loading: true });

    const shipTo = this.props.values.shipToChicago ? 'Chicago' : 'Customer';
    const packing_slip = { ...this.props.values, ship_to: shipTo };

    api
      .post('/packing_slip', { packing_slip })
      .then(res => {
        this.setState({
          loading: false,
          errors: false,
          packingSlipUrl: res.data.packing_slip_path,
          cocUrl: res.data.coc_path,
        });
        window.open(res.data.packing_slip_path);
        window.open(res.data.coc_path);
      })
      .catch(err => {
        const errors = err.response.data.messages;
        this.setState({
          errors: errors,
          loading: false,
          packingSlipUrl: false,
          cocUrl: false,
        });
      });
  };

  handleOrderChange = e => {
    if (e) {
      e.name = 'public_id';

      const order = e.order;
      const lineItems = e.order && e.order.line_items;

      this.setState({
        order,
        lineItems,
        packingSlipUrl: null,
        cocUrl: null,
        errors: null,
      });
      this.props.setFieldValue('line_items', null);

      lineItems &&
        lineItems.length > 0 &&
        lineItems.forEach((value, i) => {
          this.props.setFieldValue(`line_items.${i}.id`, value.id);
          this.props.setFieldValue(`line_items.${i}.quantity`, value.quantity);
        });

      this.props.handleChange({ target: e });
    } else {
      this.setState({ order: null });
    }
  };

  handleLineChange = e => {
    this.setState({ packingSlipUrl: null, cocUrl: null });
    this.props.handleChange({ target: e });
  };

  render() {
    const { values } = this.props;

    const { errors, order, loading, lineItems, cocUrl, packingSlipUrl } = this.state;

    return (
      <>
        <Breadcrumb to="/admin/packing_slips">Packing Slips</Breadcrumb>

        <div className="page-header">
          <div className="container">
            <div>
              <h2>Packing Slip &amp; Certificate of Conformance</h2>
              {!order && (
                <div className="text-muted">
                  Click <i>Generate Packing Slip</i> from an Order page to prefill the form, or
                  choose below
                </div>
              )}
            </div>
            {order && (
              <div className="page-actions">
                <Button color="secondary" to={`/admin/orders/${order.id}`}>
                  Back
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className="container">
          {order && (
            <h4 className="text-muted">
              Order{' '}
              <Link to={`/admin/orders/${order.id}`}>
                FR
                {order.public_id}
              </Link>{' '}
              - {order.customer.name}
            </h4>
          )}
          {errors && (
            <Alert color="danger">
              <Icon name="exclamation-triangle" className="mr-2" />
              There was a problem with your request. Please fix the following issues and try
              again.
              <ul className="my-2">
                {errors.map(e => (
                  <li key={e}>{e}</li>
                ))}
              </ul>
            </Alert>
          )}

          {packingSlipUrl && (
            <Alert color="success">
              Packing Slip and Certificate created successfully.
              <div>
                <Link to={{ pathname: packingSlipUrl }} openNewWindow>
                  Click here to download <strong>packing slip</strong>{' '}
                  {order && `for FR${order.public_id}`}
                </Link>
              </div>
              <div>
                <Link to={{ pathname: cocUrl }} openNewWindow>
                  Click here to download <strong>certificate of conformance</strong>{' '}
                  {order && `for FR${order.public_id}`}
                </Link>
              </div>
            </Alert>
          )}

          <form onSubmit={this.handleSubmit}>
            <OrderPicker name="public_id" onChange={this.handleOrderChange} />
            {order && (
              <div className="my-4">
                <LineItemPicker
                  id={order.public_id}
                  values={values}
                  onChange={this.handleLineChange}
                  data={lineItems}
                />
                <div className="mb-3 text-right">
                  <FormCheckbox
                    name="ship_to_chicago"
                    label="Ship to Chicago"
                    className="d-inline-block"
                  />
                </div>
                <div className="pb-5 text-right">
                  <Button type="submit" color="success" loading={loading}>
                    Generate
                  </Button>
                </div>
              </div>
            )}
          </form>
        </div>
      </>
    );
  }
}

const formik = {
  // default values of form
  mapPropsToValues: () => ({
    line_items: [{}],
    ship_to_chicago: false,
  }),
};

export default withFormik(formik)(PackingSlips);
