import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { v4 as uuidv4 } from 'uuid';

import { classNames } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';

import { fetchCostDriverData } from 'portal/lib/autoquote';

import styles from './CostCurves.module.css';
import CostDrivers from './CostDrivers';

const CostCurves = ({
  className,
  insight,
  height,
  showHeader = true,
  partFileRevision,
  onCreateFeasibilityReport,
}) => {
  const userAnalytics = useUserAnalyticsContext();
  const [costDrivers, setCostDrivers] = useState(insight?.cost_drivers);
  const [maxCostDriver, setMaxCostDriver] = useState(insight?.max_cost_driver);
  const [quantity, setQuantity] = useState(insight?.cost_drivers_quantity);
  const [caratPosition, setCaratPosition] = useState(null);

  if (!insight || !insight.cost_curve_points) return null;

  const {
    cost_curve_points: costCurvePointsMoney,
    optimal_quantity: optimalQuantity,
    manufacturing_process: manufacturingProcess,
    machine_type: machineType,
    machine_time: machineTime,
    autoquote_id: autoquoteId,
  } = insight;

  const costCurvePoints = costCurvePointsMoney.map(point => ({
    predicted_cost: parseFloat(point.predicted_cost.amount),
    quantity: point.quantity,
  }));

  const fetchCostData = selectedQuantity => {
    if (autoquoteId && selectedQuantity !== quantity) {
      fetchCostDriverData(autoquoteId, selectedQuantity)
        .then(res => {
          setCostDrivers(res.cost_drivers);
          setMaxCostDriver(res.max_cost_driver);
          setQuantity(res.cost_drivers_quantity);
        })
        .catch(() => {
          setCostDrivers(null);
          setMaxCostDriver(null);
          setQuantity(null);
        });
    }
  };

  const carat = caratPosition ? (
    <div style={{ marginLeft: `${caratPosition - 10}px` }} className={styles.Carat} />
  ) : null;

  return (
    <>
      <div>
        {showHeader && <h3 className="pt-3 px-3 pb-0">Cost Curve</h3>}

        <div className={classNames(['my-3', className])}>
          <div className={styles.Chart} style={{ height: height }}>
            <CostCurveGraph
              data={costCurvePoints}
              height={height}
              optimalQuantity={optimalQuantity}
              onBarSelect={data => {
                setCaratPosition(data.x + data.width / 2);
                userAnalytics.track('Cost Insights - Clicked Cost Curve');
                fetchCostData(data.quantity);
              }}
            />
          </div>
        </div>

        {maxCostDriver && (
          <>
            {carat}
            <div className={styles.CostCurves}>
              <CostDrivers
                manufacturingProcess={manufacturingProcess}
                quantity={quantity}
                costCurveDriver={maxCostDriver}
                costDrivers={costDrivers}
                costCurvePoints={costCurvePoints}
                machineType={machineType}
                machineTime={machineTime}
                onCreateFeasibilityReport={onCreateFeasibilityReport}
                partFileRevision={partFileRevision}
                isFeasibilityReport={!onCreateFeasibilityReport}
              />
            </div>
          </>
        )}
      </div>
      <div className="border-top font-size-sm p-2 mt-8">
        * Reported economic insights are subject to change based upon any required design changes
        to improve manufacturability.
      </div>
    </>
  );
};

CostCurves.propTypes = {
  className: PropTypes.string,
  insight: PropTypes.object,
  height: PropTypes.number,
  showHeader: PropTypes.bool,
  onCreateFeasibilityReport: PropTypes.func,
  partFileRevision: PropTypes.object,
};

const TOOLTIP_WIDTH = 130;
const TOOLTIP_MARKER_HEIGHT = 12;

export const CostCurveGraph = ({
  colorForAllBarBgs,
  barBgScale = 1,
  barCategoryGap = 4,
  barRadius = 8,
  className = '',
  data,
  height,
  optimalQuantity,
  onBarSelect,
  xAxisDx = -31,
  xAxisDy = 12,
  xValueShortened = false,
  xValueFontSize = '14px',
  xValueTextAnchor = 'start',
  yAxisHidden = false,
  tooltipMarkerHidden = false,
  tooltipTrigger = 'click',
}) => {
  const [currentBarData, setCurrentBarData] = useState(null);
  const [currentHoverIndex, setCurrentHoverIndex] = useState(null);
  const [currentBarIndex, setCurrentBarIndex] = useState(null);
  const graphIdRef = useRef(uuidv4());
  const barChartRef = useRef(null);

  useEffect(() => {
    setCurrentHoverIndex(null);
    if (tooltipTrigger === 'hover') {
      handleBarDeselect();
    }
  }, [data, tooltipTrigger]);

  useEffect(() => {
    const formattedData = barChartRef.current?.state?.formatedGraphicalItems[0]?.props?.data;
    if (formattedData) {
      const optimalDataIndex = formattedData.findIndex(e => e.quantity === optimalQuantity);
      if (optimalDataIndex >= 0) {
        handleBarSelect(formattedData[optimalDataIndex], optimalDataIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barChartRef.current, optimalQuantity]);

  const handleBarSelect = (data, index) => {
    if (onBarSelect) onBarSelect(data);
    if (data.predicted_cost > 0) {
      setCurrentBarData(data);
      setCurrentBarIndex(index);
    }
  };

  const handleBarDeselect = () => {
    setCurrentBarData(null);
    setCurrentBarIndex(null);
  };

  // Customize the pattern scale and uniquely identify it within the entire DOM via graphIdRef.current namespace
  const hatchTransform = `rotate(45) scale(${barBgScale} ${barBgScale})`;
  const patternId = `diagonalHatch-${graphIdRef.current}`;

  return (
    <ResponsiveContainer
      className={classNames([styles.ChartContainer, className])}
      height={height}
      width="100%"
    >
      <BarChart data={data} barCategoryGap={barCategoryGap} ref={barChartRef}>
        {/* Define a striped pattern within the svg context (BarChart) to be referenced as fill pattern for the background */}
        <pattern
          id={patternId}
          width="4"
          height="4"
          patternTransform={hatchTransform}
          patternUnits="userSpaceOnUse"
        >
          <rect width="2" height="4" transform="translate(0, 0)" fill="#191e25"></rect>
        </pattern>
        <XAxis
          axisLine={false}
          tickLine={false}
          dataKey="quantity"
          height={42}
          interval={0}
          label={{
            value: 'Number of units',
            position: 'insideBottomLeft',
            offset: 0,
            dy: -12 + xAxisDy,
          }}
          padding={{ right: 0 }}
          tick={
            <CustomizedAxisTick
              dx={xAxisDx}
              dy={xAxisDy}
              fontSize={xValueFontSize}
              shortenDisplayText={xValueShortened}
              textAnchor={xValueTextAnchor}
            />
          }
        />
        {!yAxisHidden && (
          <YAxis
            axisLine={false}
            tickLine={false}
            stroke="#646E7C"
            tickCount={6}
            tickFormatter={tick => `\uFE69${tick}`}
          />
        )}
        {currentBarData && (
          <Tooltip
            wrapperStyle={{ visibility: 'visible' }}
            content={CostCurveTooltip}
            optimalQuantity={optimalQuantity}
            hideMarker={tooltipMarkerHidden}
            data={currentBarData}
            offset={0}
            animationDuration={0}
            isAnimationActive={false}
            cursor={{ fill: 'transparent' }}
            position={{
              x: currentBarData.x - TOOLTIP_WIDTH / 2 + currentBarData.width / 2,
              y:
                optimalQuantity === currentBarData.quantity
                  ? currentBarData.y - 108 + (tooltipMarkerHidden && TOOLTIP_MARKER_HEIGHT)
                  : currentBarData.y - 66 + (tooltipMarkerHidden && TOOLTIP_MARKER_HEIGHT),
            }}
          />
        )}
        <Bar
          background={
            colorForAllBarBgs
              ? { fill: colorForAllBarBgs }
              : { fill: `url(#${patternId})`, radius: [barRadius, barRadius, 0, 0] }
          }
          className={styles.Bar}
          dataKey="predicted_cost"
          radius={[barRadius, barRadius, 0, 0]}
          onMouseOver={(data, index) => {
            setCurrentHoverIndex(index);
            if (tooltipTrigger === 'hover') {
              handleBarSelect(data, index);
            }
          }}
          onClick={(data, index) => {
            if (tooltipTrigger === 'click') {
              handleBarSelect(data, index);
            }
          }}
          onMouseOut={() => {
            setCurrentHoverIndex(null);
            if (tooltipTrigger === 'hover') {
              handleBarDeselect();
            }
          }}
        >
          {data.map((entry, index) => {
            const barSelected = index === currentHoverIndex || index === currentBarIndex;
            const barCellState =
              entry.quantity === optimalQuantity
                ? 'BarCell--optimal'
                : barSelected
                ? 'BarCell--hover'
                : 'BarCell--normal';

            return (
              <Cell className={classNames([styles.BarCell, styles[barCellState]])} key={index} />
            );
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

CostCurveGraph.propTypes = {
  colorForAllBarBgs: PropTypes.string,
  barBgScale: PropTypes.number,
  barCategoryGap: PropTypes.number,
  barRadius: PropTypes.number,
  className: PropTypes.string,
  data: PropTypes.array,
  height: PropTypes.number,
  optimalQuantity: PropTypes.number,
  onBarSelect: PropTypes.func,
  xAxisDx: PropTypes.number,
  xAxisDy: PropTypes.number,
  xValueShortened: PropTypes.bool,
  xValueTextAnchor: PropTypes.string,
  xValueFontSize: PropTypes.string,
  yAxisHidden: PropTypes.bool,
  tooltipMarkerHidden: PropTypes.bool,
  tooltipTrigger: PropTypes.oneOf(['hover', 'click']),
};

const CostCurveTooltip = ({ data, optimalQuantity, hideMarker = false }) => {
  if (!data || data.length === 0 || Object.keys(data).length === 0) return null;

  const cost = data?.predicted_cost.toFixed(2);

  return (
    <div className="flex flex-column print:hidden">
      <div className={classNames(['react-hint relative', styles.Tooltip])}>
        <div className={classNames(['react-hint__content', styles.TooltipContent])}>
          {data.quantity === optimalQuantity && <div className={styles.Optimal}>Optimal</div>}
          <div className="text-xs">
            <strong className="text-base">
              <span className={styles.Dollar}>$</span>
              {cost}
            </strong>
            &nbsp;per unit
          </div>
        </div>
      </div>
      {!hideMarker && (
        <div
          className={classNames({
            'border-gray-300': data.quantity !== optimalQuantity,
            [styles.Circle]: true,
          })}
        ></div>
      )}
    </div>
  );
};

CostCurveTooltip.propTypes = {
  data: PropTypes.object,
  optimalQuantity: PropTypes.number,
  hideMarker: PropTypes.bool,
};

const getCustomDisplayText = (value, shortenDisplayText) => {
  if (shortenDisplayText) {
    if (value >= 1000) {
      return (value / 1000).toFixed(0) + 'K';
    }
  }
  return value.toString();
};

const CustomizedAxisTick = ({
  x,
  y,
  dx = -37,
  dy = 12,
  payload,
  fontSize = '14px',
  shortenDisplayText = false,
  textAnchor = 'start',
}) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={dx}
        dy={dy}
        textAnchor={textAnchor}
        fill="#646E7C"
        fontSize={fontSize}
      >
        {getCustomDisplayText(payload.value, shortenDisplayText)}
      </text>
    </g>
  );
};

CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  dx: PropTypes.number,
  dy: PropTypes.number,
  payload: PropTypes.oneOf([PropTypes.object, PropTypes.array]),
  fontSize: PropTypes.string,
  shortenDisplayText: PropTypes.bool,
  textAnchor: PropTypes.string,
};

export default CostCurves;
