import React, { memo } from 'react';

import { Notification } from 'fr-shared/components';

import { FailedManufacturabilityCheckCounts } from 'portal/lib/cart';

interface ComponentProps {
  className?: string;
  didManufacturabilityChecksRun: boolean;
  failedManufacturabilityCheckCounts: FailedManufacturabilityCheckCounts;
  onClick?: () => void;
  renderForNoChecks?: () => JSX.Element;
  showActionText?: boolean;
}

type RenderFunction<P> = (props: P) => JSX.Element;

const shouldShow = (
  didChecksRun: boolean,
  failedCheckCounts: FailedManufacturabilityCheckCounts
) => {
  return (
    failedCheckCounts &&
    didChecksRun &&
    (failedCheckCounts.failed_error_checks_count > 0 ||
      failedCheckCounts.failed_warning_checks_count)
  );
};

const _DfmCheckNotification = memo(
  ({
    className,
    didManufacturabilityChecksRun,
    failedManufacturabilityCheckCounts,
    onClick,
    renderForNoChecks,
    showActionText,
  }: ComponentProps) => {
    const show = shouldShow(didManufacturabilityChecksRun, failedManufacturabilityCheckCounts);

    if (!show) {
      if (renderForNoChecks) {
        return renderForNoChecks();
      }
      return null;
    }

    const errorCheckCount = failedManufacturabilityCheckCounts.failed_error_checks_count;
    const warningCheckCount = failedManufacturabilityCheckCounts.failed_warning_checks_count;
    const hasCheckErrors = errorCheckCount > 0;

    const actionText = showActionText
      ? hasCheckErrors
        ? 'See critical issues'
        : 'See issues'
      : null;

    return (
      <Notification
        className={className}
        level={hasCheckErrors ? 'error' : 'warning'}
        iconNameOrNumber={errorCheckCount || warningCheckCount}
        primaryText={
          hasCheckErrors ? 'Your part has critical issues' : 'Your part has non-critical issues'
        }
        secondaryText="View in detail to see our suggested fixes."
        actionText={actionText}
        onClick={onClick}
      />
    );
  }
);

_DfmCheckNotification.displayName = '_DfmCheckNotification';

/***
 * Conditionally renders a component using a functional call. This allows for null comparisons when passing a rendering as a slot.
 * @param props the {@link ComponentProps} for which to render with
 */
const renderConditionally: RenderFunction<ComponentProps> = (props: ComponentProps) => {
  const show = shouldShow(
    props.didManufacturabilityChecksRun,
    props.failedManufacturabilityCheckCounts
  );
  if (!show) {
    return null;
  }
  return <DfmCheckNotification {...props} />;
};

// Decorate with namespaced helpers
export const DfmCheckNotification = Object.assign(_DfmCheckNotification, { renderConditionally });

export default DfmCheckNotification;
