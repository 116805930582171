import React from 'react';

interface QuoteFormMenuQuoteQuantity {
  amount: string;
  formatted: string;
}

interface QuoteFormMenuQuoteQuantityProps {
  onClick: (value: QuoteFormMenuQuoteQuantity) => any;
  menuQuoteQuantityOptions: Array<QuoteFormMenuQuoteQuantity>;
  selectedQuoteQuantityOption: QuoteFormMenuQuoteQuantity;
  shouldDisplayOneMenuQuoteOption: boolean;
}

const QuoteFormMenuQuoteQuantity = ({
  onClick,
  menuQuoteQuantityOptions,
  selectedQuoteQuantityOption,
  shouldDisplayOneMenuQuoteOption,
}: QuoteFormMenuQuoteQuantityProps) => {
  const handleOnClick = (value: QuoteFormMenuQuoteQuantity) => {
    onClick(value);
  };

  const quantityOptions = shouldDisplayOneMenuQuoteOption
    ? Array.of(menuQuoteQuantityOptions[0])
    : menuQuoteQuantityOptions;

  return (
    <div className="row">
      <div>
        <div className="col-10">
          {shouldDisplayOneMenuQuoteOption
            ? 'This quote only includes one quantity from each costing request line item. The estimated quote value is shown below.'
            : 'Please select the quantity option you expect the customer to purchase.'}
          <span className="text-error">*</span>
        </div>
        <div className="col-7">
          {quantityOptions.map((quantity, index) => {
            const id = `${quantity?.formatted}_${index}`;
            return (
              <div className="col-auto form-check pr-0" key={`quote_form_menu_quantity_${index}`}>
                <div className="custom-control custom-radio mb-0 mr-2 flex">
                  <input
                    className="custom-control-input"
                    type="radio"
                    id={id}
                    checked={selectedQuoteQuantityOption?.formatted === quantity?.formatted}
                    onChange={() => {
                      handleOnClick(quantity);
                    }}
                  />
                  <label htmlFor={id} className="flex custom-control-label font-weight-normal">
                    <div className="form-group">
                      {index === 0
                        ? 'Lowest quantity'
                        : index === 1
                        ? 'Median quantity'
                        : 'Highest quantity'}{' '}
                      - <span className="font-weight-bold">{quantity?.formatted}</span>
                    </div>
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default QuoteFormMenuQuoteQuantity;
