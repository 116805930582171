export const classNames = classes => {
  var out = '';
  var type = typeof classes;
  if (type === 'string' || type === 'number') {
    return classes || '';
  }
  if (Array.isArray(classes) && classes.length > 0) {
    for (var i = 0, len = classes.length; i < len; i++) {
      var next = classNames(classes[i]);
      if (next) {
        out += (out && ' ') + next;
      }
    }
  } else {
    for (var key in classes) {
      if (Object.prototype.hasOwnProperty.call(classes, key) && classes[key]) {
        out += (out && ' ') + key;
      }
    }
  }
  return out;
};
