import PropTypes from 'prop-types';
import React from 'react';

import { CardWrapper } from 'fr-shared/components';

import { QuoteCard } from 'portal/components';

const GridView = ({ quotes }) => {
  return (
    <CardWrapper>
      {quotes.map(quote => (
        <QuoteCard key={quote.public_id || quote.id} quote={quote} />
      ))}
    </CardWrapper>
  );
};

GridView.propTypes = {
  quotes: PropTypes.array,
};

export default GridView;
