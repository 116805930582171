import PropTypes from 'prop-types';
import React from 'react';

import QuoteLineItemCard from './QuoteLineItemCard';

const QuoteDetailsBody = ({ quote, readonly = false }) => {
  const { line_items, state } = quote;

  return (
    <div className="py-5 font-size-md">
      <h3 className="mb-4">Items {line_items ? `(${line_items.length})` : ''}</h3>
      {line_items.map((lineItem, index) => {
        return (
          <QuoteLineItemCard
            key={`line_item_card_${index}`}
            lineItem={lineItem}
            quoteState={state}
            readonly={readonly}
          />
        );
      })}
    </div>
  );
};

QuoteDetailsBody.propTypes = {
  quote: PropTypes.shape({
    line_items: PropTypes.array,
    state: PropTypes.string,
  }),
  readonly: PropTypes.bool,
};

export default QuoteDetailsBody;
