import PropTypes from 'prop-types';
import React from 'react';

import { IconFont } from 'fr-shared/components';

import { Button, EmptyStateCard } from 'portal/components';

export const EmptyPartsCard = ({ readonly = false, onActionClick = () => {} }) => {
  return (
    <EmptyStateCard
      iconFont="cloud-3d-box"
      cardHeader="Recent Parts"
      mainText="You have no parts to view.  Let’s add some to your account."
    >
      <div className="mb-1">
        <Button disabled={readonly} block outline to="/add-part" onClick={onActionClick}>
          <IconFont name="plus" />
          Add new part
        </Button>
      </div>
    </EmptyStateCard>
  );
};

EmptyPartsCard.propTypes = {
  onActionClick: PropTypes.func,
  readonly: PropTypes.bool,
};

export default EmptyPartsCard;
