import { useEffect } from 'react';

// https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
const useEffectDebounced = (callback, deps = [], delay = 400) => {
  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
};

export default useEffectDebounced;
