import PropTypes from 'prop-types';
import React from 'react';

import { useUserAnalyticsContext } from 'fr-shared/context';

import {
  Badge,
  Button,
  ContactUs,
  ContactUsType,
  DetailsHeader,
  Money,
  ShippingPriceLabel,
} from 'portal/components';
import { date } from 'portal/lib/date';
import { getQuoteStateLabel, isQuoteAcceptance } from 'portal/lib/quotes';

import { QuoteDetailsDropdown } from './QuoteDetailsDropdown';

const QuoteDetailsHeader = ({ quote, onReorderClicked, readonly = false }) => {
  const userAnalytics = useUserAnalyticsContext();
  const canReorder = quote.state === 'Ordered';
  const handleContactClick = () => userAnalytics.track('Contact Us - Quote', { valid: true });

  const canDownloadPdf = isQuoteAcceptance(quote.state);

  return (
    <DetailsHeader backLink="/quotes" backText="Back to all quotes" readonly={readonly}>
      <DetailsHeader.MainCell>
        <div className="font-size-sm text-gray">Quote #</div>
        <div className="flex align-items-center">
          <h3 className="mb-0">{quote.public_id}</h3>
          <div className="ml-3">
            <Badge>{getQuoteStateLabel(quote.state)}</Badge>
          </div>
        </div>
        <div className="row mt-3">
          {quote.state === 'Requested' && (
            <div className="col-md mb-3">
              <div className="text-gray font-size-sm">Date requested</div>
              <div className="text-light">{date(quote.inserted_at)}</div>
            </div>
          )}
          {quote.state === 'Submitted' && (
            <div className="col-md mb-3">
              <div className="text-gray font-size-sm">Date submitted</div>
              <div className="text-light">{date(quote.completed_at)}</div>
            </div>
          )}
          <div className="col-md mb-3">
            <div className="text-gray font-size-sm">Requested by</div>
            <div className="text-light">{quote.customer_contact_name}</div>
          </div>
        </div>
      </DetailsHeader.MainCell>
      <DetailsHeader.EndCell>
        <div className="font-size-md flex justify-content-between align-items-center">
          <p className="text-gray">Items subtotal</p>
          <Money amount={quote?.sub_total_price?.amount} className="text-gray" />
        </div>
        {quote.state !== 'Requested' && (
          <div className="font-size-md flex justify-content-between align-items-center">
            <p className="text-gray">
              <ShippingPriceLabel />
            </p>
            <Money amount={quote?.shipping_price?.amount} className="text-gray" />
          </div>
        )}
        {+quote?.discount?.amount > 0 && (
          <div className="font-size-md flex justify-content-between align-items-center">
            <p className="text-gray">Discount</p>
            <Money
              amount={quote?.discount?.amount}
              className="text-success"
              isNegative={true}
              unstyledSymbol={true}
            />
          </div>
        )}
        <div className="flex justify-content-between align-items-center">
          <p className="text-light">Total</p>
          <Money
            amount={quote?.total_price?.amount}
            className="text-light font-size-lg font-weight-bolder"
          />
        </div>
        <div className="flex justify-content-between mt-2">
          {canDownloadPdf && (
            /** When we can download - we have more than one option, so show the dropdown */
            <QuoteDetailsDropdown
              quoteId={quote.id}
              onContactClick={handleContactClick}
              readonly={readonly}
            />
          )}
          {!canDownloadPdf && (
            /** When restricting download, show the contact us button */
            <ContactUs
              outline
              className="flex-1"
              linkType={ContactUsType['button']}
              onClick={handleContactClick}
              readonly={readonly}
            >
              Contact us
            </ContactUs>
          )}
          {canReorder ? (
            <Button onClick={onReorderClicked} className="ml-2 flex-1">
              Reorder
            </Button>
          ) : (
            quote.user_can_checkout && (
              <Button
                to={readonly ? '#' : `/checkout/${quote.id}`}
                className="ml-2 flex-1"
                onClick={() => userAnalytics.track('Started Quote Checkout')}
              >
                Checkout
              </Button>
            )
          )}
        </div>
      </DetailsHeader.EndCell>
    </DetailsHeader>
  );
};

QuoteDetailsHeader.propTypes = {
  onReorderClicked: PropTypes.func,
  quote: PropTypes.shape({
    id: PropTypes.number,
    state: PropTypes.string,
    public_id: PropTypes.string,
    customer_contact_name: PropTypes.string,
    sub_total_price: PropTypes.object,
    shipping_price: PropTypes.object,
    discount: PropTypes.object,
    total_price: PropTypes.object,
    completed_at: PropTypes.string,
    inserted_at: PropTypes.string.isRequired,
    user_can_checkout: PropTypes.bool,
  }).isRequired,
  readonly: PropTypes.bool,
};

export default QuoteDetailsHeader;
