import { connect } from 'formik';
import { get, sumBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Alert, Button, Icon, LineItemProcess } from 'fr-shared/components';
import { getPercent, percentColor } from 'fr-shared/utils';

import YieldReportPartFailure from './YieldReportPartFailure';

const YieldReportLineItem = ({ index, formik, availableRejectionReasons }) => {
  const { line_item, part_failures } = formik.values.part_failure_updates[index];

  const failureCount = sumBy(part_failures, 'quantity');
  const error = get(formik, `errors.part_failure_updates.${index}.total_part_failures`);
  const percentPass = getPercent(line_item.quantity, failureCount);
  const screenshot = get(line_item, `order_line_item.part.current_revision.screenshot`);
  const description = get(line_item, `order_line_item.description`);

  const handleAddPartFailure = () => {
    formik.setFieldValue(`part_failure_updates.${index}.part_failures`, [
      ...part_failures,
      { quantity: '', comments: '' },
    ]);
  };
  return (
    <div className="card card-border mb-3" key={line_item.id}>
      <div className="flex flex-column flex-md-row mb-0 border-bottom px-3 py-2">
        <div className="flex flex-row">
          {screenshot && (
            <div className="mr-3">
              <img
                width={40}
                height={40}
                className="rounded"
                src={screenshot}
                alt="Part Screenshot"
              />
            </div>
          )}
          {description && (
            <span className="mr-3">
              <strong>{description}</strong>
            </span>
          )}
        </div>

        <span className="d-none d-md-flex mr-3">
          FR {get(line_item, `order_line_item.order.public_id`)}
        </span>
        <span className="d-none d-md-flex mr-3">
          <Icon name="user" opacity right />
          {get(line_item, `order.customer.name`)}
        </span>
        <div className="ml-md-auto">
          <span>
            <LineItemProcess data={line_item} />
          </span>
        </div>
      </div>
      {error && (
        <Alert color="danger" className="rounded-0">
          <Icon name="exclamation-triangle" right />
          {error}
        </Alert>
      )}
      <div className="card-body flex flex-column flex-md-row py-2">
        <div className="flex flex-row">
          <div className="mr-4">
            <div className="form-group mb-0">
              <span>Total Part Failures</span>
              <div className="flex">
                <h2 className="font-size-xl">
                  {failureCount} / {line_item.quantity}
                </h2>
              </div>
            </div>
          </div>
          <div>
            <div className="form-group mb-0">
              <span>% Pass</span>
              <h2 style={{ color: percentColor(percentPass) }}>{percentPass}%</h2>
            </div>
          </div>
        </div>
        <div className="ml-0 ml-md-auto">
          {line_item.comments && (
            <div className="align-items-center d-none d-md-flex flex-row">
              <small className="mr-2">{line_item.comments}</small>
              <Icon name="comment" className="mr-2 ml-md-auto" opacity />
            </div>
          )}
          {line_item.finishing && (
            <div className="flex align-items-center justify-content-end flex-row-reverse flex-md-row container">
              <small className="mr-2">{line_item.finishing}</small>
              <Icon name="clipboard-list" className="mr-2 ml-md-auto" opacity />
            </div>
          )}
        </div>
      </div>
      {
        <YieldReportPartFailure
          lineIndex={index}
          availableRejectionReasons={availableRejectionReasons}
        />
      }
      <div className="card-footer border-top flex justify-content-end">
        <div className="d-flex flex-1 d-md-block text-right">
          <Button
            className="d-flex flex-1 d-md-inline-block justify-content-center"
            color="primary"
            onClick={handleAddPartFailure}
          >
            Document New Failure
          </Button>
        </div>
      </div>
    </div>
  );
};

YieldReportLineItem.propTypes = {
  index: PropTypes.number,
  formik: PropTypes.object,
  availableRejectionReasons: PropTypes.array,
};

export default connect(YieldReportLineItem);
