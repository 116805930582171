import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { AddressModal, Button } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { AddressTypes, addressPath } from 'fr-shared/lib/address';

import AddressTable from '../components/AddressTable';

const Addresses = ({ type, userId }) => {
  const { setAlert } = useContext(AlertContext);
  const [addresses, setAddresses] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    refreshAddresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshAddresses = useCallback(async () => {
    try {
      const res = await api.get(`/users/${userId}/${addressPath(type)}`, {
        params: { sort_key: 'inserted_at', sort_asc: true },
      });
      setAddresses(res.data);
    } catch {
      setAlert({
        message: 'We could not load your saved addresses. Please refresh the page to try again',
        color: 'danger',
      });
    }
  }, [userId, type, setAlert]);

  const handleSave = () => {
    setAlert({ message: `${type} information successfully added.`, color: 'success' });
    refreshAddresses();
  };

  const handleError = () => {
    setAlert({
      message: 'Failed to add address. Please refresh the page to try again',
      color: 'danger',
    });
  };

  const handleDelete = () => {
    setAlert({ message: 'Successfully deleted address', color: 'success' });
    refreshAddresses();
  };

  return (
    <div>
      <div className="pb-3 flex justify-content-between align-items-center">
        <h3 className="border-0">{startCase(type) + ' Information'}</h3>
        <Button color="success" className="ml-2" onClick={() => setIsOpen(true)}>
          + Add {type} Information
        </Button>
      </div>
      {isOpen && (
        <AddressModal
          backdrop="static"
          isOpen={true}
          onDelete={handleDelete}
          onError={handleError}
          onSave={handleSave}
          title={`Add ${startCase(type)} Information`}
          toggle={() => setIsOpen(false)}
          type={type}
          userId={userId}
        />
      )}

      <AddressTable
        addresses={addresses}
        userId={userId}
        type={type}
        onSave={refreshAddresses}
        onDelete={refreshAddresses}
      />
    </div>
  );
};

Addresses.propTypes = {
  type: PropTypes.oneOf(AddressTypes).isRequired,
  userId: PropTypes.string.isRequired,
};

export default Addresses;
