import React from 'react';

import { IconFont, classNames } from 'fr-shared/components';
import { numberInputMinValueFilter } from 'fr-shared/utils/input';

import styles from './NumberInputField.module.css';

interface Props {
  /** The minimum value */
  min: number;

  /** Is field interactive */
  disabled?: boolean;

  /** The step to increment / decrement by */
  step?: number;

  /** The current controlled value */
  value: number;

  onBlur?: (value: number) => void;
  onChange?: (value: number) => void;
}

/**
 * Renders specifically a number input field with custom "spinners" for increment / decrement.
 * To be used within a {@link FormFieldBase} component.
 */
export const NumberInputField = ({
  min,
  step = 1,
  disabled = false,
  value,
  onBlur,
  onChange,
}: Props) => {
  return (
    <div className="form-group is-floating">
      <input
        disabled={disabled}
        className={classNames([styles['control'], 'form-control'])}
        type="number"
        min={min}
        value={value}
        onBlur={e =>
          onBlur
            ? onBlur(numberInputMinValueFilter(e.currentTarget.valueAsNumber, min))
            : undefined
        }
        onChange={e =>
          onChange
            ? onChange(numberInputMinValueFilter(e.currentTarget.valueAsNumber, min))
            : undefined
        }
      />
      <span className={styles['custom-buttons']}>
        <button disabled={value <= min} onClick={() => onChange(value - step)}>
          <IconFont name="minus" />
        </button>
        <button onClick={() => onChange(value + step)}>
          <IconFont name="plus" />
        </button>
      </span>
    </div>
  );
};

export default NumberInputField;
