import { isEmpty } from 'lodash';
import moment from 'moment';

import { isExternalProcess } from 'fr-shared/lib/manufacturing_process';

interface Customer {
  id: number;
}

interface Deal {
  type: string;
}

interface ManufacturingProcess {
  name: string;
}

interface Processes {
  id?: number | null;
  manufacturing_process?: ManufacturingProcess;
}

interface CostingRequestCart {
  type?: string;
}

interface BuildPackage {
  notes?: string;
  internal_notes?: string;
  working_status?: string;
  processes: Array<Processes>;
  part?: LineItemPart;
}

export interface CostingRequest {
  bulk?: object;
  customer?: Customer;
  deal?: Deal;
  id?: number | null;
  last_state_change_at?: string;
  line_items?: CostingRequestLineItem[];
  requested_at?: string;
  state?: string;
  customer_portal?: boolean;
  cart?: CostingRequestCart;
}

export interface CostingRequestLineItem {
  id?: number | null;
  build_package?: BuildPackage;
  description?: string;
  completed_at?: Date;
  state?: string;
  manufacturing_process?: ManufacturingProcess;
  part?: LineItemPart;
  box_file_url?: string;
  isOpen?: boolean;
  customer_portal_finish?: string;
  surface?: string;
  selected?: boolean;
  supplier_costs?: SupplierCost[] | null;
  source?: string;
  costing_input_values?: Array<{ value?: any }>;
  upload_status?: string;
  uuid?: string;
  is_prototype?: string;
}

interface LineItemPart {
  id?: number | null;
  documents?: Document[];
  current_revision?: PartFileRevision;
  usage?: string;
  designation?: string;
}

interface PartFileRevision {
  model_processing_status?: string;
  file_type?: string;
}

export interface SupplierCost {
  id?: number | null;
  documents?: Document[];
  location_id?: number;
  part_quantities: PartQuantity[];
}

export interface PartQuantity {
  id?: number | null;
  quantity: string | number;
  per_unit_cost: string | object;
  selected?: boolean;
  generated_per_unit_cost?: string | object;
}

export interface Document {
  id?: number | null;
  deleted_at?: string | moment.Moment;
  file_name?: string;
  mixed?: boolean;
}

export const COSTING_REQUEST_STATES = {
  Closed: 'Closed',
  Completed: 'Completed',
  Draft: 'Draft',
  InProgress: 'In Progress',
  Rejected: 'Rejected',
  Requested: 'Requested',
  Archived: 'Archived',
};

export const COSTING_REQUEST_STATE_COLORS: { [key: string]: string } = {
  Draft: 'secondary',
  Closed: 'secondary',
  Completed: 'success',
  Rejected: 'danger',
  Requested: 'primary',
  'In Progress': 'warning',
};

export const COSTING_REQUEST_LI_STATES = {
  Closed: 'Closed',
  Completed: 'Completed',
  Draft: 'Draft',
  InProgress: 'In Progress',
  Rejected: 'Rejected',
  Requested: 'Requested',
  Archived: 'Archived',
};

export const NEW_COSTING_REQUEST_LINE_ITEM: CostingRequestLineItem = {
  box_file_url: null,
  build_package: {
    working_status: 'in_progress',
    processes: [
      {
        manufacturing_process: null,
      },
    ],
  },
  description: '',
  isOpen: true,
  customer_portal_finish: '',
  surface: 'Basic',
  selected: false,
  supplier_costs: [{ part_quantities: [{ per_unit_cost: '', quantity: '' }] }],
  source: 'FROS',
  state: COSTING_REQUEST_LI_STATES.Draft,
  is_prototype: 'false',
};

export const isSubmitted = (costingRequest: CostingRequest): boolean =>
  [
    COSTING_REQUEST_STATES.Closed,
    COSTING_REQUEST_STATES.Completed,
    COSTING_REQUEST_STATES.InProgress,
    COSTING_REQUEST_STATES.Rejected,
    COSTING_REQUEST_STATES.Requested,
  ].includes(costingRequest.state);

export const isDraft = (costingRequest: CostingRequest): boolean =>
  !costingRequest.state || costingRequest.state === COSTING_REQUEST_STATES.Draft;

export const isAutoquote = (costingRequest: CostingRequest): boolean => {
  return costingRequest?.customer_portal && costingRequest?.cart?.type === 'Autoquote';
};

export const filterByCostingState = (lineItems: CostingRequestLineItem[] = [], state: string) => {
  const newLineItems = [...lineItems].filter(li => li.state === state);
  return newLineItems;
};

export const inProgressNoticeText = (lineItem: CostingRequestLineItem): string => {
  if (!lineItem.manufacturing_process?.name) {
    return 'In Progress items cannot be edited.';
  }

  const responsibleParty = isExternalProcess(lineItem.manufacturing_process.name)
    ? 'supply chain team'
    : 'supporting DME';

  return `In Progress items cannot be edited. Coordinate updates by reaching out to the ${responsibleParty}.`;
};

export const tabStateForLineItems = (lineItems: CostingRequestLineItem[]): string => {
  if (lineItems.some(li => li.state === COSTING_REQUEST_LI_STATES.Requested)) {
    return COSTING_REQUEST_STATES.Requested;
  } else if (lineItems.every(li => li.state === COSTING_REQUEST_LI_STATES.Rejected)) {
    return COSTING_REQUEST_STATES.Rejected;
  } else if (lineItems.some(li => li.state === COSTING_REQUEST_LI_STATES.InProgress)) {
    return COSTING_REQUEST_STATES.InProgress;
  } else if (lineItems.some(li => li.state === COSTING_REQUEST_LI_STATES.Completed)) {
    return COSTING_REQUEST_STATES.Completed;
  } else {
    return COSTING_REQUEST_STATES.Closed;
  }
};

const transformLineItemErrors = (e: any, index: number) => {
  return e.errors.reduce((acc: object, error: any) => {
    const path = error.split(' ')[0];
    const field = `line_items[${index}].${path}`.replace(/\./gi, '').replace(/[[\]']+/g, '.');

    return { ...acc, [field]: 'Required' };
  }, {});
};

const updateFormikLineItems = (updatedLineItems: Array<any>, formik: any): void => {
  if (isEmpty(updatedLineItems)) return;

  const newLineItems = formik.values.line_items.map((li: any) => {
    const newLineItem = updatedLineItems.find(r => r.id === li.id);
    return newLineItem ? newLineItem : li;
  });

  formik.setFieldValue('line_items', newLineItems);
};

interface Formik {
  values: any;
  errors: object;
  setFieldValue: (key: string, values: any) => void;
  setErrors: (errors: object) => void;
}
export const handleBulkUpdates = (
  bulkUpdatePromises: Array<Promise<any>>,
  formik: Formik
): Promise<Array<any>> => {
  return Promise.all(bulkUpdatePromises).then(results => {
    const invalidLineItems = results.filter(r => r.value && r.errors);
    const validLineItems = results.filter(r => !r.errors);
    updateFormikLineItems(validLineItems, formik);
    return new Promise((resolve, reject) => {
      if (!isEmpty(invalidLineItems)) {
        const newErrors = invalidLineItems.reduce((acc, e) => {
          if (e.value?.id) {
            const index = formik.values.line_items.findIndex((li: any) => li.id === e.value.id);
            return { ...acc, ...transformLineItemErrors(e, index) };
          }
        }, {});
        formik.setErrors({ ...formik.errors, ...newErrors });
        return reject(invalidLineItems.length);
      } else {
        formik.setErrors({});
        return resolve(results);
      }
    });
  });
};

export const stateCanDuplicateAndEditLineItem = (state: string): Boolean => {
  return [
    COSTING_REQUEST_LI_STATES.Completed,
    COSTING_REQUEST_LI_STATES.InProgress,
    COSTING_REQUEST_LI_STATES.Rejected,
  ].includes(state);
};

export const MODEL_PROCESSING_STATUSES = {
  Complete: 'complete',
};

export const isModelProcessingComplete = (lineItem: CostingRequestLineItem): boolean => {
  const status = lineItem?.part?.current_revision?.model_processing_status;
  return status === MODEL_PROCESSING_STATUSES.Complete;
};

// used to find build pack data within our form state framework, ie formik.values.line_items
export const LINE_ITEMS_ACCESSOR = 'line_items';

export const SUPPLIER_RESPONSE_STATE_COLORS: { [key: string]: string } = {
  'Quote with No Changes': 'success',
  'Quote with Conditions': 'warning',
  'No Quote': 'danger',
};
