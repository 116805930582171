import { useCallback, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { addressPath } from 'fr-shared/lib/address';

const useAddresses = (contactId, addressType) => {
  const [addresses, setAddresses] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState(null);

  const refreshAddresses = useCallback(async () => {
    if (contactId) {
      try {
        const { data } = await api.get(`/users/${contactId}/${addressPath(addressType)}`);
        setDefaultAddress(data.find(address => address.is_default === true));
        setAddresses(data);
      } catch {
        setDefaultAddress(null);
        setAddresses([]);
      }
    }
  }, [addressType, contactId]);

  useEffect(() => {
    refreshAddresses();
  }, [refreshAddresses]);

  return { defaultAddress, addresses, refreshAddresses };
};

export default useAddresses;
