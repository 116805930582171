import ColorHash from 'color-hash';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon, classNames } from 'fr-shared/components';

import styles from './Avatar.module.css';

const Avatar = ({
  className = '',
  imageLeft = true,
  isAnonymous = false,
  showEmail = false,
  showName = true,
  size,
  style,
  subHeading = '',
  subject = null,
}) => {
  const name = subject.name ?? '';
  const initials = name
    .split(' ')
    .map(n => n[0])
    .join('');
  const colorHash = new ColorHash();
  const background = isAnonymous ? 'gray' : colorHash.hex(subject.name || initials);

  const InitialsAvatar = ({ className }) => (
    <div
      className={classNames([styles.Avatar, 'avatar-initials', size && styles[size], className])}
      style={{
        background: background,
        backgroundSize: 'cover',
        backgroundImage: !isAnonymous && subject.logo_url && `url(${subject.logo_url})`,
      }}
    >
      {(isAnonymous && <Icon name="user" sm />) || (!subject.logo_url && initials)}
    </div>
  );

  return (
    <div className={`avatar flex align-items-center ${className}`}>
      {imageLeft && <InitialsAvatar />}
      <div className="avatar-name" style={style}>
        {showName && name}
      </div>
      {showEmail && <strong>{subject?.email || subHeading}</strong>}
      {!imageLeft && <InitialsAvatar className="ml-1" />}
    </div>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  imageLeft: PropTypes.bool,
  isAnonymous: PropTypes.bool,
  showEmail: PropTypes.bool,
  showName: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  style: PropTypes.object,
  subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  subject: PropTypes.object,
};

export default Avatar;
