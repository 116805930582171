import React from 'react';

import {
  Modal,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableCustom,
  TableHead,
  TableHeadCell,
} from 'portal/components';

import styles from './PartTrackerModal.module.css';
import ProductionRows from './components/ProductionRows';
import ShipmentRows from './components/ShipmentRows';
import { PartTrackerModalProps } from './types';
import { formatDate } from './utils';

const PartTrackerModal = ({
  displayModal,
  orderSubmitted,
  lineItem,
  toggleDisplayModal,
}: PartTrackerModalProps) => {
  const {
    process_name,
    description: partName,
    work_order_state_summary,
    shipment_line_items,
    quantity,
    state,
  } = lineItem;
  const nonProdStates = ['Draft', 'Created', 'Submitted'];
  const [orderDate, orderTime] = formatDate(orderSubmitted);
  const isInProduction = !nonProdStates.includes(state);
  const isBeingShipped = shipment_line_items.length;

  return (
    <Modal isOpen={displayModal} className={styles.modal} toggle={toggleDisplayModal}>
      <Modal.Header className={styles.ModalHeaderFixed} onClose={toggleDisplayModal} />
      <div className={styles.ModalContentContainer}>
        <h2>Part Tracker</h2>
        <span>{partName}</span>
        <TableCustom className={styles.PartTrackerTable}>
          <TableHead>
            <TableHeadCell value="Status" />
            <TableHeadCell value="Date ordered" />
            <TableHeadCell value="Qty" />
          </TableHead>

          <TableBody>
            <TableBodyRow className={isInProduction ? styles.TableSectionDivider : ''}>
              <TableBodyCell>
                <strong>
                  <span>Submitted</span>
                </strong>
              </TableBodyCell>
              <TableBodyCell>
                <span>{orderDate}</span> {orderTime}
              </TableBodyCell>
              <TableBodyCell>
                <span>{quantity}</span>/{quantity}{' '}
                {/* this will always be #/# because it won't even be considered*/}
              </TableBodyCell>
            </TableBodyRow>

            <ProductionRows
              isInProduction={isInProduction}
              isBeingShipped={isBeingShipped}
              workOrderStateSummary={work_order_state_summary}
              manufacturingName={process_name}
            />

            <ShipmentRows shipmentLineItems={shipment_line_items} qtyOrdered={quantity} />
          </TableBody>
        </TableCustom>
      </div>
    </Modal>
  );
};

export default PartTrackerModal;
