/* eslint-disable react/display-name, react/prop-types */
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Icon, Link, Table } from 'fr-shared/components';

const CustomersTable = ({ data, pages, onFetchData }) => (
  <Table
    manual
    data={data}
    pages={pages}
    sortable={false}
    className="bg-white -striped -left mb-5"
    noDataText="No customers found"
    onFetchData={onFetchData}
    columns={[
      {
        Header: '',
        width: 45,
        filterable: false,
        sortable: false,
        expander: true,
        Expander: ({ isExpanded, ...rest }) => {
          return (
            <div className="flex justify-content-between align-items-center">
              {rest.original.users.length > 0 && (
                <Button size="sm" color="secondary">
                  <i className={`fas fa-chevron-${isExpanded ? 'up' : 'down'}`} />
                </Button>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Customer Name',
        accessor: 'name',
        Cell: row => {
          return (
            <div className="text-left">
              <Link to={`/admin/customers/${row.original.id}`}>{row.original.name}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Contacts',
        maxWidth: 100,
        id: 'contacts',
        accessor: d => (
          <div>
            <Icon name="user" className="mr-1" />
            {d.users.length}
          </div>
        ),
      },
      {
        Header: 'Created',
        id: 'created_at',
        maxWidth: 100,
        accessor: d => moment(d.inserted_at).format('MM/DD/YYYY'),
      },
      {
        Header: '',
        width: 70,
        sortable: false,
        Cell: row => (
          <div className="text-center">
            <Link to={`/admin/customers/${row.original.id}`}>View</Link>
          </div>
        ),
      },
    ]}
    SubComponent={row => {
      const orgId = row.original.id;
      return (
        <Table
          data={row.original.users}
          defaultPageSize={row.original.users.length}
          className="-highlight -left"
          showPagination={false}
          columns={[
            {
              Header: 'Contact Name',
              accessor: 'name',
              Cell: row => {
                return (
                  <div className="text-left">
                    <Link to={`/admin/customers/${orgId}/contact/${row.original.id}`}>
                      {row.original.name}
                    </Link>
                  </div>
                );
              },
            },
            { Header: 'Contact Email', accessor: 'email' },
            { Header: 'Phone Number', accessor: 'phone_number' },
            { Header: 'Braintree ID', accessor: 'braintree_id' },
            {
              Header: '',
              filterable: false,
              Cell: row => (
                <div className="text-right">
                  <Link to={`/admin/customers/${orgId}/contact/${row.original.id}`}>View</Link>
                </div>
              ),
            },
          ]}
        />
      );
    }}
  />
);

CustomersTable.propTypes = {
  data: PropTypes.array,
  pages: PropTypes.number,
  onFetchData: PropTypes.func,
};

export default CustomersTable;
