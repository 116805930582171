import { useQuery } from 'react-query';

import { api } from 'fr-shared/api';

const fetchTolerances = (): Promise<Tolerance[]> => {
  return api.get('/customer_portal/tolerances').then(res => res.data);
};

const useTolerances = () => {
  return useQuery<Tolerance[], Error>(['tolerances'], () => fetchTolerances(), {
    refetchOnWindowFocus: false,
    staleTime: 2000,
  });
};

export default useTolerances;
