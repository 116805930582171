import * as yup from 'yup';

import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';

// Add a customer uniform array method
yup.addMethod(yup.array, 'uniformBy', function (message, mapper = a => a) {
  return this.test('uniformBy', message, list => {
    return new Set(list.map(mapper)).size <= 1;
  });
});

export const workOrderValidationSchema = yup.object().shape({
  failed_system_error: yup
    .string()
    .nullable()
    .when(['state', 'manufacturing_process'], (state, manufacturing_process) =>
      state === 'Failed' && manufacturing_process === MANUFACTURING_PROCESSES.MJF
        ? yup.mixed().required()
        : yup.mixed().notRequired()
    ),
  line_items: yup
    .array()
    .uniformBy(
      'Line items must all have the same manufacturing process',
      li => li.order_line_item.manufacturing_process.id
    )
    .uniformBy(
      'Line items must all have the same material',
      li => li.order_line_item.material.id
    ),
});
