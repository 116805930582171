import * as Sentry from '@sentry/react';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes, { number } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { AlertContext, UserContext, useUserAnalyticsContext } from 'fr-shared/context';
import { useEffectDebounced } from 'fr-shared/hooks';
import { AddressTypeEnum, CP_SHIPPING_COUNTRIES, LABELS_BY_COUNTRY } from 'fr-shared/lib/address';
import {
  ACTIVE_SHIPPING_METHODS_LIST,
  shippingMethodToDescription,
  shippingMethodToDisplayName,
} from 'fr-shared/lib/shipping';

import {
  Button,
  FormCheckbox,
  FormCountries,
  FormField,
  FormPhoneNumber,
  FormRadioButtons,
  FormSelect,
  FormStates,
  Loading,
} from 'portal/components';

import AddressSelector from './AddressSelector';
import CardCheckout from './CardCheckout';
import styles from './Checkout.module.css';

const FROS_SHIPPING_METHOD_OPTIONS = ACTIVE_SHIPPING_METHODS_LIST.filter(
  option => option !== 'Other - See notes'
);

const CheckoutShipping = ({
  analyticsContext = 'Checkout',
  isActive,
  quoteId = null,
  shippingAddressId = null,
  schemaIsValid,
  isCustomerShippingAccount,
}) => {
  const formik = useFormikContext();
  const { user } = useContext(UserContext);
  const userAnalytics = useUserAnalyticsContext();
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const [addresses, setAddresses] = useState();
  const [shippingData, setShippingData] = useState({
    options: [],
    isAllInternational: null,
    loading: false,
  });
  const { setAlert } = useContext(AlertContext);
  const prefixAnalyticsName = name => `${analyticsContext} - ${name}`;
  const labelsByCountry = LABELS_BY_COUNTRY[formik.values?.shipping_address?.country];

  const refreshAddresses = useCallback(() => {
    api
      .get(`/customer_portal/account/${user.id}/shipping_addresses`, {
        params: { sort_key: 'inserted_at', sort_desc: true },
      })
      .then(res => {
        let selectedAddressIndex = 0;
        if (shippingAddressId) {
          selectedAddressIndex =
            res.data.findIndex(address => address.id === shippingAddressId) || 0;
        }
        setSelectedAddressIndex(selectedAddressIndex);
        setAddresses(res.data);
      });
  }, [user.id, shippingAddressId]);

  const handleToBilling = () =>
    userAnalytics.track(prefixAnalyticsName('Completed Shipping'), { valid: true });

  const handleShippingMethodsError = context => {
    if (process.env.MIX_ENV !== 'dev') {
      Sentry.setExtra('error', context);
      Sentry.captureMessage('Unable to fetch shipping methods for portal checkout');
    }
    setShippingData({
      options: [],
      isAllInternational: null,
      loading: false,
    });
    setAlert({ message: 'Unable to fetch your shipping methods.', color: 'danger' });
    formik.setFieldValue('shipping_method', null);
  };

  const fetchShippingMethods = async (cart_id, shipping_address) => {
    setShippingData(prev => ({ ...prev, loading: true }));
    formik.setFieldValue('shipping_method', undefined);
    formik.setFieldValue('shipping_and_taxes_total', {});

    try {
      const response = await api.post(
        `/customer_portal/shipping/carts/${cart_id}/shipping_methods`,
        { shipping_address }
      );
      const shipping_methods = response.data.shipping_methods;
      if (shipping_methods.length > 0) {
        formik.setFieldValue('shipping_method', shipping_methods[0]);
        setShippingData({
          options: shipping_methods.map(shipping_method => {
            return {
              value: shipping_method,
              label: (
                <div className={styles.ShippingOption}>
                  <strong className="pr-1">{shippingMethodToDisplayName(shipping_method)}</strong>
                  &nbsp;
                  {shippingMethodToDescription(shipping_method)}
                </div>
              ),
            };
          }),
          isAllInternational: response.data.is_all_international,
          loading: false,
        });
      } else {
        handleShippingMethodsError({ reason: 'API returned no shipping methods.' });
      }
    } catch (error) {
      handleShippingMethodsError(error);
    }
  };

  useEffectDebounced(() => {
    if (!quoteId) {
      const { id, shipping_address } = formik.values;
      const { street_line_1, city, state, postal_code, country } = shipping_address;
      if (
        street_line_1 &&
        city &&
        state &&
        postal_code &&
        country &&
        !formik.errors.shipping_address &&
        formik
      ) {
        fetchShippingMethods(id, shipping_address);
      }
    }
  }, [
    formik.values.id,
    formik.values.shipping_address?.street_line_1,
    formik.values.shipping_address?.city,
    formik.values.shipping_address?.state,
    formik.values.shipping_address?.postal_code,
    formik.values.shipping_address?.country,
    formik.errors.shipping_address,
  ]);

  useEffect(() => {
    refreshAddresses();
  }, [refreshAddresses]);

  useEffect(() => {
    if (!addresses) return;
    const selectedAddress = addresses[selectedAddressIndex];
    if (selectedAddress) {
      formik.setFieldValue('shipping_address', selectedAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddressIndex, addresses]);

  // Clearing out postal_code and state so we don't try and fetch shipping prices with bad data
  useEffect(() => {
    if (!formik.values.shipping_address?.id) {
      formik.setFieldValue('shipping_address.postal_code', '');
      formik.setFieldValue('shipping_address.state', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.shipping_address?.country, formik.values.shipping_address?.id]);

  return (
    <CardCheckout
      name="Shipping"
      editUrl={quoteId ? `/checkout/${quoteId}/shipping` : '/checkout/shipping'}
      isEditable={!formik.isSubmitting}
      isActive={isActive}
      footer={
        <Button
          className="mt-4"
          disabled={!schemaIsValid}
          to={quoteId ? `/checkout/${quoteId}/billing` : '/checkout/billing'}
          dataTestId="continue-to-billing-button"
          onClick={handleToBilling}
        >
          Continue to billing
        </Button>
      }
    >
      {isCustomerShippingAccount && (
        <>
          <h5 className="mb-3">Your Shipping Account</h5>
          <div className="row">
            <FormField
              className="col-md"
              name="shipping_account_number"
              label="Account Number"
              floatingLabel={true}
              onlyShowErrorIfTouched={true}
              required={true}
            />
            <FormSelect
              className="col-md"
              label="Shipping Method"
              floatingLabel
              name="shipping_method"
              optionList={FROS_SHIPPING_METHOD_OPTIONS}
              nameAccessor={option => option}
              valueAccessor={option => option}
              placeholder={'Please select'}
              onlyShowErrorIfTouched={true}
            />
          </div>
        </>
      )}

      <h5 className="mb-3">Address</h5>
      {isEmpty(addresses) ? (
        <div>
          <div className="row">
            <div className="col-md">
              <FormField
                name="shipping_address.name_line_1"
                label="Full Name"
                floatingLabel={true}
                onlyShowErrorIfTouched={true}
                required={true}
              />
            </div>
            <div className="col-md">
              <FormField
                name="shipping_address.name_line_2"
                label="Company (optional)"
                floatingLabel={true}
                onlyShowErrorIfTouched={true}
                onBlur={e => {
                  if (e.target.value) {
                    userAnalytics.track(prefixAnalyticsName('Company field shipping'), {
                      inputValue: e.target.value,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <FormField
                name="shipping_address.street_line_1"
                label="Address line 1"
                floatingLabel={true}
                onlyShowErrorIfTouched={true}
                required={true}
              />
            </div>
            <div className="col-md">
              <FormField
                name="shipping_address.street_line_2"
                label="Address line 2 (optional)"
                floatingLabel={true}
                onlyShowErrorIfTouched={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <FormCountries
                name="shipping_address.country"
                options={CP_SHIPPING_COUNTRIES}
                placeholder="Country"
                label={<span>Country</span>}
                required={true}
                floatingLabel={true}
                onlyShowErrorIfTouched={true}
              />
            </div>
            <div className="col-md">
              <FormStates
                country={formik.values.shipping_address?.country}
                required={true}
                label={labelsByCountry ? labelsByCountry.state : 'State'}
                name="shipping_address.state"
                onlyShowErrorIfTouched={true}
                placeholder={labelsByCountry ? labelsByCountry.state : 'State'}
                floatingLabel={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <FormField
                name="shipping_address.city"
                label="City"
                floatingLabel={true}
                onlyShowErrorIfTouched={true}
                required={true}
              />
            </div>
            <div className="col-md">
              <FormField
                name="shipping_address.postal_code"
                label={labelsByCountry ? labelsByCountry.zip : 'ZIP Code'}
                floatingLabel={true}
                onlyShowErrorIfTouched={true}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <FormPhoneNumber
                defaultCountry={formik.values?.shipping_address?.country}
                label="Phone Number (optional)"
                name="phone_number"
                floatingLabel={true}
                onBlur={e => {
                  if (e.target.value) {
                    userAnalytics.track(prefixAnalyticsName('Phone number field'), {
                      inputValue: e.target.value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-md"></div>
          </div>

          <FormCheckbox
            name="shipping_address.associate_user"
            label="Save this shipping address to my account"
            className="mt-3"
            onBlur={e => {
              userAnalytics.track(prefixAnalyticsName('Save shipping address'), {
                valid: !!e.target.checked,
              });
            }}
          />
        </div>
      ) : (
        <AddressSelector
          addresses={addresses}
          onAddressAdded={refreshAddresses}
          onAddressIndexSelected={setSelectedAddressIndex}
          selectedAddressIndex={selectedAddressIndex}
          type={AddressTypeEnum.Shipping}
        />
      )}
      {!quoteId && (
        <div className="mt-5">
          <h5 className="mb-3">Shipping Method</h5>
          {shippingData.loading ? (
            <Loading />
          ) : (
            <>
              {shippingData.isAllInternational &&
                'Shipping and taxes are international: your order will arrive from outside your country.'}
              {shippingData.options.length > 0 && (
                <FormRadioButtons
                  className={styles.ShippingOptions}
                  stacked={true}
                  options={shippingData.options}
                  name="shipping_method"
                />
              )}
            </>
          )}
        </div>
      )}
    </CardCheckout>
  );
};

CheckoutShipping.propTypes = {
  analyticsContext: PropTypes.string,
  isActive: PropTypes.bool,
  quoteId: PropTypes.number,
  schemaIsValid: PropTypes.bool,
  shippingAddressId: number,
  isCustomerShippingAccount: PropTypes.bool,
};

export default CheckoutShipping;
