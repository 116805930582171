/// <reference types="react" />
/// <reference types="react-router-dom" />
import * as Sentry from '@sentry/react';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Breadcrumb, Button, GlobalErrorBoundary, Helmet, NotFound } from 'fr-shared/components';
import { AlertContext, UserContext } from 'fr-shared/context';
import { useLocations, useManufacturingProcess } from 'fr-shared/hooks';

import PrinterForm from './PrinterForm';

interface PrinterEditRouteParams {
  id?: string;
}

type PrinterEditProps = RouteComponentProps<PrinterEditRouteParams>;

const PrinterEdit: React.FC<PrinterEditProps> = ({ match, history }: PrinterEditProps) => {
  const { setAlert } = useContext(AlertContext);
  const { user }: UserContext = useContext(UserContext);

  const [printer, setPrinter] = useState<Printer>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [types, setTypes] = useState<PrinterType[]>();

  const { data: manufacturingProcesses }: { data: ManufacturingProcess[] } =
    useManufacturingProcess();

  const { data: locations } = useLocations();

  useEffect(() => {
    api.get(`/printer_types`).then(res => {
      setTypes(res.data);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setHasError(false);
    setPrinter(null);

    api
      .get(`/printers/${match.params.id}`)
      .then(res => {
        setPrinter(res.data);
      })
      .catch(error => {
        if (error.response?.status !== 404) {
          setHasError(true);
          Sentry.captureMessage('Printer GET Failed');
          Sentry.setExtra('printer_id', match.params.id);
          Sentry.setExtra('error', error);
        }
      })
      .finally(() => setIsLoading(false));
  }, [match.params.id]);

  const handleSubmit = (values: PrinterParams, actions: any) => {
    api
      .put(`/printers/${match.params.id}`, { printer: values })
      .then(() => {
        setAlert({
          message: 'Successfully updated Printer',
          color: 'success',
        });
        history.push('/admin/printers');
      })
      .catch(err => {
        if (err.response?.data) {
          const { errors, messages } = err.response.data;
          actions.setErrors({
            server: messages,
            ...errors,
          });
        }

        setAlert({
          message: 'Sorry, we were unable to update this printer.',
          color: 'danger',
        });
      });
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this printer?')) {
      api.delete(`/printers/${match.params.id}`).then(() => {
        setAlert({
          message: 'Deleted Printer',
          color: 'success',
        });
        history.push('/admin/printers');
      });
    }
  };

  if (isLoading) return null;
  if (hasError) return <GlobalErrorBoundary />;
  if (!printer) return <NotFound />;

  return (
    <>
      <Helmet title="Printers" />
      <Breadcrumb to="/admin/printers">Printers</Breadcrumb>
      <Breadcrumb to={`/admin/printers/${match.params.id}/edit`}>Edit Printer</Breadcrumb>
      <div className="container">
        <div className="flex align-items-center py-3">
          <h2 className="mb-0">Edit Printer</h2>
          <div className="ml-auto">
            <Button color="secondary" onClick={(_event: any) => history.goBack()}>
              Back
            </Button>
          </div>
        </div>
        <PrinterForm
          locations={locations}
          manufacturingProcesses={manufacturingProcesses}
          onDelete={user.canDeletePrinters && handleDelete}
          onSubmit={handleSubmit}
          printer={printer}
          printerTypes={types}
        />
      </div>
    </>
  );
};

export default PrinterEdit;
