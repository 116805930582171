import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button, Icon, Link, classNames } from 'fr-shared/components';

const ENV_COLORS = { dev: 'warning', staging: 'tertiary' };

export const ENV_COLOR_CLASSNAME = ENV_COLORS[process.env.MIX_ENV]
  ? `bg-${ENV_COLORS[process.env.MIX_ENV]}`
  : 'bg-dark';

const EnvironmentIndicator = ({ className }) => {
  const [open, setOpen] = useState(true);
  const iconName = process.env.MIX_ENV === 'staging' ? 'theater-masks' : 'tools';

  if (process.env.MIX_ENV === 'prod') return null;

  if (!open) return null;

  return (
    <div
      className={classNames([
        'text-light text-center uppercase font-size-sm sticky-top',
        ENV_COLOR_CLASSNAME,
        className,
      ])}
    >
      <div className="flex container-fluid align-items-center ">
        <Icon right sm name={iconName} />
        <strong>{upperFirst(process.env.MIX_ENV || 'dev')}</strong>
        <div className="ml-auto flex items-center">
          <Link className="px-3 text-white" to="/admin">
            Admin
          </Link>
          <Link className="px-3 text-white" to="/">
            Portal
          </Link>
          <Button size="xs" className="text-white" color="link" onClick={() => setOpen(false)}>
            <Icon name="times" className="m-0" />
          </Button>
        </div>
      </div>
    </div>
  );
};

EnvironmentIndicator.propTypes = {
  className: PropTypes.string,
};

export default EnvironmentIndicator;
