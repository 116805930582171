import { useQuery } from 'react-query';

import { api } from 'fr-shared/api';

const fetchLeadTimes = () => api.get('/lead_times').then(res => res.data);

const useLeadTimes = () => {
  return useQuery('lead_times', fetchLeadTimes, {
    refetchOnWindowFocus: false,
  });
};

export default useLeadTimes;
