import React from 'react';

import { UserAnalyticsService, useUserAnalyticsContext } from 'fr-shared/context';

import { EmptyQuotesCard, Link, QuoteButton } from 'portal/components';
import QuoteGridView from 'portal/pages/quotes/components/GridView';

interface RecentQuotesProp {
  hasQuotes: boolean;
  recentQuotes: any[] /* TODO: create interface once other quote components are typescript as well to share */;
}

export const RecentQuotes = ({ hasQuotes, recentQuotes }: RecentQuotesProp) => {
  const userAnalytics: UserAnalyticsService = useUserAnalyticsContext();

  const logEvt = (str: string) => userAnalytics.track(str, { valid: true });
  const handleRecentQuoteClick = (type: string) =>
    logEvt(`${type === 'create' ? 'Create' : 'Edit'} Quote CTA - Dash Quotes`);
  const handleAllQuotesClick = () => logEvt(`View All Quotes`);

  const Header = () => (
    <div className="flex flex-col md:flex-row justify-content-between mb-4">
      <div className="flex align-items-center justify-content-between">
        <h3>Recent quotes</h3>
        {hasQuotes && (
          <div className="ml-2 underline">
            <Link to="/quotes" onClick={handleAllQuotesClick}>
              View all
            </Link>
          </div>
        )}
      </div>
      {hasQuotes && (
        <div className="flex justify-content-end">
          <QuoteButton size="sm" onClick={handleRecentQuoteClick} dataTestId="btn-quote" />
        </div>
      )}
    </div>
  );

  return (
    <div className="py-2">
      {hasQuotes && <Header />}

      {hasQuotes ? <QuoteGridView quotes={recentQuotes} /> : <EmptyQuotesCard />}
    </div>
  );
};

export default RecentQuotes;
