import React from 'react';

interface Props {
  machineType: string;
  machineTime: number;
}

const MachiningInfoSection = ({ machineTime, machineType }: Props) => {
  return (
    <div className="flex flex-row justify-between">
      {machineTime !== null && (
        <div className="w-50">
          <div className="mb-1 text-coolGray-300">Machine time</div>
          <div>{machineTime.toFixed(2)}m</div>
        </div>
      )}
      {machineType !== null && (
        <div className="w-50">
          <div className="mb-1 text-coolGray-300">Machine type</div>
          <div>{machineType}</div>
        </div>
      )}
    </div>
  );
};

export default MachiningInfoSection;
