import React from 'react';

import { FormSelect } from 'fr-shared/components';
import { DLS_CASSETTES } from 'fr-shared/lib/work_orders';

const CassetteFormField = props => {
  return (
    <FormSelect
      {...props}
      name="cassette"
      label="Cassette"
      optionList={DLS_CASSETTES}
      nameAccessor={option => option}
      valueAccessor={option => option}
    />
  );
};

export default CassetteFormField;
