import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, FormCheckboxMultiple, FormField } from 'fr-shared/components';

const RejectionReasonForm = ({ reason, handleSubmit, handleDelete = null }) => {
  return (
    <Formik enableReinitialize={true} initialValues={reason} onSubmit={handleSubmit}>
      <Form>
        <div className="row">
          <div className="col">
            <FormField name="description" label="Description" required />
          </div>
        </div>
        {reason && (
          <FormCheckboxMultiple
            label="Select MFG Process"
            hasSelectAll
            name="manufacturing_processes"
            options={reason.manufacturing_processes}
            required
            stacked
          />
        )}
        <div>
          <div className="ml-auto">
            <Button type="submit" color="success">
              Submit
            </Button>
            {!!handleDelete && (
              <Button color="danger" outline={true} className="ml-2" onClick={handleDelete}>
                Delete
              </Button>
            )}
          </div>
        </div>
      </Form>
    </Formik>
  );
};

RejectionReasonForm.propTypes = {
  reason: PropTypes.object,
  allMfgProcesses: PropTypes.arrayOf(PropTypes.object),
  handleSubmit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default RejectionReasonForm;
