import React from 'react';

import AccountDetails from './components/AccountDetails';
import EditableAvatar from './components/EditableAvatar';

const AccountDetailsContainer = () => {
  return (
    <>
      <div data-testid="account-details-container" className="flex w-1/2 pt-12">
        <EditableAvatar />
        <AccountDetails />
      </div>
    </>
  );
};

export default AccountDetailsContainer;
