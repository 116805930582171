import { Form, Formik } from 'formik';
import React, { useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { api } from 'fr-shared/api';
import { ButtonLink, IconFont } from 'fr-shared/components';
import { AlertContext, UserContext, useUserAnalyticsContext } from 'fr-shared/context';
import useSalesforceSybridgeInstanceFeatureFlag from 'fr-shared/hooks/useSalesforceSybridgeInstanceFeatureFlag';

import {
  Button,
  FormField,
  FormPhoneNumber,
  FormSelect,
  Page,
  PageHeader,
} from 'portal/components';

import styles from './ContactUs.module.css';

const numericOnly = (str: string) => str?.replace(/\D+/g, '');

const baseUrl = window.location.origin;
const preferredContactTypeOptions = ['Email', 'Phone call'];
const subjectOptions = [
  {
    id: 'Trouble uploading/configuring parts, submitting a quote, or placing an order',
    name: 'Help me upload parts, submit a quote, or place an order',
  },
  {
    id: 'Need part design assistance',
    name: 'Help me with my part design',
  },
  {
    id: 'Learning about all of Fast Radius capabilities',
    name: 'Explain the capabilities at Fast Radius',
  },
  {
    id: 'Other',
    name: 'Something else',
  },
];
const contactUsSchema = Yup.object().shape({
  company: Yup.string().label('Company').required(),
  name: Yup.string().label('Name').required(),
  email: Yup.string().email().label('Email').required(),
  phone: Yup.string().label('Phone number').required(),
  description: Yup.string().label('description').required(),
});
const ORG_ID: Record<string, string> = {
  dev: '00D2C0000008czv',
  staging: '00D2C0000008czv',
  prod: '00Dj00000029Yh8',
};
const RECORD_TYPE_ID: Record<string, string> = {
  dev: '0122C0000001Shg',
  staging: '0122C0000001Shg',
  prod: '0125b0000015Pgu',
};
const CONTACT_PREFERENCE_ID: Record<string, string> = {
  dev: '00N2C0000021cqK',
  staging: '00N2C0000021cqK',
  prod: `00N5b00000E75wX`,
};
const SUBMIT_URL: Record<string, string> = {
  dev: 'https://fastradius--frclone.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8',
  staging:
    'https://fastradius--frclone.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8',
  prod: 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8',
};
const SYBRIDGE_SUBMIT_URL: Record<string, string> = {
  dev: 'https://sybridgetech--frclone.sandbox.lightning.force.com/servlet/servlet.WebToCase?encoding=UTF-8',
  staging:
    'https://sybridgetech--frclone.sandbox.lightning.force.com/servlet/servlet.WebToCase?encoding=UTF-8',
  prod: 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8',
};

const RETURN_URL: Record<string, string> = {
  dev: 'https://beta.fastradius.com/dashboard?contactsuccess=true',
  staging: `${baseUrl}/dashboard?contactsuccess=true`,
  prod: `${baseUrl}/dashboard?contactsuccess=true`,
};

const ContactUsContainer = () => {
  const handleClick = useHistory().goBack;
  const { setAlert } = useContext(AlertContext);
  const sybridgeSalesforceInstanceEnabled = useSalesforceSybridgeInstanceFeatureFlag();
  const history = useHistory();
  const env = process.env.MIX_ENV;
  const returnUrl = RETURN_URL[env];
  const orgId = ORG_ID[env];
  const contactPreferenceId = CONTACT_PREFERENCE_ID[env];
  const recordType = RECORD_TYPE_ID[env];
  const submitUrl = sybridgeSalesforceInstanceEnabled
    ? SYBRIDGE_SUBMIT_URL[env]
    : SUBMIT_URL[env];
  const formElement = useRef(null);
  const submitForm = (values: any) => {
    if (sybridgeSalesforceInstanceEnabled) {
      api
        .post('/customer_portal/send_contact_email', { form_values: values })
        .then(_response => {
          history.push('/dashboard?contactsuccess=true');
        })
        .catch(() => {
          setAlert({
            message: 'An unexpected error occurred. Please refresh and try again.',
            color: 'danger',
          });
        });
    } else {
      formElement.current.submit();
    }
  };
  const userAnalytics = useUserAnalyticsContext();
  const trackEvent = (eventName: string, payload?: Record<string, any>) =>
    userAnalytics.track(`Contact Us - ${eventName}`, payload);

  const { user } = useContext(UserContext) as {
    user: User;
  };

  return (
    <Page>
      <ButtonLink
        className="cursor-pointer font-size-sm mb-3 text-gray text-decoration-none"
        onClick={handleClick}
      >
        <IconFont name="chevron-left" />
        Back
      </ButtonLink>
      <PageHeader
        title="Contact us"
        subtitle="Send us a few details and we’ll get back to you within 4 business hours. We’re here 8:00am - 6:00pm Central Time."
      />

      <Formik
        initialValues={{
          company: user.marketing_company_name,
          name: user.name,
          email: user.email,
          phone: user.phone_number,
        }}
        onSubmit={submitForm}
        validationSchema={contactUsSchema}
      >
        <Form
          action={submitUrl}
          method="POST"
          className={`${styles.Form} flex flex-fill flex-column`}
          ref={formElement}
        >
          <input type="hidden" name="retURL" value={returnUrl} />
          <input type="hidden" name="orgid" value={orgId} />
          <input id="recordType" name="recordType" type="hidden" value={recordType} />
          {/* uncomment to debug inputs going into SF*/}
          {/* <input type="hidden" name="debug" value={1} />
          <input type="hidden" name="debugEmail" value="alex.rodriguez@fastradius.com" /> */}

          <FormField
            label="Company"
            floatingLabel
            name="company"
            type="hidden"
            onlyShowErrorIfTouched={true}
            required={true}
            readonly
          />

          <FormField
            label="Your name"
            floatingLabel
            name="name"
            type="text"
            onlyShowErrorIfTouched={true}
            required={true}
            onBlur={e => {
              if (e.target.value) {
                trackEvent('Name', { changed: e.target.value !== user.name });
              }
            }}
          />

          <div className="row">
            <div className="col-md">
              <FormField
                label="Your email"
                floatingLabel
                name="email"
                type="text"
                onlyShowErrorIfTouched={true}
                required={true}
                onBlur={e => {
                  if (e.target.value) {
                    trackEvent('Email Address', { changed: e.target.value !== user.email });
                  }
                }}
              />
            </div>
            <div className="col-md">
              <FormPhoneNumber
                className="mb-2"
                label="Your phone number"
                floatingLabel={true}
                name="phone"
                onBlur={e => {
                  if (e.target.value) {
                    trackEvent('Phone Number', {
                      changed: numericOnly(e.target.value) !== numericOnly(user.phone_number),
                    });
                  }
                }}
              />
            </div>
          </div>

          <FormSelect
            label="What's the best way to reach you?"
            floatingLabel
            name={sybridgeSalesforceInstanceEnabled ? 'contact_preference' : contactPreferenceId}
            optionList={preferredContactTypeOptions}
            nameAccessor={option => option}
            valueAccessor={option => option}
            placeholder={'Please select'}
            onBlur={() => trackEvent('Selected Contact Method')}
          />

          <FormSelect
            label="How can we help?"
            floatingLabel
            name="type"
            optionList={subjectOptions}
            placeholder={'Please select'}
            onBlur={() => trackEvent('Issue Type')}
          />

          <FormField
            label="Tell us any extra details"
            floatingLabel
            name="description"
            type="textarea"
            onlyShowErrorIfTouched={true}
            required={true}
            onBlur={e => {
              if (e.target.value) {
                trackEvent('Additional Details');
              }
            }}
          />
          <div>
            <Button type="submit" onClick={() => trackEvent('Submitted Form')}>
              Send
            </Button>
          </div>
        </Form>
      </Formik>
    </Page>
  );
};

export default ContactUsContainer;
