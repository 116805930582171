/* eslint-disable react/display-name,react/prop-types */
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { TABLE_COLUMNS } from 'src/views/admin/costing_requests/utils/queues';

import { api } from 'fr-shared/api';
import { IconFont, Link, Table } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';

import { Alert, Button, Loading, Modal, PartDimensions, PartImage } from 'portal/components';
import { addPartsToCart } from 'portal/lib/cart';
import { date } from 'portal/lib/date';

import styles from './PartPreviewModal.module.css';

const COLUMNS = [
  TABLE_COLUMNS.Id,
  TABLE_COLUMNS.PublicId,
  TABLE_COLUMNS.State,
  TABLE_COLUMNS.ManufacturingProcessId,
  TABLE_COLUMNS.DateRequested,
  TABLE_COLUMNS.LatestStateChangeAt,
];

const columnsByName = {
  [TABLE_COLUMNS.Id]: {
    Header: 'Request #',
    accessor: 'id',
    Cell: ({ value }) => <Link to={`/admin/costing_requests/${value}`}>CR-{value}</Link>,
  },
  [TABLE_COLUMNS.PublicId]: {
    Header: 'Public ID',
    accessor: 'public_id',
  },
  [TABLE_COLUMNS.State]: {
    Header: 'State',
    accessor: 'state',
  },
  [TABLE_COLUMNS.ManufacturingProcessId]: {
    Header: 'MFG Process',
    accessor: 'manufacturing_process_name',
    width: 120,
  },
  [TABLE_COLUMNS.DateRequested]: {
    Header: 'Date Requested',
    accessor: 'requested_at',
    Cell: ({ value }) => (value ? moment(value).format('MM/DD/YY h:mm A') : ''),
    width: 140,
  },
  [TABLE_COLUMNS.LatestStateChangeAt]: {
    Header: 'Date Completed',
    accessor: 'completed_at',
    Cell: ({ value }) => (value ? moment(value).format('MM/DD/YY h:mm A') : ''),
    width: 140,
  },
};

export const PartPreviewModal = ({
  action,
  onActionHandler,
  isOpen,
  showAddToQuote = true,
  toggle,
  pageName,
  part,
}) => {
  const userAnalytics = useUserAnalyticsContext();
  const history = useHistory();
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState(null);
  if (!part) return null;

  const documents = get(part, 'documents', []);
  const partHistory = get(part, 'part_history', []);

  const handleAddToQuote = () => {
    userAnalytics.track(`${pageName} - Add to Quote`, {
      valid: true,
    });

    setAlert(null);
    setIsSubmitting(true);
    addPartsToCart([part])
      .then(res => {
        const { data: clis } = res;
        history.push(`/part-config/${clis[0].id}`);
      })
      .catch(() => {
        setAlert('An unexpected error occurred, please refresh and try again');
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleDownloadAllDocuments = () => {
    setAlert(null);
    setIsLoadingDocuments(true);
    api
      .get('/customer_portal/download_part_documents', { params: { part_id: part.id } })
      .then(res => {
        window.open(res.data.url);
      })
      .catch(() => {
        setAlert('Sorry, we were unable to download all supporting documents');
      })
      .finally(() => {
        setIsLoadingDocuments(false);
      });
  };

  return (
    <Modal
      dataTestId="part-preview-modal"
      action={action}
      isOpen={isOpen}
      toggle={toggle}
      onActionHandler={onActionHandler}
    >
      {({ toggle }) => (
        <>
          <Modal.Header title={part.name} onClose={toggle} />
          <Modal.Body>
            <div className="flex flex-row mb-3 w-100">
              <div className="flex flex-1 flex-column">
                <div className="flex flex-col md:flex-row">
                  <div className="flex-1">
                    <p className={`font-size-sm mb-0 ${styles.Label}`}>Date added</p>
                    <p className={`font-size-md ${styles.LabelValue}`}>
                      {date(part.inserted_at)}
                    </p>
                  </div>
                  <div className="flex-1">
                    <p className={`font-size-sm mb-0 ${styles.Label}`}>Dimensions</p>
                    <p className="font-size-md">
                      <PartDimensions
                        className={styles.Label}
                        missingDimensionsMessage="Dimensions have not been calculated for this part yet"
                        part={part}
                      />
                    </p>
                  </div>
                </div>
                <div className="mt-4">
                  <p className="font-size-sm text-gray">
                    Last edited: <strong>{date(part.updated_at)}</strong>
                  </p>
                </div>
              </div>

              <PartImage size="md" src={part.current_revision?.screenshot} />
            </div>
            {alert && (
              <Alert color="danger" toggle={true}>
                <IconFont name="error-triangle" className="mr-1" />
                {alert}
              </Alert>
            )}
            {isEmpty(documents) ? (
              <div>No supporting documents</div>
            ) : (
              <>
                <div className="flex justify-content-between">
                  <div className={`font-size-sm mb-0 ${styles.Label}`}>
                    Supporting documents ({documents.length})
                  </div>
                  {isLoadingDocuments ? (
                    <Loading />
                  ) : (
                    <Button color="link" onClick={handleDownloadAllDocuments}>
                      Download all
                    </Button>
                  )}
                </div>
                <div className="mt-2">
                  {documents.map((doc, index) => {
                    return <Document doc={doc} key={`document_${index}`} />;
                  })}
                </div>
              </>
            )}
            {partHistory?.length > 0 ? (
              <Table
                manual={true}
                data={partHistory}
                className="bg-white -striped -flex -left mt-2"
                columns={[...COLUMNS.map(name => columnsByName[name])]}
                showPagination={false}
              />
            ) : null}
          </Modal.Body>
          {showAddToQuote && (
            <Modal.Footer>
              <Button loading={isSubmitting} onClick={handleAddToQuote} className="px-4">
                Add to quote
              </Button>
            </Modal.Footer>
          )}
        </>
      )}
    </Modal>
  );
};

PartPreviewModal.propTypes = {
  action: PropTypes.node,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  pageName: PropTypes.string,
  onActionHandler: PropTypes.func,
  showAddToQuote: PropTypes.bool,
  part: PropTypes.shape({
    current_revision: PropTypes.shape({
      screenshot: PropTypes.string,
    }).isRequired,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        file_name: PropTypes.string,
      })
    ),
    id: PropTypes.number,
    inserted_at: PropTypes.string,
    name: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};

const Document = ({ doc }) => {
  const handleOpen = () => {
    window.open(doc.url);
  };

  return (
    <div className={styles.Document}>
      <div className="text-light">{doc.file_name}</div>
      <button onClick={handleOpen}>
        <IconFont name="arrow-down" />
      </button>
    </div>
  );
};

Document.propTypes = {
  doc: PropTypes.shape({
    file_name: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default PartPreviewModal;
