import PropTypes from 'prop-types';
import React from 'react';

const LineItemProcess = ({ data, separator }) => {
  const manufacturingProcessName = data.manufacturing_process?.name;
  const materialName = data.material?.name || data.material_name;
  const colorName = data.hex_color ? data.hex_color : data.color?.name;
  const inspectionTypeName = data.inspection_type?.name;

  return (
    <>
      {manufacturingProcessName}
      {materialName && `${separator}${materialName}`}
      {colorName && `${separator}${colorName}`}
      {inspectionTypeName && `${separator}${inspectionTypeName} Inspection`}
      {data.layer_thickness &&
        `${separator}${data.layer_thickness.value} ${data.layer_thickness.unit}`}
      {data.infill && `${separator}${data.infill}`}
    </>
  );
};

LineItemProcess.defaultProps = {
  separator: ' - ',
};

LineItemProcess.propTypes = {
  data: PropTypes.object,
  separator: PropTypes.string,
};

export default LineItemProcess;
