/* eslint-disable react/display-name */
import { pickBy } from 'lodash';
import React, { useContext } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, Button, Helmet, Link, Table } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { useTableFilters } from 'fr-shared/hooks';

const getRejectionReasons = filters => api.get('/rejection_reasons', { params: pickBy(filters) });

const RejectionReasonsIndex = () => {
  const { user } = useContext(UserContext);

  const {
    data: rejectionReasons,
    pages,
    onFetchData,
    loading,
  } = useTableFilters(
    'rejection_reasons',
    { page: 1, sort_key: 'description' },
    getRejectionReasons
  );

  return (
    <>
      <Helmet title="Rejection Reasons" />
      <Breadcrumb to="/admin/rejection_reasons">Rejection Reasons</Breadcrumb>
      <div className="container">
        <div className="flex align-items-center py-3">
          <h2 className="mb-0">Part Rejection Reasons</h2>
          <div className="ml-auto">
            {user.canCreateRejectionReasons && (
              <Button color="primary" to="/admin/rejection_reasons/new">
                Add a reason
              </Button>
            )}
          </div>
        </div>

        <Table
          manual={true}
          data={rejectionReasons || []}
          pageSize={(rejectionReasons && rejectionReasons.length) || 20}
          pages={pages}
          showPagination={true}
          onFetchData={onFetchData}
          loading={loading}
          className="bg-white -striped mb-5"
          columns={[
            { Header: 'Description', accessor: 'description', sortable: true },
            {
              Header: 'MFG Processes',
              id: 'mfg_processes',
              Cell: row => {
                const { manufacturing_processes } = row.original;
                if (!manufacturing_processes.length) {
                  return <>None</>;
                }
                return <>{manufacturing_processes.map(p => p.name).join(', ')}</>;
              },
              sortable: false,
            },
            {
              show: user.canUpdateRejectionReasons,
              Header: '',
              sortable: false,
              id: 'Edit',
              Cell: row => {
                return (
                  <Link
                    to={{
                      pathname: `/admin/rejection_reasons/${row.original.id}/edit/`,
                    }}
                  >
                    Edit
                  </Link>
                );
              },
            },
          ]}
        />
      </div>
    </>
  );
};

export default RejectionReasonsIndex;
