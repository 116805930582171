import { omit, pick, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Button, Icon } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { keyboardDown } from 'fr-shared/utils';

const OrdersSearch = ({ filters, handleTextSearch, setFilters }) => {
  const { user } = useContext(UserContext);

  const { public_id, customer_name, description, customer_po } = filters;
  const sanitizedFilters = pick(filters, [
    'engineering_contact_id',
    'location_id',
    'manufacturing_process_id',
    'not_shipped',
    'public_id',
    'sales_contact_id',
    'state',
    'support_contact_id',
  ]);

  const filterLabels = {
    engineering_contact_id: 'Engineering Contact ID',
    location_id: 'Produced By ID',
    manufacturing_process_id: 'MFG Process ID',
    public_id: 'Public ID',
    not_shipped: 'Not Shipped',
    sales_contact_id: 'Sales Contact ID',
    state: 'State',
    support_contact_id: 'Support Contact ID',
  };

  const visibleFilters = pickBy(sanitizedFilters);

  const handleRemoveFilter = filter => {
    setFilters(omit(filters, filter));
  };

  return (
    <div className="page-actions mb-0">
      <div className="flex justify-content-end align-items-center">
        <div className="col-lg relative mb-1">
          <input
            type="text"
            name="customer_po"
            className="form-control"
            placeholder="Search by PO #"
            value={customer_po}
            onChange={handleTextSearch}
          />
        </div>
        <div className="col-lg relative mb-1">
          <input
            type="text"
            name="public_id"
            className="form-control"
            placeholder="Search by Order #"
            value={public_id}
            onChange={handleTextSearch}
          />
        </div>
        <div className="col-lg relative mb-1">
          <input
            type="text"
            name="customer_name"
            className="form-control"
            placeholder="Search by Customer"
            value={customer_name}
            onChange={handleTextSearch}
          />
        </div>
        <div className="col-lg relative mb-1">
          <input
            type="text"
            name="description"
            className="form-control"
            placeholder="Search by File Name"
            value={description}
            onChange={handleTextSearch}
          />
        </div>
        <div className="col-lg-2 relative text-right mb-1">
          {user.canCreateOrders && (
            <Button color="primary" to="/admin/orders/new">
              New Order
            </Button>
          )}
        </div>
      </div>
      {Object.keys(visibleFilters).length > 0 && (
        <div className="flex justify-content-end">
          <h5>
            <Icon name="filter" className="text-primary" right />
            Applied Filters
          </h5>
        </div>
      )}
      <div className="flex flex-wrap justify-content-end">
        {Object.keys(visibleFilters).map((filter, index) => (
          <div
            key={index}
            className="filter-label delete ml-2"
            onClick={() => handleRemoveFilter(filter)}
            onKeyDown={evt => keyboardDown(evt, 'Enter', () => handleRemoveFilter(filter))}
            role="button"
            tabIndex={0}
          >
            <div>
              <strong>{filterLabels[filter]}</strong>: {filters[filter].toString()}
            </div>
            <i />
          </div>
        ))}
      </div>
    </div>
  );
};

OrdersSearch.propTypes = {
  filters: PropTypes.shape({
    customer_po: PropTypes.string,
    public_id: PropTypes.string,
    customer_name: PropTypes.string,
    description: PropTypes.string,
  }),
  handleTextSearch: PropTypes.func,
  setFilters: PropTypes.func,
};

export default OrdersSearch;
