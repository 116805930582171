import React from 'react';
import { components } from 'react-select';

import { Icon } from 'fr-shared/components';

const DropdownIndicator = dropdownProps => {
  const { selectProps, hasValue } = dropdownProps;
  return (
    <components.DropdownIndicator {...dropdownProps}>
      {selectProps.defaultOptions.length > 0 || hasValue ? (
        <i className="custom-select-icon" data-testid="dropdown-indicator--icon" />
      ) : (
        <Icon name="search" dataTestId="dropdown-indicator--icon" />
      )}
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
