import { useTreatments } from '@splitsoftware/splitio-react';
import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';

import { Button, FormDate, FormField, Modal, classNames } from 'fr-shared/components';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { ORDER_LINE_ITEM_STATES } from 'fr-shared/lib/orders';

import styles from './OrderLineItemStatePicker.module.css';

interface ModalProps {
  action: ReactElement;
  onActionHandler?: () => void;
  submitButtonText?: string;
  title?: string;
  toggle?: () => void;
}

interface OrderLineItemStatePickerProps {
  lineItemIndex: number;
  selectableLineItemStates: string[];
}

const OrderLineItemStatePicker = ({
  lineItemIndex,
  selectableLineItemStates,
}: OrderLineItemStatePickerProps) => {
  const { values, setFieldValue, setFieldError } = useFormikContext();
  const lineItemFormikSelector = `line_items.${lineItemIndex}`;
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const capLineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'Build Pack' : 'Line Item';

  const onActionHandler = () => {
    // reset form field errors if modal is opened, validated with errors, closed and opened again.
    setFieldError(`${lineItemFormikSelector}.actual_ship_date`, '');
  };

  const onCancelHandler = () => {
    // reset values to initial
    setFieldValue(`${lineItemFormikSelector}.state`, initialState);
    setFieldValue(`${lineItemFormikSelector}.actual_ship_date`, initialActualShipDate);
  };

  const formValidationMessage = () => {
    if (showActualShipDate) {
      const { actual_ship_date } = get(values, lineItemFormikSelector, {
        actual_ship_date: '',
      });

      if (isEmpty(actual_ship_date)) {
        return 'is required';
      }

      if (new Date(actual_ship_date) > new Date()) {
        return 'cannot be in the future';
      }
    }
    return '';
  };

  const [initialState, setInitialState] = useState('');
  const [initialActualShipDate, setInitialActualShipDate] = useState('');
  const [showActualShipDate, setShowActualShipDate] = useState(false);

  useEffect(() => {
    const { state, actual_ship_date } = get(values, lineItemFormikSelector, {
      state: '',
      actual_ship_date: '',
    });
    if (isEmpty(initialState) && isEmpty(initialActualShipDate)) {
      setInitialState(state);
      setInitialActualShipDate(actual_ship_date);
    }
    setShowActualShipDate(state === ORDER_LINE_ITEM_STATES.Shipped);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <>
      <Modal
        dataTestId={`order-line-item-${lineItemIndex}-change-state-modal-form`}
        action={
          <Button
            dataTestId={`order-line-item-${lineItemIndex}-change-state-button`}
            outline
            className={classNames([styles.button, 'mr-2'])}
            color="primary"
          >
            Change State
          </Button>
        }
        onActionHandler={onActionHandler}
        onCloseHandler={onCancelHandler}
      >
        {({ toggle }: ModalProps) => (
          <>
            <Modal.Header
              title={`Change ${capLineItemName} State`}
              onClose={() => {
                onCancelHandler();
                toggle();
              }}
              textUppercase={false}
            />
            <Modal.Body className="px-3">
              <div className="form-group mb-3">
                <FormField
                  label="Select a new state"
                  name={`line_items.${lineItemIndex}.state`}
                  className="line-item-state"
                  component="select"
                >
                  <option value="" />
                  {selectableLineItemStates.map(state => (
                    <option
                      key={state}
                      value={state}
                      data-testid={`order-line-item-state-change-modal-${state
                        .trim()
                        .replace(/\s/g, '-')
                        .toLowerCase()}-option`}
                    >
                      {state}
                    </option>
                  ))}
                </FormField>

                {showActualShipDate && (
                  <FormDate
                    label="Actual Ship Date"
                    name={`line_items.${lineItemIndex}.actual_ship_date`}
                    isRequired
                    disallowFutureDate
                  />
                )}
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-space-between">
              <>
                <Button
                  dataTestId="order-line-item-state-change-modal-cancel-button"
                  color="light"
                  className="border uppercase px-3"
                  onClick={() => {
                    onCancelHandler();
                    toggle();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  dataTestId="order-line-item-state-change-modal-submit-button"
                  color="primary"
                  className="ml-2 uppercase px-3"
                  onClick={() => {
                    const validationMsg = formValidationMessage();
                    if (isEmpty(validationMsg)) {
                      // already updated in formik state, just close them modal
                      const currentState = get(values, `${lineItemFormikSelector}.state`, '');
                      if (currentState !== ORDER_LINE_ITEM_STATES.Shipped) {
                        setFieldValue(`${lineItemFormikSelector}.actual_ship_date`, '');
                      }
                      toggle();
                    } else {
                      setFieldError(`${lineItemFormikSelector}.actual_ship_date`, validationMsg);
                    }
                  }}
                >
                  Apply
                </Button>
              </>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default OrderLineItemStatePicker;
