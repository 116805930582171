import React from 'react';

import { Button, IconFont, classNames } from 'fr-shared/components';

import { FailedManufacturabilityCheckCounts } from 'portal/lib/cart';

/** Public API */
export interface DFMCheckButtonProps {
  failedChecksCount: FailedManufacturabilityCheckCounts;
  onClick: (state: CheckState) => void;
}

export type CheckState = 'non-critical' | 'critical' | 'no-issues' | 'checks-incomplete';

/** Internal Helpers */
const STATE_TO_TEXT_COLOR: Record<CheckState, string> = {
  ['non-critical']: 'text-warning-300',
  ['critical']: 'text-error-300',
  ['no-issues']: 'text-success-300',
  ['checks-incomplete']: 'text-coolGray-300',
};

const STATE_TO_BORDER_COLOR: Record<CheckState, string> = {
  ['non-critical']: 'border-warning-300',
  ['critical']: 'border-error-300',
  ['no-issues']: 'border-success-300',
  ['checks-incomplete']: 'border-coolGray-300',
};

function getCheckState(failedChecksCount: FailedManufacturabilityCheckCounts): CheckState {
  const errorCount = failedChecksCount?.failed_error_checks_count || 0;
  const warningCount = failedChecksCount?.failed_warning_checks_count || 0;

  let state: CheckState = 'checks-incomplete';
  if (failedChecksCount === null || failedChecksCount === undefined) {
    state = 'checks-incomplete';
  } else if (errorCount > 0) {
    state = 'critical';
  } else if (warningCount > 0) {
    state = 'non-critical';
  } else {
    state = 'no-issues';
  }
  return state;
}

/** Public Component */
export const DfmCheckButton = ({ failedChecksCount, onClick }: DFMCheckButtonProps) => {
  const state = getCheckState(failedChecksCount);
  const textColor = STATE_TO_TEXT_COLOR[state];
  const borderColor = STATE_TO_BORDER_COLOR[state];

  return (
    <Button
      outline
      className={classNames([textColor, borderColor, 'px-2 py-1 text-sm'])}
      onClick={() => onClick(state)}
    >
      <DfmCheckContent failedChecksCount={failedChecksCount} />
    </Button>
  );
};

/** Implementation detail components */

interface ContentProps {
  failedChecksCount: FailedManufacturabilityCheckCounts;
}

const DfmCheckContent = ({ failedChecksCount }: ContentProps) => {
  const errorCount = failedChecksCount?.failed_error_checks_count || 0;
  const warningCount = failedChecksCount?.failed_warning_checks_count || 0;
  const state = getCheckState(failedChecksCount);
  switch (state) {
    case 'critical':
      return (
        <>
          <CountBadge count={errorCount} isError={true} />
          <CountBadge count={warningCount} />
          <span className="ml-1">See issues</span>
        </>
      );
    case 'non-critical':
      return (
        <>
          <CountBadge count={warningCount} />
          <span className="ml-1">See issues</span>
        </>
      );
    case 'no-issues':
      return (
        <>
          <IconFont name="check" className="text-base mr-1" /> DFM checks passed
        </>
      );
    default:
      return <>DFM checks in progress</>;
  }
};

interface CountBadgeProps {
  count: number;
  isError?: boolean;
}

const CountBadge = ({ count, isError = false }: CountBadgeProps) => {
  if (count === 0) {
    return null;
  }
  const textColor = isError ? 'text-error-300' : 'text-warning-300';
  const borderColor = isError ? 'border-error-300' : 'border-warning-300';
  return (
    <span
      className={classNames([
        textColor,
        borderColor,
        'border-solid border-[1px] rounded-full inline-block w-[1rem] text-[0.625rem] align-text-top mr-0.5',
      ])}
    >
      {count}
    </span>
  );
};

export default DfmCheckButton;
