import { connect } from 'formik';
import { cloneDeep, get, isEmpty, set } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { api } from 'fr-shared/api';
import { Alert, CostingRequestStateBadge, Icon, Link } from 'fr-shared/components';
import { AlertContext, UserContext, withAlert } from 'fr-shared/context';
import { COSTING_REQUEST_LI_STATES } from 'fr-shared/lib/costing_requests';
import { CRLI_REJECTED_CODES } from 'fr-shared/lib/crli_rejected_codes';
import { mfgProcessesWithEditableSupplierOnAutoquote } from 'fr-shared/lib/manufacturing_process';
import { isFormikAtPrefixTheSame, renderWithLineBreaks } from 'fr-shared/utils';

import { supplierCostPartQuantitySchema } from '../utils/schema';
import CostingDocuments from './CostingDocuments';
import CostingRejectLineItemModal from './CostingRejectLineItemModal';
import { EditQuantitiesButtons } from './EditButtons';
import SupplierCostPartQuantityFields from './SupplierCostPartQuantityFields';
import SupplierCosts from './SupplierCosts';
import SupplierCostsContainer from './SupplierCostsContainer';

const CostingLineItemUnitCost = ({
  fieldPrefix,
  formik,
  isCustomerPortalAutoquote,
  onSaveLineItem,
}) => {
  const { setAlert } = useContext(AlertContext);
  const { user } = useContext(UserContext);

  const lineItem = get(formik, `values.${fieldPrefix}`);
  const process = get(formik, `values.${fieldPrefix}.build_package.processes.0`);
  const [initialSupplierCosts, setInitialSupplierCosts] = useState(
    cloneDeep(lineItem?.supplier_costs)
  );
  const [initialRejectedReason, setInitialRejectedReason] = useState(lineItem?.rejected_reason);

  const availableRejectedCodes = CRLI_REJECTED_CODES[process?.manufacturing_process.name];

  const getRejectedCodeValuesAsString = () =>
    availableRejectedCodes
      ? lineItem?.rejected_codes
          .map(code => {
            return CRLI_REJECTED_CODES[process?.manufacturing_process?.name][code.rejected_code];
          })
          .join(', ')
      : [];

  const [initialRejectedCode, setInitialRejectedCode] = useState(getRejectedCodeValuesAsString());

  if (!process || !lineItem) return null;

  const rejectedCodeInfoUrl = availableRejectedCodes
    ? CRLI_REJECTED_CODES.more_info[process.manufacturing_process.name]
    : '';

  const resetRejectedReason = () =>
    formik.setFieldValue(`${fieldPrefix}.rejected_reason`, initialRejectedReason);

  const isEditingQuantities = get(formik.values, `${fieldPrefix}.is_editing_quantities`, false);

  const saveRejectedReason = async () => {
    try {
      await onSaveLineItem(lineItem);
      setInitialRejectedReason(lineItem.rejected_reason);
      setInitialRejectedCode(getRejectedCodeValuesAsString());
      setAlert({ color: 'success', message: 'Saved!' });
    } catch (error) {
      setAlert({
        color: 'danger',
        message: error?.response?.data?.messages?.[0] ?? 'Failed to save',
      });
    }
  };

  const validateSupplierCosts = () => {
    return new Promise((resolve, reject) => {
      try {
        supplierCostPartQuantitySchema.validateSync(lineItem, {
          abortEarly: false,
        });
        resolve();
      } catch (validation) {
        const errors = cloneDeep(formik.errors);
        validation.inner.forEach(validation => {
          set(errors, `${fieldPrefix}.${validation.path}`, validation.message);
        });
        formik.setErrors(errors);
        reject();
      }
    });
  };

  const saveSupplierCosts = () => {
    return new Promise((resolve, reject) => {
      validateSupplierCosts()
        .then(() => {
          Promise.all(
            lineItem.supplier_costs.map(({ id, part_quantities }) =>
              api.put(`/supplier_cost/${id}/part_quantities`, {
                supplier_cost_part_quantities: part_quantities,
              })
            )
          )
            .then(() => {
              return api.get(`/costing_request_line_items/${lineItem.id}`);
            })
            .then(res => {
              formik.setFieldValue(`${fieldPrefix}.supplier_costs`, res.data.supplier_costs);
              setInitialSupplierCosts(cloneDeep(lineItem.supplier_costs));
              resolve();
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const isRejected = lineItem.state === COSTING_REQUEST_LI_STATES.Rejected;
  const isInProgress = lineItem.state === COSTING_REQUEST_LI_STATES.InProgress;
  const isCompleted = lineItem.state === COSTING_REQUEST_LI_STATES.Completed;
  const canSubmitCosts =
    user.canSubmitCosts &&
    (!isCustomerPortalAutoquote ||
      mfgProcessesWithEditableSupplierOnAutoquote.includes(
        process?.manufacturing_process?.name
      )) &&
    isInProgress;

  return (
    <div>
      <div className="card-footer bg-white pb-0">
        <div>
          <h4 className="flex align-items-center">
            <span className="mr-2">Unit Cost</span>
            <CostingRequestStateBadge state={lineItem.state} />
            {isCompleted && lineItem.completed_at && (
              <p className="ml-2 text-muted font-size-sm">
                {moment(lineItem.completed_at).format('M/D/YY @ H:mma')}
              </p>
            )}
            <EditQuantitiesButtons
              fieldPrefix={fieldPrefix}
              initialSupplierCosts={initialSupplierCosts}
              isCustomerPortalAutoquote={isCustomerPortalAutoquote}
              isEditing={isEditingQuantities}
              onSave={saveSupplierCosts}
            />
            {isRejected && (
              <div className="flex ml-auto">
                <CostingRejectLineItemModal
                  fieldPrefix={fieldPrefix}
                  isEdit={true}
                  onConfirm={saveRejectedReason}
                  onCancel={resetRejectedReason}
                />
              </div>
            )}
          </h4>
        </div>

        {isRejected && (
          <Alert className="font-size-md mt-2" color="danger">
            {availableRejectedCodes && !isEmpty(rejectedCodeInfoUrl) && (
              <Link
                to={{
                  pathname: rejectedCodeInfoUrl,
                }}
                openNewWindow
                className="float-right alert-link font-weight-bold"
              >
                <Icon name="external-link-alt" className="mr-1" />
                More info on rejections
              </Link>
            )}
            Rejection Reason(s):
            {availableRejectedCodes && (
              <>
                <span className="font-weight-bold"> {initialRejectedCode}</span>
                {initialRejectedReason && (
                  <p className="col-md-8 p-0 m-0 my-1">
                    {renderWithLineBreaks(initialRejectedReason)}
                  </p>
                )}
              </>
            )}
            {!availableRejectedCodes && (
              <strong> {renderWithLineBreaks(initialRejectedReason)}</strong>
            )}
            <CostingDocuments
              withLabel={false}
              readonly={true}
              fieldPrefix={fieldPrefix}
              fieldName="rejection_documents"
            />
          </Alert>
        )}
      </div>

      <SupplierCostsContainer
        fieldPrefix={fieldPrefix}
        canSubmitCosts={canSubmitCosts}
        editQuantitiesMode={isEditingQuantities}
      >
        {props => {
          if (isEditingQuantities) {
            return (
              <div className="col-md-6">
                <SupplierCostPartQuantityFields {...props} />
              </div>
            );
          } else {
            return <SupplierCosts {...props} />;
          }
        }}
      </SupplierCostsContainer>
    </div>
  );
};

CostingLineItemUnitCost.propTypes = {
  fieldPrefix: PropTypes.string,
  formik: PropTypes.object,
  isCustomerPortalAutoquote: PropTypes.bool,
  lineItem: PropTypes.object,
  onSaveLineItem: PropTypes.func,
};

export default withAlert(connect(React.memo(CostingLineItemUnitCost, isFormikAtPrefixTheSame)));
