/* eslint-disable react/display-name,react/prop-types */
import { sanitizeUrl } from '@braintree/sanitize-url';
import { countBy, kebabCase } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, CostingRequestStateBadge, Icon, Link, Table } from 'fr-shared/components';
import { getOpportunityUrl } from 'fr-shared/lib/salesforce';

import { TABLE_COLUMNS } from '../utils/queues';

const columnsByName = {
  [TABLE_COLUMNS.Id]: {
    Header: 'Request #',
    accessor: 'id',
    Cell: ({ original: { id, customer_portal } }) => (
      <div>
        {customer_portal && (
          <span
            className="border border-secondary text-secondary rounded-circle font-size-xs mr-1"
            style={{ padding: '2px 3px' }}
          >
            <Icon name="shopping-cart" />
          </span>
        )}
        <Link
          dataTestId="costing-table-costing-request-id-link"
          to={`/admin/costing_requests/${id}`}
        >
          CR-{id}
        </Link>
      </div>
    ),
    width: 100,
  },
  [TABLE_COLUMNS.Completed]: {
    Header: 'Completed',
    id: 'completed',
    accessor: data => {
      return (
        data.line_items &&
        data.line_items.filter(
          li => li.state === 'Completed' || li.state === 'Closed' || li.state === 'Rejected'
        ).length
      );
    },
    sortable: false,
    className: 'flex justify-content-end',
  },
  [TABLE_COLUMNS.CustomerId]: {
    Header: 'Customer',
    id: 'customer_id',
    accessor: 'customer',
    Cell: ({ value }) => <Link to={`/admin/customers/${value?.id}`}>{value?.name}</Link>,
  },
  [TABLE_COLUMNS.Items]: {
    Header: 'Items',
    id: 'items',
    accessor: data => data.line_items?.length,
    className: 'flex justify-content-end',
    sortable: false,
    width: 75,
  },
  [TABLE_COLUMNS.LatestStateChangeAt]: {
    Header: 'Date Completed',
    accessor: 'latest_state_change_at',
    Cell: ({ value }) => (value ? moment(value).format('MM/DD/YYYY h:mm A') : ''),
  },
  [TABLE_COLUMNS.ManufacturingProcessId]: {
    Header: 'MFG Process',
    id: 'manufacturing_process_id',
    sortable: false,
    Cell: ({ original: { line_items } }) => {
      const count = countBy(line_items, 'manufacturing_process.name');
      const countString = Object.keys(count).map(k => ` ${k}: ${count[k]}`);
      return <span data-rh={countString}>{Object.keys(count).join(', ')}</span>;
    },
  },
  [TABLE_COLUMNS.OpportunityId]: {
    Header: 'Opportunity',
    accessor: 'opportunity_id',
    Cell: ({ value }) => (
      <Link to={{ pathname: getOpportunityUrl(value) }} openNewWindow>
        {value}
      </Link>
    ),
  },
  [TABLE_COLUMNS.PublicId]: {
    Header: 'Public ID',
    accessor: 'public_id',
  },
  [TABLE_COLUMNS.DateClosed]: {
    Header: 'Date Closed',
    accessor: 'closed_at',
    Cell: ({ value }) => (value ? moment(value).format('MM/DD/YYYY h:mm A') : ''),
  },
  [TABLE_COLUMNS.DateCreated]: {
    Header: 'Date Created',
    accessor: 'inserted_at',
    Cell: ({ value }) => moment(value).format('MM/DD/YYYY h:mm A'),
  },
  [TABLE_COLUMNS.DateRequested]: {
    Header: 'Date Requested',
    accessor: 'requested_at',
    Cell: ({ value }) => (value ? moment(value).format('MM/DD/YYYY h:mm A') : ''),
  },
  [TABLE_COLUMNS.DateSubmitted]: {
    Header: 'Date Submitted',
    accessor: 'requested_at',
    Cell: ({ value }) => (value ? moment(value).format('MM/DD/YYYY h:mm A') : ''),
  },
  [TABLE_COLUMNS.State]: {
    Header: 'State',
    sortable: false,
    accessor: 'state',
    Cell: ({ value }) => <CostingRequestStateBadge state={value} style={{ width: '100px' }} />,
  },
  [TABLE_COLUMNS.TimeInDraftQueue]: {
    Header: 'Time in Queue',
    className: 'flex justify-content-end',
    accessor: 'inserted_at',
    Cell: ({ value }) => moment(value).fromNow(true),
  },
  [TABLE_COLUMNS.TimeInRequestedQueue]: {
    Header: 'Time in Queue',
    className: 'flex justify-content-end',
    accessor: 'requested_at',
    Cell: ({ value }) => (value ? moment(value).fromNow(true) : ''),
  },
  [TABLE_COLUMNS.TimeInInProgressQueue]: {
    Header: 'Time Since Requested',
    className: 'flex justify-content-end',
    accessor: 'requested_at',
    Cell: ({ value }) => (value ? moment(value).fromNow(true) : ''),
  },
};

const CostingTable = ({
  children,
  columns = [],
  data,
  emptyText,
  loading,
  onFetchData,
  pages,
  padding = true,
  showPagination = true,
  sort,
}) => (
  <div className={padding ? 'p-3' : ''}>
    {children}
    {data?.length > 0 ? (
      <Table
        manual={true}
        data={data}
        pages={pages}
        pageSize={data.length || 20}
        showPagination={showPagination}
        onFetchData={onFetchData}
        loading={loading}
        className="bg-white -striped -flex -left"
        defaultSorted={sort}
        columns={[
          {
            Header: '',
            width: 45,
            expander: true,
            Expander: ({ isExpanded, ...rest }) => (
              <div className="flex justify-content-between align-items-center">
                {rest.original.line_items && rest.original.line_items.length > 0 && (
                  <Button size="sm" outline color="secondary">
                    <i className={`fas fa-chevron-${isExpanded ? 'up' : 'down'}`} />
                  </Button>
                )}
              </div>
            ),
          },
          ...columns.map(name => columnsByName[name]),
          {
            Header: 'Actions',
            id: 'actions',
            Cell: ({ original: { id, box_file_location } }) => {
              return (
                <>
                  <Link
                    className="font-size-md"
                    to={{ pathname: sanitizeUrl(box_file_location) }}
                    openNewWindow
                  >
                    box
                  </Link>
                  <Link className="font-size-md" to={`/admin/costing_requests/${id}`}>
                    Open
                  </Link>
                </>
              );
            },
            className: 'flex justify-content-around',
            sortable: false,
            width: 120,
          },
        ]}
        SubComponent={row => {
          return (
            <Table
              data={row.original.line_items}
              defaultPageSize={row.original.line_items.length}
              showPagination={false}
              className="-no-shadow"
              columns={[
                {
                  Header: 'Part Name',
                  id: 'part.name',
                  accessor: data => {
                    return (
                      <Link
                        to={{
                          pathname: `https://api.fastradius.com/api/v1/part_file_revisions/${data.part.current_revision?.id}`,
                        }}
                        openNewWindow
                      >
                        {data.part.name}
                      </Link>
                    );
                  },
                },
                {
                  Header: 'Item State',
                  accessor: 'state',
                },
                {
                  Header: 'MFG Process',
                  accessor: 'manufacturing_process.name',
                },
                { Header: 'Material', accessor: 'material.name' },
                {
                  Header: 'Color',
                  id: 'color',
                  accessor: data => (
                    <span className={kebabCase(data.color && data.color.name)}>
                      {data.color && data.color.name}
                    </span>
                  ),
                },
                { Header: 'Inspection Type', accessor: 'inspection_type.name' },
              ]}
            />
          );
        }}
      />
    ) : loading ? (
      <h1 className="text-gray-400 text-center flex-column">Loading Requests...</h1>
    ) : (
      <>
        <div className="flex">
          <div className="flex-grow-1" />
          <div className="d-block border border-3 border-gray-400 rounded-circle text-gray-400 px-2 my-4">
            <Icon
              className="font-size-2xl text-gray-400 px-3 py-3"
              type="far"
              name="clipboard-check"
            />
          </div>
          <div className="flex-grow-1" />
        </div>
        <h1 className="text-gray-400 text-center">{emptyText}</h1>
      </>
    )}
  </div>
);

CostingTable.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      line_items: PropTypes.arrayOf(
        PropTypes.shape({
          state: PropTypes.string,
          manufacturing_process: PropTypes.shape({ name: PropTypes.string }),
          part: PropTypes.shape({
            name: PropTypes.string,
          }),
          material: PropTypes.shape({ name: PropTypes.string }),
          inspection_type: PropTypes.shape({ name: PropTypes.string }),
        })
      ),
      box_file_location: PropTypes.string,
      color: PropTypes.shape({ name: PropTypes.string }),
    })
  ),
  emptyText: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  showPagination: PropTypes.bool,
  sort: PropTypes.array,
};

export default CostingTable;
