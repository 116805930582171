import PropTypes from 'prop-types';
import React from 'react';

import styles from './Table.module.css';

export const Table = ({ className, children }) => (
  <table className={`${styles.Root} ${className}`}>{children}</table>
);

Table.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Table;
