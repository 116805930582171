import PropTypes from 'prop-types';

export const idType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);
export const materialType = PropTypes.shape({
  id: idType.isRequired,
  name: PropTypes.string.isRequired,
});
export const processType = PropTypes.shape({
  id: idType.isRequired,
  name: PropTypes.string.isRequired,
  materials: PropTypes.arrayOf(materialType).isRequired,
});
