import { flatMap, groupBy, isEmpty, values } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Address } from 'portal/components';
import { getShippingText } from 'portal/lib/shipping';

import Shipment from './Shipment';

/**
 * Get the shipments from the order build packs (line items)
 * @param {*} orderLineItems
 * @returns an object that contains shipment ids as keys and lists of shipment line items as values
 */
const formatShipments = orderLineItems => {
  const shipmentLines = flatMap(orderLineItems, lineItem => {
    return lineItem.shipment_line_items.map(sli => {
      return {
        shipmentId: sli.shipment_id,
        trackingNumber: sli.tracking_number,
        shippedQuantity: sli.quantity,
        shippedDate: sli.shipped_date,
        orderQuantity: lineItem.quantity,
        description: lineItem.description,
      };
    });
  });
  return values(groupBy(shipmentLines, 'shipmentId'));
};

const formatRemainingQuantities = lineItems => {
  return lineItems
    .filter(lineItem => lineItem.remaining_to_fulfill > 0)
    .map(lineItem => {
      return {
        shipmentId: '',
        trackingNumber: '',
        shippedQuantity: lineItem.remaining_to_fulfill,
        shippedDate: '',
        orderQuantity: lineItem.quantity,
        description: lineItem.description,
      };
    });
};

const ShipmentDetails = ({ order }) => {
  const shipments = formatShipments(order.line_items);
  const unfulfilledLineItems = formatRemainingQuantities(order.line_items);

  return (
    <div className="py-5 border-top">
      <h3>Shipping Details</h3>
      <div className="row my-5">
        <div className="col">
          <div className="text-gray font-size-sm">Shipping method</div>
          <div className="text-light">{getShippingText(order.shipping_method)}</div>
        </div>
        <div className="col">
          <div className="text-gray font-size-sm">Address</div>
          <div className="text-light">
            <Address address={order.shipping_address} />
          </div>
        </div>
      </div>
      <div>
        {!isEmpty(shipments) &&
          shipments.map((lineItems, index) => {
            return (
              <div key={`sld_${index}`} className="mt-2">
                <Shipment index={index} lineItems={lineItems} status="Shipped" />
              </div>
            );
          })}
        {!isEmpty(unfulfilledLineItems) && (
          <div className="mt-2">
            <Shipment
              index={shipments.length}
              lineItems={unfulfilledLineItems}
              status="Pending"
            />
          </div>
        )}
      </div>
    </div>
  );
};

ShipmentDetails.propTypes = {
  order: PropTypes.shape({
    line_items: PropTypes.array.isRequired,
    shipping_address: PropTypes.object.isRequired,
    shipping_method: PropTypes.string.isRequired,
  }).isRequired,
};

export default ShipmentDetails;
