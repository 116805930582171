import PropTypes from 'prop-types';
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';

import { PartDimensions, TableBodyCell, TableBodyRow } from 'portal/components';
import { PartsContext } from 'portal/contexts/PartsContext';
import { date } from 'portal/lib/date';

import PartNameCell from './PartNameCell/PartNameCell';
import PartSelector from './PartSelector/PartSelector';

const ListViewRow = ({ part }) => {
  const { handleSelectPart, selectedParts } = useContext(PartsContext);
  const [isSelected, setIsSelected] = useState(false);

  const isPartSelected = !!selectedParts[part.id];

  useEffect(() => {
    setIsSelected(isPartSelected);
  }, [isPartSelected, setIsSelected]);

  const handleSelectClick = useCallback(() => {
    handleSelectPart(isSelected, part);
  }, [handleSelectPart, isSelected, part]);

  const dateAdded = date(part?.inserted_at);
  const dateEdited = date(part?.updated_at);
  const documents = part?.documents || [];
  const screenshotSrc = part?.current_revision?.screenshot;

  return (
    <TableBodyRow isSelected={isSelected} part={part}>
      <TableBodyCell>
        <PartSelector isSelected={isSelected} handleSelectClick={handleSelectClick} />
      </TableBodyCell>
      <TableBodyCell>
        <PartNameCell id={part.id} name={part.name} screenshotSrc={screenshotSrc} />
      </TableBodyCell>
      <TableBodyCell dataTestId="list-view-row-date-added-body-cell" value={dateAdded} />
      <TableBodyCell dataTestId="list-view-row-date-edited-body-cell" value={dateEdited} />

      <TableBodyCell>
        <PartDimensions part={part} />
      </TableBodyCell>

      <TableBodyCell value={documents.length} />
    </TableBodyRow>
  );
};

ListViewRow.propTypes = {
  part: PropTypes.shape({
    current_revision: PropTypes.shape({
      screenshot: PropTypes.string,
    }).isRequired,
    documents: PropTypes.array,
    id: PropTypes.number.isRequired,
    inserted_at: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    units: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(ListViewRow);
