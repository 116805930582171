import { pickBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { api } from 'fr-shared/api';
import { Table, classNames } from 'fr-shared/components';
import { useTableFilters } from 'fr-shared/hooks';

export const DataTable = ({
  className,
  description,
  title,
  url,
  pageSize = 10,
  params,
  ...props
}) => {
  const {
    data: data,
    pages,
    onFetchData,
    loading,
  } = useTableFilters(null, { page: 1, page_size: pageSize }, filters => {
    return api.get(url, { params: { ...params, ...pickBy(filters) } });
  });

  return (
    <div className="pb-4">
      <div className="mb-3">
        <h3 className="">{title}</h3>
        <p className="text-muted">{description}</p>
      </div>
      <Table
        manual={true}
        data={data || []}
        pageSize={pageSize}
        pages={pages}
        showPagination={true}
        onFetchData={onFetchData}
        loading={loading}
        className={classNames(['bg-white -left -striped mb-5', className])}
        {...props}
      />
    </div>
  );
};

DataTable.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  params: PropTypes.object,
  pageSize: PropTypes.number,
};
