import { FieldArray, useFormikContext } from 'formik';
import React, { useState } from 'react';

import { Checkbox, FormCheckbox } from 'fr-shared/components';

import { FormFieldBase } from 'portal/components';

interface FormCheckboxMultipleOptionsProps {
  id: number;
  label: string;
  value: boolean;
}

interface FormCheckboxMultipleProps {
  label: string;
  hasSelectAll?: boolean;
  name: string;
  options: FormCheckboxMultipleOptionsProps[];
  required?: boolean;
  stacked?: boolean;
}

const FormCheckboxMultiple = (props: FormCheckboxMultipleProps) => {
  const { errors, setValues, values }: { [x: string]: any } = useFormikContext();
  const { options, name, required, hasSelectAll, stacked } = props;
  const error = errors?.[props.name];
  const classNames = stacked ? 'flex flex-column' : 'flex flex-row';
  const [selectAll, setSelectAll] = useState(
    hasSelectAll && Array.isArray(options) && options.length > 0
      ? options.every((mp: FormCheckboxMultipleOptionsProps) => mp.value === true)
      : false
  );

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setSelectAll(checked);
    const working = values[name].map((mp: FormCheckboxMultipleOptionsProps) => ({
      ...mp,
      value: checked,
    }));

    setValues({ ...values, [name]: working });
  };

  return (
    <FormFieldBase {...props} isRequired={required} error={error}>
      <div data-testid="form-checkbox-multiple-container" className={`col-md ${classNames}`}>
        {hasSelectAll ? (
          <Checkbox
            name="select-all"
            label="Select All"
            value={selectAll}
            onChange={handleSelectAll}
            className="mb-2"
          />
        ) : null}
        <FieldArray
          name={name}
          render={() => {
            if (Array.isArray(options)) {
              return options.map((p, index) => (
                <FormCheckbox
                  key={`${name}-${p.label}-${index}`}
                  name={`${name}.${index}.value`}
                  label={p.label}
                  className="mb-2"
                />
              ));
            }

            return null;
          }}
        />
      </div>
    </FormFieldBase>
  );
};

export default FormCheckboxMultiple;
