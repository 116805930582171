import { find } from 'lodash';
import React, { PropsWithChildren, SyntheticEvent, useEffect, useState } from 'react';

import { TooltipContent, TooltipControlled } from 'fr-shared/components/core/Tooltip/Tooltip';

import { classNames } from 'portal/components';
import { isAutoquoteLoading, isAutoquoteTierList } from 'portal/lib/autoquote';

import {
  BaseCompareContainerProps,
  Column,
  panelScrollColumnIntoView,
  scrollColumnIntoView,
} from './CompareContainer';
import styles from './CompareContainer.module.css';
import ManufacturingSpecificationFields, {
  getMaterialInfo,
} from './ManufacturingSpecificationFields';
import CostCurvesCard, { DriverStats } from './components/CostCurvesCard';
import TierPriceCard from './components/TierPriceCard';
import DfmChecks from './components/dfmChecks/DfmChecks';
import Material from './components/material';

const PrintCompareContainer = ({
  children,
  colFieldData,
  dfmChecks,
  dfmCheckOnClick,
  handleBack,
  handleFieldChanges,
  leadTimeFields,
  manufacturingProcesses,
  onCheckStandard,
  pushToEvaluate,
  selectedDfmCheck,
}: PropsWithChildren<BaseCompareContainerProps>) => {
  const [driverData, setDriverData] = useState<{ target: HTMLElement; stats: DriverStats }>({
    target: null,
    stats: null,
  });
  const containerRef = React.createRef<HTMLDivElement>();

  const isLoadingTiers = colFieldData.some(
    c => c.autoquoteTiersState && isAutoquoteLoading(c.autoquoteTiersState)
  );
  const hasMultipleTiers = colFieldData.some(
    c =>
      c.autoquoteTiersState &&
      isAutoquoteTierList(c.autoquoteTiersState) &&
      c.autoquoteTiersState.length > 1
  );

  // Switch the toggle back to standard if after loading all the tiers, none have multiple tiers
  useEffect(() => {
    if (!isLoadingTiers && !hasMultipleTiers) {
      onCheckStandard(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingTiers, hasMultipleTiers]);

  /**
   * For how the header and footer sticky work, please refer to the original CompareContainer.tsx file notes
   */
  return (
    <section>
      <div className={styles['container']} ref={containerRef}>
        {children}
        <div className="w-fit print:pt-0">
          <header className={styles['process-header']}>
            <TooltipControlled target={driverData.target}>
              <TooltipContent className="react-hint">
                <span className="text-coolGray-300">
                  <b className="text-base text-white">
                    {(driverData.stats?.percentage * 100)?.toFixed(1)}
                  </b>
                  % | <sup>$</sup>
                  <b className="text-base text-white">{driverData.stats?.price?.toFixed(2)}</b>
                  &nbsp;of the&nbsp;
                  <span className="text-white">${driverData.stats?.totalPrice?.toFixed(2)}</span>
                  &nbsp;total
                </span>
              </TooltipContent>
            </TooltipControlled>
            <div className={styles['row']}>
              {colFieldData.map((fieldData, index) => {
                const currentProcess = find(manufacturingProcesses, {
                  id: +fieldData.manufacturing_process_id,
                });
                let processName;

                const { currentMaterial } = getMaterialInfo(currentProcess, fieldData);

                const childRef = React.createRef<HTMLDivElement>();
                const pushToEvaluateParams = {
                  manufacturing_process_id: fieldData.manufacturing_process_id,
                  material_id: fieldData.material_id,
                  color_id: fieldData.color_id,
                  layer_thickness_id: fieldData.layer_thickness_id,
                };
                if (currentProcess) {
                  const { name } = currentProcess;

                  processName = name;
                }
                return (
                  <Column
                    className={classNames([
                      'pt-5 pb-6 spec-column print-spec-column print:pt-2 print:pb-0',
                    ])}
                    key={fieldData.columnId}
                    offset={fieldData.colOffset}
                    onFocus={(event: SyntheticEvent<HTMLElement>) =>
                      scrollColumnIntoView(containerRef.current, event.currentTarget)
                    }
                  >
                    <div className="compare-component print:mb-4">
                      <ManufacturingSpecificationFields
                        disabled={false}
                        index={index}
                        fieldData={fieldData}
                        manufacturingProcesses={manufacturingProcesses}
                        handleBack={handleBack}
                        handleFieldChanges={handleFieldChanges}
                      />
                    </div>

                    {currentProcess ? (
                      <div className="compare-component print:mb-4">
                        <TierPriceCard
                          state={fieldData.autoquoteTiersState}
                          isStandard={leadTimeFields.isStandard}
                          leadTimeFields={leadTimeFields}
                        />
                      </div>
                    ) : null}

                    {currentProcess && (
                      <div className="compare-component print:mb-4">
                        <Material
                          selectedManufacturingProcess={currentProcess}
                          material={currentMaterial}
                        />
                      </div>
                    )}

                    {currentProcess && (
                      <div className="compare-component print:mb-2">
                        <CostCurvesCard
                          colorForAllBarBgs="rgba(164, 173, 185, .25)"
                          costData={fieldData.costData}
                          currentProcessName={processName}
                          onSeeMore={() => {}}
                          onDriverOpen={(target, stats) => setDriverData({ target, stats })}
                          onDriverClose={() => setDriverData({ target: null, stats: null })}
                        />
                      </div>
                    )}

                    {currentProcess && (
                      <div className="compare-component">
                        <DfmChecks
                          colId={fieldData.columnId}
                          dfmChecks={dfmChecks}
                          disabled={false}
                          processId={fieldData.manufacturing_process_id}
                          processName={processName}
                          selectedDfmCheck={selectedDfmCheck}
                          handleSelected={(dfmCheck: ManufacturabilityCheck) => {
                            dfmCheckOnClick(fieldData.columnId, dfmCheck, pushToEvaluateParams);
                            panelScrollColumnIntoView({
                              childEl: childRef.current,
                              containerEl: containerRef.current,
                            });
                          }}
                          pushToEvaluate={() => pushToEvaluate(pushToEvaluateParams)}
                        />
                      </div>
                    )}
                  </Column>
                );
              })}
            </div>
          </header>
        </div>
      </div>
    </section>
  );
};

export default PrintCompareContainer;
