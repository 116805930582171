import React from 'react';

import { classNames } from 'fr-shared/components';

interface Props {
  [propName: string]: unknown;
  type?: string;
  right?: boolean;
  left?: boolean;
  opacity?: boolean;
  className?: string;
  tooltip?: string;
  name?: string;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  dataTestId?: string;
}

const Icon = ({
  type = 'fas',
  right,
  left,
  opacity,
  className,
  tooltip,
  name,
  sm,
  md,
  lg,
  xl,
  dataTestId,
  ...attributes
}: Props) => {
  const classes = classNames([
    type,
    `fa-${name}`,
    { 'mr-2': right },
    { 'ml-2': left },
    { 'opacity-30': opacity },
    { 'font-size-sm': sm },
    { 'font-size-md': md },
    { 'font-size-lg': lg },
    { 'font-size-xl': xl },
    { 'click-feedback': attributes.onClick },
    className,
  ]);
  return <i data-rh={tooltip} className={classes} data-testid={dataTestId} {...attributes} />;
};

export default Icon;
