import PropTypes from 'prop-types';
import React from 'react';
import { Popper } from 'react-popper';

import { classNames } from 'fr-shared/components';

import DropdownContext from './DropdownContext';

const noFlipModifier = { flip: { enabled: false } };

const directionPositionMap = {
  up: 'top',
  left: 'left',
  right: 'right',
  down: 'bottom',
};

const DropdownMenu = ({
  className = '',
  right,
  tag = 'div',
  flip = true,
  modifiers = {},
  persist = false,
  ...tagProps
}) => {
  const dropdownContext = React.useContext(DropdownContext);
  const classes = classNames([
    className,
    'dropdown-menu',
    {
      'dropdown-menu-right': right,
      show: dropdownContext.isOpen,
    },
  ]);

  const Tag = tag;

  if (persist || (dropdownContext.isOpen && !dropdownContext.inNavbar)) {
    const position1 = directionPositionMap[dropdownContext.direction] || 'bottom';
    const position2 = right ? 'end' : 'start';
    const poperPlacement = `${position1}-${position2}`;
    const poperModifiers = !flip
      ? {
          ...modifiers,
          ...noFlipModifier,
        }
      : modifiers;

    return (
      <Popper placement={poperPlacement} modifiers={poperModifiers}>
        {({ ref, style, placement }) => (
          <Tag
            tabIndex="-1"
            role="menu"
            ref={ref}
            style={style}
            {...tagProps}
            aria-hidden={!dropdownContext.isOpen}
            className={classes}
            x-placement={placement}
          />
        )}
      </Popper>
    );
  }

  return (
    <Tag
      tabIndex="-1"
      role="menu"
      {...tagProps}
      aria-hidden={!dropdownContext.isOpen}
      className={classes}
      x-placement={tagProps.placement}
    />
  );
};

DropdownMenu.propTypes = {
  tag: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
        PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
      ])
    ),
  ]),
  children: PropTypes.node.isRequired,
  right: PropTypes.bool,
  flip: PropTypes.bool,
  modifiers: PropTypes.object,
  className: PropTypes.string,
  persist: PropTypes.bool,
};

export default DropdownMenu;
