import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { FormField, FormSelect } from 'fr-shared/components';
import { CNC_WORKHOLDING_OPTIONS, WORK_ORDER_STATES } from 'fr-shared/lib/work_orders';

import { BuildTimeFormField, MPILinkFormField, PrinterFormField } from './fields';

const WorkOrderDetailsCNC = ({ isEditing, readonly }) => {
  const formik = useFormikContext();
  const { state } = formik.values;

  return (
    <div className="card-body">
      <div className="row">
        <div className="col-lg">
          {isEditing && <PrinterFormField labelText="Machine" readonly={readonly} />}
          <BuildTimeFormField labelText="Build Time" />
          <FormField name="material_lot_number_a" label="Material Lot #" readonly={readonly} />
          {isEditing && state !== 'Draft' && (
            <FormSelect
              label="State"
              name="state"
              className="mb-0"
              optionList={WORK_ORDER_STATES}
              valueAccessor={option => option}
              nameAccessor={option => option}
              readonly={readonly}
            />
          )}
        </div>
        <div className="col-lg">
          <FormField
            name="link_to_cam_setup_sheet"
            label="Link to CAM setup sheet"
            readonly={readonly}
          />
          <FormField name="notes" label="Notes" readonly={readonly} />
          <FormField name="stock_notes" label="Stock Notes" readonly={readonly} />
          <FormSelect
            name="workholding"
            label="Workholding"
            readonly={readonly}
            optionList={CNC_WORKHOLDING_OPTIONS}
            keyAccessor={option => option}
            valueAccessor={option => option}
            nameAccessor={option => option}
          />
          <MPILinkFormField readonly={readonly} />
        </div>
      </div>
    </div>
  );
};

WorkOrderDetailsCNC.propTypes = {
  isEditing: PropTypes.bool,
  readonly: PropTypes.bool,
};
export default WorkOrderDetailsCNC;
