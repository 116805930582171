import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, GlobalErrorBoundary, Helmet, NotFound } from 'fr-shared/components';

import ShipmentViewer from './components/ShipmentViewer';

const ShipmentShow = ({ match }) => {
  const [shipment, setShipment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const shipmentId = match.params.id;

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`/shipments/${shipmentId}`)
      .then(res => setShipment(res.data))
      .catch(error => {
        if (error.response?.status !== 404) {
          setHasError(true);
          Sentry.captureMessage('Shipment GET Failed');
          Sentry.setExtra('shipment_id', shipmentId);
          Sentry.setExtra('error', error);
        }
      })
      .finally(() => setIsLoading(false));
  }, [shipmentId]);

  if (isLoading) return null;
  if (hasError) return <GlobalErrorBoundary />;
  if (!shipment) return <NotFound />;

  return (
    <>
      <Helmet title="Shipments" />
      <Breadcrumb to="/admin/shipments">Shipments</Breadcrumb>
      {shipment && <ShipmentViewer shipment={shipment} />}
    </>
  );
};

ShipmentShow.propTypes = {
  match: PropTypes.object,
};

export default ShipmentShow;
