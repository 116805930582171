import { IconFont, Sidebar } from '@fast-radius/shared-ui';
import React, { useEffect, useRef, useState } from 'react';

import { classNames } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { useFeatureFlags } from 'fr-shared/hooks';

import styles from './SidebarLiveChat.module.css';

const SIDEBAR_LIVE_CHAT_FLAG = 'sidebar-live-chat';

const SidebarLiveChat = ({ onInit }: { onInit?: () => void }) => {
  const userAnalytics = useUserAnalyticsContext();
  const driftTimer = useRef(null);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [hasNewMessage, setHasNewMessage] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const initDrift = (drift: any) => {
    if (!drift) return;

    drift.on('ready', () => {
      // hide the widget when you close the chat
      drift.on('chatClose', (_e: any) => {
        drift.api.widget.hide();
        userAnalytics.track('Live Chat - Closed via Drift');
        setOpen(false);
      });

      // clear notifications when you open the chat
      drift.on('chatOpen', (_e: any) => {
        setHasNewMessage(false);
      });

      // set notification if we get a message while closed
      drift.on('message', (_data: any) => {
        if (!open) {
          userAnalytics.track('Live Chat - Missed Notification');
          setHasNewMessage(true);
        }
      });
    });

    onInit && onInit();
  };

  useEffect(() => {
    // Drift needs a couple seconds to initalize, so delay looking for it on the window
    // If this fails, we check for `loaded` and init there if we need to
    driftTimer.current && clearInterval(driftTimer.current);
    driftTimer.current = setTimeout(() => {
      if (window.drift) {
        initDrift(window.drift);
        setLoaded(true);
      }
    }, 3000);
    return () => clearInterval(driftTimer.current);
    /* eslint-disable-next-line */
  }, []);

  const { [SIDEBAR_LIVE_CHAT_FLAG]: isLiveChatEnabled } = useFeatureFlags({
    [SIDEBAR_LIVE_CHAT_FLAG]: 'on',
  });

  if (!isLiveChatEnabled) return null;

  const toggleDrift = () => {
    setHasNewMessage(false);
    if (open) {
      userAnalytics.track('Live Chat - Closed Via Sidebar');
      window.drift?.api?.widget.hide();
      window.drift?.api?.hideChat();
      setOpen(false);
    } else {
      userAnalytics.track('Live Chat - Opened');
      window.drift?.api?.widget.show();
      window.drift?.api?.openChat();
      setOpen(true);
    }
  };

  const handleClick = () => {
    if (window.drift) {
      // We need to bind events to window.drift, but can't guarantee it exists
      // because its loaded in via GTM. Luckily, we can bind them right before using it
      if (!loaded) {
        initDrift(window.drift);
        setLoaded(true);
      }

      toggleDrift();
    }
  };

  return (
    <Sidebar.Section className="relative">
      <Sidebar.Link isActive={false} onClick={handleClick} className="relative cursor-pointer">
        <IconFont name="live-chat" />
        <Sidebar.Text>Live Chat</Sidebar.Text>
        {hasNewMessage && <div className={classNames([styles.Pulse, 'pulse'])} />}
      </Sidebar.Link>
    </Sidebar.Section>
  );
};

export default SidebarLiveChat;
