import PropTypes from 'prop-types';
import React from 'react';

import { Button, RequestPaymentModal } from 'fr-shared/components';

import BraintreeDropIn from '../../orders/components/BraintreeDropIn';

export const ContactPaymentInformation = ({ customerContact }) => {
  const [showRequestPayment, setShowRequestPayment] = React.useState(false);

  if (!customerContact) return null;

  return (
    <div className="add-payment-disabled">
      <div className="flex justify-content-between align-items-center">
        <h3 className="border-0">Payment Information</h3>

        <RequestPaymentModal
          customerContact={customerContact}
          isOpen={showRequestPayment}
          toggle={setShowRequestPayment}
        />

        <Button color="success" className="ml-2" onClick={() => setShowRequestPayment(true)}>
          + Request New Payment Information
        </Button>
      </div>

      <BraintreeDropIn customerContactId={customerContact.id} readonly={true} />
    </div>
  );
};

ContactPaymentInformation.propTypes = {
  customerContact: PropTypes.object,
};
