import React, { useState } from 'react';

import { Pagination } from 'fr-shared/components';

import { classNames } from 'portal/components';

interface CarouselProps {
  content: React.ReactNode[];
  paginationPosition?: 'start' | 'center' | 'end';
  showArrows?: boolean;
}

export const Carousel = ({
  content,
  paginationPosition = 'center',
  showArrows = true,
}: CarouselProps) => {
  const [pageNumber, setPageNumber] = useState(1);

  return (
    <div className={classNames(['h-100', 'w-100', 'flex', 'flex-column'])}>
      {content[pageNumber - 1]}
      <Pagination
        className={classNames(['flex', `justify-content-${paginationPosition}`])}
        page={pageNumber}
        totalPages={content.length}
        pageNumberStyle={'circular'}
        showArrows={showArrows}
        setPage={setPageNumber}
      />
    </div>
  );
};

export default Carousel;
