import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useLineItemDefaults from 'portal/hooks/useLineItemDefaults';

const PartsContext = React.createContext();

const PartsProvider = ({
  children,
  currentSearchParam,
  currentSortParam,
  isSortDesc,
  parts,
  cancelLocalStorage,
}) => {
  const [areAllPartsSelected, setAreAllPartsSelected] = useState(false);
  const [selectedParts, setSelectedParts] = useState({});
  const [bulkPartConfig, setBulkPartConfig] = useState(useLineItemDefaults(null));
  const [partsLoaded, setPartsLoaded] = useState(false);

  const addPartsToStorage = useCallback(
    parts => {
      let partsObj = {};
      let currentObj = {};
      if (localStorage.getItem('selectedParts')) {
        currentObj = JSON.parse(localStorage.getItem('selectedParts'));
      }
      Object.values(parts).forEach(part => {
        let savedPartQuantity = 1;
        if (Object.keys(currentObj).length > 0 && part.id in currentObj) {
          savedPartQuantity = currentObj[part.id];
        }
        partsObj[part.id] = part?.quantity || savedPartQuantity;
      });
      if (!cancelLocalStorage) {
        localStorage.setItem('selectedParts', JSON.stringify(partsObj));
      }
    },
    [cancelLocalStorage]
  );

  useEffect(() => {
    if (selectedParts && Object.keys(selectedParts).length > 0) {
      addPartsToStorage(selectedParts);
    }
  }, [addPartsToStorage, selectedParts]);

  useEffect(() => {
    if (localStorage.getItem('selectedParts') && parts.length > 0) {
      const partsObj = JSON.parse(localStorage.getItem('selectedParts'));
      const partIDs = Object.keys(partsObj);
      for (var i = 0; i < partIDs.length; i++) {
        const restoredPart = parts.find(part => part.id === parseInt(partIDs[i]));
        if (restoredPart) {
          restoredPart.quantity = partsObj[partIDs[i]];
          handleSelectPart(false, restoredPart);
        }
      }
      setPartsLoaded(true);
    } else if (parts.length > 0) {
      setPartsLoaded(true);
    }
  }, [parts, handleSelectPart, removeAllPartsFromSelection]);

  useEffect(() => {
    if (Object.keys(bulkPartConfig).length > 0 && bulkPartConfig.manufacturing_process_id) {
      localStorage.setItem('bulkPartConfig', JSON.stringify(bulkPartConfig));
    }
  }, [bulkPartConfig]);

  useEffect(() => {
    if (localStorage.getItem('bulkPartConfig')) {
      setBulkPartConfig(JSON.parse(localStorage.getItem('bulkPartConfig')));
    }
  }, []);

  const addSelectedPart = useCallback(
    part => {
      setSelectedParts(currentlySelectedParts => ({
        ...currentlySelectedParts,
        [part.id]: part,
      }));
    },
    [setSelectedParts]
  );

  const removeAllPartsFromSelection = useCallback(() => {
    // We don't want to just set this to an empty object
    // because with pagination, we could have selections
    // from different pages that should not be removed
    setSelectedParts(currentlySelectedParts => {
      const newlySelectedParts = { ...currentlySelectedParts };

      parts.forEach(({ id }) => {
        if (newlySelectedParts[id]) delete newlySelectedParts[id];
      });

      addPartsToStorage(newlySelectedParts);
      removeBulkConfig();

      return newlySelectedParts;
    });

    setAreAllPartsSelected(false);
  }, [addPartsToStorage, parts, removeBulkConfig, setAreAllPartsSelected, setSelectedParts]);

  const removeSelectedPart = useCallback(
    partId => {
      setSelectedParts(currentlySelectedParts => {
        const newlySelectedParts = { ...currentlySelectedParts };

        if (newlySelectedParts[partId]) delete newlySelectedParts[partId];

        addPartsToStorage(newlySelectedParts);

        return newlySelectedParts;
      });

      setAreAllPartsSelected(false);
    },
    [addPartsToStorage, setAreAllPartsSelected, setSelectedParts]
  );

  const selectAllParts = useCallback(() => {
    // We're building an object that destructures `selectedParts`
    // because we could have previously selected parts from other
    // pages and so we don't want to lose those
    setSelectedParts(currentlySelectedParts => {
      const partsHash = parts.reduce((acc, part) => {
        acc[part.id] = part;
        return acc;
      }, {});

      return {
        ...currentlySelectedParts,
        ...partsHash,
      };
    });

    setAreAllPartsSelected(true);
  }, [parts, setAreAllPartsSelected, setSelectedParts]);

  const handleSelectAllParts = useCallback(() => {
    if (areAllPartsSelected) return removeAllPartsFromSelection();

    return selectAllParts();
  }, [areAllPartsSelected, removeAllPartsFromSelection, selectAllParts]);

  const handleSelectPart = useCallback(
    (isSelected, part) => {
      if (isSelected) return removeSelectedPart(part.id);

      return addSelectedPart(part);
    },
    [addSelectedPart, removeSelectedPart]
  );

  const handleSelectParts = useCallback(
    parts => {
      return parts.map(part => addSelectedPart(part));
    },
    [addSelectedPart]
  );

  const removeBulkConfig = useCallback(() => {
    setBulkPartConfig({});
    localStorage.removeItem('bulkPartConfig');
  }, []);

  const value = useMemo(
    () => ({
      areAllPartsSelected,
      currentSearchParam,
      isSortDesc,
      currentSortParam,
      handleSelectAllParts,
      handleSelectPart,
      handleSelectParts,
      selectedParts,
      removeAllPartsFromSelection,
      removeSelectedPart,
      bulkPartConfig,
      setBulkPartConfig,
      partsLoaded,
      removeBulkConfig,
    }),
    [
      areAllPartsSelected,
      currentSearchParam,
      isSortDesc,
      currentSortParam,
      handleSelectAllParts,
      handleSelectPart,
      handleSelectParts,
      selectedParts,
      removeAllPartsFromSelection,
      removeSelectedPart,
      bulkPartConfig,
      setBulkPartConfig,
      partsLoaded,
      removeBulkConfig,
    ]
  );

  return <PartsContext.Provider value={value}>{children}</PartsContext.Provider>;
};

PartsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  currentSearchParam: PropTypes.string,
  isSortDesc: PropTypes.bool,
  currentSortParam: PropTypes.string,
  parts: PropTypes.arrayOf(PropTypes.object).isRequired,
  cancelLocalStorage: PropTypes.bool,
};

export { PartsContext, PartsProvider };
