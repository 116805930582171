import { useTreatments } from '@splitsoftware/splitio-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { LineItemPart } from 'fr-shared/components';
import { CR_BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { isFormikAtPrefixTheSame } from 'fr-shared/utils';

import CostingPartViewer from './CostingPartViewer';

const CostingLineItemPart = ({ fieldPrefix, readonly, showManufacturabilityChecks }) => {
  const [showViewer, setShowViewer] = useState(false);

  const { [CR_BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    CR_BUILD_PACK_UI_SUPPORT,
  ]);
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';

  return (
    <>
      <LineItemPart
        fieldPrefix={fieldPrefix}
        readonly={readonly}
        showManufacturabilityChecks={showManufacturabilityChecks}
        onClickPart={() => setShowViewer(true)}
        showSurfaceArea={true}
        showPublicLink={true}
        lineItemName={lineItemName}
      />
      {showViewer && (
        <CostingPartViewer isOpen={showViewer} toggle={setShowViewer} fieldPrefix={fieldPrefix} />
      )}
    </>
  );
};

CostingLineItemPart.propTypes = {
  fieldPrefix: PropTypes.string,
  readonly: PropTypes.bool,
  formik: PropTypes.object,
  showManufacturabilityChecks: PropTypes.bool,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default React.memo(CostingLineItemPart, isFormikAtPrefixTheSame);
