import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, Helmet } from 'fr-shared/components';

import SupplierForm from './SupplierForm';

export default class SuppliersNew extends Component {
  static propTypes = {
    history: PropTypes.object,
    setErrors: PropTypes.func,
    values: PropTypes.object,
  };

  handleSubmit = (values, { setErrors }) => {
    values.deleted_at = values.active ? null : new Date().toISOString();

    api
      .post('/locations', { location: values })
      .then(() => {
        this.props.history.push('/admin/suppliers');
      })
      .catch(err => {
        if (err.response?.data) {
          const { errors, messages } = err.response.data;
          setErrors({ server: messages, ...errors });
          if (err.response?.status === 403) {
            setErrors({ server: ['You do not have access to create a supplier.'] });
          }
        }
      });
  };

  render() {
    return (
      <>
        <Helmet title="New Supplier" />
        <Breadcrumb to="/admin/suppliers">Supplier</Breadcrumb>
        <Breadcrumb to="/admin/suppliers/new">New Supplier</Breadcrumb>

        <SupplierForm onSubmit={this.handleSubmit} title="Add New Supplier" />
      </>
    );
  }
}
