import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { FormSelect, IconFont } from 'fr-shared/components';
import { UNITS_OF_MEASUREMENT } from 'fr-shared/lib/parts';

import { Button } from 'portal/components';

import styles from '../../../customer_portal/pages/create_quote/components/PortalDropzone/PortalDropzone.module.css';

const AdminPartFileDropzone = ({ open, getInputProps }) => {
  return (
    <div className="flex align-items-center justify-content-center">
      <input {...getInputProps()} id="file-dropzone" />

      <div>
        <h3 className="text-gray font-size-lg">Drag and drop files here</h3>
        <div className="flex flex-row justify-content-center align-items-center">
          <Button
            dataTestId="part-upload-button"
            className="flex justify-content-center align-items-center rounded-circle mt-1 mr-3 cursor-pointer p-0 rounded-circle"
            style={{ width: 48, height: 48 }}
            onClick={open}
          >
            <IconFont name="plus" className="text-white mr-0" />
          </Button>
          <FormSelect
            hasBlankOption={false}
            label="Units"
            labelClassName={styles.DropZoneLabel}
            name="part_units"
            optionList={UNITS_OF_MEASUREMENT}
            nameAccessor={option => option}
            valueAccessor={option => option}
            inputProps={{ required: true }}
            required
            stacked
          />
        </div>
      </div>
    </div>
  );
};
AdminPartFileDropzone.propTypes = {
  open: PropTypes.func,
  getInputProps: PropTypes.func,
};

export default AdminPartFileDropzone;
