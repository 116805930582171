import { omit } from 'lodash';
import React, { useContext } from 'react';

import {
  Breadcrumb,
  Helmet,
  Link,
  Sidebar,
  SidebarPage,
  SidebarPageContainer,
} from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { useTableFilters } from 'fr-shared/hooks';

import CostingSearch from './components/CostingSearch';
import CostingSidebar from './components/CostingSidebar';
import CostingTable from './components/CostingTable';
import {
  CLOSED_COLUMNS,
  COMPLETED_COLUMNS,
  DRAFT_COLUMNS,
  FILTER_FIELDS,
  INITIAL_CLOSED_FILTERS,
  INITIAL_CLOSED_SORT,
  INITIAL_COMPLETED_FILTERS,
  INITIAL_COMPLETED_SORT,
  INITIAL_DRAFT_FILTERS,
  INITIAL_DRAFT_SORT,
  INITIAL_IN_PROGRESS_FILTERS,
  INITIAL_IN_PROGRESS_SORT,
  INITIAL_REQUESTED_FILTERS,
  INITIAL_REQUESTED_SORT,
  IN_PROGRESS_COLUMNS,
  REQUESTED_COLUMNS,
  getClosedCostingRequests,
  getCompletedCostingRequestsForOps,
  getCompletedCostingRequestsForSales,
  getDraftCostingRequest,
  getInProgressCostingRequests,
  getRequestedCostingRequests,
} from './utils/queues';

const CLOSED_FILTER_NAME = 'costing_all_queues_closed';
const COMPLETED_FILTER_NAME = 'costing_all_queues_completed';
const DRAFT_FILTER_NAME = 'costing_all_queues_draft';
const REQUESTED_FILTER_NAME = 'costing_all_queues_requested';
const IN_PROGRESS_FILTER_NAME = 'costing_all_queues_in_progress';

const FIELDS = [
  FILTER_FIELDS.SalesContactId,
  FILTER_FIELDS.ManufacturingProcessId,
  FILTER_FIELDS.EngineeringContactId,
  FILTER_FIELDS.SupportContactId,
];

const INITIAL_CLOSED_FILTERS_LIMIT_5 = { ...INITIAL_CLOSED_FILTERS, page_size: 5 };
const INITIAL_COMPLETED_FILTERS_LIMIT_5 = { ...INITIAL_COMPLETED_FILTERS, page_size: 5 };
const INITIAL_DRAFT_FILTERS_LIMIT_5 = { ...INITIAL_DRAFT_FILTERS, page_size: 5 };
const INITIAL_REQUESTED_FILTERS_LIMIT_5 = { ...INITIAL_REQUESTED_FILTERS, page_size: 5 };
const INITIAL_IN_PROGRESS_FILTERS_LIMIT_5 = { ...INITIAL_IN_PROGRESS_FILTERS, page_size: 5 };

const CostingAllQueues = () => {
  const { user } = useContext(UserContext);

  const requestedTableFilters = useTableFilters(
    REQUESTED_FILTER_NAME,
    INITIAL_REQUESTED_FILTERS_LIMIT_5,
    getRequestedCostingRequests
  );

  const inProgressTableFilters = useTableFilters(
    IN_PROGRESS_FILTER_NAME,
    INITIAL_IN_PROGRESS_FILTERS_LIMIT_5,
    getInProgressCostingRequests
  );

  const completedTableFilters = useTableFilters(
    COMPLETED_FILTER_NAME,
    INITIAL_COMPLETED_FILTERS_LIMIT_5,
    user.isSales ? getCompletedCostingRequestsForSales : getCompletedCostingRequestsForOps
  );

  const draftTableFilters = useTableFilters(
    DRAFT_FILTER_NAME,
    INITIAL_DRAFT_FILTERS_LIMIT_5,
    getDraftCostingRequest
  );

  const closedTableFilters = useTableFilters(
    CLOSED_FILTER_NAME,
    INITIAL_CLOSED_FILTERS_LIMIT_5,
    getClosedCostingRequests
  );

  const handleTextSearch = e => {
    requestedTableFilters.handleSearch(e);
    inProgressTableFilters.handleSearch(e);
    completedTableFilters.handleSearch(e);

    if (user.isSales) {
      draftTableFilters.handleSearch(e);
      closedTableFilters.handleSearch(e);
    }
  };

  const filters = omit(
    {
      ...requestedTableFilters.filters,
      ...inProgressTableFilters.filters,
      ...completedTableFilters.filters,
      ...(user.isSales ? draftTableFilters.filters : {}),
      ...(user.isSales ? closedTableFilters.filters : {}),
    },
    [
      ...Object.keys(INITIAL_REQUESTED_FILTERS_LIMIT_5),
      ...Object.keys(INITIAL_IN_PROGRESS_FILTERS_LIMIT_5),
      ...Object.keys(INITIAL_COMPLETED_FILTERS_LIMIT_5),
      ...(user.isSales ? Object.keys(INITIAL_DRAFT_FILTERS_LIMIT_5) : []),
      ...(user.isSales ? Object.keys(INITIAL_CLOSED_FILTERS_LIMIT_5) : []),
    ]
  );

  const setFilters = filters => {
    requestedTableFilters.setFilters({ ...INITIAL_REQUESTED_FILTERS_LIMIT_5, ...filters });
    inProgressTableFilters.setFilters({ ...INITIAL_IN_PROGRESS_FILTERS_LIMIT_5, ...filters });
    completedTableFilters.setFilters({ ...INITIAL_COMPLETED_FILTERS_LIMIT_5, ...filters });

    if (user.isSales) {
      draftTableFilters.setFilters({ ...INITIAL_DRAFT_FILTERS_LIMIT_5, ...filters });
      closedTableFilters.setFilters({ ...INITIAL_CLOSED_FILTERS_LIMIT_5, ...filters });
    }
  };

  const resetFilters = () => {
    requestedTableFilters.resetFilters();
    inProgressTableFilters.resetFilters();
    completedTableFilters.resetFilters();

    if (user.isSales) {
      draftTableFilters.resetFilters();
      closedTableFilters.resetFilters();
    }
  };

  const requestedTable = (
    <CostingTable
      columns={REQUESTED_COLUMNS}
      data={requestedTableFilters.data}
      emptyText="No Requests are currently awaiting review"
      loading={requestedTableFilters.loading}
      onFetchData={state => requestedTableFilters.onFetchData(state)}
      pages={requestedTableFilters.pages}
      showPagination={false}
      sort={INITIAL_REQUESTED_SORT}
    >
      <div className="flex justify-content-between">
        <h4 className="mb-2">Requested Queue</h4>
        <Link to="/admin/costing_requests/requested_queue">View Entire Queue</Link>
      </div>
    </CostingTable>
  );

  const inProgressTable = (
    <CostingTable
      columns={IN_PROGRESS_COLUMNS}
      data={inProgressTableFilters.data}
      emptyText="No Requests are currently in progress"
      loading={inProgressTableFilters.loading}
      onFetchData={state => inProgressTableFilters.onFetchData(state)}
      pages={inProgressTableFilters.pages}
      showPagination={false}
      sort={INITIAL_IN_PROGRESS_SORT}
    >
      <div className="flex justify-content-between">
        <h4 className="mb-2">In Progress Queue</h4>
        <Link to="/admin/costing_requests/in_progress_queue">View Entire Queue</Link>
      </div>
    </CostingTable>
  );

  const completedTable = (
    <CostingTable
      columns={COMPLETED_COLUMNS}
      data={completedTableFilters.data}
      emptyText="No Requests are currently completed"
      loading={completedTableFilters.loading}
      onFetchData={state => completedTableFilters.onFetchData(state)}
      pages={completedTableFilters.pages}
      showPagination={false}
      sort={INITIAL_COMPLETED_SORT}
    >
      <div className="flex justify-content-between">
        <h4 className="mb-2">Completed Queue</h4>
        <Link to="/admin/costing_requests/completed_queue">View Entire Queue</Link>
      </div>
    </CostingTable>
  );

  return (
    <>
      <Helmet title="All Costing Queues" />
      <Breadcrumb to="/admin/costing_requests">Costing Requests</Breadcrumb>
      <Breadcrumb to="/admin/costing_requests/closed_queue">All Queues</Breadcrumb>

      <SidebarPageContainer localStorageKey={Sidebar.LOCAL_STORAGE_KEYS.costing_all_queues}>
        <SidebarPage>
          <div className="page-header flex mb-0 px-3">
            <h2>All Costing Queues</h2>
            <CostingSearch
              filters={filters}
              setFilters={setFilters}
              onTextSearch={handleTextSearch}
            />
          </div>

          {user.isSales ? (
            <>
              {completedTable}
              {inProgressTable}
              {requestedTable}

              <CostingTable
                columns={DRAFT_COLUMNS}
                data={draftTableFilters.data}
                emptyText="No Requests are currently drafted"
                loading={draftTableFilters.loading}
                onFetchData={state => draftTableFilters.onFetchData(state)}
                pages={draftTableFilters.pages}
                showPagination={false}
                sort={INITIAL_DRAFT_SORT}
              >
                <div className="flex justify-content-between">
                  <h4 className="mb-2">Draft Queue</h4>
                  <Link to="/admin/costing_requests/draft_queue">View Entire Queue</Link>
                </div>
              </CostingTable>

              <CostingTable
                columns={CLOSED_COLUMNS}
                data={closedTableFilters.data}
                emptyText="No Requests are currently closed"
                loading={closedTableFilters.loading}
                onFetchData={state => closedTableFilters.onFetchData(state)}
                pages={closedTableFilters.pages}
                showPagination={false}
                sort={INITIAL_CLOSED_SORT}
              >
                <div className="flex justify-content-between">
                  <h4 className="mb-2">Closed Queue</h4>
                  <Link to="/admin/costing_requests/closed_queue">View Entire Queue</Link>
                </div>
              </CostingTable>
            </>
          ) : (
            <>
              {requestedTable}
              {inProgressTable}
              {completedTable}
            </>
          )}
        </SidebarPage>

        <Sidebar>
          <CostingSidebar
            fields={FIELDS}
            filters={filters}
            resetFilters={resetFilters}
            setFilters={setFilters}
          />
        </Sidebar>
      </SidebarPageContainer>
    </>
  );
};

export default CostingAllQueues;
