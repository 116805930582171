import PropTypes from 'prop-types';
import React from 'react';

import styles from './TableHead.module.css';

export const TableHead = ({ className = '', children }) => (
  <thead className={`${styles.Root} ${className}`}>
    <tr>{children}</tr>
  </thead>
);

TableHead.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default TableHead;
