import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { TableBody, TableCustom, TableHead, TableHeadCell } from 'portal/components';

import ListViewRow from './ListViewRow';

const ListView = ({ quotes }) => {
  return (
    <TableCustom>
      <TableHead>
        <TableHeadCell value="Quote #" />
        <TableHeadCell value="Status" />
        <TableHeadCell value="Date ordered" />
        <TableHeadCell value="Items" align="center" />
        <TableHeadCell value="Total cost" align="right" />
      </TableHead>

      <TableBody>
        {quotes.map(quote => (
          <ListViewRow key={quote.public_id || quote.id} quote={quote} />
        ))}
      </TableBody>
    </TableCustom>
  );
};

ListView.propTypes = {
  quotes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default memo(ListView);
