import React from 'react';

import { AutoquoteTiersResponseState, isAutoquoteTierList } from 'portal/lib/autoquote';
import { TierRowInfo } from 'portal/pages/part_config/components/TierPriceContainer';

import { LeadTimeFields } from '..';
import TierPriceInfo from './TierPriceInfo';

interface Props {
  state: AutoquoteTiersResponseState;
  isStandard: boolean;
  leadTimeFields?: LeadTimeFields;
}

/**
 * Renders the tier price based on the given {@link state} and whether or not the active tier {@link isStandard}.
 * When loading, error, or no tiers this renders an empty state,
 * When not standard and there is only one state, this renders an empty state as well, assuming that the single state is standard.
 */
const TierPriceCard = ({ state, isStandard, leadTimeFields }: Props) => {
  // Handle invalid / empty inputs
  if (!state || !isAutoquoteTierList(state) || state.length === 0) {
    return <TierPriceInfoEmpty leadTimeFields={leadTimeFields} />;
  }

  // Find the first standard tier, then the first non-standard tier
  const standardTierIndex = state.findIndex(tier => tier.label.toLowerCase() === 'standard');
  const standardTier = state[standardTierIndex];
  const nonStandardTier = state.find((_, index) => index !== standardTierIndex);

  if (isStandard && standardTier) {
    return (
      <TierPriceInfo
        autoquoteTier={standardTier}
        setLabel={false}
        leadTimeFields={leadTimeFields}
      />
    );
  }
  if (!isStandard && nonStandardTier) {
    return (
      <TierPriceInfo
        autoquoteTier={nonStandardTier}
        setLabel={false}
        leadTimeFields={leadTimeFields}
      />
    );
  }
  return <TierPriceInfoEmpty leadTimeFields={leadTimeFields} />;
};

interface TierPriceInfoEmptyProps {
  leadTimeFields?: LeadTimeFields;
}

export const LeadTimeFieldsEl = ({ leadTimeFields }: TierPriceInfoEmptyProps) => {
  return (
    <div className="cost-quantity-and-lead-time mb-2">
      <div className="flex">
        <div className="flex-1 cost-quantity-subheader mb-1 mr-2">
          <p className="tier-lead-qty mr-6 print:text-base">
            <span className="text-xs">Quantity:</span>
            <br />
            {leadTimeFields.quantity}
          </p>
        </div>
        <div className="flex-1 cost-lead-time-subheader mb-1">
          <p className="tier-lead-time mr-6 print:text-base">
            <span className="text-xs">Lead time</span>
            <br /> {leadTimeFields?.isStandard ? 'Standard' : 'Fast'}
          </p>
        </div>
      </div>
    </div>
  );
};

const TierPriceInfoEmpty = ({ leadTimeFields }: TierPriceInfoEmptyProps) => {
  return (
    <>
      <div>
        <div className="compare-component-header cost-lead-header print:mb-1">
          <h1 className="hidden print:block print:text-xl print:pb-1 print:leading-5 text-black">
            Cost & lead times
          </h1>
        </div>
        {leadTimeFields ? <LeadTimeFieldsEl leadTimeFields={leadTimeFields} /> : null}
      </div>
      <div className="flex flex-col gap-0.5 print:gap-0 print:flex-row print:flex-wrap print:justify-between print:mr-6">
        <TierRowInfo
          label={'Lead time'}
          labelData={<span className="text-coolGray-500">—</span>}
        />
        <TierRowInfo
          label={'Production region'}
          labelData={<span className="text-coolGray-500">—</span>}
        />
        <TierRowInfo
          label={'Unit price'}
          labelData={
            <span className="text-coolGray-500">
              <MoneyEmpty />
            </span>
          }
        />
        <TierRowInfo
          label={'Item total'}
          labelData={
            <span className="text-coolGray-500 text-base font-semibold">
              <MoneyEmpty />
            </span>
          }
          dataTestId={'part-config-item-total'}
        />
      </div>
    </>
  );
};

const MoneyEmpty = () => (
  <>
    <sup>$</sup>—.<sup>—</sup>
  </>
);

export default TierPriceCard;
