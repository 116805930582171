export const percentColor = percent => {
  if (percent >= 90) {
    return '#02b875';
  } else if (percent > 60) {
    return '#f0ad4e';
  } else if (percent > 30) {
    return '#fd7e14';
  } else {
    return '#ec0928';
  }
};

export const getPercent = (quantity, total) =>
  (((parseInt(quantity) - parseInt(total || 0)) / parseInt(quantity)) * 100).toFixed(0);
