import { get, isEqual, omit, remove, uniqueId } from 'lodash';
import moment from 'moment';

import {
  COSTING_REQUEST_LI_STATES,
  CostingRequest,
  CostingRequestLineItem,
  Document,
  PartQuantity,
  SupplierCost,
} from 'fr-shared/lib/costing_requests';

// transforms the /costing_requests POST request
export const transformNewRequest = (values: CostingRequest) => {
  const costingRequest = { ...values };

  const newLineItems = costingRequest.line_items.map(
    (li: CostingRequestLineItem): CostingRequestLineItem => {
      // only send part document information through so documents can be created
      const newLineItem = {
        ...li,
        part_id: li.part?.id,
        part: {
          id: li.part?.id,
          documents: li.part?.documents,
          usage: li.part?.usage,
          designation: li.part?.designation,
        },
      };

      return newLineItem;
    }
  );

  return { ...costingRequest, line_items: newLineItems };
};

// the Completed state also includes Rejected, this is a helper to reuse that logic
export const filterByCostingState = (
  lineItems: CostingRequestLineItem[] = [],
  state: string
): CostingRequestLineItem[] => {
  const filterStates = state === 'Completed' ? ['Completed', 'Rejected'] : [state];
  const newLineItems = [...lineItems].filter(li => filterStates.some(f => li.state.includes(f)));
  return newLineItems;
};

export const transformCostingRequestLineItem = (
  lineItem: CostingRequestLineItem,
  overrides: object = {}
): CostingRequestLineItem => {
  const part_id = get(lineItem.part, 'id');
  const filteredCostingInputs = lineItem.costing_input_values?.filter(
    inputValue => inputValue.value
  );
  const lineItemUsage =
    lineItem.part.usage != null && lineItem.part.usage !== ''
      ? lineItem.part.usage
      : 'Customer Portal Quote';

  const newLineItem = {
    ...lineItem,
    part_id,
    part: {
      id: part_id,
      documents: lineItem.part?.documents,
      usage: lineItemUsage,
      designation: lineItem.part.designation,
    },
    costing_input_values: filteredCostingInputs,
    ...overrides,
  };

  return newLineItem;
};

export const transformCostingRequestFromLoad = (data: CostingRequest) => {
  return {
    ...data,
    /*
     * This converts this boolean field into a string representation because it is shown in the
     * UI as a pair of radio buttons.
     *
     * See assets/src/shared/components/common/LineItemDutiesTaxesTariffs.tsx
     *
     * HTML forms don't handle setting booleans well, even with Formik. As of this writing, the
     * `transformCostingRequestFromLoad` function doesn't need to change this value back from a string
     * because the backend accepts and coerces boolean-like strings appropriately.
     */
    line_items: data.line_items.map((li: CostingRequestLineItem): CostingRequestLineItem => {
      return {
        ...li,
        is_prototype:
          typeof li.is_prototype === 'boolean' ? String(li.is_prototype) : li.is_prototype,
      };
    }),
  };
};

// Transform initial values to set them as open
// what info is really necessary for the duplicate form?
export const transformCostingRequestForForm = (
  costingRequest: CostingRequest,
  options: object
): CostingRequest => {
  const lineItems = costingRequest
    ? costingRequest.line_items.map((li: CostingRequestLineItem) => ({
        ...li,
        isOpen: true,
        _id: uniqueId('cr-line-item-'),
      }))
    : [];
  return {
    requested_at: costingRequest?.requested_at,
    bulk: {},
    ...costingRequest,
    line_items: lineItems,
    ...options,
  };
};

export const bulkEditDocuments = (
  lineItemDocs: Document[],
  bulkEdits: Document[]
): Document[] => {
  const mixedSelection = bulkEdits.find(edit => edit.mixed);
  const edits = [...bulkEdits];
  remove(edits, edit => edit.mixed);

  let updatedDocs = [...lineItemDocs];
  if (mixedSelection) {
    if (mixedSelection.deleted_at) {
      updatedDocs = lineItemDocs.map(doc => {
        return { ...doc, deleted_at: moment() };
      });
    }
    return updatedDocs.concat(edits);
  }

  return bulkEdits.map(edit => {
    const matchingDocument = lineItemDocs.find(doc => doc.file_name === edit.file_name);
    if (matchingDocument) {
      return { ...matchingDocument, deleted_at: edit.deleted_at };
    } else {
      return edit;
    }
  });
};

export const setInitialBulkSupportingDocuments = (
  lineItems: CostingRequestLineItem[]
): Document[] => {
  const lineItemsWithPartDocuments: CostingRequestLineItem[] = lineItems.map(item => {
    const activeDocuments = item.part?.documents?.filter(doc => !doc.deleted_at);
    return { ...item, part: { documents: activeDocuments } };
  });

  const [firstItem, ...restItems] = lineItemsWithPartDocuments;

  const supportingDocsGetter = (item: CostingRequestLineItem): string[] => {
    return item.part?.documents?.map(doc => doc.file_name).sort();
  };
  if (
    restItems.every(item => isEqual(supportingDocsGetter(item), supportingDocsGetter(firstItem)))
  ) {
    return firstItem.part?.documents;
  } else {
    return [{ file_name: 'Mixed Documents...', mixed: true, deleted_at: null }];
  }
};

export const transformDuplicateLineItems = (
  lineItems: CostingRequestLineItem[]
): CostingRequestLineItem[] => {
  return lineItems.map(item => {
    return omit(
      {
        ...item,
        duplicated_from_id: item.id,
        state: COSTING_REQUEST_LI_STATES.Draft,
        costing_input_values: [],
        supplier_costs: mapExistingToNewSupplierCosts(item.supplier_costs),
      },
      [
        'rejection_documents',
        'rejected_at',
        'rejected_reason',
        'closed_at',
        'completed_at',
        'id',
        'uuid',
        'public_link',
      ]
    );
  });
};

/** used for resetting quantities when duplicating costing request line items
 * we only use the quantities from the first supplier cost
 * because there is no way to edit quantities per supplier on the DuplicateLineItemsForm
 */
const mapExistingToNewSupplierCosts = (supplierCosts: SupplierCost[] | void): SupplierCost[] => {
  if (!supplierCosts) return [];

  const part_quantities = supplierCosts[0].part_quantities.map((pq: PartQuantity) => {
    return { quantity: pq.quantity, per_unit_cost: '' };
  });

  return [{ part_quantities }];
};
