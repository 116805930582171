import { IconFont } from '@fast-radius/shared-ui';
import { capitalize } from 'lodash';
import React, { PropsWithChildren, SyntheticEvent } from 'react';

import { classNames } from 'fr-shared/components';

import { CostData, CostDriver } from 'portal/lib/cost_drivers';
import { CostCurveGraph } from 'portal/pages/insights/components/CostCurves';

export interface DriverStats {
  totalPrice: number;
  price: number;
  percentage: number;
}

interface CostCurveCardProps {
  costData: CostData;
  currentProcessName: string; // provided separately for when costData is null
  onSeeMore: () => void;
  onDriverOpen: (e: HTMLElement, stats: DriverStats) => void;
  onDriverClose: () => void;
  colorForAllBarBgs?: string;
}

const EMPTY_COST_DRIVERS = ['engineering', 'equipment', 'labor', 'material', 'yield'].map(d => ({
  cost_driver_name: d,
  price: null,
  percentage: null,
}));

const DRIVER_BG_COLORS = [
  'bg-secondary-200',
  'bg-primary-300',
  'bg-tertiary-300',
  'bg-success-300',
  'bg-warning-300',
];

const DRIVER_ORDER: Record<string, number> = {
  engineering: 0,
  equipment: 1,
  labor: 2,
  material: 3,
  yield: 4,
};

const OPTIMAL_ORDER_VOLUME_TOOLTIP =
  'All costs shown here are estimates and exclude extra details like inspections and finishes. After you create a quote, we’ll review it to confirm final costs.';

const EMPTY_COST_CURVE_POINTS = [1, 10, 15, 20, 50, 100, 1000, 2000, 3000].map(quantity => {
  return { predicted_cost: { amount: '0', formatted: '$0.00', currency: 'USD' }, quantity };
});

/** Renders the cost curves and cost drivers in a composite card */
const CostCurvesCard = ({
  costData,
  currentProcessName,
  onDriverOpen,
  onDriverClose,
  onSeeMore,
  colorForAllBarBgs,
}: CostCurveCardProps) => {
  const hasDrivers = !!costData?.cost_drivers;

  const {
    cost_curve_points: costCurvePointsMoney,
    optimal_quantity: optimalQuantity,
    cost_drivers: costDrivers,
  } = {
    cost_curve_points: EMPTY_COST_CURVE_POINTS,
    optimal_quantity: null,
    cost_drivers: EMPTY_COST_DRIVERS,
    ...(costData || {}),
  } as CostData;

  const costCurvePoints = costCurvePointsMoney.map(p => ({
    ...p,
    predicted_cost: +p.predicted_cost.amount,
  }));
  const totalPrice = costCurvePoints.find(p => p.quantity === optimalQuantity)?.predicted_cost;

  return (
    <div className="cost-curve-card p-3 border-1 border-coolGray-700 border-solid rounded-[8px] print:border-none print:p-0">
      <div className="compare-component-header cost-curve-header print:mb-1">
        <h1 className="hidden print:block print:text-xl print:pb-1 print:leading-5 text-black">
          Cost curves
        </h1>
      </div>
      <CostCurvesCell>
        <h2 className="text-coolGray-300 text-sm leading-4 m-0">
          Optimal order volume{' '}
          <IconFont
            className="text-xl ml-1"
            name="info-circle"
            data-rh={OPTIMAL_ORDER_VOLUME_TOOLTIP}
          />
        </h2>
        {optimalQuantity && (
          <p className="cost-curve-opt-text text-white text-4xl leading-12 mb-1 h-[64px] print:leading-8 print:text-lg print:h-auto print:text-black">
            {optimalQuantity} units
          </p>
        )}
        {!optimalQuantity && <NotCurrentlyAvailable processName={currentProcessName} />}

        <h2 className="text-coolGray-300 text-sm leading-4 m-0">Price</h2>
        <CostCurveGraph
          barBgScale={1}
          barCategoryGap={3}
          barRadius={4}
          className="ml-[-8px]"
          data={costCurvePoints}
          height={200}
          yAxisHidden={true}
          optimalQuantity={optimalQuantity}
          xAxisDx={0}
          xAxisDy={4}
          xValueFontSize="10px"
          xValueShortened={true}
          xValueTextAnchor="middle"
          tooltipMarkerHidden={true}
          tooltipTrigger="hover"
          colorForAllBarBgs={colorForAllBarBgs}
        />
      </CostCurvesCell>
      <CostCurvesCell className="cost-curve-drivers print:min-h-[220px]">
        <h2 className="text-white text-base mb-0">Cost drivers</h2>
        {!hasDrivers && <NotCurrentlyAvailable processName={currentProcessName} />}
        {costDrivers && ( // offset by -72px to account for the NotCurrentlyAvailable height
          <CostDriversBreakdown
            className={!hasDrivers ? 'mb-[-72px]' : ''}
            drivers={costDrivers}
            onDriverOpen={onDriverOpen}
            onDriverClose={onDriverClose}
            totalPrice={totalPrice}
          />
        )}
      </CostCurvesCell>
      <CostCurvesCell hideDivider={!hasDrivers} className="cost-curve-see-breakdown print:hidden">
        <button
          className={classNames([
            'inline-block text-white text-base bg-transparent border-0',
            !hasDrivers && 'invisible',
          ])}
          onClick={onSeeMore}
        >
          <IconFont className="mr-1 text-2xl" name="lightbulb" />
          See full breakdown
        </button>
      </CostCurvesCell>
    </div>
  );
};

/**
 * Renders a cell within the card, handling dividers for you.
 * @param hideDivider always hides the divider
 */
const CostCurvesCell = ({
  children,
  hideDivider = false,
  className,
}: PropsWithChildren<{ hideDivider?: boolean; className?: string }>) => {
  return (
    <section
      className={classNames([
        'py-3 border-0 border-coolGray-700 border-solid first-of-type:border-t-0 first:pt-0 last:pb-0 print:py-0',
        className && `${className}`,
        !hideDivider && 'border-t',
        'print:first:border-none print:border-transparent print:first:border-b',
      ])}
    >
      {children}
    </section>
  );
};

/** Renders a group of {@link CostDriverRow}'s for the given {@link drivers} */
const CostDriversBreakdown = ({
  className = '',
  drivers,
  onDriverOpen,
  onDriverClose,
  totalPrice,
}: {
  className?: string;
  drivers: CostDriver[];
  onDriverOpen: (e: HTMLElement, stats: DriverStats) => void;
  onDriverClose: () => void;
  totalPrice: number;
}) => {
  // Leaves buffer in case 4 drivers hit min height, and the last one takes up 100%
  const containerHeight = 216;
  const padding = 6;
  const minRowH = 24;
  const buffer = (minRowH + padding) * 4;
  const heightToPartition = containerHeight - buffer;
  return (
    <div
      className={classNames([
        className,
        'h-[216px] print:h-auto print:mb-0 print:mr-6 print:h-auto',
      ])}
    >
      {drivers.map((d, i) => (
        <CostDriverRow
          key={d.cost_driver_name}
          driver={d}
          index={i}
          onMouseEnter={e =>
            d?.percentage
              ? onDriverOpen(e.currentTarget, {
                  price: d?.price,
                  percentage: d?.percentage,
                  totalPrice,
                })
              : () => {}
          }
          onMouseLeave={() => onDriverClose()}
          totalHeight={heightToPartition}
        />
      ))}
    </div>
  );
};

interface CostDriverRowProps {
  driver: CostDriver;
  hideTopDivider?: boolean;
  index: number;
  onMouseEnter: (e: SyntheticEvent<HTMLElement>) => void;
  onMouseLeave: (e: SyntheticEvent<HTMLElement>) => void;
  totalHeight: number;
}

/**
 * Renders a cost {@link driver} with a bar, name, percentage, and divider.
 * The cost driver bar width is fixed at 21px.
 * The cost driver row min-height is 24px.
 * @param driver with cost_driver_name, price, and percentage where the cost_driver_name determines the color
 * @param index represents the position of the row
 * @param totalHeight determines the relative height of the bar: max(24, {@link totalHeight} * {@link driver}.percentage)
 */
const CostDriverRow = ({
  driver,
  index,
  onMouseEnter,
  onMouseLeave,
  totalHeight,
}: CostDriverRowProps) => {
  const colorIndex = DRIVER_ORDER[driver?.cost_driver_name];
  const color =
    colorIndex !== undefined
      ? DRIVER_BG_COLORS[colorIndex % DRIVER_BG_COLORS.length]
      : 'bg-coolGray-500';

  const driverToShow = {
    name: capitalize(driver?.cost_driver_name || ''),
    percentage: driver?.percentage ? (driver.percentage * 100).toFixed(1) : '-- ',
    color,
  };
  const maxHeight = totalHeight * (driver.percentage || 0);

  return (
    <div
      className="text-coolGray-300 py-[3px] min-h-[24px] transition-[max-height] duration-300 print:h-auto print:min-h-auto"
      style={{ height: maxHeight + 6, maxHeight: maxHeight + 6 }}
    >
      <div
        className={classNames([
          driverToShow.color,
          'inline-block rounded-sm w-[21px] min-h-[2px] align-middle transition-[max-height] duration-300 print:h-[20px] cost-driver-bar',
        ])}
        style={{ height: maxHeight, maxHeight }}
      ></div>
      <div
        className={classNames([
          'inline-flex flex-nowrap items-center justify-between w-[calc(100%-37px)] border-0 border-coolGray-700 border-solid align-middle ml-2 min-h-[24px] print:h-auto print:min-h-auto',
          index > 0 && 'border-t',
        ])}
        style={{ height: maxHeight, maxHeight }}
      >
        <span className="w-100" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {driverToShow.name}
        </span>
        <span className="whitespace-nowrap">{driverToShow.percentage}%</span>
      </div>
    </div>
  );
};

const NotCurrentlyAvailable = ({ processName }: { processName: string }) => {
  return (
    <p className={'cost-curve-opt-text text-coolGray-500 text-2xl leading-8 mb-1 print:text-lg'}>
      Currently unavailable for {processName || 'this process'}
    </p>
  );
};

export default CostCurvesCard;
