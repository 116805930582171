/**
 * Creates a lookup map of the given elements by their id.
 *  - input:  [{ id: 1, name: 'green' }, { id: 2, name: 'blue' }]
 *  - output: { 1: { id: 1, name: 'green' }, 2: { id: 2, name: 'blue' } }
 * @param arr the elements to map
 * @returns an object mapping each elements id to the element
 */
export function mapById<T extends { id: number | string }>(
  arr: T[]
): Record<number | string, any> {
  if (!Array.isArray(arr)) return {};
  return arr.reduce((acc: Record<number | string, any>, curr: T) => {
    if (curr.id != null) {
      acc[curr.id] = curr;
    }
    return acc;
  }, {});
}
