export interface CostData {
  cost_curve_points: CostCurvePoint[];
  cost_drivers: CostDriver[];
  max_cost_driver: string;
  optimal_quantity: number;
  manufacturing_process: ManufacturingProcess;
  autoquote_id: number;
}

export interface CostDriverData {
  cost_drivers: CostDriver[];
  max_cost_driver: string;
  cost_drivers_quantity: number;
}

export interface CostDriver {
  cost_driver_name: string;
  percentage: number;
  price: number;
}

export interface CostCurvePoint {
  predicted_cost: Money;
  quantity: number;
}

export interface CostingRecommendation {
  title: string;
  description: string;
}

interface CostDriverObj {
  cost_driver_name: string;
  color: string;
}

export const COST_DRIVER_COLORS: Array<CostDriverObj> = [
  { cost_driver_name: 'engineering', color: '#C78DFE' },
  { cost_driver_name: 'labor', color: '#6AD0FA' },
  { cost_driver_name: 'equipment', color: '#7BF3E3' },
  { cost_driver_name: 'material', color: '#63C463' },
  { cost_driver_name: 'yield', color: '#F8B77E' },
];
