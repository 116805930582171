import React from 'react';

import { Icon, classNames } from 'fr-shared/components';

import styles from './PSLIEmptyState.module.css';

interface PSLIEmptyStateProps {
  addQuantityMessage: boolean;
}

const PSLIEmptyState = ({ addQuantityMessage }: PSLIEmptyStateProps) => {
  return (
    <div
      data-testid="planned-shipment-lineitems-empty-state"
      className={classNames([styles.container])}
    >
      <Icon type="far" name="inbox" className={classNames(['mb-3', 'fa-4x', styles.icon])} />
      {addQuantityMessage ? (
        <div className={classNames(['mb-2', styles.copy])}>
          <Icon
            name={`exclamation-circle`}
            className={classNames(`font-size-lg mr-1 mt-auto mb-auto text-yellow`)}
          />
          Please enter Line Item Quantity before adding Planned Shipments.
        </div>
      ) : null}
      <div className={classNames(['mb-2', styles.copy])}>
        Add at least one Planned Shipment before placing the order.
      </div>
    </div>
  );
};

export default PSLIEmptyState;
