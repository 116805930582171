import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Alert, Icon } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';

const Z_INDEX_HIGHER_THAN_STANDARD_STICKY_TOP = 1021;

const AlertBanner = ({ container }) => {
  const { isOpen, color, message, closeAlert } = useContext(AlertContext);

  const icons = {
    danger: 'exclamation-circle',
    success: 'check-circle',
  };

  if (!isOpen) return null;

  return (
    <div
      data-testid="alert-banner"
      className="alert-banner sticky-top"
      style={{ zIndex: Z_INDEX_HIGHER_THAN_STANDARD_STICKY_TOP }}
    >
      <Alert color={color} className="mb-0 rounded-0">
        <div className={container ? `container-${container}` : 'container'}>
          <button type="button" className="close" onClick={closeAlert}>
            <span aria-hidden="true">
              <Icon name="times" />
            </span>
          </button>
          <div className="flex align-items-center">
            <Icon name={icons[color]} className={`text-${color} mr-2`} />
            <strong className="flex-1">{message}</strong>
          </div>
        </div>
      </Alert>
    </div>
  );
};

AlertBanner.propTypes = {
  container: PropTypes.oneOf(['sm', 'md', 'lg', 'fluid']),
};

export default AlertBanner;
