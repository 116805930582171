import { useQuery } from 'react-query';

import { api } from 'fr-shared/api';

const fetchInspectionTypes = () => api.get('/inspection_types').then(res => res.data);

const useInspectionTypes = () => {
  return useQuery('inspection_types', fetchInspectionTypes, {
    refetchOnWindowFocus: false,
    staleTime: 10000,
  });
};

export default useInspectionTypes;
