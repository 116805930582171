import * as Sentry from '@sentry/react';
import { Scene as BabylonScene, Color3, Engine, Layer } from 'babylonjs';
import { values } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Card, classNames } from 'fr-shared/components';

import {
  BUILD_PLATE_MESH,
  MAIN_STL_MESH,
  calculateCamRadiusForModel,
  resetCamera,
} from './utils';

const VIEWER_BACKGROUND_URL =
  'https://fast-radius-part-files.s3-us-gov-west-1.amazonaws.com/viewer_background.jpg';

const PORTAL_VIEWER_BACKGROUND_URL =
  'https://fast-radius-part-files.s3-us-gov-west-1.amazonaws.com/portal_viewer_background.png';

export const BabylonJSContext = React.createContext({
  engine: null,
  canvas: null,
  scene: null,
});

const Scene = ({
  children,
  maxHeight,
  minHeight,
  onResetCamera = () => {},
  sceneControls,
  showBuildPlateToggle = false,
  style,
  theme,
  visibleMeshes,
}) => {
  const canvasRef = useRef(null);
  const [enableXray, setEnableXray] = useState(false);
  const [showBuildPlate, setShowBuildPlate] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [engine, setEngine] = useState(null);
  const [scene, setScene] = useState(null);

  /**
   * Initializes the Babylon3D viewer once we get a DOM element
   * @param {ref} canvasRef - reference to the dom element for the viewer
   */
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      const engine = new Engine(canvasRef.current, true);
      const scene = new BabylonScene(engine);

      scene.clearColor = Color3.FromHexString('#111115');

      if (theme) {
        const background = new Layer(
          'back',
          theme !== 'admin' ? PORTAL_VIEWER_BACKGROUND_URL : VIEWER_BACKGROUND_URL,
          scene
        );
        background.isBackground = true;
      }

      setEngine(engine);
      setScene(scene);

      try {
        engine.runRenderLoop(() => {
          scene.render();
        });
      } catch (error) {
        Sentry.setExtra('error', error);
        Sentry.captureMessage('Babylon Scene error');
      }
    }

    return () => {
      if (scene !== null) scene.dispose();
    };
  }, [canvasRef, loaded, scene, theme]);

  /**
   * Allows the visibility of the meshes in Babylon to be controlled
   * This is used to show/hide manufacturability checks primarily
   * @param {object} visibleMeshes - which meshes to show on the viewer
   */
  useEffect(() => {
    if (!scene || !visibleMeshes) return;

    if (values(visibleMeshes).some(bool => bool)) {
      // turn xray on if any extra meshes are visible
      toggleXray(true);
    }

    // toggle mfg check meshes based on what should be visible
    scene.meshes
      .filter(activeMesh => activeMesh.id !== MAIN_STL_MESH)
      .map(mesh => {
        mesh.isVisible = !!visibleMeshes[mesh.id];
        if (mesh.isVisible) {
          snapCameraToMesh(mesh);
        }
        return mesh;
      });

    // update local state to match when the build plate is unloaded
    setShowBuildPlate(scene?.getMeshByID(BUILD_PLATE_MESH)?.isVisible ?? false);
  }, [scene, snapCameraToMesh, toggleXray, visibleMeshes]);

  /**
   * Enables the 3D viewer to be responsive
   * @param {object} scene - the babylon scene object
   */
  useEffect(() => {
    if (!window) return;
    const resize = () => {
      if (scene) scene.getEngine().resize();
    };
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [scene]);

  const toggleXray = useCallback(
    value => {
      const mesh = scene.getMeshByID(MAIN_STL_MESH);
      if (mesh?.material) {
        // "Xray" effect simulated by making the main stl mesh transparent
        mesh.material.alpha = value ? 0.5 : 1;
        setEnableXray(value);
      }
    },
    [scene]
  );

  const snapCameraToMesh = useCallback(
    mesh => {
      const camera = scene.activeCamera;
      camera.radius = calculateCamRadiusForModel(mesh, scene, engine);
      camera.lockedTarget.moveTo(mesh.getBoundingInfo().boundingBox.centerWorld);
      camera.alpha = 0.0;
      camera.beta = Math.PI / 2.0;
    },
    [engine, scene]
  );

  const handleResetCamera = () => {
    resetCamera(scene, engine);
    setShowBuildPlate(false);
    onResetCamera();
  };

  const handleShowBuildPlate = () => {
    const mesh = scene.getMeshByID(BUILD_PLATE_MESH);
    if (mesh) {
      mesh.isVisible = !mesh.isVisible;
      mesh.material.bumpTexture.level = 0.25;
      setShowBuildPlate(!showBuildPlate);
    }
  };

  const SceneControls = sceneControls || <BaseSceneControls />;

  return (
    <BabylonJSContext.Provider value={{ engine, canvas: canvasRef.current, scene }}>
      <>
        {React.cloneElement(SceneControls, {
          enableXray: enableXray,
          onResetCamera: handleResetCamera,
          onShowBuildPlate: handleShowBuildPlate,
          onToggleSolid: () => toggleXray(false),
          onToggleXray: () => toggleXray(true),
          showBuildPlate: showBuildPlate,
          showBuildPlateToggle: showBuildPlateToggle,
        })}
        <canvas
          className="w-100"
          style={{ maxHeight: maxHeight, minHeight: minHeight, ...style }}
          ref={canvasRef}
        >
          {scene && children}
        </canvas>
      </>
    </BabylonJSContext.Provider>
  );
};

Scene.propTypes = {
  children: PropTypes.node,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onResetCamera: PropTypes.func,
  onSceneReady: PropTypes.func,
  sceneControls: PropTypes.node,
  showBuildPlateToggle: PropTypes.bool,
  style: PropTypes.object,
  theme: PropTypes.string,
  visibleMeshes: PropTypes.object,
};

const BaseSceneControls = ({
  children,
  enableXray,
  onResetCamera,
  onShowBuildPlate,
  onToggleXray,
  onToggleSolid,
  showBuildPlate,
  showBuildPlateToggle,
}) => {
  return (
    <Card className={classNames(['viewer-scene'])}>
      <Card.Header className="p-2">
        <div>
          <Button
            className={classNames(['mr-2', !enableXray && 'active'])}
            size="md"
            onClick={onToggleSolid}
          >
            Solid
          </Button>
          <Button
            className={classNames(['mr-4', enableXray && 'active'])}
            size="md"
            onClick={onToggleXray}
          >
            X-Ray
          </Button>
        </div>
        <div>
          <Button color="primary" onClick={onResetCamera} size="md">
            Reset View
          </Button>
          {showBuildPlateToggle && (
            <Button
              color={showBuildPlate ? 'white' : 'primary'}
              className="ml-2"
              onClick={onShowBuildPlate}
              size="md"
            >
              {showBuildPlate ? 'Hide' : 'Show'} Build Plate
            </Button>
          )}
        </div>
      </Card.Header>
      {children}
    </Card>
  );
};

BaseSceneControls.propTypes = {
  children: PropTypes.node,
  enableXray: PropTypes.bool,
  onResetCamera: PropTypes.func,
  onShowBuildPlate: PropTypes.func,
  onToggleXray: PropTypes.func,
  onToggleSolid: PropTypes.func,
  showBuildPlate: PropTypes.bool,
  showBuildPlateToggle: PropTypes.bool,
};

export default Scene;
