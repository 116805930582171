import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button, Card, FormContactPicker, Icon } from 'fr-shared/components';

const FormTeamPicker = ({
  salesUsers,
  salesContact,
  supportUsers,
  supportContact,
  engineeringUsers,
  engineeringContact,
  canCreateCostingRequests,
  handleChange,
  showAvatar,
  entity,
  user,
  toggleable = true,
  open = true,
}) => {
  const [isOpen, setOpen] = useState(open);
  const userHasPermission = {
    'costing-request': canCreateCostingRequests,
  }[entity];
  return (
    <Card border className="bg-light">
      <Card.Header className="d-flex justify-content-start">
        <p className="font-weight-light font-size-lg">Team</p>
        {toggleable && (
          <Button
            onClick={() => setOpen(!isOpen)}
            className="ml-auto shadow-none font-weight-light font-size-lg"
            color="light"
          >
            <Icon name={`chevron-${isOpen ? 'up' : 'down'} `} />
          </Button>
        )}
      </Card.Header>
      {isOpen && (
        <Card.Body>
          <FormContactPicker
            name={`${entity}-sales-contact`}
            label="Sales Contact"
            unassignedButtonText="Assign Sales Contact"
            options={salesUsers ?? []}
            onChange={handleChange('sales_contact')}
            isDisabled={false}
            showAvatar={showAvatar}
            includeUser={userHasPermission}
            value={salesContact}
            isStacked={true}
          />
          <FormContactPicker
            name={`${entity}-support-contact`}
            label="Support Contact"
            unassignedButtonText="Assign Support Contact"
            options={supportUsers}
            onChange={handleChange('support_contact')}
            isDisabled={false}
            showAvatar={showAvatar}
            includeUser={userHasPermission && user.isOpsPM}
            value={supportContact}
            isStacked={true}
          />
          <FormContactPicker
            name={`${entity}-engineering-contact`}
            label="Engineering Contact"
            unassignedButtonText="Assign Engineering Contact"
            options={engineeringUsers}
            onChange={handleChange('engineering_contact')}
            isDisabled={false}
            showAvatar={showAvatar}
            includeUser={userHasPermission && user.isAE}
            value={engineeringContact}
            isStacked={true}
          />
        </Card.Body>
      )}
    </Card>
  );
};

const User = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  isAE: PropTypes.bool,
  isSales: PropTypes.bool,
});

FormTeamPicker.propTypes = {
  entity: PropTypes.string,
  handleChange: PropTypes.func,
  user: User,
  salesContact: User,
  supportContact: User,
  engineeringContact: User,
  salesUsers: PropTypes.arrayOf(User),
  supportUsers: PropTypes.arrayOf(User),
  engineeringUsers: PropTypes.arrayOf(User),
  showAvatar: PropTypes.bool,
  canCreateCostingRequests: PropTypes.bool,
  toggleable: PropTypes.bool,
  open: PropTypes.bool,
};

export default FormTeamPicker;
