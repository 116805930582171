import { get } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ContactConvertCartToCRModal from 'src/views/admin/customers/contact/ContactConvertCartToCRModal';
import PortalCartPreviewModal from 'src/views/admin/customers/contact/PortalCartPreviewModal';
import PreviewCustomerQuoteModal from 'src/views/admin/quotes/components/PreviewCustomerQuoteModal/PreviewCustomerQuoteModal';
import { Quote, getQuoteSource } from 'src/views/sales_portal/lib/quotes';

import { api } from 'fr-shared/api';
import { Badge, Money } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { getSalesPortalQuoteStateTimestamp } from 'fr-shared/lib/quotes';

import { Card } from 'portal/components';
import PartsSlider from 'portal/components/PartsSlider/PartsSlider';

interface QuoteCardProps {
  quote: Quote;
  owner?: boolean;
}

export const QuoteCard = ({ quote, owner = false }: QuoteCardProps) => {
  const history = useHistory();
  const { user }: UserContext = useContext(UserContext);
  const [cartID, setCartID] = useState(null);
  const [displayConvertToCRModal, setDisplayConvertToCRModal] = useState(false);
  const [isCartPreviewOpen, setIsCartPreviewOpen] = useState(false);
  const [isQuotePreviewOpen, setIsQuotePreviewOpen] = useState(false);
  const [portalCart, setPortalCart] = useState(null);

  useEffect(() => {
    if (isCartPreviewOpen) {
      api.get(`/carts/${cartID}`, { params: { user_id: quote.user_id } }).then(res => {
        setPortalCart(res.data);
      });
    }
  }, [cartID, isCartPreviewOpen, quote.user_id]);

  const closeModal = () => {
    setCartID(null);
    setDisplayConvertToCRModal(false);
  };

  const handleCardClicked = () => {
    if (owner && quote.state === 'Draft') {
      history.push(`/quotes/draft`);
    } else if (quote.is_cart) {
      setIsCartPreviewOpen(true);
      setCartID(quote.id);
    } else {
      setIsQuotePreviewOpen(true);
    }
  };

  const handleClosePreview = () => {
    setIsCartPreviewOpen(false);
    setIsQuotePreviewOpen(false);
    setCartID(null);
  };

  const quotePrice = quote?.total_price?.amount || null;

  const images = get(quote, 'line_items', [])
    .map((lineItem: any) => get(lineItem, 'screenshot', null))
    .filter((screenshot: any) => screenshot);

  return (
    <>
      {isCartPreviewOpen && (
        <PortalCartPreviewModal
          cart={portalCart}
          onSubmit={() => {
            if (portalCart.submitted_at === null && user.canConvertCustomerCartToCostingRequest) {
              setCartID(portalCart.id);
              setDisplayConvertToCRModal(true);
            } else {
              history.push(`/admin/costing_requests/${portalCart.costing_request_id}`);
            }
          }}
          showCartId={false}
          onClose={handleClosePreview}
        />
      )}
      {isQuotePreviewOpen && (
        <PreviewCustomerQuoteModal
          quoteId={quote.id}
          showPreviewButton={false}
          onClose={handleClosePreview}
        />
      )}
      {displayConvertToCRModal && (
        <ContactConvertCartToCRModal
          isOpen={displayConvertToCRModal}
          cartID={cartID}
          toggle={closeModal}
          userID={quote.user_id}
        />
      )}
      <Card
        ownerBorder={owner && quote.state === 'Draft'}
        border={quote.state === 'Draft' && !owner}
        dataTestId="quote-card"
        onClick={handleCardClicked}
      >
        <div className="p-3">
          <Badge className="mb-3" color="outline">
            {quote.state === 'Draft' && owner ? 'My Draft' : quote.state}
          </Badge>
          <div className="flex font-size-md">
            <div className="mr-5 mb-1 w-1/2">
              <div className="font-size-sm">Customer</div>
              <div className="text-light break-words">{quote?.customer?.name}</div>
            </div>
            <div className="w-1/2">
              <div className="font-size-sm">Source</div>
              <div className="text-light">{getQuoteSource(quote.customer_portal)}</div>
            </div>
          </div>
          <div className="flex font-size-md">
            <div className="mr-5 w-1/2">
              <div className="font-size-sm">Quote #</div>
              <div className="text-light" data-testid="quote-card-quote-number">
                {get(quote, 'public_id', '——')}
              </div>
            </div>
            <div className="w-1/2">
              <div className="font-size-sm">Total</div>
              <Money className="font-size-md ml-auto text-light" amount={quotePrice} />
            </div>
          </div>
        </div>
        <PartsSlider images={images} />
        <div className="font-size-sm my-2 mx-3">
          Last edited:{' '}
          <strong>
            {moment(getSalesPortalQuoteStateTimestamp(quote)).format('MMM DD, YYYY')}
          </strong>
        </div>
      </Card>
    </>
  );
};

export default QuoteCard;
