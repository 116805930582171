import { truncate } from 'lodash';
import React, { useMemo } from 'react';
import Select, { OptionsType } from 'react-select';

import { User, useOrganizationUsers } from 'fr-shared/hooks';

interface CreatorFiltersProps {
  creators?: number[]; // id
  onSelectCreator?: (user_ids: number[]) => void;
}

interface UserOption {
  label: string;
  value: number;
}

const FAST_RADIUS_ORGANIZATION_ID = 1;

const WorkOrdersCreatorsFilter: React.FC<CreatorFiltersProps> = ({
  creators,
  onSelectCreator,
}: CreatorFiltersProps) => {
  const onChange = useMemo(() => {
    return (selected: OptionsType<UserOption>, _event_details: any) => {
      const ids = selected ? selected.map((user: UserOption) => user.value) : [];
      onSelectCreator(ids);
    };
  }, [onSelectCreator]);

  const {
    data: availableUsers,
    isError,
    isLoading,
    error,
  } = useOrganizationUsers({ organization_id: FAST_RADIUS_ORGANIZATION_ID });

  if (isLoading) return <div>Creator list loading...</div>;
  if (isError) return <div>Error loading locations: {error.message}</div>;

  const options = availableUsers.map((user: User): UserOption => {
    return { label: `${user.first_name} ${user.last_name}`, value: user.id };
  });

  const selected =
    creators && options ? options.filter((el: UserOption) => creators.includes(el.value)) : [];

  const placeholder =
    options.length > 0
      ? `Example: ${truncate(options[0].label, { length: 20 })}`
      : 'Select a user';

  return (
    <div className="form-group">
      <label htmlFor="creator-filter">Creator</label>
      <Select
        className="mb-2"
        closeMenuOnSelect={true}
        delimiter={','}
        id="creator-filter"
        isMulti={true}
        name="creator-filter"
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        value={selected}
      />
    </div>
  );
};

export default WorkOrdersCreatorsFilter;
