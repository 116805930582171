import { useFormikContext } from 'formik';
import React, { ReactNode } from 'react';

import { Button, Modal } from 'fr-shared/components';

interface DisplayInPortalToggleModalProps {
  handleHideInPortal: () => void;
  isOpen: boolean;
  toggle: () => void;
}

export default function DisplayInPortalToggleModal({
  handleHideInPortal,
  isOpen,
  toggle,
}: DisplayInPortalToggleModalProps): ReactNode {
  const formik = useFormikContext<{
    display_in_portal?: boolean;
  }>();

  const closeModal = () => {
    formik.setFieldValue('display_in_portal', !formik.values.display_in_portal);
    toggle();
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} onCloseHandler={closeModal}>
        <Modal.Header
          textUppercase={false}
          title="Are you sure you want to hide this quote from the customer portal?"
          onClose={closeModal}
        />
        <Modal.Body className="modal-body-send-email">
          <div>
            Hiding this quote will remove it from the customer portal and disable any quote links
            previously shared with the customer.
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer style={{ border: '0' }}>
          <Button color="secondary" onClick={closeModal} className="mr-3">
            No, don&apos;t hide
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleHideInPortal();
              toggle();
            }}
            className="ml-0"
          >
            Yes, hide
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
