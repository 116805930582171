import { isEmpty } from 'lodash';
import React from 'react';

import { pluralize } from 'fr-shared/utils';

import { Money, ShippingPriceLabel } from 'portal/components';
import { CartLineItem } from 'portal/lib/cart';

import PortalLineItem from './PortalLineItem';

interface LineItemSummaryProps {
  lineItems: CartLineItem[];
  discount: string;
  shippingPrice: string;
  subtotalPrice: string;
  totalPrice: string;
}

const LineItemSummary = ({
  lineItems,
  discount,
  shippingPrice = '$——',
  subtotalPrice,
  totalPrice,
}: LineItemSummaryProps) => {
  if (isEmpty(lineItems)) return null;

  return (
    <div>
      <div className="flex align-items-center mb-3">
        <h5 className="mb-0">Items</h5>
        <div className="text-muted font-size-md ml-auto">
          {lineItems.length} {pluralize('part', lineItems.length !== 1)}
        </div>
      </div>

      {lineItems.map((lineItem, lineItemIndex) => (
        <PortalLineItem key={lineItemIndex} lineItem={lineItem} />
      ))}

      <div className="mt-5">
        {subtotalPrice && (
          <div className="flex mb-1">
            <div className="font-size-md text-muted">Items subtotal</div>
            <span className="ml-auto" data-testid="subtotal">
              <Money amount={subtotalPrice} />
            </span>
          </div>
        )}
        <div className="flex align-items-center mb-1">
          <div className="font-size-md text-muted">
            <ShippingPriceLabel />
          </div>
          <span className="ml-auto" data-testid="shipping-price">
            <Money amount={shippingPrice} />
          </span>
        </div>
        {+discount > 0 && (
          <div className="flex align-items-center mb-1">
            <div className="font-size-md text-muted">Discount</div>
            <span className="ml-auto" data-testid="shipping-price">
              <Money
                amount={discount}
                className="text-success"
                isNegative={true}
                unstyledSymbol={true}
              />
            </span>
          </div>
        )}
        <div className="flex align-items-center">
          <div className="font-size-lg">Total</div>
          <strong className="ml-auto font-size-lg" data-testid="order-total">
            <Money amount={totalPrice} />
          </strong>
        </div>
      </div>
    </div>
  );
};

export default LineItemSummary;
