import PropTypes from 'prop-types';
import React from 'react';

import { FormSelect } from 'fr-shared/components';
import { useLocations } from 'fr-shared/hooks';

const FormLocations = ({ label, name, ...props }) => {
  const { data: locations } = useLocations();

  if (!locations) return null;

  return <FormSelect name={name} label={label} optionList={locations} {...props} />;
};

FormLocations.defaultProps = {
  label: 'Produced By',
};

FormLocations.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FormLocations;
