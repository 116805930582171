import PropTypes from 'prop-types';
import React from 'react';

import { FormFieldWithLinkPreview } from 'fr-shared/components';

const CarbonLinkFormField = ({ showCarbonLink, ...props }) => {
  return (
    <FormFieldWithLinkPreview
      {...props}
      showLink={showCarbonLink}
      name="carbon_link"
      label="Carbon Link"
    />
  );
};

CarbonLinkFormField.propTypes = {
  carbonLinkUrl: PropTypes.string,
  showCarbonLink: PropTypes.bool,
};

export default CarbonLinkFormField;
