import { connect } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { FormField, FormSelect } from 'fr-shared/components';
import { WORK_ORDER_STATES } from 'fr-shared/lib/work_orders';

import {
  BuildTimeFormField,
  MPILinkFormField,
  MaterialAmountFormField,
  PrinterFormField,
} from './fields';

const WorkOrderDetailsFDM = ({ formik, isEditing, readonly }) => {
  const { state } = formik.values;

  return (
    <>
      <div className="card-body">
        <div className="row">
          <div className="col-lg">
            {isEditing && <PrinterFormField readonly={readonly} />}

            <BuildTimeFormField />
          </div>

          <div className="col-lg">
            {state === 'Draft' && (
              <FormField
                name="num_of_build_plates"
                type="Number"
                label="# of Build Plates"
                readonly={readonly}
              />
            )}
            {isEditing && state !== 'Draft' && (
              <FormSelect
                label="State"
                name="state"
                optionList={WORK_ORDER_STATES}
                valueAccessor={option => option}
                nameAccessor={option => option}
                readonly={readonly}
              />
            )}
            <div className="d-inline">
              <MaterialAmountFormField readonly={readonly} showGrams={isEditing} />
            </div>
            <FormField name="notes" label="Notes" readonly={readonly} />
            <MPILinkFormField readonly={readonly} />
          </div>
        </div>
      </div>
      <div className="flex flex-column">
        <div className="card-footer bg-light">
          <div className="row">
            <div className="d-block d-lg-flex col-md">
              <FormField
                name="material_color"
                className="mr-1"
                label="Material Color"
                readonly={readonly}
                stacked={true}
              />
              <FormField
                name="material_roll_id"
                label="Material Roll ID"
                className="mr-1"
                readonly={readonly}
                stacked={true}
              />
              <FormField
                name="support_roll_id"
                label="Support Roll ID"
                className="mr-1"
                readonly={readonly}
                stacked={true}
              />
              <FormField
                name="layer_height"
                label="Layer Height"
                readonly={readonly}
                stacked={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

WorkOrderDetailsFDM.propTypes = {
  buildCarts: PropTypes.array,
  formik: PropTypes.object,
  getMaterialGrams: PropTypes.func,
  getMaterialName: PropTypes.func,
  hasProcess: PropTypes.bool,
  isEditing: PropTypes.bool,
  printersForProcess: PropTypes.array,
  readonly: PropTypes.bool,
};

export default connect(WorkOrderDetailsFDM);
