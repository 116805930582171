import React from 'react';

import { classNames } from 'fr-shared/components';

import styles from './LoadingCircle.module.css';

const LoadingCircle = ({
  className,
  centerCircleClassName,
  size,
}: {
  className?: string;
  centerCircleClassName?: string;
  size?: string;
}) => {
  return (
    <div className={classNames([styles.Circle, size && styles[size], className])}>
      <div className={styles.BarCircle}></div>
      <div className={classNames([styles.CenterCircle, centerCircleClassName])}></div>
    </div>
  );
};

export default LoadingCircle;
