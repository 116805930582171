/* eslint-disable react/display-name,react/prop-types */
import { countBy, pickBy } from 'lodash';
import moment from 'moment';
import React from 'react';

import { api } from 'fr-shared/api';
import { Button, Link } from 'fr-shared/components';
import { useTableFilters } from 'fr-shared/hooks';

import QuotesTable from '../../quotes/components/QuotesTable';
import { quoteToDisplayID } from '../../quotes/utils/transforms';

const getQuotes = (params, filters) => {
  return api.get('/quotes', { params: { ...pickBy(filters), ...params } });
};

export const CustomerQuotes = ({ params }) => {
  const {
    data: quoteRequests,
    onFetchData,
    pages,
    loading,
  } = useTableFilters(null, { page: 1, sort_key: 'inserted_at', sort_desc: true }, filters =>
    getQuotes(params, filters)
  );

  return (
    <div>
      <div className="flex align-items-center pb-3">
        <h3 className="mb-0">Quotes</h3>
      </div>
      <QuotesTable
        data={quoteRequests || []}
        pages={pages}
        onFetchData={onFetchData}
        loading={loading}
        columns={[
          {
            Header: '',
            expander: true,
            sortable: false,
            width: 45,
            Expander: ({ isExpanded }) => (
              <div className="flex justify-content-between align-items-center">
                <Button size="sm" color="secondary">
                  <i className={`fas fa-chevron-${isExpanded ? 'up' : 'down'}`} />
                </Button>
              </div>
            ),
          },
          {
            Header: 'Quote #',
            id: 'id',
            accessor: data => (
              <Link to={`/admin/quotes/${data.id}`}>{quoteToDisplayID(data)}</Link>
            ),
            width: 100,
          },
          {
            Header: 'Contact',
            id: 'customer_contact_name',
            accessor: 'customer_contact.name',
            sortable: false,
          },
          {
            Header: 'Public ID',
            id: 'public_id',
            accessor: 'public_id',
          },
          {
            Header: 'State',
            accessor: 'state',
            sortable: false,
          },
          {
            Header: 'MFG Process',
            id: 'manufacturing_process_id',
            sortable: false,
            accessor: data => {
              const count = countBy(data.line_items, 'manufacturing_process.name');
              const countString = Object.keys(count).map(k => ` ${k}: ${count[k]}`);
              return <span data-rh={countString}>{Object.keys(count).join(', ')}</span>;
            },
          },
          {
            Header: 'Submitted',
            id: 'completed_at',
            sortable: false,
            accessor: data => data.completed_at && moment(data.completed_at).format('MM/DD/YYYY'),
          },
          {
            Header: 'Total',
            id: 'total',
            sortable: false,
            accessor: data => data.total_price.formatted,
          },
        ]}
      />
    </div>
  );
};
