import { useTreatments } from '@splitsoftware/splitio-react';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { Button, CostingRequestLink, Icon, LineItemProcess, Select } from 'fr-shared/components';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';

import { LINE_ITEM_SORT_TEXT } from '../utils/sort';
import { QuoteFormContext } from './QuoteFormContext';

const QuoteFormLineItemPicker = ({
  onSelectLineItems,
  onClearOpportunity,
  readonly,
  opportunityId,
  opportunityIdLineItems,
  unselectedIdLineItems,
}) => {
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';
  const capLineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'Build Pack' : 'Line Item';

  const { opportunityIdLineItemsSortKey, setOpportunityIdLineItemsSortKey } =
    useContext(QuoteFormContext);
  const [showLineItems, setShowLineItems] = useState(true);

  const handleSelectLineItem = lineItem => onSelectLineItems([lineItem]);
  const handleToggleLineItems = () => setShowLineItems(show => !show);

  return (
    <>
      <div className="flex mb-3 px-3">
        <div className="flex-1 flex border rounded align-items-center justify-content-between bg-light px-2 mr-3">
          <div>{opportunityId}</div>
          <div>{get(opportunityIdLineItems, '0.customer.name')}</div>
          <div>{get(opportunityIdLineItems, '0.customer_contact.name')}</div>
          <div>{get(opportunityIdLineItems, '0.customer_contact.email')}</div>
        </div>
        {!readonly && (
          <Button color="secondary" outline={true} onClick={onClearOpportunity}>
            Clear Opportunity
          </Button>
        )}
      </div>

      {isEmpty(opportunityIdLineItems) ? (
        <div className="pb-2 px-3">
          <Icon name="spinner" className="mr-2 fa-pulse text-primary" right />
          Loading Opportunity ID Information
        </div>
      ) : readonly ? null : isEmpty(unselectedIdLineItems) ? (
        <div className="pb-2 px-3">All {lineItemName}s have been added</div>
      ) : (
        <>
          <div className="flex justify-content-end border-top py-2 px-3">
            <Button color="link" className="pl-0" onClick={handleToggleLineItems}>
              {showLineItems ? 'Hide List' : 'Show List'}
            </Button>
            <div className="ml-auto">
              {showLineItems && (
                <>
                  <Select
                    className="mr-2"
                    label={
                      <div className="mr-1">
                        <Icon name="sort" /> Sort by:
                      </div>
                    }
                    optionList={Object.keys(LINE_ITEM_SORT_TEXT)}
                    nameAccessor={option => LINE_ITEM_SORT_TEXT[option]}
                    valueAccessor={option => option}
                    value={opportunityIdLineItemsSortKey}
                    onChange={e => setOpportunityIdLineItemsSortKey(e.target.value)}
                  />
                  <Button
                    color="primary"
                    onClick={() => onSelectLineItems(unselectedIdLineItems)}
                  >
                    <Icon name="plus" right={true} />
                    Add All {capLineItemName}s
                  </Button>
                </>
              )}
            </div>
          </div>
          {showLineItems &&
            unselectedIdLineItems.map((lineItem, index) => (
              <QuotePickerLineItem
                index={index}
                key={`available-${index}`}
                lineItem={lineItem}
                onSelectLineItem={handleSelectLineItem}
              />
            ))}
        </>
      )}
    </>
  );
};

QuoteFormLineItemPicker.propTypes = {
  onClearOpportunity: PropTypes.func,
  onSelectLineItems: PropTypes.func,
  onToggleLineItems: PropTypes.func,
  opportunityId: PropTypes.string,
  opportunityIdLineItems: PropTypes.array,
  readonly: PropTypes.bool,
  unselectedIdLineItems: PropTypes.array,
};

const QuotePickerLineItem = ({ index, children, onSelectLineItem, lineItem }) => {
  const currentRevision = get(lineItem, 'part.current_revision');
  const screenshot = get(currentRevision, 'screenshot');

  return (
    <div
      id={`line_item_${index}`}
      className="part-line-item relative align-items-start border-top px-3"
    >
      <div className="flex-1">
        <div className="part-line-container bg-white">
          <div className="cursor-pointer mr-3">
            <img
              src={screenshot}
              width={30}
              height={30}
              className="rounded"
              alt="Part screenshot"
            />
          </div>
          <div className="mr-3">
            <strong className="text-break">{lineItem.description}</strong>
            <div className="flex flex-row">
              <CostingRequestLink costingRequestId={lineItem.costing_request_id} />
              <div className="ml-2 text-muted font-size-md">
                {moment(lineItem.completed_at).format('M/D/YY @ H:mma')}
              </div>
            </div>
          </div>
          <div className="ml-auto flex align-items-center">
            {lineItem.supplier_location && (
              <div className="part-line-label">
                <span>Supplier</span>
                <strong data-testid="supplier-name">{lineItem.supplier_location.name}</strong>
              </div>
            )}
            <div className="part-line-label">
              <span>Manufacturing Details</span>
              <strong>
                <LineItemProcess data={lineItem} />
              </strong>
            </div>
            <div className="part-line-label">
              <span>Quantity</span>
              <span>
                <strong>{lineItem.quantity}</strong>
                {lineItem.optimal && <Icon name="star" className="text-primary ml-1" />}
              </span>
            </div>
            <div className="part-line-label">
              <span>Unit Cost</span>
              <span>
                <strong>
                  {get(lineItem, 'unit_cost.formatted') ||
                    get(lineItem, 'per_unit_cost.formatted')}
                </strong>
              </span>
            </div>
            {lineItem.offered_lead_time && (
              <div className="part-line-label">
                <span>Lead Time</span>
                <strong>{lineItem.offered_lead_time}</strong>
              </div>
            )}
            {onSelectLineItem && (
              <Button color="primary" className="ml-3" onClick={() => onSelectLineItem(lineItem)}>
                <Icon name="plus" />
              </Button>
            )}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

QuotePickerLineItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  index: PropTypes.number,
  lineItem: PropTypes.object,
  onSelectLineItem: PropTypes.func,
};

export default QuoteFormLineItemPicker;
