import { useContext, useEffect, useState } from 'react';

import { AlertContext, PhoenixSocketContext } from 'fr-shared/context';

const noSocketConnAlert = {
  message:
    "We're having trouble connecting to the server. You may want to try refreshing the page.",
  color: 'danger',
  autoClose: false,
};

const useChannel = (channelName: string) => {
  const [channel, setChannel] = useState<undefined | any>();

  const { socket } = useContext(PhoenixSocketContext);
  const { setAlert, closeAlert } = useContext(AlertContext);

  useEffect(() => {
    if (socket) {
      socket.onClose(() => {
        setAlert(noSocketConnAlert);
      });

      socket.onOpen(() => {
        closeAlert();
      });

      const phoenixChannel = socket.channel(channelName);

      phoenixChannel.join().receive('ok', () => {
        setChannel(phoenixChannel);
      });
    }
    return () => {
      closeAlert();
      if (socket) {
        socket.onClose(() => {});
      }
    };
  }, [channelName, socket, setAlert, closeAlert]);

  return [channel];
};

export default useChannel;
