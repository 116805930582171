import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, Helmet } from 'fr-shared/components';

import OrderForm from './components/OrderForm';

const OrderNew = ({ history, match }) => {
  const createOrder = formikProps => {
    api
      .post('/orders', {
        order: formikProps.values,
        send_confirmation: formikProps.values.send_confirmation,
      })
      .then(res => {
        history.push(`/admin/orders/${res.data.id}`);
      })
      .catch(err => {
        formikProps.setSubmitting(false);
        if (err.response?.data) {
          const errors = err.response.data.errors;
          const messages = err.response.data.messages;
          formikProps.setErrors({
            server: messages,
            ...errors,
            'customer_contact.id': errors && errors.customer_contact_id,
            'customer.id': errors && errors.customer_id,
          });
        } else if (err.response?.status === 403) {
          formikProps.setErrors({ server: ['You do not have access to create an order.'] });
        } else {
          formikProps.setErrors({
            server: ['An unexpected error occurred. Refresh and try again.'],
          });
        }

        window.scrollTo(0, 0);
      });
  };

  const handleSubmit = (formikProps, instance) => {
    // if we have selected a credit card, we need to get the
    // payment nonce from Braintree
    const hasCC =
      formikProps.values.payment_type === 'Customer Credit Card' ||
      formikProps.values.payment_terms === 'Credit Card';

    if (hasCC && instance) {
      instance
        .requestPaymentMethod()
        .then(res => {
          // set the braintree nonce for the order
          formikProps.values.payment_method_nonce = res.nonce;

          createOrder(formikProps);
        })
        .catch(() => {
          Sentry.captureMessage('Braintree requestPaymentMethod failed');
        });
    } else {
      createOrder(formikProps);
    }
  };

  return (
    <>
      <Helmet title="New Order" />
      <Breadcrumb to="/admin/orders">Orders</Breadcrumb>
      <Breadcrumb to="/admin/orders/new">New Order</Breadcrumb>

      <OrderForm
        title="New Order"
        onSubmit={handleSubmit}
        history={history}
        match={match}
        readonly={false}
        isEditing={true}
      />
    </>
  );
};

OrderNew.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default OrderNew;
