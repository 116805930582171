import PropTypes from 'prop-types';
import React from 'react';

import { IconFont } from 'fr-shared/components';

import { Button, EmptyStateCard } from 'portal/components';

export const EmptyOrdersCard = ({ onActionClick = () => {} }) => {
  return (
    <EmptyStateCard
      iconFont="cart"
      cardHeader="Recent Orders"
      mainText="You don’t have any orders yet. To get started, just create a new quote."
    >
      <div className="mb-1">
        <Button block outline to="/add-part" onClick={onActionClick}>
          <IconFont name="plus" />
          Create Quote
        </Button>
      </div>
    </EmptyStateCard>
  );
};

EmptyOrdersCard.propTypes = {
  onActionClick: PropTypes.func,
};

export default EmptyOrdersCard;
