import React, { useEffect, useState } from 'react';

import { Checkbox, FormCheckbox } from 'fr-shared/components';

import { QUOTE_STATES } from '../../../lib/quotes';
import styles from './FilterCheckBoxes.module.css';

interface FilterCheckBoxesProps {
  filters: any;
  setFilters: (filters: any) => void;
}

const FilterCheckBoxes = ({ filters, setFilters }: FilterCheckBoxesProps) => {
  const [selectAll, setSelectAll] = useState(true);

  const allQuoteStates = Object.values(QUOTE_STATES);

  useEffect(() => {
    if (filters.states.length < allQuoteStates.length) setSelectAll(false);

    if (filters.states.length === allQuoteStates.length) setSelectAll(true);
  }, [allQuoteStates.length, filters.states]);

  const handleCheckboxChange = (e: any, label: string) => {
    const isCheckboxChecked = e.currentTarget.checked === true;
    const wasAllCheckboxClicked = label === 'All';

    if (isCheckboxChecked) {
      if (wasAllCheckboxClicked) {
        setSelectAll(true);
        setFilters({ ...filters, page: 1, states: allQuoteStates });
      } else {
        let newFilters = filters.states.filter((state: any) => state != null);
        newFilters.push(label);
        setFilters({ ...filters, page: 1, states: newFilters });
      }
    } else {
      if (wasAllCheckboxClicked) {
        setSelectAll(false);
        setFilters({ ...filters, page: 1, states: [null] });
      } else {
        let deselectedFilters = filters.states.filter(
          (filterName: string) => filterName !== label
        );
        setFilters({
          ...filters,
          states: deselectedFilters.length === 0 ? [null] : deselectedFilters,
        });
      }
    }
  };

  let determineAllCheckboxState = !selectAll;
  if (!selectAll && filters.states.includes(null)) {
    determineAllCheckboxState = false;
  }

  return (
    <>
      <div className={styles.Checkbox}>
        <Checkbox
          label="All"
          className="text-primary"
          name="filter_all"
          onChange={e => handleCheckboxChange(e, 'All')}
          value={selectAll}
          indeterminate={determineAllCheckboxState}
        />
      </div>
      {Object.values(QUOTE_STATES).map(quote_state => (
        <div className={styles.Checkbox} key={quote_state}>
          <FormCheckbox
            label={quote_state}
            name={`filter_${quote_state}`}
            onClick={e => handleCheckboxChange(e, quote_state)}
            value={filters.states.find((label: string) => label === quote_state) != null}
          />
        </div>
      ))}
    </>
  );
};

export default FilterCheckBoxes;
