/* eslint-disable react/display-name, react/prop-types */
import { compact, uniqBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  Button,
  FirstOrderBadge,
  Icon,
  LineItemProcess,
  Link,
  Table,
  classNames,
} from 'fr-shared/components';
import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';
import { keyboardDown } from 'fr-shared/utils';

import { hasProcess } from './utils/hasProcess';

const WorkOrderRow = ({ className, onClick, onNextState, showState, workOrder }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const getRowBackground = earliestShipDate => {
    const days = moment({ hours: 0 }).diff(earliestShipDate, 'days');
    let background = 'rgba(239, 140, 140, .25)';
    if (days < 0 && days >= -3) {
      background = 'rgba(249, 212, 92, .25)';
    } else if (days < 0) {
      background = 'rgba(156, 193, 119, .25)';
    }
    return { background };
  };

  const lines = workOrder.line_items && workOrder.line_items.filter(l => l.order_line_item);
  const line = lines[0] && lines[0].order_line_item;

  const order_public_ids =
    workOrder.line_items &&
    uniqBy(
      compact(
        workOrder.line_items.map(l => {
          if (l.order_line_item && l.order_line_item.order)
            return {
              id: l.order_line_item.order_id,
              public_id: l.order_line_item.order.public_id,
            };
        })
      ),
      'public_id'
    );

  const lineItems = workOrder.line_items;

  const isFirstOrder = workOrder.line_items.some(l => l.order_line_item?.order?.is_first_order);

  return (
    <div className={classNames(['transition-all', 'flex-1', className])}>
      <div className="flex">
        <Button
          size="sm"
          color="secondary"
          className="outline-none p-1 rounded-0"
          onClick={() => setIsExpanded(!isExpanded)}
          style={{ width: 25, zIndex: 100 }}
        >
          <i className={`fas fa-chevron-${isExpanded ? 'up' : 'down'}`} />
        </Button>
        <div
          className="container px-0"
          style={getRowBackground(workOrder.earliest_ship_date)}
          onClick={onClick}
          onKeyDown={evt => keyboardDown(evt, 'Enter', onClick)}
          role="button"
          tabIndex={0}
        >
          <div className="row py-1 px-2 align-items-center">
            <div className="col-sm flex align-items-center" style={{ flex: '0 0 100px' }}>
              <div className="font-size-lg">
                <Link
                  data-testid={`work-order-id-link-${workOrder.id}`}
                  to={`/admin/work_orders/${workOrder.id}`}
                >
                  <strong>#{workOrder.id}</strong>
                </Link>
              </div>
            </div>
            {isFirstOrder && <FirstOrderBadge fontSize="sm" />}
            <div className="font-size-md col-sm-3">
              {line && (
                <strong>
                  <LineItemProcess data={line} />
                </strong>
              )}
            </div>
            {showState && (
              <div className="flex align-items-center font-size-md">
                <div>
                  State: <strong>{workOrder.state}</strong>
                </div>
              </div>
            )}
            <div className="ml-auto flex align-items-center px-2">
              {workOrder.carbon_link && (
                <Link className="mr-2" to={{ pathname: workOrder.carbon_link }} openNewWindow>
                  <Icon name="cuttlefish" type="fab" tooltip="Carbon link" sm />
                </Link>
              )}
              {workOrder.mjf_link && (
                <Link className="mr-2" to={{ pathname: workOrder.mjf_link }} openNewWindow>
                  <Icon name="cuttlefish" type="fab" tooltip="MJF link" sm />
                </Link>
              )}
            </div>
          </div>
          <div className="flex py-1 align-items-center border-top border-light">
            <div className="pl-2 pr-3 font-size-md">
              <span className="mr-1">Order #</span>
              {order_public_ids.map(l => (
                <Link
                  key={l.id}
                  className="d-inline-block mr-2"
                  to={`/admin/orders/${l.id}`}
                  openNewWindow
                  openNewWindowIcon
                >
                  <strong>{l.public_id}</strong>
                </Link>
              ))}
            </div>
            {(workOrder.build_time_hours || workOrder.build_time_minutes) && (
              <div className="mr-4 font-size-md">
                <span className="mr-1">Print Time</span>
                <span>
                  {workOrder.build_time_hours && workOrder.build_time_hours > 0 && (
                    <strong>{workOrder.build_time_hours} hrs </strong>
                  )}
                  {workOrder.build_time_minutes && workOrder.build_time_minutes > 0 && (
                    <strong>{workOrder.build_time_minutes} min</strong>
                  )}
                </span>
              </div>
            )}
            {workOrder.material_amount && (
              <div className="mr-4 font-size-md">
                <span className="mr-1">Material</span>
                <span>
                  <strong>{workOrder.material_amount}ml</strong>
                </span>
              </div>
            )}
            {workOrder.printer && (
              <div className="mr-4 font-size-md">
                <span className="mr-1">Printer</span>
                <span>
                  <strong>{workOrder.printer.name}</strong>
                </span>
              </div>
            )}
            {hasProcess(lineItems, MANUFACTURING_PROCESSES.MJF) && (
              <>
                <div className="font-size-md mr-4">
                  <span className="mr-1">Density</span>
                  <span>
                    <strong>{workOrder.build_density}%</strong>
                  </span>
                </div>
                {workOrder.irradiance && (
                  <div className="font-size-md mr-1">
                    <span className="mr-1">Irradiance</span>
                    <span>
                      <strong>{workOrder.irradiance}%</strong>
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {onNextState && (
          <Button
            color="success"
            size="sm"
            className="ml-auto rounded-0"
            tooltip="Next state"
            onClick={() => onNextState(workOrder)}
          >
            <Icon name="chevron-right" sm />
          </Button>
        )}
      </div>
      {isExpanded && (
        <div className="font-size-sm bg-white border border-secondary">
          <Table
            data={lineItems}
            className="bg-white -left -striped font-size-sm"
            defaultPageSize={lineItems.length}
            noDataText="No contacts found"
            showPagination={false}
            columns={[
              {
                Header: 'Order #',
                id: 'quote',
                accessor: line => line.order_line_item,
                Cell: props => (
                  <div>
                    <Link to={`/admin/orders/${props.value.order_id}`}>
                      <strong>{props.value.order.public_id}</strong>
                    </Link>
                  </div>
                ),
              },
              {
                Header: 'Customer',
                id: 'customer',
                accessor: line => line.order_line_item.order.customer.name,
              },
              {
                Header: 'Part Name',
                id: 'partName',
                accessor: line => line.order_line_item.description,
              },
              {
                Header: 'Qty',
                accessor: 'quantity',
                width: 60,
              },
              {
                Header: 'MFG Process',
                id: 'mfg',
                accessor: line => line.order_line_item,
                Cell: props => <LineItemProcess data={props.value} />,
              },
              {
                Header: 'Promised Ship',
                id: 'promisedShip',
                accessor: line => line.order_line_item.promised_ship_date,
                Cell: props =>
                  props.value && <div>{moment(props.value).format('MM/DD/YYYY')}</div>,
              },
              {
                Header: 'Actual Ship',
                id: 'actualShip',
                accessor: line => line.order_line_item.actual_ship_date,
                Cell: props =>
                  props.value && <div>{moment(props.value).format('MM/DD/YYYY')}</div>,
              },
              {
                id: 'inspectionType',
                accessor: line => line.order_line_item.inspection_type.name,
                Cell: props => (
                  <span className="flex-1" style={{ flex: '0 0 30px' }}>
                    {props.value}
                  </span>
                ),
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

WorkOrderRow.propTypes = {
  className: PropTypes.string,
  onNextState: PropTypes.func,
  onClick: PropTypes.func,
  showState: PropTypes.bool,
  workOrder: PropTypes.object,
};

export default WorkOrderRow;
