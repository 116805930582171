import { useFormikContext } from 'formik';
import { isEmpty, isNil } from 'lodash';
import React, { useCallback, useState } from 'react';

import { api } from 'fr-shared/api';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  UncontrolledDropdown,
} from 'fr-shared/components';
import { useUsersByRole } from 'fr-shared/hooks';
import { ORDER_STATES } from 'fr-shared/lib/orders';
import { ROLES } from 'fr-shared/lib/roles';

import TransitionToApproved from './TransitionToApproved';
import TransitionToRejected from './TransitionToRejected';

const TransitionToDropdown = () => {
  const formik = useFormikContext();
  const {
    values: { id: orderId },
  } = formik;

  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
  const [isRejectedModalOpen, setIsRejectedModalOpen] = useState(false);
  const [failedToSelectSalesContact, setFailedToSelectSalesContact] = useState(false);
  const [failedToSelectSupportContact, setFailedToSelectSupportContact] = useState(false);

  const { data: opsManagementUsers } = useUsersByRole(ROLES.OperationsProjectManagement);
  const { data: salesUsers } = useUsersByRole(ROLES.Sales);

  const setSalesContact = useCallback(
    selectedUser => {
      formik.setFieldValue('sales_contact', isNil(selectedUser) ? {} : selectedUser);
      formik.setFieldValue('sales_contact_id', selectedUser.id);
    },
    [formik]
  );

  const setSupportContact = useCallback(
    selectedUser => {
      formik.setFieldValue('support_contact', isNil(selectedUser) ? {} : selectedUser);
      formik.setFieldValue('support_contact_id', selectedUser.id);
    },
    [formik]
  );

  const onRejectTransitionToRejected = useCallback(() => {
    formik.setFieldValue('order_state_event_comment', null);
    formik.setFieldTouched('order_state_event_comment', false);
    setIsRejectedModalOpen(false);
  }, [formik]);

  const updateOrder = useCallback(
    (id, params) => {
      const send_confirmation = !!params.sendConfirmation;
      const state = params.state;
      const order_state_event_comment =
        state === ORDER_STATES.ActionRequired ? formik.values.order_state_event_comment : null;

      const line_items = formik.values.line_items.map(li => {
        return { ...li, state };
      });

      setFailedToSelectSalesContact(!formik.values.sales_contact);
      setFailedToSelectSupportContact(!formik.values.support_contact);

      if (isEmpty(order_state_event_comment) && state === ORDER_STATES.ActionRequired) {
        formik.setFieldError(
          'order_state_event_comment',
          "This note is require to update order state to 'Action Required'"
        );

        return;
      }

      if (!formik.values.sales_contact || !formik.values.support_contact) {
        return;
      }

      api
        .put(`/orders/${id}`, {
          order: {
            line_items,
            order_state_event_comment,
            sales_contact: formik.values.sales_contact,
            sales_contact_id: formik.values.sales_contact_id,
            state,
            support_contact: formik.values.support_contact,
            support_contact_id: formik.values.support_contact_id,
          },
          send_confirmation,
        })
        .then(() => {
          formik.setFieldValue('state', state);
          formik.setFieldValue('line_items', line_items);
          formik.setFieldTouched('order_state_event_comment', false);
        });
    },
    [formik]
  );

  return (
    <>
      <UncontrolledDropdown defaultOpen={false}>
        <DropdownToggle caret={false} split={false} nav={true} tag="div" className="w-100">
          <Button className="w-100 badge badge-pill text-primary" outline color="link" size="md">
            Transition To <Icon name="chevron-down" />
          </Button>
        </DropdownToggle>
        <DropdownMenu right={false} modifiers={{}} persist={true}>
          <DropdownItem onClick={() => setIsApprovalModalOpen(true)}>
            Transition to{' '}
            <span className="badge badge-pill badge-primary">Customer Order Placed</span>
          </DropdownItem>
          <DropdownItem onClick={() => setIsRejectedModalOpen(true)}>
            Transition to <span className="badge badge-pill badge-danger">Action Required</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <TransitionToApproved
        isModalOpen={isApprovalModalOpen}
        onAccept={updateOrder}
        onReject={() => setIsApprovalModalOpen(false)}
        orderId={orderId}
      />
      <TransitionToRejected
        failedToSelectSalesContact={failedToSelectSalesContact}
        failedToSelectSupportContact={failedToSelectSupportContact}
        isModalOpen={isRejectedModalOpen}
        onAccept={updateOrder}
        onReject={onRejectTransitionToRejected}
        orderId={orderId}
        salesContact={formik.values.sales_contact}
        salesUsers={salesUsers}
        setSalesContact={setSalesContact}
        setSupportContact={setSupportContact}
        supportContact={formik.values.support_contact}
        supportUsers={opsManagementUsers}
      />
    </>
  );
};

export default TransitionToDropdown;
