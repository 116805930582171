import { get, isEmpty } from 'lodash';

export const formatErrorMessages = (errors = {}, messages = [], line_item_values = []) => {
  const lineItemMessages = get(errors, 'line_items', []).reduce(
    (errorMessages, lineItem, idx) => {
      if (isEmpty(lineItem)) {
        return errorMessages;
      } else {
        const description = get(line_item_values, [idx, 'description'], '') || '';

        return errorMessages.concat(`Line Item #${idx + 1}; ${description}`);
      }
    },
    []
  );
  const topLevelMessages = messages.filter(m => !m.startsWith('Line Item'));
  return [...topLevelMessages, ...lineItemMessages];
};

const REQUIRED_ERROR_KEY = "can't be blank"; // based on the default Ecto message generated by Ecto.Changeset.validate_required/3
const PART_FILE_REQUIRED_MSG =
  'Please upload a supported part file to continue. We don’t support SolidWorks (SLDPRT).';

function formatRequiredErrors(errors, message) {
  return formatErrors(errors, key => (key === REQUIRED_ERROR_KEY ? message : key));
}

function formatErrors(errors, messageTransformer) {
  if (errors === null || errors === undefined) {
    return errors;
  }
  const errorsToMap = Array.isArray(errors) ? errors : [errors];
  return errorsToMap.map(key => messageTransformer(key));
}

// TODO - remove once validation moves to build pack processes
export const formatFormikErrors = (errors = {}) => {
  const lineItemErrors = get(errors, 'line_items', []).map(lineItem => {
    return {
      ...lineItem,
      part_id: formatRequiredErrors(lineItem.part_id, PART_FILE_REQUIRED_MSG),
      build_package: {
        ...lineItem?.build_package,
        processes: [
          {
            ...lineItem?.build_package?.processes[0],
            inspection_type_id: lineItem?.inspection_type_id,
            inspection_type: lineItem?.inspection_type,
            manufacturing_process_id: lineItem?.manufacturing_process_id,
            manufacturing_process: lineItem?.manufacturing_process,
          },
        ],
      },
    };
  });

  return { ...errors, line_items: lineItemErrors };
};
