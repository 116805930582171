import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'fr-shared/components';

const IconButton = ({ label, icon, onClick, className, iconType, dataTestId, right }) => {
  return (
    <button
      type="button"
      title={label}
      className={`btn btn-link ${className}`}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <Icon name={icon} right={right} type={iconType} />
      <span className="sr-only">{label}</span>
    </button>
  );
};

IconButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  iconType: PropTypes.string,
  dataTestId: PropTypes.string,
  right: PropTypes.bool,
};

export default IconButton;
