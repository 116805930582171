/* eslint-disable react/display-name */
import { get } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Money, Table } from 'fr-shared/components';
import { useCurrentSubscriptionPlan } from 'fr-shared/hooks';

import { SUBSCRIPTION } from 'portal/lib/plans';

export const ContactSubscriptionPlan = () => {
  const { user_id: userId } = useParams() as { user_id: string };
  const { currentSubscription, subscriptionData, isLoading } = useCurrentSubscriptionPlan(userId);
  const isBasicSubscription = currentSubscription === SUBSCRIPTION.BASIC;
  const billingCycle = get(subscriptionData, 'plan_version.billing_cycle');
  const subscriptionPrice = get(subscriptionData, 'plan_version.price.formatted');
  const cardType = get(subscriptionData, 'payment_method.card_type');
  const lastFour = get(subscriptionData, 'payment_method.last_4_digits');
  const subscriptionTransactions = get(subscriptionData, 'transactions', []);

  const stats: Record<string, any> = {
    ['Plan name']: currentSubscription,
    ['Had previous subscription']: subscriptionData ? 'Yes' : 'No',
    ['Last renewal']: '- -',
    ['Next renewal']: '- -',
    ['Billing frequency']: `${billingCycle} - ${subscriptionPrice}`,
  };

  return (
    <>
      <h3 className="border-0">Subscription plan</h3>
      <div className="bg-white rounded-sm p-3 mb-5">
        {!isLoading && (
          <>
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-4">
              {Object.keys(stats).map(label => {
                if (
                  isBasicSubscription &&
                  !['Plan name', 'Had previous subscription'].includes(label)
                )
                  return;
                if (!isBasicSubscription && label === 'Had previous subscription') return;
                return (
                  <div key={label} className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-400 mb-0">{label}</dt>
                    <dd className="text-sm text-gray-900 mb-0">{stats[label]}</dd>
                  </div>
                );
              })}
            </div>
            {subscriptionData && !isBasicSubscription && (
              <div className="flex flex-col text-sm pt-2">
                <div className="font-medium text-gray-400">Subscription payment method</div>
                <div className="text-gray-900">
                  {cardType} - {lastFour}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <h3 className="border-0">Subscription transaction history</h3>
      <Table
        manual={true}
        data={subscriptionTransactions}
        showPagination={false}
        pageSize={subscriptionTransactions.length}
        loading={isLoading}
        className={'bg-white -left -striped mb-5'}
        columns={[
          {
            Header: 'Payment date',
            id: 'payment_date',
            accessor: (data: SubscriptionTransaction) => (
              <span>{moment(data.payment_date).format('MM/DD/YY')}</span>
            ),
            sortable: false,
          },
          {
            Header: 'Payment method',
            id: 'last_4',
            accessor: (data: SubscriptionTransaction) => (
              <span>Card ending in {data.last_4}</span>
            ),
            sortable: false,
          },
          {
            Header: 'Amount',
            id: 'amount',
            accessor: (data: SubscriptionTransaction) => <Money amount={data.amount.amount} />,
            sortable: false,
          },
          {
            Header: 'Plan name',
            accessor: 'plan_name',
            sortable: false,
          },
          {
            Header: 'Braintree transaction id',
            accessor: 'invoice_id',
            sortable: false,
          },
          {
            Header: 'Status',
            id: 'successful',
            accessor: (data: SubscriptionTransaction) => {
              return (
                <span className={data?.successful ? 'text-success-400' : 'text-error-400'}>
                  {data.successful ? 'Complete' : 'Unsuccessful'}
                </span>
              );
            },
            sortable: false,
          },
        ]}
      />
    </>
  );
};

export default ContactSubscriptionPlan;
