import { useFormikContext } from 'formik';
import { debounce, isEmpty, parseInt, sortBy, unionBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { api } from 'fr-shared/api';
import { FormFieldBase, FormSelect, FormSelectCreate } from 'fr-shared/components';

const FormCustomerSelect = ({
  isCustomerNameEditable,
  onCustomerChange,
  onCustomerContactChange,
  readonly,
}) => {
  const formik = useFormikContext();
  const [customerSearchResults, setCustomerSearchResults] = useState([]);

  const customerContacts = formik.values.customer?.users;
  const customerContactEmail = formik.values.customer_contact?.email;
  const customerContactPhoneNumber = formik.values.customer_contact?.phone_number;
  const customerName = formik.values.customer?.name;
  const isCustomerPortalUser = formik.values.customer_contact?.portal_user;

  const handleCustomerChange = e => {
    const customer = e.data;

    formik.setFieldValue('customer', customer);
    formik.setFieldValue('customer_id', customer && customer.id);
    formik.setFieldValue('customer_contact', null);
    formik.setFieldValue('customer_contact_id', null);
    onCustomerChange && onCustomerChange(customer);
    formik.handleChange({ target: e });
  };

  const handleCustomerContactChange = e => {
    const customerContactId = e.target.value;
    const customerContact = customerContacts.find(
      user => parseInt(user.id) === parseInt(customerContactId)
    );

    if (customerContact) {
      formik.setFieldValue('customer_contact', customerContact);
      formik.setFieldValue('customer_contact_id', customerContact.id);
      onCustomerContactChange && onCustomerContactChange(customerContact);
      formik.handleChange(e);
    }
  };

  const handleSearchCustomers = debounce((inputValue, callback) => {
    // search for the org
    api.get('/organizations?page_size=1000', { params: { name: inputValue } }).then(res => {
      // format the search results for the dropdown
      const results = res.data.map(customer => ({
        label: customer.name,
        value: customer.id.toString(),
        data: customer,
      }));
      setCustomerSearchResults(sortBy(unionBy(customerSearchResults, results, 'value'), 'label'));
      // set the options of the dropdown to the search results
      callback(results);
    });
  }, 450);

  return (
    <>
      {!isCustomerNameEditable ? (
        <FormFieldBase label="Customer" readonly={true}>
          <div>
            <strong>{customerName}</strong>
          </div>
        </FormFieldBase>
      ) : (
        <FormSelectCreate
          name="customer.id"
          accessor="customer.name"
          isClearable={true}
          isCreateable={false}
          label="Customer"
          placeholder="Search customer..."
          defaultOptions={customerSearchResults}
          loadOptions={handleSearchCustomers}
          noOptionsMessage={() => 'No customers found'}
          onChange={handleCustomerChange}
          readonly={readonly}
          required={true}
        />
      )}

      {customerContacts && (
        <FormSelect
          label="Contact"
          name="customer_contact.id"
          optionList={customerContacts}
          onChange={handleCustomerContactChange}
          readonly={readonly && 'customer_contact.name'}
          required={true}
        />
      )}
      {customerContactEmail && (
        <FormFieldBase label="Email" readonly={true}>
          <div>
            <strong>{customerContactEmail}</strong>
          </div>
        </FormFieldBase>
      )}
      {customerContactPhoneNumber && (
        <FormFieldBase label="Phone Number" readonly={true}>
          <div>
            <strong>{customerContactPhoneNumber}</strong>
          </div>
        </FormFieldBase>
      )}
      <FormFieldBase label="Portal User" readonly={true}>
        <div>
          <strong>{isCustomerPortalUser ? 'Yes' : 'No'}</strong>
        </div>
      </FormFieldBase>
      {customerContacts && isEmpty(customerContacts) && (
        <div className="text-right">You need to add a contact to this customer</div>
      )}
    </>
  );
};

FormCustomerSelect.defaultProps = {
  isCustomerNameEditable: true,
};

FormCustomerSelect.propTypes = {
  isCustomerNameEditable: PropTypes.bool,
  onCustomerChange: PropTypes.func,
  onCustomerContactChange: PropTypes.func,
  readonly: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default FormCustomerSelect;
