import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { IconFont, classNames } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { keyboardDown } from 'fr-shared/utils';
import {
  ADDITIVE_PART_FILE_EXTENSIONS,
  TRADITIONAL_PART_FILE_EXTENSIONS,
  TWO_D_FILE_EXTENSIONS,
} from 'fr-shared/utils/files';

export const SupportedFiles = ({
  additiveFileExtensions = ADDITIVE_PART_FILE_EXTENSIONS,
  traditionalFileExtensions = TRADITIONAL_PART_FILE_EXTENSIONS,
  className,
  isFor2DFiles,
}) => {
  const userAnalytics = useUserAnalyticsContext();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleSupportType = () => {
    setIsCollapsed(!isCollapsed);
    userAnalytics.track('Viewed File Types', { valid: isCollapsed });
  };

  return (
    <div className={classNames(['text-gray', className])}>
      <div
        onClick={handleSupportType}
        className="cursor-pointer"
        onKeyDown={evt => keyboardDown(evt, 'Enter', handleSupportType)}
        role="button"
        tabIndex={0}
      >
        Supported file types
        {isCollapsed && <IconFont className="ml-1" name="chevron-down" />}
        {!isCollapsed && <IconFont className="ml-1" name="chevron-up" />}
      </div>
      {!isCollapsed && (
        <div>
          {isFor2DFiles ? (
            <div className="mt-1">
              <FormattedExtensions extensions={TWO_D_FILE_EXTENSIONS} />
            </div>
          ) : (
            <>
              <div className="mt-2">
                For DLS, FDM, MJF, and SLA:{' '}
                <FormattedExtensions extensions={additiveFileExtensions} />
              </div>
              <div className="mt-2">
                For Cast Urethane, CNC, and Injection Molding:{' '}
                <FormattedExtensions extensions={traditionalFileExtensions} />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

SupportedFiles.propTypes = {
  className: PropTypes.string,
  isFor2DFiles: PropTypes.bool,
  additiveFileExtensions: PropTypes.arrayOf(PropTypes.string),
  traditionalFileExtensions: PropTypes.arrayOf(PropTypes.string),
};

export const FormattedExtensions = ({ extensions }) => {
  return extensions.map((ext, index) => {
    return (
      <span className="ml-1 font-weight-bold" key={ext}>
        {extensions.length - 1 === index ? ext : `${ext}, `}
      </span>
    );
  });
};

FormattedExtensions.propTypes = {
  extensions: PropTypes.arrayOf(PropTypes.string),
};

export default SupportedFiles;
