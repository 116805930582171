import PropTypes from 'prop-types';
import React from 'react';

import { classNames } from 'portal/components';

import styles from './Page.module.css';

const PageToolbar = ({ className = 'flex align-items-center', children }) => {
  return <div className={classNames([className, styles.PageToolbar])}>{children}</div>;
};

PageToolbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default PageToolbar;
