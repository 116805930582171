import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Breadcrumb, Button, Card, Helmet, Icon, Link, NavLink } from 'fr-shared/components';
import useSalesforceSybridgeInstanceFeatureFlag from 'fr-shared/hooks/useSalesforceSybridgeInstanceFeatureFlag';

import { ContactAddresses } from './contact/ContactAddresses';
import { ContactCarts } from './contact/ContactCarts';
import { ContactParts } from './contact/ContactParts';
import { ContactPaymentInformation } from './contact/ContactPaymentInformation';
import ContactSummary from './contact/ContactSummary';
import { CustomerCostingRequests } from './customer/CustomerCostingRequests';
import { CustomerOrders } from './customer/CustomerOrders';
import { CustomerQuotes } from './customer/CustomerQuotes';
import styles from './show.module.css';

export const getSalesforceLink = (id, sybridgeSalesforceInstanceEnabled) => {
  if (sybridgeSalesforceInstanceEnabled) {
    return process.env.MIX_ENV === 'prod'
      ? `https://sybridgetech.lightning.force.com/lightning/r/Contact/${id}/view`
      : `https://sybridgetech--frclone.sandbox.lightning.force.com/lightning/r/Contact/${id}/view`;
  } else {
    return process.env.MIX_ENV === 'prod'
      ? `https://fastradius.lightning.force.com/lightning/r/Contact/${id}/view`
      : `https://fastradius--frclone.lightning.force.com/lightning/r/Contact/${id}/view`;
  }
};

const ContactShow = () => {
  const { user_id: userId } = useParams();
  const history = useHistory();
  const [data, setData] = useState();
  const sybridgeSalesforceInstanceEnabled = useSalesforceSybridgeInstanceFeatureFlag();

  useEffect(() => {
    api.get(`/users/${userId}`).then(res => {
      setData(res.data);
    });
  }, [userId]);

  if (!data) return null;

  const name = data.name;
  const org = data.organization;
  const orgId = org?.id;

  return (
    <>
      <Helmet title={`Customer ${name}`} />
      <Breadcrumb to="/admin/">Customers</Breadcrumb>
      <Breadcrumb to={`/admin/customers/${orgId}`}>{org.name}</Breadcrumb>
      <Breadcrumb to={`/admin/customers/${orgId}/contact/${userId}`}>{name}</Breadcrumb>

      <div className="page-header">
        <div className="container">
          <div>
            <h5 className="mb-0" key={orgId}>
              <Link to={`/admin/customers/${orgId}`}>{org.name}</Link>
            </h5>
            <h2 className="mb-0">{name}</h2>
            <h4 className="text-muted mb-0">{data.email}</h4>
            {data.portal_user && <h4 className="text-muted mb-0">Customer Portal User</h4>}
            <h4 className="text-muted mb-0">{data.phone_number}</h4>

            {data.salesforce_id && (
              <div>
                Salesforce ID:{' '}
                <Link
                  to={{
                    pathname: getSalesforceLink(
                      data.salesforce_id,
                      sybridgeSalesforceInstanceEnabled
                    ),
                  }}
                  openNewWindow
                >
                  {data.salesforce_id}
                </Link>
              </div>
            )}
          </div>
          <div className="page-actions">
            <Button onClick={() => history.push(`/admin/customers/${orgId}`)} color="secondary">
              <Icon name="chevron-left" className="mr-1" />
              Back to {org.name}
            </Button>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <div className="flex gap-4 flex-col md:flex-row">
          <div className="min-w-[250px]">
            <Card className="position-sticky">
              <NavLink
                exact={true}
                activeClassName={styles.active}
                className={styles.NavLink}
                to={`/admin/customers/${orgId}/contact/${userId}/summary`}
              >
                Summary
              </NavLink>
              <NavLink
                exact={true}
                activeClassName={styles.active}
                className={styles.NavLink}
                to={`/admin/customers/${orgId}/contact/${userId}/costing_requests`}
              >
                Costing Requests
              </NavLink>
              <NavLink
                exact={true}
                activeClassName={styles.active}
                className={styles.NavLink}
                to={`/admin/customers/${orgId}/contact/${userId}/quotes`}
              >
                Quotes
              </NavLink>
              <NavLink
                exact={true}
                activeClassName={styles.active}
                className={styles.NavLink}
                to={`/admin/customers/${orgId}/contact/${userId}/orders`}
              >
                Orders
              </NavLink>
              <NavLink
                exact={true}
                activeClassName={styles.active}
                className={styles.NavLink}
                to={`/admin/customers/${orgId}/contact/${userId}/parts`}
              >
                Uploaded Parts
              </NavLink>
              <NavLink
                exact={true}
                activeClassName={styles.active}
                className={styles.NavLink}
                to={`/admin/customers/${orgId}/contact/${userId}/addresses`}
              >
                Billing/Shipping Information
              </NavLink>
              <NavLink
                exact={true}
                activeClassName={styles.active}
                className={styles.NavLink}
                to={`/admin/customers/${orgId}/contact/${userId}/payment`}
              >
                Payment Information
              </NavLink>
              <NavLink
                exact={true}
                activeClassName={styles.active}
                className={styles.NavLink}
                to={`/admin/customers/${orgId}/contact/${userId}/carts`}
              >
                <span
                  className="border border-secondary text-secondary rounded-circle font-size-xs mr-1"
                  style={{ padding: '2px 3px' }}
                >
                  <Icon name="shopping-cart" />
                </span>
                Portal Carts
              </NavLink>
              <Link
                className={styles.NavLink}
                to={{
                  pathname: getSalesforceLink(
                    data.salesforce_id,
                    sybridgeSalesforceInstanceEnabled
                  ),
                }}
                openNewWindow
              >
                Salesforce Contact
                <Icon name="external-link" className="ml-1" />
              </Link>
            </Card>
          </div>

          <div className="flex-1 overflow-x-auto">
            <Switch>
              <Route
                exact
                path="/admin/customers/:id/contact/:user_id/summary"
                render={() => <ContactSummary customerContact={data} />}
              />
              <Route
                exact
                path="/admin/customers/:id/contact/:user_id/costing_requests"
                render={() => (
                  <CustomerCostingRequests params={{ customer_contact_id: userId }} />
                )}
              />
              <Route
                exact
                path="/admin/customers/:id/contact/:user_id/quotes"
                render={() => <CustomerQuotes params={{ customer_contact_id: userId }} />}
              />
              <Route
                exact
                path="/admin/customers/:id/contact/:user_id/orders"
                render={() => <CustomerOrders params={{ customer_contact_id: userId }} />}
              />
              <Route
                exact
                path="/admin/customers/:id/contact/:user_id/addresses"
                render={() => <ContactAddresses />}
              />
              <Route
                exact
                path="/admin/customers/:id/contact/:user_id/parts"
                component={ContactParts}
              />
              <Route
                exact
                path="/admin/customers/:id/contact/:user_id/payment"
                render={() => <ContactPaymentInformation customerContact={data} />}
              />
              <Route
                exact
                path="/admin/customers/:id/contact/:user_id/carts"
                component={ContactCarts}
              />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactShow;
