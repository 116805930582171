import React from 'react';

import { Button } from 'fr-shared/components';

import { Link } from 'portal/components';
import LINK_URLS from 'portal/lib/link_urls';

export enum ContactUsType {
  button = 'button',
  link = 'link',
}

interface Props {
  children?: React.ReactNode;
  linkType?: ContactUsType;
  readonly?: Boolean;
  to?: string;
  [x: string]: any;
}

export const ContactUs = ({
  children,
  linkType = ContactUsType.link,
  readonly = false,
  ...props
}: Props) => {
  const ButtonElem = () => {
    return (
      <Button to={readonly ? '#' : LINK_URLS.ContactUrl} {...props}>
        {children}
      </Button>
    );
  };
  const LinkElem = () => {
    return (
      <Link to={readonly ? '#' : LINK_URLS.ContactUrl} {...props}>
        {children}
      </Link>
    );
  };
  const LinkOrButton = linkType === ContactUsType.button ? ButtonElem : LinkElem;

  return (
    <>
      <LinkOrButton />
    </>
  );
};

export default ContactUs;
