import { useField, useFormikContext } from 'formik';
import React from 'react';
import Select from 'react-select';

import { FormFieldBase } from 'fr-shared/components';

interface FormSelectSearchProps {
  className?: string;
  label?: string;
  name: string;
  options: Object[] | string[];
}

const FormSelectSearch = (props: FormSelectSearchProps) => {
  const { setFieldValue }: { [x: string]: any } = useFormikContext();
  const { name, options } = props;
  const [field, meta] = useField(name);

  const onChange = ({ value }: { value: string }) => {
    setFieldValue(name, value);
  };

  return (
    <FormFieldBase {...props} error={meta.error}>
      <Select
        aria-label="react-select-search"
        value={options.find((option: { value: string }) => option.value === field.value)}
        classNamePrefix="react-select"
        name={name}
        options={options}
        onChange={onChange}
      />
    </FormFieldBase>
  );
};

export default React.memo(FormSelectSearch);
