import { flatten, isEmpty, partition, values } from 'lodash';
import memoize from 'memoize-one';

export type ManufacturingProcessForCheck = Pick<ManufacturingProcess, 'name'>;

interface ManufacturabilityCheck {
  id: number;
  name: string;
  passed: boolean;
  description?: string | object;
  manufacturing_process?: ManufacturingProcessForCheck;
  suggestion?: string;
  type: string;
}

export interface ManufacturabilityChecks {
  [name: string]: Array<ManufacturabilityCheck>;
}

export const CHECKABLE_MFG_PROCESSES = ['DLS', 'SLA', 'MJF', 'CNC', 'FDM', 'Injection Molding'];

/**
 * Separate a part's manufacturability_checks object into three values:
 * names of passed check groups, names of failed check groups, and total check groups
 */
export const checkPassFail = memoize(
  (
    manufacturabilityChecks: ManufacturabilityChecks = {},
    manufacturingProcess?: ManufacturingProcessForCheck
  ): {
    manufacturabilityChecks: ManufacturabilityChecks;
    passedCheckNames: Array<string>;
    failedCheckNames: Array<string>;
    errorCheckNames: Array<string>;
    warningCheckNames: Array<string>;
    totalChecks: number;
  } => {
    const checkGroups = getChecksByProcess(manufacturabilityChecks, manufacturingProcess);
    const checkGroupNames = Object.keys(checkGroups);

    // If we don't have any checks available for the chosen manufacturing process
    // we want to return empty arrays and 0 for total count
    if (isEmpty(flatten(values(checkGroups)))) {
      return {
        manufacturabilityChecks: {},
        passedCheckNames: [],
        failedCheckNames: [],
        errorCheckNames: [],
        warningCheckNames: [],
        totalChecks: 0,
      };
    }

    const [passedCheckNames, allFailedCheckNames] = partition(checkGroupNames, name =>
      checkGroups[name]?.every(check => check.passed)
    );

    const [errorCheckNames, warningCheckNames] = partition(allFailedCheckNames, name =>
      checkGroups[name]?.find(check => !check.passed && check.type === 'error')
    );

    return {
      manufacturabilityChecks: checkGroups,
      passedCheckNames,
      failedCheckNames: [...errorCheckNames, ...warningCheckNames],
      errorCheckNames,
      warningCheckNames,
      totalChecks: checkGroupNames.length,
    };
  }
);

/**
 * Display the correct check of a group when there is both a warning
 * and error check.
 */
export const getFailedCheckToDisplay = (
  checkGroup: Array<ManufacturabilityCheck>
): ManufacturabilityCheck => {
  if (
    checkGroup.length > 1 &&
    checkGroup.every((check: ManufacturabilityCheck) => !check.passed)
  ) {
    return checkGroup.find(check => check.type === 'error');
  } else {
    return checkGroup.find(check => !check.passed);
  }
};

/**
 * Filters the available manufacturability checks by a given
 * manufacturing process
 */
const getChecksByProcess = (
  manufacturabilityChecks: ManufacturabilityChecks = {},
  manufacturingProcess?: ManufacturingProcessForCheck
): ManufacturabilityChecks => {
  if (!manufacturingProcess) return {};

  const checkGroupNames = Object.keys(manufacturabilityChecks);

  const checksForProcess = checkGroupNames.reduce((filteredChecks, checkName) => {
    filteredChecks[checkName] = manufacturabilityChecks[checkName]?.filter(
      c => c?.manufacturing_process?.name === manufacturingProcess?.name
    );
    return filteredChecks;
  }, {} as ManufacturabilityChecks);

  return Object.fromEntries(
    Object.entries(checksForProcess).filter(([_checkName, checks]) => checks.length > 0)
  );
};
