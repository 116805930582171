import { useTreatments } from '@splitsoftware/splitio-react';
import { isEmpty } from 'lodash';
import React from 'react';

import { Button, classNames } from 'fr-shared/components';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { ORDER_LINE_ITEM_STATES } from 'fr-shared/lib/orders';

import OrderLineItem from './OrderLineItem';
import styles from './OrderLineItemSelect.module.css';
import SelectedOrderLineItem from './SelectedOrderLineItem';

interface OrderLineItemSelectProps {
  isEditing: boolean;
  line_items: any;
  onRemove: (ids: number[]) => void;
  onSelect: (ids: number[]) => void;
  selectedLineItemIds: number[];
}

const OrderLineItemSelect = (props: OrderLineItemSelectProps) => {
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const capLineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'Build Pack' : 'Line Item';
  const { isEditing, line_items, onRemove, onSelect, selectedLineItemIds } = props;

  const unselectedLineItems = line_items.filter(
    (oli: any) =>
      !selectedLineItemIds.includes(oli.id) &&
      oli.state !== ORDER_LINE_ITEM_STATES.Canceled &&
      oli.state !== ORDER_LINE_ITEM_STATES.Shipped
  );
  const selectedLineItems = line_items.filter((oli: any) => selectedLineItemIds.includes(oli.id));

  const handleAddAll = () => {
    onSelect(unselectedLineItems.map((li: any) => li.id));
  };

  const handleRemoveAll = () => {
    onRemove(selectedLineItems.map((li: any) => li.id));
  };

  const hasLineItemsLeftToShip = !isEmpty(
    unselectedLineItems.filter((li: any) => li.state !== ORDER_LINE_ITEM_STATES.Shipped)
  );

  const showSelectOli = hasLineItemsLeftToShip && !isEditing;

  return (
    <>
      <div className={styles.Card}>
        {unselectedLineItems.length > 0 && (isEditing || showSelectOli) && (
          <div data-testid="unselected_oli">
            <div className={classNames([styles.CardHeader, 'card-header pt-2'])}>
              <Button
                dataTestId="new_oli_select_add_all"
                className="mr-1"
                outline
                onClick={handleAddAll}
              >
                Add All
              </Button>
            </div>
            <strong className="pl-2">Add {capLineItemName}s</strong>
            {unselectedLineItems.map((li: any) => {
              const shippedLineItem = !isEditing && li.state === ORDER_LINE_ITEM_STATES.Shipped;
              if (shippedLineItem) return null;
              return (
                <OrderLineItem key={li.id} lineItem={li} onSelect={onSelect} selected={false} />
              );
            })}
          </div>
        )}

        {selectedLineItems.length > 0 && (
          <div data-testid="selected_oli">
            {unselectedLineItems.length > 0 && (
              <div data-testid="dashed_border" className={styles.BorderDashed}></div>
            )}
            <div className={classNames([styles.CardHeader, 'card-header pt-2'])}>
              <Button
                dataTestId="new_oli_select_remove_all"
                className="mr-1"
                outline
                color="danger"
                onClick={handleRemoveAll}
              >
                Remove All
              </Button>
            </div>
            <strong className="pl-2">Select the Planned Shipments</strong>
            {selectedLineItems.map((li: any) => {
              const shippedLineItem = !isEditing && li.state === ORDER_LINE_ITEM_STATES.Shipped;
              if (shippedLineItem) return null;
              return (
                <SelectedOrderLineItem
                  key={li.id}
                  line_item={li}
                  onSelect={onRemove}
                  isEditing={isEditing}
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default OrderLineItemSelect;
