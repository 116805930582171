import { sanitizeUrl } from '@braintree/sanitize-url';
import { useField } from 'formik';
import React from 'react';

import { FormField, Link } from 'fr-shared/components';

// This likely should change once all js components migrated to tsx, but for now
// ts will complain about descructured anonomous props passed through.
type passThroughProps = { [x: string]: any };
type distinctProps = {
  showLink?: boolean;
  name?: string;
  label?: string;
};

export type FormFieldWithLinkPreviewProps = passThroughProps & distinctProps;

export const sanitizeValue = (value: string) => {
  const sanitizedValue = sanitizeUrl(value);
  // sanitize-url's default invalid response is 'about:blank' so lets make it null
  return sanitizedValue === 'about:blank' ? null : sanitizedValue;
};

const FormFieldWithLinkPreview = ({
  showLink,
  name,
  label,
  ...props
}: FormFieldWithLinkPreviewProps) => {
  const [field, _meta, helpers] = useField(name);

  const handleOnBlur = (e: any) => {
    const sanitizedValue = sanitizeValue(e.target?.value);
    helpers.setValue(sanitizedValue);
  };

  const url = sanitizeValue(field.value);

  return (
    <div>
      <FormField
        className="d-md-flex mb-0"
        name={name}
        label={label}
        onBlur={handleOnBlur}
        dataTestId={name}
        {...props}
      />
      {showLink && (
        <div className="form-group text-right d-none d-md-block">
          <Link to={{ pathname: url }} openNewWindow dataTestId={`${name}-link`}>
            {url}
          </Link>
        </div>
      )}
    </div>
  );
};

export default FormFieldWithLinkPreview;
