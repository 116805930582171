import PropTypes from 'prop-types';
import React from 'react';

import { FormNumber } from 'fr-shared/components';

const BuildTimeFormField = ({ required, labelText, ...props }) => {
  return (
    <div className="row form-group mb-0">
      <div className="col-md-4">
        <label>
          {`${labelText} `}
          {required && <span className="text-error">*</span>}{' '}
        </label>
      </div>
      <div className="col-md flex justify-content-evenly">
        <FormNumber
          name="build_time_hours"
          append="hours"
          type="number"
          className="mr-3"
          forceZero={true}
          {...props}
        />
        <FormNumber
          name="build_time_minutes"
          append="minutes"
          type="number"
          forceZero={true}
          {...props}
        />
      </div>
    </div>
  );
};

BuildTimeFormField.propTypes = {
  required: PropTypes.bool,
  labelText: PropTypes.string,
};

BuildTimeFormField.defaultProps = {
  labelText: 'Print Time',
};

export default BuildTimeFormField;
