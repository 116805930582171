import { AbstractMesh, BoundingInfo, Color3, Vector3 } from 'babylonjs';
import React, { useEffect, useState } from 'react';

import { ProgressCircle, SceneCanvas, classNames } from 'fr-shared/components';
import {
  ArcRotateCameraConfig,
  newArcRotateCameraConfig,
} from 'fr-shared/components/part-graphics/camera';
import { newHemisphericLight } from 'fr-shared/components/part-graphics/light';
import { Material, newStandardMaterial } from 'fr-shared/components/part-graphics/material';
import { MeshSource } from 'fr-shared/components/part-graphics/meshSource';
import {
  ShapeInstance,
  ShapeInstancePickablity,
  newRotateAboutAxes,
} from 'fr-shared/components/part-graphics/shapeInstance';
import { usePortalSubscription } from 'fr-shared/hooks';

import { PartFileRevision } from 'portal/lib/cart';
import { subsumesPartMesh } from 'portal/lib/manufacturability_check';

import styles from './DfmViewer.module.css';
import DfmViewerControls from './DfmViewerControls';
import DfmViewerDetails from './DfmViewerDetails';

type Props = {
  /** Custom controls to add to viewer */
  customControls?: React.ReactNode;
  /** Custom className for the background and canvas containers */
  className?: string;
  /** PartFileRevision to get data from */
  partFileRevision: PartFileRevision;
  /** Identifies the manufacturability check mesh to show. */
  selectedDfmCheck?: ManufacturabilityCheck | null;
  /** Toggles the controls (Solid/xRay/Reset Camera) */
  showControls?: boolean;
  /** Toggles the part summary details */
  showDetails?: boolean;
};

const DfmViewer = ({
  customControls,
  className = '',
  partFileRevision,
  selectedDfmCheck,
  showDetails,
  showControls,
}: Props) => {
  const [cameraConfig, resetCamera, setCanvasSize, setPartBoundingSphere] = useCameraConfig();
  const [partMaterial, setPartMaterial] = useState(
    selectedDfmCheck ? xRayPartMaterial : solidPartMaterial
  );
  const [meshSourceIsLoading, setMeshSourceIsLoading] = useState(true);
  const isPortalSubscribed = usePortalSubscription();

  /**
   * URL of the part mesh. This mesh will be loaded into the scene and will
   * always be visible.
   */
  const partMeshUrl = partFileRevision.stl_url;
  /**
   * An array of OBJ files, one for each manufacturing process for which the
   * part has failing manufacturability checks. Each OBJ file contains a submesh
   * for each failed manufacturability check for the part and the associated
   * manufacturing process.
   */
  const manufacturabilityCheckFiles = partFileRevision.manufacturability_check_files;

  /**
   * When a new DFM check is selected automatically switch to the X-ray
   * material. If the DFM check is deselected automatically switch to the solid
   * material.
   */
  useEffect(() => {
    if (selectedDfmCheck && !subsumesPartMesh(selectedDfmCheck)) {
      // A DFM check has been selected and the DFM check mesh is smaller than
      // the part mesh, so render the part as transparent so it is easier to see
      // the DFM check mesh.
      setPartMaterial(xRayPartMaterial);
    } else {
      // Either (a) a DFM check has been unselected or (b) a DFM check has been
      // selected whose mesh will subsume the part mesh. In the case of (a) we
      // render the part as solid since that is the default view of the part. In
      // the case of (b) we render the part as solid so that it's easier to see
      // the part inside the larger and transparent DFM check mesh.
      setPartMaterial(solidPartMaterial);
    }
  }, [selectedDfmCheck]);

  return (
    <div>
      <div className={classNames([styles.DfmViewerBackground, className])} />
      {showDetails && <DfmViewerDetails partFileRevision={partFileRevision} />}
      {meshSourceIsLoading && (
        <div className="absolute z-50 h-full w-full" data-testid="loading">
          <ProgressCircle
            percentage={75}
            spinner={true}
            backgroundColor="#192343"
            className="m-auto inset-1/2 absolute"
          />
        </div>
      )}

      <SceneCanvas
        cameraConfig={cameraConfig}
        lights={[
          newHemisphericLight('light', 0.6, new Vector3(-0.25, 1, 0)),
          newHemisphericLight('light', 0.4, new Vector3(0, -1, 0)),
        ]}
        meshSources={meshSources(
          partMeshUrl,
          mesh => {
            setPartBoundingSphere(newBoundingSphere(mesh.getBoundingInfo()));
          },
          manufacturabilityCheckFiles
        )}
        shapeInstances={shapeInstances(
          isPortalSubscribed,
          partMeshUrl,
          partMaterial,
          selectedDfmCheck,
          manufacturabilityCheckFiles
        )}
        onResize={(width, height) => setCanvasSize({ width: width, height: height })}
        className={classNames([styles.DfmCanvas, className])}
        setMeshSourceIsLoading={setMeshSourceIsLoading}
      />
      {showControls && (
        <DfmViewerControls
          partMaterial={partMaterial}
          setPartMaterial={setPartMaterial}
          resetCamera={resetCamera}
        >
          {customControls}
        </DfmViewerControls>
      )}
    </div>
  );
};

export default DfmViewer;

/**
 * A bounding sphere around a mesh.
 *
 * We use this type rather than {@link babylonjs.BoundingSphere} since a
 * BabylonJS bounding sphere's `centerWorld` can be mutated by Babylon when the
 * camera moves. (This seems like a bug in Babylon.)
 */
type BoundingSphere = {
  /** The center of the bounding sphere in world space. */
  center: Vector3;
  /** The center of the bounding sphere in world space. */
  radius: number;
};

/**
 * Returns a new bounding sphere.
 */
const newBoundingSphere = (boundingInfo: BoundingInfo): BoundingSphere => {
  // Construct a new vector for the center since Babylon may mutate the
  // `centerWorld` property when the camera moves. (This seems like a bug in
  // Babylon.)
  const { x, y, z } = boundingInfo.boundingSphere.centerWorld;
  const center = new Vector3(x, y, z);
  return {
    center: center,
    radius: boundingInfo.boundingSphere.radiusWorld,
  };
};

/**
 * A hook for managing and updating the camera config that is passed into the
 * `SceneCanvas`.
 *
 * Returns the following:
 * - the most recent configuration for the camera in the "home" position
 * - a function that generates a new "home" configuration for the camera, based
 *   off the current canvas size and part bounding sphere
 * - a function that updates the current canvas size state
 * - a function that updates the current part bounding sphere state
 */
const useCameraConfig = () => {
  const [canvasSize, setCanvasSize] = useState(null);
  const [partBoundingSphere, setPartBoundingSphere] = useState(null);
  const [cameraConfig, setCameraConfig] = useState(makeInitCameraConfig());

  // Resets the scene's camera by generating a new `cameraConfig` state. Each
  // new value of `cameraConfig` is different from the previous state (even if
  // partBoundingSphere and canvasSize are unchanged) since each value will have
  // a unique `moveCameraIndex`. The new camera config will force `SceneCanvas`
  // to update it's current camera position.
  const manuallyResetCamera = () => {
    setCameraConfig(
      makeHomeCameraConfig(partBoundingSphere, canvasSize?.width, canvasSize?.height)
    );
  };

  // If part bounding sphere and canvas size have loaded and the camera hasn't
  // been set out of its initial state, then reset the camera. This will happen
  // only once, near the beginning of the `SceneCanvas` lifecycle.
  useEffect(() => {
    if (isInitCamera(cameraConfig) && partBoundingSphere && canvasSize) {
      setCameraConfig(
        makeHomeCameraConfig(partBoundingSphere, canvasSize?.width, canvasSize?.height)
      );
    }
  }, [partBoundingSphere, canvasSize, cameraConfig]);

  return [cameraConfig, manuallyResetCamera, setCanvasSize, setPartBoundingSphere] as const;
};

/**
 * Returns an initial configuration for the scene's camera before the part and
 * canvas size have been loaded.
 */
const makeInitCameraConfig = () => {
  return newArcRotateCameraConfig(0, 0, 0, new Vector3(0, 0, 0), 0.8);
};

/**
 * Returns true if the camera config is in the initial camera config (its
 * location before the part and canvas size have been loaded).
 */
const isInitCamera = (config: ArcRotateCameraConfig): boolean => {
  return config.radius === 0;
};

/**
 * Returns a new configuration for the scene's camera in the "home" position.
 *
 * This function is not pure. Calling it twice with the same arguments will
 * return `ArcRotateCameraConfig`s that each have a different values for
 * `moveCameraIndex`.
 */
const makeHomeCameraConfig = (
  partBoundingSphere: BoundingSphere,
  canvasWidth: number,
  canvasHeight: number
): ArcRotateCameraConfig => {
  let alpha = (Math.PI * 5.0) / 4.0;
  let beta = Math.PI / 4.0;
  let fovY = 0.8;
  let target = partBoundingSphere.center;
  let radius = homeCameraRadius(partBoundingSphere.radius, canvasWidth, canvasHeight, fovY);
  return newArcRotateCameraConfig(alpha, beta, radius, target, fovY);
};

/**
 * Returns radius of the scene's camera in the "home" position.
 */
const homeCameraRadius = (
  partBoundingSphereRadius: number,
  canvasWidth: number,
  canvasHeight: number,
  fovY: number
): number => {
  let aspectRatio = canvasWidth / canvasHeight;
  let halfMinFov = fovY / 2;
  if (aspectRatio < 1) {
    halfMinFov = Math.atan(aspectRatio * Math.tan(fovY / 2));
  }
  return Math.abs(partBoundingSphereRadius / Math.sin(halfMinFov));
};

/**
 * Return the mesh sources for the scene.
 */
const meshSources = (
  partMeshUrl: string,
  onPartLoadSuccess: (partMesh: AbstractMesh) => void,
  manufacturabilityCheckMeshFiles: ManufacturabilityCheckFile[]
): MeshSource[] => {
  let meshes = partMeshSource(partMeshUrl, onPartLoadSuccess);
  return (meshes = manufacturabilityCheckMeshFiles
    ? meshes.concat(manufacturabilityCheckMeshFiles?.map(manufacturabilityCheckMeshSource))
    : meshes);
};

/**
 * Return the mesh source for the part mesh.
 */
const partMeshSource = (
  partMeshUrl: string,
  onPartLoadSuccess: (mesh: AbstractMesh) => void
): MeshSource[] => {
  if (!partMeshUrl) {
    return [];
  }
  return [
    {
      url: partMeshUrl,
      onLoadSuccess: (meshes: AbstractMesh[]) => {
        if (meshes.length > 0) {
          onPartLoadSuccess(meshes[0]);
        }
      },
    },
  ];
};

/**
 * Return the mesh source for the given manufacturability check file.
 */
const manufacturabilityCheckMeshSource = (file: ManufacturabilityCheckFile): MeshSource => {
  return {
    url: file.s3_path,
  };
};

/**
 * An opaque material for the part.
 */
export const solidPartMaterial = newStandardMaterial(
  new Color3(0.7, 0.7, 0.7),
  new Color3(0.15, 0.15, 0.15),
  Color3.Black(),
  Color3.Black(),
  1.0
);

/**
 * A transparent material for the part.
 */
export const xRayPartMaterial = newStandardMaterial(
  new Color3(0.7, 0.7, 0.7),
  new Color3(0.15, 0.15, 0.15),
  Color3.Black(),
  Color3.Black(),
  0.5
);

/**
 * Return the shape instances for the scene.
 */
const shapeInstances = (
  isPortalSubscribed: boolean,
  partMeshUrl: string,
  partMaterial: Material,
  manufacturabilityCheck: ManufacturabilityCheck | null,
  manufacturabilityCheckFiles: ManufacturabilityCheckFile[]
): ShapeInstance[] => {
  let partShape = partShapeInstance(partMeshUrl, partMaterial);
  if (!manufacturabilityCheck || (!isPortalSubscribed && manufacturabilityCheck.premium)) {
    return [partShape];
  }

  let checkShape = manufacturabilityCheckShapeInstance(
    manufacturabilityCheck,
    manufacturabilityCheckFiles
  );
  if (checkShape === null) {
    return [partShape];
  }

  return [partShape, checkShape];
};

/**
 * Return the shape instance for the part mesh.
 */
const partShapeInstance = (partMeshUrl: string, partMaterial: Material): ShapeInstance => {
  return {
    id: `shape-instance/${partMeshUrl}`,
    isVisible: true,
    pickability: ShapeInstancePickablity.None,
    sourceUrl: partMeshUrl,
    position: new Vector3(0, 0, 0),
    rotation: newRotateAboutAxes(new Vector3(0, 0, 0)),
    scaling: new Vector3(1, 1, 1),
    material: partMaterial,
  };
};

/**
 * Return the shape instance for the given manufacturability check.
 */
const manufacturabilityCheckShapeInstance = (
  check: ManufacturabilityCheck,
  files: ManufacturabilityCheckFile[]
): ShapeInstance | null => {
  if (check.passed) {
    return null;
  }

  let file = files.find(f => f.manufacturing_process_id === check.manufacturing_process_id);
  if (file === null || file === undefined) {
    return null;
  }

  return {
    // The ID isn't refrenced anywhere else, but it should be unique for each
    // shape instance.
    id: `shape-instance/${file.s3_path}/${check.name}`,
    isVisible: true,
    pickability: ShapeInstancePickablity.None,
    sourceUrl: file.s3_path,
    // Each submesh in the OBJ file has a name corresponding to a
    // manufacturability check name.
    sourceSubmeshName: check.name,
    position: new Vector3(0, 0, 0),
    rotation: newRotateAboutAxes(new Vector3(0, 0, 0)),
    scaling: new Vector3(1, 1, 1),
    material: manufacturabilityCheckMaterial(check),
  };
};

/**
 * Returns the material used to render a mesh for the given manufacturability
 * check.
 */
const manufacturabilityCheckMaterial = (check: ManufacturabilityCheck) => {
  let alpha = subsumesPartMesh(check) ? 0.5 : 1.0;
  return check.type === 'error' ? errorMaterial(alpha) : warningMaterial(alpha);
};

/**
 * Returns the material used to render an error manufacturability check mesh.
 */
const errorMaterial = (alpha: number) => {
  const red = new Color3(0.69, 0.03, 0.003);
  return newStandardMaterial(red, red, red, red, alpha);
};

/**
 * Returns the material used to render a warning manufacturability check mesh.
 */
const warningMaterial = (alpha: number) => {
  const yellow = new Color3(1.0, 0.647, 0.129);
  return newStandardMaterial(yellow, yellow, yellow, yellow, alpha);
};
