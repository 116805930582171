import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { Card, FormField, FormSelect } from 'fr-shared/components';
import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';
import { WORK_ORDER_STATES, hasProcess } from 'fr-shared/lib/work_orders';

import WorkOrderDetailsBMD from './WorkOrderDetailsBMD';
import WorkOrderDetailsCNC from './WorkOrderDetailsCNC';
import WorkOrderDetailsDLS from './WorkOrderDetailsDLS';
import WorkOrderDetailsFDM from './WorkOrderDetailsFDM';
import WorkOrderDetailsMJF from './WorkOrderDetailsMJF';
import WorkOrderDetailsSLA from './WorkOrderDetailsSLA';

const WorkOrderDetails = ({ isEditing, readonly, isRework }) => {
  const formik = useFormikContext();

  let DetailsComponent = null;

  const hasMJFProcess = hasProcess(formik.values, MANUFACTURING_PROCESSES.MJF);
  const hasMFJColorProcess = hasProcess(formik.values, MANUFACTURING_PROCESSES.MJFColor);
  const hasDLSProcess = hasProcess(formik.values, MANUFACTURING_PROCESSES.DLS);
  const hasFDMProcess = hasProcess(formik.values, MANUFACTURING_PROCESSES.FDM);
  const hasSLAProcess = hasProcess(formik.values, MANUFACTURING_PROCESSES.SLA);
  const hasBoundMetalDepositionProcess = hasProcess(
    formik.values,
    MANUFACTURING_PROCESSES.BoundMetalDeposition
  );
  const hasCNCProcess = hasProcess(formik.values, MANUFACTURING_PROCESSES.CNC);

  if (hasMJFProcess || hasMFJColorProcess) DetailsComponent = WorkOrderDetailsMJF;
  else if (hasDLSProcess) DetailsComponent = WorkOrderDetailsDLS;
  else if (hasFDMProcess) DetailsComponent = WorkOrderDetailsFDM;
  else if (hasSLAProcess) DetailsComponent = WorkOrderDetailsSLA;
  else if (hasBoundMetalDepositionProcess) DetailsComponent = WorkOrderDetailsBMD;
  else if (hasCNCProcess) DetailsComponent = WorkOrderDetailsCNC;

  return (
    <Card className="mb-4" border={true}>
      <Card.Header>Work Order Details</Card.Header>
      {isRework ? (
        <Card.Body>
          <div className="row">
            <div className="col-lg">
              <FormSelect
                name="state"
                label="State"
                readonly={readonly}
                optionList={WORK_ORDER_STATES}
                keyAccessor={option => option}
                valueAccessor={option => option}
                nameAccessor={option => option}
              />
            </div>
            <div className="col-lg">
              <FormField
                name="notes"
                label="Rework Instructions"
                className="mb-0"
                readonly={readonly}
              />
            </div>
          </div>
        </Card.Body>
      ) : (
        DetailsComponent && <DetailsComponent isEditing={isEditing} readonly={readonly} />
      )}
    </Card>
  );
};

WorkOrderDetails.propTypes = {
  isEditing: PropTypes.bool,
  isRework: PropTypes.bool,
  readonly: PropTypes.bool,
  formik: PropTypes.object,
};

export default WorkOrderDetails;
