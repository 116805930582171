import { uniqBy } from 'lodash';

import { PERMISSIONS } from 'fr-shared/lib/permissions';
import { ROLES } from 'fr-shared/lib/roles';

import { ORDER_APPROVED_STATES, ORDER_DRAFT_STATES } from './orders';

export const getUserShortcuts = user => {
  const roleShortcuts = {
    isAE: hasRole(user, ROLES.ApplicationEngineering),
    isAdmin: hasRole(user, ROLES.Admin),
    isFR: hasRole(user, ROLES.FastRadius),
    isOps: hasRole(user, ROLES.Operations),
    isOpsCM: hasRole(user, ROLES.OperationsCostManagement),
    isOpsPM: hasRole(user, ROLES.OperationsProjectManagement),
    isSales: hasRole(user, ROLES.Sales),
    canPortal: hasRole(user, ROLES.Portal),
    isSupplierPortalUser: hasRole(user, ROLES.SupplierPortalUser),
    isSupplierPortalSupport: hasRole(user, ROLES.SupplierPortalSupport),
    isCustomerPortalOrgAccessUser: hasRole(user, ROLES.CustomerPortalOrgAccessUser),
    canArchiveCostingRequests: hasPermission(user, PERMISSIONS.ArchiveCostingRequests),
    canCreateRejectionReasons: hasPermission(user, PERMISSIONS.CreateRejectionReasons),
    canUpdateRejectionReasons: hasPermission(user, PERMISSIONS.UpdateRejectionReasons),
    canCreateSuppliers: hasPermission(user, PERMISSIONS.CreateSuppliers),
    canUpdateSuppliers: hasPermission(user, PERMISSIONS.UpdateSuppliers),
    canCreateLeadTimes: hasPermission(user, PERMISSIONS.CreateLeadTimes),
    canUpdateLeadTimes: hasPermission(user, PERMISSIONS.UpdateLeadTimes),
    canCreateOrganizations: hasPermission(user, PERMISSIONS.CreateOrganizations),
    canDeleteOrganizations: hasPermission(user, PERMISSIONS.DeleteOrganizations),
    canCreateSupplierPurchaseOrders: hasPermission(
      user,
      PERMISSIONS.CreateSupplierPurchaseOrders
    ),
    canCreateMaterials: hasPermission(user, PERMISSIONS.CreateMaterials),
    canCreateBillableServiceLineItems: hasPermission(
      user,
      PERMISSIONS.CreateBillableServiceLineItems
    ),
    canCreateOrders: hasPermission(user, PERMISSIONS.CreateOrders),
    canApproveOrRejectOrders: hasPermission(user, PERMISSIONS.ApproveOrRejectOrders),
    canEditDraftOrders: hasPermission(user, PERMISSIONS.EditDraftOrders),
    canEditApprovedOrders: hasPermission(user, PERMISSIONS.EditApprovedOrders),
    canEditIncoterm: hasPermission(user, PERMISSIONS.EditIncoterm),
    canEditShipment: hasPermission(user, PERMISSIONS.EditShipment),
    canDeleteShipment: hasPermission(user, PERMISSIONS.DeleteShipment),
    canViewDraftOrders: hasPermission(user, PERMISSIONS.ViewDraftOrders),
    canDuplicateOrders: hasPermission(user, PERMISSIONS.DuplicateOrders),
    canSendOrderAlerts: hasPermission(user, PERMISSIONS.SendOrderAlerts),
    canViewCreatedWorkOrders: hasPermission(user, PERMISSIONS.ViewCreatedWorkOrders),
    canViewScheduledWorkOrders: hasPermission(user, PERMISSIONS.ViewScheduledWorkOrders),
    canDuplicateWorkOrders: hasPermission(user, PERMISSIONS.DuplicateWorkOrders),
    canUpdateWorkOrders: hasPermission(user, PERMISSIONS.UpdateWorkOrders),
    canDeleteWorkOrders: hasPermission(user, PERMISSIONS.DeleteWorkOrders),
    canCreateQuotes: hasPermission(user, PERMISSIONS.CreateQuotes),
    canCreateCostingRequests: hasPermission(user, PERMISSIONS.CreateCostingRequests),
    canDuplicateAndAddLineItemsToCostingRequests: hasPermission(
      user,
      PERMISSIONS.DuplicateAndAddLineItemsToCostingRequests
    ),
    canViewDraftCostingRequests: hasPermission(user, PERMISSIONS.ViewDraftCostingRequests),
    canConvertCostingRequestsToQuotes: hasPermission(
      user,
      PERMISSIONS.ConvertCostingRequestsToQuotes
    ),
    canUpdateDraftOrRequestedCostingRequestLineItems: hasPermission(
      user,
      PERMISSIONS.UpdateDraftOrRequestedCostingRequestLineItems
    ),
    canUpdateSubmittedCostingRequestLineItems: hasPermission(
      user,
      PERMISSIONS.UpdateSubmittedCostingRequestLineItems
    ),
    canCloseCostingRequestLineItems: hasPermission(
      user,
      PERMISSIONS.CloseCostingRequestLineItems
    ),
    canToggleCostingRequestLineItemInProgress: hasPermission(
      user,
      PERMISSIONS.ToggleCostingRequestLineItemsInProgress
    ),
    canSubmitCosts: hasPermission(user, PERMISSIONS.SubmitCosts),
    canRejectCosts: hasPermission(user, PERMISSIONS.RejectCosts),
    canImportCosts: hasPermission(user, PERMISSIONS.ImportCosts),
    canViewQC: hasPermission(user, PERMISSIONS.ViewQualityControl),
    canRequestCustomerCreditCard: hasPermission(user, PERMISSIONS.GenerateCustomerCreditCardLink),
    canSendCostingEmail: hasPermission(user, PERMISSIONS.CanSendCostingEmail),
    canSendQuoteAcceptanceEmail: hasPermission(user, PERMISSIONS.canSendQuoteAcceptanceEmail),
    canViewManufacturabilityChecks: hasPermission(user, PERMISSIONS.ViewManufacturabilityChecks),
    canDeleteWebhooks: hasPermission(user, PERMISSIONS.DeleteWebhooks),
    canViewWebhooks: hasPermission(user, PERMISSIONS.ViewWebhooks),
    canCreatePrinters: hasPermission(user, PERMISSIONS.CreatePrinters),
    canUpdatePrinters: hasPermission(user, PERMISSIONS.UpdatePrinters),
    canDeletePrinters: hasPermission(user, PERMISSIONS.DeletePrinters),
    canViewSupplierRFQs: hasPermission(user, PERMISSIONS.ViewSupplierRFQs),
    canEditSupplierRFQs: hasPermission(user, PERMISSIONS.EditSupplierRFQs),
    canViewCustomerPartsForOrg: hasPermission(user, PERMISSIONS.ViewCustomerPartsForOrg),
    canEditCustomerPartsForOrg: hasPermission(user, PERMISSIONS.EditCustomerPartsForOrg),
    canViewCustomerQuotesForOrg: hasPermission(user, PERMISSIONS.ViewCustomerQuotesForOrg),
    canViewCustomerOrdersForOrg: hasPermission(user, PERMISSIONS.ViewCustomerOrdersForOrg),
    canEditMargins: hasPermission(user, PERMISSIONS.EditMargins),
    canConvertCustomerCartToCostingRequest: hasPermission(
      user,
      PERMISSIONS.ConvertCustomerCartToCostingRequest
    ),
    canViewSalesPortal: hasPermission(user, PERMISSIONS.ViewSalesPortal),
  };
  return { ...user, ...roleShortcuts };
};

export const hasRole = (user, roleName) => {
  return !!user?.roles?.find(r => r.name === roleName);
};

export const hasPermission = (user, permissionName) => {
  return (
    hasPermissionThroughRole(user, permissionName) || hasPermissionDirectly(user, permissionName)
  );
};

export const hasPermissionThroughRole = (user, permissionName) => {
  return !!user?.permissions_granted_through_roles?.find(p => p.name === permissionName);
};

export const hasPermissionDirectly = (user, permissionName) => {
  return !!user?.permissions_granted_directly?.find(p => p.name === permissionName);
};

export const getUsersFromUserRoles = userRoles => {
  return uniqBy(userRoles, userRole => userRole.user.name).map(userRole => ({
    ...userRole.user,
  }));
};

export const userCanEditOrder = (user, order) => {
  const userWithShortcuts = getUserShortcuts(user);
  if (ORDER_DRAFT_STATES.includes(order?.state)) {
    return userWithShortcuts.canEditDraftOrders;
  } else if (ORDER_APPROVED_STATES.includes(order?.state)) {
    return userWithShortcuts.canEditApprovedOrders;
  } else {
    return false;
  }
};

export const userIsCostingTeam = user => {
  const userWithShortcuts = getUserShortcuts(user);
  return (
    userWithShortcuts.canToggleCostingRequestLineItemInProgress &&
    userWithShortcuts.canSubmitCosts &&
    userWithShortcuts.canUpdateSubmittedCostingRequestLineItems &&
    userWithShortcuts.canRejectCosts
  );
};
