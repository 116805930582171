import { Formik } from 'formik';
import React, { useEffect } from 'react';

import { useUserAnalyticsContext } from 'fr-shared/context';

import OnboardingModal from '../Modal/Modal';
import StudioUploadContent from './StudioUploadContent';

const StudioUploadModal = () => {
  const userAnalytics = useUserAnalyticsContext();

  /**
   * Track an event when the modal is shown for the first time.
   */
  useEffect(() => {
    userAnalytics.track('Onboarding Modal Studio - Shown');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OnboardingModal
      content={
        <Formik initialValues={{ units_in_mm: true }} onSubmit={() => {}}>
          <StudioUploadContent />
        </Formik>
      }
      contentClassname="flex flex-row align-items-stretch"
      currentImageSrc="https://os.fastradius.com/images/product-shots-product-studio.jpg"
      onClose={closedByButton =>
        userAnalytics.track('Onboarding Modal Studio - Dismissed', { closedByButton })
      }
    />
  );
};

export default StudioUploadModal;
