import { connect } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { FormField, FormMultiSelect } from 'fr-shared/components';
import { useResubmissionReasons } from 'fr-shared/hooks';
import { isFormikAtPrefixTheSame } from 'fr-shared/utils';

const fromModelToSelect = ({ description, id }) => ({
  label: description,
  value: id,
});

const fromSelectToModel = ({ label, value }) => ({
  description: label,
  id: value,
});

const CostingLineItemResubmissionReasons = ({ fieldPrefix, formik, isDuplicating, readonly }) => {
  const { data: resubmissionReasons, isFetched } = useResubmissionReasons();
  const [resubmissionReasonOptions, setResubmissionReasonOptions] = useState([]);
  const [value, setValue] = useState([]);

  const name = `${fieldPrefix}.resubmission_reasons`;
  const error = get(formik.errors, name);

  useEffect(() => {
    if (isFetched) {
      setResubmissionReasonOptions(resubmissionReasons.map(fromModelToSelect));
    }
  }, [isFetched, resubmissionReasons]);

  const rawValue = get(formik.values, name);
  useEffect(() => {
    rawValue && setValue(rawValue.map(fromModelToSelect));
  }, [rawValue]);

  const handleChange = useCallback(
    selections => {
      formik.setFieldValue(name, (selections || []).map(fromSelectToModel));
    },
    // Don't check formik because the higher order component function `connect` makes shallowEquals comparisons
    // to the formik object return false.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name]
  );

  const hasSelectedValues = value.length > 0;
  const isOtherSelected = hasSelectedValues && value.map(rr => rr.description).includes('Other');

  if (hasSelectedValues || isDuplicating) {
    return (
      <>
        <FormMultiSelect
          error={error}
          isRequired
          label="Reason to Resubmit"
          name={name}
          onChange={handleChange}
          options={resubmissionReasonOptions}
          placeholder="Select from list"
          readonly={readonly || !isDuplicating}
          value={value}
        />
        <FormField
          component="textarea"
          inputStyle={{ height: 65, minHeight: 65 }}
          deleteTestId={`remove-resubmission-notes-${fieldPrefix}`}
          required={isOtherSelected}
          label="Resubmission Notes"
          name={`${fieldPrefix}.resubmission_notes`}
          readonly={readonly || !isDuplicating}
        />
      </>
    );
  }

  return null;
};

CostingLineItemResubmissionReasons.propTypes = {
  fieldPrefix: PropTypes.string,
  formik: PropTypes.object.isRequired,
  isDuplicating: PropTypes.bool,
  labelClassName: PropTypes.string,
  readonly: PropTypes.bool,
};

export default connect(React.memo(CostingLineItemResubmissionReasons, isFormikAtPrefixTheSame));
