import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import { Card, FormNumber, FormRadioButtons, Icon } from 'fr-shared/components';
import { shippingMethodToDisplayName } from 'fr-shared/lib/shipping';

import { QuoteFormContext } from './QuoteFormContext';
import QuoteFormMenuQuoteQuantity from './QuoteFormMenuQuoteQuantity';

const MENU_QUOTE_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

const MENU_QUOTE_TOOLTIP = `
Menu quote should be set to “Yes” if the quote value is significantly higher than the amount expected to close.
This may be the case if your quote includes options across multiple quantities, materials, and/or processes that we do not expect the customer to purchase.
Your selection will directly affect your pipeline in Salesforce.
`;

const QuoteFormLineCost = ({ readonly }) => {
  const { setValuesAndPushToChannel } = useContext(QuoteFormContext);
  const formik = useFormikContext();

  const blendedMargin = formik.values?.blended_margin_percentage;
  const subTotalPrice = formik.values?.sub_total_price?.formatted;
  const grossProfit = formik.values?.gross_profit;
  const discountedAmount = formik.values?.discount_amount?.formatted;
  const portalShippingPrice = formik.values?.shipping_price?.formatted;
  const hasMenuQuote = formik.values?.menu_quote;
  const menuQuoteQuantityOptions = formik.values?.menu_quote_quantity_options ?? [];
  const shouldDisplayOneMenuQuoteOption =
    menuQuoteQuantityOptions && menuQuoteQuantityOptions.length > 1
      ? menuQuoteQuantityOptions[0]?.formatted === menuQuoteQuantityOptions[1]?.formatted
      : false;
  const selectedQuoteQuantityOption = formik.values?.menu_quote_quantity_option;
  const estimatedDutiesTaxes = formik.values?.estimated_duties_taxes;

  const {
    price: { formatted: selectedShippingEstimatePrice } = {},
    service: selectedShippingEstimateService,
  } = formik.values?.selected_shipping_estimate ?? {};
  const totalPrice = formik.values?.total_price?.formatted;
  const shippingServiceDisplayName = shippingMethodToDisplayName(selectedShippingEstimateService);
  const sortedMenuQuoteQuantityOptions = menuQuoteQuantityOptions?.sort(
    (a, b) => a?.amount - b?.amount
  );

  const onInputSelect = value => {
    formik.setFieldValue('menu_quote_quantity_option', value);
  };

  useEffect(() => {
    if (hasMenuQuote === 'false') {
      formik.setFieldValue('menu_quote_quantity_option', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMenuQuote, selectedQuoteQuantityOption]);

  return (
    <Card>
      <Card.Header>
        <span className="flex-grow-1">Totals</span>
        <small className="mr-4">
          <span className="text-muted">Blended Margin</span>{' '}
          <strong>{blendedMargin === null ? '----' : `${blendedMargin}%`}</strong>
        </small>
        <small>
          <span className="text-muted">Gross Profit</span>{' '}
          <strong>{grossProfit?.formatted ?? '----'}</strong>
        </small>
      </Card.Header>
      <Card.Body>
        <div className="row mb-1">
          <div className="col-7 flex align-items-center">Sub Total</div>
          <div className="col flex align-items-center justify-content-end font-size-lg">
            <strong>{subTotalPrice || '--'}</strong>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-7 flex align-items-center">
            <FormNumber
              append="%"
              className="align-items-center mb-0"
              inputGroupClassName="input-group-sm"
              inputStyle={{ maxWidth: 90 }}
              label="Discount"
              labelClassName="font-size-base mr-3"
              max={100}
              min={1}
              name="discount_percentage"
              onBlur={() => setValuesAndPushToChannel(formik.values)}
              readonly={readonly}
              type="number"
            />
          </div>
          <div className="col flex align-items-center justify-content-end font-size-lg">
            <strong>{discountedAmount || '--'}</strong>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-7 flex align-items-center">
            Shipping
            {shippingServiceDisplayName && (
              <span className="text-muted ml-1">({shippingServiceDisplayName})</span>
            )}
          </div>
          <div className="col flex align-items-center justify-content-end font-size-lg">
            <strong>{portalShippingPrice || selectedShippingEstimatePrice || '--'}</strong>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-7 flex align-items-center">Estimated Duties and Taxes</div>
          <div className="col flex align-items-center justify-content-end font-size-lg">
            <strong>{estimatedDutiesTaxes?.formatted || '--'}</strong>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-7 flex align-items-center">Grand Total</div>
          <div className="col flex align-items-center justify-content-end font-size-lg">
            <strong>{totalPrice || '--'}</strong>
          </div>
        </div>
        <div className="row">
          <div className="col-7">
            <label htmlFor="menu_quote">Menu Quote</label>
            {!readonly && <span className="text-error">*</span>}
            <Icon
              name="question-circle"
              left
              className="text-primary-link"
              data-rh={MENU_QUOTE_TOOLTIP}
            />
          </div>
          <div className="col flex justify-content-end font-size-lg">
            <FormRadioButtons
              name="menu_quote"
              options={MENU_QUOTE_OPTIONS}
              readonly={readonly}
            />
          </div>
        </div>
        {hasMenuQuote === 'true' && !readonly && (
          <QuoteFormMenuQuoteQuantity
            onClick={onInputSelect}
            menuQuoteQuantityOptions={sortedMenuQuoteQuantityOptions}
            selectedQuoteQuantityOption={selectedQuoteQuantityOption}
            shouldDisplayOneMenuQuoteOption={shouldDisplayOneMenuQuoteOption}
          />
        )}
        {hasMenuQuote === 'true' && readonly && (
          <div className="row mb-1">
            <div className="col-7 flex align-items-cente">
              Estimated quote value
              {shouldDisplayOneMenuQuoteOption || !selectedQuoteQuantityOption
                ? null
                : sortedMenuQuoteQuantityOptions[0]?.formatted ===
                  selectedQuoteQuantityOption?.formatted
                ? ' (Low quantity)'
                : sortedMenuQuoteQuantityOptions[2]?.formatted ===
                  selectedQuoteQuantityOption?.formatted
                ? ' (Max quantity)'
                : ' (Median quantity)'}
            </div>

            <div className="col flex align-items-center justify-content-end font-size-lg">
              <strong>{selectedQuoteQuantityOption?.formatted}</strong>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

QuoteFormLineCost.propTypes = {
  readonly: PropTypes.bool,
};

export default QuoteFormLineCost;
