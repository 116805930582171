import { useTreatments } from '@splitsoftware/splitio-react';
import { useFormikContext } from 'formik';
import { cloneDeep, get } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { AlertBadge, Button, Icon } from 'fr-shared/components';
import { AlertContext, UserContext, withAlert } from 'fr-shared/context';
import { CR_BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { COSTING_REQUEST_LI_STATES } from 'fr-shared/lib/costing_requests';
import { userIsCostingTeam } from 'fr-shared/lib/user_roles';

export const EditQuantitiesButtons = ({
  fieldPrefix,
  initialSupplierCosts,
  isCustomerPortalAutoquote,
  isEditing,
  onSave,
}) => {
  const formik = useFormikContext();
  const { setAlert, closeAlert } = useContext(AlertContext);
  const { [CR_BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );
  const lineItemText = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';

  const lineItem = get(formik.values, fieldPrefix, null);
  const isEditingPartInfo = get(
    formik.values,
    `${fieldPrefix}.is_editing_part_information`,
    false
  );

  const isEditingQuantities = get(formik.values, `${fieldPrefix}.is_editing_quantities`, false);

  const enterEditQuantitiesMode = () => {
    closeAlert();
    formik.setFieldValue(`${fieldPrefix}.trying_to_edit_quantities`, false);
    formik.setFieldValue(`${fieldPrefix}.is_editing_quantities`, true);
  };

  const handleClick = () => {
    if (isEditingPartInfo) {
      formik.setFieldValue(`${fieldPrefix}.trying_to_edit_quantities`, true);
    } else {
      closeAlert();
      formik.setFieldValue(`${fieldPrefix}.action_error_message`, null);
      enterEditQuantitiesMode();
    }
  };

  const exitEditQuantitiesMode = () => {
    formik.setFieldValue(`${fieldPrefix}.is_editing_quantities`, false);
    formik.setFieldValue(`${fieldPrefix}.action_error_message`, null);
    closeAlert();
  };

  const resetSupplierCosts = () => {
    formik.setErrors({});
    formik.setFieldValue(`${fieldPrefix}.supplier_costs`, initialSupplierCosts);
  };

  const handleCancel = () => {
    resetSupplierCosts();
    exitEditQuantitiesMode();
    formik.setFieldValue(`${fieldPrefix}.action_error_message`, null);
  };

  const handleSave = async () => {
    try {
      await onSave();
      exitEditQuantitiesMode();
      setAlert({ color: 'success', message: 'Saved!' });
    } catch (error) {
      setAlert({
        color: 'danger',
        message: error?.response?.data?.messages?.[0] ?? 'Failed to save',
      });
    }
  };

  /**
   * If the user is currently editing build pack details, and they click "Edit Quantities",
   * we prevent them from editing both sections at once by prompting them to finish
   * editing the section they are currently editing.
   */
  const tryingToEditQuantities = get(formik.values, `${fieldPrefix}.trying_to_edit_quantities`);
  useEffect(() => {
    if (tryingToEditQuantities) {
      setAlert({ color: 'danger', message: `Must finish editing ${lineItemText} details first` });
    }
  }, [setAlert, tryingToEditQuantities, lineItemText]);

  /**
   * If the user is editing quantities in a requested costing request,
   * they are prohibited from taking any actions on the costing request,
   * eg submitting the request or rejecting the part.
   * Here we display the error warning to the user that gets set on click
   * of a costing request action button.
   */
  useEffect(() => {
    const errorMessage = get(formik.values, `${fieldPrefix}.action_error_message`);
    if (errorMessage && isEditingQuantities) {
      setAlert({ color: 'danger', message: `Finish editing to ${errorMessage}` });
    }
  }, [formik.values, fieldPrefix, setAlert, isEditingPartInfo, isEditingQuantities]);

  return (
    <EditButtons
      editText="Edit Quantities"
      isCustomerPortalAutoquote={isCustomerPortalAutoquote}
      isEditing={isEditing}
      lineItem={lineItem}
      onCancel={handleCancel}
      onClick={handleClick}
      onSave={handleSave}
    />
  );
};

EditQuantitiesButtons.propTypes = {
  fieldPrefix: PropTypes.string,
  isCustomerPortalAutoquote: PropTypes.bool,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  initialSupplierCosts: PropTypes.array,
};

const EditPartInfoButtonsBase = ({
  fieldPrefix,
  isEditing,
  onSave,
  isCustomerPortalAutoquote,
}) => {
  const formik = useFormikContext();
  const { closeAlert, setAlert } = useContext(AlertContext);
  const lineItem = get(formik.values, fieldPrefix, null);
  const buildPackage = get(formik.values, `${fieldPrefix}.build_package`, null);
  const process = get(formik.values, `${fieldPrefix}.build_package.processes.0`, null);
  const [initialLineItemInfo, setInitialLineItemInfo] = useState(cloneDeep(lineItem));
  const [initialBuildPackageInfo, setInitialBuildPackageInfo] = useState(cloneDeep(buildPackage));
  const [initialProcessInfo, setInitialProcessInfo] = useState(cloneDeep(process));

  const { [CR_BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );
  const editDetailsText =
    buildPackUiSupportFlag.treatment === 'on'
      ? 'Edit Build Pack Details'
      : 'Edit Line Item Details';

  const isEditingQuantities = get(formik.values, `${fieldPrefix}.is_editing_quantities`, false);

  const exitEditPartInfoMode = () => {
    formik.setFieldValue(`${fieldPrefix}.is_editing_part_information`, false);
    formik.setFieldValue(`${fieldPrefix}.action_error_message`, null);
    closeAlert();
  };

  const handleEdit = () => {
    if (isEditingQuantities) {
      formik.setFieldValue(`${fieldPrefix}.trying_to_edit_part_info`, true);
    } else {
      closeAlert();
      formik.setFieldValue(`${fieldPrefix}.action_error_message`, null);
      formik.setFieldValue(`${fieldPrefix}.trying_to_edit_part_info`, false);
      formik.setFieldValue(`${fieldPrefix}.is_editing_part_information`, true);
    }
  };

  const handleSave = () => {
    onSave(lineItem, fieldPrefix)
      .then(() => {
        formik.setFieldValue(`${fieldPrefix}.is_editing_part_information`, false);
        formik.setFieldValue(`${fieldPrefix}.action_error_message`, null);
        formik.setErrors(`${fieldPrefix}`, {});
        const buildPackage = lineItem.build_package;
        const process = lineItem.build_package.processes[0];
        setInitialLineItemInfo(cloneDeep(lineItem));
        setInitialProcessInfo(cloneDeep(process));
        setInitialBuildPackageInfo(cloneDeep(buildPackage));
        setAlert({ color: 'success', message: 'Saved!' });
      })
      .catch(() => {
        setAlert({ color: 'danger', message: 'Please fix errors and re-save' });
      });
  };

  const handleCancel = () => {
    const currentSupplierCosts = get(formik.values, `${fieldPrefix}.supplier_costs`);
    const revertedLineItem = {
      ...initialLineItemInfo,
      build_package: {
        ...initialBuildPackageInfo,
        processes: [
          {
            ...initialProcessInfo,
          },
        ],
      },
      supplier_costs: currentSupplierCosts,
    };
    formik.setFieldValue(`${fieldPrefix}`, revertedLineItem);
    formik.setErrors(`${fieldPrefix}`, {});
    exitEditPartInfoMode();
  };

  /**
   * If the user is editing the part info in a requested costing request,
   * they are prohibited from taking any actions on the costing request,
   * eg submitting the request or rejecting the part.
   * Here we display the error warning to the user that gets set on click
   * of a costing request action button.
   */
  const errorMessage = get(formik.values, `${fieldPrefix}.action_error_message`, false);
  useEffect(() => {
    if (errorMessage && isEditing) {
      setAlert({ color: 'danger', message: `Finish editing to ${errorMessage}` });
    }
  }, [errorMessage, isEditing, setAlert]);

  /**
   * If the user is currently editing part quantities, and they click "Edit Build Pack Details",
   * we prevent them from editing both sections at once by prompting them to finish
   * editing the section they are currently editing.
   */
  const tryingToEditPartInfo = get(
    formik.values,
    `${fieldPrefix}.trying_to_edit_part_info`,
    false
  );
  useEffect(() => {
    if (tryingToEditPartInfo) {
      setAlert({ color: 'danger', message: 'Must finish editing quantities first' });
    }
  }, [setAlert, tryingToEditPartInfo]);
  return (
    <>
      {buildPackage.working_status !== 'committed' && (
        <EditButtons
          editText={editDetailsText}
          fieldPrefix={fieldPrefix}
          isCustomerPortalAutoquote={isCustomerPortalAutoquote}
          isEditing={isEditing}
          lineItem={lineItem}
          onCancel={handleCancel}
          onClick={handleEdit}
          onSave={handleSave}
        />
      )}
    </>
  );
};

EditPartInfoButtonsBase.propTypes = {
  fieldPrefix: PropTypes.string,
  initialLineItemInfo: PropTypes.object,
  isCustomerPortalAutoquote: PropTypes.bool,
  isEditing: PropTypes.bool,
  lineItem: PropTypes.shape({
    state: PropTypes.string,
    in_progress: PropTypes.bool,
  }),
  onSave: PropTypes.func,
  user: PropTypes.shape({
    canUpdateDraftOrRequestedCostingRequestLineItems: PropTypes.bool,
    canToggleCostingRequestLineItemInProgress: PropTypes.bool,
  }),
};

export const EditPartInfoButtons = withAlert(EditPartInfoButtonsBase);

const EditButtons = ({
  editText,
  isCustomerPortalAutoquote,
  isEditing,
  lineItem,
  onCancel,
  onClick,
  onSave,
}) => {
  const { user } = useContext(UserContext);

  if (!lineItem) return null;

  const isInProgress = lineItem.state === COSTING_REQUEST_LI_STATES.InProgress;
  const isRequested = lineItem.state === COSTING_REQUEST_LI_STATES.Requested;

  const canEdit =
    (user.canUpdateDraftOrRequestedCostingRequestLineItems && !isInProgress) ||
    (userIsCostingTeam(user) && isInProgress);

  if (isCustomerPortalAutoquote || (!isRequested && !isInProgress)) {
    return null;
  }

  return (
    <div className="flex ml-auto">
      <AlertBadge sm />

      {canEdit &&
        (isEditing ? (
          <>
            <Button color="secondary" outline size="sm" onClick={onCancel}>
              Cancel
            </Button>
            <Button className="ml-2" color="success" size="sm" onClick={onSave}>
              <Icon name="check" className="mr-1" /> Save
            </Button>
          </>
        ) : (
          <Button color="secondary" outline size="sm" onClick={onClick}>
            {editText}
          </Button>
        ))}
    </div>
  );
};

EditButtons.propTypes = {
  editText: PropTypes.string,
  isCustomerPortalAutoquote: PropTypes.bool,
  isEditing: PropTypes.bool,
  lineItem: PropTypes.shape({
    in_progress: PropTypes.bool,
    state: PropTypes.string,
  }),
  onCancel: PropTypes.func,
  onClick: PropTypes.func,
  onSave: PropTypes.func,
};
