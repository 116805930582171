import { useTreatments } from '@splitsoftware/splitio-react';
import { FieldArray, connect } from 'formik';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Button, Icon } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { usePartUpdate } from 'fr-shared/hooks';
import { NEW_ORDER_LINE_ITEM } from 'fr-shared/lib/orders';

import OrderBillableLineItem from './OrderBillableLineItem';
import OrderLineItem from './OrderLineItem';
import OrderLineItemFields from './OrderLineItemFields';

const duplicateLineItemOverrides = lineItem => ({
  id: null,
  isOpen: true,
  uuid: null,
  state: lineItem.state,
  documents: lineItem.documents?.map(doc => ({ ...doc, id: null, inserted_at: null })),
  work_order_line_items: null,
  actual_ship_date: null,
  planned_shipment_line_items: lineItem.planned_shipment_line_items?.map(
    ({ commit_date, promised_ship_date, quantity, supplier_promised_ship_date }) => ({
      commit_date,
      promised_ship_date,
      quantity,
      supplier_promised_ship_date,
      already_fulfilled: 0,
      fulfillments: [],
      remaining_to_fulfill: quantity,
      valid: true,
    })
  ),
  commit_date: null,
  promised_ship_date: null,
  supplier_promised_ship_date: null,
});

const OrderFormLines = ({
  data,
  formik,
  lineItemSubtotal,
  onBulkUpdate,
  readonly,
  onDownloadDocuments,
}) => {
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const capLineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'Build Pack' : 'Line Item';

  const { user } = useContext(UserContext);
  const { values } = formik;

  usePartUpdate('line_items');

  return (
    <>
      <div className="float-left">
        <h4 className="mb-0">
          Subtotal: <span className="text-success">{lineItemSubtotal}</span>
        </h4>
      </div>

      <FieldArray name="line_items">
        {arrayHelpers => (
          <div>
            <div className="text-right mb-4 clearfix">
              <Button className="mr-2" color="primary" onClick={onDownloadDocuments}>
                Download All Files
              </Button>
              <Button
                size="sm"
                color="link"
                className="mr-2"
                onClick={() => onBulkUpdate('isOpen', true)}
              >
                <Icon name="expand" className="mr-2" />
                Expand All
              </Button>
              <Button color="link" size="sm" onClick={() => onBulkUpdate('isOpen', false)}>
                <Icon name="minus-square" right />
                Collapse All
              </Button>
              {!readonly && (
                <Button
                  className="ml-2"
                  color="primary"
                  size="sm"
                  onClick={() =>
                    arrayHelpers.push({
                      ...NEW_ORDER_LINE_ITEM,
                      _id: uniqueId('order-line-item-'),
                    })
                  }
                >
                  <Icon name="plus" right />
                  Add {capLineItemName}
                </Button>
              )}
            </div>
            {values.line_items.map((lineItem, index) => (
              <OrderLineItem
                arrayHelpers={arrayHelpers}
                data={data}
                index={index}
                key={index}
                duplicatedLineItem={duplicateLineItemOverrides(lineItem)}
                isSelectable={!!values.id}
                lineItem={lineItem}
                readonly={readonly}
              >
                <OrderLineItemFields data={data} index={index} readonly={readonly} />
              </OrderLineItem>
            ))}
          </div>
        )}
      </FieldArray>

      <FieldArray name="billable_service_line_items">
        {arrayHelpers => (
          <>
            <div className="flex align-items-center py-2 mb-4">
              {values.billable_service_line_items.length > 0 && (
                <h3 className="mb-0">Billable Services</h3>
              )}
              {!readonly && user.canCreateBillableServiceLineItems && (
                <div className="ml-auto">
                  <Button
                    className="ml-2"
                    color="primary"
                    size="sm"
                    onClick={() =>
                      arrayHelpers.push({ isOpen: true, _id: uniqueId('billable-line-item-') })
                    }
                  >
                    <Icon name="plus" right />
                    Add Billable Service {capLineItemName}
                  </Button>
                </div>
              )}
            </div>
            {values.billable_service_line_items.map((lineItem, index) => (
              <OrderBillableLineItem
                key={index}
                index={index}
                arrayHelpers={arrayHelpers}
                lineItem={lineItem}
                readonly={readonly}
              />
            ))}
          </>
        )}
      </FieldArray>
    </>
  );
};

OrderFormLines.propTypes = {
  data: PropTypes.object,
  formik: PropTypes.object,
  lineItemSubtotal: PropTypes.string,
  onBulkUpdate: PropTypes.func,
  readonly: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onDownloadDocuments: PropTypes.func,
};

export default connect(OrderFormLines);
