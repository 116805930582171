import React from 'react';

import USA from './usa';

interface Flag {
  [key: string]: JSX.Element;
}

interface FlagProps {
  region: keyof Flag;
}

const FLAGS: Flag = {
  USA: <USA />,
};

const Flag = ({ region }: FlagProps): any => {
  return FLAGS[region];
};

export default Flag;
