import yaml from 'js-yaml';
import { capitalize } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent } from 'react';

import { Badge, Button, Icon, Link } from 'fr-shared/components';
import { useToggle } from 'fr-shared/hooks';
import { AuditLineItem } from 'fr-shared/lib/audit_log';
import { keyboardDown } from 'fr-shared/utils';

interface AuditLineItemProps {
  audit: AuditLineItem;
  descriptionPresenter: descriptionPresenterType;
  hasDetails: boolean;
}

type descriptionPresenterType = (audit: AuditLineItem) => string | FunctionComponent;

const topicLinkUrl = ({ subject_id, topic, topic_id }: AuditLineItem): string => {
  switch (topic) {
    case 'shipment':
      return `/admin/shipments/${topic_id}`;
    case 'order_line_item':
      return `/admin/orders/${subject_id}#line_items`;
    case 'order':
      return `/admin/orders/${subject_id}`;
    default:
      return '';
  }
};

const formatTopic = (topic: string): string => {
  return topic.split('_').map(capitalize).join(' ');
};

const topicLink = (audit: AuditLineItem): React.ReactElement | string => {
  const topicDisplay = `${formatTopic(audit.topic)} ID: ${audit.topic_id}`;
  const url = topicLinkUrl(audit);

  if (url === location.pathname) return topicDisplay;

  return <Link to={url}>{topicDisplay}</Link>;
};

export default function AuditLogLineItem({
  audit,
  descriptionPresenter,
  hasDetails,
}: AuditLineItemProps) {
  const [open, toggle] = useToggle();

  return (
    <div className="col-md mb-3">
      <div className="row">
        <div className="col-md">
          <strong>{audit.actor_descriptor}</strong>
        </div>

        <div className="col-auto text-right text-muted">
          {moment(audit.timestamp).format('MM/DD/YY, h:mm A')}
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-md">{formatDescription(audit, descriptionPresenter)}</div>

        <div className="col-auto text-right">
          <Badge color="light">
            <strong>{topicLink(audit)}</strong>
          </Badge>
        </div>
      </div>

      {hasDetails && (
        <div className="row mt-2">
          <div className="col-md">
            <Button
              color="link"
              className="list-none m-0 p-0 font-size-md font-weight-500"
              onClick={toggle}
              onKeyDown={evt => keyboardDown(evt, 'Enter', toggle)}
              role="button"
              tabIndex={0}
            >
              {open ? 'Hide Details ' : 'Show Details '}
              <Icon name={open ? 'chevron-up' : 'chevron-down'} />
            </Button>

            {open && (
              <div className="p-2">
                <pre className="font-size-base mb-0" data-testid="audit-changes">
                  {yaml.dump(audit.changes['data'])}
                </pre>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

const formatDescription = (
  audit: AuditLineItem,
  descriptionPresenter: descriptionPresenterType
) => {
  return descriptionPresenter(audit);
};

const DefaultAuditDescription = (audit: AuditLineItem) => (
  <div>{capitalize(audit.description)}</div>
);

AuditLogLineItem.defaultProps = {
  descriptionPresenter: DefaultAuditDescription,
};
