import PropTypes from 'prop-types';
import React from 'react';

import { Button, Icon, NavLink, classNames } from 'fr-shared/components';

import './Tabs.module.css';

const Tabs = props => {
  const {
    badgeClassnames,
    className,
    'data-testid': dataTestId,
    tabs,
    endTabs,
    showCompleted,
  } = props;

  return (
    <div data-testid={dataTestId}>
      {tabs && (
        <ul className={classNames(['nav nav-tabs', className])}>
          {tabs.map((tab, index) => (
            <Tab
              badgeClassnames={badgeClassnames}
              tab={tab}
              key={index}
              showCompleted={showCompleted}
            />
          ))}
        </ul>
      )}
      {endTabs && (
        <ul className="nav nav-tabs flex-1 justify-content-end">
          {endTabs.map((tab, index) => (
            <Tab
              badgeClassnames={badgeClassnames}
              tab={tab}
              key={index}
              showCompleted={showCompleted}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

Tabs.propTypes = {
  badgeClassnames: PropTypes.string,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  showCompleted: PropTypes.bool,
  tabs: PropTypes.array,
  endTabs: PropTypes.array,
};

const Tab = ({ badgeClassnames, tab, showCompleted }) => {
  if (tab.hide) return null;
  const TabLink = () => (
    <>
      <span>{tab.name}</span>
      {tab.count !== null && (
        <span
          className={classNames([
            badgeClassnames,
            'ml-2',
            'badge',
            'badge-indicator',
            {
              'badge-primary': !showCompleted || tab.count !== 0,
              'badge-success': showCompleted && tab.count === 0,
            },
          ])}
          data-testid="tabs-component--tab-count"
        >
          {showCompleted && tab.count === 0 ? <Icon name="check" /> : tab.count}
        </span>
      )}
    </>
  );
  return (
    <li className={classNames(['nav-item', tab.className])} data-testid={tab.dataTestId}>
      {tab.to ? (
        <NavLink to={tab.to} exact={tab.exact} className="nav-link" activeClassName="active">
          <TabLink />
        </NavLink>
      ) : (
        <Button
          color="link"
          onClick={e => tab.onClick(e)}
          disabled={tab.disabled}
          className={classNames(['nav-link', { active: tab.active }])}
        >
          <TabLink />
        </Button>
      )}
    </li>
  );
};

Tab.propTypes = {
  badgeClassnames: PropTypes.string,
  tab: PropTypes.object,
  showCompleted: PropTypes.bool,
};

export default Tabs;
