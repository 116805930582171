import { Logo } from '@fast-radius/shared-ui';
import * as Sentry from '@sentry/react';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { api } from 'fr-shared/api';
import { AlertContext, UserContext, useUserAnalyticsContext } from 'fr-shared/context';
import { FormikHandleSubmit } from 'fr-shared/formik.d';
import { getMarketoCookies } from 'fr-shared/lib/marketo';

import { AlertBanner, Card, Footer, LoadingCircle } from 'portal/components';
import styles from 'portal/index.module.css';

import CustomizeForm, {
  CustomizeFormProps,
  CustomizeFormValues,
} from './components/CustomizeForm';

const Customize = () => {
  // wrap in a ref to maintain the same ref object in the event of multiple renders
  const userAnalyticsRef = useRef(useUserAnalyticsContext());

  const [submitLoading, setSubmitLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const { user } = useContext(UserContext) as any as {
    user: { id: any; marketing_role: string };
  };

  const handleChangesetErrors = (errors: Record<string, Array<any>>) => {
    let message = Object.entries(errors)
      .map(field => `${field[0]}: ${field[1].join(' ')}`)
      .join(', ');
    setAlert({
      color: 'danger',
      message: message,
      autoClose: false,
    });
  };

  const handleUnexpectedError = () => {
    setAlert({
      color: 'danger',
      message: 'An unexpected error occurred. Refresh and try again.',
      autoClose: false,
    });
  };

  const handleSubmit: FormikHandleSubmit<CustomizeFormProps, CustomizeFormValues> = (
    values,
    _formik
  ) => {
    userAnalyticsRef.current.track('Existing User Info - Completed');
    setSubmitLoading(true);

    api
      .put(`/customer_portal/account/${user.id}`, {
        user: {
          ...values,
          marketing_role:
            values.marketing_role === 'Other'
              ? values.marketing_role_other
              : values.marketing_role,
        },
        marketo_attrs: getMarketoCookies(),
      })
      .then(() => {
        window.location.reload();
      })
      .catch(err => {
        setSubmitLoading(false);
        let errors = err.response?.data?.errors;
        if (errors && !Array.isArray(errors)) {
          // Assume the error is a changeset error if it's not an array.
          handleChangesetErrors(errors);
        } else {
          Sentry.setExtra('error', err);
          Sentry.captureMessage(
            `[Portal] Customize page update user failed`,
            Sentry.Severity.Info
          );
          handleUnexpectedError();
        }
      });
  };

  // Track only once when loaded & shown to the user
  useEffect(() => {
    userAnalyticsRef.current.track('Existing User Info');
  }, [userAnalyticsRef]);

  return (
    <div className="theme-dark bg-gradient">
      <div className={styles.Unauthenticated}>
        <div className={styles.Centered}>
          <div>
            <AlertBanner />
            <Logo className="mt-2 mb-4" fill="white" />
            <Card size="lg">
              {submitLoading ? (
                <div className="flex flex-column align-items-center justify-content-center">
                  <LoadingCircle className="mb-3" />
                  <h4>Updating your information...</h4>
                </div>
              ) : (
                <>
                  <h1 className="h6 text-light mb-4">Tell us about yourself</h1>
                  <p className="mb-4">
                    Please complete a few more details. We&apos;ll use this info to customize your
                    account.
                  </p>
                  <CustomizeForm
                    onSubmit={handleSubmit}
                    submitLoading={submitLoading}
                    initialValues={user}
                  />
                </>
              )}
            </Card>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Customize;
