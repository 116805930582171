import { useTreatments } from '@splitsoftware/splitio-react';
import { connect } from 'formik';
import { get, isEmpty, isNil, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import {
  Button,
  ButtonLink,
  FormAddNotesMenu,
  FormCurrency,
  FormField,
  FormFieldWithLinkPreview,
  FormInspectionTypes,
  FormLocations,
  FormManufacturingProcess,
  FormSelect,
  FormSupportingDocuments,
  Icon,
  LineItemDutiesTaxesTariffs,
  LineItemPart,
  Link,
} from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { useFormikRef, useToggle } from 'fr-shared/hooks';
import { unitsChangeHandler } from 'fr-shared/lib/line_items';
import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';
import { ORDER_LINE_ITEM_STATES } from 'fr-shared/lib/orders';
import { UNITS_OF_MEASUREMENT } from 'fr-shared/lib/parts';
import { userCanEditOrder } from 'fr-shared/lib/user_roles';

import ShippingInformation from './ShippingInformation';
import TariffSelect from './TariffSelect';
import PSLITableShow from './planned_shipment_line_items/PSLITableShow';

const OrderLineItemFields = ({ index, droppedFiles = [], readonly, formik, data = {} }) => {
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const capLineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'Build Pack' : 'Line Item';

  const { user } = useContext(UserContext);

  const { handleChange, initialValues, setFieldValue, values } = formik;
  const { locations, in_house_locations } = data;

  const inHouseLocations = Array.isArray(in_house_locations) ? in_house_locations : [];
  const inHouseLocationOptions = [{ name: 'Supplier' }].concat(inHouseLocations);

  const isExistingOrder = !!(initialValues && initialValues.id);
  const isEditingOrder = !readonly;

  const [showPartInfo, togglePartInfo] = useToggle(true);
  const [showPartDutiesTaxesTariff, togglePartDutiesTaxesTariff] = useToggle(true);
  const [showShippingInfo, toggleShippingInfo] = useToggle(true);

  const lineItem = values.line_items[index];

  const workOrderLineItems = lineItem.work_order_line_items;
  const sortedWorkOrderLineItems = sortBy(workOrderLineItems, o => o.work_order_id);

  const hasBoxFileUrl = !isNil(lineItem.box_file_url);
  const hasFinish = !isNil(lineItem.finishing);
  const hasInternalNotes = !isNil(lineItem.internal_notes);
  const hasNotes = !isNil(lineItem.comments);

  const handleProducedBy = e => {
    const locationId = e.target.value;
    const locationName = e.target.options[e.target.selectedIndex].text;
    const inHouseLocations = in_house_locations;

    if (inHouseLocations?.find(location => location.id === parseInt(locationId))) {
      setFieldValue(`line_items.${index}.shipped_to_customer_from`, locationName);
    }

    handleChange(e);
  };

  const handleLocationByProcess = lineItem => {
    const selectedProcess = get(lineItem.manufacturing_process, 'name');
    if (locations) {
      const chicagoLocation = locations.find(location => location.name === 'FR Chicago');
      const louisvilleLocation = locations.find(location => location.name === 'FR Louisville');

      const isDLS = selectedProcess === MANUFACTURING_PROCESSES.DLS;
      const isFDM = selectedProcess === MANUFACTURING_PROCESSES.FDM;
      const isMJF = selectedProcess === MANUFACTURING_PROCESSES.MFG;

      if (isMJF || (isDLS && chicagoLocation)) {
        setFieldValue(`line_items[${index}].location_id`, chicagoLocation.id);
        setFieldValue(`line_items[${index}].shipped_to_customer_from`, chicagoLocation.name);
      }

      if (isFDM && louisvilleLocation) {
        setFieldValue(`line_items[${index}].location_id`, louisvilleLocation.id);
        setFieldValue(`line_items[${index}].shipped_to_customer_from`, louisvilleLocation.name);
      }
    }
  };

  const handleAddBoxFileUrl = () => formik.setFieldValue(`line_items.${index}.box_file_url`, '');
  const handleRemoveBoxFileUrl = () =>
    formik.setFieldValue(`line_items.${index}.box_file_url`, null);

  const handleAddFinish = () => formik.setFieldValue(`line_items.${index}.finishing`, '');
  const handleRemoveFinish = () => formik.setFieldValue(`line_items.${index}.finishing`, null);

  const handleAddNotes = () => formik.setFieldValue(`line_items.${index}.comments`, '');
  const handleRemoveNotes = () => formik.setFieldValue(`line_items.${index}.comments`, null);

  const handleAddInternalNotes = () =>
    formik.setFieldValue(`line_items.${index}.internal_notes`, '');
  const handleRemoveInternalNotes = () =>
    formik.setFieldValue(`line_items.${index}.internal_notes`, null);

  const handleChangeTariff = tariff => {
    formik.setFieldValue(`line_items.${index}.tariff_id`, tariff?.id ?? null);
    formik.setFieldValue(`line_items.${index}.tariff`, tariff ?? null);
  };

  const formikRef = useFormikRef();
  const handleUnitsChange = unitsChangeHandler(lineItem, formikRef);

  return (
    <div>
      <div className="border-top border-bottom card-body mx-0">
        <div className="row">
          <div className="col-md">
            <LineItemPart
              fieldPrefix={`line_items.${index}`}
              droppedFiles={droppedFiles}
              readonly={!isEditingOrder}
              onClickPart={() => {
                // Instead of rendering a modal, we'll open a new tab with
                // full screen viewer
                window.open(`/admin/viewer/order_line_item/${lineItem.id}`);
              }}
              lineItemName={capLineItemName}
            />
          </div>
          <div className="col-md">
            {lineItem.part && (
              <FormSupportingDocuments
                dropzoneComponent={
                  <Button color="secondary">Upload Supporting Document(s)</Button>
                }
                fieldPrefix={`line_items.${index}`}
                parentType="order_line_item"
                readonly={!isEditingOrder}
              />
            )}
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className={`card-title flex ${showPartInfo ? 'mb-3' : 'mb-0'}`}>
          <h4 className="flex-fill m-0">Build Pack Details</h4>
          <div className="flex flex-xl-row flex-lg-row flex-md-row flex-column align-items-stretch">
            {isEditingOrder && showPartInfo && (
              <>
                <FormAddNotesMenu
                  dataTestIdSuffix={index}
                  onAddInternalNotes={handleAddInternalNotes}
                  onAddNotes={handleAddNotes}
                  showAddInternalNotes={!hasInternalNotes}
                  showAddNotes={!hasNotes}
                />
                {!hasFinish && (
                  <Button
                    className="ml-2 mb-1"
                    color="secondary"
                    onClick={handleAddFinish}
                    outline
                    size="md"
                  >
                    <Icon name="plus" /> Add Finish
                  </Button>
                )}
                {!hasBoxFileUrl && (
                  <Button
                    className="ml-2 mb-1"
                    color="secondary"
                    onClick={handleAddBoxFileUrl}
                    outline
                    size="md"
                  >
                    <Icon name="plus" /> Add 3D Model Link
                  </Button>
                )}
              </>
            )}
            <Button
              className="ml-2 mb-1"
              color="secondary"
              dataTestId={`toggle-part-info-${index}`}
              onClick={togglePartInfo}
              outline
              size="md"
            >
              {showPartInfo ? <Icon name="angle-up" /> : <Icon name="angle-down" />}
            </Button>
          </div>
        </div>
        {showPartInfo && (
          <div className="row">
            <div className="col-md">
              <FormField
                label="File Name"
                name={`line_items.${index}.description`}
                readonly={!isEditingOrder}
                required={true}
              />
              <FormField
                label="File Units"
                name={`line_items.${index}.units`}
                onChange={handleUnitsChange}
                component="select"
                readonly={!isEditingOrder}
                required={true}
              >
                {UNITS_OF_MEASUREMENT.map(unit => (
                  <option key={unit} value={unit}>
                    {unit}
                  </option>
                ))}
              </FormField>
              <FormManufacturingProcess
                readonly={!isEditingOrder}
                fieldPrefix={`line_items.${index}`}
                onChange={handleLocationByProcess}
                showFinish={false}
              />
              <FormInspectionTypes
                label={'Inspection Type'}
                name={`line_items.${index}.inspection_type_id`}
                readonly={!isEditingOrder && `line_items.${index}.inspection_type.name`}
              />
              <FormFieldWithLinkPreview
                label="Inspection Plan URL"
                name={`line_items.${index}.inspection_plan_url`}
                placeholder="https://fastradius.box.com/..."
                showLink={false}
                readonly={!isEditingOrder}
                required={false}
              />
              {hasNotes && (
                <FormField
                  className="append-button"
                  component="textarea"
                  inputStyle={{ height: 65, minHeight: 65 }}
                  label="Notes"
                  name={`line_items.${index}.comments`}
                  readonly={!isEditingOrder}
                  advice={
                    <div className="font-size-sm my-1">
                      <Icon type="far" name="eye" /> Visible to everybody
                    </div>
                  }
                  deleteTestId={`remove-notes-${index}`}
                  onDelete={handleRemoveNotes}
                />
              )}
              {hasInternalNotes && (
                <FormField
                  className="append-button"
                  component="textarea"
                  inputStyle={{ height: 65, minHeight: 65 }}
                  label="Internal Notes"
                  name={`line_items.${index}.internal_notes`}
                  readonly={!isEditingOrder}
                  advice={
                    <div className="font-size-sm my-1">
                      <Icon type="far" name="eye-slash" /> Only visible to FR team
                    </div>
                  }
                  deleteTestId={`remove-internal-notes-${index}`}
                  onDelete={handleRemoveInternalNotes}
                />
              )}
              {hasFinish && (
                <FormField
                  className="append-button"
                  label="Finish"
                  name={`line_items.${index}.finishing`}
                  readonly={!isEditingOrder}
                  deleteTestId={`remove-finish-${index}`}
                  onDelete={handleRemoveFinish}
                />
              )}
              {hasBoxFileUrl && (
                <FormField
                  className="append-button"
                  label="Link to 3D Model"
                  name={`line_items.${index}.box_file_url`}
                  placeholder="https://fastradius.box.com/s/..."
                  readonly={!isEditingOrder}
                  deleteTestId={`remove-3d-model-link-${index}`}
                  onDelete={handleRemoveBoxFileUrl}
                />
              )}
              {!isEmpty(sortedWorkOrderLineItems) && (
                <div className="row form-group">
                  <span className="col-md-4">Work Orders</span>
                  <div className="col-md flex flex-column">
                    {sortedWorkOrderLineItems.map(workOrderLineItem => (
                      <div key={workOrderLineItem.id}>
                        <Link to={`/admin/work_orders/${workOrderLineItem.work_order_id}`}>
                          Work Order #{workOrderLineItem.work_order_id}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="col-md">
              <FormField
                label="Quantity"
                name={`line_items.${index}.quantity`}
                type="number"
                readonly={!isEditingOrder}
                required={true}
              />
              <FormCurrency
                label="Unit Cost"
                name={`line_items.${index}.unit_cost`}
                readonly={!isEditingOrder}
              />
              <FormCurrency
                label="Unit Sale Price"
                name={`line_items.${index}.sales_price`}
                readonly={!isEditingOrder}
              />
              <FormLocations
                label="Produced By"
                name={`line_items.${index}.location_id`}
                onChange={handleProducedBy}
                readonly={!isEditingOrder && `line_items.${index}.location.name`}
                required={true}
              />
              <FormSelect
                label="Shipped To Customer From"
                name={`line_items.${index}.shipped_to_customer_from`}
                readonly={!isEditingOrder}
                optionList={inHouseLocationOptions}
                valueAccessor={option => option.name}
              />
              <TariffSelect
                name={`line_items.${index}.tariff_id`}
                onChange={handleChangeTariff}
                readonly={!isEditingOrder}
                value={lineItem?.tariff}
              />
              {get(values, 'customer.name') && values.customer.name.includes('Jabil') && (
                <FormField
                  label="Customer PO"
                  name={`line_items.${index}.customer_po`}
                  readonly={!isEditingOrder}
                />
              )}
            </div>
          </div>
        )}
      </div>

      <div className="card-body border-top">
        <div className={`card-title flex ${showPartInfo ? 'mb-3' : 'mb-0'}`}>
          <h4 className="flex-fill m-0">Duties, Taxes, and Tariffs</h4>
          <div className="flex flex-xl-row flex-lg-row flex-md-row flex-column align-items-stretch">
            <Button
              className="ml-2 mb-1"
              color="secondary"
              dataTestId={`toggle-part-duties-taxes-tariff-info-${index}`}
              onClick={togglePartDutiesTaxesTariff}
              outline
              size="md"
              aria-expanded={showPartDutiesTaxesTariff}
              aria-label="Duties, Taxes and Tariffs"
            >
              {showPartDutiesTaxesTariff ? <Icon name="angle-up" /> : <Icon name="angle-down" />}
            </Button>
          </div>
        </div>
        {showPartDutiesTaxesTariff && (
          <div className="row">
            <LineItemDutiesTaxesTariffs
              fieldPrefix={`line_items.${index}`}
              readonly={!isEditingOrder}
            />
          </div>
        )}
      </div>
      <div className="card-body border-top">
        <ShippingInformation
          fromShippingTab={false}
          isExistingOrder={isExistingOrder}
          showShippingInfo={showShippingInfo}
          lineItem={lineItem}
          oliIndex={index}
          toggleShippingInfo={() => toggleShippingInfo(!showShippingInfo)}
        />
        {isExistingOrder && showShippingInfo && (
          <>
            <PSLITableShow plannedShipmentLineItems={lineItem.planned_shipment_line_items} />
            {userCanEditOrder(user, values) && lineItem.state !== ORDER_LINE_ITEM_STATES.Shipped && (
              <ButtonLink
                dataTestId="planned-shipment-lineitems-add-button"
                color="primary"
                size="sm"
                to={`/admin/orders/${values.id}/edit?oli_index=${index}#shipping`}
              >
                <Icon name="edit" right />
                Edit Planned Shipment Line Item
              </ButtonLink>
            )}
          </>
        )}
      </div>
    </div>
  );
};

OrderLineItemFields.propTypes = {
  data: PropTypes.object,
  droppedFiles: PropTypes.array,
  formik: PropTypes.object,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lineItem: PropTypes.object,
  readonly: PropTypes.bool,
};

export default connect(OrderLineItemFields);
