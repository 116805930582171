import { Form, useFormikContext, withFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Button, Link, ServerError } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { WORK_ORDER_FAILED_STATE, hasPartFailures, isRework } from 'fr-shared/lib/work_orders';

import WorkOrderButtons from './WorkOrderButtons';
import WorkOrderDetails from './WorkOrderDetails';
import WorkOrderLines from './WorkOrderLines';
import WorkOrderStageGateModal from './WorkOrderStageGateModal';
import WorkOrderTracker from './WorkOrderTracker';
import WorkOrderYieldReportModal from './WorkOrderYieldReportModal';
import { workOrderValidationSchema } from './utils/schemas';

const WorkOrderForm = ({
  orderId,
  travelerLoading,
  onCloneRequested,
  onTravelerRequested,
  handleDeleteWorkOrder,
  handleSubmit,
}) => {
  const formik = useFormikContext();
  const history = useHistory();
  const params = useParams();
  const { user } = useContext(UserContext);
  const [order, setOrder] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  /**
   * Load order if it's id was passed in from parent
   */
  useEffect(() => {
    if (orderId) {
      api.get(`/orders/${orderId}`).then(res => {
        handleOrderChange({ order: res.data });
      });
    }
  }, [orderId, handleOrderChange]);

  /**
   * Show the error modal every time we get errors
   */
  useEffect(() => {
    setShowErrorModal(!isEmpty(formik.errors));
  }, [formik.errors]);

  const handleOrderChange = useCallback(e => {
    setOrder(e.order);
    formik.setFieldValue('order_line_items', e.order.line_items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddLine = line => {
    const workOrderLineItem = {
      order_line_item: { ...line, order },
      order_line_item_id: line.id,
      quantity: line.quantity,
    };

    formik.setFieldValue('line_items', [...formik.values.line_items, workOrderLineItem]);
  };

  const handleCloneWorkOrder = () => {
    api.post('/work_orders', { work_order: { state: 'Draft' } }).then(res => {
      onCloneRequested();
      history.push(`/admin/work_orders/${res.data.id}/cloned_from/${params.id}`);
    });
  };

  const handleTravelerRequested = () => {
    onTravelerRequested(formik.values, formik);
  };

  const isEditing = !!formik.values.id;
  const isFailure = formik.values?.state === WORK_ORDER_FAILED_STATE;
  const hasYieldReport = hasPartFailures(formik.values);
  const isYieldReportModalOpen = isFailure && !hasYieldReport;

  return (
    <Form className="mb-5" noValidate={true}>
      <WorkOrderStageGateModal
        isOpen={showErrorModal}
        toggle={() => setShowErrorModal(!showErrorModal)}
        onSubmit={handleSubmit}
      />
      <div className="page-header mb-2">
        <div className="container flex-column flex-md-row align-items-start">
          <div>
            <h2>Work Order #{formik.values.id}</h2>
            {params.cloneId && (
              <div className="text-muted font-size-md">
                Cloned from{' '}
                <Link to={`/admin/work_orders/${params.cloneId}`}>#{params.cloneId}</Link>
              </div>
            )}
          </div>
          <div className="page-actions flex flex-column flex-md-row mt-2 mt-md-0">
            <Button
              color="secondary"
              onClick={e => history.goBack(e)}
              className="mr-2 d-none d-md-inline-block"
            >
              Back
            </Button>
            {user.canDuplicateWorkOrders && !params.cloneId && (
              <Button
                color="primary"
                onClick={handleCloneWorkOrder}
                className="mr-2 d-none d-md-inline-block"
              >
                Clone Work Order
              </Button>
            )}
            {!params.cloneId && (
              <Button
                dataTestId="yield_report_btn"
                color="primary"
                to={`/admin/work_orders/${formik.values.id}/report`}
                className="mb-2 mb-md-0 mr-md-2"
              >
                Yield Report
              </Button>
            )}
            <Button color="success" type="submit">
              Save
            </Button>
          </div>
        </div>
      </div>

      <div className="container">
        <ServerError errors={formik.errors?.server} />
        {formik.values.state !== 'Draft' && <WorkOrderTracker />}

        <WorkOrderLines
          canEdit={isEditing && user.canUpdateWorkOrders && !isRework(formik.values)}
          handleAddLine={handleAddLine}
          handleOrderChange={handleOrderChange}
          showLineItems={order !== null}
        />

        <WorkOrderDetails
          isRework={isRework(formik.values)}
          isEditing={isEditing}
          readonly={!user.canUpdateWorkOrders}
        />

        {user.canUpdateWorkOrders && (
          <WorkOrderButtons
            onDeleteClicked={handleDeleteWorkOrder}
            onTravelerClicked={handleTravelerRequested}
            travelerIsLoading={travelerLoading}
          />
        )}
      </div>

      <WorkOrderYieldReportModal workOrder={formik.values} isOpen={isYieldReportModalOpen} />
    </Form>
  );
};

const formik = {
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: workOrderValidationSchema,
  handleSubmit: (values, bag) => {
    const { props } = bag;
    window.scrollTo(0, 0);
    props.handleSubmit && props.handleSubmit(values, bag);
  },
  // default values of form
  mapPropsToValues: props => ({
    order_line_items: [],
    line_items: [],
    num_of_build_plates: 1,
    ...props.initialValues,
  }),
  enableReinitialize: true,
};

WorkOrderForm.propTypes = {
  handleDeleteWorkOrder: PropTypes.func,
  handleSubmit: PropTypes.func,
  onTravelerRequested: PropTypes.func,
  onCloneRequested: PropTypes.func,
  orderId: PropTypes.string,
  travelerLoading: PropTypes.bool,
};

export default withFormik(formik)(WorkOrderForm);
