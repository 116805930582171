import { Logo } from '@fast-radius/shared-ui';
import React, { Ref } from 'react';

import {
  Button,
  Card,
  FormCheckbox,
  FormCustomerSelect,
  FormField,
  FormFieldWithLinkPreview,
  FormFillDevButton,
  FormIncoterms,
  FormSelect,
  Icon,
} from 'fr-shared/components';
import { ORDER_TYPES } from 'fr-shared/lib/orders';
import {
  ACTIVE_SHIPPING_METHODS_LIST,
  SHIPPING_METHODS_LIST,
  SHIPPING_PAYMENT_TYPES,
  SHIPPING_PAYMENT_TYPE_OPTIONS,
} from 'fr-shared/lib/shipping';

import { SELECTABLE_ORDER_STATES } from '../utils/states';
import OrderBillingFields from './OrderBillingFields';
import OrderContactPicker from './OrderContactPicker';
import OrderFormPaymentInformation from './OrderFormPaymentInformation';
import OrderShippingFields from './OrderShippingFields';
import OrderTotalDetails from './OrderTotalDetails';

interface Props {
  braintreeInstance: Ref<any>;
  handleBillingAddress: () => void;
  handleCustomerChange: (customer: Customer) => void;
  handleCustomerContactChange: (customerContact: Contact) => void;
  isEditing: boolean;
  readonly: boolean;
  user: { canEditIncoterm: boolean };
  values: FormValues;
}

interface FormValues {
  customer_contact: Contact;
  id?: string;
  payment_terms?: string;
  payment_type: string;
  shipping_accounts?: string[];
  shipping_payment_type: string;
  state?: string;
  supplier_purchase_orders?: string[];
  total_price?: { amount: string; formatted: string } | null;
  type: string;
}

interface OrderShippingFieldsProps {
  customerContactId: number;
  readonly: boolean;
  shippingAccounts: string[];
}

interface OrderFormPaymentInformationProps {
  customerContactId: number;
  isExistingOrder: boolean;
  orderType: string;
  paymentTerms: string;
  paymentType: string;
  readonly: boolean;
  ref: Ref<any>;
  supplierPO: string[];
}

interface Customer {
  id?: number;
  name?: string;
}

interface Contact {
  id?: number;
}

interface FormCustomerSelectProps {
  onCustomerChange: (customer: Customer) => void;
  onCustomerContactChange: (customerContact: Contact) => void;
  readonly: boolean | string;
}

export default function OrderFormDetails(props: Props) {
  const orderShippingFieldsProps: OrderShippingFieldsProps = {
    readonly: props.readonly,
    shippingAccounts: props.values.shipping_accounts,
    customerContactId: props.values.customer_contact?.id,
  };

  const formCustomerSelectProps: FormCustomerSelectProps = {
    onCustomerChange: props.handleCustomerChange,
    onCustomerContactChange: props.handleCustomerContactChange,
    readonly: props.readonly && 'customer.name',
  };

  const orderFormPaymentInformationProps: OrderFormPaymentInformationProps = {
    ref: props.braintreeInstance,
    customerContactId: props.values.customer_contact?.id,
    paymentTerms: props.values.payment_terms,
    paymentType: props.values.payment_type,
    readonly: props.readonly,
    supplierPO: props.values.supplier_purchase_orders,
    isExistingOrder: props.readonly,
    orderType: props.values.type,
  };

  const { readonly, user: currentUser, values, isEditing } = props;

  const isDraftSavable = !values.id || values.state === 'Draft';

  const shippingMethods = readonly ? SHIPPING_METHODS_LIST : ACTIVE_SHIPPING_METHODS_LIST;

  return (
    <div>
      <div className="relative">
        {!readonly && (
          <div className="absolute mb-2" style={{ top: 0, right: 0, zIndex: 1000 }}>
            <FormFillDevButton form="ORDER" />
          </div>
        )}

        <OrderTotalDetails order={values} isEditing={isEditing} />

        <div className="row mb-4">
          <div className="col-md">
            <Card border={true}>
              <Card.Header>
                <Icon name="user" className="mr-2" />
                Customer Information
              </Card.Header>
              <Card.Body>
                <FormCustomerSelect {...formCustomerSelectProps} />
              </Card.Body>
            </Card>
          </div>

          <div className="col-md">
            <Card border={true}>
              <Card.Header>
                <Logo fill="#ddd" width={22} showText={false} className="mr-2" />
                FR Information
              </Card.Header>
              <Card.Body>
                {!isDraftSavable && (
                  <FormField
                    label="Order State"
                    name="state"
                    component="select"
                    readonly={readonly}
                  >
                    <option value="" />
                    {SELECTABLE_ORDER_STATES.map(orderState => (
                      <option key={orderState} value={orderState}>
                        {orderState}
                      </option>
                    ))}
                  </FormField>
                )}
                <FormField label="Order Type" name="type" component="select" readonly={readonly}>
                  <option value="" />
                  {ORDER_TYPES.map(orderType => (
                    <option key={orderType} value={orderType}>
                      {orderType}
                    </option>
                  ))}
                </FormField>
                <FormField label="Public ID" name="public_id" readonly={readonly} />
                <OrderContactPicker fieldName="sales_contact" />
                <OrderContactPicker fieldName="support_contact" />
                <OrderContactPicker fieldName="engineering_contact" />
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md">
            <OrderFormPaymentInformation {...orderFormPaymentInformationProps} />
          </div>

          <div className="col-md">
            <Card border={true}>
              <Card.Header>
                <Icon name="shipping-fast" className="mr-2" />
                Shipping Information
              </Card.Header>
              <Card.Body>
                <div className="mb-3">
                  <FormCheckbox label="Blind Ship" name="blind_ship" readonly={readonly} />
                </div>
                <FormField
                  label="Shipping Payment Type"
                  name="shipping_payment_type"
                  component="select"
                  readonly={readonly}
                  required={true}
                >
                  {SHIPPING_PAYMENT_TYPE_OPTIONS.map(paymentType => (
                    <option key={paymentType} value={paymentType}>
                      {paymentType}
                    </option>
                  ))}
                </FormField>
                {values.shipping_payment_type === SHIPPING_PAYMENT_TYPES.InvoiceToCustomer && (
                  <FormSelect
                    label="Shipping Account"
                    name="shipping_account_id"
                    optionList={values.shipping_accounts}
                    nameAccessor={(shippingAccount: { label: string; account_number: string }) =>
                      `${shippingAccount.label}: ${shippingAccount.account_number}`
                    }
                    readonly={readonly && 'shipping_account.label'}
                  />
                )}
                {values.shipping_payment_type === SHIPPING_PAYMENT_TYPES.CustomerAccount && (
                  <FormField
                    label="Shipping Account"
                    name="shipping_account_number"
                    readonly={readonly}
                    required={true}
                  />
                )}
                <FormField
                  label="Shipping Method"
                  name="shipping_method"
                  component="select"
                  readonly={readonly}
                  required={true}
                >
                  <option value="" />
                  {shippingMethods.map(shippingMethod => (
                    <option key={shippingMethod} value={shippingMethod}>
                      {shippingMethod}
                    </option>
                  ))}
                </FormField>
                <FormField
                  label=" Supplier Shipping Method"
                  name="supplier_shipping_method"
                  component="select"
                  readonly={readonly}
                >
                  <option value="" />
                  {shippingMethods.map(shippingMethod => (
                    <option key={shippingMethod} value={shippingMethod}>
                      {shippingMethod}
                    </option>
                  ))}
                </FormField>
                <FormIncoterms disabled={readonly || !currentUser.canEditIncoterm} />
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md">
            <Card border={true}>
              <Card.Header>
                <Icon name="address-card" className="mr-2" />
                Billing Address
              </Card.Header>
              <Card.Body>
                <OrderBillingFields
                  readonly={readonly}
                  customerContactId={values.customer_contact?.id}
                />
              </Card.Body>
            </Card>
          </div>

          <div className="col-md">
            <Card border={true}>
              <Card.Header>
                <Icon name="address-card" className="mr-2" />
                Shipping Address
                {!readonly && (
                  <div className="flex ml-auto">
                    <Button onClick={props.handleBillingAddress} size="sm" color="primary">
                      Use Billing Address
                    </Button>
                  </div>
                )}
              </Card.Header>
              <Card.Body>
                <OrderShippingFields {...orderShippingFieldsProps} />
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className="mb-4">
          <Card border={true}>
            <Card.Header>
              <Icon name="info-circle" className="mr-2" />
              Additional Information
            </Card.Header>
            <Card.Body>
              <div className="row">
                <div className="col-md-8">
                  <FormFieldWithLinkPreview
                    label="Link to Box Quote Folder"
                    placeholder="https://fastradius.app.box.com/folder/..."
                    name="box_file_location"
                    readonly={readonly}
                  />
                  <FormField
                    label="Order Comments"
                    name="comments"
                    component="textarea"
                    readonly={readonly}
                  />
                  <FormField
                    label="Quality Comments"
                    name="quality_comments"
                    component="textarea"
                    readonly={readonly}
                  />
                </div>
                <div className="col-md">
                  <FormCheckbox
                    label="First Article Inspection"
                    name="first_article_inspection"
                    readonly={readonly}
                  />
                  <FormCheckbox
                    label="Certificate of Conformance"
                    name="certificate_of_conformance"
                    readonly={readonly}
                  />
                  <FormCheckbox
                    label="CoC Signature Required"
                    name="coc_signature_required"
                    readonly={readonly}
                  />
                  <FormCheckbox
                    label="Material Certification Required"
                    name="material_certification_required"
                    readonly={readonly}
                  />
                  <FormCheckbox
                    label="Finishing Certification Required"
                    name="finishing_certificaton_required"
                    readonly={readonly}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}
