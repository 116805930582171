import PropTypes from 'prop-types';
import React from 'react';

import { IconFont, classNames } from 'fr-shared/components';

import { Button } from 'portal/components';

const PageViewToggle = ({ className, iconClassName, toggled, onToggle }) => {
  return (
    <Button
      color="link"
      className={classNames([
        'text-decoration-none text-coolGray-300 outline-none ml-auto',
        className,
      ])}
      onClick={onToggle}
    >
      {toggled ? (
        <>
          <IconFont name="list-view" className={'mr-1 ' + iconClassName} /> List view
        </>
      ) : (
        <>
          <IconFont name="grid-view" className={'mr-1 ' + iconClassName} /> Grid view
        </>
      )}
    </Button>
  );
};

PageViewToggle.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  toggled: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default PageViewToggle;
