import { pickBy } from 'lodash';
import React, { useContext } from 'react';

import { api } from 'fr-shared/api';
import {
  Breadcrumb,
  Helmet,
  Sidebar,
  SidebarPage,
  SidebarPageContainer,
} from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { useTableFilters } from 'fr-shared/hooks';

import CostingSearch from './components/CostingSearch';
import CostingSidebar from './components/CostingSidebar';
import CostingTable from './components/CostingTable';
import { FILTER_FIELDS, TABLE_COLUMNS } from './utils/queues';

const FILTER_NAME = 'costing';
const INITIAL_FILTERS = { page: 1, sort_key: 'id', sort_desc: true };

const COLUMNS = [
  TABLE_COLUMNS.Id,
  TABLE_COLUMNS.CustomerId,
  TABLE_COLUMNS.OpportunityId,
  TABLE_COLUMNS.PublicId,
  TABLE_COLUMNS.State,
  TABLE_COLUMNS.ManufacturingProcessId,
  TABLE_COLUMNS.DateSubmitted,
  TABLE_COLUMNS.Completed,
  TABLE_COLUMNS.Items,
];

const FIELDS = [
  FILTER_FIELDS.State,
  FILTER_FIELDS.SalesContactId,
  FILTER_FIELDS.ManufacturingProcessId,
  FILTER_FIELDS.EngineeringContactId,
  FILTER_FIELDS.SupportContactId,
];

const Costing = () => {
  const { user } = useContext(UserContext);

  const getCostingRequests = filters =>
    api.get('/costing_requests', {
      params: {
        ...pickBy(filters),
        not_draft: !user.canViewDraftCostingRequests,
      },
    });

  const {
    data: costingRequests,
    filters,
    loading,
    handleSearch: handleTextSearch,
    onFetchData: handleTableFetchData,
    pages,
    resetFilters,
    setFilters,
  } = useTableFilters(FILTER_NAME, INITIAL_FILTERS, getCostingRequests);

  return (
    <>
      <Helmet title="Costing" />
      <Breadcrumb to="/admin/costing_requests">Costing Requests</Breadcrumb>

      <SidebarPageContainer localStorageKey={Sidebar.LOCAL_STORAGE_KEYS.costing}>
        <SidebarPage>
          <div className="page-header flex mb-0 px-3">
            <h2>List of Requests</h2>
            <CostingSearch
              filters={filters}
              setFilters={setFilters}
              onTextSearch={handleTextSearch}
              showOpportunitySearch={true}
            />
          </div>

          <CostingTable
            columns={COLUMNS}
            data={costingRequests}
            emptyText="No Requests are currently awaiting review"
            pages={pages}
            onFetchData={handleTableFetchData}
            loading={loading}
          />
        </SidebarPage>

        <Sidebar>
          <CostingSidebar
            fields={FIELDS}
            filters={filters}
            setFilters={setFilters}
            resetFilters={resetFilters}
          />
        </Sidebar>
      </SidebarPageContainer>
    </>
  );
};

export default Costing;
