import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';

import { useUserAnalyticsContext } from 'fr-shared/context';

import { Button, FormField, FormPhoneNumber, FormSelect } from 'portal/components';

export const MARKETING_ROLES = [
  'Design & Mechanical Engineering',
  'Industrial & Process Engineering',
  'Manufacturing Ops & QA',
  'New Product Development ',
  'Research & Development',
  'Sourcing & Purchasing ',
  'Supply Chain & Material Planning',
  'Other',
];

const DetailsPanel = ({ submitLoading, submitLabel }) => {
  const formik = useFormikContext();
  const userAnalytics = useUserAnalyticsContext();

  return (
    <div data-testid="details_panel">
      <FormField
        dataTestId="first_name"
        label="First name"
        name="first_name"
        floatingLabel={true}
        onlyShowErrorIfTouched
        required={true}
        onBlur={e => {
          userAnalytics.trackFieldEvents({
            e,
            trackName: 'First Name',
            rawSchema: RawSchema,
          });
        }}
      />
      <FormField
        dataTestId="last_name"
        label="Last name"
        name="last_name"
        floatingLabel={true}
        onlyShowErrorIfTouched={true}
        required={true}
        onBlur={e => {
          userAnalytics.trackFieldEvents({
            e,
            trackName: 'Last Name',
            rawSchema: RawSchema,
          });
        }}
      />
      <FormPhoneNumber
        className="mb-2"
        dataTestId="phone_number"
        label="Phone number"
        name="phone_number"
        floatingLabel={true}
        onlyShowErrorIfTouched={true}
        required={true}
        onBlur={e => {
          userAnalytics.trackFieldEvents({
            e,
            trackName: 'Phone Number',
            rawSchema: RawSchema,
          });
        }}
      />
      <FormField
        dataTestId="marketing_company_name"
        label="Company name"
        name="marketing_company_name"
        floatingLabel={true}
        onlyShowErrorIfTouched={true}
        required={true}
        onBlur={e => {
          userAnalytics.trackFieldEvents({
            e,
            trackName: 'Company Name',
            rawSchema: RawSchema,
            logType: 'string',
          });
        }}
      />
      <FormSelect
        placeholder="Please select one"
        label="Your speciality (optional)"
        dataTestId="marketing_role"
        name="marketing_role"
        optionList={MARKETING_ROLES}
        nameAccessor={option => option}
        valueAccessor={option => option}
        inputProps={{ required: true }}
        floatingLabel={true}
        onlyShowErrorIfTouched={true}
        stacked={true}
        onBlur={e => {
          userAnalytics.trackFieldEvents({
            e,
            trackName: 'Your Specialty Selected',
            rawSchema: RawSchema,
            logType: 'select',
          });
        }}
      />
      {formik.values?.marketing_role === 'Other' && (
        <FormField
          label="Other speciality"
          name="marketing_role_other"
          className="mt-3"
          required={true}
          onBlur={e => {
            userAnalytics.trackFieldEvents({
              e,
              trackName: 'Your Specialty Selected - Other',
              rawSchema: RawSchema,
              logType: 'string',
            });
          }}
        />
      )}

      <div className="flex flex-column pt-4">
        <Button dataTestId="submit" type="submit" loading={submitLoading}>
          {submitLabel}
        </Button>
      </div>
    </div>
  );
};

export const RawSchema = {
  first_name: Yup.string().label('First name').nullable().required(),
  last_name: Yup.string().label('Last name').nullable().required(),
  phone_number: Yup.string().label('Phone number').nullable().required(),
  marketing_company_name: Yup.string().label('Company name').nullable().required(),
};

export const Schema = Yup.object().shape(RawSchema);

DetailsPanel.propTypes = {
  onSubmit: PropTypes.func,
  submitLoading: PropTypes.bool,
  submitLabel: PropTypes.string,
};

export default DetailsPanel;
