import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button, Icon, Modal } from 'fr-shared/components';
import { useInspectionTypes } from 'fr-shared/hooks';

const InspectionTypeModal = ({ canEditFields, inspectionTypeName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: inspectionTypes } = useInspectionTypes();

  return canEditFields ? (
    <InspectionDescriptionModal
      isOpen={isOpen}
      toggle={setIsOpen}
      inspectionTypes={inspectionTypes}
    />
  ) : (
    <InspectionIcon inspectionTypeName={inspectionTypeName} inspectionTypes={inspectionTypes} />
  );
};

InspectionTypeModal.propTypes = {
  canEditFields: PropTypes.bool,
  inspectionTypeName: PropTypes.string,
};

const InspectionIcon = ({ inspectionTypeName, inspectionTypes }) => {
  const typeAndDescription = inspectionTypes.find(
    item => item.name_with_price === inspectionTypeName
  );
  return (
    <Icon
      name="info-circle"
      className="text-primary-600"
      data-rh={typeAndDescription?.internal_description}
    />
  );
};

InspectionIcon.propTypes = {
  inspectionTypeName: PropTypes.string,
  inspectionTypes: PropTypes.array,
};

const InspectionDescriptionModal = ({ isOpen, toggle, inspectionTypes }) => (
  <Modal
    action={<Icon name="question-circle" className="text-primary-600" />}
    className="modal-scrollable"
    isOpen={isOpen}
    toggle={() => toggle(true)}
  >
    <Modal.Header title="Inspection Type Descriptions" onClose={() => toggle(false)} />
    <div className="modal-body">
      {inspectionTypes.map(type => (
        <div key={type.name} className="pb-3 pl-2 pr-2">
          <strong>{type.name}</strong>
          <p>{type.internal_description}</p>
        </div>
      ))}
    </div>
    <div className="modal-footer">
      <Button color="primary" className="mr-2" onClick={() => toggle(false)}>
        Done
      </Button>
    </div>
  </Modal>
);

InspectionDescriptionModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  inspectionTypes: PropTypes.array,
};

export default React.memo(InspectionTypeModal);
