import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Button } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';

const DuplicateLineItemActions = ({ loading, onDuplicateLineItem, onRemoveLineItem }) => {
  const { user } = useContext(UserContext);

  if (!user.canDuplicateAndAddLineItemsToCostingRequests) {
    return null;
  }

  return (
    <div
      className="card-footer flex flex-end bg-white"
      style={{ borderTop: '1px solid #dee2e6' }}
    >
      <div className="ml-auto py-2">
        <Button loading={loading} onClick={onDuplicateLineItem} className="mr-2" color="primary">
          Duplicate
        </Button>
        <Button loading={loading} onClick={onRemoveLineItem} outline color="danger">
          Delete
        </Button>
      </div>
    </div>
  );
};

DuplicateLineItemActions.propTypes = {
  loading: PropTypes.bool,
  onDuplicateLineItem: PropTypes.func,
  onRemoveLineItem: PropTypes.func,
};
export default DuplicateLineItemActions;
