import React from 'react';
import { Helmet } from 'react-helmet';

import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = () => {
  return (
    <>
      <Helmet title="Reset Your Password">
        <meta
          name="description"
          content="Forgot your password? Use this form to reset your password. If there is an account associated with the password we'll send you a reset link."
        />
        <body className="bg-dark" />
      </Helmet>

      <ForgotPasswordForm />
    </>
  );
};

export default ForgotPassword;
