import { useEffect } from 'react';

const usePreloadedImages = images => {
  /**
   * This is a hack to preload all of the images in
   * the slider before they are displayed.
   * Stack overflow post https://stackoverflow.com/questions/42615556/how-to-preload-images-in-react-js
   */
  useEffect(() => {
    images.forEach(image => {
      const img = new Image();
      img.src = image;
    });
  }, [images]);
};

export default usePreloadedImages;
