import { compact, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { FormCountries, FormField, FormStates } from 'fr-shared/components';
import { useAddresses, useForm } from 'fr-shared/hooks';
import { AddressTypeEnum } from 'fr-shared/lib/address';
import { COUNTRIES } from 'fr-shared/lib/countries';

import OrderAddressSelect from './OrderAddressSelect';

const formatAddress = address => {
  const orderedFields = [
    address.name_line_1,
    address.name_line_2,
    address.street_line_1,
    address.street_line_2,
    address.city,
    address.state,
    address.postal_code,
    address.country,
    address.phone_number,
    address.email,
  ];
  return compact(orderedFields).join(', ');
};

const OrderShippingFields = ({
  customerContactId,
  disabled,
  fieldPrefix,
  initialAddress,
  readonly,
}) => {
  const { values, setFieldValue } = useForm();
  const { defaultAddress, addresses } = useAddresses(customerContactId, AddressTypeEnum.Shipping);

  const namePrefix = fieldPrefix ? `${fieldPrefix}.shipping_address` : 'shipping_address';
  const fieldName = field => `${namePrefix}.${field}`;

  const isNew = !(fieldPrefix ? get(values, fieldPrefix).id : values.id);

  useEffect(() => {
    if (!isNew || readonly) return;

    let fieldAddress = initialAddress || defaultAddress;

    if (fieldAddress) setFieldValue(namePrefix, fieldAddress);
  }, [defaultAddress, initialAddress, isNew, namePrefix, readonly, setFieldValue]);

  const handleSelectAddress = addressAttrs => {
    setFieldValue(namePrefix, addressAttrs.address);
  };

  const handleNameOverwrite = name => {
    setFieldValue(fieldName('name_line_1'), name);
  };

  return (
    <>
      <OrderAddressSelect
        disabled={disabled}
        dropdownOptions={addresses}
        formatOptions={formatAddress}
        label="Full Name"
        name={fieldName('name_line_1')}
        onChange={handleSelectAddress}
        onCreateOption={handleNameOverwrite}
        readonly={readonly}
        required={true}
      />
      <FormField
        disabled={disabled}
        label="Company"
        name={fieldName('name_line_2')}
        readonly={readonly}
      />
      <FormCountries
        disabled={disabled}
        label="Country"
        name={fieldName('country')}
        readonly={readonly}
        required={true}
      />
      <FormField
        disabled={disabled}
        label="Address"
        name={fieldName('street_line_1')}
        readonly={readonly}
        required={true}
      />
      <FormField
        disabled={disabled}
        label="Address 2"
        name={fieldName('street_line_2')}
        readonly={readonly}
      />
      <FormField
        disabled={disabled}
        label="City"
        name={fieldName('city')}
        readonly={readonly}
        required={true}
      />
      {get(values, fieldName('country')) === COUNTRIES.UNITED_STATES ? (
        <FormStates
          disabled={disabled}
          label="State"
          name={fieldName('state')}
          readonly={readonly}
          required={true}
        />
      ) : (
        <FormField
          disabled={disabled}
          label="State"
          name={fieldName('state')}
          readonly={readonly}
          required={true}
        />
      )}
      <FormField
        disabled={disabled}
        label="Zip"
        name={fieldName('postal_code')}
        readonly={readonly}
        required={true}
      />
      <FormField
        disabled={false}
        label="Phone Number"
        name={fieldName('phone_number')}
        readonly={readonly}
        required={true}
      />
      <FormField disabled={false} label="Email" name={fieldName('email')} readonly={readonly} />
    </>
  );
};

OrderShippingFields.defaultProps = {
  fieldPrefix: '',
};

OrderShippingFields.propTypes = {
  disabled: PropTypes.bool,
  fieldPrefix: PropTypes.string,
  initialAddress: PropTypes.string,
  initialValues: PropTypes.object,
  readonly: PropTypes.bool,
  customerContactId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default OrderShippingFields;
