import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Alert } from 'fr-shared/components';
import { UserContext, useUserAnalyticsContext } from 'fr-shared/context';

import { Loading, Page, PageHeader } from 'portal/components';

import Onboarding from '../onboarding';
import RecentOrders from './components/RecentOrders/RecentOrders';
import RecentParts from './components/RecentParts/RecentParts';
import RecentQuotes from './components/RecentQuotes/RecentQuotes';
import StudioCta from './components/StudioCta/StudioCta';

const Dashboard = () => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [recentOrders, setRecentOrders] = useState([]);
  const [recentQuotes, setRecentQuotes] = useState([]);
  const [contactSubmitted, setContactSubmitted] = useState(false);
  const [recentParts, setRecentParts] = useState([]);
  const userAnalyticsRef = useRef(useUserAnalyticsContext());

  const search = useLocation().search;
  const history = useHistory();

  useEffect(() => {
    Promise.all([
      api.get('/customer_portal/quotes?page=1&page_size=4'),
      api.get('/customer_portal/orders?is_active=true&page=1&page_size=4'),
      api.get('/customer_portal/parts?page_size=4'),
    ])
      .then(([quotes, orders, parts]) => {
        setRecentQuotes(quotes.data);
        setRecentOrders(orders.data);
        setRecentParts(parts.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Track once when loaded
  useEffect(() => {
    userAnalyticsRef.current.track('Dashboard page viewed');
  }, [userAnalyticsRef]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const fromContactUs = queryParams.get('contactsuccess');

    if (fromContactUs) {
      queryParams.delete('contactsuccess');
      history.replace({
        search: queryParams.toString(),
      });
      setContactSubmitted(true);
    }
  }, [search, history]);

  const hasOrders = recentOrders.length !== 0;
  const hasQuotes = recentQuotes.length !== 0;
  const hasParts = recentParts.length !== 0;

  return (
    <>
      <Page>
        {contactSubmitted && (
          <Alert color="primary" toggle={true}>
            {'Thanks. We’ll get back to you within 4 business hours.'}
          </Alert>
        )}
        <PageHeader
          dataTestId="dashboard--page-header"
          subtitle="Here are your orders, quotes and parts at a glance."
          title={`Welcome, ${user.name}`}
        />

        {loading ? (
          <Loading />
        ) : (
          <>
            <StudioCta />
            <RecentOrders recentOrders={recentOrders} hasOrders={hasOrders} />
            <RecentQuotes hasQuotes={hasQuotes} recentQuotes={recentQuotes} />
            <RecentParts hasParts={hasParts} recentParts={recentParts} />
          </>
        )}
      </Page>

      <Onboarding logInEventsCount={user?.log_in_events_count} />
    </>
  );
};

export default Dashboard;
