import { useFormikContext } from 'formik';
import { find, get, groupBy, isEmpty, keys } from 'lodash';

import { api } from 'fr-shared/api';
import { useManufacturingProcess } from 'fr-shared/hooks';
import {
  MANUFACTURING_PROCESSES,
  mfgProcessesRequiringColor,
} from 'fr-shared/lib/manufacturing_process';

const groupMaterialsByType = materials => {
  const groupedMaterials = groupBy(materials, 'type');
  const formattedGroups = keys(groupedMaterials).map(key => ({
    label: key,
    options: groupedMaterials[key],
  }));

  return formattedGroups;
};

const useFormManufacturingProcess = (fieldPrefix, onChange, buildPackUIEnabled) => {
  const formik = useFormikContext();
  const { data: manufacturingProcesses, refetch: refetchMfgProcesses } =
    useManufacturingProcess();

  const lineItem = buildPackUIEnabled
    ? get(formik.values, `${fieldPrefix}.build_package.processes.0`)
    : get(formik.values, fieldPrefix);

  const processFieldPrefix = buildPackUIEnabled
    ? `${fieldPrefix}.build_package.processes.0`
    : fieldPrefix;

  const selectedProcess = find(manufacturingProcesses, {
    id: parseInt(lineItem?.manufacturing_process_id),
  });

  const materials = get(selectedProcess, 'materials', []);

  const formattedMaterials = materials.map(material => ({
    label: material.name,
    value: material.id.toString(),
    type: get(material, 'material_type.type', 'Other'),
  }));
  const groupedMaterials = groupMaterialsByType(formattedMaterials);

  const selectedMaterial =
    lineItem.material_id && find(materials, { id: parseInt(lineItem.material_id) });

  const layerThicknesses = selectedMaterial && selectedMaterial.layer_thicknesses;

  const finishes = get(selectedMaterial, 'finishes', []).sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const selectedFinish =
    lineItem.finish_id && find(finishes, { id: parseInt(lineItem.finish_id) });

  const colors = (
    isEmpty(selectedFinish?.colors)
      ? get(selectedMaterial, 'colors', [])
      : get(selectedFinish, 'colors', [])
  ).sort((a, b) => a.name.localeCompare(b.name));

  const handleSearchMaterials = (value, callback) => {
    callback(
      formattedMaterials.filter(material =>
        material.label.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const showHexColorField = selectedMaterial => {
    return ['UMA 90', 'Color Base'].includes(selectedMaterial);
  };

  const handleCancelSaveMaterial = () => {
    formik.setFieldValue(`${processFieldPrefix}.material_id`, null);
    formik.setFieldValue(`${processFieldPrefix}.material`, null);
  };

  const handleSaveMaterial = material => {
    return api
      .post('/materials', {
        material: { name: material, manufacturing_process_id: selectedProcess.id, custom: true },
      })
      .then(res => {
        formik.setFieldValue(`${processFieldPrefix}.material_id`, res.data.id);
        formik.setFieldValue(`${processFieldPrefix}.material`, res.data);
        handleMaterialChange({ target: { value: res.data.id, label: res.data.name } });
        refetchMfgProcesses();
      });
  };

  const handleProcessChange = e => {
    const newLineItem = {
      ...get(formik.values, processFieldPrefix),
      manufacturing_process_id: e.target.value,
      material_id: null,
      material: null,
      infill: null,
      build_time: null,
      color_id: null,
      color: null,
      finish_id: null,
      finish: null,
      hex_color: null,
      layer_thickness_id: null,
      layer_thickness: null,
    };

    if (e.target.value) {
      const process = find(manufacturingProcesses, { id: parseInt(e.target.value) });
      newLineItem.manufacturing_process = process;

      if (process.name === MANUFACTURING_PROCESSES.FDM) {
        newLineItem.infill = 'Standard';
      }
    } else {
      newLineItem.manufacturing_process = null;
    }

    formik.setFieldValue(processFieldPrefix, newLineItem);
    formik.setFieldValue(`${processFieldPrefix}.type`, newLineItem.manufacturing_process?.name);
    onChange && onChange(newLineItem);
  };

  const handleMaterialChange = e => {
    const newLineItem = {
      ...get(formik.values, processFieldPrefix),
      material_id: e.target.value,
      build_time: null,
      color_id: null,
      color: null,
      finish_id: null,
      finish: null,
      finish_note: null,
      hex_color: null,
      layer_thickness_id: null,
      layer_thickness: null,
    };

    if (e.target.value) {
      const newMaterial = find(materials, { id: parseInt(newLineItem.material_id) });
      // if the material_id is not found in materials, this is a newly created material
      // from <FormSelectCreate /> so we use the e.target.label returned from it's onChange
      // callback to populate the object below
      newLineItem.material = {
        id: newLineItem.material_id,
        name: newMaterial ? newMaterial.name : e.target.label,
      };

      // default to 0.01 layer thickness
      if (get(newMaterial, 'layer_thicknesses.length', 0) > 0) {
        const defaultThickness = newMaterial.layer_thicknesses.find(lt => lt.value === '0.01');
        if (defaultThickness) {
          newLineItem.layer_thickness_id = defaultThickness.id;
          newLineItem.layer_thickness = defaultThickness;
        }
      }
    } else {
      newLineItem.material = null;
    }

    formik.setFieldValue(processFieldPrefix, newLineItem);
    onChange && onChange(newLineItem);
  };

  const handleClearFinish = () => {
    const newLineItem = {
      ...get(formik.values, processFieldPrefix),
      finish_id: null,
      finish: null,
      color_id: null,
      color: null,
      hex_color: null,
    };

    formik.setFieldValue(processFieldPrefix, newLineItem);
    onChange && onChange(newLineItem);
  };

  const handleFinishChange = e => {
    const newLineItem = {
      ...get(formik.values, processFieldPrefix),
      finish_id: parseInt(e.target.value),
      finish: null,
      color_id: null,
      color: null,
      hex_color: null,
    };

    if (!isEmpty(e.target.value)) {
      const newFinish = find(finishes, { id: parseInt(e.target.value) });
      newLineItem.finish = {
        id: newLineItem.finish_id,
        name: newFinish.name,
      };
    }

    formik.setFieldValue(processFieldPrefix, newLineItem);
    onChange && onChange(newLineItem);
  };

  const handleColorChange = e => {
    const selectedColorName = e.target.options
      ? e.target.options[e.target.selectedIndex].text
      : e.target.label;

    const newLineItem = {
      ...get(formik.values, processFieldPrefix),
      color_id: e.target.value,
      color: { name: selectedColorName, id: e.target.value },
    };
    formik.setFieldValue(processFieldPrefix, newLineItem);
    onChange && onChange(newLineItem);
  };

  const handleLayerThicknessChange = e => {
    const selectedLayerThickness = find(selectedMaterial.layer_thicknesses, {
      id: parseInt(e.target.value),
    });

    const newLineItem = {
      ...get(formik.values, processFieldPrefix),
      layer_thickness_id: e.target.value,
      layer_thickness: selectedLayerThickness,
    };
    formik.setFieldValue(processFieldPrefix, newLineItem);
    onChange && onChange(newLineItem);
  };

  const isColorRequired = mfgProcessesRequiringColor.includes(selectedProcess?.name);

  return {
    colors,
    finishes,
    formattedMaterials:
      selectedProcess?.name === MANUFACTURING_PROCESSES.CNC
        ? groupedMaterials
        : formattedMaterials,
    handleCancelSaveMaterial,
    handleClearFinish,
    handleColorChange,
    handleFinishChange,
    handleLayerThicknessChange,
    handleMaterialChange,
    handleProcessChange,
    handleSaveMaterial,
    handleSearchMaterials,
    isColorRequired,
    layerThicknesses,
    manufacturingProcesses,
    materials,
    selectedMaterial,
    selectedProcess,
    selectedFinish,
    showHexColorField,
  };
};

export default useFormManufacturingProcess;
