import React, { ReactNode, useContext, useState } from 'react';

import { api } from 'fr-shared/api';
import { Button, Modal } from 'fr-shared/components';
import { AlertContext, UserContext } from 'fr-shared/context';

interface RequestPaymentModalProps {
  customerContact: CustomerContact;
  isOpen: boolean;
  quote?: Quote;
  toggle: () => void;
}

export default function RequestPaymentModal({
  customerContact,
  isOpen,
  quote,
  toggle,
}: RequestPaymentModalProps): ReactNode {
  const { setAlert } = useContext(AlertContext);
  const { user }: UserContext = useContext(UserContext);

  const [isSending, setIsSending] = useState(false);

  const { id, public_id } = quote || {};

  const handleSendEmailClicked = () => {
    setIsSending(true);
    api
      .post('/payment_methods/send_token_email', {
        customer_contact_id: customerContact.id,
        quote_id: id,
      })
      .then(() => {
        setAlert({ message: 'Successfully sent email' });
      })
      .catch(() => {
        setAlert({
          message: 'Email failed to send. Please reload and try again',
          color: 'danger',
        });
      })
      .finally(() => {
        setIsSending(false);
        toggle();
      });
  };

  if (!customerContact) return null;

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header title="Request Payment Information" onClose={() => toggle()} />
      <Modal.Body>
        <div>
          This will send a link to the customer&apos;s email{' '}
          <strong>{customerContact.email}</strong> from your email address to input their credit
          card information{public_id && ' for this quote'}.
        </div>
        <br />
        <div>
          <strong>The email will read:</strong>
        </div>
        <br />
        <div>Hello {customerContact.name},</div>
        <br />
        <div>
          Please go <u>here</u> to securely enter your payment information
          {public_id && ` for Quote #${public_id}`}. This link is valid for 24 hours.
        </div>
        <br />
        <div>
          Your card will be charged when your order is confirmed. If you have any issues, please
          contact me directly.
        </div>
        <br />
        <div>Thank you,</div>
        <div> {user.name} </div>
      </Modal.Body>
      <Modal.Footer>
        <Button loading={isSending} color="primary" onClick={handleSendEmailClicked}>
          Send Email
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
