import { FieldArray } from 'formik';
import { merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import Dropzone from 'react-dropzone';

import { Button, FormFieldBase, IconFont } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { useS3FilesUpload } from 'fr-shared/hooks';
import { TWO_D_FILE_EXTENSIONS } from 'fr-shared/utils/files';

import { SupportedFiles } from 'portal/components';

import TwoDFileDocItem from '../TwoDFileDocItem/TwoDFileDocItem';
import styles from './TwoDFileDocuments.module.css';

const TwoDFileDocuments = ({ onUpload, setFieldValue, supportingDocs }) => {
  const { closeAlert, setAlert } = useContext(AlertContext);
  const [uploadedDocs, uploadSupportingDocuments] = useS3FilesUpload('/s3/sign/portal_document');

  const setErrorAlert = () =>
    setAlert({
      color: 'danger',
      message:
        'Unfortunately, we could not upload your file since the format was not accepted. See "Supported file types" below to see what file formats you can upload.',
    });

  const handleDrop = acceptedFiles => {
    closeAlert(); // in case an alert is showing due to a previously bad upload

    if (!acceptedFiles || acceptedFiles.length === 0) return setErrorAlert();

    if (onUpload) onUpload();

    const uploadedFiles = acceptedFiles.map(file => ({ file }));
    const loadingFiles = uploadSupportingDocuments(uploadedFiles);
    setFieldValue('supportingDocuments', [...supportingDocs, ...loadingFiles]);
  };

  useEffect(() => {
    if (uploadedDocs.length === 0) return;
    if (uploadedDocs.find(doc => doc.upload_status === 'error')) return setErrorAlert();

    const newDocs = supportingDocs.map(doc => {
      const matchingDoc = uploadedDocs.find(ud => ud.uuid === doc.uuid);

      if (matchingDoc && matchingDoc.upload_status === 'success') {
        return merge(doc, {
          data: matchingDoc.data,
          upload_status: matchingDoc.upload_status,
          url: URL.createObjectURL(matchingDoc.file),
        });
      } else {
        return doc;
      }
    });

    setFieldValue('supportingDocuments', newDocs);
  }, [uploadedDocs]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormFieldBase className={`${styles.FormFieldBase} ${styles.FormFieldBaseExtraSpecificity}`}>
      <FieldArray name="supportingDocuments">
        {({ remove }) => (
          <div>
            {supportingDocs.map((doc, docIndex) => (
              <TwoDFileDocItem key={docIndex} doc={doc} onDelete={() => remove(docIndex)} />
            ))}
          </div>
        )}
      </FieldArray>

      <Dropzone accept={TWO_D_FILE_EXTENSIONS} onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} id="file-dropzone" />

            <div className={styles.SupportingDocs}>
              <div className={`${styles.UploadText} mb-2 font-size-sm text-center`}>
                Add any supporting documents, like part images
              </div>

              <Button
                className={`${styles.UploadFilesButton} flex flex-row`}
                outline={true}
                size="sm"
              >
                <IconFont className={styles.PlusIcon} name="plus" />
                <p>Add Documents</p>
              </Button>
            </div>
          </div>
        )}
      </Dropzone>

      <SupportedFiles className="font-size-md mt-2" isFor2DFiles />
    </FormFieldBase>
  );
};

TwoDFileDocuments.propTypes = {
  onUpload: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  supportingDocs: PropTypes.array,
};

export default TwoDFileDocuments;
