import { omit, pick, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'fr-shared/components';
import { keyboardDown } from 'fr-shared/utils';

const CostingSearch = ({ filters, setFilters, onTextSearch, showOpportunitySearch = false }) => {
  const sanitizedFilters = pick(filters, [
    'customer_name',
    'description',
    'id',
    'manufacturing_process_id',
    'opportunity_id',
    'public_id',
    'sales_contact_id',
    'state',
  ]);

  const filterLabels = {
    customer_name: 'Customer',
    description: 'File Name',
    id: 'Costing Request ID',
    manufacturing_process_id: 'MFG Process ID',
    opportunity_id: 'Opportunity ID',
    public_id: 'Public ID',
    sales_contact_id: 'Sales Contact ID',
    state: 'State',
  };

  const visibleFilters = pickBy(sanitizedFilters);

  const handleRemoveFilter = filter => {
    setFilters(omit(filters, filter));
  };

  return (
    <div className="page-actions mb-0">
      <div className="flex justify-content-end align-items-center">
        {showOpportunitySearch && (
          <div className="relative mr-2">
            <input
              type="text"
              name="opportunity_id"
              className="form-control"
              placeholder="Search by Opportunity"
              value={filters.opportunity_id || ''}
              onChange={onTextSearch}
            />
          </div>
        )}
        <div className="relative mr-2">
          <input
            type="text"
            name="public_id"
            className="form-control"
            placeholder="Search by Public ID or Deal #"
            style={{ width: 250 }}
            value={filters.public_id || ''}
            onChange={onTextSearch}
          />
        </div>
        <div className="relative mr-2">
          <input
            type="number"
            name="id"
            className="form-control"
            placeholder="Search by Request #"
            value={filters.id || ''}
            onChange={onTextSearch}
          />
        </div>
        <div className="relative mr-2">
          <input
            type="text"
            name="customer_name"
            className="form-control"
            placeholder="Search by Customer"
            value={filters.customer_name || ''}
            onChange={onTextSearch}
          />
        </div>
        <div className="relative mr-2">
          <input
            type="text"
            name="description"
            className="form-control"
            placeholder="Search by File Name"
            value={filters.description || ''}
            onChange={onTextSearch}
          />
        </div>
      </div>
      {Object.keys(visibleFilters).length > 0 && (
        <div className="flex justify-content-end">
          <h5>
            <Icon name="filter" className="text-primary" right />
            Applied Filters
          </h5>
        </div>
      )}
      <div className="flex flex-wrap justify-content-end">
        {Object.keys(visibleFilters).map((filter, index) => (
          <div
            key={index}
            className="filter-label delete ml-2"
            onClick={() => handleRemoveFilter(filter)}
            onKeyDown={evt => keyboardDown(evt, 'Enter', () => handleRemoveFilter(filter))}
            role="button"
            tabIndex={0}
          >
            <div>
              <strong>{filterLabels[filter]}</strong>: {filters[filter]}
            </div>
            <i />
          </div>
        ))}
      </div>
    </div>
  );
};

CostingSearch.propTypes = {
  filters: PropTypes.object,
  onTextSearch: PropTypes.func,
  setFilters: PropTypes.func,
  showOpportunitySearch: PropTypes.bool,
};

export default CostingSearch;
