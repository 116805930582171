import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, Button, FormField, Helmet } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';

const NewUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const handleSubmit = (values, formikBag) => {
    setIsLoading(true);
    api
      .post('/users', {
        user: values,
        organization_id: 1,
      })
      .then(res => {
        const { email } = res.data;
        setAlert({ message: `Successfully added user ${email}` });
      })
      .catch(err => {
        if (err.response?.data) {
          const { errors, messages } = err.response.data;
          const formikErrors = { server: messages, ...errors };
          formikBag.setErrors(formikErrors);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Helmet title="New User" />
      <Breadcrumb to="/admin/users">Users</Breadcrumb>
      <Breadcrumb to="/admin/users/new">New User</Breadcrumb>

      <div className="page-header">
        <div className="container">
          <div>
            <h2>Add New Fast Radius User</h2>
          </div>
          <div className="page-actions">
            <Button color="secondary" to={`/admin`}>
              Back
            </Button>
          </div>
        </div>
      </div>

      <Formik onSubmit={handleSubmit} initialValues={{}}>
        <NewUserForm isLoading={isLoading} />
      </Formik>
    </>
  );
};

const NewUserForm = ({ isLoading }) => {
  return (
    <Form>
      <div className="container">
        <div className="row">
          <div className="col">
            <FormField label="Email" name="email" required={true} />
          </div>
          <div className="col">
            <FormField label="First Name" name="first_name" required={true} />
          </div>
          <div className="col">
            <FormField label="Last Name" name="last_name" required={true} />
          </div>
        </div>
        <div className="row">
          <Button className="ml-auto mt-2" type="submit" color="success" loading={isLoading}>
            Submit
          </Button>
        </div>
      </div>
    </Form>
  );
};

NewUserForm.propTypes = {
  isLoading: PropTypes.bool,
};

export default NewUser;
