import { Field, FieldArray, connect } from 'formik';
import { every, sumBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Alert, Button, Card, Icon, OrderPicker } from 'fr-shared/components';
import { ORDER_STATES } from 'fr-shared/lib/orders';
import { getPercent, percentColor } from 'fr-shared/utils';

import WorkOrderLineItem from './WorkOrderLineItem';

const WorkOrderLines = ({ formik, handleAddLine, handleOrderChange, canEdit, showLineItems }) => {
  const [showOrderPicker, setShowOrderPicker] = useState(formik.values.state === 'Draft');
  const { order_line_items, line_items } = formik.values;
  const lineItemErrors = formik.errors.line_items;

  const filteredOrderLineItems = order_line_items
    .filter(oli => oli.state !== ORDER_STATES.Canceled)
    .filter(oli => every(line_items, woli => woli.order_line_item_id !== oli.id));

  const totalQuantity = () => line_items.reduce((total, oli) => total + oli.quantity, 0);

  return (
    <div>
      {showOrderPicker && (
        <div className="card card-border bg-light mb-4">
          <div className="p-3">
            <p className="text-muted">Choose an order to add items to the Work Order</p>
            <OrderPicker name="public_id" onChange={handleOrderChange} />
          </div>
          {showLineItems && (
            <FieldArray name="order_line_items">
              {() => (
                <>
                  {filteredOrderLineItems.map((oli, index) => {
                    return (
                      <WorkOrderLineItem data={{ order_line_item: oli }} key={index}>
                        <div className="ml-auto flex align-items-center">
                          <span>
                            <div className="mx-3">
                              <small>QUANTITY - </small> {oli.quantity}
                            </div>
                            <div className="mx-3">
                              <small>OPTIMAL - </small> {oli.build_quantity_optimal || 'N/A'}
                            </div>
                          </span>
                          <Button
                            color="secondary"
                            type="button"
                            onClick={() => handleAddLine(oli)}
                            dataTestId={`add-line-item-${index}`}
                          >
                            <Icon name="plus" />
                          </Button>
                        </div>
                      </WorkOrderLineItem>
                    );
                  })}
                </>
              )}
            </FieldArray>
          )}
        </div>
      )}

      <Card className="mb-4" border={true}>
        {lineItemErrors && <Alert color="danger"> {lineItemErrors} </Alert>}
        <Card.Header>
          Work Order Lines
          {canEdit && (
            <Card.Actions>
              <Button
                className="d-none d-md-inline-block"
                size="sm"
                color="secondary"
                onClick={() => setShowOrderPicker(!showOrderPicker)}
              >
                {showOrderPicker ? 'Hide Order Lines' : 'Edit Order Lines'}
              </Button>
            </Card.Actions>
          )}
        </Card.Header>
        <FieldArray name="line_items">
          {arrayHelpers => (
            <>
              {line_items.map((line, i) => {
                const percent = getPercent(line.quantity, sumBy(line.part_failures, 'quantity'));
                return (
                  <WorkOrderLineItem data={line} key={i}>
                    <div className="flex flex-1 align-items-center justify-content-end justify-content-md-start">
                      {showOrderPicker && (
                        <>
                          <div className="form-group flex mb-0">
                            <div className="ml-3 mr-2 pt-1">
                              <small>QUANTITY</small>
                            </div>
                            <Field
                              type="number"
                              name={`line_items.${i}.quantity`}
                              placeholder="Quantity"
                              disabled={showOrderPicker}
                            >
                              {({ field }) => (
                                <input
                                  type="number"
                                  className="form-control fake-disabled"
                                  disabled={!showOrderPicker}
                                  {...field}
                                />
                              )}
                            </Field>
                          </div>
                          <Button
                            outline={true}
                            color="danger"
                            onClick={() => arrayHelpers.remove(i)}
                            className="ml-2"
                          >
                            <Icon name="minus" />
                          </Button>
                        </>
                      )}

                      {line_items.find(l => l.part_failures) && !showOrderPicker && (
                        <div className="ml-3 text-right flex flex-row flex-md-column">
                          <span className="flex align-items-center">
                            <span className="text-muted">QTY-</span>
                            <span className="font-size-lg">{line.quantity}</span>
                          </span>
                          <div
                            className="ml-2 font-size-lg"
                            style={{ color: percentColor(percent) }}
                          >
                            {percent}%
                          </div>
                        </div>
                      )}
                    </div>
                  </WorkOrderLineItem>
                );
              })}
            </>
          )}
        </FieldArray>
        <Card.Footer className="text-right">
          <span className="text-muted">TOTAL-</span>
          <span className="font-size-lg">{totalQuantity()}</span>
        </Card.Footer>
      </Card>
    </div>
  );
};

WorkOrderLines.propTypes = {
  formik: PropTypes.object,
  canEdit: PropTypes.bool,
  handleAddLine: PropTypes.func,
  handleOrderChange: PropTypes.func,
  showLineItems: PropTypes.bool,
};

export default connect(WorkOrderLines);
