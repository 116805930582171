import React from 'react';

import { Carousel, IconFont, ProgressCircle, classNames } from 'fr-shared/components';

import { Card } from 'portal/components';
import { useAnalysisProgress } from 'portal/hooks/useAnalysisProgress';
import { Part } from 'portal/lib/cart';

import styles from './AnalysisProgress.module.css';

type Props = {
  part: Part;

  /**
   * The progress amount in the range [0, 100] to show when there is no progress
   * in any of the conversion or analysis stages.
   */
  baseProgressAmount?: number;

  /**
   * Callback that executes when analysis of the part file revision has
   * completed.
   */
  onCompleted?: () => void;
};

/**
 * Renders the amount of progress made towards analyzing the specified part file
 * revision.
 */
export const AnalysisProgress = ({ part, baseProgressAmount = 0, onCompleted }: Props) => {
  const progressPercentage = useAnalysisProgress(onCompleted, part, {
    baseProgressAmount,
    conversionWeight: 1,
    dimensionsWeight: 1,
    featuresWeight: 1,
  });

  const carouselContent = [
    <>
      <h2>What happens next?</h2>
      <p>
        We&apos;ll run our automated DFM checks. DFM (Design for Manufacturability) checks tell
        you if anything in your designs could be difficult to manufacture, before you go into
        production.
      </p>
    </>,
    <>
      <h2>Get cost estimates</h2>
      <p>
        {
          "When you choose your manufacturing process and material, we'll create cost estimates. These predict the cost per part and the most cost-effective number of parts for your order."
        }
      </p>
    </>,
    <>
      <h2>Compare your materials</h2>
      <p>
        You can explore performance metrics for your chosen materials, like density and
        elongation. Then compare materials across manufacturing processes, to see what meets your
        needs best.
      </p>
    </>,
  ];

  return (
    <div className={styles.PageContainer}>
      <Card border={true} className={classNames([styles.Root, 'flex-grow-1 p-4'])}>
        <div
          className={classNames([
            styles.TopContainer,
            'flex flex-wrap justify-content-between mb-2 pb-3',
          ])}
        >
          <ProgressCircle percentage={progressPercentage} />
          <div className={styles.TopText}>
            <h1 className="font-size-lg">We&apos;re analyzing your files</h1>
            <p className="font-size-md">
              Large part files can take a while to process. Feel free to leave this with us, for
              now. We&apos;ll email you as soon as your parts are ready to review.
            </p>
          </div>
        </div>
        <div className="flex justify-content-between">
          <IconFont
            className={classNames([styles.Lightbulb, 'mr-2 mt-1 font-size-xl'])}
            name="lightbulb"
          />
          <div className={styles.Carousel}>
            <Carousel content={carouselContent} paginationPosition={'end'} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AnalysisProgress;
