import braintree from 'braintree-web';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { IconFont } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';

import { FormFieldBase, Loading } from 'portal/components';

const BraintreeHostedFields = ({ authToken, onHostedFieldInstanceChange }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const formik = useFormikContext();

  const { setAlert } = useContext(AlertContext);
  const history = useHistory();

  useEffect(() => {
    api
      .get(`/braintree_token_with_token?token=${authToken}`)
      .then(res => {
        braintree.client
          .create({
            authorization: res.data,
          })
          .then(clientInstance => {
            const options = {
              client: clientInstance,
              fields: {
                number: {
                  selector: '#cc-number',
                  placeholder: '4111 1111 1111 1111',
                },
                cvv: {
                  selector: '#cvv',
                  placeholder: 'cvv',
                },
                expirationDate: {
                  selector: '#expirationDate',
                  placeholder: 'MM/YY',
                },
              },
            };

            return braintree.hostedFields.create(options);
          })
          .then(instance => {
            onHostedFieldInstanceChange(instance);
          })
          .catch(() => {
            setError('Secure credit card form failed to load. Please reload and try again');
            setAlert({
              color: 'danger',
              message: 'Secure credit card form failed to load. Please reload and try again',
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch(() => {
        setAlert({
          color: 'danger',
          message: 'Authorization failed',
        });

        history.push('/');
      });
  }, [authToken, onHostedFieldInstanceChange, setAlert, history]);

  return (
    <>
      {isLoading && !error && (
        <span>
          <Loading /> Loading Secure Credit Card Form
        </span>
      )}
      {error && (
        <span className="text-danger text-center">
          <IconFont name="error-triangle" color="danger" /> {error}
        </span>
      )}
      <div className={isLoading || error ? 'd-none' : ''}>
        <div className="row">
          <div className="col">
            <HostedField
              fieldName="cc-number"
              label="Card Number"
              error={formik.errors?.hosted_fields?.number}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <HostedField fieldName="cvv" label="CVV" error={formik.errors?.hosted_fields?.cvv} />
          </div>
          <div className="col">
            <HostedField
              fieldName="expirationDate"
              label="Expiration Date"
              error={formik.errors?.hosted_fields?.expirationDate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

BraintreeHostedFields.propTypes = {
  authToken: PropTypes.string,
  onHostedFieldInstanceChange: PropTypes.func,
};

const HostedField = ({ fieldName, label, error }) => (
  <FormFieldBase isStacked={true} name={fieldName} label={label} error={error}>
    <div className="form-control" id={fieldName} />
  </FormFieldBase>
);

HostedField.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
};

export default BraintreeHostedFields;
