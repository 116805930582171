import { Logo } from '@fast-radius/shared-ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { AlertProvider, useUserAnalyticsContext } from 'fr-shared/context';
import { MARKETING_URLS } from 'fr-shared/lib/marketing_urls';

import { AlertBanner, Card, VerifyEmailButton } from 'portal/components';
import styles from 'portal/index.module.css';

const VerifyEmailSentContainer = ({ location }) => {
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const userAnalytics = useUserAnalyticsContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => userAnalytics.track(`Verification`, { valid: true }), []);

  if (!email) {
    window.location = MARKETING_URLS.LandingPage;
  }

  return (
    <div className="theme-dark">
      <AlertProvider>
        <div className={styles.Unauthenticated}>
          <div className={styles.Centered}>
            <div>
              <AlertBanner />
              <Logo className="mt-2 mb-4" fill="white" />
              <Card size="lg">
                <h1 className="h6 text-light mb-4">Please check your inbox</h1>
                <div className="mb-1">
                  We&apos;ve sent a confirmation email to:{' '}
                  <div className="text-light">{email}</div>
                </div>
                <p>
                  Use the link in the email to confirm your new account and continue with your
                  quote.
                </p>
                <div>
                  Didn&apos;t get the email?{' '}
                  <VerifyEmailButton email={email}>Resend the email.</VerifyEmailButton>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </AlertProvider>
    </div>
  );
};

VerifyEmailSentContainer.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default VerifyEmailSentContainer;
