import { Field } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { classNames } from 'fr-shared/components';

import FormFieldBase from './FormFieldBase';

const removeUnderscores = value => {
  if (!value) {
    return value;
  }

  return value.replace(/_/g, '');
};

const FormCurrency = ({
  inputClassName,
  isStacked,
  label,
  name,
  onBlur,
  onFocus,
  onChange,
  placeholder,
  readonly,
  dataTestId,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const error = get(form.errors, name);

        const inputClassNames = classNames([
          'form-control',
          'flex',
          'flex-grow-1',
          'px-2',
          error && 'is-invalid',
          inputClassName,
        ]);

        const handleOnChange = e => {
          field.onChange(e);
          onChange && onChange(e);
        };

        return (
          <FormFieldBase
            name={name}
            prepend="$"
            readonly={readonly}
            isStacked={isStacked}
            error={error}
            label={label}
            {...props}
          >
            {readonly ? (
              <div id={name} aria-readonly={true}>
                <strong>{get(field, 'value.formatted', field.value)}</strong>
              </div>
            ) : (
              <MaskedInput
                className={inputClassNames}
                id={name}
                data-testid={dataTestId}
                mask={createNumberMask({ allowDecimal: true, decimalLimit: 4, prefix: '' })}
                name={name}
                onBlur={() => onBlur && onBlur(form.values)}
                onFocus={() => onFocus && onFocus(form.values)}
                onChange={handleOnChange}
                pipe={removeUnderscores}
                placeholder={placeholder}
                value={get(field, 'value.amount', field.value)}
              />
            )}
          </FormFieldBase>
        );
      }}
    </Field>
  );
};

FormCurrency.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  isStacked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  readonly: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  dataTestId: PropTypes.string,
};

export default FormCurrency;
