import React, { useContext, useEffect } from 'react';

import { AlertContext, UserContext } from 'fr-shared/context';

import { VerifyEmailButton } from 'portal/components';

const useUnverifiedAlert = () => {
  const { user } = useContext(UserContext);
  const { setAlert } = useContext(AlertContext);

  useEffect(() => {
    if (!user.verified) {
      setAlert({
        color: 'warning',
        autoClose: false,
        message: (
          <span>
            We&apos;ve emailed you a link to confirm your account. Check your inbox or we can{' '}
            <VerifyEmailButton email={user.email}>resend the email</VerifyEmailButton>.
          </span>
        ),
      });
    }
  }, [user, setAlert]);
};

export default useUnverifiedAlert;
