import * as Sentry from '@sentry/react';
import { Socket } from 'phoenix';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { SOCKET_ENDPOINT } from 'fr-shared/env';

const PhoenixSocketContext = createContext({ socket: null });

const createSocketConnection = setSocket => {
  api
    .get('/new_socket_token')
    .then(({ data }) => {
      const newSocket = new Socket(SOCKET_ENDPOINT, {
        params: { token: data.token },
      });

      newSocket.onError(() => {
        if (accessTokenIsExpired(newSocket)) {
          newSocket.onError(() => {});
          newSocket.disconnect();
          setSocket(null);
        }
      });

      setSocket(newSocket);
      newSocket.connect();
    })
    .catch(err => {
      setSocket(null);
      Sentry.setExtra('error', err);
      Sentry.captureMessage('Error creating Socket Connection');
    });
};

const accessTokenIsExpired = socket => {
  try {
    const token = socket.params().token;

    const claims = JSON.parse(window.atob(token.split('.')[1]));
    const now = ~~(Date.now() / 1000);
    return now > claims.exp;
  } catch {
    return true;
  }
};

const PhoenixSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (!socket) {
      createSocketConnection(setSocket);
    }
  }, [socket]);

  return (
    <PhoenixSocketContext.Provider value={{ socket }}>{children}</PhoenixSocketContext.Provider>
  );
};

PhoenixSocketProvider.propTypes = {
  children: PropTypes.node,
};

export { PhoenixSocketContext, PhoenixSocketProvider };
