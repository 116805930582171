import PropTypes from 'prop-types';
import React from 'react';

import { keyboardDown } from 'fr-shared/utils';

import { classNames } from 'portal/components';

import styles from './PartImage.module.css';
import PartPlaceholder from './PartPlaceholder';

export const PartImage = ({
  backdrop = true,
  className,
  onClick,
  size = 'md',
  src,
  width,
  height,
}) => {
  return (
    <div
      className={classNames([
        styles.PartImage,
        backdrop && styles.backdrop,
        !width && !height && styles[size],
        className,
      ])}
      onClick={onClick}
      onKeyDown={evt => keyboardDown(evt, 'Enter', onClick)}
      role="button"
      tabIndex={0}
    >
      {src ? (
        <img src={src} style={{ width: width, height: height }} alt="Part" />
      ) : (
        <PartPlaceholder width={width} height={height} />
      )}
    </div>
  );
};

PartImage.propTypes = {
  backdrop: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg']),
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PartImage;
