import React, { ReactNode } from 'react';

import { IconFont, classNames } from 'fr-shared/components';

interface AlertBoxTypes {
  className?: string;
  title?: string;
  icon?: string;
  tooltip?: string;
  children?: ReactNode;
}

const AlertBox = ({
  className,
  title,
  icon = 'error-triangle',
  tooltip,
  children,
}: AlertBoxTypes) => {
  return (
    <div className={classNames(['bg-coolGray-700 p-3 rounded-sm', className])}>
      <div className="flex align-items-center mb-2">
        <h5>
          {title}
          {tooltip && <IconFont name="info-circle" className="ml-1" tooltip={tooltip} />}
        </h5>
        <IconFont name={icon} className="font-size-xxl ml-auto" />
      </div>
      {children}
    </div>
  );
};

export default AlertBox;
