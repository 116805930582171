import React, { useCallback, useEffect, useState } from 'react';

import {
  Button,
  FormField,
  Icon,
  Loading,
  FormCurrency as UntypedFormCurrency,
} from 'fr-shared/components';

import ShippingChoice from './ShippingChoice';
import UnsupportedCasesModal from './UnsupportedCasesModal';

// TODO: Convert FormCurrency to Typescript
const FormCurrency = UntypedFormCurrency as React.FC<{ [key: string]: unknown }>;

interface ShippingEstimateSelectorProps {
  error?: ShippingError;
  hasShippingPrice: boolean;
  isLoading: boolean;
  onChangeShippingPrice: (...args: any[]) => any;
  onSelectShippingEstimate: (id: number, shippingMethod: string) => any;
  readonly: boolean;
  selectedShippingEstimate: ShippingEstimate;
  shippingEstimates: ShippingEstimate[];
  shippingMethods: ShippingMethod[];
}

const ShippingEstimateSelector = ({
  error = null,
  hasShippingPrice,
  isLoading,
  onChangeShippingPrice,
  onSelectShippingEstimate,
  readonly,
  selectedShippingEstimate,
  shippingEstimates,
  shippingMethods,
}: ShippingEstimateSelectorProps) => {
  const [showCouldNotCalculate, setShowCouldNotCalculate] = useState(false);
  const hasError = error !== null;
  const hasEstimates = !hasError && shippingEstimates.length > 0;

  const handleChangeShippingChoice = useCallback(
    (id: number, shippingMethod: string) => {
      onSelectShippingEstimate(id, shippingMethod);
    },
    [onSelectShippingEstimate]
  );

  const handleClickDismissMessage = useCallback(() => {
    setShowCouldNotCalculate(false);
  }, []);

  /*
   * If hasError changes and it's true, show the error message.
   */
  useEffect(() => {
    setShowCouldNotCalculate(hasError);
    if (hasError) {
      onSelectShippingEstimate(null, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);

  if (readonly) {
    return (
      <div className="px-2">
        {selectedShippingEstimate ? (
          <ShippingChoice readonly className="p-1" {...selectedShippingEstimate} />
        ) : (
          <div className="row font-size-md p-1">
            <div className="col">None Selected</div>
          </div>
        )}
        <FormCurrency
          label="Estimated Duties and Taxes Subtotal"
          name="estimated_duties_taxes"
          readonly
        />
      </div>
    );
  } else if (isLoading) {
    return (
      <div className="flex align-items-center justify-content-center font-size-lg my-5">
        <div className="mr-2">
          <Loading type="circle" className="font-size-lg" />
        </div>
        <div className="text-primary">Calculating Shipping...</div>
      </div>
    );
  } else if (hasError || hasShippingPrice) {
    return (
      <>
        {showCouldNotCalculate && (
          <div className="alert alert-warning" role="alert">
            <h4 className="alert-heading font-size-lg mb-2">
              <Icon name="exclamation-circle" right />
              Could not calculate shipping. Manually submit estimated shipping price.
            </h4>
            <p>This quote includes parts that are not supported by the shipping estimator.</p>
            <p>
              <UnsupportedCasesModal error={error} />
            </p>
            <div className="text-right">
              <Button
                color="link"
                onClick={handleClickDismissMessage}
                style={{ color: 'inherit' }}
              >
                <Icon name="times" right />
                Dismiss Message
              </Button>
            </div>
          </div>
        )}
        <div className="align-items-center">
          <FormCurrency
            label="Shipping Price"
            name="shipping_price"
            onBlur={onChangeShippingPrice}
          />
          <FormField
            label="Shipping Method"
            name="shipping_method"
            component="select"
            readonly={readonly}
            required={true}
          >
            <option value="" />
            {shippingMethods &&
              shippingMethods.map(shippingMethod => (
                <option key={shippingMethod} value={shippingMethod}>
                  {shippingMethod}
                </option>
              ))}
          </FormField>
          <FormCurrency
            label="Estimated Duties and Taxes Subtotal"
            name="estimated_duties_taxes"
            readonly
          />
        </div>
      </>
    );
  } else if (hasEstimates) {
    return (
      <>
        {shippingEstimates.map(estimate => (
          <ShippingChoice
            key={estimate.id}
            className="mb-2"
            checked={estimate.id === selectedShippingEstimate?.id}
            onChange={handleChangeShippingChoice}
            {...estimate}
          />
        ))}
        <FormCurrency
          label="Estimated Duties and Taxes Subtotal"
          name="estimated_duties_taxes"
          readonly
        />
      </>
    );
  } else {
    return (
      <p className="font-size-lg my-5 text-center text-muted">
        In order to see shipping options, select a shipping address or enter a shipping postal
        code, then press Calculate Shipping.
      </p>
    );
  }
};

export default ShippingEstimateSelector;
