import { connect } from 'formik';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { Button, FormField, FormMultiSelect, Modal } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { CRLI_REJECTED_CODES } from 'fr-shared/lib/crli_rejected_codes';
import { isFormikAtPrefixTheSame } from 'fr-shared/utils';

import CostingDocuments from './CostingDocuments';

const CostingRejectLineItemModal = ({
  fieldPrefix,
  formik,
  isEdit = false,
  onCancel = () => {},
  onConfirm = () => {},
  validateCanReject = () => {},
}) => {
  const { user } = useContext(UserContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openRejectionModal = get(formik.values, `${fieldPrefix}.openRejectionModal`);
  const lineItem = get(formik, `values.${fieldPrefix}`);

  const availableRejectedCodes = CRLI_REJECTED_CODES[lineItem?.manufacturing_process?.name];

  const codesToDropdownValues = codes => {
    return !isEmpty(codes) && availableRejectedCodes
      ? codes.map(code => ({ value: code, label: availableRejectedCodes[code] }))
      : [];
  };

  const initialRejectedCodeValues = codesToDropdownValues(
    lineItem?.rejected_codes?.map(codeSet => codeSet.rejected_code)
  );

  const [rejectedCodeValues, setRejectedCodeValues] = useState(initialRejectedCodeValues);
  const rejectedCodeError = get(formik.errors, `${fieldPrefix}.rejected_codes`);

  /**
   * This use effect makes it so the modal can be opened
   * from other components.
   */
  useEffect(() => {
    if (openRejectionModal) {
      setModalIsOpen(true);
      formik.setFieldValue(`${fieldPrefix}.openRejectionModal`, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openRejectionModal]);

  if (!user.canRejectCosts) return null;

  const editButton = (
    <Button onClick={() => setModalIsOpen(true)} color="secondary" outline size="sm">
      Edit Feedback
    </Button>
  );

  const rejectButton = (
    <Button
      onClick={() => {
        validateCanReject(() => setModalIsOpen(true));
      }}
      className="mr-2"
      color="danger"
      outline={true}
    >
      Reject Part
    </Button>
  );

  const checkAndRenderRejectedCodeIsEmptyError = () => {
    if (availableRejectedCodes && isEmpty(lineItem.rejected_codes)) {
      formik.setFieldError(`${fieldPrefix}.rejected_codes`, "can't be blank");
      return true;
    } else {
      formik.setFieldError(`${fieldPrefix}.rejected_codes`, null);
      return false;
    }
  };

  const handleCancel = toggle => {
    onCancel();
    toggle();
  };

  const handleConfirm = toggle => {
    if (checkAndRenderRejectedCodeIsEmptyError()) {
      return;
    }
    onConfirm();
    toggle();
  };

  const handleChange = e => {
    setRejectedCodeValues(e || []);

    lineItem.rejected_codes = (e || []).map(code => ({
      rejected_code: code.value,
    }));

    checkAndRenderRejectedCodeIsEmptyError();
  };

  return (
    <>
      {isEdit ? editButton : rejectButton}
      <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(!modalIsOpen)}>
        {({ toggle }) => (
          <>
            <Modal.Header title="Reject Part" onClose={toggle} />
            <div className="modal-body">
              {availableRejectedCodes && (
                <>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <span>
                        Required <span className="text-error">*</span>
                      </span>
                    </div>
                  </div>
                  <FormMultiSelect
                    error={rejectedCodeError}
                    isRequired
                    label="Reason"
                    id="rejected_codes"
                    name="rejected_codes"
                    options={codesToDropdownValues(Object.keys(availableRejectedCodes))}
                    placeholder="Select from list"
                    onChange={handleChange}
                    value={rejectedCodeValues}
                  />
                </>
              )}

              <FormField
                name={`${fieldPrefix}.rejected_reason`}
                label="Notes"
                stacked={true}
                inputStyle={{ minHeight: 150 }}
                component="textarea"
              />
              <CostingDocuments
                withLabel={false}
                fieldPrefix={fieldPrefix}
                fieldName="rejection_documents"
              />
            </div>
            <div className="modal-footer">
              <Button color="link" onClick={() => handleCancel(toggle)}>
                Cancel
              </Button>
              <Button
                onClick={() => handleConfirm(toggle)}
                color="primary"
                className="ml-2"
                dataTestId="crli-reject-button-in-modal"
              >
                Reject
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

CostingRejectLineItemModal.propTypes = {
  fieldPrefix: PropTypes.string,
  formik: PropTypes.object,
  isEdit: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  validateCanReject: PropTypes.func,
};

export default connect(React.memo(CostingRejectLineItemModal, isFormikAtPrefixTheSame));
