import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  AlertBanner,
  Breadcrumbs,
  GlobalErrorBoundary,
  Helmet,
  PrivateRoute,
} from 'fr-shared/components';
import { AlertProvider, PhoenixSocketProvider } from 'fr-shared/context';
import { useMaintenanceModeFeatureFlag } from 'fr-shared/hooks';

import ConnectivityCheck from './ConnectivityCheck';
import NavbarAdmin from './NavbarAdmin';
import VersionBanner from './VersionBanner';
import CostingCSVImports from './costing_csv_imports';
import CostingCSVImportNew from './costing_csv_imports/new';
import Costing from './costing_requests';
import CostingAllQueues from './costing_requests/all_queues';
import CostingClosedQueue from './costing_requests/closed_queue';
import CostingCompletedQueue from './costing_requests/completed_queue';
import CostingDraftQueue from './costing_requests/draft_queue';
import CostingEdit from './costing_requests/edit';
import CostingInProgressQueue from './costing_requests/in_progress_queue';
import CostingNew from './costing_requests/new';
import CostingRequestedQueue from './costing_requests/requested_queue';
import Customers from './customers/';
import CustomersSubscribed from './customers/components/CustomersSubscribed';
import ContactShow from './customers/contact';
import CustomerShow from './customers/show';
import LeadTimes from './factory/lead_times';
import LeadTimesNew from './factory/lead_times/new';
import MarginControl from './factory/margin_control';
import MarginControlEdit from './factory/margin_control/edit';
import BulkTraveler from './factory/pdfs/bulk_traveler';
import PackingSlips from './factory/pdfs/packing_slips';
import Printers from './factory/printers';
import PrinterEdit from './factory/printers/edit';
import PrinterNew from './factory/printers/new';
import RejectionReasons from './factory/rejection_reasons';
import RejectionReasonEdit from './factory/rejection_reasons/edit';
import RejectionReasonNew from './factory/rejection_reasons/new';
import Suppliers from './factory/suppliers';
import SuppliersEdit from './factory/suppliers/edit';
import SuppliersNew from './factory/suppliers/new';
import Viewer from './factory/viewer/';
import MaterialsNew from './inventory/materials/new';
import Orders from './orders/';
import OrderEdit from './orders/edit';
import OrderNew from './orders/new';
import OrderPublicID from './orders/public_id';
import OrderShow from './orders/show';
import OrganizationPartsContainer from './organizations/parts/index';
import OrganizationPartsNewContainer from './organizations/parts/new';
import QAReview from './qa/review';
import Quotes from './quotes';
import QuoteEdit from './quotes/edit';
import QuoteNew from './quotes/new';
import RolesEdit from './roles/edit';
import Shipments from './shipments';
import ShipmentsEdit from './shipments/edit';
import ShipmentsNew from './shipments/new';
import ShipmentsShow from './shipments/show';
import Webhooks from './smartsheets/webhooks';
import UsersNew from './users/new';
import WorkOrders from './work_orders/';
import WorkOrderEdit from './work_orders/edit';
import StateUpdater from './work_orders/state_updater';
import YieldReport from './work_orders/yield_report';

const AdminContainer = () => {
  const maintenanceModeFeatureFlagEnabled = useMaintenanceModeFeatureFlag();

  return (
    <div className="admin-container">
      <Helmet>
        <body className="theme-light" />
      </Helmet>
      <Switch>
        <Route path="/admin/viewer/:order_line_item_id" render={() => null} />
        <PrivateRoute path="/" component={NavbarAdmin} />
      </Switch>
      <GlobalErrorBoundary>
        <Switch>
          <Route path="/admin/viewer/:order_line_item_id" render={() => null} />
          <PrivateRoute path="/" component={Breadcrumbs} />
        </Switch>
        <PhoenixSocketProvider>
          <AlertProvider>
            <VersionBanner />
            <AlertBanner />
            <Switch>
              {maintenanceModeFeatureFlagEnabled && (
                <Redirect
                  from={['/admin/quotes', '/admin/quotes/*', '/admin/costing_requests/new']}
                  to="/maintenance"
                />
              )}
              <PrivateRoute exact path="/admin" component={Orders} />
              <PrivateRoute exact path="/admin/costing_requests" component={Costing} />
              <PrivateRoute exact path="/admin/costing_requests/new" component={CostingNew} />
              <PrivateRoute
                exact
                path="/admin/costing_csv_imports"
                component={CostingCSVImports}
              />
              <PrivateRoute
                exact
                path="/admin/costing_csv_imports/new"
                component={CostingCSVImportNew}
              />
              <PrivateRoute
                exact
                path="/admin/costing_requests/all_queues"
                component={CostingAllQueues}
              />
              <PrivateRoute
                exact
                path="/admin/costing_requests/draft_queue"
                component={CostingDraftQueue}
              />
              <PrivateRoute
                exact
                path="/admin/costing_requests/requested_queue"
                component={CostingRequestedQueue}
              />
              <PrivateRoute
                exact
                path="/admin/costing_requests/in_progress_queue"
                component={CostingInProgressQueue}
              />
              <PrivateRoute
                exact
                path="/admin/costing_requests/completed_queue"
                component={CostingCompletedQueue}
              />
              <PrivateRoute
                exact
                path="/admin/costing_requests/closed_queue"
                component={CostingClosedQueue}
              />
              <PrivateRoute path="/admin/costing_requests/:id(\d+)" component={CostingEdit} />
              <PrivateRoute exact path="/admin/customers" component={Customers} />
              <PrivateRoute
                exact
                path="/admin/customers/subscribed"
                component={CustomersSubscribed}
              />
              <Redirect
                exact
                from="/admin/customers/:id(\d+)"
                to="/admin/customers/:id(\d+)/contacts"
              />
              <Redirect
                exact
                from="/admin/customers/:id(\d+)/contact/:user_id(\d+)"
                to="/admin/customers/:id(\d+)/contact/:user_id(\d+)/summary"
              />
              <PrivateRoute
                path="/admin/customers/:id/contact/:user_id"
                component={ContactShow}
              />
              <PrivateRoute path="/admin/customers/:id(\d+)" component={CustomerShow} />
              <PrivateRoute
                path="/admin/customers/:id(\d+)/contact/:user_id(\d+)"
                component={ContactShow}
              />
              <PrivateRoute exact path="/admin/orders" component={Orders} />
              <PrivateRoute
                exact
                path="/admin/orders/public_id/:public_id"
                component={OrderPublicID}
              />
              <PrivateRoute path="/admin/orders/new/:duplicate_order_id?" component={OrderNew} />
              <PrivateRoute path="/admin/orders/:id/edit" component={OrderEdit} />
              <PrivateRoute path="/admin/orders/:id" component={OrderShow} />
              <PrivateRoute exact path="/admin/quotes" component={Quotes} />
              <PrivateRoute exact path="/admin/quotes/new" component={QuoteNew} />
              <PrivateRoute
                exact
                path="/admin/quotes/duplicate/:duplicateId"
                component={QuoteNew}
              />
              <PrivateRoute path="/admin/quotes/:id" component={QuoteEdit} />
              <PrivateRoute exact path="/admin/shipments" component={Shipments} />
              <PrivateRoute exact path="/admin/shipments/new" component={ShipmentsNew} />
              <PrivateRoute exact path="/admin/shipments/:id/edit" component={ShipmentsEdit} />
              <PrivateRoute exact path="/admin/shipments/:id" component={ShipmentsShow} />
              <PrivateRoute exact path="/admin/suppliers" component={Suppliers} />
              <PrivateRoute exact path="/admin/suppliers/new" component={SuppliersNew} />
              <PrivateRoute exact path="/admin/suppliers/:id/edit" component={SuppliersEdit} />
              <PrivateRoute exact path="/admin/lead_times" component={LeadTimes} />
              <PrivateRoute exact path="/admin/margin_control" component={MarginControl} />
              <PrivateRoute
                exact
                path="/admin/margin_control/:id/edit"
                component={MarginControlEdit}
              />
              <PrivateRoute path="/admin/lead_times/new" component={LeadTimesNew} />

              <PrivateRoute exact path="/admin/rejection_reasons" component={RejectionReasons} />
              <PrivateRoute
                exact
                path="/admin/rejection_reasons/:id/edit"
                component={RejectionReasonEdit}
              />
              <PrivateRoute path="/admin/rejection_reasons/new" component={RejectionReasonNew} />

              <PrivateRoute exact path="/admin/printers" component={Printers} />
              <PrivateRoute path="/admin/printers/new" component={PrinterNew} />
              <PrivateRoute path="/admin/printers/:id(\d+)" component={PrinterEdit} />
              <PrivateRoute path="/admin/packing_slips/:id?" component={PackingSlips} />
              <PrivateRoute path="/admin/bulk_traveler" component={BulkTraveler} />
              <PrivateRoute
                exact
                path="/admin/work_orders/:id/order/:orderId"
                component={WorkOrderEdit}
              />
              <PrivateRoute
                exact
                path="/admin/work_orders/:id/cloned_from/:cloneId"
                component={WorkOrderEdit}
              />
              <PrivateRoute exact path="/admin/work_orders/:id(\d+)" component={WorkOrderEdit} />
              <PrivateRoute exact path="/admin/work_orders/:id/report" component={YieldReport} />
              <PrivateRoute
                exact
                path="/admin/work_orders/state_updater"
                component={StateUpdater}
              />
              <PrivateRoute
                exact
                path="/admin/work_orders/state_updater/:state"
                component={StateUpdater}
              />
              <PrivateRoute path="/admin/work_orders" component={WorkOrders} />
              <PrivateRoute path="/admin/qc/:disposition_tab" component={QAReview} />
              <PrivateRoute path="/admin/roles" component={RolesEdit} />
              <PrivateRoute exact path="/admin/smartsheets/webhooks" component={Webhooks} />
              <PrivateRoute exact path="/admin/inventory/material/new" component={MaterialsNew} />
              <PrivateRoute exact path="/admin/users/new" component={UsersNew} />
              <PrivateRoute
                path="/admin/customers/:org_id/parts/new"
                component={OrganizationPartsNewContainer}
              />
              <PrivateRoute
                path="/admin/customers/:org_id/parts"
                component={OrganizationPartsContainer}
              />
              <PrivateRoute
                path="/admin/viewer/order_line_item/:order_line_item_id(\d+)"
                component={Viewer}
              />
              <Redirect to="/admin" />
            </Switch>
          </AlertProvider>
        </PhoenixSocketProvider>
      </GlobalErrorBoundary>
      <ConnectivityCheck />
    </div>
  );
};

export default AdminContainer;
