import * as Yup from 'yup';

import { INTERNAL_PROCESSES } from 'fr-shared/lib/manufacturing_process';

export const supplierCostSchema = Yup.object().shape({
  supplier_costs: Yup.array().of(
    Yup.object().shape({
      location_id: Yup.string().required(),
      part_quantities: Yup.array().of(
        Yup.object().shape({
          lead_time: Yup.string()
            .nullable()
            .when('$lineItem', (lineItem, schema) => {
              const manufacturing_process = lineItem?.manufacturing_process;

              return manufacturing_process &&
                !INTERNAL_PROCESSES.includes(manufacturing_process.name)
                ? schema.required()
                : schema;
            }),
          per_unit_cost: Yup.string().required(({ path }) => `${path} Amount is required`),
        })
      ),
    })
  ),
});

const quantityError = 'value must be greater than 0';

export const supplierCostPartQuantitySchema = Yup.object().shape({
  supplier_costs: Yup.array().of(
    Yup.object().shape({
      location_id: Yup.string().required(),
      part_quantities: Yup.array().of(
        Yup.object().shape({
          optimal: Yup.boolean(),
          quantity: Yup.number().typeError(quantityError).positive(quantityError),
        })
      ),
    })
  ),
});
