import { isEmpty, isNil } from 'lodash';
import { filter, pipe, sortBy, uniqBy } from 'lodash/fp';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Select, {
  GroupedOptionsType,
  IndicatorProps,
  OptionTypeBase,
  SelectComponentsConfig,
  SingleValueProps,
  StylesConfig,
} from 'react-select';

import { Avatar, Button, FormFieldBase, Icon } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';

export interface Contact {
  id: number | null; // Null for the case of "Unassigned"
  name: string;
}

interface DropdownIndicatorProps<T> extends IndicatorProps<T, false> {
  color?: string;
  unassignedButtonText: string;
}

interface SingleValuePropsX<T> extends SingleValueProps<T> {
  showAvatar: boolean;
}

const DropdownIndicator = <T extends OptionTypeBase>({
  className,
  color = 'primary',
  cx,
  unassignedButtonText,
  hasValue,
  innerProps,
  isDisabled,
  selectProps: { menuIsOpen },
}: DropdownIndicatorProps<T>) => (
  <div
    {...innerProps}
    className={cx(
      {
        indicator: true,
        'dropdown-indicator': true,
      },
      className
    )}
  >
    {!menuIsOpen &&
      (hasValue ? (
        <Button
          active={menuIsOpen}
          className="btn-link-dropdown-indicator"
          color="link"
          size="sm"
          disabled={isDisabled}
        >
          Assign to <Icon style={{ marginLeft: '4px' }} name="chevron-down" />
        </Button>
      ) : (
        <Button active={menuIsOpen} color={color} size="sm" disabled={isDisabled}>
          {unassignedButtonText} <Icon style={{ marginLeft: '4px' }} name="chevron-down" />
        </Button>
      ))}
  </div>
);

const SingleValue = (props: SingleValuePropsX<Contact>) => {
  const { data: user, selectProps, showAvatar } = props;

  if (selectProps.menuIsOpen) return null;

  return showAvatar ? (
    <Avatar
      style={{ wordBreak: 'break-all', fontWeight: 'bold' }}
      subject={user}
      isAnonymous={isNil(user.id)}
    />
  ) : (
    <strong className="mr-1">{user.name}</strong>
  );
};

const getOptionValue = ({ id }: Contact) => String(id);

const selectStyles: StylesConfig = {
  control: provided => ({
    ...provided,
    alignItems: 'center',
    border: 'none',
    display: 'inline-flex',
    flex: 1,
    minHeight: '0',
    minWidth: 170,
  }),
  group: provided => ({
    ...provided,
    paddingBottom: '0',
    paddingTop: '0',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: provided => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '0 !important',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 9999,
  }),
};

interface FormContactPickerProps extends Partial<React.ComponentProps<typeof FormFieldBase>> {
  unassignedButtonText: string;
  color?: string;
  isDisabled?: boolean;
  includeUser?: boolean;
  includeUnassignButton?: boolean;
  showAvatar?: boolean;
  options: Contact[];
  value?: Contact | {};
  onChange: (user: Contact) => void;
}

const FormContactPicker = ({
  unassignedButtonText,
  options,
  onChange,
  showAvatar = false,
  includeUser = false,
  isDisabled = false,
  includeUnassignButton = true,
  color,
  value,
  ...props
}: FormContactPickerProps) => {
  const selectComponents: SelectComponentsConfig<Contact, false> = {
    // eslint-disable-next-line react/display-name
    DropdownIndicator: props => (
      <DropdownIndicator color={color} unassignedButtonText={unassignedButtonText} {...props} />
    ),
    // eslint-disable-next-line react/display-name
    SingleValue: props => <SingleValue showAvatar={showAvatar} {...props} />,
  };
  const { user: signedInUser } = useContext(UserContext) as {
    user: Contact;
  };
  const [userOptions, setUserOptions] = useState<GroupedOptionsType<Contact>>([]);

  const currentValue = isEmpty(value) ? null : (value as Contact);

  useEffect(() => {
    const group1: Contact[] = [];

    // include the Unassigned option if caller allows
    if (includeUnassignButton) {
      group1.push({ id: null, name: 'Unassigned' });
    }

    // Add the signed in user to the special top group
    if (includeUser) {
      group1.push(signedInUser);
    }

    const group1Ids = group1.map(user => user.id);
    const group2 = pipe(
      // Remove users that are already in the top group.
      filter(user => !isNil(user) && !group1Ids.includes(user.id)) as (
        arr: Array<Contact | null | undefined>
      ) => Contact[],
      // Remove repeats among the lists, current value, and signed in user.
      uniqBy(user => user.id),
      sortBy(user => user.name)
    )([currentValue, ...options]);

    setUserOptions([group1, group2].map(options => ({ options })));
  }, [currentValue, options, includeUser, signedInUser, includeUnassignButton]);

  const formatOptionLabel = useCallback(
    (user: Contact) => {
      const isCurrentUser = user.id === signedInUser.id;
      const isUnassigned = isNil(user.id);
      return (
        <div
          className="flex align-items-center"
          data-testid={`user-option-${isUnassigned ? 'unassigned' : user.id}`}
        >
          <Avatar subject={user} isAnonymous={isUnassigned} />
          {isCurrentUser && <div className="text-muted mx-1">(Assign yourself)</div>}
        </div>
      );
    },
    [signedInUser]
  );

  return (
    <FormFieldBase {...props}>
      <Select<Contact>
        classNamePrefix="react-select"
        components={selectComponents}
        formatOptionLabel={formatOptionLabel}
        filterOption={(option, input) => {
          return option.data?.name?.toLowerCase().includes(input);
        }}
        getOptionValue={getOptionValue}
        inputId={props.name}
        isDisabled={isDisabled}
        isSearchable={true}
        onChange={onChange}
        options={userOptions}
        placeholder=""
        styles={selectStyles}
        value={currentValue}
      />
    </FormFieldBase>
  );
};

export default FormContactPicker;
