import { pickBy } from 'lodash';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Breadcrumb, Button, Helmet, Link, Table } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { useTableFilters } from 'fr-shared/hooks';

const getPrinters = (filters: Object) => api.get('/printers', { params: pickBy(filters) });

interface PrinterRowOriginal {
  id: number;
}

interface PrinterRow {
  original: PrinterRowOriginal;
}

interface PrinterIndexRouteParams {}

type PrinterIndexProps = RouteComponentProps<PrinterIndexRouteParams>;

const PrintersIndex: React.FC<PrinterIndexProps> = () => {
  const { user }: UserContext = useContext(UserContext);

  const {
    data: printers,
    pages,
    onFetchData,
    loading,
  } = useTableFilters('printers', { page: 1, sort_key: 'id' }, getPrinters);

  return (
    <>
      <Helmet title="Printers" />
      <Breadcrumb to="/admin/printers">Printers</Breadcrumb>
      <div className="container">
        <div className="flex align-items-center py-3">
          <h2 className="mb-0">Printers</h2>
          <div className="ml-auto">
            {user.canCreatePrinters && (
              <Button color="primary" to="/admin/printers/new">
                Add a Printer
              </Button>
            )}
          </div>
        </div>

        <Table
          manual={true}
          data={printers || []}
          pageSize={(printers && printers.length) || 20}
          pages={pages}
          showPagination={true}
          onFetchData={onFetchData}
          loading={loading}
          className="bg-white -striped mb-5"
          columns={[
            { Header: 'ID', accessor: 'id', sortable: false },
            { Header: 'Name', accessor: 'name', sortable: false },
            { Header: 'Type', accessor: 'printer_type.name', sortable: false },
            {
              Header: 'Process',
              accessor: 'printer_type.manufacturing_process.name',
              sortable: false,
            },
            { Header: 'Location', accessor: 'location.name', sortable: false },
            {
              show: user.canUpdatePrinters,
              Header: '',
              sortable: false,
              id: 'Edit',
              // eslint-disable-next-line react/display-name
              Cell: (row: PrinterRow) => {
                return <Link to={`/admin/printers/${row.original.id}/edit`}>Edit</Link>;
              },
            },
          ]}
        />
      </div>
    </>
  );
};

export default PrintersIndex;
