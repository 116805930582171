import React, { useEffect, useRef, useState } from 'react';

import { classNames } from 'fr-shared/components';

import styles from './ProgressCircle.module.css';

interface ProgressCircleProps {
  /**
   * Numerical progress amount expressed as a percentage. Max amount is 100
   */
  percentage?: number;
  /**
   * Time between each interval in milliseconds to show progress on text
   */
  txtIntervalMs?: number;
  /**
   * Time between each interval in milliseconds to show progress on the donut
   */
  donutIntervalMs?: number;
  /**
   * Custom class name that can be used on the component
   */
  className?: string;

  backgroundColor?: string;

  spinner?: boolean;

  fadesIntoBackground?: boolean;
}

// Progress Circle only shows incremental progress, not decremental
export const ProgressCircle = ({
  className,
  percentage,
  txtIntervalMs = 50,
  donutIntervalMs = 20,
  backgroundColor = '#22272e',
  spinner = false,
  fadesIntoBackground = false,
}: ProgressCircleProps) => {
  // Alway ensure it's an integer
  percentage = Math.round(percentage);
  // Check if a user inputed something over 100, it'll always max out the progress circle at 100%
  percentage = percentage > 100 ? 100 : percentage;

  // Make circle appear it's animating by consistently adjusting the bg conic gradient degree positioning (max 180 for mid color)
  const maxMidColorDegreeFormula = (perc: number) => Math.round(180 * (perc / 100));
  // Make circle appear it's animating by consistently adjusting the bg conic gradient degree positioning (max 360 for complete color)
  const maxCompleteColorDegreeFormula = (perc: number) => Math.round(360 * (perc / 100));

  const completeColorInterval = useRef(null);
  const oldCompleteColor = useRef(maxCompleteColorDegreeFormula(percentage));
  const [completeColorPercent, setCompleteColorPercent] = useState(
    maxCompleteColorDegreeFormula(percentage)
  );

  const middleColorInterval = useRef(null);
  const oldMiddleColor = useRef(maxMidColorDegreeFormula(percentage));
  const [middleColorPercent, setMiddleColorPercent] = useState(
    maxMidColorDegreeFormula(percentage)
  );

  const textInterval = useRef(null);
  const oldText = useRef(percentage);
  const [percentTxt, setPercentTxt] = useState(percentage);

  // for increasing text
  useEffect(() => {
    textInterval.current && clearInterval(textInterval.current);
    textInterval.current = setInterval(() => {
      setPercentTxt(val => {
        if (val >= percentage) {
          oldText.current = percentage;
          clearInterval(textInterval.current);
          return val;
        }
        return val + 1;
      });
    }, txtIntervalMs);

    return () => clearInterval(textInterval.current);
    // NOTE: Complaining about "txtIntervalMs" parameter that can be passed into component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentage]);

  // for increasing conic gradient degree of 2nd color value
  useEffect(() => {
    middleColorInterval.current && clearInterval(middleColorInterval.current);
    middleColorInterval.current = setInterval(() => {
      setMiddleColorPercent(val => {
        if (val >= maxMidColorDegreeFormula(percentage)) {
          oldMiddleColor.current = maxMidColorDegreeFormula(percentage);
          clearInterval(middleColorInterval.current);
          return val;
        }
        return val + 1;
      });
    }, donutIntervalMs);

    return () => clearInterval(middleColorInterval.current);
    // NOTE: Complaining about "donutIntervalMs" parameter that can be passed into component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentage]);

  // for increasing conic gradient degree of final color value (3rd)
  useEffect(() => {
    completeColorInterval.current && clearInterval(completeColorInterval.current);
    completeColorInterval.current = setInterval(() => {
      setCompleteColorPercent(val => {
        if (val >= maxCompleteColorDegreeFormula(percentage)) {
          oldCompleteColor.current = maxCompleteColorDegreeFormula(percentage);
          clearInterval(completeColorInterval.current);
          return val;
        }
        return val + 1;
      });
    }, donutIntervalMs);

    return () => clearInterval(completeColorInterval.current);
    // NOTE: Complaining about "donutIntervalMs" parameter that can be passed into component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentage]);

  const conicBackground = () => {
    if (fadesIntoBackground) {
      return `conic-gradient(
        from 0deg,
        transparent 0grad,
        #46EAC4 60grad,
        #46EAC4 10deg,
        #2A91E7 ${middleColorPercent}deg,
        #974CF4 ${completeColorPercent}deg,
        transparent 0grad
      )`;
    }

    return `conic-gradient(
      from 0deg,
      #46EAC4 0grad,
      #46EAC4 0deg,
      #2A91E7 ${middleColorPercent}deg,
      #974CF4 ${completeColorPercent}deg,
      transparent 0grad
    )`;
  };

  return (
    <div className={classNames([className, styles.Root, spinner && styles.Spinner])}>
      <div
        className={classNames([
          styles.Container,
          fadesIntoBackground && styles.FadesIntoBackground,
        ])}
      >
        <div className={styles.PercentTxt}>
          {percentTxt}
          <span>%</span>
        </div>
        <div className={styles.Conic} style={{ background: conicBackground() }} />
        <div
          className={styles.InnerCircle}
          style={{ backgroundColor: backgroundColor || '#22272e' }}
        />
        <div
          className={styles.ProgressDotPath}
          style={{
            transform: `rotate(${completeColorPercent}deg)`,
          }}
        >
          <div className={styles.ProgressEndDot} />
        </div>
      </div>
    </div>
  );
};

export default ProgressCircle;
