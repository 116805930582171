import { useField, useFormikContext } from 'formik';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { FormFieldBase, classNames } from 'fr-shared/components';

const FormRadioButtons = props => {
  const formik = useFormikContext();
  const { options, name, readonly } = props;
  const error = formik.errors?.[props.name] ?? null;
  const hasError = error !== null;

  return (
    <FormFieldBase {...props} error={error}>
      <div
        className={classNames([
          'flex',
          {
            border: hasError,
            'border-error': hasError,
            'rounded-top': hasError,
            'flex-column': props.stacked,
            'flex-row': !props.stacked,
            'p-1': !readonly,
          },
        ])}
      >
        {readonly ? (
          <strong>
            {find(options, ({ value }) => value === formik.values?.[props.name])?.label ?? '--'}
          </strong>
        ) : (
          options.map((option, optionIndex) => {
            const id = `${option.value}_${optionIndex}`;
            return (
              <FormRadioButton key={id} name={name} value={option.value} label={option.label} />
            );
          })
        )}
      </div>
    </FormFieldBase>
  );
};

FormRadioButtons.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  stacked: PropTypes.bool,
  readonly: PropTypes.bool,
};

const FormRadioButton = ({ name, value, label }) => {
  const [field] = useField({ name, value, type: 'radio' });

  return (
    <div className="custom-control custom-radio mb-0 mr-2 flex">
      <input
        {...field}
        id={`${name}-${value}`}
        type="radio"
        className="custom-control-input"
        data-testid={`${name}-${value}`}
      />
      <label
        htmlFor={`${name}-${value}`}
        className="flex custom-control-label font-weight-normal"
        data-testid={`${name}-${value}-label`}
      >
        <div className="align-self-center">{label}</div>
      </label>
    </div>
  );
};

FormRadioButton.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.string,
};

export default FormRadioButtons;
