import { connect } from 'formik';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { api } from 'fr-shared/api';

import { Address } from 'portal/components';
import { CartLineItem } from 'portal/lib/cart';
import { date } from 'portal/lib/date';
import { SUBSCRIPTION } from 'portal/lib/plans';
import { manualQuoteSchema, quoteSchema } from 'portal/lib/schema';
import { getShippingText } from 'portal/lib/shipping';

import SubscriptionSummary from '../subscription/components/SubscriptionSummary';
import CardCheckout from './CardCheckout';
import CheckoutReviewFooter from './CheckoutReviewFooter';
import LineItemSummary from './LineItemSummary';

interface CheckoutReviewProps {
  discount?: string;
  formik: any;
  isActive: boolean;
  isSubmitDisabled: boolean;
  isCustomerShippingAccount?: boolean;
  isSubscriptionCheckout?: boolean;
  shippingPrice?: string;
  shippingMethod?: string;
  subtotal?: string;
  totalPrice: string;
  quoteId?: number;
  lineItems?: CartLineItem[];
}

const CheckoutReview = ({
  discount,
  formik,
  isActive,
  isSubmitDisabled,
  isCustomerShippingAccount,
  isSubscriptionCheckout = false,
  shippingPrice,
  shippingMethod,
  subtotal,
  totalPrice,
  quoteId = null,
  lineItems = null,
}: CheckoutReviewProps) => {
  const { values } = formik;
  const subscriptionType = get(values, 'subscription.subscription_type');
  const billingCycle = get(values, 'subscription.billing_cycle');
  const lastFour = get(values, 'payment_details.lastFour');
  const cardType = get(values, 'payment_details.cardType');

  const isBasicSubscription = subscriptionType === SUBSCRIPTION.BASIC;

  const line_items = lineItems || values.line_items;
  const history = useHistory();
  const [estimatedShipDate, setEstimatedShipDate] = useState(null);
  const shippingText = isCustomerShippingAccount
    ? shippingMethod
    : getShippingText(shippingMethod);

  useEffect(() => {
    if (line_items && line_items.length > 0) {
      const lineItemLeadTimes = line_items.map((item: CartLineItem) => {
        return item?.lead_time?.max_days;
      });

      api
        .post('/customer_portal/estimated_quote_ship_date', { lead_times: lineItemLeadTimes })
        .then(result => {
          if (result.data?.estimated_ship_date) {
            const shipDate = date(result.data.estimated_ship_date);
            setEstimatedShipDate(shipDate);
          } else {
            setEstimatedShipDate(null);
          }
        })
        .catch(() => {
          setEstimatedShipDate(null);
        });
    }
  }, [line_items]);

  if (isActive && !isSubscriptionCheckout && !quoteId && !quoteSchema.isValidSync(values)) {
    history.push('/checkout');
  }

  if (isActive && quoteId && !manualQuoteSchema.isValidSync(values)) {
    history.push(`/checkout/${quoteId}`);
  }

  return (
    <CardCheckout
      name="Review"
      editUrl="/checkout/review"
      isActive={isActive}
      isEditable={false}
      footer={
        <CheckoutReviewFooter
          isSubmitDisabled={isSubmitDisabled}
          isSubscriptionCheckout={isSubscriptionCheckout}
        />
      }
    >
      <div className="font-size-md">
        <div className="row mb-5">
          {!isSubscriptionCheckout && (
            <div className="col-md mb-3">
              <div className="text-muted">Shipping address</div>
              <Address address={values.shipping_address} dataTestId="shipping-address" />
            </div>
          )}
          {isSubscriptionCheckout && !isBasicSubscription && (
            <div className="col-md mb-3">
              <div className="text-muted">Subscription payment method</div>
              {cardType && lastFour && (
                <div>
                  {cardType} ending in {lastFour}
                </div>
              )}
            </div>
          )}
          {!isBasicSubscription && (
            <div className="col-md mb-3">
              <div className="text-muted">Billing address</div>
              <Address address={values.billing_address} dataTestId="billing-address" />
            </div>
          )}

          {!isSubscriptionCheckout && (
            <div className="col-md mb-3">
              <div>
                <div className="text-muted">Shipping method</div>
                <span>{shippingText}</span>
              </div>
              {estimatedShipDate && (
                <div>
                  <div className="text-muted">Est. ship date:</div>
                  <span data-testid="estimated-shipping-date">{estimatedShipDate}</span>
                </div>
              )}
            </div>
          )}
        </div>
        {!isSubscriptionCheckout ? (
          <LineItemSummary
            lineItems={line_items}
            discount={discount}
            shippingPrice={shippingPrice}
            subtotalPrice={subtotal}
            totalPrice={totalPrice}
          />
        ) : (
          <SubscriptionSummary subscriptionType={subscriptionType} billingCycle={billingCycle} />
        )}
      </div>
    </CardCheckout>
  );
};

export default connect(CheckoutReview);
