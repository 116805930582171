import PropTypes from 'prop-types';
import React from 'react';

import { classNames } from 'fr-shared/components';

const CheckboxLabel = ({
  children,
  className,
  dataTestId,
  label,
  name,
  isRequired,
  isToggle,
  srOnlyLabel,
}) => (
  <div
    className={classNames([
      'custom-control',
      {
        'custom-checkbox': !isToggle,
        'custom-checkbox-switch': isToggle,
      },
      className,
    ])}
  >
    {children}
    <label
      className={classNames(['custom-control-label', { '-indent-[9999px]': !label }])}
      data-testid={dataTestId}
      htmlFor={name}
    >
      <span className={srOnlyLabel ? 'sr-only' : ''}>{label}</span>{' '}
      {isRequired && <span className="text-error">*</span>}
    </label>
  </div>
);

CheckboxLabel.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  isRequired: PropTypes.bool,
  isToggle: PropTypes.bool,
  srOnlyLabel: PropTypes.bool,
};

export default CheckboxLabel;
