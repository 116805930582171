import PropTypes from 'prop-types';
import React from 'react';

import { keyboardDown } from 'fr-shared/utils';

import { classNames } from 'portal/components';

import styles from './PartSelector.module.css';

const TablePartSelector = ({ handleSelectClick, isSelected }) => {
  const selectorClassNames = classNames([styles.Selector, isSelected && styles.isSelected]);

  return (
    <div className={styles.Root}>
      <div
        className={selectorClassNames}
        onClick={handleSelectClick}
        onKeyDown={evt => keyboardDown(evt, 'Enter', handleSelectClick)}
        role="button"
        tabIndex={0}
      >
        {isSelected && <i className={`fal fa-check ${styles.Icon}`} />}
      </div>
    </div>
  );
};

TablePartSelector.propTypes = {
  handleSelectClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

export default TablePartSelector;
