import { Form, Formik } from 'formik';
import { size } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { api } from 'fr-shared/api';
import {
  Button,
  FormCheckbox,
  FormCountries,
  FormField,
  FormStates,
  Modal,
} from 'fr-shared/components';
import { AddressTypeEnum, AddressTypes, createOrUpdateAddress } from 'fr-shared/lib/address';
import { COUNTRIES } from 'fr-shared/lib/countries';

const modalFieldsByType = type => {
  switch (type) {
    case AddressTypeEnum.Billing:
      return BillingAddressFields;
    case AddressTypeEnum.Shipping:
      return ShippingAddressFields;
  }
};

const AddressModal = ({
  address,
  isEdit = false,
  onDelete = () => {},
  onError = () => {},
  onSave = () => {},
  title,
  type,
  userId,
  ...modalProps
}) => {
  const handleSubmit = async (values, { setErrors }, toggle = () => {}) => {
    try {
      const { data } = await createOrUpdateAddress(values, isEdit, userId, type);
      onSave(data);
      toggle();
    } catch (err) {
      if (size(err?.response?.data?.errors) > 0) {
        const errors = err.response.data.errors;
        setErrors({ ...errors });
      } else {
        onError();
        toggle();
      }
    }
  };

  const handleDelete = async (values, toggle = () => {}) => {
    await api.delete(`/users/${userId}/addresses/${values.id}`);
    onDelete();
    toggle();
  };

  const ModalFields = modalFieldsByType(type);

  return (
    <Modal {...modalProps}>
      {({ toggle }) => (
        <Formik
          initialValues={
            address ? address : { country: COUNTRIES.UNITED_STATES, is_default: true }
          }
        >
          {formik => (
            <>
              <Modal.Header title={title} onClose={toggle} textUppercase={false} />
              <Form>
                <Modal.Body className="px-3">
                  <div className="form-group mb-3">
                    <span>
                      Required <span className="text-error">*</span>
                    </span>
                  </div>
                  <ModalFields />
                </Modal.Body>
                <Modal.Footer className="justify-space-between">
                  <div>
                    {isEdit && (
                      <Button
                        color="danger"
                        outline={true}
                        onClick={() => handleDelete(formik.values, toggle)}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                  <div>
                    <Button color="light" className="border" onClick={toggle}>
                      Cancel
                    </Button>
                    <Button
                      dataTestId="submit-address"
                      color="success"
                      className="ml-2"
                      onClick={() => handleSubmit(formik.values, formik, toggle)}
                    >
                      {isEdit ? 'Save ' : 'Add '}
                      {type === AddressTypeEnum.Billing ? 'Billing' : 'Shipping'} Information
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      )}
    </Modal>
  );
};

AddressModal.propTypes = {
  address: PropTypes.object,
  isEdit: PropTypes.bool,
  onDelete: PropTypes.func,
  onError: PropTypes.func,
  onSave: PropTypes.func,
  title: PropTypes.string,
  toggle: PropTypes.func,
  type: PropTypes.oneOf(AddressTypes).isRequired,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const BillingAddressFields = () => {
  return (
    <div data-testid="billing-fields">
      <FormField md={3} label="Full Name" name="name_line_1" required={true} showError={true} />
      <FormField
        md={3}
        label="Address Line 1"
        name="street_line_1"
        required={true}
        showError={true}
      />
      <FormField md={3} label="Address Line 2" name="street_line_2" />
      <FormField md={3} label="City" name="city" required={true} showError={true} />
      <FormStates md={3} label="State" name="state" required={true} showError={true} />
      <FormField md={3} label="Zip" name="postal_code" required={true} showError={true} />
      <FormCountries md={3} label="Country" name="country" required={true} showError={true} />
      <FormField md={3} label="Email" name="email" />
      <FormCheckbox className="mt-3" label="Set as default" name="is_default" />
    </div>
  );
};

const ShippingAddressFields = () => {
  return (
    <>
      <FormField md={3} label="Full Name" name="name_line_1" required={true} showError={true} />
      <FormField md={3} label="Company" name="name_line_2" />
      <FormField
        md={3}
        label="Address Line 1"
        name="street_line_1"
        required={true}
        showError={true}
      />
      <FormField md={3} label="Address Line 2" name="street_line_2" />
      <FormField md={3} label="City" name="city" required={true} showError={true} />
      <FormStates md={3} label="State" name="state" required={true} showError={true} />
      <FormField md={3} label="Zip" name="postal_code" required={true} showError={true} />
      <FormCountries md={3} label="Country" name="country" required={true} showError={true} />
      <FormField md={3} label="Phone Number" name="phone_number" />
      <FormField md={3} label="Email" name="email" />
      <FormCheckbox className="mt-3" label="Set as default" name="is_default" />
    </>
  );
};

export default AddressModal;
