import mixpanel from 'mixpanel-browser';

import { UserAnalyticsLib } from './UserAnalyticsContext';

const MIXPANEL_TOKENS: Record<string, string> = {
  dev: '758d09b30b7c80edccda46de6de9b133',
  staging: 'c6d835694d11ef94781a160047f61cf7',
  prod: '61f8704cfcc48932035877a554028b4d',
};
const isProd = process.env.MIX_ENV === 'prod';
const isStaging = process.env.MIX_ENV === 'staging';
const isDevUser = false; // Manually turn truthy if you want to fire/see events locally
const mixpanelToken = MIXPANEL_TOKENS[process.env.MIX_ENV];
const trackMixpanelEvts = isProd || isStaging || isDevUser;

export const mixPanelUserAnalytics: UserAnalyticsLib = (lib => {
  const loaded = new Promise<boolean>((resolve, _) => {
    lib.init(mixpanelToken, {
      debug: isStaging || isDevUser,
      loaded: () => resolve(true),
    });
  }).catch(() => Promise.resolve(false));

  // Implementations
  const identify = (id: string) => {
    if (!trackMixpanelEvts) return;
    return loaded.then(success => (success ? lib.identify(id) : null));
  };

  const alias = (id: string) => {
    if (trackMixpanelEvts) lib.alias(id);
  };

  const track = (name: string, props?: Record<string, any>) => {
    if (trackMixpanelEvts) lib.track(name, props);
  };

  const getDistinctId = () => {
    return loaded.then(success => (success ? lib.get_distinct_id() : null));
  };

  const setUserMetadata = (metadata: Record<string, any>) => {
    if (!trackMixpanelEvts) {
      return;
    }
    return loaded.then(success => (success ? lib.people.set(metadata) : null));
  };

  // Final public API
  return {
    identify,
    alias,
    track,
    getDistinctId,
    setUserMetadata,
  };
})(mixpanel);
