import { Button, IconFont } from '@fast-radius/shared-ui';
import { startCase } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { AlertContext, UserContext, useUserAnalyticsContext } from 'fr-shared/context';
import { AddressType, addressPath } from 'fr-shared/lib/address';

import { Address, Modal } from 'portal/components';

import AddressModal from './AddressModal';

interface AccountAddressesProps {
  type: AddressType;
}

interface ModalChildProps {
  toggle: () => void;
}

const NewAccountAddresses = ({ type }: AccountAddressesProps) => {
  const { user } = useContext(UserContext);
  const { setAlert } = useContext(AlertContext);
  const [addresses, setAddresses] = useState([]);
  const userAnalytics = useUserAnalyticsContext();

  const handleAddAddress = () =>
    userAnalytics.track(`Add ${startCase(type)} Address`, { valid: true });
  const handleEditAddress = () =>
    userAnalytics.track(`Edit ${startCase(type)} Address`, { valid: true });
  const handleSaveAddAddress = () => {
    userAnalytics.track(`AS - Completed New ${startCase(type)} Address`);
    refreshAddresses();
  };
  const handleSaveEditAddress = () => {
    userAnalytics.track(`AS - Completed Edit ${startCase(type)} Address`);
    refreshAddresses();
  };

  const refreshAddresses = useCallback(() => {
    api
      .get(`customer_portal/account/${user.id}/${addressPath(type)}`, {
        params: { sort_key: 'street_line_1', sort_desc: true },
      })
      .then(res => {
        setAddresses(res.data);
      })
      .catch(() => {
        setAlert({
          message: 'We could not load your saved addresses. Please refresh the page to try again',
          color: 'danger',
        });
      });
  }, [type, setAlert, user.id]);

  useEffect(() => {
    refreshAddresses();
  }, [refreshAddresses]);

  const handleDelete = async (addressId: number) => {
    await api
      .delete(`/users/${user.id}/addresses/${addressId}`)
      .then(() => refreshAddresses())
      .catch(() => {
        setAlert({
          message: 'We could not delete that saved address. Please refresh the page to try again',
          color: 'danger',
        });
      });
  };

  return (
    <div>
      <div className="border-bottom py-2 mb-4">
        <h5 className="flex align-items-center">Saved {startCase(type)} address</h5>
      </div>
      {addresses.map((address: any, index: number) => {
        return (
          <div
            key={`${type}-address-${index}`}
            className="mb-4 flex flex-1 align-items-start bg-gray-600 p-2 rounded-md justify-between w-3/4"
          >
            <Address flatten address={address} />
            <div className="border-left h-[42px]">
              <Modal
                action={
                  <Button
                    color="icon"
                    className="pt-0.5 focus:outline-none text-coolGray-300"
                    size="lg"
                  >
                    <IconFont className="pl-3" name="pencil" />
                  </Button>
                }
                onActionHandler={handleEditAddress}
              >
                {({ toggle }: ModalChildProps) => (
                  <AddressModal
                    type={type}
                    toggle={toggle}
                    isEdit={true}
                    onSave={handleSaveEditAddress}
                    initialValues={address}
                    title="Edit address"
                  />
                )}
              </Modal>
              <Button
                dataTestId="delete-address-button"
                color="icon"
                className="pl-4 pt-0.5 focus:outline-none text-coolGray-300"
                size="lg"
                onClick={() => handleDelete(address.id)}
              >
                <IconFont name="trash" />
              </Button>
            </div>
          </div>
        );
      })}

      {addresses.length === 0 && (
        <div className="font-size-md">{"You don't have any saved addresses"}</div>
      )}

      <Modal
        action={
          <Button
            color="secondary"
            size="sm"
            className="ml-auto bg-transparent hover:bg-[#ffffff1a] hover:font-bold h-4 float-right"
          >
            Add new {type.toLowerCase()} address
          </Button>
        }
        onActionHandler={handleAddAddress}
      >
        {({ toggle }: ModalChildProps) => (
          <AddressModal
            type={type}
            toggle={toggle}
            isEdit={false}
            onSave={handleSaveAddAddress}
            title={`Add New ${startCase(type)} Address`}
          />
        )}
      </Modal>
    </div>
  );
};

export default NewAccountAddresses;
