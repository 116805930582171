import React from 'react';
import { useParams } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { ButtonLink, Pagination } from 'fr-shared/components';
import { useTableFilters } from 'fr-shared/hooks';

import {
  CardWrapper,
  Page,
  PageHeader,
  PageResults,
  PageSearch,
  PageToolbar,
  PartCard,
  PartPreviewModal,
} from 'portal/components';

const fetchData = (orgId, filters) => {
  return api.get(`/organizations/${orgId}/parts`, { params: filters });
};

const initialFilters = { page: 1, page_size: 20 };

const OrganizationPartsContainer = () => {
  const { org_id: orgId } = useParams();

  const {
    data: parts,
    handleSearch: handleTableSearch,
    pages,
    totalItems,
    filters,
    setFilters,
  } = useTableFilters(null, initialFilters, filters => fetchData(orgId, filters));

  const handleSearch = value => {
    // TODO: useTableFilters expects a synthetic event, but we're controlling <Input />
    handleTableSearch({ target: { name: 'name', value } });
  };

  const handleSetPage = page => {
    setFilters({ ...filters, page });
  };

  return (
    <div className="bg-light">
      <Page>
        <PageHeader title="Organization Parts">
          <div className="d-flex justify-content-between">
            <h3>Org ID: {orgId}</h3>
            <ButtonLink
              to={`/admin/customers/${orgId}/parts/new`}
              className="mr-4"
              color="primary"
            >
              Create Part
            </ButtonLink>
          </div>
        </PageHeader>
        <PageToolbar>
          <PageSearch
            defaultValue={filters.search}
            onSearch={handleSearch}
            placeholder="Search by part name"
            icon={false}
          />
          <PageResults value={totalItems} context="part" />
        </PageToolbar>
        {parts && (
          <CardWrapper className="mt-3">
            {parts.map(part => (
              <PartPreviewModal
                key={part.id}
                part={part}
                showAddToQuote={false}
                action={<PartCard part={part} />}
              />
            ))}
          </CardWrapper>
        )}
        {parts && parts.length > 0 && (
          <div className="flex-1 mt-3 flex align-items-end">
            <Pagination page={filters.page} setPage={handleSetPage} totalPages={pages} />
          </div>
        )}
      </Page>
    </div>
  );
};

export default OrganizationPartsContainer;
