import { IconFont } from '@fast-radius/shared-ui';
import React, { SyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Button } from 'fr-shared/components';
import { DocWithStatus } from 'fr-shared/components/form/SupportingDocuments';
import { AlertContext } from 'fr-shared/context';
import { useManufacturingProcess } from 'fr-shared/hooks';
import { INSPECTION_TYPE_NAMES } from 'fr-shared/lib/inspection_types';
import { AUTOCOSTABLE_MFG_PROCESSES } from 'fr-shared/lib/manufacturing_process';
import { getMarketoCookies } from 'fr-shared/lib/marketo';
import { numberInputMinValueFilter } from 'fr-shared/utils/input';

import {
  CardWrapper,
  Input,
  Page,
  PageHeader,
  PartConfigurationCard,
  classNames,
} from 'portal/components';
import { PartsContext } from 'portal/contexts/PartsContext';
import useLineItemDefaults from 'portal/hooks/useLineItemDefaults';
import useRequestBulkConfigAutoquoteTiers from 'portal/hooks/useRequestBulkConfigAutoquoteTiers';
import { BulkConfigAutoquoteTier, isAutoquoteError } from 'portal/lib/autoquote';
import { convertLineItemAndPartToBulkConfigAutoquoteRequest } from 'portal/lib/cart';
import Panel from 'portal/pages/evaluate/components/Panel/Panel';
import { convertStateToLineItem } from 'portal/pages/part_config/components/PartFormConfig';
import { PartFormState } from 'portal/pages/part_config/components/PartFormFields';
import PartFileViewer from 'portal/pages/part_upload_v2/components/PartFileViewer';

import { cncSupportingDocumentsRequired } from '../../../part_config/util';
import ManufacturingProcessSpecificationField from '../ManufacturingProcessSpecificationField';
import BulkPartConfigForm from './BulkPartConfigForm';
import BottomPanel from './components/BottomPanel';

type BulkPartConfigContainerProps = {
  closeBulkEditing: () => void;
};

export default function BulkPartConfigContainer({
  closeBulkEditing,
}: BulkPartConfigContainerProps) {
  const history = useHistory();
  const { setAlert } = useContext(AlertContext);
  const {
    handleSelectParts,
    removeAllPartsFromSelection,
    removeSelectedPart,
    selectedParts,
    bulkPartConfig,
    setBulkPartConfig,
    partsLoaded,
    removeBulkConfig,
  } = useContext(PartsContext);
  const { data: manufacturingProcessesData }: { data: ManufacturingProcess[] } =
    useManufacturingProcess({ publiclyAvailable: true });
  const manufacturingProcesses = manufacturingProcessesData?.filter(mp =>
    AUTOCOSTABLE_MFG_PROCESSES.includes(mp.name)
  );
  const initialFormState = useLineItemDefaults(null);

  const [selectedTier, setSelectedTier] = useState<BulkConfigAutoquoteTier | null>(null);
  const [isPartViewerOpen, setIsPartViewerOpen] = useState(false);
  const [currentPart, setCurrentPart] = useState(null);
  const [isSubmitting, setIsSubmittng] = useState<boolean>(false);
  const [allQuantities, setAllQuantities] = useState(1);

  const mountRef = useRef(false);

  const handleSetAllQuantities = (value: number) => {
    const updatedSelectedParts = [...Object.values(selectedParts)];
    updatedSelectedParts.forEach((p: any) => (p.quantity = value));
    handleSelectParts(updatedSelectedParts);
  };

  useEffect(() => {
    if (partsLoaded && Object.keys(selectedParts).length < 1) {
      removeBulkConfig();
      history.push('/add-part');
    }
  }, [history, partsLoaded, removeBulkConfig, selectedParts]);

  useEffect(() => {
    if (mountRef.current) {
      handleSetAllQuantities(allQuantities);
    } else {
      mountRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allQuantities]);

  const surfaceRoughnessDep = bulkPartConfig.cnc_surface_roughness?.id;
  const toleranceDep = bulkPartConfig.tolerance?.id;

  const [autoquoteResponseState, fetchAutoquote] = useRequestBulkConfigAutoquoteTiers(
    convertLineItemAndPartToBulkConfigAutoquoteRequest(bulkPartConfig, selectedParts, false)
  );

  const partsLength = Object.values(selectedParts).length;

  useEffect(() => {
    if (partsLength > 0) {
      const partFileRevisions = Object.values(selectedParts).map(
        (p: Part) => p.current_revision?.id
      );
      api
        .get('/customer_portal/failed_counts', {
          params: { part_file_revision_ids: partFileRevisions },
        })
        .then(res => {
          const partsWithFailedCounts = Object.values(selectedParts).map((part: any) => {
            const partFileRevision = res.data.find(
              (data: any) => data.id === part.current_revision.id
            );
            return {
              ...part,
              current_revision: {
                ...part.current_revision,
                failed_manufacturability_check_counts_by_process:
                  partFileRevision.failed_manufacturability_check_counts_by_process,
              },
            };
          });
          handleSelectParts(partsWithFailedCounts);
        })
        .catch(() => {});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partsLength]);

  useEffect(() => {
    if (Array.isArray(autoquoteResponseState)) {
      const foundLocationAutoquote = autoquoteResponseState.find(
        tier => tier.location_id === bulkPartConfig.location_id
      );
      if (!foundLocationAutoquote && autoquoteResponseState.length >= 1) {
        setSelectedTier(autoquoteResponseState[0]);
      } else {
        setSelectedTier(foundLocationAutoquote);
      }
    } else if (isAutoquoteError(autoquoteResponseState)) {
      setSelectedTier(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkPartConfig.location_id]);

  /**
   * When a user chooses options, we want to request new autoquote data
   */
  useEffect(() => {
    fetchAutoquote(
      convertLineItemAndPartToBulkConfigAutoquoteRequest(bulkPartConfig, selectedParts, false)
    );
    /* eslint-disable-next-line */
  }, [
    bulkPartConfig.cnc_advanced_features,
    bulkPartConfig.cnc_certifications,
    bulkPartConfig.cnc_gdt,
    bulkPartConfig.cnc_masked_surfaces,
    bulkPartConfig.cnc_thread_count,
    bulkPartConfig.cnc_thread_variety,
    bulkPartConfig.cnc_tolerance_count_0005,
    bulkPartConfig.cnc_tolerance_count_001,
    bulkPartConfig.cnc_tolerance_count_002,
    bulkPartConfig.cnc_tolerance_count_003,
    bulkPartConfig.color_id,
    bulkPartConfig.current_documents,
    bulkPartConfig.customer_portal_finish,
    bulkPartConfig.finish_id,
    bulkPartConfig.infill,
    bulkPartConfig.inspection_type_id,
    bulkPartConfig.layer_thickness_id,
    bulkPartConfig.manufacturing_process_id,
    bulkPartConfig.material_id,
    bulkPartConfig.quantity,
    toleranceDep,
    surfaceRoughnessDep,
    selectedParts,
  ]);

  const handleFieldChanges = (changes: Partial<PartFormState>) => {
    setBulkPartConfig((prevBulkPartConfig: any) => {
      const newObj = { ...prevBulkPartConfig, ...changes };
      return newObj;
    });
  };
  const setInitialProcessDefaults = (process: ManufacturingProcess) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const defaults = useLineItemDefaults(process);
    handleFieldChanges(defaults);
  };

  const handleRemovePart = (part: Part) => {
    removeSelectedPart(part.id);
  };

  const handleSave = async () => {
    // handle validation here?
    setIsSubmittng(true);

    // mapping parts to the proper request format
    const partsRequest = Object.keys(selectedParts).map((key: string) => ({
      part: selectedParts[key],
      part_id: selectedParts[key].id,
      units: selectedParts[key].units,
      quantity: selectedParts[key].quantity,
    }));

    const configRequest = convertStateToLineItem(bulkPartConfig);
    // removing parts specific data from the formState
    delete configRequest.notes;
    delete configRequest.quantity;

    try {
      await api.post('/customer_portal/cart_line_items/bulk', {
        parts: partsRequest,
        config: configRequest,
        marketo_attrs: getMarketoCookies(),
      });
      removeAllPartsFromSelection();
      history.push('/quotes/draft');
    } catch (error) {
      setAlert({
        color: 'danger',
        message: 'An unexpected error occurred, please refresh and try again',
      });
    }
    setIsSubmittng(false);
  };

  const handleCancel = () => {
    history.push('/add-part');
  };

  useEffect(() => {
    if (selectedParts.length < 1) {
      setBulkPartConfig(initialFormState);
      closeBulkEditing();
    }
  }, [selectedParts.length, initialFormState, closeBulkEditing, setBulkPartConfig]);

  /* Set the location_id on the line item form when we get the response back from the autoquotes api endpoint */
  useEffect(() => {
    if (Array.isArray(autoquoteResponseState) && autoquoteResponseState.length >= 1) {
      const foundLocationAutoquote = autoquoteResponseState.find(
        autoquote => autoquote.location_id
      );
      // If we have an array of autoquotes, it's possible that location_ids are not on those autoquotes because they are not
      // Fast Lane options, so we want to ensure location_id is stored as null in the db
      if (!foundLocationAutoquote) {
        setBulkPartConfig((prev: any) => ({
          ...prev,
          ...{ location_id: null },
        }));
        setSelectedTier(autoquoteResponseState[0]);
      }

      if (autoquoteResponseState.length > 1) {
        // check if the selected location_id exists in the response state
        const foundSelectedTier = autoquoteResponseState.find(
          autoquote => autoquote.location_id === bulkPartConfig.location_id
        );

        if (foundSelectedTier) {
          setSelectedTier(foundSelectedTier);
        } else {
          setBulkPartConfig((prev: any) => ({
            ...prev,
            ...{ location_id: autoquoteResponseState[0].location_id },
          }));
        }
      } else {
        setBulkPartConfig((prev: any) => ({
          ...prev,
          ...{ location_id: autoquoteResponseState[0].location_id },
        }));
        setSelectedTier(autoquoteResponseState[0]);
      }
    } else if (isAutoquoteError(autoquoteResponseState)) {
      setBulkPartConfig((prev: any) => ({
        ...prev,
        ...{ location_id: null },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoquoteResponseState]);

  const totalCriticalIssues: Array<number> = [];

  Object.values(selectedParts)?.map((failedCounts: any) =>
    failedCounts?.current_revision?.failed_manufacturability_check_counts_by_process?.find(
      (findMfgProcessId: DfmCheckFailedCounts) => {
        if (
          findMfgProcessId.manufacturing_process_id === bulkPartConfig.manufacturing_process_id &&
          findMfgProcessId.failed_error_checks_count > 0
        )
          totalCriticalIssues.push(findMfgProcessId.failed_error_checks_count);
      }
    )
  );
  const hasCriticalIssues = totalCriticalIssues.length > 0;

  const onOpenPartFileViewer = (part: Part) => {
    setCurrentPart(part);
    setIsPartViewerOpen(true);
  };

  // supporting docs stoof
  const supportingDocumentIsRequired = (() => {
    const inspectionTypeDocumentsRequired =
      bulkPartConfig.inspection_type &&
      bulkPartConfig.inspection_type.name !== INSPECTION_TYPE_NAMES.VisualCheck &&
      bulkPartConfig.inspection_type.name !== INSPECTION_TYPE_NAMES.BasicInspection;
    return inspectionTypeDocumentsRequired || cncSupportingDocumentsRequired(bulkPartConfig);
  })();

  const partsThatNeedDocsCount = (() => {
    if (!supportingDocumentIsRequired) return 0;
    let count = 0;
    Object.values(selectedParts).forEach((part: { current_docs: DocWithStatus[] }) => {
      if (part.current_docs?.length < 1) count++;
    });
    return count;
  })();

  const hasSupportingDocs = partsThatNeedDocsCount === 0;

  return (
    <>
      {currentPart && (
        <PartFileViewer
          partFileRevision={currentPart.current_revision}
          isOpen={isPartViewerOpen}
          onClose={() => setIsPartViewerOpen(false)}
        />
      )}

      {manufacturingProcesses && !bulkPartConfig.manufacturing_process_id && (
        <ManufacturingProcessSpecificationField
          handleCancel={handleCancel}
          handleSubmit={setInitialProcessDefaults}
          manufacturingProcesses={manufacturingProcesses}
        />
      )}
      {bulkPartConfig.manufacturing_process_id && (
        <Panel.Wrapper>
          <div className={classNames(['row w-full m-0', `h-[calc(100vh-190px)]`])}>
            <div className="order-1 col-lg p-0 h-full">
              <Page className="h-full overflow-scroll px-6 py-0 mt-4">
                <div className="sticky z-10 pt-3 top-0" style={{ backgroundColor: '#111115' }}>
                  <PageHeader
                    title={`Selected Parts (${Object.keys(selectedParts).length})`}
                    actions={
                      <Button outline size="sm" to="/add-part">
                        <IconFont name="plus" />
                        Add more parts
                      </Button>
                    }
                  />
                  <div className="w-full flex flex-row p-0">
                    {hasCriticalIssues && (
                      <div className="w-[354px] text-error-300 flex items-center leading-none">
                        <IconFont name="error-filled" className="text-2xl mr-1" />
                        {totalCriticalIssues.length}{' '}
                        {totalCriticalIssues.length > 1 ? 'parts have' : 'part has'} critical
                        issues
                      </div>
                    )}
                    <div className="w-full flex flex-row justify-end">
                      <span className="mr-[10px] p-0 text-coolGray-300">
                        Set all quantities:{' '}
                      </span>
                      <Input
                        className="h-auto"
                        inputClassname="w-10 ml-0 pb-0 pt-0 h-auto"
                        name="quantity"
                        value={allQuantities}
                        type="number"
                        size="md"
                        onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                          const value: number = numberInputMinValueFilter(
                            +e.currentTarget.valueAsNumber,
                            1
                          );
                          setAllQuantities(value);
                        }}
                        onBlur={(e: SyntheticEvent<HTMLInputElement>) => {
                          if (isNaN(e.currentTarget.valueAsNumber)) setAllQuantities(1);
                        }}
                        min={1}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row w-full h-[34px] align-middle rounded-sm items-center justify-between bg-coolGray-900 text-coolGray-300 text-xs px-2 py-1 mb-1">
                    <div>Part name</div>
                    <div className="flex flex-row">
                      <div className="min-w-[40px] mr-4">Qty</div>
                      <div className="min-w-[100px]">Unit price</div>
                      <div className="min-w-[100px]">Item total</div>
                      <div className="min-w-[90px]" />
                    </div>
                  </div>
                </div>
                <CardWrapper className={'grid-cols-1 mx-0 mb-6'}>
                  {Object.keys(selectedParts).map((key: string, index: number) => {
                    return (
                      <PartConfigurationCard
                        handleOpenPartViewer={onOpenPartFileViewer}
                        handleFieldChanges={(docs: DocWithStatus[]) => {
                          const updatedSelectedParts: any = [...Object.values(selectedParts)];
                          const partIndex = updatedSelectedParts.findIndex(
                            (p: any) =>
                              p.current_revision.id === selectedParts[key].current_revision.id
                          );
                          updatedSelectedParts[partIndex].current_docs = docs;

                          handleSelectParts(updatedSelectedParts);
                        }}
                        key={index}
                        needsSupportingDocs={supportingDocumentIsRequired}
                        onRemovePart={handleRemovePart}
                        part={selectedParts[key]}
                        manufacturingProcessId={bulkPartConfig?.manufacturing_process_id}
                        bulkPartConfig={bulkPartConfig}
                        autoquoteData={selectedTier?.parts.find(
                          autoquoteData =>
                            autoquoteData.part_file_revision_id ===
                            selectedParts[key].current_revision.id
                        )}
                        setQuantity={(quantity: number) => {
                          const updatedSelectedParts: any = [...Object.values(selectedParts)];
                          const partIndex = updatedSelectedParts.findIndex(
                            (p: any) =>
                              p.current_revision.id === selectedParts[key].current_revision.id
                          );
                          updatedSelectedParts[partIndex].quantity = quantity;
                          handleSelectParts(updatedSelectedParts);
                        }}
                      />
                    );
                  })}
                </CardWrapper>
              </Page>
            </div>
            <Panel
              className={classNames([
                `h-[calc(100vh-190px)] min-h-[calc(100vh-190px)] w-[520px]`,
              ])}
            >
              <BulkPartConfigForm
                autoquoteResponseState={autoquoteResponseState}
                bulkPartConfig={bulkPartConfig}
                parts={selectedParts}
                handleFieldChanges={handleFieldChanges}
                hasSupportingDocs={hasSupportingDocs}
              />
            </Panel>
          </div>
          <BottomPanel
            autoquoteResponseState={autoquoteResponseState}
            bulkPartConfig={bulkPartConfig}
            currentProcess={manufacturingProcesses?.find(
              mp => mp.id === bulkPartConfig.manufacturing_process_id
            )}
            disabled={isSubmitting}
            handleCancel={handleCancel}
            handleFieldChanges={handleFieldChanges}
            handleSave={handleSave}
            partsThatNeedDocsCount={partsThatNeedDocsCount}
          />
        </Panel.Wrapper>
      )}
    </>
  );
}
