import { SplitContext } from '@splitsoftware/splitio-react';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Tabs } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { useSaasBillingFeatureFlag } from 'fr-shared/hooks';
import { AddressTypeEnum } from 'fr-shared/lib/address';

import { Page, PageHeader } from 'portal/components';

import AccountDetailsContainer from './account_details';
import AccountAddresses from './account_details/components/AccountAddresses';
import AccountInformation from './account_details/components/AccountInformation';
import PaymentDetailsContainer from './payment_details';
import AccountPaymentMethod from './payment_details/components/AccountPaymentMethod';
import SubscriptionDetailsContainer from './subscription_details';

const AccountContainer = formik => {
  const { user } = useContext(UserContext);
  const { isReady } = useContext(SplitContext);

  const { setValues } = formik;

  useEffect(() => {
    setValues({ ...user });
  }, [user, setValues]);

  const ACCOUNT_ROUTES = {
    AccountDetails: '/account/details',
    PaymentDetails: '/account/payment-info',
    SubscriptionDetails: '/account/subscription/details',
  };

  const tabs = [
    { name: 'Account details', to: ACCOUNT_ROUTES.AccountDetails },
    { name: 'Subscription details', to: ACCOUNT_ROUTES.SubscriptionDetails },
    { name: 'Payment methods & addresses', to: ACCOUNT_ROUTES.PaymentDetails },
  ];

  const isSaasBillingEnabled = useSaasBillingFeatureFlag();
  if (!isReady) return null;
  return (
    <Page>
      {!isSaasBillingEnabled && (
        <>
          <PageHeader
            title="Account"
            subtitle="Update your profile and login information below"
          />
          <div className="row">
            <div className="col-md">
              <AccountInformation />
            </div>

            <div className="col-md">
              <AccountAddresses type={AddressTypeEnum.Shipping} />
            </div>

            <div className="col-md">
              <AccountAddresses type={AddressTypeEnum.Billing} />
            </div>
          </div>
          <AccountPaymentMethod />
        </>
      )}
      {isSaasBillingEnabled && (
        <>
          <Tabs tabs={tabs} />
          <Switch>
            <Route
              path={ACCOUNT_ROUTES.AccountDetails}
              render={() => <AccountDetailsContainer />}
            />
            <Route
              path={ACCOUNT_ROUTES.SubscriptionDetails}
              render={() => <SubscriptionDetailsContainer />}
            />
            <Route
              path={ACCOUNT_ROUTES.PaymentDetails}
              render={() => <PaymentDetailsContainer />}
            />
          </Switch>
        </>
      )}
    </Page>
  );
};

AccountContainer.propTypes = {
  formik: PropTypes.object,
};

export default withFormik({})(AccountContainer);
