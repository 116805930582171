import { connect } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { FormField, FormSelect } from 'fr-shared/components';
import { WORK_ORDER_STATES } from 'fr-shared/lib/work_orders';

import {
  BuildCartFormField,
  BuildDensityFormField,
  BuildHeightFormField,
  BuildTimeFormField,
  CoolingFormField,
  DetailingAgentFormField,
  FusingAgentFormField,
  HPPrintModeFormField,
  IrradianceFormField,
  MJFLinkFormField,
  MPILinkFormField,
  PowderConsumptionFormField,
  PrinterFormField,
} from './fields';

const WorkOrderDetailsMJF = ({ formik, isEditing, readonly }) => {
  const { state } = formik.values;

  return (
    <>
      <div className="card-body">
        <div className="row">
          <div className="col-lg">
            {isEditing && <PrinterFormField readonly={readonly} />}
            <BuildTimeFormField />
            <HPPrintModeFormField readonly={readonly} />

            <div className="row form-group flex pt-1">
              <fieldset>
                <div className="col-md-4">
                  <legend>Material Lots</legend>
                </div>
                <div className="col-md flex flex-column">
                  <FormField
                    name="material_lot_number_a"
                    prepend="Powder (Lot A)"
                    readonly={readonly}
                  />
                  <FormField
                    name="material_lot_number_b"
                    prepend="Fusing Agent (Lot B)"
                    readonly={readonly}
                  />
                  <FormField
                    name="material_lot_number_c"
                    prepend="Detailing Agent (Lot C)"
                    readonly={readonly}
                  />
                </div>
              </fieldset>
            </div>
          </div>

          <div className="col-lg">
            {state === 'Draft' && (
              <FormField
                name="num_of_build_plates"
                type="Number"
                label="# of Build Plates"
                readonly={readonly}
              />
            )}
            {isEditing && state !== 'Draft' && (
              <FormSelect
                label="State"
                name="state"
                optionList={WORK_ORDER_STATES}
                valueAccessor={option => option}
                nameAccessor={option => option}
                readonly={readonly}
              />
            )}
            <div className="col-md d-inline">
              <MJFLinkFormField showMJFLink={isEditing} readonly={readonly} />
            </div>

            <FormField name="notes" label="Notes" readonly={readonly} />
            <MPILinkFormField readonly={readonly} />
          </div>
        </div>
      </div>
      <div className="flex flex-column">
        <div className="card-footer bg-light">
          <div className="row">
            <div className="d-block d-lg-flex col-md">
              <FusingAgentFormField className="mr-1" readonly={readonly} stacked={true} />
              <DetailingAgentFormField className="mr-1" readonly={readonly} stacked={true} />
              <PowderConsumptionFormField className="mr-1" readonly={readonly} stacked={true} />
              <CoolingFormField readonly={readonly} stacked={true} style={{ minWidth: 100 }} />
            </div>
            <div className="d-block d-lg-flex col-md">
              <BuildCartFormField stacked={true} className="mr-1" readonly={readonly} />
              <IrradianceFormField className="mr-1" readonly={readonly} stacked={true} />
              <BuildDensityFormField className="mr-1" readonly={readonly} stacked={true} />
              <BuildHeightFormField readonly={readonly} stacked={true} />
            </div>
          </div>
        </div>

        {state === 'Failed' && (
          <div className="card-footer bg-danger">
            <h5>Build Failure Information</h5>
            <FormField
              name="failed_system_error"
              label="System Error"
              required={true}
              readonly={readonly}
            />
            <FormField name="failed_class" label="Class" readonly={readonly} />
            <FormField name="failed_sub_class" label="Sub Class" readonly={readonly} />
            <FormField name="failed_error_code" label="Error" readonly={readonly} />
            <FormField name="failed_hp_case_no" label="HP Case #" readonly={readonly} />
            <FormField
              name="failed_description"
              label="Comments"
              component="textarea"
              readonly={readonly}
            />
          </div>
        )}
      </div>
    </>
  );
};

WorkOrderDetailsMJF.propTypes = {
  buildCarts: PropTypes.array,
  formik: PropTypes.object,
  getMaterialGrams: PropTypes.func,
  getMaterialName: PropTypes.func,
  isEditing: PropTypes.bool,
  printersForProcess: PropTypes.array,
  readonly: PropTypes.bool,
};

export default connect(WorkOrderDetailsMJF);
