import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { PartDimensions, TableBodyCell, TableBodyRow } from 'portal/components';
import { date } from 'portal/lib/date';
import { PartModel } from 'portal/types/parts';

import PartNameCell from './PartNameCell/PartNameCell';

interface PartsListViewRowProps {
  part: PartModel;
}

const PartsListViewRow = ({ part }: PartsListViewRowProps) => {
  const dateAdded = date(part?.inserted_at);
  const dateEdited = date(part?.updated_at);
  const documents = part?.documents || [];
  const screenshotSrc = part?.current_revision.screenshot;

  return (
    <TableBodyRow>
      <TableBodyCell>
        <PartNameCell id={part.id} name={part.name} screenshotSrc={screenshotSrc} />
      </TableBodyCell>
      <TableBodyCell dataTestId="list-view-row-date-added-body-cell" value={dateAdded} />
      <TableBodyCell dataTestId="list-view-row-date-edited-body-cell" value={dateEdited} />
      <TableBodyCell>
        <PartDimensions part={part} />
      </TableBodyCell>
      <TableBodyCell value={documents.length} />
    </TableBodyRow>
  );
};

PartsListViewRow.propTypes = {
  part: PropTypes.shape({
    current_revision: PropTypes.shape({
      screenshot: PropTypes.string,
    }).isRequired,
    documents: PropTypes.array,
    id: PropTypes.number.isRequired,
    inserted_at: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    units: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(PartsListViewRow);
