import { sanitizeUrl } from '@braintree/sanitize-url';
import moment from 'moment';
import React from 'react';

import { Link } from 'fr-shared/components';
import { getOpportunityUrl } from 'fr-shared/lib/salesforce';

import OrderFormDisplayField from './OrderFormDisplayField';

interface OrderFormHeaderProps {
  order: {
    box_file_location?: string | null;
    deal?: {
      hashid: string;
    } | null;
    estimated_duties_taxes?: {
      formatted: string;
    } | null;
    opportunity_id?: string | null;
    public_id: string;
    quoted_shipping_price?: {
      formatted: string;
    } | null;
    total_price: {
      amount: string;
      formatted: string;
    };
    updated_at: string | moment.Moment;
    quote?: {
      id: number;
      version: number;
    };
  };
}

const OrderFormHeader = ({
  order: { box_file_location, deal, opportunity_id, public_id, total_price, updated_at, quote },
}: OrderFormHeaderProps) => {
  let orderTotalClassName = '';
  if (typeof total_price?.amount === 'string') {
    const parsed = parseFloat(total_price.amount);

    if (parsed > 0) {
      orderTotalClassName = 'text-green';
    } else if (parsed < 0) {
      orderTotalClassName = 'text-red';
    }
  }

  return (
    <div className="flex flex-wrap">
      <OrderFormDisplayField label="Public ID" value={public_id} />
      {opportunity_id && (
        <OrderFormDisplayField
          label="Opportunity ID"
          value={
            <Link to={{ pathname: getOpportunityUrl(opportunity_id) }} openNewWindow>
              {opportunity_id}
            </Link>
          }
        />
      )}
      {deal && <OrderFormDisplayField label="Deal #" value={deal.hashid} />}
      <OrderFormDisplayField
        label="Last Update"
        value={moment(updated_at).format('MM/DD/YY, h:mm A')}
      />
      <OrderFormDisplayField
        label="Order Total"
        value={total_price?.formatted}
        valueClassName={orderTotalClassName}
      />
      {quote && (
        <div className="mr-4 mb-2">
          <span className="text-muted mr-1">{'Quote # '}</span>
          <Link to={`/admin/quotes/${quote.id}`} className={''}>
            <strong>{`${quote.id}_v${quote.version}`}</strong>
          </Link>
        </div>
      )}
      <OrderFormDisplayField
        value={
          <Link to={{ pathname: sanitizeUrl(box_file_location) }} openNewWindow>
            <strong>Go To Box Folder</strong>
          </Link>
        }
      />
    </div>
  );
};

export default OrderFormHeader;
