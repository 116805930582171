import { INSPECTION_TYPE_NAMES } from 'fr-shared/lib/inspection_types';

export const getDaysAmt = (inspectionTypeName: string) => {
  switch (inspectionTypeName) {
    case INSPECTION_TYPE_NAMES.StandardInspection:
      return '1-3 days';
    case INSPECTION_TYPE_NAMES.AdvancedInspection:
      return '2-5 days';
    case INSPECTION_TYPE_NAMES.VisualCheck:
    case INSPECTION_TYPE_NAMES.BasicInspection:
    default:
      return '0 days';
  }
};
