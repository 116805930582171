import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Button, Modal } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';

export const DeleteShipmentModal = ({ shipmentId }: { shipmentId: number }) => {
  const { setAlert } = useContext(AlertContext);

  const history = useHistory();

  const handleDelete = () => {
    return api
      .delete(`/shipments/${shipmentId}`)
      .then(() => {
        setAlert({
          message: `Shipment ${shipmentId} was deleted successfully.`,
          color: 'success',
        });
        history.push('/admin/shipments');
      })
      .catch(error => {
        setAlert({
          color: 'danger',
          message: 'An error occurred: ' + JSON.parse(error.request.response).error,
        });
      });
  };

  return (
    <Modal
      action={
        <Button className="font-weight-normal" color="danger" size="md">
          Delete
        </Button>
      }
    >
      {({ toggle }: { toggle: () => void }) => (
        <>
          <Modal.Header title="Delete Shipment" onClose={toggle} />
          <Modal.Body className="font-size-base">
            Are you sure that you want to permanently delete shipment {shipmentId}?
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="danger"
              onClick={() => {
                handleDelete();
                toggle();
              }}
            >
              Delete Shipment
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default DeleteShipmentModal;
