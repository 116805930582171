import { useTreatments } from '@splitsoftware/splitio-react';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { CR_BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';

import Link from '../core/Link/Link';

const CostingRequestLink = ({ costingRequestId, className, lineItemId }) => {
  const { [CR_BUILD_PACK_UI_SUPPORT]: crBuildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );

  if (isNil(costingRequestId)) return null;

  const url = `/admin/costing_requests/${costingRequestId}${
    lineItemId ? `#${lineItemId}` : null
  }`;

  return (
    <div className={className}>
      {crBuildPackUiSupportFlag.treatment === 'on' ? <span>Last CR </span> : <span>CR </span>}

      <Link to={url} openNewWindow>
        #{costingRequestId}
      </Link>
    </div>
  );
};

CostingRequestLink.propTypes = {
  className: PropTypes.string,
  costingRequestId: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  lineItemId: PropTypes.string,
};

export default CostingRequestLink;
