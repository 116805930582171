import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { FirstOrderBadge, Icon, LineItemProcess, Link } from 'fr-shared/components';

import { lineItemDescriptionPrefix } from './utils/partFailuresTransforms';

const WorkOrderLineItem = ({ data, className, children }) => {
  const screenshot = get(data, 'order_line_item.part.current_revision.screenshot');
  const orderLineItem = get(data, 'order_line_item', {});
  const order = get(data, 'order_line_item.order');
  const customer = get(data, 'order_line_item.order.customer');
  const spawnedWorkOrderId = get(
    data,
    'spawned_from_part_failure.work_order_line_item.work_order_id'
  );

  return (
    <div
      className={`border-top flex align-items-left align-items-md-center py-1 px-3 flex-column flex-md-row ${className}`}
    >
      <div className="flex flex-row">
        {screenshot && (
          <Link
            className="mr-2"
            to={`/admin/viewer/order_line_item/${data?.order_line_item_id}`}
            openNewWindow
          >
            <img
              width={40}
              height={40}
              className="rounded"
              src={screenshot}
              alt="Show part viewer"
            />
          </Link>
        )}
        <div>
          <div className="mr-3">
            <strong>{lineItemDescriptionPrefix(data) + orderLineItem.description}</strong>
          </div>
          {orderLineItem.comments && (
            <div
              className="flex align-items-center font-size-sm line-height-sm"
              style={{ maxWidth: 800 }}
            >
              <Icon name="comment" className="mr-2" opacity sm />
              <span>{orderLineItem.comments}</span>
            </div>
          )}
          {orderLineItem.finishing && (
            <div className="flex align-items-center font-size-sm line-height-sm">
              <Icon name="clipboard-list" className="mr-2" opacity sm />
              <span>Finishing: {orderLineItem.finishing}</span>
            </div>
          )}
          <div className="d-none d-md-flex align-items-center">
            {order?.is_first_order && <FirstOrderBadge className="mr-2" fontSize="xs" />}
            {order && (
              <span className="font-size-md mr-2 ">
                <Link
                  data-testid={`order-link-${order.id}`}
                  to={`/admin/orders/${order.id}`}
                  openNewWindow
                >
                  FR {order.public_id}
                </Link>
              </span>
            )}
            {customer && (
              <span className="font-size-md mr-2">
                <Link to={`/admin/customers/${customer.id}`} openNewWindow>
                  <Icon name="user" right opacity /> {customer.name}
                </Link>
              </span>
            )}
            {spawnedWorkOrderId && (
              <span className="font-size-md mr-2">
                <Link to={`/admin/work_orders/${spawnedWorkOrderId}`} openNewWindow>
                  Original W.O. #{spawnedWorkOrderId}
                </Link>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="flex ml-auto align-items-center justify-content-end">
        <div className="d-none d-md-flex text-center align-items-center font-size-sm">
          {orderLineItem.actual_ship_date && (
            <div className="flex flex-column ml-3">
              <span>Actual</span>
              {moment(orderLineItem.actual_ship_date).format('MM/DD/YYYY')}
            </div>
          )}
        </div>
        <div className="ml-0 ml-md-3">
          <LineItemProcess data={orderLineItem} />
        </div>
        {children}
      </div>
    </div>
  );
};

WorkOrderLineItem.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default WorkOrderLineItem;
