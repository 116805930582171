import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import {
  AutoquoteBulkConfigRequest,
  AutoquoteError,
  BulkConfigAutoquoteTiersResponseState,
  fetchAutoquoteTiersForBulkConfig,
  newAutoquoteLoading,
} from 'portal/lib/autoquote';

const AUTOQUOTE_DEBOUNCE_MS = 400;

/**
 * Returns the state of the most recently requested autoquotes as a list based on lead times and a function
 * that can be called to request a new autoquote.
 */
const useRequestBulkConfigAutoquoteTiers = (initRequest: AutoquoteBulkConfigRequest) => {
  const [autoquoteTiersResponseState, setAutoquoteResponseState] =
    useState<BulkConfigAutoquoteTiersResponseState>(newAutoquoteLoading());

  useEffect(() => {
    fetchBulkConfigAutoquoteRequest(initRequest);
    /* eslint-disable-next-line */
  }, []);

  const fetchBulkConfigAutoquoteRequest = (request: AutoquoteBulkConfigRequest) => {
    setAutoquoteResponseState(newAutoquoteLoading());

    fetchAutoquoteTiersForBulkConfig(request)
      .then(res => {
        setAutoquoteResponseState(res);
      })
      .catch((error: AutoquoteError) => {
        setAutoquoteResponseState(error);
      });
  };

  const fetchAutoquoteTiersDebounced = useCallback(
    debounce(fetchBulkConfigAutoquoteRequest, AUTOQUOTE_DEBOUNCE_MS),
    []
  );

  return [autoquoteTiersResponseState, fetchAutoquoteTiersDebounced] as const;
};

export default useRequestBulkConfigAutoquoteTiers;
