import React from 'react';

import {
  Breadcrumb,
  Helmet,
  Sidebar,
  SidebarPage,
  SidebarPageContainer,
} from 'fr-shared/components';
import { useTableFilters } from 'fr-shared/hooks';

import CostingSearch from './components/CostingSearch';
import CostingSidebar from './components/CostingSidebar';
import CostingTable from './components/CostingTable';
import {
  FILTER_FIELDS,
  INITIAL_REQUESTED_FILTERS,
  INITIAL_REQUESTED_SORT,
  REQUESTED_COLUMNS,
  getRequestedCostingRequests,
} from './utils/queues';

const FILTER_NAME = 'costing_requested';
const FIELDS = [
  FILTER_FIELDS.SalesContactId,
  FILTER_FIELDS.ManufacturingProcessId,
  FILTER_FIELDS.EngineeringContactId,
  FILTER_FIELDS.SupportContactId,
];

const CostingRequestedQueue = () => {
  const {
    data: costingRequests,
    filters,
    loading,
    handleSearch: handleTextSearch,
    onFetchData: handleTableFetchData,
    pages,
    resetFilters,
    setFilters,
  } = useTableFilters(FILTER_NAME, INITIAL_REQUESTED_FILTERS, getRequestedCostingRequests);

  return (
    <>
      <Helmet title="Requested Queue" />
      <Breadcrumb to="/admin/costing_requests">Costing Requests</Breadcrumb>
      <Breadcrumb to="/admin/costing_requests/requested_queue">Requested Queue</Breadcrumb>

      <SidebarPageContainer localStorageKey={Sidebar.LOCAL_STORAGE_KEYS.costing_requested}>
        <SidebarPage>
          <div className="page-header flex mb-0 px-3">
            <h2>Requested Queue</h2>
            <CostingSearch
              filters={filters}
              setFilters={setFilters}
              onTextSearch={handleTextSearch}
            />
          </div>

          <CostingTable
            columns={REQUESTED_COLUMNS}
            data={costingRequests}
            emptyText="No Requests are currently awaiting review"
            loading={loading}
            onFetchData={handleTableFetchData}
            pages={pages}
            sort={INITIAL_REQUESTED_SORT}
          />
        </SidebarPage>

        <Sidebar>
          <CostingSidebar
            fields={FIELDS}
            filters={filters}
            resetFilters={resetFilters}
            setFilters={setFilters}
          />
        </Sidebar>
      </SidebarPageContainer>
    </>
  );
};

export default CostingRequestedQueue;
