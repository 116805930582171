import { Form, FormikValues, withFormik } from 'formik';
import React from 'react';

import { FormikHandleSubmit } from 'fr-shared/formik.d';

import DetailsPanel, {
  Schema as DetailsSchema,
  MARKETING_ROLES,
} from '../create_account/components/DetailsPanel';

export interface CustomizeFormValues {
  marketing_role: string;
}

export interface CustomizeFormProps {
  submitLoading: boolean;
  initialValues: FormikValues & CustomizeFormValues;
  onSubmit: FormikHandleSubmit<CustomizeFormProps, CustomizeFormValues>;
}

const CustomizeForm = ({ submitLoading }: CustomizeFormProps) => {
  return (
    <Form noValidate>
      <DetailsPanel
        data-testId="details_panel"
        submitLoading={submitLoading}
        submitLabel="Submit"
      />
    </Form>
  );
};

/**
 * Map a user's marketing role that's returned from the API to Formik values.
 */
const mapMarketingRoleToFormikValues = (marketingRole: string) => {
  if (!marketingRole) {
    return {
      marketing_role: null,
      marketing_role_other: null,
    };
  }

  if (MARKETING_ROLES.includes(marketingRole)) {
    return {
      marketing_role: marketingRole,
      marketing_role_other: null,
    };
  }

  return {
    marketing_role: 'Other',
    marketing_role_other: marketingRole,
  };
};

export default withFormik({
  mapPropsToValues: (props: CustomizeFormProps) => {
    return {
      ...props.initialValues,
      ...mapMarketingRoleToFormikValues(props.initialValues.marketing_role),
    };
  },
  validationSchema: DetailsSchema,
  validateOnBlur: true,
  validateOnChange: false,
  handleSubmit: (values, formik) => {
    formik.props.onSubmit(values, formik);
  },
})(CustomizeForm);
