import { useTreatments } from '@splitsoftware/splitio-react';
import { get, isEmpty, isNil } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { Button, ButtonLink, DocumentItem, Loading, Modal } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { CR_BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { keyboardDown } from 'fr-shared/utils';

const LineItemComparisonBadge = ({ lineItem, duplicatedFromId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = e => {
    e.preventDefault();
    setIsOpen(true);
  };
  return (
    <span className="badge rounded-pill bg-gray-100 font-size-md">
      <span
        onClick={handleOpenModal}
        onKeyDown={evt => keyboardDown(evt, 'Enter', handleOpenModal)}
        role="button"
        tabIndex={0}
      >
        Revision of <ButtonLink>{`ID: ${duplicatedFromId}`}</ButtonLink>{' '}
      </span>
      <LineItemComparisonModal
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        lineItem={lineItem}
        duplicatedFromId={duplicatedFromId}
      />
    </span>
  );
};

LineItemComparisonBadge.propTypes = {
  lineItem: PropTypes.object,
  duplicatedFromId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const LineItemComparisonModal = ({ lineItem, duplicatedFromId, isOpen, toggle }) => {
  const { [CR_BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );
  const capLineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'Build Pack' : 'Line Item';

  const [duplicatedFromLineItem, setDuplicatedFromLineItem] = useState(null);
  const [loading, setLoading] = useState(true);

  const { setAlert } = useContext(AlertContext);

  /**
   * Load the remote build pack on mount
   */
  useEffect(() => {
    // Only load the data if the modal is open
    if (!isOpen) return;
    api
      .get(`/costing_request_line_items/${duplicatedFromId}`)
      .then(res => {
        setDuplicatedFromLineItem(res.data);
      })
      .catch(() => {
        setAlert({ message: 'Line item information failed to load', color: 'danger' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isOpen, duplicatedFromId, setAlert]);

  return (
    <Modal className="modal-wide-xl" isOpen={isOpen} toggle={toggle}>
      <Modal.Header title={`Duplicate ${capLineItemName} Comparison View`} onClose={toggle} />
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <ComparisonTable lineItem={lineItem} duplicatedFromLineItem={duplicatedFromLineItem} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button color="primary" onClick={toggle}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

LineItemComparisonModal.propTypes = {
  lineItem: PropTypes.object,
  duplicatedFromId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export const ComparisonTable = ({ lineItem, duplicatedFromLineItem }) => {
  const { [CR_BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );
  const capLineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'Build Pack' : 'Line Item';
  const allCapLineItemName = capLineItemName.toUpperCase();

  const buildPackage = lineItem.build_package;
  const process = buildPackage.processes[0];
  const duplicatedFromLineItemBuildPackage = duplicatedFromLineItem.build_package;
  const duplicatedFromLineItemProcess = duplicatedFromLineItemBuildPackage.processes[0];
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" className="p-1">
            <div className="flex justify-content-between align-items-end">
              <strong className="font-size-sm text-muted">
                {`${allCapLineItemName} ${duplicatedFromLineItem?.id}`}
              </strong>
              <div className="font-size-xs text-muted">
                {duplicatedFromLineItem?.requested_at
                  ? `REQUESTED: ${moment(duplicatedFromLineItem.requested_at).format(
                      'MM/DD/YYYY'
                    )}`
                  : ''}
              </div>
            </div>
          </th>
          <th scope="col" className="p-1">
            <div className="flex justify-content-between align-items-end">
              <strong className="font-size-sm text-muted">
                {`DUPLICATE OF ${allCapLineItemName} ${duplicatedFromLineItem?.id}`}
              </strong>
              <div className="font-size-xs text-muted">
                {lineItem?.requested_at && lineItem?.id
                  ? `REQUESTED: ${moment(lineItem.requested_at).format('MM/DD/YYYY')}`
                  : ''}
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <Row
          lineItem1={duplicatedFromLineItem}
          lineItem2={lineItem}
          header="PART NAME"
          path="description"
        />
        <Row
          lineItem1={duplicatedFromLineItem}
          lineItem2={lineItem}
          header="FILE INFO"
          CellComponent={PartInformationCell}
        />
        <Row
          lineItem1={duplicatedFromLineItem}
          lineItem2={lineItem}
          header="FILE UNITS"
          path="units"
        />
        <Row
          lineItem1={duplicatedFromLineItemProcess}
          lineItem2={process}
          header="MFG PROCESS"
          path="manufacturing_process.name"
        />
        <Row
          lineItem1={duplicatedFromLineItemProcess}
          lineItem2={process}
          header="MATERIAL"
          path="material.name"
        />
        <Row
          lineItem1={duplicatedFromLineItemProcess}
          lineItem2={process}
          header="COLOR"
          path={get(process, 'color.name') ? 'color.name' : 'hex_color'}
        />
        <Row
          lineItem1={duplicatedFromLineItemProcess}
          lineItem2={process}
          header="FINISH NOTES"
          path={get(process, 'finish.name') ? 'finish.name' : 'finish_note'}
        />
        <Row
          lineItem1={duplicatedFromLineItemProcess}
          lineItem2={process}
          header="INSPECTION"
          path="inspection_type.name"
        />

        <Row
          lineItem1={duplicatedFromLineItem}
          lineItem2={lineItem}
          header="DOCUMENTS"
          CellComponent={DocumentCell}
        />
        <Row
          lineItem1={duplicatedFromLineItem}
          lineItem2={lineItem}
          header="NOTES"
          path={'notes'}
        />
        <Row
          lineItem1={duplicatedFromLineItem}
          lineItem2={lineItem}
          header="INTERNAL NOTES"
          path={'internal_notes'}
        />
        <Row
          lineItem1={duplicatedFromLineItemProcess}
          lineItem2={process}
          header="COSTS"
          disabled={true}
        />
        <SupplierRows lineItem1={duplicatedFromLineItem} lineItem2={lineItem} />
      </tbody>
    </table>
  );
};

ComparisonTable.propTypes = {
  lineItem: PropTypes.object,
  duplicatedFromLineItem: PropTypes.object,
};

const Row = ({ lineItem1, lineItem2, header, path, CellComponent = Cell, disabled = false }) => {
  return (
    <tr>
      <th scope="row" className="font-size-xs text-muted p-1">
        <strong>{header}</strong>
      </th>
      <td className={`p-1 ${disabled ? 'bg-light' : ''}`}>
        <CellComponent lineItem={lineItem1} path={path} />
      </td>
      <td className={`p-1 ${disabled ? 'bg-light' : ''}`}>
        <CellComponent lineItem={lineItem2} path={path} />
      </td>
    </tr>
  );
};

Row.propTypes = {
  disabled: PropTypes.bool,
  lineItem1: PropTypes.object,
  lineItem2: PropTypes.object,
  header: PropTypes.string,
  path: PropTypes.string,
  CellComponent: PropTypes.func,
};

const SupplierRows = ({ lineItem1, lineItem2 }) => {
  const suppliers1 = get(lineItem1, 'supplier_costs', []);
  const suppliers2 = get(lineItem2, 'supplier_costs', []);
  const indexRangeOfSuppliers = [...Array(Math.max(suppliers1.length, suppliers2.length)).keys()];

  return indexRangeOfSuppliers.map(index => {
    const supplier1 = get(suppliers1, `${index}`);
    const supplier2 = get(suppliers2, `${index}`);
    return (
      <Fragment key={`supplier-${index}`}>
        <tr>
          <th className="font-size-xs text-muted p-1" scope="row">
            <strong>SUPPLIER {index + 1}</strong>
          </th>
          <td className="p-1">
            <SupplierCell supplier={supplier1} />
          </td>
          <td className="p-1">
            <SupplierCell supplier={supplier2} />
          </td>
        </tr>
        <tr>
          <th className="font-size-xs text-muted p-1" scope="row">
            <strong>
              SUPPLIER {index + 1} <br /> NOTES & DOCS
            </strong>
          </th>
          <td className="p-1">
            <SupplierNotesCell supplier={supplier1} />
          </td>
          <td className="p-1">
            <SupplierNotesCell supplier={supplier2} />
          </td>
        </tr>
      </Fragment>
    );
  });
};

SupplierRows.propTypes = {
  lineItem1: PropTypes.object,
  lineItem2: PropTypes.object,
};

const SupplierCell = ({ supplier }) => {
  const supplierName = get(supplier, 'location.name');
  const partQuantities = get(supplier, 'part_quantities', []);

  return (
    <>
      {supplierName && <div>{supplierName}</div>}
      {!isEmpty(partQuantities) && (
        <div>
          {partQuantities.map((partQuantity, index) => {
            return (
              <Fragment key={`part-quantity-${index}`}>
                {partQuantity.quantity && `Qty ${partQuantity.quantity}`}
                {partQuantity?.per_unit_cost?.formatted &&
                  ` ${partQuantity.per_unit_cost.formatted}`}
                {partQuantity.lead_time && `, ${partQuantity.lead_time} business days`}
                {'; '}
              </Fragment>
            );
          })}
        </div>
      )}
    </>
  );
};

SupplierCell.propTypes = {
  index: PropTypes.number,
  supplier: PropTypes.object,
};

const SupplierNotesCell = ({ supplier }) => {
  const supplierNotes = get(supplier, 'notes');
  const supplierDocs = get(supplier, 'documents', []);

  return (
    <>
      {supplierNotes && <div>{supplierNotes}</div>}{' '}
      {supplierDocs.map((document, index) => {
        return document.deleted_at ? null : (
          <DocumentItem
            document={document}
            readonly={true}
            key={`modal-supplier-docs-${index}`}
          />
        );
      })}
    </>
  );
};

SupplierNotesCell.propTypes = {
  supplier: PropTypes.object,
};

const Cell = ({ lineItem, path }) => {
  const value = get(lineItem, path);
  if (isNil(value)) return null;

  return value;
};

Cell.propTypes = {
  lineItem: PropTypes.object,
  path: PropTypes.string,
};

const PartInformationCell = ({ lineItem }) => {
  const screenshot = get(lineItem, 'part.current_revision.screenshot');
  const part = get(lineItem, 'part');
  const currentRevision = get(lineItem, 'part.current_revision');
  const units = get(lineItem, 'units');
  const weight = get(lineItem, 'weight');
  const weightUnits = get(lineItem, 'weight_units');

  return (
    <div className="flex">
      <div className="flex align-items-center bg-light animated mr-2">
        <img width={120} height={120} src={screenshot} alt="Part screenshot" />
      </div>
      <div>
        <div className="font-size-md line-height-sm">
          <div className="flex mb-1">
            <strong className="mr-1">Part Name: </strong>
            <p className="text-break">{part.name}</p>
          </div>
          <div className="flex mb-1">
            <strong className="mr-1">Part ID: </strong>
            <p>{part.id}</p>
          </div>
          {currentRevision.max_x_length && (
            <div className="flex mb-1">
              <strong className="mr-1">Dimensions: </strong>
              <p>
                {currentRevision.max_x_length} x {currentRevision.max_y_length} x{' '}
                {currentRevision.max_z_length} {units}
              </p>
            </div>
          )}
          {currentRevision.volume && (
            <div className="flex mb-1">
              <strong className="mr-1">Part Volume: </strong>
              <p>
                {currentRevision.volume} {units}³
              </p>
            </div>
          )}
          {currentRevision.surface_area && (
            <div className="flex mb-1">
              <strong className="mr-1">Surface Area: </strong>
              <p>
                {currentRevision.surface_area} {units}²
              </p>
            </div>
          )}
          {weight && (
            <div className="flex mb-1">
              <strong className="mr-1">Estimated Part Weight: </strong>
              <p>
                {weight} {weightUnits}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PartInformationCell.propTypes = {
  lineItem: PropTypes.object,
};

const DocumentCell = ({ lineItem }) => {
  const docs = get(lineItem, 'part.documents', []);
  return docs.map((document, index) => {
    return document.deleted_at ? null : (
      <DocumentItem document={document} readonly={true} key={`modal-part-documents-${index}`} />
    );
  });
};

DocumentCell.propTypes = {
  lineItem: PropTypes.object,
};

export default LineItemComparisonBadge;
