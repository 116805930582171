import { isMatch } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { IconFont } from 'fr-shared/components';
import { withStatus } from 'fr-shared/components/form/SupportingDocuments';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { useInspectionTypes, useManufacturingProcess } from 'fr-shared/hooks';
import { getDefaultInspectionType } from 'fr-shared/lib/inspection_types';
import {
  MANUFACTURING_PROCESSES,
  getCNCManufacturingId,
  getCUManufacturingId,
  getDefaultValues,
  getIMManufacturingId,
} from 'fr-shared/lib/manufacturing_process';

import { Button, HelpModal, InspectionTypeForDefinition, classNames } from 'portal/components';
import useRequestAutoquoteTiers from 'portal/hooks/useRequestAutoquoteTiers';
import { isAutoquoteError } from 'portal/lib/autoquote';
import { CartLineItem, convertLineItemToAutoquoteRequest } from 'portal/lib/cart';
import { CNC_DEFAULT_VALUES } from 'portal/pages/part_config/util';

import Panel from '../../evaluate/components/Panel/Panel';
import DfmViewer from '../../evaluate/dfm/DfmViewer/DfmViewer';
import BottomPanel from './BottomPanel';
import DfmCheckButton, { CheckState } from './DfmCheckButton';
import DfmRunningModal from './DfmRunningModal';
import styles from './PartForm.module.css';
import PartFormFields, { PartFormState } from './PartFormFields';

interface PartFormConfigProps {
  initialLineItem: CartLineItem;
  onLineItemSave: (lineItem: CartLineItem) => Promise<boolean>;
}

/**
 * Converts the part form state into a cart line item to be saved
 */
export const convertStateToLineItem = (partFormState: PartFormState): CartLineItem => {
  return {
    ...partFormState,
    // send just the FK for the surface roughness
    cnc_surface_roughness: undefined,
    cnc_surface_roughness_id: partFormState.cnc_surface_roughness?.id ?? null,
    // send just the FK for tolerance
    tolerance: undefined,
    tolerance_id: partFormState.tolerance?.id ?? null,
  };
};

const PartFormConfig = ({ initialLineItem, onLineItemSave }: PartFormConfigProps) => {
  const history = useHistory();
  const userAnalytics = useUserAnalyticsContext();
  const [formTouched, setFormTouched] = useState(false);
  const [dfmRunningModalOpen, setDfmRunningModalOpen] = useState(false);
  const { data: manufacturingProcesses }: { data: ManufacturingProcess[] } =
    useManufacturingProcess({ publiclyAvailable: true });
  const cncId = manufacturingProcesses && getCNCManufacturingId(manufacturingProcesses);
  const cuId = manufacturingProcesses && getCUManufacturingId(manufacturingProcesses);
  const imId = manufacturingProcesses && getIMManufacturingId(manufacturingProcesses);

  const { data: inspectionTypes }: { data: InspectionTypeForDefinition[] } = useInspectionTypes();

  const [lineItem, setLineItem] = useState<PartFormState>({
    ...initialLineItem,
    notes: initialLineItem?.comments,
    customer_portal_finish: initialLineItem?.customer_portal_finish || 'Standard',
    infill: initialLineItem?.infill || 'Standard',
    current_documents: (initialLineItem?.part?.documents || []).map(withStatus),
  });

  const currentProcess = manufacturingProcesses?.find(
    mp => mp.id === lineItem.manufacturing_process_id
  );

  /**
   * Hooks used to get the current autoquote and request new ones
   */
  const [autoquoteResponseState, fetchAutoquote] = useRequestAutoquoteTiers(
    convertLineItemToAutoquoteRequest(lineItem)
  );

  /* Set the location_id on the line item form when we get the response back from the autoquotes api endpoint */
  useEffect(() => {
    if (!initialLineItem.location_id) {
      if (Array.isArray(autoquoteResponseState)) {
        // If we have an array of autuquotes, by default we always want to select the first (standard) one.
        setLineItem(lineItem => ({
          ...lineItem,
          ...{ location_id: autoquoteResponseState[0].location_id },
        }));
      }
    } else if (Array.isArray(autoquoteResponseState)) {
      // If we have an array of autoquotes, it's possible that location_ids are not on those autoquotes because they are not Fast Lane options, so we want to ensure location_id is stored as null in the db
      const foundLocationAutoquote = autoquoteResponseState.find(
        autoquote => autoquote.location_id
      );

      if (!foundLocationAutoquote || autoquoteResponseState.length === 0) {
        setLineItem(lineItem => ({
          ...lineItem,
          ...{ location_id: null },
        }));
      } else if (autoquoteResponseState.length <= 1) {
        setLineItem(lineItem => ({
          ...lineItem,
          ...{ location_id: autoquoteResponseState[0].location_id },
        }));
      } else if (isAutoquoteError(autoquoteResponseState)) {
        setLineItem(lineItem => ({
          ...lineItem,
          ...{ location_id: null },
        }));
      }
    }
  }, [initialLineItem, autoquoteResponseState]);

  const surfaceRoughnessDep = lineItem.cnc_surface_roughness?.id;
  const toleranceDep = lineItem.tolerance?.id;
  /**
   * When a user chooses options, we want to request new autoquote data
   */
  useEffect(() => {
    fetchAutoquote(convertLineItemToAutoquoteRequest(lineItem));
    /* eslint-disable-next-line */
  }, [
    lineItem.cnc_advanced_features,
    lineItem.cnc_certifications,
    lineItem.cnc_gdt,
    lineItem.cnc_masked_surfaces,
    lineItem.cnc_thread_count,
    lineItem.cnc_thread_variety,
    lineItem.cnc_tolerance_count_0005,
    lineItem.cnc_tolerance_count_001,
    lineItem.cnc_tolerance_count_002,
    lineItem.cnc_tolerance_count_003,
    lineItem.color_id,
    lineItem.current_documents,
    lineItem.customer_portal_finish,
    lineItem.finish_id,
    lineItem.infill,
    lineItem.inspection_type_id,
    lineItem.layer_thickness_id,
    lineItem.manufacturing_process_id,
    lineItem.material_id,
    lineItem.quantity,
    toleranceDep,
    surfaceRoughnessDep,
  ]);

  /**
   * When the inspection type or manufacturing types load or the user changes the manufacturing process, let's update the line item inspection type
   */
  const defaultInspectionTypeDependencies = [
    inspectionTypes,
    manufacturingProcesses,
    lineItem.manufacturing_process_id,
  ];
  useEffect(() => {
    if (inspectionTypes) {
      setLineItem(prev => ({
        ...prev,
        ...getDefaultInspectionType(cncId, cuId, imId, inspectionTypes, currentProcess?.id),
      }));
    }
    /* eslint-disable-next-line */
  }, defaultInspectionTypeDependencies);

  const pushToStudio = useCallback(
    async (lineItem: PartFormState, cliId: number | string, partId: number | string) => {
      if (partId) {
        await onLineItemSave(convertStateToLineItem(lineItem));
        history.push(`/studio/evaluate/${partId}/dfm`, {
          cart_line_item_id: cliId,
          manufacturing_process_id: lineItem?.manufacturing_process_id,
          material_id: lineItem?.material_id,
          color_id: lineItem?.color_id,
          quantity: lineItem?.quantity,
        });
      }
    },
    /* eslint-disable-next-line */
    [history]
  );

  const cliId = initialLineItem?.id;
  const partId = initialLineItem?.part?.id;

  const onClickDfmButton = (state: CheckState) => {
    if (state === 'checks-incomplete') {
      setDfmRunningModalOpen(true);
    } else {
      handleClickDfmNotification();
    }
  };

  if (!lineItem || !initialLineItem.part) {
    return <Redirect to="/add-part" />;
  }

  const handleFieldChanges = (changes: Partial<PartFormState>, touched = true) => {
    setLineItem(lineItem => {
      const newObj = { ...lineItem, ...changes };
      return newObj;
    });
    if (touched) {
      setFormTouched(true);
    }
  };

  const handleSave = async () => {
    const success = await onLineItemSave(convertStateToLineItem(lineItem));
    if (success) {
      history.push('/quotes/draft');
    }
  };

  const handleBack = () => {
    history.push('/quotes/draft');
  };

  const defaultValues = getDefaultValues(currentProcess, CNC_DEFAULT_VALUES);
  const resetIsDisabled = defaultValues && isMatch(lineItem, defaultValues);

  const handleResetToDefaults = () => {
    handleFieldChanges(defaultValues);
  };

  const handleClickDfmNotification = () => {
    userAnalytics.track('Quote Part Viewer - Click DFM Warning');
    pushToStudio(lineItem, cliId, partId);
  };

  const revision = initialLineItem.part.current_revision;
  const checksRan = revision.did_manufacturability_checks_run;
  const checkCounts = !checksRan
    ? null
    : revision.failed_manufacturability_check_counts_by_process.find(
        counts => counts.manufacturing_process_id === lineItem.manufacturing_process_id
      );

  return (
    <Panel.Wrapper>
      <div className={classNames(['row w-full m-0', `h-[calc(100vh-220px)]`])}>
        <div className="order-1 col-lg p-0 h-full">
          <div className={classNames([styles.ViewerPortal])}>
            <DfmViewer
              className={classNames([
                'min-h-min',
                `h-[calc(100vh-220px)] min-h-[calc(100vh-220px)]`,
              ])}
              partFileRevision={initialLineItem.part.current_revision}
              showControls={true}
              showDetails={true}
            />

            <div className="flex absolute left-4 bottom-3 z-[1010]">
              <HelpModal
                userAnalyticsPrefix="Quote"
                action={
                  <Button
                    outline
                    className="text-white inline-block min-w-[128px] text-sm py-1 mr-2"
                  >
                    <IconFont name="comments" className="text-base mr-0" /> Help
                  </Button>
                }
              />
              <DfmRunningModal
                isOpen={dfmRunningModalOpen}
                onClose={() => setDfmRunningModalOpen(false)}
              />
              {(!checksRan || lineItem.manufacturing_process_id !== null) && (
                <DfmCheckButton failedChecksCount={checkCounts} onClick={onClickDfmButton} />
              )}
            </div>
          </div>
        </div>
        <Panel className={classNames([`h-[calc(100vh-220px)] min-h-[calc(100vh-220px)]`])}>
          <div className="p-3">
            <div className="flex flex-col pb-2 border-coolGray-600 border-bottom mb-3">
              {(currentProcess?.name === MANUFACTURING_PROCESSES.CNC ||
                currentProcess?.autoquotable_defaults) && (
                <div className="flex justify-end">
                  <Button
                    className={classNames([
                      'w-fit flex items-center bg-transparent border-0 p-0 leading-none',
                      resetIsDisabled ? 'text-coolGray-500' : 'text-coolGray-300',
                    ])}
                    color="none"
                    disabled={resetIsDisabled}
                    onClick={handleResetToDefaults}
                  >
                    <IconFont name="reset" className="text-2xl" />
                    <div className="text-xs">Reset to defaults</div>
                  </Button>
                </div>
              )}
              <h3 className="text-lg m-0" data-testid="part-config-create-quote">
                Manufacturing specs
              </h3>
            </div>

            <PartFormFields
              autoquoteResponseState={autoquoteResponseState}
              formState={lineItem}
              part={initialLineItem.part}
              onChanges={handleFieldChanges}
              readonly={initialLineItem?.read_only} // TODO(CX-711): remove with preconfig entitlement
            />
          </div>
        </Panel>
      </div>

      <div className="row mr-0 w-full m-0">
        <BottomPanel
          autoquoteResponseState={autoquoteResponseState}
          currentProcess={currentProcess}
          failedManufacturabilityCheckCounts={checkCounts}
          formTouched={formTouched}
          initialLineItem={initialLineItem}
          lineItem={lineItem}
          handleBack={handleBack}
          handleFieldChanges={handleFieldChanges}
          handleSave={handleSave}
          onStudio={() => pushToStudio(lineItem, cliId, partId)}
        />
      </div>
    </Panel.Wrapper>
  );
};

export default PartFormConfig;
