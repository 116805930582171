import React, { useCallback, useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { ProgressCircle } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';

import { Page } from 'portal/components';

import CartDetailsBody from './components/CartDetailsBody';
import CartDetailsHeader from './components/CartDetailsHeader';

const EditCart = () => {
  const [cart, setCart] = useState(null);
  const { setAlert } = useContext(AlertContext);

  const refreshCart = useCallback(() => {
    return api
      .post('/customer_portal/cart')
      .then(res => {
        const { data: cart } = res;
        setCart(cart);
      })
      .catch(() => {
        setAlert({
          color: 'danger',
          message: 'An unexpected error occurred, please refresh and try again',
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCart]);

  /** Load the users cart into formik */
  useEffect(() => {
    refreshCart();
  }, [refreshCart]);

  if (!cart)
    return (
      <Page className="flex justify-center">
        <ProgressCircle
          percentage={75}
          spinner={true}
          backgroundColor="#1b1a20"
          fadesIntoBackground
        />
      </Page>
    );

  return (
    <Page>
      <CartDetailsHeader cart={cart} />
      <CartDetailsBody cart={cart} refreshCart={refreshCart} />
    </Page>
  );
};

EditCart.propTypes = {};

export default EditCart;
