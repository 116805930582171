import { IconFont } from '@fast-radius/shared-ui';
import { Form, Formik, useFormikContext } from 'formik';
import { get } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AlertContext, UserContext, useUserAnalyticsContext } from 'fr-shared/context';
import { useCurrentSubscriptionPlan, usePlans } from 'fr-shared/hooks';

import { Link, Loading } from 'portal/components';
import {
  BILLING_CYCLE,
  SUBSCRIPTION,
  createOrCancelPlan,
  getPlanIdByName,
  getPricingBasedOffSelectedPlan,
} from 'portal/lib/plans';
import { subscriptionSchema } from 'portal/lib/schema';

import CheckoutBilling from '../components/CheckoutBilling';
import CheckoutLayout from '../components/CheckoutLayout';
import CheckoutReview from '../components/CheckoutReview';
import CheckoutSummary from '../components/CheckoutSummary';
import BillingFrequency from './components/BillingFrequency';

const SubscriptionCheckoutContainer = () => {
  const location = useLocation();
  const userAnalytics = useUserAnalyticsContext();
  const history = useHistory();
  const { setAlert } = useContext(AlertContext);
  const { refreshUser } = useContext(UserContext) as any;

  const { data: subscriptionPlans } = usePlans();
  const { subscriptionData } = useCurrentSubscriptionPlan();
  const planType = new URLSearchParams(location.search).get('plan');
  const [initialValues, setInitialValues] = useState(null);

  const { annualPrice, monthlyPrice } = getPricingBasedOffSelectedPlan(
    planType,
    subscriptionPlans ?? []
  );

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    const logEvt = (valid: boolean) => userAnalytics.track('Checkout - Subscription', { valid });

    setSubmitting(true);

    const subscription = {
      ...values.subscription,
      payment_method_nonce: values.payment_method_nonce,
      billing_address: values.billing_address,
    };

    createOrCancelPlan(planType, subscription, subscriptionData?.id)
      .then(({ data }) => {
        logEvt(true);
        history.push(`/subscription/confirmation/${data.id}`);
      })
      .catch(() => {
        logEvt(false);
        setAlert({
          message: 'An unexpected error occurred. Please refresh and try again.',
          color: 'danger',
        });
      })
      .finally(() => {
        refreshUser();
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (subscriptionPlans) {
      setInitialValues({
        billing_address: {},
        payment_details: {},
        subscription: {
          plan_id: getPlanIdByName(planType, subscriptionPlans),
          subscription_type: planType,
          billing_cycle:
            monthlyPrice && !annualPrice ? BILLING_CYCLE.MONTHLY : BILLING_CYCLE.ANNUALLY,
        },
        subscription_plans: subscriptionPlans,
        payment_method_nonce: '',
        credit_card_checkout: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionPlans]);

  if (!initialValues) return null;

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        <SubscriptionCheckoutForm />
      </Form>
    </Formik>
  );
};

const SubscriptionCheckoutForm = () => {
  const formik: any = useFormikContext();
  const location = useLocation();
  const planType = new URLSearchParams(location.search).get('plan');

  const isBasicSubscription = planType === SUBSCRIPTION.BASIC;
  const isFormValid = subscriptionSchema.isValidSync(formik.values) || isBasicSubscription;

  const subscriptionType = get(formik.values, 'subscription.subscription_type');
  const subscriptionPlans = get(formik.values, 'subscription_plans', []);
  const isAnnualSelected =
    get(formik.values, 'subscription.billing_cycle') === BILLING_CYCLE.ANNUALLY;

  const { totalAnnualPrice, monthlyPrice } = getPricingBasedOffSelectedPlan(
    subscriptionType,
    subscriptionPlans
  );

  if (formik.isSubmitting) {
    return (
      <div className="text-center mt-40">
        <Loading type="circle" />
      </div>
    );
  }

  return (
    <CheckoutLayout
      backLink={
        <Link className="font-size-sm text-gray text-decoration-none" to="/account/details">
          <IconFont name="chevron-left" right />
          Back to account
        </Link>
      }
      mainContent={
        <>
          {!isBasicSubscription && (
            <>
              <BillingFrequency
                formik={formik}
                isActive={location.pathname.includes('frequency')}
              />
              <CheckoutBilling
                analyticsContext="Subscription Checkout"
                isActive={location.pathname.includes('billing')}
                formik={formik}
                isSubscriptionCheckout={true}
              />
            </>
          )}
          <CheckoutReview
            formik={formik}
            isActive={location.pathname.includes('review')}
            isSubmitDisabled={!isFormValid}
            isSubscriptionCheckout={true}
            totalPrice={isAnnualSelected ? totalAnnualPrice : monthlyPrice}
          />
        </>
      }
      isSubscriptionCheckout={true}
      summary={
        <CheckoutSummary
          canEdit={false}
          isSubscriptionCheckout={true}
          totalPrice={isAnnualSelected ? totalAnnualPrice : monthlyPrice}
        />
      }
    />
  );
};

export default SubscriptionCheckoutContainer;
