export const PROCESS_DESCRIPTIONS: Record<string, string> = {
  DLS: 'A resin-based polymer printing process using light and heat to achieve isotropic properties.',
  FDM: 'The most common method of 3D printing, with short lead times and high cost efficiency.',
  MJF: 'A powder bed fusion process that gives a greater degree of design freedom.',
  SLA: 'Can produce parts with excellent surface finishes and small feature resolution.',
  CNC: 'A computer-controlled machining process that removes material from a solid workpiece.',
  'Injection Molding':
    'Injection molding is a simple process that can quickly produce intricate shapes for parts.',
  'Cast Urethane':
    'When resin is poured into a mold and put in a vacuum chamber, to form a solid part.',
};

export const MATERIAL_DESCRIPTIONS: Record<string, string> = {
  'EPU 40': 'An elastic polyurethane giving tear strength, energy return and impact absorption.',
  'EPU 41':
    'An elastic polyurethane well-suited for elastomeric lattices, to give high resiliency.',
  'MPU 100': 'Medical Polyurethane (MPU 100) is a biocompatible, engineering-grade material.',
  'RPU 130': 'Rigid Polyurethane (RPU 130) is a strong, tough, heat resistant polyurethane.',
  'RPU 70': 'Rigid Polyurethane (RPU 70) is a strong, tough and versatile 3D printing material.',
  'FPU 50': 'FPU 50 is a strong, semi-rigid resin with impact, abrasion and fatigue resistance. ',
  'CE 221':
    'CE 221 offers good strength, stiffness and high temperature and chemical resistance.',
  'IND 405':
    'IND 405 is a one-part 3D printing resin developed for the DLS 3D printing process. ',
  'UMA 90': 'UMA 90 is a photosensitive polymer liquid resin designed for DLS 3D printing. ',
  'EPX 82':
    'EPX 82 is a plastic resin with high precision attributes, developed for DLS 3D printing.',
  'SIL 30':
    'SIL 30 is a silicone urethane with biocom- patibility, low durometer, and tear resistance.',
  'ABS-ESD7': 'ABS-ESD7 is a thermoplastic additive material with static dissipative properties.',
  'ABS-M30':
    'ABS-M30 is lightweight and offers enhanced strength, toughness and impact resistance.',
  'ABS-M30i': 'ABS-M30i is a biocompatible thermoplastic commonly used in the medical industry.',
  'Nylon 12':
    'Nylon 12 is a strong thermoplastic with high impact, abrasion and chemical resistance.',
  'Nylon 12 CF': 'Nylon 12 CF is reinforced with chopped carbon fiber, for added strength.',
  'Nylon 6': 'Nylon 6 is smooth and elastic, offering excellent abrasion and fatigue resistance.',
  PPSF: 'PPSF combines mechanical strength with good chemical and heat resistance.',
  'ST-130':
    'ST-130 is a soluble, sacrificial material for making complex composite 3D-printed tools.',
  'PC-ABS': 'PC-ABS is tough, durable and heat resistant, while offering good flexural strength.',
  'Antero 800NA':
    'Antero 800NA has superior strength and exceptional chemical and wear resistance.',
  'Antero 840CN03': 'Antero 840CN03 is a PEKK-based thermoplastic with high heat resistance.',
  'PC-10': 'PC-10 is a thermoplastic with high strength, rigidity and stability.',
  'PC-ISO': 'PC-ISO is a biocompatible thermoplastic widely used for medical devices.',
  'Loctite IND 405 Clear':
    'A semi-transparent material with high elongation, toughness and impact resistance.',
  ASA: 'ASA is easily workable, offering high resistance to impact, heat and UV light.',
  'Nylon 12CF': 'Nylon 12 CF is reinforced with chopped carbon fiber, for added strength.',
  'Ultem 9085 CG': 'Ultem 9085 CG is a 3D printing resin with high heat resistance.',
  'Ultem 1010':
    'Ultem 1010 is a high performance PEI that offers high strength and heat resistance.',
  'Ultem 9085': 'Ultem 9085 is a strong, lightweight PEI with flame retardant properties.',
  'PA 12':
    'PA 12 is a strong general-use thermoplastic with toughness and high impact resistance.',
  TPA: 'TPA is a durable, lightweight polyamide powder with good rebound resilience.',
  Clear:
    'Clear Resin is a transparent SLA 3D printing resin ideal for making strong plastic parts.',
  Elastic: 'Elastic Resin suits high elongation and high energy return requirements.',
  Flexible:
    'Flexible Resin is rubber-like and can, to an extent, be bent, compressed and stretched.',
  Rigid: 'Rigid Resin is designed to withstand extensive testing and repeated stress.',
  'Surgical Guide':
    'Surgical Guide Resin is an autoclavable, biocompatible resin for 3D printing.',
  'Tough 1500':
    'Tough 1500 Resin is for pliable parts that need to spring back to their original shape.',
  'Tough 2000':
    'Tough 2000 Resin is formulated to withstand extensive testing and repeated stress.',
  'High Temp':
    'High Temp Resin is designed for extreme temperature resistance and high strength.',
  Durable:
    "Durable Resin offers good impact resistance. It's naturally lubrous and slightly pliable.",
  White:
    'White Resin gives precision surface details and produces parts with a matte white finish.',
  Color: 'Color resin produces color parts, with the same properties as other SLA resins.',
};
