export interface Order {
  line_items?: OrderLineItem[];
}

export interface OrderLineItem {
  is_prototype?: string | boolean;
}

export const ORDER_SORT_VALUES = {
  ['Order #']: 'public_id',
  ['Status']: 'state',
  ['PO #']: 'customer_po',
  ['Date ordered']: 'inserted_at',
};

export const ORDER_STATES = {
  Draft: 'Draft',
  Pending: 'Pending',
  ActionRequired: 'Action Required',
  CustomerOrderPlaced: 'Customer Order Placed',
  Confirmed: 'Confirmed',
  Production: 'Production',
  QualityControl: 'Quality Control',
  Shipped: 'Shipped',
  Canceled: 'Canceled',
};

export const ORDER_DRAFT_STATES = [ORDER_STATES.Draft, ORDER_STATES.ActionRequired];
export const ORDER_APPROVED_STATES = [
  ORDER_STATES.CustomerOrderPlaced,
  ORDER_STATES.Confirmed,
  ORDER_STATES.Production,
  ORDER_STATES.QualityControl,
  ORDER_STATES.Shipped,
  ORDER_STATES.Canceled,
];

export const ORDER_STATE_COLORS: { [key: string]: string } = {
  Draft: 'secondary',
  Pending: 'dark',
  'Action Required': 'danger',
  'Customer Order Placed': 'dark',
  Confirmed: 'dark',
  Production: 'primary',
  'Quality Control': 'primary',
  Shipped: 'success',
  Canceled: 'secondary',
};

export const ORDER_LINE_ITEM_STATES = {
  Draft: 'Draft',
  Pending: 'Pending',
  ActionRequired: 'Action Required',
  CustomerOrderPlaced: 'Customer Order Placed',
  SupplierConfirmed: 'Supplier Confirmed',
  ProductionScheduling: 'Production Scheduling',
  ReleasedToFloor: 'Released to Floor',
  PartialProduction: 'Partial Production',
  Production: 'Production',
  QualityControl: 'Quality Control',
  ReadyToShip: 'Ready to Ship',
  InTransition: 'In Transition',
  Shipped: 'Shipped',
  Canceled: 'Canceled',
  RFQSent: 'RFQ Sent',
  NeedsReview: 'Needs Review',
  NeedsRFQ: 'Needs RFQ',
};

// lib/fast_radius/orders/field_values.ex:order_types/0
// Must be kept in sync with Salesforce Shipped Orders
// Contact Connor Reilly to update the SF list
export const ORDER_TYPES = [
  'Customer Development',
  'Engineering Design',
  'Internal Development',
  'Inventory Building',
  'Marketing',
  'Personal',
  'Production',
  'RMA',
  'Sample',
];

export const ORDER_PAYMENT_TERMS_ENUM = {
  CreditCard: 'Credit Card',
  DueOnReceipt: 'Due on receipt',
  Net30: 'Net 30',
  Net45: 'Net 45',
  Net60: 'Net 60',
  Net75: 'Net 75',
  Net90: 'Net 90',
  Net120: 'Net 120',
};

const PO_PAYMENT_TERMS = [
  ORDER_PAYMENT_TERMS_ENUM.Net30,
  ORDER_PAYMENT_TERMS_ENUM.Net45,
  ORDER_PAYMENT_TERMS_ENUM.Net60,
  ORDER_PAYMENT_TERMS_ENUM.Net75,
  ORDER_PAYMENT_TERMS_ENUM.Net90,
  ORDER_PAYMENT_TERMS_ENUM.Net120,
];

export const ORDER_PAYMENT_TERMS = [
  ...PO_PAYMENT_TERMS,
  ORDER_PAYMENT_TERMS_ENUM.CreditCard,
  ORDER_PAYMENT_TERMS_ENUM.DueOnReceipt,
];

export const ORDER_PAYMENT_TYPES = [
  'Customer PO',
  'Customer Credit Card',
  'Need Info',
  'Production Credit',
  'Check',
  'Free',
];

export const BILLABLE_SERVICES = [
  'Non-Recurring Engineering',
  '2D/3D Conversion',
  'Design Assistance',
  'Design from Scratch',
  'Design Optimization',
  'File Type Conversion',
  'Finite Element Analysis',
  'Reverse Engineering',
  'Value Analysis/Value Engineering',
];

export const NEW_ORDER_LINE_ITEM: { [key: string]: string | boolean | object[] | void } = {
  box_file_url: null,
  description: '',
  documents: [],
  isOpen: true,
  selected: false,
  source: 'FROS',
  state: 'Customer Order Placed',
  units: 'mm',
};

export const CUSTOMER_PORTAL_PO_ORDER_ITEMS_SUBTOTAL_MINIMUM = 0;

export const isPoPaymentTerm = (paymentTerm: string) => PO_PAYMENT_TERMS.includes(paymentTerm);
