export interface HeterogeneousShippingError extends ShippingError {
  reason: 'heterogeneous_shipping_error';
  international_count: number;
  domestic_count: number;
}
export const isHeterogeneousShippingError = (
  error: ShippingError
): error is HeterogeneousShippingError => {
  return error.reason === 'heterogeneous_shipping_error';
};

export interface CustomMaterialsError extends ShippingError {
  reason: 'custom_materials_error';
  material_names: string[];
}
export const isCustomMaterialsError = (error: ShippingError): error is CustomMaterialsError => {
  return error.reason === 'custom_materials_error';
};

export interface MaterialsDensityMissingError extends ShippingError {
  reason: 'materials_density_missing_error';
  material_names: string[];
}
export const isMaterialsDensityMissingError = (
  error: ShippingError
): error is MaterialsDensityMissingError => {
  return error.reason === 'materials_density_missing_error';
};

export interface AllLineItemsExcludedError extends ShippingError {
  reason: 'all_line_items_excluded_error';
  part_names: string[];
}
export const isAllLineItemsExcludedError = (
  error: ShippingError
): error is AllLineItemsExcludedError => {
  return error.reason === 'all_line_items_excluded_error';
};

export interface OverweightShippingError extends ShippingError {
  reason: 'overweight_shipping_error';
  weight_lbs: number;
}
export const isOverweightShippingError = (
  error: ShippingError
): error is OverweightShippingError => {
  return error.reason === 'overweight_shipping_error';
};

export interface AssemblyLineItemError extends ShippingError {
  reason: 'assembly_line_item_error';
  line_item_ids: number[];
}
export const isAssemblyLineItemError = (error: ShippingError): error is AssemblyLineItemError => {
  return error.reason === 'assembly_line_item_error';
};

export interface NilVolumeError extends ShippingError {
  reason: 'nil_volume_error';
  line_item_ids: number[];
}
export const isNilVolumeError = (error: ShippingError): error is NilVolumeError => {
  return error.reason === 'nil_volume_error';
};

export interface PostalCodeValidationError extends ShippingError {
  reason: 'postal_code_validation_error';
  code: string;
}
export const isPostalCodeValidationError = (
  error: PostalCodeValidationError
): error is PostalCodeValidationError => {
  return error.reason === 'postal_code_validation_error';
};
