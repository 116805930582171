import React from 'react';
import { useParams } from 'react-router-dom';

import { AddressTypeEnum } from 'fr-shared/lib/address';

import Addresses from '../components/Addresses';

export const ContactAddresses = () => {
  const { user_id: userId } = useParams();
  return (
    <div>
      <div className="mb-5">
        <Addresses type={AddressTypeEnum.Billing} userId={userId} />
      </div>

      <div className="mb-5">
        <Addresses type={AddressTypeEnum.Shipping} userId={userId} />
      </div>
    </div>
  );
};

export default ContactAddresses;
