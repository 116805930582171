import PropTypes from 'prop-types';
import React from 'react';

import { IconFont, Navbar } from 'fr-shared/components';

import { Button } from 'portal/components';

import styles from './NavbarInsights.module.css';

const LogoExtra = <div className={styles.LogoExtra}>Insights Report</div>;

const NavbarInsights = ({ children, customer, customerContact, mailTo, salesContact }) => {
  return (
    <>
      <Navbar
        container="sm"
        color="dark"
        className={`${styles.Navbar} sticky-top`}
        logoUrl={'/'}
        showLogoExtra={LogoExtra}
        showEnvironment={false}
      >
        <Button size="sm" to={{ pathname: mailTo }} openNewWindow>
          <IconFont name="email" right={true} /> Email {salesContact.first_name}
        </Button>
      </Navbar>

      <div className="container-sm flex align-items-center py-3">
        <div className="text-muted">
          {customerContact.name}, {customer.name}
        </div>

        {children}
      </div>
    </>
  );
};

NavbarInsights.propTypes = {
  children: PropTypes.node,
  customer: PropTypes.object,
  customerContact: PropTypes.object,
  mailTo: PropTypes.string,
  salesContact: PropTypes.object,
};

export default NavbarInsights;
