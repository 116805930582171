import { FieldArray, Form, withFormik } from 'formik';
import { set, sumBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Button, Modal } from 'fr-shared/components';

import YieldReportLineItem from './YieldReportLineItem';
import YieldReportMarkAllModal from './YieldReportMarkAllModal';

const YieldReportForm = ({ values, workOrder, resetForm }) => {
  const [rejectionReasons, setRejectionReasons] = useState([]);
  const history = useHistory();
  useEffect(() => {
    resetForm({ values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm, workOrder]);

  useEffect(() => {
    // all work orders currently require to be the same manufacturing process type,
    // if this changes in the future, the rejection reasons to be displayed will
    // potentially show up incorrectly.

    const { manufacturing_process_id } = workOrder.line_items[0].order_line_item;
    api
      .get('/rejection_reasons', {
        params: { page_size: 1000, manufacturing_process_id, sort_key: 'description' },
      })
      .then(res => {
        setRejectionReasons(res.data);
      });
  }, [workOrder]);

  return (
    <Form noValidate={true}>
      <div className="page-header" data-testid="yield-report-form">
        <div className="container flex flex-column flex-md-row">
          <div className="d-block">
            <h2 className="mb-2">Yield Report</h2>
            <h4 className="mb-0">
              <small className="text-muted mr-2">
                WORK ORDER # {values && values.work_order_id}
              </small>
            </h4>
          </div>
          <div className="page-actions mt-2 d-flex d-md-block flex-column flex-md-row">
            <Button
              onClick={e => history.goBack(e)}
              className="d-flex d-md-inline-block justify-content-center flex-1 mr-md-2"
            >
              Back
            </Button>
            <Modal
              action={
                <Button
                  color="primary"
                  className="d-flex d-md-inline-block justify-content-center flex-1 mr-md-2 mt-2 mt-md-0"
                >
                  Mark All as Failed
                </Button>
              }
            >
              {({ toggle }) => (
                <YieldReportMarkAllModal
                  toggle={toggle}
                  availableRejectionReasons={rejectionReasons}
                />
              )}
            </Modal>
            <Button
              className="d-flex flex-1 d-md-inline-block justify-content-center mt-2 mt-md-0"
              color="success"
              type="submit"
            >
              Save
            </Button>
          </div>
        </div>
      </div>

      <div className="container mb-3">
        <FieldArray name="line_items">
          {() =>
            values.part_failure_updates.map((partFailureUpdate, index) => (
              <YieldReportLineItem
                key={partFailureUpdate.line_item.id}
                index={index}
                availableRejectionReasons={rejectionReasons}
              />
            ))
          }
        </FieldArray>
        <div className="d-flex d-md-block text-right">
          <Button
            className="d-flex flex-1 d-md-inline-block justify-content-center"
            color="success"
            type="submit"
          >
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
};

YieldReportForm.propTypes = {
  workOrder: PropTypes.object.isRequired,
  resetForm: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

const formik = {
  validate: values => {
    let errors = {};

    values.part_failure_updates.forEach((part_failure_update, partFailureUpdateIndex) => {
      const totalPartFailures = sumBy(part_failure_update.part_failures, 'quantity');
      const totalParts = part_failure_update.line_item.quantity;
      if (totalPartFailures > totalParts) {
        set(
          errors,
          `part_failure_updates.${partFailureUpdateIndex}.total_part_failures`,
          'There are more failures then there are parts'
        );
      }
    });
    return errors;
  },
  handleSubmit: (values, bag) => {
    const { props } = bag;
    props.handleSubmit && props.handleSubmit(values, bag);
  },
  // default values of form
  mapPropsToValues: props => {
    return {
      work_order_id: props.workOrder.id,
      part_failure_updates: props.workOrder.line_items.reduce((acc, line_item) => {
        acc.push({
          line_item,
          part_failures: line_item.part_failures,
          work_order_line_item_id: line_item.id,
        });
        return acc;
      }, []),
    };
  },
};

export default withFormik(formik)(YieldReportForm);
