import { every, flatMap, some } from 'lodash';

export const findSelectedItems = items => items.filter(item => item.selected);
export const anyItemsSelected = items => items.some(item => item.selected);
export const everyItemSelected = items => items.every(item => item.selected);
export const hasPartialSelection = items => anyItemsSelected(items) && !everyItemSelected(items);
export const countItemsSelected = items =>
  items.reduce((count, item) => (item.selected ? count + 1 : count), 0);

export const noPartQuantitiesSelected = (lineItem, partQuantity = {}) => {
  const partQuantities = flatMap(lineItem.supplier_costs, sc => sc.part_quantities);
  return every(
    partQuantities.map(pq => (pq.id === partQuantity.id ? !partQuantity.selected : !pq.selected))
  );
};

export const noPartQuantitiesSelectedForSupplierCost = (supplierCost, partQuantity = {}) => {
  return every(
    supplierCost.part_quantities.map(pq =>
      pq.id === partQuantity.id ? !partQuantity.selected : !pq.selected
    )
  );
};

export const somePartQuantitiesSelected = lineItem => {
  const selected = flatMap(lineItem.supplier_costs, sc =>
    sc.part_quantities.map(pq => !!pq.selected)
  );

  return some(selected) && !every(selected);
};

export const getAllSelectedPartQuantities = lineItems =>
  flatMap(lineItems, selectedPartQuantitiesForLineItem);

export const selectedPartQuantitiesForLineItem = lineItem => {
  return lineItem.supplier_costs.reduce((acc, supplierCost) => {
    return [...acc, ...supplierCost.part_quantities.filter(pq => pq.selected)];
  }, []);
};
