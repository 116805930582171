import PropTypes from 'prop-types';
import React from 'react';

import { classNames } from 'fr-shared/components';

import styles from './Page.module.css';

const Page = ({ children, className }) => {
  return <div className={classNames([styles.Root, className, 'container-sm'])}>{children}</div>;
};

Page.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Page;
