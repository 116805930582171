import { useQuery } from 'react-query';

import { api } from 'fr-shared/api';

const fetchSurfaceRoughnesses = (): Promise<SurfaceRoughness[]> => {
  return api.get('/customer_portal/surface_roughnesses').then(res => res.data);
};

const useSurfaceRoughnesses = () => {
  return useQuery<SurfaceRoughness[], Error>(
    ['surface_roughnesses'],
    () => fetchSurfaceRoughnesses(),
    {
      refetchOnWindowFocus: false,
      staleTime: 2000,
    }
  );
};

export default useSurfaceRoughnesses;
