import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { filterByCostingState, isSubmitted } from 'fr-shared/lib/costing_requests';

import { sortLineItemsByOption } from '../../utils/sort';

/**
 * Handles front-end filtering of costing request line items and
 * returns the list of visible line items to display
 */
const useLineItemFilters = () => {
  const formik = useFormikContext();
  const [lineItemFilters, setLineItemFilters] = useState({
    manufacturing_process: 'All',
    state: 'Requested',
  });
  const [lineItemSortOption, setLineItemSortOption] = useState('');

  const isCRSubmitted = isSubmitted(formik.values);

  // filter line items by the selected state (from tabs) for display purposes
  const visibleLineItems = isCRSubmitted
    ? filterByCostingState(formik.values.line_items, lineItemFilters.state)
    : formik.values.line_items;

  /**
   * When the filters change, reflect the filtering in the visible line items
   * @param {object} lineItemFilters - currently supports mfg process and state
   */
  useEffect(() => {
    const filteredLineItems = formik.values.line_items.map(lineItem => {
      const lineItemProcess = lineItem.build_package?.processes.find(
        p => p.manufacturing_process != null
      );
      const hasProcess =
        lineItemFilters.manufacturing_process === 'All'
          ? true
          : lineItemProcess?.manufacturing_process?.name ===
            lineItemFilters.manufacturing_process;

      return {
        ...lineItem,
        hidden: !hasProcess,
        selected: false,
      };
    });

    formik.setFieldValue('line_items', filteredLineItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItemFilters, lineItemSortOption]);

  const sortedAndFilteredLineItems = sortLineItemsByOption(lineItemSortOption, visibleLineItems);

  return {
    lineItems: sortedAndFilteredLineItems,
    lineItemFilters: lineItemFilters,
    setLineItemFilters: setLineItemFilters,
    lineItemSortOption: lineItemSortOption,
    setLineItemSortOption: setLineItemSortOption,
  };
};

export default useLineItemFilters;
