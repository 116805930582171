import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button, FormSelect, Icon, Modal } from 'fr-shared/components';
import { INCOTERMS } from 'fr-shared/lib/incoterms';

export const IncotermModal = () => {
  const [isOpen, toggleIsOpen] = useState(false);

  return (
    <Modal
      action={
        <Icon name="question-circle" data-testid="incoterm-modal" className="text-primary-600" />
      }
      className="modal-scrollable"
      isOpen={isOpen}
      toggle={() => toggleIsOpen(true)}
    >
      <Modal.Header title="Incoterm Descriptions" onClose={() => toggleIsOpen(false)} />
      <div className="modal-body">
        {INCOTERMS.map(term => (
          <div key={term.name} className="pb-3 pl-2 pr-2">
            <strong>{term.name}</strong>
            <p>{term.description}</p>
          </div>
        ))}
      </div>
      <div className="modal-footer">
        <Button color="primary" className="mr-2" onClick={() => toggleIsOpen(false)}>
          Done
        </Button>
      </div>
    </Modal>
  );
};

const FormIncoterms = ({ label = '', name = '', ...props }) => (
  <FormSelect
    label={label}
    name={name}
    component="select"
    optionList={INCOTERMS}
    append={<IncotermModal />}
    {...props}
  />
);

FormIncoterms.defaultProps = {
  name: 'incoterm',
  label: 'Incoterm',
};

FormIncoterms.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default FormIncoterms;
