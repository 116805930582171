import moment from 'moment';
import React, { useCallback, useContext } from 'react';

import { DocumentItem } from 'fr-shared/components';
import { DocumentsContext } from 'fr-shared/context';
import { useForm } from 'fr-shared/hooks';

const DocumentsList = () => {
  const formik = useForm();
  const { activeDocs, documents, fieldPath, readonly, showDate } = useContext(DocumentsContext);

  const handleDeleteDocument = useCallback(
    index => {
      const document = documents[index];

      // If there's no id, this document hasn't been saved to the backend. If the backend
      // doesn't know about it, we can just drop it. Otherwise we want to tell the backend
      // this document should be soft-deleted.
      if (document.id === null || document.id === undefined) {
        const newDocuments = [...documents];
        newDocuments.splice(index, 1);
        formik.setFieldValue(fieldPath, newDocuments);
      } else {
        formik.setFieldValue(`${fieldPath}.${index}.deleted_at`, moment());
      }
    },
    [documents, fieldPath, formik]
  );

  if (activeDocs.length === 0) {
    return null;
  }

  /*
   * This iterates over all documents, not just activeDocs, because document deletion is
   * index-based and so the index would have to be translated between all docs and active docs.
   * Instead, skip rendering the deleted items.
   */
  return (
    <ul className="list-none m-0 p-0">
      {documents.map(
        (document, index) =>
          !document.deleted_at && (
            <li key={`document-${index}`} className="mb-1">
              <DocumentItem
                document={document}
                index={index}
                onDelete={() => handleDeleteDocument(index)}
                readonly={readonly}
                showDate={showDate}
              />
            </li>
          )
      )}
    </ul>
  );
};

export default DocumentsList;
