import { isNil } from 'lodash';
import React from 'react';

import { Link } from 'fr-shared/components';
import {
  QUOTE_STATES,
  QUOTE_STATE_DATE_LABELS,
  getQuoteStateChangeDate,
} from 'fr-shared/lib/quotes';
import { getOpportunityUrl } from 'fr-shared/lib/salesforce';

interface DisplayFieldProps {
  label?: React.ReactNode;
  value: React.ReactNode;
  valueClassName?: string;
}

const DisplayField = ({ label, value, valueClassName = '' }: DisplayFieldProps) => (
  <div className="mr-4 mb-2">
    {label && (
      <>
        <span className="text-muted mr-1">{label}</span>{' '}
      </>
    )}
    <strong className={valueClassName}>{isNil(value) ? '----' : value}</strong>
  </div>
);

interface QuoteFormHeaderProps {
  quote: {
    box_file_location: string;
    deal?: {
      hashid: string;
    } | null;
    opportunity_id?: string | null;
    order_id?: number;
    public_id: string;
    state: keyof typeof QUOTE_STATES;
    [thing: string]: any;
  };
}

const QuoteFormHeader = ({ quote }: QuoteFormHeaderProps) => {
  const { deal, opportunity_id, public_id, state } = quote;

  return (
    <div className="flex flex-column mt-3">
      <div className="flex flex-row">
        {public_id && <DisplayField label="Public ID" value={public_id} />}
        {opportunity_id && (
          <DisplayField
            label="Opportunity ID"
            value={
              <Link to={{ pathname: getOpportunityUrl(opportunity_id) }} openNewWindow>
                {opportunity_id}
              </Link>
            }
          />
        )}
        {deal && <DisplayField label="Deal #" value={deal.hashid} />}
      </div>
      <div className="flex flex-row">
        <DisplayField
          label={QUOTE_STATE_DATE_LABELS[state]}
          value={getQuoteStateChangeDate(quote)}
        />
        {quote.order_id && (
          <div className="mr-4 mb-2">
            <span className="text-muted mr-1">{'Order # '}</span>
            <Link to={`/admin/orders/${quote.order_id}`} className={''}>
              <strong>{quote.public_id ?? quote.deal?.hashid ?? quote.order_id}</strong>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuoteFormHeader;
