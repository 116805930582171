import React from 'react';

import { IconFont, Modal, classNames } from 'fr-shared/components';

import styles from './PODocumentsModal.module.css';

interface Document {
  file_name: string;
  url: string;
}

interface PODocumentsModalProps {
  action: JSX.Element;
  documents: Document[];
}

export const PODocumentsModal = ({ action, documents }: PODocumentsModalProps) => {
  const handleOpen = (url: any) => window.open(url);

  return (
    <Modal
      action={action}
      backdrop={true}
      className={classNames(['bg-coolGray-800 max-w-lg rounded-md'])}
    >
      {({ toggle }: { toggle: any }) => (
        <>
          <Modal.Header className="pb-3" onClose={toggle}>
            <div className="mt-5 flex border-solid border-coolGray-700 border-b border-t-0 border-x-0 pb-0.5">
              <h3>PO documents</h3>
            </div>
          </Modal.Header>

          <div className="modal-body pb-4">
            {documents.map(doc => {
              return (
                <div
                  className={classNames([
                    styles.Document,
                    'bg-coolGray-700 cursor-pointer flex flex-row justify-between items-center mt-1 rounded-sm',
                  ])}
                  key={doc.url}
                  onClick={() => handleOpen(doc.url)}
                  onKeyPress={() => handleOpen(doc.url)}
                  role="button"
                  tabIndex={0}
                >
                  <div className="flex flex-row">
                    <IconFont
                      className={classNames([styles.PdfIcon, 'text-lg', 'text-coolGray-100'])}
                      name="document"
                    />

                    <div className="ml-1 text-coolGray-100">{doc.file_name}</div>
                  </div>

                  <IconFont
                    className={classNames([styles.ArrowIcon, 'text-coolGray-100'])}
                    name="arrow-down"
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </Modal>
  );
};

export default PODocumentsModal;
