import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Icon, Modal } from 'fr-shared/components';
import { checkPassFail, getFailedCheckToDisplay } from 'fr-shared/lib/manufacturability_checks';

const ManufacturabilityDefinitionsModal = ({
  manufacturingProcess,
  revision,
  onViewDfmDefinitions,
}) => {
  const { manufacturabilityChecks, passedCheckNames, failedCheckNames } = checkPassFail(
    revision?.manufacturability_checks,
    manufacturingProcess
  );
  const passedCheckGroups = pick(manufacturabilityChecks, passedCheckNames);
  const failedCheckGroups = pick(manufacturabilityChecks, failedCheckNames);
  const handleViewAllDefs = () => {
    if (onViewDfmDefinitions) {
      onViewDfmDefinitions();
    }
  };

  return (
    <div className="manufacturability-definitions-modal">
      <Modal
        action={
          <Button color="link" className="font-size-sm">
            <Icon name="info-circle" className="mr-1" />
            View Definitions
          </Button>
        }
        onActionHandler={handleViewAllDefs}
        className="modal-scrollable modal-wide-lg"
      >
        {({ toggle }) => (
          <>
            <Modal.Header title="Check Definitions" onClose={toggle} />
            <div className="modal-body px-5">
              {Object.keys(failedCheckGroups).map(checkGroupName => {
                const group = failedCheckGroups[checkGroupName];
                const check = getFailedCheckToDisplay(group);

                if (!check) return null;

                return (
                  <CheckDefinition
                    description={check.description}
                    isError={check.type === 'error'}
                    isWarning={check.type !== 'error'}
                    key={checkGroupName}
                    manufacturingProcess={manufacturingProcess}
                    name={checkGroupName}
                  />
                );
              })}
              {Object.keys(passedCheckGroups).map(checkGroupName => {
                const group = passedCheckGroups[checkGroupName];
                const check = group[0];

                return (
                  <CheckDefinition
                    description={check.description}
                    key={checkGroupName}
                    manufacturingProcess={manufacturingProcess}
                    name={checkGroupName}
                  />
                );
              })}
            </div>
            <Modal.Footer>
              <Button onClick={toggle}>Close</Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </div>
  );
};

ManufacturabilityDefinitionsModal.propTypes = {
  manufacturingProcess: PropTypes.object.isRequired,
  revision: PropTypes.object.isRequired,
  onViewDfmDefinitions: PropTypes.func,
};

const CheckDefinition = ({ description, isError, isWarning, name }) => {
  if (!description) return null;

  const iconColor = isError ? 'text-danger' : isWarning ? 'text-warning' : 'text-success';
  const iconName = isError ? 'times-circle' : isWarning ? 'exclamation-circle' : 'check-circle';

  return (
    <div className="flex my-3">
      <Icon name={iconName} lg className={`${iconColor} mr-2`} />
      <div>
        <div className="font-weight-bold mb-1">{name}</div>
        <p>{description}</p>
      </div>
    </div>
  );
};

CheckDefinition.propTypes = {
  description: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  isWarning: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default ManufacturabilityDefinitionsModal;
