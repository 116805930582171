import { useFormikContext } from 'formik';
import React, { useContext } from 'react';

import { api } from 'fr-shared/api';
import { UserContext, useUserAnalyticsContext } from 'fr-shared/context';
import { useToggle } from 'fr-shared/hooks';

import { Button, FormField, FormPhoneNumber } from 'portal/components';

const AccountInformation = () => {
  const formik = useFormikContext();
  const { user, refreshUser } = useContext(UserContext);
  const userAnalytics = useUserAnalyticsContext();
  const [isEditing, toggleIsEditing] = useToggle(false);
  const handleEditClick = () => {
    userAnalytics.track('Edit Account Info', { valid: true });
    toggleIsEditing();
  };
  const handleOnResetPwdClick = () =>
    userAnalytics.track('Reset Password - Account', { valid: true });
  const handleSave = () => {
    api
      .put(`/customer_portal/account/${user.id}`, { user: formik.values })
      .then(refreshUser)
      .catch(err => {
        formik.setValues({ ...user });
        if (err.response?.data) {
          const { errors, messages } = err.response.data;
          formik.setErrors({ server: messages, ...errors });
        } else {
          formik.setErrors({ server: ['An unexpected error occurred. Refresh and try again.'] });
        }
      })
      .finally(toggleIsEditing);
  };

  return (
    <>
      <div className="border-bottom mb-4 py-2">
        <h5 className="flex align-items-center">
          Account Information
          <Button className="ml-auto" outline size="sm" onClick={handleEditClick}>
            Edit
          </Button>
        </h5>
      </div>
      <div>
        <FormField
          label="First Name"
          name="first_name"
          className="mb-3"
          readonly={!isEditing}
          floatingLabel={true}
        />
        <FormField
          label="Last Name"
          name="last_name"
          className="mb-3"
          readonly={!isEditing}
          floatingLabel={true}
        />
        <FormPhoneNumber
          label="Phone number"
          name="phone_number"
          readonly={!isEditing}
          floatingLabel={true}
        />
        <FormField
          label="Email"
          className="mb-3"
          name="email"
          readonly={true}
          floatingLabel={true}
        />
        {isEditing && (
          <div className="flex justify-content-between text-right my-5">
            <Button
              outline
              className="px-4"
              to="/forgot"
              openNewWindow
              onClick={handleOnResetPwdClick}
            >
              Reset Password
            </Button>
            <Button className="px-5" onClick={handleSave}>
              Save
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default AccountInformation;
