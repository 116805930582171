import { connect } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { api } from 'fr-shared/api';
import { Button, Icon } from 'fr-shared/components';

const GenerateQuotePdfButton = ({ formik, preview = false }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerateQuotePdf = useCallback(() => {
    const generatePdfUrl = preview
      ? `/quotes/${formik.values.id}/preview_pdf`
      : `/quotes/${formik.values.id}/download_pdf`;

    setIsLoading(true);
    api
      .put(`/quotes/${formik.values.id}`, { quote: formik.values })
      .then(() => {
        return api.post(generatePdfUrl).then(res => {
          setIsLoading(false);
          if (res.data.length) window.open(res.data);
        });
      })
      .catch(err => {
        setIsLoading(false);
        if (err.response?.data) {
          const { errors, messages } = err.response.data;
          formik.setErrors({ server: messages, ...errors });
        } else {
          formik.setErrors({
            server: ['An unexpected error occurred. Refresh and try again.'],
          });
        }
      });
  }, [formik, preview]);

  return (
    <Button
      className="ml-2"
      color="primary"
      size="sm"
      dataTestId="quote-pdf-button"
      onClick={handleGenerateQuotePdf}
      loading={isLoading}
    >
      {preview ? (
        'Preview PDF'
      ) : (
        <>
          <Icon className="mr-1" name="download" /> Download PDF
        </>
      )}
    </Button>
  );
};

GenerateQuotePdfButton.propTypes = {
  match: PropTypes.object,
  formik: PropTypes.object,
  preview: PropTypes.bool,
};

export default connect(GenerateQuotePdfButton);
