import React, { Context, PropsWithChildren, createContext, useContext } from 'react';
import * as Yup from 'yup';

import { mixPanelUserAnalytics } from './MixPanelUserAnalytics';

/* Typing */

export interface UserAnalyticsLib {
  /**
   * Identify the current user with a unique ID
   * @param id
   */
  identify(id: string): void;

  /**
   * Aliases the current user to the provided {@link aliasId}
   * @param aliasId
   */
  alias(aliasId: string): void;

  /**
   * Track an event for the current user.
   * @param name event name describing what the user does
   * @param props optional set of properties to include with and to describe the event
   */
  track(name: string, props?: Record<string, any>): void;

  /**
   * Returns a promise for the distinct id assigned to the current user, which is resolved when the underlying lib is ready to provide this info.
   * Promise resolves to `null` if the library was unsuccessfully loaded.
   */
  getDistinctId(): Promise<string>;

  /**
   * Set metadata for the current users by distinctId.
   */
  setUserMetadata(metadata: Record<string, any>): void;
}

// Decoration
export interface FieldEventsOptions {
  e: any; // The event from the onblur function
  trackName: string; // Name of the track/log message
  logType: 'select' | 'string' | 'boolean'; // Used to declare what type of data is sent to our log provider.
  rawSchema?: any; // The Raw Yup Schema object that is being used bt
  customValidation?: (fieldValue: any) => boolean; // If no Raw Yup Schema object is passed in, you can use a custom function to validate whether to log an event as valid or false
}

interface UserAnalyticsDecoration {
  /**
   * Used for logging events on field components, typically on blur events.
   * See {@link FieldEventsOptions} for usage.
   */
  trackFieldEvents(options: FieldEventsOptions): void;
}

export type UserAnalyticsService = UserAnalyticsLib & UserAnalyticsDecoration;

const trackFieldEventsHelper = (
  lib: UserAnalyticsLib,
  { e, trackName, logType = 'boolean', rawSchema, customValidation }: FieldEventsOptions
) => {
  const fieldValue = e.target.value;
  const fieldName = e.target.name;
  const logEvt = (logType: string, valid: boolean) => {
    if (valid) {
      let dataObj;
      switch (logType) {
        case 'select':
          dataObj = { inputValue: fieldValue ? fieldValue : 'No selection' };
          break;
        case 'string':
          dataObj = { inputValue: fieldValue };
          break;
        default:
          dataObj = { valid };
      }
      lib.track(trackName, dataObj);
    } else {
      lib.track(trackName, { valid });
    }
  };

  if (rawSchema) {
    const schemaObj = { [fieldName]: rawSchema[fieldName] };
    const schema = Yup.object().shape(schemaObj);
    const validObj = { [fieldName]: fieldValue };

    schema.isValid(validObj).then((valid: any) => logEvt(logType, valid!!));
  } else if (customValidation) {
    logEvt(logType, customValidation(fieldValue));
  }
};

/**
 * Takes the provided {@link UserAnalyticsLib} and decorates it
 * @returns
 */
export const useUserAnalyticsContext = (): UserAnalyticsService => {
  const userAnalyticsLib: UserAnalyticsLib = useContext(UserAnalyticsContext);
  const newDecorationTarget: UserAnalyticsDecoration = {
    trackFieldEvents: (options: FieldEventsOptions) =>
      trackFieldEventsHelper(userAnalyticsLib, options),
  };

  // make sure we are assigning TO the decoration target and not to the context's lib
  return Object.assign(newDecorationTarget, userAnalyticsLib);
};

/* Utility helpers */

if (window.hj) {
  mixPanelUserAnalytics.getDistinctId().then(distinctId => {
    window.hj('identify', distinctId, { userAnalyticsId: distinctId });
  });
}

export const UserAnalyticsContext: Context<UserAnalyticsLib> =
  createContext(mixPanelUserAnalytics);

export const UserAnalyticsProvider: React.FC<{}> = ({ children }: PropsWithChildren<{}>) => {
  return (
    <UserAnalyticsContext.Provider value={mixPanelUserAnalytics}>
      {children}
    </UserAnalyticsContext.Provider>
  );
};
