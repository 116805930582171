import { useFormikContext } from 'formik';
import { isNil, kebabCase, startCase } from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import { QueryResult } from 'react-query';

import { api } from 'fr-shared/api';
import { FormContactPicker } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { useUsersByRole } from 'fr-shared/hooks';
import { ROLES } from 'fr-shared/lib/roles';

const fieldNameToUserRole = {
  engineering_contact: ROLES.ApplicationEngineering,
  sales_contact: ROLES.Sales,
  support_contact: ROLES.OperationsProjectManagement,
};

interface OrderContactPickerProps {
  fieldName: 'engineering_contact' | 'sales_contact' | 'support_contact';
}

const OrderContactPicker = ({ fieldName }: OrderContactPickerProps) => {
  const formik = useFormikContext<{
    id?: number | null;
    engineering_contact: User | {} | null;
    sales_contact: User | {} | null;
    support_contact: User | {} | null;
  }>();

  const { data: contacts } = useUsersByRole(fieldNameToUserRole[fieldName]) as QueryResult<
    User[],
    unknown
  >;
  const [isUpdating, setIsUpdating] = useState(false);
  const { user } = useContext(UserContext) as {
    user: {
      id: number;
      canCreateOrders: boolean;
    };
  };
  const orderId = formik.values.id;

  const handleChange = useCallback(
    selectedUser => {
      const fieldNameId = `${fieldName}_id`;
      formik.setFieldValue(fieldNameId, selectedUser.id);
      formik.setFieldValue(fieldName, isNil(selectedUser.id) ? {} : selectedUser);

      if (!isNil(orderId)) {
        setIsUpdating(true);
        api
          .put(`/orders/${orderId}`, { order: { [fieldNameId]: selectedUser.id } })
          .finally(() => {
            setIsUpdating(false);
          });
      }
    },
    [fieldName, formik, orderId]
  );

  return (
    <FormContactPicker
      name={`order-${kebabCase(fieldName)}`}
      label={startCase(fieldName)}
      includeUser={user.canCreateOrders}
      unassignedButtonText={`Assign ${startCase(fieldName)}`}
      options={contacts ?? []}
      onChange={handleChange}
      isDisabled={isUpdating}
      value={formik.values[fieldName]}
    />
  );
};

export default OrderContactPicker;
