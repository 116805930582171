import { Form, useFormikContext, withFormik } from 'formik';
import { get, isEmpty, without } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  Button,
  FirstOrderBadge,
  FormCurrency,
  FormDate,
  FormField,
  FormIncoterms,
  FormRadioButtons,
  Link,
} from 'fr-shared/components';

import { shipmentSchema } from '../utils';
import OrderDetails from './OrderDetails';
import OrderLineItemSelect from './OrderLineItemSelect';
import OrderSearch from './OrderSearch';
import PackingSlipInformation from './PackingSlipInformation';
import ShippedOrderLineItems from './ShippedOrderLineItems';
import SubmitShipmentButton from './SubmitShipmentButton';
import usePackingSlip from './usePackingSlip';

// Keep in sync with lib/fast_radius/shipments/shipment.ex
const SHIP_TO_OPTIONS = ['Customer', 'Chicago', 'Supplier'].map(opt => ({
  label: opt,
  value: opt,
}));

const ShipmentForm = ({ initialValues, isLoading, handleSubmit }) => {
  const { values, setFieldValue, setFieldError } = useFormikContext();
  const { handleGetPackingSlip, packingSlip, loadingPackingSlip } = usePackingSlip(values);
  const [order, setOrder] = useState(initialValues.order);
  const [selectedLineItemIds, setSelectedLineItemIds] = useState(
    values.line_items.map(li => li.order_line_item_id)
  );
  const selectedPSLICount = values.line_items.reduce((previous, current) => {
    return (
      (current?.planned_shipment_line_item_fulfillments?.filter(pslif => pslif.selected).length ||
        0) + previous
    );
  }, 0);

  const isValid = shipmentSchema.isValidSync(values);
  const isEditingShipment = !!values.id;

  const handleSelectOrder = order => {
    setOrder(order);
  };

  const handleClearOrder = () => {
    setSelectedLineItemIds([]);
    setOrder(null);
    setFieldValue('line_items', []);
  };

  const handlePSLISelectOrderLineItem = orderLineItemIds => {
    // get order line items from list of ids and add an order_line_item_id prop
    // because that is needed to become a shipment line item.
    const selectedOrderLineItems = Array.from(order.line_items)
      .filter(li => orderLineItemIds.includes(li.id))
      .map(selectedOrderLineItem => {
        return {
          ...selectedOrderLineItem,
          order_line_item_id: selectedOrderLineItem.id,
        };
      });

    const originalSLIs = get(values, 'original_shipment_line_items', []);
    let newShipmentLineItems = [];
    if (!isEmpty(originalSLIs)) {
      // existing shipment
      const selectedExistingShipmentLineItems = originalSLIs.filter(sli =>
        orderLineItemIds.includes(sli.order_line_item_id)
      );
      const selectedExistingShippingLineItemOLIIds = selectedExistingShipmentLineItems.map(
        existingShipmentLineItem => existingShipmentLineItem.order_line_item_id
      );

      newShipmentLineItems = orderLineItemIds.map(selectedOrderLineItemId => {
        if (selectedExistingShippingLineItemOLIIds.includes(selectedOrderLineItemId)) {
          return selectedExistingShipmentLineItems.find(
            existingShipmentLineItem =>
              existingShipmentLineItem.order_line_item_id === selectedOrderLineItemId
          );
        }

        return selectedOrderLineItems.find(
          orderLineItem => orderLineItem.id === selectedOrderLineItemId
        );
      });
    } else {
      // this is a weird "shouldn't ever happen" state. If a shipment gets
      // persisted but is rendered without shipment line items, maybe a delete
      // outside of the UI, just default to what order line items are on the order.
      newShipmentLineItems = selectedOrderLineItems;
    }

    setSelectedLineItemIds(selectedLineItemIds.concat(orderLineItemIds));
    // new shipment line items here are a combo of new OLIs to become SLI and
    // existing SLI being re-added
    setFieldValue('line_items', [...values.line_items, ...newShipmentLineItems]);
  };

  const handlePSLIRemoveLineItems = orderLineItemIds => {
    // need to loop over this to reset any line item errors
    orderLineItemIds.forEach(removedOrderLineItemId => {
      const idx = values.line_items.findIndex(
        li => li.order_line_item_id === removedOrderLineItemId
      );
      setFieldError(`line_items[${idx}]`, {});
    });

    const newLineItems = values.line_items.filter(
      li => !orderLineItemIds.includes(li.order_line_item_id)
    );
    setSelectedLineItemIds(without(selectedLineItemIds, ...orderLineItemIds));
    setFieldValue('line_items', newLineItems);
  };

  return (
    <Form>
      <div className="page-header flex">
        <div className="container">
          <h2>{isEditingShipment ? `Edit Shipment #${values.id}` : 'New Shipment'}</h2>
          <div className="ml-auto flex">
            <Button to="/admin/shipments">Back</Button>

            <Button
              color="primary"
              className="mx-2"
              loading={loadingPackingSlip}
              onClick={handleGetPackingSlip}
              disabled={!isValid}
              tooltip={
                !isValid
                  ? 'Add shipment line items and complete all required form fields to generate packing slip'
                  : null
              }
            >
              Generate Packing Slip
            </Button>

            <SubmitShipmentButton
              onUpdate={handleSubmit}
              isEditing={isEditingShipment}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
      {packingSlip && <PackingSlipInformation packingSlip={packingSlip} />}
      <div className="container">
        <div className="card">
          <div className="card-header">Choose an FR Order to add items to this Shipment</div>
          <div className="card-body border-bottom">
            {order ? (
              <div className="flex">
                <div className="flex-1 flex border rounded align-items-center px-2 mr-3">
                  <div className="mr-4">
                    <Link openNewWindow to={{ pathname: `/admin/orders/${order.id}` }}>
                      {order.public_id}
                    </Link>
                  </div>
                  <div className="mr-4">{order.customer?.name}</div>
                  <div className="mr-4">{order.customer_contact?.name}</div>
                  {order.is_first_order && <FirstOrderBadge fontSize="sm" />}
                </div>
                <Button color="link" onClick={handleClearOrder}>
                  Clear Order
                </Button>
              </div>
            ) : (
              <div data-testid="order-search-container">
                <OrderSearch onSelect={handleSelectOrder} />
              </div>
            )}
          </div>
          {order && <OrderDetails order={order} />}
          <div className="card">
            <div className="card-header">Shipment Details</div>
            {order && (
              <OrderLineItemSelect
                line_items={order.line_items}
                selectedLineItemIds={selectedLineItemIds}
                onSelect={handlePSLISelectOrderLineItem}
                onRemove={handlePSLIRemoveLineItems}
                isEditing={isEditingShipment}
              />
            )}
            {order && !isEditingShipment && (
              <div className="card-body">
                <div className="flex flex-row align-items-md-center px-3">
                  <ShippedOrderLineItems line_items={order.line_items} />
                </div>
              </div>
            )}
            <div className="card-body">
              <strong>Add Shipments Details</strong>
              <div className="row  pt-1">
                <div className="col-md">
                  <FormField label="Tracking No." name="tracking_number" required={true} />
                  <FormCurrency label="Shipping Charge" name="shipping_charge" />
                  <FormIncoterms name="order.incoterms" disabled={true} />
                </div>
                <div className="col-md">
                  <FormDate label="Ship Date" name="shipped_date" />
                  <FormRadioButtons
                    isRequired={true}
                    name="ship_to"
                    label="Ship To"
                    options={SHIP_TO_OPTIONS}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex mb-4">
          <div className="ml-auto flex">
            {order && (
              <div className="flex row">
                <div className="p-1 badge badge-primary align-self-center mr-1">
                  <strong>{selectedPSLICount || 0}</strong>
                </div>
                <div className="align-self-center mr-1">Planned Shipments Selected</div>
              </div>
            )}
            <Button
              color="primary"
              className="mx-2"
              loading={loadingPackingSlip}
              onClick={handleGetPackingSlip}
              disabled={!isValid}
              tooltip={
                !isValid
                  ? 'Add shipment line items and complete all required form fields to generate packing slip'
                  : null
              }
            >
              Generate Packing Slip
            </Button>

            <SubmitShipmentButton
              onUpdate={handleSubmit}
              isEditing={isEditingShipment}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

ShipmentForm.defaultProps = {
  initialValues: {},
};

ShipmentForm.propTypes = {
  initialValues: PropTypes.shape({
    order: PropTypes.shape({
      is_first_order: PropTypes.bool,
      public_id: PropTypes.string,
      customer: PropTypes.shape({
        name: PropTypes.string,
      }),
      customer_contact: PropTypes.shape({
        name: PropTypes.string,
      }),
      line_items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          description: PropTypes.string.isRequired,
          quantity: PropTypes.number.isRequired,
          amount_shipped: PropTypes.number,
          commit_date: PropTypes.string,
          supplier_promised_ship_date: PropTypes.string,
          planned_shipment_line_items: PropTypes.arrayOf(PropTypes.object),
          part: PropTypes.object,
        })
      ).isRequired,
    }),
  }),
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
};

export default withFormik({
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: shipmentSchema,
  handleSubmit: (values, formik) => {
    formik.props.handleSubmit(values, formik);
  },
  mapPropsToValues: ({ initialValues }) => ({
    line_items: [],
    ...initialValues,
  }),
})(ShipmentForm);
