import React from 'react';

import { Button, IconFont, Modal, classNames } from 'fr-shared/components';

import styles from './ModalStickyHeader.module.css';

interface ModalStickyHeaderProps {
  className?: string;
  // copy of the sticky header
  header?: string;
  // function on close button
  handleClose?: () => void;
  // function on primary cta
  onClickPrimaryCta?: () => void;
  // function on secondary cta
  onClickSecondaryCta?: () => void;
  // text on primary cta button
  primaryCtaText?: string;
  // text on secondary cta button
  secondaryCtaText?: string;
}

const ModalStickyHeader = ({
  className,
  handleClose,
  header,
  onClickPrimaryCta,
  onClickSecondaryCta,
  primaryCtaText,
  secondaryCtaText,
}: ModalStickyHeaderProps) => {
  return (
    <Modal.Header
      className={classNames([styles.Sticky, styles.FullScreenModalHeader, className])}
      onClose={() => {
        handleClose();
      }}
      showCloseButton={false}
      textUppercase={false}
    >
      <div className="flex justify-content-between align-items-center">
        <h3 className="mb-0 text-coolGray-100">{header || ''}</h3>
        <div className="flex flex-row align-items-center">
          {onClickSecondaryCta && (
            <Button
              className={classNames(['mr-3', styles.BtnOutlineSecondary])}
              onClick={onClickSecondaryCta}
              outline
              size="md"
            >
              {secondaryCtaText}
            </Button>
          )}
          <Button
            className={classNames(['mr-3', styles.BtnPrimary])}
            onClick={onClickPrimaryCta}
            size="md"
          >
            {primaryCtaText}
          </Button>

          <Button
            onClick={handleClose}
            className={classNames([
              'modal-close font-size-xl h-11 w-11',
              styles.ModalClose,
              styles.BtnPrimary,
            ])}
            size="md"
          >
            <IconFont name="close" />
          </Button>
        </div>
      </div>
    </Modal.Header>
  );
};

export default ModalStickyHeader;
