import { useQuery } from 'react-query';

import { api } from 'fr-shared/api';

const fetchCostingInputs = () => api.get('/costing_inputs').then(res => res.data);

const useCostingInputs = () => {
  return useQuery('costing_inputs', fetchCostingInputs, {
    refetchOnWindowFocus: false,
    staleTime: 2000,
  });
};

export default useCostingInputs;
