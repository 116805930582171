import { isNil } from 'lodash';
import React from 'react';

import { FormCurrency, Icon } from 'fr-shared/components';

import OrderFormDisplayField from './OrderFormDisplayField';

interface Props {
  order: {
    blended_margin_percentage?: number | null;
    estimated_duties_taxes?: {
      formatted: string;
    } | null;
    gross_profit?: {
      formatted: string;
    } | null;
    quoted_discount_amount?: {
      amount: string;
      formatted: string;
    } | null;
    quoted_shipping_price?: {
      formatted: string;
    } | null;
    sub_total_price?: {
      formatted: string;
    } | null;
    total_price?: {
      amount: string;
      formatted: string;
    } | null;
  };
  isEditing: boolean;
}

const OrderTotalDetails = ({
  order: {
    blended_margin_percentage,
    estimated_duties_taxes,
    gross_profit,
    quoted_discount_amount,
    quoted_shipping_price,
    sub_total_price,
    total_price,
  },
  isEditing,
}: Props) => {
  const blendedMarginDisplay = isNil(blended_margin_percentage)
    ? null
    : `${blended_margin_percentage}%`;
  const grossProfitDisplay = gross_profit?.formatted;

  let discountDisplay = null;
  if (typeof quoted_discount_amount?.amount === 'string') {
    const parsed = parseFloat(quoted_discount_amount.amount);

    if (parsed > 0) {
      discountDisplay = '-' + quoted_discount_amount.formatted;
    } else if (parsed < 0) {
      discountDisplay = quoted_discount_amount.formatted.substring(1);
    } else {
      discountDisplay = quoted_discount_amount.formatted;
    }
  }

  let totalPriceClassName = '';
  if (typeof total_price?.amount === 'string') {
    const parsed = parseFloat(total_price.amount);

    if (parsed > 0) {
      totalPriceClassName = 'text-green';
    } else if (parsed < 0) {
      totalPriceClassName = 'text-red';
    }
  }

  return (
    <div data-testid="order-totals" className="mb-4">
      <div className="card card-border h-100">
        <div className="card-header">
          <Icon name="file-invoice-dollar" right />
          Totals
        </div>
        <div className="flex flex-row mt-2 ml-2">
          <div className="col-md-3">
            <OrderFormDisplayField label="Subtotal" value={sub_total_price?.formatted} />
          </div>
          <div className="col-md-3">
            {isEditing ? (
              <div className="flex flex-row">
                <div className="mr-1">Quoted Shipping</div>
                <FormCurrency
                  label=""
                  name="quoted_shipping_price"
                  readonly={false}
                  isStacked={false}
                  inputClassName="p-1"
                  onBlur={undefined}
                  onFocus={undefined}
                  onChange={undefined}
                  placeholder={undefined}
                  dataTestId={undefined}
                />
              </div>
            ) : (
              <OrderFormDisplayField
                label="Quoted Shipping"
                value={quoted_shipping_price?.formatted}
              />
            )}
          </div>
          <div className="col-md-3">
            <OrderFormDisplayField label="Quoted Discount" value={discountDisplay} />
          </div>
          <div className="col-md-3">
            {isEditing ? (
              <div className="flex flex-row">
                <div className="mr-1">Estimated Duties and Taxes</div>
                <FormCurrency
                  label=""
                  name="estimated_duties_taxes"
                  readonly={false}
                  isStacked={false}
                  inputClassName="p-1"
                  onBlur={undefined}
                  onFocus={undefined}
                  onChange={undefined}
                  placeholder={undefined}
                  dataTestId={undefined}
                />
              </div>
            ) : (
              <OrderFormDisplayField
                label="Estimated Duties and Taxes"
                value={estimated_duties_taxes?.formatted}
              />
            )}
          </div>
        </div>
        <div className="flex flex-row mt-2 ml-2">
          <div className="col-md-3">
            <OrderFormDisplayField
              label="Total"
              value={total_price?.formatted}
              valueClassName={totalPriceClassName}
            />
          </div>
          <div className="col-md-3">
            <OrderFormDisplayField label="Blended Margin" value={blendedMarginDisplay} />
          </div>
          <div className="col-md-3">
            <OrderFormDisplayField label="Gross Profit" value={grossProfitDisplay} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTotalDetails;
