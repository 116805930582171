import PropTypes from 'prop-types';
import React from 'react';

import { PartImage } from 'fr-shared/components';

interface CustomerNameCellProps {
  id: number;
  name: string;
  screenshotSrc: string;
}

const CustomerNameCell = ({ name, screenshotSrc }: CustomerNameCellProps) => {
  return (
    <div className="flex align-items-center">
      <PartImage backdrop={false} size="xxs" src={screenshotSrc} />
      <span className="ml-1">{name}</span>
    </div>
  );
};

CustomerNameCell.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  screenshotSrc: PropTypes.string,
};

export default CustomerNameCell;
