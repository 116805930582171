import { useQuery } from 'react-query';

import { api } from 'fr-shared/api';

const fetchManufacturingProcesses = (key, { publiclyAvailable }) => {
  let apiPath = publiclyAvailable
    ? `/manufacturing_process?publicly_available=true`
    : '/manufacturing_process';

  return api.get(apiPath).then(res => res.data);
};

const useManufacturingProcess = ({ publiclyAvailable = false } = {}) => {
  return useQuery(
    ['manufacturingProcesses', { publiclyAvailable }],
    fetchManufacturingProcesses,
    { refetchOnWindowFocus: false, staleTime: 2000 }
  );
};

export default useManufacturingProcess;
