import React from 'react';

import { IconFont, classNames } from 'fr-shared/components';

import { AutoquoteErrors } from 'portal/lib/autoquote';
import {
  CART_MANUAL_REASONS,
  CartLineItem,
  hasCriticalIssues,
  hasInvalidFileType,
  hasMissingInfo,
  hasNonCriticalIssues,
  manufacturabilityChecksIncomplete,
} from 'portal/lib/cart';

/** Public API */
interface CartForNotifications {
  line_items: CartLineItem[];
  is_manual_reasons: string[];
}

interface Props {
  cart: CartForNotifications;
}

/** Internal helpers */
interface NotificationReason {
  key: string;
  text: string;
  subText: string;
  isError: boolean;
}

const DFM_REASONS = {
  NonCriticalIssues: 'NonCriticalIssues',
  CriticalIssues: 'CriticalIssues',
};

const BASE_INVALID_FILE_TYPE_REASON = {
  key: AutoquoteErrors.InvalidFileType,
  isError: false,
};

function itemHasHave(count: number, directObjectNoun: string): string {
  const possesive = count > 1 || count === 0 ? 'have' : 'has';
  return `${count} of your items ${possesive} ${directObjectNoun}`;
}

function collectManualReasons(manualReasons: string[]): NotificationReason[] {
  // Collect the manual reasons
  const reasons: NotificationReason[] = (manualReasons || [])
    .map(manualReason => {
      switch (manualReason) {
        case CART_MANUAL_REASONS.MultipleSupplierRegions:
          return {
            key: manualReason,
            text: 'We need to review your items before we can quote',
            subText:
              'Some items will be made in the USA and some internationally. Please request a manual quote and we’ll contact you within 1 business day.',
            isError: false,
          };

        case CART_MANUAL_REASONS.ExceedsCartWeight:
          return {
            key: manualReason,
            text: 'We need to review your items before we can quote',
            subText:
              'Your items weigh more than 150lbs in total. Reduce this weight for an instant quote. Or request a manual quote and we’ll contact you within 1 business day.',
            isError: false,
          };

        default:
          return null;
      }
    })
    .filter(reason => !!reason);

  return reasons;
}

function collectLineItemReasons(cartLineItems: CartLineItem[]): NotificationReason[] {
  const reasons: NotificationReason[] = [];
  const reasonFrequencies: Record<string, number> = (cartLineItems || []).reduce(
    (acc, cli) => {
      // collect DFM check related counts
      if (manufacturabilityChecksIncomplete(cli)) {
        acc[AutoquoteErrors.ManufacturabilityChecksDidNotRun] += 1;
      }
      if (hasNonCriticalIssues(cli)) {
        acc[DFM_REASONS.NonCriticalIssues] += 1;
      }
      if (hasCriticalIssues(cli)) {
        acc[DFM_REASONS.CriticalIssues] += 1;
      }
      if (hasMissingInfo(cli.autoquotable_errors)) {
        acc[AutoquoteErrors.Incomplete] += 1;
      }
      if (hasInvalidFileType(cli.autoquotable_errors)) {
        acc[AutoquoteErrors.InvalidFileType] += 1;
      }
      return acc;
    },
    {
      [AutoquoteErrors.ManufacturabilityChecksDidNotRun]: 0,
      [AutoquoteErrors.InvalidFileType]: 0,
      [AutoquoteErrors.Incomplete]: 0,
      [DFM_REASONS.NonCriticalIssues]: 0,
      [DFM_REASONS.CriticalIssues]: 0,
    } // Collect the number of line items failing under each of these buckets
  );

  const invalidFileTypeCount = reasonFrequencies[AutoquoteErrors.InvalidFileType];
  const incompleteDfmChecksCount =
    reasonFrequencies[AutoquoteErrors.ManufacturabilityChecksDidNotRun];
  const incompleteLineItemsCount = reasonFrequencies[AutoquoteErrors.Incomplete];
  const nonCriticalIssuesCount = reasonFrequencies[DFM_REASONS.NonCriticalIssues];
  const criticalIssuesCount = reasonFrequencies[DFM_REASONS.CriticalIssues];

  if (invalidFileTypeCount === 1) {
    reasons.push({
      ...BASE_INVALID_FILE_TYPE_REASON,
      text: '1 of your items has an unsupported file type',
      subText: 'Please add your part again in a supported format.',
    });
  } else if (invalidFileTypeCount > 1) {
    reasons.push({
      ...BASE_INVALID_FILE_TYPE_REASON,
      text: `${invalidFileTypeCount} of your items have unsupported file types`,
      subText: 'Please add your parts again in supported formats.',
    });
  }

  if (incompleteLineItemsCount > 0) {
    reasons.push({
      key: AutoquoteErrors.Incomplete,
      text: itemHasHave(incompleteLineItemsCount, 'missing info'),
      subText: 'Please complete all required fields.',
      isError: false,
    });
  }

  if (incompleteDfmChecksCount > 0) {
    reasons.push({
      key: AutoquoteErrors.ManufacturabilityChecksDidNotRun,
      text: itemHasHave(incompleteDfmChecksCount, 'incomplete DFM checks'),
      subText:
        'Please request a manual quote. We’ll take a closer look and contact you within 1 business day.',
      isError: false,
    });
  }

  if (criticalIssuesCount > 0) {
    reasons.push({
      key: DFM_REASONS.CriticalIssues,
      text: itemHasHave(criticalIssuesCount, 'critical issues'),
      subText: 'We recommend fixing these manufacturability issues before continuing.',
      isError: true,
    });
  }

  if (nonCriticalIssuesCount > 0) {
    reasons.push({
      key: DFM_REASONS.NonCriticalIssues,
      text: itemHasHave(nonCriticalIssuesCount, 'non-critical issues'),
      subText: 'We recommend reviewing these manufacturability issues before continuing.',
      isError: false,
    });
  }

  return reasons;
}

/** Components */
export const CartNotificationList = ({ cart }: Props) => {
  // Overriding reason above all other reasons - pricing
  const reasons = (cart.is_manual_reasons || []).includes(CART_MANUAL_REASONS.CartExceedsMaxPrice)
    ? [
        {
          key: CART_MANUAL_REASONS.CartExceedsMaxPrice,
          text: 'We need to review your items before we can quote',
          subText: 'Please request a manual quote and we’ll contact you within 1 business day.',
          isError: false,
        },
      ]
    : [
        ...collectLineItemReasons(cart.line_items),
        ...collectManualReasons(cart.is_manual_reasons),
      ];

  return (
    <ul className="w-100 px-0 mb-4">
      {reasons.map(reason => (
        <CartNotification
          key={reason.key}
          text={reason.text}
          subText={reason.subText}
          isError={reason.isError}
        />
      ))}
    </ul>
  );
};

const CartNotification = ({ text, subText, isError }: NotificationReason) => {
  const textColor = isError ? 'text-error-300' : 'text-warning-300';
  return (
    <li className="list-none bg-coolGray-800 p-2 rounded-[8px] flex flex-row mb-[4px]">
      <IconFont name="error-triangle" className={classNames(['text-2xl mr-2', textColor])} />
      <div>
        <p className={classNames(['mb-0', textColor])}>{text}</p>
        <p className="text-white">{subText}</p>
      </div>
    </li>
  );
};
