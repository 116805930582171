export const PART_SORT_VALUES = {
  ['Part name']: 'name',
  ['Date added']: 'inserted_at',
  ['Last edited']: 'updated_at',
};

export const PART_STATES = [
  'Uploaded',
  'Evaluating',
  'Feasibility Verified',
  'In Development',
  'Design Validation',
  'Production Validation',
  'Deprioritized',
];

export const UNITS_OF_MEASUREMENT = ['mm', 'in'];

export const UNAUTHENTICATED_PART_UPLOAD_LOCAL_STORAGE_KEY = 'unauthenticatedPartUploads';
