import { PORTAL_BULK_CONFIG } from 'fr-shared/feature_flags';
import { useFeatureFlags } from 'fr-shared/hooks';

const usePortalBulkConfigFeatureFlag = () => {
  const { [PORTAL_BULK_CONFIG]: isPortalBulkConfigOn } = useFeatureFlags({
    [PORTAL_BULK_CONFIG]: 'on',
  });

  return isPortalBulkConfigOn;
};

export default usePortalBulkConfigFeatureFlag;
