import PropTypes from 'prop-types';
import React from 'react';

import { Link, Loading, classNames } from 'fr-shared/components';

export const Button = props => {
  const {
    to,
    active,
    block,
    className,
    dataTestId,
    disabled,
    color = 'secondary',
    outline,
    size,
    loading,
    loadingIconColor,
    children,
    onClick,
    onKeyDown,
    role,
    tabIndex,
    tooltip,
    style,
    showLoadingIcon,
    openNewWindow,
    openNewWindowIcon,
    ...attributes
  } = props;

  let { tag: Tag } = props;

  const classes = classNames([
    'btn',
    `btn${outline ? '-outline' : ''}-${color}`,
    size ? `btn-${size}` : false,
    block ? 'btn-block' : false,
    { active, disabled: disabled },
    className,
  ]);

  if (attributes.href && Tag === 'button') {
    Tag = 'a';
  }

  const type = Tag === 'button' && attributes.onClick ? 'button' : null;
  const loadingColor = loadingIconColor || (outline ? 'black' : 'white');
  const loadingHeight = size === 'md' ? 16 : 24;

  const ButtonComponent = (
    <Tag
      type={type || 'button'}
      {...attributes}
      data-testid={dataTestId}
      className={classes}
      style={style}
      onClick={onClick}
      data-rh={tooltip}
      disabled={loading || disabled}
      onKeyDown={onKeyDown}
      role={role}
      tabIndex={tabIndex}
    >
      {loading && showLoadingIcon ? (
        <Loading height={loadingHeight} color={loadingColor} />
      ) : (
        children
      )}
    </Tag>
  );

  return to ? (
    <Link
      to={to}
      data-rh={tooltip}
      data-testid={dataTestId}
      loading={loading}
      style={style}
      className={classes}
      openNewWindow={openNewWindow}
      openNewWindowIcon={openNewWindowIcon}
      {...attributes}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role={role}
      tabIndex={tabIndex}
    >
      {children}
    </Link>
  ) : disabled && tooltip ? (
    <div className="btn-tooltip-wrapper" data-rh={tooltip} data-testid={`${dataTestId}-tooltip`}>
      {ButtonComponent}
    </div>
  ) : (
    ButtonComponent
  );
};

Button.propTypes = {
  /**  Buttons will appear pressed (with a darker background, darker border, and inset shadow) when active. */
  active: PropTypes.bool,
  /** Create block level buttons—those that span the full width of a parent—by adding block */
  block: PropTypes.bool,
  /** Styles the button to the desired color, examples above */
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'success',
    'info',
    'warning',
    'danger',
    'white',
    'light',
    'dark',
    'link',
    'none',
  ]),
  /** Make buttons look inactive by adding the disabled boolean attribute to any button element. */
  disabled: PropTypes.bool,
  /** String identifier to be used for testing. */
  dataTestId: PropTypes.string,
  /** Creates outline of button, removing color or background. */
  outline: PropTypes.bool,
  /** Url path used for Link component */
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Bool passed in to tell if button is loading. */
  loading: PropTypes.bool,
  /** Hex color string passed in for loading icon color. */
  loadingIconColor: PropTypes.string,
  /** String to pass in to be displayed in built in tool tip */
  tooltip: PropTypes.string,
  /** Pass in addition in-line styes as needed */
  style: PropTypes.object,
  /** Defines button type */
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf(['a', 'button'])]),
  /** Function that gets invoked during onClick event. */
  onClick: PropTypes.func,
  /** Function that gets invoked during keydown event. */
  onKeyDown: PropTypes.func,
  /** Defines size of button when passed in. */
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  /** Shows loading icon instead of children when loading */
  showLoadingIcon: PropTypes.bool,
  /** Allows child nodes to be passed in. */
  children: PropTypes.node,
  /** Pass in role of button for a11y purposes. */
  role: PropTypes.string,
  /** Pass in additional className to button. */
  className: PropTypes.string,
  /** Indicates whether the link should open in a new window */
  openNewWindow: PropTypes.bool,
  /** Indicates whether a new window link should show the icon */
  openNewWindowIcon: PropTypes.bool,
  /** Numbered tab index for a11y purposes */
  tabIndex: PropTypes.number,
  /** Valid subset of input elements' type attribute supported by this component */
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
};

Button.defaultProps = {
  tag: 'button',
};

export default Button;
