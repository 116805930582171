import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { CardWrapper } from 'portal/components';

import GridViewPart from './GridViewPart';

const GridView = ({ children, parts, pageName }) => {
  return (
    <CardWrapper>
      {children}
      {parts.map(part => (
        <GridViewPart key={part.id} part={part} pageName={pageName} />
      ))}
    </CardWrapper>
  );
};

GridView.propTypes = {
  children: PropTypes.node,
  pageName: PropTypes.string,
  parts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default memo(GridView);
