import React, { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { IconFont, classNames } from 'fr-shared/components';
import { AlertContext, useUserAnalyticsContext } from 'fr-shared/context';
import { usePortalSubscription } from 'fr-shared/hooks';

import {
  ContactUs,
  FormFieldBase,
  Input,
  Link,
  Page,
  PageHeader,
  PartImage,
  SubscriptionUpgradeCard,
} from 'portal/components';

import { InitialPartConfig } from '../viewer';
import CompareLineItem from './CompareLineItem';

const ComparePage = () => {
  const userAnalytics = useUserAnalyticsContext();
  const history = useHistory<InitialPartConfig>();
  const { part_id: partId }: { part_id: string } = useParams();
  const [lineItem, setLineItem] = useState(history.location.state || {});
  const [part, setPart] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const { setAlert } = useContext(AlertContext);

  const isPortalSubscribed = usePortalSubscription();

  /**
   * Load the line item from location state
   */
  useEffect(() => {
    setLineItem(history.location.state || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Load the part
   */
  const getPart = useCallback(() => {
    return api
      .get(`/customer_portal/parts/${partId}`)
      .then(res => {
        const { data: part } = res;
        setPart(part);
      })
      .catch(() => {
        setAlert({
          color: 'danger',
          message: 'An unexpected error occurred, please refresh and try again',
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Get the initial part data to start the comparison
   */
  useEffect(() => {
    getPart();
  }, [getPart]);

  /**
   * Set the line item data on the main form if you choose one of the comparisons
   * @param {object} compareLineItem
   */
  const handleSelectLineItem = (compareLineItem: any) => {
    history.push(`/studio/evaluate/${partId}/dfm`, {
      manufacturing_process_id: compareLineItem.manufacturing_process_id,
      material_id: compareLineItem.material_id,
      color_id: compareLineItem.color_id,
      quantity: compareLineItem.quantity,
      inspection_type_id: compareLineItem.inspection_type_id,
    });
  };

  if (!lineItem || !part) {
    return null;
  }

  const compareLineItemArray = isPortalSubscribed ? [0, 1, 2] : [0, 1];

  return (
    <Page>
      <div className="mb-3">
        <Link
          className="font-size-sm text-gray text-decoration-none"
          to={`/studio/evaluate/${part.id}`}
        >
          <IconFont name="chevron-left" right />
          Back to evaluation
        </Link>
      </div>

      <PageHeader
        title="Comparison Tool"
        subtitle="Compare additive manufacturing technologies"
      />

      <div className="flex py-3 mb-3">
        <PartImage className="mr-3" src={part.current_revision?.screenshot} />

        <div className="flex flex-column">
          <div className="mt-1 mb-2">
            File name:
            <strong className="ml-2">{part.name}</strong>
          </div>
          <FormFieldBase name="quantity" label="Quantity" isControl={true}>
            <Input
              className="mb-0"
              name="quantity"
              value={quantity}
              type="number"
              size="md"
              onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                setQuantity(+e.currentTarget.value)
              }
              onBlur={(e: SyntheticEvent<HTMLInputElement>) => {
                const inputValue = e.currentTarget.valueAsNumber;
                userAnalytics.track('Comparison Tool - Changed Quantity', { inputValue });
              }}
              min={1}
            />
          </FormFieldBase>
        </div>
        <div className="ml-auto flex align-items-end font-size-md text-right">
          <span className="mr-2">Need an expert?</span>

          <ContactUs
            onClick={() => userAnalytics.track('Comparison Tool - Clicked "Contact our team"')}
          >
            Contact our team
          </ContactUs>
        </div>
      </div>

      <div className="row">
        {compareLineItemArray.map(index => (
          <div
            key={`li-${index}`}
            className={classNames([
              !isPortalSubscribed && index === 1 && 'cursor-not-allowed',
              'col-md',
            ])}
          >
            <CompareLineItem
              disabled={!isPortalSubscribed && index === 1}
              lineItem={index === 0 ? lineItem : {}}
              part={part}
              index={index}
              onSelectLineItem={handleSelectLineItem}
              quantity={quantity}
            />
          </div>
        ))}
        {!isPortalSubscribed && (
          <div className="col-md relative">
            <div className="cursor-not-allowed">
              <CompareLineItem
                disabled={true}
                lineItem={{}}
                part={part}
                onSelectLineItem={handleSelectLineItem}
                quantity={quantity}
              />
            </div>
            <SubscriptionUpgradeCard
              analyticsContext="Compare Page"
              className="absolute top-24"
            />
          </div>
        )}
      </div>

      <div className="text-left text-gray font-size-md w-50 my-5 py-5">
        Displayed prices and lead times assume a standard finish and a visual inspection. Once
        selected and added onto a quote, custom options can be added. Quotes with custom finishes
        or more detailed inspection requirements are handled by our experts through a ‘Manual
        Quote’.
      </div>
    </Page>
  );
};

export default ComparePage;
