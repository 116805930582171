export const MARKETING_URLS = {
  LandingPage: 'https://www.fastradius.com/',
  Legal: 'https://www.fastradius.com/legal/',
  Privacy: 'https://www.fastradius.com/privacy/',
  Production: 'https://www.fastradius.com/parts-production/',
  Pro: 'https://www.fastradius.com/pro/',
  Resources: 'https://www.fastradius.com/resources/',
  TermsAndConditions: 'https://www.fastradius.com/terms-and-conditions/',
  TermsOfUse: 'https://www.fastradius.com/terms-of-use/',
};
