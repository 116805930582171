import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { AlertContext, useUserAnalyticsContext } from 'fr-shared/context';
import { getMarketoCookies } from 'fr-shared/lib/marketo';

import { LineItemCard, LineItemNotificationList, Money, PartImage } from 'portal/components';

const CartLineItemCard = ({ lineItem, refreshCart, readonly = false }) => {
  const { setAlert } = useContext(AlertContext);
  const history = useHistory();
  const userAnalytics = useUserAnalyticsContext();
  const logRemovePart = isValid =>
    userAnalytics.track(`Remove Item Confirm - Draft Quote`, { valid: isValid });

  const handleEdit = () => {
    if (readonly) return false;
    history.push(`/part-config/${lineItem.id}`);
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/customer_portal/cart_line_items/${lineItem.id}`, {
        data: { marketo_attrs: getMarketoCookies() },
      });
      logRemovePart(true);
      refreshCart();
    } catch (error) {
      logRemovePart(false);
      setAlert({
        color: 'danger',
        message: 'An unexpected error occurred, please refresh and try again',
      });
    }
  };

  const unitPrice = lineItem.is_manual ? null : lineItem?.unit_price?.amount;
  const totalPrice = lineItem.is_manual ? null : lineItem?.total_price?.amount;

  return (
    <LineItemCard
      title={lineItem?.part?.name}
      onClickTitle={handleEdit}
      onEdit={handleEdit}
      onDelete={handleDelete}
      readonly={readonly}
      footer={
        <LineItemNotificationList
          autoquotableErrors={lineItem?.autoquotable_errors}
          lineItem={lineItem}
          readonly={readonly}
          onPushStudio={() =>
            userAnalytics.track('Quote - Click DFM Checks', {
              state: 'Draft',
              red_checks:
                lineItem?.failed_manufacturability_check_counts?.failed_error_checks_count > 0,
              yellow_checks:
                lineItem?.failed_manufacturability_check_counts?.failed_warning_checks_count > 0,
            })
          }
          redirectState={{
            cart_line_item_id: lineItem?.id,
            manufacturing_process_id: lineItem?.manufacturing_process_id,
            material_id: lineItem?.material_id,
            color_id: lineItem?.material_id,
            quantity: lineItem?.quantity,
          }}
        />
      }
    >
      <LineItemCard.StartCell>
        <PartImage
          size="md"
          src={lineItem?.part?.current_revision?.screenshot}
          onClick={handleEdit}
        />
        <div className="text-gray font-size-sm mt-2">
          {lineItem.lead_time && (
            <>
              Lead time: <strong>{lineItem.lead_time} business days</strong>
            </>
          )}
        </div>
      </LineItemCard.StartCell>
      <LineItemCard.CenterCell lineItem={lineItem} />
      <LineItemCard.EndCell>
        <div className="flex font-size-sm justify-content-between">
          <p className="text-gray mb-1">Unit price</p>
          <Money amount={unitPrice} className="text-gray font-size-md" />
        </div>
        <div className="flex font-size-base justify-content-between">
          <p className="text-light mb-1">Item total</p>
          <Money
            amount={totalPrice}
            className="text-light font-weight-bolder font-size-lg"
            dataTestId="cart-line-item-card-item-total"
          />
        </div>
      </LineItemCard.EndCell>
    </LineItemCard>
  );
};

CartLineItemCard.propTypes = {
  lineItem: PropTypes.shape({
    id: PropTypes.number,
    autoquotable_errors: PropTypes.array,
    is_manual: PropTypes.bool,
    failed_manufacturability_check_counts: PropTypes.object,
    part_id: PropTypes.number,
    quantity: PropTypes.number,
    part: PropTypes.shape({
      id: PropTypes.number,
      current_revision: PropTypes.shape({
        screenshot: PropTypes.string,
        did_manufacturability_checks_run: PropTypes.bool,
      }),
      name: PropTypes.string,
    }),
    layer_thickness: PropTypes.object,
    color_id: PropTypes.number,
    material_id: PropTypes.number,
    lead_time: PropTypes.number,
    unit_price: PropTypes.object,
    total_price: PropTypes.object,
    manufacturing_process_id: PropTypes.number,
  }),
  refreshCart: PropTypes.func,
  readonly: PropTypes.bool,
};

export default CartLineItemCard;
