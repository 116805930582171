import { useCallback, useRef, useState } from 'react';

const useBoolTimer = () => {
  const id = useRef(null);
  const [running, setRunning] = useState(false);
  const [triggered, setTriggered] = useState(false);

  const trigger = useCallback(ms => {
    setTriggered(true);
    setRunning(true);
    clearTimeout(id.current);
    id.current = setTimeout(() => setRunning(false), ms);
  }, []);

  return [running, trigger, triggered];
};

export default useBoolTimer;
