import React from 'react';

import { ProgressCircle } from 'fr-shared/components';

import { useAnalysisProgress } from 'portal/hooks/useAnalysisProgress';
import { Part } from 'portal/lib/cart';

import styles from './StudioUploadModal.module.css';

interface StudioUploadAnalysisProps {
  onComplete: () => void;
  part: Part;
}

const StudioUploadAnalysis = ({ onComplete, part }: StudioUploadAnalysisProps) => {
  const progressPercentage = useAnalysisProgress(onComplete, part);

  return (
    <div className={styles.InnerContainer}>
      <div className={styles.Description}>
        <h3>Analyzing your part...</h3>
        <p className="text-muted font-size-md">
          Once complete, we&apos;ll help you check your part for manufacturability and explore
          costs and properties.
        </p>
      </div>
      <ProgressCircle percentage={progressPercentage} backgroundColor="#191E25" />
    </div>
  );
};

export default StudioUploadAnalysis;
