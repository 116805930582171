import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { AlertContext, useUserAnalyticsContext } from 'fr-shared/context';
import { getMarketoCookies } from 'fr-shared/lib/marketo';
import { ORDER_STATES } from 'fr-shared/lib/orders';

import { Page } from 'portal/components';
import { addLineItemsToCart, convertLineItemToCartLineItem } from 'portal/lib/cart';

import BillingDetails from './components/BillingDetails';
import OrderDetailsBody from './components/OrderDetailsBody';
import OrderDetailsHeader from './components/OrderDetailsHeader';
import ShipmentDetails from './components/ShipmentDetails';

const OrderView = () => {
  const [order, setOrder] = useState(null);
  const history = useHistory();
  const { setAlert } = useContext(AlertContext);
  const userAnalytics = useUserAnalyticsContext();
  const { id } = useParams();
  const logEvt = (type, valid) => userAnalytics.track(`Reorder - ${type} from Order`, { valid });

  useEffect(() => {
    api
      .get(`/customer_portal/orders/${id}`)
      .then(res => {
        setOrder(res.data);
      })
      .catch(() => {
        setAlert({ color: 'danger', message: 'We were unable to load your order' });
        history.push('/orders');
      });
  }, [history, id, setAlert]);

  const handleReorder = () => {
    addLineItemsToCart(order.line_items)
      .then(() => {
        logEvt('Order', true);
        history.push('/quotes/draft');
      })
      .catch(() => {
        logEvt('Order', false);
        setAlert({
          color: 'danger',
          message: 'We were unable to reorder your please refresh and try again',
        });
      });
  };

  const handleReorderLineItem = lineItem => {
    const lineItemParams = convertLineItemToCartLineItem(lineItem);

    api
      .post(`/customer_portal/cart_line_items`, {
        line_item: lineItemParams,
        marketo_attrs: getMarketoCookies(),
      })
      .then(res => {
        const { data: cli } = res;
        logEvt('Part', true);
        history.push(`/part-config/${cli.id}`);
      })
      .catch(() => {
        logEvt('Part', false);
        setAlert({
          color: 'danger',
          message: 'An unexpected error occurred, please refresh and try again',
        });
      });
  };

  if (!order) return null;

  return (
    <Page>
      <OrderDetailsHeader order={order} onReorderClicked={handleReorder} />
      <OrderDetailsBody order={order} onReorderClicked={handleReorderLineItem} />
      {order.state !== ORDER_STATES.Canceled && (
        <>
          <ShipmentDetails order={order} />
          <BillingDetails order={order} />
        </>
      )}
    </Page>
  );
};

export default OrderView;
