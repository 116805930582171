import { useEffect, useRef, useState } from 'react';

import { useChannel } from 'fr-shared/hooks';

import { Part, PartFileRevision } from 'portal/lib/cart';

/**
 * The amount of progress, as reported by the channel, that has been made
 * analyzing the part file revision.
 */
type Progress = {
  conversion_completed: number;
  conversion_total: number;
  dimensions_completed: number;
  dimensions_total: number;
  features_completed: number;
  features_total: number;
};

type PercentageOptions = {
  baseProgressAmount: number;
  conversionWeight: number;
  dimensionsWeight: number;
  featuresWeight: number;
};

/**
 * A `Progress` where all "completed" fields have been initialized to
 * zero.
 */
const zeroProgress: Progress = {
  conversion_completed: 0,
  conversion_total: 1,
  dimensions_completed: 0,
  dimensions_total: 1,
  features_completed: 0,
  features_total: 1,
};

const defaultPercentageOptions: PercentageOptions = {
  baseProgressAmount: 0,
  conversionWeight: 1,
  dimensionsWeight: 1,
  featuresWeight: 1,
};

/**
 * Returns true if the all stages of conversion and analysis have completed.
 */
const isAnalysisCompleted = (progress: Progress): boolean => {
  return (
    progress.conversion_completed >= progress.conversion_total &&
    progress.dimensions_completed >= progress.dimensions_total &&
    progress.features_completed >= progress.features_total
  );
};

/**
 * Returns a progress amount in the range [0, 100] to show.
 * @param baseProgressAmount - The progress amount in the range [0, 100] to show
 * when there is no progress in any of the conversion or analysis stages.
 * @param conversionWeight - The relative weight of conversion stage progress
 * when calculating total progress.
 * @param dimensionsWeight - The relative weight of dimensions stage progress
 * when calculating total progress.
 * @param featuresWeight - The relative weight of features stage progress when
 * calculating total progress.
 * @param progress - The "completed" to "total" progress ratios of the
 * conversion and analysis stages.
 */
const calcProgressAmount = (
  { baseProgressAmount, conversionWeight, dimensionsWeight, featuresWeight }: PercentageOptions,
  progress: Progress
): number => {
  const remainingProgressAmount = 100 - baseProgressAmount;
  const totalWeight = conversionWeight + dimensionsWeight + featuresWeight;

  const conversionPercentage = progress.conversion_completed / progress.conversion_total;
  const dimensionsPercentage = progress.dimensions_completed / progress.dimensions_total;
  const featuresPercentage = progress.features_completed / progress.features_total;

  const conversionProgressAmount =
    remainingProgressAmount * (conversionWeight / totalWeight) * conversionPercentage;
  const dimensionsProgressAmount =
    remainingProgressAmount * (dimensionsWeight / totalWeight) * dimensionsPercentage;
  const featuresProgressAmount =
    remainingProgressAmount * (featuresWeight / totalWeight) * featuresPercentage;

  return Math.ceil(
    baseProgressAmount +
      conversionProgressAmount +
      dimensionsProgressAmount +
      featuresProgressAmount
  );
};

/**
 * The number of milliseconds to wait after the part file revision's creation
 * time before hiding the "progress" component and showing the "part viewer"
 * component.
 *
 * In general, we want to wait until analysis has finished before showing the
 * "part viewer". But if enough time has passed then analysis may have failed,
 * so we'll show the "part viewer" even if analysis isn't finished.
 */
const analysisTimeoutMs: number = 120000; // 2 minutes

/**
 * Returns the number of milliseconds since the part file revision was inserted
 * into the database.
 */
const msSinceInserted = (partFileRevision: PartFileRevision) => {
  let insertedAt = new Date(partFileRevision.inserted_at).getTime();
  return Date.now() - insertedAt;
};

export const analysisTimeRemainingMs = (partFileRevision: PartFileRevision) => {
  return analysisTimeoutMs - msSinceInserted(partFileRevision);
};

/**
 * Hook to listen to analysis progress, and complete when analysis is complete OR when the timeout is reached.
 * @param onCompleted the callback to be used on completion or timeout
 * @param part the part to track
 * @param percentageOptions extra customization for weighting the different stages of analysis
 * @returns progress percentage
 */
export const useAnalysisProgress = (
  onCompleted: () => void,
  part: Part,
  percentageOptions: PercentageOptions = defaultPercentageOptions,
  isAnalyzingLastPart: boolean = true
) => {
  const [progress, setProgress] = useState<Progress>(zeroProgress);
  const [channel] = useChannel(`analysis_progress:${part.current_revision.id}`);
  const timeoutRef = useRef(null);

  // Add a fallback timeout
  useEffect(() => {
    const msRemaining = analysisTimeRemainingMs(part.current_revision);

    if (part.current_revision.is_analyzed || msRemaining <= 0) {
      // Either the current revision is already analyzed or the analysis has
      // timed out, so show the current revision in the DFM viewer.
      onCompleted();
    } else {
      // clear out any old timers using the old callback
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // set the timeout and update the ref
      const timeout = setTimeout(() => {
        onCompleted();
      }, msRemaining);
      timeoutRef.current = timeout;

      // cleanup if dismounted
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [part, onCompleted]);

  // Listen for incoming messages about progress updates on the channel.
  useEffect(() => {
    if (!channel) return;
    const subscriptionId = channel.on('progress_updated', (updates: Partial<Progress>) => {
      setProgress(oldProgress => ({ ...oldProgress, ...updates }));
    });
    return () => channel.off('progress_updated', subscriptionId);
  }, [channel]);

  // Request the current progress from the channel once the channel is
  // initialized. This ensures that we get the progress from the channel at
  // least once.
  useEffect(() => {
    if (!channel) return;
    channel
      .push('current_progress', {})
      .receive('ok', (progress: Progress) => setProgress(progress));
  }, [channel]);

  // Execute the callback when analysis is completed.
  useEffect(() => {
    if (isAnalysisCompleted(progress) && isAnalyzingLastPart) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (onCompleted) {
        onCompleted();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, onCompleted]);

  return calcProgressAmount(percentageOptions, progress);
};
