import { IconFont } from '@fast-radius/shared-ui';
import { find, get, groupBy } from 'lodash';
import React, { useState } from 'react';

import { CHECKABLE_MFG_PROCESSES } from 'fr-shared/lib/manufacturability_checks';
import {
  MANUFACTURING_PROCESSES,
  PROCESS_NAMES,
  getManufacturingProcessImageUrl,
} from 'fr-shared/lib/manufacturing_process';
import { keyboardDown } from 'fr-shared/utils';

import { CardWrapper, FormFieldBase, classNames } from 'portal/components';
import { getColorImageUrl, getMaterialImageUrl } from 'portal/lib/material';

import { ManufacturingProcessFields } from '../components/FormCollapsibleMfgProcess/FormCollapsibleMfgProcess';
import FullScreenOptionCard, {
  FullScreenOption,
} from '../components/FullScreenOptionCard/FullScreenOptionCard';
import {
  FullScreenModal,
  SelectedOptionStateContext,
} from '../components/FullScreenSelector/FullScreenSelector';
import { MATERIAL_DESCRIPTIONS, PROCESS_DESCRIPTIONS } from '../copy';

export type ManufacturingSpecState = {
  valid: boolean;
  manufacturing_process_id?: number;
  material_id?: number;
  color_id?: number;
  layer_thickness_id?: number;
};

interface Props {
  disabled?: boolean;
  fieldData: ManufacturingSpecState;
  index: number;
  manufacturingProcesses: ManufacturingProcess[];
  handleBack: () => void;
  handleFieldChanges: (changes: Partial<ManufacturingSpecState>, index: number) => void;
}

export const getMaterialInfo = (
  currentProcess: ManufacturingProcess,
  fieldData: ManufacturingProcessFields
) => {
  const relevantMaterials = currentProcess?.materials;
  const getMaterialById = (materialId: string | number) =>
    materialId && find(relevantMaterials, { id: +materialId });
  const currentMaterial = getMaterialById(fieldData.material_id);
  const materialColors = currentMaterial?.colors;
  const currentMaterialColor = materialColors?.find(c => c.id === fieldData.color_id);
  return {
    relevantMaterials,
    currentMaterialColor,
    materialColors,
    currentMaterial,
  };
};

const ManufacturingSpecificationFields = ({
  disabled,
  fieldData,
  index,
  manufacturingProcesses,
  handleBack,
  handleFieldChanges,
}: Props) => {
  // If the initial col has no process set we will open the process modal.
  const defaultModalState = index === 0 && !fieldData.manufacturing_process_id && 'process';
  const [modalState, setModalState] = useState(defaultModalState);
  const [prevSelections, setPrevSelections] = useState(null);

  const openModal = (state: 'process' | 'material' | 'color') =>
    !disabled && setModalState(state);
  const handleCancel = () => {
    // prevSelections are only set if process & material were chosen previously
    if (prevSelections) {
      // If a user selects a new manufacturing process but decides to cancel out
      // we will set the fields to their previous selections
      handleFieldChanges(prevSelections, index);
    } else {
      // If this is the first col and they had no prevSelections (initial state)
      // we need to redirect them back to where they came from
      // If this is an additional col we need to either null out the fields
      // or remove this col from the array
      index === 0
        ? handleBack()
        : handleFieldChanges(
            {
              valid: false,
              manufacturing_process_id: null,
              material_id: null,
              color_id: null,
              layer_thickness_id: null,
            },
            index
          );
    }
  };

  // Pull the process
  const currentProcess = find(manufacturingProcesses, {
    id: +fieldData.manufacturing_process_id,
  });
  const groupedProcesses = groupBy(manufacturingProcesses, (mp: ManufacturingProcess) =>
    mp.additive ? 'additive' : 'traditional'
  );

  // Pull the materials from the process
  const { relevantMaterials, currentMaterial, materialColors, currentMaterialColor } =
    getMaterialInfo(currentProcess, fieldData);

  const manufacturingProcessOnChange = (selectedProcess: FullScreenOption) => {
    // Checking if the selected process has associated materials
    const processData = find(manufacturingProcesses, {
      id: +selectedProcess.id,
    });
    const processHasMaterials = processData?.materials.length > 0;

    if (currentProcess?.id === selectedProcess.id) {
      if (!currentMaterial && processHasMaterials) openModal('material');
    } else {
      // Setting prevSelections here as once a new proccess is set all other fields are nulled
      // If process and material have been set we know that fieldData has all fields completed
      // we don't want an incomplete set of fields for prevSelections
      if (currentProcess && currentMaterial) setPrevSelections(fieldData);
      else if (currentProcess && relevantMaterials?.length === 0) setPrevSelections(fieldData);
      handleFieldChanges(
        {
          valid: !processHasMaterials,
          manufacturing_process_id: selectedProcess.id,
          material_id: null,
          color_id: null,
          layer_thickness_id: null,
        },
        index
      );
      if (processHasMaterials) openModal('material');
    }
  };
  const materialOnChange = (selectedMaterial: FullScreenOption) => {
    if (currentMaterial?.id !== selectedMaterial.id) {
      const material = find(relevantMaterials, { id: selectedMaterial.id });

      // default color
      let colorId = null;
      if (material.colors.length > 0) {
        colorId = material.colors[0].id;
      }

      // default layer thickness
      let layerThicknessId = null;
      if (get(material, 'layer_thicknesses.length', 0) > 0) {
        const defaultLayerThickness = material.layer_thicknesses.find(lt => lt.value === '0.01');
        const newLayerThickness = defaultLayerThickness
          ? defaultLayerThickness
          : material.layer_thicknesses[0];
        layerThicknessId = newLayerThickness.id;
      }

      handleFieldChanges(
        {
          valid: true,
          manufacturing_process_id: currentProcess.id,
          material_id: selectedMaterial.id,
          color_id: colorId,
          layer_thickness_id: layerThicknessId,
        },
        index
      );
    }
  };
  const colorOnChange = (selectedColor: FullScreenOption) => {
    if (currentMaterialColor?.id !== selectedColor.id) {
      handleFieldChanges(
        {
          valid: true,
          color_id: selectedColor.id,
        },
        index
      );
    }
  };

  return (
    <>
      <FormFieldBase
        className="mb-2 print:mr-1"
        name={'manufacturing_process'}
        label={currentProcess ? 'Manufacturing process' : ' '}
        isStacked={true}
      >
        <div
          className={classNames(['relative', disabled ? 'pointer-events-none' : ''])}
          onClick={() => openModal('process')}
          onKeyDown={evt => keyboardDown(evt, 'Enter', () => openModal('process'))}
          tabIndex={0}
          role="button"
        >
          <input
            className={classNames([
              'form-control text-white',
              disabled ? 'placeholder:text-coolGray-600 border-coolGray-600' : '',
            ])}
            value={
              (currentProcess?.name &&
                (PROCESS_NAMES[currentProcess.name]
                  ? PROCESS_NAMES[currentProcess.name]
                  : currentProcess.name)) ||
              ''
            }
            placeholder="Select process & material"
            name="manufacturing_process"
            disabled={true}
          />
          <IconFont
            name="plus"
            className={classNames([
              'absolute right-0 bottom-0 text-lg leading-9 print:hidden',
              disabled ? 'text-coolGray-500' : '',
            ])}
          />
        </div>
      </FormFieldBase>
      <div className="material-and-color">
        {currentMaterial && (
          <SpecTag
            processName={currentProcess.name}
            text={currentMaterial.name}
            onClick={() => openModal('material')}
          />
        )}
        {currentMaterialColor && (
          <SpecTag
            className="print:bg-coolGray-200 print:text-coolGray-900"
            text={currentMaterialColor.name}
            onClick={() => openModal('color')}
          />
        )}
      </div>
      {/* Manufacturing Process */}
      <div>
        {modalState === 'process' && (
          <FullScreenModal
            isModalOpen={true}
            modalHeader={'Manufacturing process'}
            modalSubHeader={'Select a manufacturing process for your part.'}
            setIsModalOpen={(isModalOpen: boolean) => {
              !isModalOpen ? setModalState(null) : setModalState('process');
            }}
            onCancel={() => {
              if (!currentProcess || (!currentMaterial && relevantMaterials?.length > 0))
                handleCancel();
            }}
            onSubmit={manufacturingProcessOnChange}
            currentOption={currentProcess}
          >
            {({ onSubmit, selectedOption }: SelectedOptionStateContext) => (
              <div className="flex flex-1 flex-column">
                {Object.keys(groupedProcesses)
                  .reverse()
                  .map((group: string) => {
                    // render each group of Manufacturing Processes
                    const processes: ManufacturingProcess[] = groupedProcesses[group];
                    return (
                      <div className="mb-4" key={group}>
                        <h5 className="mb-4">
                          {group === 'additive' ? '3D Printing' : 'Traditional Manufacturing'}
                        </h5>
                        <CardWrapper>
                          {processes.map((option: ManufacturingProcess) => (
                            <FullScreenOptionCard
                              key={`${option.name}${option.id}`}
                              onSubmit={onSubmit}
                              name={PROCESS_NAMES[option.name]}
                              description={PROCESS_DESCRIPTIONS[option.name]}
                              imageUrl={getManufacturingProcessImageUrl(option.name)}
                              option={option}
                              selectedOption={selectedOption}
                            >
                              {CHECKABLE_MFG_PROCESSES.includes(option.name) && (
                                <div className="border-top mt-auto pt-2">
                                  <span className="font-size-sm text-tertiary-500">
                                    Automated DFM checks available
                                  </span>
                                </div>
                              )}
                            </FullScreenOptionCard>
                          ))}
                        </CardWrapper>
                      </div>
                    );
                  })}
              </div>
            )}
          </FullScreenModal>
        )}

        {/* Material */}
        <div>
          {modalState === 'material' && (
            <FullScreenModal
              isModalOpen={true}
              modalHeader={'Material'}
              modalSubHeader={'Select a material for your part.'}
              setIsModalOpen={(isModalOpen: boolean) => {
                !isModalOpen ? setModalState(null) : setModalState('material');
              }}
              onCancel={() => {
                if (!currentMaterial) openModal('process');
              }}
              onSubmit={materialOnChange}
              currentOption={currentMaterial}
            >
              {({ onSubmit, selectedOption }: SelectedOptionStateContext) => (
                <>
                  <h5 className="mb-4">
                    {PROCESS_NAMES[currentProcess.name]
                      ? currentProcess.name && PROCESS_NAMES[currentProcess.name]
                      : currentProcess.name}
                  </h5>
                  <CardWrapper>
                    {relevantMaterials?.map((option: FullScreenOption) => {
                      return (
                        <FullScreenOptionCard
                          key={`${option.name}${option.id}`}
                          description={MATERIAL_DESCRIPTIONS[option.name]}
                          onSubmit={onSubmit}
                          imageUrl={getMaterialImageUrl(currentProcess.name, option.name)}
                          option={option}
                          selectedOption={selectedOption}
                        />
                      );
                    })}
                  </CardWrapper>
                </>
              )}
            </FullScreenModal>
          )}
        </div>
      </div>

      {/* Color */}
      <div>
        {modalState === 'color' && (
          <FullScreenModal
            isModalOpen={true}
            modalHeader={'Color'}
            modalSubHeader={'Select a color for your part.'}
            setIsModalOpen={(isModalOpen: boolean) => {
              !isModalOpen ? setModalState(null) : setModalState('color');
            }}
            onSubmit={colorOnChange}
            currentOption={currentMaterialColor}
          >
            {({ onSubmit, selectedOption }: SelectedOptionStateContext) => (
              <>
                <h5 className="mb-4">
                  {PROCESS_NAMES[currentProcess.name]
                    ? PROCESS_NAMES[currentProcess.name]
                    : currentProcess.name}{' '}
                  &bull; {currentMaterial.name}
                </h5>
                <CardWrapper>
                  {materialColors?.map((option: FullScreenOption) => {
                    return (
                      <FullScreenOptionCard
                        key={`${option.name}${option.id}`}
                        option={option}
                        onSubmit={onSubmit}
                        imageUrl={
                          materialColors.length > 1
                            ? getColorImageUrl(
                                currentProcess.name,
                                currentMaterial.name,
                                option.name
                              )
                            : getMaterialImageUrl(currentProcess.name, currentMaterial.name)
                        }
                        selectedOption={selectedOption}
                      />
                    );
                  })}
                </CardWrapper>
              </>
            )}
          </FullScreenModal>
        )}
      </div>
    </>
  );
};

interface SpecTagProps {
  className?: string;
  disabled?: boolean;
  processName?: string;
  text: string;
  onClick: () => void;
}

const SPEC_TAG_BG_COLOR = {
  [MANUFACTURING_PROCESSES.DLS]: 'bg-secondary-300',
  [MANUFACTURING_PROCESSES.MJF]: 'bg-primary-300',
  [MANUFACTURING_PROCESSES.FDM]: 'bg-tertiary-300',
  [MANUFACTURING_PROCESSES.SLA]: 'bg-success-300',
  [MANUFACTURING_PROCESSES.CNC]: 'bg-warning-300',
  [MANUFACTURING_PROCESSES.InjectionMolding]: 'bg-error-300',
  [MANUFACTURING_PROCESSES.CastUrethane]: 'bg-[#F3DD66]',
};

const SpecTag = ({
  className = '',
  disabled = false,
  processName = null,
  text,
  onClick,
}: SpecTagProps) => {
  const bgColor = SPEC_TAG_BG_COLOR[processName] || 'bg-white';

  return (
    <button
      className={classNames([
        'rounded-xs border-none text-dark mr-1 mb-[4px]',
        bgColor,
        className,
      ])}
      onClick={onClick}
      disabled={disabled}
    >
      {text}{' '}
      <IconFont
        className="text-base leading-4 align-text-bottom print:hidden"
        name="close-circle"
      ></IconFont>
    </button>
  );
};

export default ManufacturingSpecificationFields;
