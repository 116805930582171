import PropTypes from 'prop-types';
import React from 'react';

import { Badge } from 'fr-shared/components';

const getColorForState = state => {
  const DEFAULT = 'outline';

  if (!state) return DEFAULT;

  const orderColors = {
    'In review': DEFAULT,
    'In production': 'blue',
    'Partially shipped': 'teal',
    Ordered: 'purple',
    Shipped: 'green',
    Canceled: DEFAULT,
  };

  return orderColors[state] ? orderColors[state] : DEFAULT;
};

const StateBadge = ({ state, ...props }) => {
  return <Badge color={getColorForState(state)} {...props} />;
};

StateBadge.propTypes = {
  state: PropTypes.string,
};

export default StateBadge;
