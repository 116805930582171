import { pickBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { api } from 'fr-shared/api';
import { useTableFilters } from 'fr-shared/hooks';

import CostingTable from '../../costing_requests/components/CostingTable';
import { TABLE_COLUMNS } from '../../costing_requests/utils/queues';

const getCostingRequests = (params, filters) =>
  api.get('/costing_requests', {
    params: { ...pickBy(filters), ...params },
  });

export const CustomerCostingRequests = ({ params }) => {
  const {
    data: costingRequests,
    loading,
    onFetchData: handleTableFetchData,
    pages,
  } = useTableFilters(null, { page: 1, sort_key: 'id', sort_desc: true }, filters =>
    getCostingRequests(params, filters)
  );

  return (
    <div>
      <h3 className="mb-3">Costing Requests</h3>
      <CostingTable
        padding={false}
        columns={[
          TABLE_COLUMNS.Id,
          TABLE_COLUMNS.OpportunityId,
          TABLE_COLUMNS.PublicId,
          TABLE_COLUMNS.State,
          TABLE_COLUMNS.ManufacturingProcessId,
          TABLE_COLUMNS.DateSubmitted,
        ]}
        data={costingRequests}
        emptyText="No costing requests found"
        pages={pages}
        onFetchData={handleTableFetchData}
        loading={loading}
      />
    </div>
  );
};

CustomerCostingRequests.propTypes = {
  params: PropTypes.object,
};

export default CustomerCostingRequests;
