import PropTypes from 'prop-types';
import React from 'react';

import { FormFieldWithLinkPreview } from 'fr-shared/components';

const MJFLinkFormField = ({ showMJFLink, ...props }) => {
  return (
    <FormFieldWithLinkPreview
      {...props}
      showLink={showMJFLink}
      name="mjf_link"
      label="MJF Link"
    />
  );
};

MJFLinkFormField.propTypes = {
  mjfLinkUrl: PropTypes.string,
  showMJFLink: PropTypes.bool,
};

export default MJFLinkFormField;
