import { Logo } from '@fast-radius/shared-ui';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { getAndClearPreAuthRoute, logout } from 'fr-shared/auth';
import { AlertBanner } from 'fr-shared/components';
import {
  AlertProvider,
  UserAnalyticsProvider,
  UserContext,
  useUserAnalyticsContext,
} from 'fr-shared/context';

import { Footer } from 'portal/components';
import styles from 'portal/index.module.css';

import AdminContainer from './views/admin/';
import CustomerPortalContainer from './views/customer_portal';
import Customize from './views/customer_portal/pages/account/Customize';
import CreateAccountContainer from './views/customer_portal/pages/account/create_account';
import ForgotPasswordContainer from './views/customer_portal/pages/account/forgot_password';
import LogInContainer from './views/customer_portal/pages/account/log_in';
import ResetPasswordContainer from './views/customer_portal/pages/account/reset_password';
import RouteSaver from './views/customer_portal/pages/account/route_saver';
import VerifyEmail from './views/customer_portal/pages/account/verify';
import VerifyEmailSentContainer from './views/customer_portal/pages/account/verify/sent';
import CreditCardContainer from './views/customer_portal/pages/credit_card';
import InsightsContainer from './views/customer_portal/pages/insights';
import MaintenanceContainer from './views/customer_portal/pages/maintenance';
import SalesPortalContainer from './views/sales_portal';

const Router = ({ user }) => {
  return (
    <UserAnalyticsProvider>
      <Switch>
        {/* Routes that can be accessed by both authenticated and unauthenticated users */}
        <Route path="/customer/card" component={CreditCardContainer} />
        <Route path="/insights/:hashid" component={InsightsContainer} />
        <Route path="/verify" component={VerifyEmail} />
        <Route path="/sent_verification_email" component={VerifyEmailSentContainer} />
        {user ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}
      </Switch>
    </UserAnalyticsProvider>
  );
};

const UnauthenticatedRoutes = () => {
  return (
    <div className="theme-dark bg-gradient">
      <AlertProvider>
        <div className={styles.Unauthenticated}>
          <div className={styles.Centered}>
            <div>
              <AlertBanner />
              <Logo className="mt-2 mb-4" fill="white" width={40} height={40} />
              <Switch>
                <Redirect path="/quote" to="/create-account" />
                <Route path="/login" component={LogInContainer} />
                <Route path="/create-account" component={CreateAccountContainer} />
                <Route path="/reset" component={ResetPasswordContainer} />
                <Route path="/forgot" component={ForgotPasswordContainer} />
                {/* RouteSaver automatically redirects to '/' so exact pathing here
                    will eventually go to login */}
                <Redirect exact path="/" to="/login" />
                <Route component={RouteSaver} />
              </Switch>
            </div>
          </div>
          <Footer />
        </div>
      </AlertProvider>
    </div>
  );
};

const AuthenticatedRoutes = () => {
  const { user } = useContext(UserContext);
  const userAnalytics = useUserAnalyticsContext();

  userAnalytics.setUserMetadata({
    user_id: user.id,
    organization_id: user.organization?.id,
  });

  const savedRoute = getAndClearPreAuthRoute();

  // If a user is verified, or if they do not need verification (brand new user not in the database)
  // we want to deny them and send them to verification email page
  const denyPortalAccess = !user.verified && !!user.needs_verification_before_portal;

  if (denyPortalAccess) {
    return <Redirect to={`/sent_verification_email?email=${encodeURIComponent(user.email)}`} />;
  }

  // If a user exists in the database already, and is missing fields for better portal attribution/accounts,
  // we render the <Customize /> form
  const needUserDetails = user.missing_portal_fields && !user.isFR;

  if (needUserDetails) {
    return <Customize />;
  }

  // If we have a saved route from before logging in, redirect to it
  if (savedRoute) {
    if (process.env.MIX_ENV === 'dev') console.log('Saved Route loaded:', savedRoute);
    if (savedRoute.external_link) window.location.assign(savedRoute.external_link);
    return <Redirect to={savedRoute} />;
  }

  return (
    <Switch>
      {user.isFR && <Route path="/admin/sales" component={SalesPortalContainer} />}

      {user.isFR && <Route path="/admin" component={AdminContainer} />}
      <Route path="/logout" render={() => logout()} />
      <Route path="/maintenance" component={MaintenanceContainer} />
      <Route path="/" component={CustomerPortalContainer} />
      <Redirect exact={true} from="/login" to="/dashboard" />
      <Redirect to="/dashboard" />
    </Switch>
  );
};

Router.propTypes = {
  user: PropTypes.object,
};

export default Router;
