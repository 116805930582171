import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Link, Money, StateBadge, TableBodyCell, TableBodyRow } from 'portal/components';
import { date } from 'portal/lib/date';
import { getQuoteStateLabel } from 'portal/lib/quotes';

const ListViewRow = ({ quote }) => {
  const navigateToString = () => {
    if (quote.is_cart) {
      return '/quotes/draft';
    } else if (quote.is_costing_request) {
      return `/quotes/requested/${quote.id}`;
    } else {
      return `/quotes/${quote.id}`;
    }
  };

  return (
    <TableBodyRow>
      <TableBodyCell>
        <Link to={navigateToString()}>{quote.is_cart ? 'Edit quote' : quote.public_id}</Link>
      </TableBodyCell>
      <TableBodyCell>
        <StateBadge state={quote.state}>{getQuoteStateLabel(quote.state)}</StateBadge>
      </TableBodyCell>
      <TableBodyCell value={quote.is_cart ? 'Not submitted yet' : date(quote.inserted_at)} />
      <TableBodyCell value={quote.line_items.length} width="1%" align="center" />
      <TableBodyCell align="right">
        <Money amount={quote?.total_price?.amount} />
      </TableBodyCell>
    </TableBodyRow>
  );
};

ListViewRow.propTypes = {
  quote: PropTypes.object,
};

export default memo(ListViewRow);
