import { FieldArray, connect } from 'formik';
import { compact, unionBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, FormField, FormSelectSearch, Icon } from 'fr-shared/components';

import YieldReportFileUpload from './YieldReportFileUpload';

const formatSelectOptions = options => {
  if (options) {
    return options.map(o => {
      return { value: o.id, label: o.description };
    });
  } else {
    return [];
  }
};

const YieldReportPartFailure = ({ lineIndex, formik, availableRejectionReasons }) => {
  const { setFieldValue } = formik;
  const data = formik.values.part_failure_updates[lineIndex];

  // In case a rejection_reason on these failures is a soft-deleted one, union them with the active options
  const rejectionReasonsInUse = compact(data.part_failures.map(pf => pf.rejection_reason));
  const rejectionReasons = unionBy(availableRejectionReasons, rejectionReasonsInUse, 'id');
  const options = formatSelectOptions(rejectionReasons);
  const onFileCleared = partFailureIndex => () => {
    setFieldValue(`part_failure_updates.${lineIndex}.part_failures.${partFailureIndex}`, {
      ...data.part_failures[partFailureIndex],
      s3_path: '',
      link: '',
    });
  };

  const onFileUploaded = partFailureIndex => uploadedFile => {
    setFieldValue(`part_failure_updates.${lineIndex}.part_failures.${partFailureIndex}`, {
      ...data.part_failures[partFailureIndex],
      ...uploadedFile,
    });
  };

  return (
    <FieldArray name={`part_failure_updates.${lineIndex}.part_failures`}>
      {arrayHelpers => (
        <>
          {data.part_failures.map((failure, partFailureIndex) => (
            <div key={partFailureIndex} className="failure-code border-top mb-0">
              <div className="flex flex-1 flex-column flex-md-row">
                <div className="flex flex-column flex-1 mr-2 pt-2 px-3">
                  <div className="row">
                    <div>
                      Failure Information
                      <div>
                        <strong>
                          ID: {failure.id ? failure.id : '(Will generate after save)'}
                        </strong>
                      </div>
                    </div>
                    <div className="ml-auto">
                      <Button
                        color="danger"
                        outline={true}
                        onClick={() => arrayHelpers.remove(partFailureIndex)}
                      >
                        <Icon name="times" />
                      </Button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg">
                      <div className="row form-group">
                        <FormField
                          required={true}
                          name={`part_failure_updates.${lineIndex}.part_failures.${partFailureIndex}.quantity`}
                          label={`Qty`}
                          className="flex-1"
                          type="number"
                        />
                      </div>
                      <div className="row form-group">
                        <FormSelectSearch
                          className="flex-1"
                          label="Reason Code"
                          name={`part_failure_updates.${lineIndex}.part_failures.${partFailureIndex}.rejection_reason_id`}
                          options={options}
                          isRequired
                        />
                      </div>
                    </div>
                    <div className="col-lg ml-lg-2">
                      <div className="row form-group">
                        <FormField
                          name={`part_failure_updates.${lineIndex}.part_failures.${partFailureIndex}.comments`}
                          label="Comments"
                          className="flex-1"
                        />
                      </div>
                      <div className="row form-group">
                        <YieldReportFileUpload
                          image={data.part_failures[partFailureIndex]}
                          onFileUploaded={onFileUploaded(partFailureIndex)}
                          onFileCleared={onFileCleared(partFailureIndex)}
                        >
                          Add Photo
                        </YieldReportFileUpload>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </FieldArray>
  );
};

YieldReportPartFailure.propTypes = {
  lineIndex: PropTypes.number,
  formik: PropTypes.object,
  handlePartFailureChange: PropTypes.func,
  availableRejectionReasons: PropTypes.array,
};

export default connect(YieldReportPartFailure);
