import React from 'react';

import { INSPECTION_TYPE_NAMES } from 'fr-shared/lib/inspection_types';

import { InspectionTypeForDefinition } from 'portal/components';

interface InspectionTypePriceProps {
  advancedInspectionPrice: null | Money;
  inspectionType: InspectionTypeForDefinition;
}

const InspectionTypePrice = ({
  advancedInspectionPrice,
  inspectionType,
}: InspectionTypePriceProps) => {
  const isAdvancedInspection = inspectionType.name === INSPECTION_TYPE_NAMES.AdvancedInspection;

  return (
    <>
      {isAdvancedInspection ? (
        <>
          {advancedInspectionPrice ? (
            <div className="relative">
              {advancedInspectionPrice.formatted} <sup className="text-sm static">*</sup>
            </div>
          ) : (
            <>
              $5 or 25% per unit <sup className="text-sm static">*</sup>
            </>
          )}
        </>
      ) : (
        <>{inspectionType?.price?.formatted}</>
      )}
    </>
  );
};

export default InspectionTypePrice;
