import { useTreatments } from '@splitsoftware/splitio-react';
import { FieldArray, connect } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, FormFieldBase, FormNumber, Icon } from 'fr-shared/components';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { isFormikAtPrefixTheSame } from 'fr-shared/utils';

const SupplierCostPartQuantityFields = ({
  isStacked,
  fieldPrefix,
  formik,
  readonly,
  supplierCostIndex,
}) => {
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';
  const lineItem = get(formik, `values.${fieldPrefix}`);
  const supplierCosts = lineItem?.supplier_costs?.[supplierCostIndex];
  const partQuantities = supplierCosts?.part_quantities;
  const fieldName = `${fieldPrefix}.supplier_costs.${supplierCostIndex}.part_quantities`;
  const locationIdError = get(
    formik.errors,
    `${fieldPrefix}.supplier_costs.${supplierCostIndex}.location_id`
  );

  return (
    <>
      {locationIdError && (
        <div className="alert alert-danger">
          A supplier must be selected in order to save this quantity. Please ensure the{' '}
          {lineItemName}
          is In Progress and select the supplier from the Produced By dropdown.
        </div>
      )}
      {!isStacked ? (
        <div className="ml-3">
          <FieldArray name={fieldName}>
            {({ push, remove }) => (
              <FormFieldBase
                className="font-size-md"
                isRequired={true}
                label={<span className="font-size-md">Quantity</span>}
                name={`${fieldName}.0.quantity`}
                isStacked={false}
                md={1}
                readonly={readonly}
              >
                <div className="flex flex-row flex-wrap">
                  {partQuantities &&
                    partQuantities.map((pq, index) => (
                      <div className="flex flex-row" key={`partQuantity-quantity-${index}`}>
                        <FormNumber
                          name={`${fieldName}.${index}.quantity`}
                          type="number"
                          min={1}
                          className="append-button flex-fill mr-1"
                          onDelete={
                            partQuantities.length > 1 && (pq.can_delete ?? true)
                              ? () => remove(index)
                              : null
                          }
                          readonly={readonly}
                        />

                        {index === partQuantities.length - 1 && !readonly && (
                          <Button
                            className="ml-2"
                            color="secondary"
                            onClick={() => push({ per_unit_cost: '', quantity: '' })}
                            outline
                            size="md"
                            style={{ marginBottom: 8 }}
                          >
                            <Icon name="plus" /> Add quantity
                          </Button>
                        )}
                      </div>
                    ))}
                </div>
              </FormFieldBase>
            )}
          </FieldArray>
        </div>
      ) : (
        <FieldArray name={fieldName}>
          {({ push, remove }) => (
            <FormFieldBase
              isRequired={true}
              label="Quantity"
              name={`${fieldName}.0.quantity`}
              isStacked={false}
              readonly={readonly}
            >
              {partQuantities &&
                partQuantities.map((pq, index) => (
                  <div className="flex" key={`partQuantity-quantity-${index}`}>
                    <FormNumber
                      name={`${fieldName}.${index}.quantity`}
                      type="number"
                      min={1}
                      className="append-button flex-fill"
                      onDelete={
                        partQuantities.length > 1 && (pq.can_delete ?? true)
                          ? () => remove(index)
                          : null
                      }
                      readonly={readonly}
                    />

                    {index === partQuantities.length - 1 && !readonly && (
                      <Button
                        className="ml-2"
                        color="secondary"
                        onClick={() => push({ per_unit_cost: '', quantity: '' })}
                        outline
                        size="md"
                        style={{ marginBottom: 8 }}
                      >
                        <Icon name="plus" /> Add quantity
                      </Button>
                    )}
                  </div>
                ))}
            </FormFieldBase>
          )}
        </FieldArray>
      )}
    </>
  );
};

SupplierCostPartQuantityFields.propTypes = {
  isStacked: PropTypes.bool,
  fieldPrefix: PropTypes.string,
  formik: PropTypes.object,
  readonly: PropTypes.bool,
  supplierCostIndex: PropTypes.number,
};

SupplierCostPartQuantityFields.defaultProps = {
  isStacked: true,
};

export default connect(React.memo(SupplierCostPartQuantityFields, isFormikAtPrefixTheSame));
