import { useTreatments } from '@splitsoftware/splitio-react';
import { Field, Form, useFormikContext, withFormik } from 'formik';
import { isEmpty, set } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import {
  Button,
  Card,
  FormIncoterms,
  FormScrollUpOnErrors,
  Icon,
  Link,
  Modal,
  QuoteStateBadge,
  ServerError,
} from 'fr-shared/components';
import DisplayInPortalToggleModal from 'fr-shared/components/common/DisplayInPortalToggleModal';
import EmailQuoteToCustomerModal from 'fr-shared/components/common/EmailQuoteToCustomerModal';
import { AlertContext, UserContext } from 'fr-shared/context';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { usePartUpdate, useToggle } from 'fr-shared/hooks';
import { QUOTE_STATES } from 'fr-shared/lib/quotes';
import { SHIPPING_PAYMENT_TYPES } from 'fr-shared/lib/shipping';

import { quoteToOrderSchema } from '../utils/schema';
import { transformQuoteToOrder } from '../utils/transforms';
import CustomerContactPicker from './CustomerContactPicker';
import DisplayQuoteInPortalCheckbox from './DisplayQuoteInPortalCheckbox';
import QuoteFormAdditionalInfo from './QuoteFormAdditionalInfo';
import QuoteFormButtons from './QuoteFormButtons';
import QuoteFormCertificates from './QuoteFormCertificates';
import { QuoteFormProvider } from './QuoteFormContext';
import QuoteFormHeader from './QuoteFormHeader';
import QuoteFormLineCost from './QuoteFormLineCost';
import QuoteFormLineItems from './QuoteFormLineItems';
import QuoteFormShippingPaymentType from './QuoteFormShippingPaymentType';
import QuoteOpportunityIdAlert from './QuoteOpportunityIdAlert';
import QuoteToOrder from './QuoteToOrder';
import QuoteFormShippingEstimates from './shipping/QuoteFormShippingEstimates';

const QuoteForm = ({
  channel,
  history,
  isLoading,
  loadQuote,
  onSubmitAndSync,
  onSync,
  readonly,
  title,
}) => {
  const { setAlert, closeAlert } = useContext(AlertContext);
  const { user } = useContext(UserContext);
  const [showConvertToOrder, setShowConvertToOrder] = useState(false);
  const [showEmailCustomer, setShowEmailCustomer] = useState(false);
  const [showDisplayModal, setShowDisplayModal] = useState(false);
  const [lineItemsChanged, setLineItemsChanged] = useState(false);
  const [shippingIsCollapsed, toggleShippingIsCollapsed] = useToggle();
  const [isConvertingToOrder, setIsConvertingToOrder] = useState(false);
  const [afterSync, setAfterSync] = useState(false);
  const formik = useFormikContext();
  usePartUpdate('line_items');

  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';
  const capLineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'Build Pack' : 'Line Item';

  const isCreated = !!formik.values.id;
  const isEnabled = isCreated && !lineItemsChanged;
  const isCustomerPortal = formik.values.customer_portal;
  const isSubmitted = formik.values.state === QUOTE_STATES.Submitted;
  const hasLineItems = !isEmpty(formik.values.line_items);
  const hasOpportunityID = formik.values.opportunity_id;
  const canEditIncoterm = !readonly && !isCustomerPortal && user.canEditIncoterm;
  const displayInPortal = formik.values.display_in_portal;
  const showShippingEstimates =
    formik.values.shipping_payment_type !== SHIPPING_PAYMENT_TYPES.CustomerAccount;

  useEffect(() => {
    if (history && history.location.search.includes('show')) {
      setShowEmailCustomer(true);
      history.replace(history.location.pathname);
    }
  }, [history]);

  const handleHideInPortal = () => {
    api
      .put(`/quotes/${formik.values.id}/update_display_in_portal`, {
        display_in_portal: false,
      })
      .then(() => {
        if (displayInPortal === true) {
          setAlert({ message: 'This quote is now visible in the customer portal' });
        } else {
          setAlert({ message: 'This quote has been hidden in the customer portal' });
        }
      })
      .catch(() => {
        setAlert({
          message: 'Quote failed to be hidden in customer portal. Please reload and try again',
          color: 'danger',
        });
      });
  };

  const handleShowInPortal = () => {
    api
      .put(`/quotes/${formik.values.id}/update_display_in_portal`, {
        display_in_portal: true,
      })
      .then(() => {
        if (displayInPortal === true) {
          setAlert({ message: 'This quote is now visible in the customer portal' });
        } else {
          setAlert({ message: 'This quote has been hidden in the customer portal' });
        }
      })
      .catch(() => {
        setAlert({
          message: 'Quote failed to be hidden in customer portal. Please reload and try again',
          color: 'danger',
        });
      });
  };

  const handleConvertToOrder = () => {
    setIsConvertingToOrder(true);
    quoteToOrderSchema
      .validate(formik.values.order, { abortEarly: false })
      .then(() => {
        api
          .post(`/quotes/${formik.values.id}/create_order`, {
            order_params: formik.values.order,
          })
          .then(res => {
            setIsConvertingToOrder(false);
            setShowConvertToOrder(false);
            loadQuote();
            setAlert({
              autoClose: !res.data.warning,
              color: res.data.warning ? 'warning' : 'success',
              message: (
                <>
                  {`Successfully converted this quote to order `}
                  <Link onClick={closeAlert} to={`/admin/orders/${res.data.id}`}>
                    {`#${res.data.public_id}`}
                  </Link>
                  {'. '}
                  {res.data.warning}
                </>
              ),
            });
          })
          .catch(err => {
            if (err?.response?.data) {
              const { errors: serverErrors, messages } = err.response.data;
              formik.setErrors({ ...formik.errors, server: messages, ...serverErrors });
            } else {
              formik.setErrors({
                ...formik.errors,
                server: ['An unexpected error occurred. Refresh and try again.'],
              });
            }
            setIsConvertingToOrder(false);
          });
      })
      .catch(validation => {
        formik.setErrors({
          ...formik.errors,
          server: validation.errors,
          order: validation.inner.reduce((acc, { message, path }) => set(acc, path, message), {}),
        });
        setIsConvertingToOrder(false);
      });
  };

  const handleConvertToOrderClicked = () => {
    toggleQuoteToOrderForm();
  };

  const handleLineItemsChanged = () => {
    formik.setFieldValue('selected_quote_shipping_estimate_id', null);
    setLineItemsChanged(true);
  };

  const handleSaveDraft = () => setLineItemsChanged(false);

  const toggleQuoteToOrderForm = () => {
    formik.setFieldValue('order', transformQuoteToOrder(formik.values));
    setShowConvertToOrder(prevValue => !prevValue);
  };

  const toggleShowEmail = () => setShowEmailCustomer(!showEmailCustomer);

  const toggleShowDisplay = () => setShowDisplayModal(!showDisplayModal);

  const handleSalesforceSyncSuccess = () => {
    if (displayInPortal || formik.values.display_in_portal) {
      setAfterSync(true);
      setShowEmailCustomer(true);
    }
  };

  const handleToggleOn = () => {
    setShowEmailCustomer(true);
  };

  const handleToggleOff = () => {
    setShowDisplayModal(true);
  };

  return (
    <QuoteFormProvider channel={channel}>
      <Form>
        <Field type="hidden" name="sales_contact_id" />
        <Field type="hidden" name="support_contact_id" />
        <Field type="hidden" name="engineering_contact_id" />
        <FormScrollUpOnErrors />

        {isSubmitted && (
          <Modal fullscreen={true} isOpen={showConvertToOrder} toggle={toggleQuoteToOrderForm}>
            <QuoteToOrder
              onSubmit={handleConvertToOrder}
              selectedShippingEstimate={formik.values.selected_shipping_estimate}
              toggle={toggleQuoteToOrderForm}
              isLoading={isConvertingToOrder}
            />
          </Modal>
        )}
        <EmailQuoteToCustomerModal
          afterSync={afterSync}
          customerContact={formik.values.customer_contact}
          handleShowInPortal={handleShowInPortal}
          isOpen={showEmailCustomer}
          quote={formik.values}
          toggle={() => {
            toggleShowEmail();
            setAfterSync(false);
          }}
        />
        <DisplayInPortalToggleModal
          handleHideInPortal={handleHideInPortal}
          isOpen={showDisplayModal}
          toggle={toggleShowDisplay}
        />

        <div className="page-header">
          <div className="container">
            <div>
              <div className="flex flex-row mb-2">
                <h2 className="mr-2">{title}</h2>
                {isCreated && <QuoteStateBadge state={formik.values.state} />}
              </div>
              {isCreated && <QuoteFormHeader quote={formik.values} />}
            </div>

            <div className="page-actions flex align-items-center">
              <QuoteFormButtons
                isLoading={isLoading}
                onConvertToOrderClicked={handleConvertToOrderClicked}
                onSaveDraft={handleSaveDraft}
                onSyncButtonClicked={() => {
                  onSubmitAndSync(formik.values, formik, handleSalesforceSyncSuccess);
                }}
                readonly={readonly}
              />
            </div>
          </div>

          <div className="container">
            {hasOpportunityID && isCreated && (
              <div className="flex flex-row mb-2">
                <CustomerContactPicker />
              </div>
            )}

            <div className="page-actions flex justify-content-end">
              <DisplayQuoteInPortalCheckbox
                onToggleOff={handleToggleOff}
                onToggleOn={handleToggleOn}
              />
            </div>
          </div>
        </div>
        <div className="container">
          {formik.errors.completed_at_quote_id ? (
            <QuoteOpportunityIdAlert
              quoteId={formik.errors.completed_at_quote_id}
              onSync={() => onSync(formik.errors.completed_at_quote_id, formik)}
            />
          ) : (
            <ServerError
              errors={formatErrorMessages(
                formik.values.line_items,
                formik.errors,
                capLineItemName
              )}
            />
          )}

          <QuoteFormLineItems readonly={readonly} onLineItemsChanged={handleLineItemsChanged} />

          {hasOpportunityID && hasLineItems ? (
            <>
              <div className="row">
                <div className="col-md-12">
                  <Card>
                    <Card.Header>
                      <div className="flex-grow-1">Shipping</div>
                      <div>
                        <Button
                          className="border"
                          color="light"
                          onClick={toggleShippingIsCollapsed}
                          size="sm"
                        >
                          <Icon name={shippingIsCollapsed ? 'chevron-down' : 'chevron-up'} />
                        </Button>
                      </div>
                    </Card.Header>
                    {!shippingIsCollapsed && (
                      <Card.Body>
                        <div className="container">
                          <div className="row p-1">
                            <div className="col-md-6 pl-0">
                              <FormIncoterms
                                label={<h6 className="font-size-md mb-0">Incoterm</h6>}
                                disabled={!canEditIncoterm}
                              />
                            </div>
                          </div>
                          <div className="row p-1">
                            <div className="col-md-6 pl-0">
                              <QuoteFormShippingPaymentType
                                readonly={readonly || isCustomerPortal}
                              />
                            </div>
                          </div>
                          {showShippingEstimates && (
                            <div className="row">
                              <QuoteFormShippingEstimates
                                isEnabled={isEnabled}
                                readonly={readonly || isCustomerPortal}
                              />
                            </div>
                          )}
                        </div>
                      </Card.Body>
                    )}
                  </Card>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <QuoteFormCertificates readonly={readonly} />
                </div>
                <div className="col-md-6">
                  <QuoteFormLineCost readonly={readonly} />
                </div>
              </div>

              <QuoteFormAdditionalInfo readonly={readonly} />

              <div className="flex justify-content-end text-right mb-5">
                <QuoteFormButtons
                  isJustifyBottom={true}
                  isLoading={isLoading}
                  onConvertToOrderClicked={handleConvertToOrderClicked}
                  onSaveDraft={handleSaveDraft}
                  onSyncButtonClicked={() =>
                    onSubmitAndSync(formik.values, formik, handleSalesforceSyncSuccess)
                  }
                  readonly={readonly}
                />
              </div>
            </>
          ) : (
            (!hasOpportunityID || !hasLineItems) && (
              <Card>
                <Card.Body>
                  <h4 className="flex justify-content-center text-muted">
                    To continue, enter an Opportunity ID and add {lineItemName}s.
                  </h4>
                </Card.Body>
              </Card>
            )
          )}
        </div>
      </Form>
    </QuoteFormProvider>
  );
};

QuoteForm.propTypes = {
  channel: PropTypes.object,
  errors: PropTypes.object,
  history: PropTypes.object,
  isLoading: PropTypes.bool,
  loadQuote: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitAndSync: PropTypes.func,
  onSync: PropTypes.func,
  readonly: PropTypes.bool,
  setErrors: PropTypes.func,
  setFieldValue: PropTypes.func,
  setValues: PropTypes.func,
  title: PropTypes.string,
  user: PropTypes.object,
  values: PropTypes.object,
};

const defaultValues = {
  blind_ship: false,
  certificate_of_conformance: true,
  coc_signature_required: false,
  finishing_certificaton_required: false,
  first_article_inspection: false,
  incoterm: 'exw',
  line_items: [],
  material_certification_required: false,
  order: {},
  shipping_payment_type: SHIPPING_PAYMENT_TYPES.InvoiceToCustomer,
};

const formatErrorMessages = (lineItems, errors, capLineItemName) => {
  const messages = [];
  if (errors.line_items) {
    errors.line_items.forEach((itemErr, i) => {
      if (itemErr) {
        messages.push(`${capLineItemName} ${i + 1}: ${lineItems[i]?.description}`);
      }
    });
  }
  if (errors.server) {
    messages.push(...errors.server);
  }
  return messages;
};

const getInitialValues = props => ({
  ...defaultValues,
  ...props.initialValues,
});

export default withFormik({
  mapPropsToValues: getInitialValues,
  enableReinitialize: true,
  handleSubmit: (values, actions) => {
    actions.props.onSubmit && actions.props.onSubmit(values, actions);
  },
  validateOnBlur: false,
  validateOnChange: false,
})(QuoteForm);
