import PropTypes from 'prop-types';
import React, { memo, useContext } from 'react';

import { TableBody, TableCustom, TableHead, TableHeadCell } from 'portal/components';
import { PartsContext } from 'portal/contexts/PartsContext';
import { PartModel } from 'portal/types/parts';

import PartsListViewRow from './PartsListViewRow';

interface PartsListViewProps {
  parts: PartModel[];
  onSort?: (sortValue: string) => void;
}

export const PartsListView = ({ parts, onSort }: PartsListViewProps) => {
  const { currentSortParam, isSortDesc } = useContext(PartsContext);

  return (
    <TableCustom>
      <TableHead>
        <TableHeadCell
          onClick={onSort}
          sorted={currentSortParam === 'name'}
          isSortDesc={isSortDesc}
          value="Part name"
        />
        <TableHeadCell
          onClick={onSort}
          sorted={currentSortParam === 'inserted_at'}
          isSortDesc={isSortDesc}
          value="Date added"
        />
        <TableHeadCell
          onClick={onSort}
          sorted={currentSortParam === 'updated_at'}
          isSortDesc={isSortDesc}
          value="Last edited"
        />
        <TableHeadCell value="Part size" />
        <TableHeadCell value="Supporting docs" />
      </TableHead>
      <TableBody>
        {parts.map(part => (
          <PartsListViewRow key={part.id} part={part} />
        ))}
      </TableBody>
    </TableCustom>
  );
};

PartsListView.propTypes = {
  parts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSort: PropTypes.func,
};

export default memo(PartsListView);
