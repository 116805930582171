import { reverse, sortBy } from 'lodash';
import moment from 'moment';

import { CostingRequestLineItem } from 'fr-shared/lib/costing_requests';

export const LINE_ITEM_SORT_OPTIONS = {
  COMPLETED_AT_DESC: 'COMPLETED_AT_DESC',
  COMPLETED_AT_ASC: 'COMPLETED_AT_ASC',
  PART_NAME_DESC: 'PART_NAME_DESC',
  PART_NAME_ASC: 'PART_NAME_ASC',
};

export const LINE_ITEM_SORT_TEXT = {
  [LINE_ITEM_SORT_OPTIONS.COMPLETED_AT_ASC]: 'Completed, Earliest to Latest',
  [LINE_ITEM_SORT_OPTIONS.COMPLETED_AT_DESC]: 'Completed, Latest to Earliest',
  [LINE_ITEM_SORT_OPTIONS.PART_NAME_DESC]: 'Part Name, A to Z',
  [LINE_ITEM_SORT_OPTIONS.PART_NAME_ASC]: 'Part Name, Z to A',
};

/**
 *
 * @param option - The sort option selected
 * @param lineItems - The list of costing request line items to sort
 * @return - A sorted list of line items
 */
export const sortLineItemsByOption = (
  option: string,
  lineItems: Array<CostingRequestLineItem>
): Array<CostingRequestLineItem> => {
  switch (option) {
    case LINE_ITEM_SORT_OPTIONS.COMPLETED_AT_ASC:
      return sortBy(lineItems, li => moment(li.completed_at).valueOf());
    case LINE_ITEM_SORT_OPTIONS.COMPLETED_AT_DESC:
      return reverse(sortBy(lineItems, li => moment(li.completed_at).valueOf()));
    case LINE_ITEM_SORT_OPTIONS.PART_NAME_ASC:
      return reverse(sortBy(lineItems, li => li.description.toLocaleLowerCase()));
    case LINE_ITEM_SORT_OPTIONS.PART_NAME_DESC:
      return sortBy(lineItems, li => li.description.toLocaleLowerCase());
    default:
      return lineItems;
  }
};
