import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Loading } from 'fr-shared/components';

import Camera from './Camera';
import Scene from './Scene';
import StlModel from './StlModel';

const Viewer = ({
  className,
  debug,
  isRounded = true,
  manufacturingProcess,
  maxHeight = 960,
  minHeight = 600,
  onResetCamera,
  partUnits,
  revision,
  sceneControls,
  theme,
  showBuildPlateToggle,
  visibleMeshes,
}) => {
  const [loading, setLoading] = useState(true);
  const [position, setPosition] = useState(null);
  const [radius, setRadius] = useState(null);

  return (
    <div>
      {loading && (
        <div className="babylon-loader">
          <Loading />
        </div>
      )}

      <div className={className} style={{ opacity: loading ? 0 : 1 }}>
        <Scene
          isRounded={isRounded}
          maxHeight={maxHeight}
          minHeight={minHeight}
          onResetCamera={onResetCamera}
          sceneControls={sceneControls}
          showBuildPlateToggle={showBuildPlateToggle}
          theme={theme}
          visibleMeshes={visibleMeshes}
        >
          <StlModel
            debug={debug}
            manufacturingProcess={manufacturingProcess}
            onChangeLoading={loading => setLoading(loading)}
            onChangePosition={position => setPosition(position)}
            onChangeRadius={radius => setRadius(radius)}
            partUnits={partUnits}
            revision={revision}
          />
          <Camera radius={radius} position={position} />
        </Scene>
      </div>
    </div>
  );
};

Viewer.propTypes = {
  className: PropTypes.string,
  debug: PropTypes.bool,
  isRounded: PropTypes.bool,
  manufacturingProcess: PropTypes.object,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onResetCamera: PropTypes.func,
  partUnits: PropTypes.string,
  revision: PropTypes.object,
  sceneControls: PropTypes.node,
  showBuildPlateToggle: PropTypes.bool,
  theme: PropTypes.string,
  visibleMeshes: PropTypes.object,
};

export default Viewer;
