import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { AlertContext, UserContext, useUserAnalyticsContext } from 'fr-shared/context';

import {
  Badge,
  Button,
  ContactUs,
  ContactUsType,
  DetailsHeader,
  Money,
  ShippingPriceLabel,
} from 'portal/components';
import { date } from 'portal/lib/date';

import RequestQuote from './RequestQuote';

const CartDetailsHeader = ({ cart, readonly = false }) => {
  const { setAlert } = useContext(AlertContext);
  const history = useHistory();
  const isCartValid = cart.line_items.length > 0 && cart.line_items.every(li => li.is_complete);
  const totalPriceAmount = cart.is_manual ? null : cart?.total_price?.amount;
  const userAnalytics = useUserAnalyticsContext();
  const handleContactUs = () => userAnalytics.track(`Contact Us - Cart`, { valid: true });
  const handleRequestQuote = () => userAnalytics.track(`Request Quote`, { valid: true });
  const handleCheckout = () => userAnalytics.track(`Start Checkout`, { valid: true });

  const handleConvertCartToCR = () => {
    api
      .post(`/customer_portal/convert_cart_to_costing_request`)
      .then(res => {
        history.push(`/admin/costing_requests/${res.data.costing_request_id}`);
      })
      .catch(() => {
        setAlert({
          color: 'danger',
          message: 'An unexpected error occurred, please refresh and try again',
        });
      });
  };

  const { user } = useContext(UserContext);
  return (
    <DetailsHeader backLink="/quotes" backText="Back to all quotes" readonly={readonly}>
      <DetailsHeader.MainCell>
        <div className="font-size-sm text-gray">Quote #</div>
        <div className="flex align-items-center">
          <h3>&mdash;&mdash;</h3>
          <div className="ml-3">
            <Badge>Draft</Badge>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md mb-3">
            <div className="text-gray font-size-sm">Date created</div>
            <div className="text-light">{date(cart.inserted_at)}</div>
          </div>
          <div className="col-md mb-3">
            <div className="text-gray font-size-sm">Last edited</div>
            <div className="text-light">{date(cart.updated_at)}</div>
          </div>
        </div>
      </DetailsHeader.MainCell>
      <DetailsHeader.EndCell>
        <div className="font-size-md flex justify-content-between align-items-center">
          <p className="text-gray">Items subtotal</p>
          <Money amount={totalPriceAmount} className="text-gray" />
        </div>
        <div className="font-size-md flex justify-content-between align-items-center">
          <p className="text-gray">
            <ShippingPriceLabel />
          </p>
          <Money amount={null} className="text-gray" />
        </div>
        <div className="flex justify-content-between align-items-center">
          <p className="text-light">Total</p>
          <Money
            amount={totalPriceAmount}
            className="text-light font-size-lg font-weight-bolder"
            dataTestId={'cart-details-header-total'}
          />
        </div>
        <div className="flex justify-content-between mt-2">
          <ContactUs
            outline
            className="flex-1"
            linkType={ContactUsType['button']}
            onClick={handleContactUs}
            readonly={readonly}
          >
            Contact us
          </ContactUs>
          {user.canConvertCustomerCartToCostingRequest ? (
            <Button
              dataTestId="cart-header-convert-to-cr--button"
              disabled={readonly}
              className="flex-1 ml-2"
              onClick={() => {
                handleConvertCartToCR();
              }}
            >
              Convert to CR
            </Button>
          ) : (
            <>
              {cart.is_manual ? (
                <RequestQuote
                  action={
                    <Button
                      dataTestId="quotes-request-quote-button"
                      className="flex-1 ml-2"
                      disabled={!isCartValid || readonly}
                    >
                      Request Quote
                    </Button>
                  }
                  onActionHandler={handleRequestQuote}
                />
              ) : (
                <Button
                  dataTestId={'cart-details-header-checkout'}
                  disabled={!isCartValid || readonly}
                  className="flex-1 ml-2"
                  to="/checkout"
                  onClick={handleCheckout}
                >
                  Checkout
                </Button>
              )}
            </>
          )}
        </div>
      </DetailsHeader.EndCell>
    </DetailsHeader>
  );
};

CartDetailsHeader.propTypes = {
  cart: PropTypes.shape({
    line_items: PropTypes.array,
    inserted_at: PropTypes.string,
    updated_at: PropTypes.string,
    total_price: PropTypes.object,
    is_manual: PropTypes.bool,
    is_manual_reason: PropTypes.string,
  }),
  readonly: PropTypes.bool,
};

export default CartDetailsHeader;
