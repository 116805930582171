import { useFormikContext } from 'formik';
import React from 'react';

import { IconFont } from 'fr-shared/components';
import {
  getExpirationDateFromDetails,
  getFormattedLastFourFromDetails,
} from 'fr-shared/lib/braintree';

import { Button, ContactUs, FormField, FormFieldBase } from 'portal/components';

const CreditCardInformationSuccess = () => {
  const formik = useFormikContext();

  return (
    <div>
      <div className="row border-bottom pb-3">
        <div className="col flex flex-column align-items-center justify-content-center border-right">
          <IconFont name="check-circle" className="text-success font-size-3xl mb-3" />
          <h2 className="text-center mb-3 border-0">
            <strong>Information Submitted</strong>
          </h2>
        </div>
        <div className="col flex align-items-center justify-content-center">
          Your card will be charged when your order ships.
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-6">
          <h2>Credit Card Information</h2>
          <h3>Credit Card</h3>
          <FormField name="billing_address.name_line_1" label="Name on card:" readonly={true} />
          <FormFieldBase label="Card Number:">
            <strong> {getFormattedLastFourFromDetails(formik.values.payment_details)} </strong>
          </FormFieldBase>
          <FormFieldBase label="Expiration date:">
            <strong> {getExpirationDateFromDetails(formik.values.payment_details)} </strong>
          </FormFieldBase>
          <hr />
          <h3> Billing Address </h3>
          <FormField name="billing_address.email" label="Billing email:" readonly={true} />
          <FormField name="billing_address.country" label="Country: " readonly={true} />
          <FormField
            name="billing_address.street_line_1"
            label="Billing address 1:"
            readonly={true}
          />
          <FormField
            name="billing_address.street_line_2"
            label="Billing address 2:"
            readonly={true}
          />
          <FormField name="billing_address.city" label="City" readonly={true} />
          <FormField name="billing_address.state" label="State" readonly={true} />
          <FormField name="billing_address.postal_code" label="ZIP code" readonly={true} />
          <Button onClick={() => window.open('https://www.fastradius.com')} className="mt-3">
            Go To Fastradius.com
          </Button>
          <div className="mt-4">
            <IconFont name="question-circle" />
            <strong> QUESTIONS? CONTACT OUR TEAM </strong>
          </div>
          <p className="mt-2">
            <ContactUs>Contact us here.</ContactUs> We&apos;re available Monday-Friday
            8:00am-6:00pm Central Time.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CreditCardInformationSuccess;
