import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { IconFont } from 'fr-shared/components';
import { AlertContext, UserContext } from 'fr-shared/context';
import { UNITS_OF_MEASUREMENT } from 'fr-shared/lib/parts';
import { pluralize } from 'fr-shared/utils';
import {
  ADDITIVE_PART_FILE_EXTENSIONS_PORTAL,
  PART_FILE_EXTENSIONS_PORTAL,
  TRADITIONAL_PART_FILE_EXTENSIONS_PORTAL,
} from 'fr-shared/utils/files';

import {
  Button,
  CapabilitiesInfoBox,
  ContactUsInfoBox,
  FormSelect,
  Link,
  Page,
  PageHeader,
  PartDropzone,
  PartUploadModal,
  SupportedFiles,
} from 'portal/components';
import { handleFileDrop, handleS3PartCreated } from 'portal/lib/upload';

import RecentUploads from './RecentUploads';

const PartUpload = () => {
  const formik = useFormikContext();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { closeAlert, setAlert } = useContext(AlertContext);
  const [loadingText, setLoadingText] = useState('');
  const [analysisPayload, setAnalysisPayload] = useState(null);
  // Using a state variable to show the modal because without it, and without
  // setting this state variable inside of the handlers, there's some janky
  // looking transitions/re-sizing.
  const [shouldShowPartUploadModal, setShouldShowPartUploadModal] = useState(false);
  const [partUploadModalState, setPartUploadModalState] = useState('upload');
  const [recentUploadAddToQuoteCalled, setRecentUploadAddToQuoteCalled] = useState(false);

  const s3PartCreatedHandler = async (part, isOnFirstCLIRequest = true) => {
    return handleS3PartCreated({
      part,
      cartLineItemPostSuccess: res => {
        if (!isOnFirstCLIRequest) return;

        setPartUploadModalState('analyze');
        const { data: cli } = res;
        setAnalysisPayload({ cli_id: cli.id, part });
      },
      cartLineItemPostFail: () => {
        if (!isOnFirstCLIRequest) return;

        setShouldShowPartUploadModal(false);

        setAlert({
          color: 'danger',
          message: 'An unexpected error occurred, please refresh and try again',
        });
      },
    });
  };

  const onFileDropHandler = acceptedFiles => {
    setShouldShowPartUploadModal(true);
    // NOTE: the purpose of this is to ensure the `PartDropzone.Analyzing` component
    // does not open if a user uploads a part and quickly closes it before re-uploading
    // again. Without it, the analysis payload will still be set and the `onComplete`
    // handler will get called for the _previously_ uploaded part.
    setAnalysisPayload(null);

    handleFileDrop({
      acceptedFiles,
      loadHandlers: {
        initLoadingText: () => {
          closeAlert();
          setLoadingText(pluralize('Uploading file', acceptedFiles.length > 1) + '...');
        },
        clearLoadingText: () => setLoadingText(''),
        loadingError: () => {
          setShouldShowPartUploadModal(false);
          setAlert({
            color: 'danger',
            message:
              'Unfortunately, we could not upload your file since the format was not accepted. See "Supported file types" below to see what file formats you can upload.',
          });
        },
      },
      s3LoadingHandlers: {
        uploadError: file => {
          setShouldShowPartUploadModal(false);
          setAlert({
            color: 'danger',
            message: `Failed to upload ${file.name}. Please refresh and try again.`,
          });
        },
      },
      cartLineItemPostHandler: ({ part }, isOnFirstCLIRequest) =>
        s3PartCreatedHandler(part, isOnFirstCLIRequest),
      user,
      quote_units: formik?.values?.quote_units,
    });
  };

  return (
    <Page>
      {shouldShowPartUploadModal && (
        <PartUploadModal
          onClose={() => {
            setAnalysisPayload(null);
            setLoadingText('');
            setPartUploadModalState('upload');
            setShouldShowPartUploadModal(false);
          }}
          state={partUploadModalState}
        />
      )}

      <div className="mb-3">
        <Link className="font-size-sm text-gray text-decoration-none" to="/">
          <IconFont name="chevron-left" right />
          Back to dashboard
        </Link>
      </div>
      <div className="flex justify-between">
        <div className="ml-auto">
          <span className="mr-2">Don&apos;t have a CAD file?</span>
          <Button outline size="sm" to="/create-quote/2d-files">
            <IconFont name="plus" />
            Add part drawing
          </Button>
        </div>
      </div>
      <PageHeader
        title="Get a quote"
        subtitle="Add parts to your quote by uploading a file from your computer or select from
        recently uploaded parts."
      />

      <h3>Add 3D design files</h3>

      <div className="border-dashed p-5 mt-5 bg-transparent">
        {(shouldShowPartUploadModal || recentUploadAddToQuoteCalled) && analysisPayload ? (
          <PartDropzone.Analyzing
            className="flex flex-column justify-content-center align-items-center"
            part={analysisPayload.part}
            onComplete={() => history.push(`/part-config/${analysisPayload.cli_id}`)}
          />
        ) : (
          <PartDropzone
            card={false}
            isLoading={!isEmpty(loadingText)}
            label="Drag and drop 3D design files here or"
            loadingSlot={<PortalDropzone open={() => {}} />}
            onFileDrop={onFileDropHandler}
            accept={PART_FILE_EXTENSIONS_PORTAL}
            dropzoneComponent={<PortalDropzone />}
          />
        )}
      </div>

      <SupportedFiles
        className="mt-2"
        additiveFileExtensions={ADDITIVE_PART_FILE_EXTENSIONS_PORTAL}
        traditionalFileExtensions={TRADITIONAL_PART_FILE_EXTENSIONS_PORTAL}
      />

      <RecentUploads
        onPartCreated={part => {
          setRecentUploadAddToQuoteCalled(true);
          return s3PartCreatedHandler(part);
        }}
      />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-1 pt-2 my-5">
        <ContactUsInfoBox />
        <CapabilitiesInfoBox />
      </div>
    </Page>
  );
};

export const PortalDropzone = ({ open, getInputProps }) => {
  return (
    <div className="flex align-items-center justify-content-center">
      {getInputProps && <input {...getInputProps()} id="file-dropzone" />}

      <div>
        <h3 className="text-gray font-size-lg">Drag and drop files here</h3>
        <div className="flex flex-row justify-content-center align-items-center">
          <Button
            dataTestId="part-upload-button"
            className="flex justify-content-center align-items-center rounded-circle mt-1 mr-3 cursor-pointer p-0 rounded-circle"
            style={{ width: 48, height: 48 }}
            onClick={open}
          >
            <IconFont name="plus" className="text-white mr-0" />
          </Button>
          <FormSelect
            className="mb-0"
            dataTestId="quote_units"
            floatingLabel={true}
            hasBlankOption={false}
            inputProps={{ required: true }}
            label="Units&nbsp;"
            name="quote_units"
            nameAccessor={option => option}
            optionList={UNITS_OF_MEASUREMENT}
            stacked
            style={{ width: 130 }}
            valueAccessor={option => option}
          />
        </div>
      </div>
    </div>
  );
};

PortalDropzone.propTypes = {
  open: PropTypes.func,
  getInputProps: PropTypes.func,
};

export default PartUpload;
