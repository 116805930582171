import { mapValues } from 'lodash';
import { useState } from 'react';

const usePartViewer = () => {
  const [visibleMeshes, setVisibleMeshes] = useState({});

  const handleResetCamera = () => {
    setVisibleMeshes(mapValues(visibleMeshes, () => false));
  };

  const handleToggleMesh = meshesToBeShown => {
    // we want to turn off all visible meshes before applying new ones
    const disabledMeshes = mapValues(visibleMeshes, () => false);
    setVisibleMeshes({ ...disabledMeshes, ...meshesToBeShown });
  };

  return { handleResetCamera, handleToggleMesh, visibleMeshes };
};

export default usePartViewer;
