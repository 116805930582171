import React, { useEffect, useState } from 'react';

import { api } from 'fr-shared/api';

import { Button, Card, Page, PageHeader } from 'portal/components';
import { COMPARE_MATERIAL_PICTO_URL } from 'portal/lib/material';

import MaterialInfo from './MaterialInfo';

type MaterialsProps = {
  materialId: number;
  selectedManufacturingProcess: ManufacturingProcess;
  onCompare: (material: Material) => void;
};

const Materials = ({ selectedManufacturingProcess, materialId, onCompare }: MaterialsProps) => {
  const [currentMaterial, setCurrentMaterial] = useState<Material>(null);

  useEffect(() => {
    if (materialId) {
      api.get<Material>(`/customer_portal/materials/${materialId}`).then(res => {
        const { data: material } = res;
        setCurrentMaterial(material);
      });
    }
  }, [materialId]);

  return (
    <Page className="lg:overflow-y-auto lg:h-screen">
      <PageHeader
        title="Material properties"
        subtitle="Here are the core performance metrics for your selected material."
        actionClassnames="mt-auto"
      />

      <MaterialInfo
        material={currentMaterial}
        selectedManufacturingProcess={selectedManufacturingProcess}
      />

      <Card className="flex flex-row justify-content-start align-items-end p-3">
        <img
          src={COMPARE_MATERIAL_PICTO_URL}
          alt="Compare Materials Pictogram"
          className="mr-3"
        />
        <span>
          <h5>Compare more materials and manufacturing processes</h5>
          <p className="text-muted font-size-sm">
            See how similar materials perform against our DFM checks and get cost estimates across
            our manufacturing processes.
          </p>
        </span>
        <Button outline size="sm" className="ml-auto" onClick={() => onCompare(currentMaterial)}>
          Compare
        </Button>
      </Card>
    </Page>
  );
};

export default Materials;
