// keep in sync with fast-radius/lib/fast_radius/accounts/permission.ex

export const PERMISSIONS = {
  CreateRejectionReasons: 'create_rejection_reasons',
  UpdateRejectionReasons: 'update_rejection_reasons',
  CreateSuppliers: 'create_suppliers',
  UpdateSuppliers: 'update_suppliers',
  CreateLeadTimes: 'create_lead_times',
  UpdateLeadTimes: 'update_lead_times',
  CreateOrganizations: 'create_organizations',
  DeleteOrganizations: 'delete_organizations',
  CreateSupplierPurchaseOrders: 'create_supplier_purchase_orders',
  CreateMaterials: 'create_materials',
  CreateBillableServiceLineItems: 'create_billable_service_line_items',
  CreateOrders: 'create_orders',
  ApproveOrRejectOrders: 'approve_or_reject_orders',
  EditDraftOrders: 'edit_draft_orders',
  EditApprovedOrders: 'edit_approved_orders',
  ViewDraftOrders: 'view_draft_orders',
  DuplicateOrders: 'duplicate_orders',
  SendOrderAlerts: 'send_order_alerts',
  ViewCreatedWorkOrders: 'view_created_work_orders',
  ViewScheduledWorkOrders: 'view_scheduled_work_orders',
  DuplicateWorkOrders: 'duplicate_work_orders',
  UpdateWorkOrders: 'update_work_orders',
  DeleteWorkOrders: 'delete_work_orders',
  CreateQuotes: 'create_quotes',
  UpdateQuotes: 'update_quotes',
  DuplicateQuotes: 'duplicate_quotes',
  SendQuoteAcceptanceEmail: 'send_quote_acceptance_email',
  GenerateCustomerCreditCardLink: 'generate_customer_credit_card_link',
  CreateCostingRequests: 'create_costing_requests',
  ArchiveCostingRequests: 'archive_costing_requests',
  DuplicateAndAddLineItemsToCostingRequests: 'duplicate_and_add_line_items_to_costing_requests',
  ViewDraftCostingRequests: 'view_draft_costing_requests',
  ConvertCostingRequestsToQuotes: 'convert_costing_requests_to_quotes',
  UpdateDraftOrRequestedCostingRequestLineItems:
    'update_draft_or_requested_costing_request_line_items',
  UpdateSubmittedCostingRequestLineItems: 'update_submitted_costing_request_line_items',
  CloseCostingRequestLineItems: 'close_costing_request_line_items',
  ToggleCostingRequestLineItemsInProgress: 'toggle_costing_request_line_items_in_progress',
  UpdateCostingRequestLineItemsRejectedReason:
    'update_costing_request_line_items_rejected_reason',
  SubmitCosts: 'submit_costs',
  RejectCosts: 'reject_costs',
  ImportCosts: 'import_costs',
  ViewQualityControl: 'view_quality_control',
  CanSendCostingEmail: 'can_send_costing_email',
  ViewManufacturabilityChecks: 'view_manufacturability_checks',
  ViewWebhooks: 'view_webhooks',
  DeleteWebhooks: 'delete_webhooks',
  EditIncoterm: 'edit_incoterm',
  EditShipment: 'edit_shipment',
  DeleteShipment: 'delete_shipment',
  CreatePrinters: 'create_printers',
  UpdatePrinters: 'update_printers',
  DeletePrinters: 'delete_printers',
  EditSupplierRFQs: 'edit_supplier_rfqs',
  ViewSupplierRFQs: 'view_supplier_rfqs',
  ViewCustomerPartsForOrg: 'view_customer_parts',
  EditCustomerPartsForOrg: 'edit_customer_parts',
  ViewCustomerQuotesForOrg: 'view_customer_quotes',
  ViewCustomerOrdersForOrg: 'view_customer_orders',
  EditMargins: 'edit_margins',
  ConvertCustomerCartToCostingRequest: 'convert_customer_cart_to_costing_request',
  ViewSalesPortal: 'view_sales_portal',
};
