import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { WORK_ORDER_STATES } from 'fr-shared/lib/work_orders';

const ReviewFilters = ({ filters, onFilterUpdate }) => {
  const [manufacturingProcesses, setManufacturingProcesses] = useState([]);
  const [rejectionReasons, setRejectionReasons] = useState([]);

  useEffect(() => {
    Promise.all([
      api.get('/manufacturing_process'),
      api.get('/rejection_reasons', { params: { page_size: 1000 } }),
    ]).then(([mfgProcressRes, rejectionReasonRes]) => {
      setManufacturingProcesses(mfgProcressRes.data);
      setRejectionReasons(rejectionReasonRes.data);
    });
  }, []);

  return (
    <>
      <div className="form-group">
        <label htmlFor="manufacturing_process_id">Manufacturing Process</label>
        <select
          data-testid="mfg-select"
          className="custom-select"
          name="manufacturing_process_id"
          id="manufacturing_process_id"
          onChange={onFilterUpdate}
          value={filters.manufacturing_process_id || ''}
        >
          <option value="" />
          {manufacturingProcesses.map((manufacturingProcess, index) => (
            <option key={index} value={manufacturingProcess.id}>
              {manufacturingProcess.name}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="fail_state">Failure State</label>
        <select
          data-testid="state-select"
          className="custom-select"
          name="fail_state"
          id="fail_state"
          onChange={onFilterUpdate}
          value={filters.fail_state || ''}
        >
          <option value="" />
          {WORK_ORDER_STATES.map((failState, index) => (
            <option key={index} value={failState}>
              {failState}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="rejection_reason_id">Reason</label>
        <select
          data-testid="reason-select"
          className="custom-select"
          name="rejection_reason_id"
          id="rejection_reason_id"
          onChange={onFilterUpdate}
          value={filters.rejection_reason_id || ''}
        >
          <option value="" />
          {rejectionReasons.map(({ id, description }) => (
            <option key={id} value={id}>
              {description}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

ReviewFilters.propTypes = {
  onFilterUpdate: PropTypes.func,
  filters: PropTypes.object,
};

export default ReviewFilters;
