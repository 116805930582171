import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';

import { IconFont } from 'fr-shared/components';
import { keyboardDown } from 'fr-shared/utils';

import { Button, Loading, classNames } from 'portal/components';
import { useAnalysisProgress } from 'portal/hooks/useAnalysisProgress';

export const PartDropzone = ({
  accept,
  acceptedFiles,
  children,
  className,
  dropzoneComponent,
  onFileDrop,
  isDisabled,
  isInvalid,
  isLoading,
  label,
  loadingSlot,
  showIcon,
  iconClassName,
  card,
  showSelectFilesButton = true,
  filesComponent,
}) => (
  <Dropzone accept={accept} onDrop={onFileDrop} disabled={isDisabled || isLoading} noClick={true}>
    {({ getRootProps, getInputProps, isDragActive, open }) => (
      <>
        <div
          data-testid="file-upload-area"
          className={classNames([
            card && 'card',
            'flex px-2 justify-content-center',
            'part-file-dropzone',
            acceptedFiles?.length === 0 ? 'flex-column' : 'flex-row',
            isDisabled && 'disabled',
            isInvalid && 'is-invalid',
            isDragActive && 'is-dragging',
            isLoading && 'is-animating',
            className,
          ])}
          {...getRootProps()}
        >
          {isLoading ? (
            loadingSlot
          ) : dropzoneComponent ? (
            React.cloneElement(dropzoneComponent, {
              getRootProps,
              getInputProps,
              open,
              isDragActive,
            })
          ) : (
            <>
              <div className="flex flex-column align-items-center text-center outline-none">
                <input {...getInputProps()} id="file-dropzone" />

                <div
                  onClick={open}
                  onKeyDown={evt => keyboardDown(evt, 'Enter', open)}
                  role="button"
                  tabIndex={0}
                >
                  {showIcon && (
                    <IconFont
                      className={classNames(['font-size-2xl', iconClassName])}
                      name="upload"
                    />
                  )}
                  <h4 className="mt-3 mb-2">{label}</h4>
                </div>

                {children}
                {showSelectFilesButton && (
                  <div>
                    <Button
                      color="primary"
                      className="rounded-0"
                      outline
                      disabled={isDisabled}
                      onClick={open}
                    >
                      Select Files
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
          {filesComponent}
        </div>
        {isInvalid && <div className="form-control-error">{isInvalid}</div>}
      </>
    )}
  </Dropzone>
);

PartDropzone.propTypes = {
  accept: PropTypes.array,
  acceptedFiles: PropTypes.array,
  card: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  dropzoneComponent: PropTypes.node,
  onFileDrop: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.string,
  isLoading: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loadingSlot: PropTypes.node,
  showIcon: PropTypes.bool,
  showSelectFilesButton: PropTypes.bool,
  iconClassName: PropTypes.string,
  filesComponent: PropTypes.node,
};

PartDropzone.defaultProps = {
  acceptedFiles: [],
  label: 'Drop .stl files here to upload',
  showIcon: true,
  card: true,
  showSelectFilesButton: true,
};

const DropzoneLoading = ({ loadingText, showLoadingWarning }) => {
  return (
    <div className="text-center">
      <h4 className="mb-2">{loadingText}</h4>
      {showLoadingWarning && (
        <div className="mb-2">
          <i>This may take several minutes.</i>
        </div>
      )}
      <Loading />
    </div>
  );
};

DropzoneLoading.propTypes = {
  loadingText: PropTypes.string,
  showLoadingWarning: PropTypes.bool,
};

PartDropzone.Loading = DropzoneLoading;

const DropzoneAnalyzing = ({
  backgroundComponent,
  className = '',
  onComplete,
  part,
  isAnalyzingLastPart,
}) => {
  useAnalysisProgress(onComplete, part, undefined, isAnalyzingLastPart);

  if (backgroundComponent) return backgroundComponent;

  return (
    <div className={className} data-testid="dropzone-analyzing">
      <h4>Analyzing your part...</h4>
    </div>
  );
};

DropzoneAnalyzing.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundComponent: PropTypes.node,
  className: PropTypes.string,
  isAnalyzingLastPart: PropTypes.bool,
  onComplete: PropTypes.func,
  part: PropTypes.object,
};

PartDropzone.Analyzing = DropzoneAnalyzing;

export default PartDropzone;
