import { get } from 'lodash';

import { api } from 'fr-shared/api';

export const BILLING_CYCLE = {
  MONTHLY: 'Monthly',
  ANNUALLY: 'Annually',
};

export const SUBSCRIPTION = {
  BASIC: 'Basic',
  PRO: 'Pro',
};

export const getFormattedPricingBasedOffSelectedPlan = (selectedPlan: string, plans: Plan[]) => {
  let monthlyPrice;
  let annualPrice;
  let totalAnnualPrice;
  get(
    plans.find((p: Plan) => p.name === selectedPlan),
    'versions'
  ).map((planVersion: PlanVersion) => {
    if (planVersion.billing_cycle === BILLING_CYCLE.MONTHLY) {
      monthlyPrice = `$${parseInt(planVersion.price.amount)}`;
    } else if (planVersion.billing_cycle === BILLING_CYCLE.ANNUALLY) {
      totalAnnualPrice = planVersion.price.formatted;
      annualPrice = `$${parseInt(planVersion.price.amount) / 12}`;
    }
  });

  return { monthlyPrice, annualPrice, totalAnnualPrice };
};

export const getPricingBasedOffSelectedPlan = (selectedPlan: string, plans: Plan[]) => {
  let monthlyPrice;
  let annualPrice;
  let totalAnnualPrice;
  get(
    plans.find((p: Plan) => p.name === selectedPlan),
    'versions',
    []
  ).map((planVersion: PlanVersion) => {
    if (planVersion.billing_cycle === BILLING_CYCLE.MONTHLY) {
      monthlyPrice = planVersion.price.amount;
    } else if (planVersion.billing_cycle === BILLING_CYCLE.ANNUALLY) {
      totalAnnualPrice = planVersion.price.amount;
      annualPrice = `${parseInt(planVersion.price.amount) / 12}`;
    }
  });

  return { monthlyPrice, annualPrice, totalAnnualPrice };
};

export const getPlanIdByName = (planName: string, plans: Plan[]) => {
  const filteredPlan = plans.find(({ name }) => name === planName);
  return filteredPlan.id;
};

export const createOrCancelPlan = (
  planType: string,
  subscription: any,
  currentPlanId?: number
) => {
  return planType === SUBSCRIPTION.BASIC
    ? api.put(`/saas/subscriptions/${currentPlanId}/cancel`)
    : api.post(`/saas/subscriptions`, { subscription: subscription });
};
