import React from 'react';

import { IconFont, classNames } from 'fr-shared/components';

import { Card, ContactUs, ContactUsType } from 'portal/components';

import styles from './AnalysisFail.module.css';

const AnalysisFail = () => {
  return (
    <div className={classNames([styles.PageStyles, styles.AnalysisFail])}>
      <Card border={true} className={styles.Card} size="lg">
        <div>
          <header className="flex justify-content-between align-items-center mb-2">
            <h1 className="font-size-lg">We couldn’t analyze your files</h1>
            <IconFont name="error-triangle" className="text-white" />
          </header>
          <p className="font-size-md mb-7">
            Sorry. We couldn’t analyze your part designs because they aren’t STL files. We tried
            to convert them for you but something went wrong.
          </p>
          <footer className={classNames(['flex', 'justify-content-end'])}>
            <ContactUs
              outline
              className={classNames(['px-4'])}
              linkType={ContactUsType['button']}
            >
              Contact us
            </ContactUs>
          </footer>
        </div>
      </Card>
    </div>
  );
};

export default AnalysisFail;
