import { QUOTE_STATES } from 'fr-shared/lib/quotes';

export function getQuoteStateLabel(quoteState: string): string {
  if (isQuoteAcceptance(quoteState)) {
    return 'Ready to view';
  }
  return quoteState;
}

export function isQuoteAcceptance(quoteState: string): boolean {
  return quoteState === QUOTE_STATES.Submitted;
}
