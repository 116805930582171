import { snakeCase } from 'lodash';

import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';

export interface Material {
  colors: Color[];
  density: string;
  elongation_at_max_load_xy: string;
  heat_deflection_at_45_mpa: string;
  name: string;
  tensile_modulus: string;
  tensile_strength_max_load: string;
}

export interface Color {
  name: string;
  id: number;
}

const processCase = (process: string) => {
  return process.split(' ').join('_');
};

export const COMPARE_MATERIAL_PICTO_URL =
  'https://os.fastradius.com/images/studio/CompareMaterials_Picto.svg';

/**
 * Generates a URL to grab images from based on the process & material name
 * @param {string} process manufacturing process name
 * @param {string} name name of material
 * @returns {string} url to image
 */
export const getMaterialImageUrl = (process: string, name: string) => {
  let processName = processCase(process);
  let imageName: string = snakeCase(name);

  if (process === MANUFACTURING_PROCESSES.CNC) {
    imageName = getCNCImageName(name);
  }

  let returnval = `https://os.fastradius.com/images/studio/${processName}_${imageName}.png`;
  return returnval;
};

/**
 * CNC shares the same image for some materials, so we need custom logic to map it
 * @param {string} name material name
 * @returns {string} translated name of image
 */
export const getCNCImageName = (name: string) => {
  if (!name || name === '') return null;

  // These map directly to the common image urls (/CNC_aluminum.png)
  const commonMaterialImages = [
    'aluminum',
    'brass',
    'bronze',
    'copper',
    'steel',
    'titanium',
    'ultem',
  ];

  // If we have a material that matches one of the common images, use it instead
  const materialName =
    commonMaterialImages.find(m => snakeCase(name).includes(m.toLowerCase())) || name;

  return snakeCase(materialName);
};

/**
 *
 * @param {string} process manufacturing process name
 * @param {string} name material name
 * @param {string} color color name
 * @returns {string} url to image
 */
export const getColorImageUrl = (process: string, name: string, color: string) => {
  return `https://os.fastradius.com/images/studio/${process}_${snakeCase(name)}_${snakeCase(
    color
  )}.png`;
};
