import { get, some } from 'lodash';
import moment from 'moment';

import { calculateTotal } from './currency';
import { isExternalProcess } from './manufacturing_process';

export const QUOTE_STATES = {
  Closed: 'Closed',
  Converted: 'Converted to Order',
  Draft: 'Draft',
  Requested: 'Requested',
  Submitted: 'Submitted',
};

export const QUOTE_STATE_COLORS = {
  [QUOTE_STATES.Closed]: 'secondary',
  [QUOTE_STATES.Converted]: 'success',
  [QUOTE_STATES.Draft]: 'secondary',
  [QUOTE_STATES.Submitted]: 'primary',
};

export const QUOTE_STATE_DISPLAY_NAMES = {
  [QUOTE_STATES.Closed]: 'Closed',
  [QUOTE_STATES.Converted]: 'Ordered',
  [QUOTE_STATES.Draft]: 'Saved Draft',
  [QUOTE_STATES.Submitted]: 'Submitted',
};

export const QUOTE_STATE_DATE_LABELS = {
  [QUOTE_STATES.Closed]: 'Closed',
  [QUOTE_STATES.Converted]: 'Ordered',
  [QUOTE_STATES.Draft]: 'Last Update',
  [QUOTE_STATES.Submitted]: 'Submitted',
};

export const getQuoteStateChangeDate = quote => {
  const date = getQuoteStateTimestamp(quote);

  return moment(date).format('MM/DD/YY, h:mm A');
};

export const getQuoteStateTimestamp = quote => {
  const date =
    {
      [QUOTE_STATES.Converted]: quote.completed_at,
      [QUOTE_STATES.Closed]: quote.closed_at,
      [QUOTE_STATES.Draft]: quote.date_submitted,
      [QUOTE_STATES.Submitted]: quote.date_submitted,
    }[quote.state] || quote.date_submitted;
  return date;
};

export const getSalesPortalQuoteStateTimestamp = quote => {
  if (quote.is_cart) {
    return quote.submitted_at != null ? quote.submitted_at : quote.inserted_at;
  }
  return getQuoteStateTimestamp(quote);
};

export const calculateLineItemSubtotal = lineItems => {
  const prices = lineItems.map(li => get(li, 'total_price.amount'));
  return calculateTotal(prices);
};

/**
 * Returns true is some line items are not internally made
 * @param {array} lineItems - array of line items with mfg processes
 * @returns boolean
 */
export const hasExternalLineItems = lineItems => {
  return some(lineItems, lineItem => isExternalProcess(lineItem.manufacturing_process?.name));
};
