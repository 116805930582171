import { useTreatments } from '@splitsoftware/splitio-react';
import { useFormikContext } from 'formik';
import { debounce, head, isEmpty, last, pullAt, uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import React, { forwardRef, useContext, useEffect } from 'react';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { useLocation } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Button, Card, Icon } from 'fr-shared/components';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { useLeadTimes } from 'fr-shared/hooks';
import { COSTING_REQUEST_LI_STATES } from 'fr-shared/lib/costing_requests';
import { QUOTE_STATES } from 'fr-shared/lib/quotes';

import { transformCostingRequestSearchResults, transformLineItems } from '../utils/transforms';
import QuoteFormBulkEditor from './QuoteFormBulkEditor';
import { QuoteFormContext } from './QuoteFormContext';
import QuoteFormLineItem from './QuoteFormLineItem';
import QuoteFormLineItemPicker from './QuoteFormLineItemPicker';
import QuoteFormMargins from './QuoteFormMargins';
import QuoteFormPartDetails from './QuoteFormPartDetails';
import QuoteFormPartDutiesTaxesTariffs from './QuoteFormPartDutiesTaxesTariffs';
import QuoteFormSearch from './QuoteFormSearch';

const QuoteLineItem = forwardRef((props, ref) => (
  <div ref={ref}>
    <QuoteFormLineItem {...props} />
  </div>
));

QuoteLineItem.displayName = 'QuoteLineItem';

const QuoteFormLineItems = ({ readonly, onLineItemsChanged }) => {
  const formik = useFormikContext();
  const {
    setValuesAndPushToChannel,
    opportunityIdLineItems,
    setOpportunityIdLineItems,
    sortedOpportunityIdLineItems,
  } = useContext(QuoteFormContext);
  const { data: leadTimes } = useLeadTimes();
  const location = useLocation();
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const capLineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'Build Pack' : 'Line Item';

  const lineItems = formik.values.line_items;
  const isSubmitted = formik.values.state === QUOTE_STATES.Submitted;
  const showBulkEditor = !isEmpty(lineItems) && !isSubmitted;
  const selectedPartQuantityIds = location?.state?.selectedPartQuantityIds;

  useEffect(() => {
    if (formik.values.opportunity_id && leadTimes) {
      api
        .get('/costing_request_line_items', {
          params: {
            page_size: 1000,
            exact_opportunity_id: formik.values.opportunity_id,
            state: COSTING_REQUEST_LI_STATES.Completed,
          },
        })
        .then(crliRes => {
          const costingRequestID = last(crliRes.data)?.costing_request_id;
          api.get(`/costing_requests/${costingRequestID}`).then(crRes => {
            const costingRequest = {
              customer: crRes.data.customer,
              customer_contact: crRes.data.customer_contact,
              customer_contact_id: crRes.data.customer_contact_id,
              customer_id: crRes.data.customer_id,
            };
            const transformedLineItems = transformLineItems(
              crliRes.data,
              leadTimes,
              costingRequest
            );
            setOpportunityIdLineItems(transformedLineItems);
          });
        });
    }
  }, [formik.values.opportunity_id, setOpportunityIdLineItems, leadTimes]);

  // sets the selectedLineItems from a converted costing request
  useEffect(() => {
    if (!isEmpty(selectedPartQuantityIds) && !isEmpty(opportunityIdLineItems)) {
      const selectedLineItems = opportunityIdLineItems.filter(li =>
        selectedPartQuantityIds.includes(li.supplier_cost_part_quantity_id)
      );

      handleSelectLineItems(selectedLineItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartQuantityIds, opportunityIdLineItems]);

  const handleSearchOptions = debounce((opportunityIdInput, callback) => {
    api
      .get('/costing_requests', {
        params: {
          state: 'Completed',
          opportunity_id: opportunityIdInput,
        },
      })
      .then(res => {
        const formattedResults = transformCostingRequestSearchResults(res.data);
        callback(formattedResults);
      });
  }, 500);

  const handleSearchChange = result => {
    formik.setValues({
      ...formik.values,
      opportunity_id: result.opportunity_id,
      public_id: result.public_id,
      line_items: [],
    });
  };

  const handleRemoveLineItem = index => {
    const newLines = [...lineItems];
    pullAt(newLines, index);
    setValuesAndPushToChannel({ ...formik.values, line_items: newLines });
    onLineItemsChanged();
  };

  const handleSelectLineItems = newLineItems => {
    const lineItem = head(newLineItems);
    const lineItemsWithUniqueID = newLineItems.map(li => ({
      uuid: li.uuid ?? uniqueId(),
      ...li,
    }));
    setValuesAndPushToChannel({
      ...formik.values,
      customer_id: lineItem.customer_id,
      customer_contact_id: lineItem.customer_contact_id,
      line_items: [...lineItems, ...lineItemsWithUniqueID],
      supplier_location: lineItem.supplier_location,
    });
    onLineItemsChanged();
  };

  const handleClearOpportunity = () => {
    formik.setValues({ ...formik.values, opportunity_id: '', public_id: '', line_items: [] });
    setOpportunityIdLineItems([]);
  };

  const unselectedIdLineItems = sortedOpportunityIdLineItems.filter(li => {
    const id = 'supplier_cost_part_quantity_id';
    return !lineItems.find(selectedLi => selectedLi[id] === li[id]);
  });

  const onChangePosition = (dir, lineItem) => {
    const last = lineItems.length - 1;
    const index = lineItems.indexOf(lineItem);

    if ((index === 0 && dir === 'UP') || (index === last && dir === 'DOWN')) {
      // noop
    } else if (dir === 'UP') {
      lineItems[index] = lineItems[index - 1];
      lineItems[index - 1] = lineItem;
      const orderedLineItems = lineItems.map((li, index) => ({ ...li, position: index }));
      formik.setFieldValue('line_items', orderedLineItems);
    } else if (dir === 'DOWN') {
      lineItems[index] = lineItems[index + 1];
      lineItems[index + 1] = lineItem;
      const orderedLineItems = lineItems.map((li, index) => ({ ...li, position: index }));
      formik.setFieldValue('line_items', orderedLineItems);
    }
  };

  const expandOrCollapse = shouldExpand =>
    formik.setFieldValue(
      'line_items',
      lineItems.map(li => ({ ...li, open: shouldExpand }))
    );

  return (
    <>
      <Card>
        <Card.Header className="border-bottom-0">
          {!isSubmitted
            ? 'Enter the Opportunity ID associated with this quote'
            : 'Opportunity ID associated with this quote'}
        </Card.Header>

        <Card.Body className="p-0">
          {formik.values.opportunity_id ? (
            <QuoteFormLineItemPicker
              readonly={readonly}
              onClearOpportunity={handleClearOpportunity}
              onSelectLineItems={handleSelectLineItems}
              opportunityId={formik.values.opportunity_id}
              opportunityIdLineItems={opportunityIdLineItems}
              unselectedIdLineItems={unselectedIdLineItems}
            />
          ) : (
            <QuoteFormSearch
              onSearchOptions={handleSearchOptions}
              onChange={handleSearchChange}
              value={formik.values.opportunity_id}
            />
          )}
        </Card.Body>
      </Card>
      {!isEmpty(lineItems) && (
        <div className="flex flex-col">
          <h3 className="mb-0">Quote {capLineItemName}s</h3>
          <div className="flex ml-auto align-items-center">
            <Button
              size="sm"
              color="link"
              className="mr-2"
              onClick={() => expandOrCollapse(true)}
            >
              <Icon name="expand" className="mr-2" />
              Expand All
            </Button>
            <Button color="link" size="sm" onClick={() => expandOrCollapse(false)}>
              <Icon name="minus-square" right />
              Collapse All
            </Button>
          </div>
        </div>
      )}
      {showBulkEditor && <QuoteFormBulkEditor />}
      <Flipper flipKey={lineItems.map(li => li.id || li.uuid).join('')}>
        {lineItems.map((lineItem, index) => (
          <Flipped
            key={`selected-${lineItem.id || lineItem.uuid}`}
            flipId={`selected-${lineItem.id || lineItem.uuid}`}
          >
            {flippedProps => (
              <div {...flippedProps}>
                <QuoteLineItem
                  readonly={readonly}
                  index={index}
                  lineItem={lineItem}
                  onRemoveLineItem={() => handleRemoveLineItem(index)}
                  showBulkCheckbox={showBulkEditor}
                  showPricing={true}
                  changePosition={onChangePosition}
                >
                  <QuoteFormPartDetails index={index} lineItem={lineItem} />
                  <QuoteFormPartDutiesTaxesTariffs index={index} lineItem={lineItem} />

                  <QuoteFormMargins readonly={readonly} index={index} lineItem={lineItem} />
                </QuoteLineItem>
              </div>
            )}
          </Flipped>
        ))}
      </Flipper>
    </>
  );
};

QuoteFormLineItems.propTypes = {
  formik: PropTypes.object,
  readonly: PropTypes.bool,
  selectedOpportunity: PropTypes.object,
  setSelectedOpportunity: PropTypes.func,
  onLineItemsChanged: PropTypes.func,
};

export default QuoteFormLineItems;
