import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';

import { IconFont } from 'fr-shared/components';

import styles from './EmptyStateCard.module.css';

interface EmptyStateCardProps {
  cardHeader: string;
  className?: string;
  iconFont: string;
  mainText: string;
  children: ReactNode;
}

export const EmptyStateCard = ({
  cardHeader,
  children,
  className = '',
  iconFont,
  mainText,
}: EmptyStateCardProps) => {
  return (
    <div
      className={`${styles.EmptyStateCard} ${className} justify-content-between align-items-center`}
    >
      <div className="flex items-center">
        <IconFont name={`icon-${iconFont}`} className={`icon-${iconFont}`} />
        <div>
          <h3>{cardHeader}</h3>
          <div className="text-gray">{mainText}</div>
        </div>
      </div>
      {children}
    </div>
  );
};

EmptyStateCard.propTypes = {
  cardHeader: PropTypes.string,
  className: PropTypes.string,
  iconFont: PropTypes.string,
  mainText: PropTypes.string,
  children: PropTypes.node,
};

export default EmptyStateCard;
