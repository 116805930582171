import { get } from 'lodash';
import React, { useEffect, useState } from 'react';

import { IconFont, classNames } from 'fr-shared/components';
import { AUTOCOSTABLE_MFG_PROCESSES } from 'fr-shared/lib/manufacturing_process';

import { Button, TableBodyCell, TableBodyRow } from 'portal/components';

import styles from '../PartTrackerModal.module.css';
import { ProductionRowListProps, ProductionRowsProps } from '../types';
import { formatDate } from '../utils';

const NAMES_AND_TOOLTIPS = {
  released_started_at: {
    name: 'Released to floor',
    tooltip: {
      default: 'Released to floor means your item will go into production very soon.',
      additive: 'Released to floor means your item will be printed very soon.',
    },
  },
  printing_started_at: {
    name: 'Printing',
    tooltip: {
      default: 'Printing means your item is currently being 3D-printed.',
    },
  },
  thermal_started_at: {
    name: 'Thermal processing',
    tooltip: {
      default:
        'Thermal processing can mean two things. For Digital Light Synthesis (DLS), it means your item is being strengthened with heat treatment. For Multi Jet Fusion (MJF), it means your item is going through controlled cooling.',
    },
  },
  post_started_at: {
    name: 'Post production',
    tooltip: {
      default: 'Post processing means your item is receiving some essential finishing touches.',
    },
  },
  qc_started_at: {
    name: 'Quality control',
    tooltip: {
      default:
        'Quality control means your item is going through our final detailed quality checks.',
    },
  },
};
const productionOrder = [
  'released_started_at',
  'printing_started_at',
  'thermal_started_at',
  'post_started_at',
  'qc_started_at',
];

const ProductionRows = ({
  isBeingShipped,
  isInProduction,
  manufacturingName,
  workOrderStateSummary,
}: ProductionRowsProps) => {
  const [prodRowsWithData, setProdRowsWithData] = useState([]);
  const getTooltip = (processName: any) => {
    const isAdditive = AUTOCOSTABLE_MFG_PROCESSES.includes(manufacturingName);
    const additiveTooltip = processName.tooltip.additive || processName.tooltip.default;
    return isAdditive ? additiveTooltip : processName.tooltip.default;
  };

  useEffect(() => {
    // Let's note which fields have values so we know when to add a specific TR class that makes it seem like the table is divided
    const arr = [];
    for (const property in workOrderStateSummary) {
      const propVal = get(workOrderStateSummary, property);
      if (propVal) arr.push(propVal);
    }
    setProdRowsWithData(arr);
  }, [workOrderStateSummary]);

  // If not in production state, return null
  if (!isInProduction) return null;

  const ProductionRowsList = ({ workOrderStateSummary }: ProductionRowListProps) => {
    // It's possible that the Circular Graph shows it's in production even though it hasn't reached any of the 5 production states
    // represented in the modal. We'll show a default message in this scenario per product

    if (prodRowsWithData.length <= 0) {
      return (
        <TableBodyRow
          className={classNames([
            styles.TableThinRows,
            isBeingShipped && styles.TableSectionDivider,
          ])}
        >
          <TableBodyCell>Work order submitted</TableBodyCell>
          <TableBodyCell></TableBodyCell>
          <TableBodyCell></TableBodyCell>
        </TableBodyRow>
      );
    }

    return (
      <>
        {productionOrder.map((val, index) => {
          const processRowInfo = get(workOrderStateSummary, val);
          if (!processRowInfo) return;

          const [orderDate, orderTime] = formatDate(processRowInfo);
          const processName = get(NAMES_AND_TOOLTIPS, val);

          return (
            <TableBodyRow
              key={index}
              className={classNames([
                styles.TableThinRows,
                prodRowsWithData.length === index + 1 &&
                  isBeingShipped &&
                  styles.TableSectionDivider,
              ])}
            >
              <TableBodyCell>
                <span>
                  {processName.name}{' '}
                  <Button
                    className={styles.BtnTooltip}
                    tooltip={getTooltip(processName)}
                    color="link"
                  >
                    <IconFont name="info-circle" />
                  </Button>
                </span>
              </TableBodyCell>
              <TableBodyCell>
                <span>{orderDate}</span> {orderTime}
              </TableBodyCell>
              <TableBodyCell></TableBodyCell>
            </TableBodyRow>
          );
        })}
      </>
    );
  };

  return (
    <>
      <TableBodyRow className={styles.TablePostSectionDivider}>
        <TableBodyCell>
          <strong>
            <span>In production</span>
          </strong>
        </TableBodyCell>
        <TableBodyCell></TableBodyCell>
        <TableBodyCell></TableBodyCell>
      </TableBodyRow>

      <ProductionRowsList workOrderStateSummary={workOrderStateSummary} />
    </>
  );
};

export default ProductionRows;
