import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { keyboardDown } from 'fr-shared/utils';

import { Card, PartImage, classNames } from 'portal/components';
import { date } from 'portal/lib/date';

import cardStyles from '../Card.module.css';
import styles from './PartCard.module.css';

export const PartCard = ({
  selected: controlledSelected,
  logOnSelection = () => {},
  logOnClickPart = () => {},
  onClick,
  onSelection,
  part,
}) => {
  const [selected, setSelected] = useState(false);

  const handleSelected = () => {
    const isSelected = !selected;
    logOnSelection();
    setSelected(isSelected);
    onSelection();
  };
  const handleOnPartClick = () => {
    logOnClickPart();
    onClick();
  };

  const isSelected = controlledSelected !== null ? controlledSelected : selected;

  return (
    <Card
      className={classNames({ [cardStyles.selected]: isSelected, [styles.PartCardHeight]: true })}
      clickable
    >
      {onSelection && (
        <div
          className={classNames(['part-selection', styles.Selection])}
          data-testid="part-card-selector-circle"
          onClick={handleSelected}
          onKeyDown={evt => keyboardDown(evt, 'Enter', handleSelected)}
          role="button"
          tabIndex={0}
        >
          <div className={classNames(['part-selection-circle', styles.SelectionCircle])} />
        </div>
      )}
      <div
        onClick={handleOnPartClick}
        onKeyDown={evt => keyboardDown(evt, 'Enter', handleOnPartClick)}
        role="button"
        tabIndex={0}
      >
        <div className="flex align-items-center justify-content-center mt-5">
          <PartImage
            size="md"
            src={part?.current_revision?.screenshot}
            className={classNames([
              styles.PartCardImage,
              styles.PartCardImageBackdrop,
              styles.Backdrop,
            ])}
          />
        </div>

        <div className="flex align-items-center justify-content-between font-size-sm p-3">
          <div className={styles.NameContainer}>
            <div className="text-gray">Part name</div>
            <div className={`${styles.Name} text-coolGray-100 text-sm`}>{part.name}</div>
          </div>

          <div>
            <div className="text-gray">Last updated</div>
            <div className="text-coolGray-100 text-sm">
              {date(part.updated_at || part.inserted_at)}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

PartCard.propTypes = {
  logOnClickPart: PropTypes.func,
  logOnSelection: PropTypes.func,
  onSelection: PropTypes.func,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  part: PropTypes.shape({
    current_revision: PropTypes.shape({
      screenshot: PropTypes.string,
    }),
    id: PropTypes.number.isRequired,
    inserted_at: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }).isRequired,
};

export default PartCard;
