import React from 'react';

import { Button, IconFont } from 'fr-shared/components';
import { usePortalSubscription } from 'fr-shared/hooks';

import { HelpModal, SubscriptionUpgradeCard, classNames } from 'portal/components';

import Panel from '../../components/Panel/Panel';
import styles from './DfmPanel.module.css';

interface DfmPanelProps {
  className?: string;
  onClose: () => void;
  pushToEvaluate?: () => void;
  selectedDfmCheck: ManufacturabilityCheck | null;
}

const DfmPanel = ({
  className = '',
  onClose,
  pushToEvaluate,
  selectedDfmCheck,
}: DfmPanelProps) => {
  const isPortalSubscribed = usePortalSubscription();

  return (
    <Panel className={classNames([styles.DfmPanel, className])}>
      <div className="flex justify-content-end">
        <Button onClick={onClose} className="modal-close" color="secondary">
          <IconFont name="close" />
        </Button>
      </div>

      <div className="mt-0 mb-4">
        <h5 className="pb-2 mb-4 flex items-center leading-none border-bottom font-medium">
          <IconFont
            name="circle"
            className={`mr-1 text-xl ${
              selectedDfmCheck.type === 'error' ? 'text-error-400' : 'text-warning-400'
            }`}
          />
          {selectedDfmCheck.label}
        </h5>

        {!isPortalSubscribed && selectedDfmCheck.premium ? (
          <SubscriptionUpgradeCard analyticsContext="DFM Panel" />
        ) : (
          <div>
            <div className="mb-4">
              <div className="text-coolGray-100">What&apos;s the issue?</div>
              <p className="text-coolGray-300">{selectedDfmCheck.description}</p>
            </div>

            {selectedDfmCheck.impact && (
              <div className="mb-4">
                <div className="text-coolGray-100">What&apos;s the impact?</div>
                <p className="text-coolGray-300">{selectedDfmCheck.impact}</p>
              </div>
            )}

            <div>
              <div className="text-coolGray-100">How can you fix it?</div>
              <p className="text-coolGray-300">{selectedDfmCheck.suggestion}</p>
            </div>
          </div>
        )}
      </div>

      <Panel.Bottom>
        {pushToEvaluate && (
          <div className="pb-2 mb-2 border-0 border-b border-coolGray-600 border-solid">
            <Button className="w-full text-lg" onClick={pushToEvaluate}>
              View issue on the part
            </Button>
          </div>
        )}
        <HelpModal
          userAnalyticsPrefix="Studio"
          action={
            <Button className="text-lg" block outline>
              Need help?
            </Button>
          }
        />
      </Panel.Bottom>
    </Panel>
  );
};

export default DfmPanel;
