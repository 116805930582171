import auth0 from 'auth0-js';
import Cookie from 'js-cookie';

import { api } from 'fr-shared/api';
import { API_DOMAIN, API_PROTOCOL_SUFFIX, AUTH0_CLIENT_ID } from 'fr-shared/env';

const ROUTE_KEY = 'SAVED_ROUTE';

export const logout = (ignoreRedirect = false) => {
  return api.post('/logout').then(() => {
    if (ignoreRedirect) return;
    window.location = '/';
  });
};

export class Auth0 {
  /* eslint-disable import/no-named-as-default-member */
  auth0 = new auth0.WebAuth({
    domain: 'fastradius.auth0.com',
    clientID: AUTH0_CLIENT_ID,
    redirectUri: `http${API_PROTOCOL_SUFFIX}://${API_DOMAIN}/auth/auth0/callback`,
    responseType: 'code',
    leeway: 5,
  });

  loginWithGoogle = () => {
    this.auth0.authorize({ connection: 'google-oauth2' });
  };

  loginWithMicrosoft = () => {
    this.auth0.authorize({ connection: 'windowslive' });
  };
}

export const savePreAuthRoute = location => {
  localStorage.setItem(ROUTE_KEY, JSON.stringify(location));
};

export const getAndClearPreAuthRoute = () => {
  let savedRoute;

  /**
   * This bit of logic was added to make it possible for an unauthenticated
   * user from an external site (e.g. supplier portal) to be redirected
   * once logged in. It should use the cookie variable if exists; if not
   * it will use the local storage variable.
   **/
  if (localStorage.getItem(ROUTE_KEY)) {
    savedRoute = JSON.parse(localStorage.getItem(ROUTE_KEY));
    localStorage.removeItem(ROUTE_KEY);
  }

  if (Cookie.get(ROUTE_KEY)) {
    savedRoute = { external_link: Cookie.get(ROUTE_KEY) };
    Cookie.remove(ROUTE_KEY);
  }

  return savedRoute;
};
