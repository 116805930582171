import PropTypes from 'prop-types';
import React from 'react';

import {
  DocumentsAlert,
  DocumentsList,
  DocumentsUpload,
  FormFieldBase,
} from 'fr-shared/components';
import { DocumentsContext, DocumentsProvider } from 'fr-shared/context';

import UploadContainer from './UploadContainer';

const FormDocuments = ({
  acceptedFileTypes,
  children,
  fieldName,
  fieldPrefix = null,
  maxSizeInBytes,
  readonly,
  s3Endpoint,
  showAlerts = false,
  showDate,
  showUploadContainer = false,
  ...formProps
}) => (
  <DocumentsProvider
    acceptedFileTypes={acceptedFileTypes}
    fieldPrefix={fieldPrefix}
    fieldName={fieldName}
    maxSizeInBytes={maxSizeInBytes}
    readonly={readonly}
    s3Endpoint={s3Endpoint}
    showDate={showDate}
  >
    <DocumentsContext.Consumer>
      {({ fieldError, isUploadError, rejectedFiles }) => {
        const error = [...(fieldError ?? [])];

        if (rejectedFiles.length > 0) {
          error.push(
            `${rejectedFiles[0].name} cannot be uploaded. The size limit is ${
              maxSizeInBytes / 1000000
            }MB and the accepted file types are ${acceptedFileTypes.join(', ')}.`
          );
        }

        return (
          <FormFieldBase error={error} readonly={readonly} {...formProps} isStacked>
            {showAlerts && <DocumentsAlert />}
            <DocumentsList />
            <DocumentsUpload>{children}</DocumentsUpload>
            {showUploadContainer && (
              <UploadContainer hasError={isUploadError || error.length > 0} />
            )}
          </FormFieldBase>
        );
      }}
    </DocumentsContext.Consumer>
  </DocumentsProvider>
);

FormDocuments.propTypes = {
  acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  fieldName: PropTypes.string.isRequired,
  fieldPrefix: PropTypes.string,
  maxSizeInBytes: PropTypes.number,
  readonly: PropTypes.bool,
  s3Endpoint: PropTypes.string,
  showAlerts: PropTypes.bool,
  showDate: PropTypes.bool,
  showUploadContainer: PropTypes.bool,
};

export default FormDocuments;
