import * as Sentry from '@sentry/react';
import { get, isEmpty, last, map, omit, pick, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import {
  Breadcrumb,
  ButtonLink,
  GlobalErrorBoundary,
  Helmet,
  Link,
  NotFound,
} from 'fr-shared/components';
import { AlertContext, UserContext } from 'fr-shared/context';
import { useChannel } from 'fr-shared/hooks';

import WorkOrderForm from './components/WorkOrderForm';
import { transformPartFailureToLineItem } from './components/utils/partFailuresTransforms';

const WORK_ORDER_UPDATE_MESSAGE = 'work_order_update';

const transformWorkOrder = order => {
  // this makes it so we can use data from the server
  // and the same components. by deleting id/quantity, the
  // line items can be edited
  order.line_items = order.line_items.map(o => {
    const line = o.order_line_item;
    if (line) {
      delete line.quantity;
      delete line.id;
    }
    return { ...o, ...line };
  });

  return order;
};

const WorkOrderEdit = ({ match, history, location }) => {
  const [initialValues, setInitialValues] = useState();
  const [travelerIsLoading, setTravelerIsLoading] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { user } = useContext(UserContext);
  const { setAlert } = useContext(AlertContext);
  const { id, cloneId, orderId } = match.params;
  const disposition = get(location, 'state.disposition', '');
  const partFailures = get(location, 'state.partFailures');

  const getWorkOrder = useCallback(() => {
    const workOrderId = cloneId ? cloneId : id;
    setIsLoading(true);
    setHasError(false);
    api
      .get(`/work_orders/${workOrderId}`)
      .then(res => {
        const initialTransformedValues = transformWorkOrder(res.data);

        let state = initialTransformedValues.state;

        if (cloneId) state = 'Draft';
        else if (disposition === 'rework') state = 'Post-Processing';

        const line_items = !isEmpty(partFailures)
          ? partFailures.map(transformPartFailureToLineItem)
          : initialTransformedValues.line_items.map(lineItem => ({
              ...lineItem,
              part_failures: cloneId ? [] : lineItem.part_failures,
            }));

        let transformedValues = cloneId
          ? omit(initialTransformedValues, [
              'material_lot_number_a',
              'material_lot_number_b',
              'material_lot_number_c',
              'print_id',
              'printer',
              'printer_id',
            ])
          : initialTransformedValues;

        setInitialValues({ ...transformedValues, id, state, line_items });
      })
      .catch(error => {
        if (error.response?.status !== 404) {
          setHasError(true);
          Sentry.captureMessage('Work Order GET Failed');
          Sentry.setExtra('work_order_id', workOrderId);
          Sentry.setExtra('error', error);
        }
      })
      .finally(() => setIsLoading(false));
  }, [cloneId, disposition, id, partFailures]);

  useEffect(() => {
    getWorkOrder();
  }, [getWorkOrder]);

  const [updateChannel] = useChannel(`work_order:${id}`);

  useEffect(() => {
    if (!updateChannel) return;

    const callbackId = updateChannel.on(WORK_ORDER_UPDATE_MESSAGE, getWorkOrder);
    return () => updateChannel.off(WORK_ORDER_UPDATE_MESSAGE, callbackId);
  }, [updateChannel, getWorkOrder]);

  const handleTravelerRequested = (values, formik) => {
    const workOrder = values;
    delete workOrder.state_events;

    setTravelerIsLoading(true);

    // first we save the order so the traveler generates with recent data
    api
      .put(`/work_orders/${id}`, { work_order: omit(workOrder, ['num_of_build_plates']) })
      .then(res => {
        setInitialValues(transformWorkOrder(res.data));
        api
          .post('/traveler', { traveler: { work_order_id: res.data.id } })
          .then(res => {
            window.open(res.data.path);
            setAlert({
              autoClose: false,
              message: (
                <>
                  Work order was saved successfully!{' '}
                  {res.data.path && (
                    <Link to={{ pathname: res.data.path }} openNewWindow>
                      Click here to download Traveler.
                    </Link>
                  )}
                </>
              ),
            });
          })
          .catch(() => {
            setAlert({
              color: 'danger',
              message:
                'There was an error generating the traveler. Please refresh the page and try again',
            });
          })
          .finally(() => {
            setTravelerIsLoading(false);
          });
      })
      .catch(err => {
        setTravelerIsLoading(false);
        if (err?.response?.data?.messages) {
          const { errors, messages } = err.response.data;
          formik.setErrors({
            server: messages,
            ...errors,
          });
        } else {
          setAlert({
            color: 'danger',
            message: 'An unknown error occurred. Please refresh the page and try again',
          });
        }
      });
  };

  const handleCloneRequested = () => {
    setIsCloning(true);
  };

  const handleDeleteWorkOrder = () => {
    api
      .put(`/work_orders/${id}`, { work_order: { state: 'Deleted' } })
      .then(() => {
        history.push('/admin/work_orders');
      })
      .catch(() =>
        setAlert({
          color: 'danger',
          message: 'An unknown error occurred. Please refresh the page and try again',
        })
      );
  };

  const handleSubmit = (values, formik) => {
    const id = values.id;

    let workOrder = { ...values };

    // we need to remove state_events from the work order being sent to the server
    delete workOrder.state_events;

    if (workOrder.state === 'Draft') {
      workOrder.state = 'Created';
    }

    workOrder.line_items = workOrder.line_items.map(li => {
      return pick(li, [
        'id',
        'order_line_item_id',
        'order_line_item',
        'quantity',
        'spawned_from_part_failure_id',
      ]);
    });

    const handleApiError = err => {
      if (err?.response?.data?.messages) {
        const { errors, messages } = err.response.data;
        formik.setErrors({
          server: messages,
          ...errors,
        });
      } else {
        setAlert({
          color: 'danger',
          message: 'An unknown error occurred. Please refresh the page and try again',
        });
      }
    };

    const linkToWorkOrder = id => {
      return <Link to={`/admin/work_orders/${id}`}>{id}</Link>;
    };

    const rangeOfClonedWorkOrders = ids => {
      if (!ids.length || !Array.isArray(ids)) {
        return null;
      }

      if (ids.length > 1) {
        return (
          <>
            (#{linkToWorkOrder(ids[0])} - #{linkToWorkOrder(last(ids))})
          </>
        );
      }

      return <>(#{linkToWorkOrder(ids[0])})</>;
    };

    const handleCloneSuccess = async ([originalId, firstCloneId]) => {
      let { num_of_build_plates: totalQuantity } = workOrder;

      let workOrderIds = [parseInt(firstCloneId)];

      if (totalQuantity > 1) {
        await api.post(`work_orders/${id}/clone`, { quantity: totalQuantity - 1 }).then(res => {
          workOrderIds.push(...map(sortBy(res.data, 'id'), 'id'));
        });
      }

      setAlert({
        autoClose: false,
        message: (
          <div className="flex align-items-center justify-content-between">
            <span>
              Successfully created {totalQuantity} {totalQuantity > 1 ? 'copies' : 'copy'} of Work
              Order #{linkToWorkOrder(originalId)}, {rangeOfClonedWorkOrders(workOrderIds)}
            </span>
            <ButtonLink
              onClick={() => {
                const linkBase = `${window.location.origin}/admin/work_orders/`;
                const copyMsg = `${workOrderIds.map(id => `${linkBase}${id}`).join('\n')}`;

                navigator.clipboard.writeText(copyMsg);
              }}
              className="p-0 mr-2"
            >
              Copy Work Order ID{totalQuantity > 1 ? 's' : ''}
            </ButtonLink>
          </div>
        ),
      });
      setIsCloning(false);
    };

    const handleCloneError = err => {
      console.error('Could not clone work order: %o', err);
      setAlert({
        autoClose: false,
        color: 'danger',
        message: 'Could not clone Work Order, error while saving first clone',
      });
      setIsCloning(false);
    };

    api
      .put(`/work_orders/${id}`, { work_order: omit(workOrder, ['num_of_build_plates']) })
      .then(res => {
        // Handle navigation if a work order is from a part failure
        if (!isEmpty(disposition) && !isEmpty(partFailures)) {
          (disposition === 'rework'
            ? api.put('/part_failures/bulk_update', {
                ids: partFailures.map(pf => pf.id),
                part_failure_update: {
                  resolved_at: new Date(),
                  action: 'Rework',
                  resolved_by_user_id: user.id,
                },
              })
            : Promise.resolve()
          )
            .then(() => {
              history.push({
                pathname: `/admin/qc/${disposition}`,
                state: {
                  partFailureIds: partFailures.map(pf => pf.id),
                  workOrderId: parseInt(id),
                },
              });
            })
            .catch(handleApiError);
        }
        // Otherwise update page state on success
        else {
          setAlert({ message: 'Successfully updated Work Order' });

          if (cloneId) {
            history.push(`/admin/work_orders/${id}`);
          } else {
            setInitialValues(transformWorkOrder(res.data));
          }

          return Promise.resolve([cloneId, id]);
        }
      })
      .then(args => {
        isCloning ? handleCloneSuccess(args) : () => {};
      })
      .catch(isCloning ? handleCloneError : handleApiError);
  };

  if (isLoading) return null;
  if (hasError) return <GlobalErrorBoundary />;
  if (!initialValues) return <NotFound />;

  return (
    <>
      <Helmet title={`#${id} Work Order`} />
      <Breadcrumb to="/admin/work_orders">Work Orders</Breadcrumb>
      <Breadcrumb to="/admin/work_orders/new">Work Order #{id}</Breadcrumb>

      {initialValues && (
        <WorkOrderForm
          travelerLoading={travelerIsLoading}
          initialValues={initialValues}
          onTravelerRequested={handleTravelerRequested}
          handleDeleteWorkOrder={handleDeleteWorkOrder}
          handleSubmit={handleSubmit}
          onCloneRequested={handleCloneRequested}
          orderId={orderId}
          locationState={location.state}
        />
      )}
    </>
  );
};

WorkOrderEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      cloneId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      orderId: PropTypes.number,
      path: PropTypes.string,
      url: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WorkOrderEdit;
