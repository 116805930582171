import React, { useCallback } from 'react';

import { shippingMethodToDescription, shippingMethodToDisplayName } from 'fr-shared/lib/shipping';

interface ShippingChoiceProps {
  checked?: boolean;
  className?: string;
  price: { formatted: string };
  id: number;
  onChange?: (id: number, shippingMethod: string) => any;
  readonly?: boolean;
  service: ShippingMethod;
}

const ShippingChoice = ({
  checked = false,
  className = '',
  price,
  id,
  onChange = () => {},
  readonly = false,
  service,
}: ShippingChoiceProps) => {
  const htmlId = `shipping-estimate-${id}`;

  const handleChange = useCallback(() => {
    onChange(id, service);
  }, [id, service, onChange]);

  return (
    <div className={`row ${className}`}>
      {!readonly && (
        <div className="col-auto form-check pr-0">
          <input type="radio" id={htmlId} checked={checked} onChange={handleChange} />
        </div>
      )}
      <div className="col">
        <div className="mb-1">
          <label className="mb-0" htmlFor={htmlId}>
            <span>{shippingMethodToDisplayName(service)}</span>
            <b className="ml-2">{price.formatted}</b>
          </label>
        </div>
        <p className="font-size-sm">{shippingMethodToDescription(service)}</p>
      </div>
    </div>
  );
};

export default ShippingChoice;
