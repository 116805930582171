/* eslint-disable react/display-name,react/prop-types */
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Checkbox, FirstOrderBadge, Link, Table } from 'fr-shared/components';
import { keyboardDown } from 'fr-shared/utils';

import { DISPOSITION_TABS } from './utils/reviewTabs';

const openImageInNewTab = (src, workOrderId) => {
  const imageTab = window.open();
  const style = 'max-width: 100%; max-height: 95vh; height: auto;';
  imageTab.document.write(`
    <html>
      <head>
        <title>Part Failure from WO ${workOrderId}</title>
      </head>
      <body>
        <img style="${style}" src=${src} />
      </body>
    </html>
  `);
};

const ReviewTable = ({
  data,
  pages,
  onPageChange,
  onSortedChange,
  onPartSelectionChange,
  dispositionTab,
  loading,
}) => {
  return (
    <Table
      manual
      loading={!!loading}
      data={data}
      pages={pages}
      onPageChange={onPageChange}
      onSortedChange={onSortedChange}
      pageSize={data.length || 20}
      showPagination={true}
      className="bg-white -striped mb-5"
      noDataText="Nothing to review"
      columns={[
        ...(dispositionTab !== 'resolved'
          ? [
              {
                sortable: false,
                Header: '',
                width: 45,
                id: 'selected',
                Cell: row => {
                  const { selected, id } = row.original;
                  return (
                    <Checkbox
                      name={`select-${id}`}
                      srOnlyLabel={true}
                      label={`Select Item ${id}`}
                      value={selected}
                      className="mb-0"
                      onChange={e => {
                        onPartSelectionChange(e.target.checked, id);
                      }}
                    />
                  );
                },
              },
            ]
          : []),
        {
          sortable: false,
          Header: '',
          width: 45,
          expander: true,
          Expander: ({ index, isExpanded, original }) => (
            <Button
              className="expander"
              data-testid={`expand-button-${index}`}
              size="sm"
              color="secondary"
            >
              <span className="sr-only">
                {isExpanded ? 'Collapse' : 'Expand'} Item {original.id}
              </span>
              <i className={`fas fa-chevron-${isExpanded ? 'up' : 'down'}`} />
            </Button>
          ),
        },
        {
          Header: 'Rejection ID',
          accessor: 'id',
        },
        {
          Header: 'Part Name',
          id: 'description',
          accessor: 'work_order_line_item.order_line_item.description',
        },
        {
          Header: 'Rejected',
          id: 'inserted_at',
          accessor: data => moment(data.inserted_at).format('MM/DD/YY'),
        },
        {
          Header: 'Order #',
          id: 'public_id',
          Cell: row => {
            const { id, public_id, is_first_order } =
              row.original.work_order_line_item.order_line_item.order;
            return (
              <Link to={`/admin/orders/${id}`} openNewWindow openNewWindowIcon>
                {is_first_order && (
                  <FirstOrderBadge className="mr-1" fontSize="xs" showText={false} />
                )}
                {public_id}
              </Link>
            );
          },
        },
        {
          Header: 'W.O. #',
          id: 'work_order_id',
          Cell: row => {
            const { work_order_id } = row.original.work_order_line_item;
            return (
              <Link to={`/admin/work_orders/${work_order_id}`} openNewWindow openNewWindowIcon>
                {work_order_id}
              </Link>
            );
          },
        },
        {
          Header: 'Customer',
          id: 'customer_name',
          Cell: row => {
            const { customer } = row.original.work_order_line_item.order_line_item.order;
            return (
              <Link to={`/admin/customers/${customer.id}`} openNewWindow openNewWindowIcon>
                {customer.name}
              </Link>
            );
          },
        },
        {
          Header: 'MFG',
          id: 'manufacturing_process_name',
          accessor: 'work_order_line_item.order_line_item.manufacturing_process.name',
        },
        {
          Header: 'Qty Failed',
          id: 'quantity',
          accessor: data => `${data.quantity} / ${data.work_order_line_item.quantity}`,
        },

        {
          show:
            dispositionTab === DISPOSITION_TABS.IN_QC_INVENTORY ||
            dispositionTab === DISPOSITION_TABS.RESOLVED ||
            dispositionTab === DISPOSITION_TABS.SEARCH,
          Header: 'Reason',
          id: 'rejection_reason_id',
          accessor: 'rejection_reason.description',
        },
        {
          show: !(
            dispositionTab === DISPOSITION_TABS.IN_QC_INVENTORY ||
            dispositionTab === DISPOSITION_TABS.RESOLVED ||
            dispositionTab === DISPOSITION_TABS.SEARCH
          ),
          Header: 'Failure State',
          accessor: 'fail_state',
        },
        {
          show:
            dispositionTab === DISPOSITION_TABS.IN_QC_INVENTORY ||
            dispositionTab === DISPOSITION_TABS.RESOLVED ||
            dispositionTab === DISPOSITION_TABS.SEARCH,
          Header: 'Disposition',
          accessor: 'disposition',
        },
        {
          show: !(
            dispositionTab === DISPOSITION_TABS.IN_QC_INVENTORY ||
            dispositionTab === DISPOSITION_TABS.RESOLVED ||
            dispositionTab === DISPOSITION_TABS.SEARCH
          ),
          Header: 'Reason',
          id: 'rejection_reason_id',
          accessor: 'rejection_reason.description',
        },
        {
          show: dispositionTab !== DISPOSITION_TABS.REVIEW,
          Header: 'Date Dispositioned',
          id: 'dispositioned_at',
          accessor: data => moment(data.dispositioned_at).format('MM/DD/YY'),
        },
        {
          show: dispositionTab !== DISPOSITION_TABS.REVIEW,
          Header: 'Dispositioned By',
          id: 'dispositioned_by_user',
          accessor: data => get(data, 'dispositioned_by_user.name'),
        },
        {
          show: dispositionTab === DISPOSITION_TABS.RESOLVED,
          Header: 'Date Resolved',
          id: 'resolved_at',
          accessor: data => moment(data.resolved_at).format('MM/DD/YY'),
        },
        {
          show: dispositionTab === DISPOSITION_TABS.RESOLVED,
          Header: 'Action Taken',
          accessor: 'action',
        },
        {
          show: dispositionTab === DISPOSITION_TABS.RESOLVED,
          Header: 'Resolved By',
          id: 'resolved_by_user',
          accessor: data => get(data, 'resolved_by_user.name'),
        },
        {
          show: [
            DISPOSITION_TABS.IN_QC_INVENTORY,
            DISPOSITION_TABS.REWORK,
            DISPOSITION_TABS.REMAKE,
            DISPOSITION_TABS.AWAITING_CUSTOMER,
            DISPOSITION_TABS.SEARCH,
          ].includes(dispositionTab),
          Header: 'Disposition Notes',
          id: 'disposition_notes',
          Cell: row => {
            const { disposition_notes } = row.original;
            return (
              <div className="text-truncate" data-rh={disposition_notes}>
                {disposition_notes}
              </div>
            );
          },
        },
        {
          sortable: false,
          id: 'screenshot',
          Cell: row => {
            const link = row.original.link;
            const woId = row.original.work_order_line_item.work_order_id;
            return link ? (
              <div
                className="cursor-pointer"
                onClick={() => openImageInNewTab(link, woId)}
                onKeyDown={evt => keyboardDown(evt, 'Enter', () => openImageInNewTab(link, woId))}
                role="button"
                tabIndex={0}
              >
                <img
                  src={link}
                  className="rounded"
                  style={{ maxWidth: 35, maxHeight: 35 }}
                  alt="Part Screenshot"
                />
              </div>
            ) : null;
          },
        },
      ]}
      SubComponent={row => {
        return (
          <Table
            data={[row.original]}
            defaultPageSize={1}
            showPagination={false}
            className="-no-shadow"
            columns={[
              {
                sortable: false,
                Header: 'Printer',
                accessor: 'work_order_line_item.work_order.printer.name',
              },
              {
                sortable: false,
                Header: 'Material',
                accessor: 'work_order_line_item.order_line_item.material.name',
              },
              {
                sortable: false,
                Header: 'Color',
                accessor: 'work_order_line_item.order_line_item.color.name',
              },
              {
                sortable: false,
                Header: 'Finish',
                accessor: 'work_order_line_item.order_line_item.finishing',
              },
              {
                show: [
                  DISPOSITION_TABS.IN_QC_INVENTORY,
                  DISPOSITION_TABS.RESOLVED,
                  DISPOSITION_TABS.SEARCH,
                ].includes(dispositionTab),
                sortable: false,
                Header: 'Failure State',
                accessor: 'fail_state',
              },
              {
                show: ![
                  DISPOSITION_TABS.IN_QC_INVENTORY,
                  DISPOSITION_TABS.RESOLVED,
                  DISPOSITION_TABS.SEARCH,
                ].includes(dispositionTab),
                sortable: false,
                Header: 'Marked as Failed By',
                accessor: 'user.name',
              },
              {
                sortable: false,
                Header: 'Comments',
                id: 'comments',
                Cell: row => {
                  const { comments } = row.original;
                  return (
                    <div className="text-truncate" data-rh={comments}>
                      {comments}
                    </div>
                  );
                },
                width: 200,
              },
              {
                Header: 'Sales Contact',
                accessor: 'work_order_line_item.order_line_item.order.sales_contact.name',
              },
              {
                sortable: false,
                Header: 'Promised Ship',
                id: 'promised_ship',
                accessor: data =>
                  moment(data.work_order_line_item.order_line_item.promised_ship_date).format(
                    'MM/DD/YY'
                  ),
              },
              {
                sortable: false,
                Header: 'Days to Ship',
                id: 'days_to_ship',
                accessor: data =>
                  Math.floor(
                    moment
                      .duration(
                        moment(data.work_order_line_item.order_line_item.promised_ship_date).diff(
                          moment()
                        )
                      )
                      .as('days')
                  ),
              },
            ]}
          />
        );
      }}
    />
  );
};

ReviewTable.propTypes = {
  data: PropTypes.array,
  pages: PropTypes.number,
  onFetchData: PropTypes.func,
  onPartSelectionChange: PropTypes.func,
  dispositionTab: PropTypes.string,
  loading: PropTypes.bool,
};

export default ReviewTable;
