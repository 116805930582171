import { find, get } from 'lodash';

/**
 * Takes an array of line items and sees if a mfg process is present
 * @param {array} lineItems
 * @param {string} process
 */
export const hasProcess = (lineItems, process) => {
  return !!find(
    lineItems,
    l => get(l.order_line_item?.manufacturing_process, 'name') === process
  );
};
