import { useField } from 'formik';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

import { LABELS_BY_COUNTRY } from 'fr-shared/lib/address';
import { COUNTRIES } from 'fr-shared/lib/countries';

import { classNames } from 'portal/components';

import styles from './FormPhoneNumber.module.css';

export const FormPhoneNumber = ({
  defaultCountry = COUNTRIES.UNITED_STATES,
  className,
  label,
  onBlur = _evt => {},
  onChange,
  floatingLabel = true,
  name,
  readonly,
}) => {
  const [field, meta, helpers] = useField(name);
  const isInvalid = onChange ? !!meta.error : !!meta.error && meta.touched;
  const errorText = meta.error;

  const value = isNil(field.value) ? '' : field.value;

  // TODO: move this component to use FormFieldBase
  return (
    <div
      className={classNames([
        'form-group',
        isInvalid && 'is-invalid',
        floatingLabel && 'is-floating',
        readonly && 'is-readonly',
        className,
      ])}
    >
      {label && (
        <label
          htmlFor={name}
          className={classNames(['whitespace-nowrap', !readonly && 'absolute'])}
        >
          {label}
        </label>
      )}
      {readonly ? (
        <div>{value}</div>
      ) : (
        <IntlTelInput
          defaultCountry={LABELS_BY_COUNTRY[defaultCountry]?.flagCode}
          format={true}
          fieldName={name}
          fieldId={name}
          data-testid={name}
          value={value}
          onPhoneNumberChange={(_validation, value) => {
            helpers.setValue(value);
            onChange && onChange();
          }}
          onPhoneNumberBlur={(_status, _value, _country, _number, _id, evt) => onBlur(evt)}
          preferredCountries={['us', 'ca', 'gb']}
          containerClassName={`intl-tel-input ${styles.Root}`}
          inputClassName="form-control"
        />
      )}
      {isInvalid && (
        <div className="form-control-error" data-testid={`errors-${name}`}>
          {errorText}
        </div>
      )}
    </div>
  );
};

FormPhoneNumber.propTypes = {
  className: PropTypes.string,
  defaultCountry: PropTypes.string,
  readonly: PropTypes.bool,
  floatingLabel: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export default FormPhoneNumber;
