import React from 'react';

import { InspectionTypePrice } from 'fr-shared/components';
import {
  AUTOQUOTE_EXCLUDED_INSPECTION_TYPES,
  INSPECTION_TYPE_NAMES,
  getInspectionTypeDisplayName,
} from 'fr-shared/lib/inspection_types';

import { InspectionTypeForDefinition } from './InspectionTypeDefinitionsModal';

interface Props {
  advancedInspectionPrice: null | Money;
  inspectionType: InspectionTypeForDefinition;
  daysAmtFunc: (name: string) => string;
}

export const InspectionTypeColumn = ({
  advancedInspectionPrice,
  inspectionType,
  daysAmtFunc,
}: Props) => {
  return (
    <div key={`${inspectionType.name}-${'modal'}`} className="col-md">
      <div className="pb-4" key={inspectionType.id}>
        <div className="font-weight-bold font-size-md">
          {getInspectionTypeDisplayName(inspectionType?.name)}
        </div>
        <div className="border-b border-coolGray-600 border-solid my-2" />

        <div className="font-size-sm">
          <div className="h-[159px] mb-2">
            What&#39;s included?
            <div className="text-coolGray-300">{inspectionType.public_description}</div>
          </div>

          <div className="mb-2 h-[125px]">
            What&#39;s it best for?
            <div className="text-coolGray-300">{inspectionType.public_usecase_description}</div>
          </div>

          <div className="d-flex justify-content-between">
            {!AUTOQUOTE_EXCLUDED_INSPECTION_TYPES.includes(inspectionType.name) && (
              <>
                <div className="d-flex flex-column">
                  <div className="text-coolGray-300">Time it takes</div>
                  <div>{daysAmtFunc(inspectionType.name)}</div>
                </div>
                <div className="d-flex flex-column">
                  <div className="text-coolGray-300">Cost</div>
                  <div>
                    <InspectionTypePrice
                      inspectionType={inspectionType}
                      advancedInspectionPrice={advancedInspectionPrice}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="mt-4 mb-3 border-b border-coolGray-600 border-solid" />
          {inspectionType.customer_portal_autoquoteable ? (
            <div className="text-tertiary-300">Instant quote available</div>
          ) : (
            <div className="text-warning-300">Part drawing needed for instant quote</div>
          )}
          {inspectionType.name === INSPECTION_TYPE_NAMES.AdvancedInspection && (
            <div className="text-coolGray-300">
              <sup className="static">*</sup> Varies by quantity
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InspectionTypeColumn;
