import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, Helmet } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';

import ShipmentForm from './components/ShipmentForm';

const ShipmentNew = ({ history, location }) => {
  const { setAlert } = useContext(AlertContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = (values, formik) => {
    setIsSubmitting(true);
    api
      .post('/shipments', {
        shipment: values,
        send_order_shipped: values.send_order_shipped,
      })
      .then(res => {
        setAlert({ message: `Successfully created shipment ${res.data.id}` });
        history.push(`/admin/shipments/${res.data.id}`);
      })
      .catch(err => {
        setAlert({
          color: 'danger',
          message:
            'There was an error submitting the shipment. Please fix all of the marked fields.',
        });
        if (err.response?.data) {
          const { errors, messages } = err.response.data;
          formik.setErrors({ server: messages, ...errors });
        } else {
          formik.setErrors({
            server: ['An unexpected error occurred. Refresh and try again.'],
          });
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const initialValues = location.state ? { order: location.state.initialValues } : null;

  return (
    <>
      <Helmet title="Shipments" />
      <Breadcrumb to="/admin/shipments">Shipments</Breadcrumb>
      <ShipmentForm
        isLoading={isSubmitting}
        handleSubmit={handleSubmit}
        initialValues={initialValues}
      />
    </>
  );
};

ShipmentNew.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default ShipmentNew;
