import React from 'react';

import { Button, Page, PageHeader } from 'portal/components';

const RequestCallConfirmation = () => {
  return (
    <Page>
      <PageHeader
        subtitle="We’ve received your request and we’ll give you a call within 1 business day."
        title="Thanks for the info. We’re on it."
      />

      <div className="flex flex-display">
        <Button to="/">Back to dashboard</Button>
      </div>
    </Page>
  );
};

export default RequestCallConfirmation;
