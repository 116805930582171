import { Field } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncSelect from 'react-select/async';
import isCreateableSelect from 'react-select/async-creatable';

import { DropdownIndicator, Icon, classNames } from 'fr-shared/components';

const FormSelectCreate = props => {
  const {
    accessor,
    defaultOptions,
    formatCreateLabel,
    helpText,
    isClearable,
    isCreateable,
    label,
    loadOptions,
    md,
    name,
    noOptionsMessage,
    onCreateOption,
    placeholder,
    readonly,
    required,
    stacked,
    style,
  } = props;

  const handleChange = field => {
    const { name, onChange } = props;
    onChange && onChange({ name: name, ...field });
  };

  const clearIndicator = indicatorProps => (
    <Icon
      name="times"
      size="sm"
      className="cursor-pointer opacity-80 hover:opacity-100"
      opacity={true}
      right={true}
      {...indicatorProps.innerProps}
    />
  );

  const Component = isCreateable ? isCreateableSelect : AsyncSelect;

  return (
    <Field name={name}>
      {({ field, form }) => {
        const values = form.values;
        const value = field.value ? get(values, accessor || name) : '';
        const hasError = get(form.errors, name);

        const newValue = {
          label: value,
          value: field.value ? field.value.toString() : null,
        };

        let inputComponent = (
          <Component
            cacheOptions={true}
            className={classNames(['react-select-wrapper', { 'is-invalid': hasError }])}
            classNamePrefix="react-select"
            components={{
              ClearIndicator: clearIndicator,
              DropdownIndicator,
              IndicatorSeparator: null,
            }}
            defaultOptions={defaultOptions}
            formatCreateLabel={formatCreateLabel}
            inputId={name}
            isClearable={isClearable}
            loadOptions={loadOptions}
            noOptionsMessage={noOptionsMessage}
            onChange={handleChange}
            onCreateOption={onCreateOption}
            placeholder={placeholder || ''}
            value={field.value ? newValue : null}
          />
        );

        if (values && readonly) {
          const textValue = get(values, readonly) || get(values, name);

          inputComponent = (
            <div id={name}>
              <strong>{textValue}</strong>
            </div>
          );
        }

        return (
          <div className="row form-group">
            {stacked ? (
              <div className="col-md">
                <label htmlFor={name}>
                  {label} {required && !readonly && <span className="text-error">*</span>}
                </label>
                <div style={style}>
                  {inputComponent}
                  {hasError && <div className="form-control-error">{hasError}</div>}
                  {helpText && !readonly && (
                    <div className="font-italic text-gray pt-1 font-size-sm">{helpText}</div>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className={`col-md-${md ? md : 4}`}>
                  <label htmlFor={name}>
                    {label} {required && !readonly && <span className="text-error">*</span>}
                  </label>
                </div>
                <div className="col-md">
                  {inputComponent}
                  {hasError && (
                    <div className="form-control-error" data-testid={`errors-${name}`}>
                      {hasError}
                    </div>
                  )}
                  {helpText && !readonly && (
                    <div className="font-italic text-gray pt-1 font-size-sm">{helpText}</div>
                  )}
                </div>
              </>
            )}
          </div>
        );
      }}
    </Field>
  );
};

FormSelectCreate.defaultProps = {
  noOptionsMessage: () => null,
  isCreateable: true,
};

FormSelectCreate.propTypes = {
  accessor: PropTypes.string,
  defaultOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  formatCreateLabel: PropTypes.func,
  helpText: PropTypes.string,
  isClearable: PropTypes.bool,
  isCreateable: PropTypes.bool,
  label: PropTypes.string,
  loadOptions: PropTypes.func,
  md: PropTypes.number,
  name: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  onChange: PropTypes.func,
  onCreateOption: PropTypes.func,
  placeholder: PropTypes.string,
  readonly: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  required: PropTypes.bool,
  stacked: PropTypes.bool,
  style: PropTypes.object,
};

export default FormSelectCreate;
