import React, { useState } from 'react';

import { ButtonLink, Icon } from 'fr-shared/components';

interface CopyLinkProps {
  link: string;
  initialText: string;
  successText: string;
}

export const CopyLink = ({ link, initialText, successText }: CopyLinkProps) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2500);
    } catch (error) {
      setCopySuccess(false);
    }
  };

  return (
    <ButtonLink onClick={handleCopyLink} className="p-0">
      {!copySuccess && (
        <div className="text-primary">
          <Icon name="copy" right type="fal" className="mr-1" />
          <span className="align-items-center">{initialText}</span>
        </div>
      )}
      {copySuccess && (
        <div className="text-success">
          <Icon name="check" className="mr-1" />
          <span className="align-items-center">{successText}</span>
        </div>
      )}
    </ButtonLink>
  );
};

export default CopyLink;
