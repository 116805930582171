import { FormikValues } from 'formik';
import { omit } from 'lodash';
import * as Yup from 'yup';

import { api } from 'fr-shared/api';
import { COUNTRIES } from 'fr-shared/lib/countries';

export const AddressTypeEnum = {
  Shipping: 'Shipping',
  Billing: 'Billing',
} as const;

/** When migrating usage from prop types to TypeScript, see {@link AddressType} */
export const AddressTypes = Object.keys(AddressTypeEnum);

// Grab the values from AddressTypeEnum and create the union of those type literals
// https://www.typescriptlang.org/docs/handbook/enums.html#objects-vs-enums
export type AddressType = typeof AddressTypeEnum[keyof typeof AddressTypeEnum];

// make sure is_default is present as a formik value
export type AddressFormikValues = FormikValues & { is_default: boolean };

/**
 * Returns the correct url path for the requested type
 * @param {string} type - the type of address
 */
export const addressPath = (type: AddressType) => {
  switch (type) {
    case AddressTypeEnum.Shipping:
      return 'shipping_addresses';
    case AddressTypeEnum.Billing:
      return 'billing_addresses';
  }
};

const stateValidation = Yup.string()
  .nullable()
  .when('country', (country, schema) => {
    return country === COUNTRIES.UNITED_STATES ? schema.required('State is required') : schema;
  });

const postalCodeValidation = Yup.string().when('country', (country, schema) => {
  return country === COUNTRIES.UNITED_STATES ? schema.min(5, 'ZIP Code is required') : schema;
});

/**
 * Yup validation schema for shipping addresses
 */
export const shippingSchema = Yup.object().shape({
  shipping_address: Yup.object().shape({
    city: Yup.string().required('City is required'),
    country: Yup.string().required('Country is required'),
    name_line_1: Yup.string().nullable().required('Full Name is required'),
    postal_code: postalCodeValidation.required('ZIP Code is required'),
    state: stateValidation,
    street_line_1: Yup.string().required('Street line 1 is required'),
  }),
});

export const shippingWithPhoneNumberSchema = Yup.object().shape({
  shipping_address: Yup.object().shape({
    city: Yup.string().required('City is required'),
    country: Yup.string().required('Country is required'),
    name_line_1: Yup.string().nullable().required('Full Name is required'),
    postal_code: postalCodeValidation.required('ZIP Code is required'),
    state: stateValidation,
    street_line_1: Yup.string().required('Street line 1 is required'),
    phone_number: Yup.string().required('Phone number is required'),
  }),
});

/**
 * Yup validation schema for billing addresses
 */
export const billingSchema = Yup.object().shape({
  billing_address: Yup.object().shape({
    city: Yup.string().required('City is required'),
    country: Yup.string().required('Country is required'),
    name_line_1: Yup.string().nullable().required('Full Name is required'),
    postal_code: postalCodeValidation.required('ZIP Code is required'),
    state: stateValidation,
    street_line_1: Yup.string().required('Street line 1 is required'),
  }),
});

export const createOrUpdateAddress = (
  values: AddressFormikValues,
  isEdit: boolean,
  userId: number,
  type: AddressType
) => {
  const isDefault = values['is_default'];
  const address = omit(values, ['is_default']);

  return isEdit
    ? api.put(`/users/${userId}/${addressPath(type)}/${values.id}`, {
        address: address,
        is_default: isDefault,
      })
    : api.post(`/users/${userId}/${addressPath(type)}`, {
        address: address,
        is_default: isDefault,
      });
};

export const CP_SHIPPING_COUNTRIES = [
  { name: COUNTRIES.UNITED_STATES },
  { name: COUNTRIES.CANADA },
];

export const LABELS_BY_COUNTRY: any = {
  'United States': {
    zip: 'ZIP Code',
    state: 'State',
    flagCode: 'us',
  },
  Canada: {
    zip: 'Postal code',
    state: 'Province',
    flagCode: 'ca',
  },
};
