import { Logo } from '@fast-radius/shared-ui';
import React from 'react';

import { MARKETING_URLS } from 'fr-shared/lib/marketing_urls';

import { Link, classNames } from 'portal/components';

import styles from './Footer.module.css';

const Footer = () => (
  <div className={styles.Footer}>
    <div className="container-sm">
      <div className="row">
        <div className={classNames(['col-lg', styles.Copyright])}>
          <p>
            © 2023 <span>Fast Radius</span>, Inc. All rights reserved.
          </p>
        </div>
        <div className={classNames(['col-lg', styles.Logo])}>
          <a href={MARKETING_URLS.LandingPage}>
            <Logo fill="#828D9B" className="md:justify-center" />
          </a>
        </div>
        <div className={classNames(['col-lg', styles.Links])}>
          <Link to={{ pathname: MARKETING_URLS.TermsOfUse }} openNewWindow>
            Terms of Use
          </Link>
          <Link className="px-3" to={{ pathname: MARKETING_URLS.Privacy }} openNewWindow>
            Privacy Policy
          </Link>
          <Link to={{ pathname: MARKETING_URLS.Resources }} openNewWindow>
            Resources
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
