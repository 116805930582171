import { Form, Formik } from 'formik';
import React from 'react';

import styles from './AddPartCard.module.css';
import AddPartDropzone from './Dropzone';

interface AddPartCardProps {
  source?: string;
  onCompleteUpload?: (uploadedParts: Array<any>) => void;
  shouldRedirectToPartViewer?: boolean;
  shouldAddPartToCart?: boolean;
}

export const AddPartCard = ({
  source = 'Studio',
  onCompleteUpload,
  shouldRedirectToPartViewer,
  shouldAddPartToCart,
}: AddPartCardProps) => {
  return (
    <div className={styles.AddPartCard}>
      <Formik initialValues={{ units_in_mm: true }} onSubmit={() => {}}>
        <Form className="flex flex-fill flex-column">
          <AddPartDropzone
            source={source}
            onCompleteUpload={onCompleteUpload}
            shouldRedirectToPartViewer={shouldRedirectToPartViewer}
            shouldAddPartToCart={shouldAddPartToCart}
          />
        </Form>
      </Formik>
    </div>
  );
};

export default AddPartCard;
