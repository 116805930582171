import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { fetchCostData } from 'portal/lib/autoquote';
import { CostData } from 'portal/lib/cost_drivers';

const DEBOUNCE_MS = 400;

/**
 * Returns the state of the most recently requested costing data and a function
 * that can be called to request a new costing data.
 */
const useRequestCostData = (initAutoquoteId?: string | number) => {
  const [costData, setCostData] = useState<CostData>(null);

  useEffect(() => {
    if (initAutoquoteId) {
      fetchCostData(initAutoquoteId);
    }
    /* eslint-disable-next-line */
  }, []);

  const clearCostData = () => setCostData(null);

  const fetchCostDataRaw = (autoquoteId: string | number) => {
    fetchCostData(autoquoteId)
      .then(res => {
        setCostData({ autoquote_id: autoquoteId, ...res });
      })
      .catch(() => clearCostData());
  };

  const fetchCostDataDebounced = useCallback(debounce(fetchCostDataRaw, DEBOUNCE_MS), []);

  return [costData, fetchCostDataDebounced, clearCostData] as const;
};

export default useRequestCostData;
