import React from 'react';

import { FormFieldBase } from 'fr-shared/components';

import { ItarEarModal } from 'portal/components';

type Props = {
  cliId: string | number;
  partId: number;
};

function ItarEarField({ cliId, partId }: Props) {
  return (
    <FormFieldBase
      className="form-field-stacked mt-4 mb-4"
      dataTestId="itar-ear-field--form-field-title-itar-ear-regulations"
      isStacked={true}
      label="Do ITAR or EAR regulations apply?"
      name="itar_ear"
    >
      <ItarEarModal cliId={cliId} partId={partId} />
      <ItarEarModal isInfoModal />
    </FormFieldBase>
  );
}

export default ItarEarField;
