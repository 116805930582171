import { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { AlertContext } from 'fr-shared/context';

const OrderPublicID = () => {
  const history = useHistory();
  const { public_id } = useParams();
  const { setAlert } = useContext(AlertContext);

  useEffect(() => {
    if (public_id) {
      api.get('/orders', { params: { public_id } }).then(res => {
        if (res.data.length === 1) {
          history.push(`/admin/orders/${res.data[0].id}`);
        } else {
          history.push('/admin/orders');
          setAlert({
            message: `Could not find an order with Public ID: ${public_id}`,
            color: 'danger',
          });
        }
      });
    }
  }, [history, setAlert, public_id]);

  return null;
};

export default OrderPublicID;
