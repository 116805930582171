import React, { useState } from 'react';

import { Icon, LineItemDutiesTaxesTariffs } from 'fr-shared/components';

interface QuoteFormPartDutiesTaxesTariffsProps {
  index?: number;
}

const QuoteFormPartDutiesTaxesTariffs = ({ index }: QuoteFormPartDutiesTaxesTariffsProps) => {
  const [open, setOpen] = useState(true);
  const fieldPrefix = `line_items.${index}`;

  const setToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <div className="part-details mt-3">
      <button
        onClick={e => setToggle(e)}
        className="btn w-100 part-details-header cursor-pointer flex"
        aria-expanded={open}
        data-testid={`quote-form-dtt-button-${index}`}
      >
        Duties, Taxes, and Tariffs
        <div className="ml-auto">
          <Icon name={open ? 'chevron-up' : 'chevron-down'} />
        </div>
      </button>
      {open && (
        <div className="part-details-body">
          <div className="card-body border-top p-0 border-0">
            <div className="row">
              <LineItemDutiesTaxesTariffs fieldPrefix={fieldPrefix} readonly={true} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuoteFormPartDutiesTaxesTariffs;
