import { useTreatments } from '@splitsoftware/splitio-react';
import { useFormikContext } from 'formik';
import { get, merge, uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { Button, PartFileDropzone, PartFileUnits } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { CR_BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { useS3PartUpload } from 'fr-shared/hooks';
import { NEW_COSTING_REQUEST_LINE_ITEM } from 'fr-shared/lib/costing_requests';
import { ALLOWED_FROS_PART_FILE_EXTENSIONS, isAllowedPartFile } from 'fr-shared/utils/files';

const ACCEPTED_FILE_TYPES = ALLOWED_FROS_PART_FILE_EXTENSIONS.join(', ');

const CostingFormPartUpload = () => {
  const formik = useFormikContext();
  const [units, setUnits] = useState(null);
  const [parts, startUpload] = useS3PartUpload();
  const { setAlert } = useContext(AlertContext);

  const handleFileDrop = acceptedFiles => {
    const partsToUpload = acceptedFiles.map(file => ({ file }));

    // upload all the parts to s3 and update their status
    const stagedFiles = startUpload(partsToUpload, {
      organization_id: formik.values.customer.id,
      source: 'FROS Costing',
      units: units,
    });

    const newLineItems = stagedFiles.map(({ file, uuid, upload_status }) => {
      return {
        ...NEW_COSTING_REQUEST_LINE_ITEM,
        _id: uniqueId('cr-line-item-'),
        description: file.name,
        uuid,
        units,
        upload_status,
      };
    });

    formik.setFieldValue('line_items', [...formik.values.line_items, ...newLineItems]);
  };

  const handleFileDropRejected = (files, _event) => {
    let message = 'There was an issue with the file upload. Please refresh and try again.';
    const fileNames = files.map(f => f.name);
    const unacceptedFileNames = fileNames.filter(name => !isAllowedPartFile(name));
    if (unacceptedFileNames.length > 0) {
      const filesListStr = unacceptedFileNames.join(', ');
      message = `${filesListStr} can't be uploaded because of invalid file type(s). Accepted file types are ${ACCEPTED_FILE_TYPES}`;
    }
    setAlert({
      message,
      color: 'danger',
      autoClose: false,
    });
  };

  // watch for new parts being uploaded
  useEffect(() => {
    if (parts.length === 0) return;

    const partWithError = parts.find(part => part.upload_status === 'error');
    if (partWithError) {
      const message =
        partWithError.error.type === 'unsupported'
          ? `${
              partWithError.file?.name || 'The file'
            } can't be uploaded because it is not a valid file type. Accepted file types are ${ACCEPTED_FILE_TYPES}`
          : 'There was an upload error. Please refresh the page and try again.';
      setAlert({
        message,
        color: 'danger',
        autoClose: false,
      });
    }

    const newLineItems = formik.values.line_items.map(li => {
      const matchingPart = parts.find(part => part.uuid === li.uuid);
      if (matchingPart && li.upload_status !== matchingPart.upload_status) {
        return merge(li, {
          isOpen: true,
          part: matchingPart.data,
          part_id: get(matchingPart, 'data.id'),
          upload_status: matchingPart.upload_status,
        });
      } else {
        return li;
      }
    });

    formik.setFieldValue('line_items', newLineItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parts]);

  return (
    <div className="w-100 bg-light card">
      <div className="border-bottom mb-0 px-3 py-2 font-weight-bold bg-light">
        <PartFileUnits label="Set File Units to:" setUnits={setUnits} defaultChecked={false} />
      </div>

      {units ? (
        <PartFileDropzone
          accept={ALLOWED_FROS_PART_FILE_EXTENSIONS}
          onFileDropRejected={handleFileDropRejected}
          childComponent={<CostingDropzone open={open} />}
          className="bg-light flex px-2 justify-content-center"
          onFileDrop={handleFileDrop}
          testId="cr-part-file-dropzone"
        ></PartFileDropzone>
      ) : (
        <div className="my-3 flex align-items-center justify-content-center flex-column bg-light px-3 py-2 font-weight-bold">
          <h4>Select file units to get started</h4>
        </div>
      )}
    </div>
  );
};

CostingFormPartUpload.propTypes = {
  formik: PropTypes.object,
};

const CostingDropzone = ({ open }) => {
  const { [CR_BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';

  return (
    <div className="flex align-items-center justify-content-center py-3">
      <div className="text-left">
        Drag and drop files <br /> to add a {lineItemName}
      </div>

      <span className="px-4">OR</span>

      <Button color="primary" onClick={open}>
        Select from Computer
      </Button>
    </div>
  );
};
CostingDropzone.propTypes = { open: PropTypes.func };

export default CostingFormPartUpload;
