import React from 'react';

import { IconFont, classNames } from 'fr-shared/components';

interface FilterAndSortButtonProps {
  className: string;
  handleClick: () => void;
  filterCount: number;
}

const FilterAndSortButton = ({
  className,
  handleClick,
  filterCount,
}: FilterAndSortButtonProps) => {
  const toggleModal = () => {
    handleClick();
  };

  return (
    <div
      onClick={() => toggleModal()}
      role="button"
      tabIndex={0}
      onKeyDown={e => {
        if (e.key === 'Enter') toggleModal();
      }}
      className={classNames(['text-coolGray-300 cursor-pointer', className])}
    >
      <IconFont name="filter" />
      {filterCount > 0 && (
        <span
          className={classNames(['bg-primary text-black rounded-full -top-1 relative badge'])}
        >
          {filterCount}
        </span>
      )}
      <span>&nbsp;&nbsp;Filter&nbsp;&#38;&nbsp;Sort</span>
    </div>
  );
};

export default FilterAndSortButton;
