/**
 * Takes an array of strings and returns a string with each array value
 * delimited with a comma. The result utilizes the Oxford Comma.
 *
 * @param array
 */
export const toCommaSeparatedString = (array: unknown[]) => {
  if (array.length === 1) {
    return array[0];
  } else if (array.length === 2) {
    return array[0] + ' and ' + array[1];
  } else {
    const firsts = array.slice(0, array.length - 1);
    const last = array[array.length - 1];
    return firsts.join(', ') + ', and ' + last;
  }
};
