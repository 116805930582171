import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Pagination, PrivateRoute, ProgressCircle } from 'fr-shared/components';

import { AddPartCard, Page } from 'portal/components';
import { PartsContext } from 'portal/contexts/PartsContext';

import PartsSection from '../PartsSection';
import BulkPartConfigContainer from './BulkPartConfigContainer';

interface PortalUploadContainerProps {
  children: React.ReactNode;
  filters: any;
  handleSetPage: () => void;
  loading: boolean;
  partId: string;
  pages: number;
  parts: Array<any>;
  fetchAllParts: () => void;
  onSearch: () => void;
  onSort: () => void;
}

const PortalUploadContainer = ({
  filters,
  handleSetPage,
  loading,
  pages,
  partId,
  fetchAllParts,
  onSearch,
  onSort,
  parts,
  children,
}: PortalUploadContainerProps) => {
  const [uploadedPartIds, setUploadedPartIds] = useState([]);
  const history = useHistory();

  const { handleSelectParts, selectedParts } = useContext(PartsContext);

  useEffect(() => {
    const uploadedParts = parts.filter(p => uploadedPartIds.includes(p.id));
    handleSelectParts(uploadedParts);
    setUploadedPartIds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parts, handleSelectParts]);

  const startBulkEditingHandler = () => {
    let currentQuantities = JSON.parse(localStorage.getItem('selectedParts'));
    const partsArray = Object.values(selectedParts).map((partData: any) => {
      if (partData.id in currentQuantities) {
        partData.quantity = currentQuantities[partData.id];
      } else {
        partData.quantity = 1;
      }
      return partData;
    });

    handleSelectParts(partsArray);
    history.push('/edit-parts');
  };

  return (
    <>
      {(window.location.pathname === '/add-part' || window.location.pathname === '/parts') && (
        <Page>
          {children}
          <PartsSection
            partId={partId}
            parts={parts}
            loading={loading}
            onBeginBulkEdit={startBulkEditingHandler}
            onSearch={onSearch}
            onSort={onSort}
          >
            <AddPartCard
              source="Portal"
              shouldRedirectToPartViewer={false}
              shouldAddPartToCart={false}
              onCompleteUpload={partIds => {
                fetchAllParts();
                setUploadedPartIds(partIds ?? []);
              }}
            />
          </PartsSection>
          {loading && (
            <div className="flex justify-center mt-32">
              <ProgressCircle
                percentage={75}
                spinner={true}
                backgroundColor="#111115"
                fadesIntoBackground
              />
            </div>
          )}
          {pages > 1 && !loading && (
            <div className="flex-1 mt-3 flex align-items-end">
              <Pagination
                page={filters.page}
                setPage={handleSetPage}
                totalPages={pages}
                pageNumberStyle={'numbered'}
              />
            </div>
          )}
        </Page>
      )}
      <PrivateRoute
        exact={true}
        scrollToTop={true}
        path="/edit-parts"
        component={BulkPartConfigContainer}
      >
        <></>
      </PrivateRoute>
    </>
  );
};

export default PortalUploadContainer;
