import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Button, Select, SidebarContent } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { useManufacturingProcess, useUsersByRole } from 'fr-shared/hooks';
import { COSTING_REQUEST_STATES } from 'fr-shared/lib/costing_requests';
import { ROLES } from 'fr-shared/lib/roles';

import { FILTER_FIELDS } from '../utils/queues';

const CostingSidebar = ({
  fields = [],
  filters,
  resetFilters,
  setFilters,
  states = Object.values(COSTING_REQUEST_STATES),
}) => {
  const { user } = useContext(UserContext);
  const { data: manufacturingProcesses } = useManufacturingProcess();
  const { data: salesUsers } = useUsersByRole(ROLES.Sales);
  const { data: engineeringUsers } = useUsersByRole(ROLES.ApplicationEngineering);
  const { data: supportUsers } = useUsersByRole(ROLES.OperationsProjectManagement);

  const availableStateFilters = () => {
    if (user.canViewDraftCostingRequests) {
      return states;
    } else {
      return states.filter(state => state !== COSTING_REQUEST_STATES.Draft);
    }
  };

  const commonFilterFields = {
    [FILTER_FIELDS.SalesContactId]: {
      label: 'Sales Contact',
      options: salesUsers,
      value: filters.sales_contact_id,
    },
    [FILTER_FIELDS.ManufacturingProcessId]: {
      label: 'Manufacturing Process',
      options: manufacturingProcesses,
      value: filters.manufacturing_process_id,
    },
    [FILTER_FIELDS.State]: {
      label: 'Request State',
      options: availableStateFilters().map(state => ({
        id: state,
        name: state,
      })),
      value: filters.state,
    },
    [FILTER_FIELDS.EngineeringContactId]: {
      label: 'Engineering Contact',
      options: engineeringUsers,
      value: filters.engineering_contact_id,
    },
    [FILTER_FIELDS.SupportContactId]: {
      label: 'Support Contact',
      options: supportUsers,
      value: filters.support_contact_id,
    },
  };

  return (
    <SidebarContent name="Filters" icon="filter">
      {fields.map(key => {
        const { label, options, value } = commonFilterFields[key];

        return (
          <div key={key} className="form-group">
            <Select
              label={label}
              name={key}
              onChange={e => setFilters({ ...filters, [key]: e.target.value })}
              optionList={options}
              value={(value && String(value)) || ''}
            />
          </div>
        );
      })}

      <div className="form-group text-right mt-3">
        <Button size="sm" onClick={resetFilters}>
          Clear filters
        </Button>
      </div>
    </SidebarContent>
  );
};

CostingSidebar.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string),
  filters: PropTypes.object,
  resetFilters: PropTypes.func,
  setFilters: PropTypes.func,
  states: PropTypes.arrayOf(PropTypes.string),
};

export default CostingSidebar;
