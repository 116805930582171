/* eslint-disable react/display-name */
import moment from 'moment';
import React, { useMemo } from 'react';

import { Button, Link, Table, classNames } from 'fr-shared/components';

import styles from './PSLITable.module.css';
import { formatNullableDate } from './PSLITableEdit';

interface PSLITableShowProps {
  plannedShipmentLineItems: PlannedShipmentLineItem[];
}

export const getPsliStatusBadge = (is_existing_order: boolean, psli: PlannedShipmentLineItem) => {
  const badgeColor =
    psli.already_fulfilled === 0 ||
    psli.already_fulfilled < psli.quantity ||
    !psli.already_fulfilled
      ? 'secondary'
      : 'success';

  const badgeLabel = !is_existing_order
    ? 'Draft'
    : psli.already_fulfilled === 0 || !psli.already_fulfilled
    ? 'Planned'
    : psli.already_fulfilled < psli.quantity
    ? 'Partially Shipped'
    : 'Shipped';

  return (
    <span className={`badge badge-pill badge-${badgeColor} font-size-md`}>{badgeLabel}</span>
  );
};

const PSLITableShow = ({ plannedShipmentLineItems }: PSLITableShowProps) => {
  const columns = useMemo(
    () => [
      {
        Header: '',
        expander: true,
        sortable: false,
        id: 'expander',
        width: 80,
        Expander: (row: any) => (
          <div className="flex">
            {row.original.fulfillments && row.original.fulfillments.length > 0 && (
              <Button dataTestId="psli-table-show-sli-expander" size="sm" color="secondary">
                <i className={`fas fa-chevron-${row.isExpanded ? 'up' : 'down'}`} />
              </Button>
            )}
          </div>
        ),
      },
      {
        Header: 'Planned Shipment Line Item ID',
        id: 'psli-table-id',
        accessor: 'id',
        Cell: (d: any) => <div>{d.value}</div>,
      },
      {
        Header: 'Units Fulfilled',
        id: 'psli-table-units_fulfilled',
        sortable: false,
        accessor: (d: PlannedShipmentLineItem) => (
          <div>{`${d.already_fulfilled}/${d.quantity}`}</div>
        ),
      },
      {
        Header: 'Commit Date',
        id: 'psli-table-commit_date',
        accessor: 'commit_date',
        Cell: (d: any) => <div>{moment(d.value).format('MM/DD/YYYY')}</div>,
      },
      {
        Header: 'Customer Promise Date',
        id: 'psli-table-promised_ship_date',
        accessor: 'promised_ship_date',
        Cell: (d: any) => <div>{moment(d.value).format('MM/DD/YYYY')}</div>,
      },
      {
        Header: 'Supplier Promise Date',
        id: 'psli-table-supplier_promised_ship_date',
        accessor: 'supplier_promised_ship_date',
        Cell: (d: any) => <div>{formatNullableDate(d.value)}</div>,
      },
      {
        Header: 'Releases',
        id: 'psli-table-releases',
        accessor: (d: PlannedShipmentLineItem) => <div>{d.fulfillments.length || 0}</div>,
        sortable: false,
      },
      {
        Header: 'Status',
        id: 'psli-table-status',
        accessor: (d: PlannedShipmentLineItem) => <div>{getPsliStatusBadge(true, d)}</div>,
        sortable: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plannedShipmentLineItems]
  );

  if (!plannedShipmentLineItems?.length) return null;
  return (
    <Table
      data-testid="psli-table-show"
      sortable
      defaultSorted={[{ id: 'psli-table-commit_date', desc: false }]}
      data={plannedShipmentLineItems}
      pageSize={plannedShipmentLineItems.length || 5}
      className={classNames(['bg-white -striped mb-5', styles.PsliTable])}
      showPagination={false}
      columns={columns}
      SubComponent={(row: any) => {
        if (!row.original.fulfillments.length) {
          return null;
        } else {
          return (
            <Table
              data={row.original.fulfillments}
              pageSize={row.original.fulfillments.length}
              showPagination={false}
              className="-no-shadow"
              columns={[
                {
                  Header: 'Shipment ID',
                  id: 'psli-sub-table-shipment_id',
                  accessor: (d: PlannedShipmentLineItemFulfillment) => {
                    return (
                      <Link
                        dataTestId="psli_table_show_shipment_link"
                        to={{
                          pathname: `/admin/shipments/${d.shipment_id}`,
                        }}
                        openNewWindow
                      >
                        {d.shipment_id}
                      </Link>
                    );
                  },
                },
                {
                  Header: 'Units Shipped',
                  id: 'psli-sub-table-quantity',
                  accessor: (d: PlannedShipmentLineItemFulfillment) => <div>{d.quantity}</div>,
                },
                {
                  Header: 'Supplier Ship Date',
                  id: 'psli-sub-table-supplier_ship_date',
                  accessor: (d: PlannedShipmentLineItemFulfillment) => {
                    return <div>{moment(d.shipped_date).format('MM/DD/YYYY')}</div>;
                  },
                },
                {
                  Header: 'Ship To',
                  id: 'psli-sub-table-ship_to',
                  accessor: (d: PlannedShipmentLineItemFulfillment) => {
                    return <div>{d.ship_to}</div>;
                  },
                },
                {
                  Header: 'Tracking Number',
                  id: 'psli-sub-table-tracking_number',
                  accessor: (d: PlannedShipmentLineItemFulfillment) => {
                    return (
                      <Link
                        dataTestId="psli_table_show_tracking_link"
                        to={{
                          pathname: `https://www.google.com/search?q=${d.tracking_number}`,
                        }}
                        openNewWindow
                      >
                        {d.tracking_number}
                      </Link>
                    );
                  },
                },
              ]}
            />
          );
        }
      }}
    />
  );
};

export default PSLITableShow;
