import { ORDER_LINE_ITEM_STATES, ORDER_STATES } from 'fr-shared/lib/orders';

export const SELECTABLE_ORDER_LINE_ITEM_STATES = Object.values(ORDER_LINE_ITEM_STATES).filter(
  state =>
    ![
      ORDER_LINE_ITEM_STATES.ActionRequired,
      ORDER_LINE_ITEM_STATES.Draft,
      ORDER_LINE_ITEM_STATES.Pending,
    ].includes(state)
);

export const RFQ_ORDER_LINE_ITEM_STATES = [
  ORDER_LINE_ITEM_STATES.RFQSent,
  ORDER_LINE_ITEM_STATES.NeedsReview,
  ORDER_LINE_ITEM_STATES.NeedsRFQ,
];

export const SELECTABLE_ORDER_STATES = Object.values(ORDER_STATES).filter(
  state =>
    ![ORDER_STATES.ActionRequired, ORDER_STATES.Draft, ORDER_STATES.Pending].includes(state)
);
