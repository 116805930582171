import React from 'react';

import { classNames } from 'fr-shared/components';
import { pluralize } from 'fr-shared/utils';

interface PageResultsProps {
  className?: string;
  value?: number;
  context: string;
}

const PageResults = ({ className, value, context }: PageResultsProps) => {
  if (!value || value === 0) return null;

  return (
    <div
      className={classNames(['whitespace-nowrap border-left font-size-md ml-3 pl-3', className])}
    >
      {value} {pluralize(context, value !== 1)}
    </div>
  );
};

export default PageResults;
