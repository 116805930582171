import React, { LegacyRef } from 'react';

import { CheckboxLabel } from 'fr-shared/components';

interface CheckboxProps {
  /** Pass custom class name(s) to the parent element */
  className?: string;
  /** Sets whether the checkbox is a toggle switch, instead of checkbox */
  isToggle?: boolean;
  /** Sets whether the checkbox is readonly */
  readonly?: boolean;
  /** Sets whether the checkbox is required. */
  isRequired?: boolean;
  /** The label to be displayed to the right of the checkbox. The label is part of the clickable element to select the checkbox. */
  label: string | Node;
  /** The name of the form input */
  name: string;
  /** Error message to display if invalid */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** Only show label for screenreaders */
  srOnlyLabel?: boolean;
  /** Value of the raw HTML element's indeterminate property */
  indeterminate?: boolean;
  /** The state of the checkbox */
  value?: any; // this is bad because we use for `checked` and `value` which accept different types.
  /** Attribute used with react-testing-library to find DOM element */
  dataTestId?: string;
}

const Checkbox = ({
  className,
  indeterminate,
  isRequired,
  isToggle,
  label,
  name,
  onChange,
  readonly,
  srOnlyLabel,
  value,
  dataTestId,
  ...attrs
}: CheckboxProps) => {
  const inputRef: LegacyRef<HTMLInputElement> = React.useRef();
  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate;
    }
  });

  return (
    <CheckboxLabel
      className={className}
      dataTestId={`${dataTestId}-label`}
      name={name}
      isRequired={isRequired}
      isToggle={isToggle}
      label={label}
      srOnlyLabel={srOnlyLabel}
    >
      <input
        className="custom-control-input"
        data-testid={dataTestId}
        ref={inputRef}
        id={name}
        name={name}
        onChange={onChange}
        readOnly={readonly}
        type="checkbox"
        checked={value}
        value={value}
        {...attrs}
      />
    </CheckboxLabel>
  );
};

Checkbox.defaultProps = {
  label: '',
};

export default Checkbox;
