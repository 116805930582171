import React from 'react';

import { IconFont, Link } from 'fr-shared/components';
import { usePortalSubscription } from 'fr-shared/hooks';

import { SubscriptionUpgradeCard, classNames } from 'portal/components';

import styles from './StudioCta.module.css';

export const StudioCta = () => {
  const isPortalSubscribed = usePortalSubscription();
  const searchParams = new URLSearchParams(window.location.search);
  const pro = searchParams.get('pro');

  const ctaButtonStyles = classNames([
    !isPortalSubscribed && styles.CtaButtonUnsubscribed,
    styles.CtaButton,
  ]);

  return (
    <div
      className={classNames([!isPortalSubscribed && styles.WrapperUnsubscribed, styles.Wrapper])}
    >
      <Link className={ctaButtonStyles} to="/studio/evaluate">
        <div className={styles.BackgroundSvg} />
        <div className="relative z-1">
          <IconFont name="icon-lightbulb" className="icon-lightbulb" />
          <span>Evaluate your parts</span>
          <p>
            Check your part files for manufacturability. And see suggested ways to optimize your
            designs.
          </p>
        </div>
      </Link>
      <Link className={ctaButtonStyles} to="/add-part">
        <div className={styles.BackgroundSvg} />
        <div className="relative z-1">
          <IconFont name="icon-quote" className="icon-quote" />
          <span>Create a quote</span>
          <p>
            Get your next quote fast. All you need is a part file. Or you can request a call with
            us.
          </p>
        </div>
      </Link>
      {!isPortalSubscribed && (
        <SubscriptionUpgradeCard
          analyticsContext="Dashboard"
          className={classNames([!isPortalSubscribed && styles.CtaButtonUnsubscribed])}
          modalDescription
          openModalOnMount={pro === 'true'}
        />
      )}
    </div>
  );
};

export default StudioCta;
