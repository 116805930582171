import PropTypes from 'prop-types';
import React from 'react';

import styles from './TableBodyCell.module.css';

export const TableBodyCell = ({ className = '', align, children, dataTestId, value, width }) => (
  <td
    className={`${styles.Root} ${className}`}
    data-testid={dataTestId}
    style={{ textAlign: align, width }}
  >
    {children || value}
  </td>
);

TableBodyCell.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.string,
};

export default TableBodyCell;
