import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button, Icon, classNames } from 'fr-shared/components';
import { usePSLIFeatureFlagLimit } from 'fr-shared/hooks';
import { ORDER_LINE_ITEM_STATES } from 'fr-shared/lib/orders';
import { quantityShipped } from 'fr-shared/lib/shipping';

import styles from './ShippingInformation.module.css';

interface ShippingInformation {
  fromShippingTab: boolean;
  isExistingOrder: boolean;
  lineItem: OrderLineItem;
  oliIndex: number;
  showShippingInfo?: boolean;
  toggleShippingInfo?: () => void;
}

const ShippingInformation = ({
  fromShippingTab,
  isExistingOrder,
  lineItem,
  oliIndex,
  showShippingInfo,
  toggleShippingInfo,
}: ShippingInformation) => {
  const [plannedQuantity, setPlannedQuantity] = useState(0);
  const [numValidPSLI, setNumValidPSLI] = useState(0);
  const location = useLocation();
  const isEditingOrder = location.pathname.includes('edit');
  const editUrl = isEditingOrder
    ? `${location.pathname}?oli_index=${oliIndex}#shipping`
    : `${location.pathname}/edit?oli_index=${oliIndex}#shipping`;

  useEffect(() => {
    if (Array.isArray(lineItem.planned_shipment_line_items)) {
      const existingPlannedPSLIQuantity = lineItem.planned_shipment_line_items
        .filter((psli: PlannedShipmentLineItem) => psli.id !== undefined && !psli.valid)
        .reduce((acc: number, current: PlannedShipmentLineItem) => {
          const quantityInt = current.quantity ? current.quantity : 0;
          return acc + quantityInt;
        }, 0);

      const existingValidPSLICount = lineItem.planned_shipment_line_items.filter(
        (psli: PlannedShipmentLineItem) => psli.id !== undefined && !psli.valid
      ).length;

      const planningQuantity = lineItem.planned_shipment_line_items
        .filter((psli: PlannedShipmentLineItem) => psli.valid)
        .reduce((acc: number, current: PlannedShipmentLineItem) => {
          return acc + current.quantity;
        }, 0);

      const planningValidPSLICount = lineItem.planned_shipment_line_items.filter(
        (psli: PlannedShipmentLineItem) => psli.valid
      ).length;

      setPlannedQuantity(existingPlannedPSLIQuantity + planningQuantity);
      setNumValidPSLI(existingValidPSLICount + planningValidPSLICount);
    }
  }, [lineItem]);

  const lineItemQty = lineItem.quantity || 0;
  const psliLimit = usePSLIFeatureFlagLimit() || 0;
  const screenshot = get(lineItem, 'part.current_revision.screenshot');

  const canAddPSLI = () => {
    if (lineItemQty === plannedQuantity) {
      return false;
    }
    // leaving as separate if statement for easy code cleanup in future once
    // we determined PSLI feature is stable and to be turned on for everyone
    if (numValidPSLI >= psliLimit) {
      return false;
    }
    // There is empty list of valid PSLIs or the sum of valid PSLI quantity is
    // less than OLI quantity.
    return true;
  };

  const alertIconStyle =
    canAddPSLI() || numValidPSLI === 0
      ? { color: 'red', name: 'exclamation-triangle' }
      : { color: 'green', name: 'check-circle' };

  return (
    <div className="flex flex-row w-100 justify-content-between">
      {fromShippingTab ? (
        <div className="flex-row">
          <img
            width={40}
            height={40}
            className="rounded mr-2"
            src={screenshot}
            alt={`screenshot of ${lineItem.description}`}
          />
          <strong>{lineItem.description}</strong>
          <span className="ml-2 badge badge-indicator bg-gray-100">{lineItem.state}</span>
        </div>
      ) : (
        <h4 className="flex-fill m-0">Shipping Information</h4>
      )}

      <div className="flex font-size-md">
        <div
          data-testid="planned-shipment-lineitems-counter"
          className={classNames(['rounded pl-1 pr-1 mr-4', styles.ShippingInformationAlert])}
        >
          <div>{`${numValidPSLI}/${psliLimit} Planned Shipments`}</div>
        </div>
        <div
          data-testid="planned-shipment-lineitems-quantity"
          className={classNames(['row rounded pl-1 pr-1 mr-1', styles.ShippingInformationAlert])}
        >
          <Icon
            name={alertIconStyle.name}
            className={classNames(
              `font-size-lg mr-1 mt-auto mb-auto text-${alertIconStyle.color}`
            )}
          />
          {numValidPSLI === 0 ? (
            <div>{`- / - Units Planned`}</div>
          ) : (
            <div>{`${plannedQuantity}/${lineItemQty} Units Planned`}</div>
          )}
        </div>

        <div>
          <span
            className={classNames([
              'badge badge-indicator mr-2 bg-gray-100',
              styles.ShippingInformationAlert,
            ])}
          >
            {quantityShipped(lineItem)}/{lineItemQty} Units Shipped
          </span>
        </div>

        {(!toggleShippingInfo || !isExistingOrder) &&
          !fromShippingTab &&
          lineItem.state !== ORDER_LINE_ITEM_STATES.Shipped &&
          lineItem.state !== ORDER_LINE_ITEM_STATES.Pending && (
            <Button
              dataTestId={`edit-shipping-information-${oliIndex}`}
              outline
              to={editUrl}
              className={classNames(['pl-2 pr-2', styles.button])}
              color="primary"
            >
              Edit
            </Button>
          )}

        {toggleShippingInfo && isExistingOrder && (
          <Button
            className="ml-2 mb-1"
            color="secondary"
            dataTestId={`toggle-shipping-info-${oliIndex}`}
            onClick={toggleShippingInfo}
            outline
            size="md"
          >
            <Icon name={`angle-${showShippingInfo ? 'up' : 'down'}`} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default ShippingInformation;
