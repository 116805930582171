import React from 'react';

import { classNames } from 'portal/components';

import styles from './Panel.module.css';

export const PanelWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="w-100 ml-auto mr-auto flex flex-column flex-fill">
      <div className="row lg:h-screen lg:overflow-hidden flex-fill mr-0 ml-0">{children}</div>
    </div>
  );
};

const Panel = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  return (
    <div
      className={classNames([
        'portal-panel flex flex-column overflow-y-auto overflow-x-hidden bg-gray-600',
        styles.Panel,
        className,
      ])}
    >
      {children}
    </div>
  );
};

export const PanelBottom = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return <div className={classNames([styles.PanelBottom, className])}>{children}</div>;
};

Panel.Wrapper = PanelWrapper;
Panel.Bottom = PanelBottom;

export default Panel;
