import { sortBy, uniqueId } from 'lodash';
import React, { useCallback } from 'react';

import { FormField, classNames } from 'fr-shared/components';

interface FormSelectProps {
  hasBlankOption?: boolean;
  labelClassName?: string;
  inputClassName?: string;
  label?: string;
  labelWrap?: boolean;
  name: string;
  inputProps?: any;
  stacked?: boolean;
  style?: any;
  nameAccessor?: (subject: any) => string;
  onBlur?: (event: any) => void;
  onChange?: (subject: Object) => void;
  optionList?: Object[] | string[];
  placeholder?: string;
  floatingLabel?: boolean;
  readonly?: boolean | string;
  required?: boolean;
  sorted?: boolean;
  valueAccessor?: (subject: any) => string | number;
}

interface DefaultIdAccessorCompatible {
  name: string;
}

interface DefaultValueAccessorCompatible {
  id: string | number;
}

const FormSelect: React.FC<FormSelectProps> = ({
  hasBlankOption = true,
  labelClassName,
  inputClassName,
  name,
  nameAccessor = (option: DefaultIdAccessorCompatible) => option.name,
  onChange,
  optionList,
  placeholder,
  readonly = false,
  required = false,
  sorted = false,
  valueAccessor = (option: DefaultValueAccessorCompatible) => option.id,
  ...formFieldProps
}: FormSelectProps) => {
  const safeOptionList = optionList ?? [];

  const sortListAlphabetically = useCallback(
    list => {
      return sortBy(list, nameAccessor);
    },
    [nameAccessor]
  );

  const options = sorted ? sortListAlphabetically(safeOptionList) : safeOptionList;

  return (
    <FormField
      {...formFieldProps}
      readonly={readonly}
      name={name}
      labelClassName={labelClassName}
      inputClassName={classNames(['custom-select', inputClassName])}
      component="select"
      required={formFieldProps.floatingLabel || required}
      onChange={onChange}
      labelWrap={formFieldProps.labelWrap}
    >
      {placeholder && (
        <option value="" disabled>
          {placeholder}
        </option>
      )}
      {hasBlankOption && !placeholder && <option value="" />}
      {options.map(option => (
        <option key={`${uniqueId()}-${valueAccessor(option)}`} value={valueAccessor(option)}>
          {nameAccessor(option)}
        </option>
      ))}
    </FormField>
  );
};

export default FormSelect;
