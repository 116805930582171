import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { IconFont } from 'fr-shared/components';
import { ORDER_STATES } from 'fr-shared/lib/orders';

import {
  Button,
  LineItemCard,
  Money,
  PartImage,
  StateBadge,
  classNames,
} from 'portal/components';
import { date } from 'portal/lib/date';

import styles from './OrderLineItemCard.module.css';
import PartTracker from './PartTracker/PartTracker';
import PartTrackerModal from './PartTrackerModal/PartTrackerModal';

const OrderLineItemCard = ({ isCanceled, lineItem, onReorderClicked, orderSubmitted }) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [displayMoreDropdown, setDisplayMoreDropdown] = useState(false);

  if (!lineItem) return null;

  const showPartTracker = !lineItem.is_assembly && !isCanceled;
  const showReorder = !lineItem.is_assembly && lineItem.state === ORDER_STATES.Shipped;

  return (
    <LineItemCard title={lineItem.description}>
      <div className="flex w-[100%]">
        <LineItemCard.StartCell>
          {showPartTracker ? (
            <>
              <PartTracker screenshot={lineItem.screenshot} state={lineItem.state} />
              <div className="mt-1">
                <StateBadge state={lineItem.state} glow={true}>
                  {lineItem.state}
                </StateBadge>
              </div>
              {lineItem.promised_ship_date && (
                <div className="text-gray font-size-sm mt-2">
                  Est. ship date: <strong>{date(lineItem.promised_ship_date)} </strong>
                </div>
              )}
            </>
          ) : (
            <PartImage src={lineItem.screenshot} />
          )}
        </LineItemCard.StartCell>
        <LineItemCard.CenterCell lineItem={lineItem} />
        <LineItemCard.EndCell>
          <div className="flex font-size-sm justify-content-between">
            <p className="text-gray mb-1">Unit price</p>
            <Money amount={lineItem?.sales_price?.amount} className="text-gray font-size-md" />
          </div>
          <div className="flex font-size-base justify-content-between">
            <p className="text-light mb-1">Item total</p>
            <Money
              amount={lineItem?.total_price?.amount}
              className="text-light font-weight-bolder font-size-lg"
            />
          </div>
          {showReorder && (
            <div className="flex mt-2">
              <Button className="flex-1" outline onClick={() => onReorderClicked(lineItem)}>
                Reorder item
              </Button>
            </div>
          )}
        </LineItemCard.EndCell>
      </div>
      {/* TODO CX-711: Remove manufacturing details after MXD/J&J project is over. */}
      {lineItem.manufacturing_details?.length > 0 &&
        lineItem.manufacturing_details.map((details, index) => {
          return <LineItemCard.ManufacturingDataRow key={index} manufacturingDetails={details} />;
        })}

      <LineItemCard.MoreInfo>
        <nav className={styles.MoreDetails}>
          <Button
            color="link"
            className={styles.MoreDetailsBtn}
            onClick={() => setDisplayMoreDropdown(!displayMoreDropdown)}
          >
            <IconFont name="more" />
          </Button>
          <ul
            className={classNames([
              styles.MoreDetailsDropdown,
              displayMoreDropdown && styles['active'],
            ])}
          >
            <li>
              <Button
                color="link"
                onClick={() => {
                  setDisplayMoreDropdown(false);
                  setDisplayModal(!displayModal);
                }}
                className={styles.MoreDetailsDropdownBtn}
              >
                <IconFont name="icon-history-log" className="icon-history-log" />{' '}
                <span>View part tracker</span>
              </Button>
            </li>
          </ul>
        </nav>
      </LineItemCard.MoreInfo>
      <PartTrackerModal
        lineItem={lineItem}
        orderSubmitted={orderSubmitted}
        toggleDisplayModal={() => setDisplayModal(currentDisplayModal => !currentDisplayModal)}
        displayModal={displayModal}
      />
    </LineItemCard>
  );
};

OrderLineItemCard.propTypes = {
  isCanceled: PropTypes.bool,
  lineItem: PropTypes.shape({
    description: PropTypes.string.isRequired,
    is_assembly: PropTypes.bool,
    part: PropTypes.shape({
      id: PropTypes.number,
      units: PropTypes.string,
    }),
    manufacturing_details: PropTypes.arrayOf(
      PropTypes.shape({
        detailing_agent_lot_number: PropTypes.string,
        fusing_agent_lot_number: PropTypes.string,
        lot_quantity: PropTypes.number,
        mpi_link: PropTypes.string,
        powder_lot_number: PropTypes.string,
        printer_name: PropTypes.string,
      })
    ),
    quantity: PropTypes.number,
    promised_ship_date: PropTypes.string.isRequired,
    screenshot: PropTypes.string,
    state: PropTypes.string.isRequired,
    sales_price: PropTypes.object,
    total_price: PropTypes.object,
  }).isRequired,
  onReorderClicked: PropTypes.func,
  orderSubmitted: PropTypes.string,
};

export default OrderLineItemCard;
