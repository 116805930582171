import React from 'react';

import { TableBodyCell, TableBodyRow } from 'portal/components';

import styles from '../PartTrackerModal.module.css';
import { ShipmentRowsProps } from '../types';
import { formatDate } from '../utils';

const ShipmentRows = ({ shipmentLineItems, qtyOrdered }: ShipmentRowsProps) => {
  if (!shipmentLineItems.length) return null;

  const ShipmentRowsList = ({ shipmentLineItems, qtyOrdered }: ShipmentRowsProps) => {
    return (
      <>
        {shipmentLineItems.map((val, index) => {
          const { shipped_date, quantity } = val;
          const [orderDate] = formatDate(shipped_date);

          return (
            <TableBodyRow key={index} className={styles.TableThinRows}>
              <TableBodyCell>
                <span>Shipped</span>
              </TableBodyCell>
              <TableBodyCell>
                <span>{orderDate}</span>
              </TableBodyCell>
              <TableBodyCell>
                <span>{quantity}</span>/{qtyOrdered}
              </TableBodyCell>
            </TableBodyRow>
          );
        })}
      </>
    );
  };

  return (
    <>
      <TableBodyRow className={styles.TablePostSectionDivider}>
        <TableBodyCell>
          <strong>
            <span>Shipped</span>
          </strong>
        </TableBodyCell>
        <TableBodyCell></TableBodyCell>
        <TableBodyCell></TableBodyCell>
      </TableBodyRow>
      <ShipmentRowsList shipmentLineItems={shipmentLineItems} qtyOrdered={qtyOrdered} />
    </>
  );
};

export default ShipmentRows;
