/* eslint-disable react/display-name,react/prop-types */
import { values } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, Button, Helmet, Link, Table } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';

const LeadTimesIndex = () => {
  const [leadTimes, setLeadTimes] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    api.get('/lead_times').then(res => {
      setLeadTimes(values(res.data));
    });
  }, []);

  return (
    <>
      <Helmet title="Lead Times" />
      <Breadcrumb to="/admin/lead_times">Lead Times</Breadcrumb>
      <div className="container">
        <div className="flex align-items-center py-3">
          <h2 className="mb-0">Lead Times</h2>
          <div className="ml-auto">
            {user.canCreateLeadTimes && (
              <Button color="primary" to="/admin/lead_times/new">
                Add a lead time
              </Button>
            )}
          </div>
        </div>
        <Table
          data={leadTimes}
          pageSize={leadTimes.length < 20 ? leadTimes.length : 20}
          className="bg-white -striped mb-5"
          columns={[
            { Header: 'Manufacturing Process', accessor: 'manufacturing_process.name' },
            { Header: 'Supplier Name', accessor: 'location.name' },
            { Header: 'Minimum Lead Time (days)', accessor: 'min_days' },
            { Header: 'Maximum Lead Time (days)', accessor: 'max_days' },
            {
              show: user.canUpdateLeadTimes,
              Header: '',
              id: 'Update',
              Cell: row => {
                const manufacturing_process_id = row.original.manufacturing_process.id;
                const location_id = row.original.location_id;
                return (
                  <Link
                    to={{
                      pathname: `/admin/lead_times/new`,
                      state: { ...row.original, manufacturing_process_id, location_id },
                    }}
                  >
                    Update
                  </Link>
                );
              },
            },
          ]}
        />
      </div>
    </>
  );
};

export default LeadTimesIndex;
