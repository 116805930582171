import { useEffect, useState } from 'react';

import { api } from 'fr-shared/api';

const useAssetsChecksum = () => {
  const [assetsChecksum, setAssetsChecksum] = useState(process.env.ASSETS_CHECKSUM ?? null);

  useEffect(() => {
    const assetsChecksumInterceptor = api.interceptors.response.use(
      response => {
        const assetsChecksum = response.headers['assets-checksum'] ?? null;
        if (assetsChecksum !== null) {
          setAssetsChecksum(assetsChecksum);
        }

        return response;
      },
      error => {
        const assetsChecksum = error?.headers?.['assets-checksum'] ?? null;
        if (assetsChecksum !== null) {
          setAssetsChecksum(assetsChecksum);
        }

        return Promise.reject(error);
      }
    );

    return () => {
      api.interceptors.response.eject(assetsChecksumInterceptor);
    };
  }, []);

  return assetsChecksum;
};

export default useAssetsChecksum;
