import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';

import { PartFormState } from 'portal/pages/part_config/components/PartFormFields';
import { CNC_DEFAULT_VALUES } from 'portal/pages/part_config/util';

// Returns an object with default formState values based of manufacturing process
function useLineItemDefaults(process: ManufacturingProcess): PartFormState {
  {
    const changes: PartFormState = { manufacturing_process_id: process?.id, is_prototype: false };
    const autoquotableDefaults = process?.autoquotable_defaults;
    // Default CNC values if we changed from non-CNC to CNC
    if (process?.name === MANUFACTURING_PROCESSES.CNC) {
      // NOTE: The autoquotable_defaults object for CNC only has the material_id
      return autoquotableDefaults
        ? { ...changes, ...CNC_DEFAULT_VALUES, ...autoquotableDefaults }
        : { ...changes, ...CNC_DEFAULT_VALUES };
    } else {
      if (autoquotableDefaults) {
        changes.material_id = autoquotableDefaults.material_id;
        if (autoquotableDefaults?.color_id) changes.color_id = autoquotableDefaults.color_id;

        if (autoquotableDefaults?.layer_thickness_id)
          changes.layer_thickness_id = autoquotableDefaults.layer_thickness_id;
      }
      return { customer_portal_finish: 'Standard', ...changes };
    }
  }
}

export default useLineItemDefaults;
