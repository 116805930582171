import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { AlertContext } from 'fr-shared/context';

import { Page } from 'portal/components';

import Confirmation from './components/Confirmation';

const ManualQuoteConfirmationContainer = () => {
  const [order, setOrder] = useState(null);
  const history = useHistory();
  const { setAlert } = useContext(AlertContext);
  const { id }: any = useParams();

  useEffect(() => {
    api
      .get(`/customer_portal/orders/${id}`)
      .then(res => {
        setOrder(res.data);
      })
      .catch(() => {
        setAlert({ color: 'danger', message: 'We were unable to load your order confirmation' });
        history.push('/orders');
      });
  }, [history, id, setAlert]);

  if (!order) return null;

  return (
    <Page>
      <Confirmation
        quote={order}
        quoteNumber={order.public_id}
        status={order.state}
        isOrder={true}
        paymentTerms={order?.payment_terms}
        poNumber={order?.customer_po}
      />
    </Page>
  );
};

export default ManualQuoteConfirmationContainer;
