import { lowerCase, startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { AddressModal, Button, Table } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { AddressTypeEnum, AddressTypes } from 'fr-shared/lib/address';

const AddressTable = ({ addresses, userId, type, onDelete, onSave }) => {
  const { setAlert } = useContext(AlertContext);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [activeAddress, setActiveAddress] = useState(null);

  const toggle = () => {
    setIsEditingAddress(!isEditingAddress);
  };

  const handleDelete = () => {
    setAlert({
      message: `${type} information successfully ${isEditingAddress ? 'saved' : 'added'}.`,
      color: 'success',
    });
    onDelete();
  };

  const handleEditingAddress = row => {
    setActiveAddress(row.original);
    toggle();
  };

  const handleError = () => {
    setAlert({
      message: `Failed to ${
        isEditingAddress ? 'save' : 'add'
      } address. Please refresh the page to try again`,
      color: 'danger',
    });
  };

  const handleSave = address => {
    setAlert({ message: `${type} information successfully saved.`, color: 'success' });
    onSave(address);
  };

  const editAddressButton = row => {
    return (
      <Button
        className="p-0"
        key={row.index}
        onClick={() => handleEditingAddress(row)}
        color="link"
      >
        Edit
      </Button>
    );
  };

  const editButtonColumn = () => {
    return [
      {
        Header: '',
        id: 'edit_actions',
        Cell: row => editAddressButton(row),
      },
    ];
  };

  return (
    <>
      <Table
        data={addresses}
        className="bg-white -left -hover -striped"
        pageSize={addresses.length}
        noDataText={`No ${lowerCase(type)} information yet`}
        showPagination={false}
        defaultSorted={[{ id: 'created', desc: true }]}
        columns={[...columnsByType(type), ...defaultColumn(), ...editButtonColumn()]}
      />
      <AddressModal
        address={activeAddress}
        backdrop="static"
        isEdit={isEditingAddress}
        isOpen={isEditingAddress}
        onDelete={handleDelete}
        onError={handleError}
        onSave={handleSave}
        title={`Edit ${startCase(type)} Information`}
        toggle={toggle}
        type={type}
        userId={userId}
      />
    </>
  );
};

AddressTable.propTypes = {
  addresses: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  type: PropTypes.oneOf(AddressTypes).isRequired,
  userId: PropTypes.string.isRequired,
};

const columnsByType = type => {
  switch (type) {
    case AddressTypeEnum.Billing:
      return billingAddressColumns();
    case AddressTypeEnum.Shipping:
      return shippingAddressColumns();
  }
};

const billingAddressColumns = () => {
  return [
    {
      Header: 'Full Name',
      id: 'name_line_1',
      accessor: address => address.name_line_1,
    },
    {
      Header: 'Country',
      id: 'country',
      accessor: address => address.country,
    },
    {
      Header: 'Address',
      id: 'address_line_1',
      accessor: address => address.street_line_1,
    },
    {
      Header: 'Address 2',
      id: 'address_line_2',
      accessor: address => address.street_line_2,
    },
    {
      Header: 'City',
      id: 'city',
      accessor: address => address.city,
    },
    {
      Header: 'State',
      id: 'state',
      accessor: address => address.state,
    },
    {
      Header: 'Zip',
      id: 'zip',
      accessor: address => address.postal_code,
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: address => address.email,
    },
  ];
};

const shippingAddressColumns = () => {
  return [
    {
      Header: 'Full Name',
      id: 'name_line_1',
      accessor: address => address.name_line_1,
    },
    {
      Header: 'Company',
      id: 'name_line_2',
      accessor: address => address.name_line_2,
    },
    {
      Header: 'Country',
      id: 'country',
      accessor: address => address.country,
    },
    {
      Header: 'Address',
      id: 'address_line_1',
      accessor: address => address.street_line_1,
    },
    {
      Header: 'Address 2',
      id: 'address_line_2',
      accessor: address => address.street_line_2,
    },
    {
      Header: 'City',
      id: 'city',
      accessor: address => address.city,
    },
    {
      Header: 'State',
      id: 'state',
      accessor: address => address.state,
    },
    {
      Header: 'Zip',
      id: 'zip',
      accessor: address => address.postal_code,
    },
    {
      Header: 'Phone Number',
      id: 'phone_number',
      accessor: address => address.phone_number,
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: address => address.email,
    },
  ];
};

const defaultColumn = () => {
  const defaultIndicator = <div className="badge badge-primary">DEFAULT</div>;
  return [
    {
      Header: '',
      id: 'is_default',
      accessor: address => {
        return address.is_default ? defaultIndicator : '';
      },
    },
  ];
};

export default AddressTable;
