import { useQuery } from 'react-query';

import { api } from 'fr-shared/api';

const fetchPrinters = (params = {}) => {
  const { manufacturingProcess } = params;

  let translatedParams = {};
  if (params.manufacturingProcess) translatedParams.manufacturing_process = manufacturingProcess;

  return api.get('/printers', { params: translatedParams }).then(res => res.data);
};

const usePrinters = (params = {}) => {
  const { manufacturingProcess } = params;
  const cacheKey = ['printers', manufacturingProcess ?? ''];
  return useQuery(cacheKey, () => fetchPrinters(params), {
    refetchOnWindowFocus: false,
    staleTime: 2000,
  });
};

export default usePrinters;
