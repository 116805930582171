import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Card, FormCheckbox, FormField, Prompt, ServerError } from 'fr-shared/components';
import { COUNTRIES } from 'fr-shared/lib/countries';

const defaultValues = {
  region: COUNTRIES.UNITED_STATES,
  active: true,
};

const SupplierForm = ({ initialValues, onSubmit, title }) => {
  return (
    <Formik onSubmit={onSubmit} initialValues={{ ...defaultValues, ...initialValues }}>
      {formik => (
        <div className="container my-3">
          <ServerError errors={formik.errors?.server} />
          <Prompt
            message={
              'You\'re about to deactivate this supplier. They will no longer be selectable for production. \n\nYou can reactive them later by coming back to this page, checking "Active" and clicking "Save"'
            }
            when={formik.dirty && !formik.values.active}
          />
          <Card border>
            <Card.Header>
              <h2>{title}</h2>
            </Card.Header>
            <Card.Body>
              <Form className="form-group">
                <div className="row">
                  <div className="col">
                    <FormField label="Address" name="address" required type="text" />
                    <FormField label="Address 2" name="address2" type="text" />
                    <FormField label="City" name="city" required type="text" />
                    <FormField label="State/Region" name="state_region" required type="text" />
                    <FormField label="Zip" name="zip" required type="text" />
                    <FormField label="Country" name="country" required type="text" />
                    <FormField
                      label="Estimated Duties and Taxes"
                      name="tax_percentage"
                      type="number"
                      required
                      append={'%'}
                      inputProps={{ min: 0, max: 100, step: 0.01 }}
                    />
                  </div>
                  <div className="col">
                    <FormField
                      className="mr-3"
                      label="Supplier Name"
                      name="name"
                      required
                      type="text"
                    />
                    <FormField
                      className="mr-3"
                      component="select"
                      label="Region"
                      name="region"
                      required
                    >
                      <option value="" />
                      <option value={COUNTRIES.UNITED_STATES}>{COUNTRIES.UNITED_STATES}</option>
                      <option value="Asia Pacific">Asia Pacific</option>
                    </FormField>
                    <FormField
                      className="mr-3"
                      label="aPriori VPE Name"
                      name="apriori_vpe_name"
                      type="text"
                    />
                    <FormCheckbox label="In House" name="in_house" />
                    {!isEmpty(initialValues) && <FormCheckbox label="Active" name="active" />}
                  </div>
                </div>
                <div className="text-right">
                  <Button type="submit" color="success" id="submit-button">
                    Save Supplier
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      )}
    </Formik>
  );
};

SupplierForm.propTypes = {
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    in_house: PropTypes.bool,
    region: PropTypes.string,
  }),
  active: PropTypes.bool,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
};

export default SupplierForm;
