import { useTreatments } from '@splitsoftware/splitio-react';
import { useFormikContext } from 'formik';
import { get, set } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import {
  BulletPointAdviceModal,
  FormCurrency,
  FormNumber,
  FormTextArea,
  Icon,
  Sticky,
} from 'fr-shared/components';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { keyboardDown } from 'fr-shared/utils';

import QuoteFormAdjustments from './QuoteFormAdjustments';
import { QuoteFormContext } from './QuoteFormContext';

const ADJUSTMENT_LABELS = {
  discount: 'Unit Price Discount',
  extra: 'Extra',
};

const INSPECTION_PRICE_ADJUSTMENT =
  'Unit Sale Price is automatically adjusted to include inspection costs.';

const QuoteFormMargins = ({ readonly, index, lineItem }) => {
  const formik = useFormikContext();
  const { setValuesAndPushToChannel } = useContext(QuoteFormContext);
  const [open, setOpen] = useState(lineItem.open);
  const [unitPriceOnFocusAmount, setUnitPriceOnFocusAmount] = useState(
    lineItem?.unit_price?.amount
  );
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';

  const name = `line_items.${index}`;

  /**
   * Watches the state of the line item to know when to open/close,
   * used by the Expand/Collapse buttons
   * @param {bool} lineItem.open - state of the line item to watch
   */
  useEffect(() => {
    setOpen(lineItem.open === undefined ? true : lineItem.open);
  }, [lineItem.open]);

  const synchronizeTargetPriceToUnitPrice = e => {
    formik.setFieldValue(`${name}.target_price`, e.target.value);
  };

  const handleMarginBlur = () => {
    // remove `target_price` here before sending to pricing channel
    // forcing the margin to calculate the unit sale price by margin
    const values = { ...formik.values };
    set(values, `${name}.target_price`, null);
    setValuesAndPushToChannel(values);
  };

  const handleUnitPriceBlur = values => {
    if (unitPriceOnFocusAmount !== values.line_items[index]?.unit_price?.amount) {
      set(values, `${name}.target_price`, lineItem.unit_price.amount);
      setValuesAndPushToChannel(values);
    }
  };

  const handleUnitPriceFocus = () => {
    // record price on focus in order to prevent unnecessary price updates in
    // handleUnitPriceBlur
    setUnitPriceOnFocusAmount(lineItem?.unit_price?.amount);
  };

  return (
    <div className="part-details">
      <div
        onClick={() => setOpen(open => !open)}
        className="part-details-header cursor-pointer flex"
        onKeyDown={evt => keyboardDown(evt, 'Enter', () => setOpen(open => !open))}
        role="button"
        tabIndex={0}
      >
        Margins and Mark Ups
        <div className="ml-auto">
          <Icon name={open ? 'chevron-up' : 'chevron-down'} />
        </div>
      </div>
      {open && (
        <div className="part-details-body row">
          <div className="col-md">
            <h5>Mark Ups Determined At Costing</h5>
            <FormTextArea
              readonly={readonly}
              label="Finish Description"
              placeholder="Enter information on finishing"
              name={`${name}.finish_note`}
              stacked={true}
              helpText="Text entered here will appear on the quote"
            />
            <hr />
            <h5>Your Mark Ups</h5>
            <div className="row mb-2">
              <div className="col-md">
                <FormNumber
                  readonly={readonly}
                  append="%"
                  label="Margin"
                  min={0}
                  max={99.9999}
                  step={0.0001}
                  helpText={get(lineItem, 'margin_value.formatted')}
                  onBlur={handleMarginBlur}
                  stacked={true}
                  name={`${name}.margin_percentage`}
                />
              </div>
              <div className="col-md"></div>
            </div>
            <QuoteFormAdjustments readonly={readonly} index={index} />
            <br />
            <FormNumber
              readonly={readonly}
              append="business days"
              helpText="Text entered here will appear on the quote"
              isStacked={true}
              label="Lead Time"
              min={1}
              name={`${name}.offered_lead_time`}
              onlyShowErrorIfTouched={true}
              placeholder="Number of business days"
            />
            <FormTextArea
              readonly={readonly}
              name={`${name}.notes`}
              label="Notes"
              isStacked={true}
              placeholder={`Add a note about the ${lineItemName}`}
              advice={
                !readonly && (
                  <div className="align-items-center flex flex-wrap font-size-sm justify-content-between">
                    <em className="text-secondary">Text entered here will appear on the quote</em>
                    <BulletPointAdviceModal />
                  </div>
                )
              }
            />
          </div>
          <div className="col-md relative mb-3" id={`quote_margin_${index}`}>
            <Sticky top={60} bottomBoundary={`#quote_margin_${index}`}>
              <h5>Price Calculation</h5>
              <ul>
                <li className="flex items-center mb-1" data-testid="unit-cost">
                  Unit Cost{' '}
                  {lineItem.supplier_location ? `(${lineItem.supplier_location.name})` : ''}
                  <div className="ml-auto">
                    <strong>{lineItem?.unit_cost?.formatted}</strong>
                  </div>
                </li>
                <li className="flex items-center mb-1">
                  Margin {lineItem.margin_percentage > 0 && `(${lineItem.margin_percentage}%)`}
                  <div className="ml-auto">
                    <strong>{get(lineItem, 'margin_value.formatted', '--')}</strong>
                  </div>
                </li>
                {lineItem.inspection_price && lineItem.inspection_price.amount !== '0.00' && (
                  <li className="flex items-center mb-1">
                    Inspection ({lineItem.inspection_type.name})
                    <div className="ml-auto">
                      <strong>{lineItem.inspection_price.formatted}</strong>
                    </div>
                  </li>
                )}
                {lineItem.adjustments?.length > 0 &&
                  lineItem.adjustments.map(
                    (adjustment, index) =>
                      adjustment.amount && (
                        <li className="flex items-center mb-1" key={`adjustment-${index}`}>
                          {ADJUSTMENT_LABELS[adjustment.type]}{' '}
                          {adjustment.description && `(${adjustment.description})`}
                          <div className="ml-auto">
                            <strong>{get(adjustment, 'amount.formatted', '--')}</strong>
                          </div>
                        </li>
                      )
                  )}
                <li className="flex items-center mb-1 mt-4">
                  <strong>Unit Sale Price&nbsp;</strong>
                  {lineItem.inspection_price && lineItem.inspection_price.amount !== '0.00' && (
                    <Icon
                      name="info-circle"
                      data-testid="inspection-sales-price-info-icon"
                      className="text-primary-link"
                      tooltip={INSPECTION_PRICE_ADJUSTMENT}
                    />
                  )}
                  <div className="ml-auto">
                    <FormCurrency
                      name={`${name}.unit_price.amount`}
                      dataTestId="unit-sale-price"
                      label="Unit Sale Price"
                      inputClassName="text-right mr-n1 font-weight-bolder"
                      onBlur={handleUnitPriceBlur}
                      onFocus={handleUnitPriceFocus}
                      onChange={synchronizeTargetPriceToUnitPrice}
                      readonly={readonly}
                      showLabel={false}
                    />
                  </div>
                </li>
                <li className="flex items-center mb-1">
                  <strong>Total ({lineItem.quantity} units)</strong>
                  <div className="ml-auto">
                    <strong>{get(lineItem, 'total_price.formatted', '--')}</strong>
                  </div>
                </li>
              </ul>
            </Sticky>
          </div>
        </div>
      )}
    </div>
  );
};

QuoteFormMargins.propTypes = {
  index: PropTypes.number,
  lineItem: PropTypes.object,
  readonly: PropTypes.bool,
};

export default QuoteFormMargins;
