import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Icon } from 'fr-shared/components';
import { keyboardDown } from 'fr-shared/utils';

const DropdownCard = ({ expanded, renderHeader, renderBody }) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const handleExpanded = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <div className="dropdown-card rounded">
      <div
        className="header p-1 flex flex-row cursor-pointer"
        onClick={handleExpanded}
        onKeyDown={evt => keyboardDown(evt, 'Enter', handleExpanded)}
        role="button"
        tabIndex={0}
      >
        <div className="flex-1">{renderHeader()}</div>
        <div className="flex align-items-center ml-1">
          <Icon name={isExpanded ? 'angle-up' : 'angle-down'} opacity type="fal" />
        </div>
      </div>
      {isExpanded && <div className="border-top p-1">{renderBody()}</div>}
    </div>
  );
};

DropdownCard.propTypes = {
  expanded: PropTypes.bool,
  renderHeader: PropTypes.func,
  renderBody: PropTypes.func,
};

export default DropdownCard;
