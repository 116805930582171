/**
 * Checks if a given value is below the givin minValue
 * and then returns the greater of the two.
 * If the given value is NaN this will return null.
 * Mainly used to prevent users from copy and pasting invalid
 * characters in a number input field with out removing their
 * ability to clear / backspace.
 * @param value - given value
 * @param minValue - minimum threshold value
 */
export const numberInputMinValueFilter = (value: number, minValue: number): number | null => {
  return value === null || isNaN(value) ? null : value < minValue ? minValue : value;
};

/**
 * Cleans number input values to always be typeof number
 * @param value
 */
export const numberInputFilter = (value: any): number => {
  if (isNaN(value)) return 0;
  return typeof value === 'number'
    ? value
    : typeof value === 'string' && !isNaN(parseInt(value))
    ? +value
    : 0;
};

/**
 * Cleans boolean input values to always be typeof boolean
 * @param value
 */
export const booleanInputFilter = (value: any) => {
  return typeof value === 'boolean' ? value : value === 'true';
};
