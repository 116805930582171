import { connect } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {
  Button,
  FormCurrency,
  FormDate,
  FormNumber,
  FormSelect,
  Icon,
  classNames,
} from 'fr-shared/components';
import { BILLABLE_SERVICES } from 'fr-shared/lib/orders';

const OrderBillableLineItem = ({ index, arrayHelpers, readonly, formik }) => {
  const { values, setFieldValue } = formik;
  const lineItem = values.billable_service_line_items[index];

  const handleLineToggle = () => {
    setFieldValue(`billable_service_line_items.${index}.isOpen`, !lineItem.isOpen);
  };

  // TODO This should move to the back end
  const hourlySum =
    lineItem.hourly_rate &&
    get(lineItem, 'hourly_rate.amount', lineItem.hourly_rate) * lineItem.hours;

  const billableLineItemSum =
    hourlySum &&
    hourlySum.toLocaleString('en', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 4,
    });

  return (
    <div className={classNames(['card', 'card-border-alt', 'card-shadow', 'mb-3'])}>
      <div className="line-item-wrapper">
        <div className="line-item">
          <strong>{lineItem.name}</strong>
          {lineItem.id && (
            <>
              <span className="mx-2 text-success">{billableLineItemSum}</span>
              <span>
                {lineItem.hours} hours at{' '}
                {get(lineItem, 'hourly_rate.formatted', `$${lineItem.hourly_rate}`)}
              </span>
            </>
          )}
          <div className="ml-auto">
            {!readonly && (
              <Button
                color="danger"
                outline={true}
                size="sm"
                onClick={() => arrayHelpers.remove(index)}
              >
                <Icon name="times" />
              </Button>
            )}
            <Button
              id="line-item-toggle"
              className="ml-2"
              color={lineItem.isOpen ? 'dark' : 'secondary'}
              size="sm"
              data-testid={`expand-${index}`}
              onClick={handleLineToggle}
            >
              <Icon name={`chevron-${lineItem.isOpen ? 'up' : 'down'} `} />
            </Button>
          </div>
        </div>
      </div>
      {lineItem.isOpen && (
        <div className="card-body border-top">
          <div className="row">
            <div className="col-md">
              <FormSelect
                label="Service"
                name={`billable_service_line_items.${index}.service`}
                hasBlankOption={false}
                optionList={BILLABLE_SERVICES}
                readonly={readonly}
                nameAccessor={option => option}
                valueAccessor={option => option}
              />
              <FormNumber
                label="Hours"
                min={1}
                name={`billable_service_line_items.${index}.hours`}
                readonly={readonly}
                step={0.5}
                append="hours"
              />
              <FormCurrency
                label="Hourly Rate"
                name={`billable_service_line_items.${index}.hourly_rate`}
                readonly={readonly}
              />
              <FormDate
                label="Completed Date"
                name={`billable_service_line_items.${index}.completed_date`}
                readonly={readonly}
              />
            </div>
            <div className="col-md" />
          </div>
        </div>
      )}
    </div>
  );
};

OrderBillableLineItem.propTypes = {
  index: PropTypes.number,
  formik: PropTypes.object,
  arrayHelpers: PropTypes.object,
  readonly: PropTypes.bool,
};

export default connect(OrderBillableLineItem);
