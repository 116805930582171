import { useFormikContext } from 'formik';
import React, { ReactNode, useContext, useState } from 'react';

import { api } from 'fr-shared/api';
import { Button, CopyLink, Modal } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';

interface EmailQuoteToCustomerModalProps {
  afterSync: boolean;
  customerContact: CustomerContact;
  isOpen: boolean;
  quote?: Quote;
  toggle: () => void;
  handleShowInPortal: () => void;
}

export default function EmailQuoteToCustomerModal({
  afterSync,
  customerContact,
  handleShowInPortal,
  isOpen,
  quote,
  toggle,
}: EmailQuoteToCustomerModalProps): ReactNode {
  const { setAlert } = useContext(AlertContext);
  const [isSending, setIsSending] = useState(false);

  const formik = useFormikContext<{
    display_in_portal?: boolean;
  }>();
  const { id } = quote || {};

  const handleSendEmailClicked = () => {
    setIsSending(true);
    handleShowInPortal();
    api
      .post(`/quotes/send_quote_acceptance_email`, {
        id: id,
        customer_contact_id: customerContact.id,
        update_salesforce: afterSync,
      })
      .then(() => {
        setAlert({ message: 'Successfully sent email' });
      })
      .catch(err => {
        if (err.message.split(' ').pop() === '403') {
          setAlert({
            message: 'Your account does not have permission to send this email.',
            color: 'danger',
          });
        } else {
          setAlert({
            message: 'Email failed to send. Please reload and try again',
            color: 'danger',
          });
        }
      })
      .finally(() => {
        setIsSending(false);
        toggle();
      });
  };

  const handleDontSend = () => {
    handleShowInPortal();
    toggle();
  };

  const closeModal = () => {
    if (!afterSync) {
      formik.setFieldValue('display_in_portal', !formik.values.display_in_portal);
    }
    toggle();
  };

  if (!customerContact) return null;

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} onCloseHandler={closeModal}>
        <Modal.Header
          textUppercase={false}
          title="Do you want to email this quote to the customer?"
          onClose={handleDontSend}
        />
        <Modal.Body className="modal-body-send-email">
          <div>
            We can send an email to {quote.customer_contact.name} at{' '}
            <b>{quote.customer_contact.email}</b> to let them know their quote is ready to view in
            the customer portal.
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer style={{ border: '0', padding: '1.5rem' }}>
          <div className="flex justify-between align-items-center w-100">
            <div className="mr-auto">
              <CopyLink
                link={`${window.location.origin}/quotes/${quote.id}`}
                initialText={'Copy quote link'}
                successText={'Quote link copied!'}
              />
            </div>
            <div>
              <Button color="secondary" onClick={handleDontSend} className="mr-3">
                Don&apos;t send email
              </Button>
              <Button
                loading={isSending}
                color="primary"
                onClick={handleSendEmailClicked}
                className="ml-0"
              >
                Send email
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
