// keep in sync with fast-radius/lib/fast_radius/inspection_types.ex
// this list includes ALL inspection_type names;
// keep in mind that some of them may be deprecated (the backend stores if they are deprecated)
import { InspectionTypeForDefinition } from 'portal/components';

export const INSPECTION_TYPE_NAMES = {
  AdvancedInspection: 'Advanced Inspection',
  BasicInspection: 'Basic Inspection',
  Critical: 'Critical',
  CriticalFull: 'Critical Full',
  CriticalSample: 'Critical Sample',
  Digital: 'Digital',
  FAI: 'FAI',
  FirstArticleInspection: 'First Article Inspection (FAI)',
  FullInspection: 'Full Inspection',
  Functional: 'Functional',
  PPAP: 'PPAP',
  ProductionPartApprovalProcess: 'Production Part Approval Process (PPAP)',
  StandardInspection: 'Standard Inspection',
  VisualCheck: 'Visual Check',
  XYZ: 'XYZ',
};

export const getInspectionTypeDisplayName = (inspectionTypeName: string) => {
  if (!inspectionTypeName) return inspectionTypeName;
  return inspectionTypeName.split(' ').includes('Inspection') &&
    inspectionTypeName !== INSPECTION_TYPE_NAMES.FirstArticleInspection
    ? inspectionTypeName.split(' ')[0]
    : inspectionTypeName;
};

export const getCNCInspectionTypes = (inspectionTypes: InspectionTypeForDefinition[]) => {
  return inspectionTypes.filter((x: InspectionTypeForDefinition) => {
    return CNC_INSPECTION_TYPES.includes(x.name);
  });
};

export const getMoldingInspectionTypes = (inspectionTypes: InspectionTypeForDefinition[]) => {
  return inspectionTypes.filter((x: InspectionTypeForDefinition) => {
    return MOLDING_INSPECTION_TYPES.includes(x.name);
  });
};

export const getDefaultCNCInspectionType = (
  cncInspectionTypes: InspectionTypeForDefinition[]
) => {
  return cncInspectionTypes.find(
    (x: InspectionTypeForDefinition) => x.name === INSPECTION_TYPE_NAMES.VisualCheck
  );
};

export const getDefaultMoldingInspectionType = (
  moldingInspectionTypes: InspectionTypeForDefinition[]
) => {
  return moldingInspectionTypes.find(
    (x: InspectionTypeForDefinition) => x.name === INSPECTION_TYPE_NAMES.FirstArticleInspection
  );
};

export const getDefaultAdditiveInspectionType = (
  inspectionTypes: InspectionTypeForDefinition[]
) => {
  return inspectionTypes.find(
    (x: InspectionTypeForDefinition) => x.name === INSPECTION_TYPE_NAMES.VisualCheck
  );
};

export const AUTOQUOTE_EXCLUDED_INSPECTION_TYPES = [
  INSPECTION_TYPE_NAMES.FirstArticleInspection,
  INSPECTION_TYPE_NAMES.ProductionPartApprovalProcess,
];

export const CNC_INSPECTION_TYPES = [INSPECTION_TYPE_NAMES.VisualCheck];

export const MOLDING_INSPECTION_TYPES = [
  INSPECTION_TYPE_NAMES.FirstArticleInspection,
  INSPECTION_TYPE_NAMES.VisualCheck,
  INSPECTION_TYPE_NAMES.ProductionPartApprovalProcess,
];

export const getDefaultInspectionType = (
  cnc_id: number,
  cu_id: number,
  im_id: number,
  inspectionTypes: InspectionTypeForDefinition[],
  mp_id: number
) => {
  const cncInspectionTypes: InspectionTypeForDefinition[] =
    inspectionTypes && getCNCInspectionTypes(inspectionTypes);
  const defaultCncInspectionType =
    cncInspectionTypes && getDefaultCNCInspectionType(cncInspectionTypes);
  const visualCheckInspectionType =
    inspectionTypes && getDefaultAdditiveInspectionType(inspectionTypes);
  let defaultInspectionObj = {};
  if (mp_id === cnc_id) {
    defaultInspectionObj = {
      inspection_type_id: defaultCncInspectionType.id,
      inspection_type: cncInspectionTypes.find(
        (x: InspectionTypeForDefinition) => x.id === defaultCncInspectionType.id
      ),
    };
  } else if (mp_id === cu_id || mp_id === im_id) {
    const moldingInspectionTypes: InspectionTypeForDefinition[] =
      inspectionTypes && getMoldingInspectionTypes(inspectionTypes);
    const defaultMoldingInspectionType =
      moldingInspectionTypes && getDefaultMoldingInspectionType(moldingInspectionTypes);
    defaultInspectionObj = {
      inspection_type_id: defaultMoldingInspectionType.id,
      inspection_type: moldingInspectionTypes.find(
        (x: InspectionTypeForDefinition) => x.id === defaultMoldingInspectionType.id
      ),
    };
  } else {
    defaultInspectionObj = {
      inspection_type_id: visualCheckInspectionType?.id,
      inspection_type: visualCheckInspectionType,
    };
  }
  return defaultInspectionObj;
};
