import { Vector3 } from 'babylonjs';

import { Material } from './material';

/**
 * Describes a shape to render in the scene.
 */
export type ShapeInstance = {
  /**
   * A unique ID for the shape instance in the scene.
   */
  id: string;

  /**
   * The URL of the mesh source that will be used as the shape's geometry. This
   * value must match the `id` of one of the `MeshSoure` objects in the
   * `SceneCanvas`.
   */
  sourceUrl: string;

  /**
   * The name of the submesh in the mesh source that will be used as the shape's
   * geometry.
   *
   * A mesh source can contain multiple submeshes. If this is `null`, then the
   * shape's geometry will be the union of all submeshes in the mesh source. If
   * this value is not `null`, then the shape's geometry will be the submesh
   * that has the given name.
   */
  sourceSubmeshName?: string;

  /**
   * Indicates whether the shape is visible in the scene.
   */
  isVisible: boolean;

  /**
   * Indicates on which pointer events we should perform a ray cast to check if
   * the shape is under the pointer.
   */
  pickability: ShapeInstancePickablity;

  /**
   * Translation of the mesh source's local coordinate system to the scene's
   * coordinate system.
   */
  position: Vector3;

  /**
   * Rotation of the mesh source about its local coordinate system's x, y, and z
   * axes.
   */
  rotation: ShapeInstanceRotation;

  /**
   * Scaling of the mesh source in its local coordinate system.
   */
  scaling: Vector3;

  // TODO: Rather than storing the Material directly on the ShapeInstance,
  // SceneCanvas should take a list of MaterialSources that are loaded and that
  // ShapeInstances can reference.
  /**
   * The material that the shape is rendered with.
   *
   * If this is omitted, then the shape will be rendered with the material that
   * was loaded in the shape's mesh source. If the mesh source was not loaded
   * with a material then a default gray material will be used.
   */
  material?: Material;
};

/**
 * Describes differnt kinds of mouse pointer events. By selecting different
 * values of this enum, we can control when the component tries to find the mesh
 * surface properties under the mouse pointer. We can try to find mesh surface
 * properties under the mouse pointer in response to pointer up, pointer down,
 * and pointer move events.
 *
 * In order to find the surface properties, Babylon performs a ray cast into the
 * scene. Ray casting can be computationally expensive, so performing a ray cast
 * on every mouse move event can be very expensive.
 */
export enum ShapeInstancePickablity {
  /**
   * Do not try to find the surface properties under the pointer for any pointer
   * events.
   */
  None = 0,

  /**
   * Try to find the surface properties under the pointer for mouse up and mouse
   * down events.
   */
  UpDown = 1,

  /**
   * Try to find the surface properties under the pointer for mouse up, mouse
   * down, and mouse move events.
   */
  UpDownMove = 2,
}

/**
 * Describes how to rotate the shape instance.
 */
export type ShapeInstanceRotation = RotateAboutAxes | LookAt;

/**
 * A rotation about a shape's local x, y, and z axes.
 */
export type RotateAboutAxes = {
  kind: 'RotateAboutAxes';
  angles: Vector3;
};

/**
 * Returns a new roation about a shape's local x, y, and z axes.
 */
export const newRotateAboutAxes = (angles: Vector3): RotateAboutAxes => {
  return {
    kind: 'RotateAboutAxes',
    angles,
  };
};

/**
 * Asserts that the rotation is of type `RotateAboutAxes`.
 */
export const isRotateAboutAxex = (r: ShapeInstanceRotation): r is RotateAboutAxes => {
  return r.kind === 'RotateAboutAxes';
};

/**
 * A rotation that orients a shape to look at a target position.
 */
export type LookAt = {
  kind: 'LookAt';
  target: Vector3;
};

/**
 * Returns a new roation that orients a shape to look at a target position.
 */
export const newLookAt = (target: Vector3): LookAt => {
  return {
    kind: 'LookAt',
    target,
  };
};

/**
 * Asserts that the rotation is of type `LookAt`.
 */
export const isLookAt = (r: ShapeInstanceRotation): r is LookAt => {
  return r.kind === 'LookAt';
};
