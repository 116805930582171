import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { AlertContext, useUserAnalyticsContext } from 'fr-shared/context';

import { Page } from 'portal/components';
import { addLineItemsToCart } from 'portal/lib/cart';

import QuoteDetailsBody from './components/QuoteDetailsBody';
import QuoteDetailsHeader from './components/QuoteDetailsHeader';
import QuoteShippingAndBilling from './components/QuoteShippingAndBilling';

const QuoteView = () => {
  const [quote, setQuote] = useState(null);
  const history = useHistory();
  const { setAlert } = useContext(AlertContext);
  const userAnalytics = useUserAnalyticsContext();
  const { id } = useParams();

  const logEvt = (type, valid) => userAnalytics.track(`Reorder - ${type} from Quote`, { valid });

  useEffect(() => {
    if (quote) {
      userAnalytics.track(`Viewed ${quote.state} Quote`, { quote_id: quote.id });
    }
  }, [quote, userAnalytics]);

  useEffect(() => {
    api
      .get(`/customer_portal/quotes/${id}`)
      .then(res => {
        setQuote(res.data);
      })
      .catch(() => {
        setAlert({ color: 'danger', message: 'We were unable to load your quote' });
        history.push('/quotes');
      });
  }, [history, id, setAlert]);

  const handleReorderClicked = () => {
    addLineItemsToCart(quote.line_items)
      .then(() => {
        logEvt('Order', true);
        history.push('/quotes/draft');
      })
      .catch(() => {
        logEvt('Order', false);
        setAlert({
          color: 'danger',
          message: 'We were unable to reorder your please refresh and try again',
        });
      });
  };

  if (!quote) return null;

  return (
    <Page>
      <QuoteDetailsHeader quote={quote} onReorderClicked={handleReorderClicked} />
      <QuoteDetailsBody quote={quote} />
      {quote.state === 'Ordered' && <QuoteShippingAndBilling quote={quote} />}
    </Page>
  );
};

export default QuoteView;
