import * as Sentry from '@sentry/react';
import { SceneLoader, Vector3 } from 'babylonjs';
import 'babylonjs-loaders';
import { isEqual, last } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';

import { usePrevious } from 'fr-shared/hooks';

import { BabylonJSContext } from './Scene';
import {
  BUILD_PLATE_MESH,
  CARBON_BUILD_PLATE_URL,
  MAIN_STL_MESH,
  alignBuildPlateAndPart,
  calculateCamRadiusForModel,
  resetCamera,
  rotatePartToBuildOrientation,
  setMeshActions,
  solidMaterial,
} from './utils';

const StlModel = ({
  debug,
  manufacturingProcess,
  onChangeLoading,
  onChangePosition,
  onChangeRadius,
  partUnits,
  revision: {
    stl_url,
    manufacturability_check_files,
    manufacturability_obj_url,
    assumed_build_orientation,
  },
}) => {
  const { scene, engine } = useContext(BabylonJSContext);

  const prevStlUrl = usePrevious(stl_url);
  const prevManufacturingProcess = usePrevious(manufacturingProcess);
  const prevManufacturabilityCheckFiles = usePrevious(manufacturability_check_files);
  const prevManufacturabilityObjUrl = usePrevious(manufacturability_obj_url);
  const prevPartUnits = usePrevious(partUnits);
  const scale = partUnits === 'in' ? 25.4 : 1;

  /**
   * Shows or hides the debug interface of Babylon
   */
  useEffect(() => {
    if (!scene) return;

    debug ? scene.debugLayer.show() : scene.debugLayer.hide();

    // This useEffect doesn't need to run on changes to `scene`.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debug]);

  /**
   * Initializes the meshes for the STL model onto the
   * babylon scene. This also gives the camera a position to center at
   */
  useEffect(() => {
    if (!scene) return;

    (async () => {
      let stlMesh = scene.meshes.find(mesh => mesh.id === MAIN_STL_MESH);
      let buildPlateMesh = scene.meshes.find(mesh => mesh.id === BUILD_PLATE_MESH);
      const newBaseMesh = !isEqual(stl_url, prevStlUrl);
      const updateUnits = !isEqual(partUnits, prevPartUnits);
      const newCheckMeshes =
        !isEqual(manufacturingProcess, prevManufacturingProcess) ||
        !isEqual(manufacturability_check_files, prevManufacturabilityCheckFiles) ||
        !isEqual(manufacturability_obj_url, prevManufacturabilityObjUrl);

      onChangeLoading(true);

      /*
       * STL meshes may be reloaded (although maybe only on local dev?). This hopefully
       * only happens with the same mesh file but a different URL due to how AWS signs
       * S3 URLs. Due to how the build plate is positioned relative to the STL mesh
       * (see below), loading in a completely different STL mesh would cause the build
       * plate to be misaligned.
       */
      if (newBaseMesh) {
        let stlResult;

        try {
          stlResult = await SceneLoader.ImportMeshAsync('', stl_url, '', scene);
        } catch (error) {
          Sentry.setExtra('error', error);
          Sentry.captureMessage('Babylon error');

          return;
        }

        stlMesh?.dispose();

        for (const mesh of stlResult.meshes) {
          mesh.id = MAIN_STL_MESH;
          mesh.name = `imported-mesh-${stl_url}`;
          mesh.metadata = { imported: true };
          mesh.material = solidMaterial(scene);
        }

        stlMesh = stlResult.meshes[0];
      }

      if (updateUnits) {
        stlMesh.scaling.x = scale;
        stlMesh.scaling.y = scale;
        stlMesh.scaling.z = scale;
        // the bounding info for a babylon mesh is not updated to account
        // for affine transformations until this is called
        stlMesh.computeWorldMatrix(true);
      }

      /*
       * The build plate mesh can only be loaded once and it requires the STL mesh to
       * already be loaded. The build plate mesh can't be reloaded because it is stateful.
       * Its visibility and material settings, set elsewhere, would be overwritten by
       * multiple calls to alignBuildPlateAndPart. Also, alignBuildPlateAndPart sets the
       * build plate mesh's offset to the STL mesh relatively, so multiple calls will
       * cause the build plate to drift away from the STL mesh.
       */
      if (stlMesh && !buildPlateMesh) {
        let buildPlateResult;

        try {
          buildPlateResult = await SceneLoader.ImportMeshAsync(
            '',
            CARBON_BUILD_PLATE_URL,
            '',
            scene
          );
        } catch (error) {
          Sentry.setExtra('error', error);
          Sentry.captureMessage('Babylon error');

          return;
        }

        buildPlateMesh = buildPlateResult.meshes[0];
      }

      if (newCheckMeshes) {
        for (const mesh of scene.meshes) {
          if (mesh?.metadata?.manufacturability) {
            mesh.dispose();
          }
        }

        let manufacturabilityMeshUrl;

        if (manufacturability_check_files?.length > 0) {
          manufacturabilityMeshUrl = last(
            manufacturability_check_files.filter(
              mfgCheckFile =>
                mfgCheckFile.manufacturing_process?.name === manufacturingProcess?.name
            )
          )?.s3_path;
        } else if (manufacturability_obj_url) {
          manufacturabilityMeshUrl = manufacturability_obj_url;
        }

        if (manufacturabilityMeshUrl) {
          let manufacturabilityResult;
          let buildOrientation;

          if (assumed_build_orientation) {
            buildOrientation = new Vector3(
              assumed_build_orientation[0],
              assumed_build_orientation[1],
              assumed_build_orientation[2]
            );

            rotatePartToBuildOrientation(stlMesh, buildOrientation);
          }

          try {
            manufacturabilityResult = await SceneLoader.ImportMeshAsync(
              '',
              manufacturabilityMeshUrl,
              '',
              scene
            );
          } catch (error) {
            Sentry.setExtra('error', error);
            Sentry.captureMessage('Babylon error');

            return;
          }

          for (const mesh of manufacturabilityResult.meshes) {
            mesh.isVisible = false;
            mesh.metadata = { manufacturability: true };
            if (buildOrientation) {
              rotatePartToBuildOrientation(mesh, buildOrientation);
            }

            if (updateUnits) {
              mesh.scaling.x = scale;
              mesh.scaling.y = scale;
              mesh.scaling.z = scale;
            }

            mesh.computeWorldMatrix(true);
          }
        }
      }

      if (stlMesh) {
        alignBuildPlateAndPart(buildPlateMesh, stlMesh);
        stlMesh.computeWorldMatrix(true);
      }

      if (newBaseMesh) {
        setMeshActions(scene, stlMesh);

        // set the initial camera position
        onChangePosition(stlMesh.getBoundingInfo().boundingBox.centerWorld);
        onChangeRadius(calculateCamRadiusForModel(stlMesh, scene, engine));
      }

      resetCamera(scene, engine);
      onChangeLoading(false);
    })();

    /*
     * The following deps are omitted:
     * - 'engine'
     * - 'onChangeLoading'
     * - 'onChangePosition'
     * - 'onChangeRadius'
     * - 'prevManufacturabilityCheckFiles'
     * - 'prevManufacturabilityObjUrl'
     * - 'prevManufacturingProcess'
     * - 'prevPartUnits'
     * - 'prevStlUrl'
     * - 'scene'
     *
     * Changes to `engine`, `onChangeLoading`, `onChangePosition`,
     * `onChangeRadius`, and `scene` don't need to cause this useEffect to rerun.
     *
     * If this useEffect has any of the usePrevious values, it runs twice when
     * the underlying values change. Once when the value itself changes, and
     * once when the usePrevious value catches up. Excluding them is a
     * performance optimization.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    manufacturability_check_files,
    manufacturability_obj_url,
    manufacturingProcess,
    partUnits,
    stl_url,
  ]);

  return null;
};

StlModel.propTypes = {
  onChangeLoading: PropTypes.func,
  onChangePosition: PropTypes.func,
  onChangeRadius: PropTypes.func,
  revision: PropTypes.shape({
    manufacturability_check_files: PropTypes.arrayOf(
      PropTypes.shape({
        manufacturing_process: PropTypes.shape({
          name: PropTypes.string,
        }),
        s3_path: PropTypes.string,
      })
    ),
    manufacturability_obj_url: PropTypes.string,
    stl_url: PropTypes.string,
  }),
  partUnits: PropTypes.string,
};

StlModel.defaultProps = {
  onChangeLoading: () => {},
  onChangePosition: () => {},
  onChangeRadius: () => {},
  revision: {},
};

export default StlModel;
