import { pickBy } from 'lodash';
import React, { useContext } from 'react';

import { api } from 'fr-shared/api';
import {
  Breadcrumb,
  Helmet,
  Sidebar,
  SidebarPage,
  SidebarPageContainer,
} from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { useTableFilters } from 'fr-shared/hooks';

import OrderFilters from './components/OrderFilters';
import OrdersSearch from './components/OrdersSearch';
import OrdersTable from './components/OrdersTable';

const FILTER_NAME = 'orders';
const INITIAL_FILTERS = { page: 1, sort_key: 'id', sort_desc: true };

const Orders = () => {
  const { user } = useContext(UserContext);

  const getOrders = filters => {
    const userFilters = user.canViewDraftOrders ? {} : { not_draft: true };
    const params = pickBy({ ...filters, ...userFilters });
    return api.get('/orders', { params });
  };

  const { data, filters, loading, handleSearch, onFetchData, pages, resetFilters, setFilters } =
    useTableFilters(FILTER_NAME, INITIAL_FILTERS, getOrders);

  return (
    <>
      <Helmet title="Orders" />
      <Breadcrumb to="/admin/orders">Orders</Breadcrumb>

      <SidebarPageContainer localStorageKey={Sidebar.LOCAL_STORAGE_KEYS.orders}>
        <SidebarPage>
          <div className="page-header flex align-items-center px-3">
            <h2 className="mb-0">List of Orders</h2>
            <div className="page-actions flex align-items-center">
              <OrdersSearch
                filters={filters}
                handleTextSearch={handleSearch}
                setFilters={setFilters}
              />
            </div>
          </div>

          <div className="px-3">
            <OrdersTable
              data={data || []}
              loading={loading}
              onFetchData={onFetchData}
              pages={pages}
            />
          </div>
        </SidebarPage>

        <Sidebar>
          <OrderFilters clearFilters={resetFilters} filters={filters} setFilters={setFilters} />
        </Sidebar>
      </SidebarPageContainer>
    </>
  );
};

export default Orders;
