import React, { useState } from 'react';

import { Button, Helmet, Modal, ModalStickyHeader, Page } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';

import { Cart } from 'portal/lib/cart';
import CartDetailsBody from 'portal/pages/cart/components/CartDetailsBody';
import CartDetailsHeader from 'portal/pages/cart/components/CartDetailsHeader';

const PreviewPortalCartModal = ({
  cart,
  onSubmit,
  showCartId = true,
  onClose = () => {},
}: {
  cart: Cart;
  onSubmit: () => void;
  showCartId: boolean;
  onClose: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(!showCartId);
  const userAnalytics = useUserAnalyticsContext();

  const handleOpenModal = () => {
    userAnalytics.track('FROS - Preview Cart Modal', {
      cartId: cart.id,
      admin: true,
    });

    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <>
      {showCartId && (
        <Button color="link" tooltip="Preview Cart" onClick={handleOpenModal}>
          {cart.id}
        </Button>
      )}
      {isOpen && (
        <Modal
          contentClassName="bg-dark border-none"
          fullscreen={true}
          isOpen={isOpen}
          toggle={handleCloseModal}
        >
          {({ toggle }: { toggle: () => void }) =>
            cart && (
              <>
                <ModalStickyHeader
                  header={`Portal Cart ${cart.id}`}
                  handleClose={() => {
                    toggle();
                    setIsOpen(false);
                  }}
                  onClickPrimaryCta={onSubmit}
                  onClickSecondaryCta={handleCloseModal}
                  primaryCtaText={cart.submitted_at === null ? 'Convert to CR' : `View CR`}
                  secondaryCtaText="Cancel"
                />
                <Page className="bg-dark">
                  <Helmet>
                    <body className="theme-dark" />
                  </Helmet>
                  <div className="bg-dark text-white">
                    <CartDetailsHeader cart={cart} readonly={true} />
                    <CartDetailsBody cart={cart} readonly={true} />
                  </div>
                </Page>
              </>
            )
          }
        </Modal>
      )}
    </>
  );
};

export default PreviewPortalCartModal;
