import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import {
  Button,
  ButtonLink,
  FirstOrderBadge,
  IncotermModal,
  Link,
  classNames,
} from 'fr-shared/components';
import AuditLog from 'fr-shared/components/common/AuditLog';
import { UserContext } from 'fr-shared/context';
import { INCOTERMS } from 'fr-shared/lib/incoterms';

import { DeleteShipmentModal } from './DeleteShipmentModal';
import OrderDetails from './OrderDetails';
import PackingSlipInformation from './PackingSlipInformation';
import ShipmentLineItem from './ShipmentLineItem';
import usePackingSlip from './usePackingSlip';

const ShipmentViewer = ({ shipment }) => {
  const { handleGetPackingSlip, packingSlip, loadingPackingSlip } = usePackingSlip(shipment);

  const incoterm = INCOTERMS.find(i => i.id === shipment.order.incoterm);

  const { user } = useContext(UserContext);
  const [showShipmentHistory, setShowShipmentHistory] = useState(false);

  const toggleShowHistory = value => {
    setShowShipmentHistory(value);
    window.scrollTo(0, 0);
  };

  const auditLogQueryObject = {
    orgId: shipment.order.customer.id,
    subject: 'order',
    subjectId: shipment.order.id,
    topic: 'shipment',
    topicId: shipment.id,
  };

  return (
    <>
      <div className="page-header flex pb-0">
        <div className="container">
          <div>
            <h2>Shipment #{shipment.id}</h2>
            <div className="mt-3">
              <div>
                Order #:{' '}
                <Link className="mr-1" to={`/admin/orders/${shipment.order.id}`}>
                  <strong>{shipment.order.public_id}</strong>
                </Link>
                {shipment.order.is_first_order && <FirstOrderBadge fontSize="sm" />}
              </div>
              <div>
                Ship Date:{' '}
                <strong>{moment(shipment.shipped_date).format('MM/DD/YY, h:mm A')}</strong>
              </div>
            </div>

            <ul className="nav nav-tabs mt-4">
              <li className="nav-item cursor-pointer">
                <ButtonLink
                  dataTestId="shipment-details-nav"
                  className={classNames(['nav-link', { active: !showShipmentHistory }])}
                  onClick={() => toggleShowHistory(false)}
                >
                  Shipment Details
                </ButtonLink>
              </li>
              <li className="nav-item cursor-pointer">
                <ButtonLink
                  dataTestId="shipment-history-nav"
                  className={classNames(['nav-link', { active: showShipmentHistory }])}
                  onClick={() => toggleShowHistory(true)}
                >
                  Shipment Activity
                </ButtonLink>
              </li>
            </ul>
          </div>
          <div className="ml-auto">
            <Button to="/admin/shipments" className="mr-2">
              Back
            </Button>

            {user?.canDeleteShipment && <DeleteShipmentModal shipmentId={shipment.id} />}

            <Button
              color="primary"
              className="mx-2"
              loading={loadingPackingSlip}
              onClick={handleGetPackingSlip}
            >
              Generate Packing Slip
            </Button>
            {user.canEditShipment && (
              <Button
                to={`/admin/shipments/${shipment.id}/edit`}
                color="primary"
                className="ml-2"
              >
                Edit
              </Button>
            )}
          </div>
        </div>
      </div>
      {packingSlip && <PackingSlipInformation packingSlip={packingSlip} />}
      <div className="container">
        <div
          className={classNames(['relative', { hidden: showShipmentHistory }])}
          data-testid="shipment-details-wrapper"
        >
          <div className="card">
            <OrderDetails order={shipment.order} title={<h3>Order Details</h3>} />
          </div>
          <div className="card">
            <div className="peer card-header">
              <h3>Shipment Line Items</h3>
            </div>
            <div>
              {shipment.line_items.map(lineItem => (
                <ShipmentLineItem
                  key={lineItem.id}
                  shipmentId={shipment.id}
                  order={shipment.order}
                  lineItem={lineItem}
                  isEditing={false}
                />
              ))}
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h3 data-testid="shipment-details-header">Shipment Details</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md">
                  <div className="row">
                    <div className="col-md-3 text-muted">Tracking No.</div>
                    <div className="col-md">
                      <Link
                        to={{
                          pathname: `https://www.google.com/search?q=${shipment.tracking_number}`,
                        }}
                        openNewWindow
                      >
                        {shipment.tracking_number}
                      </Link>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-3 text-muted">Shipping Charge</div>
                    <div className="col-md">
                      <strong>{shipment.shipping_charge.formatted}</strong>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-3 text-muted">Incoterm</div>
                    <div className="col-md">
                      <strong>{incoterm?.name}</strong> <IncotermModal />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="row">
                    <div className="col-md-2 text-muted">Ship Date</div>
                    <div className="col-md">
                      <strong>{moment(shipment.shipped_date).format('MM/DD/YYYY')}</strong>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-2 text-muted">Ship To</div>
                    <div className="col-md">
                      <strong>{shipment.ship_to}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames([{ hidden: !showShipmentHistory }])}
          data-testid="shipment-history-wrapper"
        >
          <AuditLog
            detailEvents={['shipment.create']}
            logHeading="Shipment"
            logObject={auditLogQueryObject}
          />
        </div>
      </div>
    </>
  );
};

ShipmentViewer.propTypes = {
  shipment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    line_items: PropTypes.arrayOf(
      PropTypes.shape({
        order_line_item_id: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
      })
    ).isRequired,
    ship_to: PropTypes.string.isRequired,
    shipping_charge: PropTypes.shape({
      formatted: PropTypes.string.isRequired,
    }).isRequired,
    shipped_date: PropTypes.string,
    tracking_number: PropTypes.string.isRequired,
    order: PropTypes.shape({
      id: PropTypes.number.isRequired,
      public_id: PropTypes.string.isRequired,
      is_first_order: PropTypes.bool.isRequired,
      incoterm: PropTypes.string,
      line_items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
        })
      ).isRequired,
      customer: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default ShipmentViewer;
