import Cookie from 'js-cookie';
import React, { useState } from 'react';

import { MARKETING_URLS } from 'fr-shared/lib/marketing_urls';

import { Button, Link } from 'portal/components';

const COOKIE_BANNER_KEY = 'portal_accepted_cookie';

export const CookieBanner = () => {
  const [isHidden, setIsHidden] = useState(Cookie.get(COOKIE_BANNER_KEY));

  const handleUserAccept = () => {
    Cookie.set(COOKIE_BANNER_KEY, 'true', { expires: 365 });
    setIsHidden(true);
  };

  if (isHidden) return null;

  return (
    <div className="fixed-bottom p-2 bg-gray-600 text-light">
      <div className="container">
        <div className="flex flex-row justify-content-center align-items-center">
          <div>
            We use cookies in order to improve and personalize your browsing experience. To find
            out more, click{' '}
            <Link to={{ pathname: MARKETING_URLS.Privacy }} openNewWindow>
              here
            </Link>
            .
          </div>
          <div className="flex align-items-center ml-5">
            <Button onClick={handleUserAccept} size="sm">
              OK
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
