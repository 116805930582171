import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { savePreAuthRoute } from 'fr-shared/auth';

const RouteSaver = ({ location }) => {
  useEffect(() => {
    savePreAuthRoute(location);
  }, [location]);
  return <Redirect to="/" />;
};

RouteSaver.propTypes = {
  location: PropTypes.object,
};

export default RouteSaver;
