import React from 'react';

interface Props {
  active?: boolean;
  color: string;
}

const ColorSwatchSVG = ({ active = false, color }: Props) => {
  return (
    <svg
      data-testid={active ? 'active-legend-svg' : 'default-legend-svg'}
      width={active ? '8' : '4'}
      height="20"
      viewBox={active ? '0 0 8 20' : '0 0 4 20'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={active ? '8' : '4'} height="20" fill={color} />
    </svg>
  );
};

export default ColorSwatchSVG;
