import { IconFont } from '@fast-radius/shared-ui';
import * as Sentry from '@sentry/react';
import { Form, Formik, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { AlertContext, useUserAnalyticsContext } from 'fr-shared/context';
import { useEffectDebounced } from 'fr-shared/hooks';
import { billingSchema, shippingSchema } from 'fr-shared/lib/address';
import { COUNTRIES } from 'fr-shared/lib/countries';
import { calculateTotal } from 'fr-shared/lib/currency';
import { getMarketoCookies } from 'fr-shared/lib/marketo';
import { calculateLineItemSubtotal } from 'fr-shared/lib/quotes';
import { getFallbackShippingCost } from 'fr-shared/lib/shipping';
import { mergeSchemas } from 'fr-shared/utils';

import { ContactUs, Link } from 'portal/components';
import { draftCheckoutShippingSchema, lineItemsSchema, quoteSchema } from 'portal/lib/schema';

import CheckoutBilling from '../components/CheckoutBilling';
import CheckoutLayout from '../components/CheckoutLayout';
import CheckoutReview from '../components/CheckoutReview';
import CheckoutShipping from '../components/CheckoutShipping';
import CheckoutSummary from '../components/CheckoutSummary';

const CheckoutContainer = () => {
  const history = useHistory();
  const userAnalytics = useUserAnalyticsContext();
  const { setAlert } = useContext(AlertContext);
  const [cart, setCart] = useState(null);
  const refreshCart = useCallback(() => {
    return api
      .post('/customer_portal/cart')
      .then(res => {
        const { data: cart } = res;
        setCart(cart);
      })
      .catch(() => {
        setAlert({
          color: 'danger',
          message: 'An unexpected error occurred, please refresh and try again',
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCart]);

  /** Load the users cart into formik */
  useEffect(() => {
    refreshCart();
  }, [refreshCart]);

  const handleSubmit = (values, { setSubmitting }) => {
    const logEvt = valid => userAnalytics.track('Checkout - Ordered', { valid });

    api
      .post('/customer_portal/submit_cart', { ...values, marketo_attrs: getMarketoCookies() })
      .then(response => {
        logEvt(true);
        history.push(`/checkout/confirmation/${response.data.hashid}`);
      })
      .catch(() => {
        logEvt(false);
        setAlert({
          message: 'An unexpected error occurred. Please refresh and try again.',
          color: 'danger',
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (!cart) return null;

  return (
    <Formik
      initialValues={{
        ...cart,
        billing_address: {},
        shipping_address: { country: COUNTRIES.UNITED_STATES },
        shipping_and_taxes_total: {},
        shipping_method: undefined,
        credit_card_checkout: true,
      }}
      onSubmit={handleSubmit}
      validationSchema={quoteSchema}
    >
      <Form className="flex flex-fill flex-column">
        <CheckoutForm />
      </Form>
    </Formik>
  );
};

const CheckoutForm = () => {
  const { setAlert } = useContext(AlertContext);
  const userAnalytics = useUserAnalyticsContext();
  const formik = useFormikContext();
  const location = useLocation();
  const [isShippingPriceLoading, setIsShippingPriceLoading] = useState(false);

  const shippingFieldsValid = mergeSchemas(
    shippingSchema,
    draftCheckoutShippingSchema
  ).isValidSync(formik.values);
  const billingFieldsValid = billingSchema.isValidSync(formik.values);
  const lineItemsValid = lineItemsSchema.isValidSync(formik.values);

  const isFormValid =
    billingFieldsValid &&
    shippingFieldsValid &&
    isEmpty(formik.errors) &&
    !isShippingPriceLoading;

  const subtotal = useMemo(
    () => calculateLineItemSubtotal(formik.values.line_items),
    [formik.values.line_items]
  );

  const totalPrice = useMemo(() => {
    return calculateTotal([subtotal.amount, formik.values.shipping_and_taxes_total.amount]);
  }, [subtotal.amount, formik.values.shipping_and_taxes_total.amount]);

  const fetchShippingPrice = async (cart_id, shipping_address, shipping_method) => {
    setIsShippingPriceLoading(true);
    try {
      const response = await api.post(
        `/customer_portal/shipping/carts/${cart_id}/calculate_shipping_charge`,
        {
          shipping_address,
          shipping_method,
        }
      );
      const fetchedPrice = response.data.shipping_charge;
      formik.setFieldValue('shipping_and_taxes_total', fetchedPrice);
    } catch (error) {
      formik.setFieldValue('shipping_and_taxes_total', getFallbackShippingCost());
      setAlert({
        message: (
          <>
            Sorry, we couldn&apos;t calculate your shipping &amp; taxes. Please refresh this page
            or <ContactUs>contact us</ContactUs> to continue.
          </>
        ),
        color: 'danger',
      });
      if (process.env.MIX_ENV !== 'dev') {
        Sentry.setExtra('error', error);
        Sentry.captureMessage('Unable to fetch shipping price');
      }
    }
    setIsShippingPriceLoading(false);
  };

  useEffectDebounced(() => {
    const { id, shipping_address, shipping_method } = formik.values;
    const { street_line_1, city, state, postal_code, country } = shipping_address;
    if (
      shipping_method &&
      street_line_1 &&
      city &&
      state &&
      postal_code &&
      country &&
      !formik.errors.shipping_address &&
      formik
    ) {
      fetchShippingPrice(id, shipping_address, shipping_method);
    }
  }, [
    formik.values.id,
    formik.values.shipping_address,
    formik.errors.shipping_address,
    formik.values.shipping_method,
  ]);

  useEffectDebounced(() => {
    const { shipping_method } = formik.values;
    if (shipping_method) {
      userAnalytics.track('Checkout - Shipping Option', { inputValue: shipping_method });
    }
  }, [formik.values.shipping_method]);

  // This is not using the debounced useEffect to invalidate the formik quicker
  useEffect(() => {
    const { shipping_method } = formik.values;
    if (shipping_method) {
      formik.setFieldValue('shipping_and_taxes_total', {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.shipping_method]);

  if (!lineItemsValid) {
    return <Redirect to="/" />;
  }

  return (
    <CheckoutLayout
      backLink={
        <Link className="font-size-sm text-gray text-decoration-none" to="/">
          <IconFont name="chevron-left" right />
          Back to dashboard
        </Link>
      }
      mainContent={
        <>
          <CheckoutShipping
            isActive={location.pathname.includes('shipping')}
            schemaIsValid={shippingFieldsValid}
          />
          <CheckoutBilling
            isActive={location.pathname.includes('billing')}
            itemsSubtotal={parseFloat(subtotal?.amount)}
            shippingIsValid={shippingFieldsValid}
          />
          <CheckoutReview
            isActive={location.pathname.includes('review')}
            isSubmitDisabled={!isFormValid}
            shippingMethod={formik.values.shipping_method}
            shippingPrice={formik.values.shipping_and_taxes_total?.amount}
            subtotal={subtotal.amount}
            totalPrice={totalPrice.amount}
          />
        </>
      }
      summary={
        <CheckoutSummary
          canEdit={!formik.isSubmitting}
          partCount={formik.values.line_items.length}
          lineItems={formik.values.line_items}
          shippingPrice={formik.values.shipping_and_taxes_total.amount}
          showNotifications={true}
          subtotal={subtotal.amount}
          totalPrice={totalPrice.amount}
        />
      }
    />
  );
};

export default CheckoutContainer;
