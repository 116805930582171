import { Field } from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';

import { FormFieldBase, classNames } from 'fr-shared/components';

const FormDate = ({
  name,
  label,
  onChange,
  readonly,
  isStacked,
  isRequired,
  placeholder,
  disallowFutureDate,
}) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const value = field.value ? formatDate(field.value) : '';
        return (
          <FormFieldBase
            name={name}
            readonly={readonly}
            isStacked={isStacked}
            label={label}
            isRequired={isRequired}
          >
            {readonly ? (
              <div id={name}>
                <strong>{value}</strong>
              </div>
            ) : (
              <>
                <DayPickerInput
                  formatDate={formatDate}
                  parseDate={parseDate}
                  inputProps={{
                    name: name,
                    autoComplete: 'datepicker',
                    className: classNames([
                      'form-control',
                      { 'is-invalid': get(form.errors, name) },
                    ]),
                    'data-testid': name,
                  }}
                  dayPickerProps={{
                    modifiers: {
                      grayed: { before: new Date() },
                      disabled: { after: disallowFutureDate ? new Date() : '' },
                    },
                  }}
                  format="MM/DD/YYYY"
                  placeholder={placeholder || 'MM/DD/YYYY'}
                  value={value}
                  onDayChange={day => {
                    const formattedDate = day && moment(day).format('YYYY-MM-DD');
                    form.setFieldValue(name, formattedDate ? formattedDate : '');
                    onChange && onChange(formattedDate);
                  }}
                />
                {get(form.errors, name) && (
                  <div className="form-control-error" data-testid={`errors-${name}`}>
                    {get(form.errors, name)}
                  </div>
                )}
              </>
            )}
          </FormFieldBase>
        );
      }}
    </Field>
  );
};

FormDate.propTypes = {
  disallowFutureDate: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  isStacked: PropTypes.bool,
  readonly: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default FormDate;
