import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';

import { Button, FormFieldBase, IconFont } from 'fr-shared/components';
import { keyboardDown } from 'fr-shared/utils';

import { Modal, classNames } from 'portal/components';

import { FullScreenOption } from '../FullScreenOptionCard/FullScreenOptionCard';
import styles from './FullScreenSelector.module.css';

export interface SelectedOptionStateContext {
  onSubmit: (option: FullScreenOption) => void;
  selectedOption: FullScreenOption;
  setOption: Dispatch<SetStateAction<FullScreenOption>>;
}

interface FullScreenSelectorProps {
  error?: string;
  activeGlow?: boolean;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  labelText?: string;
  modalHeader?: string;
  modalSubHeader?: string;
  name: string;
  onChange?: (option: FullScreenOption) => void;
  currentOption?: FullScreenOption;
  placeholder?: string;
}

interface FullScreenModalProps {
  children?: ReactNode;
  isModalOpen?: boolean;
  modalHeader?: string;
  modalSubHeader?: string;
  onCancel?: () => void;
  onSubmit?: (option: FullScreenOption) => void;
  currentOption?: FullScreenOption;
  setIsModalOpen?: Function;
}

const FullScreenSelector = ({
  activeGlow,
  error,
  children,
  disabled = false,
  className,
  labelText,
  modalHeader,
  modalSubHeader,
  name,
  onChange,
  currentOption,
  placeholder,
}: FullScreenSelectorProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => !disabled && setIsModalOpen(true);

  return (
    <FormFieldBase name={name} error={error} label={labelText} isStacked={true}>
      <div className={classNames(['relative', className, disabled && styles.Disabled])}>
        <div
          onClick={openModal}
          className={classNames([activeGlow && styles.activeGlow])}
          onKeyDown={evt => keyboardDown(evt, 'Enter', openModal)}
          data-testid={`selector--${name}`}
          tabIndex={0}
          role="button"
          aria-disabled={disabled}
        >
          <input
            className={classNames([
              'form-control',
              !disabled && 'text-white',
              styles.SelectorBox,
            ])}
            disabled={true}
            value={currentOption ? currentOption?.name : ''}
            placeholder={placeholder}
            name={name}
          />
          <IconFont name="plus" className={styles.Icon} />
        </div>
        {isModalOpen && (
          <FullScreenModal
            isModalOpen={isModalOpen}
            modalHeader={modalHeader}
            modalSubHeader={modalSubHeader}
            setIsModalOpen={setIsModalOpen}
            onSubmit={onChange}
            currentOption={currentOption}
          >
            {children}
          </FullScreenModal>
        )}
      </div>
    </FormFieldBase>
  );
};

export const FullScreenModal = ({
  children,
  isModalOpen,
  modalHeader,
  modalSubHeader,
  setIsModalOpen,
  onCancel,
  onSubmit,
  currentOption,
}: FullScreenModalProps) => {
  return (
    <Modal
      shouldCloseOnEsc={false}
      dataTestId="full-screen-selector-modal"
      centered={true}
      fullscreen={true}
      isRounded={false}
      isOpen={isModalOpen}
    >
      {({ toggle }: { toggle: () => void }) => {
        const handleCancel = () => {
          toggle();
          setIsModalOpen(false);
          onCancel && onCancel();
        };

        const submitOption = (option: FullScreenOption) => {
          toggle();
          setIsModalOpen(false);
          onSubmit(option);
        };

        return (
          <>
            <Modal.Header
              className={classNames([styles.Sticky, styles.FullScreenModalHeader])}
              onClose={() => {
                toggle();
                setIsModalOpen(false);
              }}
              showCloseButton={false}
              textUppercase={false}
            >
              <div className="container-md">
                <div className="border-bottom flex flex-col md:flex-row justify-content-between items-start md:items-center pb-3">
                  <div>
                    <h3>{modalHeader}</h3>
                    <div>{modalSubHeader}</div>
                  </div>
                  <div className="flex mt-2 mt-md-0 flex-row">
                    <Button
                      onClick={handleCancel}
                      onKeyDown={evt => keyboardDown(evt, 'Enter', handleCancel)}
                      className={classNames([
                        'modal-close',
                        'mr-1',
                        'font-size-xl',
                        styles.ModalButton,
                        styles.ModalButtonExtraSpecificity,
                        styles.ModalButtonExtraExtraSpecificity,
                      ])}
                      size="md"
                    >
                      <IconFont name="close" />
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Header>
            <div className={classNames(['modal-body', styles.ModalBody])}>
              <div className="container-md pt-5 pb-3">
                {children instanceof Function
                  ? children({
                      onSubmit: submitOption,
                      selectedOption: currentOption,
                    })
                  : children}
              </div>
            </div>
          </>
        );
      }}
    </Modal>
  );
};

export default FullScreenSelector;
