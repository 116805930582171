import React, { useState } from 'react';

import { IconFont, Modal, ToggleSwitch, classNames } from 'fr-shared/components';

import styles from './ConfirmationModal.module.css';
import NoConfirmation from './NoConfirmation';
import YesConfirmation from './YesConfirmation/YesConfirmation';

interface Props {
  cliId: string | number;
  partId: number;
}

export const ConfirmationModal = ({ cliId, partId }: Props) => {
  const [confirmationValue, setConfirmationValue] = useState('No');
  const [isYesChecked, setIsYesChecked] = useState(false);

  const isBulkEdit = window.location.pathname === '/edit-parts';

  const toggleSwitch = () => {
    setIsYesChecked(prev => {
      setConfirmationValue(prev ? 'No' : 'Yes');
      return !prev;
    });
  };

  return (
    <Modal
      action={
        <div
          className={classNames([
            'cursor-pointer',
            'form-group',
            'is-floating',
            'mb-0',
            styles.ActionFormGroup,
            styles.ActionFormGroupExtraSpecificity,
          ])}
          data-testid="selector--itar-ear-regulations"
        >
          <div
            className={classNames([
              'custom-select',
              'bg-none',
              'form-control',
              'pt-1',
              'text-white',
              styles.ActionValue,
              styles.ActionValueExtraSpecificity,
            ])}
          >
            {confirmationValue}
          </div>

          <IconFont name="plus" className="absolute bottom-1 right-1 text-white text-xs" lg />
        </div>
      }
      backdrop={isYesChecked ? 'static' : true}
      className={classNames([styles.Modal, styles.ModalExtraSpecificity])}
    >
      {({ toggle }: { toggle: any }) => (
        <>
          <Modal.Header
            className="pt-5"
            dataTestId="itar-ear-confirmation-modal--header-title"
            onClose={toggle}
            showCloseButton={false}
            textUppercase={false}
            title="This is important"
          />

          <div className="modal-body pb-4">
            <div className="mt-1 mb-3" style={{ borderBottom: '1px solid #3c4551' }} />

            <div className="flex flex-row justify-between">
              <p className="text-coolGray-100 mb-0 text-base">
                {isBulkEdit ? 'Are these parts ' : 'Is this part '}
                subject to ITAR or EAR regulations?
              </p>

              <div
                aria-checked="false"
                onClick={toggleSwitch}
                onKeyDown={toggleSwitch}
                role="checkbox"
                tabIndex={0}
              >
                <ToggleSwitch
                  className={styles.ToggleSwitch}
                  dataTestId="itar-ear-modal-confirmation-toggle-switch"
                  defaultTrueLabel={'No'}
                  defaultFalseLabel={'Yes'}
                  id={''}
                  checked={!isYesChecked}
                  onCheck={() => {}}
                />
              </div>
            </div>

            <div className="mt-3 mb-3" style={{ borderBottom: '1px solid #3c4551' }} />

            {isYesChecked ? (
              <YesConfirmation cliId={cliId} partId={partId} />
            ) : (
              <NoConfirmation toggle={toggle} />
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
