import { useTreatments } from '@splitsoftware/splitio-react';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { api } from 'fr-shared/api';
import { Alert, Button, Checkbox, Loading, Modal } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { SHIPPING_PAYMENT_TYPES } from 'fr-shared/lib/shipping';

const GenerateSupplierPurchaseOrderButton = ({ order, getOrders }) => {
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const capLineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'Build Pack' : 'Line Item';
  const lineItemName = capLineItemName.toLowerCase();

  const { setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [supplierPOList, setSupplierPOList] = useState([]);
  const [generateError, setGenerateError] = useState(false);

  const lineItemSuppliers = order.line_items.reduce((acc, line_item) => {
    if (!line_item.location.in_house && !acc.some(item => item.id === line_item.location.id)) {
      acc.push(line_item.location);
    }

    return acc;
  }, []);

  const [hasExternalSupplier] = useState(lineItemSuppliers.length > 0);

  const generatedSupplierPO = order.supplier_purchase_orders?.reduce((acc, purchaseOrder) => {
    acc[purchaseOrder.location_id] = purchaseOrder;
    return acc;
  }, {});

  const { shipping_payment_type, shipping_account_number, line_items } = order;

  const isValidPaymentType =
    (shipping_payment_type === SHIPPING_PAYMENT_TYPES.CustomerAccount &&
      !!shipping_account_number) ||
    shipping_payment_type === SHIPPING_PAYMENT_TYPES.FreeShipping ||
    shipping_payment_type === SHIPPING_PAYMENT_TYPES.InvoiceToCustomer;

  const hasMissingUnitCosts = line_items.some(lineItem => {
    return lineItem.unit_cost === null;
  });

  const getMissingUnitCosts = () => {
    return line_items
      .filter(lineItem => get(lineItem, 'unit_cost.amount'))
      .map(lineItemMissingPrice => {
        return `${capLineItemName} ID: ${lineItemMissingPrice.id}, \n`;
      });
  };

  const openModal = () => {
    if (lineItemSuppliers.length > 0) {
      setModalOpen(true);
      setSupplierPOList([]);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setLoading(false);
  };

  const setPOId = (state, id) => {
    id = parseInt(id.replace('location-', ''));
    if (state) {
      supplierPOList.push(parseInt(id));
    } else {
      supplierPOList.splice(supplierPOList.indexOf(id), 1);
    }
    setSupplierPOList(supplierPOList);

    if (supplierPOList.length === 0) {
      setGenerateError(true);
    } else {
      setGenerateError(false);
    }
  };

  const handleGeneratePurchaseOrder = supplierPOList => {
    if (!isValidPaymentType) {
      setAlert({
        message:
          "For this order, we are shipping on a customer account. Please enter the customer's shipping account number to generate the supplier PO.",
        color: 'danger',
      });
      return;
    }

    if (hasMissingUnitCosts) {
      setAlert({
        message: `In order to generate a Supplier Purchase Order, we need to know our unit costs - the prices per unit we will pay our supplier. The following ${lineItemName}s have missing unit costs: ${getMissingUnitCosts()}`,
        color: 'danger',
      });
      return;
    }

    let params, path;

    path = `/orders/${order.id}/supplier_po`;

    if (supplierPOList.length > 0) {
      params = { suppliers: supplierPOList };
    } else {
      setGenerateError(true);
      return;
    }

    setLoading(true);

    return api
      .post(path, params)
      .then(res => {
        if (res.data.length > 0) {
          res.data.map(path => {
            window.open(path);
          });
        }
      })
      .catch(() => {
        setAlert({
          message: 'Supplier PO generation failed. Please try again.',
          color: 'danger',
        });
      })
      .finally(() => {
        setLoading(false);
        getOrders();

        if (modalOpen) {
          closeModal();
        }
      });
  };

  return (
    <div className="d-inline-block">
      <Button
        className="ml-2 mb-2"
        color="primary"
        dataTestId="supplier-purchase-button"
        loading={loading}
        onClick={openModal}
        size="sm"
        tooltip={!hasExternalSupplier ? 'Only for externally-produced orders' : null}
        disabled={!hasExternalSupplier}
      >
        Generate Supplier Purchase Order
      </Button>

      {modalOpen && (
        <Modal isOpen={true} scrollable={true} toggle={closeModal}>
          <Modal.Header
            title={<span>Generate Supplier PO</span>}
            onClose={closeModal}
            textUppercase={false}
          />
          <Modal.Body>
            {generateError && <Alert color="danger">Please select at least one supplier.</Alert>}

            <p className="mb-2">Who would you like to generate purchase order for?</p>
            {lineItemSuppliers.map((location, index) => {
              return (
                <div key={index} className="bg-gray-100 px-2 py-1 mb-2">
                  <Checkbox
                    name={'location-' + location.id}
                    label={location.name}
                    className="mb-0"
                    onChange={e => {
                      setPOId(e.target.checked, e.target.name);
                    }}
                    disabled={loading}
                  />
                  {generatedSupplierPO && generatedSupplierPO[location.id] && (
                    <ul>
                      <li className="ml-1">
                        <i>
                          <span className="font-weight-bolder">
                            {generatedSupplierPO[location.id].location_name} - v.
                            {generatedSupplierPO[location.id].revision}.pdf
                          </span>{' '}
                          generated by {generatedSupplierPO[location.id].created_by.name} on{' '}
                          {moment(generatedSupplierPO[location.id].updated_at).format(
                            'MM/DD/YY, LT'
                          )}
                        </i>
                      </li>
                    </ul>
                  )}
                </div>
              );
            })}

            {order.supplier_purchase_orders?.length > 0 && (
              <p data-testid="po-invalidation-label">
                This action will invalidate any previously generated POs for related line items.
              </p>
            )}

            {loading && (
              <div className="mb-2">
                <i>Generating </i>
                <Loading />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={loading} color="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              disabled={loading}
              color="primary"
              onClick={() => handleGeneratePurchaseOrder(supplierPOList)}
              dataTestId="generate-multiple-supplier-po-btn"
            >
              Generate
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

GenerateSupplierPurchaseOrderButton.propTypes = {
  order: PropTypes.object,
  getOrders: PropTypes.func,
};

export default GenerateSupplierPurchaseOrderButton;
