import React from 'react';

import { IconFont } from 'fr-shared/components';
import { UserAnalyticsService, useUserAnalyticsContext } from 'fr-shared/context';

import { Button, EmptyPartsCard, Link } from 'portal/components';

import PartsGridContainer from './../../components/parts/PartsGridContainer';

interface RecentPartProps {
  hasParts: boolean;
  recentParts: any[] /* TODO: create interface once other parts components are typescript as well to share */;
}

export const RecentParts = ({ hasParts, recentParts }: RecentPartProps) => {
  const userAnalytics: UserAnalyticsService = useUserAnalyticsContext();

  const handleAllPartsClick = () => userAnalytics.track(`View All Parts`, { valid: true });
  const logAddPart = () => userAnalytics.track('Add Part - Dash', { valid: true });

  const Header = () => (
    <div className="mb-4">
      <div className="flex flex-col md:flex-row justify-content-between mb-1">
        <div className="flex align-items-center justify-content-between">
          <h3>Recent parts</h3>
          {hasParts && (
            <Link className="ml-2 underline" to="/parts" onClick={handleAllPartsClick}>
              View all
            </Link>
          )}
        </div>
        <div className="flex justify-content-end">
          <Button size="sm" to="/add-part">
            <IconFont name="plus" className="mr-1" /> Add new part
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="py-2">
      {hasParts && <Header />}

      {hasParts ? (
        <PartsGridContainer parts={recentParts} />
      ) : (
        <EmptyPartsCard onActionClick={logAddPart} />
      )}
    </div>
  );
};

export default RecentParts;
