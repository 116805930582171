import * as Yup from 'yup';

import { billingSchema, shippingSchema } from 'fr-shared/lib/address';
import { INSPECTION_TYPE_NAMES } from 'fr-shared/lib/inspection_types';
import {
  MANUFACTURING_PROCESSES,
  mfgProcessesRequiringColor,
} from 'fr-shared/lib/manufacturing_process';
import { mergeSchemas } from 'fr-shared/utils';

export const rawLineItemSchema = {
  quantity: Yup.string().required('Quantity is required'),
  inspection_type_id: Yup.string().nullable().required('Choose an inspection type'),
  inspection_type: Yup.object().required(),
  manufacturing_process_id: Yup.string().nullable().required('Choose a process'),
  manufacturing_process: Yup.object(),
  material_id: Yup.string()
    .nullable()
    .when('manufacturing_process', (manufacturing_process, schema) => {
      const isMFGProcessNeedingMaterial =
        manufacturing_process &&
        [
          MANUFACTURING_PROCESSES.DLS,
          MANUFACTURING_PROCESSES.MJF,
          MANUFACTURING_PROCESSES.FDM,
        ].includes(manufacturing_process.name);

      return isMFGProcessNeedingMaterial ? schema.required('Choose a material') : schema;
    }),
  color_id: Yup.string()
    .nullable()
    .when('manufacturing_process', (manufacturing_process, schema) => {
      const processRequiresColor =
        manufacturing_process && mfgProcessesRequiringColor.includes(manufacturing_process.name);

      return processRequiresColor ? schema.required('Choose a color') : schema;
    }),
  part: Yup.object().when('inspection_type', {
    is: inspectionType => {
      const inspectionTypeName = inspectionType.name;
      return (
        inspectionType &&
        inspectionTypeName !== INSPECTION_TYPE_NAMES.VisualCheck &&
        inspectionTypeName !== INSPECTION_TYPE_NAMES.BasicInspection
      );
    },
    then: Yup.object({
      documents: Yup.array().min(1, 'This inspection type requires a drawing'),
    }),
    otherwise: Yup.object({}),
  }),
};
export const lineItemSchema = Yup.object().shape(rawLineItemSchema);

export const lineItemsSchema = Yup.object().shape({
  line_items: Yup.array().of(lineItemSchema).required(),
});

export const requestCallSchema = Yup.object().shape({
  what_do_you_want_to_build: Yup.string().nullable().required('This is a required field'),
  which_manufacturing_process_did_you_have_in_mind: Yup.string()
    .nullable()
    .required('This is a required field'),
});

export const poPaymentSchema = Yup.object().shape({
  credit_card_checkout: Yup.boolean(),
  customer_po: Yup.string()
    .trim()
    .when('credit_card_checkout', {
      is: false,
      then: schema => schema.required('PO # is required'),
    }),
  po_documents: Yup.array().when('credit_card_checkout', {
    is: false,
    then: schema => schema.min(1).required(),
  }),
  billing_address: Yup.object()
    .shape({
      email: Yup.string().email('Enter an email address').label('Email invoice to').nullable(),
    })
    .when('credit_card_checkout', {
      is: false,
      then: Yup.object({
        email: Yup.string().required('Enter an email address'),
      }),
    }),
});

export const quoteSchema = mergeSchemas(
  lineItemsSchema,
  shippingSchema,
  billingSchema,
  poPaymentSchema
);

export const subscriptionSchema = mergeSchemas(billingSchema, poPaymentSchema);

export const manualQuoteSchema = mergeSchemas(shippingSchema, billingSchema, poPaymentSchema);

export const shippingAccountSchema = Yup.object().shape({
  shipping_account_number: Yup.string().required('Account number is required'),
  shipping_method: Yup.string().required('Shipping method is required'),
});

export const draftCheckoutShippingSchema = Yup.object().shape({
  shipping_method: Yup.string().required('Shipping method is required'),
  shipping_and_taxes_total: Yup.object().shape({
    amount: Yup.string().required(),
  }),
});
