import React, { SyntheticEvent } from 'react';

import { Button, FormFieldBase, Input } from 'fr-shared/components';

import {
  AutoquoteErrorValues,
  AutoquoteErrors,
  AutoquoteResponseState,
  isAutoquoteError,
} from 'portal/lib/autoquote';

import Panel from '../components/Panel/Panel';
import AlertBox from '../dfm/AlertBox';
import { PartConfig } from '../viewer';
import CostRanges from './CostRanges';

const COST_ESTIMATE_TOOLTIP =
  'All costs are estimates only and intended as a guide. Final costs are shown at checkout and will be affected by extra details like inspections, finishes, 2D drawings and certifications. We might need to manually review your part before a final cost is given.';

interface Props {
  autoquoteResponseState: AutoquoteResponseState;
  isSubmitting?: boolean;
  onFieldChanges: (changes: Partial<PartConfig>) => void;
  onRequestManual: () => void;
  partConfig: PartConfig;
  partId?: number;
  selectedManufacturingProcess: ManufacturingProcess;
}

const CostsTab = ({
  autoquoteResponseState,
  isSubmitting,
  onFieldChanges,
  onRequestManual,
  partConfig,
  partId,
  selectedManufacturingProcess,
}: Props) => {
  // TODO: lots of ?.id checking, make consistent
  return (
    <div className="flex flex-column flex-fill">
      <FormFieldBase name="quantity" label="Quantity" isControl={true}>
        <Input
          className="mb-0"
          name="quantity"
          value={partConfig.quantity}
          type="number"
          size="md"
          onChange={(event: SyntheticEvent<HTMLInputElement>) =>
            onFieldChanges({ quantity: +event.currentTarget.value })
          }
          min={1}
        />
      </FormFieldBase>

      {isAutoquoteError(autoquoteResponseState) && (
        <AutoquoteAlertBox
          errors={autoquoteResponseState.errors}
          isSubmitting={isSubmitting}
          selectedColorId={partConfig?.color_id}
          selectedManufacturingProcess={selectedManufacturingProcess}
          selectedMaterialId={partConfig?.material_id}
          onRequestManual={onRequestManual}
          partId={partId}
        />
      )}

      <Panel.Bottom>
        <CostRanges autoquoteResponse={autoquoteResponseState} />
      </Panel.Bottom>
    </div>
  );
};

const AutoquoteAlertBox = ({
  errors,
  isSubmitting,
  onRequestManual,
  partId,
  selectedColorId,
  selectedManufacturingProcess,
  selectedMaterialId,
}: {
  errors: AutoquoteErrorValues;
  isSubmitting?: boolean;
  onRequestManual: () => void;
  partId?: number;
  selectedColorId?: number;
  selectedManufacturingProcess: ManufacturingProcess;
  selectedMaterialId: number;
}) => {
  if (Array.isArray(errors) && errors.includes(AutoquoteErrors.Incomplete)) {
    return (
      <AlertBox title="Cost Estimates" tooltip={COST_ESTIMATE_TOOLTIP}>
        <p className="text-muted">
          We can give you instant cost estimates for any of these processes:
        </p>
        <p className="text-muted my-2">
          &mdash; Digital Light Synthesis
          <br />
          &mdash; Multijet Fusion
          <br />
          &mdash; Fused Deposition Modeling
          <br />
          &mdash; Stereolithography
          <br />
          &mdash; CNC Machining
          <br />
        </p>
        <p className="text-muted mt-2">
          Choose a{' '}
          {!selectedManufacturingProcess?.id && (
            <span className="text-white">manufacturing process</span>
          )}
          {!selectedManufacturingProcess?.id && !selectedMaterialId && ' and '}
          {!selectedMaterialId && <span className="text-white">material</span>}
          {selectedMaterialId && selectedManufacturingProcess?.id && !selectedColorId && (
            <span className="text-white">color</span>
          )}{' '}
          to get started.
        </p>
      </AlertBox>
    );
  }

  if (Array.isArray(errors) && errors.includes(AutoquoteErrors.ManufacturabilityErrors)) {
    return (
      <AlertBox title="Cost Estimates" tooltip={COST_ESTIMATE_TOOLTIP}>
        <p className="text-muted">
          We were unable to generate cost estimates for this part because it has critical
          manufacturability errors.
        </p>
        <Button block outline to={`/studio/evaluate/${partId}/dfm`}>
          See all issues in detail
        </Button>
      </AlertBox>
    );
  }

  return (
    <AlertBox title="Cost Estimates" tooltip={COST_ESTIMATE_TOOLTIP}>
      <p className="text-muted">
        We were unable to generate cost estimates for this part and configuration.
      </p>
      <p className="text-muted mt-2">
        But if you send us a request we can make the same estimates manually.
      </p>
      <Button block outline loading={isSubmitting} onClick={onRequestManual}>
        Request manual estimates
      </Button>
    </AlertBox>
  );
};

export default CostsTab;
