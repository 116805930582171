import { Logo } from '@fast-radius/shared-ui';
import PropTypes from 'prop-types';
import React from 'react';

import { MARKETING_URLS } from 'fr-shared/lib/marketing_urls';

import { Link } from 'portal/components';

import styles from './FooterInsights.module.css';

const FooterInsights = ({ mailTo, salesContact }) => {
  return (
    <>
      <div className="container-sm my-5">
        <div className="bg-gray-600 rounded-md insights-relative">
          <div className="p-3">
            <h3 className="mb-3">Want to talk to someone about your project?</h3>
            <p>
              Need an expert? Our Manufacturing Success Team can help you design for
              manufacturability, select materials, evaluate post-processing choices, run cost
              analyzes, determine inspection criteria, and more.
            </p>
            <p>
              <Link to={{ pathname: mailTo }} openNewWindow>
                Reach out to {salesContact.first_name}
              </Link>{' '}
              to get started.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.Footer}>
        <div className="pt-4 pb-5">
          <div className="container-sm ">
            <div className="flex flex-col md:flex-row justify-content-between">
              <div className="mb-4">
                <div className="text-white font-size-lg mb-1">
                  Contact {salesContact.first_name}
                </div>
                <div>
                  {!salesContact.internal && <div className="mb-1">{salesContact.name}</div>}
                  <div className="mb-1">
                    <Link
                      className="text-decoration-none"
                      to={{ pathname: `mailto:${salesContact.email}` }}
                      openNewWindow
                    >
                      {salesContact.email}
                    </Link>
                  </div>
                  <div className="mb-1">{salesContact.phone_number}</div>
                </div>
                <div className="mt-4">
                  <Link className="text-decoration-none" to={{ pathname: mailTo }} openNewWindow>
                    <span className="mr-2">Email {salesContact.first_name}</span>
                    <IconLongArrow />
                  </Link>
                </div>
              </div>
              <div>
                <Logo className="mb-2" fill="white" />
                <div>
                  <div className="mb-1">
                    <Link
                      className="text-decoration-none"
                      to={{ pathname: MARKETING_URLS.Resources }}
                      openNewWindow
                    >
                      Resources
                    </Link>
                  </div>
                  <div className="mb-1">
                    <Link
                      className="text-decoration-none"
                      to={{ pathname: MARKETING_URLS.Legal }}
                      openNewWindow
                    >
                      Legal Notices
                    </Link>
                  </div>
                  <div className="mb-1">
                    <Link
                      className="text-decoration-none"
                      to={{ pathname: MARKETING_URLS.Privacy }}
                      openNewWindow
                    >
                      Privacy Policy
                    </Link>
                  </div>
                  <div className="mb-1">
                    <Link
                      className="text-decoration-none"
                      to={{ pathname: MARKETING_URLS.TermsAndConditions }}
                      openNewWindow
                    >
                      Terms and Conditions
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const IconLongArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="61"
    height="16"
    viewBox="0 0 61 16"
    fill="none"
    className="mr-2"
  >
    <path
      d="M60.7071 8.70711C61.0976 8.31658 61.0976 7.68342 60.7071 7.29289L54.3431 0.928932C53.9526 0.538408 53.3195 0.538408 52.9289 0.928932C52.5384 1.31946 52.5384 1.95262 52.9289 2.34315L58.5858 8L52.9289 13.6569C52.5384 14.0474 52.5384 14.6805 52.9289 15.0711C53.3195 15.4616 53.9526 15.4616 54.3431 15.0711L60.7071 8.70711ZM0 9H60V7H0V9Z"
      fill="#ffffff"
    />
  </svg>
);

FooterInsights.propTypes = {
  salesContact: PropTypes.object,
  mailTo: PropTypes.string,
};

export default FooterInsights;
