import PropTypes from 'prop-types';
import React from 'react';

import { PartImage, classNames } from 'portal/components';

import styles from './PartTracker.module.css';

const PartTracker = ({ screenshot, state }) => {
  const buildClassNames = baseClass => {
    return classNames([
      baseClass,
      state === 'In production' && styles.InProduction,
      state === 'Partially shipped' && styles.PartiallyShipped,
      state === 'Shipped' && styles.Shipped,
    ]);
  };

  return (
    <div className={styles.Root}>
      <div className={styles.Container}>
        <div className={styles.ConicBackground} />
        <div className={buildClassNames(styles.ConicBlur)} />
        <div className={buildClassNames(styles.Conic)} />
        <PartImage className={styles.PartImage} src={screenshot} />

        <div data-rh="Ordered" className={styles.MarkerOne}>
          <div className={styles.MarkerCircle} />
        </div>

        <div data-rh="In production" className={buildClassNames(styles.MarkerTwo)}>
          <div className={styles.MarkerCircle} />
        </div>

        <div data-rh="Shipped" className={styles.MarkerThree}>
          <div className={styles.MarkerCircle} />
        </div>

        <div data-rh={state} className={buildClassNames(styles.CurrentStatusMarker)}>
          <div className={styles.CurrentStatusCircle} />
        </div>
      </div>
    </div>
  );
};

PartTracker.propTypes = {
  screenshot: PropTypes.string,
  state: PropTypes.string.isRequired,
};

export default PartTracker;
