import { FieldArray, useFormikContext } from 'formik';
import { get, pullAt, set } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Button, FormCurrency, FormField } from 'fr-shared/components';

import { QuoteFormContext } from './QuoteFormContext';

const QuoteFormAdjustments = ({ readonly, index }) => {
  const formik = useFormikContext();
  const { setValuesAndPushToChannel } = useContext(QuoteFormContext);
  const name = `line_items.${index}`;
  const adjustments = get(formik.values, `${name}.adjustments`, []);

  const handleAmountBlur = (type, adjustmentIndex) => {
    const amount = get(formik.values, `${name}.adjustments.${adjustmentIndex}.amount`);
    let formattedAmount = amount.replace(/[^0-9.]/g, '');
    let newValues = formik.values;
    if (type === 'discount') {
      newValues = set(
        newValues,
        `${name}.adjustments.${adjustmentIndex}.amount`,
        (formattedAmount * -1).toString()
      );
    }
    setValuesAndPushToChannel(newValues);
  };

  const handleRemoveAdjustment = adjustmentIndex => {
    const newLineItems = [...formik.values.line_items].map((li, i) => {
      if (i === index) pullAt(li.adjustments, adjustmentIndex);
      return li;
    });
    setValuesAndPushToChannel({ ...formik.values, line_items: newLineItems });
  };

  const adjustmentLabel = {
    discount: 'Unit Price Discount',
    extra: 'Extra',
  };

  return (
    <FieldArray name={`${name}.adjustments`}>
      {arrayHelpers => (
        <>
          {!readonly && (
            <div className="mb-3">
              <Button
                color="link"
                className="p-0 mr-4"
                onClick={() => arrayHelpers.push({ type: 'extra', amount: '', description: '' })}
              >
                Add Extra Charge
              </Button>
              <Button
                color="link"
                className="p-0"
                onClick={() =>
                  arrayHelpers.push({ type: 'discount', amount: '', description: '' })
                }
              >
                Add Unit Price Discount
              </Button>
            </div>
          )}
          {adjustments &&
            adjustments.length > 0 &&
            adjustments.map((adjustment, adjustmentIndex) => (
              <div key={`extra-${adjustmentIndex}`} className="mb-3">
                <div className="flex">
                  <div className="mr-3">
                    <FormCurrency
                      isRequired={true}
                      readonly={readonly}
                      label={adjustmentLabel[adjustment.type]}
                      isStacked={true}
                      onBlur={() => handleAmountBlur(adjustment.type, adjustmentIndex)}
                      name={`${name}.adjustments.${adjustmentIndex}.amount`}
                    />
                  </div>
                  <FormField
                    required={true}
                    readonly={readonly}
                    name={`${name}.adjustments.${adjustmentIndex}.description`}
                    className="flex-1 mb-0"
                    label={`${adjustmentLabel[adjustment.type]} Note`}
                    onBlur={() => setValuesAndPushToChannel(formik.values)}
                    stacked={true}
                  />
                </div>
                <div className="text-right">
                  <Button
                    color="link"
                    className="font-size-sm p-0"
                    onClick={() => handleRemoveAdjustment(adjustmentIndex)}
                  >
                    Remove {adjustment.type}
                  </Button>
                </div>
              </div>
            ))}
        </>
      )}
    </FieldArray>
  );
};

QuoteFormAdjustments.propTypes = {
  readonly: PropTypes.bool,
  formik: PropTypes.object,
  index: PropTypes.number,
};

export default QuoteFormAdjustments;
