import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { keyboardDown } from 'fr-shared/utils';

const Address = ({ address, className, dataTestId = 'address', flatten, onClick }) => {
  const hasNameLine2 = get(address, 'name_line_2', false);
  const hasStreetLine2 = get(address, 'street_line_2', false);

  if (!address) return null;

  if (flatten)
    return (
      <div
        data-testid={dataTestId}
        className={className}
        onClick={onClick}
        onKeyDown={evt => keyboardDown(evt, 'Enter', onClick)}
        role="button"
        tabIndex={0}
      >
        <span data-testid={`${dataTestId}-name_line_1`}>{get(address, 'name_line_1')}</span>
        {hasNameLine2 && (
          <span data-testid={`${dataTestId}-name_line_2`}>
            {` | `}
            {get(address, 'name_line_2')}
          </span>
        )}
        <br />
        <span data-testid={`${dataTestId}-street_line_1`}>{get(address, 'street_line_1')}</span>
        {hasStreetLine2 && (
          <span data-testid={`${dataTestId}-street_line_2`}>
            , {get(address, 'street_line_2')}
          </span>
        )}
        {', '}
        <span data-testid={`${dataTestId}-city`}>{get(address, 'city')}</span>,{' '}
        <span data-testid={`${dataTestId}-state`}>{get(address, 'state')}</span>{' '}
        <span data-testid={`${dataTestId}-postal_code`}>{get(address, 'postal_code')}</span>
        {', '}
        <span data-testid={`${dataTestId}-country`}>{get(address, 'country')}</span>
        {get(address, 'phone_number') && (
          <>
            {', '}
            <span data-testid={`${dataTestId}-phone_number`}>{get(address, 'phone_number')}</span>
          </>
        )}
      </div>
    );

  return (
    <div
      data-testid={dataTestId}
      className={`font-size-md ${className}`}
      onClick={onClick}
      onKeyDown={evt => keyboardDown(evt, 'Enter', onClick)}
      role="button"
      tabIndex="0"
    >
      <div>{get(address, 'name_line_1')}</div>
      {hasNameLine2 && <div>{get(address, 'name_line_2')}</div>}
      <div>{get(address, 'street_line_1')}</div>
      {hasStreetLine2 && <div>{get(address, 'street_line_2')}</div>}
      <div>
        {get(address, 'city')}, {get(address, 'state')} {get(address, 'postal_code')}
      </div>
      <div>{get(address, 'country')}</div>
      <div>{get(address, 'phone_number')}</div>
    </div>
  );
};

Address.propTypes = {
  address: PropTypes.object,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  flatten: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Address;
