import { useFormikContext } from 'formik';
import { get } from 'lodash';
import React from 'react';

import { Money, classNames } from 'portal/components';
import { BILLING_CYCLE, SUBSCRIPTION, getPricingBasedOffSelectedPlan } from 'portal/lib/plans';

interface SubscriptionSummaryProps {
  className?: string;
  billingCycle?: string;
  subscriptionType: string;
}

const SubscriptionSummary = ({
  className,
  billingCycle,
  subscriptionType,
}: SubscriptionSummaryProps) => {
  const { values } = useFormikContext();
  const subscriptionPlans = get(values, 'subscription_plans', []);
  const { monthlyPrice, totalAnnualPrice } = getPricingBasedOffSelectedPlan(
    subscriptionType,
    subscriptionPlans
  );
  return (
    <div
      data-testid="subscription-checkout-summary"
      className={classNames(['flex flex-col', className])}
    >
      <div className="flex flex-col">
        <div className="text-xs text-coolGray-300 pb-2">
          1x{' '}
          {subscriptionType === SUBSCRIPTION.BASIC
            ? ''
            : billingCycle === BILLING_CYCLE.MONTHLY
            ? 'Month'
            : 'Annual'}{' '}
          subscription to{' '}
          <strong className="text-coolGray-100">Fast Radius {subscriptionType}</strong>
        </div>
        <div className="flex text-coolGray-100 justify-between">
          <div className="text-base">
            Total per {billingCycle === BILLING_CYCLE.MONTHLY ? 'month' : 'year'}
          </div>
          <Money
            className="text-2xl "
            amount={billingCycle === BILLING_CYCLE.MONTHLY ? monthlyPrice : totalAnnualPrice}
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSummary;
