import React from 'react';

import { getMaterialImageUrl } from 'portal/lib/material';

import SwatchImage from '../components/SwatchImage/SwatchImage';
import { MATERIAL_DESCRIPTIONS } from '../copy';

type Props = {
  material: Material;
  selectedManufacturingProcess: ManufacturingProcess;
};

export const getColorValue = (materialColors: Color[]) => {
  let colorValue: string = null;
  if (materialColors.length === 1) {
    colorValue = materialColors[0].name;
  } else if (materialColors?.length > 1) {
    colorValue = 'Customizable';
  }

  return colorValue;
};

const MaterialInfo = ({ selectedManufacturingProcess, material }: Props) => {
  if (!material) return null;

  let colorValue = getColorValue(material.colors);

  if (material.colors?.length === 1) {
    colorValue = material.colors[0].name;
  } else if (material.colors?.length > 1) {
    colorValue = 'Customizable';
  }

  return (
    <div className="pt-4">
      <div className="flex mb-9">
        <SwatchImage
          src={getMaterialImageUrl(selectedManufacturingProcess?.name, material.name)}
          className="mr-4 rounded"
          width={98}
          height={98}
        />
        <div>
          <div className="font-size-xxl text-white my-1">{material.name}</div>
          <div className="font-size-md text-coolGray-300">
            {MATERIAL_DESCRIPTIONS[material.name]}
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row mb-9">
        <MaterialInfoFeature
          label="Density"
          description="This number indicates the average weight per unit volume."
          value={material.density}
          units="g/cm^3"
        />

        <MaterialInfoFeature
          label="Tensile modulus"
          description="Amount of force required to stretch the material per unit strain."
          value={material.tensile_modulus}
          units="MPa"
        />

        <MaterialInfoFeature
          label="Tensile Strength"
          description="Level of stress a material can take before it breaks."
          value={material.tensile_strength_max_load}
          units="MPa"
        />
      </div>

      <div className="flex flex-col md:flex-row mb-9">
        <MaterialInfoFeature
          label="Elongation"
          description="How much a material can be stretched before it breaks."
          value={material.elongation_at_max_load_xy}
          units="%"
        />

        <MaterialInfoFeature
          label="Heat deflection"
          description="Temperature at which the material will begin to deform at 1.8MPa."
          value={material.heat_deflection_at_45_mpa}
          units="&#8451;"
        />

        <MaterialInfoFeature
          label="Color"
          description={
            'Standard color options for the material (not for post-processing finishing).'
          }
          value={colorValue}
        />
      </div>
    </div>
  );
};

export type MaterialInfoFeatureProps = {
  label: string;
  description: string;
  value?: string;
  units?: string;
};

const MaterialInfoFeature = ({ label, description, value, units }: MaterialInfoFeatureProps) => {
  return (
    <div className="flex-1 border-left mb-2 pl-2">
      <div className="font-size-base text-white mb-1">{label}</div>
      <div className="font-size-md text-coolGray-300 mb-1" style={{ maxWidth: 260 }}>
        {description}
      </div>
      <div className="mt-auto">
        {value ? (
          <>
            <span className="font-size-xl text-white mr-1">{value}</span>
            {units && <span className="font-size-md text-coolGray-300">{units}</span>}
          </>
        ) : (
          <span className="font-size-xl text-white">-/-</span>
        )}
      </div>
    </div>
  );
};

export default MaterialInfo;
