import {
  Material as BabylonMaterial,
  StandardMaterial as BabylonStandardMaterial,
  Color3,
  Scene,
} from 'babylonjs';

export type Material = StandardMaterial;

/**
 * Emits light in every direction from a point.
 */
export type StandardMaterial = {
  kind: 'StandardMaterial';
  diffuseColor: Color3;
  specularColor: Color3;
  emissiveColor: Color3;
  ambientColor: Color3;
  alpha: number;
};

/**
 * Returns a point light.
 */
export const newStandardMaterial = (
  diffuseColor?: Color3,
  specularColor?: Color3,
  emissiveColor?: Color3,
  ambientColor?: Color3,
  alpha?: number
): StandardMaterial => {
  return {
    kind: 'StandardMaterial',
    diffuseColor: diffuseColor ? diffuseColor : Color3.Black(),
    specularColor: specularColor ? specularColor : Color3.Black(),
    emissiveColor: emissiveColor ? emissiveColor : Color3.Black(),
    ambientColor: ambientColor ? ambientColor : Color3.Black(),
    alpha: alpha !== undefined ? alpha : 1.0,
  };
};

/**
 * Asserts that the material is of type `StandardMaterial`.
 */
export const isStandardMaterial = (material: Material): material is Material => {
  return material.kind === 'StandardMaterial';
};

/**
 * Adds the material to the Babylon scene and returns the material.
 */
export const addToScene = (material: Material, scene: Scene): BabylonMaterial => {
  if (isStandardMaterial(material)) {
    let babylonMaterial = new BabylonStandardMaterial(null, scene);
    babylonMaterial.diffuseColor = material.diffuseColor;
    babylonMaterial.specularColor = material.specularColor;
    babylonMaterial.emissiveColor = material.emissiveColor;
    babylonMaterial.ambientColor = material.ambientColor;
    babylonMaterial.alpha = material.alpha;
    return babylonMaterial;
  } else {
    throw new Error('material type not supported');
  }
};
