import PropTypes from 'prop-types';
import React from 'react';

import { isPoPaymentTerm } from 'fr-shared/lib/orders';

import { Address } from 'portal/components';
import { getShippingText } from 'portal/lib/shipping';

const QuoteShippingAndBilling = ({ quote }) => {
  const isPoPayment = quote.order && isPoPaymentTerm(quote.order.payment_terms);
  const poDocs = quote.order.po_documents;

  return (
    <div className="row mb-5">
      <div className="col-12 col-md-6">
        <h3>Shipping details</h3>
        <div className="row mt-4">
          <div className="col">
            <div className="font-sm text-gray">Shipping method</div>
            <div>
              {quote?.selected_shipping_estimate?.service
                ? getShippingText(quote?.selected_shipping_estimate?.service)
                : 'No shipping method'}
            </div>
          </div>
          <div className="col">
            <div className="font-sm text-gray">Address</div>
            {quote?.shipping_address ? (
              <Address address={quote?.shipping_address} />
            ) : (
              'No shipping address'
            )}
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 mt-2 mt-md-0">
        <h3>Billing details</h3>
        <div className="row mt-4">
          {!isPoPayment && (
            <div className="col">
              <div className="font-sm text-gray">Credit card</div>
              <div>{quote?.payment_method?.last_4_digits}</div>
            </div>
          )}
          {isPoPayment && (
            <div className="col">
              <div className="font-sm text-gray">PO #</div>
              <div>{quote.order.customer_po}</div>

              <div className="font-sm text-gray mt-3">PO Document(s)</div>
              {!poDocs?.length && <div>None</div>}
              {!!poDocs?.length && (
                <ul className="p-0">
                  {poDocs.map(doc => (
                    <li key={doc.id} className="list-none break-all">
                      {doc.file_name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
          <div className="col">
            <div className="font-sm text-gray">Address</div>
            <Address address={quote?.billing_address} />
          </div>
        </div>
      </div>
    </div>
  );
};
QuoteShippingAndBilling.propTypes = {
  quote: PropTypes.shape({
    selected_shipping_estimate: PropTypes.shape({
      service: PropTypes.string,
    }),
    shipping_address: PropTypes.object,
    billing_address: PropTypes.object,
    payment_method: PropTypes.shape({ last_4_digits: PropTypes.string }),
    order: PropTypes.shape({
      customer_po: PropTypes.string,
      payment_terms: PropTypes.string,
      po_documents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          file_name: PropTypes.string,
        })
      ),
    }),
  }),
};

export default QuoteShippingAndBilling;
