import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { AlertContext } from 'fr-shared/context';

import { Page } from 'portal/components';

import QuoteDetailsBody from './components/QuoteDetailsBody';
import QuoteDetailsHeader from './components/QuoteDetailsHeader';

const ManualQuoteView = () => {
  const [quote, setQuote] = useState(null);
  const history = useHistory();
  const { setAlert } = useContext(AlertContext);
  const { id } = useParams();

  useEffect(() => {
    api
      .get(`/customer_portal/quotes/manual_quote_request/${id}`)
      .then(res => {
        setQuote(res.data);
      })
      .catch(() => {
        setAlert({ color: 'danger', message: 'We were unable to load your quote' });
        history.push('/quotes');
      });
  }, [history, id, setAlert]);

  if (!quote) return null;

  return (
    <Page>
      <QuoteDetailsHeader quote={quote} />
      <QuoteDetailsBody quote={quote} />
    </Page>
  );
};

export default ManualQuoteView;
