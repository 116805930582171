import PropTypes from 'prop-types';
import React from 'react';

export const PartDimensions = ({ className, missingDimensionsMessage = 'N/A', part }) => {
  if (!part?.current_revision?.max_x_length) {
    return <>{missingDimensionsMessage}</>;
  }

  return (
    <>
      <span className={className}>{part.current_revision.max_x_length} </span>{' '}
      <span className="text-gray font-size-sm">x </span>
      <span className={className}>{part.current_revision.max_y_length} </span>{' '}
      <span className="text-gray font-size-sm">x </span>
      <span className={className}>{part.current_revision.max_z_length} </span>{' '}
      <span className="text-gray font-size-sm">{part.units}</span>
    </>
  );
};

PartDimensions.propTypes = {
  className: PropTypes.string,
  missingDimensionsMessage: PropTypes.string,
  part: PropTypes.shape({
    current_revision: PropTypes.shape({
      max_x_length: PropTypes.string,
      max_y_length: PropTypes.string,
      max_z_length: PropTypes.string,
    }),
    units: PropTypes.string.isRequired,
  }).isRequired,
};

export default PartDimensions;
