import { useFormikContext } from 'formik';
import { get, merge, uniqueId } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

import {
  Button,
  ButtonLink,
  Card,
  Icon,
  PartFileDropzone,
  SupportedFileTypesModal,
  ToggleSwitch,
  classNames,
} from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { useS3PartUpload } from 'fr-shared/hooks';
import { CostingRequest, NEW_COSTING_REQUEST_LINE_ITEM } from 'fr-shared/lib/costing_requests';

import styles from './index.module.css';

interface DropzoneProps {
  open: () => void;
  units: string;
  handleUnitsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const tooltip =
  "A build pack contains all the information needed to build a part. From process, to material, to inspection, it's a unique record of requirements that updates whenever the part does.";

const unitsFromBool = new Map([
  [true, 'mm'],
  [false, 'in'],
]);

export default function BuildPackChooser() {
  const formik = useFormikContext<CostingRequest>();
  const [units, setUnits] = useState('mm');
  const [parts, startUpload] = useS3PartUpload();
  const { setAlert } = useContext(AlertContext);

  const openBuildPackSearch = () => {
    alert('Coming soon! Build pack search functionality is not enabled yet.');
  };

  const handleUnitsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUnits = unitsFromBool.get(e.currentTarget.checked);
    setUnits(newUnits);
  };

  const handleFileDrop = (acceptedFiles: Array<File>) => {
    const partsToUpload = acceptedFiles.map(file => ({ file }));

    // upload all the parts to s3 and update their status
    const stagedFiles = startUpload(partsToUpload, {
      organization_id: formik.values.customer.id,
      source: 'FROS Costing',
      units: units,
    });

    const newLineItems = stagedFiles.map(({ file, uuid, upload_status }) => ({
      ...NEW_COSTING_REQUEST_LINE_ITEM,
      _id: uniqueId('cr-line-item-'),
      description: file.name,
      uuid,
      units,
      upload_status,
    }));

    formik.setFieldValue('line_items', [...formik.values.line_items, ...newLineItems]);
  };

  // watch for new parts being uploaded
  useEffect(() => {
    if (parts.length === 0) return;

    if (parts.find(part => part.upload_status === 'error')) {
      setAlert({
        message: 'There was an upload error. Please refresh the page and try again.',
        color: 'danger',
        autoClose: false,
      });
    }

    const newLineItems = formik.values.line_items.map(li => {
      const matchingPart = parts.find(part => part.uuid === li.uuid);
      if (matchingPart && li.upload_status !== matchingPart.upload_status) {
        return merge(li, {
          isOpen: true,
          part: matchingPart.data,
          part_id: get(matchingPart, 'data.id'),
          upload_status: matchingPart.upload_status,
        });
      } else {
        return li;
      }
    });

    formik.setFieldValue('line_items', newLineItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parts]);

  return (
    <Card border className="bg-light my-3">
      <Card.Header>
        <span className="font-weight-light font-size-lg">
          Build Packs
          <Icon className="ml-1 text-primary" name="info-circle" tooltip={tooltip} />
        </span>
      </Card.Header>

      <Card.Body>
        <div className="row">
          <div className={`${styles.Search} col-md-3`}>
            <h2>Find build packs for this customer</h2>

            <Button color="primary" block={true} onClick={openBuildPackSearch}>
              Search
            </Button>
          </div>

          <PartFileDropzone
            childComponent={
              <Dropzone open={open} units={units} handleUnitsChange={handleUnitsChange} />
            }
            className={classNames([styles.AddPartDropzone, 'col-md-3 part-file-dropzone'])}
            onFileDrop={handleFileDrop}
            testId="cr-part-file-dropzone"
          />
        </div>
      </Card.Body>
    </Card>
  );
}

function Dropzone({ open, units, handleUnitsChange }: DropzoneProps) {
  return (
    <>
      <h2>Add a new part</h2>

      <p className={styles.Browse}>
        Drop a 3D file here or
        <Button color="link" className="p-0 font-weight-normal align-baseline" onClick={open}>
          &nbsp;browse
        </Button>
      </p>

      <div className={styles.DimensionToggle}>
        <p className="mb-1">Dimensions in:</p>
        <ToggleSwitch
          id="file-units"
          checked={units === unitsFromBool.get(true)}
          onCheck={handleUnitsChange}
          defaultTrueLabel="mm"
          defaultFalseLabel="inches"
        />
      </div>

      <SupportedFileTypesModal
        action={
          <ButtonLink className={classNames([styles.SupportedTypes])}>
            Supported file types
            <Icon className={`${styles.Icon} ml-1`} name="chevron-up" />
          </ButtonLink>
        }
      />

      <Button
        color="white"
        className={classNames([
          styles.AddPartButton,
          'flex justify-content-center align-items-center',
          'rounded-circle cursor-pointer',
        ])}
        onClick={open}
      >
        <Icon className={styles.Icon} name="plus" />
      </Button>
    </>
  );
}
