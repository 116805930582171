import React, { SyntheticEvent, useEffect } from 'react';

import {
  DetailOptionId,
  DetailSelect,
  DetailSelectButton,
  DetailSelectOptions,
  FormFieldBase,
  IconFont,
  Input,
  Select,
  classNames,
  useDetailSelectState,
} from 'fr-shared/components';
import SupportingDocuments, {
  DocWithStatus,
} from 'fr-shared/components/form/SupportingDocuments';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { useManufacturingProcess } from 'fr-shared/hooks';
import { INSPECTION_TYPE_NAMES } from 'fr-shared/lib/inspection_types';
import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';
import { keyboardDown } from 'fr-shared/utils';
import { booleanInputFilter } from 'fr-shared/utils/input';

import { Button } from 'portal/components';
import useScopedAnalytics from 'portal/hooks/useScopedAnalytics';
import {
  AutoquoteTierList,
  isAutoquoteError,
  isAutoquoteLoading,
  shouldShowManual,
} from 'portal/lib/autoquote';
import { Part } from 'portal/lib/cart';
import FormCollapsibleMfgProcess, {
  ManufacturingProcessFields,
} from 'portal/pages/evaluate/components/FormCollapsibleMfgProcess/FormCollapsibleMfgProcess';

import { CNC_DEFAULT_VALUES, CNC_EMPTY_VALUES, cncSupportingDocumentsRequired } from '../util';
import CNCFields, { CNCFieldsState } from './CNCFields';
import { FinishAndColorField, FinishAndColorState } from './FinishAndColorField';
import InspectionTypes from './InspectionTypes';
import ItarEarField from './ItarEarField';
import styles from './PartForm.module.css';

const FieldNames = {
  MANUFACTURING_PROCESS: 'manufacturing_process',
  INFILL: 'infill',
  NOTES: 'notes',
  PORTAL_FINISH: 'customer_portal_finish',
  QUANTITY: 'quantity',
  INSPECTION_TYPE: 'inspection_type',
  INSPECTION_TYPE_ID: 'inspection_type_id',
  SUPPORTING_DOCUMENTS: 'current_documents',
} as const;

// This form is the combination of the fields inlined union the state defined in the other sub-components
export type PartFormState = {
  quantity?: number;
  id?: string | number;
  inspection_type_id?: number;
  inspection_type?: {
    id: number;
    name: string;
  };
  location_id?: number;
  customer_portal_finish?: string;
  notes?: string;
  infill?: string;
  current_documents?: DocWithStatus[];
} & FinishAndColorState &
  CNCFieldsState &
  ManufacturingProcessFields;

interface PartFormFields {
  autoquoteResponseState: any; // ERIN TODO: fix this!
  formState: PartFormState;
  part: Part;
  onChanges: (changes: Partial<PartFormState>, touched?: boolean) => void;
  readonly: boolean; // TODO(CX-711): remove with preconfig entitlement
  showBulkFields?: boolean;
  hasSupportingDocs?: boolean;
}

const NOTES_DISCLAIMER =
  "Notes will be used for reference only. If you require secondary processing (inserts, reaming, paint, etc) please tell us here. We'll review your requirements and let you know if additional costs/lead times apply.";
const SUPPORTING_DOC_DISCLAIMER =
  'Supporting documents will be used for reference only. Your parts will be made based on the specifications you’ve chosen above.';
const PORTAL_FINISH_OPTIONS = [
  {
    id: 'Standard',
    name: 'Standard',
    data: {
      rendered: (
        <div>
          <h1 className="select-none text-base leading-5 text-coolGray-100 mb-1">Standard</h1>
          <p className="text-xs leading-4 text-coolGray-300 mb-1">
            Your part will receive our standard finish for your chosen process and material.
          </p>
          <p className="text-xs leading-4 text-tertiary-500">
            Instant costings and quote available
          </p>
        </div>
      ),
    },
  },
  {
    id: 'Custom',
    name: 'Custom',
    data: {
      rendered: (
        <div>
          <h1 className="select-none text-base leading-5 text-coolGray-100">Custom</h1>
          <p className="text-xs leading-4 text-coolGray-300">
            Your part will receive a customized finish that’s been directly specified by you.
          </p>
        </div>
      ),
    },
  },
];

const PartFormFields = ({
  formState,
  part,
  autoquoteResponseState,
  onChanges,
  readonly,
  showBulkFields,
  hasSupportingDocs,
}: PartFormFields) => {
  const userAnalytics = useUserAnalyticsContext();
  const {
    isRenderedBelow: isPortalFinishRenderedBelow,
    isOpen: isPortalFinishOpen,
    handleSelectClick: handlePortalFinishClick,
    setIsOpen: setPortalFinishOpen,
  } = useDetailSelectState();
  // Grab current inputs
  const { data: manufacturingProcesses }: { data: ManufacturingProcess[] } =
    useManufacturingProcess({ publiclyAvailable: true });
  const currentProcess = manufacturingProcesses?.find(
    process => process.id === formState.manufacturing_process_id
  );
  const currentMaterial = currentProcess?.materials?.find(m => m.id === formState.material_id);
  const currentFinish = currentMaterial?.finishes?.find(f => f.id === formState.finish_id);

  /* eslint-disable react-hooks/exhaustive-deps */
  // Handle changes in hidden CNC fields
  const showCNC = currentProcess?.name === MANUFACTURING_PROCESSES.CNC;
  const showIM = currentProcess?.name === MANUFACTURING_PROCESSES.InjectionMolding;

  const showCNCFields = showCNC && currentMaterial;
  const showMaterialFinish = showCNC || showIM;
  const showNotesField = !showCNC && !showBulkFields;
  const showCustomerPortalFinish = !showCNC && !showIM;

  useEffect(() => {
    if (currentProcess) {
      if (!showCNC) {
        // When CNC fields are hidden, clear out the CNC-specific fields
        onChanges({
          ...CNC_EMPTY_VALUES,
          finish_id: null,
          customer_portal_finish: formState.customer_portal_finish || 'Standard',
        });
      } else {
        // When CNC fields are shown, clear out the fields that get hidden
        onChanges({
          notes: null,
          customer_portal_finish: null,
        });
      }
    }
  }, [showCNC]);

  // Handle changes in hidden infill field
  const showInfill = currentProcess && currentProcess.name === MANUFACTURING_PROCESSES.FDM;
  useEffect(() => {
    if (!showInfill && currentProcess) {
      onChanges({ infill: null });
    } else if (showInfill) {
      onChanges({ infill: formState.infill || 'Standard' });
    }
  }, [showInfill]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Helpers */
  const analyticsTag = 'Part Config';
  const trackInput = useScopedAnalytics(analyticsTag);

  const onChangeByName = (event: SyntheticEvent<HTMLInputElement | HTMLSelectElement>) => {
    onChanges({ [event.currentTarget.name]: event.currentTarget.value });
  };

  const handleSelectFile = () =>
    userAnalytics.track('Part Config - Supporting Docs Click Upload', { valid: true });

  /** Error parsing */
  const supportingDocumentIsRequired = (fieldName: string) => {
    if (formState.current_documents?.length > 0 || hasSupportingDocs) return false;

    const inspectionTypeDocumentsRequired =
      formState.inspection_type &&
      formState.inspection_type.name !== INSPECTION_TYPE_NAMES.VisualCheck &&
      formState.inspection_type.name !== INSPECTION_TYPE_NAMES.BasicInspection;

    switch (fieldName) {
      case FieldNames.INSPECTION_TYPE:
        return inspectionTypeDocumentsRequired;
      case FieldNames.SUPPORTING_DOCUMENTS:
        return inspectionTypeDocumentsRequired || cncSupportingDocumentsRequired(formState);
      default:
        return false;
    }
  };

  const errors = {
    [FieldNames.QUANTITY]: formState.quantity <= 0 ? 'Must be greater than 0' : null,
    [FieldNames.INSPECTION_TYPE]: supportingDocumentIsRequired(FieldNames.INSPECTION_TYPE)
      ? 'This inspection type requires a drawing. Please upload a supporting document.'
      : null,
    [FieldNames.SUPPORTING_DOCUMENTS]: supportingDocumentIsRequired(
      FieldNames.SUPPORTING_DOCUMENTS
    )
      ? 'A part drawing is required. Please upload.'
      : null,
  };

  /** Dropzone */
  const isManual = shouldShowManual(autoquoteResponseState);
  const dropzoneText = () => {
    if (errors[FieldNames.SUPPORTING_DOCUMENTS]) {
      return errors[FieldNames.SUPPORTING_DOCUMENTS];
    } else if (isManual) {
      return 'For faster quoting, include a part drawing.';
    } else {
      return 'Drop supporting documents, such as part drawings, here to upload';
    }
  };

  const dropzoneIcon = () => {
    if (supportingDocumentIsRequired(FieldNames.SUPPORTING_DOCUMENTS)) {
      return 'info-circle';
    } else if (isManual) {
      return 'icon-customer-service';
    }
  };

  /** Advanced inspection price */
  const getSelectedAdvancedInspectionPrice = (autoquoteResponseState: AutoquoteTierList) =>
    autoquoteResponseState.find(
      autoquoteResponse => autoquoteResponse.location_id === formState.location_id
    )?.advanced_inspection_price;

  const advancedInspectionPrice =
    isAutoquoteLoading(autoquoteResponseState) || isAutoquoteError(autoquoteResponseState)
      ? null
      : getSelectedAdvancedInspectionPrice(autoquoteResponseState);

  return (
    <div>
      <FormCollapsibleMfgProcess
        activeGlow={true}
        analyticsTag={analyticsTag}
        partConfig={formState}
        manufacturingProcesses={manufacturingProcesses}
        onFieldChanges={changes => {
          const process = manufacturingProcesses?.find(
            p => p.id === changes.manufacturing_process_id
          );
          const autoquotableDefaults = process?.autoquotable_defaults;
          // Default CNC values if we changed from non-CNC to CNC
          if (
            process?.name === MANUFACTURING_PROCESSES.CNC &&
            currentProcess?.id !== changes.manufacturing_process_id
          ) {
            // NOTE: The autoquotable_defaults object for CNC only has the material_id
            let cncDefaultVals = autoquotableDefaults
              ? { ...changes, ...CNC_DEFAULT_VALUES, ...autoquotableDefaults }
              : { ...changes, ...CNC_DEFAULT_VALUES };

            onChanges(cncDefaultVals);
          } else {
            if (autoquotableDefaults) {
              changes.material_id = autoquotableDefaults.material_id;
              if (autoquotableDefaults?.color_id)
                changes.color_id = autoquotableDefaults.color_id;

              if (autoquotableDefaults?.layer_thickness_id)
                changes.layer_thickness_id = autoquotableDefaults.layer_thickness_id;
            }
            onChanges({
              customer_portal_finish: 'Standard',
              ...changes,
            });
          }
        }}
        readonly={readonly}
        showLayerThickness={true}
        showColor={!showMaterialFinish}
      />

      {currentProcess?.name === MANUFACTURING_PROCESSES.CNC && (
        <ItarEarField cliId={formState.id} partId={part?.id} />
      )}

      {showMaterialFinish && (
        <FinishAndColorField
          className="mt-2"
          material={currentMaterial}
          formState={formState}
          trackInput={trackInput}
          onChange={({ finish, color }) => {
            onChanges({ finish_id: finish?.id || null, color_id: color?.id || null });
          }}
          process={currentProcess}
        />
      )}

      {showInfill && (
        <FormFieldBase name="infill" label="Infill" isControl={true}>
          <Select
            name={FieldNames.INFILL}
            className="form-group is-floating"
            selectClassName="form-control"
            sorted={false}
            hasBlankOption={false}
            optionList={[
              { id: 'Standard', name: 'Solid' },
              { id: 'Medium', name: 'Medium' },
              { id: 'Sparse', name: 'Light' },
            ]}
            value={formState?.infill}
            onChange={onChangeByName}
          />
        </FormFieldBase>
      )}

      {showCustomerPortalFinish && (
        <DetailSelect>
          <DetailSelectButton
            className="mb-2"
            labelText="Finish"
            onClick={handlePortalFinishClick}
            readonly={false}
            value={formState[FieldNames.PORTAL_FINISH]}
          />
          <DetailSelectOptions
            className="z-[1001]"
            currentOptionId={formState[FieldNames.PORTAL_FINISH]}
            isOpen={isPortalFinishOpen}
            isRenderedBelow={isPortalFinishRenderedBelow}
            onSelect={(id: DetailOptionId) => {
              onChanges({ [FieldNames.PORTAL_FINISH]: id.toString() });
              trackInput('Finish', id);
              setPortalFinishOpen(false);
            }}
            options={PORTAL_FINISH_OPTIONS}
            renderOption={option => option.data?.rendered || option.name}
          />
        </DetailSelect>
      )}

      {showCNCFields && (
        <CNCFields
          finish={currentFinish}
          formState={formState}
          hasSupportingDocs={formState?.current_documents?.length > 0 || hasSupportingDocs}
          onChanges={onChanges}
        />
      )}

      {!showCNC && (
        <InspectionTypes
          advancedInspectionPrice={advancedInspectionPrice}
          manufacturingProcessId={formState?.manufacturing_process_id}
          inspectionTypeId={formState?.inspection_type_id}
          onChange={inspectionType => {
            onChanges({
              [FieldNames.INSPECTION_TYPE_ID]: inspectionType.id,
              [FieldNames.INSPECTION_TYPE]: inspectionType,
            });
            trackInput('Inspection', inspectionType.name);
          }}
          errorText={errors[FieldNames.INSPECTION_TYPE]}
          readonly={readonly}
        />
      )}

      {showCNCFields && (
        <FormFieldBase
          name="is_prototype"
          label="Is this a prototype?"
          tooltip="We need to know these extra details about your part so we can calculate the correct taxes, duties or tariffs for your quote (if they apply)."
          isControl={true}
        >
          <Select
            name="is_prototype"
            className="form-group is-floating"
            selectClassName="form-control"
            sorted={false}
            hasBlankOption={false}
            value={formState.is_prototype}
            optionList={[
              { id: true, name: 'Yes' },
              { id: false, name: 'No' },
            ]}
            onBlur={(event: SyntheticEvent<HTMLSelectElement>) => {
              trackInput(
                'Duties, tariffs, taxes question',
                booleanInputFilter(event.currentTarget.value)
              );
            }}
            onChange={(event: SyntheticEvent<HTMLSelectElement>) => {
              onChanges({ [event.currentTarget.name]: event.currentTarget.value === 'true' });
            }}
            dataTestId="part-config-is-prototype"
          />
        </FormFieldBase>
      )}

      {showNotesField && (
        <>
          <FormFieldBase name="notes" label="Notes" isControl={true}>
            <Input
              value={formState?.notes}
              name={FieldNames.NOTES}
              type="text-area"
              size="md"
              onBlur={(event: SyntheticEvent<HTMLInputElement>) =>
                trackInput('Notes', event.currentTarget.value)
              }
              onChange={(event: SyntheticEvent<HTMLInputElement>) => {
                onChangeByName(event);
              }}
              data-testid="part-config-notes"
            />
          </FormFieldBase>
          <span className="flex flex-row-reverse">
            <span
              className="cursor-default text-white underline font-size-sm"
              data-rh={NOTES_DISCLAIMER}
              data-rh-at="top"
              data-rh-atAlign="far"
            >
              Disclaimer
            </span>
          </span>
        </>
      )}

      {!showBulkFields && (
        <div className="mt-3">
          {part && (
            <>
              <SupportingDocuments
                isStacked={true}
                isRounded={false}
                readonly={false}
                documents={part.documents}
                baseDocumentURI={`/customer_portal/parts/${part.id}/documents`}
                onChange={docs => onChanges({ [FieldNames.SUPPORTING_DOCUMENTS]: docs }, false)}
                dropzoneComponent={
                  <div
                    className={classNames([
                      'mt-4',
                      styles.SupportingDocs,
                      supportingDocumentIsRequired(FieldNames.SUPPORTING_DOCUMENTS) &&
                        styles.isInvalid,
                    ])}
                    data-testid="file-dropzone"
                    onClick={handleSelectFile}
                    onKeyDown={evt => keyboardDown(evt, 'Enter', handleSelectFile)}
                    role="button"
                    tabIndex={0}
                  >
                    <div className="mb-3 font-size-md text-center">
                      <IconFont name={`${dropzoneIcon()}`} className="mb-1 d-block" />

                      {dropzoneText()}
                    </div>
                    <Button outline={true} size="sm">
                      Select Files
                    </Button>
                  </div>
                }
              />
              <span className="flex flex-row-reverse">
                <span
                  className="cursor-default text-white underline font-size-sm"
                  data-rh={SUPPORTING_DOC_DISCLAIMER}
                  data-rh-at="top"
                  data-rh-atAlign="far"
                >
                  Disclaimer
                </span>
              </span>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PartFormFields;
