import { SplitFactory } from '@splitsoftware/splitio-react';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter } from 'react-router-dom';
import { ThroughProvider } from 'react-through';
import { v4 as uuidv4 } from 'uuid';

import { GoogleAnalyticsWrapper, Helmet, Tooltip } from 'fr-shared/components';
import { UserConsumer, UserProvider } from 'fr-shared/context';
import FeatureFlagClient from 'fr-shared/feature_flags';

import Router from './router';

const App = () => (
  <UserProvider>
    <UserConsumer>
      {({ user }) => {
        let userId;

        userId = user?.id || localStorage.getItem('splitAnonymousId');

        if (!userId) {
          userId = uuidv4();
          localStorage.setItem('splitAnonymousId', userId);
        }

        const featureFlags = new FeatureFlagClient(userId);

        return (
          <SplitFactory factory={featureFlags.factory} updateOnSdkReady={true}>
            <ThroughProvider>
              <BrowserRouter>
                <Helmet defaultTitle="Fast Radius" titleTemplate="%s - Fast Radius">
                  <link rel="canonical" href={window.location.href} />
                </Helmet>
                <Tooltip autoPosition events />
                <GoogleAnalyticsWrapper>
                  <Router user={user} />
                </GoogleAnalyticsWrapper>
              </BrowserRouter>
            </ThroughProvider>
          </SplitFactory>
        );
      }}
    </UserConsumer>
  </UserProvider>
);

export default hot(App);
