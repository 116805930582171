import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { useUserAnalyticsContext } from 'fr-shared/context';

import { Button, PartPreviewModal } from 'portal/components';

const SupportingDocumentsLink = ({ part }) => {
  const documents = get(part, 'documents', []);
  const userAnalytics = useUserAnalyticsContext();
  const handleLink = () => userAnalytics.track(`Support Docs`, { valid: true });

  if (isEmpty(documents)) return <p>N/A</p>;

  return (
    <PartPreviewModal
      part={part}
      showAddToQuote={false}
      action={<Button color="link">{documents.length} available</Button>}
      onActionHandler={handleLink}
    />
  );
};

SupportingDocumentsLink.propTypes = {
  part: PropTypes.object,
};

export default SupportingDocumentsLink;
