import { Formik } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { classNames } from 'fr-shared/components';
import { AUTOCOSTABLE_MFG_PROCESSES } from 'fr-shared/lib/manufacturing_process';

import { Button } from 'portal/components';
import AutomatedChecks from 'portal/components/AutomatedChecks/AutomatedChecks';
import useRequestAutoquote from 'portal/hooks/useRequestAutoquote';
import useScopedAnalytics from 'portal/hooks/useScopedAnalytics';
import { convertLineItemAndPartToAutoquoteRequest } from 'portal/lib/cart';
import PartFormTotals from 'portal/pages/part_config/components/PartFormTotals';

import FormAutocostableProcess from './FormAutocostableProcess';
import FormLeadTime from './FormLeadTime';

const LINE_ITEM_FIELD_NAME = 'compare_line_item';

const CompareLineItem = ({ disabled, onSelectLineItem, lineItem, part, quantity, index }) => {
  const trackInput = useScopedAnalytics(`Comparision Tool Col [${index}]`);
  const [autoquoteResponse, fetchAutoquote] = useRequestAutoquote(null);
  const [latestLineItem, setLatestLineItem] = useState(lineItem || {});

  /**
   * Re-generate an autoquote when the inputs change
   */
  useEffect(() => {
    if (latestLineItem && part) {
      fetchAutoquote(
        convertLineItemAndPartToAutoquoteRequest({ ...latestLineItem, quantity }, part, true)
      );
    }
  }, [latestLineItem, quantity, fetchAutoquote, part]);

  /**
   * If we get the initial line item, build & update the internal state (latestLineItem)
   */
  useEffect(() => {
    if (Object.keys(lineItem).length > 0) {
      onLineItemChange(lineItem);
    }
  }, [lineItem, onLineItemChange]);

  const getInitialCompareLineItem = lineItemData => {
    const autocostingDefaults = {
      customer_portal_finish: 'Standard',
    };

    const newCompareLineItem = () => ({
      part: lineItemData.part,
      quantity: lineItemData.quantity,
      units: lineItemData.units,
      uuid: uuidv4(),
      ...autocostingDefaults,
    });

    const initialCompareLineItem = AUTOCOSTABLE_MFG_PROCESSES.includes(
      lineItem.manufacturing_process?.name
    )
      ? { ...lineItemData, ...autocostingDefaults, uuid: uuidv4() }
      : newCompareLineItem();

    return initialCompareLineItem;
  };

  const onLineItemChange = useCallback(
    lineItemData => {
      const newLineItem = {
        color_id: get(lineItemData, 'color.id') || get(lineItemData, 'color_id'),
        customer_portal_finish: 'Standard', // default
        part_id: get(lineItemData, 'part.id'),
        manufacturing_process: get(lineItemData, 'manufacturing_process'),
        manufacturing_process_id: get(lineItemData, 'manufacturing_process.id'),
        layer_thickness_id: get(lineItemData, 'layer_thickness.id'),
        material_id: get(lineItemData, 'material.id') || get(lineItemData, 'material_id'),
        lead_time: get(lineItemData, 'lead_time'),
        quantity,
        uuid: lineItemData.uuid,
        total_price: null,
        unit_price: null,
      };

      setLatestLineItem(newLineItem);
    },
    [setLatestLineItem, quantity]
  );

  if (!lineItem) return null;

  return (
    <Formik initialValues={{ [LINE_ITEM_FIELD_NAME]: getInitialCompareLineItem(lineItem) }}>
      <div
        className={classNames([
          disabled && 'pointer-events-none opacity-30 select-none',
          'bg-gray-600',
        ])}
      >
        <div className="p-3">
          <FormAutocostableProcess
            fieldName={LINE_ITEM_FIELD_NAME}
            onChange={onLineItemChange}
            onProcessChange={process => trackInput('Mfg Process', process?.name)}
            onMaterialChange={material => trackInput('Material', material?.name)}
            onColorChange={color => trackInput('Color', color?.name)}
            stacked={true}
          />
          <AutomatedChecks
            headerClassName="border-0"
            manufacturingProcess={latestLineItem?.manufacturing_process}
            onViewDfmDefinitions={() => trackInput('Clicked "View Definitions"')}
            part={part}
          />
        </div>
        <div className="border-top bg-gray-600 sticky p-3" style={{ bottom: 0, zIndex: 110 }}>
          <div className="flex align-items-center justify-content-between">
            <PartFormTotals autoquoteResponse={autoquoteResponse} />
          </div>
          <div className="flex align-items-center">
            <FormLeadTime
              fieldPrefix={LINE_ITEM_FIELD_NAME}
              className="mb-0"
              lineItem={latestLineItem}
              onLineItemChange={onLineItemChange}
              isStacked={true}
            />
            <Button
              disabled={!latestLineItem}
              className="ml-auto"
              onClick={() => {
                trackInput('Clicked "Select"');
                onSelectLineItem(latestLineItem);
              }}
            >
              Select
            </Button>
          </div>
        </div>
      </div>
    </Formik>
  );
};

CompareLineItem.propTypes = {
  disabled: PropTypes.bool,
  lineItem: PropTypes.object,
  onSelectLineItem: PropTypes.func,
  part: PropTypes.object,
  quantity: PropTypes.number,
  index: PropTypes.number,
};

export default CompareLineItem;
