import React from 'react';

import { Modal, classNames } from 'fr-shared/components';

import LinksAndSummary from 'portal/components/ItarEarModal/LinksAndSummary/LinksAndSummary';

import styles from './InfoModal.module.css';

export const InfoModal = () => {
  return (
    <Modal
      action={
        <div className="w-full mt-1">
          <div className="cursor-pointer float-right underline text-xs">
            What are ITAR & EAR regulations?
          </div>
        </div>
      }
      className={classNames([styles.Modal, styles.ModalExtraSpecificity])}
    >
      {({ toggle }: { toggle: any }) => (
        <>
          <Modal.Header
            className="pb-1"
            onClose={toggle}
            textUppercase={false}
            title="ITAR and EAR regulations"
          />

          <div className="modal-body pb-4">
            <div className="mt-1 mb-3" style={{ borderBottom: '1px solid #3c4551' }}></div>

            <LinksAndSummary />
          </div>
        </>
      )}
    </Modal>
  );
};

export default InfoModal;
