import { get } from 'lodash';
import React, { useState } from 'react';

import { useForm } from 'fr-shared/hooks';

interface Document {
  file_name: string;
  s3_path: string;
  url: string;
  deleted_at?: string;
}

interface DocumentsContext {
  acceptedFileTypes?: string[];
  activeDocs: Document[];
  documents: Document[];
  fieldError?: object;
  fieldPath: string;
  isUploadError: boolean;
  isUploading: boolean;
  maxSizeInBytes?: number;
  readonly: boolean;
  rejectedFiles: File[];
  s3Endpoint: string;
  setIsUploadError: (isUploadError: boolean) => void;
  setIsUploading: (isUploading: boolean) => void;
  setRejectedFiles: (rejectedFiles: File[]) => void;
  showDate: boolean;
}

interface Props {
  acceptedFileTypes?: string[];
  children: React.ReactNode;
  fieldName: string;
  fieldPrefix?: string;
  maxSizeInBytes?: number;
  readonly?: boolean;
  s3Endpoint: string;
  showDate?: boolean;
}

const DocumentsContext = React.createContext<DocumentsContext>({} as DocumentsContext);

const DocumentsProvider = ({
  acceptedFileTypes,
  children,
  fieldName,
  fieldPrefix = null,
  maxSizeInBytes,
  readonly = false,
  s3Endpoint,
  showDate = false,
}: Props) => {
  const formik = useForm();
  const [isUploadError, setIsUploadError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [rejectedFiles, setRejectedFiles] = useState([]);

  // Only use fieldName because server doesn't return errors nested in order object
  const fieldError = get(formik.errors, fieldName);
  const fieldPath = [fieldPrefix, fieldName].filter(field => field !== null).join('.');
  const documents = get(formik.values, fieldPath, []) as Document[];
  const activeDocs = documents.filter(doc => !doc.deleted_at);

  return (
    <DocumentsContext.Provider
      value={{
        acceptedFileTypes,
        activeDocs,
        documents,
        fieldError,
        fieldPath,
        isUploadError,
        isUploading,
        maxSizeInBytes,
        readonly,
        rejectedFiles,
        s3Endpoint,
        setIsUploadError,
        setIsUploading,
        setRejectedFiles,
        showDate,
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};

export { DocumentsContext, DocumentsProvider };
