import { useQuery } from 'react-query';

import { api } from 'fr-shared/api';

const fetchBuildCarts = () => api.get('/build_carts').then(res => res.data);

const useBuildCarts = () => {
  return useQuery('build_carts', fetchBuildCarts, {
    refetchOnWindowFocus: false,
    staleTime: 2000,
  });
};

export default useBuildCarts;
