import React, { FunctionComponent } from 'react';

import { Badge, Card, Icon } from 'fr-shared/components';
import { AuditLineItem } from 'fr-shared/lib/audit_log';

import AuditLogLineItem from './AuditLogLineItem';

interface AuditLogBatchProps {
  batch: AuditLineItem[];
  batchId: string;
  descriptionPresenter(): string | FunctionComponent;
  detailEvents: string[];
}

const tooltipText =
  'A Batch ID collects related activities into a group. It shows you updates that all happened at once or within a short space of time.';

export default function AuditLogBatch({
  batch,
  batchId,
  descriptionPresenter,
  detailEvents,
}: AuditLogBatchProps) {
  return (
    <Card border={false} className="mt-3" data-testid={`audit-log-batch-${batchId}`}>
      <Card.Body>
        {batch.map((auditItem, index) => (
          <div className="row" key={`audit-line-item-i-${batchId}-${index}`}>
            <AuditLogLineItem
              audit={auditItem}
              descriptionPresenter={descriptionPresenter}
              hasDetails={detailEvents.includes(auditItem.event_type)}
            />
          </div>
        ))}

        <div className="row">
          <div className="col-md">
            <Badge color="light">Batch ID: {batchId}</Badge>
            <Icon name="info-circle" className="text-primary-600 ml-1" tooltip={tooltipText} />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
