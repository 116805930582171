// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DropIn from 'braintree-web-drop-in-react';
import React from 'react';

import { FormToggleSwitch, classNames } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';

import styles from './Payment.module.css';
import PoCheckout from './PurchaseOrder/PoCheckout';

type PaymentContainerProps = {
  formik: any;
  showPoOrderOption: boolean;
  brainTreeToken: string;
  handleBrainTreeInstance: (instance: any) => void;
};

function PaymentContainer({
  formik,
  brainTreeToken,
  showPoOrderOption,
  handleBrainTreeInstance,
}: PaymentContainerProps) {
  const userAnalytics = useUserAnalyticsContext();

  return (
    <div className="mt-5">
      <h5 className="mb-3">{showPoOrderOption ? 'Payment' : 'Credit card'}</h5>
      {showPoOrderOption && (
        <div className="max-w-[236px] mb-3">
          <FormToggleSwitch
            onToggle={() => {
              userAnalytics.track('Checkout - Toggled "Purchase order"', {
                inputValue: !formik.values.credit_card_checkout
                  ? 'Credit Card'
                  : 'Purchase Order',
              });
            }}
            checked={formik?.values?.credit_card_checkout}
            defaultTrueLabel="Credit card"
            defaultFalseLabel="Purchase order"
            name="credit_card_checkout"
            id="credit_card_checkout"
            activeClassName={styles.active}
            toggleSwitchLabelClassName={classNames([
              'bg-transparent h-[2.5rem] max-w-none',
              styles.ToggleSwitchLabel,
            ])}
            toggleClassName={classNames(['h-[2.5rem]', styles.Toggle])}
            toggleTextClassName={classNames(['font-normal', styles.ToggleTxt])}
            textContainerClassName={classNames(['items-center h-[2.3rem]', styles.TextContainer])}
            dataTestId="payment-container-credit-card-toggle"
          />
        </div>
      )}

      {showPoOrderOption && !formik?.values?.credit_card_checkout ? (
        <PoCheckout formik={formik} />
      ) : (
        brainTreeToken && (
          <DropIn
            options={{
              authorization: brainTreeToken,
              translations: {
                chooseAnotherWayToPay: 'Add another payment method',
                chooseAWayToPay: 'Add a payment method',
                payingWith: '',
              },
              defaultFirst: true,
              card: {
                cardholderName: { required: true },
                overrides: {
                  styles: {
                    input: {
                      color: '#f5f5f5',
                    },
                    ':focus': {
                      color: '#f5f5f5',
                    },
                  },
                },
              },
              options: {
                defaultFirst: true,
                cvv: true,
                overrides: { fields: { cvv: true } },
              },
            }}
            onInstance={handleBrainTreeInstance}
            preselectVaultedPaymentMethod={true}
          />
        )
      )}
    </div>
  );
}

export default PaymentContainer;
