// @ts-ignore
import Cookie from 'js-cookie';
import { last } from 'lodash';

/**
 * Parse the url param from window.location.search
 * Normally you want to do this with react-router, but this is useful
 * when you're outside its context.
 * @param {string} param - which param to parse value from url
 * @return {string} - the value from the url params if it exists
 */
export const getParam = (param: string): string => {
  // don't use URLSearchParams here because of IE support
  const match = RegExp(`[?&]${param}=([^&]*)`).exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

/**
 * Removes the GCL versioning from cooking string since SalesForce considers it invalid
 * @param {string} gclid Google Conversion tracking cookie string
 */
export const sanitizeGCLID = (gclid: string): string => {
  if (gclid?.includes('GCL.')) {
    return last(gclid.split('.'));
  } else {
    return gclid;
  }
};

/**
 * Get a map of all of the cookies that marketo cares about
 * @return {object} - A map of all of the cookies that marketo cares about
 */
export const getMarketoCookies = (): object => {
  const gclid = getParam('gclid') || Cookie.get('_gcl_aw');

  const cookies = {
    GCLID__c: sanitizeGCLID(gclid),
    GAID__c: Cookie.get('_ga') || Cookie.get('gaid'),
    GATRACKID__c: Cookie.get('gatrackid'),
    portalReferrerLT: Cookie.get('portal_referrer'),
    portalOriginURL: Cookie.get('portal_origin'),
    UTM_Campaign_LT__c: getParam('utm_campaign') || Cookie.get('utm_campaign'),
    UTM_Content_LT__c: getParam('utm_content') || Cookie.get('utm_content'),
    UTM_Source_LT__c: getParam('utm_source') || Cookie.get('utm_source'),
    UTM_Medium_LT__c: getParam('utm_medium') || Cookie.get('utm_medium'),
    UTM_Term_LT__c: getParam('utm_term') || Cookie.get('utm_term'),
    MSCLKID__c: getParam('msclkid') || Cookie.get('msclkid'),
  };

  return cookies;
};
