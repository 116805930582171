import React from 'react';

import { IconFont } from 'fr-shared/components';
import { PROCESS_NAMES } from 'fr-shared/lib/manufacturing_process';
import { MATERIAL_MARKETING_URLS } from 'fr-shared/lib/materials';

import { Link, classNames } from 'portal/components';
import { getMaterialImageUrl } from 'portal/lib/material';

import SwatchImage from '../../components/SwatchImage/SwatchImage';
import { MATERIAL_DESCRIPTIONS } from '../../copy';
import { getColorValue } from '../../materials/MaterialInfo';
import styles from './material.module.css';

type Props = {
  material: Material;
  selectedManufacturingProcess: ManufacturingProcess;
};

const MaterialInfo = ({ selectedManufacturingProcess, material }: Props) => {
  const colorValue = material?.colors && getColorValue(material?.colors);
  const materialName = material?.name;
  const marketingtUrl = Object.getOwnPropertyDescriptor(MATERIAL_MARKETING_URLS, materialName);
  const hasMarketingUrl = !!marketingtUrl;
  const hasProperties =
    !!material?.density ||
    !!material?.elongation_at_max_load_xy ||
    !!material?.heat_deflection_at_45_mpa ||
    !!material?.tensile_modulus ||
    !!material?.tensile_strength_max_load ||
    !!colorValue;

  let description = MATERIAL_DESCRIPTIONS[materialName];
  const processName = PROCESS_NAMES[selectedManufacturingProcess?.name];
  if (!selectedManufacturingProcess?.materials?.length) {
    description = `Materials for ${processName} require a manual quote. Select a different process or contact us.`;
  } else if (!description) {
    if (hasProperties) {
      description = 'Material description is coming soon.';
    } else {
      description = 'Material info is coming soon.';
    }
  }

  // This heuristic is based on current font size
  const hasOverflowDescription = description.length >= 118;
  const hasOverflowName = materialName && materialName.length >= 28;

  return (
    <div>
      <div className="compare-component-header material-header print:mb-1">
        <h1 className="hidden print:block print:text-xl print:pb-1 print:leading-5 text-black">
          Materials
        </h1>
      </div>
      <div className="print:flex print:flex-row print:flex-nowrap print:justify-between print:items-stretch print:mr-6">
        <div className="print:w-[55%]">
          <div className="flex align-bottom mb-2 print:mb-[-8px]">
            <SwatchImage
              src={getMaterialImageUrl(selectedManufacturingProcess?.name, materialName)}
              className="mr-2 rounded"
              width={64}
              height={64}
            />
            <div className="flex-1 min-w-0">
              <div
                className={classNames([
                  'text-lg leading-8 text-white mt-1 print:mt-0 print:leading-5 print:text-base',
                  styles['material-name-text'],
                ])}
                data-rh={hasOverflowName ? materialName : null}
                data-rh-at="top"
                data-rh-atAlign="near"
              >
                {materialName || '—'}
              </div>
              <div className="text-xs leading-5 text-white print:hidden">{processName}</div>
            </div>
          </div>
        </div>

        <div
          className={'material-desc text-xs text-coolGray-300 min-h-[36px] print:w-[45%]'}
          data-rh={hasOverflowDescription ? description : null}
          data-rh-atAlign="near"
        >
          {description}
        </div>
      </div>

      <div className="material-details my-2 pt-2 border-bottom border-top flex flex-row flex-wrap print:mb-[-8px]">
        <MaterialInfoFeature label="Density" value={material?.density || '—'} units="g/cm^3" />

        <MaterialInfoFeature
          label="Tensile modulus"
          value={material?.tensile_modulus || '—'}
          units="MPa"
        />

        <MaterialInfoFeature
          label="Tensile strength"
          value={material?.tensile_strength_max_load || '—'}
          units="MPa"
        />
        <MaterialInfoFeature
          label="Elongation"
          value={material?.elongation_at_max_load_xy || '—'}
          units="%"
        />

        <MaterialInfoFeature
          label="Heat deflection"
          value={material?.heat_deflection_at_45_mpa || '—'}
          units="&#8451;"
        />

        <MaterialInfoFeature label="Color" value={colorValue || '—'} />
      </div>
      {hasMarketingUrl ? (
        <Link
          to={{ pathname: marketingtUrl?.value }}
          className="bg-transparent	border-none mb-1 p-0 no-underline text-white"
          openNewWindow
        >
          {fullSpecLink()}
        </Link>
      ) : (
        <span
          className="text-coolGray-500 cursor-default text-white text-sm"
          data-rh={'The full spec for this material is unavailable.'}
          data-rh-at="top"
          data-rh-atAlign="near"
        >
          {fullSpecLink()}
        </span>
      )}
    </div>
  );
};

const fullSpecLink = () => {
  return (
    <>
      <span className="text-sm mr-1">
        <IconFont name="external-link" />
      </span>{' '}
      See full spec
    </>
  );
};

type FeatureProps = {
  label: string;
  description?: string;
  value?: string;
  units?: string;
};

const MaterialInfoFeature = ({ label, value, units }: FeatureProps) => {
  return (
    <div className="w-1/2 mb-2 print:w-1/3">
      <div className="text-xs text-coolGray-300">{label}</div>

      <div className="mt-auto">
        {value ? (
          <>
            <span className="text-sm text-white">{value} </span>
            {units && <span className="text-xs text-coolGray-300">{units}</span>}
          </>
        ) : (
          <span className="text-sm text-coolGray-300">—</span>
        )}
      </div>
    </div>
  );
};

export default MaterialInfo;
