import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';

import { Auth0 } from 'fr-shared/auth';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { MARKETING_URLS } from 'fr-shared/lib/marketing_urls';
import { mergeSchemas } from 'fr-shared/utils';

import { Button, FormCheckbox, FormField, Link } from 'portal/components';

import GoogleIcon from '../../components/GoogleIcon';
import MicrosoftIcon from '../../components/MicrosoftIcon';
import {
  PasswordRequirement,
  Schema as PasswordRequirementsSchema,
  passwordRequirements,
} from './PasswordRequirement';

const auth0 = new Auth0();

const AccountPanel = ({ onNext }) => {
  const formik = useFormikContext();
  const userAnalytics = useUserAnalyticsContext();
  const signupWithGoogle = () => {
    userAnalytics.track('Google SSO', { valid: true });
    auth0.loginWithGoogle();
  };
  const signupWithMicrosoft = () => {
    userAnalytics.track('Microsoft SSO', { valid: true });
    auth0.loginWithMicrosoft();
  };

  return (
    <div data-testid="account_panel">
      <FormField
        dataTestId="email"
        label="Enter your business email"
        name="email"
        floatingLabel={true}
        onlyShowErrorIfTouched={true}
        required={true}
        onBlur={e => {
          userAnalytics.trackFieldEvents({
            e,
            trackName: 'Email Address',
            rawSchema: RawSchema,
          });
        }}
      />

      <FormField
        dataTestId="password"
        type="password"
        label="Create your password"
        name="password"
        floatingLabel={true}
        onlyShowErrorIfTouched={true}
        required={true}
        onBlur={e => {
          userAnalytics.trackFieldEvents({
            e,
            trackName: 'Password',
            rawSchema: RawSchema,
          });
        }}
      />

      {formik.values.password && (
        <div className="font-size-sm mb-3">
          {passwordRequirements.map((req, reqIndex) => (
            <PasswordRequirement
              description={req.description}
              isPassing={req.schema.isValidSync(formik.values.password)}
              key={'password_requirement_' + reqIndex}
            />
          ))}
        </div>
      )}

      <FormCheckbox
        className="mt-5 mb-3"
        dataTestId="terms"
        name="terms"
        label={
          <p className="text-lightgray">
            I accept the{' '}
            <Link to={{ pathname: MARKETING_URLS.TermsOfUse }} openNewWindow>
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link to={{ pathname: MARKETING_URLS.Privacy }} openNewWindow>
              Privacy Policy
            </Link>
            .
          </p>
        }
        onBlur={e => {
          userAnalytics.trackFieldEvents({
            e,
            trackName: 'T&Cs',
            rawSchema: RawSchema,
          });
        }}
      />

      <div className="flex flex-column pt-2 mb-4">
        <Button dataTestId="next" onClick={onNext}>
          Next
        </Button>
      </div>

      <div className="text-lined">Or join us with</div>

      <div className="flex justify-content-center py-4">
        <Button className="btn-icon" onClick={signupWithGoogle} outline>
          <GoogleIcon />
        </Button>
        <Button className="btn-icon" onClick={signupWithMicrosoft} outline>
          <MicrosoftIcon />
        </Button>
      </div>

      <div className="my-1 text-center">
        Already have an account? <Link to="/login">Log in</Link>
      </div>
    </div>
  );
};

export const RawSchema = {
  email: Yup.string().email().label('Email').required(),
  password: mergeSchemas(
    PasswordRequirementsSchema,
    Yup.string().required('Password is required')
  ),
  terms: Yup.boolean().oneOf([true], 'You must agree to the terms before creating an account'),
};

export const Schema = Yup.object().shape(RawSchema);

AccountPanel.propTypes = {
  onNext: PropTypes.func,
};

export default AccountPanel;
