import { useFormikContext } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {
  Alert,
  Button,
  CostingRequestLink,
  FormCheckbox,
  Icon,
  LineItemProcess,
  classNames,
} from 'fr-shared/components';
import { keyboardDown } from 'fr-shared/utils';

const QuoteFormLineItem = ({
  index,
  className,
  children,
  onRemoveLineItem,
  onSelectLineItem,
  readonly,
  showPricing,
  showBulkCheckbox,
  lineItem,
  changePosition,
}) => {
  const formik = useFormikContext();
  const currentRevision = get(lineItem, 'part.current_revision');
  const screenshot = get(currentRevision, 'screenshot');

  const isLineItemOpen = lineItem.open === undefined ? true : lineItem.open;

  const handleLineToggle = () => {
    formik.setFieldValue(`line_items.${index}.open`, !isLineItemOpen);
  };

  const handleLineItemSelect = () => {
    const selected = lineItem.selected;
    formik.setFieldValue(`line_items.${index}.selected`, !selected);
  };

  return (
    <div
      id={`line_item_${index}`}
      className={classNames(['card', 'card-border', 'card-shadow', 'mb-3', className])}
      data-testid="new-quote-form--quote-form-line-item"
    >
      <div className="line-item">
        {showBulkCheckbox && (
          <FormCheckbox
            name={`line_items.${index}.selected`}
            className="mb-0 mr-1 align-text-bottom"
          />
        )}
        <div
          className="flex flex-1 align-items-center"
          onClick={handleLineItemSelect}
          onDoubleClick={handleLineToggle}
          onKeyDown={evt => keyboardDown(evt, 'Enter', handleLineItemSelect)}
          role="button"
          tabIndex={0}
        >
          {screenshot && (
            <div className="mr-2">
              <img
                width={40}
                height={40}
                className="rounded"
                src={screenshot}
                alt="Part screenshot"
              />
            </div>
          )}
          <span className="badge badge-indicator bg-gray-100 mr-3">
            {index + 1} {lineItem.id && `- ID: ${lineItem.id}`}
          </span>
          <div className="flex align-items-center">
            <strong className="text-break mr-3">{lineItem.description}</strong>
            <CostingRequestLink
              lineItemId={lineItem.id}
              costingRequestId={lineItem.costing_request_id}
            />
          </div>
        </div>
        {!readonly && (
          <>
            <Button
              className="border ml-3"
              color="light"
              onClick={() => changePosition('UP', lineItem)}
            >
              <Icon name="arrow-up" />
            </Button>
            <Button
              className="border ml-3"
              color="light"
              onClick={() => changePosition('DOWN', lineItem)}
            >
              <Icon name="arrow-down" />
            </Button>
          </>
        )}
        <div className="ml-auto align-items-center pl-3">
          {onRemoveLineItem && !readonly && (
            <Button
              outline={true}
              color="danger"
              className="ml-3"
              onClick={() => onRemoveLineItem(lineItem)}
            >
              <Icon name="times" />
            </Button>
          )}
          {onSelectLineItem && !readonly && (
            <Button className="ml-3" onClick={() => onSelectLineItem(lineItem)}>
              <Icon name="plus" />
            </Button>
          )}
          <Button
            id="line-item-toggle"
            className="border ml-2"
            color="light"
            data-testid={`expand-${index}`}
            onClick={handleLineToggle}
          >
            <Icon name={`chevron-${lineItem.open ? 'up' : 'down'} `} />
          </Button>
        </div>
      </div>

      <div className="line-item-body">
        <div className="line-item-meta">
          {showPricing && (
            <div>
              <div className="line-item-data">
                <div>Total</div>
                <span>{lineItem.total_price ? `${lineItem.total_price.formatted}` : `--`}</span>
              </div>
              <div className="line-item-data">
                <div>Sales Price</div>
                <span>{lineItem.unit_price ? `${lineItem.unit_price.formatted}` : `--`}</span>
              </div>
            </div>
          )}
          <div>
            <div className="line-item-data">
              <div>Unit Cost</div>
              <span>{get(lineItem, 'unit_cost.formatted', '--')}</span>
            </div>
            <div className="line-item-data">
              <div>
                Margin {lineItem.margin_percentage > 0 && `(${lineItem.margin_percentage}%)`}
              </div>
              <span>{get(lineItem, 'margin_value.formatted', '--')}</span>
            </div>
          </div>
          <div>
            {lineItem.offered_lead_time && (
              <div className="line-item-data">
                <div>Lead Time</div>
                <span>{lineItem.offered_lead_time} days</span>
              </div>
            )}
            {lineItem.supplier_location && (
              <div className="line-item-data">
                <div>Supplier</div>
                <span>{lineItem.supplier_location.name}</span>
              </div>
            )}
          </div>
        </div>

        <div className="flex align-items-center ml-auto">
          <div className="flex align-items-center mr-3 font-size-lg">
            <LineItemProcess data={lineItem} />
          </div>
          {lineItem.quantity && (
            <div className="flex align-items-center font-size-sm">
              <div className="text-muted mr-2">QTY</div>
              <h3 className="mb-0">{lineItem.quantity}</h3>
            </div>
          )}
        </div>
      </div>

      {formik.errors.line_items && formik.errors.line_items[index] && formik.touched.line_items && (
        <Alert color="danger" className="rounded-0 mb-0">
          <Icon name="exclamation-triangle" right />
          Please fix the errors below before saving
        </Alert>
      )}

      {isLineItemOpen && <div className="px-3">{children}</div>}
    </div>
  );
};

QuoteFormLineItem.defaultProps = {
  showPricing: false,
};

QuoteFormLineItem.propTypes = {
  changePosition: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  index: PropTypes.number,
  lineItem: PropTypes.object,
  onRemoveLineItem: PropTypes.func,
  onSelectLineItem: PropTypes.func,
  readonly: PropTypes.bool,
  selectedItem: PropTypes.object,
  showBulkCheckbox: PropTypes.bool,
  showPricing: PropTypes.bool,
};

export default QuoteFormLineItem;
