import * as Sentry from '@sentry/react';
import { uniq } from 'lodash';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { api } from 'fr-shared/api';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  UncontrolledDropdown,
} from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';
import { ORDER_LINE_ITEM_STATES } from 'fr-shared/lib/orders';

interface OrderWorkOrderButtonProps {
  orderId: number;
  orderLineItems: OrderLineItem[];
}

interface ManufacturingProcessItemProps {
  orderId: number;
  processName: string;
}

interface DisabledWorkOrderButtonProps {
  dataTestId: string;
  tooltip: string;
}

const ManufacturingProcessItem = ({ orderId, processName }: ManufacturingProcessItemProps) => {
  const history = useHistory();
  const { setAlert } = useContext(AlertContext);
  if (processName === MANUFACTURING_PROCESSES.Assembly) {
    return null;
  }

  return (
    <DropdownItem
      data-testid={`order-work-order-list-item-${processName}`}
      onClick={() => {
        api
          .post('/work_orders', { work_order: { state: 'Draft' } })
          .then(res => {
            history.push(`/admin/work_orders/${res.data.id}/order/${orderId}`);
          })
          .catch(error => {
            setAlert({
              color: 'danger',
              message: 'Work Order failed to create. Please Refresh and try again.',
            });
            Sentry.setExtra('error', error);
            Sentry.setExtra('order_id', orderId);
            Sentry.captureMessage('Work Order Creation Error');
          });
      }}
    >
      {processName}
    </DropdownItem>
  );
};

const DisabledWorkOrderButton = ({ dataTestId, tooltip }: DisabledWorkOrderButtonProps) => (
  <div className="d-inline-block">
    <Button
      className="ml-2 mb-2"
      color="primary"
      dataTestId={dataTestId}
      disabled
      size="sm"
      tooltip={tooltip}
    >
      New Work Order
    </Button>
  </div>
);

const OrderWorkOrderButton = ({ orderId, orderLineItems }: OrderWorkOrderButtonProps) => {
  const mfgProcesses = uniq(
    orderLineItems
      .filter((oli: OrderLineItem) => oli.state !== ORDER_LINE_ITEM_STATES.Canceled)
      .map((oli: OrderLineItem) => oli.manufacturing_process.name)
  );

  if (mfgProcesses.length === 0) {
    return (
      <DisabledWorkOrderButton
        dataTestId="order-work-order-button-no-actionable"
        tooltip="Unavailable for Canceled line items"
      />
    );
  }

  if (mfgProcesses.length === 1 && mfgProcesses[0] === MANUFACTURING_PROCESSES.Assembly) {
    return (
      <DisabledWorkOrderButton
        dataTestId="order-work-order-button-assembly"
        tooltip="Unavailable for Assembly-only Orders"
      />
    );
  }

  return (
    <UncontrolledDropdown
      data-testid="order-work-order-container"
      defaultOpen={false}
      className="ml-2 mb-2"
    >
      <DropdownToggle tag="div" className="" caret={false} split={false} nav={false}>
        <Button dataTestId="order-work-order-button" color="primary" size="sm">
          New Work Order <Icon name="caret-down" />
        </Button>
      </DropdownToggle>
      <DropdownMenu data-testid="order-work-order-list-item-container" right>
        {mfgProcesses.sort().map((mfgProcess: string) => {
          return (
            <ManufacturingProcessItem
              key={mfgProcess}
              orderId={orderId}
              processName={mfgProcess}
            />
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default OrderWorkOrderButton;
