import { Form, withFormik } from 'formik';
import { flowRight, pick, uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { api } from 'fr-shared/api';
import {
  Button,
  ButtonLink,
  FirstOrderBadge,
  Icon,
  Link,
  OrderStateBadge,
  Prompt,
  ServerError,
  Sidebar,
  SidebarPage,
  SidebarPageContainer,
  classNames,
} from 'fr-shared/components';
import { AlertContext, UserContext, withContext } from 'fr-shared/context';
import { COUNTRIES } from 'fr-shared/lib/countries';
import { NEW_ORDER_LINE_ITEM, ORDER_STATES } from 'fr-shared/lib/orders';
import { SHIPPING_PAYMENT_TYPES } from 'fr-shared/lib/shipping';
import { userCanEditOrder } from 'fr-shared/lib/user_roles';

import OrderActivity from './OrderActivity';
import OrderDetails from './OrderDetails';
import OrderFormHeader from './OrderFormHeader';
import OrderFormLines from './OrderFormLines';
import OrderFormSidebar from './OrderFormSidebar';
import OrderFormSubmitButtons from './OrderFormSubmitButtons';
import ShippingDetails from './ShippingDetails';
import ShippingDetailsSidebar from './ShippingDetailsSidebar';
import TransitionToDropdown from './TransitionToDropdown';

const detailsTab = '#details';
const lineItemsTab = '#line_items';
const activityTab = '#activity';
const shippingTab = '#shipping';
const allowedTabs = [lineItemsTab, activityTab, shippingTab];
const defaultTab = detailsTab;

const dirtyWarning =
  'If you continue without saving, your updates to the order will be lost. Are you sure you want to continue without saving?';

class OrderForm extends Component {
  static propTypes = {
    children: PropTypes.node,
    dirty: PropTypes.bool,
    errors: PropTypes.object,
    history: PropTypes.object,
    initialValues: PropTypes.object,
    isEditing: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    match: PropTypes.object,
    onSubmit: PropTypes.func,
    readonly: PropTypes.bool,
    setErrors: PropTypes.func,
    setFieldValue: PropTypes.func,
    setSubmitting: PropTypes.func,
    setValues: PropTypes.func,
    setAlert: PropTypes.func,
    title: PropTypes.string,
    touched: PropTypes.object,
    treatments: PropTypes.object,
    user: PropTypes.object,
    values: PropTypes.object,
  };

  state = {
    data: {
      locations: null,
      manufacturing_process: null,
      shipping_accounts: [],
    },
    currentLocationHash: '',
    activeTab: defaultTab,
  };

  constructor(props) {
    super(props);

    this.braintreeInstance = React.createRef(null);
  }

  componentDidMount() {
    this.setActiveTab();

    // get the data needed to fill the dropdowns on the form
    Promise.all([
      api.get('/locations'),
      api.get('/manufacturing_process'),
      api.get('/shipping_accounts'),
    ]).then(([locations, manufacturing_process, shipping_accounts]) => {
      const data = {
        locations: locations.data,
        manufacturing_process: manufacturing_process.data,
        shipping_accounts: shipping_accounts.data,
        in_house_locations: locations.data.filter(location => location.in_house),
      };
      this.setState({ data }, () => {
        // This only matters if this is a new order that's being duplicated,
        // not if we're viewing an existing order that was duplicated.
        const duplicateOrderId = this.props.match?.params?.duplicate_order_id ?? null;

        if (duplicateOrderId !== null) {
          this.handleDuplicateOrder(duplicateOrderId);
        }
      });
    });
  }

  componentDidUpdate(_props, state) {
    if (location.hash !== state.currentLocationHash) {
      this.setActiveTab();
    }
  }

  transformDuplicateOrder = order => {
    const lineItems = order.line_items.map(li => {
      return {
        ...li,
        actual_ship_date: null,
        commit_date: null,
        documents: li.documents?.map(doc => ({
          ...doc,
          id: null,
          inserted_at: null,
        })),
        part_id: li.part?.id,
        promised_ship_date: null,
        shipment_line_items: [],
        state: null,
        supplier_promised_ship_date: null,
        supplier_ship_date: null,
        supplier_tracking_number: null,
        work_order_line_items: null,
        planned_shipment_line_items: li.planned_shipment_line_items?.map(
          ({ commit_date, promised_ship_date, quantity, supplier_promised_ship_date }) => ({
            commit_date,
            promised_ship_date,
            quantity,
            supplier_promised_ship_date,
            already_fulfilled: 0,
            fulfillments: [],
            remaining_to_fulfill: quantity,
            valid: true,
          })
        ),
      };
    });

    return {
      ...order,
      billing_address_id: null,
      duplicated_from_id: order.id,
      id: null,
      inserted_at: null,
      line_items: lineItems,
      po_documents: [],
      shipping_address_id: null,
      state: null,
      supplier_po: null,
      updated_at: null,
    };
  };

  handleDuplicateOrder = duplicateOrderId => {
    api
      .get(`/orders/${duplicateOrderId}`)
      .then(res => {
        const duplicateOrder = this.transformDuplicateOrder(res.data);
        this.props.setValues(duplicateOrder);
      })
      .catch(() => {
        this.props.setErrors({
          server: ['Order could not be duplicated. Please contact the dev team.'],
        });
      });
  };

  handleCustomerChange = customer => {
    // clear out braintree payment nonce
    this.props.setFieldValue('payment_method_nonce', null);

    // reset addresses
    this.props.setFieldValue('billing_address', defaultValues.billing_address);
    this.props.setFieldValue('shipping_address', defaultValues.shipping_address);

    // prefill the shipping organization name
    this.props.setFieldValue('shipping_address.name_line_2', customer?.name);
  };

  handleCustomerContactChange = customerContact => {
    // clear out braintree payment nonce
    this.props.setFieldValue('payment_method_nonce', null);

    // prefill the contact name
    this.props.setFieldValue('billing_address.name_line_1', customerContact?.name);
    this.props.setFieldValue('shipping_address.name_line_1', customerContact?.name);
  };

  handleBillingAddress = () => {
    const {
      values: { billing_address, shipping_address },
    } = this.props;

    const billingAddress = pick(billing_address, [
      'name_line_1',
      'country',
      'street_line_1',
      'street_line_2',
      'city',
      'state',
      'postal_code',
    ]);

    this.props.setFieldValue('shipping_address', { ...shipping_address, ...billingAddress });
  };

  handleSubmit = e => {
    const { onSubmit, setSubmitting } = this.props;
    // call the form submit with the braintree instance attached
    // this is hacky but has to happen because the DropIn
    e.preventDefault();
    setSubmitting(true);
    onSubmit(this.props, this.braintreeInstance.current);
  };

  setActiveTab = () => {
    const { hash, search } = location;
    const searchParams = new URLSearchParams(search);
    const openOliIndex = searchParams.get('oli_index');

    if (allowedTabs.includes(hash)) {
      this.setState({ currentLocationHash: hash });
      this.setState({ activeTab: hash });
    } else {
      // Clear out location hash when on the default tab
      history.replaceState(null, '', location.pathname);
      this.setState({ currentLocationHash: '' });
      this.setState({ activeTab: defaultTab });
    }

    if (openOliIndex) {
      this.props.setFieldValue(`line_items.${parseInt(openOliIndex)}.isOpen`, true);
      setTimeout(() => {
        const oli_element = document.getElementById(
          `planned-shipment-lineitems-add-button_${openOliIndex}`
        );
        if (!oli_element) return;
        const positionTop = oli_element.getBoundingClientRect().top - 250 + window.scrollY;
        window.scrollTo(0, positionTop || 0);
      }, 1);
    } else {
      //default scroll to top if the user doesnt come here from clicking edit psli btn
      window.scrollTo(0, 0);
    }
  };

  handleBulkUpdate = (field, value, onlySelected = false) => {
    const newLineItems = this.props.values.line_items.map(li => {
      if (onlySelected) {
        return li.selected ? { ...li, [field]: value } : li;
      } else {
        return { ...li, [field]: value };
      }
    });
    this.props.setFieldValue('line_items', newLineItems);
  };

  onDownloadDocuments = async (orderId, _state) => {
    api
      .get(`/order_line_items_download?id=` + orderId)
      .then(res => {
        location.href = res.data.url;
      })
      .catch(() => {
        this.props.setAlert({
          color: 'danger',
          message: 'There was an error that occurred while downloading.',
        });
      })
      .finally(() => {});
  };

  handleDownloadDocuments = () => {
    const id = this.props.values.id;

    this.onDownloadDocuments(id);
  };

  render() {
    // preloaded data
    const { data, activeTab } = this.state;

    const {
      children,
      dirty,
      errors,
      history,
      isSubmitting,
      match,
      readonly,
      title,
      touched,
      user: currentUser,
      values,
    } = this.props;

    const isCreated = !!values.id;
    const isDraftSavable = !values.id || values.state === 'Draft';
    const isExistingOrder = readonly;
    const duplicateOrderId = match?.params?.duplicate_order_id ?? values.duplicated_from_id;
    const showTransitionToDropdown =
      values.state === ORDER_STATES.Pending && currentUser.canApproveOrRejectOrders;

    const editRouteUrl =
      activeTab === lineItemsTab
        ? `/admin/orders/${values.id}/edit${lineItemsTab}`
        : `/admin/orders/${values.id}/edit`;

    // We don't want to count the order_state_event_comment for purposes of this form
    const touchedCount = Object.entries(touched).filter(([_key, val]) => val).length;
    const hasUnsavedChanges = !isSubmitting && dirty && touchedCount > 0;

    const orderDetailsProps = {
      ...this.props,
      braintreeInstance: this.braintreeInstance,
      handleBillingAddress: this.handleBillingAddress,
      handleCustomerChange: this.handleCustomerChange,
      handleCustomerContactChange: this.handleCustomerContactChange,
    };

    let mainContent = null;
    switch (activeTab) {
      case lineItemsTab:
        mainContent = (
          <OrderFormLines
            data={data}
            lineItemSubtotal={values.sub_total_price?.formatted}
            onBulkUpdate={this.handleBulkUpdate}
            readonly={readonly}
            onDownloadDocuments={this.handleDownloadDocuments}
          />
        );
        break;

      case activityTab:
        mainContent = <OrderActivity orderId={values.id} customerId={values.customer.id} />;
        break;

      case shippingTab:
        mainContent = <ShippingDetails />;
        break;

      default:
        mainContent = <OrderDetails {...orderDetailsProps} />;
    }

    let nextPrevTabButton = '';
    switch (activeTab) {
      case detailsTab:
        nextPrevTabButton = (
          <Button
            to={lineItemsTab}
            replace
            id="next-to-line-items"
            className="ml-2 mb-2"
            color="secondary"
          >
            Next to Line Items
            <Icon name="chevron-right" className="ml-2" left sm />
          </Button>
        );
        break;

      case lineItemsTab:
        nextPrevTabButton = (
          <Button to={detailsTab} replace color="light">
            <Icon name="chevron-left" className="mr-2" right sm />
            Back to Order Details
          </Button>
        );
        break;
    }

    return (
      <SidebarPageContainer localStorageKey={Sidebar.LOCAL_STORAGE_KEYS.orders}>
        <Prompt
          message={({ pathname }) => pathname === location.pathname || dirtyWarning}
          when={hasUnsavedChanges}
        />

        <SidebarPage>
          <Form onSubmit={this.handleSubmit}>
            <div className="page-header pb-0">
              <div className="container flex-column">
                <div className="row">
                  <div className="col-md-6">
                    <div className="flex mb-2">
                      <h2 className="mr-2">{title}</h2>
                      {isCreated && (
                        <>
                          {values.is_first_order && <FirstOrderBadge className="mr-1" />}
                          {values.state && (
                            <OrderStateBadge
                              state={values.state}
                              data-testid="order-state-badge"
                            />
                          )}
                          {showTransitionToDropdown && <TransitionToDropdown />}
                        </>
                      )}
                    </div>

                    {isCreated && <OrderFormHeader order={values} />}

                    {duplicateOrderId && (
                      <h5 className="text-muted">
                        Cloned from{' '}
                        <Link openNewWindow to={`/admin/orders/${duplicateOrderId}`}>
                          Order {duplicateOrderId}
                        </Link>
                      </h5>
                    )}
                  </div>

                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-12 text-right">
                        <div className="mb-2">
                          {isExistingOrder ? (
                            userCanEditOrder(currentUser, values) && (
                              <Button color="primary" to={editRouteUrl} className="ml-2 mb-2">
                                Edit Order
                              </Button>
                            )
                          ) : (
                            <OrderFormSubmitButtons
                              isDraftSavable={isDraftSavable}
                              onFormSubmit={this.handleSubmit}
                            />
                          )}
                          {isExistingOrder && currentUser.canDuplicateOrders && (
                            <Button
                              color="secondary"
                              to={`/admin/orders/new/${this.props.values.id}`}
                              className="ml-2 mb-2"
                            >
                              <Icon name="copy" right />
                              Duplicate Order
                            </Button>
                          )}
                          <Button
                            color="light"
                            onClick={e => history.goBack(e)}
                            className="ml-2 mb-2"
                          >
                            Back
                          </Button>
                        </div>
                      </div>
                    </div>
                    {children}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <ul className="nav nav-tabs mt-4">
                      <li className="nav-item">
                        <ButtonLink
                          to={detailsTab}
                          replace
                          dataTestId="order-details-nav"
                          className={classNames([
                            'nav-link',
                            { active: activeTab === detailsTab },
                          ])}
                        >
                          Order Details
                        </ButtonLink>
                      </li>
                      <li className="nav-item">
                        <ButtonLink
                          to={lineItemsTab}
                          replace
                          dataTestId="line-items-nav"
                          className={classNames([
                            'nav-link',
                            { active: activeTab === lineItemsTab },
                          ])}
                        >
                          Line Items
                          <span className="badge badge-primary badge-indicator ml-2">
                            {values.line_items.length}
                          </span>
                        </ButtonLink>
                      </li>

                      {readonly && (
                        <li className="nav-item">
                          <ButtonLink
                            to={activityTab}
                            replace
                            dataTestId="order-activity-nav"
                            className={classNames([
                              'nav-link',
                              { active: activeTab === activityTab },
                            ])}
                          >
                            Order Activity
                          </ButtonLink>
                        </li>
                      )}

                      {!readonly && (
                        <li className="nav-item">
                          <ButtonLink
                            to={shippingTab}
                            replace
                            dataTestId="shipping-nav"
                            className={classNames([
                              'nav-link',
                              { active: activeTab === shippingTab },
                            ])}
                          >
                            Shipping
                          </ButtonLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <ServerError errors={errors?.server} />

              {mainContent}

              <div className="flex justify-content-end text-right mb-5">
                {!readonly && (
                  <OrderFormSubmitButtons
                    isDraftSavable={isDraftSavable}
                    onFormSubmit={this.handleSubmit}
                  />
                )}

                {nextPrevTabButton}
              </div>
            </div>
          </Form>
        </SidebarPage>

        {activeTab === lineItemsTab && !readonly && values.id && (
          <Sidebar>
            <OrderFormSidebar onBulkUpdate={this.handleBulkUpdate} />
          </Sidebar>
        )}

        {activeTab === shippingTab && (
          <Sidebar>
            <ShippingDetailsSidebar />
          </Sidebar>
        )}
      </SidebarPageContainer>
    );
  }
}

const defaultValues = {
  billable_service_line_items: [],
  billing_address: { country: COUNTRIES.UNITED_STATES },
  blind_ship: false,
  bulk_updates: {},
  certificate_of_conformance: true,
  coc_signature_required: false,
  customer: {},
  customer_contact: {},
  engineering_contact: {},
  finishing_certificaton_required: false,
  first_article_inspection: false,
  incoterm: 'exw',
  line_items: [{ ...NEW_ORDER_LINE_ITEM, _id: uniqueId('order-line-item-') }],
  material_certification_required: false,
  payment_type: 'Customer PO',
  po_documents: [],
  shipping_address: { country: COUNTRIES.UNITED_STATES },
  shipping_method: 'UPS Next Day Air',
  shipping_payment_type: SHIPPING_PAYMENT_TYPES.InvoiceToCustomer,
  type: 'Production',
};

// wrap the form with formik
export default flowRight(
  withFormik({
    enableReinitialize: true,
    // default values of form
    mapPropsToValues: props => ({
      ...defaultValues,
      ...props.initialValues,
    }),
    validate: values => {
      let errors = { line_items: [] };
      values.line_items.forEach((line_item, i) => {
        if (line_item.hex_color && !/^[A-Fa-f0-9]{6}$/.test(line_item.hex_color)) {
          errors.line_items[i] = { hex_color: 'Invalid hex color' };
        }
      });
      return errors;
    },
    validateOnBlur: false,
    validateOnChange: false,
  }),
  withContext(AlertContext),
  withContext(UserContext)
)(OrderForm);
