import PropTypes from 'prop-types';
import React, { memo, useCallback, useState } from 'react';

import { Input } from 'portal/components';

const PageSearch = ({ defaultValue = '', onSearch, placeholder, ...props }) => {
  const [searchTimeout, setSearchTimeout] = useState(null);

  // The purpose of the search timeout here is so that we do not constantly
  // call the `onSearch` function. This is very likely to be an API call
  // and we don't need this to fire after EVERY change. So what we are doing
  // is actually only firing `onSearch` after 600ms have passed UNLESS the
  // user changes the search value. If that happens, then the timeout clears
  // and the `onSearch` with the previous searchQuery doesn't fire and a new
  // `onSearch` with the new searchQuery is prepped to fire after 600ms.
  //
  // 300ms gives enough time for the search to finish being written
  // completely without adding too much lag time for the user.
  const runSearchTimeout = useCallback(
    searchQuery => {
      setSearchTimeout(setTimeout(() => onSearch(searchQuery), 300));
    },
    [onSearch, setSearchTimeout]
  );

  const handleSearchChange = useCallback(
    e => {
      e.preventDefault();
      clearTimeout(searchTimeout);

      return runSearchTimeout(e.target.value);
    },
    [runSearchTimeout, searchTimeout]
  );

  return (
    <Input
      placeholder={placeholder}
      icon="search"
      defaultValue={defaultValue}
      name="search"
      size="lg"
      {...props}
      onChange={handleSearchChange}
    />
  );
};

PageSearch.propTypes = {
  defaultValue: PropTypes.string,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
};

export default memo(PageSearch);
