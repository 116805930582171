import {
  CNCFieldsState,
  CNC_FIELD_NAMES,
  DEFAULT_SURFACE_ROUGHNESS_VALUE,
} from './components/CNCFields';
import { DEFAULT_TOLERANCE_VALUE } from './components/ToleranceFields';

export const CNC_DEFAULT_VALUES: CNCFieldsState = {
  cnc_surface_roughness: { value: DEFAULT_SURFACE_ROUGHNESS_VALUE },
  cnc_gdt: false,
  cnc_masked_surfaces: null,
  cnc_thread_count: 0,
  cnc_thread_variety: null,
  cnc_advanced_features: false,
  cnc_certifications: false,
  cnc_tolerance_count_0005: null,
  cnc_tolerance_count_001: null,
  cnc_tolerance_count_002: null,
  cnc_tolerance_count_003: null,
  is_prototype: false,
  part_usage: null,
  part_designation: null,
  tolerance: { value: DEFAULT_TOLERANCE_VALUE },
};

export const CNC_EMPTY_VALUES: CNCFieldsState = {
  cnc_surface_roughness: null,
  cnc_gdt: null,
  cnc_masked_surfaces: null,
  cnc_thread_count: null,
  cnc_thread_variety: null,
  cnc_advanced_features: null,
  cnc_certifications: null,
  cnc_tolerance_count_0005: null,
  cnc_tolerance_count_001: null,
  cnc_tolerance_count_002: null,
  cnc_tolerance_count_003: null,
  is_prototype: null,
  part_usage: null,
  part_designation: null,
  tolerance: null,
};

/**
 * Whether or not to show masked surfaces input based on the selected finish.
 * If the finish exactly matches any of the disallowed phrases, then do not show masked surfaces.
 *
 * Compares the characters only, ignoring casing.
 */
export const showMaskedSurfaces = (
  finish: Finish,
  disallowPhrases: string[] = ['As Machined', 'As-Machined (Broken Edges)']
) => {
  const nameCharacterOnly = lowerCharactersOnly(finish?.name);
  return disallowPhrases.every(
    disallowed => nameCharacterOnly !== lowerCharactersOnly(disallowed)
  );
};

const SURFACE_ROUGHNESS_SUPPORTING_DOC_THRESHOLD = 3.2;

/**
 * Determines whether or not a supporting document is required for CNC form state.
 * @param formState the cnc form state to validate
 * @param fieldName The specific field on the {@link formState} to validate.
 * If not provided, returns whether any portion of the {@link formState} requires a supporting doc.
 */
export const cncSupportingDocumentsRequired = (
  formState: CNCFieldsState,
  fieldName: string = null
) => {
  const advancedFeaturesDocumentsRequired = formState.cnc_advanced_features;
  const threadCountDocumentsRequired = formState.cnc_thread_count > 0;
  const threadVarietyDocumentsRequired = formState.cnc_thread_variety > 0;
  const surfaceRoughnessDocumentsRequired =
    !!formState.cnc_surface_roughness &&
    formState.cnc_surface_roughness.value < SURFACE_ROUGHNESS_SUPPORTING_DOC_THRESHOLD;
  const maskedSurfacesDocumentsRequired = formState.cnc_masked_surfaces;

  switch (fieldName) {
    case CNC_FIELD_NAMES.CNC_ADVANCED_FEATURES:
      return advancedFeaturesDocumentsRequired;
    case CNC_FIELD_NAMES.CNC_THREAD_COUNT:
      return threadCountDocumentsRequired;
    case CNC_FIELD_NAMES.CNC_THREAD_VARIETY:
      return threadVarietyDocumentsRequired;
    case CNC_FIELD_NAMES.CNC_SURFACE_ROUGHNESS:
      return surfaceRoughnessDocumentsRequired;
    case CNC_FIELD_NAMES.CNC_MASKED_SURFACES:
      return maskedSurfacesDocumentsRequired;
    case null:
      return (
        advancedFeaturesDocumentsRequired ||
        maskedSurfacesDocumentsRequired ||
        threadCountDocumentsRequired ||
        threadVarietyDocumentsRequired ||
        surfaceRoughnessDocumentsRequired
      );
    default:
      return false;
  }
};

const lowerCharactersOnly = (str: string) => (str || '').toLowerCase().trim();
