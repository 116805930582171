import PropTypes from 'prop-types';
import React from 'react';

import { classNames } from 'fr-shared/components';

const Card = ({ children, border, className = '', ...props }) => {
  return (
    <div className={classNames(['card', { 'card-border': border }, className])} {...props}>
      {children}
    </div>
  );
};

const Header = ({ children, className = '', ...props }) => (
  <div className={classNames(['card-header flex align-items-center', className])} {...props}>
    {children}
  </div>
);

const Actions = ({ children, className = '', ...props }) => (
  <div className={classNames(['flex ml-auto', className])} {...props}>
    {children}
  </div>
);

const Body = ({ children, className = '', ...props }) => (
  <div className={classNames(['card-body', className])} {...props}>
    {children}
  </div>
);

const Footer = ({ children, className = '', ...props }) => (
  <div className={classNames(['card-footer', className])} {...props}>
    {children}
  </div>
);

Card.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Actions.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Body.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Footer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Card.Header = Header;
Card.Body = Body;
Card.Footer = Footer;
Card.Actions = Actions;

export default Card;
