import { useTreatments } from '@splitsoftware/splitio-react';
import React from 'react';

import { Button, Icon, Modal } from 'fr-shared/components';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { toCommaSeparatedString } from 'fr-shared/utils/stringUtils';

import {
  AllLineItemsExcludedError,
  AssemblyLineItemError,
  CustomMaterialsError,
  HeterogeneousShippingError,
  MaterialsDensityMissingError,
  NilVolumeError,
  OverweightShippingError,
  isAllLineItemsExcludedError,
  isAssemblyLineItemError,
  isCustomMaterialsError,
  isHeterogeneousShippingError,
  isMaterialsDensityMissingError,
  isNilVolumeError,
  isOverweightShippingError,
  isPostalCodeValidationError,
} from '../../utils/shipping';

interface Props {
  error: ShippingError;
}

const UnsupportedCasesModal = ({ error }: Props) => {
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const capLineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'Build Pack' : 'Line Item';

  return (
    <Modal
      action={
        <Button color="link" className="p-0" style={{ color: 'inherit' }}>
          <strong>Unsupported Cases</strong>
          <Icon name="question-circle" left />
        </Button>
      }
    >
      {({ toggle }: { toggle: (...args: any[]) => any }) => (
        <>
          <Modal.Header
            title="Cases Unsupported By The Shipping Calculator"
            textUppercase={false}
            onClose={toggle}
          />
          <Modal.Body className="font-size-base">
            <p>
              This quote includes cases unsupported by the shipping calculator. The calculator
              handles standard shipping cases. Some cases are not yet supported.
            </p>
            <p>
              <strong>
                Regardless of any shipping calculation issues, quotes can continue through the
                system without issue. Shipping will be omitted.
              </strong>
            </p>
            {"Reason this quote can't be estimated:"}
            {errorMessage(error, capLineItemName)}
          </Modal.Body>
          <Modal.Footer>
            <Button color="primary" onClick={toggle}>
              Done
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

const errorMessage = (error: any, capLineItemName: string) => {
  if (isHeterogeneousShippingError(error)) {
    return heterogeneousShippingErrorMessage(error);
  }
  if (isCustomMaterialsError(error)) {
    return customMaterialsErrorMessage(error);
  }
  if (isMaterialsDensityMissingError(error)) {
    return materialsDensityMissingErrorMessage(error);
  }
  if (isAllLineItemsExcludedError(error)) {
    return allLineItemsExcludedErrorMessage(error, capLineItemName);
  }
  if (isOverweightShippingError(error)) {
    return overweightShippingErrorMessage(error);
  }
  if (isOverweightShippingError(error)) {
    return overweightShippingErrorMessage(error);
  }
  if (isAssemblyLineItemError(error)) {
    return assemblyLineItemErrorMessage(error, capLineItemName);
  }
  if (isNilVolumeError(error)) {
    return nilVolumeErrorMessage(error, capLineItemName);
  }
  if (isPostalCodeValidationError(error)) {
    return postalCodeErrorMessage();
  }
  return unexpectedErrorMessage(error);
};

const customMaterialsErrorMessage = ({ material_names }: CustomMaterialsError) => (
  <ul>
    <li>
      <strong>Custom Materials: </strong>
      One or more parts has a custom material associated with it.
      {"Custom materials deviate from Fast Radius's standard, documented list."}
      {`Custom materials on this quote: ${toCommaSeparatedString(material_names)}.`}
    </li>
  </ul>
);

const materialsDensityMissingErrorMessage = ({
  material_names,
}: MaterialsDensityMissingError) => (
  <ul>
    <li>
      <strong>Missing Density: </strong>
      One or more parts has a material whose density has not been logged in the system. Without a
      density, part weight can not be calculated.
      {`Materials with missing densities on this quote: ${toCommaSeparatedString(
        material_names
      )}.`}
    </li>
  </ul>
);

const allLineItemsExcludedErrorMessage = (
  { part_names }: AllLineItemsExcludedError,
  capLineItemName: string
) => (
  <ul>
    <li>
      <strong>{`Excluded ${capLineItemName}s: `}</strong>
      {`All ${capLineItemName.toLowerCase()}s on this quote were excluded from the shipping estimate:`}
      {`${toCommaSeparatedString(part_names)}`}
    </li>
  </ul>
);

const overweightShippingErrorMessage = ({ weight_lbs }: OverweightShippingError) => {
  let weightDiff = (weight_lbs - 150).toFixed(1);

  // If weight_lbs is greater than 0 AND less than 0.05,
  // then `weightDiff` will show '0.0', which will confuse end users.
  // Set `weightDiff` to 0.1 to avoid this.
  if (weightDiff === '0.0') {
    weightDiff = '0.1';
  }

  return (
    <ul>
      <li>
        <strong>Total Weight exceeds 150 lbs: </strong>
        The total weight of parts in this quote exceed the 150 lbs limit by {weightDiff} lbs for
        parcel shipping. Freight shipping should be used instead.
      </li>
    </ul>
  );
};
const heterogeneousShippingErrorMessage = ({
  international_count,
  domestic_count,
}: HeterogeneousShippingError) => (
  <ul>
    <li>
      <strong>Shipping from both domestic and international locations: </strong>
      Mix of shipping locations unsupported. {international_count} are shipping internationally,
      while {domestic_count} are shipping domestically. Cannot calculate both domestic and
      international shipping on the same quote.
    </li>
  </ul>
);

const assemblyLineItemErrorMessage = (
  { line_item_ids }: AssemblyLineItemError,
  capLineItemName: string
) => (
  <ul>
    <li>
      <strong>Assembly: </strong>
      {'This quote includes at least one assembly ' +
        `${capLineItemName.toLowerCase()}` +
        `. Assembly ${capLineItemName.toLowerCase()}s ` +
        'have no material density associated with them. ' +
        `Cannot calculate shipping for assembly ${capLineItemName.toLowerCase()}s.`}
      {`The following ${capLineItemName.toLowerCase()}s are Assemblies: ${toCommaSeparatedString(
        line_item_ids
      )}.`}
    </li>
  </ul>
);

const nilVolumeErrorMessage = ({ line_item_ids }: NilVolumeError, capLineItemName: string) => (
  <ul>
    <li>
      <strong>Missing Volume: </strong>
      One or more part volumes could not be calculated or is otherwise missing. Without a volume,
      part weight can not be calculated.
      {`The following ${capLineItemName.toLowerCase()}s have no volume: ${toCommaSeparatedString(
        line_item_ids
      )}.`}
    </li>
  </ul>
);

const postalCodeErrorMessage = () => (
  <ul>
    <li>
      <strong>Invalid Postal Code: </strong>
      {`Postal code must be a valid US postal code.`}
    </li>
  </ul>
);

const unexpectedErrorMessage = ({ reason }: ShippingError) => (
  <ul>
    <li>
      <strong>Unexpected Error: </strong>
      An unexpected error was encountered while trying to estimate shipping costs.
      {`Reason: ${reason}.`}
    </li>
  </ul>
);

export default UnsupportedCasesModal;
