import React from 'react';

import { classNames } from 'portal/components';

import SwatchImage from '../SwatchImage/SwatchImage';
import styles from './FullScreenOptionCard.module.css';

export interface FullScreenOption {
  id: number;
  name: string;
}

interface FullScreenOptionCardProps {
  description?: string;
  children?: React.ReactNode;
  onSubmit?: (option: FullScreenOption) => void;
  option?: FullScreenOption;
  name?: string;
  selectedOption?: FullScreenOption;
  imageUrl?: string;
}

const FullScreenOptionCard = ({
  description,
  children,
  onSubmit,
  option,
  name,
  selectedOption,
  imageUrl,
}: FullScreenOptionCardProps) => {
  const handleClick = () => onSubmit(option);

  if (!option) return null;

  return (
    <div
      className={classNames([
        styles.OptionCard,
        {
          [styles.Selected]: selectedOption && option.id === selectedOption.id,
        },
      ])}
      data-testid={`full-screen-option-${option.name}`}
      onClick={handleClick}
      onKeyDown={e => {
        if (e.key === 'Enter') onSubmit(option);
      }}
      role="button"
      tabIndex={0}
    >
      <SwatchImage
        className={classNames(['mx-auto mb-3 select-none', styles.OptionImage])}
        src={imageUrl}
      />
      <div className="flex flex-column flex-1">
        <div className={classNames(['mb-2 select-none', styles.OptionName])}>
          {name || option.name}
        </div>
        {description && (
          <div className={classNames(['select-none', styles.OptionDescription])}>
            {description}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default FullScreenOptionCard;
