import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';

import { api } from 'fr-shared/api';
import { AlertContext } from 'fr-shared/context';

import { Button } from 'portal/components';

export const VerifyEmailButton = ({ children, email, className }) => {
  const { setAlert } = useContext(AlertContext);

  const handleClick = useCallback(() => {
    api
      .get(`/customer_portal/account/send_verification_email?email=${encodeURIComponent(email)}`)
      .then(() => {
        setAlert({
          color: 'success',
          message: "We've sent you a new email with a new link.",
        });
      })
      .catch(() => {
        setAlert({
          color: 'danger',
          message: 'An unexpected error occurred. Refresh and try again.',
        });
      });
  }, [setAlert, email]);

  return (
    <Button className={className} color="link" onClick={handleClick}>
      {children}
    </Button>
  );
};

VerifyEmailButton.propTypes = {
  children: PropTypes.node,
  email: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default VerifyEmailButton;
