import React from 'react';

import { IconFont, classNames } from 'fr-shared/components';
import { keyboardDown } from 'fr-shared/utils';

import { FailedManufacturabilityCheckCounts } from 'portal/lib/cart';

interface Props {
  checkCounts: FailedManufacturabilityCheckCounts;
  className?: string;
  onClick: () => void;
}

export const CheckoutNotification = ({ checkCounts, className = '', onClick }: Props) => {
  const errCount = checkCounts?.failed_error_checks_count || 0;

  if (!errCount) {
    return null;
  }

  return (
    <div
      className={classNames([
        'flex bg-transparent rounded-[8px] border-solid border-2 border-coolGray-700 p-1 text-left cursor-pointer',
        className,
      ])}
      role="button"
      onClick={onClick}
      onKeyDown={evt => keyboardDown(evt, 'Enter', onClick)}
      tabIndex={0}
    >
      <IconFont name="error-triangle" className="text-xl mr-1 text-error-300" />
      <div className="flex-1 text-xs">
        <p className="mb-0 text-error-300">This part has {errCount} critical issues</p>
        <p className="text-white">View in detail to see our suggested fixes.</p>
      </div>
    </div>
  );
};

export default CheckoutNotification;
