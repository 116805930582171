import { isEmpty } from 'lodash';
import React, { useState } from 'react';

import { api } from 'fr-shared/api';
import { Pagination } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { useTableFilters } from 'fr-shared/hooks';

import {
  EmptyQuotesCard,
  Loading,
  Page,
  PageHeader,
  PageResults,
  PageSearch,
  PageToolbar,
  PageViewToggle,
  QuoteButton,
} from 'portal/components';

import GridView from './components/GridView';
import ListView from './components/ListView';

const fetchData = filters => {
  return api.get('/customer_portal/quotes', { params: filters });
};

const initialFilters = { search: '', page: 1, page_size: 20 };

const Quotes = () => {
  const userAnalytics = useUserAnalyticsContext();
  const [isGridView, setIsGridView] = useState(true);
  const {
    data: quotes,
    loading,
    handleSearch: handleTableSearch,
    pages,
    totalItems,
    filters,
    setFilters,
  } = useTableFilters(null, initialFilters, fetchData);
  const handleSetPage = page => {
    setFilters({ ...filters, page });
  };
  const handleSearch = value => {
    // TODO: useTableFilters expects a synthetic event, but we're controlling <Input />
    handleTableSearch({ target: { name: 'search', value } });
  };
  const handleGetQuote = () => userAnalytics.track('Quote CTA - Quotes Page', { valid: true });
  const handleGridToggle = () => {
    setIsGridView(!isGridView);
    userAnalytics.track(`${isGridView ? 'List' : 'Card'} View - Quotes`, { valid: true });
  };
  const DisplayComponent = isGridView ? GridView : ListView;

  const renderBody = () => {
    if (loading) return <Loading />;
    else if (isEmpty(quotes)) return <EmptyQuotesCard />;
    else return <DisplayComponent quotes={quotes} />;
  };

  return (
    <Page>
      <PageHeader
        title="Quotes"
        subtitle="View any quote and track its progress."
        actions={<QuoteButton onClick={handleGetQuote} />}
      />

      <PageToolbar>
        <PageSearch
          defaultValue={filters.search}
          onSearch={handleSearch}
          placeholder="Search by quote number, PO, or part name"
          onBlur={e => {
            userAnalytics.trackFieldEvents({
              e,
              logType: 'string',
              trackName: 'Quotes - Search',
              customValidation: eVal => Boolean(eVal.trim()),
            });
          }}
        />
        <PageResults value={totalItems} context="quote" />
        <PageViewToggle toggled={isGridView} onToggle={handleGridToggle} />
      </PageToolbar>

      <div className="mt-5">{renderBody()}</div>

      {pages > 1 && !loading && (
        <div className="flex-1 mt-3 flex align-items-end">
          <Pagination
            page={filters.page}
            setPage={handleSetPage}
            totalPages={pages}
            pageNumberStyle={'numbered'}
          />
        </div>
      )}
    </Page>
  );
};

export default Quotes;
