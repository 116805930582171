import { omit } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Breadcrumb, Button, Helmet } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { useLocations, useManufacturingProcess } from 'fr-shared/hooks';

import PrinterForm from './PrinterForm';

interface PrinterNewRouteParams {}

type PrinterNewProps = RouteComponentProps<PrinterNewRouteParams>;

const PrinterNew: React.FC<PrinterNewProps> = ({ history }: PrinterNewProps) => {
  const { setAlert } = useContext(AlertContext);

  const [types, setTypes] = useState<PrinterType[]>();

  const { data: manufacturingProcesses }: { data: ManufacturingProcess[] } =
    useManufacturingProcess();

  const { data: locations } = useLocations();

  useEffect(() => {
    api.get(`/printer_types`).then(res => {
      setTypes(res.data);
    });
  }, []);

  const handleSubmit = (values: any, actions: any) => {
    api
      .post(`/printers`, { printer: omit(values, 'id') })
      .then(() => {
        setAlert({
          message: 'Successfully created Printer',
          color: 'success',
        });
        history.push('/admin/printers');
      })
      .catch(err => {
        if (err.response?.data) {
          const { errors, messages } = err.response.data;
          actions.setErrors({
            server: messages,
            ...errors,
          });
        }

        setAlert({
          message: 'Sorry, we were unable to create this printer.',
          color: 'danger',
        });
      });
  };

  return (
    <>
      <Helmet title="Printers" />
      <Breadcrumb to="/admin/printers">Printers</Breadcrumb>
      <Breadcrumb to={'/admin/printers/new'}>New Printer</Breadcrumb>
      <div className="container">
        <div className="flex align-items-center py-3">
          <h2 className="mb-0">New Printer</h2>
          <div className="ml-auto">
            <Button color="secondary" onClick={(_event: any) => history.goBack()}>
              Back
            </Button>
          </div>
        </div>
        <PrinterForm
          locations={locations}
          manufacturingProcesses={manufacturingProcesses}
          onSubmit={handleSubmit}
          printerTypes={types}
        />
      </div>
    </>
  );
};

export default PrinterNew;
