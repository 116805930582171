import { useTreatments } from '@splitsoftware/splitio-react';
import { connect } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import {
  Button,
  FormField,
  FormFieldAdvice,
  FormSelect,
  FormSelectCreate,
  Modal,
} from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { useFormManufacturingProcess } from 'fr-shared/hooks';
import { finishNameWithModifier } from 'fr-shared/lib/finishes';
import { INTERNAL_PROCESSES, MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';
import { materialHelpText } from 'fr-shared/lib/materials';
import { isFormikAtPrefixTheSame } from 'fr-shared/utils';

import ManufacturingProcessAdvice from './ManufacturingProcessAdvice';

const PartFileAdvice = ({ capLineItemName }) => {
  return (
    <div className="mt-2 mb-3 px-3">
      <p>
        For all additive processes, a 3D part file is required to receive a cost. Please add a 3D
        file to this {capLineItemName.toLowerCase()}. We don’t support SolidWorks (SLDPRT).
      </p>
    </div>
  );
};

PartFileAdvice.propTypes = {
  capLineItemName: PropTypes.string.isRequired,
};

const PartFileTypeAdvice = () => {
  return (
    <div className="mt-2 mb-3 px-3">
      <p>
        To ensure compatibility across our diverse manufacturing technologies, we support the
        following design file types.
      </p>
      <p>For DLS, FDM, MJF, and SLA: We accept .step, .stl, x_t, 3mf</p>
      <p>
        For Cast Urethane, CNC, Injection Molding, and Sheet Metal: We accept .step, .x_t .iges
      </p>
    </div>
  );
};

const FormManufacturingProcess = ({
  fieldPrefix,
  formik,
  md,
  onChange,
  readonly,
  showBuildTime,
  showInfill,
  showFinish,
  stacked,
  buildPackUIEnabled,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useContext(UserContext);

  const {
    colors,
    finishes,
    formattedMaterials,
    handleCancelSaveMaterial,
    handleColorChange,
    handleFinishChange,
    handleLayerThicknessChange,
    handleMaterialChange,
    handleProcessChange,
    handleSaveMaterial,
    handleSearchMaterials,
    isColorRequired,
    layerThicknesses,
    manufacturingProcesses,
    materials,
    selectedMaterial,
    selectedProcess,
    showHexColorField,
  } = useFormManufacturingProcess(fieldPrefix, onChange, buildPackUIEnabled);
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const capLineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'Build Pack' : 'Line Item';

  const lineItem = get(formik.values, fieldPrefix);
  if (!lineItem) return null;

  const processFieldPrefix = buildPackUIEnabled
    ? `${fieldPrefix}.build_package.processes.0`
    : fieldPrefix;

  const process = buildPackUIEnabled ? get(formik.values, processFieldPrefix) : lineItem;

  const advisedMFGProcesses = [
    MANUFACTURING_PROCESSES.InjectionMolding,
    MANUFACTURING_PROCESSES.CastUrethane,
    MANUFACTURING_PROCESSES.SheetMetal,
  ];

  const showInfillField = showInfill && selectedProcess?.name === MANUFACTURING_PROCESSES.FDM;
  const showBuildTimeField =
    showBuildTime && selectedProcess?.name === MANUFACTURING_PROCESSES.DLS;
  const showHexColor = showHexColorField(selectedMaterial?.name);

  const hasPartFileTypeError = get(formik.errors, `${fieldPrefix}.part_file_type_error`);

  const processCanAcceptNewMaterials =
    selectedProcess &&
    [
      MANUFACTURING_PROCESSES.InjectionMolding,
      MANUFACTURING_PROCESSES.CNC,
      MANUFACTURING_PROCESSES.CastUrethane,
      MANUFACTURING_PROCESSES.SheetMetal,
    ].includes(selectedProcess.name);

  const userCanCreateMaterials = user.canCreateMaterials && processCanAcceptNewMaterials;

  const mfgProcessesAdvice = capLineItemName => {
    const showMFGProcessAdvice =
      selectedProcess && advisedMFGProcesses.includes(selectedProcess.name);

    const showPartFileAdvice =
      selectedProcess &&
      INTERNAL_PROCESSES.includes(selectedProcess.name) &&
      !get(lineItem, 'part.current_revision');

    if (showMFGProcessAdvice) {
      return (
        <FormFieldAdvice
          isVisible={true}
          adviceText={`A supporting ${capLineItemName.toLowerCase()} may be needed.`}
          modalTitle={`Supporting ${capLineItemName}s`}
          modalContent={<ManufacturingProcessAdvice />}
        />
      );
    } else if (showPartFileAdvice) {
      return (
        <FormFieldAdvice
          isVisible={true}
          adviceText={`A 3D part file is required for ${
            selectedProcess?.name || 'this process'
          }.`}
          adviceColor="danger"
          modalTitle={'3D Part File Required'}
          modalContent={<PartFileAdvice capLineItemName={capLineItemName} />}
        />
      );
    }
  };

  return (
    <>
      <FormSelect
        md={md}
        id="mfg-process"
        data-testid="mfg-process"
        label={'MFG Process'}
        name={`${processFieldPrefix}.manufacturing_process_id`}
        onChange={handleProcessChange}
        optionList={manufacturingProcesses}
        readonly={readonly && `${processFieldPrefix}.manufacturing_process.name`}
        required={true}
        stacked={stacked}
        advice={mfgProcessesAdvice(capLineItemName)}
      />
      {hasPartFileTypeError && (
        <FormFieldAdvice
          isVisible={true}
          adviceText="Different file type required. Please upload one of the supported file types for this manufacturing process."
          adviceColor="danger"
          modalTitle={'Different File Type Required'}
          modalContent={<PartFileTypeAdvice />}
        />
      )}
      {userCanCreateMaterials ? (
        <FormSelectCreate
          md={md}
          accessor={`${processFieldPrefix}.material.name`}
          defaultOptions={formattedMaterials}
          formatCreateLabel={value =>
            userCanCreateMaterials && (
              <Button color="primary" onClick={() => setIsModalOpen(true)}>
                {`Create "${value}" material`}
              </Button>
            )
          }
          label="Material"
          helpText={materialHelpText(selectedMaterial)}
          loadOptions={handleSearchMaterials}
          name={`${processFieldPrefix}.material_id`}
          onCreateOption={value =>
            formik.setFieldValue(`${processFieldPrefix}.material.name`, value)
          }
          onChange={e => handleMaterialChange({ target: e })}
          readonly={readonly && `${processFieldPrefix}.material.name`}
          required={true}
          stacked={stacked}
        />
      ) : (
        <FormSelect
          md={md}
          label="Material"
          name={`${processFieldPrefix}.material_id`}
          required={true}
          readonly={readonly && `${processFieldPrefix}.material.name`}
          optionList={materials}
          onChange={handleMaterialChange}
          stacked={stacked}
        />
      )}
      {finishes.length > 0 && showFinish && (
        <FormSelect
          required={true}
          label="Finish"
          name={`${processFieldPrefix}.finish_id`}
          readonly={readonly && `${processFieldPrefix}.finish.name`}
          optionList={finishes}
          nameAccessor={finishNameWithModifier}
          onChange={handleFinishChange}
        />
      )}
      {showHexColor ? (
        <FormField
          md={md}
          label="Hex Color"
          placeholder="FFFFFF"
          name={`${processFieldPrefix}.hex_color`}
          className="hex-color-input"
          readonly={readonly && `${processFieldPrefix}.hex_color`}
          stacked={stacked}
          append={
            <div
              style={{
                width: 40,
                height: 36,
                backgroundColor: `#${lineItem.hex_color}`,
              }}
            />
          }
        />
      ) : (
        colors.length > 0 && (
          <FormSelect
            md={md}
            label="Color"
            name={`${processFieldPrefix}.color_id`}
            readonly={readonly && `${processFieldPrefix}.color.name`}
            optionList={colors}
            stacked={stacked}
            onChange={handleColorChange}
            required={isColorRequired}
          />
        )
      )}
      {layerThicknesses && layerThicknesses.length > 0 && (
        <FormSelect
          md={md}
          label="Layer Thickness"
          name={`${processFieldPrefix}.layer_thickness_id`}
          readonly={readonly && `${processFieldPrefix}.layer_thickness.value`}
          optionList={layerThicknesses}
          nameAccessor={lt => `${lt.value} ${lt.unit}`}
          hasBlankOption={true}
          stacked={stacked}
          onChange={e => {
            handleLayerThicknessChange(e);
          }}
        />
      )}
      {showInfillField && (
        <FormSelect
          hasBlankOption={false}
          md={md}
          label="Infill"
          name={`${processFieldPrefix}.infill`}
          optionList={['Standard', 'Medium', 'Sparse']}
          valueAccessor={option => option}
          nameAccessor={option => option}
          readonly={readonly}
          stacked={stacked}
        />
      )}
      {showBuildTimeField && (
        <div>
          <FormField
            md={md}
            label="Build Time Hours"
            name={`${fieldPrefix}.build_time_hours`}
            type="number"
            readonly={readonly}
            stacked={stacked}
          />
          <FormField
            md={md}
            label="Build Time Minutes"
            name={`${fieldPrefix}.build_time_minutes`}
            type="number"
            readonly={readonly}
            stacked={stacked}
          />
        </div>
      )}
      {isModalOpen && (
        <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
          <Modal.Header title="New Material" onClose={() => setIsModalOpen(false)} />
          <div className="modal-body">
            Add new material <strong>{process.material.name}</strong> to{' '}
            <strong>{process.manufacturing_process.name}</strong>?
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              className="mr-2"
              onClick={() => {
                handleCancelSaveMaterial;
                setIsModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color="success"
              onClick={() =>
                handleSaveMaterial(process.material.name).finally(() => setIsModalOpen(false))
              }
            >
              Save
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

FormManufacturingProcess.propTypes = {
  fieldPrefix: PropTypes.string.isRequired,
  formik: PropTypes.object,
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  readonly: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  showBuildTime: PropTypes.bool,
  showFinish: PropTypes.bool,
  showInfill: PropTypes.bool,
  stacked: PropTypes.bool,
  buildPackUIEnabled: PropTypes.bool,
};

FormManufacturingProcess.defaultProps = {
  showInfill: true,
  showBuildTime: true,
  showFinish: false,
  stacked: false,
  buildPackUIEnabled: false,
};

export default connect(React.memo(FormManufacturingProcess, isFormikAtPrefixTheSame));
