import { api } from 'fr-shared/api';
import { getMarketoCookies } from 'fr-shared/lib/marketo';
import { uploadPartToS3AndFros } from 'fr-shared/lib/s3';
import { isAllowed } from 'fr-shared/utils/files';

/**
 * After a part is created, we want to upload it as a Cart Line Item
 * and refresh the cart. Then, we push the user to editing the line item
 *
 * @param {object} part - The part that was just uploaded
 * @param {function} cartLinePostSuccess - Success callback that component will run
 * @param {function} cartLinePostFail - Fail callback that component will run
 */
export const handleS3PartCreated = async ({
  part,
  cartLineItemPostSuccess,
  cartLineItemPostFail,
}: any) => {
  const lineItemParams = {
    part_id: part.id,
  };

  try {
    const res = await api.post('/customer_portal/cart_line_items', {
      line_item: lineItemParams,
      marketo_attrs: getMarketoCookies(),
    });
    return cartLineItemPostSuccess(res);
  } catch (error) {
    return cartLineItemPostFail();
  }
};

export const handleFileDrop = ({
  acceptedFiles,
  loadHandlers: { initLoadingText, clearLoadingText, loadingError },
  s3LoadingHandlers: { uploadError },
  cartLineItemPostHandler,
  user,
  quote_units,
  source = 'Customer Portal Quote',
  onUploadComplete,
  shouldRedirectToPartViewer = true,
}: any) => {
  initLoadingText();

  const files = acceptedFiles.filter((file: any) => isAllowed(file.name));

  if (!acceptedFiles || acceptedFiles.length === 0) {
    clearLoadingText();
    return loadingError();
  }

  const additionalPartParams = {
    organization_id: user.organization?.id,
    source: source,
    units: quote_units,
  };

  const uploadPartUrl = source === 'Studio' ? '/customer_portal/parts' : '/parts';

  const uploadPartRequests = files.map((file: any, i: any) => {
    return async () => {
      return uploadPartToS3AndFros(file, additionalPartParams, uploadPartUrl)
        .then((partResponse: any) => {
          const { data: part } = partResponse;

          // NOTE: This variable is getting set so we don't end up running
          // our post handler action each time which is usually something like
          // `history.push(`/part-config/${cli.id}`)`. If we do run that
          // on each request, we'll end up sending the user through multiple
          // part-config pages and they won't be able to stop it. So instead,
          // we only call the post action on the first CLI request.
          const isOnFirstCLIRequest = i === 0;

          // NOTE: in the case where we don't want to redirect to the part viewer upon
          // upload, we will need to wait until the last file in the queue has been
          // processed.
          const isOnLastCLIRequest = i === files.length - 1;

          cartLineItemPostHandler(
            { part },
            shouldRedirectToPartViewer ? isOnFirstCLIRequest : isOnLastCLIRequest
          );
          return part;
        })
        .catch(() => {
          return uploadError(file);
        });
    };
  });

  // NOTE: We are uploading parts sequentially because a lot of Salesforce
  // work in the API is dependent upon previous changes to parts, carts,
  // and cart line items. Here is an example of a bug that popped up when
  // these requests used to be run in parallel:
  // https://fast-radius.atlassian.net/browse/CX-473
  const uploadPartsSequentially = async () => {
    let parts = [];
    for (let i = 0; i < uploadPartRequests.length; i += 1) {
      const uploadResponse = await uploadPartRequests[i]();
      parts.push(uploadResponse);
    }
    if (!shouldRedirectToPartViewer) {
      onUploadComplete(parts.map(p => p.id));
    }
  };

  return uploadPartsSequentially();
};
