import PropTypes from 'prop-types';
import React from 'react';

import { IconFont, InfoBox } from 'fr-shared/components';
import { MARKETING_URLS } from 'fr-shared/lib/marketing_urls';

export const CapabilitiesInfoBox = ({ className }) => {
  return (
    <InfoBox
      icon={<IconFont name="check-circle" className="text-primary mr-1" />}
      title="Capabilities"
      className={className}
      link={{
        text: 'View our capabilities',
        onClick: () => {
          window.open(MARKETING_URLS.Production);
        },
      }}
    >
      Learn more about our materials, finishing services, and production options.
    </InfoBox>
  );
};

CapabilitiesInfoBox.propTypes = {
  className: PropTypes.string,
};

export default CapabilitiesInfoBox;
