import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { DocumentItem } from 'fr-shared/components';
import { SHIPPING_PAYMENT_TYPES } from 'fr-shared/lib/shipping';

const OrderDetail = ({ label, value }) => (
  <div className="row mb-2">
    <div className="col-md-4 text-muted">{label}</div>
    <div className="col-md-8 flex flex-column">{value}</div>
  </div>
);

OrderDetail.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
};

const OrderPODocuments = documents => (
  <ul className="list-none p-0 m-0">
    {documents.map(
      (document, index) =>
        !document.deleted_at && (
          <li key={`document-${index}`} className="mb-1">
            <DocumentItem document={document} index={index} readonly={true} showDate={false} />
          </li>
        )
    )}
  </ul>
);

const OrderDetails = ({ order, title = 'Order Details' }) => {
  return (
    <>
      <div className="card-header">{title}</div>
      <div className="card-body row">
        <div className="col-md flex-column">
          <h5>Certifications And Notes</h5>
          <div className="row mb-2">
            <div className="col-md-3 text-muted">Certifications</div>
            <div className="col-md-9 flex flex-column">
              {order.certifications?.map(certName => {
                return <span key={certName}>{certName}</span>;
              })}
            </div>
          </div>
          <OrderDetail label="Quality Comments" value={order.quality_comments} />
          <OrderDetail label="Order Comments" value={order.comments} />
          <h5>Payment Information</h5>
          <OrderDetail label="Customer PO" value={order.customer_po} />
          {!isEmpty(order.po_documents) && (
            <OrderDetail label="PO Documents" value={OrderPODocuments(order.po_documents)} />
          )}
        </div>
        <div className="col-md flex-column">
          <h5>Shipping Information</h5>
          {order.blind_ship && (
            <div className="d-inline-flex alert bg-warning py-1 text-dark">
              <strong>Blind Ship</strong>
            </div>
          )}
          <OrderDetail
            label="Ship to Customer"
            value={get(order, 'shipping_address.name_line_2', get(order, 'customer.name'))}
          />
          <OrderDetail
            label="Ship to Contact"
            value={get(
              order,
              'shipping_address.name_line_1',
              get(order, 'customer_contact.name')
            )}
          />
          <OrderDetail label="Shipping Method" value={order.shipping_method} />
          <OrderDetail label="Shipping Payment Type" value={order.shipping_payment_type} />
          {order.shipping_payment_type !== SHIPPING_PAYMENT_TYPES.FreeShipping && (
            <OrderDetail label="Shipping Account" value={order.shipping_account_number} />
          )}
          <OrderDetail label="Country" value={order.shipping_address?.country} />
          <OrderDetail label="Address Line 1" value={order.shipping_address?.street_line_1} />
          <OrderDetail label="Address Line 2" value={order.shipping_address?.street_line_2} />
          <OrderDetail label="City" value={order.shipping_address?.city} />
          <OrderDetail label="State" value={order.shipping_address?.state} />
          <OrderDetail label="Zip" value={order.shipping_address?.postal_code} />
          <OrderDetail label="Phone" value={order.shipping_address?.phone_number} />
        </div>
      </div>
    </>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.object,
  title: PropTypes.node,
};

export default OrderDetails;
