/* eslint-disable react/display-name,react/prop-types */
import { countBy, pickBy } from 'lodash';
import moment from 'moment';
import React from 'react';

import { api } from 'fr-shared/api';
import { Button, FirstOrderBadge, Link } from 'fr-shared/components';
import { useTableFilters } from 'fr-shared/hooks';

import OrdersTable from '../../orders/components/OrdersTable';

const getOrders = (params, filters) => {
  return api.get('/orders', { params: { ...pickBy(filters), ...params } });
};

export const CustomerOrders = ({ params }) => {
  const {
    data: orders,
    onFetchData,
    pages,
    loading,
  } = useTableFilters(null, { page: 1, sort_key: 'inserted_at', sort_desc: true }, filters =>
    getOrders(params, filters)
  );

  return (
    <div>
      <div className="flex align-items-center pb-3">
        <h3 className="mb-0">Orders</h3>
      </div>
      <OrdersTable
        data={orders || []}
        loading={loading}
        onFetchData={onFetchData}
        pages={pages}
        className="bg-white -left -striped mb-5"
        columns={[
          {
            id: 'expander',
            sortable: false,
            width: 45,
            expander: true,
            Expander: ({ isExpanded, ...rest }) => (
              <div className="flex justify-content-between align-items-center">
                {rest.original.line_items && rest.original.line_items.length > 0 && (
                  <Button size="sm" color="secondary">
                    <i className={`fas fa-chevron-${isExpanded ? 'up' : 'down'}`} />
                  </Button>
                )}
              </div>
            ),
          },
          {
            Header: 'Order #',
            id: 'public_id',
            width: 120,
            Cell: ({ original }) => (
              <Link to={`/admin/orders/${original.id}`}>
                {original.is_first_order && (
                  <FirstOrderBadge className="mr-1" fontSize="xs" showText={false} />
                )}
                {original.public_id}
              </Link>
            ),
          },
          {
            Header: 'Public ID',
            id: 'public_id',
            Cell: ({ original }) => <>{original.public_id}</>,
          },
          { Header: 'State', accessor: 'state' },
          {
            Header: 'Sales Contact',
            id: 'sales_contact_id',
            accessor: d => d.sales_contact && d.sales_contact.name,
          },
          {
            Header: 'Created',
            id: 'inserted_at',
            width: 100,
            accessor: d => moment(d.inserted_at).format('MM/DD/YYYY'),
          },
          {
            Header: 'Produced By',
            id: 'location_id',
            accessor: d => d.line_items?.[0]?.location?.name,
          },
          {
            Header: 'MFG Process',
            id: 'manufacturing_process_id',
            accessor: d => {
              const count = countBy(d.line_items, 'manufacturing_process.name');
              const countString = Object.keys(count).map(k => ` ${k}: ${count[k]}`);
              return <span data-rh={countString}>{Object.keys(count).join(', ')}</span>;
            },
          },
        ]}
      />
    </div>
  );
};
