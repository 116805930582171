import PropTypes from 'prop-types';
import React from 'react';

import { FormField } from 'fr-shared/components';
import { useForm } from 'fr-shared/hooks';
import { ORDER_PAYMENT_TYPES } from 'fr-shared/lib/orders';

const PaymentTypeFormField = ({ fieldPrefix, readonly }) => {
  const { handleChange, values, setFieldValue } = useForm();

  let formValues = values;
  let namePrefix = '';

  if (fieldPrefix) {
    formValues = values[fieldPrefix];
    namePrefix = `${fieldPrefix}.`;
  }

  const handlePaymentType = e => {
    const { value } = e.target;
    if (value === 'Customer Credit Card') {
      setFieldValue(`${namePrefix}payment_terms`, 'Credit Card');
    } else {
      setFieldValue(`${namePrefix}payment_method_nonce`, null);
      setFieldValue(`${namePrefix}payment_terms`, '');
    }
    handleChange(e);
  };

  return (
    <>
      <FormField
        label="Payment Type"
        name={`${namePrefix}payment_type`}
        onChange={handlePaymentType}
        component="select"
        readonly={readonly}
        required={true}
      >
        <option value="" />
        {ORDER_PAYMENT_TYPES.map(paymentType => (
          <option key={paymentType} value={paymentType}>
            {paymentType}
          </option>
        ))}
      </FormField>
      {formValues.payment_type === 'Customer Credit Card' && !formValues.customer_contact && (
        <div className="row form-group">
          <div className="col-md-4" />
          <div className="col-md">
            <small className="text-danger">
              You must select a customer contact for credit cards
            </small>
          </div>
        </div>
      )}
    </>
  );
};

PaymentTypeFormField.defaultProps = {
  fieldPrefix: '',
};

PaymentTypeFormField.propTypes = {
  fieldPrefix: PropTypes.string,
  readonly: PropTypes.bool,
};

export default PaymentTypeFormField;
