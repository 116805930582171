import React, { useCallback, useEffect, useState } from 'react';

import { Icon, Modal } from 'fr-shared/components';

const ConnectivityCheck = () => {
  const [online, setOnline] = useState(window.navigator.onLine);
  const handleOnline = useCallback(() => {
    setOnline(true);
  }, []);
  const handleOffline = useCallback(() => {
    setOnline(false);
  }, []);

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  });

  return (
    <Modal backdrop="static" isOpen={!online}>
      <>
        <div className="modal-header">
          <h4 className="modal-title">There is no Internet connection</h4>
        </div>
        <div className="modal-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-2 flex align-items-center justify-content-center">
                <Icon name="wifi-slash" className="fa-3x" />
              </div>
              <div className="col">
                <p>
                  Your device is offline. FRos requires an active Internet connection to operate.
                  Please reconnect your device to continue.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default ConnectivityCheck;
