import { debounce } from 'lodash';
import React, { Component } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, Helmet } from 'fr-shared/components';

import CustomersTable from './components/CustomersTable';

export default class Customers extends Component {
  state = { name: '', customers: [], pages: 1 };

  UNSAFE_componentWillUnmount() {
    this.handleSearch.cancel();
  }

  handleChange = event => {
    event.persist();
    this.setState({ name: event.target.value });
    this.handleSearch(event.target.value);
  };

  handleSearch = debounce(() => {
    this.handleFetchData();
  }, 500);

  handleFetchData = tableState => {
    const { name } = this.state;
    const page = tableState ? tableState.page + 1 : 1;

    api.get('/organizations', { params: { name: name, page: page } }).then(res => {
      this.setState({
        pages: parseInt(res.headers['total-pages']),
        customers: res.data,
      });
    });
  };

  render() {
    const { customers, name, pages } = this.state;

    return (
      <>
        <Helmet title="Customers" />
        <Breadcrumb to="/admin/customers">Customers</Breadcrumb>

        <div className="page-header">
          <div className="container">
            <h2>Customers</h2>
            <div className="page-actions flex align-items-center">
              <div className="mr-2" style={{ width: 300 }}>
                <input
                  name="name"
                  type="text"
                  className="form-control mr-2"
                  placeholder="Search Customers..."
                  value={name}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <CustomersTable data={customers} pages={pages} onFetchData={this.handleFetchData} />
        </div>
      </>
    );
  }
}
