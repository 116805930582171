import React from 'react';

import { FormField } from 'fr-shared/components';

const PowderConsumptionFormField = props => {
  return (
    <FormField name="powder_consumption" label="Powder" type="number" append="kg" {...props} />
  );
};

export default PowderConsumptionFormField;
