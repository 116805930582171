import { useFormikContext } from 'formik';
import React from 'react';

import { Button } from 'fr-shared/components';
import { LINE_ITEMS_ACCESSOR } from 'fr-shared/lib/costing_requests';

const CollapseOrExpandAll = () => {
  const formik = useFormikContext();

  const toggleLineItems = isOpen => {
    formik.values.line_items.map((li, lineItemIndex) =>
      formik.setFieldValue(`${LINE_ITEMS_ACCESSOR}.${lineItemIndex}.isOpen`, isOpen)
    );
  };

  return (
    <div className="ml-auto">
      <div>
        <Button color="link" onClick={() => toggleLineItems(false)}>
          Collapse All
        </Button>
        <Button color="link" onClick={() => toggleLineItems(true)}>
          Expand All
        </Button>
      </div>
    </div>
  );
};

export default CollapseOrExpandAll;
