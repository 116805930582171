import { IconFont } from '@fast-radius/shared-ui';
import React, { SyntheticEvent } from 'react';
import { CSSTransition } from 'react-transition-group';

import { usePortalSubscription } from 'fr-shared/hooks';
import { keyboardDown } from 'fr-shared/utils';

import { classNames } from 'portal/components';

import styles from './DfmCheck.module.css';

type DfmCheckProps = {
  /** Custom className */
  className?: string;
  /** Disables events, such as clicks events and keyboard presses, on the `DfmCheck` if it is selected. */
  disableEventsWhenSelected?: boolean;
  /** The DFM Check to show */
  dfmCheck: ManufacturabilityCheck;
  /** The state of the DFM check group for animating purposes */
  open?: boolean;
  /** The selected state of the check */
  selected: boolean;
  /** Event handler that is called when clicking the check */
  onSelectDfmCheck: (event: SyntheticEvent<HTMLElement>) => void;
};

export const DfmCheck = ({
  className = '',
  disableEventsWhenSelected = false,
  dfmCheck,
  open,
  selected,
  onSelectDfmCheck,
}: DfmCheckProps) => {
  const handleSelectCheck = (event: SyntheticEvent<HTMLElement>) => {
    // We dont want to do anything if we're selected, or a Passed check
    if ((disableEventsWhenSelected && selected) || dfmCheck.passed) {
      return;
    } else {
      // Set the check to visible in the viewer
      onSelectDfmCheck(event);
    }
  };

  const isPortalSubscribed = usePortalSubscription();
  const isNotEnrolledAndPremiumCheck = !isPortalSubscribed && dfmCheck.premium;

  if (!dfmCheck.label) return null;

  return (
    <CSSTransition
      appear
      in={open}
      key={dfmCheck.id}
      classNames={{
        enter: styles.enterAnimate,
        exit: styles.exitAnimate,
      }}
      timeout={300}
      unmountOnExit
    >
      <div
        className={classNames([
          styles.DfmCheck,
          selected && styles.selected,
          dfmCheck.passed && styles.passed,
          className,
        ])}
        onClick={handleSelectCheck}
        role="button"
        onKeyDown={evt => keyboardDown(evt, 'Enter', handleSelectCheck)}
        tabIndex={0}
        style={{ zIndex: 0 }}
      >
        <div className="flex items-center leading-none">
          <IconFont
            name="circle"
            className={`${'text-' + (dfmCheck.passed ? 'success' : dfmCheck.type)} text-xl mr-1`}
          />
          {dfmCheck.label}
        </div>
        {isNotEnrolledAndPremiumCheck && (
          <div
            className="bg-tertiary-500 color rounded-full w-2.5 h-2.5 flex items-center justify-center print:hidden"
            data-testid="dfm-check-upgrade-icon"
          >
            <IconFont className="text-lg text-dark mb-0.5" name="pro" />
          </div>
        )}
      </div>
    </CSSTransition>
  );
};

export default DfmCheck;
