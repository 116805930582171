import React, { useState } from 'react';

import { Button, Card, Icon, classNames } from 'fr-shared/components';
import { ORDER_LINE_ITEM_STATES } from 'fr-shared/lib/orders';

import OrderLineItem from './OrderLineItem';
import styles from './ShippedOrderLineItems.module.css';

interface ShippedOrderLineItemsProps {
  line_items: any;
}

const ShippedOrderLineItems = (props: ShippedOrderLineItemsProps) => {
  const [showShipped, setShowShipped] = useState(false);
  const shippedLineItems = props.line_items.filter(
    (li: any) => li.state === ORDER_LINE_ITEM_STATES.Shipped
  );
  if (shippedLineItems.length === 0) return null;

  return (
    <Card border={false} className="col p-0">
      <Card.Header className={classNames([styles.CardHeader])}>
        <Button
          className={classNames([styles.Expander, 'mr-1'])}
          color="secondary"
          dataTestId="show-shipped-lines-expander"
          onClick={() => setShowShipped(!showShipped)}
        >
          <Icon className={`fas fa-chevron-${showShipped ? 'up' : 'down'}`} />
        </Button>
        {showShipped ? 'Hide' : 'View'} Shipped Line Items
      </Card.Header>
      {showShipped && (
        <Card.Body>
          {shippedLineItems.map((li: any) => {
            return <OrderLineItem key={li.id} lineItem={li} readonly={true} />;
          })}
        </Card.Body>
      )}
    </Card>
  );
};

export default ShippedOrderLineItems;
