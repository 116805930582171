import React from 'react';

import { Modal } from 'fr-shared/components';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const DfmRunningModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal isOpen={isOpen} onCloseHandler={onClose} className="modal-wide">
      <Modal.Header onClose={onClose} />
      <Modal.Body>
        <h3>DFM checks are in progress</h3>
        <div className="border-coolGray-700 border-b-0 border-t border-solid mt-1"></div>
      </Modal.Body>
      <Modal.Footer>
        <p className="text-coolGray-300 font-size-base">
          We’re still working on your DFM checks. Please refresh the page to see if they’re ready
          yet.
        </p>
      </Modal.Footer>
    </Modal>
  );
};

export default DfmRunningModal;
