import React from 'react';

const Flag = () => {
  return (
    <svg
      className="block"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4450_6133)">
        <rect y="3.33203" width="20" height="1.66667" fill="#F55962" />
        <rect y="4.99902" width="20" height="1.66667" fill="white" />
        <rect y="6.66504" width="20" height="1.66667" fill="#F55962" />
        <rect y="8.33203" width="20" height="1.66667" fill="white" />
        <rect y="9.99902" width="20" height="1.66667" fill="#F55962" />
        <rect y="11.665" width="20" height="1.66667" fill="white" />
        <rect y="13.332" width="20" height="1.66667" fill="#F55962" />
        <rect y="14.999" width="20" height="1.66667" fill="white" />
        <rect y="3.33203" width="10" height="6.66667" fill="#2D59BF" />
      </g>
      <defs>
        <clipPath id="clip0_4450_6133">
          <rect y="3.33203" width="20" height="13.3333" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Flag;
