import { startCase } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { AlertContext, UserContext, useUserAnalyticsContext } from 'fr-shared/context';
import { AddressType, addressPath } from 'fr-shared/lib/address';

import { Address, Button, Modal } from 'portal/components';

import AddressModal from './AddressModal';

interface AccountAddressesProps {
  type: AddressType;
}

interface ModalChildProps {
  toggle: () => void;
}

const AccountAddresses = ({ type }: AccountAddressesProps) => {
  const { user } = useContext(UserContext);
  const { setAlert } = useContext(AlertContext);
  const [addresses, setAddresses] = useState([]);
  const userAnalytics = useUserAnalyticsContext();

  const handleAddAddress = () =>
    userAnalytics.track(`Add ${startCase(type)} Address`, { valid: true });
  const handleEditAddress = () =>
    userAnalytics.track(`Edit ${startCase(type)} Address`, { valid: true });
  const handleSaveAddAddress = () => {
    userAnalytics.track(`AS - Completed New ${startCase(type)} Address`);
    refreshAddresses();
  };
  const handleSaveEditAddress = () => {
    userAnalytics.track(`AS - Completed Edit ${startCase(type)} Address`);
    refreshAddresses();
  };

  const refreshAddresses = useCallback(() => {
    api
      .get(`customer_portal/account/${user.id}/${addressPath(type)}`, {
        params: { sort_key: 'street_line_1', sort_desc: true },
      })
      .then(res => {
        setAddresses(res.data);
      })
      .catch(() => {
        setAlert({
          message: 'We could not load your saved addresses. Please refresh the page to try again',
          color: 'danger',
        });
      });
  }, [type, setAlert, user.id]);

  useEffect(() => {
    refreshAddresses();
  }, [refreshAddresses]);

  return (
    <div>
      <div className="border-bottom py-2 mb-4">
        <h5 className="flex align-items-center">
          {startCase(type)} address
          <Modal
            action={
              <Button outline size="sm" className="ml-auto">
                Add
              </Button>
            }
            onActionHandler={handleAddAddress}
          >
            {({ toggle }: ModalChildProps) => (
              <AddressModal
                type={type}
                toggle={toggle}
                isEdit={false}
                onSave={handleSaveAddAddress}
                title={`Add New ${startCase(type)} Address`}
              />
            )}
          </Modal>
        </h5>
      </div>
      <div>
        {addresses.length > 0 ? (
          addresses.map((address, index) => (
            <div
              key={`${type}-address-${index}`}
              className="mb-4 flex flex-1 align-items-start bg-gray-600 p-2 rounded-md"
            >
              <div>
                <Address address={address} />
                <Modal
                  action={
                    <Button outline className="mt-1" size="xs">
                      Edit address
                    </Button>
                  }
                  onActionHandler={handleEditAddress}
                >
                  {({ toggle }: ModalChildProps) => (
                    <AddressModal
                      type={type}
                      toggle={toggle}
                      isEdit={true}
                      onSave={handleSaveEditAddress}
                      initialValues={address}
                      title="Edit address"
                    />
                  )}
                </Modal>
              </div>
            </div>
          ))
        ) : (
          <div className="font-size-md">{"You don't have any saved addresses"}</div>
        )}
      </div>
    </div>
  );
};

export default AccountAddresses;
