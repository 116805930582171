export const PART_FAILURE_DISPOSITIONS = {
  AwaitingCustomer: 'Awaiting Customer',
  UseAsIs: 'Use as Is',
  Remake: 'Remake',
  Rework: 'Rework',
  Scrap: 'Scrap',
};

export const PART_FAILURE_REWORK = 'Rework';

export const PART_FAILURE_RESOLUTIONS = ['Ship', 'Scrap'];
