import PropTypes from 'prop-types';
import React from 'react';

import { BulletPointAdviceModal, Card, FormTextArea } from 'fr-shared/components';

const QuoteFormAdditionalInfo = ({ readonly }) => (
  <Card>
    <Card.Header>Additional Info</Card.Header>
    <Card.Body>
      <div className="row">
        <div className="col-md-6">
          <FormTextArea
            label="Notes"
            placeholder="Add additional notes about this quote"
            advice={
              !readonly && (
                <div className="align-items-center flex flex-wrap font-size-sm justify-content-between">
                  <em className="text-secondary">Text entered here will appear on the quote</em>
                  <BulletPointAdviceModal />
                </div>
              )
            }
            md="2"
            name="notes"
            readonly={readonly}
          />
        </div>
      </div>
    </Card.Body>
  </Card>
);

QuoteFormAdditionalInfo.propTypes = {
  readonly: PropTypes.bool,
};

export default QuoteFormAdditionalInfo;
