import PropTypes from 'prop-types';
import React from 'react';

import { Button, SidebarContent } from 'fr-shared/components';
import { useManufacturingProcess, useUsersByRole } from 'fr-shared/hooks';
import { QUOTE_STATES } from 'fr-shared/lib/quotes';
import { ROLES } from 'fr-shared/lib/roles';

const QuotesSidebar = ({ filters, resetFilters, setFilters }) => {
  const { data: manufacturingProcesses } = useManufacturingProcess();
  const { data: salesUsers } = useUsersByRole(ROLES.Sales);

  return (
    <SidebarContent name="Filters" icon="filter">
      <div className="form-group">
        <label htmlFor="state">Quote State</label>
        <select
          className="custom-select"
          name="state"
          id="state"
          onChange={e => setFilters({ ...filters, state: e.target.value })}
          value={filters.state || ''}
        >
          <option value="" />
          {Object.keys(QUOTE_STATES).map(state => (
            <option key={state} name={QUOTE_STATES[state]}>
              {QUOTE_STATES[state]}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="sales_contact_id">Sales Contact</label>
        <select
          className="custom-select"
          name="sales_contact_id"
          id="sales_contact_id"
          onChange={e => setFilters({ ...filters, sales_contact_id: e.target.value })}
          value={filters.sales_contact_id || ''}
        >
          <option value="" />
          {salesUsers &&
            salesUsers.map(user => (
              <option key={user.id} value={user.id}>
                {user.name}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="manufacturing_process_id">Manufacturing Process</label>
        <select
          className="custom-select"
          onChange={e => setFilters({ ...filters, manufacturing_process_id: e.target.value })}
          name="manufacturing_process_id"
          id="manufacturing_process_id"
          value={filters.manufacturing_process_id || ''}
        >
          <option value="" />
          {manufacturingProcesses &&
            manufacturingProcesses.map(mp => (
              <option key={mp.id} value={mp.id}>
                {mp.name}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group text-right mt-3">
        <Button size="sm" onClick={resetFilters}>
          Clear Filters
        </Button>
      </div>
    </SidebarContent>
  );
};

QuotesSidebar.propTypes = {
  filters: PropTypes.object,
  resetFilters: PropTypes.func,
  setFilters: PropTypes.func,
};

export default QuotesSidebar;
