import { SALESFORCE_SYBRIDGE_INSTANCE } from 'fr-shared/feature_flags';
import { useFeatureFlags } from 'fr-shared/hooks';

const useSalesforceSybridgeInstanceFeatureFlag = () => {
  const { [SALESFORCE_SYBRIDGE_INSTANCE]: salesforceSybridgeInstance } = useFeatureFlags({
    [SALESFORCE_SYBRIDGE_INSTANCE]: 'on',
  });
  return salesforceSybridgeInstance;
};

export default useSalesforceSybridgeInstanceFeatureFlag;
