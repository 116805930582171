import { IconFont } from '@fast-radius/shared-ui';
import React from 'react';

import { ProgressCircle } from 'fr-shared/components';

import { Button, Money, classNames } from 'portal/components';
import {
  BulkConfigAutoquoteTiersResponseState,
  isAutoquoteError,
  isAutoquoteLoading,
} from 'portal/lib/autoquote';
import { PartFormState } from 'portal/pages/part_config/components/PartFormFields';
import { TierRowInfo } from 'portal/pages/part_config/components/TierPriceContainer';
import TierPriceInfoContainers from 'portal/pages/part_config/components/TierPriceContainers';

type Props = {
  autoquoteResponseState: BulkConfigAutoquoteTiersResponseState;
  bulkPartConfig: PartFormState;
  currentProcess: ManufacturingProcess;
  disabled: boolean;
  handleCancel: () => void;
  handleFieldChanges: (changes: Partial<PartFormState>) => void;
  handleSave: () => void;
  partsThatNeedDocsCount: number;
};

function BottomPanel({
  autoquoteResponseState,
  bulkPartConfig,
  currentProcess,
  disabled,
  handleCancel,
  handleFieldChanges,
  handleSave,
  partsThatNeedDocsCount,
}: Props) {
  return (
    <div className="row mr-0 w-full m-0">
      <div
        className={classNames([
          `bg-coolGray-800 py-3 px-4 gap-4 z-[1001] border-t border-coolGray-700 shadow-[0px_0px_12px_rgba(0,0,0,0.5)] w-full min-w-full`,
          `min-h-[190px]`,
        ])}
      >
        <div className="flex flex-row justify-between h-full">
          <div className="ml-1 flex flex-row items-center">
            {isAutoquoteLoading(autoquoteResponseState) ? (
              <div
                className="min-w-[238px] min-h-[140px] flex justify-center scale-[.4]"
                data-testid="loading"
              >
                <ProgressCircle percentage={75} spinner={true} backgroundColor="#191e25" />
              </div>
            ) : isAutoquoteError(autoquoteResponseState) ? (
              <div className="min-w-[238px] p-2 border-2 border-solid rounded-[8px] min-h-[140px] mr-3 border-coolGray-600">
                <div className="text-left text-coolGray-100 text-base mb-1">Standard</div>
                <div className="mb-[4px]">
                  <TierRowInfo label={'Lead time'} labelData="" />
                </div>
                <div className="mb-[4px]">
                  <TierRowInfo label={'Production region'} labelData="" />
                </div>
                <div className="mb-[4px]">
                  <TierRowInfo
                    label={'Item total'}
                    labelData={
                      <Money amount={null} className="text-sm text-coolGray-100" unstyledSymbol />
                    }
                  />
                </div>
              </div>
            ) : (
              <TierPriceInfoContainers
                currentProcess={currentProcess}
                handleFieldChanges={handleFieldChanges}
                lineItem={bulkPartConfig}
                autoquoteTierList={autoquoteResponseState}
              />
            )}
          </div>
          <div className="flex flex-col justify-end h-full">
            {partsThatNeedDocsCount > 0 && (
              <div className="flex justify-end text-warning-300 mb-4">
                <div>
                  <IconFont name="info-filled" className="mr-1 text-2xl" />
                  {partsThatNeedDocsCount} parts require supporting documents
                </div>
              </div>
            )}

            <div className="flex flex-row gap-3 max-h-[48px] w-[304px]">
              <Button className="w-full" disabled={disabled} onClick={handleCancel} outline>
                Cancel
              </Button>
              <Button
                className="w-full"
                disabled={disabled || isAutoquoteLoading(autoquoteResponseState)}
                onClick={handleSave}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BottomPanel;
