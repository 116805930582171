interface LineItem {
  part: { id?: number | null };
  units: string;
}

interface FormikRef {
  current: {
    values: { line_items: LineItem[] };
    setFieldValue: (key: string, values: any) => void;
  };
}

type ChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => void;

export const unitsChangeHandler = (lineItem: LineItem, formikRef: FormikRef): ChangeHandler => {
  return ({ currentTarget: { value: newValue } }) => {
    const newLineItems = formikRef.current.values.line_items.map((li: LineItem) =>
      li.part.id === lineItem.part.id && li.units !== newValue ? { ...li, units: newValue } : li
    );

    formikRef.current.setFieldValue(`line_items`, newLineItems);
  };
};
