import React from 'react';

import { Link } from 'fr-shared/components';

interface FormLinkProps {
  textValue: string;
  className?: string;
}

const urlRegex = /https?:\/\/\S+/gi;

const makeComponent = (url: string, key: number = 0) => {
  return (
    <Link to={{ pathname: url }} key={key} openNewWindow>
      {url}
    </Link>
  );
};

export const FormLink = ({ textValue, className = '' }: FormLinkProps) => {
  const handleMatches = (linkText: string) => {
    let makeLink = new RegExp(urlRegex);
    return String(linkText).match(makeLink);
  };

  const parseTextBodyForLinks = (textBody: string) => {
    if (textBody === '') {
      return textBody;
    }

    const urlMatches = handleMatches(textBody);
    if (!urlMatches) {
      return textBody;
    }

    const splitText = [];
    let lastIndex = 0;

    urlMatches.forEach((urlMatch: string, i: number = 0) => {
      const urlStartingIndex = textBody.indexOf(urlMatch);

      if (urlStartingIndex > lastIndex) {
        splitText.push(textBody.substring(lastIndex, urlStartingIndex));
      }

      lastIndex = urlStartingIndex + urlMatch.length;

      const createNoteLink = makeComponent(urlMatch, i);

      splitText.push(createNoteLink);
    });
    if (textBody.length > lastIndex) {
      splitText.push(textBody.substring(lastIndex));
    }
    return splitText;
  };
  return (
    <div data-testid="text-line-break" className={`whitespace-preline ${className}`}>
      {parseTextBodyForLinks(textValue)}
    </div>
  );
};

export default FormLink;
