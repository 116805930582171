import { useQuery } from 'react-query';

import { api } from 'fr-shared/api';
import { getUsersFromUserRoles } from 'fr-shared/lib/user_roles';

const fetchUsersByRole = role =>
  api
    .get('/user_roles', { params: { page_size: 1000, role_name: role } })
    .then(res => getUsersFromUserRoles(res.data));

const useUsersByRole = role => {
  return useQuery(`${role}Users`, () => fetchUsersByRole(role), {
    refetchOnWindowFocus: false,
    staleTime: 2000,
  });
};

export default useUsersByRole;
