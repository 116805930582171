import {
  debounce,
  differenceBy,
  every,
  findIndex,
  intersectionBy,
  sortBy,
  sortedIndexBy,
  unionBy,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { api } from 'fr-shared/api';
import {
  Breadcrumb,
  Button,
  Checkbox,
  Helmet,
  Icon,
  Sidebar,
  SidebarContent,
  SidebarPage,
  SidebarPageContainer,
  Tabs,
} from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';

import WorkOrderBulkUpdate from './components/WorkOrderBulkUpdate';
import WorkOrderQRReaderModal from './components/WorkOrderQRReaderModal';
import WorkOrderRow from './components/WorkOrderRow';
import WorkOrdersFilters from './components/WorkOrdersFilters';
import WorkOrdersTable from './components/WorkOrdersTable';

export default class WorkOrders extends React.Component {
  static contextType = UserContext;

  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.shape({
      path: PropTypes.string,
      url: PropTypes.string,
    }),
  };

  state = {
    search: '',
    selectedWorkOrders: [],
    showBulkUpdate: false,
    searchResults: null,
    manufacturingProcess: '',
    creators: [],
    locations: [],
    materials: [],
    printers: [],
    refresh: false,
    filtersAreLoaded: false,
  };

  componentDidMount() {
    let localState = { filtersAreLoaded: true };
    const creators = localStorage.getItem('creators');
    if (creators)
      localState.creators = creators
        .split(',')
        .filter(elem => elem.length > 0)
        .map(id => parseInt(id, 10));
    const locations = localStorage.getItem('locations');
    if (locations)
      localState.locations = locations
        .split(',')
        .filter(elem => elem.length > 0)
        .map(id => parseInt(id, 10));
    const materials = localStorage.getItem('materials');
    if (materials) localState.materials = materials.split(',').filter(elem => elem.length > 0);
    const mfgProcess = localStorage.getItem('manufacturingProcess');
    if (mfgProcess) localState.manufacturingProcess = mfgProcess;
    localStorage.removeItem('printer');
    const printers = localStorage.getItem('printers');
    if (printers) localState.printers = printers.split(',').filter(elem => elem.length > 0);
    this.setState(localState);
  }

  UNSAFE_componentWillUnmount() {
    this.handleSearchDebounced.cancel();
  }

  handleTextSearch = event => {
    const { name, value } = event.target;

    event.persist();

    this.setState(prevState => ({ search: { ...prevState.search, [name]: value } }));

    if (value.trim() === '' || value === null) return;

    this.handleSearchDebounced(name, value);
  };

  handleSearchDebounced = debounce((name, value) => {
    const { manufacturingProcess, materials, printers } = this.state;
    api
      .get(`/work_orders`, {
        params: {
          [`filter_${name}`]: value,
          materials: materials,
          process: manufacturingProcess,
          printers: printers,
          page_size: 1000,
          sort_key: 'earliest_ship_date',
        },
      })
      .then(res => {
        this.setState({ search: '', searchResults: res.data }, () => {
          this.props.history.push('/admin/work_orders/search');
        });
      });
  }, 800);

  handleSelectCreator = creators => {
    if (creators && creators.length > 0) {
      localStorage.setItem('creators', creators);
    } else {
      localStorage.removeItem('creators');
    }
    this.setState({ creators });
  };

  handleSelectLocation = locations => {
    if (locations && locations.length > 0) {
      localStorage.setItem('locations', locations);
    } else {
      localStorage.removeItem('locations');
    }
    this.setState({ locations });
  };

  handleSelectMaterial = e => {
    const materials = e.target.value;
    if (materials && materials.length > 0) {
      localStorage.setItem('materials', materials);
    } else {
      localStorage.removeItem('materials');
    }
    this.setState({ materials });
  };

  handleSelectPrinter = e => {
    const printers = e.target.value;
    if (printers && printers.length > 0) {
      localStorage.setItem('printers', printers);
    } else {
      localStorage.removeItem('printers');
    }
    this.setState({ printers });
  };

  handleSelectProcess = e => {
    const mfgProcess = e.target.value;
    mfgProcess
      ? localStorage.setItem('manufacturingProcess', mfgProcess)
      : localStorage.removeItem('manufacturingProcess');
    this.setState({ manufacturingProcess: mfgProcess });
  };

  handleMassSelectWorkOrder = newWorkOrders => {
    this.setState(prevState => {
      let addedWorkOrders = differenceBy(newWorkOrders, prevState.selectedWorkOrders, 'id');
      let removedWorkOrders = intersectionBy(newWorkOrders, prevState.selectedWorkOrders, 'id');
      let selectedWorkOrders = unionBy(
        differenceBy(prevState.selectedWorkOrders, removedWorkOrders, 'id'),
        addedWorkOrders,
        'id'
      );
      return { selectedWorkOrders: sortBy(selectedWorkOrders, 'id') };
    });
  };

  handleSelectWorkOrder = workOrder => {
    this.setState(({ selectedWorkOrders }) => {
      let index = findIndex(selectedWorkOrders, wo => wo.id === workOrder.id);

      // Element not found, so should be removed
      if (index !== -1) {
        selectedWorkOrders.splice(index, 1);
      } else {
        // New element, add it at the appropriate index to keep the array sorted by ID
        selectedWorkOrders.splice(
          sortedIndexBy(selectedWorkOrders, workOrder, 'id'),
          0,
          workOrder
        );
      }

      return { selectedWorkOrders };
    });
  };

  handleNewWorkOrder = () => {
    api.post('/work_orders', { work_order: { state: 'Draft' } }).then(res => {
      this.props.history.push(`/admin/work_orders/${res.data.id}`);
    });
  };

  handleNextState = workOrder => {
    const { state, id } = workOrder;

    const nextStates = {
      Created: { name: 'Scheduled' },
      Scheduled: { name: 'Released to Floor' },
      'Released to Floor': { name: 'Printing' },
      Printing: { name: 'Thermal Processing', url: '/thermal' },
      'Thermal Processing': { name: 'Post-Processing', url: '/post' },
      'Post-Processing': { name: 'Quality Control', url: '/quality' },
      'Quality Control': { name: 'Complete', url: '/complete' },
    };

    const { name } = nextStates[state];

    api.put(`/work_orders/${id}`, { work_order: { state: name } });
  };

  render() {
    const { user } = this.context;
    const {
      manufacturingProcess,
      creators,
      locations,
      materials,
      printers,
      search,
      showBulkUpdate,
      searchResults,
      selectedWorkOrders,
      filtersAreLoaded,
    } = this.state;
    const { match, history } = this.props;

    // See also: backend limit in FastRadiusWeb.Api.V1.WorkOrderController
    const bulkEditLimit = 100;

    const canBulkUpdate =
      selectedWorkOrders.length > 0 &&
      every(selectedWorkOrders, [
        'manufacturing_process',
        selectedWorkOrders[0].manufacturing_process,
      ]) &&
      selectedWorkOrders.length <= bulkEditLimit;

    const workOrderTableParams = {
      creators,
      locations,
      manufacturingProcess,
      materials,
      onNextState: this.handleNextState,
      printers,
      selectedWorkOrders,
      onMassSelectWorkOrder: this.handleMassSelectWorkOrder.bind(this),
      onSelectWorkOrder: this.handleSelectWorkOrder,
    };

    return (
      <>
        <Helmet title="Work Orders" />
        <Breadcrumb to="/admin/work_orders">Work Orders</Breadcrumb>
        <WorkOrderQRReaderModal />
        <SidebarPageContainer localStorageKey={Sidebar.LOCAL_STORAGE_KEYS.workOrders}>
          <SidebarPage>
            {filtersAreLoaded && (
              <>
                <div className="page-header page-mobile pb-0">
                  <div className="container">
                    <h2>Work Orders</h2>

                    <div className="page-actions">
                      <div className="search relative mr-2" style={{ flex: '0 0 200px' }}>
                        <input
                          type="text"
                          name="public_id"
                          className="form-control"
                          placeholder="Search by Order #"
                          value={search.public_id}
                          onChange={this.handleTextSearch}
                        />
                      </div>
                      <div className="search relative mr-2" style={{ flex: '0 0 200px' }}>
                        <MaskedInput
                          type="text"
                          name="work_order_id"
                          className="form-control"
                          placeholder="Search by Work Order #"
                          value={search.work_order_id}
                          onChange={this.handleTextSearch}
                          mask={createNumberMask({
                            allowDecimal: false,
                            prefix: '',
                            includeThousandsSeparator: false,
                          })}
                        />
                      </div>
                      <Button color="primary" onClick={this.handleNewWorkOrder} className="mr-2">
                        New Work Order
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => history.push('/admin/work_orders/qr_reader')}
                      >
                        QR Reader
                      </Button>
                    </div>
                  </div>

                  <div className="container">
                    <Tabs
                      className="mt-4"
                      data-testid="work-orders-states-tab-bar"
                      tabs={[
                        {
                          to: `${match.url}/created`,
                          name: 'Created',
                          hide: !user.canViewCreatedWorkOrders,
                        },
                        {
                          to: `${match.url}/scheduled`,
                          name: 'Scheduled',
                          hide: !user.canViewScheduledWorkOrders,
                        },
                        { to: `${match.url}`, name: 'Current', exact: true },
                        { to: `${match.url}/thermal`, name: 'Thermal Processing' },
                        { to: `${match.url}/post`, name: 'Post-Processing' },
                        { to: `${match.url}/quality`, name: 'Quality Control' },
                        { to: `${match.url}/complete`, name: 'Complete' },
                        { to: `${match.url}/failed`, name: 'Failed' },
                        {
                          to: `${match.url}/search`,
                          name: 'Search Results',
                          className: 'ml-auto',
                          hide: !searchResults || searchResults.length <= 0,
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="container-fluid">
                  <div className="row mb-5">
                    {user.canViewCreatedWorkOrders && (
                      <Route
                        exact
                        path={`${match.path}/created`}
                        render={() => (
                          <div className="container">
                            <WorkOrdersTable
                              filterState="Created"
                              pageSize={100}
                              sortDesc="true"
                              {...workOrderTableParams}
                            />
                          </div>
                        )}
                      />
                    )}
                    {user.canViewScheduledWorkOrders && (
                      <Route
                        exact
                        path={`${match.path}/scheduled`}
                        render={() => (
                          <div className="container">
                            <WorkOrdersTable
                              filterState="Scheduled"
                              pageSize={100}
                              sortDesc="true"
                              {...workOrderTableParams}
                            />
                          </div>
                        )}
                      />
                    )}
                    <Route
                      exact
                      path={`${match.path}/`}
                      render={() => (
                        <>
                          <div className="col-lg mb-3">
                            <WorkOrdersTable
                              filterState="Released to Floor"
                              pageSize={100}
                              {...workOrderTableParams}
                            />
                          </div>
                          <div className="col-lg">
                            <WorkOrdersTable
                              filterState="Printing"
                              pageSize={100}
                              {...workOrderTableParams}
                            />
                          </div>
                        </>
                      )}
                    />
                    <Route
                      exact
                      path={`${match.path}/thermal`}
                      render={() => (
                        <div className="container">
                          <WorkOrdersTable
                            filterState="Thermal Processing"
                            pageSize={20}
                            {...workOrderTableParams}
                          />
                        </div>
                      )}
                    />
                    <Route
                      exact
                      path={`${match.path}/post`}
                      render={() => (
                        <div className="container">
                          <WorkOrdersTable
                            filterState="Post-Processing"
                            pageSize={20}
                            {...workOrderTableParams}
                          />
                        </div>
                      )}
                    />
                    <Route
                      exact
                      path={`${match.path}/quality`}
                      render={() => (
                        <div className="container">
                          <WorkOrdersTable
                            filterState="Quality Control"
                            pageSize={20}
                            {...workOrderTableParams}
                          />
                        </div>
                      )}
                    />
                    <Route
                      exact
                      path={`${match.path}/complete`}
                      render={() => (
                        <div className="container">
                          <WorkOrdersTable
                            filterState="Complete"
                            pageSize={20}
                            sortDesc="true"
                            {...workOrderTableParams}
                          />
                        </div>
                      )}
                    />
                    <Route
                      exact
                      path={`${match.path}/failed`}
                      render={() => (
                        <div className="container">
                          <WorkOrdersTable
                            filterState="Failed"
                            pageSize={20}
                            {...workOrderTableParams}
                          />
                        </div>
                      )}
                    />
                    <Route
                      exact
                      path={`${match.path}/search`}
                      render={() => (
                        <div className="container">
                          <div className="flex align-items-center mb-3">
                            <h4 className="mb-0">
                              Search Results
                              {searchResults && (
                                <span className="badge badge-primary badge-indicator ml-2">
                                  {searchResults.length}
                                </span>
                              )}
                            </h4>
                          </div>
                          {searchResults && searchResults.length > 0 ? (
                            searchResults.map(workOrder => (
                              <div key={workOrder.id} className="flex align-items-center mb-2">
                                <Checkbox
                                  name={`work_order.${workOrder.id}`}
                                  className="d-inline-block mb-0"
                                  onChange={() => this.handleSelectWorkOrder(workOrder)}
                                  value={
                                    selectedWorkOrders &&
                                    selectedWorkOrders.length > 0 &&
                                    !!selectedWorkOrders.find(wo => wo.id === workOrder.id)
                                  }
                                />
                                <WorkOrderRow
                                  key={workOrder.id}
                                  className="mb-2"
                                  workOrder={workOrder}
                                  onClick={() => this.handleSelectWorkOrder(workOrder)}
                                  showState={true}
                                />
                              </div>
                            ))
                          ) : (
                            <div>No results found!</div>
                          )}
                        </div>
                      )}
                    />
                    {showBulkUpdate && (
                      <WorkOrderBulkUpdate
                        selectedWorkOrders={selectedWorkOrders}
                        isOpen={showBulkUpdate}
                        toggle={() =>
                          this.setState(prevState => ({
                            showBulkUpdate: !prevState.showBulkUpdate,
                          }))
                        }
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </SidebarPage>
          <Sidebar>
            <SidebarContent name="Filters" icon="filter">
              <WorkOrdersFilters
                creators={creators}
                locations={locations}
                manufacturingProcess={manufacturingProcess}
                materials={materials}
                onSelectCreator={this.handleSelectCreator.bind(this)}
                onSelectLocation={this.handleSelectLocation.bind(this)}
                onSelectMaterial={this.handleSelectMaterial.bind(this)}
                onSelectPrinter={this.handleSelectPrinter.bind(this)}
                onSelectProcess={this.handleSelectProcess.bind(this)}
                printers={printers}
              />
            </SidebarContent>
            <SidebarContent name="Bulk Update" icon="tasks">
              <p>Select work orders on the left to bulk apply changes to</p>
              {selectedWorkOrders.length > 0 && (
                <div>
                  <div className="mb-3">
                    <h5 className="text-muted mb-0">
                      Selected ({selectedWorkOrders.length}/{bulkEditLimit}):
                    </h5>
                    <ul className="pl-2">
                      {selectedWorkOrders.map(workOrder => {
                        if (!workOrder) return null;
                        return (
                          <li key={workOrder.id}>
                            <div className="flex whitespace-nowrap">
                              WO&nbsp;<strong>#{workOrder.id}</strong>&nbsp;(
                              {workOrder.manufacturing_process}) (
                              <div style={{ maxWidth: 80 }} className="text-truncate">
                                {workOrder.state}
                              </div>
                              )
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <div className="text-right">
                    <Button
                      size="md"
                      className="mr-2"
                      onClick={() => this.setState({ selectedWorkOrders: [] })}
                    >
                      Clear Selection
                    </Button>
                    {canBulkUpdate ? (
                      <Button
                        size="md"
                        color="primary"
                        onClick={() => this.setState({ showBulkUpdate: true })}
                      >
                        Bulk Update
                      </Button>
                    ) : (
                      <div className="font-size-md text-left text-danger mt-2">
                        <Icon name="exclamation-sign" right /> You must select Work Orders (max
                        {bulkEditLimit}) of the same process.
                      </div>
                    )}
                  </div>
                </div>
              )}
            </SidebarContent>
          </Sidebar>
        </SidebarPageContainer>
      </>
    );
  }
}
