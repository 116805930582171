import { isMatch } from 'lodash';
import React, { useEffect } from 'react';

import { IconFont } from 'fr-shared/components';
import { useInspectionTypes, useManufacturingProcess } from 'fr-shared/hooks';
import { getDefaultInspectionType } from 'fr-shared/lib/inspection_types';
import {
  MANUFACTURING_PROCESSES,
  getCNCManufacturingId,
  getCUManufacturingId,
  getDefaultValues,
  getIMManufacturingId,
} from 'fr-shared/lib/manufacturing_process';

import { Button, InspectionTypeForDefinition, classNames } from 'portal/components';
import { BulkConfigAutoquoteTiersResponseState } from 'portal/lib/autoquote';
import { Part } from 'portal/lib/cart';
import PartFormFields, {
  PartFormState,
} from 'portal/pages/part_config/components/PartFormFields';
import { CNC_DEFAULT_VALUES } from 'portal/pages/part_config/util';

interface BulkPartConfigFormProps {
  autoquoteResponseState: BulkConfigAutoquoteTiersResponseState;
  bulkPartConfig: PartFormState;
  hasSupportingDocs: boolean;
  parts: Part[];
  handleFieldChanges: (changes: Partial<PartFormState>) => void;
}

const BulkPartConfigForm = ({
  autoquoteResponseState,
  bulkPartConfig,
  hasSupportingDocs,
  parts,
  handleFieldChanges,
}: BulkPartConfigFormProps) => {
  const { data: inspectionTypes }: { data: InspectionTypeForDefinition[] } = useInspectionTypes();

  const { data: manufacturingProcesses }: { data: ManufacturingProcess[] } =
    useManufacturingProcess({ publiclyAvailable: true });

  const cncId = manufacturingProcesses && getCNCManufacturingId(manufacturingProcesses);
  const cuId = manufacturingProcesses && getCUManufacturingId(manufacturingProcesses);
  const imId = manufacturingProcesses && getIMManufacturingId(manufacturingProcesses);

  const currentProcess = manufacturingProcesses?.find(
    (mp: any) => mp.id === bulkPartConfig.manufacturing_process_id
  );

  const defaultValues = getDefaultValues(currentProcess, CNC_DEFAULT_VALUES);
  const resetIsDisabled = defaultValues && isMatch(bulkPartConfig, defaultValues);

  const handleResetToDefaults = () => {
    handleFieldChanges(defaultValues);
  };

  /**
   * When the inspection type or manufacturing types load or the user changes the manufacturing process, let's update the line item inspection type
   */
  const defaultInspectionTypeDependencies = [
    inspectionTypes,
    manufacturingProcesses,
    bulkPartConfig.manufacturing_process_id,
  ];
  useEffect(() => {
    if (inspectionTypes) {
      handleFieldChanges(
        getDefaultInspectionType(cncId, cuId, imId, inspectionTypes, currentProcess?.id)
      );
    }
    /* eslint-disable-next-line */
  }, defaultInspectionTypeDependencies);

  if (parts.length < 1) return null;
  return (
    <div className="p-3">
      <div className="flex flex-col pb-2 border-coolGray-600 border-bottom mb-3">
        {(currentProcess?.name === MANUFACTURING_PROCESSES.CNC ||
          currentProcess?.autoquotable_defaults) && (
          <div className="flex justify-end">
            <Button
              className={classNames([
                'w-fit flex items-center bg-transparent border-0 p-0 leading-none',
                resetIsDisabled ? 'text-coolGray-500' : 'text-coolGray-300',
              ])}
              color="none"
              disabled={resetIsDisabled}
              onClick={handleResetToDefaults}
            >
              <IconFont name="reset" className="text-2xl" />
              <div className="text-xs">Reset to defaults</div>
            </Button>
          </div>
        )}
        <h3 className="text-lg m-0" data-testid="part-config-create-quote">
          Manufacturing specs
        </h3>
      </div>

      <PartFormFields
        autoquoteResponseState={autoquoteResponseState}
        formState={bulkPartConfig}
        part={parts[0]}
        onChanges={handleFieldChanges}
        readonly={false}
        showBulkFields={true}
        hasSupportingDocs={hasSupportingDocs}
      />
    </div>
  );
};

export default BulkPartConfigForm;
