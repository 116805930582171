import React from 'react';

import { Animated, Button, IconFont, classNames } from 'fr-shared/components';

import { Card, HelpModal, Page } from 'portal/components';

import styles from './index.module.css';

const UploadFailContainer = () => {
  return (
    <Page
      className={classNames([
        styles.PageStyles,
        'flex flex-column flex-fill align-items-center justify-content-center p-0',
      ])}
    >
      <Animated animationIn="fadeIn">
        <div className={styles.UploadFail}>
          <Card border={true} className={styles.Card} size="lg">
            <div>
              <header className="flex justify-content-between align-items-center mb-2">
                <h1 className="font-size-lg">We couldn&apos;t complete your upload</h1>
                <IconFont name="error-triangle" className="text-white" />
              </header>
              <p className="font-size-md mb-7">
                Sorry, something went wrong and we couldn&apos;t upload your part files.
              </p>
              <div className="flex">
                <Button outline to="/add-part" className="px-4 flex-1 mr-1">
                  Try again
                </Button>
                <HelpModal
                  userAnalyticsPrefix="Upload Fail"
                  action={
                    <Button outline className="px-4 flex-1 ml-1">
                      Contact us
                    </Button>
                  }
                />
              </div>
            </div>
          </Card>
        </div>
      </Animated>
    </Page>
  );
};

export default UploadFailContainer;
