import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'fr-shared/components';

const ButtonLink = props => (
  <Button color="link" dataTestId="button-link" {...props}>
    {props.children}
  </Button>
);

ButtonLink.propTypes = {
  /**  Buttons will appear pressed (with a darker background, darker border, and inset shadow) when active. */
  active: PropTypes.bool,
  /** Create block level buttons—those that span the full width of a parent—by adding block */
  block: PropTypes.bool,
  /** Styles the button to the desired color, examples above */
  color: PropTypes.string,
  /** Make buttons look inactive by adding the disabled boolean attribute to any button element. */
  disabled: PropTypes.bool,
  /** String identifier to be used for testing. */
  dataTestId: PropTypes.string,
  /** Creates outline of button, removing color or background. */
  outline: PropTypes.bool,
  /** Url path used for Link component */
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Bool passed in to tell if button is loading. */
  loading: PropTypes.bool,
  /** String to pass in to be displayed in built in tool tip */
  tooltip: PropTypes.string,
  /** Pass in addition in-line styes as needed */
  style: PropTypes.object,
  /** Defines button type */
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf(['a', 'button'])]),
  /** Function that gets invoked during onClick event. */
  onClick: PropTypes.func,
  /** Defines size of button when passed in. */
  size: PropTypes.string,
  /** Shows loading icon instead of children when loading */
  showLoadingIcon: PropTypes.bool,
  /** Allows child nodes to be passed in. */
  children: PropTypes.node,
  /** Pass in additional className to button. */
  className: PropTypes.string,
  /** Indicates whether the link should open in a new window */
  openNewWindow: PropTypes.bool,
  /** Indicates whether a new window link should show the icon */
  openNewWindowIcon: PropTypes.bool,
  /** Valid subset of input elements' type attribute supported by this component */
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
};

ButtonLink.defaultProps = {
  tag: 'button',
};

export default ButtonLink;
