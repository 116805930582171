import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, GlobalErrorBoundary, NotFound } from 'fr-shared/components';

import SupplierForm from './SupplierForm';

export default class SupplierEdit extends Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
  };

  state = { location: null, loading: false, hasErrors: false };

  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({ loading: true, hasErrors: false, location: null });
    api
      .get(`/locations/${id}`)
      .then(response => {
        response.data.active = response.data.deleted_at === null ? true : false;
        this.setState({ location: response.data });
      })
      .catch(error => {
        if (error.response?.status !== 404) {
          this.setState({ hasErrors: true });
          Sentry.captureMessage('Supplier GET Failed');
          Sentry.setExtra('supplier_id', id);
          Sentry.setExtra('error', error);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleSubmit = (values, actions) => {
    const { setErrors } = actions;
    const id = this.props.match.params.id;

    if (values.active === false && values.deleted_at === null) {
      values.deleted_at = new Date().toISOString();
    } else if (values.active) {
      values.deleted_at = null;
    }

    api
      .put(`/locations/${id}`, { location: values })
      .then(() => {
        this.props.history.push('/admin/suppliers');
      })
      .catch(err => {
        if (err.response?.data) {
          const { errors, messages } = err.response.data;
          setErrors({
            server: messages,
            ...errors,
          });
          if (err.response?.status === 403) {
            setErrors({ server: ['You do not have access to update this supplier.'] });
          }
        }
      });
  };

  render() {
    const { loading, hasErrors, location } = this.state;
    if (loading) return null;
    if (hasErrors) return <GlobalErrorBoundary />;
    if (!location) return <NotFound />;

    return (
      <>
        <Breadcrumb to="/admin/suppliers">Suppliers</Breadcrumb>
        <Breadcrumb to={`/admin/suppliers/${location.id}/edit`}>{location.name}</Breadcrumb>

        <SupplierForm
          initialValues={location}
          onSubmit={this.handleSubmit}
          title="Edit Supplier"
        />
      </>
    );
  }
}
