import { drop, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Button, Icon, Modal } from 'fr-shared/components';
import { useS3Upload } from 'fr-shared/hooks';
import { keyboardDown } from 'fr-shared/utils';

const YieldReportFileUpload = ({
  children,
  image,
  onFileUploaded,
  onFileCleared,
  ...buttonProps
}) => {
  const inputRef = React.createRef();
  const selectFile = () => inputRef.current.click();

  const [imagePreview, setImagePreview] = React.useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);
  const [uploadedImage, uploadToS3] = useS3Upload();

  const handlePreviewOpen = () => setIsPreviewOpen(true);

  useEffect(() => {
    if (uploadedImage.data) {
      onFileUploaded({ link: imagePreview, ...uploadedImage.data });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagePreview, uploadedImage]);

  const onSelectFile = e => {
    const file = e.target.files[0];
    setImagePreview(URL.createObjectURL(file));
    uploadToS3('/s3/sign/image_file', file);
  };

  const imageLink = imagePreview || get(image, 'link');

  return (
    <div className="flex-1 d-flex d-md-inline-block">
      {get(image, 's3_path') ? (
        <div className="flex flex-1 flex-row align-items-center cursor-pointer">
          <div
            onClick={handlePreviewOpen}
            onKeyDown={evt => keyboardDown(evt, 'Enter', handlePreviewOpen)}
            role="button"
            tabIndex={0}
          >
            {imageLink && (
              <img
                src={imageLink}
                className="rounded h-auto"
                width={50}
                height={50}
                alt="Upload preview"
              />
            )}

            <Button color="link" className="mr-2 d-none d-md-inline-block">
              {drop(image.s3_path.split('_'))}
            </Button>
          </div>
          <div className="flex flex-1 align-items-end">
            <Button
              className="py-1 px-2 ml-auto"
              onClick={onFileCleared}
              color="danger"
              outline={true}
            >
              <Icon name="times" />
            </Button>
          </div>
        </div>
      ) : (
        <>
          <Button
            {...buttonProps}
            className="flex-1 d-flex d-md-inline-block justify-content-center"
            loading={uploadedImage.loading}
            onClick={selectFile}
          >
            {children}
          </Button>
          <input
            id="supporting-document"
            ref={inputRef}
            type="file"
            className="hidden"
            onClick={e => (e.target.value = null)}
            onChange={onSelectFile}
          />
        </>
      )}
      <Modal isOpen={isPreviewOpen} toggle={() => setIsPreviewOpen(false)}>
        <div className="modal-body text-center">
          <img
            src={imageLink}
            style={{ maxHeight: '100%', maxWidth: '100%' }}
            alt="Upload preview"
          />
        </div>
      </Modal>
    </div>
  );
};

YieldReportFileUpload.propTypes = {
  children: PropTypes.node,
  image: PropTypes.object,
  onFileUploaded: PropTypes.func,
  onFileCleared: PropTypes.func,
};

export default YieldReportFileUpload;
