import { useFormikContext } from 'formik';
import { useRef } from 'react';

/**
 * This hook abstracts keeping a reference to the formik context
 *
 * We need to sometimes use a ref of formik when we want to access
 * updated values in a closure.
 *
 * For example: with JS' timeout function, we pass in a closure that may reference formik:
 * setTimeout(() => {
 *  const lineItems = formik.values.line_items
 *  // ... do something with lineItems
 * }, 2000)
 *
 * In this example, if formik.values is mutate in between when the closure is created and
 * when the code is executed, `lineItems` would be stale.
 *
 * To fix this, we use `useFormikRef`, which allows us to pass an object that holds a reference to
 * the most current formik context. So our new, correct code will look like:
 *
 * setTimeout(() => {
 *  const lineItems = formikRef.current.values.line_items
 *  // ... do something with lineItems
 * }, 2000)
 */

const useFormikRef = () => {
  const formik = useFormikContext();
  const formikRef = useRef();

  formikRef.current = formik;

  return formikRef;
};

export default useFormikRef;
