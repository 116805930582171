import { isFinite, toNumber } from 'lodash';
import { map, pipe, sumBy } from 'lodash/fp';

export const safeNumber = unsafeNumber => {
  const price = toNumber(unsafeNumber);
  return isFinite(price) ? price : 0.0;
};

export const currencyFactory = amount => {
  return {
    currency: 'USD',
    amount: Number(amount).toLocaleString('en', {
      minimumFractionDigits: 2,
      useGrouping: false,
    }),
    formatted: Number(amount).toLocaleString('en', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }),
  };
};

export const calculateTotal = pipe(
  map(safeNumber),
  sumBy(v => v),
  currencyFactory
);
