import { useFormikContext } from 'formik';
import { find, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { useManufacturingProcess } from 'fr-shared/hooks';
import { AUTOCOSTABLE_MFG_PROCESSES } from 'fr-shared/lib/manufacturing_process';

import { FormSelect } from 'portal/components';

const FormAutocostableProcess = ({
  fieldName,
  inputClassName,
  md,
  onChange,
  onProcessChange,
  onMaterialChange,
  onColorChange,
  readonly,
  stacked,
}) => {
  const formik = useFormikContext();
  const { data: manufacturingProcessesData } = useManufacturingProcess({
    publiclyAvailable: true,
  });

  const lineItem = get(formik.values, fieldName);

  if (!manufacturingProcessesData || !lineItem) return null;

  const manufacturingProcesses = manufacturingProcessesData.filter(mp =>
    AUTOCOSTABLE_MFG_PROCESSES.includes(mp.name)
  );

  const currentProcess = find(manufacturingProcesses, {
    id: parseInt(lineItem.manufacturing_process_id),
  });

  const currentMaterials = currentProcess && currentProcess.materials;

  const selectedMaterial =
    lineItem.material_id && find(currentMaterials, { id: parseInt(lineItem.material_id) });

  const colors = get(selectedMaterial, 'colors', []);

  const layerThicknesses = selectedMaterial && selectedMaterial.layer_thicknesses;

  const handleProcessChange = e => {
    const newLineItem = {
      ...formik.values[fieldName],
      manufacturing_process_id: e.target.value,
      material_id: null,
      material: null,
    };

    if (e.target.value) {
      const process = find(manufacturingProcesses, { id: parseInt(e.target.value) });
      newLineItem.manufacturing_process = process;
    } else {
      newLineItem.manufacturing_process = null;
    }

    formik.setFieldValue(fieldName, newLineItem);
    onChange && onChange(newLineItem);
    onProcessChange && onProcessChange(newLineItem.manufacturing_process);
  };

  const handleMaterialChange = e => {
    const newLineItem = {
      ...formik.values[fieldName],
      material_id: e.target.value,
    };

    if (e.target.value) {
      const material = find(currentMaterials, { id: parseInt(newLineItem.material_id) });
      newLineItem.material = material;
      if (material.colors.length > 0) {
        newLineItem.color_id = material.colors[0].id;
        newLineItem.color = material.colors[0];
      }
      if (get(material, 'layer_thicknesses.length', 0) > 0) {
        const defaultLayerThickness = material.layer_thicknesses.find(lt => lt.value === '0.01');
        const newLayerThickness = defaultLayerThickness
          ? defaultLayerThickness
          : material.layer_thicknesses[0];
        newLineItem.layer_thickness_id = newLayerThickness.id;
        newLineItem.layer_thickness = newLayerThickness;
      }
    } else {
      newLineItem.material = null;
    }

    formik.setFieldValue(fieldName, newLineItem);
    onChange && onChange(newLineItem);
    onMaterialChange && onMaterialChange(newLineItem.material);
  };

  const handleColorChange = e => {
    const selectedColorName = e.target.options
      ? e.target.options[e.target.selectedIndex].text
      : e.target.label;

    const newLineItem = {
      ...formik.values[fieldName],
      color_id: e.target.value,
      color: { name: selectedColorName, id: e.target.value },
    };
    formik.setFieldValue(fieldName, newLineItem);
    onChange && onChange(newLineItem);
    onColorChange && onColorChange(newLineItem.color);
  };

  if (isEmpty(manufacturingProcesses)) return null;

  return (
    <>
      <FormSelect
        data-testid="mfg-process"
        id="mfg-process"
        inputClassName={inputClassName}
        label={readonly ? 'MFG Process' : 'Manufacturing process'}
        md={md}
        name={`${fieldName}.manufacturing_process_id`}
        onChange={e => handleProcessChange(e)}
        onlyShowErrorIfTouched={true}
        placeholder={'Select from List'}
        readonly={readonly && `${fieldName}.manufacturing_process.name`}
        required={true}
        optionList={manufacturingProcesses}
        floatingLabel={true}
        showError={!readonly}
        sorted={true}
        stacked={stacked}
      />
      {currentProcess && !isEmpty(currentMaterials) && (
        <FormSelect
          inputClassName={inputClassName}
          label="Material"
          md={md}
          name={`${fieldName}.material_id`}
          onChange={e => handleMaterialChange(e)}
          onlyShowErrorIfTouched={true}
          optionList={currentMaterials}
          placeholder={'Select from List'}
          floatingLabel={true}
          readonly={readonly && `${fieldName}.material.name`}
          required={true}
          showError={!readonly}
          sorted={true}
          stacked={stacked}
        />
      )}
      {!isEmpty(colors) && (
        <FormSelect
          hasBlankOption={false}
          inputClassName={inputClassName}
          label="Color"
          md={md}
          name={`${fieldName}.color_id`}
          onChange={e => {
            handleColorChange(e);
          }}
          onlyShowErrorIfTouched={true}
          optionList={colors}
          placeholder="Select from List"
          readonly={readonly && `${fieldName}.color.name`}
          floatingLabel={true}
          required={true}
          showError={!readonly}
          sorted={true}
          stacked={stacked}
        />
      )}
      {!isEmpty(layerThicknesses) && (
        <div className="mt-2">
          <span>Available layer thickness</span>
          <div>- {`0.01"`}</div>
        </div>
      )}
    </>
  );
};

FormAutocostableProcess.propTypes = {
  fieldName: PropTypes.string.isRequired,
  inputClassName: PropTypes.string,
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  onProcessChange: PropTypes.func,
  onMaterialChange: PropTypes.func,
  onColorChange: PropTypes.func,
  readonly: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  stacked: PropTypes.bool,
};

FormAutocostableProcess.defaultProps = {
  stacked: false,
};

export default FormAutocostableProcess;
