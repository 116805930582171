import { FieldArray, useFormikContext } from 'formik';
import { get, merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import Dropzone from 'react-dropzone';

import { api } from 'fr-shared/api';
import { Button, FormFieldBase, Icon, Modal, classNames } from 'fr-shared/components';
import { AlertContext, useUserAnalyticsContext } from 'fr-shared/context';
import { useS3FilesUpload } from 'fr-shared/hooks';

const FormSupportingDocuments = ({
  dropzoneComponent,
  fieldPrefix,
  readonly,
  isRounded = true,
  parentType = 'default',
  onUploadSuccess = () => {},
  ...formProps
}) => {
  const formik = useFormikContext();
  const lineItem = get(formik.values, fieldPrefix);
  const userAnalytics = useUserAnalyticsContext();

  let parent, basePath, valuePrefix;
  switch (parentType) {
    case 'order_line_item':
      parent = lineItem;
      basePath = `/order_line_items/${parent.id}/documents`;
      valuePrefix = `${fieldPrefix}.documents`;
      break;

    default:
      parent = lineItem.part;
      basePath = `/parts/${parent?.id}/documents`;
      valuePrefix = `${fieldPrefix}.part.documents`;
  }

  const onFileUploadSuccess = res => {
    onUploadSuccess();
    userAnalytics.track('Part Config - Supporting Doc Uploaded', {
      fileName: res?.data?.file_name,
    });
  };
  const onFileUploadFail = error => {
    userAnalytics.track('Part Config - Supporting Doc Uploaded Failed', {
      error: error?.config?.data || error,
    });
  };

  // Define file upload method
  const addDocument = (response, file) => {
    const { filename, path } = response.data;
    const fileType = filename.match(/[^.]+$/)[0];
    const fileAttrs = {
      file_name: file.name,
      file_type: fileType,
      s3_path: path,
    };

    if (parent.id) {
      return api.post(basePath, { document: fileAttrs });
    } else {
      return Promise.resolve({ data: fileAttrs });
    }
  };

  const [supportingDocuments, uploadSupportingDocuments] = useS3FilesUpload(
    '/s3/sign/part_file',
    addDocument,
    onFileUploadSuccess,
    onFileUploadFail
  );

  useEffect(() => {
    if (supportingDocuments.length === 0) return;
    const transformedFiles = supportingDocuments.map(file => ({ ...file, ...file.data }));
    const newDocuments = parent.documents.map(doc =>
      merge(
        doc,
        transformedFiles.find(file => file.uuid === doc.uuid)
      )
    );

    formik.setFieldValue(valuePrefix, newDocuments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportingDocuments]);

  const handleDrop = files => {
    const uploadFiles = files.map(file => ({ file }));
    const loadingFiles = uploadSupportingDocuments(uploadFiles);
    formik.setFieldValue(valuePrefix, [...parent.documents, ...loadingFiles]);
  };

  return (
    <FormFieldBase readonly={readonly} label="Supporting documents" {...formProps}>
      <>
        <FieldArray name={valuePrefix}>
          {({ remove }) => (
            <div>
              {get(parent, 'documents', []).map((doc, docIndex) => {
                if (!doc.deleted_at)
                  return (
                    <SupportingDocItem
                      key={docIndex}
                      basePath={basePath}
                      doc={doc}
                      onDelete={() => remove(docIndex)}
                      readonly={readonly}
                    />
                  );
              })}
            </div>
          )}
        </FieldArray>
        {!readonly && (
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} id="file-dropzone" />
                {dropzoneComponent ? (
                  dropzoneComponent
                ) : (
                  <div
                    className={classNames([
                      'card',
                      'py-3 px-3',
                      'bg-white',
                      'flex align-items-center justify-content-center',
                      isDragActive && 'is-dragging',
                      !isRounded && 'rounded-0',
                    ])}
                  >
                    <>
                      <div className="mb-3 font-size-md text-center">
                        <div>
                          Drop supporting documents, such as <br /> part drawings, here to upload
                        </div>
                      </div>

                      <Button
                        color="primary"
                        outline={true}
                        className={classNames(['px-5', !isRounded && 'rounded-0'])}
                      >
                        Select Files
                      </Button>
                    </>
                  </div>
                )}
              </div>
            )}
          </Dropzone>
        )}
      </>
    </FormFieldBase>
  );
};

const DeleteSupportingDocModal = ({ basePath, doc, onDelete = () => {} }) => {
  const { setAlert } = useContext(AlertContext);

  const handleDelete = toggle => {
    if (doc.id) {
      api
        .delete(`${basePath}/${doc.id}`)
        .then(() => {
          toggle();
          onDelete();
        })
        .catch(() => {
          setAlert({
            message:
              'Sorry, we were unable to delete the supporting document. Please refresh and try again.',
            color: 'danger',
          });
        });
    } else {
      toggle();
      onDelete();
    }
  };

  return (
    <Modal
      action={
        <Button
          className="delete-doc"
          color="danger"
          dataTestId="supporting-doc-item-modal-deletion-button"
          size="sm"
          outline={true}
        >
          <Icon name="times" />
        </Button>
      }
    >
      {({ toggle }) => (
        <>
          <Modal.Header title="Delete Supporting Document" onClose={toggle} />
          <div className="modal-body">
            <p>Are you sure you want to delete {doc.file_name}?</p>
          </div>
          <div className="modal-footer">
            <Button outline onClick={toggle}>
              Cancel
            </Button>
            <Button onClick={() => handleDelete(toggle)} color="danger" className="ml-2">
              Delete
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

DeleteSupportingDocModal.propTypes = {
  basePath: PropTypes.string.isRequired,
  doc: PropTypes.shape({
    id: PropTypes.number,
    file_name: PropTypes.string,
  }),
  onDelete: PropTypes.func,
};

const SupportingDocItem = ({ readonly, basePath, doc, onDelete }) => {
  let name = doc.file_name ? doc.file_name : doc.file.name;
  let failed = doc.upload_status === 'error';
  let loading = doc.upload_status === 'loading';

  return (
    <div
      className={classNames([
        'supporting-doc-item flex align-items-center mb-2',
        failed && 'alert alert-danger p-0 px-1',
      ])}
    >
      {loading && <Icon name="spinner" className="fa-pulse text-primary mr-1" />}
      {failed ? (
        <div>
          <strong>{name}</strong> ... failed to upload
        </div>
      ) : (
        <Button
          disabled={doc.upload_status === 'loading'}
          color="link"
          onClick={() => window.open(doc.url)}
        >
          {name}
        </Button>
      )}
      {!loading && !readonly && (
        <div className="ml-auto">
          <DeleteSupportingDocModal basePath={basePath} doc={doc} onDelete={onDelete} />
        </div>
      )}
    </div>
  );
};

SupportingDocItem.propTypes = {
  basePath: PropTypes.string.isRequired,
  doc: PropTypes.shape({
    file_name: PropTypes.string,
    file: PropTypes.shape({
      name: PropTypes.string,
    }),
    upload_status: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
};

FormSupportingDocuments.propTypes = {
  dropzoneComponent: PropTypes.node,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fieldPrefix: PropTypes.string,
  parentType: PropTypes.string,
  onUploadSuccess: PropTypes.func,
  isRounded: PropTypes.bool,
  readonly: PropTypes.bool,
};

export default FormSupportingDocuments;
