import { connect } from 'formik';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

const FormScrollUpOnErrors = ({ formik }) => {
  useEffect(() => {
    if (formik.isSubmitting && !isEmpty(formik.errors)) {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [formik, formik.errors]);

  return null;
};

export default connect(FormScrollUpOnErrors);
