import { FormikContextType, useFormikContext } from 'formik';
import React, { useCallback, useContext, useState } from 'react';
import Dropzone from 'react-dropzone';

import { Button, IconFont, Modal, ProgressCircle, classNames } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { uploadDocumentsToS3 } from 'fr-shared/lib/s3';
import { keyboardDown } from 'fr-shared/utils';
import {
  PURCHASE_ORDER_FILE_EXTENSIONS,
  PURCHASE_ORDER_MAX_FILE_SIZE_MB,
  getExtension,
} from 'fr-shared/utils/files';

import useScopedAnalytics from 'portal/hooks/useScopedAnalytics';

import PoDocumentsList from './PoDocumentsList';

const s3Endpoint = '/s3/sign/purchase_order_file';

function PoDocumentsModal() {
  const handleAnalytics = useScopedAnalytics('Checkout');
  const maxFileSizeInBytes = PURCHASE_ORDER_MAX_FILE_SIZE_MB * 1000000;
  const formik: FormikContextType<any> = useFormikContext();
  const { user } = useContext(UserContext);

  const [documents, setDocuments] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [rejectedFiles, setRejectedFiles] = useState([]);

  const handleDrop = useCallback(
    async (files: File[]) => {
      setIsUploading(true);
      uploadDocumentsToS3(files, user, s3Endpoint)
        .then((uploadedDocs: FRDocument[]) => {
          setDocuments([...documents, ...uploadedDocs]);
        })
        .catch(error => {
          setRejectedFiles([...rejectedFiles, { fileName: error.file.name }]);
        })
        .finally(() => {
          setIsUploading(false);
        });
    },
    [documents, user, rejectedFiles, setRejectedFiles, setIsUploading]
  );

  const handleRejectedFiles = useCallback(
    files => {
      const newRejectedFiles = files.map((file: File) => {
        const ext = getExtension(file.name);

        if (!PURCHASE_ORDER_FILE_EXTENSIONS.includes(ext)) {
          return { fileName: file.name, errorReason: 'Invalid file type.' };
        } else {
          return { fileName: file.name, errorReason: 'The file is too big.' };
        }
      });

      setRejectedFiles([...rejectedFiles, ...newRejectedFiles]);
    },
    [setRejectedFiles, rejectedFiles]
  );

  const handleDeleteDocument = (index: number) => {
    const newDocuments = [...documents];
    newDocuments.splice(index, 1);
    setDocuments(newDocuments);
  };

  const handleSupportType = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleCancel = () => {
    handleAnalytics('Clicked "Cancel" on PO document upload');
    setIsOpen(false);
    setDocuments([]);
    setRejectedFiles([]);
  };

  const handleSubmit = () => {
    if (documents.length > 0) {
      const currentDocuments = formik.values.po_documents ? formik.values.po_documents : [];
      formik.setFieldValue('po_documents', [...currentDocuments, ...documents]);
      setIsOpen(false);
      setDocuments([]);
      setRejectedFiles([]);
    }
    handleAnalytics('Clicked "Done" on PO document upload');
    setIsOpen(false);
  };

  return (
    <Modal
      className="modal-wide"
      action={
        <Button className="btn-sm" dataTestId="po-checkout-add-document-btn" outline>
          <IconFont name="upload" />
          Add PO document
        </Button>
      }
      onActionHandler={() => handleAnalytics('Clicked "Add PO document"')}
      backdrop="static"
      toggle={() => setIsOpen(!isOpen)}
      isOpen={isOpen}
    >
      <Modal.Header className="pb-0" showCloseButton={false}>
        <div className="flex border-solid border-coolGray-700 border-b border-t-0 border-x-0 pb-0.5">
          <h3>Add purchase order document</h3>
        </div>
      </Modal.Header>
      <Modal.Body className="pb-4">
        <div className="flex flex-column align-items-center text-center border-dashed mx-0 my-4 bg-coolGray-900 rounded-md h-[116px]">
          {isUploading ? (
            <div className="scale-[.5]">
              <ProgressCircle
                className="bg-coolGray-900"
                percentage={75}
                spinner={true}
                backgroundColor="#141719"
              />
            </div>
          ) : (
            <Dropzone
              accept={PURCHASE_ORDER_FILE_EXTENSIONS}
              maxSize={maxFileSizeInBytes}
              onDrop={handleDrop}
              onDropRejected={handleRejectedFiles}
              noClick
            >
              {({ getRootProps, getInputProps, open, isDragActive }) => (
                <div className="w-100 p-3" {...getRootProps()}>
                  <input {...getInputProps()} id="file-dropzone" data-testid="file-dropzone" />
                  <div className={classNames([isDragActive && 'is-dragging'])}>
                    <div className="text-coolGray-300 text-xs mb-2">
                      Drag and drop files here, or
                    </div>
                    <Button onClick={open} className="py-1 font-size-sm" outline>
                      Browse
                    </Button>
                  </div>
                </div>
              )}
            </Dropzone>
          )}
        </div>
        {rejectedFiles.length > 0 && (
          <ul className="list-none m-0 p-0">
            {rejectedFiles.map((rejectedFile, index) => {
              return (
                <ErrorChip
                  key={index}
                  fileName={rejectedFile.fileName}
                  errorReason={rejectedFile.errorReason}
                />
              );
            })}
          </ul>
        )}
        <PoDocumentsList documents={documents} handleDeleteDocument={handleDeleteDocument} />

        <div className="flex w-100 mt-3 mb-4">
          <Button className="w-100 mr-2" onClick={handleCancel} outline>
            Cancel
          </Button>
          <Button
            disabled={documents.length === 0 || isUploading}
            className="w-100"
            onClick={handleSubmit}
            dataTestId="po-checkout-documents-modal-done-btn"
          >
            {documents.length === 0 ? 'Attach' : 'Done'}
          </Button>
        </div>

        <div className="text-coolGray-100">
          <div
            onClick={handleSupportType}
            className="cursor-pointer"
            onKeyDown={evt => keyboardDown(evt, 'Enter', handleSupportType)}
            role="button"
            tabIndex={0}
          >
            Supported file types
            {isCollapsed && <IconFont className="ml-1 font-size-lg" name="chevron-down" />}
            {!isCollapsed && <IconFont className="ml-1 font-size-lg" name="chevron-up" />}
          </div>
          {!isCollapsed && (
            <div className="mt-1 text-xs">
              .png, .jpg, .pdf, .xcl, .xls, xlsx
              <br />
              <span className="text-coolGray-300">Max file size:&nbsp;</span>100MB
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

interface ErrorChipProps {
  fileName: string;
  errorReason?: string;
}
const ErrorChip = ({ fileName, errorReason = '' }: ErrorChipProps) => {
  return (
    <li className="mb-1 flex w-100 p-1 bg-coolGray-700 rounded-sm">
      <div className="flex flex-row w-100 items-center">
        <IconFont className="text-xl text-error-300 flex" name="error-triangle" />

        <div className="ml-1 text-xs text-coolGray-100 p-0">
          <span>{fileName}</span>{' '}
          <span className="text-error-300">can&apos;t be uploaded. {errorReason}</span>
        </div>
      </div>
    </li>
  );
};

export default PoDocumentsModal;
