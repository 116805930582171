import { connect } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Alert, Button } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import {
  COSTING_REQUEST_LI_STATES,
  inProgressNoticeText,
  stateCanDuplicateAndEditLineItem,
} from 'fr-shared/lib/costing_requests';
import { isFormikAtPrefixTheSame } from 'fr-shared/utils';

import { noPartQuantitiesSelected, selectedPartQuantitiesForLineItem } from '../utils/selected';
import CostingLineItemMarkAsClosed from './CostingLineItemMarkAsClosed';
import { CostingFormContext } from './context/CostingFormContext';

const ManageCostingRequestActions = ({
  fieldPrefix,
  lineItem,
  onDuplicateLineItem,
  onSaveLineItem,
  values,
}) => {
  const history = useHistory();
  const { validateAction } = useContext(CostingFormContext);
  const {
    opportunity_id,
    sales_contact_id,
    support_contact_id,
    engineering_contact_id,
    public_id,
  } = values;

  const handleConvertToQuote = () => {
    const selectedPartQuantities = selectedPartQuantitiesForLineItem(lineItem);
    const selectedPartQuantityIds = selectedPartQuantities.map(pq => pq.id);
    history.push('/admin/quotes/new', {
      opportunity_id,
      public_id,
      sales_contact_id,
      support_contact_id,
      engineering_contact_id,
      selectedPartQuantityIds,
      display_in_portal: true,
    });
  };

  const { user } = useContext(UserContext);

  const canMarkAsClosed =
    user.canCloseCostingRequestLineItems &&
    lineItem.id &&
    lineItem.state !== COSTING_REQUEST_LI_STATES.Closed &&
    lineItem.state !== COSTING_REQUEST_LI_STATES.InProgress;

  const canConvertToQuote =
    user.canConvertCostingRequestsToQuotes &&
    lineItem.id &&
    lineItem.state === COSTING_REQUEST_LI_STATES.Completed;

  const canDuplicateLineItem =
    user.canDuplicateAndAddLineItemsToCostingRequests &&
    stateCanDuplicateAndEditLineItem(lineItem.state);

  const handleDuplicateLineItem = () => {
    validateAction(fieldPrefix, 'duplicate', () => onDuplicateLineItem(lineItem));
  };

  return (
    <div className="bg-white flex flex-column flex-md-row">
      {lineItem.state === COSTING_REQUEST_LI_STATES.InProgress &&
        !user.canToggleCostingRequestLineItemInProgress && (
          <Alert color="dark" className="my-2">
            {inProgressNoticeText(lineItem)}
          </Alert>
        )}
      <div className="ml-md-auto pt-2 pt-md-0 flex justify-content-between">
        {canMarkAsClosed && (
          <CostingLineItemMarkAsClosed
            lineItem={lineItem}
            onUpdateLineItem={() =>
              onSaveLineItem(lineItem, fieldPrefix, {
                closed_at: moment(),
                state: COSTING_REQUEST_LI_STATES.Closed,
              })
            }
          />
        )}
        {canDuplicateLineItem && (
          <Button className="ml-2" color="primary" onClick={handleDuplicateLineItem}>
            Duplicate & Edit
          </Button>
        )}
        {canConvertToQuote && (
          <Button
            color="primary"
            className="ml-2"
            onClick={handleConvertToQuote}
            disabled={noPartQuantitiesSelected(lineItem)}
            tooltip={
              noPartQuantitiesSelected(lineItem)
                ? 'To continue, select desired part quantities.'
                : null
            }
          >
            Convert to Quote
          </Button>
        )}
      </div>
    </div>
  );
};

ManageCostingRequestActions.propTypes = {
  fieldPrefix: PropTypes.string,
  lineItem: PropTypes.shape({
    state: PropTypes.string,
    id: PropTypes.number,
    manufacturing_process: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  onDuplicateLineItem: PropTypes.func,
  onSaveLineItem: PropTypes.func,
  values: PropTypes.shape({
    sales_contact_id: PropTypes.number,
    support_contact_id: PropTypes.number,
    engineering_contact_id: PropTypes.number,
    opportunity_id: PropTypes.string,
    public_id: PropTypes.string.isRequired,
  }),
};

export default connect(React.memo(ManageCostingRequestActions, isFormikAtPrefixTheSame));
