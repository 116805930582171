import PropTypes from 'prop-types';
import React from 'react';

import { getInspectionTypeDisplayName } from 'fr-shared/lib/inspection_types';
import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';

const LineItemProcess = ({ data, separator }) => {
  const manufacturingProcessName = data.manufacturing_process?.name || data?.process_name;
  const materialName = data.material?.name || data.material_name;
  const colorName = (data.hex_color ? data.hex_color : data.color?.name) || data?.color_name;
  const inspectionTypeName = data.inspection_type?.name || data?.inspection_type_name;
  const showInspectionType =
    manufacturingProcessName !==
    MANUFACTURING_PROCESSES.CNC; /* logic may expand in the future, hence generic variable name*/

  return (
    <>
      {manufacturingProcessName}
      {materialName && `${separator}${materialName}`}
      {colorName && `${separator}${colorName}`}
      {showInspectionType &&
        inspectionTypeName &&
        `${separator}${getInspectionTypeDisplayName(inspectionTypeName)} Inspection`}
      {data.layer_thickness &&
        `${separator}${data.layer_thickness.value} ${data.layer_thickness.unit}`}
      {data.infill && `${separator}${data.infill}`}
    </>
  );
};

LineItemProcess.defaultProps = {
  separator: ' - ',
};

LineItemProcess.propTypes = {
  data: PropTypes.object,
  separator: PropTypes.string,
};

export default LineItemProcess;
