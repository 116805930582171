// keep in sync with lib/fast_radius/types/rejected_code.ex
import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';

export const CRLI_REJECTED_CODES: { [key: string]: any } = {
  [MANUFACTURING_PROCESSES.InjectionMolding]: {
    cad: 'CAD file issue',
    dup: 'Line item not needed',
    fin: 'Finish unavailable',
    fros_info_line: 'Info is missing',
    mfg: 'Injection molding issue',
    mat_scp: 'Supplier unavailable',
    moq: '100 pieces minimum',
    dims: 'Dimensions too tight',
    othr: 'Other: please describe',
  },
  [MANUFACTURING_PROCESSES.CNC]: {
    cad: 'CAD file issue',
    cnc_issue: 'CNC issue',
    doc: 'Document missing',
    fros_info_line: 'Info is missing',
    mat_scp: 'Supplier unavailable',
    othr: 'Other: please describe',
  },
  [MANUFACTURING_PROCESSES.SheetMetal]: {
    cad: 'CAD file issue',
    sheet_metal_issue: 'Sheet Metal issue',
    doc: 'Document missing',
    fros_info_line: 'Info is missing',
    mat_scp: 'Supplier unavailable',
    othr: 'Other: please describe',
  },
  more_info: {
    [MANUFACTURING_PROCESSES.InjectionMolding]:
      'https://docs.google.com/document/d/1lMv1S19v8E-LlN2SIcUMuajTQ75q8Z2ZLYDgW5pyw-s/edit',
    [MANUFACTURING_PROCESSES.CNC]:
      'https://docs.google.com/document/d/1WCAq3Lqb3IgZZJ_KU9v2tz9poNf0FouBqhFk3W_PHGc/edit',
    [MANUFACTURING_PROCESSES.SheetMetal]:
      'https://docs.google.com/document/d/1kAZ1BN8wgC9G3T3JPl886BYGMRh4g1Aw7-vE6ahGyf0/edit',
  },
};
