import PropTypes from 'prop-types';
import React from 'react';

export const TableBody = ({ children }) => <tbody>{children}</tbody>;

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableBody;
