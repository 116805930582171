import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { _useEffect, _useState } from 'react';

import { Select } from 'fr-shared/components';
import { useManufacturingProcess } from 'fr-shared/hooks';

import WorkOrdersCreatorsFilter from './WorkOrdersCreatorsFilter';
import WorkOrdersLocationsFilter from './WorkOrdersLocationsFilter';
import WorkOrdersMaterialsFilter from './WorkOrdersMaterialsFilter';
import WorkOrdersPrintersFilter from './WorkOrdersPrintersFilter';
import { processType } from './utils/WorkOrdersFiltersTypes';

export const WorkOrdersProcessFilter = ({
  onSelectProcess,
  manufacturingProcess,
  manufacturingProcesses,
}) => {
  const extraAttrs = { style: { flex: '0 0 90px' } };
  return (
    <div className="form-group">
      <Select
        className="mb-2"
        hasBlankOption={true}
        id="process-filter"
        label="Manufacturing Process"
        name="process-filter"
        onChange={onSelectProcess}
        optionList={manufacturingProcesses}
        selectAttrs={extraAttrs}
        selectClassName="custom-select"
        value={manufacturingProcess}
        valueAccessor={process => process.name}
      />
    </div>
  );
};

WorkOrdersProcessFilter.propTypes = {
  onSelectProcess: PropTypes.func,
  manufacturingProcess: PropTypes.string,
  manufacturingProcesses: PropTypes.arrayOf(processType),
};

const WorkOrdersFilters = ({
  onSelectCreator,
  onSelectLocation,
  onSelectMaterial,
  onSelectPrinter,
  onSelectProcess,
  creators,
  locations,
  manufacturingProcess,
  materials,
  printers,
}) => {
  const { data: manufacturingProcesses, isError, isLoading, error } = useManufacturingProcess();

  if (isLoading) return <div>Manufacturing process list loading...</div>;
  if (isError) return <div>Error loading manufacturing processes: {error.message}</div>;

  const processData = manufacturingProcess
    ? manufacturingProcesses.find(process => process.name === manufacturingProcess)
    : undefined;
  const availableMaterials = get(processData, 'materials', []);

  const creatorProps = { creators, onSelectCreator };
  const locationProps = { onSelectLocation, manufacturingProcess, locations };
  const materialProps = { availableMaterials, onSelectMaterial, materials };
  const processProps = { onSelectProcess, manufacturingProcess, manufacturingProcesses };
  const printerProps = { onSelectPrinter, manufacturingProcess, printers };

  return (
    <>
      <WorkOrdersProcessFilter {...processProps} />
      <WorkOrdersPrintersFilter {...printerProps} />
      <WorkOrdersMaterialsFilter {...materialProps} />
      <WorkOrdersCreatorsFilter {...creatorProps} />
      <WorkOrdersLocationsFilter {...locationProps} />
    </>
  );
};

WorkOrdersFilters.propTypes = {
  onSelectCreator: PropTypes.func,
  onSelectLocation: PropTypes.func,
  onSelectMaterial: PropTypes.func,
  onSelectPrinter: PropTypes.func,
  onSelectProcess: PropTypes.func,
  creators: PropTypes.arrayOf(PropTypes.number),
  locations: PropTypes.arrayOf(PropTypes.number),
  manufacturingProcess: PropTypes.string,
  materials: PropTypes.arrayOf(PropTypes.string),
  printers: PropTypes.arrayOf(PropTypes.string),
};

export default WorkOrdersFilters;
