import { Logo } from '@fast-radius/shared-ui';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { IconFont } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { DEAL_STATUSES, getDealStatusLabel } from 'fr-shared/lib/deals';
import { ORDER_PAYMENT_TERMS_ENUM } from 'fr-shared/lib/orders';

import { Address, ContactUs, Link } from 'portal/components';

import ConfirmationSummary from './ConfirmationSummary';

const formatDate = date => moment(date).format('MM/DD/YYYY');

const OrderConfirmation = ({
  paymentTerms = '',
  poNumber = '',
  quote,
  quoteNumber,
  status,
  isOrder = false,
}) => {
  const { setAlert } = useContext(AlertContext);

  if (!quote) {
    setAlert({
      message: 'Sorry, we were unable to find an order with that ID.',
      color: 'danger',
    });
    return <Redirect to="/" />;
  }

  const isQuoteConvertedToOrder = !!quote.order_id;
  const displayText = isQuoteConvertedToOrder || isOrder ? 'Order' : 'Quote';
  const isCCPaymentTerm = paymentTerms === ORDER_PAYMENT_TERMS_ENUM.CreditCard;
  const isPONet30PaymentTerm = paymentTerms === ORDER_PAYMENT_TERMS_ENUM.Net30;
  const PAYMENT_TERMS_TEXT = {
    [ORDER_PAYMENT_TERMS_ENUM.CreditCard]:
      'Thanks for your order. We’ll send you an email confirmation soon.',
    [ORDER_PAYMENT_TERMS_ENUM.Net30]:
      'Thanks for your order. We’ll review it and send you an email confirmation within 1 business day.  We might run a credit check or request a deposit as part of our review. If we have any questions or issues, we’ll reach out to you directly. ',
  };
  const dealStatus = getDealStatusLabel(status);

  return (
    <>
      <div className="container-sm pt-5">
        <div className="mb-3">
          <Link
            className="font-size-sm text-gray text-decoration-none"
            to="/"
            data-testid="checkout-back-to-dashboard"
          >
            <IconFont name="chevron-left" right />
            Back to dashboard
          </Link>
        </div>
        <div className="mb-5">
          <Logo textColor="white" />
        </div>
        <div className="flex w-full">
          <div className="w-full">
            <h1 data-testid={`confirmation-header-${displayText.toLowerCase()}`}>
              {displayText} #{quoteNumber}
            </h1>
            <div className="text-muted ">
              <div className="row">
                <div className="col-md mr-5">
                  {PAYMENT_TERMS_TEXT[paymentTerms] || dealStatus}
                </div>
                <div className="col-md-4 flex-column"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-sm py-5">
        <div className="row">
          <div className="col-md mr-5">
            <ConfirmationSummary quote={quote} showDisclaimer={true} showProductionData={true} />
          </div>

          <div className="col-md-4 flex-column">
            <div className="mb-3">
              <div className="flex align-items-center py-3 border-bottom">
                <h3>{displayText} Summary</h3>
              </div>
              <div className="font-size-md py-3">
                <div className="mb-1 flex align-items-center">
                  {displayText} number{' '}
                  <strong
                    data-testid="confirmation-quote-summary-quote-number"
                    className="ml-auto"
                  >
                    #{quoteNumber}
                  </strong>
                </div>
                <div className="mb-1 flex align-items-center">
                  Date submitted{' '}
                  <strong className="ml-auto">{formatDate(quote.date_submitted)}</strong>
                </div>
                <div className="mb-1 flex align-items-center">
                  Number of items{' '}
                  <strong data-testid="number-of-items" className="ml-auto">
                    {quote.line_items.length}
                  </strong>
                </div>
                <OrderStatus status={status} />
              </div>
            </div>

            {quote.shipping_address && (
              <div className="mb-3">
                <div className="flex align-items-center py-3 border-bottom">
                  <h3>Shipping Details</h3>
                </div>
                <div className="font-size-md py-3">
                  {quote.shipping_account_number && (
                    <>
                      <div className="mb-1 flex align-items-center">
                        Account Number{' '}
                        <strong className="ml-auto">{quote.shipping_account_number}</strong>
                      </div>
                      <div className="mb-1 flex align-items-center">
                        Shipping Method{' '}
                        <strong className="ml-auto">{quote.shipping_method}</strong>
                      </div>
                    </>
                  )}
                  <div className="flex font-size-md">
                    <span className="mr-5">Address</span>
                    <Address
                      address={quote.shipping_address}
                      dataTestId="shipping-address"
                      className="ml-auto text-right font-weight-500"
                    />
                  </div>
                </div>
              </div>
            )}

            {quote.billing_address && (
              <div className="mb-3">
                <div className="flex align-items-center py-3 border-bottom">
                  <h3>Billing Details</h3>
                </div>
                <div className="flex py-3 font-size-md">
                  <span className="mr-5">Address</span>
                  <Address
                    address={quote.billing_address}
                    dataTestId="billing-address"
                    className="ml-auto text-right font-weight-500"
                  />
                </div>
              </div>
            )}

            {(isCCPaymentTerm || isPONet30PaymentTerm) && (
              <div className="mb-3">
                <div className="flex align-items-center py-3 border-bottom">
                  <h3>Payment Method</h3>
                </div>
                <div className="py-3 font-size-md">
                  {isPONet30PaymentTerm ? (
                    <div className="flex font-size-md justify-between">
                      <span className="mr-5">Purchase Order </span>
                      <div>{poNumber}</div>
                    </div>
                  ) : (
                    <>
                      {quote.payment_method.card_type} ending with{' '}
                      <strong>{quote.payment_method.last_4_digits}</strong>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const OrderStatus = ({ status }) => {
  return (
    <div className="flex align-items-center">
      Status
      <strong data-testid="status" className="ml-auto">
        {status === DEAL_STATUSES.Rejected ? (
          <ContactUs>
            <IconFont
              name="info-circle"
              className="text-primary mr-1"
              tooltip="Contact us for more information"
            />
            Contact Fast Radius
          </ContactUs>
        ) : (
          getDealStatusLabel(status)
        )}
      </strong>
    </div>
  );
};

OrderStatus.propTypes = {
  status: PropTypes.string,
};

OrderConfirmation.propTypes = {
  isOrder: PropTypes.bool,
  quote: PropTypes.object,
  quoteNumber: PropTypes.string,
  status: PropTypes.string,
  paymentTerms: PropTypes.string,
  poNumber: PropTypes.string,
};

export default OrderConfirmation;
