import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, IconFont, classNames } from 'fr-shared/components';
import { pluralize } from 'fr-shared/utils';

import { AutoquoteErrors } from 'portal/lib/autoquote';
import {
  CartLineItemWithChecks,
  hasCriticalIssues,
  hasInvalidFileType,
  hasMissingInfo,
  hasNonCriticalIssues,
  manufacturabilityChecksIncomplete,
} from 'portal/lib/cart';

/** Public API */
interface Props {
  autoquotableErrors: string[];
  lineItem: CartLineItemWithChecks;
  onPushStudio: () => void;
  redirectState: StudioRedirectState;
  readonly: boolean;
}

/** History state to push when redirecting for DFM checks */
interface StudioRedirectState {
  cart_line_item_id?: string;
  manufacturing_process_id: string;
  material_id: string;
  color_id: string;
  quantity: number;
}

/** Internal helpers */
interface NotificationReason {
  key: string;
  text: string;
  subText: string;
  isError: boolean;
  callToAction?: NotificationCTA;
}

interface NotificationCTA {
  text: string;
  onClick: () => void;
}

const DFM_REASONS = {
  NonCriticalIssues: 'NonCriticalIssues',
  CriticalIssues: 'CriticalIssues',
};

function collectLineItemReasons(
  lineItem: CartLineItemWithChecks,
  autoquotableErrors: string[]
): NotificationReason[] {
  const reasons: NotificationReason[] = [];
  const criticalIssuesCount =
    lineItem?.failed_manufacturability_check_counts?.failed_error_checks_count || 0;
  const nonCriticalIssuesCount =
    lineItem?.failed_manufacturability_check_counts?.failed_warning_checks_count || 0;
  const process = lineItem.process_name || 'The selected manufacturing process';

  if (hasInvalidFileType(autoquotableErrors)) {
    reasons.push({
      key: AutoquoteErrors.InvalidFileType,
      text: 'This item has an unsupported file type',
      subText: `${process} requires a different file type. Please remove this part and add it again in a supported format.`,
      isError: false,
    });
  }

  if (hasMissingInfo(autoquotableErrors)) {
    reasons.push({
      key: AutoquoteErrors.Incomplete,
      text: 'This item has missing info',
      subText: 'Edit your manufacturing specs to complete all required fields.',
      isError: false,
    });
  }

  if (manufacturabilityChecksIncomplete(lineItem)) {
    reasons.push({
      key: AutoquoteErrors.ManufacturabilityChecksDidNotRun,
      text: 'This item has incomplete DFM checks',
      subText: 'Please request a manual quote so we can take a closer look.',
      isError: false,
    });
  }
  if (hasCriticalIssues(lineItem)) {
    reasons.push({
      key: DFM_REASONS.CriticalIssues,
      text: `This item has ${criticalIssuesCount} ${pluralize(
        'critical issue',
        criticalIssuesCount > 1
      )}`,
      subText: 'View in detail to see our suggested fixes.',
      isError: true,
    });
  } else if (hasNonCriticalIssues(lineItem)) {
    reasons.push({
      key: DFM_REASONS.NonCriticalIssues,
      text: `This item has ${nonCriticalIssuesCount} ${pluralize(
        'non-critical issue',
        nonCriticalIssuesCount > 1
      )}`,
      subText: 'View in detail to see our suggested fixes.',
      isError: false,
    });
  }

  return reasons;
}

/** Components */
export const LineItemNotificationList = ({
  autoquotableErrors,
  lineItem,
  onPushStudio,
  redirectState,
  readonly = false,
}: Props) => {
  const history = useHistory();

  // Collect the reasons in order and attach "callToAction" metadata as needed
  // this metadata is added here since it is component / routing specific logic
  const reasons = collectLineItemReasons(lineItem, autoquotableErrors).map(reason => {
    if (
      reason.key === DFM_REASONS.CriticalIssues ||
      reason.key === DFM_REASONS.NonCriticalIssues
    ) {
      reason.callToAction = {
        text: 'See all issues',
        onClick: () => {
          if (readonly) return;
          onPushStudio();
          history.push(`/studio/evaluate/${lineItem.part.id}/dfm`, {
            cart_line_item_id: redirectState.cart_line_item_id,
            manufacturing_process_id: redirectState.manufacturing_process_id,
            material_id: redirectState.material_id,
            color_id: redirectState.color_id,
            quantity: redirectState.quantity,
          });
        },
      };
    } else if (reason.key === AutoquoteErrors.Incomplete) {
      reason.callToAction = {
        text: 'Manufacturing specs',
        onClick: () => {
          if (readonly) return;

          history.push(`/part-config/${redirectState.cart_line_item_id}`);
        },
      };
    }
    return reason;
  });

  if (reasons.length === 0) return null;

  return (
    <ul className="w-100 px-0 mb-0">
      {reasons.map(reason => (
        <LineItemNotification
          key={reason.key}
          text={reason.text}
          subText={reason.subText}
          isError={reason.isError}
          callToAction={reason.callToAction}
        />
      ))}
    </ul>
  );
};

const LineItemNotification = ({ text, subText, isError, callToAction }: NotificationReason) => {
  const textColor = isError ? 'text-error-300' : 'text-warning-300';
  return (
    <li className="list-none bg-transparent border-coolGray-700 border-2 border-solid p-2 rounded-[8px] flex flex-row items-start mb-0.5 text-xs">
      <IconFont name="error-triangle" className={classNames(['text-2xl mr-2', textColor])} />
      <div>
        <p className={classNames(['mb-0', textColor])}>{text}</p>
        <p className="text-white">{subText}</p>
      </div>
      {callToAction && (
        <Button className="ml-auto" size="sm" onClick={() => callToAction.onClick()}>
          {callToAction.text}
        </Button>
      )}
    </li>
  );
};
