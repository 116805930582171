import React from 'react';

import { Modal } from 'portal/components';

interface SubscriptionDescriptionModalProps {
  action: JSX.Element;
  onActionHandler?: () => void;
}

const SubscriptionDescriptionModal = ({
  action,
  onActionHandler,
}: SubscriptionDescriptionModalProps) => {
  return (
    <Modal className="max-w-lg" action={action} backdrop={true} onActionHandler={onActionHandler}>
      {({ toggle }: { toggle: () => void }) => (
        <Modal.Body className="p-4 pt-0">
          <Modal.Header className="!pl-0" onClose={toggle}>
            <h3 className="border-b border-coolGray-600 border-solid border-x-0 border-t-0 pb-1 pt-4">
              What&#39;s Fast Radius Pro?
            </h3>
          </Modal.Header>
          <p className="text-base text-coolGray-300">
            Fast Radius Pro gives you an expanding set of digital manufacturing tools. Sign up for
            free Early Access and be the first to get premium DFM insights, compare more materials
            and processes, share every detail with teammates and more.
          </p>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default SubscriptionDescriptionModal;
