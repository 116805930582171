import React from 'react';

import AuditLog from 'fr-shared/components/common/AuditLog';

interface Props {
  customerId: number;
  orderId: number;
}

const detailEvents = [
  'order.create',
  'order.update',
  'order.update_state',
  'order.add_billable_service_line_item',
  'order.update_billable_service_line_item',
  'order.remove_billable_service_line_item',
  'order_line_item.create',
  'order_line_item.update',
];

export default function OrderActivity({ customerId, orderId }: Props) {
  const auditLogQueryObject = {
    orgId: customerId,
    subject: 'order',
    subjectId: orderId,
  };

  return (
    <AuditLog detailEvents={detailEvents} logHeading="Order" logObject={auditLogQueryObject} />
  );
}
