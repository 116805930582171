import { useTreatments } from '@splitsoftware/splitio-react';
import { useFormikContext } from 'formik';
import { findIndex, get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useLocation } from 'react-router-dom';

import {
  Alert,
  Animated,
  Button,
  Checkbox,
  CostingRequestLink,
  FormField,
  Icon,
  LineItemComment,
  LineItemProcess,
  Link,
  Loading,
  classNames,
} from 'fr-shared/components';
import {
  BUILD_PACK_UI_SUPPORT,
  CR_BUILD_PACK_UI_SUPPORT,
  DISABLE_AUTO_SYNC_OLI_DATE_FIELDS_WITH_PSLI,
} from 'fr-shared/feature_flags';
import { useBoolTimer, useDuplicateLineItem } from 'fr-shared/hooks';
import { inProgressNoticeText } from 'fr-shared/lib/costing_requests';
import { ORDER_LINE_ITEM_STATES, ORDER_STATES } from 'fr-shared/lib/orders';
import { keyboardDown } from 'fr-shared/utils';
import { PART_FILE_EXTENSIONS } from 'fr-shared/utils/files';

import LineItemComparisonBadge from '../../costing_requests/components/LineItemComparisonBadge';
import SupplierCostPartQuantityFields from '../../costing_requests/components/SupplierCostPartQuantityFields';
import { RFQ_ORDER_LINE_ITEM_STATES, SELECTABLE_ORDER_LINE_ITEM_STATES } from '../utils/states';
import OrderLineItemStatePicker from './OrderLineItemStatePicker';
import ShippingInformation from './ShippingInformation';

const ALERT_SHOW_MS = 15000; // 15 seconds

const downloadAllFiles = lineItem => {
  const revisions = get(lineItem, 'part.revisions');
  const supportingDocs = get(lineItem, 'documents');
  const allFiles = revisions.concat(supportingDocs);

  allFiles.forEach((r, index) => {
    setTimeout(url => window.open(url), 1000 * index, r.url);
  });
};

const OrderLineItem = ({
  arrayHelpers,
  changePosition,
  children,
  className,
  duplicatedLineItem,
  fieldPrefix,
  indeterminate,
  index,
  isDropzoneEnabled,
  isSelectable,
  lineItem,
  onSelect,
  readonly,
  showDelete,
  showDuplicate,
  showPreview,
  showStateSelect,
  showSummary,
  buildPackUIEnabled,
}) => {
  const {
    [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag,
    [CR_BUILD_PACK_UI_SUPPORT]: crBuildPackUiSupportFlag,
    [DISABLE_AUTO_SYNC_OLI_DATE_FIELDS_WITH_PSLI]: oliDeadlineInfoFeatureFlag,
  } = useTreatments(
    [
      BUILD_PACK_UI_SUPPORT,
      CR_BUILD_PACK_UI_SUPPORT,
      DISABLE_AUTO_SYNC_OLI_DATE_FIELDS_WITH_PSLI,
    ],
    {
      pr: location.host.split('.')[0],
    }
  );

  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';
  const isDeadlineFeatureFlagEnabled = oliDeadlineInfoFeatureFlag.treatment === 'on';

  const formik = useFormikContext();
  const path = useLocation();
  const costingRequestOli = path.pathname.includes('costing_request');
  const { setFieldValue, values, initialValues } = formik;

  const [droppedFiles, setDroppedFiles] = useState(null);
  const [showEditedAlert, toggleEditedAlert, hasBeenBulkEdited] = useBoolTimer();
  const { duplicateLineItem } = useDuplicateLineItem('line_items', duplicatedLineItem);

  const lineItemIndex = lineItem.id
    ? findIndex(values.line_items, li => li.id === lineItem.id)
    : index;

  const process = buildPackUIEnabled ? lineItem.build_package.processes[0] : lineItem;
  const displayCollapsedBuildPackView =
    buildPackUIEnabled && crBuildPackUiSupportFlag.treatment === 'on' && !lineItem.isOpen;
  const updateLineItemState = () => {
    const selected = lineItem.selected;
    setFieldValue(`line_items.${lineItemIndex}.selected`, !selected);
  };

  const handleCopyLine = () => {
    const closedLineItems = values.line_items.map(line => {
      return { ...line, isOpen: false };
    });
    setFieldValue('line_items', closedLineItems);

    duplicateLineItem(index);

    // hack to scroll to bottom after rerender
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    }, 1);
  };

  const handleRemoveLine = () => {
    if (lineItem.id) {
      if (buildPackUIEnabled) {
        setFieldValue(`line_items.${lineItemIndex}.build_package.deleted_at`, moment());
        setFieldValue(
          `line_items.${lineItemIndex}.build_package.processes.0.deleted_at`,
          moment()
        );
      }
      setFieldValue(`line_items.${lineItemIndex}.deleted_at`, moment());
    } else {
      arrayHelpers.remove(lineItemIndex);
    }
  };

  const renderOLIDeadlineFields = isDeadlineFeatureFlagEnabled => {
    let commitDate = null;
    let promisedShipDate = null;

    if (isDeadlineFeatureFlagEnabled) {
      commitDate = lineItem.effective_commit_date;
      promisedShipDate = lineItem.effective_promised_ship_date;
    } else {
      commitDate = lineItem.commit_date;
      promisedShipDate = lineItem.promised_ship_date;
    }

    return (
      <div>
        {commitDate && (
          <div className="line-item-data">
            <div>Commit Date</div>
            <span>{moment(commitDate).format('MM/DD/YYYY')}</span>
          </div>
        )}
        {promisedShipDate && (
          <div className="line-item-data">
            <div>Promised Ship Date</div>
            <span>{moment(promisedShipDate).format('MM/DD/YYYY')}</span>
          </div>
        )}
      </div>
    );
  };

  const handleLineItemSelect = () => {
    if (onSelect) onSelect(lineItem);
    updateLineItemState();
  };

  const handleLineToggle = () => {
    setFieldValue(`line_items.${lineItemIndex}.isOpen`, !lineItem.isOpen);
  };

  const isExistingOrder = !!(initialValues && initialValues.id);

  const canSelectLineItem =
    (isSelectable && !readonly) ||
    (crBuildPackUiSupportFlag.treatment === 'on' && buildPackUIEnabled && readonly);
  const isStateReadOnly = readonly || lineItem.state === ORDER_LINE_ITEM_STATES.ActionRequired;

  const workOrderLineItems = lineItem.work_order_line_items;
  const hasWorkOrderLineItems = workOrderLineItems && workOrderLineItems.length > 0;

  const screenshot = get(lineItem, 'part.current_revision.screenshot');

  const selectableLineItemStates =
    values.state !== ORDER_STATES.CustomerOrderPlaced
      ? SELECTABLE_ORDER_LINE_ITEM_STATES.filter(
          lineItemState => !RFQ_ORDER_LINE_ITEM_STATES.includes(lineItemState)
        )
      : SELECTABLE_ORDER_LINE_ITEM_STATES;
  useEffect(() => {
    if (lineItem.bulk_edit_applied) {
      toggleEditedAlert(ALERT_SHOW_MS);
      setFieldValue(`line_items.${lineItemIndex}.bulk_edit_applied`, undefined);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [lineItem.bulk_edit_applied, lineItemIndex, toggleEditedAlert]);

  // used in filtering
  if (lineItem.deleted_at || lineItem.hidden) return null;

  const orderLineItemPartData = (
    <>
      <span className="badge badge-indicator badge-light mr-2">
        {lineItem.id && `ID: ${lineItem.id}`}
      </span>
      {!lineItem.part && !lineItem.description && (
        <Alert color="danger" className="mb-0">
          This {lineItemName} requires a part
        </Alert>
      )}
      <div>
        <strong data-testid={`${lineItem.description}-${index}`}>{lineItem.description}</strong>
        {!isStateReadOnly && lineItem.state && (
          <span
            className="badge badge-indicator badge-light ml-2"
            data-testid="costing-request-page--crli-status-badge"
          >
            {lineItem.state}
          </span>
        )}
        <LineItemComment lineItem={lineItem} />
      </div>
      <CostingRequestLink
        className="mx-3"
        costingRequestId={lineItem.costing_request_id}
        lineItemId={lineItem.id}
      />
      {lineItem.in_progress && (
        <span className="ml-2 px-2 badge badge-pill badge-dark font-size-md">
          In Progress{' '}
          <Icon tooltip={inProgressNoticeText(lineItem)} name="question-circle"></Icon>
        </span>
      )}
    </>
  );

  const lineItemProcess = (
    <>
      {showSummary && (
        <div className="mr-3">
          <LineItemProcess data={process} />
        </div>
      )}
    </>
  );

  const orderLineItemDetails = (
    <>
      {showPreview && lineItem.id && lineItem.order_id && lineItem.upload_status !== 'loading' && (
        <div className="line-item-body">
          <div className="line-item-meta mb-1">
            {renderOLIDeadlineFields(isDeadlineFeatureFlagEnabled)}
            <div>
              {lineItem.actual_ship_date && (
                <div className="line-item-data">
                  <div>Actual Ship Date</div>
                  <span>{moment(lineItem.actual_ship_date).format('MM/DD/YYYY')}</span>
                </div>
              )}
              {lineItem.customer_po && (
                <div className="line-item-data">
                  <div>Customer PO</div>
                  <span>{lineItem.customer_po}</span>
                </div>
              )}
            </div>
            <div>
              {lineItem.work_order_line_items && lineItem.work_order_line_items.length > 0 && (
                <div className="line-item-data">
                  <div>Work Orders</div>
                  <span>{lineItem.work_order_line_items.length}</span>
                </div>
              )}
            </div>
          </div>
          <div className="flex ml-auto">
            <div className="flex align-items-center mr-3">
              <div className="font-size-lg">
                <LineItemProcess data={lineItem} />
              </div>
            </div>
            {lineItem.quantity && (
              <div className="flex align-items-center font-size-sm">
                <div className="text-muted mr-2">QTY</div>
                <h3 className="mb-0">{lineItem.quantity}</h3>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
  return (
    <Dropzone
      accept={PART_FILE_EXTENSIONS}
      key={index}
      onDrop={files => {
        setDroppedFiles(files);
        handleLineToggle();
      }}
      multiple={false}
      disabled={readonly || !isDropzoneEnabled}
    >
      {({ getRootProps, isDragActive }) => (
        <div
          {...getRootProps()}
          className={classNames([
            'card',
            'card-border',
            'card-shadow',
            'mb-2',
            {
              'is-animating': lineItem.upload_status === 'loading',
              'is-finished': lineItem.upload_status === 'success',
              'is-invalid': lineItem.upload_status === 'error',
              'is-selected': lineItem.selected,
              'is-dragging': isDragActive,
            },
            className,
          ])}
          data-testid={`line_items.${index}`}
        >
          <div className="line-item-wrapper">
            <div className="line-item">
              {canSelectLineItem && (
                <Checkbox
                  name={`line_items.${index}.selected`}
                  onChange={handleLineItemSelect}
                  className="mb-0 mr-1 align-text-bottom"
                  indeterminate={indeterminate}
                  value={lineItem.selected || false}
                />
              )}
              <div
                className="flex flex-1 align-items-center"
                onClick={handleLineItemSelect}
                onDoubleClick={handleLineToggle}
                onKeyDown={evt => keyboardDown(evt, 'Enter', handleLineItemSelect)}
                role="button"
                tabIndex={0}
              >
                {screenshot && (
                  <div className="mr-2">
                    <Link to={`/admin/viewer/order_line_item/${lineItem.id}`} openNewWindow>
                      <img
                        width={40}
                        height={40}
                        className="rounded"
                        src={screenshot}
                        alt={lineItem.part.name}
                      />
                    </Link>
                  </div>
                )}

                {showSummary &&
                buildPackUIEnabled &&
                crBuildPackUiSupportFlag.treatment === 'on' ? (
                  <div className="line-item-body">
                    <div className="line-item-meta">
                      <div className="line-item-build-pack-data">
                        <div>Build pack id</div>
                        <span>{lineItem.id && ` ${lineItem.build_package?.id}`}</span>
                      </div>
                      {!lineItem.part && !lineItem.description && (
                        <Alert color="danger" className="mb-0">
                          This line item requires a part
                        </Alert>
                      )}
                      <div className="line-item-build-pack-data">
                        <div>Part name</div>
                        <span>{lineItem.id && ` ${lineItem.description}`}</span>
                      </div>
                      {process.manufacturing_process?.name && (
                        <div className="line-item-build-pack-data">
                          <div>Process</div>
                          <span>{lineItem.id && ` ${process.manufacturing_process.name}`}</span>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  orderLineItemPartData
                )}
                {lineItem.duplicated_from_id && (
                  <span
                    onClick={e => e.stopPropagation()}
                    onDoubleClick={e => e.stopPropagation()}
                    onKeyDown={e => e.stopPropagation()}
                    role="button"
                    tabIndex={0}
                  >
                    <LineItemComparisonBadge
                      lineItem={lineItem}
                      duplicatedFromId={lineItem.duplicated_from_id}
                    />
                  </span>
                )}
              </div>
              <div className="ml-auto align-items-center pl-3">
                {lineItem.upload_status === 'loading' && (
                  <div className="text-primary mr-2">
                    <Loading type="circle" className="mr-2" width={16} />
                    Uploading part
                  </div>
                )}
                {lineItem.upload_status === 'success' && (
                  <Animated animationIn="fadeIn" animationOut="fadeOutUp" timeout={6000}>
                    <div className="text-success mr-2">
                      <Icon name="check" className="text-success mr-2" />
                      Part upload success
                    </div>
                  </Animated>
                )}
                {lineItem.upload_status === 'error' && (
                  <Animated animationIn="fadeIn" animationOut="fadeOutUp" timeout={6000}>
                    <div className="text-danger mr-2">
                      <Icon name="exclamation-triangle" className="text-danger mr-2" />
                      Part upload failed!
                    </div>
                  </Animated>
                )}
                {hasBeenBulkEdited && (
                  <Animated
                    animationIn="fadeIn"
                    animationOut="fadeOutUp"
                    isVisible={showEditedAlert}
                  >
                    <div className="text-success mr-2">
                      <Icon name="check" className="text-success mr-2" />
                      Edit applied
                    </div>
                  </Animated>
                )}
                {buildPackUIEnabled && crBuildPackUiSupportFlag.treatment === 'on' ? (
                  <>
                    <div className="line-item-body">
                      <div className="line-item-meta">
                        <span className="badge badge-indicator badge-build-pack ml-2">
                          {formik.values.state}
                        </span>
                      </div>
                    </div>
                    {showSummary && (
                      <div className="line-item-body">
                        <div className="line-item-meta">
                          <div>
                            <div className="line-item-build-pack-data">
                              <CostingRequestLink
                                className="mx-3"
                                costingRequestId={lineItem.costing_request_id}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  lineItemProcess
                )}
                {isExistingOrder &&
                  showStateSelect &&
                  (isStateReadOnly ? (
                    <FormField
                      name={`line_items.${index}.state`}
                      className="line-item-state"
                      component="select"
                      readonly={isStateReadOnly}
                    >
                      <option value="" />
                      {selectableLineItemStates.map(state => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </FormField>
                  ) : (
                    <OrderLineItemStatePicker
                      lineItemIndex={index}
                      selectableLineItemStates={selectableLineItemStates}
                    />
                  ))}
                {!readonly && arrayHelpers && (
                  <>
                    {isExistingOrder &&
                      (get(lineItem, 'part.current_revision') ||
                        lineItem.documents?.length > 0) && (
                        <Button
                          onClick={() => downloadAllFiles(lineItem)}
                          openNewWindow
                          className="mr-2"
                          tooltip={`Download all files associated with this ${lineItemName}`}
                        >
                          <Icon name="file-download" />
                        </Button>
                      )}
                    {showDuplicate && (
                      <Button
                        name={`line_items.${index}.copy_line`}
                        className="mr-2"
                        tooltip={`Duplicate ${lineItemName}`}
                        onClick={handleCopyLine}
                        color="primary"
                        dataTestId={`duplicate-${index}`}
                      >
                        <Icon name="copy" />
                      </Button>
                    )}
                    {changePosition && lineItem.state === 'Draft' && (
                      <>
                        <Button
                          className="border mr-2"
                          color="light"
                          onClick={() => changePosition('UP', lineItem)}
                        >
                          <Icon name="arrow-up" />
                        </Button>
                        <Button
                          className="border mr-2"
                          color="light"
                          onClick={() => changePosition('DOWN', lineItem)}
                        >
                          <Icon name="arrow-down" />
                        </Button>
                      </>
                    )}
                    {showDelete && (
                      <Button
                        color="danger"
                        outline={true}
                        className="mr-2"
                        dataTestId={`delete-${index}`}
                        disabled={hasWorkOrderLineItems}
                        tooltip={
                          hasWorkOrderLineItems
                            ? `A ${lineItemName} can't be deleted if it's associated with a work order`
                            : `Remove ${lineItemName}`
                        }
                        onClick={handleRemoveLine}
                      >
                        <Icon name="times" />
                      </Button>
                    )}
                  </>
                )}
                <Button
                  id="line-item-toggle"
                  className="border"
                  color="light"
                  dataTestId={`expand-${index}`}
                  onClick={handleLineToggle}
                >
                  <Icon name={`chevron-${lineItem.isOpen ? 'up' : 'down'} `} />
                </Button>
              </div>
            </div>
            {/* TODO: Make this a separate component */}
            {displayCollapsedBuildPackView ? (
              <>
                <div className="m-3">
                  <h4>Build pack details</h4>
                </div>
                <div className="line-item-collapsed-data">
                  {formik.values.created_by?.name && (
                    <div>
                      Created by
                      <span className="line-item-span">{formik.values.created_by.name}</span>
                    </div>
                  )}
                  <div>
                    Created on{' '}
                    <span className="line-item-span">
                      {moment(formik.values.inserted_at).format('DD MMM YYYY')}
                    </span>
                  </div>
                  {lineItem.part?.source && (
                    <div>
                      Source <span className="line-item-span">{lineItem.part.source}</span>
                    </div>
                  )}
                </div>
                <div className="line-item-collapsed-data line-item-bottom-row">
                  {process.material?.name && (
                    <div>
                      Material <span className="line-item-span">{process.material.name}</span>
                    </div>
                  )}
                  {process.finish?.name && (
                    <div>
                      Finish <span className="line-item-span">{process.finish.name}</span>
                    </div>
                  )}
                  {process.color && (
                    <div>
                      Color <span className="line-item-span">{process.color.name}</span>
                    </div>
                  )}
                  {process.inspection_type?.name && (
                    <div>
                      Inspection type{' '}
                      <span className="line-item-span">{process.inspection_type.name}</span>
                    </div>
                  )}
                </div>
                {lineItem.state === 'Draft' && (
                  <div>
                    <SupplierCostPartQuantityFields
                      isStacked={false}
                      fieldPrefix={fieldPrefix}
                      supplierCostIndex={0}
                      readonly={false}
                    />
                  </div>
                )}
              </>
            ) : (
              orderLineItemDetails
            )}
          </div>
          {!lineItem.isOpen && !costingRequestOli && (
            <div className="p-1 border-top">
              <ShippingInformation
                fromShippingTab={false}
                isExistingOrder={isExistingOrder}
                lineItem={lineItem}
                oliIndex={index}
              />
            </div>
          )}
          {lineItem.isOpen && React.cloneElement(children, { droppedFiles: droppedFiles })}
        </div>
      )}
    </Dropzone>
  );
};

OrderLineItem.defaultProps = {
  isDropzoneEnabled: true,
  showDelete: true,
  showDuplicate: true,
  showPreview: true,
  showStateSelect: true,
  showSummary: false,
  buildPackUIEnabled: false,
};

OrderLineItem.propTypes = {
  arrayHelpers: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  changePosition: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  data: PropTypes.object,
  duplicatedLineItem: PropTypes.object,
  fieldPrefix: PropTypes.string,
  indeterminate: PropTypes.bool,
  index: PropTypes.number,
  isDropzoneEnabled: PropTypes.bool,
  isSelectable: PropTypes.bool,
  lineItem: PropTypes.object,
  onSelect: PropTypes.func,
  readonly: PropTypes.bool,
  showDelete: PropTypes.bool,
  showDuplicate: PropTypes.bool,
  showPreview: PropTypes.bool,
  showStateSelect: PropTypes.bool,
  showSummary: PropTypes.bool,
  buildPackUIEnabled: PropTypes.bool,
};

export default OrderLineItem;
