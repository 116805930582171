import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { IconFont } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';

import { Button, EmptyPartsCard } from 'portal/components';

import CartLineItemCard from './CartLineItemCard';
import { CartNotificationList } from './CartNotificationList';

const CartDetailsBody = ({ cart, readonly = false, refreshCart }) => {
  const { line_items } = cart;
  const hasNoLineItems = isEmpty(line_items);
  const userAnalytics = useUserAnalyticsContext();
  const handleAddPart = () => userAnalytics.track(`Add Part - Quotes`, { valid: true });

  return (
    <div className="py-5 font-size-md">
      <CartNotificationList cart={cart} />
      <div className="mb-5 flex flex-row align-items-center justify-content-between">
        <h3 data-testid="cart-details-body-items-header">
          Items {line_items ? `(${line_items.length})` : ''}
        </h3>
        <div>
          <Button to="/add-part" size="sm" outline onClick={handleAddPart} disabled={readonly}>
            <IconFont name="plus" /> Add new part
          </Button>
        </div>
      </div>
      {hasNoLineItems ? (
        <EmptyPartsCard
          readonly={readonly}
          mainText="You have no items in your quote. Start by uploading a part."
        />
      ) : (
        <TransitionGroup>
          {line_items.map((lineItem, index) => {
            return (
              <CSSTransition key={lineItem.id} classNames="opacity" timeout={300}>
                <CartLineItemCard
                  key={`line_item_card_${index}`}
                  lineItem={lineItem}
                  readonly={readonly}
                  refreshCart={refreshCart}
                />
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      )}
    </div>
  );
};

CartDetailsBody.propTypes = {
  cart: PropTypes.shape({
    line_items: PropTypes.array,
  }),
  refreshCart: PropTypes.func,
  readonly: PropTypes.bool,
};

export default CartDetailsBody;
