import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Select from 'react-select';

import { materialType } from './utils/WorkOrdersFiltersTypes';

const WorkOrdersMaterialsFilter = ({ availableMaterials, onSelectMaterial, materials }) => {
  const onChange = useMemo(() => {
    return (selected, _event_details) => {
      const e = { target: { value: selected ? selected.map(material => material.value) : [] } };
      onSelectMaterial(e);
    };
  }, [onSelectMaterial]);

  const options = (availableMaterials || []).map(material => {
    return { label: material.name, value: material.name };
  });

  const value = materials ? options.filter(option => materials.includes(option.label)) : [];

  return (
    <div className="form-group">
      <label htmlFor="material-filter">Material</label>
      <Select
        className="mb-2"
        closeMenuOnSelect={false}
        delimiter={','}
        id="material-filter"
        isMulti={true}
        name="material-filter"
        onChange={onChange}
        options={options}
        placeholder={options.length > 0 ? `Example: ${options[0].label}` : 'Select a process'}
        value={value}
      />
    </div>
  );
};

WorkOrdersMaterialsFilter.propTypes = {
  availableMaterials: PropTypes.arrayOf(materialType),
  onSelectMaterial: PropTypes.func,
  materials: PropTypes.arrayOf(PropTypes.string),
};

export default WorkOrdersMaterialsFilter;
