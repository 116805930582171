import { connect } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { api } from 'fr-shared/api';
import { Button, Modal } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';

const SendCostingEmailModal = ({ formik }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { setAlert } = useContext(AlertContext);

  const handleSendEmail = () => {
    setIsSending(true);
    api
      .put(`/costing_requests/${formik.values.id}/send_information_email`)
      .then(() => {
        setAlert({ color: 'success', message: 'Email Sent to Costing Team' });
      })
      .catch(() => {
        setAlert({ color: 'danger', message: 'Email failed to send' });
      })
      .finally(() => {
        setIsOpen(false);
        setIsSending(false);
      });
  };
  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Confirm Import</Button>
      <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        {({ toggle }) => (
          <>
            <Modal.Header title="Send Email" onClose={toggle} />
            <Modal.Body>
              This will send an email with the costing request information bundled in a CSV to the
              appropriate costing team.
              <br />
              <br />
              You will be {"CC'd"}
            </Modal.Body>
            <Modal.Footer>
              <Button color="secondary" onClick={toggle} loading={isSending}>
                Cancel
              </Button>
              <Button color="primary" onClick={handleSendEmail} loading={isSending}>
                Confirm
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

SendCostingEmailModal.propTypes = {
  formik: PropTypes.object,
};

const idIsSame = (prevProps, nextProps) => {
  prevProps.formik.values.id !== nextProps.formik.values.id;
};

export default connect(React.memo(SendCostingEmailModal, idIsSame));
