import { range } from 'lodash';
import React from 'react';

import { IconFont } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';

import { classNames } from 'portal/components';

import styles from './Pagination.module.css';

interface PaginationProps {
  page: number;
  totalPages: number;
  setPage: (pageNumber: number) => void;
  prevPageButton?: React.ReactNode;
  nextPageButton?: React.ReactNode;
  pageNumberStyle?: typeof PaginationTypes[keyof typeof PaginationTypes];
  children?: React.ReactNode;
  showArrows?: boolean;
  className?: string;
  enabledChevronClassName?: string;
  activePaginationCircleClassName?: string;
  inactivePaginationCircleClassName?: string;
  analyticsEventOnClick?: string;
}

export const PaginationTypes = {
  NUMBERED: 'numbered',
  CIRCULAR: 'circular',
  HIDDEN: 'hidden',
};

export const Pagination = ({
  page,
  totalPages,
  setPage,
  pageNumberStyle = PaginationTypes.NUMBERED,
  prevPageButton,
  nextPageButton,
  children,
  showArrows = true,
  className,
  enabledChevronClassName,
  activePaginationCircleClassName,
  inactivePaginationCircleClassName,
  analyticsEventOnClick,
}: PaginationProps) => {
  const userAnalytics = useUserAnalyticsContext();

  const setPageToNext = () => {
    const nextPage = page + 1;
    if (nextPage <= totalPages) {
      setPage(nextPage);
    }
    if (analyticsEventOnClick) {
      userAnalytics.track(analyticsEventOnClick);
    }
  };

  const setPageToPrevious = () => {
    const previousPage = page - 1;
    if (previousPage > 0) {
      setPage(previousPage);
    }
    if (analyticsEventOnClick) {
      userAnalytics.track(analyticsEventOnClick);
    }
  };
  const PageNumberButton = ({ pageNumber }: { pageNumber: number }) => {
    return (
      <button
        onClick={() => {
          setPage(pageNumber);
          if (analyticsEventOnClick) {
            userAnalytics.track(analyticsEventOnClick);
          }
        }}
        className={classNames([
          styles.PaginationButton,
          styles.PageButton,
          pageNumber === page && styles.active,
        ])}
        data-testid={`pagination-page-${pageNumberStyle}`}
      >
        <div
          className={classNames([
            pageNumber === page && styles.active,
            pageNumber === page
              ? activePaginationCircleClassName
              : inactivePaginationCircleClassName,
          ])}
        >
          {pageNumberStyle === PaginationTypes.NUMBERED && pageNumber}
        </div>
      </button>
    );
  };

  const renderCenterButtons = () => {
    if (page - 3 <= 1) {
      return (
        <>
          {range(2, 6).map((pageIndex: number) => (
            <li key={pageIndex}>
              <PageNumberButton pageNumber={pageIndex} />
            </li>
          ))}
          <div className={styles.Ellipsis}>...</div>
        </>
      );
    } else if (page + 3 >= totalPages) {
      return (
        <>
          <div className={styles.Ellipsis}>...</div>
          {range(totalPages - 4, totalPages).map((pageIndex: number) => (
            <li key={pageIndex}>
              <PageNumberButton pageNumber={pageIndex} />
            </li>
          ))}
        </>
      );
    } else {
      return (
        <>
          <div className={styles.Ellipsis}>...</div>
          {range(page - 1, page + 2).map((pageIndex: number) => (
            <li key={pageIndex}>
              <PageNumberButton pageNumber={pageIndex} />
            </li>
          ))}
          <div className={styles.Ellipsis}>...</div>
        </>
      );
    }
  };

  return (
    <>
      {children && children}
      <nav className={classNames(className)}>
        <ul className={classNames([styles.Pagination, styles[pageNumberStyle]])}>
          {prevPageButton ? (
            <li>{prevPageButton}</li>
          ) : (
            showArrows && (
              <li>
                <button
                  onClick={setPageToPrevious}
                  aria-disabled={page === 1}
                  className={classNames([
                    styles.PaginationButton,
                    styles.Arrow,
                    page === 1 && styles.disabled,
                  ])}
                  data-testid="pagination-arrow-left"
                >
                  <IconFont
                    name="chevron-left"
                    className={classNames(page !== 1 && enabledChevronClassName)}
                  />
                </button>
              </li>
            )
          )}
          {pageNumberStyle === PaginationTypes.HIDDEN ? null : totalPages < 8 ||
            pageNumberStyle === PaginationTypes.CIRCULAR ? (
            range(1, totalPages + 1).map((pageIndex: number) => (
              <li key={pageIndex}>
                <PageNumberButton pageNumber={pageIndex} />
              </li>
            ))
          ) : (
            <>
              <li>
                <PageNumberButton pageNumber={1} />
              </li>
              {renderCenterButtons()}
              <li>
                <PageNumberButton pageNumber={totalPages} />
              </li>
            </>
          )}
          {nextPageButton ? (
            <li>{nextPageButton}</li>
          ) : (
            showArrows && (
              <li>
                <button
                  onClick={setPageToNext}
                  aria-disabled={page === totalPages}
                  className={classNames([
                    styles.PaginationButton,
                    styles.Arrow,
                    page === totalPages && styles.disabled,
                  ])}
                  data-testid="pagination-arrow-right"
                >
                  <IconFont
                    name="chevron-right"
                    className={classNames(page !== totalPages && enabledChevronClassName)}
                  />
                </button>
              </li>
            )
          )}
        </ul>
      </nav>
    </>
  );
};

export default Pagination;
