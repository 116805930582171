import { connect } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { FormCheckbox, FormField, FormSelect } from 'fr-shared/components';
import { DLS_SMART_WASH, WORK_ORDER_STATES } from 'fr-shared/lib/work_orders';

import {
  BuildTimeFormField,
  CarbonLinkFormField,
  CassetteFormField,
  MPILinkFormField,
  MaterialAmountFormField,
  PrintIdFormField,
  PrinterFormField,
} from './fields';

const WorkOrderDetailsDLS = ({ formik, isEditing, readonly }) => {
  const { state } = formik.values;

  return (
    <>
      <div className="card-body p-0">
        <div className="row border-bottom py-3 m-0">
          <div className="col-md">
            {isEditing && state !== 'Draft' && (
              <FormSelect
                label="State"
                name="state"
                className="mb-0"
                optionList={WORK_ORDER_STATES}
                valueAccessor={option => option}
                nameAccessor={option => option}
                readonly={readonly}
              />
            )}
          </div>
          <div className="col-md d-inline">
            <CarbonLinkFormField showCarbonLink={isEditing} readonly={readonly} />
          </div>
        </div>

        <div className="row py-3 m-0">
          <div className="col-lg">
            <MaterialAmountFormField readonly={readonly} showGrams={isEditing} />
            <BuildTimeFormField />
            <MPILinkFormField readonly={readonly} />
          </div>
          <div className="col-lg">
            <CassetteFormField readonly={readonly} />
            <FormSelect
              name="smart_wash"
              label="Smart Wash"
              optionList={DLS_SMART_WASH}
              nameAccessor={option => option}
              valueAccessor={option => option}
            />
            <FormField name="post_process_wash" label="Post Process Wash" />
            <FormField name="oven_notes" label="Oven Notes" component="textarea" />
            <FormCheckbox
              name="requires_release_film"
              label="Requires Release Film"
              readonly={readonly}
            />
            <FormCheckbox
              name="requires_salt_bake"
              label="Requires Salt Bake"
              readonly={readonly}
            />
            {state === 'Draft' && (
              <FormField
                name="num_of_build_plates"
                type="Number"
                label="# of Build Plates"
                readonly={readonly}
              />
            )}
          </div>
        </div>

        <div className="row border-bottom border-top py-3 m-0">
          <div className="col-lg">
            <FormField
              name="notes"
              label="Notes"
              md="2"
              className="mb-0"
              component="textarea"
              inputStyle={{ minHeight: 100 }}
              readonly={readonly}
            />
          </div>
        </div>

        <div className="row py-3 m-0">
          <div className="col-md">
            {isEditing && <PrinterFormField readonly={readonly} />}

            <div className="row form-group flex">
              <fieldset>
                <div className="col-md-4">
                  <legend>Lot</legend>
                </div>
                <div className="col-md flex justify-content-around">
                  <FormField
                    name="material_lot_number_a"
                    prepend="A"
                    className="mr-3"
                    readonly={readonly}
                  />
                  <FormField name="material_lot_number_b" prepend="B" readonly={readonly} />
                </div>
              </fieldset>
            </div>
          </div>
          <div className="col-md">
            <PrintIdFormField showPrintIdLink={isEditing} readonly={readonly} />
          </div>
        </div>
      </div>
    </>
  );
};

WorkOrderDetailsDLS.propTypes = {
  buildCarts: PropTypes.array,
  formik: PropTypes.object,
  getMaterialGrams: PropTypes.func,
  getMaterialName: PropTypes.func,
  hasProcess: PropTypes.bool,
  isEditing: PropTypes.bool,
  readonly: PropTypes.bool,
};

export default connect(WorkOrderDetailsDLS);
