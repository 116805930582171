import { useQuery } from 'react-query';

import { api } from 'fr-shared/api';

const fetchPlans = (): Promise<Plan[]> => {
  return api.get('/saas/plans').then(res => res.data);
};

const usePlans = () => {
  return useQuery<Plan[], Error>(['plans'], () => fetchPlans(), {
    refetchOnWindowFocus: false,
    staleTime: 2000,
  });
};

export default usePlans;
