import { useFormikContext, withFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropType from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import QrReader from 'react-qr-reader';

import { AlertContext } from 'fr-shared/context';

import WorkOrderStageGateModal from './WorkOrderStageGateModal';

const WorkOrderStateUpdateForm = ({ state, canScan, handleSubmit }) => {
  const formik = useFormikContext();
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const { setAlert } = useContext(AlertContext);

  /**
   * Open the error modal when formik has errors
   */
  useEffect(() => {
    if (!isEmpty(formik.errors)) {
      setErrorModalIsOpen(true);
    }
  }, [formik.errors]);

  const handleScan = data => {
    if (!data || !canScan || !state || !isEmpty(formik.errors)) {
      return null;
    }

    try {
      const { work_order_id } = JSON.parse(data);
      formik.setValues({ state: state, id: work_order_id });
      handleSubmit();
    } catch (e) {
      setAlert({ color: 'danger', message: 'QR code is invalid' });
    }
  };

  const handleQRReaderError = () => {
    setAlert({
      color: 'danger',
      message: 'QRReader failed to load. Please reload the page and try again.',
    });
  };

  const handleErrorModalSubmit = () => {
    setErrorModalIsOpen(false);
    handleSubmit();
  };

  return (
    <div className={state ? '' : 'd-none'}>
      <WorkOrderStageGateModal
        isOpen={errorModalIsOpen}
        toggle={() => setErrorModalIsOpen(false)}
        onSubmit={handleErrorModalSubmit}
      />
      <QrReader
        delay={500}
        onScan={handleScan}
        onError={handleQRReaderError}
        style={{ width: '100%' }}
      />
    </div>
  );
};

WorkOrderStateUpdateForm.propTypes = {
  state: PropType.string,
  canScan: PropType.bool,
  handleSubmit: PropType.func,
};

export default withFormik({
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, formik) => {
    const { props } = formik;
    props.onSubmit && props.onSubmit(values, formik);
  },
})(WorkOrderStateUpdateForm);
