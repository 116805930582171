import React, { useContext } from 'react';

import { Button, DocumentsUpload, classNames } from 'fr-shared/components';
import { DocumentsContext } from 'fr-shared/context';

interface UploadContainerProps {
  hasError?: boolean;
}

const UploadContainer = ({ hasError }: UploadContainerProps) => {
  const { acceptedFileTypes, isUploading, maxSizeInBytes, readonly } =
    useContext(DocumentsContext);

  if (readonly) {
    return null;
  }

  const showMaxSize = typeof maxSizeInBytes === 'number';
  const showFileTypes = acceptedFileTypes?.length > 0;

  return (
    <div
      className={classNames([
        'border',
        {
          'border-error': hasError,
          'mb-1': !hasError,
          rounded: !hasError,
          'rounded-top': hasError,
        },
      ])}
    >
      {(showMaxSize || showFileTypes) && (
        <div className="px-2 py-1 border-bottom flex flex-row text-secondary font-size-xs justify-content-between">
          {showFileTypes && <div>Accepted file types: {acceptedFileTypes.join(', ')}</div>}
          {showMaxSize && <div>Max file size: {maxSizeInBytes / 1000000}MB</div>}
        </div>
      )}

      <DocumentsUpload>
        <div className="flex flex-row justify-content-center py-3">
          <p className="mr-2 mt-2 text-secondary">Drag and drop file or</p>

          <div className="mt-1">
            <Button className="px-2 py-1" color="primary" loading={isUploading}>
              Select from computer
            </Button>
          </div>
        </div>
      </DocumentsUpload>
    </div>
  );
};

export default React.memo(UploadContainer);
