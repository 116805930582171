import { Form, useFormikContext } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, FormField, FormSelect } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { useManufacturingProcess } from 'fr-shared/hooks';

import TwoDFileDocuments from '../TwoDFileDocuments/TwoDFileDocuments';
import styles from './RequestCallForm.module.css';

const RequestCallForm = () => {
  const { data: manufacturingProcesses } = useManufacturingProcess({ publiclyAvailable: true });
  const optionList = manufacturingProcesses?.map(({ name }) => ({ name, value: name }));
  const { location } = useHistory();
  const userAnalytics = useUserAnalyticsContext();

  const trackEvent = eventName => {
    const prefix = location.pathname.includes('2d-files') ? '2D File' : 'Request Call';
    userAnalytics.track(`Create Quote - ${prefix} ${eventName}`);
  };

  const { isSubmitting, setFieldValue, values } = useFormikContext();
  const supportingDocs = values.supportingDocuments;

  return (
    <Form className={`${styles.Form} flex flex-fill flex-column`}>
      <p className={styles.Label}>2D files or supporting documents (optional)</p>

      <div className="my-1">
        <TwoDFileDocuments
          onUpload={() => trackEvent('Upload')}
          setFieldValue={setFieldValue}
          supportingDocs={supportingDocs || []}
        />
      </div>

      <p className={styles.Label}>What do you want to build?</p>

      <FormField
        className={`${styles.FormField} mb-3`}
        name="what_do_you_want_to_build"
        required={true}
        stacked={false}
        type="textarea"
        placeholder="Add your answer here..."
        onBlur={e => {
          if (e.target.value) {
            trackEvent('Build Details');
          }
        }}
      />

      <p className={styles.Label}>Which manufacturing process do you have in mind?</p>

      <FormSelect
        className="mt-1"
        floatingLabel={true}
        label="Process"
        name="which_manufacturing_process_did_you_have_in_mind"
        onlyShowErrorIfTouched={false}
        optionList={optionList}
        placeholder={'Please select'}
        required={true}
        sorted={true}
        onBlur={() => trackEvent('Mfg Process Selection')}
      />

      <p className={styles.Label}>Do your parts have any special requirements? (optional)</p>

      <FormField
        className={`${styles.FormField} mb-3`}
        name="do_your_parts_have_any_special_requirements"
        required={false}
        stacked={false}
        type="textarea"
        placeholder="Add your answer here..."
        onBlur={e => {
          if (e.target.value) {
            trackEvent('Special Reqs');
          }
        }}
      />

      <p className={styles.Label}>Any other notes for us? (optional)</p>

      <FormField
        className={`${styles.FormField} mb-3`}
        name="any_other_notes_for_us"
        required={false}
        stacked={false}
        type="textarea"
        placeholder="Add your answer here..."
        onBlur={e => {
          if (e.target.value) {
            trackEvent('Additional Details');
          }
        }}
      />

      <div>
        <Button
          disabled={isSubmitting}
          type="submit"
          onClick={() => trackEvent('Form Submitted')}
        >
          Request your call
        </Button>
      </div>
    </Form>
  );
};

export default RequestCallForm;
