import { snakeCase } from 'lodash';

export const MANUFACTURING_PROCESSES = {
  Assembly: 'Assembly',
  BoundMetalDeposition: 'Bound Metal Deposition',
  CastUrethane: 'Cast Urethane',
  CNC: 'CNC',
  DLS: 'DLS',
  DMLS: 'DMLS',
  FDM: 'FDM',
  InjectionMolding: 'Injection Molding',
  MJF: 'MJF',
  MJFColor: 'MJF Color',
  PolyJet: 'PolyJet',
  SheetMetal: 'Sheet Metal',
  SLA: 'SLA',
  SLS: 'SLS',
};

// Keep in sync with lib/fast_radius/inventory/manufacturing_process.ex
export const EXTERNAL_PROCESSES = [
  MANUFACTURING_PROCESSES.Assembly,
  MANUFACTURING_PROCESSES.CastUrethane,
  MANUFACTURING_PROCESSES.CNC,
  MANUFACTURING_PROCESSES.DMLS,
  MANUFACTURING_PROCESSES.InjectionMolding,
  MANUFACTURING_PROCESSES.PolyJet,
  MANUFACTURING_PROCESSES.SheetMetal,
  MANUFACTURING_PROCESSES.SLA,
  MANUFACTURING_PROCESSES.SLS,
];

export const INTERNAL_PROCESSES = [
  MANUFACTURING_PROCESSES.BoundMetalDeposition,
  MANUFACTURING_PROCESSES.DLS,
  MANUFACTURING_PROCESSES.FDM,
  MANUFACTURING_PROCESSES.MJFColor,
  MANUFACTURING_PROCESSES.MJF,
  MANUFACTURING_PROCESSES.SLA,
];

export const AUTOCOSTABLE_MFG_PROCESSES = [
  MANUFACTURING_PROCESSES.DLS,
  MANUFACTURING_PROCESSES.MJF,
  MANUFACTURING_PROCESSES.FDM,
  MANUFACTURING_PROCESSES.SLA,
  MANUFACTURING_PROCESSES.CNC,
  MANUFACTURING_PROCESSES.InjectionMolding,
];

// Each material has a subset of these layer thicknesses
export const AUTOCOSTABLE_LAYER_THICKNESSES = ['0.005', '0.007', '0.01', '0.013'];

export const ADDITIVE_PROCESSES = {
  [MANUFACTURING_PROCESSES.DLS]: 'Digital Light Synthesis',
  [MANUFACTURING_PROCESSES.MJF]: 'Multi Jet Fusion',
  [MANUFACTURING_PROCESSES.FDM]: 'Fused Deposition Modeling',
  [MANUFACTURING_PROCESSES.SLA]: 'Stereolithography',
};

export const ADDITIVE_PROCESS_NAMES = Object.values(ADDITIVE_PROCESSES);

export const TRADITIONAL_PROCESSES = {
  [MANUFACTURING_PROCESSES.CastUrethane]: 'Cast Urethane',
  [MANUFACTURING_PROCESSES.CNC]: 'CNC Machining',
  [MANUFACTURING_PROCESSES.InjectionMolding]: 'Injection Molding',
};

export const TRADITIONAL_PROCESS_NAMES = Object.values(TRADITIONAL_PROCESSES);

export const PROCESS_NAMES = { ...ADDITIVE_PROCESSES, ...TRADITIONAL_PROCESSES };

export const isInternalProcess = mfgProcessName => INTERNAL_PROCESSES.includes(mfgProcessName);

export const isExternalProcess = mfgProcessName => EXTERNAL_PROCESSES.includes(mfgProcessName);

export const mfgProcessesWithEditableSupplierOnAutoquote = [MANUFACTURING_PROCESSES.CNC];

export const mfgProcessesRequiringColor = [MANUFACTURING_PROCESSES.FDM];

export const getManufacturingProcessImageUrl = name =>
  `https://os.fastradius.com/images/studio/${snakeCase(name)}.png`;

export const getCNCManufacturingId = manufacturingProcesses =>
  manufacturingProcesses.find(x => x.name === MANUFACTURING_PROCESSES.CNC)?.id;

export const getIMManufacturingId = manufacturingProcesses =>
  manufacturingProcesses.find(x => x.name === MANUFACTURING_PROCESSES.InjectionMolding)?.id;

export const getCUManufacturingId = manufacturingProcesses =>
  manufacturingProcesses.find(x => x.name === MANUFACTURING_PROCESSES.CastUrethane)?.id;

export const getDefaultValues = (process, CNC_DEFAULT_VALUES) => {
  const autoquotableDefaults = process?.autoquotable_defaults;
  if (process?.name === MANUFACTURING_PROCESSES.CNC) {
    return autoquotableDefaults
      ? { ...CNC_DEFAULT_VALUES, ...autoquotableDefaults }
      : { ...CNC_DEFAULT_VALUES };
  }
  return (
    autoquotableDefaults && {
      customer_portal_finish: 'Standard',
      ...autoquotableDefaults,
    }
  );
};
