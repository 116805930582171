import { useField } from 'formik';
import React from 'react';
import AsyncSelect from 'react-select/async';

import { api } from 'fr-shared/api';
import { FormFieldBase, classNames } from 'fr-shared/components';

interface Tariff {
  id: number;
  hts_code: string;
  additional_duties: string | null;
  column_2_rate_of_duty: string | null;
  description: string | null;
  general_rate_of_duty: string | null;
  quota_quantity: string | null;
  special_rate_of_duty: string | null;
  unit_of_quantity: string[] | string | null;
}

interface TariffOption {
  data: Tariff;
  label: string;
  value: string;
}

interface TariffSelectProps {
  isStacked?: boolean;
  name: string;
  onChange?: (tariff: Tariff | null) => void;
  readonly?: boolean;
  value: Tariff | null;
}

const formatTariff = ({ hts_code, description }: Tariff) => `${hts_code} ${description}`;

const tariffToTariffOption = (tariff: Tariff): TariffOption => ({
  data: tariff,
  label: formatTariff(tariff),
  value: String(tariff.id),
});

const loadOptions = (inputValue: string) =>
  api
    .get<Tariff[]>('/tariffs', { params: { hts_code: inputValue, description: inputValue } })
    .then(resp => resp.data.map(tariffToTariffOption));

const TariffSelect = ({
  isStacked,
  name,
  onChange = () => {},
  readonly = false,
  value,
}: TariffSelectProps) => {
  const [_value, meta] = useField<number>(name);

  const handleChange = React.useCallback(
    (option: TariffOption | null) => onChange(option?.data ?? null),
    [onChange]
  );

  return (
    <FormFieldBase
      error={meta.error}
      isStacked={isStacked}
      label="HTS Code"
      name={name}
      readonly={readonly}
      value={value?.id}
    >
      {readonly ? (
        <strong>{value && formatTariff(value)}</strong>
      ) : (
        <AsyncSelect
          aria-label="tariff-select"
          cacheOptions
          className={classNames(['react-select-wrapper', { 'is-invalid': meta.error }])}
          classNamePrefix="react-select"
          defaultOptions
          isClearable
          loadOptions={loadOptions}
          name="tariff_id"
          noOptionsMessage={() => 'No HTS codes found'}
          onChange={handleChange}
          placeholder="Enter the HTS code"
          value={value ? tariffToTariffOption(value) : null}
        />
      )}
    </FormFieldBase>
  );
};

export default TariffSelect;
