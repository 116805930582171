import PropTypes from 'prop-types';
import React from 'react';

import { Button, classNames } from 'portal/components';

import styles from './Checkout.module.css';

const CardCheckout = ({ editUrl, children, name, isActive, footer, isEditable }) => {
  return (
    <div className={!isActive ? 'border-bottom' : undefined}>
      <div className="flex align-items-center">
        <h3 className={classNames(['py-2', !isActive && 'text-muted'])}>{name}</h3>
        {isEditable && !isActive && (
          <div className="ml-auto">
            <Button outline size="sm" to={editUrl}>
              Edit
            </Button>
          </div>
        )}
      </div>
      {isActive && (
        <div className={styles.CheckoutCard}>
          <div>{children}</div>
          <div className="flex justify-content-start">{footer}</div>
        </div>
      )}
    </div>
  );
};

CardCheckout.defaultProps = {
  isEditable: true,
};

CardCheckout.propTypes = {
  children: PropTypes.node,
  editUrl: PropTypes.string,
  footer: PropTypes.node,
  isActive: PropTypes.bool,
  name: PropTypes.string,
  isEditable: PropTypes.bool,
};

export default CardCheckout;
