import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { api } from 'fr-shared/api';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  IconFont,
  UncontrolledDropdown,
} from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';

import useScopedAnalytics from 'portal/hooks/useScopedAnalytics';
import LINK_URLS from 'portal/lib/link_urls';

// [Public API]
interface Props {
  quoteId: string;
  onContactClick: () => void;
  readonly?: boolean;
}

export const QuoteDetailsDropdown = ({ quoteId, onContactClick, readonly = false }: Props) => {
  const { setAlert } = useContext(AlertContext);
  const history = useHistory();

  const handleAnalytics = useScopedAnalytics('Quote Acceptance');

  const handleContactUs = () => {
    if (readonly) {
      return;
    }
    onContactClick();
    history.push(LINK_URLS.ContactUrl);
  };

  const handleGenerateQuotePDF = () => {
    if (readonly) {
      return;
    }
    handleAnalytics('Clicked "Download as PDF"');
    api
      .get(`/customer_portal/quotes/${quoteId}/download_pdf`)
      .then(res => {
        window.open(res.data);
      })
      .catch(() => {
        setAlert({
          message: 'We couldn’t download your PDF quote. Please try again or contact us.',
          color: 'warning',
          autoClose: true,
        });
      });
  };

  return (
    <UncontrolledDropdown tag="div" defaultOpen={false}>
      <DropdownToggle
        onClick={() => handleAnalytics('Clicked More Icon')}
        className=""
        caret={false}
        tag="div"
        nav={false}
        split={false}
      >
        <div className="border-2 border-solid border-white rounded-full p-1 text-white cursor-pointer">
          <IconFont name="more" className="text-2xl leading-4" />
        </div>
      </DropdownToggle>

      <DropdownMenu right={false} className="bg-coolGray-700 rounded-[8px] my-1">
        <QuoteDetailsDropdownItem onClick={handleContactUs}>
          <IconFont name="support" className="mr-0.5 text-xl leading-none" />
          Contact us
        </QuoteDetailsDropdownItem>
        <QuoteDetailsDropdownItem onClick={handleGenerateQuotePDF}>
          <IconFont name="pdf-document" className="mr-0.5 text-xl leading-none" />
          Download as PDF
        </QuoteDetailsDropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default QuoteDetailsDropdown;

// [Private Implementation]
const QuoteDetailsDropdownItem = ({ children, ...props }: React.PropsWithChildren<any>) => {
  return (
    <DropdownItem
      className="text-white px-2 py-1 hover:bg-coolGray-600 hover:text-coolGray-300 focus:bg-coolGray-600 focus:text-coolGray-300"
      {...props}
    >
      {children}
    </DropdownItem>
  );
};
