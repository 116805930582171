import React from 'react';

import { IconFont } from 'fr-shared/components';
import { UserAnalyticsService, useUserAnalyticsContext } from 'fr-shared/context';

import { Button, EmptyStateCard } from 'portal/components';

export const EmptyQuotesCard = () => {
  const userAnalytics: UserAnalyticsService = useUserAnalyticsContext();
  const handleRecentQuoteClick = () =>
    userAnalytics.track(`Create Quote CTA - Dash Quotes`, { valid: true });

  return (
    <EmptyStateCard
      iconFont="quote"
      cardHeader="Recent Quotes"
      mainText="You have no quotes with us yet. But it’s easy to create one. "
    >
      <div className="mb-1">
        <Button block outline to="/add-part" onClick={handleRecentQuoteClick}>
          <IconFont name="plus" />
          Create Quote
        </Button>
      </div>
    </EmptyStateCard>
  );
};

export default EmptyQuotesCard;
