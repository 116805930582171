import PropTypes from 'prop-types';
import React from 'react';

import { ORDER_STATES } from 'fr-shared/lib/orders';

import OrderLineItemCard from './OrderLineItemCard';

const OrderDetailsBody = ({ onReorderClicked, order }) => {
  const { inserted_at, line_items } = order;

  const isCanceled = order.state === ORDER_STATES.Canceled;

  return (
    <div className="py-5 font-size-md">
      <h3 className="mb-4">Items {line_items ? `(${line_items.length})` : ''}</h3>
      {line_items.map((lineItem, index) => {
        return (
          <OrderLineItemCard
            key={`line_item_card_${index}`}
            orderSubmitted={inserted_at}
            lineItem={lineItem}
            isCanceled={isCanceled}
            onReorderClicked={onReorderClicked}
          />
        );
      })}
    </div>
  );
};

OrderDetailsBody.propTypes = {
  onReorderClicked: PropTypes.func,
  order: PropTypes.shape({
    state: PropTypes.string,
    line_items: PropTypes.array,
    inserted_at: PropTypes.string,
  }),
};

export default OrderDetailsBody;
