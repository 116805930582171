import React from 'react';

import { ButtonLink, Icon, Link } from 'fr-shared/components';

import withErrorBoundaryFallback from './withErrorBoundaryFallback';

const GlobalFallback = () => {
  return (
    <div className="container mt-4">
      <div className="alert p-4 flex justify-content-center align-items-center" role="alert">
        <div className="circle p-4 border-0">
          <Icon name="exclamation-triangle" className="font-size-5xl text-muted mb-2" />
        </div>
        <div className="ml-4">
          <p className="font-size-xl">
            <strong>Something unexpected occurred</strong>
          </p>
          <p>Please try again</p>
          <p>
            <ButtonLink onClick={() => window.location.reload()}>Refresh</ButtonLink> or visit{' '}
            <Link to="/">os.fastradius.com</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundaryFallback(GlobalFallback, 'Global');
