import * as Yup from 'yup';

import { billingSchema, shippingWithPhoneNumberSchema } from 'fr-shared/lib/address';
import { SHIPPING_PAYMENT_TYPES } from 'fr-shared/lib/shipping';
import { mergeSchemas } from 'fr-shared/utils';

export const quoteToOrderFormSchema = Yup.object().shape({
  payment_terms: Yup.string().required('Payment terms is required'),
  payment_type: Yup.string().required('Payment type is required'),
  shipping_account_number: Yup.string().when('shipping_payment_type', {
    is: SHIPPING_PAYMENT_TYPES.CustomerAccount,
    then: Yup.string().required('Shipping Account is required'),
    otherwise: Yup.string(),
  }),
  shipping_method: Yup.string().required('Shipping method is required'),
  shipping_payment_type: Yup.string(),
  type: Yup.string().required('Order type is required'),
});

export const quoteToOrderSchema = mergeSchemas(
  quoteToOrderFormSchema,
  billingSchema,
  shippingWithPhoneNumberSchema
);
