import { isString } from 'lodash';
import React, { Fragment } from 'react';

export const renderWithLineBreaks = text => {
  if (isString(text)) {
    return (
      text &&
      text.split('\n').map((item, key) => {
        return (
          <Fragment key={key}>
            {item}
            <br />
          </Fragment>
        );
      })
    );
  } else {
    return text;
  }
};
