import React from 'react';

import { IconFont, classNames } from 'fr-shared/components';
import { keyboardDown } from 'fr-shared/utils';

import styles from './Notification.module.css';

interface NotificationProps {
  level: 'info' | 'secondary' | 'warning' | 'error';
  iconNameOrNumber: 'comments' | 'lightbulb' | string | number;
  primaryText: string;
  secondaryText: string;
  actionText?: string;
  className?: string;
  onClick?: (event: any) => void;
}

const Notification = ({
  level = 'info',
  iconNameOrNumber = 'comments',
  primaryText,
  secondaryText,
  actionText,
  className,
  onClick = () => {},
}: NotificationProps) => {
  const renderIcon = (nameOrNumber: string | number) => {
    if (typeof nameOrNumber === 'number') {
      return (
        <span className={styles.IconNumberContainer}>
          <p className={styles.IconNumberText}>{nameOrNumber}</p>
        </span>
      );
    } else {
      return <IconFont name={nameOrNumber} className="font-size-xl" />;
    }
  };

  return (
    <div
      className={classNames([styles.Container, 'p-2 font-size-md', className])}
      onClick={onClick}
      onKeyDown={evt => keyboardDown(evt, 'Enter', onClick)}
      role="button"
      tabIndex={0}
    >
      <span className={classNames([styles.Icon, styles[`Icon--${level}`]])}>
        {renderIcon(iconNameOrNumber)}
      </span>
      <span className={styles.Content}>
        <h1>{primaryText}</h1>
        <p className="font-size-sm text-muted">{secondaryText}</p>
      </span>
      {actionText && (
        <p className={classNames([styles.ActionText, 'font-size-sm'])}>
          {actionText} <IconFont name="arrow-right" />
        </p>
      )}
    </div>
  );
};

export default Notification;
