import React from 'react';

import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import InfoModal from './InfoModal/InfoModal';

interface Props {
  cliId?: string | number;
  isInfoModal?: boolean;
  partId?: number;
}

export const ItarEarModal = ({ cliId, isInfoModal, partId }: Props) => {
  if (isInfoModal) return <InfoModal />;

  return <ConfirmationModal cliId={cliId} partId={partId} />;
};

export default ItarEarModal;
