import React from 'react';

import { Button, Icon, Link, Modal } from 'fr-shared/components';

const BulletPointAdviceModal = () => (
  <Modal
    action={
      <Button className="font-weight-normal text-dark" color="link" size="sm">
        <Icon className="text-info" name="info-circle" />
        {' How to add bullet points'}
      </Button>
    }
  >
    {({ toggle }: { toggle: (...args: any[]) => any }) => (
      <>
        <Modal.Header title="Adding Bullet Points" onClose={toggle} />
        <Modal.Body className="font-size-base">
          <strong>Windows</strong>
          <p>
            {
              'Hold down the "Alt" key, then individually type "0149" on the special numbers-section of your keyboard. Then release the "Alt" key.'
            }
          </p>
          <p>
            <Link to={{ pathname: 'https://www.youtube.com/watch?v=j8m0hQEWLUY' }} openNewWindow>
              Having trouble?
            </Link>
          </p>

          <strong>Macs</strong>
          <p>{'Press "Option (⌥)" and "8" at the same time.'}</p>

          <strong>Both</strong>
          <p>Copy this bullet ( • ) and paste it into the form field.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button color="primary" onClick={toggle}>
            Done
          </Button>
        </Modal.Footer>
      </>
    )}
  </Modal>
);

export default BulletPointAdviceModal;
