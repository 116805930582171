import { sumBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, Helmet } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';

import YieldReportForm from './components/YieldReportForm';

const YieldReport = ({ match }) => {
  const [workOrder, setWorkOrder] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const { id } = match.params;

  const loadWorkOrder = useCallback(() => {
    api.get(`/work_orders/${id}`).then(res => {
      setWorkOrder(res.data);
    });
  }, [id]);

  useEffect(() => {
    loadWorkOrder();
  }, [loadWorkOrder]);

  const handleSubmit = (values, bag) => {
    const { setErrors } = bag;

    api
      .put('/part_failures/bulk_assign', {
        ...values,
      })
      .then(() => {
        loadWorkOrder();
        const partFailureCount = sumBy(values.part_failure_updates, update => {
          return update.part_failures.length;
        });
        setAlert({
          message: `Successfully Documented (${partFailureCount}) part failures`,
          color: 'success',
        });
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      })
      .catch(error => {
        if (error?.response?.data) {
          const errors = error.response.data.errors;
          const messages = error.response.data.messages;
          setErrors({ server: messages, ...errors });
          setAlert({
            message: 'There was an error submitting, please fix all errors on the form',
            color: 'danger',
          });
        }
      });
  };

  return (
    <>
      <Helmet title={`Yield Report - Work Order ${id}`} />
      <Breadcrumb to="/admin/work_orders">Work Orders</Breadcrumb>
      <Breadcrumb to={`/admin/work_orders/${id}`}>Work Order #{id}</Breadcrumb>
      <Breadcrumb to={`/admin/work_orders/${id}/report`}>Yield Report</Breadcrumb>

      {workOrder && <YieldReportForm id={id} workOrder={workOrder} handleSubmit={handleSubmit} />}
    </>
  );
};

YieldReport.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      path: PropTypes.string,
      url: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default YieldReport;
