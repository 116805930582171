import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { IconFont } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { usePortalBulkUploadFeatureFlag } from 'fr-shared/hooks';
import usePortalBulkConfigFeatureFlag from 'fr-shared/hooks/__mocks__/usePortalBulkConfigFeatureFlag';
import { keyboardDown, pluralize } from 'fr-shared/utils';

import { Animated, Button, classNames } from 'portal/components';
import { PartsContext } from 'portal/contexts/PartsContext';
import useCart from 'portal/hooks/useCart';
import { addPartsToCart } from 'portal/lib/cart';

import Part from './Part';
import styles from './PartSelection.module.css';

export const PartSelection = ({
  closePartSelection,
  isOpen,
  onRemovePart,
  selectedParts,
  selectedPartsLength,
  onBeginBulkEdit,
}) => {
  const history = useHistory();
  const userAnalytics = useUserAnalyticsContext();
  const { data: cart, isFetched } = useCart();
  const { removeAllPartsFromSelection } = useContext(PartsContext);

  const bulkUploadFeatureFlagEnabled = usePortalBulkUploadFeatureFlag();
  const bulkConfigFeatureFlagEnabled = usePortalBulkConfigFeatureFlag();

  if (!isFetched) return null;

  const partsArray = (selectedParts && Object.values(selectedParts)) || [];

  const handleAddParts = () => {
    if (bulkConfigFeatureFlagEnabled && onBeginBulkEdit && selectedPartsLength > 1) {
      onBeginBulkEdit();
    } else {
      const logEvt = valid => userAnalytics.track(`Add Part to Quote`, { valid });
      addPartsToCart(partsArray)
        .then(res => {
          const { data: clis } = res;
          logEvt(true);
          removeAllPartsFromSelection();
          if (onBeginBulkEdit && selectedPartsLength === 1) {
            history.push(`/part-config/${clis[0].id}`);
          } else {
            history.push('/quotes/draft');
          }
        })
        .catch(() => {
          logEvt(false);
          closePartSelection();
        });
    }
  };

  return (
    <Animated
      animateOnMount={false}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      animationInDuration={500}
      animationOutDuration={500}
      isVisible={isOpen}
      className={classNames([styles.PartSelection, !isOpen && 'hidden'])}
    >
      <div className="flex align-items-center mb-2">
        <div>
          {selectedPartsLength} {pluralize('part', selectedPartsLength !== 1)} selected
        </div>

        {!bulkUploadFeatureFlagEnabled && (
          <div className="ml-auto">
            <div
              className={styles.CloseSelection}
              onClick={closePartSelection}
              onKeyDown={evt => keyboardDown(evt, 'Enter', closePartSelection)}
              role="button"
              tabIndex={0}
            >
              <IconFont name="close" />
            </div>
          </div>
        )}
      </div>

      <div className="flex align-items-center">
        <div className={classNames([styles.PartsList, 'flex flex-row flex-wrap'])}>
          {partsArray.map(part => {
            return (
              <Part
                key={`part-selection-part-${part.id}`}
                screenshotSrc={part.current_revision.screenshot}
                onRemovePart={() => onRemovePart(part.id)}
              />
            );
          })}
        </div>

        <div className="ml-auto mt-auto flex">
          {bulkUploadFeatureFlagEnabled ? (
            <>
              <Button
                onClick={closePartSelection}
                block
                outline
                to="#"
                size="md"
                className="mr-2 pl-5 pr-5"
              >
                Cancel
              </Button>
              <Button to="#" onClick={handleAddParts} size="md" className="pl-5 pr-5">
                Next
              </Button>
            </>
          ) : (
            <Button onClick={handleAddParts} size="md">
              {cart ? 'Add to existing quote' : 'Create Quote'}
            </Button>
          )}
        </div>
      </div>
    </Animated>
  );
};

PartSelection.propTypes = {
  isOpen: PropTypes.bool,
  closePartSelection: PropTypes.func.isRequired,
  onRemovePart: PropTypes.func,
  selectedParts: PropTypes.object.isRequired,
  selectedPartsLength: PropTypes.number.isRequired,
  onBeginBulkEdit: PropTypes.func,
};

export default PartSelection;
