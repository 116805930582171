import { find, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Pagination, ProgressCircle } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { usePortalBulkUploadFeatureFlag } from 'fr-shared/hooks';

import {
  EmptyPartsCard,
  PageResults,
  PageSearch,
  PageToolbar,
  PageViewToggle,
  PartPreviewModal,
  PartSelection,
} from 'portal/components';
import { PartsContext } from 'portal/contexts/PartsContext';

import GridView from './gridView/GridView';
import ListView from './listView/ListView';

const PartsSection = ({
  children,
  filters,
  handleSetPage,
  loading,
  pages,
  partId,
  parts,
  onSearch,
  onSort,
  onBeginBulkEdit,
  totalParts,
}) => {
  const userAnalytics = useUserAnalyticsContext();
  const [isGridView, setIsGridView] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPartSelectionOpen, setIsPartSelectionOpen] = useState(false);
  const [previewPart, setPreviewPart] = useState(null);
  const {
    areAllPartsSelected,
    currentSearchParam,
    handleSelectAllParts,
    removeSelectedPart,
    removeAllPartsFromSelection,
    selectedParts,
  } = useContext(PartsContext);
  const history = useHistory();

  const isPortalBulkUploadOn = usePortalBulkUploadFeatureFlag();

  const selectedPartsLength = Object.keys(selectedParts).length;

  useEffect(() => {
    setIsModalOpen(!!partId);
  }, [partId]);

  useEffect(() => {
    setIsPartSelectionOpen(selectedPartsLength > 0);
  }, [selectedPartsLength]);

  useEffect(() => {
    if (partId) {
      const part = find(parts, part => part.id.toString() === partId);
      setPreviewPart(part);
    }
  }, [parts, partId]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    const search = window.location.search;
    history.push(`/parts${search}`);
  }, [history, setIsModalOpen]);

  const closePartSelection = useCallback(() => {
    removeAllPartsFromSelection();
    setIsPartSelectionOpen(false);
  }, [removeAllPartsFromSelection, setIsPartSelectionOpen]);

  const handleRemovePart = useCallback(
    partId => {
      removeSelectedPart(partId);
    },
    [removeSelectedPart]
  );
  const handleGridToggle = () => {
    setIsGridView(!isGridView);
    userAnalytics.track(`${isGridView ? 'List' : 'Card'} View - Parts`, { valid: true });
  };

  const DisplayComponent = isGridView ? GridView : ListView;

  const hasNoParts = !loading && isEmpty(parts);

  return (
    <>
      <PageToolbar>
        {isPortalBulkUploadOn && (
          <div className="custom-control custom-radio-check py-2 mr-2 flex">
            <input
              id="select-all"
              type="checkbox"
              className="custom-control-input"
              checked={areAllPartsSelected}
              onChange={handleSelectAllParts}
            ></input>
            <label
              htmlFor="select-all"
              className="flex custom-control-label font-weight-normal ml-1"
            >
              <div className="align-self-center ml-1 text-coolGray-300">Select all parts</div>
            </label>
          </div>
        )}
        <PageSearch
          className={isPortalBulkUploadOn ? 'ml-auto' : ''}
          defaultValue={currentSearchParam}
          onSearch={onSearch}
          placeholder="Search by part name"
          onBlur={e => {
            userAnalytics.trackFieldEvents({
              e,
              logType: 'string',
              trackName: 'Parts - Search',
              customValidation: eVal => Boolean(eVal.trim()),
            });
          }}
        />
        {!isPortalBulkUploadOn && <PageResults value={totalParts} context="part" />}
        <PageViewToggle
          className={isPortalBulkUploadOn ? 'ml-3 pl-3 border-left rounded-none' : ''}
          iconClassName={isPortalBulkUploadOn ? 'ml-3' : ''}
          toggled={isGridView}
          onToggle={handleGridToggle}
        />
      </PageToolbar>

      {hasNoParts && window.location.pathname !== '/add-part' ? (
        <EmptyPartsCard />
      ) : (
        <div className="mt-5">
          {!loading && (
            <DisplayComponent parts={parts} onSort={onSort} pageName="Parts">
              {children}
            </DisplayComponent>
          )}
        </div>
      )}

      {loading && (
        <div className="flex justify-center mt-32">
          <ProgressCircle
            percentage={75}
            spinner={true}
            backgroundColor="#111115"
            fadesIntoBackground
          />
        </div>
      )}
      {pages > 1 && !loading && (
        <div className="flex-1 mt-3 flex align-items-end">
          <Pagination
            page={filters.page}
            setPage={handleSetPage}
            totalPages={pages}
            pageNumberStyle={'numbered'}
          />
        </div>
      )}

      {!loading && (
        <PartSelection
          isOpen={isPartSelectionOpen}
          closePartSelection={closePartSelection}
          selectedParts={selectedParts}
          selectedPartsLength={selectedPartsLength}
          onRemovePart={handleRemovePart}
          onBeginBulkEdit={onBeginBulkEdit}
        />
      )}

      {previewPart && (
        <PartPreviewModal
          isOpen={isModalOpen}
          toggle={closeModal}
          part={previewPart}
          pageName="Parts"
        />
      )}
    </>
  );
};

PartsSection.propTypes = {
  children: PropTypes.node,
  filters: PropTypes.array,
  handleSetPage: PropTypes.func,
  loading: PropTypes.bool,
  pages: PropTypes.array,
  partId: PropTypes.string,
  parts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  onSearch: PropTypes.func,
  onSort: PropTypes.func,
  totalParts: PropTypes.number,
  onBeginBulkEdit: PropTypes.func,
};

export default memo(PartsSection);
