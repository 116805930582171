import React, { SyntheticEvent, useEffect } from 'react';

import { FormFieldBase, Input, Select } from 'fr-shared/components';
import useSurfaceRoughnesses from 'fr-shared/hooks/useSurfaceRoughnesses';
import {
  booleanInputFilter,
  numberInputFilter,
  numberInputMinValueFilter,
} from 'fr-shared/utils/input';

import useScopedAnalytics from 'portal/hooks/useScopedAnalytics';

import { CNC_DEFAULT_VALUES, cncSupportingDocumentsRequired, showMaskedSurfaces } from '../util';
import ToleranceFields, { ToleranceState } from './ToleranceFields';

export const CNC_FIELD_NAMES = {
  CNC_ADVANCED_FEATURES: 'cnc_advanced_features',
  CNC_MASKED_SURFACES: 'cnc_masked_surfaces',
  CNC_THREAD_COUNT: 'cnc_thread_count',
  CNC_THREAD_VARIETY: 'cnc_thread_variety',
  CNC_SURFACE_ROUGHNESS: 'cnc_surface_roughness_id',
  PART_USAGE: 'part_usage',
};

export type CNCFieldsState = {
  cnc_surface_roughness?: SurfaceRoughness;
  cnc_gdt?: boolean;
  cnc_masked_surfaces?: boolean;
  cnc_thread_count?: number;
  cnc_thread_variety?: number;
  cnc_advanced_features?: boolean;
  cnc_certifications?: boolean;
  is_prototype: boolean;
  part_usage?: string;
  part_designation?: string;
} & Partial<ToleranceState>;

type CNCFieldProps = {
  finish: Finish;
  formState: CNCFieldsState;
  hasSupportingDocs: boolean;
  onChanges: (changes: Partial<CNCFieldsState>) => void;
};

export const DEFAULT_SURFACE_ROUGHNESS_VALUE = 3.2;

function CNCFields({ finish, formState, hasSupportingDocs, onChanges }: CNCFieldProps) {
  const trackInput = useScopedAnalytics('CNC');
  const { data: surfaceRoughnesses } = useSurfaceRoughnesses();
  const currentSurfaceRoughness = surfaceRoughnesses?.find(
    s => s.id === formState.cnc_surface_roughness?.id
  );
  const defaultSurfaceRoughness = surfaceRoughnesses?.find(
    s => s.value === DEFAULT_SURFACE_ROUGHNESS_VALUE
  );

  /** Adjust the masked surfaces input if the finish changes */
  useEffect(() => {
    if (!showMaskedSurfaces(finish)) {
      onChanges({ cnc_masked_surfaces: null });
    } else {
      if (formState.cnc_masked_surfaces === null) {
        onChanges({ cnc_masked_surfaces: CNC_DEFAULT_VALUES.cnc_masked_surfaces });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finish, formState.cnc_masked_surfaces]);

  /** Select the default surface roughness if the surface roughness is not set or valid */
  useEffect(() => {
    // if null or undefined, default the surface roughness
    if (currentSurfaceRoughness == null && defaultSurfaceRoughness) {
      onChanges({
        cnc_surface_roughness: defaultSurfaceRoughness,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSurfaceRoughness?.id, defaultSurfaceRoughness?.id]);

  const onChangeBoolean = (event: SyntheticEvent<HTMLSelectElement>) => {
    onChanges({ [event.currentTarget.name]: event.currentTarget.value === 'true' });
  };

  const cncFieldsErrors = {
    [CNC_FIELD_NAMES.CNC_ADVANCED_FEATURES]:
      !hasSupportingDocs &&
      cncSupportingDocumentsRequired(formState, CNC_FIELD_NAMES.CNC_ADVANCED_FEATURES)
        ? 'Please upload a supporting document.'
        : null,
    [CNC_FIELD_NAMES.CNC_MASKED_SURFACES]:
      !hasSupportingDocs &&
      cncSupportingDocumentsRequired(formState, CNC_FIELD_NAMES.CNC_MASKED_SURFACES)
        ? 'Please upload a supporting document.'
        : null,
    [CNC_FIELD_NAMES.CNC_THREAD_COUNT]:
      formState.cnc_thread_count < 0
        ? 'Must be 0 or greater'
        : !hasSupportingDocs &&
          cncSupportingDocumentsRequired(formState, CNC_FIELD_NAMES.CNC_THREAD_COUNT)
        ? 'Please upload a supporting document.'
        : null,
    [CNC_FIELD_NAMES.CNC_THREAD_VARIETY]:
      formState.cnc_thread_variety < 0
        ? 'Must be 0 or greater'
        : !hasSupportingDocs &&
          cncSupportingDocumentsRequired(formState, CNC_FIELD_NAMES.CNC_THREAD_VARIETY)
        ? 'Please upload a supporting document.'
        : null,
    [CNC_FIELD_NAMES.CNC_SURFACE_ROUGHNESS]:
      !hasSupportingDocs &&
      cncSupportingDocumentsRequired(formState, CNC_FIELD_NAMES.CNC_SURFACE_ROUGHNESS)
        ? 'Please upload a part drawing.'
        : null,
  };

  return (
    <>
      <FormFieldBase
        name="cnc_surface_roughness"
        label="As-machined surface roughness"
        isControl={true}
        tooltip="Our standard surface roughness is 125μi (3.2μm) Ra. If you choose a lower surface roughness, we'll need a drawing to manufacture your part."
        error={cncFieldsErrors[CNC_FIELD_NAMES.CNC_SURFACE_ROUGHNESS]}
      >
        <Select
          name="cnc_surface_roughness"
          className="form-group is-floating"
          selectClassName="form-control"
          sorted={false}
          hasBlankOption={false}
          value={formState.cnc_surface_roughness?.id || ''}
          optionList={surfaceRoughnesses}
          onBlur={(event: SyntheticEvent<HTMLSelectElement>) => {
            const surfaceRoughness = surfaceRoughnesses?.find(
              s => s.id === +event.currentTarget.value
            );
            if (surfaceRoughness)
              trackInput('As-machined surface roughness', surfaceRoughness.name);
          }}
          onChange={(event: SyntheticEvent<HTMLSelectElement>) => {
            const surfaceRoughness = surfaceRoughnesses?.find(
              s => s.id === +event.currentTarget.value
            );
            if (surfaceRoughness) {
              onChanges({
                cnc_surface_roughness: surfaceRoughness,
              });
            }
          }}
          dataTestId="part-config-cnc-surface-roughness"
        />
      </FormFieldBase>
      <ToleranceFields formState={formState} onChange={onChanges} trackInput={trackInput} />
      <FormFieldBase
        name="cnc_gdt"
        label="GD&amp;T"
        isControl={true}
        tooltip="Geometric Dimensioning &amp; Tolerancing (GD&amp;T) contains info about your part's geometry, for manufacturing accuracy. If you require GD&amp;T, please tell us here."
      >
        <Select
          name="cnc_gdt"
          className="form-group is-floating"
          selectClassName="form-control"
          sorted={false}
          hasBlankOption={false}
          value={formState.cnc_gdt}
          optionList={[
            { id: true, name: 'Yes' },
            { id: false, name: 'No' },
          ]}
          onBlur={(event: SyntheticEvent<HTMLSelectElement>) => {
            trackInput(
              'Geometric Dimensioning & Tolerancing',
              booleanInputFilter(event.currentTarget.value)
            );
          }}
          onChange={(event: SyntheticEvent<HTMLSelectElement>) => {
            onChangeBoolean(event);
          }}
          dataTestId="part-config-cnc-gdt"
        />
      </FormFieldBase>
      {showMaskedSurfaces(finish) && (
        <FormFieldBase
          name="cnc_masked_surfaces"
          label="Masked surfaces"
          isControl={true}
          tooltip="A masked surface is any area where post-processing should not be applied. If your part needs masked surfaces or has holes to be plugged, please tell us here."
          error={cncFieldsErrors[CNC_FIELD_NAMES.CNC_MASKED_SURFACES]}
        >
          <Select
            name="cnc_masked_surfaces"
            className="form-group is-floating"
            selectClassName="form-control"
            sorted={false}
            hasBlankOption={false}
            value={formState.cnc_masked_surfaces}
            optionList={[
              { id: true, name: 'Yes' },
              { id: false, name: 'No' },
            ]}
            onBlur={(event: SyntheticEvent<HTMLSelectElement>) => {
              trackInput('Masked surfaces', booleanInputFilter(event.currentTarget.value));
            }}
            onChange={(event: SyntheticEvent<HTMLSelectElement>) => {
              onChangeBoolean(event);
            }}
            dataTestId="part-config-cnc-masked-surfaces"
          />
        </FormFieldBase>
      )}
      <FormFieldBase
        name="cnc_thread_count"
        label="Threaded features"
        isControl={true}
        tooltip="A threaded feature is an external or internal thread on a part, typically for screws and screw holes. If your part has any threaded features, please tell us how many here."
        error={cncFieldsErrors[CNC_FIELD_NAMES.CNC_THREAD_COUNT]}
      >
        <Input
          name="cnc_thread_count"
          value={formState.cnc_thread_count}
          type="number"
          size="md"
          onBlur={(event: SyntheticEvent<HTMLInputElement>) => {
            if (isNaN(event.currentTarget.valueAsNumber)) {
              onChanges({ cnc_thread_count: 0, cnc_thread_variety: null });
            }
            trackInput(
              'Number of threaded features',
              numberInputFilter(event.currentTarget.valueAsNumber)
            );
          }}
          onChange={(event: SyntheticEvent<HTMLInputElement>) => {
            const value = numberInputMinValueFilter(event.currentTarget.valueAsNumber, 0);

            // Sets the default value for the cnc_thread_variety conditional field
            if (value !== null && value < 1) {
              onChanges({ cnc_thread_variety: null, cnc_thread_count: value });
            } else if (value !== null && value > 0 && formState.cnc_thread_variety === null) {
              onChanges({ cnc_thread_variety: 0, cnc_thread_count: value });
            } else {
              onChanges({ cnc_thread_count: value });
            }
          }}
          min={0}
          data-testid="part-config-cnc-thread-count"
        />
      </FormFieldBase>
      {formState.cnc_thread_count > 0 && (
        <FormFieldBase
          name="cnc_thread_variety"
          label="Unique thread sizes"
          isControl={true}
          error={cncFieldsErrors[CNC_FIELD_NAMES.CNC_THREAD_VARIETY]}
          tooltip="Every threaded feature has a specific size. Please tell us how many thread sizes are required for your threaded features."
        >
          <Input
            name="cnc_thread_variety"
            value={formState.cnc_thread_variety}
            type="number"
            size="md"
            onBlur={(event: SyntheticEvent<HTMLInputElement>) => {
              if (isNaN(event.currentTarget.valueAsNumber)) onChanges({ cnc_thread_variety: 0 });
              trackInput('Thread sizes', numberInputFilter(event.currentTarget.valueAsNumber));
            }}
            onChange={(event: SyntheticEvent<HTMLInputElement>) => {
              onChanges({
                cnc_thread_variety: numberInputMinValueFilter(
                  event.currentTarget.valueAsNumber,
                  0
                ),
              });
            }}
            min={0}
            data-testid="part-config-cnc-thread-variety"
          />
        </FormFieldBase>
      )}

      <FormFieldBase
        name="cnc_advanced_features"
        label="Advanced features"
        isControl={true}
        tooltip="Advanced features include inserts, knurling, broaching, gear profiles, ACME threads and splines. If your part requires any features like these, please tell us here."
        error={cncFieldsErrors[CNC_FIELD_NAMES.CNC_ADVANCED_FEATURES]}
      >
        <Select
          name="cnc_advanced_features"
          className="form-group is-floating"
          selectClassName="form-control"
          sorted={false}
          hasBlankOption={false}
          value={formState.cnc_advanced_features}
          optionList={[
            { id: true, name: 'Yes' },
            { id: false, name: 'No' },
          ]}
          onBlur={(event: SyntheticEvent<HTMLSelectElement>) => {
            trackInput('Advanced features', booleanInputFilter(event.currentTarget.value));
          }}
          onChange={(event: SyntheticEvent<HTMLSelectElement>) => {
            onChangeBoolean(event);
          }}
          dataTestId="part-config-cnc-advanced-features"
        />
      </FormFieldBase>
      <FormFieldBase
        name="cnc_certifications"
        label="Certifications & inspections"
        isControl={true}
        tooltip="If you need any certifications for your materials or finishes, or any inspection reports for your part, please tell us here."
      >
        <Select
          name="cnc_certifications"
          className="form-group is-floating"
          selectClassName="form-control"
          sorted={false}
          hasBlankOption={false}
          value={formState.cnc_certifications}
          optionList={[
            { id: true, name: 'Yes' },
            { id: false, name: 'No' },
          ]}
          onBlur={(event: SyntheticEvent<HTMLSelectElement>) => {
            trackInput('Certifications', booleanInputFilter(event.currentTarget.value));
          }}
          onChange={(event: SyntheticEvent<HTMLSelectElement>) => {
            onChangeBoolean(event);
          }}
          dataTestId="part-config-cnc-certifications"
        />
      </FormFieldBase>
    </>
  );
}

export default CNCFields;
