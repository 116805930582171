import { connect } from 'formik';
import { differenceWith, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { FormField } from 'fr-shared/components';
import { useCostingInputs } from 'fr-shared/hooks';

const getCostingInputsForManufacturingProcess = (costingInputs, manufacturingProcess) => {
  if (!costingInputs || !manufacturingProcess) return [];

  return costingInputs.filter(costingInput => {
    return costingInput?.manufacturing_process?.id === manufacturingProcess.id;
  });
};

const costingInputsDifferFromCostingInputValues = (costingInputs, costingInputValues) =>
  differenceWith(costingInputs, costingInputValues, (costingInput, costingInputValue) => {
    return costingInput.id === costingInputValue.costing_input.id;
  }).length !== 0;

const AdditionalCostingInputs = ({ fieldPrefix, readonly, manufacturingProcess, formik }) => {
  const { data: costingInputs } = useCostingInputs();
  const lineItem = get(formik.values, fieldPrefix);
  const costingInputValues = get(formik.values, `${fieldPrefix}.costing_input_values`, []);

  useEffect(() => {
    const costingInputsForManufacturingProcess = getCostingInputsForManufacturingProcess(
      costingInputs,
      manufacturingProcess
    );

    // We want to see if costingInputsForManufacturingProcess ids are the same as costingInputValues ids
    // So we don't hit an infinite loop when updating the costing input values
    if (
      isEmpty(costingInputsForManufacturingProcess) ||
      !costingInputsDifferFromCostingInputValues(
        costingInputsForManufacturingProcess,
        costingInputValues
      )
    ) {
      return;
    }

    // Merge saved costing input values with costing inputs fields
    const newCostingInputValues = costingInputsForManufacturingProcess.map(costingInput => {
      const matchingData = costingInputValues.find(
        cIV => cIV.costing_input.id === costingInput.id
      );
      return {
        value: matchingData?.value,
        costing_input: costingInput,
        costing_input_id: costingInput.id,
        costing_request_line_item_id: get(formik.values, `${fieldPrefix}.id`),
      };
    });

    formik.setFieldValue(`${fieldPrefix}.costing_input_values`, newCostingInputValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costingInputs, manufacturingProcess, fieldPrefix, lineItem]);

  if (!costingInputValues) {
    return null;
  }

  return (
    <div className="col-md">
      {costingInputValues.map((costingInputValue, costingInputValueIndex) => (
        <div key={`costingInput-${costingInputValueIndex}`} className="mb-1">
          <FormField
            label={costingInputValue.costing_input.name}
            name={`${fieldPrefix}.costing_input_values.${costingInputValueIndex}.value`}
            className="mb-0"
            inputClassName="font-size-md"
            readonly={readonly}
            md={6}
          />
        </div>
      ))}
    </div>
  );
};

AdditionalCostingInputs.propTypes = {
  fieldPrefix: PropTypes.string,
  readonly: PropTypes.bool,
  manufacturingProcess: PropTypes.object,
  formik: PropTypes.object,
};

export default connect(AdditionalCostingInputs);
