import { Button } from '@fast-radius/shared-ui';
import { get } from 'lodash';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { keyboardDown } from 'fr-shared/utils';

import { classNames } from 'portal/components';
import { BILLING_CYCLE, getFormattedPricingBasedOffSelectedPlan } from 'portal/lib/plans';

import CardCheckout from '../../components/CardCheckout';

interface BillingFrequencyProps {
  isActive: boolean;
  formik: any;
}

const BillingFrequency = ({ isActive, formik }: BillingFrequencyProps) => {
  const history = useHistory();

  const location = useLocation();
  const planType = new URLSearchParams(location.search).get('plan');
  const selectedBillingCycle = get(
    formik.values,
    'subscription.billing_cycle',
    BILLING_CYCLE.ANNUALLY
  );
  const subscriptionPlans = get(formik.values, 'subscription_plans', []);

  const handleSelectionCardClick = (billingCycle: string) => {
    formik.setFieldValue('subscription.billing_cycle', billingCycle);
  };

  const { annualPrice, monthlyPrice, totalAnnualPrice } = getFormattedPricingBasedOffSelectedPlan(
    planType,
    subscriptionPlans
  );

  return (
    <CardCheckout
      name="Billing frequency"
      isActive={isActive}
      isEditable={true}
      editUrl={`/subscription/frequency?plan=${planType}`}
    >
      <div className="flex" data-testid="billing-frequency-checkout-form">
        <div className="flex flex-col w-1/2">
          {annualPrice && (
            <SelectionCard
              billingCycle={BILLING_CYCLE.ANNUALLY}
              isActive={selectedBillingCycle === BILLING_CYCLE.ANNUALLY}
              onClick={handleSelectionCardClick}
              price={monthlyPrice ? annualPrice : totalAnnualPrice}
              showYearWording={!monthlyPrice}
            />
          )}
          {monthlyPrice && (
            <SelectionCard
              billingCycle={BILLING_CYCLE.MONTHLY}
              isActive={selectedBillingCycle === BILLING_CYCLE.MONTHLY}
              onClick={handleSelectionCardClick}
              price={monthlyPrice}
            />
          )}
        </div>
        <div className="flex flex-col w-1/2 pl-6">
          <div className="text-xs text-coolGray-300 pb-44">
            {monthlyPrice && annualPrice && (
              <span>
                Annual subscriptions require payment once a year but cost less per month overall.
              </span>
            )}
          </div>

          <Button
            dataTestId="link-to-billing-details"
            onClick={() => history.push(`/subscription/billing?plan=${planType}`)}
            color="primary"
          >
            Continue to billing details
          </Button>
        </div>
      </div>
    </CardCheckout>
  );
};

const SelectionCard = ({
  billingCycle,
  isActive,
  onClick,
  price,
  showYearWording = false,
}: {
  billingCycle: string;
  isActive: boolean;
  onClick: (billingCycle: string) => void;
  price: string;
  showYearWording?: boolean;
}) => (
  <div
    data-testid={`billing-frequency-selection-card-${billingCycle}`}
    onClick={() => onClick(billingCycle)}
    role="button"
    tabIndex={0}
    onKeyDown={evt => keyboardDown(evt, 'Enter', onClick(billingCycle))}
    className={classNames([
      'flex h-9 border border-coolGray-700 rounded-[8px] mb-2 hover:cursor-pointer',
      isActive && 'border-coolGray-100',
    ])}
  >
    <div
      className={classNames([
        'w-[18px] h-[18px] border border-coolGray-500 rounded-md mt-2 mx-3.5',
        isActive && 'border-primary-400',
      ])}
    >
      {isActive && <div className="w-[10px] h-[10px] bg-primary-400 rounded-md m-[3px]"></div>}
    </div>
    <div className="flex flex-col text-base self-center">
      <div className="text-coolGray-100">{billingCycle}</div>
      <div className="text-coolGray-300">
        {price} per {showYearWording ? 'year' : 'month'}
      </div>
    </div>
  </div>
);

export default BillingFrequency;
