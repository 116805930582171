import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Money, PartImage, classNames } from 'portal/components';

import styles from './Checkout.module.css';
import LineItemProcess from './LineItemProcess';

const PortalLineItem = ({ className, lineItem }) => {
  if (!lineItem) return null;

  const part = lineItem.part;

  // This is because sometimes we're dealing with a quote line item, and other times an order line item
  const getUnitPrice = lineItem =>
    get(lineItem, 'unit_price.amount') || get(lineItem, 'sales_price.amount');

  return (
    <div className={classNames([styles.PortalLineItem, className])}>
      <PartImage size="xs" src={part.current_revision?.screenshot} backdrop={false} />
      <div className={styles.PartInfo}>
        <div className="font-size-base">
          <strong>{part.name}</strong>
        </div>
        <div data-testid="line-item-process" className="font-size-md">
          <LineItemProcess data={lineItem} />
        </div>
      </div>
      <div className={styles.PartTotals}>
        <div className="mr-5">
          <div className="text-muted font-size-sm whitespace-nowrap">Qty</div>
          <div>
            <span data-testid="item-quantity">{lineItem.quantity}</span>
          </div>
        </div>
        <div className="mr-5">
          <div className="text-muted font-size-sm whitespace-nowrap">Unit price</div>
          <div>
            <div data-testid="item-unit-price">
              <Money amount={getUnitPrice(lineItem)} />
            </div>
          </div>
        </div>
        <div className="text-right">
          <div className="text-muted font-size-sm whitespace-nowrap">Item total</div>
          <div>
            <strong data-testid="item-total-price">
              <Money amount={get(lineItem, 'total_price.amount')} />
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

PortalLineItem.propTypes = {
  lineItem: PropTypes.object,
  className: PropTypes.string,
  showPrices: PropTypes.bool,
};

export default PortalLineItem;
