import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { FormField, FormSelect } from 'fr-shared/components';
import { WORK_ORDER_STATES } from 'fr-shared/lib/work_orders';

import {
  BuildTimeFormField,
  MPILinkFormField,
  MaterialAmountFormField,
  PrinterFormField,
} from './fields';

const WorkOrderDetailsSLA = ({ isEditing, readonly }) => {
  const formik = useFormikContext();
  const { state } = formik.values;

  return (
    <>
      <div className="card-body p-0">
        <div className="row border-bottom py-3 m-0">
          <div className="col-md">
            {isEditing && state !== 'Draft' && (
              <FormSelect
                label="State"
                name="state"
                className="mb-0"
                optionList={WORK_ORDER_STATES}
                valueAccessor={option => option}
                nameAccessor={option => option}
                readonly={readonly}
              />
            )}
          </div>
          <div className="col-md" />
        </div>

        <div className="row py-3 m-0">
          <div className="col-lg">
            <MaterialAmountFormField readonly={readonly} showGrams={isEditing} />
            <BuildTimeFormField />
          </div>
          <div className="col-lg">
            <FormField name="form_wash" label="Form Wash" />
            <FormField name="post_process_wash" label="Post Process Wash" />
            <FormField name="cure_notes" label="Cure Notes" />
            <MPILinkFormField readonly={readonly} />
          </div>
        </div>

        <div className="row border-bottom border-top py-3 m-0">
          <div className="col-lg">
            <FormField
              name="notes"
              label="Notes"
              md="2"
              className="mb-0"
              component="textarea"
              inputStyle={{ minHeight: 100 }}
              readonly={readonly}
            />
          </div>
        </div>

        <div className="row py-3 m-0">
          <div className="col-md">
            {isEditing && <PrinterFormField readonly={readonly} />}

            <div className="row form-group flex">
              <div className="col-md-4">
                <label htmlFor="material_lot_number_a">Lot</label>
              </div>
              <div className="col-md flex justify-content-around">
                <FormField
                  id="material_lot_number_a"
                  name="material_lot_number_a"
                  prepend="A"
                  className="mr-3"
                  readonly={readonly}
                />
              </div>
              <div className="col-md flex justify-content-around" />
            </div>
          </div>
          <div className="col-md" />
        </div>
      </div>
    </>
  );
};

WorkOrderDetailsSLA.propTypes = {
  isEditing: PropTypes.bool,
  readonly: PropTypes.bool,
};

export default WorkOrderDetailsSLA;
