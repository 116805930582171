import PropTypes from 'prop-types';
import React from 'react';

import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  UncontrolledDropdown,
} from 'fr-shared/components';

const FormAddNotesMenu = ({
  dataTestIdSuffix,
  onAddInternalNotes,
  onAddNotes,
  showAddInternalNotes,
  showAddNotes,
}) => {
  if (!showAddNotes && !showAddInternalNotes) {
    return null;
  }

  let notesTestId, internalNotesTestId;

  if (dataTestIdSuffix !== null) {
    notesTestId = `add-notes-${dataTestIdSuffix}`;
    internalNotesTestId = `add-internal-notes-${dataTestIdSuffix}`;
  }

  return (
    <UncontrolledDropdown className="ml-2 mb-1">
      <DropdownToggle tag="div" className="w-100">
        <Button className="w-100" outline color="secondary" size="md">
          <Icon name="plus" /> Add Notes <Icon name="caret-down" />
        </Button>
      </DropdownToggle>
      <DropdownMenu>
        {showAddNotes && (
          <DropdownItem data-testid={notesTestId} onClick={onAddNotes}>
            <strong>Notes</strong> Visible to everybody
          </DropdownItem>
        )}
        {showAddInternalNotes && (
          <DropdownItem data-testid={internalNotesTestId} onClick={onAddInternalNotes}>
            <strong>Internal Notes</strong> Only visible FR team
          </DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

FormAddNotesMenu.propTypes = {
  dataTestIdSuffix: PropTypes.any,
  onAddInternalNotes: PropTypes.func.isRequired,
  onAddNotes: PropTypes.func.isRequired,
  showAddInternalNotes: PropTypes.bool.isRequired,
  showAddNotes: PropTypes.bool.isRequired,
};

FormAddNotesMenu.defaultProps = {
  dataTestIdSuffix: null,
};

export default FormAddNotesMenu;
