import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'fr-shared/components';
import { useToggle } from 'fr-shared/hooks';
import { keyboardDown } from 'fr-shared/utils';
import {
  ADDITIVE_PART_FILE_EXTENSIONS,
  TRADITIONAL_PART_FILE_EXTENSIONS,
} from 'fr-shared/utils/files';

const AcceptedFileTypes = () => {
  const [showAll, toggleAll] = useToggle(false);

  const iconName = showAll ? 'minus' : 'plus';

  return (
    <div className="mt-1 mb-3">
      <div
        className="text-muted font-size-md cursor-pointer"
        onClick={toggleAll}
        onKeyDown={evt => keyboardDown(evt, 'Enter', toggleAll)}
        role="button"
        tabIndex={0}
      >
        Accepted file types
        <Icon name={iconName} type="fal" className="ml-1 opacity-50 font-size-sm" />
      </div>
      {showAll && (
        <div className="mt-1 font-size-sm">
          <div>
            For DLS, FDM, MJF, and SLA:{' '}
            <FormattedExtensions extensions={ADDITIVE_PART_FILE_EXTENSIONS} />
          </div>
          <div>
            For Cast Urethane, CNC, and Injection Molding:{' '}
            <FormattedExtensions extensions={TRADITIONAL_PART_FILE_EXTENSIONS} />
          </div>
        </div>
      )}
    </div>
  );
};

const FormattedExtensions = ({ extensions }) => {
  return extensions.map((ext, index) => {
    return (
      <span className="ml-1 font-weight-bold" key={ext}>
        {extensions.length - 1 === index ? ext : `${ext}, `}
      </span>
    );
  });
};

FormattedExtensions.propTypes = {
  extensions: PropTypes.arrayOf(PropTypes.string),
};

export default AcceptedFileTypes;
