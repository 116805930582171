import { useTreatments } from '@splitsoftware/splitio-react';
import React from 'react';

import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';

const ManufacturingProcessAdvice = () => {
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';

  return (
    <div className="mt-2 mb-3 px-3">
      <strong>How to add them</strong>
      <ol>
        <li>Duplicate the relevant {lineItemName}.</li>
        <li>
          Add <strong>-(TOOL-ONLY)</strong> to the end of the Part Name.
        </li>
        <li>Change Quantity to 1 (one).</li>
        <li>Adjust any form fields that are relevant to the tool.</li>
      </ol>

      <strong>Why to add them</strong>
      <p>
        Adding a tool {lineItemName} gives the External Supply Costing team member a place to
        input the tool cost for each unique part. These team members can’t add a tool{' '}
        {lineItemName} to a part themselves, nor can they add individual {lineItemName}s.
      </p>

      <strong>For Cast Urethane</strong>
      <p>
        You may want to quote a model—which is used to form a tool—in addition to a tool. To
        identify a model, add <strong>-(MODEL-ONLY)</strong> to the end of the Part Name.
      </p>

      <strong>For Injection Molding and Sheet Metal</strong>
      <p>
        A tool {lineItemName} might be needed. You dont’t need to request a tool cost if the
        customer already has one created for this part. If it’s a reorder, include the previous
        quote number in the Notes section.
      </p>
    </div>
  );
};

export default ManufacturingProcessAdvice;
