import React, { PropsWithChildren } from 'react';

import { IconFont } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { getInspectionTypeDisplayName } from 'fr-shared/lib/inspection_types';
import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';
import { keyboardDown } from 'fr-shared/utils';

import { Alert, ModalPrompt, classNames } from 'portal/components';
import { CartLineItem } from 'portal/lib/cart';

import styles from './LineItemCard.module.css';
import SupportingDocumentsLink from './SupportingDocumentsLink';

interface LineItemCardProps {
  alertMessage: string;
  footer?: JSX.Element;
  onClickTitle: () => void;
  onDelete: () => void;
  onEdit: () => void;
  readonly?: boolean;
  title: string;
}

export const LineItemCard = ({
  alertMessage,
  children,
  footer,
  onClickTitle,
  onDelete,
  onEdit,
  readonly = false,
  title,
}: PropsWithChildren<LineItemCardProps>) => {
  const userAnalytics = useUserAnalyticsContext();
  const handleOnRemoveItem = () =>
    userAnalytics.track(`Remove Item - Draft Quote`, { valid: true });

  return (
    <div className={styles.Root} data-testid="line-item-card">
      {alertMessage && (
        <Alert color="gray" className="cursor-pointer" onClick={onEdit}>
          <IconFont name="info-circle" /> {alertMessage}
        </Alert>
      )}
      <div className="flex align-items-center flex-col md:flex-row">
        <h5>
          <span
            onClick={onClickTitle}
            onKeyDown={evt => keyboardDown(evt, 'Enter', onClickTitle)}
            role="button"
            tabIndex={0}
            className={classNames([styles.Title, onClickTitle && 'cursor-pointer'])}
          >
            {title}
          </span>
        </h5>
        <div className="flex flex-1 justify-content-between">
          <div>
            {onEdit && (
              <button
                className={`${styles.ButtonLink} mr-2`}
                data-testid="line-item-card-edit-item-button"
                onClick={onEdit}
                disabled={readonly}
              >
                <IconFont name="pencil" /> Edit item
              </button>
            )}
          </div>
          <div>
            {onDelete && (
              <ModalPrompt
                title="Delete items"
                message="Are you sure you want to remove this item from your quote?"
                onConfirm={onDelete}
                action={
                  <button
                    className={styles.ButtonLink}
                    data-testid="line-item-card-remove-item-button"
                    disabled={readonly}
                  >
                    <IconFont name="trash" /> Remove item
                  </button>
                }
                onActionHandler={handleOnRemoveItem}
              />
            )}
          </div>
        </div>
      </div>
      <div className="row mt-3" data-testid="line-item-card-children">
        {children}
      </div>
      {footer && <div className="mt-3">{footer}</div>}
    </div>
  );
};

const StartCell = ({ children }: PropsWithChildren<{}>) => {
  return <div className={classNames([styles.StartCell, 'border-lg-right'])}>{children}</div>;
};

interface CenterCellItemProps {
  className?: string;
  label: string;
  value?: string | React.ReactNode | object;
}

const CenterCellItem = ({ label, value, className }: CenterCellItemProps) => {
  return (
    <div className={`${className} col-sm`}>
      <div className="text-gray font-size-sm">{label}</div>
      <div className={value ? 'text-light' : 'text-gray'}>{value || '—'}</div>
    </div>
  );
};

interface CenterCellProps {
  lineItem: CartLineItem;
}

const CenterCell = ({ lineItem }: CenterCellProps) => {
  return (
    <div className={classNames([styles.CenterCell, 'col-lg border-lg-right'])}>
      <div className="row mb-3">
        <CenterCellItem label="Qty" value={lineItem.quantity} />
        <CenterCellItem label="Process" value={lineItem.process_name} />
        <CenterCellItem label="Material" value={lineItem.material_name} />
        <CenterCellItem label="Color" value={lineItem.color_name} />
      </div>
      <div className="row">
        <CenterCellItem label="Finish" value={lineItem.finish_name} />
        <CenterCellItem
          label="Supporting docs"
          value={<SupportingDocumentsLink part={lineItem?.part} />}
        />
        <CenterCellItem
          className={lineItem.process_name === MANUFACTURING_PROCESSES.CNC ? 'invisible' : ''}
          label="Inspection"
          value={getInspectionTypeDisplayName(lineItem.inspection_type_name)}
        />
        <div className="col-sm" />
      </div>
      {lineItem.comments && (
        <div className="row mt-2">
          <div className="col">
            <div className={styles.Label}>Notes</div>
            <div className={styles.LabelValue}>{lineItem.comments}</div>
          </div>
        </div>
      )}
    </div>
  );
};

const EndCell = ({ children }: PropsWithChildren<{}>) => {
  return <div className={classNames([styles.EndCell, 'col-lg-3'])}>{children}</div>;
};

const MoreInfo = ({ children }: PropsWithChildren<{}>) => {
  return <div>{children}</div>;
};

interface ManufacturingDataCellProps {
  manufacturingDetails: {
    detailing_agent_lot_number: string;
    fusing_agent_lot_number: string;
    lot_quantity: number;
    mpi_link: string;
    powder_lot_number: string;
    printer_name: string;
  };
}
// TODO CX-711: Remove manufacturing details after MXD/J&J project is over.
const ManufacturingDataRow = ({ manufacturingDetails }: ManufacturingDataCellProps) => {
  return (
    <div className="flex justify-end w-[100%]">
      <div className={classNames([styles.StartCell])}></div>
      <div className={classNames([styles.ManufacturingDataCell, 'bg-coolGray-700 w-[100%]'])}>
        <div className="row mb-3">
          <CenterCellItem label="Powder Lot" value={manufacturingDetails.powder_lot_number} />
          <CenterCellItem
            label="Fusing Agent Lot"
            value={manufacturingDetails.fusing_agent_lot_number}
          />
          <CenterCellItem
            label="Detailing Agent Lot"
            value={manufacturingDetails.detailing_agent_lot_number}
          />
        </div>
        <div className="row">
          <CenterCellItem label="Printer" value={manufacturingDetails.printer_name} />
          <CenterCellItem label="UDI" value={manufacturingDetails.lot_quantity} />
          <CenterCellItem label="SOP" value={manufacturingDetails.mpi_link} />
        </div>
      </div>
    </div>
  );
};

LineItemCard.StartCell = StartCell;
LineItemCard.CenterCell = CenterCell;
LineItemCard.EndCell = EndCell;
LineItemCard.MoreInfo = MoreInfo;
LineItemCard.ManufacturingDataRow = ManufacturingDataRow;

export default LineItemCard;
