import { compact, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { FormCountries, FormField, FormStates } from 'fr-shared/components';
import { useAddresses, useForm } from 'fr-shared/hooks';
import { AddressTypeEnum } from 'fr-shared/lib/address';
import { COUNTRIES } from 'fr-shared/lib/countries';

import OrderAddressSelect from './OrderAddressSelect';

const formatAddress = address => {
  const orderedFields = [
    address.name_line_1,
    address.street_line_1,
    address.street_line_2,
    address.city,
    address.state,
    address.postal_code,
    address.country,
    address.email,
  ];
  return compact(orderedFields).join(', ');
};

const OrderBillingFields = ({ fieldPrefix, readonly, customerContactId }) => {
  const { values, setFieldValue } = useForm();
  const { defaultAddress, addresses } = useAddresses(customerContactId, AddressTypeEnum.Billing);

  const namePrefix = fieldPrefix ? `${fieldPrefix}.billing_address` : 'billing_address';
  const fieldName = field => `${namePrefix}.${field}`;

  const isNew = !(fieldPrefix ? get(values, fieldPrefix).id : values.id);
  const isCountryUnitedStates = get(values, fieldName('country')) === COUNTRIES.UNITED_STATES;

  useEffect(() => {
    if (defaultAddress && !readonly && isNew) {
      setFieldValue(namePrefix, defaultAddress);
    }
  }, [defaultAddress, namePrefix, setFieldValue, readonly, isNew]);

  const handleSelectAddress = addressAttrs => {
    setFieldValue(namePrefix, addressAttrs.address);
  };

  const handleNameOverwrite = name => {
    setFieldValue(fieldName('name_line_1'), name);
  };

  return (
    <>
      <OrderAddressSelect
        dropdownOptions={addresses}
        formatOptions={formatAddress}
        label="Full Name"
        name={fieldName('name_line_1')}
        onChange={handleSelectAddress}
        onCreateOption={handleNameOverwrite}
        readonly={readonly}
        required={true}
      />
      <FormCountries
        label="Country"
        name={fieldName('country')}
        readonly={readonly}
        required={true}
      />
      <FormField
        label="Address"
        name={fieldName('street_line_1')}
        readonly={readonly}
        required={true}
      />
      <FormField label="Address 2" name={fieldName('street_line_2')} readonly={readonly} />
      <FormField label="City" name={fieldName('city')} readonly={readonly} required={true} />
      {isCountryUnitedStates ? (
        <FormStates label="State" name={fieldName('state')} readonly={readonly} required={true} />
      ) : (
        <FormField label="State" name={fieldName('state')} readonly={readonly} required={false} />
      )}
      <FormField
        label="Zip"
        name={fieldName('postal_code')}
        readonly={readonly}
        required={true}
      />
      <FormField label="Email" name={fieldName('email')} readonly={readonly} />
    </>
  );
};

OrderBillingFields.defaultProps = {
  fieldPrefix: '',
};

OrderBillingFields.propTypes = {
  fieldPrefix: PropTypes.string,
  readonly: PropTypes.bool,
  customerContactId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default OrderBillingFields;
