import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Dropzone from 'react-dropzone';

import { api } from 'fr-shared/api';
import { Breadcrumb, Button, Helmet, Link } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { uploadCostingCSVToS3 } from 'fr-shared/lib/s3';

import ConfirmImportModal from './components/ConfirmImportModal';

const COMPONENT_STATES = {
  NoFileSelected: 'noFileSelected',
  FileSelected: 'fileSelected',
  InProgress: 'inProgress',
  Complete: 'complete',
};

const CostingCSVImportNew = () => {
  const { setAlert } = useContext(AlertContext);
  const [droppedFile, setDroppedFile] = useState(null);
  const [componentState, setComponentState] = useState(COMPONENT_STATES.NoFileSelected);

  const handleFileDrop = acceptedFiles => {
    if (componentState === COMPONENT_STATES.NoFileSelected && acceptedFiles.length > 0) {
      setDroppedFile(acceptedFiles[0]);
      setComponentState(COMPONENT_STATES.FileSelected);
    }
  };

  const uploadCostingCSVAndStartImport = () => {
    if (componentState !== COMPONENT_STATES.FileSelected || !droppedFile) {
      return;
    }

    setComponentState(COMPONENT_STATES.InProgress);
    uploadCostingCSVToS3(droppedFile)
      .then(uploadedFile => {
        return api.post('/costing_csv_imports', {
          costing_csv_import: {
            file_name: uploadedFile.file_name,
            s3_path: uploadedFile.s3_path,
          },
        });
      })
      .then(() => {
        setComponentState(COMPONENT_STATES.Complete);
      })
      .catch(() => {
        setAlert({
          message: 'There was an error. Please refresh the page and try again.',
          color: 'danger',
          autoClose: false,
        });
      });
  };

  return (
    <>
      <Helmet title="New Import" />
      <Breadcrumb to="/admin/costing_csv_imports">Imports</Breadcrumb>
      <Breadcrumb to="/admin/costing_csv_imports/new">New</Breadcrumb>

      <div className="px-3 bg-white">
        <div className="page-header flex mb-0">
          <h2>New Import</h2>
          <div className="page-actions mb-0">
            <div className="flex justify-content-end align-items-center">
              <Button to="/admin/costing_csv_imports" color="light">
                Back
              </Button>
              <ConfirmImportModal
                onConfirm={uploadCostingCSVAndStartImport}
                actionButton={
                  <Button
                    disabled={componentState !== COMPONENT_STATES.FileSelected || !droppedFile}
                    color="success"
                    className="ml-2"
                  >
                    Import
                  </Button>
                }
              />
            </div>
          </div>
        </div>

        <div className="w-100 bg-light card">
          <div className="flex align-items-center justify-content-center py-3">
            {componentState === COMPONENT_STATES.NoFileSelected && (
              <CSVFileDropzone onFileDrop={handleFileDrop} />
            )}

            {componentState === COMPONENT_STATES.FileSelected && (
              <p>File to Import: {droppedFile.name}</p>
            )}

            {componentState === COMPONENT_STATES.InProgress && <p>Uploading costing CSV.</p>}

            {componentState === COMPONENT_STATES.Complete && (
              <p>
                We are processing your import. We&apos;ll email you when it&apos;s ready. Click{' '}
                <Link to="/admin/costing_csv_imports">here</Link> to go back to the Imports page.
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const CSVFileDropzone = ({ onFileDrop }) => {
  return (
    <Dropzone onDrop={onFileDrop} accept=".csv" multiple={false}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} id="file-dropzone" />
          <Button color="primary">Select from Computer</Button>
        </div>
      )}
    </Dropzone>
  );
};

CSVFileDropzone.propTypes = {
  onFileDrop: PropTypes.func.isRequired,
};

export default CostingCSVImportNew;
