import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { AlertContext, useUserAnalyticsContext } from 'fr-shared/context';
import { useInspectionTypes } from 'fr-shared/hooks';
import { INSPECTION_TYPE_NAMES } from 'fr-shared/lib/inspection_types';
import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';

import { InspectionTypeForDefinition } from 'portal/components';
import { CartLineItem } from 'portal/lib/cart';

import PartFormConfig from './components/PartFormConfig';
import { CNC_DEFAULT_VALUES } from './util';

const PartConfigContainer = () => {
  const history = useHistory();
  const [lineItem, setCartLineItem] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const userAnalytics = useUserAnalyticsContext();

  const { data: inspectionTypes }: { data: InspectionTypeForDefinition[] } = useInspectionTypes();

  const { id: lineItemId } = useParams<{ id: string }>();

  /**
   * Load the cart line item into formik
   */
  const getCartLineItem = useCallback(() => {
    return api
      .get(`/customer_portal/cart_line_items/${lineItemId}`)
      .then(res => {
        let { data: cli } = res;

        // Sets default values when CNC was previously selected but the new cnc db values are still nulled
        if (cli?.manufacturing_process?.name === MANUFACTURING_PROCESSES.CNC) {
          cli = { ...CNC_DEFAULT_VALUES, ...cli };
        } else if (
          cli?.manufacturing_process?.name === MANUFACTURING_PROCESSES.InjectionMolding ||
          cli?.manufacturing_process?.name === MANUFACTURING_PROCESSES.CastUrethane
        ) {
          cli.inspection_type_id = inspectionTypes.find(
            inspectionType => inspectionType.name === INSPECTION_TYPE_NAMES.FirstArticleInspection
          );
        }
        setCartLineItem(cli);
      })
      .catch(() => {
        setAlert({
          color: 'danger',
          message: 'An unexpected error occurred, please refresh and try again',
        });
        history.push('/add-part');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCartLineItem();
  }, [getCartLineItem]);

  /**
   * Handler function for saving a line item.
   * We save the line item as it is in formik and then refresh the whole cart
   *
   * @param {CartLineItem} lineItem - the line item to save
   */
  const handleLineItemSaved = async (lineItem: CartLineItem) => {
    const isValid = true; // TODO: yup schema?

    try {
      await api.put(`/customer_portal/cart_line_items/${lineItem.id}`, { line_item: lineItem });
      userAnalytics.track('Part Saved', { isComplete: isValid });
      return true;
    } catch (error) {
      setAlert({
        color: 'danger',
        message: 'An unexpected error occurred, please refresh and try again',
      });
      return false;
    }
  };

  if (!lineItem) return null;

  return <PartFormConfig initialLineItem={lineItem} onLineItemSave={handleLineItemSaved} />;
};

export default PartConfigContainer;
