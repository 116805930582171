import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';

import { classNames } from 'fr-shared/components';

const PartFileDropzone = ({
  accept,
  childComponent,
  className,
  onFileDrop,
  onFileDropRejected = (_files, _event) => {},
  isDisabled = false,
  isLoading = false,
  testId = 'part-file-dropzone',
}) => (
  <Dropzone
    accept={accept}
    onDrop={onFileDrop}
    onDropRejected={onFileDropRejected}
    disabled={isDisabled || isLoading}
    noClick={true}
  >
    {({ getRootProps, getInputProps, isDragActive, open }) => (
      <div
        data-testid="file-upload-area"
        className={classNames([
          'part-file-dropzone',
          isDisabled && 'disabled',
          isDragActive && 'is-dragging',
          className,
        ])}
        style={{ backgroundColor: 'transparent' }}
        {...getRootProps()}
      >
        <input {...getInputProps()} id="file-dropzone" data-testid={testId} />
        {React.cloneElement(childComponent, { open })}
      </div>
    )}
  </Dropzone>
);

PartFileDropzone.propTypes = {
  accept: PropTypes.array,
  childComponent: PropTypes.element,
  className: PropTypes.string,
  onFileDrop: PropTypes.func.isRequired,
  onFileDropRejected: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  testId: PropTypes.string,
};

export default PartFileDropzone;
