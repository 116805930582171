import React from 'react';
import { BreadcrumbsItem, Breadcrumbs as DynamicBreadcrumbs } from 'react-breadcrumbs-dynamic';

import { Link, classNames } from 'fr-shared/components';

import styles from './Breadcrumbs.module.css';

export const Breadcrumb = props => <BreadcrumbsItem {...props} />;

export const Breadcrumbs = () => (
  <div className="bg-light">
    <BreadcrumbsItem to="/admin">Home</BreadcrumbsItem>
    <nav aria-label="breadcrumb">
      <ol className={styles.Breadcrumb}>
        <DynamicBreadcrumbs
          finalItem="li"
          finalProps={{ className: classNames([styles.BreadcrumbItem, styles.active]) }}
          item={props => (
            <li className={styles.BreadcrumbItem}>
              <Link {...props} />
            </li>
          )}
          container={React.Fragment}
        />
      </ol>
    </nav>
  </div>
);
