import { connect } from 'formik';
import { get, pickBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { COSTING_REQUEST_LI_STATES } from 'fr-shared/lib/costing_requests';
import { isFormikAtPrefixTheSame } from 'fr-shared/utils';

import { supplierCostSchema } from '../utils/schema';
import InternalCostingActions from './InternalCostingActions';
import ManageCostingRequestActions from './ManageCostingRequestActions';

const CostingLineItemActions = ({
  fieldPrefix,
  formik,
  isCustomerPortalAutoquote,
  lineItem,
  onDuplicateLineItem,
  onSaveLineItem,
}) => {
  const handleAddAdditionalSupplier = () => {
    // when creating a new supplier, read off of the initialValues to get
    // part quantities, since the user might have removed all suppliers
    const initialLineItem = get(formik.initialValues, fieldPrefix);

    const partQuantities = initialLineItem.supplier_costs[0].part_quantities.map(pq => ({
      quantity: pq.quantity,
    }));

    formik.setFieldValue(
      `${fieldPrefix}.supplier_costs`,
      lineItem.supplier_costs.concat({ part_quantities: partQuantities })
    );
  };

  const handleSubmitCosts = () => {
    supplierCostSchema
      .validate(lineItem, { abortEarly: false })
      .then(() => {
        formik.setErrors(pickBy(formik.errors, (value, key) => !key.includes(fieldPrefix)));
        onSaveLineItem(lineItem, fieldPrefix, {
          completed_at: moment(),
          in_progress: false,
          state: COSTING_REQUEST_LI_STATES.Completed,
        });
      })
      .catch(validation => {
        const newFields = validation.errors.reduce((acc, error) => {
          const [_, path, message] = /(.+?) (.*)/.exec(error);
          const field = `${fieldPrefix}.${path}`.replace(/\[(.+?)\]/g, '.$1');

          return { ...acc, [field]: message };
        }, {});
        formik.setErrors({ ...formik.errors, ...newFields });
      });
  };

  return (
    lineItem.state !== 'Closed' && (
      <div data-testid="costing-line-item-actions" className="card-footer bg-white border-top">
        <div data-testid="costing-request-actions">
          <InternalCostingActions
            lineItem={lineItem}
            fieldPrefix={fieldPrefix}
            onSaveLineItem={onSaveLineItem}
            onSubmitCosts={handleSubmitCosts}
            onAddAdditionalSupplier={handleAddAdditionalSupplier}
          />
          {!isCustomerPortalAutoquote && (
            <div className="mt-2" data-testid="manually-quoted-actions">
              <ManageCostingRequestActions
                fieldPrefix={fieldPrefix}
                lineItem={lineItem}
                onSaveLineItem={onSaveLineItem}
                opportunityId={formik.values.opportunity_id}
                publicId={formik.values.public_id}
                salesContactId={formik.values.sales_contact_id}
                values={formik.values}
                onDuplicateLineItem={onDuplicateLineItem}
              />
            </div>
          )}
        </div>
      </div>
    )
  );
};

CostingLineItemActions.propTypes = {
  fieldPrefix: PropTypes.string,
  formik: PropTypes.object,
  isCustomerPortalAutoquote: PropTypes.bool,
  lineItem: PropTypes.shape({
    state: PropTypes.string,
    supplier_costs: PropTypes.arrayOf(
      PropTypes.shape({
        part_quantities: PropTypes.arrayOf(
          PropTypes.shape({
            quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          })
        ),
      })
    ),
  }),
  onDuplicateLineItem: PropTypes.func,
  onSaveLineItem: PropTypes.func,
};

export default connect(React.memo(CostingLineItemActions, isFormikAtPrefixTheSame));
