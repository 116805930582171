import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useUserAnalyticsContext } from 'fr-shared/context';
import { ORDER_STATES } from 'fr-shared/lib/orders';

import { Card, StateBadge } from 'portal/components';
import { date } from 'portal/lib/date';

import PartsSlider from '../../PartsSlider/PartsSlider';

export const OrderCard = ({ order, dataTestId }) => {
  const userAnalytics = useUserAnalyticsContext();
  const history = useHistory();

  const images = get(order, 'line_items', [])
    .map(lineItem => lineItem.screenshot)
    .filter(screenshot => screenshot);

  const handleCardClicked = () => {
    userAnalytics.track('View Order', { valid: true });

    history.push(`/orders/${order.id}`);
  };

  return (
    <Card onClick={handleCardClicked} dataTestId={dataTestId}>
      <div className="p-3">
        <StateBadge state={order.state} className="mb-2">
          {order.state}
        </StateBadge>
        <div className="flex justify-content-between font-size-md">
          <div>
            <div className="font-size-sm">Order #</div>
            <div className="text-light">{order.public_id}</div>
          </div>
          <div>
            <OrderDateView order={order} />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <PartsSlider images={images} />
      </div>
    </Card>
  );
};

OrderCard.propTypes = {
  dataTestId: PropTypes.string,
  order: PropTypes.shape({
    id: PropTypes.number,
    state: PropTypes.string,
    public_id: PropTypes.string,
    line_items: PropTypes.array,
  }),
};

const OrderDateView = ({ order }) => {
  switch (order.state) {
    case ORDER_STATES.Shipped:
      return (
        <>
          <div className="font-size-sm">Shipped on</div>
          <div className="text-light">{date(order.actual_ship_date)}</div>
        </>
      );
    case ORDER_STATES.Canceled:
      return (
        <>
          <div className="font-size-sm">Date canceled</div>
          <div className="text-light">{date(order.updated_at)}</div>
        </>
      );
    default:
      return (
        <>
          <div className="font-size-sm">Est. ship date</div>
          <div className="text-light">{date(order.promised_ship_date)}</div>
        </>
      );
  }
};

OrderDateView.propTypes = {
  order: PropTypes.shape({
    actual_ship_date: PropTypes.string,
    promised_ship_date: PropTypes.string,
    updated_at: PropTypes.string,
    state: PropTypes.string,
  }),
};

export default OrderCard;
