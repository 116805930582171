import { isShippingMethod, shippingMethodToDisplayName } from 'fr-shared/lib/shipping';

// These values map to the shipping methods in lib/fast_radius/quotes/shipping_estimate.ex
export const CP_SHIPPING_METHODS = {
  Ground: 'ground',
  NextDayAir: 'next_day_air',
};

export const getShippingText = (shippingType: string): string => {
  if (!shippingType || !shippingType.trim()) return 'Shipping';
  if (isShippingMethod(shippingType)) {
    return shippingMethodToDisplayName(shippingType);
  }
  return shippingType;
};
