import '@fast-radius/shared-ui';
import React, { SyntheticEvent } from 'react';

import { ProgressCircle } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { numberInputMinValueFilter } from 'fr-shared/utils/input';

import { Button, FormFieldBase, Input, ModalPrompt, classNames } from 'portal/components';
import DfmCheckModal from 'portal/components/DfmCheckNotification/DfmCheckModal';
import {
  AutoquoteTiersResponseState,
  isAutoquoteError,
  isAutoquoteLoading,
} from 'portal/lib/autoquote';
import { CartLineItem, FailedManufacturabilityCheckCounts } from 'portal/lib/cart';

import AutoquoteErrorBox from './AutoquoteErrorBox';
import { PartFormState } from './PartFormFields';
import TierPriceInfoContainers from './TierPriceContainers';

type BottomPanelProps = {
  autoquoteResponseState: AutoquoteTiersResponseState;
  currentProcess: ManufacturingProcess;
  failedManufacturabilityCheckCounts: FailedManufacturabilityCheckCounts | null;
  formTouched: boolean;
  initialLineItem: CartLineItem;
  lineItem: PartFormState;
  handleBack: () => void;
  handleFieldChanges: (changes: Partial<PartFormState>) => void;
  handleSave: () => void;
  onStudio: () => void;
};

function BottomPanel({
  autoquoteResponseState,
  currentProcess,
  failedManufacturabilityCheckCounts,
  formTouched,
  initialLineItem,
  lineItem,
  handleBack,
  handleFieldChanges,
  handleSave,
  onStudio,
}: BottomPanelProps) {
  const userAnalytics = useUserAnalyticsContext();
  const nextButton = (
    <Button
      className="w-full"
      dataTestId="part-config-submit"
      onClick={handleSave}
      disabled={isAutoquoteLoading(autoquoteResponseState)}
    >
      Next
    </Button>
  );

  const renderNextButton = () => {
    const failedErrorChecksCount = failedManufacturabilityCheckCounts?.failed_error_checks_count;
    const failedWarningChecksCount =
      failedManufacturabilityCheckCounts?.failed_warning_checks_count;
    const hasChecks = failedErrorChecksCount > 0;

    if (hasChecks) {
      return (
        <DfmCheckModal
          onActionHandler={() => {
            userAnalytics.track('Part Config - Viewed red DFM confirmation modal');
          }}
          onContinue={() => {
            userAnalytics.track(
              'Part Config - Clicked "Continue anyway" red DFM confirmation modal'
            );
            handleSave();
          }}
          onSeeIssues={() => {
            userAnalytics.track(
              'Part Config - Clicked "See all issues" red DFM confirmation modal'
            );
            onStudio();
          }}
          errorCheckCount={failedErrorChecksCount}
          warningCheckCount={failedWarningChecksCount}
          action={nextButton}
        />
      );
    }
    return nextButton;
  };

  return (
    <div
      className={classNames([
        `bg-coolGray-800 p-3 gap-4 z-[1001] border-t border-coolGray-700 shadow-[0px_0px_12px_rgba(0,0,0,0.5)] w-full min-w-full`,
        `min-h-[220px]`,
      ])}
    >
      <div className="flex flex-row justify-between h-full">
        <div className="ml-1 flex flex-row items-center">
          {isAutoquoteLoading(autoquoteResponseState) ? (
            <div
              className="min-w-[238px] min-h-[140px] flex justify-center scale-[.4]"
              data-testid="loading"
            >
              <ProgressCircle percentage={75} spinner={true} backgroundColor="#191e25" />
            </div>
          ) : isAutoquoteError(autoquoteResponseState) ? (
            <AutoquoteErrorBox
              autoquoteError={autoquoteResponseState}
              lineItem={initialLineItem}
            />
          ) : (
            <TierPriceInfoContainers
              currentProcess={currentProcess}
              handleFieldChanges={handleFieldChanges}
              lineItem={lineItem}
              autoquoteTierList={autoquoteResponseState}
            />
          )}
        </div>

        <div className="flex flex-col justify-between w-[378px] h-full">
          <div className="ml-1">
            <FormFieldBase name="quantity" label="Quantity" isControl={true}>
              <Input
                name={'quantity'}
                value={lineItem.quantity}
                disabled={initialLineItem?.read_only}
                type="number"
                size="md"
                onBlur={(event: SyntheticEvent<HTMLInputElement>) => {
                  if (isNaN(event.currentTarget.valueAsNumber))
                    handleFieldChanges({ quantity: 1 });
                }}
                onChange={(event: SyntheticEvent<HTMLInputElement>) => {
                  handleFieldChanges({
                    quantity: numberInputMinValueFilter(event.currentTarget.valueAsNumber, 1),
                  });
                }}
                min={1}
                data-testid="part-config-quantity"
              />
            </FormFieldBase>
          </div>

          <div className="flex flex-row gap-3">
            <ModalPrompt
              onConfirm={handleBack}
              when={formTouched}
              title="You have unsaved changes"
              message="Are you sure you want to cancel?"
              action={
                <Button className="w-full" outline onClick={handleBack}>
                  Cancel
                </Button>
              }
            />
            {renderNextButton()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BottomPanel;
