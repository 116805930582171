import { connect } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, FormField, FormSelectSearch, Modal } from 'fr-shared/components';

import YieldReportFileUpload from './YieldReportFileUpload';

const formatSelectOptions = options => {
  if (options) {
    return options.map(o => {
      return { value: o.id, label: o.description };
    });
  } else {
    return [];
  }
};

const YieldReportMarkAllModal = ({ toggle, formik, availableRejectionReasons }) => {
  const { values, setFieldValue } = formik;
  const options = formatSelectOptions(availableRejectionReasons);
  const onFileCleared = () => {
    setFieldValue('mark_modal_uploaded_file', {});
  };

  const onFileUploaded = uploadedFile => {
    setFieldValue('mark_modal_uploaded_file', uploadedFile);
  };

  const handleMarkAllAsFailed =
    ({ withPartFailures }) =>
    () => {
      const { mark_modal_rejection_reason_id, mark_modal_comments, mark_modal_uploaded_file } =
        values;
      const failedLineItems = values.part_failure_updates.map(partFailureUpdate => {
        return {
          ...partFailureUpdate,
          part_failures: [
            {
              ...partFailureUpdate.part_failures[0],
              quantity: partFailureUpdate.line_item.quantity,
              rejection_reason_id:
                withPartFailures && mark_modal_rejection_reason_id
                  ? mark_modal_rejection_reason_id
                  : null,
              comments: withPartFailures && mark_modal_comments ? mark_modal_comments : '',
              link: '',
              ...(withPartFailures && mark_modal_uploaded_file
                ? mark_modal_uploaded_file
                : { s3_path: '' }),
            },
          ],
        };
      });
      setFieldValue('part_failure_updates', failedLineItems);
      toggle();
    };

  return (
    <div>
      <Modal.Header title="Complete Yield Report" onClose={toggle} />
      <div className="modal-body mb-2">
        {`To complete the yield report, you'll need to provide a reason for the failure. Select below to apply the same reason to all line items.`}
        <FormSelectSearch
          isStacked
          label="Select Reason"
          name="mark_modal_rejection_reason_id"
          options={options}
          required
        />
        <FormField name="mark_modal_comments" stacked className="mt-2" label="Comment" />
        <YieldReportFileUpload
          image={values.mark_modal_uploaded_file}
          onFileUploaded={onFileUploaded}
          onFileCleared={onFileCleared}
        >
          Attach Photo
        </YieldReportFileUpload>

        <div className="flex justify-content-end mt-3">
          <Button
            onClick={handleMarkAllAsFailed({ withPartFailures: false })}
            color="link"
            className="mr-2"
          >
            Edit Individually
          </Button>
          <Button onClick={handleMarkAllAsFailed({ withPartFailures: true })} color="success">
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

YieldReportMarkAllModal.propTypes = {
  toggle: PropTypes.func,
  formik: PropTypes.object,
  availableRejectionReasons: PropTypes.array,
};

export default connect(YieldReportMarkAllModal);
