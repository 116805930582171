import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { CardWrapper } from 'portal/components';
import { PartModel } from 'portal/types/parts';

import PartView from './PartView';

export interface PartsGridViewProps {
  pageName: string;
  parts: PartModel[];
  onSort?: (sortValue: string) => void;
}

export const PartsGridView = ({ parts, pageName }: PartsGridViewProps) => {
  return (
    <CardWrapper>
      {parts.map(part => (
        <PartView key={part.id} part={part} pageName={pageName} />
      ))}
    </CardWrapper>
  );
};

PartsGridView.propTypes = {
  pageName: PropTypes.string,
  parts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default memo(PartsGridView);
