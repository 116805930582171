import { debounce, isEmpty, isEqual } from 'lodash';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { AlertContext } from 'fr-shared/context';

const useTableFilters = (name, initialFilters = {}, fetchData) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [pages, setPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { setAlert } = useContext(AlertContext);

  const saveToLocalStorage = !isEmpty(name);

  const filterDefault = saveToLocalStorage
    ? JSON.parse(localStorage.getItem(`filters.${name}`)) || initialFilters
    : initialFilters;
  const [filters, setFilters] = useState(filterDefault);
  const [debouncedEvent, setDebouncedEvent] = useState(false);
  const latestFetchToken = useRef();

  const setFiltersAndPersist = useCallback(
    filters => {
      setFilters(filters);

      if (saveToLocalStorage) {
        localStorage.setItem(`filters.${name}`, JSON.stringify(filters));
      }
    },
    [name, saveToLocalStorage]
  );

  const performFetch = useCallback(
    (filters, fetchData) => {
      setLoading(true);

      /*
       * This technique prevents race conditions in fetching such that only the most recent request
       * is allowed to alter state.
       */
      const myFetchToken = Symbol();
      latestFetchToken.current = myFetchToken;

      fetchData(filters)
        .then(resp => {
          if (latestFetchToken.current === myFetchToken) {
            setData(resp.data);
            setPages(parseInt(resp.headers['total-pages']));
            setTotalItems(parseInt(resp.headers['total']));
          }
        })
        .catch(() => {
          if (latestFetchToken.current === myFetchToken) {
            setAlert({
              color: 'danger',
              message:
                'Failed to load the table with the given filters. Please refresh and try again.',
            });
          }
        })
        .finally(() => {
          if (latestFetchToken.current === myFetchToken) {
            setLoading(false);
          }
        });
    },
    [setAlert]
  );

  const debouncedPerformFetch = useCallback(debounce(performFetch, 700), [performFetch]);

  /**
   * When filter changes (by value), fetch new data. If debouncedEvent
   * is set, use the debounced version of the fetch function and unset
   * debouncedEvent. Otherwise use the normal data fetch function.
   */
  useEffect(() => {
    if (isEqual(filters, previousFilters.current)) return;

    if (debouncedEvent) {
      setDebouncedEvent(false);
      debouncedPerformFetch(filters, fetchData);
    } else {
      performFetch(filters, fetchData);
    }
  }, [debouncedEvent, debouncedPerformFetch, fetchData, filters, performFetch]);

  const previousFilters = useRef();
  useEffect(() => {
    previousFilters.current = filters;
  });

  const handleSearch = e => {
    const { name, value } = e.target;
    setDebouncedEvent(true);
    setFiltersAndPersist({ ...filters, [name]: value });
  };

  const onFetchData = state => {
    const newFilters = { ...filters, page: state.page + 1 };
    if (state.sorted.length > 0) {
      newFilters.sort_key = state.sorted[0].id;
      newFilters.sort_desc = state.sorted[0].desc;
    }
    setFiltersAndPersist(newFilters);
  };

  const resetFilters = () => {
    if (saveToLocalStorage) localStorage.removeItem(`filters.${name}`);
    setFilters({});
  };

  return {
    data,
    filters,
    handleSearch,
    onFetchData,
    pages,
    totalItems,
    resetFilters,
    refreshData: performFetch,
    setFilters: setFiltersAndPersist,
    loading,
  };
};

export default useTableFilters;
