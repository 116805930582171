import { Toast } from '@fast-radius/shared-ui';
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { Button, Modal } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { AddressTypeEnum } from 'fr-shared/lib/address';

import AddressSelector from '../../../checkout/components/AddressSelector';

interface ModalProps {
  title?: string;
  toggle?: () => void;
  action: ReactElement;
  onActionHandler?: () => void;
}

type SubscriptionBillingInfoModalProps = ModalProps & {
  currentSubscriptionId: number;
  onSubscriptionUpdate?: () => void;
};

const SubscriptionBillingInfoModal: React.FC<SubscriptionBillingInfoModalProps> = ({
  action,
  currentSubscriptionId,
  onActionHandler,
  onSubscriptionUpdate,
}) => {
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const [addresses, setAddresses] = useState([]);
  const [saveSuccess, setSaveSuccess] = useState(null);

  const { user } = useContext(UserContext);
  const refreshAddresses = useCallback(() => {
    api
      .get(`/customer_portal/account/${user.id}/billing_addresses`, {
        params: { sort_key: 'inserted_at', sort_desc: true },
      })
      .then(res => {
        setAddresses(res.data);
      });
  }, [user.id]);

  const handleUpdateSubscriptionAddress = () => {
    setSaveSuccess(null);

    if (addresses) {
      api
        .put(`/saas/subscriptions/${currentSubscriptionId}`, {
          billing_address_id: addresses[selectedAddressIndex].id,
        })
        .then(() => {
          setSaveSuccess(true);
          onSubscriptionUpdate();
        })
        .catch(() => setSaveSuccess(false));
    }
  };

  useEffect(() => {
    refreshAddresses();
  }, [refreshAddresses]);

  return (
    <>
      {saveSuccess ? (
        <Toast.Success iconName="check">Billing address updated</Toast.Success>
      ) : saveSuccess !== null ? (
        <Toast.Danger iconName="error-triangle">Failed to update billing address</Toast.Danger>
      ) : null}

      <Modal
        dataTestId="subscription-billing-info-modal"
        action={action}
        onActionHandler={onActionHandler}
      >
        {({ toggle }: ModalProps) => (
          <>
            <Modal.Header title="Change billing info" onClose={toggle} textUppercase={false} />
            <Modal.Body className="px-3">
              <div className="px-3 text-center pb-2">
                Update your billing information below. If you don&apos;t have any addresses saved
                you can save a new billing address below.
              </div>
              <AddressSelector
                addresses={addresses}
                onAddressAdded={refreshAddresses}
                onAddressIndexSelected={setSelectedAddressIndex}
                selectedAddressIndex={selectedAddressIndex}
                type={AddressTypeEnum.Billing}
              />
            </Modal.Body>
            <Modal.Footer className="justify-end">
              <Button
                outline
                onClick={() => {
                  handleUpdateSubscriptionAddress();
                  toggle();
                }}
              >
                Click to update
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default SubscriptionBillingInfoModal;
