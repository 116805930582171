import { Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { api } from 'fr-shared/api';
import { IconFont } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { mergeSchemas } from 'fr-shared/utils';

import { Button, Card, FormField } from 'portal/components';

import PasswordRequirement, {
  Schema as PasswordRequirementsSchema,
  passwordRequirements,
} from '../create_account/components/PasswordRequirement';

const ResetPassword = formik => {
  const location = useLocation();
  const history = useHistory();
  const userAnalytics = useUserAnalyticsContext();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const logEvt = valid => userAnalytics.track('Reset Password - Login', { valid });

  useEffect(() => {
    if (formik?.status?.error) logEvt(false);
    if (formik?.status?.resetSuccess) {
      logEvt(true);
      window.location = '/';
    }
  });

  if (!token) history.push('/');

  return (
    <Card size="lg">
      <h1 className="h6 text-light mb-4">
        <strong>Reset your password</strong>
      </h1>

      {formik.status && formik.status.error && (
        <p className="text-danger mb-4">
          <IconFont name="error-triangle" right />
          {formik.status.error}
        </p>
      )}

      <div className="row">
        <div className="col">
          <Form noValidate>
            <FormField
              type="password"
              label="Enter New Password"
              name="password"
              className="mb-3"
              floatingLabel={true}
              required
            />
            <FormField
              type="password"
              label="Confirm Password"
              name="password_confirmation"
              className="mb-3"
              floatingLabel={true}
              required
            />

            <div className="font-size-sm mb-3">
              {passwordRequirements.map((req, reqIndex) => (
                <PasswordRequirement
                  description={req.description}
                  isPassing={req.schema.isValidSync(formik.values.password)}
                  key={'password_requirement_' + reqIndex}
                />
              ))}
            </div>

            <div className="flex flex-column mt-2">
              <Button type="submit" className="px-5 py-2 my-2">
                Update Password
              </Button>
              <Button outline to="/">
                Back to Login
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Card>
  );
};

ResetPassword.propTypes = {
  formik: PropTypes.object,
};

export default withFormik({
  validateOnBlur: true,
  validateOnChange: false,
  validationSchema: Yup.object().shape({
    password: mergeSchemas(
      PasswordRequirementsSchema,
      Yup.string().required('Password is required')
    ),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  }),
  handleSubmit: ({ password }, { props, setStatus }) => {
    const searchParams = new URLSearchParams(props.history.location.search);
    const token = searchParams.get('token');

    api
      .patch(`/passwords/${token}`, { password, token })
      .then(() => {
        setStatus({ resetSuccess: true });
      })
      .catch(err => {
        try {
          setStatus({
            error: err.response.data.errors[0],
          });
        } catch (e) {
          setStatus({
            error: 'Sorry, something went wrong',
          });
        }
      });
  },
})(ResetPassword);
