import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { api } from 'fr-shared/api';
import { Loading } from 'fr-shared/components';

const BulkTraveler = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const params = useLocation().search;

  useEffect(() => {
    api
      .get(`/bulk_traveler${params}`)
      .then(res => {
        window.open(res.data.path);
        history.push('/admin/work_orders');
      })
      .finally(() => setLoading(false));
  }, [history, params]);

  return <div>{loading && <Loading />}</div>;
};

export default BulkTraveler;
