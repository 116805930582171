import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Alert, Icon, classNames } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';

const AlertBadge = ({ sm, md }) => {
  const { isOpen, color, message } = useContext(AlertContext);

  const icons = {
    danger: 'exclamation-circle',
    success: 'check-circle',
  };

  if (!isOpen) return null;

  return (
    <Alert
      className={classNames([
        'align-items-center',
        'border',
        `border-${color}`,
        'flex',
        'mb-0',
        'mr-2',
        'rounded',
        {
          'font-size-sm': sm,
          'font-size-md': md,
          'line-height-sm': sm,
          'line-height-md': md,
          'px-1': sm,
          'px-2': md,
          'py-0': sm,
          'py-1': md,
        },
      ])}
      color={color}
      isOpen={isOpen}
    >
      {icons[color] && <Icon name={icons[color]} className={'text-${color} mr-1'} />}
      {message}
    </Alert>
  );
};

AlertBadge.propTypes = {
  sm: PropTypes.bool,
  md: PropTypes.bool,
};

export default AlertBadge;
