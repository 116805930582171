import React from 'react';

import { api } from 'fr-shared/api';
import { useTableFilters } from 'fr-shared/hooks';
import { PART_SORT_VALUES } from 'fr-shared/lib/parts';

import { PageHeader } from 'portal/components';
import { PartsProvider } from 'portal/contexts/PartsContext';

import FileRedirectLink from '../parts/components/portalUploadContainer/FileRedirectLink';
import PortalUploadContainer from '../parts/components/portalUploadContainer/PortalUploadContainer';

const fetchData = filters => {
  return api.get('/customer_portal/parts', { params: filters });
};

const PortalQuoteV2Container = () => {
  const initialFilters = { page: 1, page_size: 19, sort_key: 'updated_at', sort_desc: true };

  const {
    data,
    loading,
    filters,
    handleSearch: handleTableSearch,
    pages,
    refreshData,
    setFilters,
  } = useTableFilters(null, initialFilters, fetchData);

  const handleSearch = value => {
    // TODO: useTableFilters expects a synthetic event, but we're controlling <Input />
    handleTableSearch({ target: { name: 'name', value } });
  };

  const handleSetPage = page => {
    setFilters({ ...filters, page });
  };

  const handleSort = sortValue => {
    const sortKeys = PART_SORT_VALUES;
    setFilters({
      ...filters,
      sort_key: sortKeys[sortValue],
      sort_desc: !filters.sort_desc,
    });
  };

  const { name: partName, sort_key: sortBy, sort_desc: isSortDesc } = filters;

  const parts = data ?? [];

  return (
    <PartsProvider
      currentSearchParam={partName}
      isSortDesc={isSortDesc}
      currentSortParam={sortBy}
      parts={parts}
    >
      <PortalUploadContainer
        fetchAllParts={() => refreshData(filters, fetchData)}
        filters={filters}
        handleSetPage={handleSetPage}
        loading={loading}
        onSearch={handleSearch}
        onSort={handleSort}
        pages={pages}
        parts={parts}
      >
        <PageHeader
          actions={<FileRedirectLink />}
          subtitle="Add new parts or select saved parts."
          title={window.location.pathname === '/parts' ? 'Parts' : 'Create Quote'}
        />
      </PortalUploadContainer>
    </PartsProvider>
  );
};

export default PortalQuoteV2Container;
