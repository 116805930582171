import axios from 'axios';

import { api } from 'fr-shared/api';
import { getExtension, isAllowed, isPartFile, sanitizeFilename } from 'fr-shared/utils/files';

interface S3Response {
  data: {
    presigned_url: string;
    path: string;
  };
}

interface FRUser {
  id: number;
}

interface FRDocument {
  file_name?: string;
  file_type?: string;
  s3_path?: string;
  created_by_id?: number;
  url?: string;
}

interface Part {
  id?: string;
  documents?: Array<FRDocument>;
  revisions?: Array<PartFileRevision>;
  organization_id?: string;
  source?: string;
  units?: string;
}

interface PartFileRevision {
  name?: string;
  s3_path?: string;
}

export const uploadPartToS3AndFros = (
  file: File,
  partParams: Part,
  partUrl: string = '/parts'
) => {
  const s3Endpoint = '/s3/sign/part_file';
  if (!isAllowed(file.name)) {
    return Promise.reject(file.name + ' is a file type that is not allowed for upload.');
  }
  return api
    .post(s3Endpoint, {
      filename: sanitizeFilename(file.name),
    })
    .then((res: S3Response) => {
      return axios
        .put(res.data.presigned_url, file, {
          headers: { 'content-type': file.type },
        })
        .then(() => {
          const part = { ...partParams, name: file.name };

          // Part files are intended to be 3D representations of a part
          // and are stored as revisions,
          // whereas documents are supplementary files of any filetype
          // that help round out the context for making the part.
          if (isPartFile(file.name)) {
            part.revisions = [
              {
                name: file.name,
                s3_path: res.data.path,
              },
            ];
          } else {
            part.documents = [
              {
                file_name: file.name,
                s3_path: res.data.path,
              },
            ];
          }

          if (part.id) {
            return api.put(`${partUrl}/${part.id}`, { part: part });
          } else {
            return api.post(partUrl, { part: part });
          }
        });
    });
};

export const uploadDocumentsToS3 = (files: File[], user: FRUser, s3Endpoint: string) => {
  return new Promise((resolve, reject) => {
    const uploadPromises = files.map(file => {
      if (!isAllowed(file.name)) {
        return Promise.reject(file.name + ' is not allowed for upload based on file type.');
      }

      const ext = getExtension(file.name);
      return api
        .post(s3Endpoint, {
          filename: sanitizeFilename(file.name),
        })
        .then((res: S3Response) => {
          return axios
            .put(res.data.presigned_url, file, {
              headers: { 'content-type': file.type },
            })
            .then(() => {
              const document: FRDocument = {
                s3_path: res.data.path,
                created_by_id: user.id,
                file_name: file.name,
                file_type: ext,
                url: URL.createObjectURL(file),
              };
              return document;
            })
            .catch(error => Promise.reject({ error: error, file: file }));
        })
        .catch(error => Promise.reject({ error: error, file: file }));
    });

    Promise.all(uploadPromises).then(resolve).catch(reject);
  });
};

export const uploadCostingCSVToS3 = (file: File) => {
  return api
    .post('/s3/sign/costing_csv_file', {
      filename: sanitizeFilename(file.name),
    })
    .then((res: S3Response) => {
      return axios
        .put(res.data.presigned_url, file, {
          headers: { 'content-type': file.type },
        })
        .then(() => {
          return {
            file_name: file.name,
            s3_path: res.data.path,
          };
        });
    });
};
