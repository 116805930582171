import React from 'react';

import { Button } from 'portal/components';

import AlertBox from '../dfm/AlertBox';

interface Props {
  isSubmitting?: boolean;
  onRequestChecks?: () => void;
  selectedManufacturingProcess?: ManufacturingProcess;
  selectedMaterialId?: number;
}
const MaterialsTab = ({
  isSubmitting,
  onRequestChecks,
  selectedManufacturingProcess,
  selectedMaterialId,
}: Props) => {
  if (selectedManufacturingProcess?.materials?.length === 0) {
    return (
      <AlertBox title="Material Properties" className="mt-2">
        <p className="text-muted mt-2">
          Material information isn&apos;t available for {selectedManufacturingProcess.name}{' '}
          materials yet.
        </p>
        <p className="text-muted mt-2">
          But if you send us a request, we can help and provide more information.
        </p>
        <Button loading={isSubmitting} block outline onClick={onRequestChecks}>
          Request more information
        </Button>
      </AlertBox>
    );
  }

  if (!selectedManufacturingProcess?.id || !selectedMaterialId) {
    return (
      <AlertBox title="Material Properties" className="mt-2">
        <p className="text-muted mt-2">
          We can show you properties and performance metrics for all our materials.
        </p>
        <p className="text-muted my-2">
          You can also compare properties across different manufacturing processes.
        </p>
        <p className="mt-2 text-muted">
          Just choose a{' '}
          {!selectedManufacturingProcess?.id && (
            <span className="text-white">manufacturing process</span>
          )}
          {!selectedManufacturingProcess?.id && !selectedMaterialId && ' and '}
          {!selectedMaterialId && <span className="text-white">material</span>} to get started
        </p>
      </AlertBox>
    );
  }

  return null;
};

export default MaterialsTab;
