import { useFormikContext } from 'formik';
import { get, isFunction, isNil, uniqueId } from 'lodash';
import { useCallback } from 'react';

import { useFormikRef } from 'fr-shared/hooks';

const useDuplicateLineItem = (lineItemsPrefix, defaultLineItem = {}) => {
  const formik = useFormikContext();
  const formikRef = useFormikRef();

  const duplicateLineItem = useCallback(
    lineItemIndex => {
      const lineItem = get(formik, `values.${lineItemsPrefix}.${lineItemIndex}`);
      const lineItems = get(formik, `values.${lineItemsPrefix}`);
      if (isNil(lineItem)) return;

      const uuid = uniqueId('dup-line-item-');
      const newLineItem = {
        ...lineItem,
        ...(isFunction(defaultLineItem) ? defaultLineItem(lineItem) : defaultLineItem),
        _id: uuid,
        duplicated: true,
        upload_status: null,
      };
      formik.setFieldValue(lineItemsPrefix, [...lineItems, newLineItem]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lineItemsPrefix, defaultLineItem, formik.values]
  );

  const bulkDuplicateLineItems = useCallback(
    lineItems => {
      const newLineItems = lineItems.map(lineItem => {
        const uuid = uniqueId('dup-line-item-');
        return {
          ...lineItem,
          ...(isFunction(defaultLineItem) ? defaultLineItem(lineItem) : defaultLineItem),
          _id: uuid,
          duplicated: true,
          upload_status: null,
        };
      });

      lineItems = get(formikRef.current, `values.${lineItemsPrefix}`);
      formikRef.current.setFieldValue(lineItemsPrefix, [...lineItems, ...newLineItems]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lineItemsPrefix, defaultLineItem, formik.values]
  );

  return { duplicateLineItem, bulkDuplicateLineItems };
};

export default useDuplicateLineItem;
