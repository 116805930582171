import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { useUserAnalyticsContext } from 'fr-shared/context';

import { Link, StateBadge } from 'portal/components';
import { date } from 'portal/lib/date';

import styles from './Shipment.module.css';

const Shipment = ({ lineItems, index, status }) => {
  const userAnalytics = useUserAnalyticsContext();
  const handleTrackShipment = () => userAnalytics.track('Track Shipment', { valid: true });

  if (isEmpty(lineItems)) return null;

  // Each line item should have the same tracking link
  const trackingNumber = lineItems[0].trackingNumber;
  const shippedDate = lineItems[0].shippedDate;

  return (
    <div className={styles.Root}>
      <div className="row justify-content-between">
        <div className="col">
          <div className="text-gray font-size-sm">Shipment</div>
          <div className="text-light font-size-md">Shipment {index + 1}</div>
        </div>

        <div className="col">
          <div className="text-gray font-size-sm">Status</div>
          <div className="text-light font-size-md">
            <StateBadge glow={true} state={status}>
              {status}
            </StateBadge>
          </div>
        </div>

        <div className="col">
          <div className="text-gray font-size-sm">Tracking Link</div>
          <div className="font-size-md">
            {isEmpty(trackingNumber) ? (
              '—'
            ) : (
              <Link
                to={{
                  pathname: `https://www.google.com/search?q=${trackingNumber}`,
                }}
                openNewWindow
                onClick={handleTrackShipment}
              >
                Track shipment
              </Link>
            )}
          </div>
        </div>

        <div className="col">
          <div className="text-gray font-size-sm">Shipped on</div>
          <div className="text-light font-size-md">{date(shippedDate)}</div>
        </div>

        <div className="col">
          <div className="text-gray font-size-sm">Item name</div>
          {lineItems.map((lineItem, lineItemIndex) => (
            <div
              className="text-light font-size-md"
              key={`${index}_shipment_line_name_${lineItemIndex}`}
            >
              {lineItem.description}
            </div>
          ))}
        </div>

        <div className="col text-right">
          <div className="text-gray font-size-sm">Qty</div>
          {lineItems.map((lineItem, lineItemIndex) => (
            <div className="text-light" key={`${index}_shipment_line_qty_${lineItemIndex}`}>
              <span className="font-size-sm">{lineItem.shippedQuantity}</span> /
              <span className="text-gray font-size-sm"> {lineItem.orderQuantity}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Shipment.propTypes = {
  index: PropTypes.number,
  status: PropTypes.string,
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      orderQuantity: PropTypes.number.isRequired,
      shippedDate: PropTypes.string.isRequired,
      shippedQuantity: PropTypes.number.isRequired,
      trackingNumber: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default Shipment;
