import { SALESFORCE_CALLS_ENABLED } from 'fr-shared/feature_flags';
import { useFeatureFlags } from 'fr-shared/hooks';

const useSalesforceEnabledFeatureFlag = () => {
  const { [SALESFORCE_CALLS_ENABLED]: salesforceCallsEnabled } = useFeatureFlags({
    [SALESFORCE_CALLS_ENABLED]: 'on',
  });

  return salesforceCallsEnabled;
};

export default useSalesforceEnabledFeatureFlag;
