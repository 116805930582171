import { useTreatments } from '@splitsoftware/splitio-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Alert, Icon } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { CR_BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';

const RequestedLineItems = ({ requestedLineItemsCount }) => {
  const { [CR_BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';
  const { user } = useContext(UserContext);

  if (requestedLineItemsCount === 0) return null;

  return (
    <Alert className="py-1 mr-3 mb-0" color={user.canCreateCostingRequests ? 'info' : 'warning'}>
      <Icon name={user.canCreateCostingRequests ? 'info-circle' : 'exclamation-triangle'} right />
      {requestedLineItemsCount === 1
        ? `1 ${lineItemName} waiting to be costed`
        : `${requestedLineItemsCount} ${lineItemName}s waiting to be costed`}
    </Alert>
  );
};

RequestedLineItems.propTypes = {
  requestedLineItemsCount: PropTypes.number,
};

export default React.memo(RequestedLineItems);
