import { Field } from 'formik';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select';

import { classNames } from 'fr-shared/components';

export default class LineItemSelect extends Component {
  static propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    md: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.object,
    styles: PropTypes.object,
    options: PropTypes.array,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    components: PropTypes.object,
    placeholder: PropTypes.string,
  };

  handleChange = field => {
    const { name, onChange } = this.props;
    // this is going to call setFieldValue (formik) and manually update values
    const e = { name: name, ...field };
    onChange && onChange(e);
  };

  handleBlur = () => {
    const { onBlur, name } = this.props;
    onBlur && onBlur(name, true);
  };

  render() {
    const {
      md,
      name,
      label,
      style,
      styles,
      options,
      disabled,
      children,
      className,
      components,
      placeholder,
    } = this.props;

    return (
      <Field name={name}>
        {({ field }) => {
          const value = options ? options.find(option => option.value === field.value) : '';
          return (
            <div className="row form-group">
              {label && (
                <div className={`col-md-${md ? md : 3}`}>
                  <label htmlFor={name}>{label}</label>
                </div>
              )}
              <div className={classNames(['col-md', className])} style={style}>
                <Select
                  classNamePrefix="react-select"
                  isDisabled={disabled}
                  styles={styles}
                  components={components}
                  options={options}
                  placeholder={placeholder}
                  value={value}
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                />
                {children}
              </div>
            </div>
          );
        }}
      </Field>
    );
  }
}
