/* eslint-disable react/prop-types */
import { useTreatments } from '@splitsoftware/splitio-react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';

import { api } from 'fr-shared/api';
import { FirstOrderBadge } from 'fr-shared/components';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';

class OrderPicker extends Component {
  static propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
  };

  orderSearch = debounce((inputValue, callback) => {
    api
      .get('/orders', { params: { page_size: 1000, public_id: inputValue, not_draft: 'true' } })
      .then(res => {
        // format the search results for the dropdown
        const results = res.data.map(o => ({
          label: o.public_id,
          value: o.public_id,
          order: o,
        }));
        // set the options of the dropdown to the search results
        callback(results);
      });
  }, 500);

  render() {
    const { name, onChange } = this.props;

    return (
      <AsyncSelect
        name={name}
        classNamePrefix="react-select"
        placeholder="Search Orders by Public ID"
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          SingleValue: SingleValue,
          Option: Option,
        }}
        noOptionsMessage={() => 'No orders found...'}
        onChange={onChange}
        loadOptions={this.orderSearch}
      />
    );
  }
}

export default OrderPicker;

const SingleValue = props => {
  const { className, data, cx, isDisabled, innerProps } = props;
  return (
    <div
      className={cx(
        {
          'single-value': true,
          'single-value--is-disabled': isDisabled,
        },
        'flex align-items-center',
        className
      )}
      {...innerProps}
    >
      <span className="mr-2">FR {data.order.public_id}</span>
      <span className="mr-2">{data.order.customer.name}</span>
      {data.order.is_first_order && <FirstOrderBadge fontSize="sm" />}
    </div>
  );
};

const Option = props => {
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const allcapLineItemName =
    buildPackUiSupportFlag.treatment === 'on' ? 'BUILD PACK' : 'LINE ITEM';

  const { data, className, cx, isDisabled, isFocused, isSelected, innerRef, innerProps } = props;
  return (
    <div
      {...innerProps}
      ref={innerRef}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className
      )}
    >
      <div className="flex w-100 align-items-center">
        <div className="text-muted mr-2">FR {data.order.public_id}</div>
        <div className="mr-2">{data.order.customer.name}</div>
        {data.order.is_first_order && <FirstOrderBadge fontSize="sm" />}
        <div className="ml-auto">
          <span>
            <small className="text-muted mr-1">{allcapLineItemName}S</small>
            {data.order.line_items.length}
          </span>
        </div>
      </div>
    </div>
  );
};
