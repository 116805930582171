// keep in sync with fast-radius/lib/fast_radius/accounts/role.ex

export const ROLES = {
  Admin: 'Admin',
  ApplicationEngineering: 'Application Engineering',
  Costing: 'Costing',
  FastRadius: 'Fast Radius',
  Itar: 'ITAR',
  Management: 'Management',
  Member: 'Member',
  Operations: 'Operations',
  OperationsCostManagement: 'Operations - Cost Management',
  OperationsProjectManagement: 'Operations - Project Management',
  OperationsTechnicians: 'Operations - Technicians',
  Owner: 'Owner',
  Portal: 'Portal',
  ProjectEngineering: 'Project Engineering',
  Quoting: 'Quoting',
  Sales: 'Sales',
  SupplierPortalSupport: 'Supplier Portal Support',
  SupplierPortalUser: 'Supplier Portal User',
  CustomerPortalOrgAccessUser: 'Customer Portal Org Access User',
  SupplierManagement: 'Supplier Management',
};
