import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { api } from 'fr-shared/api';
import { Pagination, ProgressCircle } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import {
  useComparisonToolV2FeatureFlag,
  usePortalBulkUploadFeatureFlag,
  useTableFilters,
} from 'fr-shared/hooks';
import { PART_SORT_VALUES } from 'fr-shared/lib/parts';

import {
  AddPartCard,
  CardWrapper,
  Page,
  PageHeader,
  PageResults,
  PageSearch,
  PageToolbar,
  PageViewToggle,
} from 'portal/components';
import { PartsProvider } from 'portal/contexts/PartsContext';

import PartsGridView, { PartsGridViewProps } from '../components/PartsGridView/PartsGridView';
import PartsListView from '../components/PartsListView/PartsListView';

const fetchData = (filters: any) => {
  return api.get('/customer_portal/parts', { params: filters });
};

type MemoizedDisplayComponent = React.MemoExoticComponent<
  ({ parts, pageName }: PartsGridViewProps) => JSX.Element
>;

const CompareContainer = () => {
  const userAnalytics = useUserAnalyticsContext();
  const [isGridView, setIsGridView] = useState(true);
  const isPortalBulkUploadOn = usePortalBulkUploadFeatureFlag();

  const initialFilters = {
    page: 1,
    page_size: isPortalBulkUploadOn ? 19 : 20,
    sort_key: 'updated_at',
    sort_desc: true,
  };

  const {
    data,
    loading,
    handleSearch: handleTableSearch,
    pages,
    filters,
    setFilters,
    totalItems,
  } = useTableFilters(null, initialFilters, fetchData);

  const handleSetPage = (page: number) => {
    setFilters({ ...filters, page });
  };

  const handleGridToggle = () => {
    setIsGridView(!isGridView);
    userAnalytics.track(`${isGridView ? 'List' : 'Card'} View - Compare`, { valid: true });
  };

  const handleSearch = (value: string) => {
    // TODO: useTableFilters expects a synthetic event, but we're controlling <Input />
    handleTableSearch({ target: { name: 'name', value } });
  };

  const handleSort = (sortValue: string) => {
    const sortKeys: { [key: string]: string } = PART_SORT_VALUES;
    setFilters({
      ...filters,
      sort_key: sortKeys[sortValue],
      sort_desc: !filters.sort_desc,
    });
  };

  const DisplayComponent: MemoizedDisplayComponent = isGridView ? PartsGridView : PartsListView;
  const parts = data ?? [];
  const { name: partName, sort_key: sortBy, sort_desc: isSortDesc } = filters;

  const totalParts = partName ? parts.length : totalItems;

  const renderBody = () => {
    if (loading)
      return (
        <div className="flex justify-center mt-32">
          <ProgressCircle
            percentage={75}
            spinner={true}
            backgroundColor="#111115"
            fadesIntoBackground
          />
        </div>
      );
    else if (isEmpty(parts))
      return (
        <CardWrapper>
          <AddPartCard />
        </CardWrapper>
      );
    else return <DisplayComponent parts={parts} onSort={handleSort} pageName="Parts" />;
  };

  const isCompareV2Enabled = useComparisonToolV2FeatureFlag();
  const header = isCompareV2Enabled ? 'Compare specs' : 'Start comparing specs';
  const subtitle = isCompareV2Enabled
    ? 'See which process and material gives you the best cost and lead time for your part.'
    : 'Upload a file from your computer or select a part you’ve already added.';

  return (
    <PartsProvider
      currentSearchParam={partName}
      isSortDesc={isSortDesc}
      currentSortParam={sortBy}
      parts={parts}
    >
      <Page>
        <PageHeader title={header} subtitle={subtitle} />

        <PageToolbar>
          <div className="text-lg text-coolGray-100">Add or select a part</div>
          <PageSearch
            className="ml-auto"
            onSearch={handleSearch}
            placeholder="Search by part name"
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              userAnalytics.trackFieldEvents({
                e,
                logType: 'string',
                trackName: 'Compare - Search',
                customValidation: eVal => Boolean(eVal.trim()),
              });
            }}
          />
          <PageResults value={totalParts} context="part" />
          <PageViewToggle toggled={isGridView} onToggle={handleGridToggle} />
        </PageToolbar>

        <div className="mt-5">{renderBody()}</div>

        {pages > 1 && !loading && (
          <div className="flex-1 mt-3 flex align-items-end">
            <Pagination
              page={filters.page}
              setPage={handleSetPage}
              totalPages={pages}
              pageNumberStyle={'numbered'}
            />
          </div>
        )}
      </Page>
    </PartsProvider>
  );
};

CompareContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default CompareContainer;
