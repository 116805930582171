import { Form, Formik } from 'formik';
import React, { useContext } from 'react';

import { Button, FormField, FormSelect } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';

interface PrinterFormProps {
  locations?: Location[];
  manufacturingProcesses?: ManufacturingProcess[];
  onDelete?: () => void;
  onSubmit: (values: any, actions: any) => void;
  printer?: Printer;
  printerTypes?: PrinterType[];
}

const PrinterForm: React.FC<PrinterFormProps> = ({
  locations,
  printer,
  printerTypes,
  onSubmit,
  onDelete = null,
}: PrinterFormProps) => {
  const { user }: UserContext = useContext(UserContext);

  const initialValues = {
    id: printer?.id,
    name: printer?.name,
    location_id: printer?.location?.id,
    printer_type_id: printer?.printer_type?.id,
  };

  // TODO: Best way to scope Submit-disabled per-action while reusing overall markup?
  return (
    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={onSubmit}>
      <Form>
        <div className="row">
          <div className="col-md">
            <FormField name="name" label="Name" />
            <FormSelect
              hasBlankOption={false}
              label="Printer Type"
              name="printer_type_id"
              optionList={printerTypes}
              placeholder={'Printer Type'}
              required={true}
              sorted={true}
            />
            <FormSelect
              hasBlankOption={false}
              label="Location"
              name="location_id"
              optionList={locations}
              placeholder={'Location'}
              required={true}
              sorted={true}
            />
          </div>
        </div>
        <div>
          <div className="ml-auto">
            <Button
              disabled={!(user.canCreatePrinters || user.canUpdatePrinters)}
              type="submit"
              color="success"
            >
              Submit
            </Button>
            {onDelete && user.canDeletePrinters && (
              <Button color="danger" outline={true} className="ml-2" onClick={onDelete}>
                Delete
              </Button>
            )}
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default PrinterForm;
