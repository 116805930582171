import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { ButtonLink, IconFont } from 'fr-shared/components';
import { AlertContext, UserContext } from 'fr-shared/context';
import { pluralize } from 'fr-shared/utils';
import {
  ADDITIVE_PART_FILE_EXTENSIONS_PORTAL,
  PART_FILE_EXTENSIONS_PORTAL,
  TRADITIONAL_PART_FILE_EXTENSIONS_PORTAL,
} from 'fr-shared/utils/files';

import {
  Button,
  FormToggleSwitch,
  PartDropzone,
  PartUploadModal,
  SupportedFileTypesModal,
} from 'portal/components';
import { handleFileDrop, handleS3PartCreated } from 'portal/lib/upload';

import styles from './Dropzone.module.css';

interface AddPartDropzoneProps {
  source?: string;
  shouldRedirectToPartViewer?: boolean;
  shouldAddPartToCart?: boolean;
  onCompleteUpload?: (uploadedParts: Array<any>) => void;
}

export const AddPartDropzone = ({
  shouldRedirectToPartViewer = true,
  shouldAddPartToCart = true,
  source,
  onCompleteUpload,
}: AddPartDropzoneProps) => {
  const formik: any = useFormikContext();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { closeAlert, setAlert } = useContext(AlertContext);
  const [loadingText, setLoadingText] = useState('');
  const [analysisPayload, setAnalysisPayload] = useState(null);
  const [isAnalyzingLastPart, setIsAnalyzingLastPart] = useState(true);
  // Using a state variable to show the modal because without it, and without
  // setting this state variable inside of the handlers, there's some janky
  // looking transitions/re-sizing.
  const [partUploadModalState, setPartUploadModalState] = useState('upload');
  const [shouldShowPartUploadModal, setShouldShowPartUploadModal] = useState(false);
  const [uploadedParts, setUploadedParts] = useState([]);

  const partCreatedAndAddedToCartHandler = async (part: any, isOnFirstCLIRequest: any = true) => {
    return handleS3PartCreated({
      part,
      cartLineItemPostSuccess: () => {
        if (!isOnFirstCLIRequest) return;

        setPartUploadModalState('analyze');
        setAnalysisPayload({ part });
      },
      cartLineItemPostFail: () => {
        if (!isOnFirstCLIRequest) return;

        setShouldShowPartUploadModal(false);

        setAlert({
          color: 'danger',
          message: 'An unexpected error occurred, please refresh and try again',
        });
      },
    });
  };

  const partCreatedHandler = async (part: any, isOnLastCLIRequest: any = true) => {
    setIsAnalyzingLastPart(isOnLastCLIRequest);
    setPartUploadModalState('analyze');
    setAnalysisPayload({ part });
  };

  const cartLineItemPostHandler = shouldAddPartToCart
    ? partCreatedAndAddedToCartHandler
    : partCreatedHandler;

  const onFileDropHandler = (acceptedFiles: any) => {
    setShouldShowPartUploadModal(true);
    // NOTE: the purpose of this is to ensure the `PartDropzone.Analyzing` component
    // does not open if a user uploads a part and quickly closes it before re-uploading
    // again. Without it, the analysis payload will still be set and the `onComplete`
    // handler will get called for the _previously_ uploaded part.
    setAnalysisPayload(null);

    handleFileDrop({
      acceptedFiles,
      loadHandlers: {
        initLoadingText: () => {
          closeAlert();
          setLoadingText(pluralize('Uploading file', acceptedFiles.length > 1) + '...');
        },
        clearLoadingText: () => setLoadingText(''),
        loadingError: () => {
          setShouldShowPartUploadModal(false);
          setAlert({
            color: 'danger',
            message:
              'Unfortunately, we could not upload your file since the format was not accepted. See "Supported file types" below to see what file formats you can upload.',
          });
        },
      },
      s3LoadingHandlers: {
        uploadError: () => {
          setShouldShowPartUploadModal(false);
          history.push(`/studio/upload-fail`);
        },
      },
      cartLineItemPostHandler: ({ part }: any, isOnFirstCLIRequest: any) =>
        cartLineItemPostHandler(part, isOnFirstCLIRequest),
      user,
      quote_units: formik?.values?.units_in_mm ? 'mm' : 'in',
      source,
      onUploadComplete: (parts: Array<any>) => {
        setUploadedParts(parts);
      },
      shouldRedirectToPartViewer,
    });
  };

  const PortalDropzone = ({ open, getInputProps }: any) => {
    return (
      <>
        {getInputProps && <input {...getInputProps()} id="file-dropzone" />}

        <h2 className="font-size-lg">Add a new part</h2>
        <p className="text-gray font-size-sm">
          Drag a 3D file here or{' '}
          <Button onClick={open} color="link" className="text-white -top-[1px]">
            browse
          </Button>
        </p>

        <div>
          <span className={styles.PartDimensionsText}>Dimensions in: </span>
          <FormToggleSwitch
            checked={formik?.values?.units_in_mm}
            defaultTrueLabel="mm"
            defaultFalseLabel="inches"
            name="units_in_mm"
            id="units_in_mm"
          />
        </div>

        <SupportedFileTypesModal
          action={
            <ButtonLink className={`${styles.SupportedTypes} text-gray font-size-sm`}>
              Supported file types
              <IconFont className="ml-1 text-base" name="plus" />
            </ButtonLink>
          }
          additiveFileExtensions={ADDITIVE_PART_FILE_EXTENSIONS_PORTAL}
          traditionalFileExtensions={TRADITIONAL_PART_FILE_EXTENSIONS_PORTAL}
        />

        <Button
          color="primary"
          dataTestId="part-upload-button"
          className={`${styles.BtnAddPart} flex justify-content-center align-items-center btn-outline-secondary cursor-pointer p-0 rounded-circle`}
          onClick={open}
        >
          <IconFont name="plus" className="text-white mr-0" />
        </Button>
      </>
    );
  };

  const DropzoneComponent = () => {
    return (
      <PartDropzone
        card={false}
        className={`${styles.AddPartDropzone} border-dashed`}
        isLoading={!isEmpty(loadingText)}
        label="Drag and drop 3D design files here or"
        loadingSlot={<PortalDropzone open={() => {}} />}
        onFileDrop={onFileDropHandler}
        accept={PART_FILE_EXTENSIONS_PORTAL}
        dropzoneComponent={<PortalDropzone />}
      />
    );
  };

  return (
    <>
      {shouldShowPartUploadModal && (
        <PartUploadModal
          onClose={() => {
            setAnalysisPayload(null);
            setLoadingText('');
            setPartUploadModalState('upload');
            setShouldShowPartUploadModal(false);
          }}
          state={partUploadModalState}
        />
      )}

      {shouldShowPartUploadModal && analysisPayload ? (
        <PartDropzone.Analyzing
          backgroundComponent={<DropzoneComponent />}
          className="flex flex-column justify-content-center align-items-center"
          part={analysisPayload.part}
          isAnalyzingLastPart={isAnalyzingLastPart}
          onComplete={() => {
            if (shouldRedirectToPartViewer) {
              history.push(`/studio/evaluate/${analysisPayload.part.id}`);
            } else {
              setLoadingText('');
              setShouldShowPartUploadModal(false);
              onCompleteUpload(uploadedParts);
            }
          }}
        />
      ) : (
        <DropzoneComponent />
      )}
    </>
  );
};

export default AddPartDropzone;
