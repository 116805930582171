import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';

import {
  Autoquote,
  AutoquoteError,
  AutoquoteLoading,
  AutoquoteRequest,
  AutoquoteResponseState,
} from 'portal/lib/autoquote';

const newAutoquoteLoading = (): AutoquoteLoading => {
  return { loading: '' };
};

const AUTOQUOTE_DEBOUNCE_MS = 400;

/**
 * Returns the state of the most recently requested autoquote and a function
 * that can be called to request a new autoquote.
 */
const useRequestAutoquote = (initRequest: AutoquoteRequest) => {
  const [autoquoteResponseState, setAutoquoteResponseState] = useState<AutoquoteResponseState>(
    newAutoquoteLoading()
  );

  useEffect(() => {
    fetchAutoquote(initRequest);
    /* eslint-disable-next-line */
  }, []);

  const fetchAutoquoteRequest = (request: AutoquoteRequest) => {
    setAutoquoteResponseState(newAutoquoteLoading());

    api
      .post<Autoquote>('/customer_portal/autoquotes', request)
      .then(res => {
        setAutoquoteResponseState(res.data);
      })
      .catch((error: AxiosError<AutoquoteError>) => {
        setAutoquoteResponseState(error.response.data);
      });
  };

  const fetchAutoquote = useCallback(debounce(fetchAutoquoteRequest, AUTOQUOTE_DEBOUNCE_MS), []);

  return [autoquoteResponseState, fetchAutoquote] as const;
};

export default useRequestAutoquote;
