/* eslint-disable react/display-name, react/prop-types */
import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Link } from 'fr-shared/components';

import { PartImage } from 'portal/components';

import { DataTable } from '../components/DataTable';

export const ContactParts = () => {
  const { user_id: userId } = useParams();
  return (
    <DataTable
      title="Uploaded Parts"
      description="These are parts that the user has uploaded directly via the Customer Portal"
      url="/parts"
      className="-flex"
      params={{ filter_user_id: userId }}
      columns={[
        {
          Header: '',
          id: 'screenshot',
          sortable: false,
          width: 60,
          Cell: row => {
            return (
              <PartImage
                size="xs"
                backdrop={false}
                src={row.original.current_revision?.screenshot}
              />
            );
          },
        },
        {
          Header: 'Name',
          id: 'name',
          sortable: false,
          Cell: row => {
            return (
              <Link
                openNewWindow
                to={{
                  pathname: `https://api.fastradius.com/api/v1/part_file_revisions/${row.original.current_revision.id}`,
                }}
              >
                {row.original.name}
              </Link>
            );
          },
        },
        {
          Header: 'Uploaded',
          id: 'inserted_at',
          accessor: d => moment(d.inserted_at).format('MM/DD/YYYY'),
          width: 160,
        },
        { Header: 'Source', accessor: 'source', sortable: false },
        { Header: 'Part ID', accessor: 'id', sortable: true },
        {
          Header: 'Part File Revision ID',
          accessor: 'current_revision.id',
          sortable: true,
        },
        {
          Header: '',
          sortable: false,
          id: 'download',
          // eslint-disable-next-line react/display-name
          Cell: row => {
            return (
              <Link
                openNewWindow
                to={{
                  pathname: `https://api.fastradius.com/api/v1/part_file_revisions/${row.original.current_revision.id}`,
                }}
              >
                Download
              </Link>
            );
          },
        },
      ]}
    />
  );
};
