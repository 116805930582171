import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'fr-shared/components';

const ShipmentSearch = ({ filters, onTextSearch }) => {
  return (
    <div className="page-actions mb-0">
      <div className="flex justify-content-end align-items-center">
        <div className="relative mr-2">
          <input
            type="text"
            name="public_id"
            className="form-control"
            placeholder="Search by Order #"
            value={filters.public_id || ''}
            onChange={onTextSearch}
          />
        </div>
        <div className="relative mr-2">
          <input
            type="text"
            name="customer_name"
            className="form-control"
            placeholder="Search by Customer"
            value={filters.customer_name || ''}
            onChange={onTextSearch}
          />
        </div>
        <div className="relative mr-2">
          <input
            type="text"
            name="tracking_number"
            className="form-control"
            placeholder="Search by Tracking #"
            value={filters.tracking_number || ''}
            onChange={onTextSearch}
          />
        </div>
        <Button color="primary" to="/admin/shipments/new">
          New Shipment
        </Button>
      </div>
    </div>
  );
};

ShipmentSearch.propTypes = {
  filters: PropTypes.object,
  onTextSearch: PropTypes.func,
  setFilters: PropTypes.func,
};

export default ShipmentSearch;
