import { compact, get, head, isEmpty, map } from 'lodash';

import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';
import { PART_FAILURE_REWORK } from 'fr-shared/lib/part_failures';

interface WorkOrder {
  line_items: Array<WorkOrderLineItem>;
}

interface WorkOrderLineItem {
  quantity: Number;
  part_failures?: Array<any>;
  order_line_item?: {
    manufacturing_process: {
      name: String;
    };
    material: {
      name: String;
    };
  };
}

export const WORK_ORDER_FAILED_STATE = 'Failed';

export const WORK_ORDER_MANUFACTURING_PROCESSES = [
  MANUFACTURING_PROCESSES.BoundMetalDeposition,
  MANUFACTURING_PROCESSES.CNC,
  MANUFACTURING_PROCESSES.DLS,
  MANUFACTURING_PROCESSES.FDM,
  MANUFACTURING_PROCESSES.MJFColor,
  MANUFACTURING_PROCESSES.MJF,
  MANUFACTURING_PROCESSES.SLA,
];

export const WORK_ORDER_STATES = [
  // 'Draft', hidden state
  'Created',
  'Scheduled',
  'Released to Floor',
  'Printing',
  'Thermal Processing',
  'Post-Processing',
  'Quality Control',
  'Complete',
  'Failed',
  'Deleted',
];

export const WORK_ORDER_STATE_STATIONS = {
  thermal_processing: 'Thermal Processing',
  post_processing: 'Post-Processing',
  qc: 'Quality Control',
  printing: 'Printing',
  complete: 'Complete',
  failed: 'Failed',
};

export const TRACKER_WORK_ORDER_STATES = [
  'Created',
  'Scheduled',
  'Released to Floor',
  'Printing',
  'Thermal Processing',
  'Post-Processing',
  'Quality Control',
  'Complete',
];

export const MATERIAL_CONVERSIONS = {
  'CE 221': 1.15,
  'EPU 40': 1.0,
  'EPX 82': 1.12,
  'FPU 50': 1.06,
  'RPU 60': 1.02,
  'RPU 61': 1.01,
  'RPU 70': 1.01,
  'MPU 100': 1.01,
  'SIL 30': 1.07,
  'UMA 90': 1.1,
};

export const CNC_WORKHOLDING_OPTIONS = [
  'R96-2DM12',
  'R96-DM25',
  'R96-DM50',
  'R96-CERX-40',
  'R96-V75100X',
  'R96-V510X',
  'R96-V562X',
];

export const getMaterialName = (workOrder: WorkOrder): String => {
  return head(
    compact(
      map(workOrder.line_items, (li: WorkOrderLineItem) => li?.order_line_item?.material?.name)
    )
  );
};

export const hasProcess = (workOrder: WorkOrder, processName: string): Boolean => {
  return (
    workOrder.line_items &&
    workOrder.line_items.some(
      (li: WorkOrderLineItem) => li?.order_line_item?.manufacturing_process?.name === processName
    )
  );
};

export const hasPartFailures = (workOrder: WorkOrder): boolean => {
  return workOrder.line_items.some(li => li.part_failures && li.part_failures.length > 0);
};

export const isRework = (workOrder: WorkOrder): boolean =>
  !isEmpty(workOrder.line_items) &&
  workOrder.line_items.every(
    li => get(li, 'spawned_from_part_failure.disposition') === PART_FAILURE_REWORK
  );

export function convertMetalMaterialToRods(massInGrams: string): number;
export function convertMetalMaterialToRods(massInGrams: undefined): void;
export function convertMetalMaterialToRods(massInGrams: null): void;

//eslint-disable-next-line func-style
export function convertMetalMaterialToRods(
  massInGrams: string | undefined | null
): number | void {
  const mass = parseFloat(massInGrams);
  if (isNaN(mass)) {
    return null;
  }
  if (mass < 0) {
    return null;
  }
  return Math.ceil(mass / 21.0);
}

export function convertCeramicMaterialToRods(massInGrams: string): number;
export function convertCeramicMaterialToRods(massInGrams: undefined): void;
export function convertCeramicMaterialToRods(massInGrams: null): void;

//eslint-disable-next-line func-style
export function convertCeramicMaterialToRods(
  massInGrams: string | undefined | null
): number | void {
  const mass = parseFloat(massInGrams);
  if (isNaN(mass)) {
    return null;
  }
  if (mass < 0) {
    return null;
  }
  return Math.ceil(mass / 8.7);
}

export const DLS_CASSETTES = ['C3', 'C4', 'C5'];

export const DLS_SMART_WASH = ['Gentle', 'Default', 'Extreme', 'Custom'];

export const HP_PRINT_MODES = ['Balanced', 'Fast', 'Mechanical', 'Cosmetic'];

export const COOLING_OPTIONS = ['NC', 'FC'];
