import { IconFont, Logo } from '@fast-radius/shared-ui';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Address, Alert, Button, Link, Loading, Money, classNames } from 'fr-shared/components';
import { useCurrentSubscriptionPlan, useSaasBillingFeatureFlag } from 'fr-shared/hooks';
import { MARKETING_URLS } from 'fr-shared/lib/marketing_urls';

import { ContactUsInfoBox } from 'portal/components';
import { BILLING_CYCLE, SUBSCRIPTION } from 'portal/lib/plans';

interface SubscriptionConfirmationProps {
  match: {
    params: {
      id: string;
    };
  };
}

const SubscriptionConfirmation = ({ match }: SubscriptionConfirmationProps) => {
  const subscriptionId = get(match, 'params.id');
  const [subscriptionIdMatchError, setSubscriptionIdMatchError] = useState(false);
  const { subscriptionData, isLoading, isError } = useCurrentSubscriptionPlan();
  const isSaasBillingFeatureFlagEnabled = useSaasBillingFeatureFlag();
  const history = useHistory();

  useEffect(() => {
    if (!isSaasBillingFeatureFlagEnabled) {
      history.push('/');
    }

    if (subscriptionData?.id) {
      setSubscriptionIdMatchError(false);
      if (parseInt(subscriptionId) !== subscriptionData.id) {
        setSubscriptionIdMatchError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionData, subscriptionId, isSaasBillingFeatureFlagEnabled]);

  const isSubscriptionError = subscriptionIdMatchError || isError;

  const planType =
    subscriptionData?.status === 'Canceled'
      ? 'Basic'
      : get(subscriptionData, 'plan_version.plan.name');

  const billingCycle = get(subscriptionData, 'plan_version.billing_cycle', 'Monthly');
  const subscriptionPrice = get(subscriptionData, 'plan_version.price.amount', '');
  const billingAddress = get(subscriptionData, 'billing_address');
  const cardType = get(subscriptionData, 'payment_method.card_type');
  const lastFour = get(subscriptionData, 'payment_method.last_4_digits');

  if (isSubscriptionError) {
    return (
      <Alert color="danger" toggle={false}>
        There was an issue with confirmation. Please refresh and try again.
      </Alert>
    );
  }

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-40">
          <Loading type="circle" />
        </div>
      ) : (
        <div data-testid="subscription-confirmation-page" className="flex pl-16">
          <div className="w-3/4">
            <div className="container-sm pt-5">
              <div className="mb-3">
                <Link
                  className="font-size-sm text-gray text-decoration-none"
                  to="/"
                  data-testid="checkout-back-to-dashboard"
                >
                  <IconFont name="chevron-left" right />
                  Back to dashboard
                </Link>
              </div>
              <div className="mb-5">
                <Logo textColor="white" />
              </div>
              <div className="flex w-full">
                <div className="w-full">
                  <h1 data-testid={`confirmation-header-`}>
                    Order Confirmation - {planType} subscription
                  </h1>
                  <div className="text-muted">
                    <div className="row  border-bottom">
                      <div className="col-md mr-5 text-base pb-3">
                        Thanks for your order. We’ll send you an email confirmation soon.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames([
                planType === SUBSCRIPTION.BASIC && 'border-bottom',
                'container-sm py-5',
              ])}
            >
              <div className="row border-bottom pb-3">
                <h2 className="col-md">Order Summary</h2>
              </div>
              <div className="row">
                <div className="col-md text-xs text-coolGray-300 pt-6">
                  1x{' '}
                  {planType === SUBSCRIPTION.BASIC
                    ? ''
                    : billingCycle === BILLING_CYCLE.MONTHLY
                    ? 'Month'
                    : 'Annual'}{' '}
                  subscription to{' '}
                  <strong className="text-coolGray-100">Fast Radius {planType}</strong>
                </div>
              </div>
              <div className="row text-coolGray-100 ">
                <div className="col-md flex text-coolGray-100 justify-between">
                  <div className="text-base">
                    Total per {billingCycle === BILLING_CYCLE.MONTHLY ? 'month' : 'year'}
                  </div>
                  <Money
                    className="text-2xl "
                    amount={planType === SUBSCRIPTION.BASIC ? '0.00' : subscriptionPrice}
                  />
                </div>
              </div>
            </div>
            {planType !== SUBSCRIPTION.BASIC && (
              <div className="container-sm py-5 border-bottom">
                <div className="row border-bottom pb-3">
                  <h2 className="col-md">Details</h2>
                </div>
                <div className="row pt-6">
                  <div className="col-md">
                    <div>Payment method</div>
                    <div className="text-coolGray-100">{cardType}</div>
                    <div>
                      Ending in <span className="text-coolGray-100">{lastFour}</span>
                    </div>
                  </div>
                  <div className="col-md">
                    <div>Billing address</div>
                    <Address className="text-coolGray-100" address={billingAddress} />
                  </div>
                </div>
              </div>
            )}
            <div className="container-sm py-5 border-bottom">
              <div>
                Fast Radius is the leading provider of additive and traditional manufacturing
                solutions - including application discovery, product design and testing,
                production-grade manufacturing, and global fulfillment. The Fast Radius Terms and
                Conditions
                <Link
                  className="text-coolGray-100"
                  to={{ pathname: MARKETING_URLS.TermsAndConditions }}
                  openNewWindow
                >
                  {`(${MARKETING_URLS.TermsAndConditions})`}
                </Link>{' '}
                and Terms of Use{' '}
                <Link
                  className="text-coolGray-100"
                  to={{ pathname: MARKETING_URLS.TermsOfUse }}
                  openNewWindow
                >
                  {`(${MARKETING_URLS.TermsOfUse})`}
                </Link>{' '}
                apply to every quote and contract.
              </div>
            </div>
            <Button
              to="/"
              className="px-8 w-fit ml-auto mt-6"
              dataTestId="subscription-confirmation-go-to-dashboard"
            >
              Go to Dashboard
            </Button>
          </div>
          <ContactUsInfoBox className="bg-transparent lg:block -mx-3 pl-14 pt-56 pr-16" />
        </div>
      )}
    </>
  );
};

SubscriptionConfirmation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default SubscriptionConfirmation;
