/* eslint-disable react/prop-types */
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import React, { useState } from 'react';

import { Checkbox, Link, classNames } from 'fr-shared/components';
import { MARKETING_URLS } from 'fr-shared/lib/marketing_urls';

import { Button } from 'portal/components';
import { SUBSCRIPTION } from 'portal/lib/plans';

import styles from './CheckoutReviewFooter.module.css';

interface CheckoutReviewFooterProps {
  isSubmitDisabled: boolean;
  isSubscriptionCheckout?: boolean;
}

export const CheckoutReviewFooter = ({
  isSubmitDisabled = false,
  isSubscriptionCheckout = false,
}) => {
  const [termsAcknowledged, setTermsAcknowledged] = useState(false);
  const { values, isSubmitting } = useFormikContext() as any;
  const subscriptionType = get(values, 'subscription.subscription_type');
  const isBasicSubscription = subscriptionType === SUBSCRIPTION.BASIC;

  const isSubmitButtonDisabled =
    isSubscriptionCheckout && !isBasicSubscription
      ? isSubmitDisabled || !termsAcknowledged
      : isSubmitDisabled;

  return (
    <div data-testid="chekout-review-footer" className="border-top py-6 mt-6 w-full">
      {!isSubscriptionCheckout && (
        <p data-testid="parts-terms-and-conditions" className="font-size-sm text-muted mb-3">
          {values.credit_card_checkout
            ? 'Payment is made when we confirm your order.'
            : 'Payment is due when we confirm your order, unless otherwise stated in an accepted PO.'}
          <br />
          <span className="text-white">
            By selecting “Submit order” you agree to our{' '}
            <Link to={{ pathname: MARKETING_URLS.TermsOfUse }} openNewWindow>
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link to={{ pathname: MARKETING_URLS.TermsAndConditions }} openNewWindow>
              Terms and Conditions
            </Link>
            . You agree that no additional or conflicting terms apply to your order, even if
            included or referenced in a PO. Order subject to review and acceptance by Fast Radius.
          </span>
        </p>
      )}
      <div
        className={classNames([
          isSubscriptionCheckout && !isBasicSubscription && 'justify-between',
          'flex',
        ])}
      >
        {isSubscriptionCheckout && !isBasicSubscription && (
          <div className="flex items-center">
            <Checkbox
              className={styles.SubscriptionCheckbox}
              dataTestId="subscription-terms-checkbox"
              name="acknowledge-terms"
              value={termsAcknowledged}
              onChange={() => setTermsAcknowledged(!termsAcknowledged)}
            />
            <div className="text-[10px]">
              I agree to the{' '}
              <Link
                className="text-coolGray-100"
                to={{ pathname: MARKETING_URLS.TermsOfUse }}
                openNewWindow
              >
                Terms of Use
              </Link>{' '}
              and{' '}
              <Link
                className="text-coolGray-100"
                to={{ pathname: MARKETING_URLS.TermsAndConditions }}
                openNewWindow
              >
                Terms &amp; Conditions
              </Link>
              <br />
              and confirm I&apos;ve read the{' '}
              <Link
                className="text-coolGray-100"
                to={{ pathname: MARKETING_URLS.Privacy }}
                openNewWindow
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        )}

        <Button
          className="px-8"
          dataTestId="checkout-submit"
          disabled={isSubmitButtonDisabled}
          loading={isSubmitting}
          type="submit"
        >
          {isBasicSubscription
            ? 'Switch to basic'
            : isSubscriptionCheckout
            ? 'Purchase'
            : 'Submit order'}
        </Button>
      </div>
    </div>
  );
};

export default CheckoutReviewFooter;
