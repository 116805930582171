/* eslint-disable jsx-a11y/interactive-supports-focus */

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { uniqueId } from 'lodash';
import React, { MouseEvent, PropsWithChildren, ReactNode, useState } from 'react';

import { classNames } from 'fr-shared/components';

import styles from './DetailSelect.module.css';

// #region [Globals]

export type DetailOptionId = string | number;
export interface DetailOption {
  id: DetailOptionId;
  name: string;
  data?: any;
}

// #endregion

export const DetailSelect = ({ children }: PropsWithChildren<Record<never, never>>) => {
  return (
    <div className={classNames(['w-100 relative z-auto'])} tabIndex={0} role="menu">
      {children}
    </div>
  );
};

// #region [DetailSelect]

export interface DetailSelectButtonProps {
  /*
   * Initial text to be rendered in the readOnly input
   */
  initialText?: string;
  /*
   * String for the label
   */
  labelText?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  className?: string;
  readonly?: boolean;
  value?: any;
}

export const DetailSelectButton = ({
  labelText,
  className,
  onClick,
  readonly = false,
  value,
}: DetailSelectButtonProps) => {
  return (
    <div
      className={classNames([className, readonly && 'pointer-events-none'])}
      data-testid="detail-select"
    >
      <label
        onClick={onClick}
        className="text-xs leading-4 text-coolGray-300 mb-0 w-full relative cursor-pointer"
        htmlFor="selectorBox"
      >
        {labelText} <br />
        <input
          className={classNames([styles.SelectorBox, readonly && 'text-coolGray-300'])}
          disabled={true}
          value={value}
          name="selectorBox"
        />
        <div
          onClick={onClick}
          style={{ position: 'absolute', left: '0', right: '0', top: '0', bottom: '0' }}
          role="button"
          aria-disabled={readonly}
        ></div>
      </label>
    </div>
  );
};

// #endregion

// #region [DetailSelectOptions]

export interface DetailSelectOptionsProps {
  className?: string;
  currentOptionId?: DetailOptionId;
  /*
   * Boolean representing whether the dropdown is open or not.
   */
  isOpen?: boolean;
  isRenderedBelow?: boolean;
  onSelect?: (optionId: DetailOptionId) => void;
  options?: DetailOption[];
  renderOption?: (option: DetailOption, currentOptionId: DetailOptionId) => ReactNode;
}

function renderOptionDefault(option: DetailOption, _currentOptionId: DetailOptionId) {
  return (
    <div className={'text-base select-none text-gray-100 leading-[32px]'}>{option.name}</div>
  );
}

export const DetailSelectOptions = ({
  className,
  currentOptionId,
  isOpen,
  isRenderedBelow,
  onSelect,
  options,
  renderOption = renderOptionDefault,
}: DetailSelectOptionsProps) => {
  const handleSelected = (option: DetailOption) => {
    onSelect(option.id);
  };

  return (
    <div
      className={classNames([
        className,
        'mt-2 absolute w-100',
        {
          block: isOpen,
          hidden: !isOpen,
          'top-[-316px]': !isRenderedBelow,
        },
      ])}
      data-testid="detail-select-options"
    >
      <div className="max-h-[300px] bg-coolGray-700 rounded-[8px] overflow-auto p-3">
        <ul className="p-0 m-0" role="listbox">
          {options &&
            options.map((option: DetailOption, index: number) => {
              return (
                <li
                  className={styles.Option}
                  key={`${option.name}-${uniqueId()}`}
                  id={`detailOption${index}`}
                  onClick={() => handleSelected(option)}
                  value={option.id}
                  data-testid={`option-${option.id}`}
                  aria-selected={currentOptionId === option.id}
                  role="option"
                >
                  <div className="flex justify-between">
                    {renderOption(option, currentOptionId)}
                    <div
                      className={classNames([
                        'part-selection pl-3',
                        {
                          [styles.Selected]: currentOptionId === option.id,
                        },
                      ])}
                      data-testid="part-card-selector-circle"
                      onClick={() => handleSelected(option)}
                      role="button"
                    >
                      <div
                        className={classNames(['part-selection-circle', styles.SelectionCircle])}
                      />
                    </div>
                  </div>

                  {index !== options.length - 1 && (
                    <div className="border-0 border-b border-solid border-coolGray-600 my-2" />
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

// #endregion

// #region [useDetailSelectState]

export const useDetailSelectState = (initialOpen = false) => {
  const [isRenderedBelow, setIsRenderedBelow] = useState(true);
  const [isOpen, setIsOpen] = useState(initialOpen);

  const handleSelectClick = (e: MouseEvent<HTMLElement>) => {
    if (e.clientY > window.innerHeight - 310) {
      setIsRenderedBelow(false);
    } else {
      setIsRenderedBelow(true);
    }
    setIsOpen(!isOpen);
  };

  return { isRenderedBelow, isOpen, handleSelectClick, setIsOpen };
};

// #endregion
