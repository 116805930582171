import React from 'react';

import { Button, Icon, Link, Modal } from 'fr-shared/components';

interface Props {
  customerId: number;
  customerContactId: number;
}

const AddressManagementModal = ({ customerId, customerContactId }: Props) => (
  <Modal
    action={
      <Button color="link" size="sm" className="p-0" style={{ color: 'inherit' }}>
        <strong>Manage shipping addresses</strong>
        <Icon name="question-circle" left className="text-primary-600" />
      </Button>
    }
  >
    {({ toggle }: { toggle: (...args: any[]) => any }) => (
      <>
        <Modal.Header title="Managing Addresses" textUppercase={false} onClose={toggle} />
        <Modal.Body className="font-size-base">
          <p>
            Addresses that have been saved to FROS are available on our{' '}
            <Link
              to={`/admin/customers/${customerId}/contact/${customerContactId}`}
              openNewWindow
            >
              <strong>{"Customer Contact's page"}</strong>
            </Link>
            . Get there by pressing customers in the navigation bar. Find the name of the customer
            (ex: Fast Radius) you are woking with. Then select the name of the customer contact
            (Ex: John Sweich).
          </p>
          <p>
            Billing Information and Shipping Information for your Customer Contact is organized
            there. From that page you are able to Add Addresses, Delete Addresses, and Set a
            Default Address.
          </p>
          <p>
            Default Addresses will be automatically selected for all forms associated with that
            Customer Contact.
          </p>
          <p>
            <em>
              Note: A refresh of the quote may be required to reflect changes made via the
              Customer Contact Page. Save your quote before refreshing.
            </em>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button color="primary" onClick={toggle}>
            Done
          </Button>
        </Modal.Footer>
      </>
    )}
  </Modal>
);

export default AddressManagementModal;
