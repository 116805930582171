export const isAllowed = (filename: string): boolean => {
  const ext = getExtension(filename);

  return !DANGEROUS_FILE_EXTENSIONS.includes(ext);
};

export const sanitizeFilename = (filename: string): string => {
  return filename.replace(/[^\w\d_\-.]+/gi, '');
};

export const getExtension = (filename: string): string => {
  const [match] = filename.match(/\.[\w]+$/) ?? [''];

  return match.toLowerCase();
};

/** Whether the user can use the file as a part file. */
export const isAllowedPartFile = (filename: string): boolean => {
  const extension = getExtension(filename);
  return ALLOWED_FROS_PART_FILE_EXTENSIONS.includes(extension);
};

export const isPartFile = (filename: string): boolean => {
  const extension = getExtension(filename);

  return PART_FILE_EXTENSIONS.includes(extension);
};

export const TWO_D_FILE_EXTENSIONS = [
  '.doc',
  '.docx',
  '.dxf',
  '.dwg',
  '.jpeg',
  '.jpg',
  '.pdf',
  '.png',
  '.ppt',
  '.pptx',
  '.tif',
  '.tiff',
  '.x3d',
];

export const PURCHASE_ORDER_FILE_EXTENSIONS = [
  '.png',
  '.jpg',
  '.pdf',
  '.doc',
  '.xcl',
  '.xls',
  '.xlsx',
];

export const PURCHASE_ORDER_MAX_FILE_SIZE_MB = 100;

// Taken from @supporting_doc_file_types in aws_s3.ex
export const KNOWN_FROS_PART_FILE_EXTENSIONS = [
  '.3dm',
  '.3ds',
  '.3dxml',
  '.3mf',
  '.ai',
  '.amf',
  '.asab',
  '.asat',
  '.bmp',
  '.brep',
  '.catpart',
  '.catproduct',
  '.cmb',
  '.csv',
  '.doc',
  '.docx',
  '.dwg',
  '.dxf',
  '.edrw',
  '.eml',
  '.exp',
  '.fbx',
  '.glb',
  '.gltf',
  '.gz',
  '.iam',
  '.iges',
  '.igs',
  '.ipt',
  '.jpeg',
  '.jpg',
  '.jt',
  '.model',
  '.mtl',
  '.obj',
  '.p_b',
  '.p_t',
  '.par',
  '.pdf',
  '.ppt',
  '.pptx',
  '.ply',
  '.png',
  '.psm',
  '.sab',
  '.sat',
  '.session',
  '.slddrw',
  '.sjb',
  '.sbs',
  '.sldprt',
  '.stp',
  '.step',
  '.stl',
  '.svg',
  '.skp',
  '.slc',
  '.sld',
  '.tif',
  '.tiff',
  '.vda',
  '.vrml',
  '.wrl',
  '.xlxs',
  '.xmp_bin',
  '.xmp_txt',
  '.xmt_bin',
  '.xmt_txt',
  '.x_b',
  '.x_t',
  '.x3d',
  '.zip',
];

/** Part files allowed by the API, minus SLDPRT to prevent users uploading SLDPRT part files. */
export const ALLOWED_FROS_PART_FILE_EXTENSIONS = KNOWN_FROS_PART_FILE_EXTENSIONS.filter(
  x => x !== '.sldprt'
);

// From https://platform-documentation.3yourmind.com/pages/viewpage.action?pageId=1409099
// These are all the part file extensions that 3yourmind will convert
// CX-857: removed sldprt as accepted part file extension
export const PART_FILE_EXTENSIONS = [
  '.step',
  '.stp',
  '.stl',
  '.obj',
  '.x_b',
  '.x_t',
  '.ipt',
  '.3dm',
  '.3ds',
  '.3dxml',
  '.3mf',
  '.asab',
  '.asat',
  '.catpart',
  '.catproduct',
  '.exp',
  '.fbx',
  '.iam',
  '.iges',
  '.igs',
  '.jt',
  '.model',
  '.p_b',
  '.p_t',
  '.par',
  '.ply',
  '.prt',
  '.psm',
  '.sab',
  '.sat',
  '.session',
  '.skp',
  '.slc',
  '.sld',
  '.vda',
  '.wrl',
  '.x3d',
  '.xmp_bin',
  '.xmp_txt',
  '.xmt_bin',
  '.xmt_txt',
];

// https://its.uiowa.edu/support/article/1348
// https://owasp.org/www-community/vulnerabilities/Unrestricted_File_Upload
export const DANGEROUS_FILE_EXTENSIONS = [
  '.ace',
  '.ade',
  '.adp',
  '.ani',
  '.app',
  '.appcontent-ms',
  '.appref-ms',
  '.appref-ms',
  '.asa',
  '.asp',
  '.aspx',
  '.asx',
  '.bas',
  '.bat',
  '.cdxml',
  '.cer',
  '.cer',
  '.chm',
  '.cmd',
  '.cnt',
  '.com',
  '.cpl',
  '.crt',
  '.csh',
  '.der',
  '.diagcab',
  '.dmg',
  '.docm',
  '.exe',
  '.fxp',
  '.gadget',
  '.grp',
  '.hlp',
  '.hpj',
  '.hta',
  '.htc',
  '.html',
  '.inf',
  '.ins',
  '.isp',
  '.its',
  '.jar',
  '.jnlp',
  '.js',
  '.jse',
  '.ksh',
  '.lnk',
  '.mad',
  '.maf',
  '.mag',
  '.mam',
  '.maq',
  '.mar',
  '.mas',
  '.mat',
  '.mau',
  '.mav',
  '.maw',
  '.mcf',
  '.mda',
  '.mdb',
  '.mde',
  '.mdt',
  '.mdw',
  '.mdz',
  '.mht',
  '.mhtml',
  '.msc',
  '.msh',
  '.msh1',
  '.msh1xml',
  '.msh2',
  '.msh2xml',
  '.mshxml',
  '.msi',
  '.msp',
  '.mst',
  '.msu',
  '.msu',
  '.ops',
  '.osd',
  '.pcd',
  '.php',
  '.php5',
  '.pht',
  '.phtml',
  '.pif',
  '.pkg',
  '.pl',
  '.plg',
  '.prf',
  '.prg',
  '.printerexport',
  '.ps1',
  '.ps1.xml',
  '.ps1xml',
  '.ps2',
  '.ps2xml',
  '.psc1',
  '.psc2',
  '.psd1',
  '.psdm1',
  '.psdm1cdxml',
  '.pssc',
  '.pst',
  '.py',
  '.pyc',
  '.pyo',
  '.pyw',
  '.pyz',
  '.pyzw',
  '.reg',
  '.scf',
  '.scr',
  '.sct',
  '.settingcontent-ms',
  '.shb',
  '.shs',
  '.shtml',
  '.swf',
  '.theme',
  '.tmp',
  '.udl',
  '.udl',
  '.url',
  '.vb',
  '.vbe',
  '.vbp',
  '.vbs',
  '.vsmacros',
  '.vss',
  '.vst',
  '.vsw',
  '.webpnp',
  '.website',
  '.website',
  '.ws',
  '.wsb',
  '.wsc',
  '.wsf',
  '.wsh',
  '.xbap',
  '.xll',
  '.xml',
  '.xnk',
];

export const ADDITIVE_PART_FILE_EXTENSIONS = ['.step', '.stl', '.x_t', '.3mf', '.sldprt'];

export const TRADITIONAL_PART_FILE_EXTENSIONS = ['.step', '.sldprt', '.x_t', '.iges'];

// Portal variations used as separate constants, meant to decouple the experience
export const PART_FILE_EXTENSIONS_PORTAL = [...PART_FILE_EXTENSIONS].filter(x => x !== '.sldprt');

export const ADDITIVE_PART_FILE_EXTENSIONS_PORTAL = [...ADDITIVE_PART_FILE_EXTENSIONS].filter(
  x => x !== '.sldprt'
);

export const TRADITIONAL_PART_FILE_EXTENSIONS_PORTAL = [
  ...TRADITIONAL_PART_FILE_EXTENSIONS,
].filter(x => x !== '.sldprt');
