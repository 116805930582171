import { useTreatments } from '@splitsoftware/splitio-react';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Modal } from 'fr-shared/components';
import { CR_BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';

const CostingLineItemMarkAsClosed = ({ onUpdateLineItem }) => {
  const { [CR_BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';

  const handleClosed = toggle => {
    onUpdateLineItem();
    toggle();
  };

  return (
    <Modal
      action={
        <Button color="secondary" className="ml-2">
          Closed
        </Button>
      }
    >
      {({ toggle }) => (
        <>
          <Modal.Header title="Mark as Closed" onClose={toggle} />
          <div className="modal-body">
            <p>
              This action will move all quantities associated with this {lineItemName} to the
              &quot;Closed&quot; tab. They will no longer be available for quoting.
            </p>
            <p>Are you sure you want to continue?</p>
          </div>
          <div className="modal-footer">
            <Button color="link" onClick={toggle}>
              No, Cancel
            </Button>
            <Button onClick={() => handleClosed(toggle)} color="primary" className="ml-2">
              Yes, Mark as Closed
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

CostingLineItemMarkAsClosed.propTypes = {
  onUpdateLineItem: PropTypes.func,
};

export default CostingLineItemMarkAsClosed;
