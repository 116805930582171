import { get } from 'lodash';
import React from 'react';

import { Address, Alert, Button, Loading } from 'fr-shared/components';
import { useCurrentSubscriptionPlan, usePlans } from 'fr-shared/hooks';

import { SUBSCRIPTION } from 'portal/lib/plans';

import SubscriptionBillingInfoModal from './components/SubscriptionBillingInfoModal';
import SubscriptionCards from './components/SubscriptionCards';
import SubscriptionPaymentHistory from './components/SubscriptionPaymentHistory';
import SubscriptionPaymentMethodModal from './components/SubscriptionPaymentMethodModal';

const SubscriptionDetailsContainer = () => {
  const {
    data: subscriptionPlans,
    isError: isPlansError,
    isLoading: isPlansLoading,
  } = usePlans();
  const {
    currentSubscription,
    isError: isActivePlanError,
    isLoading: isActivePlanLoading,
    refreshSubscription,
    subscriptionData,
  } = useCurrentSubscriptionPlan();

  const subscriptionDataError = isPlansError || isActivePlanError;
  const isBasicSubscription = currentSubscription === SUBSCRIPTION.BASIC;
  const cardType = get(subscriptionData, 'payment_method.card_type');
  const lastFour = get(subscriptionData, 'payment_method.last_4_digits');
  return (
    <>
      {subscriptionDataError && (
        <Alert color="danger" toggle={false}>
          There was an issue loading available/selected plans. Please refresh and try again.
        </Alert>
      )}
      {isPlansLoading ? (
        <div className="text-center mt-40">
          <Loading type="circle" />
        </div>
      ) : (
        <SubscriptionCards
          isLoading={isActivePlanLoading}
          currentSubscription={currentSubscription}
          plans={subscriptionPlans ?? []}
          subscriptionData={subscriptionData}
        />
      )}
      {!isBasicSubscription && (
        <div className="flex">
          <div className="flex flex-col w-80 mr-4 h-44 justify-between">
            <div className="flex flex-col pt-2">
              <div className="font-medium text-lg text-coolGray-300">
                Subscription payment method
              </div>
              <div className="text-sm text-coolGray-100">
                {cardType} - Ending in {lastFour}
              </div>
            </div>

            <SubscriptionPaymentMethodModal
              action={
                <Button className="p-1 h-4 w-fit mt-2" outline>
                  Change payment method
                </Button>
              }
              currentSubscriptionId={subscriptionData.id}
              onSubscriptionUpdate={refreshSubscription}
            />
          </div>
          <div className="flex flex-col w-80 h-44">
            <div className="flex flex-col pt-2">
              <div className="font-medium text-lg text-coolGray-300">Billing information</div>
              <div className="text-sm text-coolGray-100">
                <Address
                  address={subscriptionData.billing_address}
                  dataTestId="billing-address"
                />
              </div>
            </div>

            <SubscriptionBillingInfoModal
              action={
                <Button className="p-1 h-4 w-fit mt-2" outline>
                  Change billing info
                </Button>
              }
              currentSubscriptionId={subscriptionData.id}
              onSubscriptionUpdate={refreshSubscription}
            />
          </div>
        </div>
      )}
      {/* If the user has subscriptionData they have previously had a subscription (so subscription payment history should be shown) */}
      {subscriptionData && (
        <div className="pt-8 w-full">
          <div className="flex flex-col">
            <div className="font-medium text-lg text-coolGray-300 pb-2 mb-2 border border-b-coolGray-600 border-x-0 border-t-0">
              Subscription Payment History
            </div>
            <div className="flex flex-col">
              <SubscriptionPaymentHistory transactions={subscriptionData.transactions} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscriptionDetailsContainer;
