import { IconFont } from '@fast-radius/shared-ui';
import { parseInt } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Button } from 'fr-shared/components';
import { AlertContext, useUserAnalyticsContext } from 'fr-shared/context';
import { useManufacturingProcess, usePortalSubscription } from 'fr-shared/hooks';

import { SubscriptionUpgradeCard } from 'portal/components';
import useRequestAutoquote from 'portal/hooks/useRequestAutoquote';
import useRequestCostData from 'portal/hooks/useRequestCostData';
import {
  AutoquoteRequest,
  generateInsightsLink,
  isAutoquote,
  isAutoquoteError,
} from 'portal/lib/autoquote';
import { PartFileRevision, useCreateCart } from 'portal/lib/cart';
import { findManufacturabilityCheck } from 'portal/lib/manufacturability_check';

import EvaluateActions from './components/EvaluateActions/EvaluateActions';
import FormCollapsibleMfgProcess, {
  ManufacturingProcessFields,
} from './components/FormCollapsibleMfgProcess/FormCollapsibleMfgProcess';
import Panel from './components/Panel/Panel';
import ViewerTabs from './components/ViewerTabs/ViewerTabs';
import Costs from './costs';
import CostsTab from './costs/CostsTab';
import DfmPanel from './dfm/DfmPanel/DfmPanel';
import DfmTab from './dfm/DfmTab';
import DfmViewer from './dfm/DfmViewer/DfmViewer';
import Materials from './materials';
import MaterialsTab from './materials/MaterialsTab';
import styles from './viewer.module.css';

type EvaluateProps = {
  partFileRevision: PartFileRevision;
};

export type PartConfig = ManufacturingProcessFields & {
  quantity?: number;
  inspection_type_id?: number;
};

export type InitialPartConfig = PartConfig & {
  cart_line_item_id?: number;
  from?: string;
};

type LocationState = {
  from_edit_parts?: boolean;
};

export interface StudioRoutes {
  Costs: string;
  DFMChecks: string;
  Materials: string;
}

function buildAutoquoteRequest(
  partConfig: PartConfig,
  partFileRevision: PartFileRevision
): AutoquoteRequest {
  return {
    part_id: partFileRevision.part_id,
    part_file_revision_id: partFileRevision.id,
    manufacturing_process_id: partConfig.manufacturing_process_id,
    material_id: partConfig.material_id,
    color_id: partConfig.color_id,
    quantity: partConfig.quantity,
    infill: 'Standard',
    customer_portal_finish: 'Standard',
    layer_thickness_id: partConfig.layer_thickness_id,
    use_defaults: true,
  };
}

/**
 * Renders a tool that allows the user to evaluate the manufacturability and
 * cost of the given part file revision with various different configurations.
 */
export const Evaluate = ({ partFileRevision }: EvaluateProps) => {
  const params = useParams<{ check_id: string; material_id: string }>();
  const history = useHistory();
  const location = useLocation<InitialPartConfig>();

  const searchParams = new URLSearchParams(location.search);
  const initialState = Object.assign(
    {},
    location.state,
    Object.fromEntries(searchParams) as InitialPartConfig
  );

  // Store the initial cart_line_item_id coming from the Draft Quote (Cart)
  const initialCartLineItemId = useRef<number>();

  const initialPartConfig: PartConfig = {
    manufacturing_process_id: initialState.manufacturing_process_id,
    material_id: initialState.material_id,
    color_id: initialState.color_id,
    quantity: initialState.quantity || 1,
  };
  /**
   * Store the initial cart_line_item_id coming from other pages since
   * subsequent history.pushes will null that field out
   */
  useEffect(() => {
    initialCartLineItemId.current = initialState.cart_line_item_id;
    setIsFromEditParts((history?.location?.state as LocationState)?.from_edit_parts);
    /* eslint-disable-next-line */
  }, []);
  const [partConfig, setPartConfig] = useState<PartConfig>(initialPartConfig);
  const [isFromEditParts, setIsFromEditParts] = useState(false);

  const [showSubscriptionUpgrade, setShowSubscriptionUpgrade] = useState(false);

  const { setAlert } = useContext(AlertContext);
  const { data: manufacturingProcesses }: { data: ManufacturingProcess[] } =
    useManufacturingProcess({ publiclyAvailable: true });

  const isPortalSubscribed = usePortalSubscription();
  const userAnalytics = useUserAnalyticsContext();
  /**
   * Hook used to get the current autoquote and request new ones
   */
  const [autoquoteResponseState, fetchAutoquote] = useRequestAutoquote(
    buildAutoquoteRequest(partConfig, partFileRevision)
  );

  const [costData, fetchCostData, clearCostData] = useRequestCostData();
  const { isSubmitting, createCart } = useCreateCart();
  const dfmCheckId: number | null = parseInt(params.check_id);
  const materialId: number | null = parseInt(params.material_id) || partConfig.material_id;
  const partId: number | null = partFileRevision.part_id;

  const baseUrl = `/studio/evaluate/${partId}`;

  const STUDIO_ROUTES: StudioRoutes = {
    Costs: `${baseUrl}/costs`,
    DFMChecks: `${baseUrl}/dfm`,
    Materials: materialId ? `${baseUrl}/materials/${materialId}` : `${baseUrl}/materials`,
  };

  const analyticsTag = location.pathname.includes(STUDIO_ROUTES.Costs)
    ? 'Studio Cost Insights'
    : 'Studio Part Viewer';

  const selectedManufacturingProcess = manufacturingProcesses
    ? manufacturingProcesses.find(process => process?.id === partConfig?.manufacturing_process_id)
    : null;

  /**
   * This state stores the selected DFM check, and defaults to a DFM Check ID
   * if included in the URL params.
   */
  const [selectedDfmCheck, setSelectedDfmCheck] = useState<ManufacturabilityCheck | null>(
    findManufacturabilityCheck(partFileRevision.manufacturability_checks_v2, dfmCheckId)
  );

  /** When we get back a valid autoquote, fetch costing data */
  useEffect(() => {
    if (isAutoquote(autoquoteResponseState)) {
      fetchCostData(autoquoteResponseState.id);
    } else if (isAutoquoteError(autoquoteResponseState)) {
      clearCostData();
    }
    /* eslint-disable-next-line */
  }, [autoquoteResponseState]);

  /**
   * When a user chooses options, we want to request new autoquote data
   */
  useEffect(() => {
    fetchAutoquote(buildAutoquoteRequest(partConfig, partFileRevision));
    /* eslint-disable-next-line */
  }, [
    partConfig.quantity,
    partConfig.manufacturing_process_id,
    partConfig.material_id,
    partConfig.color_id,
  ]);

  /**
   * Watches to see if the DFM check ID changes, and if so it will update the Part Config's
   * manufacturing_process_id. This is necessary if you go directly to a DFM check via URL.
   */
  useEffect(() => {
    if (!selectedDfmCheck) return;
    const manufacturingProcessId = selectedDfmCheck.manufacturing_process_id;
    setPartConfig(partConfig => ({
      ...partConfig,
      manufacturing_process_id: manufacturingProcessId,
    }));
  }, [dfmCheckId, selectedDfmCheck]);

  /**
   * Watches to see if the MFG Process changes in Part Config, then clears the selected DFM check
   */
  useEffect(() => {
    if (
      Object.keys(partConfig).length > 0 &&
      location.pathname.includes(STUDIO_ROUTES.DFMChecks) &&
      selectedDfmCheck?.manufacturing_process_id !== partConfig.manufacturing_process_id
    ) {
      history.push(STUDIO_ROUTES.DFMChecks);
      setSelectedDfmCheck(null);
    }
    // We only want to track changes to manufacturing_process_id to cut down on re-renders
    /* eslint-disable-next-line */
  }, [partConfig.manufacturing_process_id]);

  const handleFieldChanges = (changes: Partial<PartConfig>) => {
    setPartConfig(partConfig => ({ ...partConfig, ...changes }));

    // When we're on the materials tab, and change the material_id, we cant to navigate to the material show page
    const tabName = location.pathname.split('/')[4];
    if (tabName === 'materials' && changes.material_id) {
      history.push(`/studio/evaluate/${partId}/materials/${changes.material_id}`);
    }
  };

  const handleSelectDfmCheck = (dfmCheck: ManufacturabilityCheck) => {
    setSelectedDfmCheck(dfmCheck);
    // Navigate to the check_id to open the DfmPanel
    history.push(`/studio/evaluate/${partId}/dfm/${dfmCheck?.id}`);
  };

  const handleCreateQuote = () => {
    userAnalytics.track('Studio Part Viewer - Add to Quote');

    const lineItemParams = {
      part_id: partFileRevision.part_id,
      color_id: partConfig.color_id,
      manufacturing_process_id: partConfig.manufacturing_process_id,
      material_id: partConfig.material_id,
      layer_thickness_id: partConfig.layer_thickness_id,
    };

    return createCart(lineItemParams, selectedManufacturingProcess?.name)
      .then(res => {
        const { data: cli } = res;
        history.push(`/part-config/${cli.id}`);
      })
      .catch(() => {
        setAlert({
          color: 'danger',
          message: 'An unexpected error occurred, please refresh and try again',
        });
      });
  };

  const handleDfmPanelClose = () => {
    userAnalytics.track('Studio Part Viewer - Create Report');
    setSelectedDfmCheck(null);
    history.push(STUDIO_ROUTES.DFMChecks);
  };

  const handleCompare = (material = {}) => {
    userAnalytics.track('Studio - Clicked "Compare Results"');
    history.push(`/studio/evaluate/${partFileRevision.part_id}/compare`, {
      ...partConfig,
      manufacturing_process: selectedManufacturingProcess,
      material,
    });
  };

  const handleCreateFeasibilityReport = () => {
    if (!isPortalSubscribed) {
      setShowSubscriptionUpgrade(true);
    } else {
      userAnalytics.track('Studio - Create Feasibility Report', {
        autoquote_id: isAutoquote(autoquoteResponseState) && autoquoteResponseState.id,
        part_file_revision_id: partFileRevision.id,
      });

      generateInsightsLink({
        autoquote_id: isAutoquote(autoquoteResponseState) ? autoquoteResponseState.id : null,
        part_file_revision_id: partFileRevision.id,
        manufacturing_process_id: selectedManufacturingProcess.id,
        material_id: partConfig.material_id,
        color_id: partConfig.color_id,
      });
    }
  };

  const costDataToRender =
    !isAutoquoteError(autoquoteResponseState) && costData?.cost_curve_points;

  return (
    <Panel.Wrapper>
      <div className="order-1 col-lg p-0">
        <Switch>
          {costDataToRender && (
            <Route
              path={STUDIO_ROUTES.Costs}
              render={() => (
                <Costs
                  costData={costData}
                  onCreateFeasibilityReport={handleCreateFeasibilityReport}
                  partFileRevision={partFileRevision}
                />
              )}
            />
          )}
          {materialId && (
            <Route
              path={STUDIO_ROUTES.Materials}
              render={() => (
                <Materials
                  materialId={materialId}
                  selectedManufacturingProcess={selectedManufacturingProcess}
                  onCompare={handleCompare}
                />
              )}
            />
          )}
          <Route
            path={[STUDIO_ROUTES.DFMChecks, STUDIO_ROUTES.Costs, STUDIO_ROUTES.Materials]}
            render={() => (
              <DfmViewer
                partFileRevision={partFileRevision}
                selectedDfmCheck={selectedDfmCheck}
                showDetails={true}
                showControls={true}
              />
            )}
          />
        </Switch>
      </div>

      <TransitionGroup component={null}>
        <CSSTransition
          key={location.key}
          classNames={{ enter: styles.slideIn, exit: styles.slideOut }}
          timeout={500}
        >
          <Switch>
            <Route
              path={`${STUDIO_ROUTES.DFMChecks}/:check_id`}
              render={() =>
                /* TODO: this guard is necessary, and unsure why */
                selectedDfmCheck && (
                  <DfmPanel onClose={handleDfmPanelClose} selectedDfmCheck={selectedDfmCheck} />
                )
              }
            />
          </Switch>
        </CSSTransition>
      </TransitionGroup>

      <Panel>
        <div className="flex flex-column flex-fill pt-3 px-3">
          <ViewerTabs onTabChange={() => setSelectedDfmCheck(null)} routes={STUDIO_ROUTES} />

          <div className="mb-2">
            <FormCollapsibleMfgProcess
              activeGlow={true}
              analyticsTag={analyticsTag}
              manufacturingProcesses={manufacturingProcesses}
              onFieldChanges={changes => {
                const process = manufacturingProcesses?.find(
                  p => p.id === changes.manufacturing_process_id
                );
                const autoquotableDefaults = process?.autoquotable_defaults;
                const defaultMaterial =
                  autoquotableDefaults &&
                  autoquotableDefaults.material_id &&
                  process.materials.find(
                    material => material.id === autoquotableDefaults.material_id
                  );
                if (autoquotableDefaults) {
                  changes.material_id = autoquotableDefaults.material_id;
                  if (autoquotableDefaults?.color_id)
                    changes.color_id = autoquotableDefaults.color_id;
                  else if (defaultMaterial && defaultMaterial.colors.length === 1)
                    changes.color_id = defaultMaterial.colors[0].id;
                  if (autoquotableDefaults?.layer_thickness_id)
                    changes.layer_thickness_id = autoquotableDefaults.layer_thickness_id;
                }
                handleFieldChanges(changes);
              }}
              partConfig={partConfig}
              partId={partId}
              showColor={[STUDIO_ROUTES.Costs, STUDIO_ROUTES.DFMChecks].includes(
                location.pathname
              )}
            />
          </div>

          <Switch>
            <Route
              path={STUDIO_ROUTES.DFMChecks}
              render={() => (
                <DfmTab
                  dfmChecks={partFileRevision.manufacturability_checks_v2}
                  isSubmitting={isSubmitting}
                  onRequestChecks={handleCreateQuote}
                  onSelectDfmCheck={handleSelectDfmCheck}
                  onCompare={handleCompare}
                  selectedDfmCheck={selectedDfmCheck}
                  selectedManufacturingProcess={selectedManufacturingProcess}
                  hasMaterial={partConfig.material_id != null}
                  isBulkEditPart={isFromEditParts}
                />
              )}
            />
            <Route
              path={STUDIO_ROUTES.Materials}
              render={() => (
                <MaterialsTab
                  onRequestChecks={handleCreateQuote}
                  selectedManufacturingProcess={selectedManufacturingProcess}
                  selectedMaterialId={materialId}
                />
              )}
            />
            <Route
              path={STUDIO_ROUTES.Costs}
              render={() => (
                <CostsTab
                  autoquoteResponseState={autoquoteResponseState}
                  isSubmitting={isSubmitting}
                  onFieldChanges={handleFieldChanges}
                  onRequestManual={handleCreateQuote}
                  partConfig={partConfig}
                  partId={partId}
                  selectedManufacturingProcess={selectedManufacturingProcess}
                />
              )}
            />
          </Switch>
        </div>

        <Panel.Bottom>
          <CSSTransition
            unmountOnExit
            in={showSubscriptionUpgrade}
            classNames={{ enter: styles.slideUp, exit: styles.slideDown }}
            timeout={300}
          >
            <div className="p-3 mt-1 text-coolGray-100 shadow-[0px_-12px_12px_-12px_rgb(0,0,0,0.5)]">
              <div className="flex pb-3.5 items-center">
                <IconFont className="font-size-xl" name="error-triangle" />
                <div className="text-sm pl-1">Insight reporting is a Pro tool.</div>
                <Button
                  onClick={() => setShowSubscriptionUpgrade(false)}
                  className="modal-close ml-auto"
                  size="md"
                >
                  <IconFont name="close" />
                </Button>
              </div>
              <SubscriptionUpgradeCard analyticsContext="Feasibility report" />
            </div>
          </CSSTransition>

          <EvaluateActions
            disableButtonDueToSubscription={showSubscriptionUpgrade}
            onCreateFeasibilityReport={handleCreateFeasibilityReport}
            initialCartLineItemId={initialCartLineItemId.current}
            isSubmitting={isSubmitting}
            onCreateQuote={handleCreateQuote}
            partConfig={partConfig}
            selectedManufacturingProcess={selectedManufacturingProcess}
            isBulkEditPart={isFromEditParts}
          />
        </Panel.Bottom>
      </Panel>
    </Panel.Wrapper>
  );
};

export default Evaluate;
