import { useUserAnalyticsContext } from 'fr-shared/context';

export type trackInputFunctionType = (
  name: string,
  inputValue?: number | string | boolean
) => void;

const useScopedAnalytics = (prefix: string): trackInputFunctionType => {
  const userAnalytics = useUserAnalyticsContext();

  return (name: string, inputValue?: number | string | boolean) => {
    if (inputValue === undefined) {
      userAnalytics.track(`${prefix} - ${name}`);
    } else {
      userAnalytics.track(`${prefix} - ${name}`, { inputValue });
    }
  };
};

export default useScopedAnalytics;
