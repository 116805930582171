import { Field } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { CheckboxLabel } from 'fr-shared/components';
import { classNames } from 'fr-shared/utils/classNames';

const FormCheckbox = ({
  name,
  className,
  onBlur,
  required,
  label,
  readonly,
  isToggle,
  onClick,
  value,
}) => (
  <Field name={name}>
    {({ form, field, meta }) => {
      const handleBlur = e => {
        field.onBlur(e);
        onBlur && onBlur(e, form, meta);
      };
      return (
        <CheckboxLabel
          className={className}
          name={name}
          isRequired={required}
          isToggle={isToggle}
          label={label}
        >
          <input
            type="checkbox"
            name={name}
            id={name}
            readOnly={readonly}
            data-testid={name}
            checked={value != null ? value : field.value}
            className={classNames([
              'custom-control-input',
              { 'is-invalid': get(form.errors, name) },
            ])}
            {...field}
            onBlur={handleBlur}
            onClick={onClick}
          />
        </CheckboxLabel>
      );
    }}
  </Field>
);

FormCheckbox.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  isToggle: PropTypes.bool,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  readonly: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  required: PropTypes.bool,
  value: PropTypes.bool,
};

export default FormCheckbox;
