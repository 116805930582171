import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, FormNumber, Icon, LineItemComment, LineItemProcess } from 'fr-shared/components';

import { formatNullableDate } from '../../orders/components/planned_shipment_line_items/PSLITableEdit';

const getScreenshot = li => get(li, 'part.current_revision.screenshot');

const ShipmentLineItem = ({ fieldPrefix, lineItem, onRemove, onSelect, isEditing = false }) => {
  return (
    <div key={lineItem.order_line_item_id} className="card-body border-top first:border-0">
      <div className="flex flex-row">
        <div className="col">
          <div className="row">
            <div>
              {lineItem.part && (
                <img
                  src={getScreenshot(lineItem)}
                  width={50}
                  height={50}
                  className="rounded mr-3"
                  alt="Part Screenshot"
                />
              )}
              <div className="flex flex-column">
                <strong>{lineItem.description}</strong>
                <LineItemComment lineItem={lineItem} />
              </div>
            </div>
            <div className="flex-1 text-right mb-2">
              <strong>
                <LineItemProcess data={lineItem} />
              </strong>
            </div>
          </div>
          <div className="row flex justify-content-end">
            <div className="flex justify-content-between">
              <div className="text-muted whitespace-nowrap mr-1">Customer Promised Date:</div>
              <div className="flex-shrink-1">
                <strong>
                  {lineItem.promised_ship_date &&
                    moment(lineItem.promised_ship_date).format('MM/DD/YYYY')}
                </strong>
              </div>
              <div className="col">
                <div className="flex justify-content-between">
                  <div className="text-muted whitespace-nowrap mr-1">Supplier Promised Date:</div>
                  <div className="flex-shrink-1 whitespace-nowrap">
                    <strong>{formatNullableDate(lineItem.supplier_promised_ship_date)}</strong>
                  </div>
                </div>
                <div className="flex justify-content-between">
                  <div className="text-muted whitespace-nowrap mr-1">Commit Date:</div>
                  <div className="flex-shrink-1">
                    <strong>
                      {lineItem.commit_date && moment(lineItem.commit_date).format('MM/DD/YYYY')}
                    </strong>
                  </div>
                </div>
              </div>
              <div className="col">
                {onRemove ? (
                  <FormNumber name={`${fieldPrefix}.quantity`} label="Quantity" min={0} />
                ) : (
                  <>
                    {isEditing && (
                      <div className="flex justify-content-between">
                        <div className="text-muted mr-1">Left To Ship:</div>
                        <div className="flex-shrink-1">
                          <strong data-testid={`line_item.${lineItem.id}.available_to_ship`}>
                            {lineItem.remaining_to_fulfill}
                          </strong>
                        </div>
                      </div>
                    )}
                    <div className="flex justify-content-between">
                      <div className="text-muted mr-1">Quantity Ordered:</div>
                      <div className="flex-shrink-1">
                        <strong>{lineItem.order_line_item.quantity}</strong>
                      </div>
                    </div>
                    {!isEditing && (
                      <div className="flex justify-content-between">
                        <div className="text-muted">Quantity Shipped</div>
                        <div className="flex-shrink-1">
                          <strong data-testid={`line_item.${lineItem.id}.quantity_shipped`}>
                            {lineItem.quantity}
                          </strong>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex align-items-center">
          <div>
            {onRemove ? (
              <Button
                className="ml-3"
                color="danger"
                outline={true}
                onClick={() => onRemove([lineItem.order_line_item_id])}
                aria-label={`Remove line item ${lineItem.order_line_item_id}`}
              >
                <Icon name="times" />
              </Button>
            ) : (
              isEditing && (
                <Button
                  className="ml-3"
                  onClick={() => onSelect([lineItem.order_line_item_id])}
                  aria-label={`Add line item ${lineItem.order_line_item_id}`}
                >
                  <Icon name="plus" />
                </Button>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ShipmentLineItem.propTypes = {
  order: PropTypes.object,
  shipmentId: PropTypes.number,
  lineItem: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    commit_date: PropTypes.string,
    order_line_item: PropTypes.shape({ quantity: PropTypes.number }),
    order_line_item_id: PropTypes.number,
    supplier_promised_ship_date: PropTypes.string,
    promised_ship_date: PropTypes.string,
    part: PropTypes.object,
    remaining_to_fulfill: PropTypes.number,
  }).isRequired,
  fieldPrefix: PropTypes.string,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
  isEditing: PropTypes.bool,
};

export default ShipmentLineItem;
