import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Button, Checkbox, Select, SidebarContent } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { useLocations, useManufacturingProcess, useUsersByRole } from 'fr-shared/hooks';
import { ORDER_STATES } from 'fr-shared/lib/orders';
import { ROLES } from 'fr-shared/lib/roles';

const OrderFilters = ({ clearFilters, setFilters, filters }) => {
  const { user } = useContext(UserContext);
  const { data: locations } = useLocations();
  const { data: manufacturingProcesses } = useManufacturingProcess();
  const { data: salesUsers } = useUsersByRole(ROLES.Sales);
  const { data: opsManagementUsers } = useUsersByRole(ROLES.OperationsProjectManagement);
  const { data: aeUsers } = useUsersByRole(ROLES.ApplicationEngineering);

  return (
    <SidebarContent name="Filters" icon="filter">
      <div className="form-group">
        <Checkbox
          label="Hide shipped orders"
          name="not_shipped"
          onChange={e => setFilters({ ...filters, not_shipped: e.target.checked })}
          value={filters.not_shipped || false}
        />
      </div>
      <div className="form-group">
        <Select
          label="Order State"
          name="state"
          nameAccessor={option => option}
          onChange={e => setFilters({ ...filters, state: e.target.value })}
          optionList={Object.values(ORDER_STATES).filter(
            state => state !== ORDER_STATES.Draft || user.canViewDraftOrders
          )}
          value={filters.state || ''}
          valueAccessor={option => option}
        />
      </div>
      <div className="form-group">
        <Select
          label="Sales Contact"
          name="sales_contact_id"
          onChange={e => setFilters({ ...filters, sales_contact_id: e.target.value })}
          optionList={salesUsers}
          value={filters.sales_contact_id || ''}
        />
      </div>
      <div className="form-group">
        <Select
          label="Support Contact"
          name="support_contact_id"
          onChange={e => setFilters({ ...filters, support_contact_id: e.target.value })}
          optionList={opsManagementUsers}
          value={filters.support_contact_id || ''}
        />
      </div>
      <div className="form-group">
        <Select
          label="Engineering Contact"
          name="engineering_contact_id"
          onChange={e => setFilters({ ...filters, engineering_contact_id: e.target.value })}
          optionList={aeUsers}
          value={filters.engineering_contact_id || ''}
        />
      </div>
      <div className="form-group">
        <Select
          label="Produced By"
          name="location_id"
          onChange={e => setFilters({ ...filters, location_id: e.target.value })}
          optionList={locations}
          value={filters.location_id || ''}
        />
      </div>
      <div className="form-group">
        <Select
          label="Manufacturing Process"
          name="manufacturing_process_id"
          onChange={e => setFilters({ ...filters, manufacturing_process_id: e.target.value })}
          optionList={manufacturingProcesses}
          value={filters.manufacturing_process_id || ''}
        />
      </div>
      <div className="form-group text-right mt-3">
        <Button size="sm" onClick={clearFilters}>
          Clear filters
        </Button>
      </div>
    </SidebarContent>
  );
};

OrderFilters.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    engineering_contact_id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    location_id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    manufacturing_process_id: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    not_shipped: PropTypes.bool,
    sales_contact_id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    state: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    support_contact_id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }),
};

export default OrderFilters;
