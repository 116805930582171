import PropTypes from 'prop-types';
import React from 'react';

import { PartsProvider } from 'portal/contexts/PartsContext';

import PartsGrid from './PartsGrid';

const PartsGridContainer = ({ parts }) => {
  return (
    <PartsProvider parts={parts} cancelLocalStorage>
      <PartsGrid parts={parts} />
    </PartsProvider>
  );
};

PartsGridContainer.propTypes = {
  parts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PartsGridContainer;
