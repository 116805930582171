import React from 'react';

import { classNames } from 'fr-shared/components';
import { keyboardDown } from 'fr-shared/utils';

import styles from './ToggleSwitch.module.css';

interface ToggleSwitchProps {
  className?: string;
  dataTestId?: string;
  defaultTrueLabel: string;
  defaultFalseLabel: string;
  id: string;
  checked: boolean;
  onCheck: any;
  disabled?: boolean;
}

export const ToggleSwitch = ({
  className = '',
  dataTestId = '',
  defaultTrueLabel,
  defaultFalseLabel,
  id,
  disabled,
  checked,
  onCheck,
}: ToggleSwitchProps) => {
  const handleCheck = (e: any) => {
    if (!disabled) onCheck(e);
  };
  return (
    <div className={classNames([styles.Root, className])} data-testid={dataTestId}>
      <input
        checked={checked}
        className="hidden"
        id={id}
        onChange={handleCheck}
        type="checkbox"
      />

      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions*/}
      <label
        className={classNames([
          styles.ToggleSwitchLabel,
          disabled && styles.ToggleSwitchLabelDisabled,
        ])}
        htmlFor={id}
        onKeyDown={evt => keyboardDown(evt, 'Enter', handleCheck)}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <div
          className={classNames([
            styles.Toggle,
            !checked && styles.active,
            disabled && styles.disabled,
          ])}
        />
        <div className={styles.Names}>
          <span className={classNames([styles.ToggleText, checked && styles.active])}>
            {defaultTrueLabel}
          </span>
          <span className={classNames([styles.ToggleText, !checked && styles.active])}>
            {defaultFalseLabel}
          </span>
        </div>
      </label>
    </div>
  );
};

export default ToggleSwitch;
