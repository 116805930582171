import { Toast } from '@fast-radius/shared-ui';
import React, { ReactElement, useRef, useState } from 'react';

import { api } from 'fr-shared/api';
import { Button, Modal } from 'fr-shared/components';

import AccountPaymentMethod from '../../payment_details/components/AccountPaymentMethod';

interface ModalProps {
  action: ReactElement;
  onActionHandler?: () => void;
  title?: string;
  toggle?: () => void;
}

type SubscriptionPaymentMethodModalProps = ModalProps & {
  currentSubscriptionId: number;
  onSubscriptionUpdate?: () => void;
};

const SubscriptionPaymentMethodModal: React.FC<SubscriptionPaymentMethodModalProps> = ({
  action,
  currentSubscriptionId,
  onActionHandler,
  onSubscriptionUpdate,
}) => {
  const braintreeInstance = useRef(null);
  const [saveSuccess, setSaveSuccess] = useState(null);

  const handleBraintreeInstance = (instance: any) => {
    braintreeInstance.current = instance;
  };

  const handleSubmitPaymentMethodChange = () => {
    setSaveSuccess(null);

    if (braintreeInstance.current) {
      braintreeInstance.current.requestPaymentMethod().then((res: any) => {
        api
          .put(`/saas/subscriptions/${currentSubscriptionId}`, {
            payment_method_nonce: res.nonce,
          })
          .then(() => {
            setSaveSuccess(true);
            onSubscriptionUpdate();
          })
          .catch(() => setSaveSuccess(false));
      });
    }
  };

  return (
    <>
      {saveSuccess ? (
        <Toast.Success iconName="check" clearToast>
          Payment method updated
        </Toast.Success>
      ) : saveSuccess !== null ? (
        <Toast.Danger iconName="error-triangle" clearToast>
          Failed to update payment method
        </Toast.Danger>
      ) : null}

      <Modal
        dataTestId="subscription-payment-method-modal"
        action={action}
        onActionHandler={onActionHandler}
      >
        {({ toggle }: ModalProps) => (
          <>
            <Modal.Header title="Change payment method" onClose={toggle} textUppercase={false} />
            <Modal.Body className="px-3">
              <div className="px-3 text-center pb-2">
                Select your new payment method below. (Note: you can add a new payment method in
                the payment methods tab)
              </div>
              <AccountPaymentMethod
                onlyShowPaymentInfo={true}
                onInstance={handleBraintreeInstance}
              />
            </Modal.Body>
            <Modal.Footer className="justify-end">
              <Button
                outline
                onClick={() => {
                  handleSubmitPaymentMethodChange();
                  toggle();
                }}
              >
                Click to update
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default SubscriptionPaymentMethodModal;
