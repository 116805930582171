import { FieldArray, connect } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Checkbox, Icon } from 'fr-shared/components';
import { SupplierPortalConfirmationBadge } from 'fr-shared/components/common/StateBadges';
import { useSupplierPortalConfirmationBadgeFeatureFlag } from 'fr-shared/hooks';
import { isExternalProcess } from 'fr-shared/lib/manufacturing_process';
import { isFormikAtPrefixTheSame } from 'fr-shared/utils';

import { hasPartialSelection } from '../utils/selected';

const SupplierCostsContainer = ({
  fieldPrefix,
  formik,
  canSubmitCosts,
  editQuantitiesMode,
  children = () => {},
}) => {
  const isSupplierPortalConfirmationBadgeEnabled =
    useSupplierPortalConfirmationBadgeFeatureFlag();

  const lineItem = get(formik, `values.${fieldPrefix}`);
  const process = get(formik, `values.${fieldPrefix}.build_package.processes.0`);

  if (!lineItem.supplier_costs || !process.manufacturing_process) return null;

  const isExternal = isExternalProcess(process.manufacturing_process.name);
  const isLineItemCompleted = lineItem.state === 'Completed';
  const canRemoveSupplier = !editQuantitiesMode && canSubmitCosts && isExternal;

  const handleSelectSupplier = (supplierCost, supplierCostIndex) => {
    const newPartQuantities = supplierCost.part_quantities.map(pq => ({
      ...pq,
      selected: !supplierCost.selected,
    }));
    formik.setFieldValue(`${fieldPrefix}.selected`, !supplierCost.selected);
    formik.setFieldValue(`${fieldPrefix}.supplier_costs.${supplierCostIndex}`, {
      ...supplierCost,
      selected: !supplierCost.selected,
      part_quantities: newPartQuantities,
    });
  };

  return (
    <div>
      <FieldArray name={`${fieldPrefix}.supplier_costs`}>
        {({ remove }) =>
          lineItem.supplier_costs.map((supplierCost, supplierCostIndex) => (
            <div
              key={`sc-${supplierCost.id || supplierCostIndex}`}
              className="border-top first:border-0 px-3 py-2 mb-0"
            >
              <div className="bg-white flex justify-content-between mb-1">
                <div className="flex align-items-center">
                  {isLineItemCompleted && (
                    <Checkbox
                      name={`${fieldPrefix}.supplier_costs.${supplierCostIndex}.selected`}
                      className="m-0"
                      indeterminate={hasPartialSelection(supplierCost.part_quantities)}
                      onChange={() => handleSelectSupplier(supplierCost, supplierCostIndex)}
                      value={supplierCost.selected || false}
                    />
                  )}
                  <p className="font-weight-bolder mb-0 mr-3">Supplier {supplierCostIndex + 1}</p>
                  {isSupplierPortalConfirmationBadgeEnabled &&
                    supplierCost.is_in_supplier_portal && <SupplierPortalConfirmationBadge />}
                </div>
                {canRemoveSupplier && (
                  <Button
                    color="danger"
                    outline={true}
                    size="sm"
                    onClick={() => remove(supplierCostIndex)}
                  >
                    <Icon name="times" right />
                    Remove Supplier
                  </Button>
                )}
              </div>
              <div className="pl-3">
                {children({
                  canSubmitCosts,
                  fieldPrefix,
                  lineItem,
                  supplierCost,
                  supplierCostIndex,
                  process,
                })}
              </div>
            </div>
          ))
        }
      </FieldArray>
    </div>
  );
};

SupplierCostsContainer.propTypes = {
  canSubmitCosts: PropTypes.bool,
  editQuantitiesMode: PropTypes.bool,
  children: PropTypes.func,
  formik: PropTypes.object.isRequired,
  fieldPrefix: PropTypes.string.isRequired,
};

const isSameForMemo = (prevProps, nextProps) => {
  return (
    isFormikAtPrefixTheSame(prevProps, nextProps) &&
    prevProps.editQuantitiesMode === nextProps.editQuantitiesMode
  );
};

export default connect(React.memo(SupplierCostsContainer, isSameForMemo));
