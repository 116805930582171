import '@fast-radius/shared-ui';
import React, { useEffect } from 'react';

import { useUserAnalyticsContext } from 'fr-shared/context';

import { AutoquoteTierList, BulkConfigAutoquoteTierList } from 'portal/lib/autoquote';

import { PartFormState } from './PartFormFields';
import { TierPriceInfoContainer } from './TierPriceContainer';

interface TierPriceInfoProps {
  autoquoteTierList: AutoquoteTierList | BulkConfigAutoquoteTierList;
  currentProcess: ManufacturingProcess;
  lineItem: PartFormState;
  handleFieldChanges: (changes: Partial<PartFormState>) => void;
}

const TierPriceInfoContainers = ({
  currentProcess,
  handleFieldChanges,
  lineItem,
  autoquoteTierList,
}: TierPriceInfoProps) => {
  const userAnalytics = useUserAnalyticsContext();

  const hasFastTier = autoquoteTierList?.some(tier => tier.label === 'Fast');

  useEffect(() => {
    if (hasFastTier) {
      userAnalytics.track('Part Config - Viewed Fast / Made in USA lead time');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFastTier]);

  if (!autoquoteTierList || !autoquoteTierList.length) return null;
  return (
    <>
      {autoquoteTierList.map((autoquoteTier, index) => {
        return (
          <TierPriceInfoContainer
            onSelected={() => {
              userAnalytics.track(
                `Part Config - Selected ${currentProcess?.name} Lead Time Option`,
                { inputValue: autoquoteTier.label }
              );
              handleFieldChanges({ location_id: autoquoteTier.location_id || null });
            }}
            selectedLocationId={lineItem.location_id}
            autoquoteTier={autoquoteTier}
            key={index}
            hasSiblingTiers={autoquoteTierList.length >= 2}
          />
        );
      })}
    </>
  );
};

export default TierPriceInfoContainers;
