import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-hot-loader';
import '../styles/legacy.css';
import '../styles/styles.css';
import '../styles/tailwind.css';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import * as Sentry from '@sentry/react';
import App from './app';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.MIX_ENV,
  release: process.env.VERSION,
  ignoreErrors: ['Array size is not a small enough positive integer.'],
  tracesSampleRate: 1.0,
});

const appEl = document.getElementById('root');
ReactModal.setAppElement(appEl);

ReactDOM.render(<App />, appEl);
