import {
  AbstractMesh,
  ArcRotateCamera,
  Color4,
  Engine,
  PickingInfo,
  Scene,
  SceneLoader,
  Vector3,
} from 'babylonjs';
import { Map } from 'immutable';
import React, { useEffect, useRef, useState } from 'react';

import { ArcRotateCameraConfig } from './camera';
import { Light, addToScene as addLightToScene } from './light';
import { addToScene as addMaterialToScene } from './material';
import { MeshSource } from './meshSource';
import { ShapeInstance, ShapeInstancePickablity, isRotateAboutAxex } from './shapeInstance';

export type Props = {
  cameraConfig: ArcRotateCameraConfig;
  lights: Light[];
  meshSources: MeshSource[];
  shapeInstances: ShapeInstance[];
  clearColor?: Color4;
  onScenePointerDown?: (event: PointerEvent, pickInfo: PickingInfo) => void;
  onScenePointerUp?: (event: PointerEvent, pickInfo: PickingInfo) => void;
  onScenePointerMove?: (event: PointerEvent, pickInfo: PickingInfo) => void;
  onResize?: (width: number, height: number) => void;
  setMeshSourceIsLoading?: (isLoading: boolean) => void;
} & React.DetailedHTMLProps<React.CanvasHTMLAttributes<HTMLCanvasElement>, HTMLCanvasElement>;

/**
 * The loading state of a mesh source.
 */
enum MeshSourceLoadingState {
  Loading,
  Loaded,
  Failed,
}

/**
 * Initialize a scene and engine on the given canvas.
 * @param canvas - the canvas on which to render the scene
 * @param onSceneReady - callback to execute when the scene is ready
 * @param onSceneResize - callback to execute when the scene resizes
 */
const initScene = (
  canvas: HTMLCanvasElement,
  onSceneReady: (scene: Scene) => void,
  onSceneResize?: (width: number, height: number) => void
) => {
  const engine = new Engine(canvas, true, {}, false);
  const scene = new Scene(engine, {});

  if (scene.isReady()) {
    onSceneReady(scene);
  } else {
    scene.onReadyObservable.addOnce(scene => onSceneReady(scene));
  }

  engine.runRenderLoop(() => {
    scene.render();
  });

  const resizeScene = () => {
    engine.resize();
    if (onSceneResize) {
      onSceneResize(engine.getRenderWidth(), engine.getRenderHeight());
    }
  };

  if (window) {
    window.addEventListener('resize', resizeScene);
  }

  const canvasResizeObserver = new ResizeObserver(resizeScene);
  canvasResizeObserver.observe(canvas);

  return () => {
    scene.getEngine().dispose();
    if (window) {
      window.removeEventListener('resize', resizeScene);
    }
    canvasResizeObserver.disconnect();
  };
};

/**
 * Initialize an arc rotate camera and attach it to the scene.
 */
const initArcRotateCamera = (scene: Scene, cameraConfig: ArcRotateCameraConfig) => {
  const camera = new ArcRotateCamera(
    'camera',
    cameraConfig.alpha,
    cameraConfig.beta,
    cameraConfig.radius,
    cameraConfig.target,
    scene
  );
  camera.fov = cameraConfig.fovY;
  camera.wheelDeltaPercentage = 0.002;
  camera.panningSensibility = 100;
  camera.lowerRadiusLimit = 0;
  const canvas = scene.getEngine().getRenderingCanvas();
  camera.attachControl(canvas, true);
};

/**
 * Update the scene's camera.
 */
const updateCamera = (scene: Scene, cameraConfig: ArcRotateCameraConfig) => {
  const camera = scene.cameras[0] as ArcRotateCamera;

  // We can't give Babylon the reference to the target vector because Babylon
  // will mutate it.
  const { x, y, z } = cameraConfig.target;
  camera.setTarget(new Vector3(x, y, z));

  camera.alpha = cameraConfig.alpha;
  camera.beta = cameraConfig.beta;
  camera.radius = cameraConfig.radius;
  camera.fov = cameraConfig.fovY;
};

/**
 * Start loading a new mesh source.
 *
 * Update the `meshSourceUrlToLoadingState` React state variable when the source
 * starts and finishes loading so that we can keep track of which sources are
 * partially and completely loaded in the scene.
 *
 * When a mesh source is loaded, a Babylon mesh is generated in the scene and we
 * immediately disable it so that it is not visible.
 */
const loadMeshSource = (
  scene: Scene,
  meshSource: MeshSource,
  setMeshSourceUrlToLoadingState: React.Dispatch<
    React.SetStateAction<Map<string, MeshSourceLoadingState>>
  >
) => {
  setMeshSourceUrlToLoadingState(urlToState =>
    urlToState.set(meshSource.url, MeshSourceLoadingState.Loading)
  );
  SceneLoader.LoadAssetContainer(
    meshSource.url,
    '',
    scene,
    // on success
    assets => {
      // A single mesh source (eg, STL file or OBJ file) can actually contain
      // multiple separate submeshes. When we load the source file's submeshes
      // into the scene, we will save the source URL and submesh name in the
      // metadata of each Babylon mesh.
      for (const m of assets.meshes) {
        m.setEnabled(false);
        m.metadata = {
          sourceUrl: meshSource.url,
          sourceSubmeshName: m.name,
          shapeInstanceId: null,
        };
        scene.addMesh(m);
      }

      setMeshSourceUrlToLoadingState(urlToState =>
        urlToState.set(meshSource.url, MeshSourceLoadingState.Loaded)
      );
      if (meshSource.onLoadSuccess) {
        meshSource.onLoadSuccess(assets.meshes);
      }
    },
    // on progress
    event => {
      if (meshSource.onLoadProgress) {
        meshSource.onLoadProgress(event);
      }
    },
    // on error
    (_, message, exception) => {
      setMeshSourceUrlToLoadingState(urlToState =>
        urlToState.set(meshSource.url, MeshSourceLoadingState.Failed)
      );
      if (meshSource.onLoadError) {
        meshSource.onLoadError(message, exception);
      }
    }
  );
};

/**
 * Remove any mesh sources and shape instances associated with the given mesh
 * source URL.
 */
const removeMeshSourceAndShapeInstances = (
  scene: Scene,
  meshSourceUrl: string,
  setMeshSourceUrlToLoadingState: React.Dispatch<
    React.SetStateAction<Map<string, MeshSourceLoadingState>>
  >
) => {
  for (const mesh of scene.meshes) {
    if (mesh.metadata?.sourceUrl === meshSourceUrl) {
      mesh.dispose();
    }
  }
  setMeshSourceUrlToLoadingState(urlToState =>
    urlToState.filter((_state, url) => url !== meshSourceUrl)
  );
};

/**
 * Find the Babylon meshes in the scene that are associated with the specifed
 * source URL and (optionally) the given submesh name.
 *
 * A single mesh source (eg, STL file or OBJ file) can contain multiple meshes,
 * each of which may have a submesh name. If `sourceSubmeshName` is `null` then
 * this function will return all the meshes from the mesh source. If
 * `sourceSubmeshName` is not `null` then this function will return only those
 * meshes from the mesh source that have the given name.
 *
 * This does not return Babylon meshes that are associated with shape instances,
 * even if those shape instances use the given mesh source URL. To find meshes
 * associated with a specific shape instance, use `findMeshesForShapeInstance`.
 */
const findMeshesForMeshSource = (
  scene: Scene,
  sourceUrl: string,
  sourceSubmeshName?: string
): AbstractMesh[] => {
  if (sourceSubmeshName === null || sourceSubmeshName === undefined) {
    return scene.meshes.filter(
      m => !m.metadata?.shapeInstanceId && m.metadata?.sourceUrl === sourceUrl
    );
  } else {
    return scene.meshes.filter(
      m =>
        !m.metadata?.shapeInstanceId &&
        m.metadata?.sourceUrl === sourceUrl &&
        m.metadata?.sourceSubmeshName === sourceSubmeshName
    );
  }
};

/**
 * Find the Babylon meshes in the scene that are associated with the specified
 * shape instance.
 *
 * This does not return Babylon meshes that are associated with shape instance's
 * mesh source. To find meshes associated with the mesh source, use
 * `findMeshesForMeshSource`.
 */
const findMeshesForShapeInstance = (scene: Scene, shapeInstanceId: string): AbstractMesh[] => {
  return scene.meshes.filter(m => m.metadata?.shapeInstanceId === shapeInstanceId);
};

/**
 * Update the Babylon meshes in the scene that are associated with the given
 * shape instance.
 */
const updateShapeInstanceMeshes = (scene: Scene, shapeInstance: ShapeInstance) => {
  findMeshesForShapeInstance(scene, shapeInstance.id).map(m =>
    updateShapeInstanceMesh(scene, shapeInstance, m)
  );
};

/**
 * Update the Babylon mesh in the scene so that it matches the properties in the
 * given shape instance.
 */
const updateShapeInstanceMesh = (
  scene: Scene,
  shapeInstance: ShapeInstance,
  mesh: AbstractMesh
) => {
  mesh.setEnabled(shapeInstance.isVisible);
  mesh.enablePointerMoveEvents = true;
  mesh.position = shapeInstance.position;

  mesh.scaling = shapeInstance.scaling;

  // TODO: Rather than constructing and disposing materials on each shape
  // instance change, SceneCanvas should take a list of MaterialSources that are
  // loaded and that ShapeInstances can reference.
  if (shapeInstance.material) {
    let oldMaterial = mesh.material;
    mesh.material = addMaterialToScene(shapeInstance.material, scene);
    if (oldMaterial) {
      oldMaterial.dispose();
    }
  } else {
    // TODO: Look up the material associated with the original mesh that was loaded.
    // mesh = undefined;
  }
  if (isRotateAboutAxex(shapeInstance.rotation)) {
    mesh.rotation = shapeInstance.rotation.angles;
  } else {
    mesh.lookAt(shapeInstance.rotation.target);
  }

  switch (shapeInstance.pickability) {
    case ShapeInstancePickablity.None: {
      mesh.isPickable = false;
      mesh.enablePointerMoveEvents = false;
      break;
    }
    case ShapeInstancePickablity.UpDown: {
      mesh.isPickable = true;
      mesh.enablePointerMoveEvents = false;
      break;
    }
    case ShapeInstancePickablity.UpDownMove: {
      mesh.isPickable = true;
      mesh.enablePointerMoveEvents = true;
      break;
    }
  }
};

/**
 * Canvas that renders a 3D scene.
 *
 * This component provides an abstraction over Babylon that allows the caller to
 * create and modify a Babylon scene in a declarative, React-friendly style.
 */
export const SceneCanvas = (props: Props) => {
  const {
    cameraConfig,
    lights,
    meshSources,
    shapeInstances,
    clearColor,
    onScenePointerDown,
    onScenePointerUp,
    onScenePointerMove,
    onResize,
    setMeshSourceIsLoading,
    // Pull out the HTML canvas properties.
    ...canvasProps
  } = props;

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const sceneRef = useRef<Scene>(null);

  // We put the initial camera config and the onResize callback behind refs so
  // that we can use them in the scene initialization useEffect without
  // re-running scene initialization every time these values change.
  const initCameraConfigRef = useRef<ArcRotateCameraConfig>(cameraConfig);
  const initOnResizeRef = useRef(onResize);

  // Keep track of which mesh sources are loading, have finished loading, and
  // have failed to load so that we don't accidentally start loading the same
  // source multiple times.
  const [meshSoureUrlToLoadingState, setMeshSourceUrlToLoadingState] = useState<
    Map<string, MeshSourceLoadingState>
  >(Map());

  // When the canvas element is mounted to the DOM, initialize the Babylon
  // scene, and save a reference to the scene so we can mutate it later.
  useEffect(() => {
    if (canvasRef.current) {
      let cleanup = initScene(
        canvasRef.current,
        scene => {
          initArcRotateCamera(scene, initCameraConfigRef.current);
          sceneRef.current = scene;

          // Call onResize upon initialization so that a parent component
          // listening to this event can know the size of the canvas after it
          // has initialized.
          if (initOnResizeRef.current) {
            let engine = sceneRef.current.getEngine();
            initOnResizeRef.current(engine.getRenderWidth(), engine.getRenderHeight());
          }
        },
        initOnResizeRef.current
      );
      return cleanup;
    }
  }, [canvasRef, initCameraConfigRef, initOnResizeRef]);

  // Update the clear color.
  useEffect(() => {
    if (sceneRef.current) {
      sceneRef.current.clearColor = clearColor ? clearColor : new Color4(0, 0, 0, 0);
    }
  });

  // Register pointer down event.
  useEffect(() => {
    if (sceneRef.current) {
      sceneRef.current.onPointerDown = onScenePointerDown;
    }
  }, [sceneRef, onScenePointerDown]);

  // Register pointer up event.
  useEffect(() => {
    if (sceneRef.current) {
      sceneRef.current.onPointerUp = onScenePointerUp;
    }
  }, [sceneRef, onScenePointerUp]);

  // Register pointer move event.
  useEffect(() => {
    if (sceneRef.current) {
      sceneRef.current.onPointerMove = onScenePointerMove;
    }
  }, [sceneRef, onScenePointerMove]);

  // Update the camera orientation. We initialize the camera before saving the
  // sceneRef, so it's safe to assume the scene has a camera.
  useEffect(() => {
    if (sceneRef.current) {
      updateCamera(sceneRef.current, cameraConfig);
    }
  }, [sceneRef, cameraConfig]);

  // When the lights change, delete all lights in the scene and then reconstruct
  // them.
  useEffect(() => {
    if (sceneRef.current) {
      for (const light of sceneRef.current.lights) {
        light.dispose();
      }

      for (const light of lights) {
        addLightToScene(light, sceneRef.current);
      }
    }
  }, [lights, sceneRef]);

  // When a new mesh source is added, start loading the mesh. When a mesh source
  // is removed, dispose the mesh.
  useEffect(() => {
    if (sceneRef.current) {
      setMeshSourceIsLoading && setMeshSourceIsLoading(true);

      // Start loading new mesh sources.
      for (const meshSource of meshSources) {
        const isNew = !meshSoureUrlToLoadingState.has(meshSource.url);
        if (isNew) {
          setMeshSourceIsLoading && setMeshSourceIsLoading(true);
          loadMeshSource(sceneRef.current, meshSource, setMeshSourceUrlToLoadingState);
        }
      }

      // Dispose removed mesh sources.
      for (const meshSourceUrl of meshSoureUrlToLoadingState.keys()) {
        const isRemoved = !meshSources.some(source => source.url === meshSourceUrl);
        if (isRemoved) {
          removeMeshSourceAndShapeInstances(
            sceneRef.current,
            meshSourceUrl,
            setMeshSourceUrlToLoadingState
          );
        }
      }
    }
  }, [meshSources, meshSoureUrlToLoadingState, sceneRef, setMeshSourceIsLoading]);

  // When a shape is added, render the shape in the scene. When a shape is
  // changed, update its properties in the scene. When a shape is removed,
  // remove it from the scene.
  useEffect(() => {
    if (sceneRef.current) {
      const scene = sceneRef.current;

      // If a shape's mesh source is loaded but the shape instance is not in the
      // scene yet, add the shape instance to the scene by cloning the Babylon
      // meshes associated with the mesh source.
      for (const shapeInstance of shapeInstances) {
        const isMeshSourceLoaded =
          meshSoureUrlToLoadingState.get(shapeInstance.sourceUrl, undefined) ===
          MeshSourceLoadingState.Loaded;
        const isShapeInstanceInScene =
          findMeshesForShapeInstance(scene, shapeInstance.id).length > 0;

        if (isMeshSourceLoaded) {
          setMeshSourceIsLoading && setMeshSourceIsLoading(false);
        }

        if (isMeshSourceLoaded && !isShapeInstanceInScene) {
          // A mesh source (eg, STL file or OBJ file) can contain multiple
          // meshes, and if `shapeInstance.sourceSubmeshName` is `null`, then
          // the shape instance should be the union of all those meshes.
          // Therefore, we search for all meshes in the scene that match the
          // source URL and (optionally) the source submesh name in the shape
          // instance, and we clone each of those meshes.
          const sourceMeshes = findMeshesForMeshSource(
            scene,
            shapeInstance.sourceUrl,
            shapeInstance.sourceSubmeshName
          );

          for (const sourceMesh of sourceMeshes) {
            const clone = sourceMesh.clone('', null);
            clone.metadata = {
              shapeInstanceId: shapeInstance.id,
              sourceUrl: shapeInstance.sourceUrl,
              sourceSubmeshName: shapeInstance.sourceSubmeshName,
            };
          }
        }
      }

      // Update each shape instance in the scene.
      for (const shapeInstance of shapeInstances) {
        updateShapeInstanceMeshes(scene, shapeInstance);
      }

      // If there is a mesh with a `shapeInstanceId` in the scene, but it
      // doesn't correspond to an element from `shapeInstances` then remove it.
      for (const mesh of scene.meshes) {
        if (
          mesh.metadata?.shapeInstanceId &&
          !shapeInstances.some(s => s.id === mesh.metadata.shapeInstanceId)
        ) {
          mesh.dispose();
        }
      }
    }
  }, [shapeInstances, meshSources, meshSoureUrlToLoadingState, sceneRef, setMeshSourceIsLoading]);

  return <canvas ref={canvasRef} {...canvasProps} />;
};

export default SceneCanvas;
