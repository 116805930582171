import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import { UserAnalyticsService, useUserAnalyticsContext } from 'fr-shared/context';

import { AddPartCard, CardWrapper, PartCard } from 'portal/components';
import { PartModel } from 'portal/types/parts';

export interface PartsGridViewProps {
  pageName: string;
  parts: PartModel[];
  onSort?: (sortValue: string) => void;
}

export const PartsGridView = ({ parts, pageName }: PartsGridViewProps) => {
  const history = useHistory();
  const userAnalytics: UserAnalyticsService = useUserAnalyticsContext();

  const logOnClickPart = () => userAnalytics.track(`${pageName} - View Part`);

  const handleClickingPart = (part: PartModel) => {
    const toComparison = history.location.pathname.includes('compare');
    const toUrl = toComparison
      ? `/studio/evaluate/${part.id}/compare`
      : `/studio/evaluate/${part.id}/dfm`;

    history.push(toUrl);
  };

  return (
    <CardWrapper>
      <AddPartCard />
      {parts.map(part => (
        <PartCard
          key={part.id}
          part={part}
          onClick={() => handleClickingPart(part)}
          logOnClickPart={logOnClickPart}
        />
      ))}
    </CardWrapper>
  );
};

export default memo(PartsGridView);
