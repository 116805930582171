import { AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';

import { api } from 'fr-shared/api';
import {
  Alert,
  Button,
  FormFieldBase,
  Icon,
  IconFont,
  Modal,
  classNames,
} from 'fr-shared/components';
import { AlertContext, useUserAnalyticsContext } from 'fr-shared/context';
import { useS3FilesUpload } from 'fr-shared/hooks';
import { AwsS3File, FileState, UploadError } from 'fr-shared/hooks/useS3FilesUpload';

/** Types */
interface SupportingDocumentsProps {
  bulkEdit?: boolean;
  dropzoneComponent?: any;
  readonly: boolean;
  isRounded?: boolean;
  documents: FRDocument[];
  baseDocumentURI: string;
  needsSupportingDocs?: boolean;
  onChange?: (documents: DocWithStatus[]) => void;
  [prop: string]: any;
}

export interface DocWithStatus {
  id?: number;
  file_name?: string;
  upload_status: 'loading' | 'success' | 'error';
  url?: string;
  uuid?: string;
  batchId?: string;
  deleted_at?: string;
  error?: UploadError;
}

interface FRDocument {
  id: number;
  file_name?: string;
  file_type?: string;
  s3_path?: string;
}

/** Helpers */

export const withStatus = (doc: FRDocument): DocWithStatus => ({
  ...doc,
  upload_status: 'success',
});

export const fileStateAsDoc = (fileState: FileState<AwsS3File | FRDocument>): DocWithStatus => ({
  id: fileState.data && 'id' in fileState.data ? fileState.data.id : undefined, // the S3 file may not have an id, which is ok
  file_name: fileState.file.name,
  upload_status: fileState.upload_status,
  uuid: fileState.uuid,
  batchId: fileState.batchId,
  error: fileState.error,
});

/** Component */

const SupportingDocuments = ({
  bulkEdit = false,
  dropzoneComponent,
  readonly,
  isRounded = true,
  documents,
  baseDocumentURI,
  needsSupportingDocs,
  onChange,
  ...formProps
}: SupportingDocumentsProps) => {
  const userAnalytics = useUserAnalyticsContext();
  const [docsWithStatus, setDocsWithStatus] = useState<DocWithStatus[]>(
    documents.map(withStatus) || []
  );

  const onFileUploadSuccess = (res: any) => {
    userAnalytics.track('Part Config - Supporting Doc Uploaded', {
      fileName: res?.data?.file_name,
    });
  };
  const onFileUploadFail = (error: any) => {
    userAnalytics.track('Part Config - Supporting Doc Uploaded Failed', {
      error: error?.config?.data || error,
    });
  };

  // Define file upload method
  const addDocument = (response: AxiosResponse<AwsS3File>, file: File) => {
    const { filename, path } = response.data;
    const fileType = filename.match(/[^.]+$/)[0];
    const fileAttrs: FRDocument = {
      id: undefined,
      file_name: file.name,
      file_type: fileType,
      s3_path: path,
    };

    if (baseDocumentURI) {
      return api.post<FRDocument>(baseDocumentURI, { document: fileAttrs });
    } else {
      return Promise.resolve({ data: fileAttrs });
    }
  };

  const updateDocState = (updateFn: (docs: DocWithStatus[]) => DocWithStatus[]) => {
    setDocsWithStatus(docs => {
      const newDocs = updateFn(docs);
      onChange(newDocs);
      return newDocs;
    });
  };

  const [supportingDocuments, uploadSupportingDocuments] = useS3FilesUpload<FRDocument>(
    '/s3/sign/part_file',
    addDocument,
    onFileUploadSuccess,
    onFileUploadFail
  );

  /**
   * When the useS3FilesUpload state updates, also internally update this component's combined state
   * (existing files, deleted files, and newly uploaded files).
   *
   * Filter out the documents that don't match the supporting docs to keep -
   * either no uuid or the uuid doesn't match any of the supporting documents.
   * Then keep the supporting docs that were previously already on the state, but maybe their upload_status updated.
   *
   * Ignore the dep on updateDocState since we know that uses the state hook under the hood.
   */
  useEffect(() => {
    updateDocState(docs => {
      const originalDocs = docs.filter(
        d => !d.uuid || supportingDocuments.every(s => s.uuid !== d.uuid)
      );
      const supportingDocsToKeep = supportingDocuments.filter(s =>
        docs.some(d => d.uuid === s.uuid)
      );
      return [...originalDocs, ...supportingDocsToKeep.map(fileStateAsDoc)];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportingDocuments]);

  const handleDrop = (files: any) => {
    const uploadFiles = files.map((file: any) => ({ file }));
    const loadingDocuments = uploadSupportingDocuments(uploadFiles);
    updateDocState(existingDocs => [...existingDocs, ...loadingDocuments.map(fileStateAsDoc)]);
  };

  const handleDeleteDoc = (doc: DocWithStatus) => {
    updateDocState(docsWithStatus => {
      // Delete either an uploaded file (by id), or remove a doc that failed to upload (by uuid)
      if (doc.id) {
        return docsWithStatus.filter(d => d.id !== doc.id);
      } else if (doc.uuid) {
        return docsWithStatus.filter(d => d.uuid !== doc.uuid);
      }
    });
  };

  const handleDeleteDocs = (docs: DocWithStatus[]) => {
    updateDocState(docsWithStatus => {
      // Delete either an uploaded file (by id), or remove a doc that failed to upload (by uuid)
      return docsWithStatus.filter(
        d => !docs.some(docToDelete => d.uuid === docToDelete.uuid || d.id === docToDelete.id)
      );
    });
  };

  const nonDeletedDocs = docsWithStatus.filter(d => !d.deleted_at);
  // Group the docs with errors, and identify the batch for multiple errors
  const failedDocs = nonDeletedDocs.filter(d => d.upload_status === 'error');
  const allFailedBatchIds = new Set(failedDocs.map(d => d.batchId).filter(batchId => !!batchId));
  const failedDocsBatched: Record<string, Array<DocWithStatus>> = Array.from(
    allFailedBatchIds
  ).reduce((batchedDocs, currBatchId) => {
    const batch = failedDocs.filter(d => d.batchId === currBatchId);
    // Consider it a failed batch only if more than one failure in the batch
    if (batch.length > 1) {
      return { ...batchedDocs, [currBatchId]: batch };
    }
    return batchedDocs;
  }, {});

  // Identify the failed docs that are unbatched (single)
  const failedBatchIds = Object.keys(failedDocsBatched);
  const failedDocsUnbatched = failedDocs.filter(d => !failedBatchIds.includes(d.batchId));

  // Identify the docs without errors
  const otherDocs = nonDeletedDocs.filter(d => d.upload_status !== 'error');

  return (
    <>
      {!bulkEdit ? (
        <FormFieldBase
          readonly={readonly}
          label="Supporting documents"
          className="h-[inherit]"
          {...formProps}
        >
          <>
            <div>
              {Object.values(failedDocsBatched).map((failedBatch: DocWithStatus[]) => {
                return (
                  <Alert
                    className="mb-1 block"
                    color="danger"
                    toggle={() => handleDeleteDocs(failedBatch)}
                    key={failedBatch[0].batchId}
                  >
                    <FailedBatch docs={failedBatch} />
                  </Alert>
                );
              })}
              {failedDocsUnbatched.map((failedDoc: DocWithStatus) => {
                return (
                  <SupportingDocItem
                    key={failedDoc.uuid}
                    basePath={baseDocumentURI}
                    doc={failedDoc}
                    onDelete={() => handleDeleteDoc(failedDoc)}
                    readonly={readonly}
                  />
                );
              })}
              {otherDocs.map((doc: DocWithStatus) => {
                return (
                  <SupportingDocItem
                    key={doc.id || doc.uuid}
                    basePath={baseDocumentURI}
                    doc={doc}
                    onDelete={() => handleDeleteDoc(doc)}
                    readonly={readonly}
                  />
                );
              })}
            </div>
            {!readonly && (
              <Dropzone onDrop={handleDrop}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} id="file-dropzone" />
                    {dropzoneComponent ? (
                      dropzoneComponent
                    ) : (
                      <div
                        className={classNames([
                          'card',
                          'py-3 px-3',
                          'bg-white',
                          'flex align-items-center justify-content-center',
                          isDragActive && 'is-dragging',
                          !isRounded && 'rounded-0',
                        ])}
                      >
                        <>
                          <div className="mb-3 font-size-md text-center">
                            <div>
                              Drop supporting documents, such as <br /> part drawings, here to
                              upload
                            </div>
                          </div>

                          <Button
                            color="primary"
                            outline={true}
                            className={classNames(['px-5', !isRounded && 'rounded-0'])}
                          >
                            Select Files
                          </Button>
                        </>
                      </div>
                    )}
                  </div>
                )}
              </Dropzone>
            )}
          </>
        </FormFieldBase>
      ) : (
        <div>
          <Dropzone onDrop={handleDrop} noClick>
            {({ getInputProps, open }) => (
              <>
                <input {...getInputProps()} id="file-dropzone" data-testid="file-dropzone" />
                <div className="flex flex-row w-full justify-end">
                  <button
                    onClick={open}
                    className={classNames([
                      'flex flex-row items-center bg-transparent border-none p-0 text-md',
                      needsSupportingDocs ? 'text-warning-300' : 'text-white',
                    ])}
                  >
                    <IconFont name="plus" className="mr-1" /> Supporting documents
                  </button>
                </div>
              </>
            )}
          </Dropzone>
          <div>
            {Object.values(failedDocsBatched).map((failedBatch: DocWithStatus[]) => {
              return (
                <Alert
                  className="mb-1 block"
                  color="danger"
                  toggle={() => handleDeleteDocs(failedBatch)}
                  key={failedBatch[0].batchId}
                >
                  <FailedBatch docs={failedBatch} />
                </Alert>
              );
            })}
            {failedDocsUnbatched.map((failedDoc: DocWithStatus) => {
              return (
                <SupportingDocItem
                  key={failedDoc.uuid}
                  basePath={baseDocumentURI}
                  bulkEdit
                  doc={failedDoc}
                  onDelete={() => handleDeleteDoc(failedDoc)}
                  readonly={readonly}
                />
              );
            })}
            {otherDocs.map((doc: DocWithStatus) => {
              return (
                <SupportingDocItem
                  key={doc.id || doc.uuid}
                  basePath={baseDocumentURI}
                  bulkEdit
                  doc={doc}
                  onDelete={() => handleDeleteDoc(doc)}
                  readonly={readonly}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

interface DeleteSupportingDocModalProps {
  basePath: string;
  doc: DocWithStatus;
  onDelete: () => void;
}

const DeleteSupportingDocModal = ({
  basePath,
  doc,
  onDelete = () => {},
}: DeleteSupportingDocModalProps) => {
  const { setAlert } = useContext(AlertContext);

  const handleDelete = (toggle: () => void) => {
    if (doc.id) {
      api
        .delete(`${basePath}/${doc.id}`)
        .then(() => {
          toggle();
          onDelete();
        })
        .catch(() => {
          setAlert({
            message:
              'Sorry, we were unable to delete the supporting document. Please refresh and try again.',
            color: 'danger',
          });
        });
    } else {
      toggle();
      onDelete();
    }
  };

  return (
    <Modal
      action={
        <button className="bg-transparent border-none p-0">
          <IconFont className="text-2xl text-coolGray-300" name="trash" />
        </button>
      }
    >
      {({ toggle }: { toggle: () => void }) => (
        <>
          <Modal.Header title="Delete Supporting Document" onClose={toggle} />
          <div className="modal-body">
            <p>Are you sure you want to delete {doc.file_name}?</p>
          </div>
          <div className="modal-footer">
            <Button outline onClick={toggle}>
              Cancel
            </Button>
            <Button onClick={() => handleDelete(toggle)} color="danger" className="ml-2">
              Delete
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

/** Returns a description for the failed document error. */
function singleFailedDocDescription(error: UploadError): string {
  const genericError = 'failed to upload.';
  if (!error) return genericError;
  if (error.type === 'unsupported')
    return 'failed to upload. Please select a different file type.';
  return genericError;
}

/** Renders a failed batch of docs, grouped by their error type. */
const FailedBatch = ({ docs }: { docs: DocWithStatus[] }) => {
  const docsByErrorType: Record<string, DocWithStatus[]> = ['unknown', 'unsupported'].reduce(
    (docMap, errorType) => {
      const relatedDocs = docs.filter(d => d.error?.type === errorType);
      if (relatedDocs.length > 0) {
        return { ...docMap, [errorType]: relatedDocs };
      }
      return docMap;
    },
    {}
  );

  return (
    <>
      {Object.keys(docsByErrorType).map(type => (
        <div className="mb-1 last-of-type:mb-0" key={type}>
          {type === 'unsupported'
            ? 'These files require a different file type:'
            : 'These files failed to upload:'}
          <ul className="pl-3 mb-0">
            {docsByErrorType[type].map(doc => (
              <li key={doc.uuid}>{doc.file_name}</li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};

interface SupportingDocItemProps {
  basePath: string;
  bulkEdit?: boolean;
  doc: DocWithStatus;
  onDelete: () => void;
  readonly?: boolean;
}

const SupportingDocItem = ({
  bulkEdit = false,
  basePath,
  doc,
  onDelete,
  readonly,
}: SupportingDocItemProps) => {
  let name = doc.file_name;
  let failed = doc.upload_status === 'error';
  let loading = doc.upload_status === 'loading';

  return (
    <div
      className={classNames([
        'supporting-doc-item flex items-center leading-none',
        failed && 'alert alert-danger p-0 px-1 my-1',
        bulkEdit &&
          !failed &&
          'border border-solid border-coolGray-600 rounded bg-coolGray-900 px-2 py-1 mt-1 first:mt-2',
      ])}
    >
      {loading && <Icon name="spinner" className="fa-pulse text-primary mr-1" />}
      {failed ? (
        <div className="relative w-full pr-3 text-error-700">
          <span className="font-bold">{name}</span> {singleFailedDocDescription(doc.error)}
          <button
            className="text-error-400 border-0 bg-transparent absolute right-0 top-0 leading-4 text-xl p-0"
            onClick={onDelete}
          >
            <IconFont name="close-filled" />
          </button>
        </div>
      ) : (
        <>
          <IconFont className="text-2xl text-coolGray-100 flex mr-1" name="document" />
          <Button
            disabled={doc.upload_status === 'loading'}
            color="link"
            onClick={() => window.open(doc.url)}
            className="break-all whitespace-pre-wrap text-left"
          >
            {name}
          </Button>
        </>
      )}
      {!loading && !readonly && !failed && (
        <div className="ml-auto">
          <DeleteSupportingDocModal basePath={basePath} doc={doc} onDelete={onDelete} />
        </div>
      )}
    </div>
  );
};

export default SupportingDocuments;
