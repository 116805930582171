import PropTypes from 'prop-types';
import React from 'react';

import { classNames } from 'portal/components';

import styles from './PageHeader.module.css';

const PageHeader = ({
  actions,
  actionClassnames,
  border = true,
  className,
  dataTestId,
  children,
  subtitle,
  title,
}) => {
  return (
    <div
      className={classNames(['mb-3', border && 'border-bottom pb-3', className])}
      data-testid={dataTestId}
    >
      <div className={styles.PageHeader}>
        <div className={styles.Title}>
          {title && <h1 className="h6">{title}</h1>}
          {subtitle && <p>{subtitle}</p>}
        </div>
        {actions && <div className={classNames(['ml-auto', actionClassnames])}>{actions}</div>}
      </div>
      {children}
    </div>
  );
};

PageHeader.propTypes = {
  actions: PropTypes.node,
  actionClassnames: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  border: PropTypes.bool,
};

export default PageHeader;
