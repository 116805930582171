import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export const SidebarContext = React.createContext({
  open: null,
  setOpenValues: null,
  toggle: null,
  localStorageKey: null,
});

const initialSidebarState = (defaultOpen, localStorageKey) => {
  return typeof defaultOpen !== 'undefined'
    ? defaultOpen
    : localStorage.getItem(localStorageKey) === 'true';
};

export const SidebarProvider = ({ children, localStorageKey, defaultOpen }) => {
  const [open, setOpen] = useState(initialSidebarState(defaultOpen, localStorageKey));
  const toggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    localStorage.setItem(localStorageKey, open);
  }, [open, localStorageKey]);

  return (
    <SidebarContext.Provider value={{ open, setOpen, toggle }}>
      {children}
    </SidebarContext.Provider>
  );
};

SidebarProvider.propTypes = {
  children: PropTypes.node,
  localStorageKey: PropTypes.string,
  defaultOpen: PropTypes.bool,
};

export default SidebarContext;
