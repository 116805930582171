import PropTypes from 'prop-types';
import React from 'react';

import { IconFont, classNames } from 'fr-shared/components';

const FormFieldBase = ({
  append,
  children,
  labelClassName,
  className,
  dataTestId,
  error,
  isRequired,
  isControl,
  isStacked,
  label,
  md,
  name,
  prepend,
  readonly,
  showLabel,
  style,
  value,
  tooltip,
}) => {
  // isControl is used when wrapping an actual DOM input or select,
  // in which case we need to align the label slightly differently
  const isFieldStacked = isControl || isStacked;

  const decoratedClassNames = {
    readonly: readonly,
    'aria-readonly': readonly,
    hidden: !value && readonly,
  };

  const wrapperClasses = classNames([
    'row',
    'form-group',
    error && 'is-invalid',
    isControl && 'form-field-stacked-control',
    className,
  ]);

  const decoratedInput = (
    <div className="input-group">
      {prepend && (
        <div className={classNames(['input-group-prepend', decoratedClassNames])}>
          <span className="input-group-text">{prepend}</span>
        </div>
      )}
      {children}
      {append && (
        <div className={classNames(['input-group-append ', decoratedClassNames])}>
          <span className="input-group-text">{append}</span>
        </div>
      )}
    </div>
  );

  const labelComponent = (
    <label
      htmlFor={name}
      className={classNames(['whitespace-nowrap', isControl ? 'mb-1' : 'mb-0', labelClassName])}
    >
      {label} {isRequired && !readonly && <span className="text-error">*</span>}
      {tooltip && <IconFont className="ml-1" name="info-circle" tooltip={tooltip} />}
    </label>
  );

  const component = prepend || append ? decoratedInput : children;

  const errorMessages =
    typeof error === 'string'
      ? [<p key={0}>{error}</p>]
      : (error ?? []).map((message, i) => <p key={i}>{message}</p>);

  return (
    <div className={wrapperClasses} data-testid={dataTestId} style={style}>
      {isFieldStacked ? (
        <div className="col-md">
          {showLabel && label && labelComponent}

          {component}

          {errorMessages.length > 0 && (
            <div className="form-control-error" data-testid={`errors-${name}`}>
              {errorMessages}
            </div>
          )}
        </div>
      ) : (
        <>
          {showLabel && label && <div className={`col-md-${md ? md : 4}`}>{labelComponent}</div>}

          <div className="col-md">
            {component}

            {errorMessages.length > 0 && (
              <div className="form-control-error" data-testid={`errors-${name}`}>
                {errorMessages}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

FormFieldBase.propTypes = {
  append: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  children: PropTypes.node,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  labelClassName: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  readonly: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isControl: PropTypes.bool,
  isRequired: PropTypes.bool,
  isStacked: PropTypes.bool,
  label: PropTypes.string,
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  prepend: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  showLabel: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.any,
  tooltip: PropTypes.string,
};

FormFieldBase.defaultProps = {
  showLabel: true,
  value: '',
};

export default FormFieldBase;
