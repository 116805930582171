import PropTypes from 'prop-types';
import React from 'react';

import { FormSelect } from 'fr-shared/components';
import { useInspectionTypes } from 'fr-shared/hooks';

const nameWithPrice = inspectionType => inspectionType.name_with_price;

const FormInspectionTypes = ({ label, name, ...props }) => {
  let { data: inspectionTypes } = useInspectionTypes();
  if (!inspectionTypes) return null;
  inspectionTypes = inspectionTypes.map(val => {
    const getInspectionPrice = val => {
      if (val.price) {
        return val?.price?.amount === '0.00' ? '' : `(${val?.price?.amount} per line item)`;
      } else {
        return '';
      }
    };
    return {
      ...val,
      name_with_price: `${val.name} ${getInspectionPrice(val)}`,
    };
  });

  return (
    <FormSelect
      name={name}
      nameAccessor={nameWithPrice}
      label={label}
      optionList={inspectionTypes}
      required={true}
      {...props}
    />
  );
};

FormInspectionTypes.defaultProps = {
  label: 'Inspection Type',
};

FormInspectionTypes.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FormInspectionTypes;
