import React, { useState } from 'react';

import { IconFont, Pagination, classNames } from 'fr-shared/components';

import styles from './Carousel.module.css';

export const Carousel = () => {
  const [pageNumber, setPageNumber] = useState(1);

  const content = [
    {
      title: 'What happens next?',
      paragraph: `
        We’ll run our automated DFM checks. DFM (Design for Manufacturability) checks
        tell you if anything in your designs could be difficult to manufacture, before
        you go into production.
      `,
    },
    {
      title: 'Get cost estimates',
      paragraph: `
        When you choose your manufacturing process and material, we'll create cost estimates.
        These predict the cost per part and the most cost-effective number of parts for your
        order.
      `,
    },
    {
      title: 'Compare your materials',
      paragraph: `
        You can explore performance metrics for your chosen materials, like density and
        elongation. Then compare materials across manufacturing processes, to see what meets your
        needs best.
      `,
    },
  ];

  return (
    <div>
      <div className={classNames(['border-1 border-solid rounded p-2 border-coolGray-700'])}>
        <div className="flex">
          <IconFont
            name="lightbulb"
            className={classNames([styles.LightBulb, 'text-coolGray-300 font-size-xl'])}
          />

          <div className="ml-1">
            <p className="text-coolGray-100 mb-0 text-xs">{content[pageNumber - 1].title}</p>

            <p className="text-coolGray-300 mb-0 text-xs">{content[pageNumber - 1].paragraph}</p>
          </div>
        </div>
      </div>

      <Pagination
        enabledChevronClassName={styles.EnabledChevron}
        activePaginationCircleClassName={styles.ActivePaginationCircle}
        inactivePaginationCircleClassName={styles.InactivePaginationCircle}
        className={classNames(['flex', 'justify-content-end', 'mt-1'])}
        page={pageNumber}
        totalPages={content.length}
        pageNumberStyle={'circular'}
        showArrows
        setPage={setPageNumber}
      />
    </div>
  );
};

export default Carousel;
