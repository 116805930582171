/* eslint-disable react/display-name,react/prop-types */
import { values } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, Helmet, Link, Table } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';

const MarginControlIndex = () => {
  const [costMargins, setCostMargins] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    api.get('/cost_margins').then(res => {
      setCostMargins(
        values(res.data).sort((a, b) =>
          a.manufacturing_process.name > b.manufacturing_process.name ? 1 : -1
        )
      );
    });
  }, []);

  return (
    <>
      <Helmet title="Margin Control" />
      <Breadcrumb to="/admin/margin_control">Margin Control</Breadcrumb>
      <div className="container">
        <div className="container">
          <div className="flex align-items-center py-3">
            <h2 className="mb-0">Margin Control</h2>
          </div>
          <Table
            data={costMargins}
            pageSize={costMargins.length < 20 ? costMargins.length : 20}
            className="bg-white -striped mb-5"
            columns={[
              { Header: 'Manufacturing Process', accessor: 'manufacturing_process.name' },
              {
                Header: 'Portal Gross Margin',
                id: 'margins',
                accessor: d => <div>{d.margin_percentage}%</div>,
              },
              {
                show: user.canEditMargins,
                Header: '',
                id: 'Update',
                Cell: row => {
                  const manufacturing_process_id = row.original.manufacturing_process.id;
                  return (
                    <Link
                      to={{
                        pathname: `/admin/margin_control/${manufacturing_process_id}/edit`,
                        state: { ...row.original, manufacturing_process_id },
                      }}
                    >
                      Update
                    </Link>
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default MarginControlIndex;
