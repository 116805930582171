import { AxiosError } from 'axios';
import { groupBy, sortBy } from 'lodash';
import React, { FunctionComponent, useState } from 'react';

import { useAuditLogs } from 'fr-shared/hooks';
import { AuditLogQueryObject } from 'fr-shared/lib/audit_log';

import Loading from '../core/Loading/Loading';
import AuditLogBatch from './AuditLogBatch';

interface AuditLogProps {
  descriptionFormatter?(): string | FunctionComponent;
  detailEvents: string[];
  error?: AxiosError | null;
  logHeading: string;
  logObject: AuditLogQueryObject;
}

const AuditLog = ({
  descriptionFormatter,
  detailEvents,
  logHeading,
  logObject,
}: AuditLogProps) => {
  const [isError, setIsError] = useState(false);
  const auditLogs = useAuditLogs(logObject);

  if (auditLogs.isError && !isError) {
    setIsError(true);
  }

  if (isError && auditLogs.isLoading) {
    return <div>{auditLogs.error?.toString()}</div>;
  }

  if (isError && auditLogs.isFetched) {
    if (auditLogs.isError) {
      return <div>{auditLogs?.error?.toString()}</div>;
    } else {
      setIsError(false);
    }
  }

  if (!isError && auditLogs.isLoading) {
    return (
      <div className="text-center">
        <Loading />
      </div>
    );
  }

  if (!isError && (!auditLogs.data || auditLogs.data?.length === 0)) {
    return <div className="text-center">No activity to display</div>;
  }

  const sortedLogs = sortBy(auditLogs.data, ({ sort_key }) => sort_key).reverse();
  const batches = groupBy(sortedLogs, auditLog => auditLog.changes?.meta?.batch_id);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-8 mx-auto">
        <div className="card-body">
          <div className="row">
            <div className="col-md text-muted" data-testid="audit-log-disclaimer">
              <i className="fal fa-eye-slash text-muted"></i>&nbsp;
              {logHeading} Activity information is only visible to the internal FR team
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              {Object.entries(batches).map(([batchId, batch]) => (
                <AuditLogBatch
                  key={batchId}
                  batch={batch}
                  batchId={batchId}
                  descriptionPresenter={descriptionFormatter}
                  detailEvents={detailEvents}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditLog;
