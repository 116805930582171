import PropTypes from 'prop-types';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

const GoogleAnalyticsWrapper = ({ debug = false, children }) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('UA-61919525-1', { testMode: process.env.MIX_ENV === 'dev', debug });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname) {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
  }, [location]);

  return children;
};

GoogleAnalyticsWrapper.propTypes = {
  debug: PropTypes.bool,
  children: PropTypes.node,
};

export default GoogleAnalyticsWrapper;
