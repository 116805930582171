/* eslint-disable react/display-name */
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { Checkbox, IconButton, Table } from 'fr-shared/components';

import DeletePSLIModal from './DeletePSLIModal';
import EditPSLIModalForm from './EditPSLIModalForm';
import styles from './PSLITable.module.css';
import { getPsliStatusBadge } from './PSLITableShow';

interface PSLITableEditProps {
  oliIndex: number;
  plannedShipmentLineItems: Array<PlannedShipmentLineItem>;
  quantity: string;
  isExistingOrder: boolean;
}

export const formatNullableDate = (date: string) =>
  date ? moment(date).format('MM/DD/YYYY') : '- -';

const PSLITableEdit = ({
  plannedShipmentLineItems,
  oliIndex,
  quantity,
  isExistingOrder,
}: PSLITableEditProps) => {
  const { setFieldError, setFieldValue, values } = useFormikContext();
  const [pslis, setPslis] = useState([]);

  const oliSelectedFieldPrefix = `line_items.${oliIndex}.selected`;
  const isOliSelected = get(values, oliSelectedFieldPrefix, false);

  useEffect(() => {
    if (plannedShipmentLineItems && plannedShipmentLineItems.length) {
      const validPSLIs = plannedShipmentLineItems.filter(
        (item: PlannedShipmentLineItem) => item.id !== undefined || item.valid
      );
      const indexedPSLIs = validPSLIs.map((psli, index) => ({
        index,
        ...psli,
      }));
      setPslis(indexedPSLIs);
    }
  }, [plannedShipmentLineItems]);

  useEffect(() => {
    pslis.forEach((psli: PlannedShipmentLineItem, index: number) => {
      if (psli.remaining_to_fulfill > 0) {
        const psliSelectedFieldPrefix = `line_items.${oliIndex}.planned_shipment_line_items.${index}.selected`;
        setFieldValue(psliSelectedFieldPrefix, isOliSelected ? true : false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOliSelected]);

  if (!pslis.length) return null;
  return (
    <Table
      className={styles.CheckboxCell}
      data={pslis}
      pageSize={pslis.length || 5}
      showPagination={false}
      sortable={false}
      columns={[
        {
          Header: () => {
            return (
              <Checkbox
                name={oliSelectedFieldPrefix}
                value={isOliSelected}
                onChange={() => {
                  setFieldValue(oliSelectedFieldPrefix, !isOliSelected);
                }}
              />
            );
          },
          id: 'checkbox',
          width: 60,
          accessor: (d: any) => {
            const psliFieldPrefix = `line_items.${oliIndex}.planned_shipment_line_items.${d.index}.selected`;
            const isPsliSelected = get(values, psliFieldPrefix, false);
            if (d.remaining_to_fulfill <= 0) return null;
            return (
              <Checkbox
                dataTestId={`select-psli-checkbox-${d.index}`}
                className="mb-0"
                name={psliFieldPrefix}
                value={isPsliSelected}
                onChange={() => {
                  setFieldValue(psliFieldPrefix, !isPsliSelected);
                }}
              />
            );
          },
        },
        {
          Header: 'Units to be Shipped',
          id: 'psli-table-quantity',
          accessor: (d: PlannedShipmentLineItem) => d.quantity,
        },
        {
          Header: 'Commit Date',
          id: 'psli-table-commit_date',
          accessor: (d: PlannedShipmentLineItem) => moment(d.commit_date).format('MM/DD/YYYY'),
        },
        {
          Header: 'Customer Promise Date',
          id: 'psli-table-promised_ship_date',
          accessor: (d: PlannedShipmentLineItem) =>
            moment(d.promised_ship_date).format('MM/DD/YYYY'),
        },
        {
          Header: 'Supplier Promise Date',
          id: 'psli-table-supplier_promised_ship_date',
          accessor: 'supplier_promised_ship_date',
          Cell: (d: any) => <div>{formatNullableDate(d.value)}</div>,
        },
        {
          Header: 'Status',
          id: 'psli-table-status',
          accessor: (d: PlannedShipmentLineItem) => getPsliStatusBadge(isExistingOrder, d),
          sortable: false,
        },
        {
          Header: 'Actions',
          id: 'psli-table-actions',
          accessor(data: any) {
            if (data.remaining_to_fulfill <= 0) return null;
            return (
              <>
                <EditPSLIModalForm
                  action={
                    <IconButton
                      dataTestId={`psli-table-edit-${data.index}`}
                      label="icon-button-edit"
                      icon="edit"
                    />
                  }
                  onActionHandler={() => {
                    const formikPrefix = `line_items.${oliIndex}.planned_shipment_line_items.${data.index}`;
                    // reset errors if form was opened, submitted invalid, closed and opened again.
                    setFieldError(formikPrefix, null);
                  }}
                  oliIndex={oliIndex}
                  psliIndex={data.index}
                  quantity={quantity}
                />
                {data.already_fulfilled === 0 && (
                  <DeletePSLIModal
                    action={
                      <IconButton
                        dataTestId={`psli-table-delete-${data.index}`}
                        label="icon-button-delete"
                        icon="trash-alt"
                        iconType="far"
                      />
                    }
                    oliIndex={oliIndex}
                    psliIndex={data.index}
                    psliQuantity={data.quantity}
                  />
                )}
              </>
            );
          },
        },
      ]}
    />
  );
};

export default PSLITableEdit;
