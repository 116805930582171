import { useFormikContext } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { FormSelect } from 'fr-shared/components';
import { usePrinters } from 'fr-shared/hooks';
import { hasProcess } from 'fr-shared/lib/work_orders';

const PrinterFormField = ({ labelText, ...props }) => {
  const { data: printers } = usePrinters();
  const formik = useFormikContext();

  if (!printers) return null;

  const printersForWorkOrder = printers.filter(printer => {
    const printerProcess = get(printer, 'printer_type.manufacturing_process.name');
    if (!printerProcess) return true;
    return hasProcess(formik.values, printer.printer_type.manufacturing_process.name);
  });

  return (
    <FormSelect
      label={labelText}
      name="printer_id"
      optionList={printersForWorkOrder}
      {...props}
    />
  );
};

PrinterFormField.propTypes = {
  labelText: PropTypes.string,
};

PrinterFormField.defaultProps = {
  labelText: 'Printer',
};

export default PrinterFormField;
