import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';

import { classNames } from 'fr-shared/components';
import { keyboardDown } from 'fr-shared/utils';

import styles from './FormToggleSwitch.module.css';

interface ToggleSwitchProps {
  dataTestId?: string;
  defaultTrueLabel: string;
  defaultFalseLabel: string;
  name: string;
  id: string;
  checked: boolean;
  activeClassName?: string;
  toggleClassName?: string;
  toggleTextClassName?: string;
  toggleSwitchLabelClassName?: string;
  textContainerClassName?: string;
  onToggle?: () => void;
}

export const ToggleSwitch = ({
  dataTestId,
  defaultTrueLabel,
  defaultFalseLabel,
  name,
  id,
  checked,
  activeClassName,
  toggleClassName,
  toggleTextClassName,
  toggleSwitchLabelClassName,
  textContainerClassName,
  onToggle,
}: ToggleSwitchProps) => {
  const formik: any = useFormikContext();
  const [isChecked, setIsChecked] = useState(checked);
  const setFieldToggle = () => {
    formik.setFieldValue(id, !isChecked);
    setIsChecked(!isChecked);
    onToggle && onToggle();
  };

  return (
    <div className={styles.Root}>
      <Field
        type="checkbox"
        className={styles.Checkbox}
        checked={isChecked}
        id={id}
        name={name}
        onChange={setFieldToggle}
      />
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions*/}
      <label
        className={classNames([styles.ToggleSwitchLabel, toggleSwitchLabelClassName])}
        data-testid={dataTestId}
        htmlFor={id}
        onKeyDown={evt => keyboardDown(evt, 'Enter', setFieldToggle)}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <div
          className={classNames([
            styles.Toggle,
            !formik.values[id] && styles.active,
            !formik.values[id] && activeClassName,
            toggleClassName,
          ])}
        />
        <div className={classNames([styles.TextContainer, textContainerClassName])}>
          <span
            className={classNames([
              styles.ToggleTxt,
              formik.values[id] && styles.active,
              toggleTextClassName,
              formik.values[id] && activeClassName,
            ])}
          >
            {defaultTrueLabel}
          </span>
          <span
            className={classNames([
              styles.ToggleTxt,
              !formik.values[id] && styles.active,
              toggleTextClassName,
              !formik.values[id] && activeClassName,
            ])}
          >
            {defaultFalseLabel}
          </span>
        </div>
      </label>
    </div>
  );
};

export default ToggleSwitch;
