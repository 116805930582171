import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, GlobalErrorBoundary, Helmet, NotFound } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';

import ShipmentForm from './components/ShipmentForm';

const ShipmentEdit = ({ history, match }) => {
  const { setAlert } = useContext(AlertContext);
  const [initialValues, setInitialValues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const shipmentId = match.params.id;

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`/shipments/${shipmentId}`)
      .then(res => setInitialValues(res.data))
      .catch(error => {
        if (error.response?.status !== 404) {
          setHasError(true);
          Sentry.captureMessage('Shipment GET Failed');
          Sentry.setExtra('shipment_id', shipmentId);
          Sentry.setExtra('error', error);
        }
      })
      .finally(() => setIsLoading(false));
  }, [shipmentId]);

  const handleSubmit = (values, formik) => {
    api
      .put(`/shipments/${values.id}`, {
        shipment: values,
        send_order_shipped: values.send_order_shipped,
      })
      .then(res => {
        history.push(`/admin/shipments/${res.data.id}`);
      })
      .catch(err => {
        setAlert({
          color: 'danger',
          message:
            'There was an error submitting the shipment. Please fix all of the marked fields.',
        });
        if (err.response?.data) {
          const { errors, messages } = err.response.data;
          formik.setErrors({ server: messages, ...errors });
        } else {
          formik.setErrors({
            server: ['An unexpected error occurred. Refresh and try again.'],
          });
        }
      });
  };

  if (isLoading) return null;
  if (hasError) return <GlobalErrorBoundary />;
  if (!initialValues) return <NotFound />;

  return (
    <>
      <Helmet title="Shipments" />
      <Breadcrumb to="/admin/shipments">Shipments</Breadcrumb>
      <ShipmentForm handleSubmit={handleSubmit} initialValues={initialValues} />
    </>
  );
};

ShipmentEdit.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default ShipmentEdit;
