import React from 'react';

import { UserAnalyticsService, useUserAnalyticsContext } from 'fr-shared/context';

import { EmptyOrdersCard, Link } from 'portal/components';
import OrderGridView from 'portal/pages/orders/components/GridView';

interface RecentOrdersProp {
  hasOrders: boolean;
  recentOrders: any[] /* TODO: create interface once other order components are typescript as well to share */;
}

export const RecentOrders = ({ hasOrders, recentOrders }: RecentOrdersProp) => {
  const userAnalytics: UserAnalyticsService = useUserAnalyticsContext();

  const handleAllOrdersClick = () => userAnalytics.track('View All Orders', { valid: true });
  const logCreateQuote = () => userAnalytics.track('Create Quote CTA - Dash', { valid: true });

  const Header = () => (
    <div className="mb-4 flex align-items-center">
      <h3>Recent orders</h3>
      {hasOrders && (
        <Link className="ml-2 underline" to="/orders" onClick={handleAllOrdersClick}>
          View all
        </Link>
      )}
    </div>
  );

  return (
    <div className="py-2 pt-3">
      {hasOrders && <Header />}

      {hasOrders ? (
        <OrderGridView orders={recentOrders} dataTestId="recent-order" />
      ) : (
        <EmptyOrdersCard onActionClick={logCreateQuote} />
      )}
    </div>
  );
};

export default RecentOrders;
