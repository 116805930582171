import React, { ReactElement } from 'react';

import {
  Modal,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableCustom,
  TableHead,
  TableHeadCell,
  classNames,
} from 'fr-shared/components';
import {
  ADDITIVE_PROCESS_NAMES,
  TRADITIONAL_PROCESS_NAMES,
} from 'fr-shared/lib/manufacturing_process';
import {
  ADDITIVE_PART_FILE_EXTENSIONS,
  TRADITIONAL_PART_FILE_EXTENSIONS,
} from 'fr-shared/utils/files';

import styles from './index.module.css';

interface SupportedFileTypesModalProps {
  toggleDisplayModal?: () => void;
  toggle?: () => void;
  action: ReactElement;
  additiveFileExtensions?: string[];
  traditionalFileExtensions?: string[];
}

interface RowsProps {
  rowList: any[];
  processNames: string[];
  processFiles: string[];
  postSection?: boolean;
}

const Rows = ({ rowList, processNames, postSection, processFiles }: RowsProps) => {
  return (
    <>
      {rowList.map((_val, index) => {
        return (
          <TableBodyRow
            key={index}
            className={classNames([
              styles.TableFlushParallelRows,
              styles.TableRowsFlushLeft,
              styles.TableRow,
              index === 0 && styles.TableSectionDivider,
              postSection && index === rowList.length - 1 && styles.TablePostSectionDivider,
            ])}
          >
            <TableBodyCell>{processNames[index] || ''}</TableBodyCell>
            <TableBodyCell align="left" width="3rem">
              {processFiles[index] || ''}
            </TableBodyCell>
          </TableBodyRow>
        );
      })}
    </>
  );
};

interface SupportedFileTypesTableProps {
  className?: string;
  additiveFileExtensions?: string[];
  traditionalFileExtensions?: string[];
}

export const SupportedFileTypesTable = ({
  additiveFileExtensions = ADDITIVE_PART_FILE_EXTENSIONS,
  className = '',
  traditionalFileExtensions = TRADITIONAL_PART_FILE_EXTENSIONS,
}: SupportedFileTypesTableProps) => {
  const additiveFileLength = additiveFileExtensions.length;
  const additiveNamesLength = ADDITIVE_PROCESS_NAMES.length;
  const traditionalFileLength = traditionalFileExtensions.length;
  const traditionalNamesLength = TRADITIONAL_PROCESS_NAMES.length;
  const additiveRowsList =
    additiveFileLength > additiveNamesLength ? additiveFileExtensions : ADDITIVE_PROCESS_NAMES;
  const traditionalRowsList =
    traditionalFileLength > traditionalNamesLength
      ? traditionalFileExtensions
      : TRADITIONAL_PROCESS_NAMES;
  return (
    <TableCustom className={classNames([styles.SupportFileTable, className])}>
      <TableHead className={styles.PlainHeader}>
        <TableHeadCell value="Process" />
        <TableHeadCell align="left" width="3rem" value="File type" />
      </TableHead>

      <TableBody>
        <Rows
          rowList={additiveRowsList}
          processNames={ADDITIVE_PROCESS_NAMES}
          processFiles={additiveFileExtensions}
          postSection={true}
        />
        <Rows
          rowList={traditionalRowsList}
          processNames={TRADITIONAL_PROCESS_NAMES}
          processFiles={traditionalFileExtensions}
        />
      </TableBody>
    </TableCustom>
  );
};

type ListFileExtensionsProps = {
  extensions: string[];
  ulClassName: string;
};

const List = ({ extensions, ulClassName }: ListFileExtensionsProps) => {
  return (
    <ul className={ulClassName}>
      {extensions.map((val, index) => {
        return (
          <li
            key={index}
            className={classNames([
              styles['supported-list-el'],
              "text-coolGray-100 ml-[16px] text-base first:list-none first:ml-0 inline-block before:content-[''] before:list-item before:absolute before:ml-[-2px] before:transform before:scale-50",
            ])}
          >
            {val}
          </li>
        );
      })}
    </ul>
  );
};

export const SupportedFileTypesModal = ({
  action,
  additiveFileExtensions = ADDITIVE_PART_FILE_EXTENSIONS,
  traditionalFileExtensions = TRADITIONAL_PART_FILE_EXTENSIONS,
}: SupportedFileTypesModalProps) => {
  return (
    <Modal className={styles.Root} action={action}>
      {({ toggle }: { toggle: () => void }) => (
        <>
          <Modal.Header className="mr-[-8px] mt-[-32px] pr-0" onClose={toggle} />
          <Modal.Body className="p-0">
            <div className={styles.ModalContentContainer}>
              <h2 className="text-lg border-1 border-l-0 border-r-0 border-t-0 border-coolGray-700 border-solid pb-[5px] mb-[10px]">
                Supported file types
              </h2>
              <section className="support-file-types-additive border-1 border-l-0 border-r-0 border-t-0 border-coolGray-700 border-solid pb-[10px] mb-[10px]">
                <div>
                  <h3 className="text-base text-coolGray-300 mb-0">For...</h3>
                  <List
                    extensions={ADDITIVE_PROCESS_NAMES}
                    ulClassName="supported-additive-list pl-0"
                  />
                </div>
                <div>
                  <h3 className="text-base text-coolGray-300 mb-0">We support...</h3>
                  <List extensions={additiveFileExtensions} ulClassName="pl-0" />
                </div>
              </section>
              <section className="support-file-types-traditional">
                <div>
                  <h3 className="text-base text-coolGray-300 mb-0">For...</h3>
                  <List extensions={TRADITIONAL_PROCESS_NAMES} ulClassName="pl-0" />
                </div>
                <div>
                  <h3 className="text-base text-coolGray-300 mb-0">We support...</h3>
                  <List extensions={traditionalFileExtensions} ulClassName="pl-0 mb-0" />
                </div>
              </section>
            </div>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};

export default SupportedFileTypesModal;
