import { Order, OrderLineItem } from 'fr-shared/lib/orders';

export const transformOrderFromLoad = (data: Order) => {
  return {
    ...data,
    /*
     * This converts this boolean field into a string representation because it is shown in the
     * UI as a pair of radio buttons.
     *
     * See assets/src/shared/components/common/LineItemDutiesTaxesTariffs.tsx
     *
     * HTML forms don't handle setting booleans well, even with Formik. As of this writing, the
     * `transformOrderFromLoad` function doesn't need to change this value back from a string
     * because the backend accepts and coerces boolean-like strings appropriately.
     */
    line_items: data.line_items.map((li: OrderLineItem): OrderLineItem => {
      return {
        ...li,
        is_prototype:
          typeof li.is_prototype === 'boolean' ? String(li.is_prototype) : li.is_prototype,
      };
    }),
  };
};
