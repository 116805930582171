import { FieldArray } from 'formik';
import React, { ReactElement } from 'react';

import { Button, Modal } from 'fr-shared/components';

interface ModalProps {
  title?: string;
  toggle?: () => void;
  action: ReactElement;
  onActionHandler?: () => void;
}

type DeletePSLIModalProps = ModalProps & {
  oliIndex: number;
  psliIndex: number;
  psliQuantity: number;
};

const DeletePSLIModal: React.FC<DeletePSLIModalProps> = ({
  action,
  onActionHandler,
  oliIndex,
  psliIndex,
  psliQuantity,
}) => {
  const psliPrefix = `line_items[${oliIndex}].planned_shipment_line_items`;
  return (
    <Modal dataTestId="psli-delete-modal" action={action} onActionHandler={onActionHandler}>
      {({ toggle }: ModalProps) => (
        <>
          <Modal.Header title="Delete Planned Shipment" onClose={toggle} textUppercase={false} />
          <Modal.Body className="px-3">
            Do you want to delete this Planned Shipment ({psliQuantity} Units Planned)?
          </Modal.Body>
          <Modal.Footer>
            <FieldArray name={psliPrefix}>
              {({ remove }) => (
                <>
                  <Button
                    color="light"
                    className="border uppercase px-5"
                    dataTestId="psli-delete-modal-yes-button"
                    onClick={() => {
                      remove(psliIndex);
                      toggle();
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    dataTestId="psli-delete-modal-no-button"
                    color="primary"
                    className="ml-2 uppercase px-5"
                    onClick={toggle}
                  >
                    No
                  </Button>
                </>
              )}
            </FieldArray>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default DeletePSLIModal;
