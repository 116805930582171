import PropTypes from 'prop-types';
import React from 'react';

import { classNames } from 'fr-shared/components';

const PartFileUnits = ({ className, defaultChecked, label, setUnits }) => (
  <div className={classNames(['flex align-items-center font-size-md', className])}>
    <span className="mr-3">{label || 'These parts are modeled in:'} </span>
    <div className="flex align-items-center">
      <div className="custom-radio custom-control mb-0 mr-2">
        <input
          name="units"
          type="radio"
          id="units_mm"
          value="mm"
          defaultChecked={defaultChecked}
          onChange={e => setUnits(e.target.value)}
          className="custom-control-input"
          data-testid="part-file-units--button"
        />
        <label htmlFor="units_mm" className="custom-control-label font-weight-normal">
          mm
        </label>
      </div>
      <div className="custom-control custom-radio mb-0">
        <input
          name="units"
          type="radio"
          id="units_in"
          value="in"
          onChange={e => setUnits(e.target.value)}
          className="custom-control-input"
        />
        <label htmlFor="units_in" className="custom-control-label font-weight-normal">
          inches
        </label>
      </div>
    </div>
  </div>
);

PartFileUnits.defaultProps = {
  defaultChecked: true,
};

PartFileUnits.propTypes = {
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  setUnits: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default PartFileUnits;
