import React from 'react';

import { FormNumber } from 'fr-shared/components';

const IrradianceFormField = props => {
  return (
    <FormNumber
      name="irradiance"
      label="Irradiance"
      min={-10}
      max={10}
      step={0.5}
      append="%"
      {...props}
    />
  );
};

export default IrradianceFormField;
