import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, Helmet } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { isSubmitted } from 'fr-shared/lib/costing_requests';

import CostingForm from './components/CostingForm';
import { formatErrorMessages, formatFormikErrors } from './utils/formatErrorMessages';
import { transformNewRequest } from './utils/transforms';

const CostingNew = ({ history }) => {
  const { setAlert } = useContext(AlertContext);

  const handleSubmit = (values, formik) => {
    const costingRequest = transformNewRequest(values);
    formik.setSubmitting(true);

    api
      .post('/costing_requests', { costing_request: costingRequest })
      .then(res => {
        history.push(`/admin/costing_requests/${res.data.id}`);
        setAlert({
          message: isSubmitted(res.data)
            ? `Submitted CR #${res.data.id}`
            : `Successfully Saved CR #${res.data.id} as a draft`,
          color: 'success',
        });
      })
      .catch(err => {
        if (err.response?.data) {
          const { errors, messages } = err.response.data;
          formik.setErrors({
            server: formatErrorMessages(errors, messages, values.line_items),
            ...formatFormikErrors(errors),
            'customer_contact.id': errors && errors.customer_contact_id,
            'customer.id': errors && errors.customer_id,
          });
        } else {
          formik.setErrors({
            server: ['An unexpected error occurred. Refresh and try again.'],
          });
        }
      })
      .finally(() => formik.setSubmitting(false));
  };
  return (
    <>
      <Helmet title="New Costing Request" />
      <Breadcrumb to="/admin/costing_requests">Costing</Breadcrumb>
      <Breadcrumb to="/admin/costing_requests/new">New Request</Breadcrumb>

      <CostingForm title="New Costing Request" onSubmit={handleSubmit} />
    </>
  );
};

CostingNew.propTypes = {
  history: PropTypes.object,
};

export default CostingNew;
