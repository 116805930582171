import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import { IconFont, SupportedFileTypesTable } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import {
  ADDITIVE_PART_FILE_EXTENSIONS_PORTAL,
  TRADITIONAL_PART_FILE_EXTENSIONS_PORTAL,
} from 'fr-shared/utils/files';

import { Button, FormToggleSwitch } from 'portal/components';

import styles from './StudioUploadModal.module.css';

const StudioUploadDropzone = ({ open, getInputProps }: any) => {
  const [showFileTypes, setShowFileTypes] = useState(false);
  const formik = useFormikContext<{ units_in_mm: boolean }>();
  const userAnalytics = useUserAnalyticsContext();

  const handleOpen = () => {
    userAnalytics.track('Onboarding Modal Studio - Part Upload Button Clicked');
    open();
  };

  const toggleFileTypes = () => {
    setShowFileTypes(prev => !prev);
  };

  const renderContent = () => {
    if (showFileTypes) {
      return (
        <div className="w-100">
          <h3>Supported file types</h3>
          <SupportedFileTypesTable
            className="mb-0"
            additiveFileExtensions={ADDITIVE_PART_FILE_EXTENSIONS_PORTAL}
            traditionalFileExtensions={TRADITIONAL_PART_FILE_EXTENSIONS_PORTAL}
          />
        </div>
      );
    } else {
      return (
        <div className={styles.InnerContainer}>
          <h3 className={styles.Description}>
            Welcome to your new account.
            <br />
            Let&apos;s analyze your first part.
          </h3>
          <p className="text-gray font-size-md">
            Drop a 3D file here or&nbsp;
            <button className="text-white bg-transparent border-none p-0" onClick={handleOpen}>
              browse
            </button>
          </p>
          <div className="flex flex-row align-items-center">
            <Button
              color="primary"
              dataTestId="part-upload-button"
              className="flex justify-content-center align-items-center btn-outline-secondary cursor-pointer p-0 rounded-circle mr-3"
              style={{ width: 32, height: 32 }}
              onClick={handleOpen}
            >
              <IconFont name="plus" className="text-white mr-0" />
            </Button>
            <div className="w-50">
              <FormToggleSwitch
                checked={formik?.values?.units_in_mm}
                defaultTrueLabel="mm"
                defaultFalseLabel="inches"
                name="units_in_mm"
                id="units_in_mm"
              />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-column flex-fill align-items-start justify-content-between">
      <input {...getInputProps()} id="file-dropzone" />
      {renderContent()}
      <button className={styles.SupportedFileTypes} onClick={toggleFileTypes}>
        <span className="text-muted font-size-sm mr-1">Supported file types</span>
        <span className={styles.IconOutline}>
          <IconFont
            name={showFileTypes ? 'minus' : 'plus'}
            className="text-muted text-white mr-0 font-size-sm"
          />
        </span>
      </button>
    </div>
  );
};

export default StudioUploadDropzone;
