import React, { useState } from 'react';

import { IconFont } from 'fr-shared/components';

import { Button, Modal, classNames } from 'portal/components';

import styles from './Modal.module.css';

interface OnboardingModalProps {
  content: React.ReactNode;
  contentClassname?: string;
  currentImageSrc: string;
  footer?: (closeModal: () => void) => React.ReactNode;
  onClose: (closedByButton: boolean) => void;
}

const OnboardingModal = ({
  content,
  contentClassname = '',
  currentImageSrc,
  footer,
  onClose,
}: OnboardingModalProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleCloseModal = () => {
    setIsOpen(false);
    onClose(true);
  };

  return (
    <Modal
      className={`${styles.Root} ${styles.RootExtraSpecificity}`}
      dataTestId="onboarding-modal"
      isOpen={isOpen}
      onCloseHandler={() => onClose(false)} // modal is already closing so just defer to callback
    >
      <Button
        className={`${styles.CloseModalButton} modal-close`}
        dataTestId="onboarding-modal-close-button"
        onClick={handleCloseModal}
      >
        <IconFont name="close" />
      </Button>

      <Modal.Body className={styles.ModalBody}>
        <div className={styles.TopBody}>
          <div className={classNames([styles.ModalContent, contentClassname])}>{content}</div>
          <div className={classNames([styles.ModalContent, styles.Image])}>
            <img src={currentImageSrc} alt="" />
          </div>
        </div>
      </Modal.Body>

      {footer && (
        <Modal.Footer className={styles.ModalFooter}>
          {footer(() => setIsOpen(false))}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default OnboardingModal;
