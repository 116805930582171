import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { IconFont } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { usePortalBulkUploadFeatureFlag, useTableFilters } from 'fr-shared/hooks';
import { PART_SORT_VALUES } from 'fr-shared/lib/parts';
import {
  ADDITIVE_PART_FILE_EXTENSIONS_PORTAL,
  TRADITIONAL_PART_FILE_EXTENSIONS_PORTAL,
} from 'fr-shared/utils/files';

import { Button, Page, PageHeader, SupportedFiles } from 'portal/components';
import { PartsProvider } from 'portal/contexts/PartsContext';

import PartsSection from './components/PartsSection';
import FileRedirectLink from './components/portalUploadContainer/FileRedirectLink';
import PortalUploadContainer from './components/portalUploadContainer/PortalUploadContainer';

const fetchData = filters => {
  return api.get('/customer_portal/parts', { params: filters });
};

const PartsContainer = () => {
  const userAnalytics = useUserAnalyticsContext();

  const { id: partId } = useParams();

  const isPortalBulkUploadOn = usePortalBulkUploadFeatureFlag();

  const initialFilters = {
    page: 1,
    page_size: isPortalBulkUploadOn ? 19 : 20,
    sort_key: 'updated_at',
    sort_desc: true,
  };

  const handleAddNewPart = () => {
    userAnalytics.track(`Add to Part to Quote CTA - Parts Page`, {
      valid: true,
    });
  };

  const handleSearch = value => {
    // TODO: useTableFilters expects a synthetic event, but we're controlling <Input />
    handleTableSearch({ target: { name: 'name', value } });
  };

  const handleSetPage = page => {
    setFilters({ ...filters, page });
  };

  const handleSort = sortValue => {
    const sortKeys = PART_SORT_VALUES;
    setFilters({
      ...filters,
      sort_key: sortKeys[sortValue],
      sort_desc: !filters.sort_desc,
    });
  };

  const {
    data,
    loading,
    handleSearch: handleTableSearch,
    pages,
    filters,
    refreshData,
    setFilters,
    totalItems,
  } = useTableFilters(null, initialFilters, fetchData);

  const { name: partName, sort_key: sortBy, sort_desc: isSortDesc } = filters;
  const parts = data ?? [];
  const totalParts = partName ? parts.length : totalItems;

  return (
    <PartsProvider
      currentSearchParam={partName}
      isSortDesc={isSortDesc}
      currentSortParam={sortBy}
      parts={parts}
    >
      <Page>
        {isPortalBulkUploadOn ? (
          <>
            <PortalUploadContainer
              filters={filters}
              handleSetPage={handleSetPage}
              loading={loading}
              pages={pages}
              partId={partId}
              parts={parts}
              onSearch={handleSearch}
              onSort={handleSort}
              fetchAllParts={() => refreshData(filters, fetchData)}
            >
              <PageHeader
                title="Parts"
                actions={<FileRedirectLink />}
                subtitle="Add new parts or select saved parts."
              />
            </PortalUploadContainer>
          </>
        ) : (
          <>
            <PageHeader
              title="Parts"
              actions={
                <Button dataTestId="parts-add-new-part" to="/add-part" onClick={handleAddNewPart}>
                  <IconFont name="plus" />
                  Add new part
                </Button>
              }
            >
              <SupportedFiles
                additiveFileExtensions={ADDITIVE_PART_FILE_EXTENSIONS_PORTAL}
                traditionalFileExtensions={TRADITIONAL_PART_FILE_EXTENSIONS_PORTAL}
              />
            </PageHeader>

            <PartsSection
              handleSetPage={handleSetPage}
              filters={filters}
              partId={partId}
              parts={parts}
              pages={pages}
              loading={loading}
              onSearch={handleSearch}
              onSort={handleSort}
              totalParts={totalParts}
            />
          </>
        )}
      </Page>
    </PartsProvider>
  );
};

PartsContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default PartsContainer;
