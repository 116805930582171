import { IconFont } from '@fast-radius/shared-ui';
import React, { useEffect } from 'react';

import { ButtonLink } from 'fr-shared/components';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { useToggle } from 'fr-shared/hooks';

import { Button, SubscriptionModal, classNames } from 'portal/components';

import SubscriptionDescriptionModal from './SubscriptionDescriptionModal';

interface SubscriptionUpgradeCardProps {
  analyticsContext: string;
  className?: string;
  modalDescription?: boolean;
  openModalOnMount?: boolean;
}

export const SubscriptionUpgradeCard = ({
  analyticsContext,
  className,
  modalDescription = false,
  openModalOnMount = false,
}: SubscriptionUpgradeCardProps) => {
  const [hideUpgradeDescription, toggleHideUpgradeDescription] = useToggle(false);

  const userAnalytics = useUserAnalyticsContext();

  useEffect(() => {
    if (openModalOnMount) {
      userAnalytics.track('Subscription - View Modal', { context: 'FR Website' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalOnMount]);

  return (
    <div
      className={classNames([
        'bg-dark border border-coolGray-700 rounded-[12px] border-solid p-4 md:p-3 lg:p-4 inset-x-1.5',
        className,
      ])}
      data-testid="subscription-upgrade-card"
    >
      <h5 className="font-medium">Upgrade to Fast Radius Pro to unlock more tools.</h5>
      <p className="mt-1 text-sm">It takes a few seconds. Early Access is free.</p>
      <SubscriptionModal
        action={
          <Button color="tertiary" className="mt-3 lg:w-[168px]">
            <IconFont className="text-xl mr-0.5" name="pro" />
            Upgrade to Pro
          </Button>
        }
        isOpen={openModalOnMount}
        onActionHandler={() =>
          userAnalytics.track('Subscription - View Modal', { context: analyticsContext })
        }
      />
      {modalDescription ? (
        <SubscriptionDescriptionModal
          action={
            <ButtonLink
              dataTestId="subscription-description-modal"
              className="flex text-coolGray-100 mt-3 mb-1.5 no-underline"
            >
              <p className="mb-0">What&#39;s Fast Radius Pro?</p>
              <IconFont className="ml-1" name="arrow-right" />
            </ButtonLink>
          }
        />
      ) : (
        <ButtonLink
          className="flex text-coolGray-100 mt-3 mb-1.5 no-underline"
          onClick={toggleHideUpgradeDescription}
        >
          <p className="mb-0">What&#39;s Fast Radius Pro?</p>
          <IconFont
            className="ml-1"
            name={!hideUpgradeDescription ? 'chevron-down' : 'chevron-up'}
          />
        </ButtonLink>
      )}
      {hideUpgradeDescription && (
        <p className="text-coolGray-300 mt-1">
          Fast Radius Pro gives you an expanding set of digital manufacturing tools. Sign up for
          free Early Access and be the first to get premium DFM insights, compare more materials
          and processes, share every detail with your team and more.
        </p>
      )}
    </div>
  );
};

export default SubscriptionUpgradeCard;
