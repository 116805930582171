import React, { memo, useContext } from 'react';
import { Quote } from 'src/views/sales_portal/lib/quotes';

import { TableBody, TableCustom, TableHead, TableHeadCell } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';

import ListViewRow from './ListViewRow';

interface ListViewProps {
  firstElement?: Quote;
  quotes: Array<Quote>;
}

const ListView = ({ quotes }: ListViewProps) => {
  const { user } = useContext(UserContext);

  return (
    <TableCustom>
      <TableHead>
        <TableHeadCell value="Customer company" />
        <TableHeadCell value="Quote #" />
        <TableHeadCell value="Source" />
        <TableHeadCell value="Total" />
        <TableHeadCell value="Requested" />
        <TableHeadCell value="Status" align="left" />
      </TableHead>

      <TableBody>
        {quotes &&
          quotes.length > 0 &&
          quotes.map((quote: any, index) => (
            <ListViewRow
              key={quote.public_id || quote.id}
              quote={quote}
              owner={index === 0 && quote.state === 'Draft' && quote.user_id === user.id}
            />
          ))}
      </TableBody>
    </TableCustom>
  );
};

export default memo(ListView);
