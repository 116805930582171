import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useUserAnalyticsContext } from 'fr-shared/context';

import { Badge, Card, Money } from 'portal/components';
import { date } from 'portal/lib/date';
import { getQuoteStateLabel } from 'portal/lib/quotes';

import PartsSlider from '../../PartsSlider/PartsSlider';

export const QuoteCard = ({ quote }) => {
  const userAnalytics = useUserAnalyticsContext();
  const history = useHistory();

  const handleCardClicked = () => {
    userAnalytics.track(`View Quote`, {
      valid: true,
    });
    if (quote.is_cart) {
      history.push('/quotes/draft');
    } else if (quote.is_costing_request) {
      history.push(`/quotes/requested/${quote.id}`);
    } else {
      history.push(`/quotes/${quote.id}`);
    }
  };

  const quotePrice = (!quote?.is_manual && quote?.total_price?.amount) || null;

  const images = get(quote, 'line_items', [])
    .map(lineItem => get(lineItem, 'screenshot', null))
    .filter(screenshot => screenshot);

  return (
    <Card border={quote.state === 'Draft'} dataTestId="quote-card" onClick={handleCardClicked}>
      <div className="p-3">
        <Badge className="mb-3" color="outline">
          {getQuoteStateLabel(quote.state)}
        </Badge>
        <div className="flex font-size-md">
          <div className="mr-5">
            <div className="font-size-sm">Quote #</div>
            <div className="text-light" data-testid="quote-card-quote-number">
              {get(quote, 'public_id', '——')}
            </div>
          </div>
          <div>
            <div className="font-size-sm">Total</div>
            <Money className="font-size-md ml-auto text-light" amount={quotePrice} />
          </div>
        </div>
      </div>
      <PartsSlider images={images} />
      <div className="font-size-sm my-2 mx-3">
        {quote.is_cart ? (
          <>
            Last edited: <strong>{date(quote.updated_at)}</strong>
          </>
        ) : (
          <QuoteCardDate quote={quote} />
        )}
      </div>
    </Card>
  );
};

const QuoteCardDate = ({ quote }) => {
  switch (quote.state) {
    case 'Submitted':
      return (
        <>
          Date submitted: <strong>{date(quote.inserted_at)}</strong>
        </>
      );
    case 'Pending':
      return (
        <>
          Date requested: <strong>{date(quote.inserted_at)}</strong>
        </>
      );
    case 'Requested':
      return (
        <>
          Date requested: <strong>{date(quote.inserted_at)}</strong>
        </>
      );
    case 'Ordered':
      return (
        <>
          Date ordered: <strong>{date(quote.completed_at)}</strong>
        </>
      );
    case 'Canceled':
      return (
        <>
          Date canceled: <strong>{date(quote.closed_at)}</strong>
        </>
      );
    default:
      return null;
  }
};

QuoteCardDate.propTypes = {
  quote: PropTypes.object,
};

QuoteCard.propTypes = {
  quote: PropTypes.shape({
    id: PropTypes.number,
    inserted_at: PropTypes.string,
    is_cart: PropTypes.bool,
    is_manual: PropTypes.bool,
    is_costing_request: PropTypes.bool,
    state: PropTypes.string,
    total_price: PropTypes.object,
    updated_at: PropTypes.string,
  }),
};

export default QuoteCard;
