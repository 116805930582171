import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { PartImage } from 'portal/components';

import styles from './PartNameCell.module.css';

const TablePartNameCell = ({ id, name, screenshotSrc }) => {
  return (
    <Link
      className="flex align-items-center"
      data-testid="part-name-cell-link"
      to={`/parts/${id}${window.location.search}`}
    >
      <PartImage backdrop={false} size="xxs" src={screenshotSrc} />
      <span className={`ml-1 ${styles.Title}`}>{name}</span>
    </Link>
  );
};

TablePartNameCell.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  screenshotSrc: PropTypes.string,
};

export default TablePartNameCell;
