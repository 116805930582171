import { Logo } from '@fast-radius/shared-ui';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { FormCountries, FormField, FormStates } from 'fr-shared/components';
import { transformBraintreeErrorsToFormikErrors } from 'fr-shared/lib/braintree';
import { COUNTRIES } from 'fr-shared/lib/countries';

import { Button } from 'portal/components';

import BraintreeHostedFields from './BraintreeHostedFields';

const CreditCardInformationForm = ({ authToken, onSubmit }) => {
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState();
  const formik = useFormikContext();

  const setNonceAndSubmit = () => {
    hostedFieldsInstance
      .tokenize()
      .then(token => {
        formik.setValues({
          ...formik.values,
          payment_method_nonce: token.nonce,
          payment_details: token.details,
        });
        onSubmit();
      })
      .catch(err => {
        if (err.details) {
          formik.setFieldError(
            'hosted_fields',
            transformBraintreeErrorsToFormikErrors(err.details.invalidFieldKeys)
          );
        }
      });
  };

  const requireState =
    formik.values?.billing_address?.country === COUNTRIES.UNITED_STATES ? true : false;

  return (
    <>
      <Logo className="mb-3" />
      <form>
        <h1 className="mb-3">Payment Information</h1>
        <div className="row">
          <div className="col-md-6">
            <h3 className="mb-3">Credit Card</h3>
            <h4 className="mb-3">
              Required <span className="text-danger">*</span>
            </h4>
            <div>
              <FormField
                stacked={true}
                label="Name on card"
                name="billing_address.name_line_1"
                required={true}
              />
              <BraintreeHostedFields
                authToken={authToken}
                onHostedFieldInstanceChange={setHostedFieldsInstance}
              />
            </div>

            <hr className="my-4" />

            <h3 className="mb-3">Billing Address</h3>

            <FormField
              stacked={true}
              label="Billing email"
              name="billing_address.email"
              required={true}
            />
            <FormCountries
              stacked={true}
              label="Country"
              required={true}
              name="billing_address.country"
            />
            <FormField
              stacked={true}
              label="Billing address 1"
              name="billing_address.street_line_1"
              required={true}
            />
            <FormField
              stacked={true}
              label="Billing address 2"
              name="billing_address.street_line_2"
            />
            <div className="row">
              <div className="col">
                <FormField
                  stacked={true}
                  label="City"
                  name="billing_address.city"
                  required={true}
                />
              </div>
              <div className="col">
                <FormStates
                  stacked={true}
                  label="State"
                  name="billing_address.state"
                  required={requireState}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <FormField
                  stacked={true}
                  label="Postal Code"
                  name="billing_address.postal_code"
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>
        <Button className="mt-2" onClick={setNonceAndSubmit}>
          Submit
        </Button>
        <p className="mt-2">To pay with a Purchase Order, contact your sales representative.</p>
      </form>
    </>
  );
};

CreditCardInformationForm.propTypes = {
  authToken: PropTypes.string,
  onSubmit: PropTypes.func,
  setFieldError: PropTypes.func,
};

export default CreditCardInformationForm;
