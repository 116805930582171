import { IconFont, LogoPro } from '@fast-radius/shared-ui';
import React, { useContext, useState } from 'react';

import { api } from 'fr-shared/api';
import { Modal, classNames } from 'fr-shared/components';
import { UserContext, useUserAnalyticsContext } from 'fr-shared/context';
import { MARKETING_URLS } from 'fr-shared/lib/marketing_urls';

import { Button, Link, LoadingCircle } from 'portal/components';

import styles from './SubscriptionModal.module.css';

interface SubscriptionModalProps {
  action: JSX.Element;
  isOpen?: boolean;
  onActionHandler: () => void;
}

export const SubscriptionModal = ({
  action,
  isOpen,
  onActionHandler,
}: SubscriptionModalProps) => {
  const { refreshUser } = useContext(UserContext) as any;
  const [upgradeLoading, setUpgradeLoading] = useState(false);
  const [upgradeError, setUpgradeError] = useState(false);
  const [upgradeSuccess, setUpgradeSuccess] = useState(false);

  const userAnalytics = useUserAnalyticsContext();

  const handleUpgrade = () => {
    userAnalytics.track('Subscription - Clicked to Subscribe');
    setUpgradeLoading(true);

    api
      .post('/customer_portal/subscribe')
      .then(() => {
        setUpgradeError(false);

        setTimeout(() => {
          setUpgradeSuccess(true);
          setUpgradeLoading(false);
          refreshUser();
        }, 2000);
      })
      .catch(() => {
        setUpgradeSuccess(false);
        setUpgradeError(true);
        setUpgradeLoading(false);
      });
  };

  return (
    <Modal
      className={classNames([styles.Root, styles.SubscriptionModalRoot])}
      action={action}
      backdrop={true}
      isOpen={isOpen}
      onActionHandler={onActionHandler}
    >
      {({ toggle }: { toggle: () => void }) => (
        <Modal.Body className="!pl-0">
          <div className="flex">
            {/* Left Side */}
            <div
              className={classNames([styles.ModalContent, styles.Image, 'text-coolGray-200 p-6'])}
            >
              <h3 className="text-coolGray-100 mb-0">
                Join the next manufacturing revolution with Pro
              </h3>
              <div className="pt-2">
                Upgrade to <strong>Pro Early Access</strong> to unlock an expanding set of digital
                manufacturing tools. Get premium DFM insights, compare materials and technologies,
                and much more. It’s free to get started.
              </div>
              <div className="pt-2">
                <Link
                  className="text-coolGray-100"
                  to={{ pathname: MARKETING_URLS.Pro }}
                  openNewWindow
                >
                  More about Pro Early Access
                </Link>
              </div>
            </div>

            {/* Right Side */}
            <div className={classNames([styles.ModalContent, 'pt-6 px-6'])}>
              <div className="flex pb-1 border-solid border-coolGray-600 border-b border-t-0 border-x-0 items-start">
                <IconFont
                  className={classNames([styles.LightningIcon, 'text-tertiary-500 text-3xl'])}
                  name="pro"
                />
                <h4 className="mb-0 pr-1">Fast Radius Pro</h4>
                <LogoPro />
              </div>
              <strong>
                <div className="text-coolGray-100 text-lg pt-1 pb-3.5">$0</div>
              </strong>
              <Button
                dataTestId="modal-close"
                onClick={() => toggle()}
                className={classNames([styles.ModalClose, 'modal-close'])}
                size="md"
              >
                <IconFont name="close" />
              </Button>

              <div className="flex">
                <div className="w-1/2 text-coolGray-300 pr-2">Instant access to:</div>
                <div className="flex- flex-col w-1/2">
                  <Feature>Premium DFM checks</Feature>
                  <Feature>Comparison Tool</Feature>
                  <Feature>Shareable Insight Reports</Feature>
                </div>
              </div>

              <div className="flex pt-4">
                <div className="w-1/2 text-coolGray-300 pr-2">You’ll also get:</div>
                <div className="flex flex-col w-1/2">
                  <Feature>50% off Pro*</Feature>
                  <Feature>Early access to new Pro features</Feature>
                </div>
              </div>

              <div className="justify-end flex items-baseline mt-auto">
                {upgradeError && (
                  <div className="pr-2 text-xs text-error-300">
                    <IconFont className="mr-1 text-sm" name="error-triangle" />
                    Upgrade failed
                  </div>
                )}

                {upgradeSuccess && (
                  <div className="pr-2 text-xs">
                    <IconFont className="mr-1 text-sm text-tertiary-500" name="check" />
                    Upgraded
                  </div>
                )}
                {upgradeSuccess ? (
                  <Button
                    className="w-[168px] h-[31px] text-xs"
                    color="tertiary"
                    outline
                    onClick={() => toggle()}
                  >
                    Close
                  </Button>
                ) : (
                  <Button
                    dataTestId="upgrade-button"
                    onClick={handleUpgrade}
                    color="tertiary"
                    className={classNames([
                      'w-[168px] h-[31px] text-xs',
                      upgradeLoading && 'bg-tertiary-300 border-tertiary-300 pointer-events-none',
                    ])}
                  >
                    {upgradeLoading ? (
                      <LoadingCircle
                        size="sm"
                        className="w-2 h-2 mr-1"
                        centerCircleClassName="bg-tertiary-300"
                      />
                    ) : (
                      <IconFont className="text-xl mr-1" name="pro" />
                    )}
                    {upgradeLoading ? 'Upgrading' : upgradeError ? 'Try again' : 'Upgrade'}
                  </Button>
                )}
              </div>

              <div className="flex text-[10px] py-3">
                *Applies for 2023 calendar year. See&nbsp;
                <Link
                  className="text-coolGray-300"
                  to={{ pathname: MARKETING_URLS.TermsAndConditions }}
                  openNewWindow
                >
                  Terms &amp; Conditions.
                </Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
};

const Feature = ({ children }: { children: string }) => (
  <div className="inline-flex">
    <IconFont name="check" className="mr-1 text-tertiary-300" />
    <span className="inline-block text-coolGray-100">{children}</span>
  </div>
);

export default SubscriptionModal;
