import { Form, Formik, useFormikContext } from 'formik';
import _, { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { AlertContext } from 'fr-shared/context';
import { uploadPartToS3AndFros } from 'fr-shared/lib/s3';
import { pluralize } from 'fr-shared/utils';
import { PART_FILE_EXTENSIONS, getExtension } from 'fr-shared/utils/files';

import { PartDropzone, SupportedFiles } from 'portal/components';

import AdminPartFileDropzone from './AdminPartFileDropzone';
import RecentUploads from './RecentUploads';

const DropzoneContainer = ({ organizationId }) => {
  const [loadingText, setLoadingText] = useState('');
  const { closeAlert, setAlert } = useContext(AlertContext);
  const formik = useFormikContext();
  const [partIds, setPartIds] = useState([]);

  const handleFileDrop = files => {
    closeAlert(); // in case an alert is showing due to a previously bad upload
    setLoadingText(pluralize('Uploading file', files.length > 1) + '...');

    const additionalPartParams = {
      organization_id: organizationId,
      source: 'Admin Portal Quote',
      units: formik.values.quote_units,
    };

    const { allowedFiles, disallowedFiles } = _.reduce(
      files,
      (acc, el) => {
        if (PART_FILE_EXTENSIONS.includes(getExtension(el.name))) {
          acc.allowedFiles.push(el);
        } else {
          acc.disallowedFiles.push(el);
        }
        return acc;
      },
      { allowedFiles: [], disallowedFiles: [] }
    );

    Promise.allSettled(
      allowedFiles.map(file =>
        uploadPartToS3AndFros(file, additionalPartParams)
          .then(response => ({ filename: file.name, partId: response.data.id }))
          .catch(_ => {
            throw { message: 'Failed to upload.', filename: file.name };
          })
      )
    )
      .then(promises => {
        if (isEmpty(promises)) {
          setLoadingText('');
          throw new Error(
            `Unfortunately, we could not upload your file since the format was not accepted. See "Supported file types" below to see what file formats your can upload.`
          );
        }
        return promises;
      })
      .then(promises => {
        let { rejected, fulfilled } = _.groupBy(promises, 'status');

        rejected = rejected ?? [];
        fulfilled = fulfilled ?? [];

        setPartIds(partIds.concat(fulfilled.map(f => f.value.partId)));

        const rejectedFilenames = rejected
          .map(r => r.reason.filename)
          .concat(disallowedFiles.map(f => f.name));

        if (isEmpty(rejectedFilenames)) {
          setAlert({
            color: 'success',
            message: 'Part(s) uploaded sucessfully.',
          });
        } else {
          setAlert({
            color: isEmpty(fulfilled) ? 'danger' : 'warning',
            message: `Some files failed to upload: ${rejectedFilenames.join(', ')}`,
          });
        }

        setLoadingText('');
      })
      .catch(err => {
        setLoadingText('');
        setAlert({
          color: 'danger',
          message: err.message,
        });
      });
  };

  return (
    <>
      <div className="mt-5">
        <PartDropzone
          card={false}
          className="border-dashed p-5 mt-5 bg-transparent"
          isLoading={!isEmpty(loadingText)}
          label="Drag and drop 3D design files here or"
          loadingSlot={
            <PartDropzone.Loading loadingText={loadingText} showLoadingWarning={true} />
          }
          onFileDrop={handleFileDrop}
          // accept={PART_FILE_EXTENSIONS}
          dropzoneComponent={<AdminPartFileDropzone />}
        />
        <SupportedFiles className="mt-2" />
      </div>
      <RecentUploads partIds={partIds} organizationId={organizationId} />
    </>
  );
};

DropzoneContainer.propTypes = {
  organizationId: PropTypes.string,
};

const OrganizationPartsNewContainer = ({
  match: {
    params: { org_id },
  },
}) => {
  return (
    <div className="container d-flex justify-content-center">
      <div className="w-75">
        <Formik initialValues={{ part_units: 'mm' }} onSubmit={() => {}}>
          <Form>
            <DropzoneContainer organizationId={org_id} />
          </Form>
        </Formik>
      </div>
    </div>
  );
};

OrganizationPartsNewContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      org_id: PropTypes.string,
    }),
  }),
};

export default OrganizationPartsNewContainer;
