import { debounce, isEmpty, omit } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import {
  Breadcrumb,
  Helmet,
  Link,
  Sidebar,
  SidebarContent,
  SidebarPage,
  SidebarPageContainer,
  Tabs,
} from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';

import ReviewActionBar from './components/ReviewActionBar';
import ReviewFilters from './components/ReviewFilters';
import ReviewTable from './components/ReviewTable';
import {
  convertDispositionTabToFilters,
  endTabDefinitions,
  tabDefinitions,
} from './components/utils/reviewTabs';

const QAReview = ({ match, history, location }) => {
  const { setAlert, closeAlert } = useContext(AlertContext);
  const [partFailures, setPartFailures] = useState([]);
  const [loadingParts, setLoadingParts] = useState(true);
  const [filters, setFilters] = useState({ page: 1, sort_key: 'inserted_at', sort_desc: true });
  const [totalPages, setTotalPages] = useState(1);
  const [totalPartFailures, setTotalPartFailures] = useState();

  const { disposition_tab } = match.params;
  useEffect(() => {
    setLoadingParts(true);
    api
      .get('/part_failures', {
        params: { ...filters, ...convertDispositionTabToFilters(disposition_tab) },
      })
      .then(res => {
        setTotalPages(parseInt(res.headers['total-pages']));
        setTotalPartFailures(parseInt(res.headers['total']));
        setPartFailures(prevPartFailures =>
          res.data.map(newPartFailure => {
            const selected = prevPartFailures.some(
              partFailure => newPartFailure.id === partFailure.id && partFailure.selected
            );
            return { ...newPartFailure, selected };
          })
        );
      })
      .finally(() => {
        setLoadingParts(false);
      });
  }, [filters, disposition_tab]);

  useEffect(() => {
    if (location.state && location.state.partFailureIds && location.state.workOrderId) {
      const numberOfParts = location.state.partFailureIds.length;
      setAlert({
        message: (
          <>
            {`${numberOfParts} item${numberOfParts > 1 ? 's have' : ' has'} been assigned to `}
            <Link onClick={closeAlert} to={`/admin/work_orders/${location.state.workOrderId}`}>
              {`Work Order #${location.state.workOrderId}`}
            </Link>
          </>
        ),
        color: 'success',
      });
    }
  }, [location, closeAlert, setAlert]);

  const onPageChange = pageNumber => {
    setFilters({ ...filters, page: pageNumber + 1 });
  };

  const onSortedChange = sortState => {
    const newFilters = {};
    if (sortState.length > 0) {
      newFilters.sort_key = sortState[0].id;
      newFilters.sort_desc = sortState[0].desc;
    }
    setFilters({ ...filters, ...newFilters });
  };

  const transformFilters = event => {
    const { name, value } = event.target;
    const newFilters = omit(filters, [name]);
    if (!isEmpty(value)) {
      newFilters[name] = value;
    }
    return newFilters;
  };

  const onFilterUpdate = event => {
    setFilters(transformFilters(event));
  };

  const debouncedSearch = debounce(event => {
    setFilters(transformFilters(event));
    if (isEmpty(event.target.value)) {
      history.replace('/admin/qc/review');
    } else {
      history.replace('/admin/qc/search');
    }
  }, 500);

  const onSearchUpdate = event => {
    event.persist();
    debouncedSearch(event);
  };

  const onPartSelectionChange = (selected, partFailureId) => {
    setPartFailures(
      partFailures.map(partFailure => ({
        ...partFailure,
        selected: partFailure.id === partFailureId ? selected : partFailure.selected,
      }))
    );
  };

  const selectedPartFailures = partFailures.filter(pf => pf.selected);
  const updatePartFailures = updateParams => {
    api
      .put('/part_failures/bulk_update', {
        ids: selectedPartFailures.map(pf => pf.id),
        part_failure_update: updateParams,
      })
      .then(() => {
        setFilters({ ...filters, page: 1 });
        const numberOfParts = selectedPartFailures.length;
        setAlert({
          message: `${numberOfParts} item${
            numberOfParts > 1 ? 's have' : ' has'
          } been assigned a disposition`,
          color: 'success',
        });
      })
      .catch(() => {
        setAlert({
          message: 'We were unable to assign dispositions. Please refresh and try again',
          color: 'danger',
        });
      });
  };
  return (
    <>
      <Helmet title="Part Review" />
      <Breadcrumb to="/admin/qc/review"> Parts for Review </Breadcrumb>
      {disposition_tab && (
        <Breadcrumb to={`/admin/qc/${disposition_tab}`}>Dispositioned Parts</Breadcrumb>
      )}
      <SidebarPageContainer
        localStorageKey={Sidebar.LOCAL_STORAGE_KEYS.review}
        defaultOpen={false}
      >
        <SidebarPage>
          <div className="flex flex-grow-1">
            <div className="flex-grow-1">
              <div className="page-header mb-0 px-4">
                <div className="flex">
                  <h2>List of Non-Conformance Parts For Review</h2>
                  <div className="page-actions flex align-items-center">
                    <div className="mr-2">
                      <input
                        name="id"
                        type="text"
                        className="form-control mr-2"
                        placeholder="Search by Rejection Id"
                        onChange={onSearchUpdate}
                      />
                    </div>
                    <div className="mr-2">
                      <input
                        name="public_id"
                        type="text"
                        className="form-control mr-2"
                        placeholder="Search by Order #"
                        onChange={onSearchUpdate}
                      />
                    </div>
                    <div className="position-relative ">
                      <input
                        type="text"
                        name="customer_name"
                        className="form-control"
                        placeholder="Search by Customer"
                        onChange={onSearchUpdate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {disposition_tab && (
                <Tabs
                  className="bg-white px-4"
                  tabs={tabDefinitions(disposition_tab)}
                  endTabs={endTabDefinitions()}
                />
              )}
              <ReviewActionBar
                totalPartFailures={totalPartFailures}
                onUpdate={updatePartFailures}
                selectedPartFailures={selectedPartFailures}
                dispositionTab={disposition_tab}
                history={history}
              />
              <div className="p-4">
                <ReviewTable
                  loading={loadingParts}
                  onPageChange={onPageChange}
                  onSortedChange={onSortedChange}
                  pages={totalPages}
                  data={partFailures}
                  onPartSelectionChange={onPartSelectionChange}
                  dispositionTab={disposition_tab}
                />
              </div>
            </div>
          </div>
        </SidebarPage>
        <Sidebar>
          <SidebarContent name="Filters" icon="filter">
            <ReviewFilters filters={filters} onFilterUpdate={onFilterUpdate} />
          </SidebarContent>
        </Sidebar>
      </SidebarPageContainer>
    </>
  );
};

QAReview.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default QAReview;
