import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { classNames } from 'fr-shared/components';

const Animated = props => {
  const { children, style, isVisible, animateOnMount, timeout, className } = props;
  const [animation, setAnimation] = useState({});

  const getNewAnimation = ({
    isVisible,
    animationIn,
    animationOut,
    animationInDuration,
    animationOutDuration,
    animationInDelay,
    animationOutDelay,
  }) =>
    isVisible
      ? {
          name: animationIn,
          duration: animationInDuration,
          delay: animationInDelay,
        }
      : {
          name: animationOut,
          duration: animationOutDuration,
          delay: animationOutDelay,
        };

  const classes = classNames(['animated', animation.name, className]);

  // animate based on isVisible
  useEffect(() => {
    setAnimation(getNewAnimation(props));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  // hide on timeout
  useEffect(() => {
    if (!timeout) return;
    const showTimer = setTimeout(() => {
      // trigger the `out` animations
      setAnimation(getNewAnimation({ ...props, isVisible: false }));
    }, timeout);
    return () => clearTimeout(showTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout]);

  // animate on mount
  useEffect(() => {
    animateOnMount && getNewAnimation(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classes}
      style={{
        animationDelay: `${animation.delay}ms`,
        animationDuration: `${animation.duration}ms`,
        pointerEvents: isVisible ? 'all' : 'none',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

Animated.propTypes = {
  /** trigger the animation when the component mounts */
  animateOnMount: PropTypes.bool,
  /** control the component's state manually, triggering animations */
  isVisible: PropTypes.bool,
  /** name of animation to use when animating in */
  animationIn: PropTypes.string,
  /** name of animation to use when animating out */
  animationOut: PropTypes.string,
  /** animation delay animating in */
  animationInDelay: PropTypes.number,
  /** animation delay animating out */
  animationOutDelay: PropTypes.number,
  /** animating in duration */
  animationInDuration: PropTypes.number,
  /** animating out duration */
  animationOutDuration: PropTypes.number,
  /** pass through class names */
  className: PropTypes.string,
  /** the content to animate */
  children: PropTypes.node,
  /** trigger out animation after ms */
  timeout: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** custom styling */
  style: PropTypes.object,
};

Animated.defaultProps = {
  animateOnMount: true,
  isVisible: true,
  animationInDelay: 0,
  animationOutDelay: 0,
  animationInDuration: 1000,
  animationOutDuration: 1000,
  className: '',
  style: {},
};

Animated.FADE = {
  animationIn: 'fadeIn',
  animationOut: 'fadeOut',
};

export default Animated;
