import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import { Button, Icon, classNames } from 'fr-shared/components';

import styles from './Modal.module.css';

const Modal = ({
  action,
  backdrop,
  centered = true,
  children,
  className,
  closeTimeoutMS,
  contentClassName,
  contentScrollable = false,
  dataTestId,
  fullscreen,
  isOpen,
  isRounded = true,
  onActionHandler,
  onCloseHandler,
  overlayClassName = 'Modal-Overlay',
  rightSidebar = false,
  scrollable = false,
  shouldCloseOnEsc = true,
  toggle,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const defaultToggle = () => setOpen(!open);
  const toggleModal = toggle || defaultToggle;

  return (
    <>
      {action &&
        React.cloneElement(action, {
          onClick: () => {
            if (onActionHandler) onActionHandler();
            toggleModal(true);
          },
        })}
      <ReactModal
        testId={dataTestId}
        closeTimeoutMS={closeTimeoutMS}
        className={classNames([
          'Modal-Dialog',
          centered && 'Modal-Dialog--centered',
          fullscreen && 'Modal-Dialog--fullscreen mw-100',
          rightSidebar && 'Modal-Dialog--right-sidebar',
          className,
        ])}
        bodyOpenClassName={scrollable ? null : undefined}
        isOpen={open}
        shouldCloseOnEsc={shouldCloseOnEsc}
        onRequestClose={() => {
          if (onCloseHandler) {
            onCloseHandler();
          }
          toggleModal(false);
        }}
        overlayClassName={overlayClassName}
        shouldCloseOnOverlayClick={backdrop !== 'static'}
      >
        <div
          className={classNames([
            'modal-content',
            !isRounded && 'rounded-0',
            fullscreen && 'min-h-screen h-fit',
            contentScrollable && 'overflow-auto',
            contentClassName,
          ])}
        >
          {children instanceof Function ? children({ toggle: toggleModal }) : children}
        </div>
      </ReactModal>
    </>
  );
};

Modal.propTypes = {
  /** Optional, clickable element that will trigger toggle on clic  */
  action: PropTypes.element,
  /** Optional, delay for close in case animations are running */
  closeTimeoutMS: PropTypes.number,
  /** Optional, function that will trigger after the clickable action element is clicked  */
  onActionHandler: PropTypes.func,
  /** Optional, function that will trigger after the modal close action is fired  */
  onCloseHandler: PropTypes.func,
  /** Controls if the backdrop is able to be clicked away */
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['static'])]),
  /** Controls if the page behind the modal is scrollable while the modal is open */
  scrollable: PropTypes.bool,
  /** Controls if the modal content is scrollable on overflow */
  contentScrollable: PropTypes.bool,
  /** Override the model-content styling */
  contentClassName: PropTypes.string,
  /** Vertically center the box */
  centered: PropTypes.bool,
  /** String used to target modal for testing */
  dataTestId: PropTypes.string,
  /** Sets the modal to fullscreen */
  fullscreen: PropTypes.bool,
  rightSidebar: PropTypes.bool,
  /** Bool controlling whether the modal is open or not */
  isOpen: PropTypes.bool,
  /** Function that is called when the modal open state should be toggled */
  toggle: PropTypes.func,
  /** Any custom styling classes */
  className: PropTypes.string,
  /** Overwrite the react-modal overlay className */
  overlayClassName: PropTypes.string,
  /** The actual content of the modal */
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  /** Determines whether the modal has sharp or round edges */
  isRounded: PropTypes.bool,
  /** Determines whether the modal should close using the escape key */
  shouldCloseOnEsc: PropTypes.bool,
};

const Header = ({
  className = '',
  title,
  dataTestId,
  onClose,
  children,
  showCloseButton = true,
  textUppercase = true,
  textCentered,
}) => {
  const closeButtonPadding = showCloseButton && (textCentered ? 'px-4' : 'pr-4');

  return (
    <div className={`${className} modal-header`} data-testid={dataTestId}>
      <div className="flex flex-1 flex-column">
        <div className={classNames(['relative', closeButtonPadding])}>
          <h3
            className={classNames([textUppercase && 'uppercase', textCentered && 'text-center'])}
          >
            {title}
          </h3>
          {showCloseButton && (
            <div className={styles.HeaderCloseButton}>
              <Button
                className="modal-close"
                dataTestId="modal-header-close-button"
                onClick={onClose}
              >
                <Icon name="times" type="fal" />
              </Button>
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  dataTestId: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  showCloseButton: PropTypes.bool,
  textUppercase: PropTypes.bool,
  textCentered: PropTypes.bool,
};

const Body = ({ children, className }) => (
  <div className={classNames(['modal-body', className])}>{children}</div>
);

Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

const Footer = ({ children, className, style }) => {
  return (
    <div className={classNames(['modal-footer', className])} style={style}>
      {children}
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
