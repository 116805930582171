import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import {
  CookieBanner,
  EnvironmentIndicator,
  GlobalErrorBoundary,
  Helmet,
  PrivateRoute,
} from 'fr-shared/components';
import { withAlert } from 'fr-shared/context';

import { AlertBanner, ScrollToTop } from 'portal/components';

import styles from './index.module.css';
import QuotesContainer from './pages/quotes';

const SalesPortal = () => {
  return (
    <div>
      <Helmet>
        <body className="theme-dark" />
      </Helmet>
      <EnvironmentIndicator className="pl-10" />
      <div className={styles.Wrapper}>
        <ScrollToTop />
        <CookieBanner />
        <main className={styles.Content}>
          <GlobalErrorBoundary>
            <div className={styles.PageWrapper}>
              <AlertBanner container="sm" />
              <Switch>
                <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
                <PrivateRoute
                  scrollToTop={true}
                  path="/admin/sales/quotes"
                  component={QuotesContainer}
                />
                <Redirect to="/admin/sales/quotes" />
              </Switch>
            </div>
          </GlobalErrorBoundary>
        </main>
      </div>
    </div>
  );
};

export default withAlert(SalesPortal);
