import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Breadcrumb, Helmet } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { useChannel } from 'fr-shared/hooks';
import { QUOTE_STATES } from 'fr-shared/lib/quotes';

import QuoteForm from './components/QuoteForm';
import {
  transformDuplicateQuote,
  transformErrors,
  transformQuoteFromLoad,
  transformQuoteToSave,
} from './utils/transforms';

const QuoteNew = ({ history, match }) => {
  const location = useLocation();
  const { setAlert } = useContext(AlertContext);
  const [channel] = useChannel('quote:new');
  const [initialValues, setInitialValues] = useState(location.state);
  const [isLoading, setIsLoading] = useState(false);
  const { duplicateId } = match.params;

  useEffect(() => {
    if (duplicateId) {
      api.get(`/quotes/${duplicateId}/duplicate`).then(res => {
        const transformedQuote = transformQuoteFromLoad(res.data);
        setInitialValues(transformDuplicateQuote(transformedQuote));
      });
    }
  }, [duplicateId]);

  const handleSync = (quoteId, formik) => {
    setIsLoading(true);
    api
      .put(`/quotes/${quoteId}/update_state`, { state: QUOTE_STATES.Submitted })
      .then(() => {
        setAlert({ message: `Quote #${quoteId} was submitted to Salesforce!` });
      })
      .catch(async err => {
        if (err?.response?.data) {
          const { errors: serverErrors, messages } = err.response.data;
          formik.setErrors({ ...formik.errors, server: messages, ...serverErrors });
        } else {
          setAlert({
            color: 'danger',
            message: 'An unexpected error occurred. Refresh and try again',
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmitAndSync = (values, formik) => {
    setIsLoading(true);
    api
      .post('/quotes', { quote: transformQuoteToSave(values), sync: true })
      .then(res => {
        if (typeof res.headers['fr-warning'] === 'string') {
          setAlert({
            color: 'warning',
            message: decodeURIComponent(res.headers['fr-warning']),
          });
        } else {
          setAlert({
            color: 'success',
            message: 'Your changes were submitted to Salesforce!',
          });
        }
        if (values.display_in_portal) {
          history.push(`/admin/quotes/${res.data.id}?show_email_modal=1`);
        } else {
          history.push(`/admin/quotes/${res.data.id}`);
        }
      })
      .catch(async err => {
        if (err?.response?.data) {
          const { errors: serverErrors, messages } = err.response.data;
          const transformedErrors = await transformErrors(serverErrors, values);
          formik.setErrors({ ...formik.errors, server: messages, ...transformedErrors });
        } else {
          setAlert({
            color: 'danger',
            message: 'An unexpected error occurred. Refresh and try again',
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = (values, formik) => {
    setIsLoading(true);
    api
      .post('/quotes', { quote: transformQuoteToSave(values) })
      .then(res => {
        history.push(`/admin/quotes/${res.data.id}`);
      })
      .catch(async err => {
        if (err?.response?.data?.errors) {
          const { errors, messages } = err.response.data;
          const transformedErrors = await transformErrors(errors, values);
          formik.setErrors({ ...formik.errors, server: messages, ...transformedErrors });
        } else {
          formik.setErrors({
            server: ['An unexpected error occurred. Refresh and try again.'],
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (duplicateId && !initialValues) return null;

  return (
    <>
      <Helmet title="New Quote" />
      <Breadcrumb to="/admin/quotes">Quotes</Breadcrumb>
      <Breadcrumb to="/admin/quotes/new">New Quote</Breadcrumb>

      <QuoteForm
        channel={channel}
        history={history}
        isLoading={isLoading}
        title="New Quote"
        onSync={handleSync}
        onSubmit={handleSubmit}
        onSubmitAndSync={handleSubmitAndSync}
        initialValues={initialValues}
      />
    </>
  );
};

QuoteNew.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  values: PropTypes.object,
};

export default QuoteNew;
