import { Toast } from '@fast-radius/shared-ui';
import { FormikContextType, useFormikContext } from 'formik';
import React, { useContext, useState } from 'react';

import { api } from 'fr-shared/api';
import { UserContext, useUserAnalyticsContext } from 'fr-shared/context';
import { useEffectDebounced } from 'fr-shared/hooks';

import { Button, FormField, FormPhoneNumber } from 'portal/components';

const AccountDetails = () => {
  const formik: FormikContextType<any> = useFormikContext();

  const [detailsSaved, setAreDetailsSaved] = useState(false);
  const [detailsFailed, setAreDetailsFailed] = useState(false);
  const [isInit, setIsInit] = useState(true);

  const { user, refreshUser } = useContext(UserContext) as {
    user: User;
    refreshUser: () => void;
  };
  const userAnalytics = useUserAnalyticsContext();

  const handleOnResetPwdClick = () =>
    userAnalytics.track('Reset Password - Account', { valid: true });

  const handleAccountInfoSave = (e: any) => {
    const newValue = e.target.value;
    const fieldName = e.target.name;
    formik.setFieldValue(fieldName, newValue);
    setAreDetailsSaved(false);
    setAreDetailsFailed(false);
    setIsInit(false);
  };

  //FormPhoneNumber component handles saving the formik field itself.
  //In this function we really just need to set some states to get the put to update account info to happen
  const handlePhoneNumberSave = () => {
    setAreDetailsSaved(false);
    setAreDetailsFailed(false);
    setIsInit(false);
  };

  useEffectDebounced(
    () => {
      if (!isInit) {
        api
          .put(`/customer_portal/account/${user.id}`, { user: formik.values })
          .then(() => {
            refreshUser(), setAreDetailsSaved(true);
          })
          .catch(err => {
            if (err.response?.data) {
              const { errors, messages } = err.response.data;
              setAreDetailsFailed(true);
              formik.setErrors({ server: messages, ...errors });
            } else {
              setAreDetailsFailed(true);
              formik.setErrors({
                server: ['An unexpected error occurred. Refresh and try again.'],
              });
            }
          });
      }
    },
    [
      formik.values.first_name,
      formik.values.last_name,
      formik.values.phone_number,
      formik.values.marketing_company_name,
    ],
    2000
  );

  return (
    <div data-testid="account-details-form" className="w-1/2">
      {detailsSaved && (
        <Toast.Success iconName="check" clearToast>
          Details updated succesfully
        </Toast.Success>
      )}
      {detailsFailed && (
        <Toast.Danger iconName="error-triangle" clearToast>
          Details failed to update
        </Toast.Danger>
      )}
      <FormField
        dataTestId="first-name-field"
        label="First Name"
        name="first_name"
        className="mb-3"
        readonly={false}
        onChange={handleAccountInfoSave}
        floatingLabel={true}
      />
      <FormField
        label="Last Name"
        name="last_name"
        className="mb-3"
        onChange={handleAccountInfoSave}
        readonly={false}
        floatingLabel={true}
      />
      <FormField
        label="Company name"
        name="marketing_company_name"
        onChange={handleAccountInfoSave}
        readonly={false}
        floatingLabel={true}
      />
      <FormField
        label="Email"
        className="mb-3"
        onChange={handleAccountInfoSave}
        name="email"
        readonly={true}
        floatingLabel={true}
      />
      <FormPhoneNumber
        label="Phone number"
        name="phone_number"
        onChange={handlePhoneNumberSave}
        readonly={false}
        floatingLabel={true}
      />
      <div className="flex justify-content-between text-right my-5">
        <Button
          outline
          dataTestId="reset-password-button"
          className="px-4 h-4 py-1"
          to="/forgot"
          onClick={handleOnResetPwdClick}
        >
          Reset Password
        </Button>
      </div>
    </div>
  );
};

export default AccountDetails;
