/* eslint-disable react/display-name,react/prop-types */
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Icon, Table } from 'fr-shared/components';

const columns = setOpportunity => [
  {
    Header: 'Opportunity Name',
    accessor: 'name',
  },
  {
    Header: 'Date Created',
    accessor: 'created_date',
    Cell: ({ value }) => (value ? moment(value).format('MM/DD/YYYY') : ''),
  },
  {
    Header: 'Public ID',
    accessor: 'public_id',
  },
  {
    Header: 'Est. Date Closed',
    accessor: 'close_date',
    Cell: ({ value }) => (value ? moment(value).format('MM/DD/YYYY') : ''),
  },
  {
    Header: 'Opportunity State',
    sortable: false,
    accessor: 'state',
  },
  {
    Header: '',
    accessor: '',
    Cell: ({ original }) => (
      <Button color="primary" size="sm" outline={true} onClick={() => setOpportunity(original)}>
        Use this opportunity
      </Button>
    ),
  },
];

const OpportunityTable = ({
  children,
  data,
  emptyText,
  loading,
  onFetchData,
  pages,
  showPagination = true,
  sort,
  setOpportunity,
}) => (
  <div className="p-3">
    {children}
    {data?.length > 0 ? (
      <Table
        manual={true}
        data={data}
        pages={pages}
        pageSize={data.length || 20}
        showPagination={showPagination}
        onFetchData={onFetchData}
        loading={loading}
        className="bg-white -striped -flex -left border"
        defaultSorted={sort}
        columns={[...columns(setOpportunity)]}
      />
    ) : loading ? (
      <h1 className="text-gray-400 text-center flex-column">Loading Requests...</h1>
    ) : (
      <>
        <div className="flex">
          <div className="flex-grow-1" />
          <div className="d-block border-3 border-gray-400 rounded-circle text-gray-400 px-2 my-4">
            <Icon
              className="font-size-2xl text-gray-400 px-3 py-3"
              type="far"
              name="clipboard-check"
            />
          </div>
          <div className="flex-grow-1" />
        </div>
        <h1 className="text-gray-400 text-center">{emptyText}</h1>
      </>
    )}
  </div>
);

OpportunityTable.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      line_items: PropTypes.arrayOf(
        PropTypes.shape({
          state: PropTypes.string,
          manufacturing_process: PropTypes.shape({ name: PropTypes.string }),
          part: PropTypes.shape({
            name: PropTypes.string,
          }),
          material: PropTypes.shape({ name: PropTypes.string }),
          inspection_type: PropTypes.shape({ name: PropTypes.string }),
        })
      ),
      box_file_location: PropTypes.string,
      color: PropTypes.shape({ name: PropTypes.string }),
    })
  ),
  emptyText: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  showPagination: PropTypes.bool,
  sort: PropTypes.array,
};

export default OpportunityTable;
