import { compact } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  AlertBadge,
  Button,
  Loading,
  AddressModal as UntypedAddressModal,
} from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { usePrevious } from 'fr-shared/hooks';
import { AddressTypeEnum } from 'fr-shared/lib/address';

import AddressManagementModal from './AddressManagementModal';

// TODO: Convert AddressModal to Typescript
const AddressModal = UntypedAddressModal as React.FC<{ [key: string]: unknown }>;

enum DestinationInputMethod {
  PostalCode,
  ShippingAddress,
}

interface DestinationSelectorProps {
  addresses: ShippingAddress[];
  className?: string;
  isError: boolean;
  isLoading: boolean;
  onAddAddress: (address: ShippingAddress) => any;
  onSubmit: (payload: { postal_code: string } | { shipping_address_id: string }) => any;
  quote: {
    customer: { id: number };
    customer_contact: { id: number };
    shipping_price?: object;
    selected_shipping_estimate?: ShippingEstimate;
    shipping_estimates?: ShippingEstimate[];
  };
  readonly: boolean;
}

const initialValues = (
  { postal_code, shipping_address_id = null }: Partial<ShippingEstimate>,
  shippingPrice: object | null
): {
  inputMethod: DestinationInputMethod;
  postalCode: string;
  shippingAddressId: string;
} => {
  if (!shippingPrice && Boolean(postal_code)) {
    return {
      inputMethod: DestinationInputMethod.PostalCode,
      postalCode: postal_code,
      shippingAddressId: '',
    };
  } else if (!shippingPrice && shipping_address_id !== null) {
    return {
      inputMethod: DestinationInputMethod.ShippingAddress,
      postalCode: '',
      shippingAddressId: String(shipping_address_id),
    };
  } else {
    return {
      inputMethod: DestinationInputMethod.PostalCode,
      postalCode: '',
      shippingAddressId: '',
    };
  }
};

const addressToOption = (address: ShippingAddress) => {
  const orderedFields = [
    address.name_line_1,
    address.name_line_2,
    address.street_line_1,
    address.street_line_2,
    address.city,
    address.state,
    address.postal_code,
    address.country,
  ];

  return {
    id: address.id,
    name: compact(orderedFields).join(', '),
  };
};

const DestinationSelector = ({
  addresses,
  className = '',
  isLoading,
  onAddAddress,
  onSubmit,
  quote,
  readonly,
}: DestinationSelectorProps) => {
  const { setAlert } = useContext(AlertContext);

  const [inputMethod, setInputMethod] = useState(DestinationInputMethod.PostalCode);
  const [postalCode, setPostalCode] = useState('');
  const [shippingAddressId, setShippingAddressId] = useState('');
  const previousAddresses = usePrevious(addresses) ?? [];

  const shippingPrice = quote.shipping_price;
  const shippingEstimates = quote.shipping_estimates ?? [];
  const hasEstimates = shippingEstimates.length > 0;
  const postalCodeSelected = inputMethod === DestinationInputMethod.PostalCode;
  const shippingAddressSelected = inputMethod === DestinationInputMethod.ShippingAddress;
  const selectedShippingEstimate = quote.selected_shipping_estimate;

  const handleAddressError = useCallback(() => {
    setAlert({
      message: 'Failed to add. Refresh to try again.',
      color: 'danger',
    });
  }, [setAlert]);

  const handleAddressSave = useCallback(
    (address: ShippingAddress) => {
      setAlert({ message: 'Address added', color: 'success' });

      setInputMethod(DestinationInputMethod.ShippingAddress);
      setShippingAddressId(String(address.id));

      onAddAddress(address);
    },
    [onAddAddress, setAlert]
  );

  const handleSubmit = useCallback(() => {
    if (postalCodeSelected && Boolean(postalCode)) {
      onSubmit({ postal_code: postalCode });
    } else if (shippingAddressSelected && Boolean(shippingAddressId)) {
      onSubmit({ shipping_address_id: shippingAddressId });
    } else {
      setAlert({
        message: 'Shipping location is required',
        color: 'danger',
      });
    }
  }, [
    onSubmit,
    postalCode,
    postalCodeSelected,
    setAlert,
    shippingAddressId,
    shippingAddressSelected,
  ]);

  const handleChangePostalCodeRadioButton = useCallback(() => {
    setInputMethod(DestinationInputMethod.PostalCode);
  }, []);

  const handleChangePostalCodeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPostalCode(e.target.value);
  }, []);

  const handleKeyDownPostalCodeInput = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  const handleChangeShippingAddressRadioButton = useCallback(() => {
    setInputMethod(DestinationInputMethod.ShippingAddress);
  }, []);

  const handleChangeShippingAddressSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setShippingAddressId(e.target.value);
    },
    []
  );

  /*
   * Initialize the form only on initial mount.
   */
  useEffect(() => {
    const { inputMethod, postalCode, shippingAddressId } = initialValues(
      shippingEstimates?.[0] ?? {},
      shippingPrice
    );

    setInputMethod(inputMethod);
    setPostalCode(postalCode);
    setShippingAddressId(shippingAddressId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * If addresses are received and the user hasn't started filling anything out yet,
   * automatically select the customer's default shipping address.
   */
  useEffect(() => {
    if (
      shippingEstimates.length === 0 &&
      previousAddresses.length === 0 &&
      addresses.length > 0
    ) {
      const defaultAddress = addresses.find(({ is_default }) => is_default);

      if (defaultAddress) {
        setInputMethod(DestinationInputMethod.ShippingAddress);
        setShippingAddressId(String(defaultAddress.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses]);

  return (
    <div className={className}>
      {readonly ? (
        <div className="align-items-center font-size-md p-1 row">
          <div className="col-md-3">
            {selectedShippingEstimate?.postal_code && 'Postal Code'}
            {selectedShippingEstimate?.shipping_address && 'Address'}
            {!selectedShippingEstimate && 'None selected'}
          </div>
          {selectedShippingEstimate?.postal_code && (
            <div className="col">
              <strong>{selectedShippingEstimate.postal_code}</strong>
            </div>
          )}
          {selectedShippingEstimate?.shipping_address && (
            <div className="col">
              <strong>{addressToOption(selectedShippingEstimate.shipping_address).name}</strong>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="align-items-center font-size-md p-1 row">
            <div className="col-md-4">
              <div className="form-check">
                <input
                  id="postal-code-radio"
                  type="radio"
                  className="form-check-input"
                  checked={postalCodeSelected}
                  disabled={isLoading}
                  onChange={handleChangePostalCodeRadioButton}
                />
                <label htmlFor="postal-code-radio" className="form-check-label">
                  Enter US Postal Code
                </label>
              </div>
            </div>
            <div className="col">
              <input
                id="postal-code-input"
                type="text"
                className="form-control"
                disabled={isLoading || !postalCodeSelected}
                value={postalCode}
                onChange={handleChangePostalCodeInput}
                onKeyDown={handleKeyDownPostalCodeInput}
              />
            </div>
            <div className="col">
              <strong>OR</strong>
            </div>
          </div>
          <div className="align-items-center bg-light font-size-md p-1 row">
            <div className="col-md-4">
              <div className="form-check">
                <input
                  id="shipping-address-radio"
                  type="radio"
                  className="form-check-input"
                  checked={shippingAddressSelected}
                  disabled={isLoading}
                  onChange={handleChangeShippingAddressRadioButton}
                />
                <label htmlFor="shipping-address-radio" className="form-check-label font-size-md">
                  Select Address
                </label>
              </div>
            </div>
            <div className="col-md-8">
              <div className="form-group row">
                <div className="col-md-12">
                  <select
                    id="shipping-address-select"
                    className="form-control custom-select"
                    disabled={isLoading || !shippingAddressSelected}
                    value={shippingAddressId}
                    onChange={handleChangeShippingAddressSelect}
                  >
                    <option value=""></option>
                    {addresses.map(addressToOption).map(({ id, name }) => (
                      <option key={`shipping-address-id-${id}`} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="align-items-center row">
                <div className="col-md-4">
                  <AddressModal
                    action={
                      <Button outline color="secondary" size="sm" disabled={isLoading}>
                        Use New Address
                      </Button>
                    }
                    onError={handleAddressError}
                    onSave={handleAddressSave}
                    title="Use New Shipping Address"
                    userId={quote.customer_contact.id}
                    type={AddressTypeEnum.Shipping}
                  />
                </div>
                <div className="col text-right">
                  <AddressManagementModal
                    customerContactId={quote.customer_contact.id}
                    customerId={quote.customer.id}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-auto">
              <Button
                color="primary"
                onClick={handleSubmit}
                disabled={isLoading}
                tooltip={
                  isLoading ? 'Calculating shipping. This should only take a few seconds.' : null
                }
              >
                {isLoading ? (
                  <>
                    <Loading type="circle" color="white" className="font-size-md mr-2" />
                    Calculating Shipping...
                  </>
                ) : hasEstimates ? (
                  'Recalculate Shipping Costs'
                ) : (
                  'Calculate Shipping Costs'
                )}
              </Button>
            </div>
            <div className="col-auto">
              <AlertBadge md />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DestinationSelector;
