import PropTypes from 'prop-types';
import React from 'react';

import { Button, Icon, Modal } from 'fr-shared/components';

const DeletePartModal = ({ part, handleDelete, lineItemName }) => {
  return (
    <Modal
      action={
        <Button
          outline={true}
          color="danger"
          className="px-2"
          dataTestId="delete-part-modal--x-button"
        >
          <Icon name="times" />
        </Button>
      }
    >
      {({ toggle }) => (
        <>
          <Modal.Header
            title={`Are you sure you want to remove the part from ${lineItemName.toLowerCase()}?`}
            onClose={toggle}
          />
          <div className="modal-body">
            <ul>
              <li className="word-break" key={part.id}>
                {part.name}
              </li>
            </ul>
            <p>Make sure to Save your changes after deleting.</p>
          </div>
          <div className="modal-footer">
            <Button color="link" onClick={toggle}>
              Cancel
            </Button>
            <Button
              outline={true}
              color="danger"
              className="ml-2"
              onClick={() => {
                handleDelete && handleDelete();
                toggle();
              }}
            >
              Delete
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

DeletePartModal.propTypes = {
  part: PropTypes.object,
  handleDelete: PropTypes.func.isRequired,
  lineItemName: PropTypes.string,
};

export default DeletePartModal;
