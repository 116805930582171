import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { useUserAnalyticsContext } from 'fr-shared/context';

import { analysisTimeRemainingMs } from 'portal/hooks/useAnalysisProgress';

import AnalysisFail from './components/AnalysisFail/AnalysisFail';
import AnalysisProgress from './components/AnalysisProgress/AnalysisProgress';
import EvaluateViewer from './viewer';

// Keys be kept in sync with assets/src/views/customer_portal/index.js
const ROUTE_TO_TAG: Record<string, string> = {
  dfm: 'DFM Checks',
  materials: 'Materials',
  costs: 'Costs',
};

const EvaluatePartContainer = () => {
  const location = useLocation();
  const userAnalytics = useUserAnalyticsContext();

  // const { setAlert } = useContext(AlertContext);
  const { part_id: partId } = useParams<{ part_id: string }>();

  // The part that is loaded immediately when the component renders. This part
  // might have a revision that has not been analyzed and that, therefore,
  // doesn't have an stl_path or analysis results.
  const [initialPart, setInitialPart] = useState(null);

  // The part whose revision will be shown in the DFM viewer. This will be set
  // to a non-null value after the revision has finished analyzing or analysis
  // has timed out and after an updated part has been loaded from the API.
  const [partToShow, setPartToShow] = useState(null);

  // Load the part for the first time when the page first loads.
  useEffect(() => {
    // Check which viewer tab we landed on (if we did) and track that
    if (location.pathname.includes('studio/evaluate')) {
      Object.keys(ROUTE_TO_TAG).forEach(route => {
        if (location.pathname.includes(route)) {
          userAnalytics.track(`Studio - ${ROUTE_TO_TAG[route]} - in Tab`);
        }
      });
    }

    // Load
    api
      .get(`/customer_portal/parts/${partId}`)
      .then(res => {
        const { data: part } = res;
        setInitialPart(part);
        const msRemaining = analysisTimeRemainingMs(part.current_revision);

        if (part.current_revision.is_analyzed || msRemaining <= 0) {
          // Either the current revision is already analyzed or the analysis has
          // timed out, so show the current revision in the DFM viewer.
          setPartToShow(part);
        }
      })
      .catch(() => {
        // setAlert({
        //   color: 'danger',
        //   message: 'An unexpected error occurred, please refresh and try again',
        // });
        // history.push('/evaluate'); TODO
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load the part again after analysis has finished or timed out so that we can
  // get the results of the analysis on the revision before showing it in the
  // DFM viewer.
  const loadPartAfterAnalysisOrTimeout = useCallback(() => {
    api
      .get(`/customer_portal/parts/${partId}`)
      .then(res => {
        const { data: part } = res;
        if (part.current_revision.id === initialPart.current_revision.id) {
          setPartToShow(part);
        } else {
          // If a new revision was created in the time since the part was
          // initially loaded then just refresh the page so that we show loading
          // progress for the newest revision.
          window.location.reload();
        }
      })
      .catch(() => {
        // setAlert({
        //   color: 'danger',
        //   message: 'An unexpected error occurred, please refresh and try again',
        // });
        // history.push('/evaluate'); TODO
      });
  }, [initialPart, partId]);

  if (!initialPart || !initialPart.current_revision) return null;

  return partToShow ? (
    partToShow.current_revision.stl_url ? (
      <EvaluateViewer partFileRevision={partToShow.current_revision} />
    ) : (
      // If STL conversion failed then we can't show anything in the viewer.
      <AnalysisFail />
    )
  ) : (
    <AnalysisProgress part={initialPart} onCompleted={() => loadPartAfterAnalysisOrTimeout()} />
  );
};

export default EvaluatePartContainer;
