import React from 'react';

import { Icon, classNames } from 'fr-shared/components';
import {
  COSTING_REQUEST_STATE_COLORS,
  SUPPLIER_RESPONSE_STATE_COLORS,
} from 'fr-shared/lib/costing_requests';
import { ORDER_STATE_COLORS } from 'fr-shared/lib/orders';
import { QUOTE_STATE_COLORS, QUOTE_STATE_DISPLAY_NAMES } from 'fr-shared/lib/quotes';

interface Props {
  children: React.ReactNode;
  className?: string;
  color: string;
  fontSize?: string;
  style?: Object;
  title?: string;
}

interface WrapperProps {
  className?: string;
  state: string;
  style?: Object;
}

interface FirstOrderBadgeProps extends Omit<WrapperProps, 'state'> {
  fontSize?: string;
  showText?: boolean;
}

const StateBadge = ({
  children,
  className,
  color = 'secondary',
  fontSize = 'md',
  ...props
}: Props) => (
  <span
    className={classNames([
      'badge',
      'badge-pill',
      `badge-${color} text-white`,
      `font-size-${fontSize}`,
      'align-self-center',
      className,
    ])}
    {...props}
  >
    {children}
  </span>
);

export const FirstOrderBadge = ({ showText = true, ...props }: FirstOrderBadgeProps) => (
  <StateBadge color="tertiary" title="First Order" {...props}>
    <Icon name="star" className={classNames({ 'mr-1': showText })} />
    {showText && 'First Order'}
  </StateBadge>
);

export const QuoteStateBadge = ({ state, ...props }: WrapperProps) => {
  const color = QUOTE_STATE_COLORS[state];
  const displayName = QUOTE_STATE_DISPLAY_NAMES[state];

  return (
    <StateBadge color={color} {...props}>
      {displayName}
    </StateBadge>
  );
};

export const CostingRequestStateBadge = ({ state, ...props }: WrapperProps) => {
  const color = COSTING_REQUEST_STATE_COLORS[state];

  return (
    <StateBadge color={color} {...props}>
      {state}
    </StateBadge>
  );
};

export const OrderStateBadge = ({ state, ...props }: WrapperProps) => {
  const color = ORDER_STATE_COLORS[state];

  return (
    <StateBadge color={color} {...props}>
      {state}
    </StateBadge>
  );
};

export const SupplierResponseStateBadge = ({ state, ...props }: WrapperProps) => {
  const color = SUPPLIER_RESPONSE_STATE_COLORS[state];

  return (
    <StateBadge color={color} {...props}>
      {state}
    </StateBadge>
  );
};

export const SupplierPortalConfirmationBadge = (props: WrapperProps) => {
  return (
    <StateBadge color="success" title="In Supplier Portal" {...props}>
      In Supplier Portal
    </StateBadge>
  );
};
