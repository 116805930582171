import { Form, useFormikContext, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useUserAnalyticsContext } from 'fr-shared/context';
import { mergeSchemas } from 'fr-shared/utils';

import AccountPanel, { Schema as AccountSchema } from './AccountPanel';
import DetailsPanel, { Schema as DetailsSchema } from './DetailsPanel';

const CreateAccountForm = ({ submitLoading }) => {
  const formik = useFormikContext();
  const history = useHistory();
  const userAnalytics = useUserAnalyticsContext();
  const trackNextStep = valid => {
    userAnalytics.track('Sign Up Step 1 Complete', { valid });
  };

  const handleNextPanel = () => {
    formik.validateField('email');
    formik.validateField('password');
    formik.validateField('terms');

    if (AccountSchema.isValidSync(formik.values)) {
      trackNextStep(true);
      history.push('/create-account/details');
    } else {
      trackNextStep(false);
    }
  };

  const hasValidAccount = AccountSchema.isValidSync(formik.values);

  return (
    <Form noValidate>
      <Switch>
        <Route
          exact={true}
          path="/create-account/details"
          scrollToTop={true}
          render={() =>
            hasValidAccount ? (
              <DetailsPanel
                dataTestId="details_panel"
                submitLoading={submitLoading}
                submitLabel="Create Account"
              />
            ) : (
              <Redirect to="/create-account" />
            )
          }
        />
        <Route path="/create-account" scrollToTop={true}>
          <AccountPanel dataTestId="account_panel" onNext={handleNextPanel} />
        </Route>
      </Switch>
    </Form>
  );
};

CreateAccountForm.propTypes = {
  onSubmit: PropTypes.func,
  submitLoading: PropTypes.bool,
  onValidateUniqueEmail: PropTypes.func,
  validateUniqueEmailLoading: PropTypes.bool,
};

const FormSchema = mergeSchemas(AccountSchema, DetailsSchema);

export default withFormik({
  mapPropsToValues: props => ({
    email: props.email || '',
    password: '',
    terms: false,
    first_name: '',
    last_name: '',
    phone_number: '',
    marketing_company_name: '',
    marketing_role: '',
  }),
  validationSchema: FormSchema,
  validateOnBlur: true,
  validateOnChange: false,
  handleSubmit: (values, formik) => {
    formik.props.onSubmit(values, formik);
  },
})(CreateAccountForm);
