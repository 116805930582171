import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import StudioUploadModal from './StudioUploadModal/StudioUploadModal';

/* eslint-disable no-unreachable */
const Onboarding = ({ logInEventsCount }) => {
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    const hasOnboardingLoaded = localStorage.getItem('hasOnboardingLoaded');

    if (hasOnboardingLoaded !== 'true') {
      setShouldShow(true);
    }

    localStorage.setItem('hasOnboardingLoaded', 'true');
  }, [setShouldShow]);

  if (logInEventsCount > 0 || !shouldShow) {
    return null;
  }

  return <StudioUploadModal />;
};
/* eslint-enable no-unreachable */

Onboarding.propTypes = {
  logInEventsCount: PropTypes.number,
};

export default Onboarding;
