import { IconFont } from '@fast-radius/shared-ui';
import { Form, Formik, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { AlertContext, useUserAnalyticsContext } from 'fr-shared/context';
import { useEffectDebounced } from 'fr-shared/hooks';
import { billingSchema, shippingSchema } from 'fr-shared/lib/address';
import { COUNTRIES } from 'fr-shared/lib/countries';
import { SHIPPING_PAYMENT_TYPES } from 'fr-shared/lib/shipping';
import { mergeSchemas } from 'fr-shared/utils';

import { Link } from 'portal/components';
import { manualQuoteSchema, shippingAccountSchema } from 'portal/lib/schema';
import { QuoteProps } from 'portal/types/quotes';

import CheckoutBilling from '../components/CheckoutBilling';
import CheckoutLayout from '../components/CheckoutLayout';
import CheckoutReview from '../components/CheckoutReview';
import CheckoutShipping from '../components/CheckoutShipping';
import CheckoutSummary from '../components/CheckoutSummary';

const CheckoutQuotesContainer = () => {
  const [quote, setQuote] = useState(null);
  const history = useHistory();
  const { setAlert } = useContext(AlertContext);
  const { id }: any = useParams();

  useEffect(() => {
    api
      .get(`/customer_portal/quotes/${id}`)
      .then(res => {
        const { data: quoteData } = res;

        const incompleteQuote =
          (quoteData.shipping_payment_type !== SHIPPING_PAYMENT_TYPES.CustomerAccount &&
            !quoteData.shipping_price?.amount) ||
          !quoteData.sub_total_price?.amount ||
          !quoteData.total_price.amount;
        if (!quoteData?.user_can_checkout || incompleteQuote)
          throw 'Can not checkout a quote that is not in a submitted state';
        setQuote(quoteData);
      })
      .catch(() => {
        setAlert({ color: 'danger', message: 'Sorry this quote is unable to be checked out' });
        history.push(`/quotes/${id}`);
      });
  }, [history, id, setAlert]);

  if (!quote) return null;

  const schema =
    quote.shipping_payment_type === SHIPPING_PAYMENT_TYPES.CustomerAccount
      ? mergeSchemas(manualQuoteSchema, shippingAccountSchema)
      : manualQuoteSchema;

  let initialVals = {
    billing_address: {},
    shipping_address: { country: COUNTRIES.UNITED_STATES },
    shipping_method: quote.shipping_method,
    quote_id: quote.id,
    payment_method_nonce: '',
    credit_card_checkout: true,
  };

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    setSubmitting(true);

    api
      .post(`customer_portal/orders`, { ...values })
      .then(response => {
        // logEvt(true);
        history.push(`/checkout/confirmation/order/${response.data.order_id}`);
      })
      .catch(() => {
        // logEvt(false);
        setAlert({
          message: 'An unexpected error occurred. Please refresh and try again.',
          color: 'danger',
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik initialValues={initialVals} onSubmit={handleSubmit} validationSchema={schema}>
      <Form className="flex flex-fill flex-column">
        <CheckoutForm quote={quote} />
      </Form>
    </Formik>
  );
};

export default CheckoutQuotesContainer;

interface CheckoutFormProps {
  quote: QuoteProps;
}

const ANALYTICS_CONTEXT = 'Quote Checkout';

const CheckoutForm = ({ quote }: CheckoutFormProps) => {
  const formik: any = useFormikContext();
  const userAnalytics = useUserAnalyticsContext();
  const {
    discount,
    sub_total_price,
    total_price,
    shipping_price,
    line_items,
    selected_shipping_estimate,
    shipping_payment_type,
    id: quoteId,
  } = quote;

  const isCustomerShippingAccount =
    shipping_payment_type === SHIPPING_PAYMENT_TYPES.CustomerAccount;

  const shippingSchemaToValidate = isCustomerShippingAccount
    ? mergeSchemas(shippingSchema, shippingAccountSchema)
    : shippingSchema;

  const shippingFieldsValid = shippingSchemaToValidate.isValidSync(formik.values);

  const billingFieldsValid = billingSchema.isValidSync(formik.values);

  const shipping_address_id = selected_shipping_estimate?.shipping_address_id;
  const isFormValid = billingFieldsValid && shippingFieldsValid && isEmpty(formik.errors);

  let shippingMethod = isCustomerShippingAccount
    ? formik.values.shipping_method
    : quote.selected_shipping_estimate?.service != null
    ? quote.selected_shipping_estimate?.service
    : formik.values.shipping_method;

  useEffectDebounced(() => {
    const { shipping_method } = formik.values;
    if (shipping_method) {
      userAnalytics.track('Quote Checkout - Shipping Option', { inputValue: shipping_method });
    }
  }, [formik.values.shipping_method]);

  return (
    <CheckoutLayout
      backLink={
        <Link className="font-size-sm text-gray text-decoration-none" to="/">
          <IconFont name="chevron-left" right />
          Back to dashboard
        </Link>
      }
      mainContent={
        <>
          <CheckoutShipping
            analyticsContext={ANALYTICS_CONTEXT}
            isActive={location.pathname.includes('shipping')}
            quoteId={quoteId}
            schemaIsValid={shippingFieldsValid}
            shippingAddressId={shipping_address_id}
            isCustomerShippingAccount={isCustomerShippingAccount}
          />
          <CheckoutBilling
            analyticsContext={ANALYTICS_CONTEXT}
            isActive={location.pathname.includes('billing')}
            formik={formik}
            itemsSubtotal={parseFloat(sub_total_price?.amount)}
            quoteId={quoteId}
          />
          <CheckoutReview
            quoteId={quoteId}
            formik={formik}
            lineItems={line_items}
            isActive={location.pathname.includes('review')}
            isSubmitDisabled={!isFormValid}
            discount={quote.discount.amount}
            isCustomerShippingAccount={isCustomerShippingAccount}
            shippingMethod={shippingMethod}
            shippingPrice={shipping_price?.amount}
            subtotal={sub_total_price.amount}
            totalPrice={total_price.amount}
          />
        </>
      }
      summary={
        <CheckoutSummary
          canEdit={false}
          discount={discount.amount}
          partCount={line_items.length}
          lineItems={line_items}
          shippingPrice={shipping_price?.amount}
          subtotal={sub_total_price.amount}
          totalPrice={total_price.amount}
        />
      }
    />
  );
};
