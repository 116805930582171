import { api } from 'fr-shared/api';
import { useS3FilesUpload } from 'fr-shared/hooks';
import { isPartFile } from 'fr-shared/utils/files';

const buildPart = (partParams, fileParams) => {
  const { path, fileName } = fileParams;

  const part = { ...partParams, name: fileName };

  // Part files are intended to be 3D representations of a part
  // and are stored as revisions,
  // whereas documents are supplementary files of any filetype
  // that help round out the context for making the part.
  if (isPartFile(fileName)) {
    part.revisions = [
      {
        name: fileName,
        s3_path: path,
      },
    ];
  } else {
    part.documents = [
      {
        file_name: fileName,
        s3_path: path,
      },
    ];
  }

  return part;
};

const persistPart = (response, file, partParams) => {
  const fileParams = {
    path: response.data.path,
    fileName: file.name,
  };

  const part = buildPart(partParams, fileParams);

  if (part.id) {
    return api.put(`/parts/${part.id}`, { part: part });
  } else {
    return api.post('/parts', { part: part });
  }
};

const useS3PartUpload = () => {
  return useS3FilesUpload('/s3/sign/part_file', persistPart);
};

export default useS3PartUpload;
