import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { Breadcrumb, GlobalErrorBoundary, Helmet, NotFound } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { useChannel } from 'fr-shared/hooks';
import { QUOTE_STATES } from 'fr-shared/lib/quotes';

import QuoteForm from './components/QuoteForm';
import {
  quoteToDisplayID,
  transformErrors,
  transformQuoteFromLoad,
  transformQuoteToSave,
} from './utils/transforms';

const QuoteEdit = ({ history, match }) => {
  const [initialValues, setInitialValues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { setAlert } = useContext(AlertContext);

  const id = match.params.id;
  const [channel] = useChannel(`quote:${id}`);

  const handleSync = (quoteId, formik) => {
    setIsLoading(true);
    api
      .put(`/quotes/${quoteId}/update_state`, { state: QUOTE_STATES.Submitted })
      .then(() => {
        setAlert({ message: `Quote #${quoteId} was submitted to Salesforce!` });
      })
      .catch(err => {
        if (err?.response?.data) {
          const { errors: serverErrors, messages } = err.response.data;
          formik.setErrors({ ...formik.errors, server: messages, ...serverErrors });
        } else if (err?.response?.status === 524) {
          setAlert({
            color: 'danger',
            message:
              'This quote is taking a long time to sync. Please periodically refresh salesforce to check that the sync has complete.',
            autoClose: false,
          });
        } else {
          setAlert({
            color: 'danger',
            message: 'An unexpected error occurred. Refresh and try again',
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmitAndSync = (values, formik, onSuccess) => {
    setIsLoading(true);
    api
      .put(`/quotes/${values.id}`, { quote: transformQuoteToSave(values) })
      .then(res => {
        return api
          .put(`/quotes/${res.data.id}/update_state`, { state: QUOTE_STATES.Submitted })
          .then(res => {
            setAlert({ message: 'Your changes were submitted to Salesforce!' });
            setInitialValues(transformQuoteFromLoad(res.data));
            onSuccess();
          })
          .catch(async err => {
            if (err?.response?.data) {
              const { errors: serverErrors, messages } = err.response.data;
              const transformedErrors = await transformErrors(serverErrors, values);
              formik.setErrors({ ...formik.errors, server: messages, ...transformedErrors });
            } else if (err?.response?.status === 524) {
              setAlert({
                color: 'danger',
                message:
                  'This quote is taking a long time to sync. Please periodically refresh salesforce to check that the sync has complete.',
                autoClose: false,
              });
              loadQuote();
            } else {
              setAlert({
                color: 'danger',
                message: 'An unexpected error occurred. Refresh and try again',
              });
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch(async err => {
        if (err.response?.data) {
          const { errors: serverErrors, messages } = err.response.data;
          const transformedErrors = await transformErrors(serverErrors, values);
          formik.setErrors({ ...formik.errors, server: messages, ...transformedErrors });
        } else {
          formik.setErrors({
            ...formik.errors,
            server: ['An unexpected error occurred. Refresh and try again.'],
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSave = (values, formik) => {
    api
      .put(`/quotes/${values.id}`, { quote: transformQuoteToSave(values) })
      .then(res => {
        setAlert({ message: 'Your changes were saved successfully!' });
        setInitialValues(transformQuoteFromLoad(res.data));
      })
      .catch(async err => {
        if (err.response?.data) {
          const { errors: serverErrors, messages } = err.response.data;
          const transformedErrors = await transformErrors(serverErrors, values);
          formik.setErrors({ ...formik.errors, server: messages, ...transformedErrors });
        } else {
          formik.setErrors({
            ...formik.errors,
            server: ['An unexpected error occurred. Refresh and try again.'],
          });
        }
      });
  };

  const loadQuote = useCallback(() => {
    setIsLoading(true);
    setHasError(false);
    setInitialValues(null);
    api
      .get(`/quotes/${id}`)
      .then(res => {
        setInitialValues(transformQuoteFromLoad(res.data));
      })
      .catch(error => {
        if (error.response?.status !== 404) {
          setHasError(true);
          Sentry.captureMessage('Quote GET Failed');
          Sentry.setExtra('quote_id', id);
          Sentry.setExtra('error', error);
        }
      })
      .finally(() => setIsLoading(false));
  }, [id]);

  useEffect(() => {
    loadQuote();
  }, [loadQuote]);

  if (hasError) return <GlobalErrorBoundary />;
  if (!initialValues && !isLoading) return <NotFound />;
  if (!initialValues) return null;

  const readonly = initialValues.state !== QUOTE_STATES.Draft;

  return (
    <>
      <Helmet title="Edit Quote" />
      <Breadcrumb to="/admin/quotes">Quotes</Breadcrumb>
      <Breadcrumb to={`/admin/quotes/${id}/edit`}>
        Edit Quote #{quoteToDisplayID(initialValues)}
      </Breadcrumb>

      <QuoteForm
        channel={channel}
        history={history}
        isLoading={isLoading}
        initialValues={initialValues}
        loadQuote={loadQuote}
        onSubmitAndSync={handleSubmitAndSync}
        onSubmit={handleSave}
        onSync={handleSync}
        readonly={readonly}
        title={`Quote #${quoteToDisplayID(initialValues)}`}
      />
    </>
  );
};

QuoteEdit.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default QuoteEdit;
