import { useTreatments } from '@splitsoftware/splitio-react';
import { connect } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Alert, Button, Icon } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { CR_BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { COSTING_REQUEST_LI_STATES } from 'fr-shared/lib/costing_requests';
import { isExternalProcess } from 'fr-shared/lib/manufacturing_process';
import { userIsCostingTeam } from 'fr-shared/lib/user_roles';
import { isFormikAtPrefixTheSame } from 'fr-shared/utils';

import CostingRejectLineItemModal from './CostingRejectLineItemModal';
import { CostingFormContext } from './context/CostingFormContext';

const InternalCostingActions = ({
  fieldPrefix,
  lineItem,
  onAddAdditionalSupplier,
  onSaveLineItem,
  onSubmitCosts,
}) => {
  const { [CR_BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';

  const { user } = useContext(UserContext);
  const { validateAction } = useContext(CostingFormContext);
  const process = lineItem.build_package.processes[0];

  const canAddAdditionalSupplier =
    lineItem.state === COSTING_REQUEST_LI_STATES.InProgress &&
    isExternalProcess(process.manufacturing_process?.name);

  if (!userIsCostingTeam(user)) {
    return null;
  }

  return (
    <div className="bg-white flex flex-column flex-md-row">
      <>
        {lineItem.state === COSTING_REQUEST_LI_STATES.Requested && (
          <Alert color="dark" className="my-0">
            Move {lineItemName} to &apos;In Progress&apos; to edit part info and quantities, or to
            submit costs.
          </Alert>
        )}
        {canAddAdditionalSupplier && (
          <Button
            color="light"
            onClick={() => {
              validateAction(fieldPrefix, 'add an additional supplier', onAddAdditionalSupplier);
            }}
          >
            <Icon name="plus" right={true} />
            Add Additional Supplier
          </Button>
        )}
      </>
      <div className="ml-md-auto pt-2 pt-md-0 flex justify-content-between align-items-center">
        {lineItem.state === COSTING_REQUEST_LI_STATES.InProgress && (
          <div>
            <Button
              className="mr-2"
              color="primary"
              onClick={() =>
                validateAction(fieldPrefix, 'revert to Requested', () => {
                  onSaveLineItem(lineItem, fieldPrefix, {
                    state: COSTING_REQUEST_LI_STATES.Requested,
                  });
                })
              }
            >
              Revert to Requested
            </Button>
            <Button
              className="mr-2"
              color="primary"
              onClick={() =>
                validateAction(fieldPrefix, 'save', () => {
                  onSaveLineItem(lineItem, fieldPrefix);
                })
              }
            >
              Save
            </Button>
            <CostingRejectLineItemModal
              fieldPrefix={fieldPrefix}
              lineItem={lineItem}
              validateCanReject={onReject => validateAction(fieldPrefix, 'reject part', onReject)}
              onConfirm={() =>
                onSaveLineItem(lineItem, fieldPrefix, {
                  rejected_at: moment(),
                  state: COSTING_REQUEST_LI_STATES.Rejected,
                })
              }
            />
            <Button
              color="success"
              onClick={() => {
                validateAction(fieldPrefix, 'submit costs', onSubmitCosts);
              }}
            >
              Submit Costs
            </Button>
          </div>
        )}
        {lineItem.state === COSTING_REQUEST_LI_STATES.Requested && (
          <div>
            <CostingRejectLineItemModal
              fieldPrefix={fieldPrefix}
              lineItem={lineItem}
              validateCanReject={onReject => validateAction(fieldPrefix, 'reject part', onReject)}
              onConfirm={() =>
                onSaveLineItem(lineItem, fieldPrefix, {
                  rejected_at: moment(),
                  state: COSTING_REQUEST_LI_STATES.Rejected,
                })
              }
            />
            <Button
              color="success"
              onClick={() =>
                validateAction(fieldPrefix, 'move to In Progress', () => {
                  onSaveLineItem(lineItem, fieldPrefix, { state: 'In Progress' });
                })
              }
              dataTestId="move-to-in-progress--button"
            >
              Move to In Progress
            </Button>
          </div>
        )}
        {lineItem.state === COSTING_REQUEST_LI_STATES.Completed && (
          <Button
            color="primary"
            onClick={() =>
              onSaveLineItem(lineItem, fieldPrefix, {
                completed_at: null,
                state: COSTING_REQUEST_LI_STATES.InProgress,
              })
            }
          >
            Edit Costs
          </Button>
        )}
        {lineItem.state === COSTING_REQUEST_LI_STATES.Rejected && (
          <Button
            color="primary"
            onClick={() =>
              onSaveLineItem(lineItem, fieldPrefix, {
                rejected_at: null,
                rejected_reason: null,
                rejected_codes: [],
                state: COSTING_REQUEST_LI_STATES.Requested,
              })
            }
          >
            <Icon name="undo" right={true} />
            Undo Rejection
          </Button>
        )}
      </div>
    </div>
  );
};

InternalCostingActions.propTypes = {
  formik: PropTypes.object,
  fieldPrefix: PropTypes.string,
  lineItem: PropTypes.shape({
    build_package: PropTypes.shape({
      processes: PropTypes.arrayOf(
        PropTypes.shape({
          manufacturing_process: PropTypes.shape({
            name: PropTypes.string,
          }),
        })
      ),
    }),
    state: PropTypes.string,
    in_progress: PropTypes.bool,
  }),
  onAddAdditionalSupplier: PropTypes.func,
  onSaveLineItem: PropTypes.func,
  onSubmitCosts: PropTypes.func,
};

export default connect(React.memo(InternalCostingActions, isFormikAtPrefixTheSame));
