import { useFormikContext } from 'formik';
import { get } from 'lodash';
import React from 'react';

import { FormField, FormSelect, classNames } from 'fr-shared/components';
import { renderWithLineBreaks } from 'fr-shared/utils';

import FormRadioButtons from '../form/FormRadioButtons';

interface LineItemDutiesTaxesTariffsProps {
  fieldPrefix: string;
  readonly: boolean;
}

const LineItemDutiesTaxesTariffs = ({
  fieldPrefix,
  readonly,
}: LineItemDutiesTaxesTariffsProps) => {
  const formik: any = useFormikContext();

  const usage = get(formik.values, `${fieldPrefix}.part.usage`);
  const designation = get(formik.values, `${fieldPrefix}.part.designation`);
  const is_prototype = get(formik.values, `${fieldPrefix}.is_prototype`);
  const is_prototype_text = is_prototype === 'true' || is_prototype === true ? 'Yes' : 'No';

  const options = [
    { name: 'Sub-component', id: 'subcomponent' },
    { name: 'Finished Good', id: 'finished_good' },
  ];

  const prototype_options = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ];

  const labelClassNames = classNames(['col-md-12']);

  const inputClassNames = classNames(['col-md-12']);

  return (
    <div className="d-flex flex-column">
      {readonly ? (
        <div className="row form-group">
          <label htmlFor={`${fieldPrefix}.is_prototype`} className="ml-2 col-md-4">
            Are these parts prototypes (only used for testing and development, not resold by the
            customer)?
          </label>
          <div id={`${fieldPrefix}.is_prototype`} className="col-md flex flex-column ml-2">
            <div className="text-break" aria-readonly="true">
              <strong>{is_prototype_text}</strong>
            </div>
          </div>
        </div>
      ) : (
        <div className="row form-group">
          <label htmlFor={`${fieldPrefix}.is_prototype`} className="ml-2 col-md-4">
            Are these parts prototypes (only used for testing and development, not resold by the
            customer)?
          </label>
          <FormRadioButtons
            name={`${fieldPrefix}.is_prototype`}
            options={prototype_options}
            readonly={readonly}
          />
        </div>
      )}

      {readonly ? (
        <div className="row form-group">
          <label htmlFor={`${fieldPrefix}.part.usage`} className="ml-2 col-md-4">
            What is it used for? If machine or vehicle, what type?
          </label>
          <div id={`${fieldPrefix}.part.usage`} className="col-md flex flex-column ml-2">
            <div className="text-break" aria-readonly="true">
              <strong>{usage}</strong>
            </div>
          </div>
        </div>
      ) : (
        <FormField
          label="What is it used for? If machine or vehicle, what type?"
          labelWrap={true}
          name={`${fieldPrefix}.part.usage`}
          required
          readonly={readonly}
          labelClassName={labelClassNames}
          inputClassName={inputClassNames}
        />
      )}

      {readonly && (
        <div className="row form-group">
          <label htmlFor={`${fieldPrefix}.part.designation`} className="ml-2 col-md-4">
            Is it a sub-component or a finished good?
          </label>
          <div id={`${fieldPrefix}.part.designation`} className="col-md flex flex-column ml-2">
            <div className="text-break" aria-readonly="true">
              <strong>
                {renderWithLineBreaks(options.find(item => item.id === designation)?.name)}
              </strong>
            </div>
          </div>
        </div>
      )}

      {!readonly && (
        <FormSelect
          hasBlankOption={false}
          name={`${fieldPrefix}.part.designation`}
          label="Is it a sub-component or a finished good?"
          labelWrap={true}
          optionList={options}
          labelClassName={labelClassNames}
          inputClassName={inputClassNames}
          required
        />
      )}
    </div>
  );
};

export default LineItemDutiesTaxesTariffs;
