/* eslint-disable react/display-name,react/prop-types */
import { pickBy } from 'lodash';
import React, { useContext } from 'react';

import { api } from 'fr-shared/api';
import {
  Breadcrumb,
  Button,
  Helmet,
  Icon,
  Link,
  Sidebar,
  SidebarPage,
  SidebarPageContainer,
  Table,
} from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { useTableFilters } from 'fr-shared/hooks';

import SupplierFilters from './SupplierFilters';

const FILTER_NAME = 'suppliers';
const INITIAL_FILTERS = { show_inactive: true };

const Suppliers = () => {
  const { user } = useContext(UserContext);

  const getSuppliers = filters => {
    return api.get('/locations', { params: pickBy(filters) });
  };

  const {
    data: suppliers,
    filters,
    onFetchData,
    resetFilters,
    setFilters,
  } = useTableFilters(FILTER_NAME, INITIAL_FILTERS, getSuppliers);

  return (
    <>
      <Helmet title="Suppliers" />
      <Breadcrumb to="/admin/suppliers">Suppliers</Breadcrumb>

      <SidebarPageContainer>
        <SidebarPage>
          <div className="container">
            <div className="flex align-items-center py-3">
              <div>
                <h2 className="mb-0">Suppliers</h2>
              </div>
              {user.canCreateSuppliers && (
                <div className="ml-auto">
                  <Button to="/admin/suppliers/new" color="primary" size="md">
                    Add New Supplier
                  </Button>
                </div>
              )}
            </div>
            <Table
              data={suppliers || []}
              pageSize={suppliers?.length || 15}
              noDataText="No suppliers found"
              className="bg-white -striped mb-5"
              onFetchData={onFetchData}
              defaultSorted={[{ id: 'name', desc: false }]}
              columns={[
                {
                  Header: 'Supplier Name',
                  accessor: 'name',
                  width: 300,
                  Cell: row => (
                    <div className="h-100 flex align-items-center justify-content-center">
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Location',
                  id: 'address',
                  accessor: d => d,
                  Cell: row => {
                    const formatAddressPart = (addressPart, prefix = '') =>
                      addressPart ? `${prefix} ${addressPart}` : '';
                    const { address, address2, city, state_region, zip, country } = row.value;
                    return (
                      <div>
                        <div data-testid="location-address">
                          {formatAddressPart(address) + formatAddressPart(address2, ',')}
                        </div>
                        <div data-testid="location-area">
                          {formatAddressPart(city) +
                            formatAddressPart(state_region, ',') +
                            formatAddressPart(zip)}
                        </div>
                        <div data-testid="location-country">{formatAddressPart(country)}</div>
                      </div>
                    );
                  },
                },
                {
                  Header: 'Region',
                  accessor: 'region',
                  width: 300,
                  Cell: row => (
                    <div className="h-100 flex align-items-center justify-content-center">
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Active',
                  accessor: 'deleted_at',
                  width: 100,
                  Cell: row => (
                    <div
                      className="h-100 flex align-items-center justify-content-center"
                      data-testid="active"
                    >
                      {!row.value && <Icon name="check" />}
                    </div>
                  ),
                },
                {
                  Header: 'In House',
                  accessor: 'in_house',
                  width: 100,
                  Cell: row => (
                    <div
                      className="h-100 flex align-items-center justify-content-center"
                      data-testid="in_house"
                    >
                      {row.value && <Icon name="check" />}
                    </div>
                  ),
                },
                {
                  Header: '',
                  filterable: false,
                  width: 100,
                  show: user.canUpdateSuppliers,
                  Cell: row => (
                    <div className="h-100 flex align-items-center justify-content-end">
                      <Link to={`/admin/suppliers/${row.original.id}/edit`}>Edit</Link>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </SidebarPage>

        <Sidebar>
          <SupplierFilters
            filters={filters}
            clearFilters={resetFilters}
            setFilters={setFilters}
          />
        </Sidebar>
      </SidebarPageContainer>
    </>
  );
};

export default Suppliers;
