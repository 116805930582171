import PropTypes from 'prop-types';
import React from 'react';

import { keyboardDown } from 'fr-shared/utils';

import { classNames } from 'portal/components';

import styles from './Card.module.css';

export const Card = ({
  border = null,
  children,
  clickable = false,
  className = '',
  dataTestId = '',
  ownerBorder = false,
  size = 'md',
  onClick = null,
  ...props
}) => {
  // Only add click based properties to the card if defined
  if (onClick) {
    props = {
      ...props,
      onClick,
      onKeyDown: evt => keyboardDown(evt, 'Enter', onClick),
      tabIndex: 0,
      role: 'button',
    };
  }
  return (
    <div
      {...props}
      className={classNames([
        styles.Card,
        {
          [styles.border]: border,
          [styles.ownerBorder]: ownerBorder,
          [styles.clickable]: clickable || onClick,
        },
        size && styles[size],
        className,
      ])}
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
};

Card.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  clickable: PropTypes.bool,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
  onSelection: PropTypes.func,
  ownerBorder: PropTypes.bool,
  selected: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

export default Card;
