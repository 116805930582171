import React from 'react';

import {
  ALL_YIELD_COST_DRIVER_COPY,
  ALL_YIELD_MAX_COST_DRIVER,
  CNC_ENGINEERING_COST_DRIVER_COPY,
  CNC_ENGINEERING_MAX_COST_DRIVER,
  CNC_EQUIPMENT_COST_DRIVER_COPY,
  CNC_EQUIPMENT_MAX_COST_DRIVER,
  CNC_LABOR_COST_DRIVER_COPY,
  CNC_LABOR_MAX_COST_DRIVER,
  CNC_MATERIAL_COST_DRIVER_COPY,
  CNC_MATERIAL_MAX_COST_DRIVER,
  DLS_FDM_SLA_ENGINEERING_COST_DRIVER_COPY,
  DLS_FDM_SLA_EQUIPMENT_COST_DRIVER_COPY,
  DLS_FDM_SLA_LABOR_COST_DRIVER_COPY,
  DLS_FDM_SLA_LABOR_MAX_COST_DRIVER,
  DLS_FDM_SLA_MATERIAL_COST_DRIVER_COPY,
  DLS_FDM_SLA_MATERIAL_MAX_COST_DRIVER,
  MJF_DLS_FDM_SLA_ENGINEERING_MAX_COST_DRIVER,
  MJF_DLS_FDM_SLA_EQUIPMENT_MAX_COST_DRIVER,
  MJF_ENGINEERING_COST_DRIVER_COPY,
  MJF_EQUIPMENT_COST_DRIVER_COPY,
  MJF_LABOR_COST_DRIVER_COPY,
  MJF_LABOR_MAX_COST_DRIVER,
  MJF_MATERIAL_COST_DRIVER_COPY,
  MJF_MATERIAL_MAX_COST_DRIVER,
} from './copy';

// TODO: Re-factor as Component
export const MAX_COST_DRIVER_COPY: any = {
  CNC: {
    engineering: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{CNC_ENGINEERING_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    labor: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{CNC_LABOR_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    equipment: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{CNC_EQUIPMENT_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    material: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{CNC_MATERIAL_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    yield: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{ALL_YIELD_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
  },
  MJF: {
    engineering: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{MJF_DLS_FDM_SLA_ENGINEERING_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    labor: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{MJF_LABOR_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    equipment: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{MJF_DLS_FDM_SLA_EQUIPMENT_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    material: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{MJF_MATERIAL_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    yield: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{ALL_YIELD_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
  },
  DLS: {
    engineering: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{MJF_DLS_FDM_SLA_ENGINEERING_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    labor: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{DLS_FDM_SLA_LABOR_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    equipment: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{MJF_DLS_FDM_SLA_EQUIPMENT_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    material: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{DLS_FDM_SLA_MATERIAL_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    yield: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{ALL_YIELD_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
  },
  FDM: {
    engineering: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{MJF_DLS_FDM_SLA_ENGINEERING_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    labor: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{DLS_FDM_SLA_LABOR_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    equipment: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{MJF_DLS_FDM_SLA_EQUIPMENT_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    material: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{DLS_FDM_SLA_MATERIAL_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    yield: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{ALL_YIELD_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
  },
  SLA: {
    engineering: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{MJF_DLS_FDM_SLA_ENGINEERING_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    labor: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{DLS_FDM_SLA_LABOR_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    equipment: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{MJF_DLS_FDM_SLA_EQUIPMENT_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    material: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{DLS_FDM_SLA_MATERIAL_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
    yield: {
      null: (
        <>
          <div className="text-coolGray-100">
            <p>{ALL_YIELD_MAX_COST_DRIVER}</p>
          </div>
        </>
      ),
    },
  },
};

export const NEW_COST_DRIVER_COPY: any = {
  CNC: {
    engineering: (
      <>
        <div className="mb-2 text-coolGray-300">Engineering notes</div>
        <div className="text-coolGray-100">
          <p>{CNC_ENGINEERING_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    labor: (
      <>
        <div className="mb-2 text-coolGray-300">Labor notes</div>
        <div className="text-coolGray-100">
          <p>{CNC_LABOR_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    equipment: (
      <>
        <div className="mb-2 text-coolGray-300">Equipment notes</div>
        <div className="text-coolGray-100">
          <p>{CNC_EQUIPMENT_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    material: (
      <>
        <div className="mb-2 text-coolGray-300">Material notes</div>
        <div className="text-coolGray-100">
          <p>{CNC_MATERIAL_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    yield: (
      <>
        <div className="mb-2 text-coolGray-300">Yield notes</div>
        <div className="text-coolGray-100">
          <p>{ALL_YIELD_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
  },
  MJF: {
    engineering: (
      <>
        <div className="mb-2 text-coolGray-300">Engineering notes</div>
        <div className="text-coolGray-100">
          <p>{MJF_ENGINEERING_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    labor: (
      <>
        <div className="mb-2 text-coolGray-300">Labor notes</div>
        <div className="text-coolGray-100">
          <p>{MJF_LABOR_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    equipment: (
      <>
        <div className="mb-2 text-coolGray-300">Equipment notes</div>
        <div className="text-coolGray-100">
          <p>{MJF_EQUIPMENT_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    material: (
      <>
        <div className="mb-2 text-coolGray-300">Material notes</div>
        <div className="text-coolGray-100">
          <p>{MJF_MATERIAL_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    yield: (
      <>
        <div className="mb-2 text-coolGray-300">Yield notes</div>
        <div className="text-coolGray-100">
          <p>{ALL_YIELD_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
  },
  DLS: {
    engineering: (
      <>
        <div className="mb-2 text-coolGray-300">Engineering notes</div>
        <div className="text-coolGray-100">
          <p>{DLS_FDM_SLA_ENGINEERING_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    labor: (
      <>
        <div className="mb-2 text-coolGray-300">Labor notes</div>
        <div className="text-coolGray-100">
          <p>{DLS_FDM_SLA_LABOR_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    equipment: (
      <>
        <div className="mb-2 text-coolGray-300">Equipment notes</div>
        <div className="text-coolGray-100">
          <p>{DLS_FDM_SLA_EQUIPMENT_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    material: (
      <>
        <div className="mb-2 text-coolGray-300">Material notes</div>
        <div className="text-coolGray-100">
          <p>{DLS_FDM_SLA_MATERIAL_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    yield: (
      <>
        <div className="mb-2 text-coolGray-300">Yield notes</div>
        <div className="text-coolGray-100">
          <p>{ALL_YIELD_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
  },
  FDM: {
    engineering: (
      <>
        <div className="mb-2 text-coolGray-300">Engineering notes</div>
        <div className="text-coolGray-100">
          <p>{DLS_FDM_SLA_ENGINEERING_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    labor: (
      <>
        <div className="mb-2 text-coolGray-300">Labor notes</div>
        <div className="text-coolGray-100">
          <p>{DLS_FDM_SLA_LABOR_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    equipment: (
      <>
        <div className="mb-2 text-coolGray-300">Equipment notes</div>
        <div className="text-coolGray-100">
          <p>{DLS_FDM_SLA_EQUIPMENT_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    material: (
      <>
        <div className="mb-2 text-coolGray-300">Material notes</div>
        <div className="text-coolGray-100">
          <p>{DLS_FDM_SLA_MATERIAL_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    yield: (
      <>
        <div className="mb-2 text-coolGray-300">Yield notes</div>
        <div className="text-coolGray-100">
          <p>{ALL_YIELD_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
  },
  SLA: {
    engineering: (
      <>
        <div className="mb-2 text-coolGray-300">Engineering notes</div>
        <div className="text-coolGray-100">
          <p>{DLS_FDM_SLA_ENGINEERING_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    labor: (
      <>
        <div className="mb-2 text-coolGray-300">Labor notes</div>
        <div className="text-coolGray-100">
          <p>{DLS_FDM_SLA_LABOR_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    equipment: (
      <>
        <div className="mb-2 text-coolGray-300">Equipment notes</div>
        <div className="text-coolGray-100">
          <p>{DLS_FDM_SLA_EQUIPMENT_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    material: (
      <>
        <div className="mb-2 text-coolGray-300">Material notes</div>
        <div className="text-coolGray-100">
          <p>{DLS_FDM_SLA_MATERIAL_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
    yield: (
      <>
        <div className="mb-2 text-coolGray-300">Yield notes</div>
        <div className="text-coolGray-100">
          <p>{ALL_YIELD_COST_DRIVER_COPY}</p>
        </div>
      </>
    ),
  },
};
