import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const OrdersContext = React.createContext();

const OrdersProvider = ({ children, currentSearchParam, currentSortParam, isSortDesc }) => {
  const value = useMemo(
    () => ({
      currentSearchParam,
      currentSortParam,
      isSortDesc,
    }),
    [currentSearchParam, currentSortParam, isSortDesc]
  );

  return <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>;
};

OrdersProvider.propTypes = {
  children: PropTypes.node.isRequired,
  currentSearchParam: PropTypes.string,
  currentSortParam: PropTypes.string,
  isSortDesc: PropTypes.bool,
};

export { OrdersContext, OrdersProvider };
