import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { api } from 'fr-shared/api';
import { useUserAnalyticsContext } from 'fr-shared/context';
import { getMarketoCookies } from 'fr-shared/lib/marketo';

import { Button, Input, Modal } from 'portal/components';

const RequestQuote = ({ action, onActionHandler, onSave }) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [notes, setNotes] = useState('');
  const userAnalytics = useUserAnalyticsContext();

  const handleGoToDash = () =>
    userAnalytics.track('Request Quote - Back to Dash', { valid: true });
  const handleRequestQuote = () => {
    const logEvt = quoteSuccess =>
      userAnalytics.track('Request Quote - Confirm', { quoteSuccess });

    setLoading(true);
    api
      .post('/customer_portal/submit_cart', {
        manual_notes: notes,
        manual_quote: true,
        marketo_attrs: getMarketoCookies(),
      })
      .then(() => {
        logEvt(true);
        return setSubmitted(true);
      })
      .catch(() => {
        logEvt(false);
        return setSubmitted(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      dataTestId="manual-quote-submit-modal"
      action={action}
      backdrop="static"
      onActionHandler={onActionHandler}
    >
      {({ toggle }) => (
        <>
          <Modal.Header
            title={submitted ? 'Thank you for your request' : 'Ready to request this quote?'}
            onClose={toggle}
          />
          {submitted ? (
            <RequestSuccess />
          ) : (
            <RequestForm
              onChange={e => setNotes(e.target?.value)}
              onBlur={() => {
                const trimNotes = notes.trim();
                if (trimNotes)
                  userAnalytics.track('Request Quote - Notes', { inputVal: trimNotes });
              }}
            />
          )}
          <Modal.Footer>
            {submitted ? (
              <Button
                to="/"
                className="px-4"
                onClick={handleGoToDash}
                dataTestId="modal-request-quote-go-to-dashboard"
              >
                Go to Dashboard
              </Button>
            ) : (
              <>
                <Button outline onClick={toggle}>
                  Cancel
                </Button>
                <Button
                  className="ml-2"
                  dataTestId="request-quote-modal-request-quote-button"
                  loading={loading}
                  onClick={onSave || handleRequestQuote}
                >
                  Request Quote
                </Button>
              </>
            )}
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

RequestQuote.propTypes = {
  action: PropTypes.node,
  onActionHandler: PropTypes.func,
  onSave: PropTypes.func,
};

const RequestSuccess = () => {
  return (
    <Modal.Body>
      <p>A representative will reach out to you within 1 business day.</p>
    </Modal.Body>
  );
};

const RequestForm = ({ onChange, onBlur }) => {
  return (
    <Modal.Body className="font-size-base">
      <div className="mb-4">
        <p>
          One of our experts will check every item in your request and come back to you ASAP with
          a detailed quote.
        </p>
      </div>
      <div>
        <p>Add any notes for us here:</p>
      </div>
      <Input className="mb-0" name="manual_notes" onChange={onChange} onBlur={onBlur} />
    </Modal.Body>
  );
};

RequestForm.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default RequestQuote;
