import { PART_FAILURE_DISPOSITIONS } from 'fr-shared/lib/part_failures';

export const DISPOSITION_TABS = {
  REVIEW: 'review',
  REMAKE: 'remake',
  REWORK: 'rework',
  AWAITING_CUSTOMER: 'awaiting_customer',
  IN_QC_INVENTORY: 'in_qc_inventory',
  SEARCH: 'search',
  RESOLVED: 'resolved',
};

export const convertDispositionTabToFilters = disposition => {
  switch (disposition) {
    case DISPOSITION_TABS.REMAKE:
      return {
        has_spawned_line_item: false,
        disposition: 'Remake',
      };
    case DISPOSITION_TABS.REWORK:
      return {
        has_spawned_line_item: false,
        disposition: 'Rework',
      };
    case DISPOSITION_TABS.AWAITING_CUSTOMER:
      return {
        has_spawned_line_item: false,
        disposition: 'Awaiting Customer',
      };
    case DISPOSITION_TABS.IN_QC_INVENTORY:
      return { is_in_qa_inventory: true };
    case DISPOSITION_TABS.RESOLVED:
      return { is_resolved: true };
    case DISPOSITION_TABS.SEARCH:
      return {};
    case DISPOSITION_TABS.REVIEW:
      return { disposition: '' };
  }
};

export const convertDispositionToExplanation = disposition => {
  switch (disposition) {
    case PART_FAILURE_DISPOSITIONS.AwaitingCustomer:
      return 'Customer input is needed to make a dispositioning decision.';
    case PART_FAILURE_DISPOSITIONS.UseAsIs:
      return 'Ready for shipment.';
    case PART_FAILURE_DISPOSITIONS.Remake:
      return 'Reprinting is needed. Failed parts can be sent to the customer or scrapped.';
    case PART_FAILURE_DISPOSITIONS.Rework:
      return 'Additional post-processing (sanding, dyeing, etc.) is needed. Re-printing is not required.';
    case PART_FAILURE_DISPOSITIONS.Scrap:
      return 'Parts should be destroyed in the Granulator. No further printing/processing should occur.';
  }
};

export const tabDefinitions = disposition_tab => {
  const baseTabs = [
    { to: '/admin/qc/review', name: 'Parts To Review' },
    { to: '/admin/qc/rework', name: 'Rework' },
    { to: '/admin/qc/remake', name: 'Remake' },
    { to: '/admin/qc/awaiting_customer', name: 'Awaiting Customer' },
    { to: '/admin/qc/in_qc_inventory', name: 'In QC Inventory' },
  ];
  return disposition_tab === DISPOSITION_TABS.SEARCH
    ? [...baseTabs, { to: '/admin/qc/search', name: 'Search Results' }]
    : baseTabs;
};

export const endTabDefinitions = () => [{ to: '/admin/qc/resolved', name: 'Resolved' }];
