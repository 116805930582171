import { groupBy } from 'lodash';
import React, { useState } from 'react';

import { CHECKABLE_MFG_PROCESSES } from 'fr-shared/lib/manufacturability_checks';
import {
  PROCESS_NAMES,
  getManufacturingProcessImageUrl,
} from 'fr-shared/lib/manufacturing_process';

import { CardWrapper } from 'portal/components';

import FullScreenOptionCard, {
  FullScreenOption,
} from '../../evaluate/components/FullScreenOptionCard/FullScreenOptionCard';
import {
  FullScreenModal,
  SelectedOptionStateContext,
} from '../../evaluate/components/FullScreenSelector/FullScreenSelector';
import { PROCESS_DESCRIPTIONS } from '../../evaluate/copy';

interface Props {
  handleSubmit: (mp: ManufacturingProcess) => void;
  handleCancel: () => void;
  manufacturingProcesses: ManufacturingProcess[];
}

const ManufacturingProcessSpecificationField = ({
  handleCancel,
  handleSubmit,
  manufacturingProcesses,
}: Props) => {
  const [modalState, setModalState] = useState(true);

  const groupedProcesses = groupBy(manufacturingProcesses, (mp: ManufacturingProcess) =>
    mp.additive ? 'additive' : 'traditional'
  );

  const manufacturingProcessOnChange = (selectedProcess: FullScreenOption) => {
    handleSubmit(selectedProcess);
  };

  return (
    <FullScreenModal
      isModalOpen={modalState}
      modalHeader={'Manufacturing process'}
      modalSubHeader={'Select a manufacturing process for your part.'}
      setIsModalOpen={(isModalOpen: boolean) => {
        !isModalOpen ? setModalState(false) : setModalState(true);
      }}
      onCancel={() => {
        handleCancel();
      }}
      onSubmit={manufacturingProcessOnChange}
    >
      {({ onSubmit, selectedOption }: SelectedOptionStateContext) => (
        <div className="flex flex-1 flex-column">
          {Object.keys(groupedProcesses)
            .reverse()
            .map((group: string) => {
              // render each group of Manufacturing Processes
              const processes: ManufacturingProcess[] = groupedProcesses[group];
              return (
                <div className="mb-4" key={group}>
                  <h5 className="mb-4">
                    {group === 'additive' ? '3D Printing' : 'Traditional Manufacturing'}
                  </h5>
                  <CardWrapper>
                    {processes.map((option: ManufacturingProcess) => (
                      <FullScreenOptionCard
                        key={`${option.name}${option.id}`}
                        onSubmit={onSubmit}
                        name={PROCESS_NAMES[option.name]}
                        description={PROCESS_DESCRIPTIONS[option.name]}
                        imageUrl={getManufacturingProcessImageUrl(option.name)}
                        option={option}
                        selectedOption={selectedOption}
                      >
                        {CHECKABLE_MFG_PROCESSES.includes(option.name) && (
                          <div className="border-top mt-auto pt-2">
                            <span className="font-size-sm text-tertiary-500">
                              Automated DFM checks available
                            </span>
                          </div>
                        )}
                      </FullScreenOptionCard>
                    ))}
                  </CardWrapper>
                </div>
              );
            })}
        </div>
      )}
    </FullScreenModal>
  );
};

export default ManufacturingProcessSpecificationField;
