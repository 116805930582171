import React, { SyntheticEvent, useContext, useEffect } from 'react';

import { FormFieldBase, Input, Select } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import useTolerances from 'fr-shared/hooks/useTolerances';
import { numberInputFilter, numberInputMinValueFilter } from 'fr-shared/utils/input';

import { trackInputFunctionType } from 'portal/hooks/useScopedAnalytics';
import { mapById } from 'portal/lib/util';

interface Props {
  className?: string;
  formState: Partial<ToleranceState>;
  onChange: (changes: Partial<ToleranceState>) => void;
  trackInput: trackInputFunctionType;
}

export type ToleranceState = {
  tolerance: Partial<Tolerance>;
  cnc_tolerance_count_0005: number;
  cnc_tolerance_count_001: number;
  cnc_tolerance_count_002: number;
  cnc_tolerance_count_003: number;
};

// These name values have to match the form state key exactly in order for hidden fields to render properly
export const FIELD_NAMES = {
  ToleranceCount0005: 'cnc_tolerance_count_0005',
  ToleranceCount001: 'cnc_tolerance_count_001',
  ToleranceCount002: 'cnc_tolerance_count_002',
  ToleranceCount003: 'cnc_tolerance_count_003',
} as const;
type ToleranceFieldName = typeof FIELD_NAMES[keyof typeof FIELD_NAMES];

// Maps a tolerance value to its respective comparison order. For example 0005 > Less than 0005
const TOLERANCE_VALUE_ORDER: Record<string, number> = {
  'Less than ±0.0005': 0,
  '±0.0005': 1,
  '±0.001': 2,
  '±0.002': 3,
  '±0.003': 4,
  '±0.004': 5,
  '±0.005': 6,
};

export const DEFAULT_TOLERANCE_VALUE = 'ISO 2768 m/k';

const shouldShow = (fieldTolerance: string, selectedTolerance: Tolerance) => {
  // If the field's tolerance is greater than or equal to the selected tolerance, show the field
  // Example: Select 0.002, then show 0.002 and 0.003
  return compareToleranceValue(fieldTolerance, selectedTolerance?.value) >= 0;
};

const compareToleranceValue = (aValue: string, bValue: string) => {
  if (aValue === DEFAULT_TOLERANCE_VALUE) {
    // if a is default value then a (default) > b, so as to make default option the last option
    return 1;
  } else if (bValue === DEFAULT_TOLERANCE_VALUE) {
    // if b is default value then a < b (default), so as to make default option the last option
    return -1;
  } else {
    // pull the sort order from our map of values and sort ascending where possible
    const aOrder = TOLERANCE_VALUE_ORDER[aValue];
    const bOrder = TOLERANCE_VALUE_ORDER[bValue];
    if (aOrder != null && bOrder != null) {
      return aOrder - bOrder;
    }
    // else fallback to ascending lexicographic order
    return aValue?.localeCompare(bValue);
  }
};

export const ToleranceFields = ({ formState, onChange, trackInput }: Props) => {
  const { data: tolerances, error: tolerancesApiCallError } = useTolerances();
  const { setAlert } = useContext(AlertContext);
  const toleranceMap = mapById(tolerances);
  // sort descending
  const toleranceOptions = tolerances
    ?.map(t => ({ id: t.id, name: t.value }))
    ?.sort((a, b) => compareToleranceValue(a.name, b.name))
    ?.reverse();
  const currentTolerance = toleranceMap[formState?.tolerance?.id];

  // Default the tolerance if component is shown and the tolerance form state is not valid
  useEffect(() => {
    if (!currentTolerance && tolerances?.length) {
      const tolerance = tolerances.find(t => t.value === DEFAULT_TOLERANCE_VALUE);
      if (tolerance) {
        onChange({ tolerance: tolerance });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTolerance, tolerances]);

  useEffect(() => {
    if (tolerancesApiCallError)
      setAlert({
        message: 'It looks like something went wrong. Please refresh this page to continue.',
        color: 'danger',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tolerancesApiCallError]);

  /**
   * Renders a dependent / hideable tolerance count field
   * @param fieldName the field name that must match the form state property name in order for the controlled inputs & output to work
   * @param toleranceValue the `value` column in the tolerances table that this field corresponds to, for the purposes of order comparison
   */
  const renderToleranceCountField = (fieldName: ToleranceFieldName, toleranceValue: string) => {
    return (
      <HiddenToleranceField
        minValue={currentTolerance?.value === toleranceValue ? 1 : 0}
        show={shouldShow(toleranceValue, currentTolerance)}
        fieldName={fieldName}
        label={`Number of features with ${toleranceValue}`}
        state={formState && formState[fieldName]}
        onBlur={(event: SyntheticEvent<HTMLInputElement>) => {
          trackInput(
            `Tolerance features with ${toleranceValue}`,
            numberInputFilter(event.currentTarget.valueAsNumber)
          );
        }}
        onChange={value => {
          onChange({ [fieldName]: value });
        }}
        dataTestId={`tolerance-count-${toleranceValue}`}
      />
    );
  };

  return (
    <>
      <FormFieldBase
        name={'tolerance'}
        label="Tightest tolerance"
        isControl={true}
        tooltip="We follow the dimensional tolerances in the ISO 2768 standard. If any dimensions of your part are tighter than these, please select them here."
      >
        <Select
          name={'tolerance'}
          className="form-group is-floating"
          selectClassName="form-control"
          sorted={false}
          hasBlankOption={false}
          value={formState?.tolerance?.id?.toString()}
          optionList={toleranceOptions}
          onBlur={(event: SyntheticEvent<HTMLSelectElement>) => {
            if (!tolerances) return;

            const selectedTolerance = tolerances.find(
              tolerance => tolerance?.id === +event.currentTarget.value
            );

            trackInput('Tolerances', selectedTolerance.value);
          }}
          onChange={(event: SyntheticEvent<HTMLSelectElement>) => {
            const tolerance = tolerances?.find(t => t.id === +event.currentTarget.value);
            if (tolerance) {
              onChange({ tolerance: tolerance });
            }
          }}
          dataTestId="tolerance-selector"
        />
      </FormFieldBase>

      {renderToleranceCountField(FIELD_NAMES.ToleranceCount003, '±0.003')}
      {renderToleranceCountField(FIELD_NAMES.ToleranceCount002, '±0.002')}
      {renderToleranceCountField(FIELD_NAMES.ToleranceCount001, '±0.001')}
      {renderToleranceCountField(FIELD_NAMES.ToleranceCount0005, '±0.0005')}
    </>
  );
};

interface HiddenProps {
  minValue: number;
  show: boolean;
  fieldName: string;
  label: string;
  state: number;
  dataTestId: string;
  onBlur: (event: SyntheticEvent<HTMLInputElement>) => void;
  onChange: (value: number) => void;
}

const HiddenToleranceField = ({
  minValue,
  show,
  fieldName,
  label,
  state,
  dataTestId,
  onBlur,
  onChange,
}: HiddenProps) => {
  // If hidden, set the tolerance value to null, else default it as necessary
  useEffect(() => {
    if (!show) {
      onChange(null);
    } else if (state == null || state < minValue) {
      onChange(minValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, minValue]);

  if (!show) {
    return null;
  }
  return (
    <FormFieldBase
      name={fieldName}
      label={label}
      isControl={true}
      tooltip="Please tell us how many times this tight dimensional tolerance appears on your part."
    >
      <Input
        name={fieldName}
        value={state}
        type="number"
        size="md"
        onBlur={(event: SyntheticEvent<HTMLInputElement>) => {
          if (isNaN(event.currentTarget.valueAsNumber)) onChange(minValue);
          onBlur(event);
        }}
        onChange={(event: SyntheticEvent<HTMLInputElement>) => {
          const value = numberInputMinValueFilter(event.currentTarget.valueAsNumber, minValue);
          onChange(value);
        }}
        min={minValue}
        data-testid={dataTestId}
      />
    </FormFieldBase>
  );
};

export default ToleranceFields;
