import PropTypes from 'prop-types';
import React, { memo, useContext } from 'react';

import { TableBody, TableCustom, TableHead, TableHeadCell } from 'portal/components';
import { PartsContext } from 'portal/contexts/PartsContext';

import ListViewRow from './ListViewRow';
import PartSelector from './PartSelector/PartSelector';

const ListView = ({ parts, onSort }) => {
  const { areAllPartsSelected, currentSortParam, isSortDesc, handleSelectAllParts } =
    useContext(PartsContext);

  return (
    <TableCustom>
      <TableHead>
        <TableHeadCell width="6%">
          <PartSelector
            isSelected={areAllPartsSelected}
            handleSelectClick={handleSelectAllParts}
          />
        </TableHeadCell>
        <TableHeadCell
          onClick={onSort}
          isSortDesc={isSortDesc}
          sorted={currentSortParam === 'name'}
          value="Part name"
        />
        <TableHeadCell
          onClick={onSort}
          isSortDesc={isSortDesc}
          sorted={currentSortParam === 'inserted_at'}
          value="Date added"
        />
        <TableHeadCell
          onClick={onSort}
          isSortDesc={isSortDesc}
          sorted={currentSortParam === 'updated_at'}
          value="Last edited"
        />
        <TableHeadCell value="Part size" />
        <TableHeadCell value="Supporting docs" />
      </TableHead>
      <TableBody>
        {parts.map(part => (
          <ListViewRow key={part.id} part={part} />
        ))}
      </TableBody>
    </TableCustom>
  );
};

ListView.propTypes = {
  parts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSort: PropTypes.func,
};

export default memo(ListView);
