import '@fast-radius/shared-ui';
import { capitalize } from 'lodash';
import React from 'react';

import { Button as FrButton, classNames } from 'fr-shared/components';
import Flag from 'fr-shared/components/common/flags/index';

import { Money } from 'portal/components';
import { Autoquote, AutoquoteTier, BulkConfigAutoquoteTier } from 'portal/lib/autoquote';

interface TierPriceInfoContainerProps {
  autoquoteTier: AutoquoteTier | BulkConfigAutoquoteTier;
  index?: number;
  selectedLocationId?: number;
  hasSiblingTiers?: boolean;
  onSelected?: () => void;
  hasBorder?: boolean;
}

interface TierRowInfoProps {
  label: string;
  labelData: any;
  dataTestId?: string;
}

export const TierRowInfo = ({ label, labelData, dataTestId }: TierRowInfoProps) => {
  return (
    <div className="flex flex-row justify-between items-end leading-5 print:w-[48%]">
      <div className="text-xs text-coolGray-300">{label}</div>
      <span className="text-coolGray-100" data-testid={dataTestId}>
        {labelData}
      </span>
    </div>
  );
};

interface FlagWithTooltipProps {
  region: string;
}
const FlagWithTooltip = ({ region }: FlagWithTooltipProps) => {
  return (
    <span
      className="cursor-default text-white underline text-sm"
      data-rh={`Made in the ${region}`}
      data-rh-at="top"
      data-rh-atAlign="far"
    >
      <Flag region={region} />
    </span>
  );
};

export interface TierPriceInfoProps {
  autoquoteTier: Autoquote | AutoquoteTier | BulkConfigAutoquoteTier;
  setLabel?: boolean;
}

export const TierPriceInfo = ({ autoquoteTier, setLabel = true }: TierPriceInfoProps) => {
  if (!autoquoteTier) {
    return null;
  }
  const { production_region: region } = autoquoteTier;

  return (
    <>
      {setLabel && (
        <div className="text-left text-coolGray-100 text-base mb-1">
          {autoquoteTier?.label || 'Standard'}
        </div>
      )}
      <div className="flex flex-col gap-0.5 print:gap-0 print:flex-row print:flex-wrap print:justify-between print:mr-6">
        {autoquoteTier?.lead_time && (
          <TierRowInfo
            label={'Lead time'}
            labelData={`${autoquoteTier.lead_time} business day${
              autoquoteTier.lead_time > 1 ? `s` : ''
            }`}
          />
        )}
        {region && (
          <TierRowInfo
            label={'Production region'}
            labelData={
              region === 'INTERNATIONAL' ? (
                capitalize(region)
              ) : (
                <FlagWithTooltip region={region} />
              )
            }
          />
        )}

        {autoquoteTier?.unit_price && (
          <TierRowInfo
            label={'Unit price'}
            labelData={
              <Money
                amount={autoquoteTier.unit_price?.amount}
                className="text-sm text-coolGray-100"
                unstyledSymbol
              />
            }
          />
        )}

        <TierRowInfo
          label={'Item total'}
          labelData={
            <Money
              amount={autoquoteTier.total_price?.amount}
              className="text-base text-coolGray-100 font-semibold"
              unstyledSymbol
            />
          }
          dataTestId={'part-config-item-total'}
        />
      </div>
    </>
  );
};

export const TierPriceInfoContainer = ({
  onSelected,
  selectedLocationId,
  autoquoteTier,
  hasSiblingTiers = true,
}: TierPriceInfoContainerProps) => {
  return (
    <FrButton
      color="none"
      className={classNames([
        'min-w-[238px] p-2 border-2 border-solid rounded-[8px] min-h-[140px] mr-3',
        selectedLocationId === autoquoteTier.location_id ||
        !selectedLocationId ||
        !hasSiblingTiers
          ? 'border-coolGray-100 cursor-default'
          : 'border-coolGray-600',
      ])}
      onClick={() => onSelected()}
    >
      <TierPriceInfo autoquoteTier={autoquoteTier} />
    </FrButton>
  );
};
