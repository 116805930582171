import { pickBy } from 'lodash';

import { api } from 'fr-shared/api';
import { COSTING_REQUEST_STATES } from 'fr-shared/lib/costing_requests';

export const INITIAL_CLOSED_FILTERS = { page: 1, sort_key: 'closed_at', sort_desc: true };
export const INITIAL_COMPLETED_FILTERS = {
  page: 1,
  sort_key: 'latest_state_change_at',
  sort_desc: true,
};
export const INITIAL_DRAFT_FILTERS = { page: 1, sort_key: 'inserted_at', sort_desc: false };
export const INITIAL_REQUESTED_FILTERS = { page: 1, sort_key: 'requested_at', sort_desc: false };
export const INITIAL_IN_PROGRESS_FILTERS = {
  page: 1,
  sort_key: 'requested_at',
  sort_desc: false,
};

export const INITIAL_CLOSED_SORT = [{ id: 'closed_at', desc: true }];
export const INITIAL_COMPLETED_SORT = [{ id: 'latest_state_change_at', desc: true }];
export const INITIAL_DRAFT_SORT = [{ id: 'inserted_at', desc: false }];
export const INITIAL_REQUESTED_SORT = [{ id: 'requested_at', desc: false }];
export const INITIAL_IN_PROGRESS_SORT = [{ id: 'requested_at', desc: false }];

export const SALES_COMPLETED_STATES = [
  COSTING_REQUEST_STATES.Completed,
  COSTING_REQUEST_STATES.Rejected,
];
export const OPS_COMPLETED_STATES = [
  COSTING_REQUEST_STATES.Closed,
  COSTING_REQUEST_STATES.Completed,
  COSTING_REQUEST_STATES.Rejected,
];

export enum FILTER_FIELDS {
  SalesContactId = 'sales_contact_id',
  ManufacturingProcessId = 'manufacturing_process_id',
  State = 'state',
  EngineeringContactId = 'engineering_contact_id',
  SupportContactId = 'support_contact_id',
}

export enum TABLE_COLUMNS {
  Completed = 'completed',
  CustomerId = 'customer_id',
  DateClosed = 'closed_at',
  DateCreated = 'inserted_at',
  DateRequested = 'requested_at',
  DateSubmitted = 'requested_at_submitted',
  Id = 'id',
  Items = 'items',
  LatestStateChangeAt = 'latest_state_change_at',
  ManufacturingProcessId = 'manufacturing_process_id',
  OpportunityId = 'opportunity_id',
  PublicId = 'public_id',
  State = 'state',
  TimeInDraftQueue = 'inserted_at_time_in_draft_queue',
  TimeInRequestedQueue = 'requested_at_time_in_requested_queue',
  // currently, we will show the time since the CR was requested in the In Progress Queue
  // since determining the time since the CR was requested will require more work,
  // leveraging the line item state events instead of an in_progress_at timestamp
  TimeInInProgressQueue = 'requested_at_time_in_in_progress_queue',
}

export const CLOSED_COLUMNS = [
  TABLE_COLUMNS.Id,
  TABLE_COLUMNS.PublicId,
  TABLE_COLUMNS.CustomerId,
  TABLE_COLUMNS.State,
  TABLE_COLUMNS.ManufacturingProcessId,
  TABLE_COLUMNS.DateCreated,
  TABLE_COLUMNS.DateClosed,
];

export const COMPLETED_COLUMNS = [
  TABLE_COLUMNS.Id,
  TABLE_COLUMNS.PublicId,
  TABLE_COLUMNS.CustomerId,
  TABLE_COLUMNS.State,
  TABLE_COLUMNS.ManufacturingProcessId,
  TABLE_COLUMNS.DateRequested,
  TABLE_COLUMNS.LatestStateChangeAt,
];

export const DRAFT_COLUMNS = [
  TABLE_COLUMNS.Id,
  TABLE_COLUMNS.PublicId,
  TABLE_COLUMNS.CustomerId,
  TABLE_COLUMNS.State,
  TABLE_COLUMNS.ManufacturingProcessId,
  TABLE_COLUMNS.DateCreated,
  TABLE_COLUMNS.TimeInDraftQueue,
];

export const REQUESTED_COLUMNS = [
  TABLE_COLUMNS.Id,
  TABLE_COLUMNS.PublicId,
  TABLE_COLUMNS.CustomerId,
  TABLE_COLUMNS.State,
  TABLE_COLUMNS.ManufacturingProcessId,
  TABLE_COLUMNS.DateRequested,
  TABLE_COLUMNS.TimeInRequestedQueue,
];

export const IN_PROGRESS_COLUMNS = [
  TABLE_COLUMNS.Id,
  TABLE_COLUMNS.PublicId,
  TABLE_COLUMNS.CustomerId,
  TABLE_COLUMNS.State,
  TABLE_COLUMNS.ManufacturingProcessId,
  TABLE_COLUMNS.DateRequested,
  TABLE_COLUMNS.TimeInInProgressQueue,
];

export const getRequestedCostingRequests = (filters: object) =>
  api.get('/costing_requests', {
    params: {
      state: COSTING_REQUEST_STATES.Requested,
      ...pickBy(filters),
    },
  });

export const getCompletedCostingRequestsForOps = (filters: object) =>
  api.get('/costing_requests', {
    params: {
      state: OPS_COMPLETED_STATES,
      ...pickBy(filters),
    },
  });

export const getCompletedCostingRequestsForSales = (filters: object) =>
  api.get('/costing_requests', {
    params: {
      state: SALES_COMPLETED_STATES,
      ...pickBy(filters),
    },
  });

export const getDraftCostingRequest = (filters: object) =>
  api.get('/costing_requests', {
    params: {
      state: COSTING_REQUEST_STATES.Draft,
      ...pickBy(filters),
    },
  });

export const getClosedCostingRequests = (filters: object) =>
  api.get('/costing_requests', {
    params: {
      state: COSTING_REQUEST_STATES.Closed,
      ...pickBy(filters),
    },
  });

export const getInProgressCostingRequests = (filters: object) =>
  api.get(`/costing_requests`, {
    params: {
      state: COSTING_REQUEST_STATES.InProgress,
      ...pickBy(filters),
    },
  });
