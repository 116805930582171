/* eslint-disable react/display-name,react/prop-types */
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { Link, Table } from 'fr-shared/components';
import { API_DOMAIN, API_PROTOCOL_SUFFIX } from 'fr-shared/env';

const csv_url = costing_csv_import_id =>
  `http${API_PROTOCOL_SUFFIX}://${API_DOMAIN}/api/v1/costing_csv_imports/${costing_csv_import_id}`;

const CostingCSVImportsTable = ({ data, pages, loading, sort, onFetchData }) => {
  return (
    <Table
      manual
      data={data}
      pages={pages}
      pageSize={data.length || 20}
      showPagination={true}
      loading={loading}
      className="bg-white -striped mb-5"
      onFetchData={onFetchData}
      noDataText="No imports found"
      defaultSorted={sort}
      columns={[
        { Header: 'Record', accessor: 'id', width: 100 },
        { Header: 'File Name', accessor: 'file_name', width: 200 },
        { Header: "# of CR's updated", accessor: 'updated_costing_request_count', width: 160 },
        { Header: 'Status', accessor: 'state', width: 160 },
        {
          Header: 'Date Imported',
          id: 'inserted_at',
          accessor: d => moment(d.inserted_at).format('MM/DD/YYYY'),
          width: 160,
        },
        {
          Header: 'Download',
          Cell: row => (
            <Link to={{ pathname: csv_url(row.original.id) }} openNewWindow>
              Link
            </Link>
          ),
          width: 160,
        },
      ]}
    />
  );
};

CostingCSVImportsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      file_name: PropTypes.string.isRequired,
      file_url: PropTypes.string.isRequired,
      updated_costing_request_count: PropTypes.number.isRequired,
      state: PropTypes.string.isRequired,
      inserted_at: PropTypes.string.isRequired,
    })
  ),
  pages: PropTypes.number,
  loading: PropTypes.bool,
  sort: PropTypes.array,
  onFetchData: PropTypes.func,
};

export default CostingCSVImportsTable;
