import moment from 'moment';
import React from 'react';

import { ContactAddresses } from './ContactAddresses';
import { ContactPaymentInformation } from './ContactPaymentInformation';
import { ContactSubscriptionPlan } from './ContactSubscriptionPlan';

interface Props {
  customerContact: User;
}

const dateFormat = (date: string) => (date ? moment(date).format('MM/DD/YY, h:mm A') : 'N/A');

const ContactSummary = ({ customerContact }: Props) => {
  if (!customerContact) return null;

  const stats: Record<string, any> = {
    ['Portal user']: customerContact.portal_user ? 'Yes' : 'No',
    ['Last login']: dateFormat(customerContact.last_log_in),
    ['# of logins']: customerContact.log_in_events_count,
    ['Email verified']: customerContact.verified ? 'Yes' : 'No',
    ['Created at']: dateFormat(customerContact.inserted_at),
    ['Updated at']: dateFormat(customerContact.updated_at),
    ['Strategy']: customerContact.strategy,
    ['Marketing role']: customerContact.marketing_role || 'N/A',
  };

  return (
    <div>
      <h3 className="mb-3">Summary</h3>

      <div className="bg-white rounded-sm p-3 mb-5">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-4 mb-0">
          {Object.keys(stats).map(label => (
            <div key={label} className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-400 mb-0">{label}</dt>
              <dd className="text-sm text-gray-900 mb-0">{stats[label]}</dd>
            </div>
          ))}
        </dl>
      </div>

      <div className="mb-5">
        <ContactSubscriptionPlan />
      </div>

      <div className="mb-5">
        <ContactAddresses />
      </div>

      <div className="mb-5">
        <ContactPaymentInformation customerContact={customerContact} />
      </div>
    </div>
  );
};

export default ContactSummary;
