import { useTreatments } from '@splitsoftware/splitio-react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { CR_BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import {
  COSTING_REQUEST_LI_STATES,
  COSTING_REQUEST_STATE_COLORS,
} from 'fr-shared/lib/costing_requests';

import OrderLineItem from '../../orders/components/OrderLineItem';
import { somePartQuantitiesSelected } from '../utils/selected';
import CostingLineItemActions from './CostingLineItemActions';
import CostingLineItemFields from './CostingLineItemFields';
import CostingLineItemUnitCost from './CostingLineItemUnitCost';

const CostingFormLineItem = ({
  arrayHelpers,
  changePosition,
  children,
  customBorderColor,
  fieldPrefix,
  handleLineItemSelect,
  handleUpdateLineItem,
  isCRSubmitted,
  isCustomerPortalAutoquote,
  isDuplicating,
  lineItem,
  lineItemIndex,
  onDuplicateLineItem,
  updateCostingRequestLineItem,
}) => {
  const isSelectable = [
    COSTING_REQUEST_LI_STATES.Completed,
    COSTING_REQUEST_LI_STATES.Draft,
    COSTING_REQUEST_LI_STATES.InProgress,
    COSTING_REQUEST_LI_STATES.Requested,
  ].includes(lineItem.state);
  const borderTopColor = customBorderColor || COSTING_REQUEST_STATE_COLORS[lineItem.state];
  const { [CR_BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );

  return (
    <div
      className="mb-1"
      id={lineItem.id}
      data-testid="costing-request-page--costing-form-line-item"
      style={{ scrollMargin: 120 }}
    >
      <OrderLineItem
        arrayHelpers={arrayHelpers}
        changePosition={changePosition}
        className={`border-top-${borderTopColor}`}
        duplicatedLineItem={{
          id: null,
          isOpen: true,
          state: 'Draft',
          supplier_costs: lineItem.supplier_costs?.map(({ part_quantities }) => ({
            part_quantities: part_quantities?.map(({ per_unit_cost, quantity }) => ({
              per_unit_cost,
              quantity,
            })),
          })),
          uuid: null,
        }}
        buildPackUIEnabled={true}
        fieldPrefix={fieldPrefix}
        indeterminate={somePartQuantitiesSelected(lineItem)}
        index={lineItemIndex}
        isDropzoneEnabled={false}
        isSelectable={isSelectable}
        lineItem={lineItem}
        onSelect={handleLineItemSelect}
        readonly={
          isCustomerPortalAutoquote ||
          (buildPackUiSupportFlag.treatment === 'on' &&
            lineItem.build_package.working_status === 'committed')
        }
        showDelete={!isCRSubmitted}
        showDuplicate={!isCRSubmitted}
        showPreview={true}
        showStateSelect={false}
        showSummary={true}
      >
        <>
          <CostingLineItemFields
            fieldPrefix={fieldPrefix}
            isCustomerPortalAutoquote={isCustomerPortalAutoquote}
            isDuplicating={isDuplicating}
            lineItem={lineItem}
            onSaveLineItem={handleUpdateLineItem}
          />
          {children}
          {isCRSubmitted && (
            <>
              <CostingLineItemUnitCost
                fieldPrefix={fieldPrefix}
                isCustomerPortalAutoquote={isCustomerPortalAutoquote}
                lineItem={lineItem}
                onSaveLineItem={updateCostingRequestLineItem}
              />
              <CostingLineItemActions
                fieldPrefix={fieldPrefix}
                isCustomerPortalAutoquote={isCustomerPortalAutoquote}
                lineItem={lineItem}
                onSaveLineItem={handleUpdateLineItem}
                onDuplicateLineItem={onDuplicateLineItem}
              />
            </>
          )}
        </>
      </OrderLineItem>
    </div>
  );
};

CostingFormLineItem.propTypes = {
  arrayHelpers: PropTypes.object,
  changePosition: PropTypes.func,
  children: PropTypes.node,
  customBorderColor: PropTypes.string,
  fieldPrefix: PropTypes.string,
  handleLineItemSelect: PropTypes.func,
  handleUpdateLineItem: PropTypes.func,
  isCRSubmitted: PropTypes.bool,
  isCustomerPortalAutoquote: PropTypes.bool,
  isDuplicating: PropTypes.bool,
  lineItem: PropTypes.object,
  lineItemIndex: PropTypes.number,
  onDuplicateLineItem: PropTypes.func,
  updateCostingRequestLineItem: PropTypes.func,
};

const isLineItemSame = (prevProps, nextProps) => {
  return (
    isEqual(prevProps.fieldPrefix, nextProps.fieldPrefix) &&
    isEqual(prevProps.lineItem, nextProps.lineItem)
  );
};

export default React.memo(CostingFormLineItem, isLineItemSame);
