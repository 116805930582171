import { Form, Formik, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { api } from 'fr-shared/api';
import {
  Breadcrumb,
  Button,
  FormCheckbox,
  FormField,
  FormNumber,
  FormSelect,
  Helmet,
} from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { useManufacturingProcess } from 'fr-shared/hooks';

const NewMaterial = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const handleSubmit = (values, formikBag) => {
    setIsLoading(true);
    api
      .post('/materials', {
        material: values,
      })
      .then(res => {
        const { name } = res.data;
        setAlert({ message: `Successfully added material ${name}` });
      })
      .catch(err => {
        if (err.response?.data) {
          const { errors, messages } = err.response.data;
          const formikErrors = { server: messages, ...errors };
          formikBag.setErrors(formikErrors);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Helmet title="New Material" />
      <Breadcrumb to="/admin/inventory">Inventory</Breadcrumb>
      <Breadcrumb to="/admin/inventory/material/new">New Material</Breadcrumb>

      <div className="page-header">
        <div className="container">
          <div>
            <h2>Add New Material</h2>
          </div>
          <div className="page-actions">
            <Button color="secondary" to={`/admin`}>
              Back
            </Button>
          </div>
        </div>
      </div>

      <Formik onSubmit={handleSubmit} initialValues={{ manufacturing_process_id: null }}>
        <NewMaterialForm isLoading={isLoading} />
      </Formik>
    </>
  );
};

const NewMaterialForm = ({ isLoading }) => {
  const formik = useFormikContext();
  const { data: manufacturingProcesses } = useManufacturingProcess();
  return (
    <Form>
      <div className="container">
        <div className="row">
          <div className="col">
            <FormSelect
              required={true}
              name="manufacturing_process_id"
              label="MFG Process"
              optionList={manufacturingProcesses}
            />
            {!isEmpty(formik.values.manufacturing_process_id) && (
              <>
                <FormField required={true} name="name" label="Material Name" />
                <FormNumber name="density" label="Density" />
                <FormCheckbox name="publicly_available" label="Publicly Available" />
                <FormCheckbox name="custom" label="Custom" />
              </>
            )}
          </div>
        </div>
        <div className="row">
          <Button className="ml-auto mt-2" type="submit" color="success" loading={isLoading}>
            Submit
          </Button>
        </div>
      </div>
    </Form>
  );
};

NewMaterialForm.propTypes = {
  isLoading: PropTypes.bool,
};

export default NewMaterial;
