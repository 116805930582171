import React from 'react';

import { Button } from 'fr-shared/components';

import LinksAndSummary from 'portal/components/ItarEarModal/LinksAndSummary/LinksAndSummary';

interface Props {
  toggle: () => void;
}

const NoConfirmation = ({ toggle }: Props) => {
  return (
    <>
      <LinksAndSummary />
      <div className="mt-2" style={{ borderBottom: '1px solid #3c4551' }}></div>

      <Button className="float-right mt-4" onClick={toggle}>
        Continue
      </Button>
    </>
  );
};

export default NoConfirmation;
