import { useFormikContext } from 'formik';
import { flatMap, get } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Button, FormDate, FormField, Icon, SidebarContent } from 'fr-shared/components';

const ShippingDetailsSidebar = () => {
  const [selectedPsliCount, setSelectedPsliCount] = useState(0);
  const { values, setFieldValue, setErrors, setFieldError, errors } = useFormikContext();

  const bulkUpdateParams = get(values, 'bulk_updates', {});
  const bulkErrors = get(errors, 'bulk_updates', {});

  const dateFields = ['commit_date', 'promised_ship_date', 'supplier_promised_ship_date'];
  const disableBulkUpdate = Object.keys(bulkErrors).some(r => dateFields.includes(r));

  const lineItems = get(values, 'line_items', []);

  useEffect(() => {
    if (Array.isArray(lineItems)) {
      const flattenedPslis = flatMap(lineItems, lineItem => {
        return lineItem.planned_shipment_line_items?.map((psli: PlannedShipmentLineItem) => {
          return psli;
        });
      });
      const selectedPslisCount = flattenedPslis.filter(
        (psli: PlannedShipmentLineItem) => psli?.selected === true
      ).length;
      setSelectedPsliCount(selectedPslisCount);
    }
  }, [lineItems]);

  const handleSelecting = (selectAll: boolean) => {
    const newLineItems = lineItems.map((li: OrderLineItem) => {
      return {
        ...li,
        selected: selectAll,
        planned_shipment_line_items: li.planned_shipment_line_items?.map(
          (psli: PlannedShipmentLineItem) => {
            if (psli.remaining_to_fulfill === 0) {
              return psli;
            } else {
              return { ...psli, selected: selectAll };
            }
          }
        ),
      };
    });
    setFieldValue('line_items', newLineItems);
  };

  const handleBulkUpdate = () => {
    setErrors({});
    lineItems.forEach((li: OrderLineItem, index: number) => {
      const fieldPrefix = `line_items.${index}.planned_shipment_line_items`;
      const updatedPSLI = li.planned_shipment_line_items?.map((psli: PlannedShipmentLineItem) => {
        if (!psli.selected) {
          return psli;
        } else if (isBulkUpdateQuantityInvalid(psli.already_fulfilled)) {
          return psli;
        } else {
          return { ...psli, ...bulkUpdateParams };
        }
      });
      setFieldValue(fieldPrefix, updatedPSLI);
    });
  };

  const isBulkUpdateQuantityInvalid = (alreadyFulfilled: number) => {
    if (Object.keys(bulkUpdateParams).includes('quantity')) {
      if (!bulkUpdateParams['quantity']) {
        if (bulkUpdateParams['quantity'] <= 0) {
          setFieldError(`bulk_updates.quantity`, `quantity must be greater than zero`);
        }
        return true;
      } else if (bulkUpdateParams['quantity'] < alreadyFulfilled) {
        setFieldError(
          `bulk_updates.quantity`,
          `quantity cannot be less than already fulfilled quantity(${alreadyFulfilled})`
        );
        return true;
      }
      return false;
    }
  };

  const handleValidatingAndChangingDate = (day: string, field: string) => {
    if (!day) {
      setFieldError(field, 'must provide a valid date');
    } else {
      setFieldValue(field, day);
    }
  };

  const handleClearBulkSelections = () => {
    setFieldValue('bulk_updates', {});
    setErrors({});
  };

  return (
    <SidebarContent name="Bulk Updates" icon="spinner">
      <p className="font-size-md text-muted">
        Select planned shipment line items on the left to apply your bulk updates to.
      </p>
      <div className="flex justify-content-between mb-2">
        <Button
          className="px-0"
          color="link"
          dataTestId="psli-bulk-edit-select-all"
          onClick={() => handleSelecting(true)}
          size="md"
        >
          <Icon name="check-square" right />
          Select All
        </Button>
        <Button
          className="px-0"
          color="link"
          dataTestId="psli-bulk-edit-deselect-all"
          size="md"
          onClick={() => handleSelecting(false)}
        >
          <Icon name="square" type="far" right />
          Deselect All
        </Button>
      </div>
      <FormDate
        isStacked
        label="Commit Date"
        name="bulk_updates.commit_date"
        onChange={day => handleValidatingAndChangingDate(day, 'bulk_updates.commit_date')}
      />
      <FormDate
        isStacked
        label="Customer Promised Date"
        name="bulk_updates.promised_ship_date"
        onChange={day => handleValidatingAndChangingDate(day, 'bulk_updates.promised_ship_date')}
      />
      <FormDate
        isStacked
        label="Supplier Promised Ship Date"
        name="bulk_updates.supplier_promised_ship_date"
        onChange={day =>
          handleValidatingAndChangingDate(day, 'bulk_updates.supplier_promised_ship_date')
        }
      />
      <FormField stacked type="number" name={`bulk_updates.quantity`} label="Quantity" />
      <div className="flex form-group align-items-center text-right mt-3">
        <div className="text-muted font-size-md">
          <span data-testid="selected-psli-count">{selectedPsliCount} selected</span>
        </div>
        <div className="ml-auto">
          <Button
            className="mr-2"
            dataTestId="bulk-update-clear-input"
            onClick={handleClearBulkSelections}
          >
            Clear
          </Button>
          <Button
            color="primary"
            dataTestId="bulk-update-apply-input"
            disabled={disableBulkUpdate}
            onClick={handleBulkUpdate}
          >
            Apply
          </Button>
        </div>
      </div>
    </SidebarContent>
  );
};

export default ShippingDetailsSidebar;
