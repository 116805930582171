import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import {
  Page,
  PageHeader,
  PageResults,
  PageSearch,
  PageToolbar,
  PageViewToggle,
  Pagination,
  ProgressCircle,
  Tabs,
} from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';
import { useTableFilters } from 'fr-shared/hooks';

import { EmptyQuotesCard, QuoteButton } from 'portal/components';

import { DEFAULT_SORT, QUOTE_STATES, TAB_STATES } from '../../lib/quotes';
import FilterAndSortButton from './components/FilterAndSortButton';
import FilterModalContainer from './components/FilterModal/FilterModalContainer';
import GridView from './components/GridView/GridView';
import ListView from './components/ListView/ListView';

const fetchData = filters => {
  return api.get('/sales_portal/quotes', { params: filters });
};

const Quotes = () => {
  const { user } = useContext(UserContext);

  const initialFilters = {
    search: '',
    page: 1,
    page_size: 20,
    is_active: true,
    sales_contact_id: null,
    tab_name: TAB_STATES.Carts,
    source: null,
    states: Object.values(QUOTE_STATES),
    sort_key: DEFAULT_SORT.value,
  };

  const SALES_PORTAL_QUOTES = 'sales_portal_quotes';
  const resetFilters = () => {
    setFilters({
      ...filters,
      page: 1,
      sales_contact_id: null,
      source: null,
      states: Object.values(QUOTE_STATES),
      sort_key: DEFAULT_SORT.value,
    });
  };

  const {
    data: quotes,
    handleSearch: handleTableSearch,
    loading,
    pages,
    totalItems,
    filters,
    setFilters,
  } = useTableFilters(SALES_PORTAL_QUOTES, initialFilters, fetchData);

  const handleSetPage = page => {
    setFilters({ ...filters, page });
  };

  const [counts, setCounts] = useState({});
  const [currentTab, setCurrentTab] = useState(filters.tab_name);
  const [isGridView, setIsGridView] = useState(true);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  useEffect(() => {
    api
      .get('/sales_portal/quote_counts', {
        params: {
          ...filters,
          tab_names: Object.keys(TAB_STATES),
        },
      })
      .then(res => setCounts(res.data));
  }, [quoteTabs, filters]);

  const handleSearch = value => {
    handleTableSearch({ target: { name: 'search_key', value } });
  };

  const updateTab = tabName => {
    setCurrentTab(tabName);
    if (tabName === TAB_STATES.Carts) {
      setFilters({
        ...filters,
        tab_name: TAB_STATES.Carts,
        page: 1,
        page_size: 20,
      });
    } else {
      setFilters({
        ...filters,
        tab_name: TAB_STATES.Quotes,
        page: 1,
        page_size: 20,
      });
    }
  };

  const getFilterCount = filters => {
    let filterCount = 0;
    if (filters.sales_contact_id !== null) filterCount++;
    if (filters.source !== null) filterCount++;
    if (filters.states.length !== Object.values(QUOTE_STATES).length) {
      filterCount += filters.states.length;
    }

    return filterCount;
  };

  const filterCount = getFilterCount(filters);

  const handleGridToggle = () => {
    setIsGridView(!isGridView);
  };

  const DisplayComponent = isGridView ? GridView : ListView;

  const quoteTabs = Object.values(TAB_STATES).map(tabName => ({
    active: currentTab === tabName,
    count: tabName === TAB_STATES.Carts ? counts.Carts : counts.Quotes,
    disabled: false,
    name: tabName,
    onClick: () => updateTab(tabName),
  }));

  const toggleShowSidebar = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  const renderBody = () => {
    if (loading)
      return (
        <div data-testid="sales-portal-loading-spinner" className="flex justify-center mt-32">
          <ProgressCircle
            percentage={75}
            spinner={true}
            backgroundColor="#111115"
            fadesIntoBackground
          />
        </div>
      );
    else if (isEmpty(quotes)) return <EmptyQuotesCard />;
    else return <DisplayComponent quotes={quotes} />;
  };

  return (
    <Page>
      <PageHeader
        actions={<QuoteButton dataTestId={'create-edit-quote--button'} />}
        border={false}
        className="mb-2"
        title="Quotes"
      />
      <div>
        <Tabs
          badgeClassnames="bg-error-400 text-coolGray-100 rounded-full -top-1 right-1"
          className="flex margin-auto"
          showCompleted={false}
          tabs={quoteTabs}
        />
        <PageToolbar className="flex items-center justify-between">
          <div className="flex items-center">
            <PageSearch
              defaultValue={filters.search_key}
              onSearch={handleSearch}
              placeholder="Search"
            />

            <PageResults className="text-coolGray-500" value={totalItems} context="quote" />
          </div>
          <div className="flex flex-row">
            <FilterAndSortButton
              className="border-right pr-3"
              handleClick={toggleShowSidebar}
              filterCount={filterCount}
            />
            <PageViewToggle
              className="ml-3 text-coolGray-300"
              toggled={isGridView}
              onToggle={handleGridToggle}
            />
          </div>
        </PageToolbar>
      </div>

      <div className="mt-5">{renderBody()}</div>

      {pages > 1 && !loading && (
        <div className="flex-1 mt-3 flex align-items-end">
          <Pagination
            page={filters.page}
            setPage={handleSetPage}
            totalPages={pages}
            pageNumberStyle={'numbered'}
          />
        </div>
      )}
      <FilterModalContainer
        isOpen={isFilterModalOpen}
        loading={loading}
        toggle={toggleShowSidebar}
        resetFilters={resetFilters}
        setFilters={setFilters}
        filters={filters}
        salesContactId={user.id}
        filterCount={filterCount}
      />
    </Page>
  );
};

export default Quotes;
