import PropTypes from 'prop-types';
import React from 'react';

import { Alert, Icon, Link } from 'fr-shared/components';

const QuoteOpportunityIdAlert = ({ quoteId, onSync }) => (
  <Alert color="danger" toggle={true}>
    <Icon name="exclamation-triangle" right />
    Opportunities can only have one draft quote open at a time.{' '}
    <strong>
      <Link to={`/admin/quotes/${quoteId}`}> Quote #{quoteId} </Link>
    </strong>{' '}
    already exists as a draft for this opportunity.
    <br />
    <strong>If you{"'"}ve shared that quote with a customer</strong>, please sync it to Salesforce
    before continuing.{' '}
    <strong>
      <Link onClick={onSync}>Sync Quote #{quoteId}</Link>
    </strong>
    <br />
    Otherwise, please edit the existing quote.{' '}
    <strong>
      <Link to={`/admin/quotes/${quoteId}`}> Open Quote #{quoteId} </Link>
    </strong>
  </Alert>
);

QuoteOpportunityIdAlert.propTypes = {
  quoteId: PropTypes.object,
  onSync: PropTypes.func,
};

export default QuoteOpportunityIdAlert;
