import { connect } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, FormDocuments, Icon } from 'fr-shared/components';
import { DocumentsContext } from 'fr-shared/context';
import { isFormikAtPrefixTheSame } from 'fr-shared/utils';

const CostingDocuments = ({ fieldPrefix, fieldName, withLabel, ...formProps }) => {
  return (
    <div>
      <FormDocuments
        label={withLabel ? 'Costing Documents' : null}
        fieldPrefix={fieldPrefix}
        fieldName={fieldName || 'documents'}
        s3Endpoint="/s3/sign/costing_request_line_item_file"
        showAlerts
        {...formProps}
      >
        <DocumentsContext.Consumer>
          {({ documents, isUploading }) => (
            <div className="flex flex-row align-items-center">
              <Button color="primary" loading={isUploading}>
                <Icon name="paperclip" right />
                Attach Document(s)
              </Button>
              <p className="ml-2 text-muted">
                {isUploading
                  ? 'Uploading'
                  : `${documents.length > 0 ? documents.length : 'None'} Attached`}
              </p>
            </div>
          )}
        </DocumentsContext.Consumer>
      </FormDocuments>
    </div>
  );
};

CostingDocuments.propTypes = {
  fieldPrefix: PropTypes.string,
  fieldName: PropTypes.string,
  withLabel: PropTypes.bool,
};

export default connect(React.memo(CostingDocuments, isFormikAtPrefixTheSame));
