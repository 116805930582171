import React, { useContext } from 'react';
import { Quote } from 'src/views/sales_portal/lib/quotes';

import { CardWrapper } from 'fr-shared/components';
import { UserContext } from 'fr-shared/context';

import { QuoteCard } from '../QuoteCard/QuoteCard';

interface GridViewProps {
  firstElement?: Quote;
  quotes: Array<Quote>;
}

const GridView = ({ quotes }: GridViewProps) => {
  const { user } = useContext(UserContext);
  return (
    <CardWrapper>
      {quotes &&
        quotes.length > 0 &&
        quotes.map((quote: Quote, index) => (
          <QuoteCard
            key={`${quote.public_id}_${quote.id}`}
            quote={quote}
            owner={index === 0 && quote.state === 'Draft' && quote.user_id === user.id}
          />
        ))}
    </CardWrapper>
  );
};

export default GridView;
