import PropTypes from 'prop-types';
import React from 'react';

import { classNames } from 'fr-shared/components';

const PLACEHOLDER = '--';

export const displayDollars = formattedMoneyAmount => {
  if (!formattedMoneyAmount) return PLACEHOLDER;
  const words = formattedMoneyAmount.split('.');
  // This case catches when money isn't formatted correctly
  if (words.length !== 2) return PLACEHOLDER;
  if (isNaN(words[0])) return PLACEHOLDER;
  return words[0];
};

export const displayCents = formattedMoneyAmount => {
  if (!formattedMoneyAmount) return PLACEHOLDER;
  const words = formattedMoneyAmount.split('.');
  // This case catches when money isn't formatted correctly
  if (words.length !== 2) return PLACEHOLDER;
  if (isNaN(words[1])) return PLACEHOLDER;
  return words[1];
};

export const Money = ({
  amount,
  className = '',
  dataTestId,
  isNegative = false,
  unstyledSymbol = false,
}) => {
  const cents = displayCents(amount);
  return (
    <div data-amount={amount} data-testid={dataTestId} className={classNames([className])}>
      {isNegative && '-'}
      <sup className={classNames([!unstyledSymbol && 'text-gray'])}>$</sup>
      {displayDollars(amount)}
      {cents && (
        <>
          .<sup>{cents}</sup>
        </>
      )}
    </div>
  );
};

Money.propTypes = {
  amount: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  isNegative: PropTypes.bool,
  unstyledSymbol: PropTypes.bool,
};

export default Money;
