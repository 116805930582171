import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Button, Modal } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';

interface ContactConvertCartToCRModalProps {
  isOpen: boolean;
  cartID: number;
  toggle: () => void;
  userID: number;
}

export default function ContactConvertCartToCRModal({
  isOpen,
  cartID,
  toggle,
  userID,
}: ContactConvertCartToCRModalProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { setAlert } = useContext(AlertContext);

  const handleConvertCartToCR = (cartID: number) => {
    setIsLoading(true);
    api
      .post(`/carts/${cartID}/convert_cart_to_costing_request`, {
        user_id: userID,
      })
      .then(response => {
        setIsLoading(false);
        toggle();
        history.push(`/admin/costing_requests/${response.data.costing_request_id}`);
      })
      .catch(() => {
        setIsLoading(false);
        toggle();
        setAlert({
          color: 'danger',
          message: 'An unexpected error occurred, please refresh and try again',
        });
      });
  };

  const closeModal = () => {
    toggle();
  };
  return (
    <>
      <Modal isOpen={isOpen} toggle={closeModal} onCloseHandler={closeModal}>
        <Modal.Header
          textUppercase={false}
          title="Convert Cart to Costing Request"
          onClose={closeModal}
        />
        <Modal.Body>
          <div>
            You are submitting this cart on behalf of the customer. The customer will no longer be
            able to modify this cart. They will see that the Cart has been &quot;Requested&quot;
            until you return a Quote to them. Are you sure you want to proceed?
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer className="justify-end">
          <Button
            onClick={() => {
              handleConvertCartToCR(cartID);
            }}
            className="ml-0"
            loading={isLoading}
            showLoadingIcon={true}
          >
            Confirm
          </Button>
          <Button
            outline
            onClick={() => {
              toggle();
            }}
            className="mr-3"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
