import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { useUserAnalyticsContext } from 'fr-shared/context';

import LogInForm from './LogInForm';

const LogIn = () => {
  const location = useLocation();
  const email = location.state?.email;
  const userAnalytics = useUserAnalyticsContext();

  useEffect(() => {
    userAnalytics.track('Login Page');
  }, [userAnalytics]);

  return (
    <>
      <Helmet title="Log In to Your Account">
        <meta
          name="description"
          content="Log in to your Fast Radius account to view and track the status of your quotes and orders, as well as submit new quotes."
        />
        <body className="bg-dark" />
      </Helmet>

      <LogInForm username={email} />
    </>
  );
};

export default LogIn;
