import { useFormikContext } from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { api } from 'fr-shared/api';
import { Button, Icon } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';

const LineItemPublicLink = ({ fieldPrefix, lineItem }) => {
  const { setAlert } = useContext(AlertContext);
  const [copySuccess, setCopySuccess] = useState(false);
  const [generateLinkLoading, setGenerateLinkLoading] = useState(false);
  const formik = useFormikContext();
  const part = get(lineItem, 'part');
  const currentRevision = part?.current_revision;

  const publicLink = get(lineItem, 'public_link');
  const linkUrl = `/insights/${publicLink?.uuid}`;

  const isAvailable = publicLink
    ? moment(moment()).isBefore(moment(publicLink.expires_at))
    : false;

  if (!lineItem.id) return null;

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(`${window.location.origin}${linkUrl}`);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (error) {
      setCopySuccess(false);
      Sentry.setExtra('error', error);
      Sentry.captureMessage('Insights copy link error');
    }
  };

  const handleGenerateLink = () => {
    setGenerateLinkLoading(true);
    api
      .post(`/insights`, {
        costing_request_line_item_id: lineItem.id,
        part_file_revision_id: currentRevision.id,
      })
      .then(res => {
        formik.setFieldValue(`${fieldPrefix}.public_link`, res.data);
      })
      .catch(error => {
        setAlert({
          color: 'danger',
          message: "Couldn't generate Insight link, please save form as draft and try again",
        });
        Sentry.setExtra('error', error);
        Sentry.captureMessage('Insights link generation error');
      })
      .finally(() => setGenerateLinkLoading(false));
  };

  return (
    <div className="alert bg-primary flex align-items-center text-white p-1 font-size-md mb-1">
      <Icon name="link" right />
      {publicLink && isAvailable ? (
        <>
          <span>
            Feasibility Report is ready! Expires{' '}
            {moment(publicLink.expires_at).format('MM/DD/YYYY')}
          </span>
          <div className="ml-auto flex align-items-center">
            <Button
              color="white"
              className="flex align-items-center font-size-sm px-1 py-0 mr-1"
              onClick={handleCopyLink}
              tooltip={copySuccess ? 'Copied!' : null}
            >
              <span>Copy Link</span>
              {copySuccess && <Icon name="check" className="text-success ml-1" />}
            </Button>
            <Button
              color="white"
              className="font-size-sm px-1 py-0 mr-1"
              openNewWindow
              to={{ pathname: linkUrl }}
            >
              View
            </Button>
          </div>
        </>
      ) : (
        <>
          <span>Part is eligible for Feasibility Report!</span>
          <div className="ml-auto flex align-items-center">
            <Button
              color="white"
              className="font-size-sm px-1 py-0 mr-1"
              onClick={handleGenerateLink}
              loading={generateLinkLoading}
            >
              Generate Link
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

LineItemPublicLink.propTypes = {
  fieldPrefix: PropTypes.string.isRequired,
  lineItem: PropTypes.object,
};

export default LineItemPublicLink;
