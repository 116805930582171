import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { IconFont, classNames } from 'fr-shared/components';

class Alert extends Component {
  static propTypes = {
    /** The content of the alert, usually an icon & message */
    children: PropTypes.node,
    /** Additional classNames for the alert wrapper */
    className: PropTypes.string,
    /** The color of the alert */
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'success',
      'danger',
      'warning',
      'info',
      'light',
      'dark',
      'gray',
    ]),
    /** The size of the alert */
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    /** The DOM element of the alert */
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    /** Used to control the state of the alert, if needed */
    isOpen: PropTypes.bool,
    /** Custom onClick event handler */
    onClick: PropTypes.func,
    /** Make the alert dismissable. Can send a function as a callback after it toggles */
    toggle: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  };

  static defaultProps = {
    color: 'primary',
    isOpen: true,
    tag: 'div',
  };

  state = {
    isOpen: true,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen });
    }
  }

  handleClick = () => {
    const { toggle } = this.props;
    this.setState(state => {
      return { isOpen: !state.isOpen };
    });
    isFunction(toggle) && toggle();
  };

  render() {
    const {
      children,
      className,
      color,
      isOpen: initialOpen,
      size,
      onClick,
      toggle,
      tag: Tag,
    } = this.props;

    const { isOpen } = this.state;

    const classes = classNames([
      'alert',
      `alert-${color}`,
      size && `alert-${size}`,
      { 'alert-dismissible': toggle },
      className,
    ]);

    if (!initialOpen || !isOpen) return null;

    return (
      <Tag role="alert" className={classes} onClick={onClick}>
        <>
          {toggle && (
            <button
              className="text-error-400 border-0 bg-transparent absolute right-1 top-1 leading-4 text-xl p-0"
              onClick={this.handleClick}
            >
              <IconFont name="close-filled" />
            </button>
          )}
          {children}
        </>
      </Tag>
    );
  }
}

export default Alert;
