import { connect } from 'formik';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import { api } from 'fr-shared/api';
import { DropdownIndicator, FirstOrderBadge } from 'fr-shared/components';

const handleSearch = debounce((inputValue, callback) => {
  api.get('/orders', { params: { public_id: inputValue } }).then(res => {
    const formattedData = res.data.map(o => ({
      label: o.public_id,
      value: o.public_id,
      data: o,
    }));
    callback(formattedData);
  });
}, 500);

const OrderSearch = ({ onSelect }) => {
  const handleSelect = res => {
    onSelect(res.data);
  };

  return (
    <AsyncSelect
      cacheOptions={true}
      classNamePrefix="react-select"
      components={{
        DropdownIndicator: DropdownIndicator,
        IndicatorSeparator: null,
        Option: Option,
      }}
      loadOptions={handleSearch}
      noOptionsMessage={option => (option.inputValue === '' ? null : 'No orders found')}
      onChange={handleSelect}
      placeholder="Search FR Orders by #"
    />
  );
};

OrderSearch.propTypes = {
  onSelect: PropTypes.func,
};

const Option = ({ data, ...props }) => {
  const order = data.data;

  return (
    <components.Option {...props}>
      <div className="flex">
        <div className="mr-4">
          <div>
            <small className="text-muted">Order Number</small>
          </div>
          <div>
            <strong>{order.public_id}</strong>
          </div>
        </div>
        <div className="mr-4">
          <div>
            <small className="text-muted">Customer</small>
          </div>
          <div>
            <strong>{order.customer.name}</strong>
          </div>
        </div>
        {order.customer_contact && (
          <div className="mr-4">
            <div>
              <small className="text-muted">Contact</small>
            </div>
            <div>
              <strong>{order.customer_contact.name}</strong>
            </div>
          </div>
        )}
        {order.is_first_order && (
          <div className="flex flex-column justify-content-around mr-4">
            <div>&nbsp;</div>
            <FirstOrderBadge className="mt-n1" fontSize="sm" />
          </div>
        )}
      </div>
    </components.Option>
  );
};

Option.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      public_id: PropTypes.string.isRequired,
      customer: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      customer_contact: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
      is_first_order: PropTypes.bool,
    }),
  }).isRequired,
};

export default connect(OrderSearch);
