import { USE_SUPPLIER_PORTAL_CONFIRMATION_BADGE } from 'fr-shared/feature_flags';
import { useFeatureFlags } from 'fr-shared/hooks';

const useSupplierPortalConfirmationBadgeFeatureFlag = () => {
  const { [USE_SUPPLIER_PORTAL_CONFIRMATION_BADGE]: isSupplierPortalConfirmationBadgeOn } =
    useFeatureFlags({
      [USE_SUPPLIER_PORTAL_CONFIRMATION_BADGE]: 'on',
    });
  return isSupplierPortalConfirmationBadgeOn;
};

export default useSupplierPortalConfirmationBadgeFeatureFlag;
