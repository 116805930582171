import PropTypes from 'prop-types';
import React, { useState } from 'react';
import QrReader from 'react-qr-reader';
import { Route } from 'react-router-dom';

import { Alert, Modal } from 'fr-shared/components';

const WorkOrderQRReaderModal = () => {
  return (
    <Route
      path="/admin/work_orders/qr_reader"
      render={({ match, history }) => <QRModal match={match} history={history} />}
    />
  );
};

const QRModal = ({ history }) => {
  const [isError, setIsError] = useState(false);
  const handleScan = data => {
    if (!data) {
      return null;
    }

    const workOrder = JSON.parse(data);
    const { work_order_id } = workOrder;
    if (work_order_id) {
      history.push(`/admin/work_orders/${work_order_id}`);
    }
  };

  const handleClose = () => {
    history.push('/admin/work_orders');
  };

  return (
    <Modal isOpen toggle={handleClose}>
      {({ toggle }) => (
        <>
          <Modal.Header title="QR Reader" onClose={toggle} />
          {isError && (
            <Alert color="danger" toggle={true}>
              There is an error with the camera, please fix the camera and reload the page to try
              again.
            </Alert>
          )}
          <div className="modal-body">
            <QrReader
              delay={500}
              onScan={handleScan}
              onError={() => setIsError(true)}
              style={{ width: '100%' }}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

QRModal.propTypes = {
  history: PropTypes.object,
};

export default WorkOrderQRReaderModal;
