import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { IconFont } from 'fr-shared/components';
import { keyboardDown } from 'fr-shared/utils';

import { PartImage } from 'portal/components';
import usePreloadedImages from 'portal/hooks/usePreloadedImages';

import styles from './PartsSlider.module.css';

const PartsSlider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  usePreloadedImages(images);

  const multipleImages = images.length > 1;

  const onPreviousImageClicked = event => {
    event.stopPropagation();

    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    } else {
      setCurrentImageIndex(images.length - 1);
    }
  };

  const onNextImageClicked = event => {
    event.stopPropagation();
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    } else {
      setCurrentImageIndex(0);
    }
  };

  return (
    <div className={styles.PartsSlider}>
      <div className="flex align-items-center mb-1">
        {multipleImages && (
          <div
            className={styles.SliderNav}
            onClick={onPreviousImageClicked}
            onKeyDown={evt => keyboardDown(evt, 'Enter', onPreviousImageClicked)}
            role="button"
            tabIndex={0}
          >
            <IconFont name="chevron-left" className="font-size-base" />
          </div>
        )}
        <PartImage src={images?.[currentImageIndex]} />
        {multipleImages && (
          <div
            className={styles.SliderNav}
            onClick={onNextImageClicked}
            onKeyDown={evt => keyboardDown(evt, 'Enter', onNextImageClicked)}
            role="button"
            tabIndex={0}
          >
            <IconFont name="chevron-right" className="font-size-base" />
          </div>
        )}
      </div>
      {multipleImages && (
        <div className={styles.PartPagination}>
          <span className="text-light">{currentImageIndex + 1}</span>
          <span className="text-light">&nbsp;/&nbsp;</span>
          <span>{images?.length}</span>
        </div>
      )}
    </div>
  );
};

PartsSlider.propTypes = {
  images: PropTypes.array,
};

export default PartsSlider;
