import React, { useContext } from 'react';

import { Alert } from 'fr-shared/components';
import { DocumentsContext } from 'fr-shared/context';

const DocumentsAlert = () => {
  const { isUploadError } = useContext(DocumentsContext);

  if (!isUploadError) {
    return null;
  }

  return (
    <Alert color="danger">
      There was an issue with the file upload. Please refresh and try again.
    </Alert>
  );
};

export default DocumentsAlert;
