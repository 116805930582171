import React from 'react';
import * as Yup from 'yup';

import { IconFont } from 'fr-shared/components';
import { mergeSchemas } from 'fr-shared/utils';

interface Props {
  description: string;
  isPassing: boolean;
}

export const PasswordRequirement = ({ description, isPassing }: Props) => (
  <div className="mb-1 last:mb-0 flex align-items-center">
    <IconFont
      name="check-circle"
      className={`mr-1 font-size-md ${isPassing ? 'text-success' : 'text-coolGray-500'}`}
    />
    <span className={`font-size-sm ${isPassing ? 'text-success' : ''}`}>{description}</span>
  </div>
);

export const passwordRequirements = [
  {
    description: '10 or more characters',
    schema: Yup.string()
      .min(10, 'Must contain 10 or more characters')
      .required('Must contain 10 or more characters'),
  },
  {
    description: 'Upper & lowercase letters',
    schema: Yup.string()
      .matches(/[a-z]/, 'Must contain upper & lowercase letters')
      .matches(/[A-Z]/, 'Must contain upper & lowercase letters')
      .required(),
  },
  {
    description: 'At least one number',
    schema: Yup.string().matches(/\d/, 'Must contain at least one number').required(),
  },
];

export const Schema = mergeSchemas(...passwordRequirements.map(req => req.schema));

export default PasswordRequirement;
