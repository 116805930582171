import { IconFont } from '@fast-radius/shared-ui';
import React from 'react';

import { Button, classNames } from 'fr-shared/components';
import { MANUFACTURING_PROCESSES } from 'fr-shared/lib/manufacturing_process';

import useCart from 'portal/hooks/useCart';

import { PartConfig } from '../../viewer';

interface Props {
  disableButtonDueToSubscription: boolean;
  initialCartLineItemId?: number;
  isSubmitting?: boolean;
  onCreateFeasibilityReport: () => void;
  onCreateQuote: () => void;
  partConfig: PartConfig;
  selectedManufacturingProcess: ManufacturingProcess;
  isBulkEditPart: boolean;
}

const EvaluateActions = ({
  disableButtonDueToSubscription,
  initialCartLineItemId,
  isSubmitting,
  onCreateFeasibilityReport,
  onCreateQuote,
  partConfig,
  selectedManufacturingProcess,
  isBulkEditPart,
}: Props) => {
  const { data: cart } = useCart();

  const getTooltip = () => {
    const needsMaterial = [
      MANUFACTURING_PROCESSES.DLS,
      MANUFACTURING_PROCESSES.MJF,
      MANUFACTURING_PROCESSES.FDM,
      MANUFACTURING_PROCESSES.SLA,
      MANUFACTURING_PROCESSES.CNC,
    ].includes(selectedManufacturingProcess?.name);

    const needsColor = [
      MANUFACTURING_PROCESSES.DLS,
      MANUFACTURING_PROCESSES.MJF,
      MANUFACTURING_PROCESSES.FDM,
      MANUFACTURING_PROCESSES.CNC,
    ].includes(selectedManufacturingProcess?.name);

    if (!selectedManufacturingProcess) {
      return 'Choose a process';
    }

    if (needsMaterial && !partConfig.material_id) {
      return 'Choose a material';
    }

    if (needsColor && !partConfig.color_id) {
      return 'Choose a color';
    }

    if (disableButtonDueToSubscription) {
      return 'Upgrade subscription for feasibility report';
    }

    return null;
  };

  const isReportDisabled = getTooltip() !== null;
  return (
    <div className="flex">
      {isBulkEditPart ? (
        <div className="align-middle m-0 gap-4 z-[1001] shadow-[0px_0px_12px_rgba(0,0,0,0.5)] w-full min-w-full min-h-[96px]">
          <div>
            <Button className="flex-1 ml-6 my-2 p-0 w-[330px] h-[48px] px-4" to="/edit-parts">
              <span>
                <IconFont className="text-xl mr-0" name="arrow-left" />
              </span>
              <span className="text-base m-0">Back to manufacturing specs</span>
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex gap-2 p-3">
          <div className="flex-1 flex" data-rh={getTooltip()}>
            <Button
              className={classNames(['flex-1 px-4', isReportDisabled && 'pointer-events-none'])}
              outline
              size="md"
              onClick={onCreateFeasibilityReport}
              disabled={isReportDisabled}
            >
              <IconFont className="text-base" name="pro" />
              Create report
            </Button>
          </div>
          <div className="flex-1 flex">
            {initialCartLineItemId ? (
              <Button className="flex-1 px-4" to={`/part-config/${initialCartLineItemId}`}>
                Return to quote
              </Button>
            ) : (
              <Button
                loading={isSubmitting}
                className="flex-1 px-4"
                onClick={onCreateQuote}
                size="md"
              >
                {cart ? 'Add to quote' : 'Create quote'}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EvaluateActions;
