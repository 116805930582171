import { connect } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Checkbox, FormCurrency, FormNumber } from 'fr-shared/components';
import { SupplierResponseStateBadge } from 'fr-shared/components/common/StateBadges';
import { isExternalProcess } from 'fr-shared/lib/manufacturing_process';
import { isFormikAtPrefixTheSame } from 'fr-shared/utils';

import {
  noPartQuantitiesSelected,
  noPartQuantitiesSelectedForSupplierCost,
} from '../utils/selected';

const SupplierCostPartQuantity = ({
  formik,
  fieldPrefix,
  lineItemState,
  name,
  partQuantity,
  canSubmitCosts,
  supplierCostIndex,
}) => {
  const processFieldPrefix = `${fieldPrefix}.build_package.processes.0`;
  const lineItem = get(formik, `values.${fieldPrefix}`);
  const process = get(formik, `values.${processFieldPrefix}`);

  if (!lineItem) return null;

  const generatedUnitCost = get(partQuantity, 'generated_per_unit_cost.formatted');
  const supplierCost = get(formik, `values.${fieldPrefix}.supplier_costs.${supplierCostIndex}`);

  const canAddLeadTime = isExternalProcess(process.manufacturing_process.name);

  const handleUseGeneratedPerUnitCost = () => {
    const generatedValue = get(formik.values, `${name}.generated_per_unit_cost.amount`);
    formik.setFieldValue(`${name}.per_unit_cost`, generatedValue);
  };

  const handleSelectCost = () => {
    let isLineItemSelected;
    const newPartQuantity = { ...partQuantity, selected: !partQuantity.selected };

    if (!partQuantity.selected) {
      isLineItemSelected = true;
    } else {
      isLineItemSelected = !noPartQuantitiesSelected(lineItem, newPartQuantity);
    }

    formik.setFieldValue(`${fieldPrefix}.selected`, isLineItemSelected);
    formik.setFieldValue(
      `${fieldPrefix}.supplier_costs.${supplierCostIndex}.selected`,
      !noPartQuantitiesSelectedForSupplierCost(supplierCost, newPartQuantity)
    );
    formik.setFieldValue(name, newPartQuantity);
  };

  return (
    <>
      <div className="row mb-1">
        <div className="col-md">
          <div className="flex align-items-center">
            {lineItemState === 'Completed' && (
              <div>
                <Checkbox
                  name={`${name}.selected`}
                  className="m-0"
                  onChange={handleSelectCost}
                  value={partQuantity.selected || false}
                />
              </div>
            )}
            <div className="flex-grow-1">
              <FormCurrency
                label={`Cost at Qty ${partQuantity.quantity}`}
                className="mb-0"
                dataTestId="costing-request-page--cost-at-qty"
                name={`${name}.per_unit_cost`}
                readonly={!canSubmitCosts}
                isRequired={true}
              />
            </div>
          </div>

          {generatedUnitCost && canSubmitCosts && (
            <div className="mb-1 font-size-sm text-right">
              <Button
                color="link"
                dataTestId="use-cost"
                className="font-size-sm p-0 pl-1"
                onClick={handleUseGeneratedPerUnitCost}
              >
                <span className="text-dark font-weight-normal mr-1">
                  Autocosted at <strong>{generatedUnitCost}</strong>
                </span>
                <span className="text-success">Use this cost</span>
              </Button>
            </div>
          )}
        </div>

        {canAddLeadTime && (
          <div className="col-md">
            <FormNumber
              name={`${name}.lead_time`}
              className="mb-0"
              label={`Lead Time at Qty ${partQuantity.quantity}`}
              append="business days"
              readonly={!canSubmitCosts}
              required={true}
              dataTestId="costing-request-page--lead-time"
            />
          </div>
        )}

        {!!partQuantity.supplier_response_state && (
          <div
            className="col-md flex align-items-center"
            data-testid="supplierResponseStateBadge"
          >
            <SupplierResponseStateBadge state={partQuantity.supplier_response_state} />
          </div>
        )}
      </div>
    </>
  );
};

SupplierCostPartQuantity.propTypes = {
  canSubmitCosts: PropTypes.bool,
  formik: PropTypes.object,
  fieldPrefix: PropTypes.string,
  lineItemState: PropTypes.string,
  lineItem: PropTypes.object,
  name: PropTypes.string,
  partQuantity: PropTypes.object,
  supplierCostIndex: PropTypes.number,
};

export default connect(React.memo(SupplierCostPartQuantity, isFormikAtPrefixTheSame));
