import PropTypes from 'prop-types';
import React from 'react';
import { Reference } from 'react-popper';

import { classNames } from 'fr-shared/components';

import DropdownContext from './DropdownContext';

const DropdownToggle = ({ className, caret, split, nav, tag, ...tagProps }) => {
  const dropdownContext = React.useContext(DropdownContext);

  const ariaLabel = tagProps['aria-label'] || 'Toggle Dropdown';
  const classes = classNames([
    className,
    {
      'dropdown-toggle': caret || split,
      'dropdown-toggle-split': split,
      'nav-link': nav,
    },
  ]);
  const children = tagProps.children || <span className="sr-only">{ariaLabel}</span>;

  let Tag;

  if (tag) {
    Tag = tag;
  } else {
    Tag = 'a';
    tagProps.href = '#';
  }

  const onClick = e => {
    if (tagProps.disabled) {
      e.preventDefault();
      return;
    }

    if (nav && !tag) {
      e.preventDefault();
    }

    if (tagProps.onClick) {
      tagProps.onClick(e);
    }

    dropdownContext.toggle(e);
  };

  if (dropdownContext.inNavbar) {
    return (
      <Tag
        {...tagProps}
        className={classes}
        onClick={onClick}
        aria-expanded={dropdownContext.isOpen}
      >
        {children}
      </Tag>
    );
  }

  return (
    <Reference>
      {({ ref }) => (
        <Tag
          {...tagProps}
          ref={ref}
          className={classes}
          onClick={onClick}
          aria-expanded={dropdownContext.isOpen}
        >
          {children}
        </Tag>
      )}
    </Reference>
  );
};

DropdownToggle.propTypes = {
  caret: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  'aria-haspopup': PropTypes.bool,
  split: PropTypes.bool,
  tag: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
        PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
      ])
    ),
  ]),
  nav: PropTypes.bool,
};

DropdownToggle.defaultProps = {
  'aria-haspopup': true,
};

export default DropdownToggle;
