import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Select from 'react-select';

import { usePrinters } from 'fr-shared/hooks';

const WorkOrdersPrintersFilter = ({ manufacturingProcess, onSelectPrinter, printers }) => {
  const onChange = useMemo(() => {
    return (selected, _event_details) => {
      const e = { target: { value: selected ? selected.map(printer => printer.value) : [] } };
      onSelectPrinter(e);
    };
  }, [onSelectPrinter]);

  const {
    data: availablePrinters,
    isError,
    isLoading,
    error,
  } = usePrinters({ manufacturingProcess });

  if (isLoading) return <div>Printer list loading...</div>;
  if (isError) return <div>Error loading printers: {error.message}</div>;

  const options = manufacturingProcess
    ? availablePrinters.map(printer => {
        return { label: printer.name, value: printer.name };
      })
    : [];

  const value = printers ? options.filter(printer => printers.includes(printer.label)) : [];

  return (
    <div className="form-group">
      <label htmlFor="printer-filter">Printer</label>
      <Select
        className="mb-2"
        closeMenuOnSelect={false}
        delimiter={','}
        id="printer-filter"
        isMulti={true}
        name="printer-filter"
        onChange={onChange}
        options={options}
        placeholder={options.length > 0 ? `Example: ${options[0].label}` : 'Select a process'}
        value={value}
      />
    </div>
  );
};

WorkOrdersPrintersFilter.propTypes = {
  manufacturingProcess: PropTypes.string,
  onSelectPrinter: PropTypes.func,
  printer: PropTypes.string,
  printers: PropTypes.arrayOf(PropTypes.string),
};

export default WorkOrdersPrintersFilter;
