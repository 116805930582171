import { useFormikContext } from 'formik';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ProgressCircle } from 'fr-shared/components';
import { AlertContext, UserContext, useUserAnalyticsContext } from 'fr-shared/context';
import { pluralize } from 'fr-shared/utils';
import { PART_FILE_EXTENSIONS_PORTAL } from 'fr-shared/utils/files';

import { PartDropzone, classNames } from 'portal/components';
import { Part } from 'portal/lib/cart';
import { handleFileDrop } from 'portal/lib/upload';

import StudioUploadAnalysis from './StudioUploadAnalysis';
import StudioUploadDropzone from './StudioUploadDropzone';
import styles from './StudioUploadModal.module.css';

interface EmptyState {
  status: 'empty';
}

interface LoadingState {
  status: 'uploading';
  text: string;
}

interface AnalysisState {
  status: 'analyzing';
  part: Part;
}

type UploadState = EmptyState | LoadingState | AnalysisState;

const StudioUploadContent = () => {
  const userAnalytics = useUserAnalyticsContext();
  const formik = useFormikContext<{ units_in_mm: boolean }>();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { closeAlert, setAlert } = useContext(AlertContext);
  const [uploadState, setUploadState] = useState<UploadState>({ status: 'empty' });

  const onFileDropHandler = (acceptedFiles: any[]) => {
    handleFileDrop({
      acceptedFiles,
      loadHandlers: {
        initLoadingText: () => {
          userAnalytics.track('Onboarding Modal Studio - Part Upload Started', {
            fileCount: acceptedFiles.length,
          });
          closeAlert();
          setUploadState({
            status: 'uploading',
            text: pluralize('Uploading file', acceptedFiles.length > 1) + '...',
          });
        },
        clearLoadingText: () => setUploadState({ status: 'empty' }),
        loadingError: () =>
          setAlert({
            color: 'danger',
            message:
              'Unfortunately, we could not upload your file since the format was not accepted. See "Supported file types" below to see what file formats you can upload.',
          }),
      },
      s3LoadingHandlers: {
        uploadError: (file: { name: string }) =>
          setAlert({
            color: 'danger',
            message: `Failed to upload ${file.name}. Please refresh and try again.`,
          }),
      },
      cartLineItemPostHandler: ({ part }: any) => {
        if (part?.current_revision) {
          setUploadState({
            status: 'analyzing',
            part,
          });
        } else {
          setUploadState({ status: 'empty' });
        }
      },
      user,
      quote_units: formik?.values?.units_in_mm ? 'mm' : 'in',
      source: 'Studio',
    });
  };

  const renderContent = (uploadState: UploadState) => {
    if (uploadState.status === 'analyzing') {
      return (
        <div className="p-6">
          <StudioUploadAnalysis
            onComplete={() => history.push(`/studio/evaluate/${uploadState.part.id}`)}
            part={uploadState.part}
          />
        </div>
      );
    }
    return (
      <PartDropzone
        card={false}
        className={classNames([
          'p-3 m-3 flex-fill p-relative',
          styles.BorderDashed,
          styles.Dropzone,
        ])}
        isLoading={uploadState.status === 'uploading'}
        label="Drag and drop 3D design files here or"
        loadingSlot={
          <div className="flex-fill">
            <div className={styles.InnerContainer}>
              <div className={styles.Description}>
                <h3>
                  {(uploadState.status === 'uploading' && uploadState.text) ||
                    'Uploading file...'}
                </h3>
                <p className="text-muted font-size-md">This may take several minutes.</p>
              </div>
              <ProgressCircle percentage={75} backgroundColor="#141719" spinner={true} />
            </div>
          </div>
        }
        onFileDrop={onFileDropHandler}
        accept={PART_FILE_EXTENSIONS_PORTAL}
        dropzoneComponent={<StudioUploadDropzone />}
      />
    );
  };

  return renderContent(uploadState);
};

export default StudioUploadContent;
