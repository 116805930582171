import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import Dropzone from 'react-dropzone';

import { classNames } from 'fr-shared/components';
import { DocumentsContext, UserContext } from 'fr-shared/context';
import { useForm } from 'fr-shared/hooks';
import { uploadDocumentsToS3 } from 'fr-shared/lib/s3';

const DocumentsUpload = ({ children }) => {
  const formik = useForm();
  const { user } = useContext(UserContext);
  const {
    acceptedFileTypes,
    documents,
    fieldPath,
    maxSizeInBytes,
    readonly,
    s3Endpoint,
    setIsUploadError,
    setIsUploading,
    setRejectedFiles,
  } = useContext(DocumentsContext);

  const handleDrop = useCallback(
    files => {
      setIsUploadError(false);
      setIsUploading(true);
      uploadDocumentsToS3(files, user, s3Endpoint)
        .then(uploadedDocs => {
          formik.setFieldValue(fieldPath, [...documents, ...uploadedDocs]);
        })
        .catch(() => {
          setIsUploadError(true);
        })
        .finally(() => {
          setIsUploading(false);
        });
    },
    [documents, fieldPath, formik, s3Endpoint, setIsUploading, setIsUploadError, user]
  );

  const handleRejectedFiles = useCallback(
    rejectedFiles => setRejectedFiles(rejectedFiles),
    [setRejectedFiles]
  );

  if (readonly) {
    return null;
  }

  return (
    <Dropzone
      accept={acceptedFileTypes} // as long as it is undefined, all file types will be accepted
      maxSize={maxSizeInBytes}
      onDrop={handleDrop}
      onDropRejected={handleRejectedFiles}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div className="w-100" {...getRootProps()}>
          <input {...getInputProps()} id="file-dropzone" data-testid="file-dropzone" />
          <div className={classNames([isDragActive && 'is-dragging'])}>{children}</div>
        </div>
      )}
    </Dropzone>
  );
};

DocumentsUpload.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default DocumentsUpload;
