import PropTypes from 'prop-types';
import React from 'react';

import { IconFont } from 'fr-shared/components';

import { InfoBox } from 'portal/components';
import LINK_URLS from 'portal/lib/link_urls';

export const ContactUsInfoBox = ({ className, ...props }) => {
  return (
    <InfoBox
      icon={<IconFont name="question-circle" className="text-white mr-1" />}
      title="Need help?"
      className={className}
      link={{
        text: 'Contact us',
        icon: true,
        onClick: () => {
          window.location.pathname = LINK_URLS.ContactUrl;
        },
      }}
      {...props}
    >
      We&apos;re available Monday-Friday 8:00am-6:00pm Central Time.
    </InfoBox>
  );
};

ContactUsInfoBox.propTypes = {
  className: PropTypes.string,
};

export default ContactUsInfoBox;
