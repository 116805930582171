import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { AlertContext, withAlert } from 'fr-shared/context';

import { AlertBanner, Button, PartImage } from 'portal/components';

import CostCurves from './components/CostCurves';
import { DesignInsights } from './components/DesignInsights';
import FooterInsights from './components/FooterInsights';
import NavbarInsights from './components/NavbarInsights';
import PartOverview from './components/PartOverview';
import styles from './index.module.css';

const InsightsContainer = () => {
  const { hashid } = useParams();
  const history = useHistory();
  const { setAlert } = useContext(AlertContext);
  const [insight, setInsight] = useState(null);

  // ref's for scrollTo
  const partOverviewRef = useRef(null);
  const designInsightsRef = useRef(null);
  const costCurvesRef = useRef(null);

  useEffect(() => {
    api
      .get(`/insights/${hashid}`)
      .then(res => setInsight(res.data))
      .catch(error => {
        setAlert({
          color: 'danger',
          message:
            error.response?.status === 404
              ? `Sorry, we couldn't find the page you're looking for.`
              : 'Something unexpected happened. Please try again.',
          autoClose: true,
        });
        history.push('/');
      });
  }, [hashid, history, setAlert]);

  if (!insight) return null;

  const {
    part,
    customer,
    customer_contact: customerContact,
    sales_contact: insightSalesContact,
  } = insight;

  const currentRevision = part?.current_revision;

  // fallback to Our Team if we don't have a sales contact for the customer
  const salesContact = insightSalesContact || {
    email: 'sales@fastradius.com',
    name: 'Our Team',
    first_name: 'Our Team',
    last_name: 'Our Team',
    internal: true,
  };

  const hasDesignInsights = Object.keys(currentRevision?.manufacturability_checks)?.length > 0;

  const hasCostCurves = !!insight.cost_curve_data;

  const mailTo = `mailto:${salesContact.email}?subject=Re: ${part.name}&body=Hello ${salesContact.name},`;

  const scrollTo = ref => ref.current.scrollIntoView();

  return (
    <div className="theme-dark bg-dark">
      <Helmet title={`${part.name} Feasibility Report`}>
        <body className="bg-dark" />
      </Helmet>

      <AlertBanner />

      <NavbarInsights
        part={part}
        customer={customer}
        customerContact={customerContact}
        salesContact={salesContact}
        mailTo={mailTo}
      >
        <div className={styles.PageNav}>
          <Button outline onClick={() => scrollTo(partOverviewRef)}>
            Part Overview
          </Button>
          {hasDesignInsights && (
            <Button outline onClick={() => scrollTo(designInsightsRef)}>
              Design Insights
            </Button>
          )}
          {hasCostCurves && (
            <Button outline onClick={() => scrollTo(costCurvesRef)}>
              Cost Curve
            </Button>
          )}
        </div>
      </NavbarInsights>

      <div className="container-sm">
        <div className="flex align-items-center mb-5">
          <PartImage size="sm" backdrop={false} src={currentRevision.screenshot} />
          <h1 className="ml-3">{part.name}</h1>
        </div>

        <div className="flex gap-2">
          <div className="w-full" ref={partOverviewRef} style={{ scrollMargin: 80 }}>
            <PartOverview insight={insight} />
          </div>

          <div className="w-full relative" ref={designInsightsRef} style={{ scrollMargin: 80 }}>
            <DesignInsights insight={insight} />
          </div>
        </div>
      </div>

      <div ref={costCurvesRef} style={{ scrollMargin: 80 }}>
        <div className="container-sm my-5">
          <CostCurves insight={insight} partFileRevision={currentRevision} />
        </div>
      </div>

      <FooterInsights salesContact={salesContact} mailTo={mailTo} />
    </div>
  );
};

InsightsContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.object,
};

export default withAlert(InsightsContainer);
