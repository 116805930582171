import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'fr-shared/components';

const LineItemComment = ({ lineItem }) => {
  if (!lineItem.comments) return null;

  return (
    <div className="line-height-sm">
      <Icon name="comment" className="d-inline-block" right opacity sm />
      <span className="font-size-sm">{lineItem.comments}</span>
    </div>
  );
};

LineItemComment.propTypes = {
  lineItem: PropTypes.object,
};

export default LineItemComment;
