import PropTypes from 'prop-types';
import React from 'react';

import { useInspectionTypes } from 'fr-shared/hooks';

import styles from './PartOverview.module.css';

const Details = ({ className = 'mr-5', children, label }) => (
  <div className={className}>
    <div className={styles.Label}>{label}</div>
    <div className={styles.Value}>{children}</div>
  </div>
);

const PartOverview = ({ insight }) => {
  const { data: inspectionTypes } = useInspectionTypes();
  const { part, manufacturing_process, material } = insight;
  const currentRevision = part?.current_revision;
  const units = part?.units;

  return (
    <div className="bg-gray-600 rounded-md">
      <div className="flex p-3">
        <div>
          <h3 className="mb-3">Part Overview</h3>

          <div className="flex py-1">
            <Details label="File Name">{part.name.split('.')[0]}</Details>
            <Details label="File Format" className="mr-0">
              {part.name.split('.')[1]}
            </Details>
          </div>

          <div className="flex flex-col md:flex-row py-1">
            <Details label="Process">{manufacturing_process.name}</Details>
            {material && <Details label="Material">{material.name}</Details>}
            {insight.color && <Details label="Color">{insight.color.name}</Details>}
            {insight.inspection_type && (
              <Details label="Inspection">{insight.inspection_type.name}*</Details>
            )}
            <Details label="File Units">{units}</Details>
          </div>

          <div className="flex flex-col md:flex-row py-1">
            {currentRevision.max_x_length && (
              <Details label="Dimensions">
                {currentRevision.max_x_length} x {currentRevision.max_y_length} x{' '}
                {currentRevision.max_z_length} {units}
              </Details>
            )}
            {currentRevision.volume && (
              <Details label="Volume">
                {currentRevision.volume} {units}
                <sup>3</sup>
              </Details>
            )}
            {insight.weight && (
              <Details label="Esimated Part Weight">
                {insight.weight} {insight.weight_units}
              </Details>
            )}
          </div>
        </div>
      </div>

      {insight.inspection_type && inspectionTypes && (
        <div className="border-top text-center font-size-sm py-2">
          *{' '}
          {
            inspectionTypes.find(insp => insp.name === insight.inspection_type.name)
              ?.public_usecase_description
          }
        </div>
      )}
    </div>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

PartOverview.propTypes = {
  insight: PropTypes.object,
};

export default PartOverview;
