import React from 'react';

import { FormField } from 'fr-shared/components';

const DetailingAgentFormField = props => {
  return (
    <FormField
      name="detailing_agent_consumption"
      label="Detailing Agent"
      type="number"
      append="cm^3"
      {...props}
    />
  );
};

export default DetailingAgentFormField;
