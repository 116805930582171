import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'fr-shared/components';

const PackingSlipInformation = ({ packingSlip }) => (
  <div className="container">
    <div className="alert alert-success">
      Packing Slip and Certificate created successfully.
      <ul>
        <li>
          <Link to={{ pathname: packingSlip.packing_slip_path }} openNewWindow>
            Click here to download the <strong> packing slip </strong>
          </Link>
        </li>
        <li>
          <Link to={{ pathname: packingSlip.coc_path }} openNewWindow>
            Click here to download the <strong> Certificate of Conformance </strong>
          </Link>
        </li>
      </ul>
    </div>
  </div>
);

PackingSlipInformation.propTypes = {
  packingSlip: PropTypes.object,
};

export default PackingSlipInformation;
