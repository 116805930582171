import * as Sentry from '@sentry/react';
import { connect } from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Dropzone from 'react-dropzone';

import { Button, Icon, Loading } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { isModelProcessingComplete } from 'fr-shared/lib/costing_requests';
import { checkPassFail } from 'fr-shared/lib/manufacturability_checks';
import {
  AUTOCOSTABLE_MFG_PROCESSES,
  MANUFACTURING_PROCESSES,
} from 'fr-shared/lib/manufacturing_process';
import { uploadPartToS3AndFros } from 'fr-shared/lib/s3';
import { isFormikAtPrefixTheSame, keyboardDown } from 'fr-shared/utils';
import { PART_FILE_EXTENSIONS } from 'fr-shared/utils/files';

import DeletePartModal from './DeletePartModal';
import LineItemPublicLink from './LineItemPublicLink';

const LineItemPart = ({
  fieldPrefix,
  formik,
  lineItemName = 'line item',
  onClickPart = () => {},
  processFieldPrefix,
  readonly,
  showManufacturabilityChecks,
  showPublicLink,
  showSurfaceArea,
}) => {
  const { setAlert } = useContext(AlertContext);
  const [isUploading, setIsUploading] = useState(false);
  const lineItem = get(formik.values, fieldPrefix);
  const part = get(lineItem, 'part');
  const currentRevision = part?.current_revision;
  const weight = get(lineItem, 'weight');
  const weightUnits = get(lineItem, 'weight_units');
  const units = lineItem?.units || part?.units || 'mm';
  const loadingExpired = part && moment().diff(moment(part.inserted_at), 'minutes') >= 5;
  const isFileTypeSTL = get(currentRevision, 'file_type', '').toLowerCase().includes('stl');
  const hasSTLFile = !!get(currentRevision, 'stl_url');
  const screenshot = get(currentRevision, 'screenshot');
  const hasError = get(formik.errors, `${fieldPrefix}.part_id`);
  const lineItemProcess =
    processFieldPrefix != null ? get(formik.values, processFieldPrefix) : lineItem;

  const { passedCheckNames, totalChecks } = checkPassFail(
    currentRevision?.manufacturability_checks,
    lineItemProcess?.manufacturing_process
  );

  const showManufacturabilityChecksAlert = showManufacturabilityChecks && totalChecks > 0;

  const showPublicLinkBanner =
    showPublicLink &&
    AUTOCOSTABLE_MFG_PROCESSES.includes(lineItemProcess?.manufacturing_process?.name) &&
    lineItemProcess?.manufacturing_process?.name !== MANUFACTURING_PROCESSES.FDM &&
    isModelProcessingComplete(lineItem);

  const handleFileDrop = acceptedFiles => {
    const file = acceptedFiles[0];
    const hasCustomer = get(formik.values, 'customer.id');
    const part = lineItem.part;

    if (!hasCustomer) return;

    setIsUploading(true);
    formik.setFieldValue(`${fieldPrefix}.upload_status`, 'loading');
    formik.setTouched({ ...formik.touched, [`${fieldPrefix}.part`]: true });

    let partParams;

    if (part?.id) {
      partParams = { id: lineItem.part.id, units: units };
    } else {
      partParams = { organization_id: formik.values.customer.id, source: 'FROS', units: units };
    }

    uploadPartToS3AndFros(file, partParams)
      .then(partResponse => {
        formik.setFieldValue(fieldPrefix, {
          ...lineItem,
          description: partResponse.data.name,
          isOpen: true,
          part: {
            ...partResponse.data,
            documents: lineItem?.part?.documents,
            usage: lineItem?.part?.usage,
            designation: lineItem?.part?.designation,
          },
          part_id: partResponse.data.id,
          upload_status: 'success',
        });
      })
      .catch(error => {
        formik.setFieldValue(`${fieldPrefix}.upload_status`, 'error');
        setAlert({
          color: 'danger',
          message: 'Part Failed to upload. Please Refresh and try again.',
        });
        Sentry.setExtra('error', error);
        Sentry.captureMessage('Part Upload Error');
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const handleDropRejected = rejectedFiles => {
    if (rejectedFiles.length > 1) {
      setAlert({
        color: 'danger',
        message: 'Only one file at a time is allowed for upload. Please try again.',
      });
    } else {
      const acceptedFileTypes = PART_FILE_EXTENSIONS.join(', ');
      const rejectedFileName = rejectedFiles[0].name;
      setAlert({
        autoClose: false,
        color: 'danger',
        message: `${rejectedFileName} can't be uploaded because it is not a valid file type. Accepted file types are ${acceptedFileTypes}`,
      });
    }
  };

  const handleDelete = () => {
    formik.setFieldValue(fieldPrefix, {
      ...lineItem,
      part: null,
      part_id: null,
      description: null,
    });
  };

  const handleClickPart = () => {
    if (hasSTLFile) {
      onClickPart();
    }
  };

  return (
    <Dropzone
      accept={PART_FILE_EXTENSIONS}
      onDropAccepted={handleFileDrop}
      onDropRejected={handleDropRejected}
      multiple={false}
      disabled={readonly}
    >
      {({ getRootProps, getInputProps }) =>
        currentRevision ? (
          <div
            {...getRootProps()}
            className="border rounded overflow-hidden mb-2 p-1"
            key={fieldPrefix}
          >
            {showManufacturabilityChecksAlert && (
              <div role="alert">
                <div
                  onClick={handleClickPart}
                  onKeyDown={evt => keyboardDown(evt, 'Enter', handleClickPart)}
                  role="button"
                  tabIndex={0}
                  className="alert cursor-pointer bg-warning flex align-items-center text-white p-1 font-size-md mb-1"
                >
                  <Icon name="exclamation-triangle" right />
                  {passedCheckNames.length}/{totalChecks} checks passed
                  <div className="ml-auto flex align-items-center">
                    <Button color="white" className="font-size-sm px-1 py-0 mr-1">
                      STL Viewer
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {showPublicLinkBanner && (
              <LineItemPublicLink fieldPrefix={fieldPrefix} lineItem={lineItem} />
            )}

            <div className="flex">
              {screenshot ? (
                <div
                  onClick={handleClickPart}
                  onKeyDown={evt => keyboardDown(evt, 'Enter', handleClickPart)}
                  role="button"
                  tabIndex={0}
                  className="flex align-items-center bg-light animated fadeInLeft cursor-pointer mr-2"
                >
                  <img width={120} height={120} src={screenshot} alt={part.name} />
                </div>
              ) : (
                !loadingExpired && (
                  <div
                    className="rounded bg-light flex align-items-center justify-content-center d-inline-block mr-2"
                    style={{ width: 120, height: 120 }}
                  >
                    <Loading type="circle" color="#ddd" width={22} />
                  </div>
                )
              )}

              <div className="font-size-md line-height-sm">
                <div className="flex mb-1">
                  <strong className="mr-1">Part Name: </strong>
                  <p className="text-break">{part.name}</p>
                </div>
                <div className="flex mb-1">
                  <strong className="mr-1">Part ID: </strong>
                  <p>{part.id}</p>
                </div>
                {currentRevision.max_x_length && (
                  <div className="flex mb-1">
                    <strong className="mr-1">Dimensions: </strong>
                    <p>
                      {currentRevision.max_x_length} x {currentRevision.max_y_length} x{' '}
                      {currentRevision.max_z_length} {units}
                    </p>
                  </div>
                )}
                {currentRevision.volume && (
                  <div className="flex mb-1">
                    <strong className="mr-1">Part Volume: </strong>
                    <p>
                      {currentRevision.volume} {units}³
                    </p>
                  </div>
                )}
                {currentRevision.surface_area && showSurfaceArea && (
                  <div className="flex mb-1">
                    <strong className="mr-1">Surface Area: </strong>
                    <p>
                      {currentRevision.surface_area} {units}²
                    </p>
                  </div>
                )}
                {weight && (
                  <div className="flex mb-1">
                    <strong className="mr-1">Estimated Part Weight: </strong>
                    <p>
                      {weight} {weightUnits}
                    </p>
                  </div>
                )}
                <div className="flex flex-wrap align-items-center">
                  <Button
                    color="link"
                    size="sm"
                    className="mr-3"
                    to={{ pathname: currentRevision.url }}
                    openNewWindow
                  >
                    <Icon name="download" right />
                    Download Original File
                  </Button>

                  {!isFileTypeSTL && hasSTLFile && (
                    <Button
                      color="link"
                      size="sm"
                      className="mr-3"
                      to={{ pathname: currentRevision.stl_url }}
                      openNewWindow
                    >
                      <Icon name="download" right />
                      Download STL
                    </Button>
                  )}

                  {hasSTLFile && (
                    <Button color="link" size="sm" className="mr-3" onClick={handleClickPart}>
                      <Icon name="cube" right />
                      STL Viewer
                    </Button>
                  )}
                </div>
              </div>
              {!readonly && (
                <div className="ml-auto">
                  <DeletePartModal
                    part={part}
                    handleDelete={handleDelete}
                    lineItemName={lineItemName}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div {...getRootProps()} className="row form-group">
            <input {...getInputProps()} id="file-dropzone" />
            <div className="col-md-4">
              <label htmlFor="file-dropzone">Part File</label>
            </div>
            <div className="col-md">
              {!readonly ? (
                <Button loading={isUploading} color="secondary" block={true}>
                  Upload Part File
                </Button>
              ) : (
                'No Part File Uploaded'
              )}
              {hasError && <div className="form-control-error">{hasError}</div>}
            </div>
          </div>
        )
      }
    </Dropzone>
  );
};

LineItemPart.propTypes = {
  formik: PropTypes.object,
  fieldPrefix: PropTypes.string,
  readonly: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onClickPart: PropTypes.func,
  showManufacturabilityChecks: PropTypes.bool,
  showSurfaceArea: PropTypes.bool,
  showPublicLink: PropTypes.bool,
  lineItemName: PropTypes.string,
  processFieldPrefix: PropTypes.string,
};

const memoizedLineItemPart = connect(React.memo(LineItemPart, isFormikAtPrefixTheSame));

export default memoizedLineItemPart;
