import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { PartSelection } from 'portal/components';
import { PartsContext } from 'portal/contexts/PartsContext';

import { PartsGridView } from './PartsGridView';

const PartsGrid = ({ parts = [] }) => {
  const [isPartSelectionOpen, setIsPartSelectionOpen] = useState(false);

  const { selectedParts, removeSelectedPart, removeAllPartsFromSelection } =
    useContext(PartsContext);

  const selectedPartsLength = Object.keys(selectedParts).length;

  useEffect(() => {
    setIsPartSelectionOpen(selectedPartsLength > 0);
  }, [selectedPartsLength]);

  const closePartSelection = useCallback(() => {
    removeAllPartsFromSelection();
    setIsPartSelectionOpen(false);
  }, [removeAllPartsFromSelection, setIsPartSelectionOpen]);

  const handleRemovePart = useCallback(
    partId => removeSelectedPart(partId),
    [removeSelectedPart]
  );

  return (
    <>
      <PartsGridView parts={parts} pageName="Dash" />

      <PartSelection
        isOpen={isPartSelectionOpen}
        closePartSelection={closePartSelection}
        selectedParts={selectedParts}
        selectedPartsLength={selectedPartsLength}
        onRemovePart={handleRemovePart}
      />
    </>
  );
};

PartsGrid.propTypes = {
  parts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PartsGrid;
