import axios from 'axios';

import { logout } from 'fr-shared/auth';
import { API_DOMAIN, API_PROTOCOL_SUFFIX } from 'fr-shared/env';

export const api = axios.create({
  baseURL: `http${API_PROTOCOL_SUFFIX}://${API_DOMAIN}/api/v1/`,
  withCredentials: true,
});

// Excludes any route from the error.request.config.url that includes any of the following substring rules
const LOGOUT_REDIRECT_ROUTES_EXCLUDED = ['/users/me'];

// intercept api responses to check for 401s from auth
api.interceptors.response.use(
  response => {
    const newResponse = response;
    // axios wraps response in .data, and so does elixir
    newResponse.data = newResponse.data.data;
    return newResponse;
  },
  error => {
    const newError = error;

    // if any api request 401s, redirect to home
    const errorRequestUrl = error.response?.config?.url || '';
    const shouldIgnoreLogoutRedirect = LOGOUT_REDIRECT_ROUTES_EXCLUDED.some(rule =>
      errorRequestUrl.includes(rule)
    );
    if (error.response && error.response.status === 401) {
      logout(shouldIgnoreLogoutRedirect);
    }

    if (newError.response && newError.response.data) {
      // axios wraps response in .data, and so does elixir
      newError.response.data = newError.response.data.data;

      if (!newError.response.data) {
        // this means the server 500'd
        newError.response.data = {
          messages: [
            'Something unexpected happened. Please refresh your browser and try again. Our team has been notified.',
          ],
        };
      }
    }

    return Promise.reject(newError);
  }
);
