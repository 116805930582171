import { get } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Button, Card, Icon } from 'fr-shared/components';
import { usePSLIFeatureFlagLimit } from 'fr-shared/hooks';
import { ORDER_LINE_ITEM_STATES } from 'fr-shared/lib/orders';

import ShippingInformation from './ShippingInformation';
import styles from './ShippingInformation.module.css';
import AddPSLIModalForm from './planned_shipment_line_items/AddPSLIModalForm';
import PSLIEmptyState from './planned_shipment_line_items/PSLIEmptyState';
import PSLITableEdit from './planned_shipment_line_items/PSLITableEdit';

interface ShippingLineItem {
  lineItem: OrderLineItem;
  oliIndex: number;
}

const OrderDetail = ({ label, value }: { label: string; value: string | number }) => (
  <div className="flex-column mb-2 col-6">
    <div className="text-muted font-size-sm">{label}</div>
    <div className="font-size-md">{value}</div>
  </div>
);

const ShippingLineItem = ({ lineItem, oliIndex }: ShippingLineItem) => {
  const [plannedQuantity, setPlannedQuantity] = useState(0);
  const [numValidPSLI, setNumValidPSLI] = useState(0);
  const [workingPSLIIndex, setWorkingPSLIIndex] = useState(0);

  useEffect(() => {
    if (Array.isArray(lineItem.planned_shipment_line_items)) {
      const existingPlannedPSLIQuantity = lineItem.planned_shipment_line_items
        .filter((psli: PlannedShipmentLineItem) => psli.id !== undefined && !psli.valid)
        .reduce((acc: number, current: PlannedShipmentLineItem) => {
          const quantityInt = current.quantity ? current.quantity : 0;
          return acc + quantityInt;
        }, 0);

      const existingValidPSLICount = lineItem.planned_shipment_line_items.filter(
        (psli: PlannedShipmentLineItem) => psli.id !== undefined && !psli.valid
      ).length;

      const planningQuantity = lineItem.planned_shipment_line_items
        .filter((psli: PlannedShipmentLineItem) => psli.valid)
        .reduce((acc: number, current: PlannedShipmentLineItem) => {
          return acc + current.quantity;
        }, 0);

      const planningValidPSLICount = lineItem.planned_shipment_line_items.filter(
        (psli: PlannedShipmentLineItem) => psli.valid
      ).length;

      setPlannedQuantity(existingPlannedPSLIQuantity + planningQuantity);
      setNumValidPSLI(existingValidPSLICount + planningValidPSLICount);
    }
  }, [lineItem]);

  const isExistingOrder = !!(lineItem && lineItem.id);
  const lineItemQty = lineItem.quantity || 0;
  const manufacturingProcess = get(lineItem, 'manufacturing_process.name', '- -');
  const material = get(lineItem, 'material.name', '- -');
  const psliLimit = usePSLIFeatureFlagLimit() || 0;
  const totalPlannedQty = lineItemQty - plannedQuantity;

  const canAddPSLI = () => {
    if (lineItemQty === plannedQuantity) {
      return false;
    }
    // leaving as separate if statement for easy code cleanup in future once
    // we determined PSLI feature is stable and to be turned on for everyone
    if (numValidPSLI >= psliLimit) {
      return false;
    }
    // There is empty list of valid PSLIs or the sum of valid PSLI quantity is
    // less than OLI quantity.
    return true;
  };
  return (
    <Card border={true} className="mb-4">
      <Card.Header className="flex-column mt-1">
        <ShippingInformation
          fromShippingTab={true}
          isExistingOrder={isExistingOrder}
          lineItem={lineItem}
          oliIndex={oliIndex}
        />
        <div className="flex flex-row w-100 justify-content-between mt-1">
          <div className="flex flex-row">
            <OrderDetail label="Line Item ID" value={lineItem.id} />
            <OrderDetail label="Material" value={material} />
            <OrderDetail label="Technology" value={manufacturingProcess} />
          </div>
          <div>
            {lineItem.state !== ORDER_LINE_ITEM_STATES.Shipped && (
              <AddPSLIModalForm
                action={
                  <div id={`planned-shipment-lineitems-add-button_${oliIndex}`}>
                    <Button
                      className={styles.button}
                      color="primary"
                      dataTestId="planned-shipment-lineitems-add-button"
                      disabled={!(lineItemQty && canAddPSLI())}
                      outline
                      size="sm"
                    >
                      <Icon className="mr-1" name="plus" />
                      Add PSLI
                    </Button>
                  </div>
                }
                onActionHandler={() => {
                  const { planned_shipment_line_items: pslis } = lineItem;
                  const psliIndex = pslis && Array.isArray(pslis) ? pslis.length : 0;
                  setWorkingPSLIIndex(psliIndex);
                }}
                oliIndex={oliIndex}
                psliIndex={workingPSLIIndex}
                quantity={totalPlannedQty.toString()}
              />
            )}
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <PSLITableEdit
          isExistingOrder={true}
          oliIndex={oliIndex}
          plannedShipmentLineItems={lineItem.planned_shipment_line_items}
          quantity={totalPlannedQty.toString()}
        />
        {numValidPSLI === 0 && (
          <PSLIEmptyState addQuantityMessage={!(lineItemQty && canAddPSLI())} />
        )}
      </Card.Body>
    </Card>
  );
};

export default ShippingLineItem;
