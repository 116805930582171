import { useTreatments } from '@splitsoftware/splitio-react';
import { FieldArray, useFormikContext } from 'formik';
import { findIndex, isEmpty, set, uniq } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { useHistory } from 'react-router-dom';

import { api } from 'fr-shared/api';
import { Button, Modal } from 'fr-shared/components';
import { AlertContext } from 'fr-shared/context';
import { CR_BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { useFormikRef } from 'fr-shared/hooks';
import {
  COSTING_REQUEST_LI_STATES,
  LINE_ITEMS_ACCESSOR,
  handleBulkUpdates,
  isAutoquote,
  isSubmitted,
} from 'fr-shared/lib/costing_requests';

import { supplierCostSchema } from '../utils/schema';
import { getAllSelectedPartQuantities } from '../utils/selected';
import { transformCostingRequestLineItem } from '../utils/transforms';
import CollapseOrExpandAll from './CollapseOrExpandAll';
import CostingFormFilterAndSort from './CostingFormFilterAndSort';
import CostingFormLineItem from './CostingFormLineItem';
import CostingLineItemBulkEditor from './CostingLineItemBulkEditor';
import { CostingFormContext } from './context/CostingFormContext';

const updateCostingRequestLineItem = (lineItem, overrides) => {
  return api
    .put(`/costing_request_line_items/${lineItem.id}`, {
      costing_request_line_item: transformCostingRequestLineItem(lineItem, overrides),
    })
    .then(res => ({ ...lineItem, ...res.data }))
    .catch(error => Promise.reject(error));
};

const bulkUpdateLineItems = (lineItems, overrides) => {
  return lineItems.map(lineItem => {
    return updateCostingRequestLineItem(lineItem, overrides);
  });
};

export const validateAndUpdateLineItems = lineItems => {
  return lineItems.map(lineItem =>
    supplierCostSchema
      .validate(lineItem, { abortEarly: false, context: { lineItem } })
      .then(() =>
        updateCostingRequestLineItem(lineItem, {
          completed_at: moment(),
          state: COSTING_REQUEST_LI_STATES.Completed,
        })
      )
      .catch(e => e)
  );
};

const formatErrorMessage = (countFailedLineItems, countSelectedLineItems, lineItemName) => {
  return `${countFailedLineItems} out of ${countSelectedLineItems} selected ${lineItemName}(s) failed to submit. Please fix the errors below and submit again.`;
};

const CostingFormLineItems = ({ onDuplicateLineItems }) => {
  const { [CR_BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments(
    [CR_BUILD_PACK_UI_SUPPORT],
    { pr: location.host.split('.')[0] }
  );
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';
  const formik = useFormikContext();
  const formikRef = useFormikRef();
  const { lineItemFilters, lineItems } = useContext(CostingFormContext);
  const { setAlert } = useContext(AlertContext);
  const history = useHistory();
  const [showSubmitCostsModal, setShowSubmitCostsModal] = useState(false);

  if (isEmpty(lineItems)) return null;

  const isClosedTab = lineItems[0].state === 'Closed';
  const isCRSubmitted = isSubmitted(formik.values);
  const isCustomerPortalAutoquote = isAutoquote(formik.values);

  const handleUpdateLineItem = (lineItem, fieldPrefix, overrides = {}) => {
    return new Promise((resolve, reject) => {
      updateCostingRequestLineItem(lineItem, overrides)
        .then(lineItem => {
          const costingRequest = {
            state: formikRef.current.values?.state ?? null,
            opportunityId: formikRef.current.values?.opportunity_id ?? null,
            id: formikRef.current.values?.id ?? null,
            salesforceSynced: formikRef.current.values?.salesforce_synced ?? false,
          };
          if (costingRequest) setCostingRequestAlert(costingRequest);
          const newLineItems = formikRef.current.values.line_items.map(li => {
            const buildPackage = lineItem.build_package;
            const lineItemProcess = lineItem.build_package?.processes[0];
            return lineItem.id === li.id
              ? {
                  ...lineItem,
                  is_editing_part_information: false,
                  build_package: {
                    ...buildPackage,
                    processes: [
                      {
                        ...lineItemProcess,
                      },
                    ],
                  },
                }
              : li;
          });
          formik.setFieldValue('line_items', newLineItems);
          resolve(newLineItems);
        })
        .catch(err => {
          if (err.response?.data) {
            const { errors, messages } = err.response.data;
            const formikErrors = { server: messages };
            set(formikErrors, fieldPrefix, errors);
            formik.setErrors(formikErrors);
          } else {
            formik.setErrors({
              server: ['An unexpected error occurred. Refresh and try again.'],
            });
          }
          reject();
        });
    });
  };

  const onChangePosition = (dir, lineItem) => {
    const last = lineItems.length - 1;
    const index = lineItems.indexOf(lineItem);

    if ((index === 0 && dir === 'UP') || (index === last && dir === 'DOWN')) {
      // noop
    } else if (dir === 'UP') {
      lineItems[index] = lineItems[index - 1];
      lineItems[index - 1] = lineItem;
      const orderedLineItems = lineItems.map((li, index) => ({ ...li, position: index }));
      formik.setFieldValue('line_items', orderedLineItems);
    } else if (dir === 'DOWN') {
      lineItems[index] = lineItems[index + 1];
      lineItems[index + 1] = lineItem;
      const orderedLineItems = lineItems.map((li, index) => ({ ...li, position: index }));
      formik.setFieldValue('line_items', orderedLineItems);
    }
  };

  const handleDuplicateLineItems = selectedLineItems => {
    onDuplicateLineItems(selectedLineItems);
  };

  const handleDuplicateLineItem = lineItem => {
    onDuplicateLineItems([lineItem]);
  };

  const handleBulkSubmitCosts = selectedLineItems => {
    const updatePromises = validateAndUpdateLineItems(selectedLineItems);
    handleBulkUpdates(updatePromises, formik)
      .then(_lineItems => {
        const costingRequest = {
          state: formik.values?.state ?? null,
          opportunityId: formik.values?.opportunity_id ?? null,
          id: formik.values?.id ?? null,
          salesforceSynced: formik.values?.salesforce_synced ?? false,
        };
        setCostingRequestAlert(costingRequest);
      })
      .catch(countFailedLineItems => {
        setAlert({
          color: 'danger',
          message: formatErrorMessage(
            countFailedLineItems,
            selectedLineItems.length,
            lineItemName
          ),
        });
      });
  };

  const setCostingRequestAlert = () => {
    setAlert({ color: 'success', message: 'Your changes have been saved successfully!' });
  };

  const handleBulkUpdateState = async (selectedLineItems, state) => {
    const updatePromises = bulkUpdateLineItems(selectedLineItems, {
      state,
    });
    const numberOfSelectedLineItems = selectedLineItems.length;
    handleBulkUpdates(updatePromises, formik).catch(countFailedLineItems => {
      setAlert({
        color: 'danger',
        message: formatErrorMessage(
          countFailedLineItems,
          numberOfSelectedLineItems,
          lineItemName
        ),
      });
    });
  };

  const handleDownloadDocuments = async (selectedLineItems, _state) => {
    const ids = selectedLineItems
      .map(costingRequestLineItems => costingRequestLineItems.id)
      .join(',');

    api
      .get(`/costing_request_line_items_download?ids=` + ids)
      .then(res => {
        location.href = res.data.url;
      })
      .catch(() => {
        setAlert({
          color: 'danger',
          message: 'There was an error that occurred while downloading.',
        });
      })
      .finally(() => {});
  };

  const handleLineItemSelect = lineItem => {
    // if you're on the Completed tab, set the part quantities to selected when checking a build package
    if (lineItemFilters.state === 'Completed') {
      const selectedCosts = lineItem.supplier_costs.map(sc => ({
        ...sc,
        selected: !lineItem.selected,
        part_quantities: sc.part_quantities.map(pq => ({ ...pq, selected: !lineItem.selected })),
      }));
      const newLineItems = formikRef.current.values.line_items.map(li =>
        li.id === lineItem.id ? { ...lineItem, supplier_costs: selectedCosts } : li
      );

      formik.setFieldValue(`line_items`, newLineItems);
    }
  };

  const handleBulkConvertToQuote = () => {
    const {
      line_items,
      opportunity_id,
      public_id,
      sales_contact_id,
      engineering_contact_id,
      support_contact_id,
    } = formik.values;
    const selectedPartQuantities = getAllSelectedPartQuantities(line_items);
    const selectedPartQuantityIds = selectedPartQuantities.map(pq => pq.id);
    const fromMultipleSuppliers =
      uniq(selectedPartQuantities.map(pq => pq.supplier_cost_id)).length > 1;

    if (fromMultipleSuppliers && !showSubmitCostsModal) {
      openModal();
    } else {
      history.push('/admin/quotes/new', {
        opportunity_id,
        public_id,
        sales_contact_id,
        support_contact_id,
        engineering_contact_id,
        selectedPartQuantityIds,
        display_in_portal: true,
      });
    }
  };

  const closeModal = () => setShowSubmitCostsModal(false);
  const openModal = () => setShowSubmitCostsModal(true);

  return (
    <>
      <FieldArray name={LINE_ITEMS_ACCESSOR}>
        {arrayHelpers => {
          return (
            <>
              <div className="flex flex-column py-2 sticky-top bg-light">
                <div className="flex flex-row flex-1">
                  {!isClosedTab && !isCustomerPortalAutoquote && (
                    <CostingLineItemBulkEditor
                      onDuplicateLineItems={handleDuplicateLineItems}
                      onSubmitCosts={handleBulkSubmitCosts}
                      onConvertToQuote={handleBulkConvertToQuote}
                      isSubmitted={isCRSubmitted}
                      onBulkUpdateState={handleBulkUpdateState}
                      onDownloadDocuments={handleDownloadDocuments}
                    />
                  )}

                  <div className="flex ml-auto">
                    {isCRSubmitted && <CostingFormFilterAndSort />}
                  </div>
                </div>
                <CollapseOrExpandAll />
              </div>
              <Flipper flipKey={lineItems.map(li => li._id).join('')}>
                {lineItems.map((lineItem, arrayIndex) => {
                  const lineItemIndex = lineItem.id
                    ? findIndex(formik.values.line_items, li => li.id === lineItem.id)
                    : arrayIndex;
                  return (
                    <Flipped
                      key={`line-item-${lineItem.id || lineItem._id}`}
                      flipId={`line-item-${lineItem.id || lineItem._id}`}
                    >
                      {flippedProps => (
                        <div {...flippedProps}>
                          <CostingFormLineItem
                            fieldPrefix={`${LINE_ITEMS_ACCESSOR}.${lineItemIndex}`}
                            changePosition={onChangePosition}
                            lineItem={lineItem}
                            lineItemIndex={lineItemIndex}
                            arrayHelpers={arrayHelpers}
                            handleLineItemSelect={handleLineItemSelect}
                            isCustomerPortalAutoquote={isCustomerPortalAutoquote}
                            isCRSubmitted={isCRSubmitted}
                            handleUpdateLineItem={handleUpdateLineItem}
                            updateCostingRequestLineItem={updateCostingRequestLineItem}
                            onDuplicateLineItem={handleDuplicateLineItem}
                          />
                        </div>
                      )}
                    </Flipped>
                  );
                })}
              </Flipper>
            </>
          );
        }}
      </FieldArray>
      <Modal isOpen={showSubmitCostsModal}>
        <Modal.Header title="Multiple Suppliers" onClose={closeModal} />
        <Modal.Body>
          <p className="mb-2">
            Multiple suppliers have been selected to convert to a quote on the following line
            item(s):
          </p>
          <ul>
            {lineItems
              .filter(li => li.selected)
              .map((li, index) => (
                <li key={li.id || index}>
                  ID: {li.id} - {li.part?.name}
                </li>
              ))}
          </ul>
          <p>Continue anyway?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button color="secondary" onClick={closeModal}>
            No, Cancel
          </Button>
          <Button
            color="primary"
            dataTestId="costing-request-page--multiple-suppliers-modal--yes-convert-to-quote-button"
            onClick={handleBulkConvertToQuote}
          >
            Yes, Convert to Quote
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

CostingFormLineItems.propTypes = {
  onDuplicateLineItems: PropTypes.func,
};

export default CostingFormLineItems;
