import React from 'react';
import { Helmet } from 'react-helmet';

const Maintenance = () => {
  return (
    <>
      <Helmet title="Routine Maintenance">
        <body className="bg-dark" />
      </Helmet>

      <div className="p-8 max-w-[860px]">
        <h1 className="text-[56px] leading-[67px] font-normal text-coolGray-100 m-0">
          {"We're down for maintenance"}
        </h1>
        <p className="text-[56px] leading-[67px] text-coolGray-300">
          {
            "We're making some essential updates right now. Please come back to your account in a few hours."
          }
        </p>
      </div>
    </>
  );
};

export default Maintenance;
