import { get } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';

import { api } from 'fr-shared/api';
import { UserContext } from 'fr-shared/context';

// This hook is used to get the current subscription information on a user

// Here is what is available for use on this hook:
// currentSubscription - type : string (this is a string of the current plan name ex: "Basic" or "Pro")
// isError - type: boolean (will return true if the api call to get this information fails(*not for basic))
// isLoading - type: boolean (will return true when api is fetching it's data)
// refreshSubscription - type: func (call if you need to refresh subscription data)
// subscriptionData - type: Subscription (this is the data of the active subscription)

const useCurrentSubscriptionPlan = (userIdSearchParam?: string) => {
  const [currentSubscription, setCurrentSubscription] = useState('Basic');
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(UserContext);
  const userId = userIdSearchParam ? userIdSearchParam : user.id;

  const refreshSubscription = useCallback(async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const { data } = await api.get(`/users/${userId}/subscription`);
      setSubscriptionData(data);
      setIsLoading(false);
      //active plan will be basic since pro plan was cancelled
      if (data.status === 'Canceled') {
        setCurrentSubscription('Basic');
      } else {
        setCurrentSubscription(get(data, 'plan_version.plan.name', 'Pro'));
      }
    } catch {
      setIsLoading(false);
      if (currentSubscription !== 'Basic') {
        setIsError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    refreshSubscription();
  }, [refreshSubscription]);

  return { currentSubscription, isError, isLoading, refreshSubscription, subscriptionData };
};

export default useCurrentSubscriptionPlan;
