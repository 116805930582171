import { useTreatments } from '@splitsoftware/splitio-react';
import PropTypes from 'prop-types';
import React from 'react';

import {
  FormFastField,
  FormInspectionTypes,
  FormManufacturingProcess,
  FormSelect,
  FormSupportingDocuments,
  Icon,
  LineItemPart,
} from 'fr-shared/components';
import { BUILD_PACK_UI_SUPPORT } from 'fr-shared/feature_flags';
import { useToggle } from 'fr-shared/hooks';
import { keyboardDown } from 'fr-shared/utils';

const QuoteFormPartDetails = ({ index, lineItem }) => {
  const { [BUILD_PACK_UI_SUPPORT]: buildPackUiSupportFlag } = useTreatments([
    BUILD_PACK_UI_SUPPORT,
  ]);
  const lineItemName = buildPackUiSupportFlag.treatment === 'on' ? 'build pack' : 'line item';

  const isMissingPartFile = !lineItem.part?.current_revision;
  const [open, toggle] = useToggle(isMissingPartFile);

  const fieldPrefix = `line_items.${index}`;

  return (
    <div className="part-details mt-3">
      <div
        onClick={toggle}
        className="part-details-header cursor-pointer flex"
        onKeyDown={evt => keyboardDown(evt, 'Enter', toggle)}
        role="button"
        tabIndex={0}
      >
        Part Details
        <div className="ml-auto">
          <Icon name={open ? 'chevron-up' : 'chevron-down'} />
        </div>
      </div>
      {open && (
        <div className="part-details-body">
          <div className="card-body border-top p-0 border-0">
            <div className="row">
              <div className="col-md-6">
                <FormFastField
                  label="Part Name"
                  name={`${fieldPrefix}.description`}
                  readonly={true}
                />
                <FormSelect
                  label="File Units"
                  name={`${fieldPrefix}.units`}
                  readonly={true}
                  hasBlankOption={false}
                  valueAccessor={option => option}
                  nameAccessor={option => option}
                />
                <FormManufacturingProcess
                  readonly={true}
                  fieldPrefix={fieldPrefix}
                  showBuildTime={false}
                  showFinish={false}
                />
                <FormFastField
                  label="Finish Description"
                  name={`${fieldPrefix}.finish_description`}
                  component="textarea"
                  readonly={true}
                />
                <FormInspectionTypes
                  name={`${fieldPrefix}.inspection_type_id`}
                  label="Inspection Type"
                  readonly={`${fieldPrefix}.inspection_type.name_with_price`}
                />
              </div>
              <div className="col-md-6">
                <LineItemPart
                  fieldPrefix={`line_items.${index}`}
                  readonly={false}
                  lineItemName={lineItemName}
                />
                <FormSupportingDocuments readonly={true} fieldPrefix={`line_items.${index}`} />
                <FormFastField
                  label="Notes"
                  name={`${fieldPrefix}.line_item_notes`}
                  component="textarea"
                  readonly={true}
                  inputStyle={{ minHeight: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

QuoteFormPartDetails.propTypes = {
  index: PropTypes.number,
  lineItem: PropTypes.object,
};

export default QuoteFormPartDetails;
